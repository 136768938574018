import React, { useContext, useState } from 'react'
import { Logger } from 'aws-amplify'
import { CustomForm } from '../../.././Components'
import { WarningOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import {
  initSubscription,
  marshalCustomer,
  marshalSubscription,
} from '../../../utils'
import { CustomerContext } from '../contexts'
import FormHeader from './FormHeader'
import CustomerForm from './Form'

const log = new Logger('FormWrapper')
const FormWrapper = (props) => {
  // inject intl
  const intl = useIntl()

  const { customer, legal_forms } = props

  // get current phase, if unset then it's on registry phase
  const phase = _.get(props, 'phase', 'registry')

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  // button type
  const [buttonType, setButtonType] = useState({
    type: 'primary',
    danger: false,
  })

  // form submit
  const onFinish = (values) => {
    log.debug('submit form...', values)

    setLoading(true)
    document.getElementById('customer-form').submit()
  }

  const [loading, setLoading] = useState(false)

  const onFinishFailed = (e) => {
    log.warn('Finish failed', e)
    setButtonType({
      type: 'dashed',
      icon: <WarningOutlined />,
      danger: true,
    })
  }

  log.debug('rendering customers form ...')

  const newCustomer = marshalCustomer(customer)

  let chain = _.chain(customer)
    .thru((c) => marshalCustomer(c))
    .tap((c) => {
      _.map(c.facilities, (f) => {
        if (_.get(f, 'subscription.id')) {
          f.subscription = marshalSubscription(f.subscription)
        } else {
          f.subscription ||= initSubscription
        }
        f.referents = _.map(f.referents, (r) => {
          r.roles ||= []
          return r
        })
        f.required_equipment_vehicles ||= []
        return f
      })
      log.debug('Customer ', c)
    })
    .value()

  log.debug('Lodash chain: ', chain)

  // marshalled customer
  const initialValues = newCustomer

  log.debug('Marshalled customer', newCustomer)

  const { readonly } = useContext(CustomerContext)

  log.debug('Readonly: ', readonly)

  const phaseParam = () => _.split(window.location.href, 'edit', 2)

  return (
    <CustomForm
      layout={'vertical'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      method={'post'}
      action={`/customers${
        newCustomer.id
          ? `/${newCustomer.id}${_.get(phaseParam(), '[1]', null)}`
          : ''
      }`}
      name={'customer-form'}
      validateMessages={validateMessages}
      disabled={loading}
    >
      <FormHeader buttonType={buttonType} formLoading={loading}>
        {props.children}
      </FormHeader>

      <CustomerForm
        initialValues={initialValues}
        loading={loading}
        legal_forms={legal_forms}
        phase={phase}
      />
    </CustomForm>
  )
}

export default FormWrapper
