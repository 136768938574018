import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { priorities as interventionPriorities } from '../../enums/intervention'

const log = new Logger('Facility/marshal')

export const marshalFacility = (facility) => {
  if (_.isEmpty(_.get(facility, 'address', null))) {
    return {
      // default address value
      address: {
        address_type: 'physical',
        country: 'ITA',
      },
      ...facility,
    }
  }

  return facility
}

export const getPin = (facility, stages) => {
  log.debug('getPin.start', { facility, stages })

  const interventions = _.filter(stages, (i) =>
    _.includes(['to_schedule', 'in_progress'], _.get(i, 'status'))
  )

  const priorities = _.map(interventions, (i) => _.get(i, 'priority'))

  let pinString = ''

  _.forEach(_.reverse(_.slice(interventionPriorities)), (priority) => {
    if (_.includes(priorities, priority)) {
      pinString = priority + '_pin'
      return false
    }
  })

  if (!_.isEmpty(pinString)) return pinString
  if (_.find(interventions, { typology: 'maintenance_subscription' }))
    pinString = 'maintenance_pin'

  return pinString
}
