[
  {
    "key": 1,
    "value": "Claudio Spilotti"
  },
  {
    "key": 2,
    "value": "Remo Toffolutti"
  },
  {
    "key": 3,
    "value": "Lorenzo Spilotti"
  },
  {
    "key": 4,
    "value": "David Del Riccio"
  },
  {
    "key": 5,
    "value": "Marco Petri"
  },
  {
    "key": 6,
    "value": "Alessio Marega"
  },
  {
    "key": 7,
    "value": "Sorin Bancos"
  },
  {
    "key": 8,
    "value": "Luca Martinis"
  },
  {
    "key": 8,
    "value": "Altro"
  }
]