import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { WarningOutlined } from '@ant-design/icons'
import { Form, message } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { CustomForm } from '../../.././Components'
import { FormHeader } from '../components'
import { InvoiceContext } from '../contexts'

const log = new Logger('Invoices/form/InvoiceForm')

const InvoiceForm = (props) => {
  // inject intl
  const intl = useIntl()

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  const { title } = props
  const { invoice } = useContext(InvoiceContext)

  const [loading, setLoading] = useState(false)

  // button type
  const [buttonType, setButtonType] = useState({
    type: 'primary',
    danger: false,
  })

  const onFinish = (values) => {
    log.debug('Values', values)
    setLoading(true)

    const interventions = form.getFieldValue('interventions')

    log.debug(interventions)

    if (_.isEmpty(interventions)) {
      message.error(
        intl.formatMessage(
          { id: 'ui.messages.invoices.at_least_one' },
          {
            model: intl.formatMessage({ id: 'ui.interventions.singular' }),
          }
        )
      )
      setLoading(false)
      return
    } else {
      document.getElementById('invoice-form').submit()
    }
  }

  const onFinishFailed = (e) => {
    log.warn('Finish failed', e)
    setButtonType({
      type: 'dashed',
      icon: <WarningOutlined />,
      danger: true,
    })
  }

  const [form] = Form.useForm()

  return (
    <CustomForm
      form={form}
      action={_.get(props, 'action', '/invoices')}
      layout={'vertical'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={invoice}
      method={'post'}
      name={'invoice-form'}
      validateMessages={validateMessages}
      disabled={loading}
    >
      {/* Add Form header based on mode */}
      <FormHeader title={title} buttonType={buttonType} />

      {props.children}

      {/*  Duplicate Form header on bottom*/}
      <FormHeader
        buttonType={buttonType}
        rowStyle={{
          paddingTop: '10px',
        }}
      />
    </CustomForm>
  )
}

export default InvoiceForm
