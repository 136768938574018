import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Col, Form, Input, Row } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { DateTimePickerField, TextAreaField } from '@components/Fields'
import { LocalSelect, SingleSelect, TagSelect } from '@components/Select'

import { GlobalContext } from '@/contexts'

import { GutteredRow, StatusTag } from '../../.././Components'
import { DisplayDetail, LocaleDisplay } from '../../../Components/Display'
import { priorities, statuses } from '../../../enums/call'
import { CallContext } from '../contexts'

const log = new Logger('Call/FormFields')

const FormFields = ({ facilities, referents }) => {
  const { call } = useContext(CallContext)

  const { mode } = useContext(GlobalContext)

  const intl = useIntl()

  // form instance
  const form = Form.useFormInstance()

  const options = (referents) =>
    _.uniqBy(
      _.map(referents, (r, key) => {
        const translatedRoles = R.map(
          (role) =>
            R.unless(
              R.either(R.isNil, R.isEmpty),
              (v) =>
                intl.formatMessage({
                  id: `ui.referents.enums.roles.${v}`,
                }),
              role
            ),
          r.roles
        )

        return {
          key,
          id: _.get(r, 'id', null),
          label: `${r.first_name} ${r.last_name} - ${R.join(
            ',',
            translatedRoles
          )}`,
          value: `${r.first_name} ${r.last_name} - ${R.join(
            ',',
            translatedRoles
          )}`,
        }
      }),
      'value'
    )

  // build phone options once reference is selected
  const phoneOptions = (referent) => {
    const options = []

    _.forEach(['phone_1', 'phone_2'], (phone, key) => {
      if (_.isEmpty(_.get(referent, phone))) return

      options.push({
        key,
        id: _.get(referent, 'id', null),
        label: _.get(referent, phone),
        value: _.get(referent, phone),
      })
    })

    return options
  }

  // states
  const [reportedByOptions, setReportedByOptions] = useState(options(referents))
  const [contactOptions, setContactOptions] = useState(options(referents))
  const [reportedByPhoneOptions, setReportedByPhoneOptions] = useState(
    phoneOptions(_.get(call, 'reported_by'))
  )
  const [contactPhoneOptions, setContactPhoneOptions] = useState(
    phoneOptions(_.get(call, 'contact_phone'))
  )

  log.debug({
    reportedByPhoneOptions,
    reportedByOptions,
    contactOptions,
  })

  // update phones
  useEffect(() => {
    if (!_.isEmpty(reportedByPhoneOptions))
      form.setFieldValue('phone', _.map(reportedByPhoneOptions, 'value'))
    if (!_.isEmpty(contactPhoneOptions))
      form.setFieldValue('contact_phone', _.map(contactPhoneOptions, 'value'))
  }, [reportedByPhoneOptions, contactPhoneOptions])

  return (
    <>
      {/*  Call ID only on detail */}
      {mode === 'detail' ? (
        <GutteredRow>
          <Col xl={4}>
            <DisplayDetail
              label={<FormattedMessage id={'ui.calls.attributes.id'} />}
              value={_.get(call, 'id')}
            />
          </Col>

          <Col md={6}>
            {/*  Facility Reference */}
            <SingleSelect
              name={`facility_id`}
              required
              options={_.map(facilities, (f) => {
                return {
                  label: f.business_name,
                  value: f.id,
                }
              })}
              label={`ui.calls.form.labels.facility`}
              defaultValue={call.facility_id}
              disabled={mode === 'edit'}
              onSelect={(value) => {
                const facility = _.find(facilities, { id: value })
                if (!_.isNull(_.get(facility, 'subscription')))
                  form.setFieldValue('priority', 'high')
                else form.setFieldValue('priority', null)
              }}
            />
          </Col>
        </GutteredRow>
      ) : (
        <GutteredRow>
          {/*  Facility Reference */}
          <Col md={12}>
            <SingleSelect
              name={`facility_id`}
              required
              options={_.map(facilities, (f) => {
                return {
                  label: f.business_name,
                  value: f.id,
                }
              })}
              label={`ui.calls.form.labels.facility`}
              defaultValue={call.facility_id}
              disabled={mode === 'edit'}
              onSelect={(value) => {
                const facility = _.find(facilities, { id: value })
                if (!_.isNull(_.get(facility, 'subscription')))
                  form.setFieldValue('priority', 'high')
                else form.setFieldValue('priority', null)
                setReportedByOptions(
                  options(
                    _.filter(referents, (referent) =>
                      _.includes(_.get(referent, 'facility_ids', []), value)
                    )
                  )
                )
                setContactOptions(
                  options(
                    _.filter(referents, (referent) =>
                      _.includes(_.get(referent, 'facility_ids', []), value)
                    )
                  )
                )
              }}
            />
          </Col>
        </GutteredRow>
      )}

      <GutteredRow>
        {/*  Requested At*/}
        <Col xl={4}>
          <DateTimePickerField
            name={'requested_at'}
            required
            label={'ui.calls.form.labels.requested_at'}
            initialValue={call.requested_at}
            disabled={mode === 'edit'}
          />
        </Col>

        {/*  Priority*/}
        <Col md={5}>
          <LocalSelect
            name={'priority'}
            label={`ui.calls.form.labels.priority`}
            required
            options={priorities}
            localAttribute={`ui.calls.enums.priorities`}
            defaultValue={call.priority}
            useWatch={true}
          />
        </Col>

        {/*  Status */}

        {mode === 'edit' && (
          <Col md={4}>
            <LocalSelect
              name={'status'}
              label={`ui.calls.form.labels.status`}
              required
              options={statuses}
              localAttribute={`ui.calls.enums.statuses`}
              defaultValue={call.status}
            />
          </Col>
        )}

        {/* Detail mode */}
        {mode === 'detail' && (
          <Col xl={4}>
            <Row style={{ flexDirection: 'column' }}>
              <Col style={{ paddingBottom: '8px' }}>
                <FormattedMessage id={'ui.calls.attributes.status'} />
              </Col>
              <Col>
                <StatusTag status={call.status}>
                  <LocaleDisplay
                    localePath={`ui.calls.enums.statuses`}
                    value={call.status}
                  />
                </StatusTag>
              </Col>
            </Row>
          </Col>
        )}
      </GutteredRow>

      <GutteredRow>
        {/*  Reported By*/}
        <Col xl={6}>
          <TagSelect
            name={'reported_by'}
            options={reportedByOptions}
            label={`ui.calls.form.labels.reported_by`}
            required
            tag_mode={'single'}
            defaultValue={call.reported_by}
            onChange={(value, form) => {
              log.debug('value, form', { value, form })
              if (!_.isNil(value))
                setReportedByPhoneOptions(
                  phoneOptions(_.find(referents, { id: value.id }))
                )
            }}
          />
        </Col>

        {/* Phone */}
        <Col xl={5}>
          <TagSelect
            name={'phone'}
            options={reportedByPhoneOptions}
            label={`ui.calls.form.labels.phone`}
            required
            defaultValue={call.phone}
            useWatch
            customRenderer={(value, railsName) => {
              return (
                <Input
                  name={railsName}
                  type={'hidden'}
                  value={_.join(value, ',')}
                />
              )
            }}
            customValidator={[
              {
                pattern: /^\d/,
                message: intl.formatMessage({
                  id: 'ui.validations.invalid_field',
                }),
              },
              {
                validator: (rule, value) => {
                  if (value && value.length > 2) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(
                          {
                            id: 'ui.validations.tag_select.multiple',
                          },
                          {
                            count: 2,
                          }
                        )
                      )
                    )
                  }

                  return Promise.resolve()
                },
              },
            ]}
          />
        </Col>
      </GutteredRow>

      {/* CONTACT INFO */}
      <GutteredRow>
        {/* Contact Person */}
        <Col xl={6}>
          <TagSelect
            name={'contact_person'}
            options={contactOptions}
            label={`ui.calls.form.labels.contact_person`}
            tag_mode={'single'}
            defaultValue={_.get(call, 'contact_person')}
            onChange={(value, form) => {
              log.debug('value, form', { value, form })
              if (!_.isNil(value))
                setContactPhoneOptions(
                  phoneOptions(_.find(referents, { id: value.id }))
                )
            }}
          />
        </Col>

        {/* Contact Phone */}
        <Col xl={5}>
          <TagSelect
            name={'contact_phone'}
            options={contactPhoneOptions}
            label={`ui.calls.form.labels.contact_phone`}
            useWatch
            defaultValue={call.contact_phone}
            customRenderer={(value, railsName) => {
              return (
                <Input
                  name={railsName}
                  type={'hidden'}
                  value={_.join(value, ',')}
                />
              )
            }}
            customValidator={[
              {
                validator: (rule, value) => {
                  if (value && !String(value).match(/\d*$/)) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage({
                          id: 'ui.validations.invalid_field',
                        })
                      )
                    )
                  }

                  return Promise.resolve()
                },
              },
              {
                validator: (rule, value) => {
                  if (value && value.length > 2) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(
                          {
                            id: 'ui.validations.tag_select.multiple',
                          },
                          {
                            count: 2,
                          }
                        )
                      )
                    )
                  }

                  return Promise.resolve()
                },
              },
            ]}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        {/*  Info */}
        <Col xl={12}>
          <TextAreaField
            name={'info'}
            label={<FormattedMessage id={`ui.calls.form.labels.info`} />}
          />
        </Col>
        {/*  Notes */}
        <Col xl={12}>
          <TextAreaField
            name={'notes'}
            label={<FormattedMessage id={`ui.calls.form.labels.notes`} />}
          />
        </Col>
      </GutteredRow>
    </>
  )
}

export default FormFields
