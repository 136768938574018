import React from 'react'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import Display from "./Display";

const log = new Logger('DisplayDateTime')
const DisplayDateTime = ({ value, format = 'DD/MM/YYYY HH:mm ' }) => {
  if (_.isNil(value)) return <Display />

  const dayjsObj = dayjs(value)
  const parsedValue = dayjsObj.format(format)

  log.verbose('Parsed datetime', parsedValue)
  return <Display value={parsedValue} />
}

export default DisplayDateTime
