import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Button, Col, DatePicker, Row, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import * as R from 'ramda'

import { INTERVENTION_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'

import { CenteredSpinner, GutteredRow } from '../.././Components'
import { GlobalContext } from '../../contexts'
import { StyledCard } from '../../styled'
import { InterventionSearch, InterventionsTable } from './components'
import { InterventionsContextProvider } from './contexts'

dayjs.extend(customParseFormat)

const { Title } = Typography
const { RangePicker } = DatePicker

const log = new Logger('InterventionsIndex')

const Index = (props) => {
  log.debug('Rendering interventions view ...', props)

  const intl = useIntl()

  // get interventions data
  const [interventions, setInterventions] = useState(props.interventions)

  log.info('Interventions data: ', interventions)

  // loading state
  const [loading, setLoading] = useState(false)

  // search loading state
  const [searchLoading, setSearchLoading] = useState(false)

  // query
  const [query, setQuery] = useState(
    F.retrieveFiltersOr('', ['interventions', 'query'])
  )

  // filters
  const [filters, setFilters] = useState(
    R.unless(
      R.any(R.propEq('field', 'break')),
      R.concat([{ field: 'break', values: ['0'] }])
    )(F.retrieveFiltersOr([], ['interventions', 'filters']))
  )
  // ranges
  const [ranges, setRanges] = useState(
    F.retrieveFiltersOr([], ['interventions', 'ranges'])
  )
  // orders
  const [orders, setOrders] = useState(
    F.retrieveFiltersOr([], ['interventions', 'orders'])
  )
  const [pagination, setPagination] = useState(
    F.retrieveFiltersOr(DEFAULT_PAGER, ['interventions', 'pagination'])
  )

  const { setMode } = useContext(GlobalContext)

  useEffect(() => {
    setMode('list')
  }, [])

  const handleRangeChange = (dates) => {
    if (_.isEmpty(dates)) {
      setRanges([])
      return
    }
    const startDate = dates[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const endDate = dates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')

    log.debug('parsed dates', { startDate, endDate })

    setRanges([
      {
        field: 'expected_date',
        from: startDate,
        to: endDate,
      },
    ])
  }

  const filterInterventions = async () => {
    setSearchLoading(true)
    const { data, headers } = await makeApiRequest(
      INTERVENTION_SEARCH,
      'post',
      {
        body: {
          query,
          filters,
          ranges,
          orders,
        },
        queryParams: F.marshalPaginationTo(pagination),
      }
    )

    const newPagination = {
      ...pagination,
      ...F.marshalPaginationFrom(headers),
    }

    log.debug('filterInterventions.success ', { data, newPagination })

    F.storeFilters({
      interventions: {
        filters,
        orders,
        ranges,
        query,
        pagination: newPagination,
      },
    })

    setPagination(newPagination)
    setInterventions(data)
    setSearchLoading(false)
  }
  return loading ? (
    <CenteredSpinner />
  ) : (
    <InterventionsContextProvider
      value={{
        interventions,
        setInterventions,
        query,
        setQuery,
        filters,
        setFilters,
        ranges,
        orders,
        setOrders,
        searchLoading,
        setSearchLoading,
        pagination,
        setPagination,
      }}
    >
      <GutteredRow>
        <Col>
          <Title level={3}>
            <FormattedMessage id={'ui.interventions.list_name'} />
          </Title>
        </Col>
      </GutteredRow>
      <Row justify={'space-between'}>
        <Col xl={10}>
          {/* interventions search*/}
          <InterventionSearch
            onSearch={() =>
              setPagination({ total: pagination.total, ...DEFAULT_PAGER })
            }
          />
        </Col>
        <Col xl={6}>
          {/*  range picker for filter*/}
          <RangePicker
            placeholder={[
              intl.formatMessage({ id: 'ui.placeholders.date.from' }),
              intl.formatMessage({ id: 'ui.placeholders.date.to' }),
            ]}
            defaultValue={F.rangeForPicker(R.head(ranges))}
            format={'DD-MM-YYYY'}
            onChange={handleRangeChange}
          />
        </Col>
        <Col
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type='primary'
            href='/interventions/new'
            onClick={() => setLoading(!loading)}
          >
            <FormattedMessage id={'ui.interventions.buttons.create'} />
          </Button>
        </Col>
      </Row>
      <StyledCard>
        <InterventionsTable
          dataSource={interventions}
          searchInterventions={filterInterventions}
        />
      </StyledCard>
    </InterventionsContextProvider>
  )
}

export default Index
