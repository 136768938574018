import React from 'react'
import { FormattedMessage } from 'react-intl/lib'

import { Card, Col } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import styled from 'styled-components'

import { CenteredSpinner, GutteredRow } from '../../../.././Components'
import {
  Display,
  DisplayDateTime,
  LocaleDisplay,
} from '../../../../Components/Display'
import { StyledBoldDetail } from '../../../../styled'

dayjs.extend(customParseFormat)

const log = new Logger('InterventionCard')

const StyledCard = styled(Card)`
  padding: 10px !important;

  .ant-card-body {
    padding: 0 !important;
  }
`
const InterventionCard = ({ intervention }) => {
  log.debug('Intervention', { intervention })

  const cardCondition = _.get(intervention, 'status') === 'in_progress'

  return (
    <StyledCard
      style={{
        backgroundColor: cardCondition && 'rgba(0, 0, 0, 0.2)',
      }}
    >
      <GutteredRow>
        <Col xl={24}>
          <StyledBoldDetail>
            <LocaleDisplay
              style={{
                fontWeight: 'bold',
              }}
              localePath={`ui.interventions.enums.typologies`}
              value={_.get(intervention, 'typology')}
            />
            {' - '}
            <Display value={_.get(intervention, 'id')} />
          </StyledBoldDetail>
        </Col>
        <Col xl={24}>{intervention.facility.business_name}</Col>
        <Col xl={24}>
          <FormattedMessage id={`ui.routes.interventions.expected_at`} />:
          <DisplayDateTime
            value={intervention.expected_date}
            format={'DD/MM/YYYY'}
          />
          -{' '}
          <LocaleDisplay
            value={_.get(intervention, 'priority')}
            localePath={`ui.interventions.enums.priorities`}
          />
        </Col>
      </GutteredRow>
    </StyledCard>
  )
}

export default InterventionCard
