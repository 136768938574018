import React from 'react'
import { Tag } from 'antd'

const StatusTag = ({ status, children }) => {
  let color = null
  switch (status) {
    case 'active':
      color = '#35C2A2'
      break
    case 'pending':
      color = '#F3BB5D'
      break
    case 'canceled':
      color = '#A8071A'
      break
  }
  return <Tag color={color}>{children}</Tag>
}

export default StatusTag
