import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Card, Col, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { CenteredSpinner, GutteredRow } from '../.././Components'
import { GlobalContext } from '../../contexts'
import { fetchBreakStage, marshalRoute } from '../../utils'
import { InterventionsMap } from './components'
import { RouteContextProvider } from './contexts'
import { RouteForm } from './form'
import { InterventionsList, RouteDetails } from './form/sidebar'

const log = new Logger('RouteNew')

const New = (props) => {
  const [loading, setLoading] = useState(false)

  const routeToMarshal = _.get(props, 'route', {})

  // routes
  const [route, setRoute] = useState(marshalRoute(routeToMarshal))

  // total distance
  const [totalDistance, setTotalDistance] = useState(
    _.defaultTo(_.get(route, 'total_distance'), 0)
  )

  // total time
  const [totalTime, setTotalTime] = useState(
    _.defaultTo(_.get(route, 'total_time'), 0)
  )

  // interventions
  const [interventions, setInterventions] = useState(
    _.get(props, 'interventions', [])
  )

  // filters
  const [filters, setFilters] = useState([
    {
      field: 'status',
      values: ['to_schedule'],
    },
    ,
    {
      field: 'expected_date',
      values: [1],
    },
  ])
  // ranges
  const [ranges, setRanges] = useState([])
  // orders
  const [orders, setOrders] = useState([
    {
      field: 'priority',
      value: 'desc',
    },
    {
      field: 'expected_date',
      value: 'asc',
    },
  ])

  // stages
  const [stages, setStages] = useState([fetchBreakStage()])

  const { setMode } = useContext(GlobalContext)

  log.debug('props, route, interventions', { props, route, interventions })
  useEffect(() => {
    setMode('create')
  }, [])

  return (
    <RouteContextProvider
      value={{
        route,
        setRoute,
        interventions,
        setInterventions,
        filters,
        ranges,
        setRanges,
        orders,
        setOrders,
        stages,
        setStages,
        totalDistance,
        setTotalDistance,
        totalTime,
        setTotalTime,
      }}
    >
      {loading ? (
        <CenteredSpinner />
      ) : (
        <RouteForm
          title={
            <Typography.Title level={2}>
              <FormattedMessage id={'ui.routes.actions.create'} />
            </Typography.Title>
          }
          {...props}
        >
          <Card>
            <GutteredRow>
              {/* Interventions List */}
              <Col xl={5}>
                <InterventionsList />
              </Col>

              {/*  Google Maps */}
              <Col xl={12}>
                <InterventionsMap {...props} />
              </Col>

              {/*  Route Detail*/}
              <Col xl={7}>
                <RouteDetails />
              </Col>
            </GutteredRow>
          </Card>
        </RouteForm>
      )}
    </RouteContextProvider>
  )
}

export default New
