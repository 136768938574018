import React, { useContext, useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import { GutteredRow } from '../../.././Components'
import { Col, Form } from 'antd'
import { FormattedMessage } from 'react-intl'
import { MaterialContext } from '../contexts'
import _ from 'lodash'
import { categories } from '../../../enums'
import { InputField, TextAreaField } from '../../../Components/Fields'
import { LocalSelect } from '../../../Components/Select'
import { StyledFieldCurrency, StyledInputNumber } from '../../../styled'

const log = new Logger('MaterialFormFields')

const FormFields = () => {
  const { material } = useContext(MaterialContext)

  // states
  const [unitPriceFields, setUnitPriceFields] = useState({
    ..._.pick(material, 'vendor_price', 'sales_margin'),
    form: Form.useFormInstance(),
  })

  // update unit price
  useEffect(() => {
    const { form } = unitPriceFields

    const salesMargin = _.get(unitPriceFields, 'sales_margin')
    if (!_.isNil(salesMargin)) {
      const vendorPrice = _.get(unitPriceFields, 'vendor_price')
      const percentageValue = _.multiply(
        vendorPrice,
        _.divide(salesMargin, 100)
      )

      const unitPrice = _.sum([vendorPrice, percentageValue])

      form.setFieldValue('unit_price', unitPrice)
      log.debug('calculateUnitPrice', {
        percentageValue,
        salesMargin,
        vendorPrice,
        unitPrice,
      })
    }
  }, [
    _.get(unitPriceFields, 'sales_margin'),
    _.get(unitPriceFields, 'vendor_price'),
  ])

  return (
    <>
      <GutteredRow>
        {/* Product Code */}
        <Col xl={6}>
          <InputField
            name={'code'}
            label={<FormattedMessage id={'ui.materials.form.labels.code'} />}
            required
          />
        </Col>
        {/* Product Name */}
        <Col xl={6}>
          <InputField
            name={'name'}
            label={<FormattedMessage id={'ui.materials.form.labels.name'} />}
            required
          />
        </Col>
        {/*  Category  */}
        <Col md={6}>
          <LocalSelect
            name={'category'}
            localAttribute={'ui.materials.enums.categories'}
            label={'ui.materials.form.labels.category'}
            required
            options={categories}
            defaultValue={_.get(material, 'category')}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        {/* Unit Price */}
        <Col xl={3}>
          <StyledFieldCurrency
            name={'unit_price'}
            railsName={'unit_price'}
            initialValue={_.get(material, 'unit_price', null)}
            label={
              <FormattedMessage id={'ui.materials.form.labels.unit_price'} />
            }
            required
            useWatch
          />
        </Col>

        {/*  Measure Unit */}
        <Col xl={3}>
          <InputField
            name={'measure_unit'}
            label={
              <FormattedMessage id={'ui.materials.form.labels.measure_unit'} />
            }
            required
          />
        </Col>
      </GutteredRow>

      {/* Description */}
      <GutteredRow>
        <Col xl={8}>
          <TextAreaField
            name={'description'}
            label={
              <FormattedMessage id={'ui.materials.form.labels.description'} />
            }
            required
          />
        </Col>
      </GutteredRow>

      {/* Manufacturer Fields (OPTIONAL) */}

      <GutteredRow>
        {/* Manufacturer Code */}
        <Col xl={6}>
          <InputField
            name={'manufacturer_code'}
            label={
              <FormattedMessage
                id={'ui.materials.form.labels.manufacturer_code'}
              />
            }
          />
        </Col>

        {/*  Manufacturer Name */}
        <Col xl={6}>
          <InputField
            name={'manufacturer_name'}
            label={
              <FormattedMessage
                id={'ui.materials.form.labels.manufacturer_name'}
              />
            }
          />
        </Col>

        {/*  Manufacturer Price */}
        <Col xl={3}>
          <StyledFieldCurrency
            name={'manufacturer_price'}
            railsName={'manufacturer_price'}
            initialValue={_.get(material, 'manufacturer_price', null)}
            label={
              <FormattedMessage
                id={'ui.materials.form.labels.manufacturer_price'}
              />
            }
          />
        </Col>
      </GutteredRow>

      {/*  Vendor Fields (OPTIONAL) */}
      <GutteredRow>
        {/* Vendor Code */}
        <Col xl={6}>
          <InputField
            name={'vendor_code'}
            label={
              <FormattedMessage id={'ui.materials.form.labels.vendor_code'} />
            }
          />
        </Col>

        {/*  Vendor Name */}
        <Col xl={6}>
          <InputField
            name={'vendor_name'}
            label={
              <FormattedMessage id={'ui.materials.form.labels.vendor_name'} />
            }
          />
        </Col>

        {/*  Vendor Price */}
        <Col xl={3}>
          <StyledFieldCurrency
            name={'vendor_price'}
            railsName={'vendor_price'}
            initialValue={_.get(material, 'vendor_price', null)}
            label={
              <FormattedMessage id={'ui.materials.form.labels.vendor_price'} />
            }
            onChange={(form, value) => {
              setUnitPriceFields({
                ...unitPriceFields,
                form,
                vendor_price: value,
              })
            }}
          />
        </Col>

        {/*  Sales Margin */}
        <Col xl={3}>
          <StyledInputNumber
            min={0.0}
            max={100.0}
            step={'0.01'}
            precision={2}
            name={'sales_margin'}
            label={
              <FormattedMessage id={'ui.materials.form.labels.sales_margin'} />
            }
            initialValue={_.get(material, 'sales_margin', null)}
            onChange={(form, value) => {
              setUnitPriceFields({
                ...unitPriceFields,
                form,
                sales_margin: value,
              })
            }}
          />
        </Col>
      </GutteredRow>
    </>
  )
}

export default FormFields
