import React from 'react'
import { Tag } from 'antd'

const StatusTag = ({ status, children }) => {
  let color = null
  switch (status) {
    case 'suspended':
      color = '#E914B8'
      break
    case 'to_issue':
      color = '#4096FF'
      break
    case 'invoiced':
      color = '#35C2A2'
  }
  return <Tag color={color}>{children}</Tag>
}

export default StatusTag
