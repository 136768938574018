import { define } from "remount";
import Root from "./Root";
import Authenticator from "./Authenticator";
import { Amplify } from "aws-amplify";
import awsExports from "../../../../src/aws-exports";
import { logLevel } from "./utils";

Amplify.Logger.LOG_LEVEL = logLevel(process.env.RAILS_ENV);
Amplify.configure(awsExports);

// define App root component
define({ "x-app": Root });
define({ "x-auth": Authenticator });
