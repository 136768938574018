import React from 'react'
import { CustomForm, ModalDelete } from './index'
import { Input } from 'antd'
import { FormattedMessage } from 'react-intl'

const GenericDeleteForm = ({
  action,
  name,
  deleteModalData,
  model,
  onCancel,
  keys = undefined,
}) => {
  const onFinish = () => {
    document.getElementById(name).submit()
  }

  return (
    <CustomForm method={'post'} action={action} name={name} onFinish={onFinish}>
      <Input type={'hidden'} value={'delete'} name={'_method'} />

      <ModalDelete
        model={model}
        onOk={onFinish}
        open={deleteModalData.open}
        onCancel={onCancel}
        recordType={<FormattedMessage id={`ui.${model}.singular`} />}
        data={deleteModalData.record}
        keys={keys}
      />
    </CustomForm>
  )
}

export default GenericDeleteForm
