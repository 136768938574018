import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { EyeOutlined } from '@ant-design/icons'
import { Table as AntdTable, Button, DatePicker, Space } from 'antd'
import { Auth, Logger } from 'aws-amplify'
import _ from 'lodash'
import styled from 'styled-components'

import { StatusTag } from '../../../.././Components'
import {
  Display,
  DisplayDateTime,
  LocaleDisplay,
} from '../../../../Components/Display'
import { CALL_SEARCH, makeApiRequest } from '../../../../api'
import { addPropertyToColumns } from '../../../../utils'
import { FacilityContext } from '../../contexts'

const { RangePicker } = DatePicker
const log = new Logger('CallsTable')

const StyledDiv = styled.div`
  margin-bottom: 20px;
`

export const CallsTable = ({ dataSource }) => {
  // inject Intl instance
  const intl = useIntl()

  const { facility } = useContext(FacilityContext)

  const getColumns = (intl) => {
    let columns = [
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.requested_at' }),
        dataIndex: 'requested_at',
        render: (_, record) => <DisplayDateTime value={record.requested_at} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.priority' }),
        dataIndex: 'priority',
        render: (_, record) => (
          <LocaleDisplay
            localePath={`ui.calls.enums.priorities`}
            value={record.priority}
          />
        ),
      },
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.status' }),
        dataIndex: 'status',
        render: (_, record) => (
          <StatusTag status={record.status}>
            <LocaleDisplay
              localePath={`ui.calls.enums.statuses`}
              value={record.status}
            />
          </StatusTag>
        ),
      },
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.info' }),
        dataIndex: 'info',
        render: (_, record) => <Display value={record.info} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.reported_by' }),
        dataIndex: 'reported_by',
        render: (_, record) => <Display value={record.reported_by} />,
      },

      {
        title: intl.formatMessage({
          id: 'ui.actions.title',
        }),
        key: 'action',
        render: (_, record) => (
          <>
            <Space>
              {/* DETAIL */}
              <Button icon={<EyeOutlined />} href={`/calls/${record.id}`} />
            </Space>
          </>
        ),
      },
    ]

    log.debug('Adding responsive property to columns ...')
    return addPropertyToColumns(columns, 'responsive', ['md'])
  }

  const columns = getColumns(intl)

  log.debug('Rendering calls table ...')

  // const { searchLoading, setCalls, query } =
  //   useContext(CallsContext);

  // data source
  const [calls, setCalls] = useState(dataSource)

  // ranges
  const [ranges, setRanges] = useState([])

  const handleRangeChange = (dates) => {
    if (_.isEmpty(dates)) {
      setRanges([])
      return
    }
    const startDate = dates[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const endDate = dates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')

    log.debug('parsed dates', { startDate, endDate })

    setRanges([
      {
        field: 'requested_at',
        from: startDate,
        to: endDate,
      },
    ])
  }

  useEffect(() => {
    const filterCalls = async () => {
      const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      const { data } = await makeApiRequest(CALL_SEARCH, 'post', {
        body: { facility_id: facility.id, ranges },
      })

      log.debug('filterCalls.result ', { data, jwtToken })
      setCalls(data)
    }

    filterCalls()
  }, [ranges])

  log.info('Calls data source ... :', dataSource)

  return (
    <>
      <StyledDiv>
        <RangePicker
          placeholder={[
            intl.formatMessage({ id: 'ui.placeholders.date.from' }),
            intl.formatMessage({ id: 'ui.placeholders.date.to' }),
          ]}
          format={'DD-MM-YYYY'}
          onChange={handleRangeChange}
        />
      </StyledDiv>
      {/*  range picker for filter*/}

      <AntdTable
        dataSource={calls}
        rowKey={'id'}
        columns={columns}
        // loading={{
        //   spinning: searchLoading,
        //   indicator: (
        //     <LoadingOutlined
        //       style={{
        //         fontSize: 40,
        //       }}
        //       spin
        //     />
        //   ),
        // }}
      />
    </>
  )
}

export default CallsTable
