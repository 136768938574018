import React from 'react'
import { Logger } from 'aws-amplify'
import { CopyOutlined } from '@ant-design/icons'
import { Display } from './index'
import { message, Tooltip } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

const log = new Logger('Components/DisplayCopy')

const DisplayCopy = ({ value, successMessagePath }) => {
  const intl = useIntl()

  return (
    <div>
      <Tooltip title={<FormattedMessage id={'ui.tooltips.copy'} />}>
        <CopyOutlined
          style={{
            marginRight: '8px',
            color: 'rgb(64, 169, 255)',
          }}
          onClick={() => {
            navigator.clipboard.writeText(value)
            message.success(intl.formatMessage({ id: successMessagePath }))
          }}
        />
      </Tooltip>
      <Display value={value} />
    </div>
  )
}

export default DisplayCopy
