import {
  GetObjectCommand,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3'
import { Logger } from 'aws-amplify'
import {
  AWS_ACCESS_KEY,
  AWS_REGION,
  AWS_SECRET_ACCESS_KEY,
  S3_DOCS_BUCKET,
} from '../../config'

import { v4 as uuidv4 } from 'uuid'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'

const log = new Logger('S3Service')

const BASE_KEY = `s3://${S3_DOCS_BUCKET}/`

// DO NOT PUSH PLAIN CREDENTIALS!
const S3 = new S3Client({
  region: AWS_REGION,
  credentials: {
    accessKeyId: AWS_ACCESS_KEY,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  },
})

// Get Object
export const getObject = async (key) => {
  log.verbose('Trying to get object ...', key)

  // Set the parameters
  const input = {
    Bucket: S3_DOCS_BUCKET,
    // Add the required 'Key' parameter
    Key: key,
  }

  try {
    // create GetObjectCommand to get the object
    const command = new GetObjectCommand(input)
    const response = await S3.send(command)

    log.info('Got object :', response)

    // return response
    return response
  } catch (err) {
    log.error('Error retrieving object : ', JSON.stringify(err))
    return err
  }
}

// Upload File
export const uploadFile = async (file, key, replaceFileName = true) => {
  // Add uuid to file name
  if (replaceFileName) file.name = file.name.replace('.', `_${uuidv4()}.`)

  // Set the parameters
  const input = {
    Bucket: S3_DOCS_BUCKET,
    // Add the required 'Key' parameter using the 'path' module.
    Key: `${key}/${file.name}`,
    // Add the required 'Body' parameter
    Body: file.originFileObj,
  }

  log.debug('File name: ', file.name)
  log.debug('PutObjectCommandInput: ', input)
  

  try {
    // create PutObjectCommand to upload the object
    const command = new PutObjectCommand(input)
    const response = await S3.send(command)

    log.info('Upload success:', response)

    // return storage url
    return {
      ...response,
      storageUrl: `${BASE_KEY}${input.Key}`,
    }
  } catch (err) {
    log.error('Error uploading file : ', JSON.stringify(err))
    return err
  }
}

// Get Presigned Url
export const getPresignedUrl = async (key) => {
  log.verbose('Trying to get presigned url ...', key)

  // Set the parameters
  const input = {
    Bucket: S3_DOCS_BUCKET,
    // Add the required 'Key' parameter
    Key: key,
  }

  try {
    // create GetObjectCommand to get the object
    const command = new GetObjectCommand(input)
    const url = await getSignedUrl(S3, command, {
      expiresIn: 3600,
    })

    log.info('Got url :', url)

    // return url
    return url
  } catch (err) {
    log.error('Error retrieving presigned url : ', JSON.stringify(err))
    return err
  }
}

// export default {
//   uploadFile,
//   getPresignedUrl,
// }
