// customers
export { default as CustomerIndex } from '././Customers/Index'
export { default as CustomerNew } from '././Customers/New'
export { default as CustomerEdit } from '././Customers/Edit'
export { default as CustomerShow } from '././Customers/Show'

// calls
export { default as CallIndex } from '././Calls/Index'
export { default as CallNew } from '././Calls/New'
export { default as CallEdit } from '././Calls/Edit'
export { default as CallShow } from '././Calls/Show'

// interventions
export { default as InterventionIndex } from '././Interventions/Index'
export { default as InterventionNew } from '././Interventions/New'
export { default as InterventionEdit } from '././Interventions/Edit'
export { default as InterventionShow } from '././Interventions/Show'

// routes
export { default as RouteIndex } from '././Routes/Index'
export { default as RouteNew } from '././Routes/New'
export { default as RouteEdit } from '././Routes/Edit'

// materials
export { default as MaterialIndex } from '././Materials/Index'
export { default as MaterialNew } from '././Materials/New'
export { default as MaterialEdit } from '././Materials/Edit'

// invoices
export { default as InvoiceIndex } from '././Invoices/Index'
export { default as InvoiceNew } from '././Invoices/New'
export { default as InvoiceEdit } from '././Invoices/Edit'

// devices
export { default as DeviceIndex } from '././Devices/Index'
export { default as DeviceNew } from '././Devices/New'

// device_alarms
export { default as DeviceAlarmIndex } from '././DeviceAlarms/Index'
