import React, { useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import * as F from '@utils/functions'
import { Table as AntdTable, Button, Space } from 'antd'
import { Auth, Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { GenericDeleteForm as DeleteMaterialForm } from '../../.././Components'
import {
  Display,
  DisplayPrice,
  LocaleDisplay,
} from '../../../Components/Display'
import { MATERIAL_SEARCH, makeApiRequest } from '../../../api'
import { MaterialsContext } from '../contexts'

const log = new Logger('MaterialsTable')

export const Table = ({ dataSource, searchMaterials }) => {
  // inject Intl instance
  const intl = useIntl()

  // first load ref
  const firstLoad = useRef(true)

  const getColumns = () => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.materials.attributes.code',
        }),
        fixed: true,
        dataIndex: 'code',
        render: (_, record) => <Display value={record.code} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.materials.attributes.name',
        }),
        dataIndex: 'name',
        render: (_, record) => <Display value={record.name} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.materials.attributes.unit_price',
        }),
        dataIndex: 'unit_price',
        render: (value, record) => <DisplayPrice value={value} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.materials.attributes.category',
        }),
        dataIndex: 'category',
        render: (_, record) => (
          <LocaleDisplay
            value={record.category}
            localePath={'ui.materials.enums.categories'}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.materials.attributes.manufacturer_name',
        }),
        dataIndex: 'manufacturer_name',
        render: (_, record) => <Display value={record.manufacturer_name} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.actions.title',
        }),
        key: 'action',
        fixed: 'right',
        render: (_, record) => (
          <>
            <Space>
              {/* EDIT */}
              <Button
                icon={<EditOutlined />}
                href={`/materials/${record.id}/edit`}
              />

              {/*  DELETE */}
              <Button
                icon={<DeleteOutlined />}
                onClick={() =>
                  setDeleteModalData({
                    open: true,
                    record,
                  })
                }
              />
            </Space>
          </>
        ),
      },
    ]

    log.debug('Adding responsive property to columns ...')
    return columns
  }

  // delete modal data
  const [deleteModalData, setDeleteModalData] = useState({ open: false })

  const columns = getColumns()

  const {
    setMaterials,
    searchLoading,
    isTableChanged,
    setSearchLoading,
    filters,
    setFilters,
    query,
    pagination,
    setPagination,
  } = useContext(MaterialsContext)

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    if (isTableChanged.current) {
      searchMaterials()
      isTableChanged.current = false
    }
  }, [filters, query, isTableChanged.current])

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
    isTableChanged.current = true
  }

  log.info('Materials data source :', dataSource)

  return (
    <>
      <AntdTable
        dataSource={dataSource}
        rowKey={'id'}
        columns={columns}
        pagination={{ ...pagination, onChange: handlePaginationChange }}
        loading={{
          spinning: searchLoading,
          indicator: (
            <LoadingOutlined
              style={{
                fontSize: 40,
              }}
              spin
            />
          ),
        }}
        scroll={{ x: true }}
      />

      {/*  Delete Material */}
      <DeleteMaterialForm
        name={'delete-material-form'}
        onCancel={() => setDeleteModalData({ open: false })}
        model={'materials'}
        action={`/materials/${_.get(deleteModalData, 'record.id', 0)}`}
        deleteModalData={deleteModalData}
      />
    </>
  )
}

export default Table
