import _ from "lodash";

export const marshalCustomer = (customer) => {
  if (_.isEmpty(_.get(customer, "addresses", null))) {
    return {
      // default addresses values
      registered_office: {
        address_type: "registered_office",
        country: "ITA",
      },
      correspondence: {
        address_type: "correspondence",
        country: "ITA",
      },
      ...customer,
    };
  } else {
    const addresses = _.get(customer, "addresses");

    const newAddresses = _.reduce(
      addresses,
      (group, element) => {
        const { address_type } = element;
        group[address_type] = element;
        return group;
      },
      {}
    );

    return {
      ...customer,
      ...newAddresses,
    };
  }
};
