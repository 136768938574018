import React, { useContext } from 'react'
import { Table as AntdTable } from 'antd'
import { Logger } from 'aws-amplify'
import { useIntl } from 'react-intl'
import {
  Display,
  DisplayCopy,
  DisplayPrice,
  LocaleDisplay,
} from '../../../Components/Display'
import { MaterialsContext } from '../contexts'
import _ from 'lodash'

const log = new Logger('Invoices/components/MaterialsTable')

const MaterialsTable = () => {
  // inject Intl instance
  const intl = useIntl()

  // states
  const { materials } = useContext(MaterialsContext)

  const columns = [
    {
      title: intl.formatMessage({
        id: 'ui.materials.attributes.code',
      }),
      dataIndex: 'code',
      render: (_, record) => <Display value={record.code} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.materials.attributes.name',
      }),
      dataIndex: 'name',
      render: (_, record) => <Display value={record.name} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.materials.attributes.description',
      }),
      dataIndex: 'description',
      render: (_, record) => (
        <DisplayCopy
          value={record.description}
          successMessagePath={'ui.materials.tooltips.copy'}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.materials.attributes.unit_price',
      }),
      dataIndex: 'unit_price',
      render: (_, record) => (
        <DisplayPrice
          value={record.unit_price}
          suffix={`/${record.measure_unit}`}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.materials.attributes.category',
      }),
      dataIndex: 'category',
      render: (_, record) => (
        <LocaleDisplay
          value={record.category}
          localePath={'ui.materials.enums.categories'}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.materials.attributes.quantity',
      }),
      dataIndex: 'quantity',
      render: (_, record) => <Display value={record.quantity} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.materials.attributes.total',
      }),
      dataIndex: 'total',
      render: (total, record) => (
        <DisplayPrice value={_.multiply(record.quantity, record.unit_price)} />
      ),
    },
  ]

  log.info('Materials data source :', materials)

  return (
    <>
      <AntdTable
        dataSource={materials}
        rowKey={'id'}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />
    </>
  )
}

export default MaterialsTable
