import React, { useContext } from 'react'

import { Logger } from 'aws-amplify'

import { SearchBox } from '../../.././Components'
import { InterventionsContext } from '../contexts'

const InterventionSearch = ({ onSearch }) => {
  const { query, setQuery } = useContext(InterventionsContext)

  return (
    <SearchBox
      onSearch={(query) => {
        setQuery(query)
        onSearch(query)
      }}
      defaultValue={query}
      placeholder={`ui.interventions.placeholders.search`}
    />
  )
}

export default InterventionSearch
