import { DirectionsRenderer } from '@react-google-maps/api'
import React from 'react'

const RouteDirections = ({ directions }) => {
  return directions ? (
    <DirectionsRenderer
      options={{ suppressMarkers: true }}
      directions={directions}
    />
  ) : null
}

export default RouteDirections
