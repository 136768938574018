import { useIntl } from 'react-intl'
import {
  GutteredRow,
} from '../../../.././Components'
import React, { useEffect } from 'react'
import { Logger } from 'aws-amplify'
import { Col, Form } from 'antd'
import { roles } from '../../../../enums'
import MultipleSelect from '../../../../Components/Select/MultipleSelect'
import _ from 'lodash'
import {FieldEmail, FieldPhone, InputField, TextAreaField} from "../../../../Components/Fields";

const log = new Logger('ReferentForm')

export const ReferentForm = ({ form, initialValues }) => {
  const intl = useIntl()

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  log.debug('Referent initial values', initialValues)

  useEffect(() => {
    log.debug('Form values', form.getFieldsValue())
    log.debug('useEffect', initialValues)
    if (initialValues) form.setFieldsValue(initialValues)
  }, [initialValues])

  return (
    <Form
      form={form}
      name={'referent-form'}
      layout={'vertical'}
      validateMessages={validateMessages}
    >
      <GutteredRow>
        <Col xl={12}>
          {/* First Name */}
          <InputField
            name={'first_name'}
            label={intl.formatMessage({
              id: 'ui.referents.attributes.first_name',
            })}
            required
          />
        </Col>
        <Col xl={12}>
          {/*  Last Name */}
          <InputField
            name={'last_name'}
            label={intl.formatMessage({
              id: 'ui.referents.attributes.last_name',
            })}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        <Col md={12}>
          {/*  Role */}
          <MultipleSelect
            name={'roles'}
            railsName={'roles[]'}
            label={'ui.referents.attributes.roles'}
            options={_.map(roles, (role, key) => {
              return {
                key,
                label: intl.formatMessage({
                  id: `ui.referents.enums.roles.${role}`,
                }),
                value: role,
              }
            })}
            defaultValue={_.get(initialValues, 'roles', [])}
            // localAttribute={'ui.referents.enums.roles'}
          />
        </Col>
        <Col xl={12}>
          {/*  Phone 1*/}
          <FieldPhone
            name={'phone_1'}
            label={intl.formatMessage({
              id: 'ui.referents.attributes.phone_1',
            })}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        <Col xl={12}>
          {/*  Phone 2*/}
          <FieldPhone
            name={'phone_2'}
            label={intl.formatMessage({
              id: 'ui.referents.attributes.phone_2',
            })}
          />
        </Col>
        <Col xl={12}>
          {/*  Email */}
          <FieldEmail
            name={'email'}
            label={intl.formatMessage({ id: 'ui.referents.attributes.email' })}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        <Col xl={24}>
          {/*  Notes */}
          <TextAreaField
            name={'notes'}
            label={intl.formatMessage({ id: 'ui.referents.attributes.notes' })}
          />
        </Col>
      </GutteredRow>

      {/*  Hidden ID Field */}
      {initialValues?.id && <InputField name={'id'} hidden />}
      {initialValues?.key && <InputField name={'key'} hidden />}
    </Form>
  )
}
