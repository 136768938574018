import React from 'react'
import { Logger } from 'aws-amplify'
import { CustomForm, ModalDelete } from '../../.././Components'
import { FormattedMessage } from 'react-intl'

const log = new Logger('CallCloseForm')

const CloseForm = ({ id, modalData, setModalData }) => {
  const onFinish = () => {
    document.getElementById('close-call-form').submit()
  }

  return (
    <CustomForm
      method={'post'}
      action={`/calls/${id}/close`}
      name={'close-call-form'}
      onFinish={onFinish}
    >
      {/*  Modal */}
      <ModalDelete
        model={'calls'}
        onOk={onFinish}
        open={modalData.open}
        onCancel={() => setModalData({ open: false })}
        recordType={<FormattedMessage id={'ui.calls.singular'} />}
      />
    </CustomForm>
  )
}

export default CloseForm
