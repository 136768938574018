[
  {
    "key": 1,
    "value": "Scala fino a 4 mt"
  },
  {
    "key": 2,
    "value": "Scala superiore a 4 mt"
  },
  {
    "key": 3,
    "value": "Piattaforma"
  },
  {
    "key": 4,
    "value": "Traghetto"
  },
  {
    "key": 5,
    "value": "Imbragatura"
  }
]