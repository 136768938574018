import React, { useContext, useEffect } from 'react'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledDivider } from '../../../styled'
import { Table } from 'antd'
import { DisplayDateTime, DisplayPrice } from '../../../Components/Display'
import { calculateFee } from '../../../utils'
import { InterventionsContext } from '../contexts'

const log = new Logger('Invoices/components/SubscriptionDetail')
const SubscriptionDetail = ({ interventions, facility }) => {
  const intl = useIntl()

  const interventionsCountByTypology = _.countBy(interventions, 'typology')

  const maintenanceInterventionsCount = _.get(
    interventionsCountByTypology,
    'maintenance_subscription',
    0
  )

  const subscription = _.get(facility, 'subscription')

  const dataSource = [
    {
      ...subscription,
      quantity: maintenanceInterventionsCount,
      unit_price: calculateFee(subscription),
    },
  ]

  log.debug('maintenanceInterventionsCount', {
    maintenanceInterventionsCount,
    dataSource,
  })

  const { setTotalSubscriptionFee } = useContext(InterventionsContext)

  useEffect(() => {
    const totalFee = _.multiply(
      _.get(dataSource, '[0].quantity'),
      _.get(dataSource, '[0].unit_price')
    )
    setTotalSubscriptionFee(totalFee)

    return () => setTotalSubscriptionFee(0)
  }, [interventions])

  const columns = [
    {
      title: intl.formatMessage({ id: 'ui.subscription_detail.attributes.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({
        id: 'ui.subscription_detail.attributes.start_date',
      }),
      dataIndex: 'start_date',
      render: (value) => (
        <DisplayDateTime value={value} format={'DD/MM/YYYY'} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.subscription_detail.attributes.quantity',
      }),
      dataIndex: 'quantity',
    },
    {
      title: intl.formatMessage({
        id: 'ui.subscription_detail.attributes.unit_price',
      }),
      dataIndex: 'unit_price',
      render: (value) => <DisplayPrice value={value} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.subscription_detail.attributes.total',
      }),
      dataIndex: 'total',
      render: (value, record) => (
        <DisplayPrice
          value={_.multiply(
            _.get(record, 'unit_price'),
            _.get(record, 'quantity')
          )}
        />
      ),
    },
  ]

  return (
    <>
      <StyledDivider orientation={'left'}>
        <FormattedMessage id={'ui.subscription_detail.title'} />
      </StyledDivider>

      <Table
        columns={columns}
        pagination={false}
        rowKey={'id'}
        dataSource={dataSource}
      />
    </>
  )
}

export default SubscriptionDetail
