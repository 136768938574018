import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Form as AntdForm, Form, Input, TimePicker } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'

import { GlobalContext } from '../../contexts'

var utc = require('dayjs/plugin/utc')

dayjs.extend(utc)

const log = new Logger('TimePickerField')
const TimePickerField = ({
  name,
  label = undefined,
  onChange = undefined,
  initialValue = null,
  railsName = name,
  required = false,
  disabled = false,
  placeholder = undefined,
  useWatch = false,
  style = undefined,
  shouldUpdate = false,
  ...otherProps
}) => {
  const validateRequired = () => {
    return required
      ? [
          {
            required: true,
          },
        ]
      : ''
  }
  const { mode } = useContext(GlobalContext)

  // Form Instance
  const form = Form.useFormInstance()

  const parseTime = (time) => {
    return _.invoke(_.invoke(time, 'utc'), 'format', 'HH:mm')
  }

  const [selectedTime, setSelectedTime] = useState(parseTime(initialValue))

  const handleTimeChange = (time, timeString) => {
    log.debug('Selected time', time)

    const parsedTime = parseTime(time)
    log.debug('Parsed time', parsedTime)

    setSelectedTime(parsedTime)

    if (_.isFunction(onChange)) onChange(parsedTime, time, form)
  }

  return (
    <>
      <AntdForm.Item
        name={_.toPath(name)}
        label={label && <FormattedMessage id={label} />}
        required={required}
        rules={validateRequired()}
        style={style}
        shouldUpdate={shouldUpdate}
        {...otherProps}
      >
        <TimePicker
          suffixIcon={null}
          onChange={handleTimeChange}
          disabled={_.defaultTo(disabled, mode == 'detail')}
          format={'HH:mm'}
          // value={selectedTime}
          placeholder={placeholder}
        />
      </AntdForm.Item>
      <Input
        type={'hidden'}
        name={railsName}
        value={
          useWatch ? parseTime(AntdForm.useWatch(_.toPath(name))) : selectedTime
        }
      />
    </>
  )
}

export default TimePickerField
