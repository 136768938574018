import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Button, Col, Input, Row, Typography } from 'antd'
import { Logger } from 'aws-amplify'

import { CUSTOMER_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'

import { CenteredSpinner } from '../.././Components'
import { GlobalContext } from '../../contexts'
import { StyledCard } from '../../styled'
import CustomerSearch, { CustomersTable } from './components'
import { CustomersContextProvider } from './contexts'

const { Title } = Typography
const { Search } = Input

const log = new Logger('Customers/Index')

const Index = (props) => {
  log.debug('Rendering customers view ...', props)

  const intl = useIntl()

  // get customers data
  const [customers, setCustomers] = useState(props.customers)

  log.info('Customers data: ', customers)

  // states
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)

  // search states
  const [query, setQuery] = useState(
    F.retrieveFiltersOr('', ['customers', 'query'])
  )

  const [filters, setFilters] = useState(
    F.retrieveFiltersOr([], ['customers', 'filters'])
  )

  const [orders, setOrders] = useState(
    F.retrieveFiltersOr([], ['customers', 'orders'])
  )
  const [pagination, setPagination] = useState(
    F.retrieveFiltersOr(DEFAULT_PAGER, ['customers', 'pagination'])
  )

  const { setMode } = useContext(GlobalContext)

  const filterCustomers = async () => {
    setSearchLoading(true)

    const { data, headers } = await makeApiRequest(CUSTOMER_SEARCH, 'post', {
      body: {
        orders,
        filters,
        query,
      },
      queryParams: F.marshalPaginationTo(pagination),
    })

    const newPagination = {
      ...pagination,
      ...F.marshalPaginationFrom(headers),
    }

    F.storeFilters({
      customers: { query, orders, filters, pagination: newPagination },
    })
    log.debug('filterCustomers.success ', { data, newPagination })
    setPagination(newPagination)
    setCustomers(data)
    setSearchLoading(false)
  }

  useEffect(() => {
    setMode('list')
  }, [])

  return loading ? (
    <CenteredSpinner />
  ) : (
    <CustomersContextProvider
      value={{
        customers,
        setCustomers,
        searchLoading,
        setSearchLoading,
        query,
        setQuery,
        orders,
        setOrders,
        filters,
        setFilters,
        pagination,
        setPagination,
      }}
    >
      <Row>
        <Col>
          <Title level={3}>
            <FormattedMessage id={'ui.customers.list_name'} />
          </Title>
        </Col>
      </Row>
      <Row justify={'space-between'}>
        <Col xl={12}>
          {/* customer search*/}
          <CustomerSearch
            onSearch={() =>
              setPagination({ total: pagination.total, ...DEFAULT_PAGER })
            }
          />
        </Col>
        <Col
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type='primary'
            href='/customers/new'
            onClick={() => setLoading(!loading)}
          >
            <FormattedMessage id={'ui.customers.buttons.create'} />
          </Button>
        </Col>
      </Row>
      <StyledCard>
        <CustomersTable
          dataSource={customers}
          searchCustomers={filterCustomers}
        />
      </StyledCard>
    </CustomersContextProvider>
  )
}

export default Index
