import React from 'react'
import { useIntl } from 'react-intl'

import { DatePicker } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

const { RangePicker } = DatePicker
const log = new Logger('Components/Search/Ranges')

const Ranges = ({ field, onChange, defaultValue, format = 'DD-MM-YYYY' }) => {
  const intl = useIntl()

  const handleRangeChange = (dates) => {
    let result = []
    if (_.isEmpty(dates)) {
      onChange(result)
      return
    }

    const startDate = dates[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const endDate = dates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')

    log.debug('parsed dates', { startDate, endDate })

    result = [
      {
        field,
        from: startDate,
        to: endDate,
      },
    ]

    onChange(result)
  }

  return (
    <RangePicker
      defaultValue={defaultValue}
      placeholder={[
        intl.formatMessage({ id: 'ui.placeholders.date.from' }),
        intl.formatMessage({ id: 'ui.placeholders.date.to' }),
      ]}
      format={format}
      onChange={handleRangeChange}
    />
  )
}

export default Ranges
