import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Col, Form, Input, Row } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import {
  DisplayDateTime,
  DisplayDetail,
  LocaleDisplay,
} from '@components/Display'

import { GutteredRow } from '../../.././Components'
import { DatePickerField, TextAreaField } from '../../../Components/Fields'
import {
  LocalSelect,
  SingleSelect,
  TagSelect,
} from '../../../Components/Select'
import { GlobalContext } from '../../../contexts'
import { priorities, typologies } from '../../../enums/intervention'
import {
  InterventionCall,
  InterventionChild,
  InterventionFather,
  StatusTag,
} from '../components'
import { InterventionContext } from '../contexts'
import InterventionDetailFields from './InterventionDetailFields'

const log = new Logger('Intervention/FormFields')

const FormFields = ({ facilities, referents }) => {
  const { intervention, call } = useContext(InterventionContext)

  const { mode } = useContext(GlobalContext)

  const intl = useIntl()

  log.debug({ intervention, call, mode })

  const form = Form.useFormInstance()

  const options = (referents) =>
    _.uniqBy(
      _.map(referents, (r, key) => {
        const translatedRoles = R.map(
          (role) =>
            R.unless(
              R.either(R.isNil, R.isEmpty),
              (v) =>
                intl.formatMessage({
                  id: `ui.referents.enums.roles.${v}`,
                }),
              role
            ),
          r.roles
        )

        return {
          key,
          id: _.get(r, 'id', null),
          label: `${r.first_name} ${r.last_name} - ${R.join(
            ',',
            translatedRoles
          )}`,
          value: `${r.first_name} ${r.last_name} - ${R.join(
            ',',
            translatedRoles
          )}`,
        }
      }),
      'value'
    )

  // build phone options once reference is selected
  const phoneOptions = (referent) => {
    const options = []

    _.forEach(['phone_1', 'phone_2'], (phone, key) => {
      if (_.isEmpty(_.get(referent, phone))) return

      options.push({
        key,
        id: _.get(referent, 'id', null),
        label: _.get(referent, phone),
        value: _.get(referent, phone),
      })
    })

    return options
  }

  // states
  const [reportedByOptions, setReportedByOptions] = useState(options(referents))
  const [contactOptions, setContactOptions] = useState(options(referents))
  const [reportedByPhoneOptions, setReportedByPhoneOptions] = useState(
    phoneOptions(_.get(call, 'reported_by'))
  )
  const [contactPhoneOptions, setContactPhoneOptions] = useState(
    phoneOptions(_.get(call, 'contact_phone'))
  )

  log.debug({
    reportedByPhoneOptions,
    reportedByOptions,
    contactOptions,
  })

  // update phones
  useEffect(() => {
    if (!_.isEmpty(reportedByPhoneOptions))
      form.setFieldValue('phone', _.map(reportedByPhoneOptions, 'value'))
    if (!_.isEmpty(contactPhoneOptions))
      form.setFieldValue('contact_phone', _.map(contactPhoneOptions, 'value'))
  }, [reportedByPhoneOptions, contactPhoneOptions])

  return (
    <>
      <GutteredRow>
        {mode === 'detail' && (
          <>
            <Col xl={4}>
              <DisplayDetail
                label={
                  <FormattedMessage id={`ui.interventions.attributes.id`} />
                }
                style={{ paddingBottom: '8px' }}
                value={_.get(intervention, 'id')}
              />
            </Col>
            <Col xl={4}>
              <DisplayDetail
                label={
                  <FormattedMessage
                    id={`ui.interventions.form.labels.facility`}
                  />
                }
                value={_.get(intervention, 'business_name')}
              />
            </Col>
          </>
        )}

        {mode === 'edit' && (
          <Col xl={4}>
            <DisplayDetail
              label={
                <FormattedMessage
                  id={`ui.interventions.form.labels.facility`}
                />
              }
              value={_.get(intervention, 'business_name')}
            />
          </Col>
        )}

        {/* Call Reference */}
        {call && (
          <Col xl={5} style={{ marginBottom: '20px' }}>
            <InterventionCall call={call} />
          </Col>
        )}

        <Col xl={6}>
          {R.either(R.prop('father'), R.prop('child'))(intervention) ? (
            <>
              {intervention.father && (
                <InterventionFather
                  father={intervention.father}
                  facilityId={R.prop('facility_id', intervention)}
                />
              )}
              {intervention.child && (
                <InterventionChild child={intervention.child} />
              )}
            </>
          ) : (
            mode != 'detail' && (
              <SingleSelect
                name={`facility_id`}
                required
                options={_.map(facilities, (f) => {
                  return {
                    label: f.business_name,
                    value: f.id,
                  }
                })}
                label={`ui.interventions.form.labels.facility`}
                defaultValue={intervention.facility_id}
                disabled={mode === 'edit'}
                onSelect={(value) => {
                  const facility = _.find(facilities, { id: value })
                  if (!_.isNull(_.get(facility, 'subscription')))
                    form.setFieldValue('priority', 'high')
                  else form.setFieldValue('priority', null)

                  setReportedByOptions(
                    options(
                      _.filter(referents, (referent) =>
                        _.includes(_.get(referent, 'facility_ids', []), value)
                      )
                    )
                  )
                  setContactOptions(
                    options(
                      _.filter(referents, (referent) =>
                        _.includes(_.get(referent, 'facility_ids', []), value)
                      )
                    )
                  )
                }}
              />
            )
          )}
        </Col>
      </GutteredRow>

      <GutteredRow>
        {mode === 'edit' &&
          R.not(R.propEq('to_schedule', 'status')(intervention)) && (
            <Col xl={4}>
              <DisplayDetail
                label={
                  <FormattedMessage
                    id={'ui.interventions.attributes.scheduled_datetime'}
                  />
                }
                value={
                  <DisplayDateTime
                    value={_.get(intervention, 'scheduled_datetime')}
                  />
                }
              />
            </Col>
          )}

        {/*  Expected Date */}
        <Col xl={4}>
          <DatePickerField
            name={'expected_date'}
            railsName={'expected_date'}
            required
            label={
              <FormattedMessage
                id={'ui.interventions.form.labels.expected_date'}
              />
            }
            disabled={
              _.get(intervention, 'status') === 'in_progress' ? true : undefined
            }
            initialValue={_.invoke(
              intervention.expected_date,
              'format',
              'YYYY-MM-DD'
            )}
          />
        </Col>

        {/*  Priority */}
        <Col xl={4}>
          <LocalSelect
            name={'priority'}
            label={`ui.interventions.form.labels.priority`}
            required
            options={priorities}
            localAttribute={`ui.interventions.enums.priorities`}
            defaultValue={intervention.priority}
            useWatch={true}
          />
        </Col>

        {/*  Typology */}
        <Col xl={5}>
          <LocalSelect
            name={'typology'}
            label={`ui.interventions.form.labels.typology`}
            required
            options={typologies}
            localAttribute={`ui.interventions.enums.typologies`}
            defaultValue={intervention.typology}
            disabled={_.get(intervention, 'typology') && mode === 'edit'}
          />
        </Col>

        {/*  Status */}

        {(mode === 'edit' || mode === 'detail') && (
          <Col md={4}>
            <Row style={{ flexDirection: 'column' }}>
              <Col style={{ paddingBottom: '8px', color: '#c8c5c5' }}>
                <FormattedMessage id={'ui.interventions.attributes.status'} />
              </Col>
              <Col>
                <span>
                  <StatusTag status={intervention.status}>
                    <LocaleDisplay
                      localePath={`ui.interventions.enums.statuses`}
                      value={intervention.status}
                    />
                  </StatusTag>
                </span>
              </Col>
            </Row>
          </Col>
        )}
      </GutteredRow>

      {mode === 'detail' && _.get(intervention, 'status') !== 'to_schedule' && (
        <InterventionDetailFields intervention={intervention} />
      )}

      <GutteredRow>
        {/*  Reported By*/}
        <Col xl={4}>
          <TagSelect
            name={'reported_by'}
            options={reportedByOptions}
            label={`ui.interventions.form.labels.reported_by`}
            tag_mode={'single'}
            defaultValue={intervention.reported_by}
            onChange={(value, form) => {
              log.debug('value, form', { value, form })
              if (!_.isNil(value))
                setReportedByPhoneOptions(
                  phoneOptions(_.find(referents, { id: value.id }))
                )
            }}
          />
        </Col>

        {/* Phone */}
        <Col xl={6}>
          <TagSelect
            name={'phone'}
            options={reportedByPhoneOptions}
            label={`ui.interventions.form.labels.phone`}
            defaultValue={intervention.phone}
            useWatch
            customRenderer={(value, railsName) => {
              return (
                <Input
                  name={railsName}
                  type={'hidden'}
                  value={_.join(value, ',')}
                />
              )
            }}
            customValidator={[
              {
                validator: (rule, value) => {
                  if (value && !String(value).match(/\d*$/)) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage({
                          id: 'ui.validations.invalid_field',
                        })
                      )
                    )
                  }

                  return Promise.resolve()
                },
              },
              {
                validator: (rule, value) => {
                  if (value && value.length > 2) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(
                          {
                            id: 'ui.validations.tag_select.multiple',
                          },
                          {
                            count: 2,
                          }
                        )
                      )
                    )
                  }

                  return Promise.resolve()
                },
              },
            ]}
          />
        </Col>
      </GutteredRow>

      {/* CONTACT INFO */}
      <GutteredRow>
        {/* Contact Person */}
        <Col xl={4}>
          <TagSelect
            name={'contact_person'}
            options={contactOptions}
            label={`ui.interventions.form.labels.contact_person`}
            tag_mode={'single'}
            defaultValue={_.get(intervention, 'contact_person')}
            onChange={(value, form) => {
              log.debug('value, form', { value, form })
              if (!_.isNil(value))
                setContactPhoneOptions(
                  phoneOptions(_.find(referents, { id: value.id }))
                )
            }}
          />
        </Col>

        {/* Contact Phone */}
        <Col xl={6}>
          <TagSelect
            name={'contact_phone'}
            options={contactPhoneOptions}
            label={`ui.interventions.form.labels.contact_phone`}
            useWatch
            defaultValue={intervention.contact_phone}
            customRenderer={(value, railsName) => {
              return (
                <Input
                  name={railsName}
                  type={'hidden'}
                  value={_.join(value, ',')}
                />
              )
            }}
            customValidator={[
              {
                validator: (rule, value) => {
                  if (value && !String(value).match(/\d*$/)) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage({
                          id: 'ui.validations.invalid_field',
                        })
                      )
                    )
                  }

                  return Promise.resolve()
                },
              },
              {
                validator: (rule, value) => {
                  if (value && value.length > 2) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(
                          {
                            id: 'ui.validations.tag_select.multiple',
                          },
                          {
                            count: 2,
                          }
                        )
                      )
                    )
                  }

                  return Promise.resolve()
                },
              },
            ]}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        {/*  Customer Request */}
        <Col md={12}>
          <TextAreaField
            name={'customer_request'}
            label={
              <FormattedMessage
                id={`ui.interventions.form.labels.customer_request`}
              />
            }
          />
        </Col>
        {/*  Notes */}
        <Col md={12}>
          <TextAreaField
            name={'notes'}
            label={
              <FormattedMessage id={`ui.interventions.form.labels.notes`} />
            }
          />
        </Col>
      </GutteredRow>
    </>
  )
}

export default FormFields
