import React, { useContext } from 'react'
import { Logger } from 'aws-amplify'
import { Button, Col, Input, Row } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Display } from '../../.././Components/Display'
import { ExportOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { GlobalContext } from '../../../contexts'

const logger = new Logger('InterventionFather')
const InterventionFather = ({ father, facilityId }) => {
  const { setLoading } = useContext(GlobalContext)
  return (
    <>
      <Row style={{ flexDirection: 'column' }}>
        <Col style={{ paddingBottom: '8px', color: `rgba(0, 0, 0, 0.6)` }}>
          <FormattedMessage id={'ui.interventions.form.labels.father'} />
        </Col>
        <Col>
          <span style={{ marginRight: '5px' }}>
            <Button
              type={'text'}
              href={`/interventions/${_.get(father, 'id')}`}
              icon={<ExportOutlined />}
              onClick={() => setLoading(true)}
            />
          </span>
          <span>
            <Display value={_.get(father, 'id')} />
          </span>
        </Col>
      </Row>

      {/* Hidden Facility Id */}
      <Input name={'facility_id'} type={'hidden'} value={facilityId} />
    </>
  )
}

export default InterventionFather
