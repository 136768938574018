import { Button, Col, Form as AntdForm, Row } from 'antd'
import { FormattedMessage } from 'react-intl'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { CustomerContext } from '../contexts'
import { GlobalContext } from '../../../contexts'

const StyledDiv = styled.div`
  margin-bottom: 20px;
`
const FormHeader = ({ children, buttonType, formLoading }) => {
  const { readonly, setLoading: setCenteredLoading } =
    useContext(CustomerContext)
  const { mode } = useContext(GlobalContext)

  return (
    <>
      {children}

      {mode !== 'detail' && (
        <Row justify={'space-between'}>
          <Col>
            <StyledDiv>
              <Button
                type={'primary'}
                href={'/customers'}
                onClick={() => setCenteredLoading(true)}
              >
                <FormattedMessage id={'ui.customers.actions.back_to_list'} />
              </Button>
            </StyledDiv>
          </Col>
          <Col>
            {/*  Submit */}
            {!readonly && (
              <AntdForm.Item>
                <Button
                  {...buttonType}
                  htmlType={'submit'}
                  loading={formLoading}
                >
                  <FormattedMessage id={'ui.button.submit'} />
                </Button>
              </AntdForm.Item>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export default FormHeader
