import React, { useContext, useEffect, useState } from 'react'
import { CenteredSpinner } from '../.././Components'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'
import { InterventionContextProvider } from './contexts'
import { Card, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { InterventionForm } from './form'
import FormFields from './form/FormFields'
import _ from 'lodash'
import {marshalIntervention} from "../../utils";

const log = new Logger('InterventionNew')

const New = (props) => {
  const [loading, setLoading] = useState(false)

  log.verbose('Current props: ', props)

  const [intervention, setIntervention] = useState(
    marshalIntervention(props.intervention)
  )

  const { setMode } = useContext(GlobalContext)

  const { facilities, call, referents } = props

  log.debug('props, facilities, call', { props, facilities, call })
  useEffect(() => {
    setMode('create')
  }, [])

  return (
    <InterventionContextProvider
      value={{ intervention, setIntervention, call }}
    >
      {loading ? (
        <CenteredSpinner />
      ) : (
        <InterventionForm
          title={
            <Typography.Title level={2}>
              <FormattedMessage id={'ui.interventions.actions.create'} />
            </Typography.Title>
          }
          action={call ? `/calls/${call.id}/interventions` : undefined}
          {...props}
        >
          <Card>
            <FormFields facilities={facilities} call={call} referents={referents} />
          </Card>
        </InterventionForm>
      )}
    </InterventionContextProvider>
  )
}

export default New
