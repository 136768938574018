import React from 'react'
import { Logger } from 'aws-amplify'
import { Card, Col } from 'antd'
import { GutteredRow } from '../../.././Components'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { StyledDivider } from '../../../styled'
import styled from 'styled-components'
import { enrichInterventionTotals } from '../../../utils'
import { DisplayDetail, DisplayPrice } from '../../../Components/Display'

const log = new Logger('Intervention/AccountingDetail')

const StyledGutteredRow = styled(GutteredRow)`
  margin-bottom: 20px;
  padding: 0 0;
`

// Styled Total Paragraph
const StyledBigP = styled.p`
  font-size: 20px;
`
const AccountingDetail = ({ intervention }) => {
  intervention = enrichInterventionTotals(intervention)

  log.debug('enriched intervention', { intervention })
  return (
    <Card style={{ marginTop: '20px' }}>
      <StyledDivider orientation={'left'}>
        <FormattedMessage id={'ui.interventions.labels.accounting'} />
      </StyledDivider>

      {/*  Hours */}
      <StyledGutteredRow>
        {/* Apprentice Working Hours */}
        <Col xl={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={
                  'ui.interventions.close_form.labels.apprentice_working_hours'
                }
              />
            }
            value={_.get(intervention, 'apprentice_working_hours')}
          />
        </Col>

        {/* Qualified Working Hours */}
        <Col xl={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={
                  'ui.interventions.close_form.labels.qualified_working_hours'
                }
              />
            }
            value={_.get(intervention, 'qualified_working_hours')}
          />
        </Col>

        {/* Specialized Working Hours */}
        <Col xl={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={
                  'ui.interventions.close_form.labels.specialized_working_hours'
                }
              />
            }
            value={_.get(intervention, 'specialized_working_hours')}
          />
        </Col>
      </StyledGutteredRow>

      {/*  Total for Typologies */}
      <StyledGutteredRow>
        {/* Total Apprentice */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total_apprentice'}
              />
            }
            value={
              <DisplayPrice value={_.get(intervention, 'total_apprentice')} />
            }
          />
        </Col>

        {/* Total Qualified */}
        <Col xl={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total_qualified'}
              />
            }
            value={
              <DisplayPrice value={_.get(intervention, 'total_qualified')} />
            }
          />
        </Col>

        {/* Total Specialized */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total_specialized'}
              />
            }
            value={
              <DisplayPrice value={_.get(intervention, 'total_specialized')} />
            }
          />
        </Col>
      </StyledGutteredRow>

      <StyledGutteredRow>
        {/* Fixed Fee */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.fixed_fee'}
              />
            }
            value={<DisplayPrice value={_.get(intervention, 'fixed_fee')} />}
          />
        </Col>

        {/* Mileage Refund */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.mileage_refund'}
              />
            }
            value={
              <DisplayPrice value={_.get(intervention, 'mileage_refund')} />
            }
          />
        </Col>
      </StyledGutteredRow>

      <StyledGutteredRow>
        {/* Total Manpower */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total_manpower'}
              />
            }
            value={
              <DisplayPrice value={_.get(intervention, 'total_manpower')} />
            }
          />
        </Col>

        {/* Total Material */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total_material'}
              />
            }
            value={
              <DisplayPrice value={_.get(intervention, 'total_material')} />
            }
          />
        </Col>
      </StyledGutteredRow>

      <StyledGutteredRow>
        {/* Total Intervention */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total'}
              />
            }
            value={
              <DisplayPrice value={_.get(intervention, 'total_intervention')} />
            }
            style={{
              fontSize: '20px',
            }}
          />
        </Col>
      </StyledGutteredRow>
    </Card>
  )
}

export default AccountingDetail
