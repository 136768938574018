import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import * as F from '@utils/functions'
import { Table as AntdTable, Button, Space } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import {
  GenericDeleteForm as DeleteCallForm,
  StatusTag,
} from '../../.././Components'
import {
  Display,
  DisplayDateTime,
  LocaleDisplay,
} from '../../../Components/Display'
import { priorities, statuses } from '../../../enums/call'
import { marshalSorterParams } from '../../../utils'
import { CallsContext } from '../contexts'

const log = new Logger('CallsTable')

export const Table = ({ dataSource, searchCalls }) => {
  // inject Intl instance
  const intl = useIntl()

  // first load ref
  const firstLoad = useRef(true)

  // delete modal data
  const [deleteModalData, setDeleteModalData] = useState({ open: false })

  log.debug('Rendering calls table ...')

  const {
    searchLoading,
    query,
    filters,
    setFilters,
    ranges,
    setOrders,
    orders,
    pagination,
    setPagination,
  } = useContext(CallsContext)

  /**
   * useMemo callback in order to avoid antd rerending table columns twice
   */
  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'ui.calls.attributes.id',
        }),
        dataIndex: 'id',
        fixed: true,
        render: (_, record) => <Display value={record.id} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.calls.attributes.facility',
        }),
        dataIndex: 'facility',
        render: (_, record) => <Display value={record.facility} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.requested_at' }),
        dataIndex: 'requested_at',
        render: (_, record) => <DisplayDateTime value={record.requested_at} />,
        sorter: true,
        defaultSortOrder: F.orderToSort('requested_at', orders),
      },
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.priority' }),
        dataIndex: 'priority',
        render: (_, record) => (
          <LocaleDisplay
            localePath={`ui.calls.enums.priorities`}
            value={record.priority}
          />
        ),
        defaultFilteredValue: F.getFilterValues('priority', filters),
        filters: _.map(priorities, (v) => {
          return {
            text: intl.formatMessage({
              id: `ui.calls.enums.priorities.${v}`,
            }),
            value: v,
          }
        }),
      },
      {
        title: intl.formatMessage({ id: 'ui.calls.attributes.status' }),
        dataIndex: 'status',
        render: (_, record) => (
          <StatusTag status={record.status}>
            <LocaleDisplay
              localePath={`ui.calls.enums.statuses`}
              value={record.status}
            />
          </StatusTag>
        ),
        defaultFilteredValue: F.getFilterValues('status', filters),
        filters: _.map(statuses, (v) => {
          return {
            text: intl.formatMessage({ id: `ui.calls.enums.statuses.${v}` }),
            value: v,
          }
        }),
      },
      {
        title: intl.formatMessage({
          id: 'ui.actions.title',
        }),
        key: 'action',
        fixed: 'right',
        render: (r, record) => (
          <>
            <Space>
              {/* DETAIL */}
              <Button icon={<EyeOutlined />} href={`/calls/${record.id}`} />
              {/* EDIT */}
              <Button
                icon={<EditOutlined />}
                href={`/calls/${record.id}/edit`}
              />
              {/* DELETE */}
              {!_.find(_.get(record, 'interventions', []), {
                status: 'invoiced',
              }) && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    setDeleteModalData({
                      open: true,
                      record,
                    })
                  }
                />
              )}
            </Space>
          </>
        ),
      },
    ],
    []
  )

  // handle table change
  const handleTableChange = (pager, newFilters, sorter) => {
    log.debug('handleTableChange.start', { pager, newFilters, sorter })

    const marshalledFilters = _.map(newFilters, (v, k) => {
      return {
        field: k,
        values: v || [],
      }
    })

    const orders = marshalSorterParams(sorter)
    log.debug('Marshalled filters', { pager, marshalledFilters, orders })

    setFilters(marshalledFilters)
    setOrders(orders)
  }

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    searchCalls()
  }, [query, filters, ranges, orders])

  log.info('Calls data source ... :', {
    dataSource,
    storageFilters: F.retrieveFilters(['calls']),
  })

  return (
    !firstLoad.current && (
      <>
        <AntdTable
          dataSource={dataSource}
          rowKey={'id'}
          columns={columns}
          pagination={{
            ...pagination,
            onChange: handlePaginationChange,
          }}
          loading={{
            spinning: searchLoading,
            indicator: (
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
                spin
              />
            ),
          }}
          scroll={{ x: true }}
          onChange={handleTableChange}
        />

        {/* Delete Call Form */}
        <DeleteCallForm
          deleteModalData={deleteModalData}
          action={`/calls/${_.get(deleteModalData, 'record.id')}`}
          model={'calls'}
          name={'delete-call-form'}
          onCancel={() => setDeleteModalData({ open: false })}
        />
      </>
    )
  )
}

export default Table
