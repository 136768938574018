import "@aws-amplify/ui/dist/styles.css";
import {
  Authenticator as AmplifyAuthenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { CustomForm } from "././Components";
import { Input } from "antd";

// Amplify I18n
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";

// import custom translations dict
import { itDict } from "./dictionaries";

// styled components
import styled from "styled-components";

// I18n for authenticator configured
I18n.putVocabularies(translations);
I18n.setLanguage("it");

I18n.putVocabulariesForLanguage("it", itDict);

// Create a Login Container wrapper for Amplify authenticator with company background image
const StyledLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const Authenticator = (props) => {
  const context = useAuthenticator((context) => [context.authStatus]);

  if (context.authStatus === "authenticated")
    console.log("Amplify context", context);

  return context.authStatus === "authenticated" ? (
    // call backend to save JWT token
    // hidden form with POST /login
    <CustomForm
      method={"post"}
      action={"/auth/login"}
      name={"login-form"}
      instantSubmit={true}
    >
      <Input
        name={"jwt_token"}
        type={"hidden"}
        value={context.user.signInUserSession.idToken.jwtToken}
      />
    </CustomForm>
  ) : (
    <StyledLoginContainer image={props.images.castello_campanario_sfondo}>
      <AmplifyAuthenticator loginMechanisms={["email"]} hideSignUp />
    </StyledLoginContainer>
  );
};

export default (props) => (
  <AmplifyAuthenticator.Provider>
    <Authenticator {...props} />
  </AmplifyAuthenticator.Provider>
);
