import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Card, Typography } from 'antd'
import { Logger } from 'aws-amplify'

import { GlobalContext } from '@/contexts'

import { DeviceContextProvider } from './contexts'
import { DeviceForm } from './form'
import FormFields from './form/FormFields'

const log = new Logger('Devices/New')

const New = (props) => {
  const { setMode } = useContext(GlobalContext)
  const { device } = props

  useEffect(() => {
    setMode('create')
  }, [])

  log.info('device', device)

  return (
    <DeviceContextProvider value={{ device }}>
      <DeviceForm
        title={
          <Typography.Title level={2}>
            <FormattedMessage id={'ui.devices.actions.create'} />
          </Typography.Title>
        }
        {...props}
      >
        <Card>
          <FormFields />
        </Card>
      </DeviceForm>
    </DeviceContextProvider>
  )
}

export default New
