import React, { useContext, useState } from 'react'
import { Logger } from 'aws-amplify'
import { CustomForm } from '../../.././Components'
import { useIntl } from 'react-intl'
import { CallContext } from '../contexts'
import { WarningOutlined } from '@ant-design/icons'
import { GlobalContext } from '../../../contexts'
import FormHeader from '../components/FormHeader'
import _ from 'lodash'

const log = new Logger('CallForm')

const CallForm = (props) => {
  // inject intl
  const intl = useIntl()

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  const { title } = props
  const { call } = useContext(CallContext)

  const { mode, setLoading: setGlobalLoading } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)

  // button type
  const [buttonType, setButtonType] = useState({
    type: 'primary',
    danger: false,
  })

  const onFinish = (values) => {
    log.debug('Values', values)
    setLoading(true)

    document.getElementById('call-form').submit()
  }

  const onFinishFailed = (e) => {
    log.warn('Finish failed', e)
    setButtonType({
      type: 'dashed',
      icon: <WarningOutlined />,
      danger: true,
    })
  }

  return (
    <CustomForm
      action={_.get(props, 'action', '/calls')}
      layout={'vertical'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={call}
      method={'post'}
      name={'call-form'}
      validateMessages={validateMessages}
      disabled={loading}
    >
      {/* Add Form header based on mode */}
      <FormHeader title={title} mode={mode} buttonType={buttonType} />

      {props.children}
    </CustomForm>
  )
}

export default CallForm
