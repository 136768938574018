import _ from 'lodash'
import { yearlyInterventions } from '../../enums'
import { Logger } from 'aws-amplify'

const log = new Logger('Subscription/Helpers')
// Get Yearly Interventions value by period

export const getYearlyInterventionsVal = (period) => {
  if (_.isNil(period)) return NaN
  if (!_.includes(yearlyInterventions, period)) {
    throw new Error(`Invalid period ${period}`)
  }
  switch (period) {
    case 'monthly':
      return 12
    case 'bimonthly':
      return 6
    case 'quarterly':
      return 4
    case 'semester':
      return 2
    case 'annual':
    case 'biennial':
      return 1
  }
}

// calculate fee
export const calculateFee = (fields) => {
  // Rope
  const ropeBellsCount = parseInt(_.get(fields, 'rope_bells_count'))
  log.verbose('Rope Bells count: ', ropeBellsCount)

  const ropeBellFee = parseFloat(_.get(fields, 'rope_bell_fee'))
  log.verbose('Rope Bell fee: ', ropeBellFee)

  // Electrified
  const electrifiedBellsCount = parseInt(
    _.get(fields, 'electrified_bells_count')
  )

  log.verbose('Electrified Bells count: ', electrifiedBellsCount)

  const electrifiedBellFee = parseFloat(_.get(fields, 'electrified_bell_fee'))
  log.verbose('Electrified Bell fee: ', electrifiedBellFee)

  const fixedFee = parseFloat(_.get(fields, 'fixed_fee'))
  log.verbose('Fixed fee: ', fixedFee)

  const equipmentVehiclesCharge = parseFloat(
    _.get(fields, 'equipment_vehicles_charge')
  )
  log.verbose('Equipment vehicles charge: ', equipmentVehiclesCharge)

  const simData = parseFloat(_.get(fields, 'sim_data'))
  log.verbose('Sim data: ', simData)

  const nInterventions = getYearlyInterventionsVal(
    _.get(fields, 'yearly_interventions')
  )
  log.verbose('N interventions: ', nInterventions)

  let calculatedFee =
    (ropeBellFee * ropeBellsCount +
      electrifiedBellFee * electrifiedBellsCount +
      (fixedFee + equipmentVehiclesCharge + simData)) *
    nInterventions

  if (_.isNaN(calculatedFee)) return 0
  return _.round(calculatedFee, 2)
}

export const hasActiveSubscription = (facility) =>
  _.get(facility, 'subscription.status') === 'active'
