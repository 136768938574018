import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { WarningOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as R from 'ramda'

import { CustomForm } from '../../.././Components'
import { GlobalContext } from '../../../contexts'
import FormHeader from '../components/FormHeader'
import { InterventionContext } from '../contexts'

const log = new Logger('InterventionForm')

const InterventionForm = (props) => {
  // inject intl
  const intl = useIntl()

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  const { title } = props
  const { intervention } = useContext(InterventionContext)

  const { mode, setLoading: setGlobalLoading } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)

  // button type
  const [buttonType, setButtonType] = useState({
    type: 'primary',
    danger: false,
  })

  const onFinish = (values) => {
    log.debug('Values', values)
    setLoading(true)

    document.getElementById('intervention-form').submit()
  }

  const onFinishFailed = (e) => {
    log.warn('Finish failed', e)
    setButtonType({
      type: 'dashed',
      icon: <WarningOutlined />,
      danger: true,
    })
  }

  return (
    <CustomForm
      action={_.get(props, 'action', '/interventions')}
      layout={'vertical'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={intervention}
      method={'post'}
      name={'intervention-form'}
      validateMessages={validateMessages}
      disabled={loading}
    >
      {/* Add Form header based on mode */}
      <FormHeader title={title} mode={mode} buttonType={buttonType} />

      {props.children}

      {/*  Hidden Timezone Field */}
      <Input name={'timezone'} type={'hidden'} value={dayjs.tz.guess()} />
    </CustomForm>
  )
}

export default InterventionForm
