import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Form as AntdForm, TimePicker } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as R from 'ramda'

import { GlobalContext } from '../../contexts'
import { HiddenField } from './index'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const log = new Logger('Components/Fields/RangeTimePickerField')

const RangeTimePickerField = ({
  name,
  label,
  required = false,
  disabled = false,
  onChange = undefined,
  railsNames = [],
}) => {
  const intl = useIntl()

  const [selectedTimes, setSelectedTimes] = useState([])

  const parseTime = (time) => _.invoke(_.invoke(time, 'utc'), 'format', 'HH:mm')

  const handleChange = (times, timeStrings) => {
    log.debug('handleChange times PRE', times)

    const parsedTimes = _.map(times, parseTime)

    log.debug('handleChange times POST', parsedTimes)

    if (R.length(parsedTimes) == 0) setSelectedTimes([null, null])
    else setSelectedTimes(parsedTimes)

    if (_.isFunction(onChange)) onChange(times)
  }

  const { mode } = useContext(GlobalContext)

  return (
    <>
      <AntdForm.Item
        name={_.toPath(name)}
        label={<FormattedMessage id={label} />}
        required={required}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          suffixIcon={null}
          placeholder={[
            intl.formatMessage({ id: 'ui.placeholders.date.from' }),
            intl.formatMessage({ id: 'ui.placeholders.date.to' }),
          ]}
          disabledTime={(_, type) => {
            if (type === 'start') return { disabledHours: () => [0, 1] }
          }}
          onChange={handleChange}
          disabled={_.defaultTo(disabled, mode === 'detail')}
        />
      </AntdForm.Item>
      {/* Hidden Inputs */}
      {_.map(selectedTimes, (time, idx) => (
        <HiddenField
          key={idx}
          name={_.get(railsNames, `[${idx}]`)}
          value={time}
        />
      ))}
    </>
  )
}

export default RangeTimePickerField
