import {
  DirectionsService,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from '@react-google-maps/api'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'

import * as F from '@utils/functions'
import {
  simetOrigin as destination,
  getPin,
  hasActiveSubscription,
  isBreak,
  isMaintenance,
  simetOrigin as origin,
  rejectBreakStage,
  resetStageTimes,
  simetOrigin,
} from '@utils/index'
import * as FRoutes from '@utils/routes'
import { Form } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as R from 'ramda'

import { Display, LocaleDisplay } from '../../.././Components/Display'
import { MAPS_API_KEY } from '../../../config'
import { addSubscriptionTime } from '../../../utils/routes/helpers'
import { RouteContext } from '../contexts'
import RouteDirections from './RouteDirections'
import { ModalFacility } from './index'

var duration = require('dayjs/plugin/duration')

dayjs.extend(duration)

const log = new Logger('Routes/InterventionsMap')

const containerStyle = {
  width: '100%',
  height: '60vh',
}

const center = {
  lat: 46.071068,
  lng: 13.234579,
}

// Get facilities
const getFacilities = (interventions) =>
  _.uniqBy(_.map(interventions, 'facility'), 'id')

export default function InterventionsMap(props) {
  const {
    interventions,
    stages,
    setStages,
    setTotalDistance,
    setTotalTime,
    route,
  } = useContext(RouteContext)

  // form instance
  const form = Form.useFormInstance()

  // interventions modal
  const [modalData, setModalData] = useState({
    open: false,
    interventions: [],
  })

  // show info window
  const [infoWindow, setInfoWindow] = useState({
    show: false,
    facilityId: -1,
  })

  // show facility info window
  const [facilityInfo, setFacilityInfo] = useState({
    show: false,
    facilityId: -1,
  })

  const firstLoad = useRef(true)

  const facilities = getFacilities(
    _.unionBy(interventions, rejectBreakStage(stages), 'id')
  )

  // simet map pins
  const simetPin = _.get(props, 'images.pin_sede')

  log.debug('props, interventions, facilities, stages', {
    props,
    interventions,
    facilities,
    stages,
  })

  // Check if facility already programmed
  const isProgrammed = (facility) =>
    _.some(
      rejectBreakStage(stages),
      (stage) => stage.facility.id === facility.id
    )

  // handle marker click
  const handleMarkerClick = (facility, interventions) => {
    const relatedInterventions = _.filter(
      interventions,
      (intervention) => _.get(intervention, 'facility.id') == facility.id
    )

    log.debug('facility , Related interventions', {
      facility,
      relatedInterventions,
    })

    if (_.every(relatedInterventions, (ri) => _.find(stages, { id: ri.id }))) {
      setInfoWindow({ show: true, facilityId: facility.id })
      return
    }

    setModalData({
      open: true,
      interventions: relatedInterventions,
      selectedInterventions: _.filter(relatedInterventions, (ri) =>
        _.some(stages, { id: ri.id })
      ),
      facility,
    })
  }

  // handle modal cancel
  const handleModalCancel = () =>
    setModalData({ open: false, interventions: [] })

  // handle modal confirm
  const handleModalOk = (selectedInterventions) => {
    if (!_.isEmpty(selectedInterventions)) {
      log.debug('selected interventions', { selectedInterventions })
      setStages(_.uniqBy([...stages, ...selectedInterventions], 'id'))
      setModalData({ open: false, interventions: [] })
    }
  }

  // directions
  const [directions, setDirections] = useState(null)

  // directions service options
  const [directionsServiceOptions, setDirectionsServiceOptions] = useState({
    origin: {},
    destination: {},
    travelMode: 'DRIVING',
    waypoints: [],
  })

  // reset directions
  const [resetDirections, setResetDirections] = useState(false)

  // directions service
  const handleDirectionsChange = useCallback(
    (res) => {
      log.debug('direction options, stages, response, route', {
        directionsServiceOptions,
        stages,
        res,
        route,
      })

      setDirections(res)

      // Get total distance
      const totalDistance = _.reduce(
        _.get(res, 'routes[0].legs', []),
        (acc, leg) => acc + _.get(leg, 'distance.value', 0),
        0
      )

      // Get total time (seconds)
      const totalTimeInSeconds = _.reduce(
        _.get(res, 'routes[0].legs', []),
        (acc, leg) => acc + _.get(leg, 'duration.value', 0),
        0
      )

      log.debug('total distance, total time (seconds)', {
        totalDistance,
        totalTimeInSeconds,
      })

      alignTimes(res, route.scheduled_datetime)

      setTotalDistance(_.defaultTo(totalDistance, 0))

      setTotalTime(
        _.sum([
          FRoutes.getTotalTime(form.getFieldValue('interventions')),
          _.defaultTo(totalTimeInSeconds, 0),
        ])
      )
    },
    [directionsServiceOptions]
  )

  /*
                                                               set start time to previous + duration between stages
                                                               handle break stage times
                                                               align total time
                                                               */

  const alignTimes = (res, startTime) => {
    log.debug('alignTimes PRE', {
      res,
      stages,
      startTime,
      formValues: form.getFieldsValue(true),
    })

    if (R.isNil(startTime)) return

    const legs = R.path(['routes', 0, 'legs'], res)

    const clonedStages = R.clone(stages)
    F.forEachIndexed((stage, idx) => {
      log.debug('alignTimes loop...', { stage, clonedStages, idx })
      let currentStartTime = R.ifElse(
        R.equals(0),
        R.always(startTime),
        FRoutes.setPrevEndTime(clonedStages)
      )(idx)

      log.debug('alignTimes', { currentStartTime })

      currentStartTime = R.unless(
        () => isBreak(stage),
        () => FRoutes.addRouteDuration(legs, currentStartTime, idx)
      )(currentStartTime)

      log.debug('alignTimes', { legs, currentStartTime, idx })

      // if stage has subscription autocomplete also end time with coverage minutes
      // and is a maintenance_subscription

      const endTime = R.pipe(
        R.when(
          (s) =>
            R.and(
              FRoutes.subscriptionEligible(s),
              R.propSatisfies(R.isNil, 'expected_duration', s)
            ),
          R.assoc(
            'expected_duration',
            FRoutes.mmToHh(FRoutes.subscriptionCoverage(stage))
          )
        ),
        R.assoc('expected_end_time', null),
        R.when(
          R.propSatisfies(R.isNil, 'expected_end_time'),
          R.assoc('expected_end_time', currentStartTime)
        ),
        R.over(R.lensProp('expected_duration'), FRoutes.hhToMm),
        R.props(['expected_end_time', 'expected_duration']),
        R.apply(FRoutes.addDuration)
      )(stage)

      log.debug('alignTimes', { legs, currentStartTime, endTime, idx })

      const duration = R.ifElse(
        (s) =>
          R.and(
            FRoutes.subscriptionEligible(s),
            R.propSatisfies(R.isNil, 'expected_duration', s)
          ),
        (s) => FRoutes.mmToHh(FRoutes.subscriptionCoverage(s)),
        R.prop('expected_duration')
      )(stage)

      // set form values
      form.setFieldValue(
        _.toPath(`interventions.${idx}.scheduled_time`),
        currentStartTime
      )

      form.setFieldValue(
        _.toPath(`interventions.${idx}.expected_end_time`),
        endTime
      )

      form.setFieldValue(
        _.toPath(`interventions.${idx}.expected_duration`),
        duration
      )

      form.setFieldValue(
        _.toPath(`interventions.${idx}.id`),
        _.get(stage, 'id')
      )

      log.debug(`alignTimes ${idx}`, {
        currentStartTime,
        endTime,
      })

      _.set(clonedStages, `${idx}.expected_end_time`, endTime)
    }, clonedStages)
  }

  // reset directions hook
  useEffect(() => {
    if (resetDirections) {
      log.debug('resetting')
      setTotalDistance(0)
      setTotalTime(0)
      setDirectionsServiceOptions({
        origin: {},
        destination: {},
        travelMode: 'DRIVING',
        waypoints: [],
      })
      setDirections(null)

      setResetDirections(false)
    }
  }, [resetDirections])

  // directions service hook

  useEffect(() => {
    if (_.isEmpty(rejectBreakStage(stages))) {
      log.debug('inside cleanup', { stages })

      setResetDirections(true)
      return
    }

    const waypoints = _.map(stages, (stage, idx) => {
      if (isBreak(stage)) {
        if (idx === 0)
          return {
            location: {
              ...simetOrigin,
            },
          }
        else
          return {
            location: {
              lat: _.toNumber(
                _.get(stages, `[${idx - 1}].facility.latitude`, 0)
              ),
              lng: _.toNumber(
                _.get(stages, `[${idx - 1}].facility.longitude`, 0)
              ),
            },
          }
      } else
        return {
          location: {
            lat: _.toNumber(_.get(stage, 'facility.latitude', 0)),
            lng: _.toNumber(_.get(stage, 'facility.longitude', 0)),
          },
        }
    })

    log.debug('origin, destination, waypoints', {
      origin,
      destination,
      waypoints,
    })

    setDirectionsServiceOptions({
      origin,
      destination,
      travelMode: 'DRIVING',
      waypoints,
    })
  }, [stages])

  // reset times on route date change
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
      return
    }

    _.forEach(stages, (stage, idx) => {
      // set scheduled_time to null
      form.setFieldValue(_.toPath(`interventions.${idx}.scheduled_time`), null)

      // set expected_end_time to null
      form.setFieldValue(
        _.toPath(`interventions.${idx}.expected_end_time`),
        null
      )
    })

    setStages(resetStageTimes(stages))
  }, [route.scheduled_datetime])

  const byFacilityId = R.groupBy(
    R.path(['facility', 'id']),
    _.unionBy(interventions, rejectBreakStage(stages), 'id')
  )

  log.debug({ byFacilityId })
  return (
    <LoadScript googleMapsApiKey={MAPS_API_KEY}>
      <div
        style={{
          position: 'sticky',
          top: '16%',
        }}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          options={{ streetViewControl: false }}
        >
          {/* Child components, such as markers, info windows, etc. */}
          {_.map(facilities, (item, idx) => {
            const facilityStages = _.map(
              _.filter(
                rejectBreakStage(stages),
                (s) => _.get(s, 'facility.id') === item.id
              ),
              (stage) => {
                return { position: _.indexOf(stages, stage), stage }
              }
            )

            const firstProgrammedIdx = R.path([0, 'position'], facilityStages)

            log.debug({ facilityStages, firstProgrammedIdx })

            const programmedPin = R.path(
              ['images', `programmed_pin_${firstProgrammedIdx + 1}`],
              props
            )

            const facilityInterventions = R.pipe(R.prop(R.prop('id', item)))(
              byFacilityId
            )

            const pin = getPin(item, facilityInterventions)

            return (
              <Marker
                key={idx}
                icon={
                  isProgrammed(item)
                    ? {
                        url: programmedPin,
                        scaledSize: {
                          width: 37,
                          height: 37,
                        },
                      }
                    : pin && {
                        url: _.get(props, `images.${pin}`),
                        scaledSize: {
                          width: 37,
                          height: 37,
                        },
                      }
                }
                position={{
                  lat: _.toNumber(_.get(item, 'latitude', 0)),
                  lng: _.toNumber(_.get(item, 'longitude', 0)),
                }}
                onMouseOver={() => {
                  if (!_.isEmpty(facilityStages))
                    setFacilityInfo({
                      show: open,
                      facilityId: item.id,
                      facilityStages: facilityStages,
                    })
                }}
                onMouseOut={() => {
                  if (_.get(facilityInfo, 'show'))
                    setFacilityInfo({ show: false, facilityId: -1 })
                }}
                onClick={() => handleMarkerClick(item, interventions)}
              >
                {/*  Render info window only if an intervention has been scheduled */}
                {infoWindow.show && infoWindow.facilityId == item.id && (
                  <InfoWindow
                    onCloseClick={() =>
                      setInfoWindow({ show: false, facilityId: -1 })
                    }
                  >
                    <FormattedMessage
                      id={'ui.messages.interventions.already_programmed'}
                    />
                  </InfoWindow>
                )}

                {/*  Render facility info window on hover */}
                {facilityInfo.show && facilityInfo.facilityId == item.id && (
                  <InfoWindow
                    onCloseClick={() =>
                      setFacilityInfo({ show: false, facilityId: -1 })
                    }
                  >
                    <ol>
                      {_.map(
                        _.get(facilityInfo, 'facilityStages', []),
                        (stage, key) => (
                          <li key={key} value={_.get(stage, 'position') + 1}>
                            <Display value={_.get(item, 'business_name')} />
                            {'-'}
                            <LocaleDisplay
                              style={{
                                fontWeight: 'bold',
                              }}
                              localePath={`ui.interventions.enums.typologies`}
                              value={_.get(stage, 'stage.typology')}
                            />
                          </li>
                        )
                      )}
                    </ol>
                  </InfoWindow>
                )}
              </Marker>
            )
          })}
          <Marker
            position={origin}
            icon={{
              url: simetPin,
              scaledSize: {
                width: 37,
                height: 37,
              },
            }}
          />
          <ModalFacility
            modalData={modalData}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
          />

          {/* directions service */}
          {!_.isEmpty(_.get(directionsServiceOptions, 'destination')) && (
            <DirectionsService
              options={directionsServiceOptions}
              callback={handleDirectionsChange}
            />
          )}

          <RouteDirections directions={directions} />
        </GoogleMap>
      </div>
    </LoadScript>
  )
}
