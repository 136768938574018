import {createContext} from 'react'

// Materials Context
export const MaterialsContext = createContext()
export const MaterialsContextProvider = MaterialsContext.Provider

// Material Context
export const MaterialContext = createContext()

export const MaterialContextProvider = MaterialContext.Provider
