import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { WarningOutlined } from '@ant-design/icons'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { CustomForm } from '../../../Components'
import { GlobalContext } from '../../../contexts'
import FormHeader from '../components/FormHeader'
import { DeviceContext } from '../contexts'

const log = new Logger('DeviceForm')

const DeviceForm = (props) => {
  const intl = useIntl()

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  const { title } = props

  const { device } = useContext(DeviceContext)
  const { mode } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)

  // button type
  const [buttonType, setButtonType] = useState({
    type: 'primary',
    danger: false,
  })

  const onFinish = (values) => {
    log.debug('Values', values)
    setLoading(true)
    document.getElementById('device-form').submit()
  }

  const onFinishFailed = (e) => {
    log.warn('Finish failed', e)
    setButtonType({
      type: 'dashed',
      icon: <WarningOutlined />,
      danger: true,
    })
  }

  return (
    <CustomForm
      action={_.get(props, 'action', '/devices')}
      layout={'vertical'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={device}
      method={'post'}
      name={'device-form'}
      validateMessages={validateMessages}
      disabled={loading}
    >
      {/* Add Form header based on mode */}
      <FormHeader title={title} mode={mode} buttonType={buttonType} />

      {props.children}
    </CustomForm>
  )
}

export default DeviceForm
