import React from 'react'
import { Button, Form, Modal } from 'antd'
import { Logger } from 'aws-amplify'
import { FormattedMessage } from 'react-intl'
import { DocumentForm } from './DocumentForm'

const log = new Logger('DocumentModal')

export const DocumentModal = ({ initialValues, fileList, setFileList, open, onCancel, onCreate }) => {
  const [form] = Form.useForm()

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields()
        onCreate(values)
      })
      .catch((info) => {
        log.warn('Validate Failed:', info)
      })
  }
  return (
    <Modal
      open={open}
      title={<FormattedMessage id={'ui.documents.modal.title'} />}
      onCancel={onCancel}
      footer={
        <Button type={'primary'} onClick={handleOk}>
          <FormattedMessage id={'ui.documents.modal.ok_text'} />
        </Button>
      }
    >
      <DocumentForm initialValues={initialValues} fileList={fileList} setFileList={setFileList} form={form} />
    </Modal>
  )
}