import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Button, Col, Row, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import * as R from 'ramda'

import { CenteredSpinner, GutteredRow } from '@components/index'

import { makeApiRequest } from '@/api'
import { DEVICE_SEARCH } from '@/api/devices'
import { DEFAULT_PAGER } from '@/config'
import { GlobalContext } from '@/contexts'
import { StyledCard } from '@/styled'

import { DeviceSearch, DevicesTable } from './components'
import { DevicesContextProvider } from './contexts'

const { Title } = Typography

const log = new Logger('Devices/index')

const Index = (props) => {
  const intl = useIntl()

  const [devices, setDevices] = useState(R.propOr([], 'devices', props))

  log.info('props.devices', devices)

  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)

  const [query, setQuery] = useState(
    F.retrieveFiltersOr('', ['devices', 'query'])
  )
  const [pagination, setPagination] = useState(
    F.retrieveFiltersOr(DEFAULT_PAGER, ['devices', 'pagination'])
  )

  const { setMode } = useContext(GlobalContext)

  useEffect(() => {
    setMode('list')
  }, [])

  const searchDevices = async () => {
    setSearchLoading(true)
    const { data, headers } = await makeApiRequest(DEVICE_SEARCH, 'post', {
      body: { query },
      queryParams: F.marshalPaginationTo(pagination),
    })

    const newPagination = {
      ...pagination,
      ...F.marshalPaginationFrom(headers),
    }

    F.storeFilters({
      devices: {
        query,
        pagination: newPagination,
      },
    })

    log.debug('searchDevices.post', { data, headers })

    setPagination(newPagination)
    setSearchLoading(false)
    setDevices(data)
  }

  const isTableChanged = useRef(true)

  return loading ? (
    <CenteredSpinner />
  ) : (
    <DevicesContextProvider
      value={{
        devices,
        setDevices,
        query,
        setQuery,
        pagination,
        setPagination,
        isTableChanged,
        searchLoading,
      }}
    >
      <GutteredRow>
        <Col>
          <Title level={3}>
            <FormattedMessage id={'ui.devices.list_name'} />
          </Title>
        </Col>
      </GutteredRow>
      <Row justify={'space-between'}>
        <Col xl={10}>
          <DeviceSearch
            onSearch={() => {
              setPagination({ total: pagination.total, ...DEFAULT_PAGER })
              isTableChanged.current = true
            }}
          />
        </Col>
        <Col
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type='primary'
            href='/devices/new'
            onClick={() => setLoading(!loading)}
          >
            <FormattedMessage id={'ui.devices.buttons.create'} />
          </Button>
        </Col>
      </Row>
      <StyledCard>
        <DevicesTable dataSource={devices} search={searchDevices} />
      </StyledCard>
    </DevicesContextProvider>
  )
}

export default Index
