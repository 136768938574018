import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Col, Form, Row } from 'antd'
import styled from 'styled-components'

import { GlobalContext } from '../../../contexts'
import { InvoiceContext } from '../contexts'

const StyledDiv = styled.div`
  margin-bottom: 20px;
`

const FormHeader = ({ title, rowStyle = {}, buttonType = {} }) => {
  const { setLoading: setGlobalLoading } = useContext(GlobalContext)

  return (
    <>
      {title}
      <Row justify={'space-between'} style={rowStyle}>
        <Col>
          <StyledDiv>
            <Button
              type={'primary'}
              href={'/invoices'}
              onClick={() => setGlobalLoading(true)}
            >
              <FormattedMessage id={'ui.invoices.actions.back'} />
            </Button>
          </StyledDiv>
        </Col>

        <Col>
          <Form.Item>
            <Button {...buttonType} htmlType={'submit'}>
              <FormattedMessage id={'ui.button.submit'} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default FormHeader
