import { Form as AntdForm, Input } from 'antd'
import React, { useContext } from 'react'
import _ from 'lodash'
import { GlobalContext } from '../../contexts'

const { TextArea } = Input

const TextAreaField = ({
  name,
  label,
  placeholder = null,
  railsName = null,
  required = false,
  hidden = false,
  disabled = undefined,
}) => {
  const { mode } = useContext(GlobalContext)

  const rules = [
    {
      required,
    },
  ]

  return (
    <AntdForm.Item
      name={_.toPath(name)}
      label={label}
      required={required}
      rules={rules}
      hidden={hidden}
    >
      <TextArea
        name={railsName ?? name}
        disabled={_.defaultTo(disabled, mode === 'detail')}
        rows={4}
      />
    </AntdForm.Item>
  )
}

export default TextAreaField
