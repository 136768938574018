import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { LoadingOutlined } from '@ant-design/icons'
import { Table as AntdTable } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import {
  Display,
  DisplayDateTime,
  LocaleDisplay,
} from '../../../../Components/Display'
import { InvoiceContext } from '../../contexts'

const log = new Logger('Invoices/components/Modal/InterventionsTable')
const InterventionsTable = ({
  pagination,
  onChange,
  searchLoading,
  dataSource,
  selectedInterventions,
}) => {
  const intl = useIntl()

  const { invoice } = useContext(InvoiceContext)

  const { filterSelectedInterventions, setFilterSelectedInterventions } =
    selectedInterventions

  const columns = [
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.id',
      }),
      dataIndex: 'id',
      render: (_, record) => <Display value={record.id} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.call.id',
      }),
      dataIndex: 'call_id',
      render: (a, record) => <Display value={_.get(record, 'call_id')} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.created_at',
      }),
      dataIndex: 'created_at',
      render: (_, record) => (
        <DisplayDateTime value={record.created_at} format={'DD/MM/YYYY'} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.typology',
      }),
      dataIndex: 'typology',
      render: (_, record) => (
        <LocaleDisplay
          localePath={`ui.interventions.enums.typologies`}
          value={record.typology}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.scheduled_datetime',
      }),
      dataIndex: 'scheduled_datetime',
      render: (_, record) => (
        <DisplayDateTime value={record.scheduled_datetime} />
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      log.debug('rowSelection onChange', selectedRows)
      setFilterSelectedInterventions(selectedRows)
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: _.map(filterSelectedInterventions, 'id'),
  }

  log.debug('dataSource, selectedInterventions', {
    dataSource,
    filterSelectedInterventions,
  })

  return (
    <AntdTable
      dataSource={dataSource}
      rowKey={'id'}
      columns={columns}
      rowSelection={rowSelection}
      pagination={pagination}
      loading={{
        spinning: searchLoading,
        indicator: (
          <LoadingOutlined
            style={{
              fontSize: 40,
            }}
            spin
          />
        ),
      }}
      onChange={onChange}
      scroll={{ x: true }}
    />
  )
}

export default InterventionsTable
