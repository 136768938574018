import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EditOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons'
import * as F from '@utils/functions'
import { Table as AntdTable, Button, Space } from 'antd'
import { Auth, Logger } from 'aws-amplify'
import _ from 'lodash'

import { CUSTOMER_SEARCH, makeApiRequest } from '@/api'
import { statuses } from '@/enums/customer'

import { Display, LocaleDisplay } from '../../.././Components/Display'
import { StyledDivider } from '../../../styled'
import { marshalSorterParams, prepareFilters } from '../../../utils'
import { FacilitiesTable } from '../.././Facilities/components'
import { CustomersContext } from '../contexts'
import { StatusTag } from './index'

const log = new Logger('Customers')

const expandedRowRender = (record) => {
  log.info('expanding row ... ', record)
  return (
    <>
      <StyledDivider orientation={'left'}>
        <FormattedMessage id={'ui.facilities.list_name'} />
      </StyledDivider>
      <FacilitiesTable dataSource={record.facilities} />
    </>
  )
}

export const Table = (props) => {
  // inject Intl instance
  const intl = useIntl()

  const firstLoad = useRef(true)

  // table data loading
  const {
    searchLoading,
    filters,
    setFilters,
    setOrders,
    orders,
    query,
    pagination,
    setPagination,
  } = useContext(CustomersContext)

  log.info('Rendering customers table ...')
  const { dataSource, searchCustomers } = props

  log.info('Customers data source ... :', dataSource)

  // handle table change
  const handleTableChange = (pager, newFilters, sorter) => {
    log.debug('handleTableChange.start', { newFilters, sorter })

    const orders = marshalSorterParams(sorter)

    log.debug('handleTableChange.params', { pager, orders, newFilters })
    setOrders(orders)
    setFilters(prepareFilters(newFilters))
  }

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    searchCustomers()
  }, [query, orders, filters])

  const getColumns = (intl, orders) => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.customers.attributes.id',
        }),
        dataIndex: 'id',
        fixed: true,
        render: (_, record) => <Display value={record.id} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.customers.attributes.business_name',
        }),
        dataIndex: 'business_name',
        render: (_, record) => <Display value={record.business_name} />,
        sorter: true,
        defaultSortOrder: F.orderToSort('business_name', orders),
      },
      {
        title: intl.formatMessage({ id: 'ui.customers.attributes.legal_form' }),
        dataIndex: 'legal_form',
        render: (_, record) => (
          <FormattedMessage
            id={`ui.customers.enums.legal_forms.${record.legal_form}`}
          />
        ),
      },
      {
        title: intl.formatMessage({ id: 'ui.customers.attributes.tax_code' }),
        dataIndex: 'tax_code',
        render: (_, record) => <Display value={record.tax_code} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.customers.attributes.vat_number' }),
        dataIndex: 'vat_number',
        render: (_, record) => <Display value={record.vat_number} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.addresses.attributes.city',
        }),
        dataIndex: 'registered_office',
      },
      {
        title: intl.formatMessage({ id: 'ui.customers.attributes.status' }),
        dataIndex: 'status',
        render: (_, record) => (
          <StatusTag status={record.status}>
            <LocaleDisplay
              localePath={'ui.customers.enums.statuses'}
              value={record.status}
            />
          </StatusTag>
        ),
        defaultFilteredValue: F.getFilterValues('status', filters),
        filters: _.map(statuses, (v) => {
          return {
            text: intl.formatMessage({
              id: `ui.customers.enums.statuses.${v}`,
            }),
            value: v,
          }
        }),
      },
      {
        title: intl.formatMessage({
          id: 'ui.customers.actions.title',
        }),
        key: 'action',
        fixed: 'right',
        render: (_, record) => (
          <>
            {/* DETAIL */}
            <Space>
              <Button href={`/customers/${record.id}`} icon={<EyeOutlined />} />

              {/* EDIT */}
              <Button
                href={`/customers/${record.id}/edit?phase=completed`}
                icon={<EditOutlined />}
              />
            </Space>
          </>
        ),
      },
    ]
    return columns
  }

  /**
   * useMemo hook
   */
  const columns = useMemo(() => getColumns(intl, orders), [])

  return (
    !firstLoad.current && (
      <>
        <AntdTable
          dataSource={dataSource}
          rowKey={'id'}
          columns={columns}
          pagination={{
            ...pagination,
            onChange: handlePaginationChange,
          }}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => !_.isEmpty(record.facilities),
          }}
          loading={{
            spinning: searchLoading,
            indicator: (
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
                spin
              />
            ),
          }}
          scroll={{ x: true }}
          onChange={handleTableChange}
        />
      </>
    )
  )
}

export default Table
