import React, { useContext } from 'react'

import { SearchBox } from '../../.././Components'
import { RoutesContext } from '../contexts'

const RouteSearch = ({ defaultValue = '', onSearch }) => {
  // searchLoading, routes
  const { setQuery } = useContext(RoutesContext)

  return (
    <SearchBox
      onSearch={(query) => {
        setQuery(query)
        onSearch(query)
      }}
      defaultValue={defaultValue}
      placeholder={`ui.routes.placeholders.search`}
    />
  )
}

export default RouteSearch
