import React, { useContext, useEffect, useState } from 'react'
import { CustomerForm } from './components'
import { Button, Col, Row, Space, Typography } from 'antd'
import { CenteredSpinner, DeleteForm } from '../.././Components'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Logger } from 'aws-amplify'
import { CustomerContextProvider } from './contexts'
import _ from 'lodash'
import { StyledDetailLabel } from '../../styled'
import { GlobalContext } from '../../contexts'

const log = new Logger('CustomerDetail')

const StyledDiv = styled.div`
  margin-bottom: 20px;
`

const Show = (props) => {
  const [loading, setLoading] = useState(false)

  log.verbose('Current props: ', props)

  const [customer, setCustomer] = useState(_.get(props, 'customer', {}))

  const enums = _.get(props, 'enums', {})

  const readonly = true

  // state set when delete customer button is clicked
  const [customerDelete, setCustomerDelete] = useState(false)

  // set detail mode
  const { setMode } = useContext(GlobalContext)

  useEffect(() => {
    setMode('detail')
  }, [])

  return (
    <CustomerContextProvider value={{ customer, setCustomer, enums, readonly }}>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <CustomerForm {...props}>
          <Typography.Title level={2}>
            <FormattedMessage id={`ui.customers.detail`} />
          </Typography.Title>

          <Typography.Title level={5}>
            <StyledDetailLabel>{customer.business_name}</StyledDetailLabel>
          </Typography.Title>

          <Row justify={'space-between'}>
            <Col>
              <StyledDiv>
                <Button
                  type={'primary'}
                  href={'/customers'}
                  onClick={() => setLoading(true)}
                >
                  <FormattedMessage id={'ui.customers.actions.back'} />
                </Button>
              </StyledDiv>
            </Col>

            <Col>
              <Space>
                {/* DELETE */}
                <DeleteForm
                  name={'delete-customer-form'}
                  action={`/customers/${customer.id}`}
                  record={customer}
                />

                {/* EDIT */}
                <Button
                  type={'primary'}
                  href={`/customers/${customer.id}/edit`}
                  onClick={() => setLoading(true)}
                >
                  <FormattedMessage id={'ui.button.edit'} />
                </Button>
              </Space>
            </Col>
          </Row>
        </CustomerForm>
      )}
    </CustomerContextProvider>
  )
}

export default Show
