import React from 'react'
import { useIntl } from 'react-intl/lib'

import { Table } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import {
  Display,
  DisplayDateTime,
  LocaleDisplay,
} from '../../.././Components/Display'
import { LinkToButton } from '../../../Components/Button'
import { addPropertyToColumns } from '../../../utils'

const log = new Logger('RouteInterventionsTable')

const InterventionsTable = ({ dataSource }) => {
  const intl = useIntl()

  const buildLinkTo = (el) => {
    const baseUrl = R.ifElse(
      R.prop('call_id'),
      (record) =>
        R.concat(
          `/calls/${record.call_id}/interventions/`,
          R.toString(R.prop('id', record))
        ),
      (record) => R.concat(`/interventions/`, R.toString(R.prop('id', record)))
    )(el)

    return baseUrl
  }

  const getColumns = () => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.interventions.id',
        }),
        dataIndex: 'id',
        render: (_, record) => <Display value={record.id} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.interventions.scheduled_time',
        }),
        dataIndex: 'scheduled_datetime',
        render: (_, record) => (
          <DisplayDateTime value={record.scheduled_datetime} format={'HH:mm'} />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.interventions.facility',
        }),
        dataIndex: 'facility',

        render: (_, record) => <Display value={record.facility} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.interventions.typology',
        }),
        dataIndex: 'typology',
        render: (_, record) => (
          <LocaleDisplay
            localePath={`ui.interventions.enums.typologies`}
            value={record.typology}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.interventions.call_id',
        }),
        dataIndex: 'call_id',
        render: (a, record) => <Display value={_.get(record, 'call_id')} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.interventions.notes',
        }),
        dataIndex: 'notes',
        render: (_, record) => <Display value={record.notes} />,
      },
      {
        render: (value, record) =>
          R.isNotNil(buildLinkTo(record)) && (
            <LinkToButton href={buildLinkTo(record)} />
          ),
      },
    ]

    return addPropertyToColumns(columns, 'responsive', ['md'])
  }

  return (
    <Table
      dataSource={dataSource}
      rowKey={'id'}
      columns={getColumns()}
      pagination={false}
    />
  )
}

export default InterventionsTable
