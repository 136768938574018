[
  {
    "key": 1,
    "type": "vehicle",
    "value": "Ducato"
  },
  {
    "key": 2,
    "type": "vehicle",
    "value": "Piattaforma"
  },
  {
    "key": 3,
    "type": "vehicle",
    "value": "Daily"
  },
  {
    "key": 4,
    "type": "vehicle",
    "value": "Telonato"
  },
  {
    "key": 5,
    "type": "vehicle",
    "value": "Camion"
  },
  {
    "key": 6,
    "type": "vehicle",
    "value": "Traghetto"
  },
  {
    "key": 7,
    "type": "vehicle",
    "value": "Macchina"
  }
]