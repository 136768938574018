import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { ExportOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { Display } from '../../../Components/Display'
import { GlobalContext } from '../../../contexts'

const logger = new Logger('IntervetionChild')
const InterventionChild = ({ child }) => {
  const { setLoading } = useContext(GlobalContext)
  return (
    <>
      <Row style={{ flexDirection: 'column' }}>
        <Col style={{ paddingBottom: '8px', color: `rgba(0, 0, 0, 0.6)` }}>
          <FormattedMessage id={'ui.interventions.form.labels.child'} />
        </Col>
        <Col>
          <span style={{ marginRight: '5px' }}>
            <Button
              type={'text'}
              href={`/interventions/${_.get(child, 'id')}`}
              icon={<ExportOutlined />}
              onClick={() => setLoading(true)}
            />
          </span>
          <span>
            <Display value={_.get(child, 'id')} />
          </span>
        </Col>
      </Row>
    </>
  )
}

export default InterventionChild
