import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as R from 'ramda'

import { makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'

const log = new Logger('utils/functions')

const STORAGE_KEY = 'simet'

const ORDER_MAP = {
  asc: 'ascend',
  desc: 'descend',
}

export const floor = R.curry((value) => _.floor(value))

export const round = R.curry((value, precision = 0) =>
  _.round(value, precision)
)

export const forEachIndexed = R.addIndex(R.forEach)
export const mapIndexed = R.addIndex(R.map)
/**
 * Store filters in local storage
 * @param {Object} value
 * @returns
 */
export const storeFilters = (value) =>
  R.pipe((v) => {
    const currentValue = JSON.parse(sessionStorage.getItem(STORAGE_KEY))
    const newValue = R.mergeDeepRight(currentValue, v)
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newValue))
  })(value)

/**
 * Retrieve filters by key
 * @param {Array.<String>} key
 * @returns
 */
export const retrieveFilters = (key) =>
  R.path(key, JSON.parse(sessionStorage.getItem(STORAGE_KEY)))

export const orderToSort = (k, v) =>
  R.pipe(
    R.find(R.propEq(k, 'field')),
    R.prop('value'),
    (val) => ORDER_MAP[val]
  )(v)

export const getFilterValues = (key, filters) =>
  R.pipe(R.find(R.propEq(key, 'field')), R.propOr([], 'values'))(filters)

/**
 * Retrieve filters providing a default value
 * @param {*} val
 * @param {Array.<String>} key
 * @returns {Array.<Filter> | *} list of filters by key
 */
export const retrieveFiltersOr = (val, key) =>
  R.defaultTo(val, retrieveFilters(key))

/**
 *
 * @param {RangeFilter | undefined} range
 * @returns
 */
export const rangeForPicker = (range) =>
  R.ifElse(
    R.isNil,
    R.always([null, null]),
    R.always([dayjs(R.prop('from', range)), dayjs(R.prop('to', range))])
  )(range)

/**
 *
 * @param {*} headers
 * @returns
 */
export const marshalPaginationFrom = (headers) => {
  const { total, 'per-page': pageSize } = headers

  return { total: Number(total), pageSize: Number(pageSize) }
}

export const marshalPaginationTo = (pager) => {
  const { current: page, pageSize: per_page } = pager

  return {
    page,
    per_page,
  }
}

/**
 * Check if pagination has changed
 * @param {*} oldPagination
 * @param {*} newPagination
 * @returns
 */
export const hasPaginationChanged = (oldPagination, newPagination) => {
  log.debug('hasPaginationChanged', { oldPagination, newPagination })
  if (R.eqProps('current', oldPagination, newPagination))
    return { ...DEFAULT_PAGER, total: oldPagination.total }
  else return { ...newPagination, total: oldPagination.total }
}
