import React from 'react'
import { Logger } from 'aws-amplify'
import { Col, message } from 'antd'
import {
  GutteredRow,
} from '../../.././Components'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import styled from 'styled-components'
import { download, getFileNameByKey, getKeyByStorageUrl } from '../../../utils'
import { S3_DOCS_BUCKET } from '../../../config'
import { DownloadOutlined } from '@ant-design/icons'
import { StyledClickableSpan } from '../../../styled'
import * as s3 from '../../../lib'
import {DisplayDateTime, DisplayDetail} from "../../../Components/Display";
import {DisplayArray} from "../.././Routes/components";

const log = new Logger('InterventionDetailFields')

const StyledGutteredRow = styled(GutteredRow)`
  margin-bottom: 20px;
  padding: 0 0;
`
const InterventionDetailFields = ({ intervention }) => {
  const handleDownload = async (record) => {
    log.verbose('Downloading file ...')
    const fileKey = getKeyByStorageUrl(record.storage_url, S3_DOCS_BUCKET)
    const presignedUrl = await s3.getPresignedUrl(fileKey)

    const fileName = getFileNameByKey(fileKey)

    try {
      await download(presignedUrl, fileName)
    } catch (err) {
      log.error('Error downloading file: ', err)
      message.error(`Error downloading file: ${err}`)
    }
  }

  const getFileName = (record) => {
    const fileKey = getKeyByStorageUrl(record.storage_url, S3_DOCS_BUCKET)

    return getFileNameByKey(fileKey)
  }

  return (
    <>
      <StyledGutteredRow>
        {/*  Scheduled Datetime */}
        <Col xl={4}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.attributes.scheduled_datetime'}
              />
            }
            value={
              <DisplayDateTime
                value={_.get(intervention, 'scheduled_datetime')}
              />
            }
          />
        </Col>

        {/*  Team */}
        <Col xl={6}>
          <DisplayDetail
            label={<FormattedMessage id={'ui.interventions.attributes.team'} />}
            value={
              <DisplayArray array={_.get(intervention, 'route.team', [])} />
            }
          />
        </Col>

        {/* Vehicles */}
        <Col xl={6}>
          <DisplayDetail
            label={
              <FormattedMessage id={'ui.interventions.attributes.vehicles'} />
            }
            value={
              <DisplayArray array={_.get(intervention, 'route.vehicles', [])} />
            }
          />
        </Col>

        {/*   Document  */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage id={'ui.interventions.attributes.document'} />
            }
            value={
              _.get(intervention, 'document', null) && (
                <>
                  <StyledClickableSpan
                    onClick={() =>
                      handleDownload(_.get(intervention, 'document', null))
                    }
                  >
                    <DownloadOutlined />
                  </StyledClickableSpan>
                  <span>{getFileName(_.get(intervention, 'document'))}</span>
                </>
              )
            }
          />
        </Col>
      </StyledGutteredRow>

      <StyledGutteredRow>
        {/*  Closing Datetime */}
        <Col md={6}>
          <DisplayDetail
            label={
              <FormattedMessage
                id={'ui.interventions.attributes.closing_datetime'}
              />
            }
            value={
              <DisplayDateTime
                value={_.get(intervention, 'closing_datetime')}
              />
            }
          />
        </Col>
      </StyledGutteredRow>
    </>
  )
}

export default InterventionDetailFields
