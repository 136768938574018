import React, { useContext } from 'react'
import { Logger } from 'aws-amplify'
import { Form, Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { GlobalContext } from '../../contexts'
import _ from 'lodash'

const { Dragger } = Upload

const log = new Logger('FieldUpload')

// Field Upload
const FieldUpload = ({
  name = 'dragger',
  normFile,
  label = undefined,
  disabled = undefined,
  ...props
}) => {
  log.debug('Field Upload props: ', props)

  const intl = useIntl()

  const { mode } = useContext(GlobalContext)

  return (
    <Form.Item
      name={name}
      valuePropName={'fileList'}
      getValueFromEvent={normFile}
      rules={[
        {
          required: true,
          message: intl.formatMessage(
            { id: 'ui.validations.custom_is_required' },
            { name: intl.formatMessage({ id: 'ui.documents.singular' }) }
          ),
        },
      ]}
    >
      <Dragger disabled={_.defaultTo(disabled, mode === 'detail')} {...props}>
        <p className='ant-upload-drag-icon'>
          <CloudUploadOutlined />
        </p>
        <span className='ant-upload-text'>
          {_.defaultTo(
            label,
            <FormattedMessage id={'ui.documents.upload_document'} />
          )}
        </span>
      </Dragger>
    </Form.Item>
  )
}

export default FieldUpload
