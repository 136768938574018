import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'

import { marshalForFileList } from '../documents'
import { marshalSingleSelect } from '../helpers'
import { calculateFee } from '../subscriptions'
import { getWorkingTimes } from './helpers'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const log = new Logger('Intervention/marshal')

export const marshalIntervention = (intervention) => {
  const result = {
    ...intervention,
    reported_by: marshalSingleSelect(intervention.reported_by),
    phone: marshalPhone(intervention.phone),
    contact_phone: marshalPhone(intervention.contact_phone),
    contact_person: marshalSingleSelect(intervention.contact_person),
    fixed_fee_reduction_flag: _.defaultTo(
      _.get(intervention, 'fixed_fee_reduction_flag'),
      false
    ),
    km_reduction_flag: _.defaultTo(
      _.get(intervention, 'km_reduction_flag'),
      false
    ),
    expected_date: _.thru(intervention.expected_date, (date) => {
      if (!date) return date
      else return dayjs(intervention.expected_date)
    }),
    qualified_workers: _.defaultTo(_.get(intervention, 'qualified_workers'), 0),
    apprentice_workers: _.defaultTo(
      _.get(intervention, 'apprentice_workers'),
      0
    ),
    specialized_workers: _.defaultTo(
      _.get(intervention, 'specialized_workers'),
      0
    ),
    dragger: undefined,
    ...marshalWorkingTimes(intervention),
    subscription_fee: isMaintenance(intervention)
      ? enrichFee(intervention)
      : undefined,
    subscription_coverage: isMaintenance(intervention)
      ? _.get(intervention, 'subscription.coverage_minutes')
      : undefined,
  }

  const draggerPromise = marshalForFileList(
    _.get(intervention, 'document', null)
  )

  draggerPromise.then((file) => {
    result.dragger = file ? [file] : [] // Update the dragger property once the promise is resolved
  })
  return result
}

const marshalWorkingTimes = (intervention) => {
  const workingTimes = {}
  _.forEach(['qualified', 'apprentice', 'specialized'], (typology) => {
    _.forEach(['morning', 'evening'], (timeOfDay) => {
      const times = []

      times.push(
        _.thru(
          _.get(intervention, `${timeOfDay}_start_${typology}_working_time`),
          (date) => {
            if (!date) return date
            else
              return dayjs
                .utc(
                  _.get(
                    intervention,
                    `${timeOfDay}_start_${typology}_working_time`
                  ),
                  'HH:mm'
                )
                .local()
          }
        )
      )

      times.push(
        _.thru(
          _.get(intervention, `${timeOfDay}_end_${typology}_working_time`),
          (date) => {
            if (!date) return date
            else
              return dayjs
                .utc(
                  _.get(
                    intervention,
                    `${timeOfDay}_end_${typology}_working_time`
                  ),
                  'HH:mm'
                )
                .local()
          }
        )
      )

      workingTimes[`${timeOfDay}_${typology}_times`] = times
    })
  })

  log.debug('marshalWorkingTimes POST', workingTimes)

  return workingTimes
}
/*
  Calculation Methods
 */

export const enrichInterventionTotals = (intervention) => {
  const getTotalByTypology = (typology) => {
    const hourlyCost = _.get(intervention, `${typology}_hourly_cost`)

    const [minutes, workingHours] = getWorkingTimes(
      _.get(intervention, `morning_${typology}_times`),
      _.get(intervention, `evening_${typology}_times`)
    )

    const workers = _.get(intervention, `${typology}_workers`)

    const subscriptionCoverage = _.get(intervention, 'subscription_coverage')

    const [coverage, isCovered] = discountCoverage(
      minutes,
      subscriptionCoverage
    )

    const total = isMaintenance(intervention)
      ? isCovered
        ? 0
        : _.round(_.multiply(_.multiply(coverage, hourlyCost), workers), 2) || 0
      : _.round(_.multiply(_.multiply(workingHours, hourlyCost), workers), 2) ||
        0

    return total
  }

  const getHoursByTypology = (typology) => {
    const [totalMinutes, hoursInCents] = getWorkingTimes(
      _.get(intervention, `morning_${typology}_times`),
      _.get(intervention, `evening_${typology}_times`)
    )

    const hours = _.floor(hoursInCents)
    const minutes = _.multiply(_.subtract(hoursInCents, hours), 60)

    log.debug('getHoursByTypology', {
      typology,
      hours,
      hoursInCents,
      totalMinutes,
    })

    const string = `${hours}h ${minutes}m`
    return _.includes(string, 'NaN') ? null : string
  }

  const totalManpower = _.sum([
    getTotalByTypology('qualified'),
    getTotalByTypology('apprentice'),
    getTotalByTypology('specialized'),
  ])

  const totalMaterial = _.sum(
    _.flatMap(_.get(intervention, 'intervention_materials'), (m) =>
      _.multiply(_.get(m, 'quantity'), _.get(m, 'unit_price'))
    )
  )

  return {
    ...intervention,
    qualified_working_hours: getHoursByTypology('qualified'),
    apprentice_working_hours: getHoursByTypology('apprentice'),
    specialized_working_hours: getHoursByTypology('specialized'),
    total_qualified: getTotalByTypology('qualified'),
    total_apprentice: getTotalByTypology('apprentice'),
    total_specialized: getTotalByTypology('specialized'),
    total_manpower: totalManpower,
    total_material: totalMaterial,
  }
}

/* handle time round calculations
       ((durata in min / 15) arrotondo per eccesso / 4 ) = ore durata
*/
export const roundTime = (minutes) => {
  // const minutes = dayjs(end).diff(dayjs(start), 'm')
  return toHours(minutes)
}

export const isCovered = (start, end, coverage) =>
  _.subtract(dayjs(end).diff(dayjs(start), 'm'), coverage) <= 0

export const toHours = (minutes) => {
  const hours = _.ceil(_.divide(minutes, 15)) / 4
  return hours < 1 ? 1 : hours
}

const maintenanceToHours = (minutes) => {
  const hours = _.ceil(_.divide(minutes, 15)) / 4

  // return zero if in first 15 minutes
  if (_.inRange(hours, 0.01, 0.26)) {
    return 0
  } else return hours
}

/**
 *
 * @param {*} minutes
 * @param {*} coverage
 * @returns {[number, boolean]}
 */
export const discountCoverage = (minutes, coverage) => {
  const difference = _.subtract(minutes, coverage)
  return [maintenanceToHours(difference), difference <= 0]
}

export const isMaintenance = (intervention) =>
  _.get(intervention, 'typology') === 'maintenance_subscription'

export const enrichFee = (intervention) => {
  const subscription = _.get(intervention, 'subscription')
  log.debug('enriching subscription fee....', { subscription })

  return calculateFee(subscription)
}

const marshalPhone = (phone = '') =>
  _.isEmpty(phone) ? [] : _.split(phone, ',')
