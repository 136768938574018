[
  {
    "key": "1",
    "name": "AFGHANISTAN",
    "id": "004",
    "alpha2": "AF",
    "alpha3": "AFG",
    "code_ita_bank": "002",
    "code_istat": "301",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "2",
    "name": "ALBANIA",
    "id": "008",
    "alpha2": "AL",
    "alpha3": "ALB",
    "code_ita_bank": "087",
    "code_istat": "201",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "3",
    "name": "ALGERIA",
    "id": "012",
    "alpha2": "DZ",
    "alpha3": "DZA",
    "code_ita_bank": "003",
    "code_istat": "401",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "4",
    "name": "ANDORRA",
    "id": "020",
    "alpha2": "AD",
    "alpha3": "AND",
    "code_ita_bank": "004",
    "code_istat": "202",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "5",
    "name": "ANGOLA",
    "id": "024",
    "alpha2": "AO",
    "alpha3": "AGO",
    "code_ita_bank": "133",
    "code_istat": "402",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "6",
    "name": "ANGUILLA",
    "id": "660",
    "alpha2": "AI",
    "alpha3": "AIA",
    "code_ita_bank": "209",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "7",
    "name": "ANTARTIDE",
    "id": "010",
    "alpha2": "AQ",
    "alpha3": "ATA",
    "code_ita_bank": "180",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "8",
    "name": "ANTIGUA E BARBUDA",
    "id": "028",
    "alpha2": "AG",
    "alpha3": "ATG",
    "code_ita_bank": "197",
    "code_istat": "503",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "9",
    "name": "ARABIA SAUDITA",
    "id": "682",
    "alpha2": "SA",
    "alpha3": "SAU",
    "code_ita_bank": "005",
    "code_istat": "302",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "10",
    "name": "ARGENTINA",
    "id": "032",
    "alpha2": "AR",
    "alpha3": "ARG",
    "code_ita_bank": "006",
    "code_istat": "602",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "11",
    "name": "ARMENIA",
    "id": "051",
    "alpha2": "AM",
    "alpha3": "ARM",
    "code_ita_bank": "266",
    "code_istat": "358",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "12",
    "name": "ARUBA",
    "id": "533",
    "alpha2": "AW",
    "alpha3": "ABW",
    "code_ita_bank": "212",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "13",
    "name": "AUSTRALIA",
    "id": "036",
    "alpha2": "AU",
    "alpha3": "AUS",
    "code_ita_bank": "007",
    "code_istat": "701",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "14",
    "name": "AUSTRIA",
    "id": "040",
    "alpha2": "AT",
    "alpha3": "AUT",
    "code_ita_bank": "008",
    "code_istat": "203",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "15",
    "name": "AZERBAIGIAN",
    "id": "031",
    "alpha2": "AZ",
    "alpha3": "AZE",
    "code_ita_bank": "268",
    "code_istat": "359",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "16",
    "name": "BAHAMAS",
    "id": "044",
    "alpha2": "BS",
    "alpha3": "BHS",
    "code_ita_bank": "160",
    "code_istat": "505",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "17",
    "name": "BAHREIN",
    "id": "048",
    "alpha2": "BH",
    "alpha3": "BHR",
    "code_ita_bank": "169",
    "code_istat": "304",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "18",
    "name": "BANGLADESH",
    "id": "050",
    "alpha2": "BD",
    "alpha3": "BGD",
    "code_ita_bank": "130",
    "code_istat": "305",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "19",
    "name": "BARBADOS",
    "id": "052",
    "alpha2": "BB",
    "alpha3": "BRB",
    "code_ita_bank": "118",
    "code_istat": "506",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "20",
    "name": "BELGIO",
    "id": "056",
    "alpha2": "BE",
    "alpha3": "BEL",
    "code_ita_bank": "009",
    "code_istat": "206",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "21",
    "name": "BELIZE",
    "id": "084",
    "alpha2": "BZ",
    "alpha3": "BLZ",
    "code_ita_bank": "198",
    "code_istat": "507",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "22",
    "name": "BENIN",
    "id": "204",
    "alpha2": "BJ",
    "alpha3": "BEN",
    "code_ita_bank": "158",
    "code_istat": "406",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "23",
    "name": "BERMUDA",
    "id": "060",
    "alpha2": "BM",
    "alpha3": "BMU",
    "code_ita_bank": "207",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "24",
    "name": "BHUTAN",
    "id": "064",
    "alpha2": "BT",
    "alpha3": "BTN",
    "code_ita_bank": "097",
    "code_istat": "306",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "25",
    "name": "BIELORUSSIA",
    "id": "112",
    "alpha2": "BY",
    "alpha3": "BLR",
    "code_ita_bank": "264",
    "code_istat": "256",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "26",
    "name": "BIRMANIA",
    "id": "104",
    "alpha2": "MM",
    "alpha3": "MMR",
    "code_ita_bank": "083",
    "code_istat": "307",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "27",
    "name": "BOLIVIA",
    "id": "068",
    "alpha2": "BO",
    "alpha3": "BOL",
    "code_ita_bank": "010",
    "code_istat": "604",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "28",
    "name": "BOSNIA ED ERZEGOVINA",
    "id": "070",
    "alpha2": "BA",
    "alpha3": "BIH",
    "code_ita_bank": "274",
    "code_istat": "252",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "29",
    "name": "BOTSWANA",
    "id": "072",
    "alpha2": "BW",
    "alpha3": "BWA",
    "code_ita_bank": "098",
    "code_istat": "408",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "30",
    "name": "BRASILE",
    "id": "076",
    "alpha2": "BR",
    "alpha3": "BRA",
    "code_ita_bank": "011",
    "code_istat": "605",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "31",
    "name": "BRUNEI",
    "id": "096",
    "alpha2": "BN",
    "alpha3": "BRN",
    "code_ita_bank": "125",
    "code_istat": "309",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "32",
    "name": "BULGARIA",
    "id": "100",
    "alpha2": "BG",
    "alpha3": "BGR",
    "code_ita_bank": "012",
    "code_istat": "209",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "33",
    "name": "BURKINA FASO",
    "id": "854",
    "alpha2": "BF",
    "alpha3": "BFA",
    "code_ita_bank": "142",
    "code_istat": "409",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "34",
    "name": "BURUNDI",
    "id": "108",
    "alpha2": "BI",
    "alpha3": "BDI",
    "code_ita_bank": "025",
    "code_istat": "410",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "35",
    "name": "CAMBOGIA",
    "id": "116",
    "alpha2": "KH",
    "alpha3": "KHM",
    "code_ita_bank": "135",
    "code_istat": "310",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "36",
    "name": "CAMERUN",
    "id": "120",
    "alpha2": "CM",
    "alpha3": "CMR",
    "code_ita_bank": "119",
    "code_istat": "411",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "37",
    "name": "CANADA",
    "id": "124",
    "alpha2": "CA",
    "alpha3": "CAN",
    "code_ita_bank": "013",
    "code_istat": "509",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "38",
    "name": "CAPO VERDE",
    "id": "132",
    "alpha2": "CV",
    "alpha3": "CPV",
    "code_ita_bank": "188",
    "code_istat": "413",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "39",
    "name": "CIAD",
    "id": "148",
    "alpha2": "TD",
    "alpha3": "TCD",
    "code_ita_bank": "144",
    "code_istat": "415",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "40",
    "name": "CILE",
    "id": "152",
    "alpha2": "CL",
    "alpha3": "CHL",
    "code_ita_bank": "015",
    "code_istat": "606",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "41",
    "name": "CINA",
    "id": "156",
    "alpha2": "CN",
    "alpha3": "CHN",
    "code_ita_bank": "016",
    "code_istat": "314",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "42",
    "name": "CIPRO",
    "id": "196",
    "alpha2": "CY",
    "alpha3": "CYP",
    "code_ita_bank": "101",
    "code_istat": "315",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "43",
    "name": "CITTÀ DEL VATICANO",
    "id": "336",
    "alpha2": "VA",
    "alpha3": "VAT",
    "code_ita_bank": "093",
    "code_istat": "246",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "44",
    "name": "COLOMBIA",
    "id": "170",
    "alpha2": "CO",
    "alpha3": "COL",
    "code_ita_bank": "017",
    "code_istat": "608",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "45",
    "name": "COMORE",
    "id": "174",
    "alpha2": "KM",
    "alpha3": "COM",
    "code_ita_bank": "176",
    "code_istat": "417",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "46",
    "name": "COREA DEL NORD",
    "id": "408",
    "alpha2": "KP",
    "alpha3": "PRK",
    "code_ita_bank": "074",
    "code_istat": "319",
    "fiscally_subsized": true,
    "not_collaborative": true
  },
  {
    "key": "47",
    "name": "COREA DEL SUD",
    "id": "410",
    "alpha2": "KR",
    "alpha3": "KOR",
    "code_ita_bank": "084",
    "code_istat": "320",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "48",
    "name": "COSTA D'AVORIO",
    "id": "384",
    "alpha2": "CI",
    "alpha3": "CIV",
    "code_ita_bank": "146",
    "code_istat": "404",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "49",
    "name": "COSTA RICA",
    "id": "188",
    "alpha2": "CR",
    "alpha3": "CRI",
    "code_ita_bank": "019",
    "code_istat": "513",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "50",
    "name": "CROAZIA",
    "id": "191",
    "alpha2": "HR",
    "alpha3": "HRV",
    "code_ita_bank": "261",
    "code_istat": "250",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "51",
    "name": "CUBA",
    "id": "192",
    "alpha2": "CU",
    "alpha3": "CUB",
    "code_ita_bank": "020",
    "code_istat": "514",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "52",
    "name": "CURAÇAO",
    "id": "531",
    "alpha2": "CW",
    "alpha3": "CUW",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "53",
    "name": "DANIMARCA",
    "id": "208",
    "alpha2": "DK",
    "alpha3": "DNK",
    "code_ita_bank": "021",
    "code_istat": "212",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "54",
    "name": "DOMINICA",
    "id": "212",
    "alpha2": "DM",
    "alpha3": "DMA",
    "code_ita_bank": "192",
    "code_istat": "515",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "55",
    "name": "ECUADOR",
    "id": "218",
    "alpha2": "EC",
    "alpha3": "ECU",
    "code_ita_bank": "024",
    "code_istat": "609",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "56",
    "name": "EGITTO",
    "id": "818",
    "alpha2": "EG",
    "alpha3": "EGY",
    "code_ita_bank": "023",
    "code_istat": "419",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "57",
    "name": "EL SALVADOR",
    "id": "222",
    "alpha2": "SV",
    "alpha3": "SLV",
    "code_ita_bank": "064",
    "code_istat": "517",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "58",
    "name": "EMIRATI ARABI UNITI",
    "id": "784",
    "alpha2": "AE",
    "alpha3": "ARE",
    "code_ita_bank": "238",
    "code_istat": "322",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "59",
    "name": "ERITREA",
    "id": "232",
    "alpha2": "ER",
    "alpha3": "ERI",
    "code_ita_bank": "277",
    "code_istat": "466",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "60",
    "name": "ESTONIA",
    "id": "233",
    "alpha2": "EE",
    "alpha3": "EST",
    "code_ita_bank": "257",
    "code_istat": "247",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "61",
    "name": "ETIOPIA",
    "id": "231",
    "alpha2": "ET",
    "alpha3": "ETH",
    "code_ita_bank": "026",
    "code_istat": "420",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "62",
    "name": "FIGI",
    "id": "242",
    "alpha2": "FJ",
    "alpha3": "FJI",
    "code_ita_bank": "161",
    "code_istat": "703",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "63",
    "name": "FILIPPINE",
    "id": "608",
    "alpha2": "PH",
    "alpha3": "PHL",
    "code_ita_bank": "027",
    "code_istat": "323",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "64",
    "name": "FINLANDIA",
    "id": "246",
    "alpha2": "FI",
    "alpha3": "FIN",
    "code_ita_bank": "028",
    "code_istat": "214",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "65",
    "name": "FRANCIA",
    "id": "250",
    "alpha2": "FR",
    "alpha3": "FRA",
    "code_ita_bank": "029",
    "code_istat": "215",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "66",
    "name": "GABON",
    "id": "266",
    "alpha2": "GA",
    "alpha3": "GAB",
    "code_ita_bank": "157",
    "code_istat": "421",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "67",
    "name": "GAMBIA",
    "id": "270",
    "alpha2": "GM",
    "alpha3": "GMB",
    "code_ita_bank": "164",
    "code_istat": "422",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "68",
    "name": "GEORGIA",
    "id": "268",
    "alpha2": "GE",
    "alpha3": "GEO",
    "code_ita_bank": "267",
    "code_istat": "360",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "69",
    "name": "GEORGIA DEL SUD E ISOLE SANDWICH AUSTRALI",
    "id": "239",
    "alpha2": "GS",
    "alpha3": "SGS",
    "code_ita_bank": "283",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "70",
    "name": "GERMANIA",
    "id": "276",
    "alpha2": "DE",
    "alpha3": "DEU",
    "code_ita_bank": "094",
    "code_istat": "216",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "71",
    "name": "GHANA",
    "id": "288",
    "alpha2": "GH",
    "alpha3": "GHA",
    "code_ita_bank": "112",
    "code_istat": "423",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "72",
    "name": "GIAMAICA",
    "id": "388",
    "alpha2": "JM",
    "alpha3": "JAM",
    "code_ita_bank": "082",
    "code_istat": "518",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "73",
    "name": "GIAPPONE",
    "id": "392",
    "alpha2": "JP",
    "alpha3": "JPN",
    "code_ita_bank": "088",
    "code_istat": "326",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "74",
    "name": "GIBILTERRA",
    "id": "292",
    "alpha2": "GI",
    "alpha3": "GIB",
    "code_ita_bank": "102",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "75",
    "name": "GIBUTI",
    "id": "262",
    "alpha2": "DJ",
    "alpha3": "DJI",
    "code_ita_bank": "113",
    "code_istat": "424",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "76",
    "name": "GIORDANIA",
    "id": "400",
    "alpha2": "JO",
    "alpha3": "JOR",
    "code_ita_bank": "122",
    "code_istat": "327",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "77",
    "name": "GRECIA",
    "id": "300",
    "alpha2": "GR",
    "alpha3": "GRC",
    "code_ita_bank": "032",
    "code_istat": "220",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "78",
    "name": "GRENADA",
    "id": "308",
    "alpha2": "GD",
    "alpha3": "GRD",
    "code_ita_bank": "156",
    "code_istat": "519",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "79",
    "name": "GROENLANDIA",
    "id": "304",
    "alpha2": "GL",
    "alpha3": "GRL",
    "code_ita_bank": "200",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "80",
    "name": "GUADALUPA",
    "id": "312",
    "alpha2": "GP",
    "alpha3": "GLP",
    "code_ita_bank": "214",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "81",
    "name": "GUAM",
    "id": "316",
    "alpha2": "GU",
    "alpha3": "GUM",
    "code_ita_bank": "154",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "82",
    "name": "GUATEMALA",
    "id": "320",
    "alpha2": "GT",
    "alpha3": "GTM",
    "code_ita_bank": "033",
    "code_istat": "523",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "83",
    "name": "GUERNSEY",
    "id": "831",
    "alpha2": "GG",
    "alpha3": "GGY",
    "code_ita_bank": "201",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "84",
    "name": "GUINEA",
    "id": "324",
    "alpha2": "GN",
    "alpha3": "GIN",
    "code_ita_bank": "137",
    "code_istat": "425",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "85",
    "name": "GUINEA-BISSAU",
    "id": "624",
    "alpha2": "GW",
    "alpha3": "GNB",
    "code_ita_bank": "185",
    "code_istat": "426",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "86",
    "name": "GUINEA EQUATORIALE",
    "id": "226",
    "alpha2": "GQ",
    "alpha3": "GNQ",
    "code_ita_bank": "167",
    "code_istat": "427",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "87",
    "name": "GUYANA",
    "id": "328",
    "alpha2": "GY",
    "alpha3": "GUY",
    "code_ita_bank": "159",
    "code_istat": "612",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "88",
    "name": "GUYANA FRANCESE",
    "id": "254",
    "alpha2": "GF",
    "alpha3": "GUF",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "89",
    "name": "HAITI",
    "id": "332",
    "alpha2": "HT",
    "alpha3": "HTI",
    "code_ita_bank": "034",
    "code_istat": "524",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "90",
    "name": "HONDURAS",
    "id": "340",
    "alpha2": "HN",
    "alpha3": "HND",
    "code_ita_bank": "035",
    "code_istat": "525",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "91",
    "name": "HONG KONG",
    "id": "344",
    "alpha2": "HK",
    "alpha3": "HKG",
    "code_ita_bank": "103",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "92",
    "name": "INDIA",
    "id": "356",
    "alpha2": "IN",
    "alpha3": "IND",
    "code_ita_bank": "114",
    "code_istat": "330",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "93",
    "name": "INDONESIA",
    "id": "360",
    "alpha2": "ID",
    "alpha3": "IDN",
    "code_ita_bank": "129",
    "code_istat": "331",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "94",
    "name": "IRAN",
    "id": "364",
    "alpha2": "IR",
    "alpha3": "IRN",
    "code_ita_bank": "039",
    "code_istat": "332",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "95",
    "name": "IRAQ",
    "id": "368",
    "alpha2": "IQ",
    "alpha3": "IRQ",
    "code_ita_bank": "038",
    "code_istat": "333",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "96",
    "name": "IRLANDA",
    "id": "372",
    "alpha2": "IE",
    "alpha3": "IRL",
    "code_ita_bank": "040",
    "code_istat": "221",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "97",
    "name": "ISLANDA",
    "id": "352",
    "alpha2": "IS",
    "alpha3": "ISL",
    "code_ita_bank": "041",
    "code_istat": "223",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "98",
    "name": "ISOLA BOUVET",
    "id": "074",
    "alpha2": "BV",
    "alpha3": "BVT",
    "code_ita_bank": "280",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "99",
    "name": "ISOLA DI MAN",
    "id": "833",
    "alpha2": "IM",
    "alpha3": "IMN",
    "code_ita_bank": "203",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "100",
    "name": "ISOLA DI NATALE",
    "id": "162",
    "alpha2": "CX",
    "alpha3": "CXR",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "101",
    "name": "ISOLA NORFOLK",
    "id": "574",
    "alpha2": "NF",
    "alpha3": "NFK",
    "code_ita_bank": "285",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "102",
    "name": "ISOLE ÅLAND",
    "id": "248",
    "alpha2": "AX",
    "alpha3": "ALA",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "103",
    "name": "ISOLE BES",
    "id": "535",
    "alpha2": "BQ",
    "alpha3": "BES",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "104",
    "name": "ISOLE CAYMAN",
    "id": "136",
    "alpha2": "KY",
    "alpha3": "CYM",
    "code_ita_bank": "211",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "105",
    "name": "ISOLE COCOS (KEELING)",
    "id": "166",
    "alpha2": "CC",
    "alpha3": "CCK",
    "code_ita_bank": "281",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "106",
    "name": "ISOLE COOK",
    "id": "184",
    "alpha2": "CK",
    "alpha3": "COK",
    "code_ita_bank": "237",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "107",
    "name": "ISOLE FAER OER",
    "id": "234",
    "alpha2": "FO",
    "alpha3": "FRO",
    "code_ita_bank": "204",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "108",
    "name": "ISOLE FALKLAND",
    "id": "238",
    "alpha2": "FK",
    "alpha3": "FLK",
    "code_ita_bank": "190",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "109",
    "name": "ISOLE HEARD E MCDONALD",
    "id": "334",
    "alpha2": "HM",
    "alpha3": "HMD",
    "code_ita_bank": "284",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "110",
    "name": "ISOLE MARIANNE SETTENTRIONALI",
    "id": "580",
    "alpha2": "MP",
    "alpha3": "MNP",
    "code_ita_bank": "219",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "111",
    "name": "ISOLE MARSHALL",
    "id": "584",
    "alpha2": "MH",
    "alpha3": "MHL",
    "code_ita_bank": "217",
    "code_istat": "712",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "112",
    "name": "ISOLE MINORI ESTERNE DEGLI STATI UNITI",
    "id": "581",
    "alpha2": "UM",
    "alpha3": "UMI",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "113",
    "name": "ISOLE PITCAIRN",
    "id": "612",
    "alpha2": "PN",
    "alpha3": "PCN",
    "code_ita_bank": "175",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "114",
    "name": "ISOLE SALOMONE",
    "id": "090",
    "alpha2": "SB",
    "alpha3": "SLB",
    "code_ita_bank": "191",
    "code_istat": "725",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "115",
    "name": "ISOLE VERGINI BRITANNICHE",
    "id": "092",
    "alpha2": "VG",
    "alpha3": "VGB",
    "code_ita_bank": "249",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "116",
    "name": "ISOLE VERGINI AMERICANE",
    "id": "850",
    "alpha2": "VI",
    "alpha3": "VIR",
    "code_ita_bank": "221",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "117",
    "name": "ISRAELE",
    "id": "376",
    "alpha2": "IL",
    "alpha3": "ISR",
    "code_ita_bank": "182",
    "code_istat": "334",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "118",
    "name": "ITALIA",
    "id": "380",
    "alpha2": "IT",
    "alpha3": "ITA",
    "code_ita_bank": "086",
    "code_istat": "100",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "119",
    "name": "JERSEY",
    "id": "832",
    "alpha2": "JE",
    "alpha3": "JEY",
    "code_ita_bank": "202",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "120",
    "name": "KAZAKISTAN",
    "id": "398",
    "alpha2": "KZ",
    "alpha3": "KAZ",
    "code_ita_bank": "269",
    "code_istat": "356",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "121",
    "name": "KENYA",
    "id": "404",
    "alpha2": "KE",
    "alpha3": "KEN",
    "code_ita_bank": "116",
    "code_istat": "428",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "122",
    "name": "KIRGHIZISTAN",
    "id": "417",
    "alpha2": "KG",
    "alpha3": "KGZ",
    "code_ita_bank": "270",
    "code_istat": "361",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "123",
    "name": "KIRIBATI",
    "id": "296",
    "alpha2": "KI",
    "alpha3": "KIR",
    "code_ita_bank": "194",
    "code_istat": "708",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "124",
    "name": "KUWAIT",
    "id": "414",
    "alpha2": "KW",
    "alpha3": "KWT",
    "code_ita_bank": "126",
    "code_istat": "335",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "125",
    "name": "LAOS",
    "id": "418",
    "alpha2": "LA",
    "alpha3": "LAO",
    "code_ita_bank": "136",
    "code_istat": "336",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "126",
    "name": "LESOTHO",
    "id": "426",
    "alpha2": "LS",
    "alpha3": "LSO",
    "code_ita_bank": "089",
    "code_istat": "429",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "127",
    "name": "LETTONIA",
    "id": "428",
    "alpha2": "LV",
    "alpha3": "LVA",
    "code_ita_bank": "258",
    "code_istat": "248",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "128",
    "name": "LIBANO",
    "id": "422",
    "alpha2": "LB",
    "alpha3": "LBN",
    "code_ita_bank": "095",
    "code_istat": "337",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "129",
    "name": "LIBERIA",
    "id": "430",
    "alpha2": "LR",
    "alpha3": "LBR",
    "code_ita_bank": "044",
    "code_istat": "430",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "130",
    "name": "LIBIA",
    "id": "434",
    "alpha2": "LY",
    "alpha3": "LBY",
    "code_ita_bank": "045",
    "code_istat": "431",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "131",
    "name": "LIECHTENSTEIN",
    "id": "438",
    "alpha2": "LI",
    "alpha3": "LIE",
    "code_ita_bank": "090",
    "code_istat": "225",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "132",
    "name": "LITUANIA",
    "id": "440",
    "alpha2": "LT",
    "alpha3": "LTU",
    "code_ita_bank": "259",
    "code_istat": "249",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "133",
    "name": "LUSSEMBURGO",
    "id": "442",
    "alpha2": "LU",
    "alpha3": "LUX",
    "code_ita_bank": "092",
    "code_istat": "226",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "134",
    "name": "MACAO",
    "id": "446",
    "alpha2": "MO",
    "alpha3": "MAC",
    "code_ita_bank": "059",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "135",
    "name": "MACEDONIA DEL NORD",
    "id": "807",
    "alpha2": "MK",
    "alpha3": "MKD",
    "code_ita_bank": "278",
    "code_istat": "253",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "136",
    "name": "MADAGASCAR",
    "id": "450",
    "alpha2": "MG",
    "alpha3": "MDG",
    "code_ita_bank": "104",
    "code_istat": "432",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "137",
    "name": "MALAWI",
    "id": "454",
    "alpha2": "MW",
    "alpha3": "MWI",
    "code_ita_bank": "056",
    "code_istat": "434",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "138",
    "name": "MALAYSIA",
    "id": "458",
    "alpha2": "MY",
    "alpha3": "MYS",
    "code_ita_bank": "106",
    "code_istat": "340",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "139",
    "name": "MALDIVE",
    "id": "462",
    "alpha2": "MV",
    "alpha3": "MDV",
    "code_ita_bank": "127",
    "code_istat": "339",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "140",
    "name": "MALI",
    "id": "466",
    "alpha2": "ML",
    "alpha3": "MLI",
    "code_ita_bank": "149",
    "code_istat": "435",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "141",
    "name": "MALTA",
    "id": "470",
    "alpha2": "MT",
    "alpha3": "MLT",
    "code_ita_bank": "105",
    "code_istat": "227",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "142",
    "name": "MAROCCO",
    "id": "504",
    "alpha2": "MA",
    "alpha3": "MAR",
    "code_ita_bank": "107",
    "code_istat": "436",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "143",
    "name": "MARTINICA",
    "id": "474",
    "alpha2": "MQ",
    "alpha3": "MTQ",
    "code_ita_bank": "213",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "144",
    "name": "MAURITANIA",
    "id": "478",
    "alpha2": "MR",
    "alpha3": "MRT",
    "code_ita_bank": "141",
    "code_istat": "437",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "145",
    "name": "MAURITIUS",
    "id": "480",
    "alpha2": "MU",
    "alpha3": "MUS",
    "code_ita_bank": "128",
    "code_istat": "438",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "146",
    "name": "MAYOTTE",
    "id": "175",
    "alpha2": "YT",
    "alpha3": "MYT",
    "code_ita_bank": "226",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "147",
    "name": "MESSICO",
    "id": "484",
    "alpha2": "MX",
    "alpha3": "MEX",
    "code_ita_bank": "046",
    "code_istat": "527",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "148",
    "name": "MICRONESIA",
    "id": "583",
    "alpha2": "FM",
    "alpha3": "FSM",
    "code_ita_bank": "215",
    "code_istat": "713",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "149",
    "name": "MOLDAVIA",
    "id": "498",
    "alpha2": "MD",
    "alpha3": "MDA",
    "code_ita_bank": "265",
    "code_istat": "254",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "150",
    "name": "MONGOLIA",
    "id": "496",
    "alpha2": "MN",
    "alpha3": "MNG",
    "code_ita_bank": "110",
    "code_istat": "341",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "151",
    "name": "MONTENEGRO",
    "id": "499",
    "alpha2": "ME",
    "alpha3": "MNE",
    "code_ita_bank": "290",
    "code_istat": "270",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "152",
    "name": "MONTSERRAT",
    "id": "500",
    "alpha2": "MS",
    "alpha3": "MSR",
    "code_ita_bank": "208",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "153",
    "name": "MOZAMBICO",
    "id": "508",
    "alpha2": "MZ",
    "alpha3": "MOZ",
    "code_ita_bank": "134",
    "code_istat": "440",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "154",
    "name": "NAMIBIA",
    "id": "516",
    "alpha2": "NA",
    "alpha3": "NAM",
    "code_ita_bank": "206",
    "code_istat": "441",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "155",
    "name": "NAURU",
    "id": "520",
    "alpha2": "NR",
    "alpha3": "NRU",
    "code_ita_bank": "109",
    "code_istat": "715",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "156",
    "name": "NEPAL",
    "id": "524",
    "alpha2": "NP",
    "alpha3": "NPL",
    "code_ita_bank": "115",
    "code_istat": "342",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "157",
    "name": "NICARAGUA",
    "id": "558",
    "alpha2": "NI",
    "alpha3": "NIC",
    "code_ita_bank": "047",
    "code_istat": "529",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "158",
    "name": "NIGER",
    "id": "562",
    "alpha2": "NE",
    "alpha3": "NER",
    "code_ita_bank": "150",
    "code_istat": "442",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "159",
    "name": "NIGERIA",
    "id": "566",
    "alpha2": "NG",
    "alpha3": "NGA",
    "code_ita_bank": "117",
    "code_istat": "443",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "160",
    "name": "NIUE",
    "id": "570",
    "alpha2": "NU",
    "alpha3": "NIU",
    "code_ita_bank": "205",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "161",
    "name": "NORVEGIA",
    "id": "578",
    "alpha2": "NO",
    "alpha3": "NOR",
    "code_ita_bank": "048",
    "code_istat": "231",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "162",
    "name": "NUOVA CALEDONIA",
    "id": "540",
    "alpha2": "NC",
    "alpha3": "NCL",
    "code_ita_bank": "253",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "163",
    "name": "NUOVA ZELANDA",
    "id": "554",
    "alpha2": "NZ",
    "alpha3": "NZL",
    "code_ita_bank": "049",
    "code_istat": "719",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "164",
    "name": "OMAN",
    "id": "512",
    "alpha2": "OM",
    "alpha3": "OMN",
    "code_ita_bank": "163",
    "code_istat": "343",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "165",
    "name": "PAESI BASSI",
    "id": "528",
    "alpha2": "NL",
    "alpha3": "NLD",
    "code_ita_bank": "050",
    "code_istat": "232",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "166",
    "name": "PAKISTAN",
    "id": "586",
    "alpha2": "PK",
    "alpha3": "PAK",
    "code_ita_bank": "036",
    "code_istat": "344",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "167",
    "name": "PALAU",
    "id": "585",
    "alpha2": "PW",
    "alpha3": "PLW",
    "code_ita_bank": "216",
    "code_istat": "720",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "168",
    "name": "PALESTINA",
    "id": "275",
    "alpha2": "PS",
    "alpha3": "PSE",
    "code_ita_bank": "279",
    "code_istat": "324",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "169",
    "name": "PANAMA",
    "id": "591",
    "alpha2": "PA",
    "alpha3": "PAN",
    "code_ita_bank": "051",
    "code_istat": "530",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "170",
    "name": "PAPUA NUOVA GUINEA",
    "id": "598",
    "alpha2": "PG",
    "alpha3": "PNG",
    "code_ita_bank": "186",
    "code_istat": "721",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "171",
    "name": "PARAGUAY",
    "id": "600",
    "alpha2": "PY",
    "alpha3": "PRY",
    "code_ita_bank": "052",
    "code_istat": "614",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "172",
    "name": "PERÙ",
    "id": "604",
    "alpha2": "PE",
    "alpha3": "PER",
    "code_ita_bank": "053",
    "code_istat": "615",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "173",
    "name": "POLINESIA FRANCESE",
    "id": "258",
    "alpha2": "PF",
    "alpha3": "PYF",
    "code_ita_bank": "225",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "174",
    "name": "POLONIA",
    "id": "616",
    "alpha2": "PL",
    "alpha3": "POL",
    "code_ita_bank": "054",
    "code_istat": "233",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "175",
    "name": "PORTO RICO",
    "id": "630",
    "alpha2": "PR",
    "alpha3": "PRI",
    "code_ita_bank": "220",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "176",
    "name": "PORTOGALLO",
    "id": "620",
    "alpha2": "PT",
    "alpha3": "PRT",
    "code_ita_bank": "055",
    "code_istat": "234",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "177",
    "name": "MONACO",
    "id": "492",
    "alpha2": "MC",
    "alpha3": "MCO",
    "code_ita_bank": "091",
    "code_istat": "229",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "178",
    "name": "QATAR",
    "id": "634",
    "alpha2": "QA",
    "alpha3": "QAT",
    "code_ita_bank": "168",
    "code_istat": "345",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "179",
    "name": "REGNO UNITO",
    "id": "826",
    "alpha2": "GB",
    "alpha3": "GBR",
    "code_ita_bank": "031",
    "code_istat": "219",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "180",
    "name": "RD DEL CONGO",
    "id": "180",
    "alpha2": "CD",
    "alpha3": "COD",
    "code_ita_bank": "018",
    "code_istat": "463",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "181",
    "name": "REP. CECA",
    "id": "203",
    "alpha2": "CZ",
    "alpha3": "CZE",
    "code_ita_bank": "275",
    "code_istat": "257",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "182",
    "name": "REP. CENTRAFRICANA",
    "id": "140",
    "alpha2": "CF",
    "alpha3": "CAF",
    "code_ita_bank": "143",
    "code_istat": "414",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "183",
    "name": "REP. DEL CONGO",
    "id": "178",
    "alpha2": "CG",
    "alpha3": "COG",
    "code_ita_bank": "145",
    "code_istat": "418",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "184",
    "name": "REP. DOMINICANA",
    "id": "214",
    "alpha2": "DO",
    "alpha3": "DOM",
    "code_ita_bank": "063",
    "code_istat": "516",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "185",
    "name": "RIUNIONE",
    "id": "638",
    "alpha2": "RE",
    "alpha3": "REU",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "186",
    "name": "ROMANIA",
    "id": "642",
    "alpha2": "RO",
    "alpha3": "ROU",
    "code_ita_bank": "061",
    "code_istat": "235",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "187",
    "name": "RUANDA",
    "id": "646",
    "alpha2": "RW",
    "alpha3": "RWA",
    "code_ita_bank": "151",
    "code_istat": "446",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "188",
    "name": "RUSSIA",
    "id": "643",
    "alpha2": "RU",
    "alpha3": "RUS",
    "code_ita_bank": "262",
    "code_istat": "245",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "189",
    "name": "SAHARA OCCIDENTALE",
    "id": "732",
    "alpha2": "EH",
    "alpha3": "ESH",
    "code_ita_bank": "166",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "190",
    "name": "SAINT KITTS E NEVIS",
    "id": "659",
    "alpha2": "KN",
    "alpha3": "KNA",
    "code_ita_bank": "195",
    "code_istat": "534",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "191",
    "name": "SAINT LUCIA",
    "id": "662",
    "alpha2": "LC",
    "alpha3": "LCA",
    "code_ita_bank": "199",
    "code_istat": "532",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "192",
    "name": "SANT'ELENA, ASCENSIONE E TRISTAN DA CUNHA",
    "id": "654",
    "alpha2": "SH",
    "alpha3": "SHN",
    "code_ita_bank": "229",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "193",
    "name": "SAINT VINCENT E GRENADINE",
    "id": "670",
    "alpha2": "VC",
    "alpha3": "VCT",
    "code_ita_bank": "196",
    "code_istat": "533",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "194",
    "name": "SAINT-BARTHÉLEMY",
    "id": "652",
    "alpha2": "BL",
    "alpha3": "BLM",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "195",
    "name": "SAINT-MARTIN",
    "id": "663",
    "alpha2": "MF",
    "alpha3": "MAF",
    "code_ita_bank": "222",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "196",
    "name": "SAINT-PIERRE E MIQUELON",
    "id": "666",
    "alpha2": "PM",
    "alpha3": "SPM",
    "code_ita_bank": "248",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "197",
    "name": "SAMOA",
    "id": "882",
    "alpha2": "WS",
    "alpha3": "WSM",
    "code_ita_bank": "131",
    "code_istat": "727",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "198",
    "name": "SAMOA AMERICANE",
    "id": "016",
    "alpha2": "AS",
    "alpha3": "ASM",
    "code_ita_bank": "131",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "199",
    "name": "SAN MARINO",
    "id": "674",
    "alpha2": "SM",
    "alpha3": "SMR",
    "code_ita_bank": "037",
    "code_istat": "236",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "200",
    "name": "SÃO TOMÉ E PRÍNCIPE",
    "id": "678",
    "alpha2": "ST",
    "alpha3": "STP",
    "code_ita_bank": "187",
    "code_istat": "448",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "201",
    "name": "SENEGAL",
    "id": "686",
    "alpha2": "SN",
    "alpha3": "SEN",
    "code_ita_bank": "152",
    "code_istat": "450",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "202",
    "name": "SERBIA",
    "id": "688",
    "alpha2": "RS",
    "alpha3": "SRB",
    "code_ita_bank": "289",
    "code_istat": "271",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "203",
    "name": "SEYCHELLES",
    "id": "690",
    "alpha2": "SC",
    "alpha3": "SYC",
    "code_ita_bank": "189",
    "code_istat": "449",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "204",
    "name": "SIERRA LEONE",
    "id": "694",
    "alpha2": "SL",
    "alpha3": "SLE",
    "code_ita_bank": "153",
    "code_istat": "451",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "205",
    "name": "SINGAPORE",
    "id": "702",
    "alpha2": "SG",
    "alpha3": "SGP",
    "code_ita_bank": "147",
    "code_istat": "346",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "206",
    "name": "SINT MAARTEN",
    "id": "534",
    "alpha2": "SX",
    "alpha3": "SXM",
    "code_ita_bank": "222",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "207",
    "name": "SIRIA",
    "id": "760",
    "alpha2": "SY",
    "alpha3": "SYR",
    "code_ita_bank": "065",
    "code_istat": "348",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "208",
    "name": "SLOVACCHIA",
    "id": "703",
    "alpha2": "SK",
    "alpha3": "SVK",
    "code_ita_bank": "276",
    "code_istat": "255",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "209",
    "name": "SLOVENIA",
    "id": "705",
    "alpha2": "SI",
    "alpha3": "SVN",
    "code_ita_bank": "260",
    "code_istat": "251",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "210",
    "name": "SOMALIA",
    "id": "706",
    "alpha2": "SO",
    "alpha3": "SOM",
    "code_ita_bank": "066",
    "code_istat": "453",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "211",
    "name": "SPAGNA",
    "id": "724",
    "alpha2": "ES",
    "alpha3": "ESP",
    "code_ita_bank": "067",
    "code_istat": "239",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "212",
    "name": "SRI LANKA",
    "id": "144",
    "alpha2": "LK",
    "alpha3": "LKA",
    "code_ita_bank": "085",
    "code_istat": "311",
    "fiscally_subsized": true,
    "not_collaborative": true
  },
  {
    "key": "213",
    "name": "STATI UNITI",
    "id": "840",
    "alpha2": "US",
    "alpha3": "USA",
    "code_ita_bank": "069",
    "code_istat": "536",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "214",
    "name": "SUDAFRICA",
    "id": "710",
    "alpha2": "ZA",
    "alpha3": "ZAF",
    "code_ita_bank": "078",
    "code_istat": "454",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "215",
    "name": "SUDAN",
    "id": "729",
    "alpha2": "SD",
    "alpha3": "SDN",
    "code_ita_bank": "070",
    "code_istat": "455",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "216",
    "name": "SUDAN DEL SUD",
    "id": "728",
    "alpha2": "SS",
    "alpha3": "SSD",
    "code_ita_bank": "297",
    "code_istat": "467",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "217",
    "name": "SURINAME",
    "id": "740",
    "alpha2": "SR",
    "alpha3": "SUR",
    "code_ita_bank": "124",
    "code_istat": "616",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "218",
    "name": "SVALBARD E JAN MAYEN",
    "id": "744",
    "alpha2": "SJ",
    "alpha3": "SJM",
    "code_ita_bank": "286",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "219",
    "name": "SVEZIA",
    "id": "752",
    "alpha2": "SE",
    "alpha3": "SWE",
    "code_ita_bank": "068",
    "code_istat": "240",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "220",
    "name": "SVIZZERA",
    "id": "756",
    "alpha2": "CH",
    "alpha3": "CHE",
    "code_ita_bank": "071",
    "code_istat": "241",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "221",
    "name": "ESWATINI",
    "id": "748",
    "alpha2": "SZ",
    "alpha3": "SWZ",
    "code_ita_bank": "138",
    "code_istat": "456",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "222",
    "name": "TAIWAN",
    "id": "158",
    "alpha2": "TW",
    "alpha3": "TWN",
    "code_ita_bank": "022",
    "code_istat": "363",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "223",
    "name": "TAGIKISTAN",
    "id": "762",
    "alpha2": "TJ",
    "alpha3": "TJK",
    "code_ita_bank": "272",
    "code_istat": "362",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "224",
    "name": "TANZANIA",
    "id": "834",
    "alpha2": "TZ",
    "alpha3": "TZA",
    "code_ita_bank": "057",
    "code_istat": "457",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "225",
    "name": "TERRE AUSTRALI E ANTARTICHE FRANCESI",
    "id": "260",
    "alpha2": "TF",
    "alpha3": "ATF",
    "code_ita_bank": "",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "226",
    "name": "TERRITORIO BRITANNICO DELL'OCEANO INDIANO",
    "id": "086",
    "alpha2": "IO",
    "alpha3": "IOT",
    "code_ita_bank": "245",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "227",
    "name": "THAILANDIA",
    "id": "764",
    "alpha2": "TH",
    "alpha3": "THA",
    "code_ita_bank": "072",
    "code_istat": "349",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "228",
    "name": "TIMOR EST",
    "id": "626",
    "alpha2": "TL",
    "alpha3": "TLS",
    "code_ita_bank": "287",
    "code_istat": "338",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "229",
    "name": "TOGO",
    "id": "768",
    "alpha2": "TG",
    "alpha3": "TGO",
    "code_ita_bank": "155",
    "code_istat": "458",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "230",
    "name": "TOKELAU",
    "id": "772",
    "alpha2": "TK",
    "alpha3": "TKL",
    "code_ita_bank": "236",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "231",
    "name": "TONGA",
    "id": "776",
    "alpha2": "TO",
    "alpha3": "TON",
    "code_ita_bank": "162",
    "code_istat": "730",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "232",
    "name": "TRINIDAD E TOBAGO",
    "id": "780",
    "alpha2": "TT",
    "alpha3": "TTO",
    "code_ita_bank": "120",
    "code_istat": "617",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "233",
    "name": "TUNISIA",
    "id": "788",
    "alpha2": "TN",
    "alpha3": "TUN",
    "code_ita_bank": "075",
    "code_istat": "460",
    "fiscally_subsized": true,
    "not_collaborative": true
  },
  {
    "key": "234",
    "name": "TURCHIA",
    "id": "792",
    "alpha2": "TR",
    "alpha3": "TUR",
    "code_ita_bank": "076",
    "code_istat": "351",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "235",
    "name": "TURKMENISTAN",
    "id": "795",
    "alpha2": "TM",
    "alpha3": "TKM",
    "code_ita_bank": "273",
    "code_istat": "364",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "236",
    "name": "TURKS E CAICOS",
    "id": "796",
    "alpha2": "TC",
    "alpha3": "TCA",
    "code_ita_bank": "210",
    "code_istat": "",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "237",
    "name": "TUVALU",
    "id": "798",
    "alpha2": "TV",
    "alpha3": "TUV",
    "code_ita_bank": "193",
    "code_istat": "731",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "238",
    "name": "UCRAINA",
    "id": "804",
    "alpha2": "UA",
    "alpha3": "UKR",
    "code_ita_bank": "263",
    "code_istat": "243",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "239",
    "name": "UGANDA",
    "id": "800",
    "alpha2": "UG",
    "alpha3": "UGA",
    "code_ita_bank": "132",
    "code_istat": "461",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "240",
    "name": "UNGHERIA",
    "id": "348",
    "alpha2": "HU",
    "alpha3": "HUN",
    "code_ita_bank": "077",
    "code_istat": "244",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "241",
    "name": "URUGUAY",
    "id": "858",
    "alpha2": "UY",
    "alpha3": "URY",
    "code_ita_bank": "080",
    "code_istat": "618",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "242",
    "name": "UZBEKISTAN",
    "id": "860",
    "alpha2": "UZ",
    "alpha3": "UZB",
    "code_ita_bank": "271",
    "code_istat": "357",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "243",
    "name": "VANUATU",
    "id": "548",
    "alpha2": "VU",
    "alpha3": "VUT",
    "code_ita_bank": "121",
    "code_istat": "732",
    "fiscally_subsized": true,
    "not_collaborative": true
  },
  {
    "key": "244",
    "name": "VENEZUELA",
    "id": "862",
    "alpha2": "VE",
    "alpha3": "VEN",
    "code_ita_bank": "081",
    "code_istat": "619",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "245",
    "name": "VIETNAM",
    "id": "704",
    "alpha2": "VN",
    "alpha3": "VNM",
    "code_ita_bank": "062",
    "code_istat": "353",
    "fiscally_subsized": true,
    "not_collaborative": false
  },
  {
    "key": "246",
    "name": "WALLIS E FUTUNA",
    "id": "876",
    "alpha2": "WF",
    "alpha3": "WLF",
    "code_ita_bank": "218",
    "code_istat": "",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "247",
    "name": "YEMEN",
    "id": "887",
    "alpha2": "YE",
    "alpha3": "YEM",
    "code_ita_bank": "042",
    "code_istat": "354",
    "fiscally_subsized": false,
    "not_collaborative": true
  },
  {
    "key": "248",
    "name": "ZAMBIA",
    "id": "894",
    "alpha2": "ZM",
    "alpha3": "ZMB",
    "code_ita_bank": "058",
    "code_istat": "464",
    "fiscally_subsized": false,
    "not_collaborative": false
  },
  {
    "key": "249",
    "name": "ZIMBABWE",
    "id": "716",
    "alpha2": "ZW",
    "alpha3": "ZWE",
    "code_ita_bank": "073",
    "code_istat": "465",
    "fiscally_subsized": true,
    "not_collaborative": false
  }
]