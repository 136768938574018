[
  {
    "key": "1",
    "ITA": "AGRIGENTO",
    "state_code": "AG",
    "region": "Sicilia"
  },
  {
    "key": "2",
    "ITA": "ALESSANDRIA",
    "state_code": "AL",
    "region": "Piemonte"
  },
  {
    "key": "3",
    "ITA": "ANCONA",
    "state_code": "AN",
    "region": "Marche"
  },
  {
    "key": "4",
    "ITA": "AOSTA",
    "state_code": "AO",
    "region": "Valle d'Aosta"
  },
  {
    "key": "5",
    "ITA": "AREZZO",
    "state_code": "AR",
    "region": "Toscana"
  },
  {
    "key": "6",
    "ITA": "ASCOLI PICENO",
    "state_code": "AP",
    "region": "Marche"
  },
  {
    "key": "7",
    "ITA": "ASTI",
    "state_code": "AT",
    "region": "Piemonte"
  },
  {
    "key": "8",
    "ITA": "AVELLINO",
    "state_code": "AV",
    "region": "Campania"
  },
  {
    "key": "9",
    "ITA": "BARI",
    "state_code": "BA",
    "region": "Puglia"
  },
  {
    "key": "10",
    "ITA": "BARLETTA-ANDRIA-TRANI",
    "state_code": "BT",
    "region": "Puglia"
  },
  {
    "key": "11",
    "ITA": "BELLUNO",
    "state_code": "BL",
    "region": "Veneto"
  },
  {
    "key": "12",
    "ITA": "BENEVENTO",
    "state_code": "BN",
    "region": "Campania"
  },
  {
    "key": "13",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "region": "Lombardia"
  },
  {
    "key": "14",
    "ITA": "BIELLA",
    "state_code": "BI",
    "region": "Piemonte"
  },
  {
    "key": "15",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "region": "Emilia-Romagna"
  },
  {
    "key": "16",
    "ITA": "BOLZANO",
    "state_code": "BZ",
    "region": "Trentino-Alto Adige"
  },
  {
    "key": "17",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "region": "Lombardia"
  },
  {
    "key": "18",
    "ITA": "BRINDISI",
    "state_code": "BR",
    "region": "Puglia"
  },
  {
    "key": "19",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "region": "Sardegna"
  },
  {
    "key": "20",
    "ITA": "CALTANISSETTA",
    "state_code": "CL",
    "region": "Sicilia"
  },
  {
    "key": "21",
    "ITA": "CAMPOBASSO",
    "state_code": "CB",
    "region": "Molise"
  },
  {
    "key": "23",
    "ITA": "CASERTA",
    "state_code": "CE",
    "region": "Campania"
  },
  {
    "key": "24",
    "ITA": "CATANIA",
    "state_code": "CT",
    "region": "Sicilia"
  },
  {
    "key": "25",
    "ITA": "CATANZARO",
    "state_code": "CZ",
    "region": "Calabria"
  },
  {
    "key": "26",
    "ITA": "CHIETI",
    "state_code": "CH",
    "region": "Abruzzo"
  },
  {
    "key": "27",
    "ITA": "COMO",
    "state_code": "CO",
    "region": "Lombardia"
  },
  {
    "key": "28",
    "ITA": "COSENZA",
    "state_code": "CS",
    "region": "Calabria"
  },
  {
    "key": "29",
    "ITA": "CREMONA",
    "state_code": "CR",
    "region": "Lombardia"
  },
  {
    "key": "30",
    "ITA": "CROTONE",
    "state_code": "KR",
    "region": "Calabria"
  },
  {
    "key": "31",
    "ITA": "CUNEO",
    "state_code": "CN",
    "region": "Piemonte"
  },
  {
    "key": "32",
    "ITA": "ENNA",
    "state_code": "EN",
    "region": "Sicilia"
  },
  {
    "key": "33",
    "ITA": "FERMO",
    "state_code": "FM",
    "region": "Marche"
  },
  {
    "key": "34",
    "ITA": "FERRARA",
    "state_code": "FE",
    "region": "Emilia-Romagna"
  },
  {
    "key": "35",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "region": "Toscana"
  },
  {
    "key": "36",
    "ITA": "FOGGIA",
    "state_code": "FG",
    "region": "Puglia"
  },
  {
    "key": "37",
    "ITA": "FORLÌ-CESENA",
    "state_code": "FC",
    "region": "Emilia-Romagna"
  },
  {
    "key": "38",
    "ITA": "FROSINONE",
    "state_code": "FR",
    "region": "Lazio"
  },
  {
    "key": "39",
    "ITA": "GENOVA",
    "state_code": "GE",
    "region": "Liguria"
  },
  {
    "key": "40",
    "ITA": "GORIZIA",
    "state_code": "GO",
    "region": "Friuli-Venezia Giulia"
  },
  {
    "key": "41",
    "ITA": "GROSSETO",
    "state_code": "GR",
    "region": "Toscana"
  },
  {
    "key": "42",
    "ITA": "IMPERIA",
    "state_code": "IM",
    "region": "Liguria"
  },
  {
    "key": "43",
    "ITA": "ISERNIA",
    "state_code": "IS",
    "region": "Molise"
  },
  {
    "key": "44",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "region": "Liguria"
  },
  {
    "key": "45",
    "ITA": "L'AQUILA",
    "state_code": "AQ",
    "region": "Abruzzo"
  },
  {
    "key": "46",
    "ITA": "LATINA",
    "state_code": "LT",
    "region": "Lazio"
  },
  {
    "key": "47",
    "ITA": "LECCE",
    "state_code": "LE",
    "region": "Puglia"
  },
  {
    "key": "48",
    "ITA": "LECCO",
    "state_code": "LC",
    "region": "Lombardia"
  },
  {
    "key": "49",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "region": "Toscana"
  },
  {
    "key": "50",
    "ITA": "LODI",
    "state_code": "LO",
    "region": "Lombardia"
  },
  {
    "key": "51",
    "ITA": "LUCCA",
    "state_code": "LU",
    "region": "Toscana"
  },
  {
    "key": "52",
    "ITA": "MACERATA",
    "state_code": "MC",
    "region": "Marche"
  },
  {
    "key": "53",
    "ITA": "MANTOVA",
    "state_code": "MN",
    "region": "Lombardia"
  },
  {
    "key": "54",
    "ITA": "MASSA-CARRARA",
    "state_code": "MS",
    "region": "Toscana"
  },
  {
    "key": "55",
    "ITA": "MATERA",
    "state_code": "MT",
    "region": "Basilicata"
  },
  {
    "key": "56",
    "ITA": "MESSINA",
    "state_code": "ME",
    "region": "Sicilia"
  },
  {
    "key": "57",
    "ITA": "MILANO",
    "state_code": "MI",
    "region": "Lombardia"
  },
  {
    "key": "58",
    "ITA": "MODENA",
    "state_code": "MO",
    "region": "Emilia-Romagna"
  },
  {
    "key": "59",
    "ITA": "MONZA E DELLA BRIANZA",
    "state_code": "MB",
    "region": "Lombardia"
  },
  {
    "key": "60",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "region": "Campania"
  },
  {
    "key": "61",
    "ITA": "NOVARA",
    "state_code": "NO",
    "region": "Piemonte"
  },
  {
    "key": "62",
    "ITA": "NUORO",
    "state_code": "NU",
    "region": "Sardegna"
  },
  {
    "key": "63",
    "ITA": "OLBIA-TEMPIO",
    "state_code": "OT",
    "region": "Sardegna"
  },
  {
    "key": "64",
    "ITA": "ORISTANO",
    "state_code": "OR",
    "region": "Sardegna"
  },
  {
    "key": "65",
    "ITA": "PADOVA",
    "state_code": "PD",
    "region": "Veneto"
  },
  {
    "key": "66",
    "ITA": "PALERMO",
    "state_code": "PA",
    "region": "Sicilia"
  },
  {
    "key": "67",
    "ITA": "PARMA",
    "state_code": "PR",
    "region": "Emilia-Romagna"
  },
  {
    "key": "68",
    "ITA": "PAVIA",
    "state_code": "PV",
    "region": "Lombardia"
  },
  {
    "key": "69",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "region": "Umbria"
  },
  {
    "key": "70",
    "ITA": "PESARO E URBINO",
    "state_code": "PU",
    "region": "Marche"
  },
  {
    "key": "71",
    "ITA": "PESCARA",
    "state_code": "PE",
    "region": "Abruzzo"
  },
  {
    "key": "72",
    "ITA": "PIACENZA",
    "state_code": "PC",
    "region": "Emilia-Romagna"
  },
  {
    "key": "73",
    "ITA": "PISA",
    "state_code": "PI",
    "region": "Toscana"
  },
  {
    "key": "74",
    "ITA": "PISTOIA",
    "state_code": "PT",
    "region": "Toscana"
  },
  {
    "key": "75",
    "ITA": "PORDENONE",
    "state_code": "PN",
    "region": "Friuli-Venezia Giulia"
  },
  {
    "key": "76",
    "ITA": "POTENZA",
    "state_code": "PZ",
    "region": "Basilicata"
  },
  {
    "key": "77",
    "ITA": "PRATO",
    "state_code": "PO",
    "region": "Toscana"
  },
  {
    "key": "78",
    "ITA": "RAGUSA",
    "state_code": "RG",
    "region": "Sicilia"
  },
  {
    "key": "79",
    "ITA": "RAVENNA",
    "state_code": "RA",
    "region": "Emilia-Romagna"
  },
  {
    "key": "80",
    "ITA": "REGGIO CALABRIA",
    "state_code": "RC",
    "region": "Calabria"
  },
  {
    "key": "81",
    "ITA": "REGGIO EMILIA",
    "state_code": "RE",
    "region": "Emilia-Romagna"
  },
  {
    "key": "82",
    "ITA": "RIETI",
    "state_code": "RI",
    "region": "Lazio"
  },
  {
    "key": "83",
    "ITA": "RIMINI",
    "state_code": "RN",
    "region": "Emilia-Romagna"
  },
  {
    "key": "84",
    "ITA": "ROMA",
    "state_code": "RM",
    "region": "Lazio"
  },
  {
    "key": "85",
    "ITA": "ROVIGO",
    "state_code": "RO",
    "region": "Veneto"
  },
  {
    "key": "86",
    "ITA": "SALERNO",
    "state_code": "SA",
    "region": "Campania"
  },
  {
    "key": "87",
    "ITA": "SUD SARDEGNA",
    "state_code": "SU",
    "region": "Sardegna"
  },
  {
    "key": "88",
    "ITA": "SASSARI",
    "state_code": "SS",
    "region": "Sardegna"
  },
  {
    "key": "89",
    "ITA": "SAVONA",
    "state_code": "SV",
    "region": "Liguria"
  },
  {
    "key": "90",
    "ITA": "SIENA",
    "state_code": "SI",
    "region": "Toscana"
  },
  {
    "key": "91",
    "ITA": "SIRACUSA",
    "state_code": "SR",
    "region": "Sicilia"
  },
  {
    "key": "92",
    "ITA": "SONDRIO",
    "state_code": "SO",
    "region": "Lombardia"
  },
  {
    "key": "93",
    "ITA": "TARANTO",
    "state_code": "TA",
    "region": "Puglia"
  },
  {
    "key": "94",
    "ITA": "TERAMO",
    "state_code": "TE",
    "region": "Abruzzo"
  },
  {
    "key": "95",
    "ITA": "TERNI",
    "state_code": "TR",
    "region": "Umbria"
  },
  {
    "key": "96",
    "ITA": "TORINO",
    "state_code": "TO",
    "region": "Piemonte"
  },
  {
    "key": "97",
    "ITA": "OGLIASTRA",
    "state_code": "OG",
    "region": "Sardegna"
  },
  {
    "key": "98",
    "ITA": "TRAPANI",
    "state_code": "TP",
    "region": "Sicilia"
  },
  {
    "key": "99",
    "ITA": "TRENTO",
    "state_code": "TN",
    "region": "Trentino-Alto Adige"
  },
  {
    "key": "100",
    "ITA": "TREVISO",
    "state_code": "TV",
    "region": "Veneto"
  },
  {
    "key": "101",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "region": "Friuli-Venezia Giulia"
  },
  {
    "key": "102",
    "ITA": "UDINE",
    "state_code": "UD",
    "region": "Friuli-Venezia Giulia"
  },
  {
    "key": "103",
    "ITA": "VARESE",
    "state_code": "VA",
    "region": "Lombardia"
  },
  {
    "key": "104",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "region": "Veneto"
  },
  {
    "key": "105",
    "ITA": "VERBANO-CUSIO-OSSOLA",
    "state_code": "VB",
    "region": "Piemonte"
  },
  {
    "key": "106",
    "ITA": "VERCELLI",
    "state_code": "VC",
    "region": "Piemonte"
  },
  {
    "key": "107",
    "ITA": "VERONA",
    "state_code": "VR",
    "region": "Veneto"
  },
  {
    "key": "108",
    "ITA": "VIBO VALENTIA",
    "state_code": "VV",
    "region": "Calabria"
  },
  {
    "key": "109",
    "ITA": "VICENZA",
    "state_code": "VI",
    "region": "Veneto"
  },
  {
    "key": "110",
    "ITA": "VITERBO",
    "state_code": "VT",
    "region": "Lazio"
  },
  {
    "key": "111",
    "ITA": "ESTERO",
    "state_code": "EE",
    "region": "Estero"
  }
]