import { DownOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { Select } from 'antd'
import React, { useContext } from 'react'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'

const log = new Logger('NormalMultipleSelect')

const NormalMultipleSelect = ({
  options,
  disabled = false,
  onChange = undefined,
  onSelect = undefined,
  onDeselect = undefined,
  defaultValue = undefined,
}) => {
  const { mode } = useContext(GlobalContext)

  return (
    <Select
      mode={'multiple'}
      options={options}
      optionFilterProp={'label'}
      showSearch
      onChange={onChange}
      onSelect={onSelect}
      onDeselect={onDeselect}
      suffixIcon={<DownOutlined />}
      showArrow
      style={{ width: '100%' }}
      disabled={_.defaultTo(disabled, mode === 'detail')}
      defaultValue={defaultValue}
    />
  )
}

export default NormalMultipleSelect
