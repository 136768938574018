import React, { useContext } from 'react'
import { Table as AntdTable } from 'antd'
import { Logger } from 'aws-amplify'
import { useIntl } from 'react-intl'
import { addPropertyToColumns } from '../../../utils'
import { Display, DisplayPrice } from '../../.././Components/Display'
import { InterventionContext } from '../contexts'
import _ from 'lodash'

const log = new Logger('Intervention/InterventionMaterialsTable')

const InterventionMaterialsTable = ({ dataSource }) => {
  // inject Intl instance
  const intl = useIntl()

  // context
  const { materials } = useContext(InterventionContext)

  // get measure unit by material Id
  const getMeasureUnit = (materialId) =>
    _.get(_.find(materials, { id: materialId }), 'measure_unit', null)

  const getColumns = () => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.intervention_materials.attributes.name',
        }),
        dataIndex: 'name',
        render: (_, record) => <Display value={record.name} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.intervention_materials.attributes.quantity',
        }),
        dataIndex: 'quantity',
        render: (_, record) => <Display value={record.quantity} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.intervention_materials.attributes.unit_price',
        }),
        dataIndex: 'unit_price',
        render: (a, record) => (
          <DisplayPrice
            value={record.unit_price}
            suffix={`/${getMeasureUnit(
              _.defaultTo(_.get(record, 'material_id'), _.get(record, 'id'))
            )}`}
          />
        ),
      },
    ]

    log.debug('Adding responsive property to columns ...')
    return addPropertyToColumns(columns, 'responsive', ['md'])
  }

  const columns = getColumns()

  log.info('Materials data source :', dataSource)

  return (
    <>
      <AntdTable dataSource={dataSource} rowKey={'id'} columns={columns} />
    </>
  )
}

export default InterventionMaterialsTable
