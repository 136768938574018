import React from 'react'

import { Col, Row } from 'antd'
import { Logger } from 'aws-amplify'
import styled from 'styled-components'

import Display from './Display'

const log = new Logger('DisplayDetail')

const StyledDetailLabel = styled.span`
  color: rgba(0, 0, 0, 0.6);
`

const Detail = ({
  label,
  value,
  flexDirection = 'column',
  suffix = undefined,
  ...otherProps
}) => {
  return (
    <>
      <Row style={{ flexDirection }}>
        <Col {...otherProps}>
          <StyledDetailLabel>
            <Display value={label} />
            {flexDirection === 'row' && ':'}
          </StyledDetailLabel>
        </Col>
        <Col style={{ marginBottom: '8px' }} {...otherProps}>
          <Display value={value} suffix={suffix} />
        </Col>
      </Row>
    </>
  )
}

export default Detail
