import React, { useContext } from 'react'
import { Card, Input, Tabs } from 'antd'
import { Logger } from 'aws-amplify'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { FacilitiesForm, RegistryForm } from '../form'
import { DocumentsTable } from '../form/document/DocumentsTable'
import { CustomerContext } from '../contexts'

const log = new Logger('Customers')

const CustomerForm = (props) => {
  // inject intl
  const intl = useIntl()

  const { initialValues, loading, legal_forms } = props

  // get current phase, if unset then it's on registry phase
  const phase = _.get(props, 'phase', 'registry')


  log.debug('Marshalled customer', initialValues)

  const { readonly } = useContext(CustomerContext)

  log.debug('Readonly: ', readonly)

  // Tabs items
  const items = [
    {
      key: 'registry',
      label: intl.formatMessage({ id: 'ui.customers.phases.registry' }),
      children: (
        <RegistryForm
          initialValues={initialValues}
          legalForms={legal_forms}
          loading={loading}
        />
      ),
      // forceRender: true,
      // disabled
    },
    {
      key: 'facilities',
      label: intl.formatMessage({ id: 'ui.customers.phases.facilities' }),
      children: <FacilitiesForm {...props} loading={loading} />,
      // forceRender: true,
      disabled: !(
        phase === 'facilities' ||
        phase === 'documents' ||
        phase === 'completed'
      ),
    },
    {
      key: 'documents',
      label: intl.formatMessage({ id: 'ui.customers.phases.documents' }),
      children: (
        <DocumentsTable
          dataSource={_.get(initialValues, 'documents', [])}
          prefix={`documents_attributes`}
        />
      ),
      // forceRender: true,
      disabled: !(phase === 'documents' || phase === 'completed'),
    },
  ]

  return (
    <Card>
      {phase !== 'registry' && (
        <Input name={'_method'} type={'hidden'} value={'patch'} />
      )}
      <Tabs items={items} defaultActiveKey={phase ?? 'registry'} />

    </Card>
  )
}

export default CustomerForm
