import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { Input } from 'antd'
import { Logger } from 'aws-amplify'

import { SearchBox } from '../../../Components'
// Context
import { CustomersContext } from '../contexts'

const CustomerSearch = ({ onSearch }) => {
  const { query, setQuery } = useContext(CustomersContext)

  return (
    <SearchBox
      onSearch={(query) => {
        setQuery(query)
        onSearch(query)
      }}
      defaultValue={query}
      placeholder={`ui.customers.placeholders.search`}
    />
  )
}
export default CustomerSearch
