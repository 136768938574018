import { useContext, useEffect, useState } from 'react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { getNewOptions, prepareFilters } from '@utils/helpers'
import { Col } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { InputField } from '@components/Fields'
import { SingleSelect } from '@components/Select'
import { GutteredRow } from '@components/form-components'

import { FACILITY_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'
import { GlobalContext } from '@/contexts'

import { DeviceContext } from '../contexts'

const log = new Logger('Devices/FormFields')

const FormFields = () => {
  const intl = useIntl()

  const [facilities, setFacilities] = useState([])
  const [facility, setFacility] = useState(null)

  // fetch facilities on mount
  useEffect(() => {
    searchFacility()
  }, [])

  const searchFacility = (query) => {
    makeApiRequest(FACILITY_SEARCH, 'post', {
      body: {
        query,
        serializer: 'strict',
        filters: prepareFilters({ status: ['active'] }),
      },
      queryParams: F.marshalPaginationTo({ ...DEFAULT_PAGER, pageSize: 9999 }),
    })
      .then(({ data }) => setFacilities(data))
      .catch(log.error)
  }

  const fetch = _.debounce(searchFacility, 500)

  return (
    <>
      <GutteredRow>
        <Col xl={6}>
          <SingleSelect
            name={'facility_id'}
            label={'ui.devices.form.labels.facility'}
            required
            options={getNewOptions(['business_name', 'id'])(facilities)}
            onSearch={fetch}
            defaultValue={R.propOr(null, 'id', facility)}
          />
        </Col>
      </GutteredRow>
      <GutteredRow>
        <Col span={4}>
          {/* Serial Number */}
          <InputField
            label={
              <FormattedMessage id='ui.devices.form.labels.serial_number' />
            }
            required
            name={'serial_number'}
          />
        </Col>
      </GutteredRow>
    </>
  )
}

export default FormFields
