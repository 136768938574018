import _ from 'lodash'
import dayjs from 'dayjs'
import { Logger } from 'aws-amplify'
import axios from 'axios'
import { message } from 'antd'
import JSZip from 'jszip'
import { S3_DOCS_BUCKET } from '../config'

import { saveAs } from 'file-saver'
import * as s3 from '../lib'
import * as R from 'ramda'

const log = new Logger('Helpers')

export const simetOrigin = {
  lat: _.toNumber('46.09582'),
  lng: _.toNumber('13.215608'),
}
export const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const logLevel = (currentEnvironment) => {
  switch (currentEnvironment) {
    case 'staging':
      return 'WARN'
    case 'production':
      return 'ERROR'
    default:
      return 'VERBOSE'
  }
}

export const addAlignToColumns = (columns, alignType) => {
  for (const column of columns) {
    column.align = alignType
  }
  return columns
}

export const addPropertyToColumns = (columns, property, value) => {
  for (const column of columns) {
    column[property] = value
  }
  return columns
}

// build single select options
/*
 expected :
 options : [
 {
  label : ..,
  value: ...
  }
 ]
 */

export const buildCountryOptions = (collection, labelKey, valueKey) => {
  if (_.isArray(collection)) {
    return _.chain(collection)
      .map((value, index) => {
        return {
          key: index,
          label: _.startCase(_.toLower(value[labelKey])),
          value: value[valueKey],
        }
      })
      .value()
  }
}

export const buildStateOptions = (
  collection,
  labelKey,
  valueKey,
  selectedCountry = 'ITA'
) => {
  if (_.isArray(collection)) {
    if (selectedCountry != 'ITA') {
      const abroadState = _.find(collection, (v, index) => v[valueKey] == 'EE')

      return [
        {
          key: 0,
          label: _.startCase(_.toLower(abroadState[labelKey])),
          value: abroadState[valueKey],
        },
      ]
    }
    return _.chain(collection)
      .uniqBy(selectedCountry)
      .map((value, index) => {
        return {
          key: index,
          label: _.startCase(_.toLower(value[labelKey])),
          value: value[valueKey],
        }
      })
      .value()
  }
}

export const buildCityOptions = (
  collection,
  labelKey,
  valueKey,
  selectedState = null,
  fraction = false
) => {
  if (_.isArray(collection)) {
    if (fraction) collection = splitByFraction(collection)
    return _.chain(collection)
      .uniqBy('ITA')
      .filter((o) => selectedState == _.get(o, 'state_code'))
      .map((value, index) => {
        return {
          key: index,
          label: _.startCase(_.toLower(value[labelKey])),
          value: value[valueKey],
        }
      })
      .value()
  }
}

const splitByFraction = (collection) => {
  if (_.isArray(collection)) {
    return _.chain(collection)
      .tap((values) => {
        _.map(values, (v) => (v['ITA'] = _.split(v['ITA'], '-', 1)[0]))
      })
      .value()
  }
}

export const buildPostalCodeOptions = (
  collection,
  labelKey,
  valueKey,
  selectedCity = null
) => {
  if (_.isArray(collection)) {
    return _.chain(collection)
      .filter((o) => selectedCity == _.get(o, 'ITA'))
      .uniqBy(valueKey)
      .map((value, index) => {
        return {
          key: index,
          label: _.startCase(_.toLower(value[labelKey])),
          value: value[valueKey],
        }
      })
      .value()
  }
}

// get S3 file key by storage url
export const getKeyByStorageUrl = (storageUrl, bucketName) => {
  log.debug('getKeyByStorageUrl PRE ', { storageUrl, bucketName })
  const key = _.chain(storageUrl)
    .invoke('split', `s3://${bucketName}/`)
    .value()[1]
  log.debug('getKeyByStorageUrl POST ', key)
  return key
}

export const getFileNameByKey = (key) => {
  return _.last(key.split('/'))
}

// format date
export const formatDate = (dateString, format) => {
  log.debug('Formatting date with format', format)
  return dayjs(dateString).format(format)
}

export const download = async (fileUrl, fileName) => {
  const response = await axios.get(fileUrl, {
    responseType: 'blob',
  })

  log.debug('response', response)
  const blob = response.data
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = fileName || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()

  a.remove()
}

export const zipDownload = async (urls, folderName) => {
  log.info('zipDownload.urls', { urls })

  const fileUrls = await Promise.all(
    _.map(urls, async (url) => {
      const fileKey = getKeyByStorageUrl(url, S3_DOCS_BUCKET)
      return await s3.getPresignedUrl(fileKey)
    })
  )

  log.info('zipDownload.fileUrls', { fileUrls })

  try {
    if (_.isEmpty(fileUrls)) {
      message.error('Impossibile effettuare il download. File non trovato')
    } else {
      const zip = new JSZip()
      const documents = zip.folder(`${folderName}`)

      const list = fileUrls.map(async (documentUrl, index) => {
        const response = await axios.get(documentUrl, {
          responseType: 'blob',
        })

        const data = response.data
        const fileName = getFileNameByKey(
          getKeyByStorageUrl(urls[index], S3_DOCS_BUCKET)
        )

        log.info('zipDownload', { data, response, fileName })
        documents.file(`${fileName}`, data, { binary: true })
        return data
      })

      Promise.all(list).then(function () {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${folderName}`)
        })
      })

      message.success('Download dei Documenti in corso...')
    }
  } catch (error) {
    message.error('Errore nel download del file')
    log.error(error)
  }
}

export const fetchConstant = (key, constants) => _.get(constants, key)

// marshal sorter params
export const marshalSorterParams = (sorter) => {
  const order = _.pick(sorter, 'field', 'order')

  switch (_.get(order, 'order')) {
    case 'ascend':
      return [
        {
          field: _.get(order, 'field'),
          value: 'asc',
        },
      ]
    case 'descend':
      return [
        {
          field: _.get(order, 'field'),
          value: 'desc',
        },
      ]
  }
}

/**
 * @param {Array} props
 * @property {string} props.labelProp
 * @property {string} props.valueProp
 * @return {(options:Array) => Array<SelectOption> } a function that applies map on a list of options
 * */
export const getNewOptions =
  ([labelProp, valueProp]) =>
  /**
   *
   * @param {Array} options
   * @return {Array.<SelectOption>}
   */
  (options) =>
    _.map(options, (el) => {
      return {
        label: _.get(el, labelProp, el),
        value: _.get(el, valueProp, el),
      }
    })

/**
 *
 * @param args
 * @return {Array<Filter>}
 */
export const prepareFilters = (...args) =>
  _.reduce(
    args,
    (acc, filter) => {
      const newFilter = {
        field: _.get(_.keys(filter), '0'),
        values: _.flatten(_.compact(_.values(filter))),
      }
      return [...acc, { ...newFilter }]
    },
    []
  )

export const marshalSingleSelect = (value) => (_.isEmpty(value) ? [] : [value])