import React, { useContext, useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import { StyledDetailLabel, StyledDivider } from '../../../../styled'
import { FormattedMessage, useIntl } from 'react-intl'
import { GutteredRow } from '../../../.././Components'
import { Alert, Col, Form, Input } from 'antd'
import { statuses, yearlyInterventions } from '../../../../enums'
import _ from 'lodash'
import FieldCurrency from '../../../.././Components/Fields/FieldCurrency'
import { calculateFee } from '../../../../utils'
import dayjs from 'dayjs'
import { GlobalContext } from '../../../../contexts'
import {
  DatePickerField,
  FieldInputNumber,
  InputField,
  TextAreaField,
} from '../../../../Components/Fields'
import { LocalSelect } from '../../../../Components/Select'

const log = new Logger('SubscriptionForm')

export const SubscriptionForm = ({
  initialValues,
  sectionTitle,
  index,
  prefix,
  fee,
  setFee,
}) => {
  const intl = useIntl()

  const form = Form.useFormInstance()

  const { mode } = useContext(GlobalContext)

  log.debug('Current form values', form.getFieldsValue(true))
  log.debug('Initial values', initialValues)
  log.verbose('Subscription statuses enum', statuses)

  const basePath = _.toPath(`facilities.${index}`)
  const subscriptionPath = [...basePath, 'subscription']

  // set true if start date is selected
  const [required, setRequired] = useState(
    !_.isNil(_.get(initialValues, 'start_date'))
  )
  const [destroy, setDestroy] = useState(false)

  const feeFields = [
    'rope_bell_fee',
    'electrified_bell_fee',
    'fixed_fee',
    'equipment_vehicles_charge',
    'sim_data',
    'yearly_interventions',
  ]

  const facilityFeeFields = ['rope_bells_count', 'electrified_bells_count']

  // get fee fields
  const getFeeFields = () => {
    const facilityFields = _.reduce(
      facilityFeeFields,
      (fields, name) => {
        fields[name] = form.getFieldValue([...basePath, name])
        return fields
      },
      {}
    )

    const subscriptionFields = _.reduce(
      feeFields,
      (fields, name) => {
        fields[name] = form.getFieldValue([...subscriptionPath, name])
        return fields
      },
      {}
    )

    return {
      ...facilityFields,
      ...subscriptionFields,
    }
  }

  const onChange = () => {
    log.debug({ fields: getFeeFields(), feeFields })
    const calculatedFee = calculateFee(getFeeFields())
    log.debug('Calculated Fee', calculatedFee)
    setFee(_.defaultTo(calculatedFee, 0))
  }

  const handleStartDateChange = (d) => {
    log.debug(`Start date change`, { d, subscriptionPath })
    if (!d) {
      let values = _.get(
        form.getFieldsValue([subscriptionPath]),
        `facilities[${index}].subscription`,
        {}
      )

      log.debug('resetting fields values...', { values })
      _.forIn(_.omit(values, 'id'), (v, k) => {
        form.setFieldValue([...subscriptionPath, k], null)
      })
    }
    setDestroy(true)
    setRequired(dayjs.isDayjs(d))
  }

  // do not destroy record if required is true
  useEffect(() => {
    if (destroy && required) setDestroy(false)
  }, [required])

  log.debug('Subscription id, required, destroy', {
    id: initialValues?.id,
    required,
    destroy,
  })

  return (
    <>
      <StyledDivider orientation={'left'}>
        <FormattedMessage id={sectionTitle} />
      </StyledDivider>

      <GutteredRow>
        <Col xl={4}>
          {/*  Reference Code */}
          <InputField
            name={`${index}.subscription.reference_code`}
            railsName={required ? `${prefix}[reference_code]` : undefined}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.reference_code',
            })}
          />
        </Col>
        <Col xl={4}>
          {/* Start Date */}
          <DatePickerField
            name={`${index}.subscription.start_date`}
            railsName={required ? `${prefix}[start_date]` : undefined}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.start_date',
            })}
            required={required}
            initialValue={_.invoke(
              _.get(initialValues, 'start_date'),
              'format',
              'YYYY-MM-DD'
            )}
            onChange={handleStartDateChange}
          />
        </Col>
        <Col xl={4}>
          {/* End Date */}
          <DatePickerField
            name={`${index}.subscription.end_date`}
            railsName={required ? `${prefix}[end_date]` : undefined}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.end_date',
            })}
            initialValue={_.invoke(
              _.get(initialValues, 'end_date'),
              'format',
              'YYYY-MM-DD'
            )}
          />
        </Col>

        <Col xl={4}>
          {/* Coverage Minutes */}
          <FieldInputNumber
            label={
              <FormattedMessage
                id={`ui.subscriptions.attributes.coverage_minutes`}
              />
            }
            initialValue={_.get(initialValues, 'coverage_minutes')}
            name={`${index}.subscription.coverage_minutes`}
            railsName={required ? `${prefix}[coverage_minutes]` : undefined}
          />
        </Col>

        <Col md={4}>
          {/* Status */}
          <LocalSelect
            name={`${index}.subscription.status`}
            options={statuses}
            label={'ui.subscriptions.attributes.status'}
            localAttribute={'ui.subscriptions.enums.statuses'}
            railsName={required ? `${prefix}[status]` : undefined}
            defaultValue={_.get(initialValues, 'status', '')}
            required={required}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        <Col xl={3}>
          {/* Fixed Fee */}
          <FieldCurrency
            name={`${index}.subscription.fixed_fee`}
            railsName={required ? `${prefix}[fixed_fee]` : undefined}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.fixed_fee',
            })}
            required={required}
            onChange={onChange}
            initialValue={_.get(initialValues, 'fixed_fee')}
          />
        </Col>
        <Col xl={4}>
          {/* Single Rope Bell Fee */}
          <FieldCurrency
            name={`${index}.subscription.rope_bell_fee`}
            railsName={required ? `${prefix}[rope_bell_fee]` : undefined}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.rope_bell_fee',
            })}
            required={required}
            onChange={onChange}
            initialValue={_.get(initialValues, 'rope_bell_fee', 25)}
          />
        </Col>
        <Col xl={4}>
          {/* Single Electrified Bell Fee */}
          <FieldCurrency
            name={`${index}.subscription.electrified_bell_fee`}
            railsName={required ? `${prefix}[electrified_bell_fee]` : undefined}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.electrified_bell_fee',
            })}
            required={required}
            onChange={onChange}
            initialValue={_.get(initialValues, 'electrified_bell_fee', 20)}
          />
        </Col>
        <Col xl={4}>
          {/* Equipment and vehicles charge */}
          <FieldCurrency
            name={`${index}.subscription.equipment_vehicles_charge`}
            railsName={
              required ? `${prefix}[equipment_vehicles_charge]` : undefined
            }
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.equipment_vehicles_charge',
            })}
            required={required}
            onChange={onChange}
            initialValue={_.get(initialValues, 'equipment_vehicles_charge')}
          />
        </Col>
        <Col xl={3}>
          {/* Sim Data */}
          <FieldCurrency
            name={`${index}.subscription.sim_data`}
            railsName={required ? `${prefix}[sim_data]` : undefined}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.sim_data',
            })}
            required={required}
            initialValue={_.get(initialValues, 'sim_data')}
            onChange={onChange}
          />
        </Col>
        <Col xl={3}>
          {/* Yearly Interventions  */}
          <LocalSelect
            name={`${index}.subscription.yearly_interventions`}
            options={yearlyInterventions}
            label={'ui.subscriptions.attributes.yearly_interventions'}
            localAttribute={'ui.subscriptions.enums.yearly_interventions'}
            railsName={required ? `${prefix}[yearly_interventions]` : undefined}
            defaultValue={_.get(initialValues, 'yearly_interventions', null)}
            required={required}
            onChange={onChange}
          />
        </Col>
        <Col xl={2}>
          {/* Fee */}
          <div>
            <StyledDetailLabel>
              {intl.formatMessage({
                id: 'ui.subscriptions.attributes.fee',
              })}
            </StyledDetailLabel>
            <p>€ &nbsp; {fee} </p>
          </div>
        </Col>
      </GutteredRow>

      <GutteredRow>
        <Col xl={8} md={8}>
          {/* Notes */}
          <TextAreaField
            name={`${index}.subscription.notes`}
            label={intl.formatMessage({
              id: 'ui.subscriptions.attributes.notes',
            })}
            railsName={required ? `${prefix}[notes]` : undefined}
          />
        </Col>
      </GutteredRow>

      {/* Show Warning */}
      {mode === 'edit' && (
        <GutteredRow>
          <Col span={16}>
            <Alert
              type={'warning'}
              message={<FormattedMessage id={'ui.subscriptions.warning'} />}
              closable
            />
          </Col>
        </GutteredRow>
      )}

      {/*  Hidden ID field */}
      {initialValues?.id && (
        <InputField
          name={`${index}.subscription.id`}
          railsName={`${prefix}[id]`}
          hidden
          // preserve={false}
        />
      )}

      {/*  Destroy subscription */}
      {destroy && (
        <Input name={`${prefix}[_destroy]`} type={'hidden'} value={1} />
      )}
    </>
  )
}
