import React from 'react'
import { Logger } from 'aws-amplify'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { formats } from '../../../enums'
import { Input } from 'antd'
import {FieldUpload} from "../../../Components/Fields";

const log = new Logger('CloseIntervention/DocumentSection')

const DocumentSection = ({
  prefix = 'document',
  document,
  setDocument,
  marshalDocument,
  fileList,
  setFileList,
}) => {
  const normFile = (e) => {
    log.debug('Event on uploader: ', e)
    return _.isArray(e) ? e : e?.fileList
  }

  const handleDraggerChange = ({ fileList: newFileList }) => {
    log.verbose('Handling dragger change', newFileList)
    setFileList(newFileList)
    const document = _.get(newFileList, '0', null)

    setDocument(marshalDocument(document))
  }

  log.debug({
    document,
    fileList,
  })

  return (
    <>
      <FieldUpload
        accept={_.map(formats, (format) => `.${format}`).toString()}
        beforeUpload={(f) => {
          setFileList([...fileList, f])
          return false
        }}
        normFile={normFile}
        maxCount={1}
        fileList={fileList}
        onChange={handleDraggerChange}
        disabled={false}
        label={
          <FormattedMessage
            id={`ui.interventions.close_form.document.upload_text`}
          />
        }
      />
      {/*Hidden Document*/}

      {!_.isEmpty(document) &&
        _.map(document, (value, key) => (
          <Input
            name={`${prefix}[${key}]`}
            type={'hidden'}
            value={value}
            key={key}
          />
        ))}
    </>
  )
}

export default DocumentSection
