import React, { useContext, useEffect, useState } from 'react'
import { Card, Input, Typography } from 'antd'
import { CenteredSpinner } from '../.././Components'
import { Logger } from 'aws-amplify'
import { CallContextProvider } from './contexts'
import { GlobalContext } from '../../contexts'
import { CallForm } from './form'
import FormFields from './form/FormFields'
import { marshalCall } from '../../utils/calls'
import { FormattedMessage } from 'react-intl'
import {InputField} from "../../Components/Fields";

const log = new Logger('Calls/Edit')

const Edit = (props) => {
  const [loading, setLoading] = useState(false)

  log.verbose('Current props: ', props)

  const [call, setCall] = useState(marshalCall(props.call))

  const { setMode } = useContext(GlobalContext)

  const { facilities, referents } = props

  log.debug('props, facilities, referents', { props, facilities, referents })
  useEffect(() => {
    setMode('edit')
  }, [])

  return (
    <CallContextProvider value={{ call, setCall }}>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <CallForm
          title={
            <Typography.Title level={2}>
              <FormattedMessage id={'ui.calls.actions.edit'} />
            </Typography.Title>
          }
          {...props}
          action={`/calls/${call.id}`}
        >
          <Card>
            {/* Hidden Call ID */}
            <InputField name={'id'} hidden />
            <Input type={'hidden'} name={'_method'} value={'patch'} />
            <FormFields facilities={facilities} referents={referents} />
          </Card>
        </CallForm>
      )}
    </CallContextProvider>
  )
}

export default Edit
