import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import * as F from '@utils/functions'
import { marshalSorterParams } from '@utils/index'
import { Table as AntdTable, Button, Space } from 'antd'
import { Auth, Logger } from 'aws-amplify'
import _, { head } from 'lodash'
import * as R from 'ramda'

import { Display, DisplayDateTime, LocaleDisplay } from '@components/Display'

import { INTERVENTION_SEARCH, makeApiRequest } from '@/api'
import { booleanFilters } from '@/enums'

import { GenericDeleteForm as DeleteInterventionForm } from '../../.././Components'
import { priorities } from '../../../enums/call'
import { statuses, typologies } from '../../../enums/intervention'
import { InterventionsContext } from '../contexts'
import { StatusTag } from './index'

const log = new Logger('InterventionsTable')

export const Table = ({ dataSource, searchInterventions }) => {
  // inject Intl instance
  const intl = useIntl()

  // first load ref
  const firstLoad = useRef(true)

  log.debug('Rendering interventions table ...')

  const {
    searchLoading,
    setSearchLoading,
    setInterventions,
    filters,
    setFilters,
    ranges,
    query,
    orders,
    setOrders,
    pagination,
    setPagination,
  } = useContext(InterventionsContext)

  // delete modal data
  const [deleteModalData, setDeleteModalData] = useState({ open: false })

  // handle table change
  const handleTableChange = (pager, newFilters, sorter) => {
    log.debug('handleTableChange.start', { pager, newFilters, sorter })

    const marshalledFilters = _.map(newFilters, (v, k) => {
      return {
        field: k,
        values: v || [],
      }
    })

    const orders = marshalSorterParams(sorter)
    log.debug('Marshalled filters', { marshalledFilters, orders })
    setFilters(_.unionBy(marshalledFilters, filters, 'field'))
    setOrders(orders)
    setPagination(pager)
  }

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    searchInterventions()
  }, [query, filters, ranges, orders])

  log.info('Interventions data source ... :', dataSource)

  const getColumns = (intl) => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.id',
        }),
        dataIndex: 'id',
        fixed: true,
        render: (_, record) => <Display value={record.id} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.facility',
        }),
        dataIndex: 'facility',
        render: (_, record) => <Display value={record.facility} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.typology',
        }),
        dataIndex: 'typology',
        render: (_, record) => (
          <LocaleDisplay
            localePath={`ui.interventions.enums.typologies`}
            value={record.typology}
          />
        ),
        defaultFilteredValue: F.getFilterValues('typology', filters),
        filters: _.map(typologies, (v) => {
          return {
            text: intl.formatMessage({
              id: `ui.interventions.enums.typologies.${v}`,
            }),
            value: v,
          }
        }),
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.expected_date',
        }),
        dataIndex: 'expected_date',
        render: (_, record) => (
          <DisplayDateTime value={record.expected_date} format={'DD/MM/YYYY'} />
        ),
        defaultSortOrder: F.orderToSort('expected_date', orders),
        sorter: true,
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.scheduled_datetime',
        }),
        dataIndex: 'scheduled_datetime',
        render: (_, record) => (
          <DisplayDateTime value={record.scheduled_datetime} />
        ),
        defaultSortOrder: F.orderToSort('scheduled_datetime', orders),
        sorter: true,
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.call.id',
        }),
        dataIndex: 'call_id',
        render: (a, record) => <Display value={_.get(record, 'call.id')} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.priority',
        }),
        dataIndex: 'priority',
        render: (_, record) => (
          <LocaleDisplay
            localePath={`ui.interventions.enums.priorities`}
            value={record.priority}
          />
        ),
        defaultFilteredValue: F.getFilterValues('priority', filters),
        filters: _.map(priorities, (v) => {
          return {
            text: intl.formatMessage({
              id: `ui.interventions.enums.priorities.${v}`,
            }),
            value: v,
          }
        }),
      },
      {
        title: intl.formatMessage({ id: 'ui.interventions.attributes.status' }),
        dataIndex: 'status',
        render: (_, record) => (
          <StatusTag status={record.status}>
            <LocaleDisplay
              localePath={`ui.interventions.enums.statuses`}
              value={record.status}
            />
          </StatusTag>
        ),
        defaultFilteredValue: F.getFilterValues('status', filters),
        filters: _.map(statuses, (v) => {
          return {
            text: intl.formatMessage({
              id: `ui.interventions.enums.statuses.${v}`,
            }),
            value: v,
          }
        }),
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.attributes.continuation',
        }),
        dataIndex: 'continuation',
        render: (value) => (
          <LocaleDisplay value={R.toString(value)} localePath={'ui'} />
        ),
        defaultFilteredValue: F.getFilterValues('continuation', filters),
        filters: R.map(
          ([key, value]) => ({
            text: intl.formatMessage({
              id: `ui.${key}`,
            }),
            value,
          }),
          R.toPairs(booleanFilters)
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.actions.title',
        }),
        key: 'action',
        fixed: 'right',
        render: (a, record) => (
          <>
            <Space>
              {/* DETAIL */}
              <Button
                icon={<EyeOutlined />}
                href={
                  record.call
                    ? `/calls/${record.call.id}/interventions/${record.id}`
                    : `/interventions/${record.id}`
                }
              />

              {/* EDIT */}
              {_.includes(
                ['in_progress', 'to_schedule'],
                _.get(record, 'status')
              ) && (
                <Button
                  icon={<EditOutlined />}
                  href={
                    record.call
                      ? `/calls/${record.call.id}/interventions/${record.id}/edit`
                      : `/interventions/${record.id}/edit`
                  }
                />
              )}

              {/*  DELETE */}
              {_.get(record, 'status') !== 'invoiced' && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setDeleteModalData({
                      open: true,
                      record,
                    })
                  }}
                />
              )}
            </Space>
          </>
        ),
      },
    ]

    return columns
  }

  const columns = useMemo(() => getColumns(intl), [])
  return (
    !firstLoad.current && (
      <>
        <AntdTable
          dataSource={dataSource}
          rowKey={'id'}
          columns={columns}
          pagination={{
            ...pagination,
            onChange: handlePaginationChange,
          }}
          loading={{
            spinning: searchLoading,
            indicator: (
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
                spin
              />
            ),
          }}
          scroll={{ x: true }}
          onChange={handleTableChange}
        />

        {/*  Modal Delete Intervention */}
        <DeleteInterventionForm
          deleteModalData={deleteModalData}
          name={'delete-intervention-form'}
          // keys={['']}
          onCancel={() => setDeleteModalData({ open: false })}
          action={`/interventions/${_.get(deleteModalData, 'record.id')}`}
          model={'interventions'}
        />
      </>
    )
  )
}

export default Table
