import _ from 'lodash'
import dayjs from 'dayjs'
import { Logger } from 'aws-amplify'

const log = new Logger('utils/invoices/marshal')

/**
 *
 * @param invoice {Invoice}
 * @return {Invoice}
 */
export const marshal = (invoice) => {
  return {
    ...invoice,
    motivations: _.defaultTo(invoice.motivations, []),
    intervention_typologies: _.defaultTo(invoice.intervention_typologies, []),
    interventions: _.get(invoice, 'interventions', []),
    invoice_date: _.isNil(invoice.invoice_date)
      ? null
      : dayjs(invoice.invoice_date),
  }
}

/**
 *
 * @param {Array<.InterventionExtended>} interventions
 * @returns {Array<.MaterialStrict>}
 */
export const marshalMaterialsFromInterventions = (interventions) => {
  const interventionMaterials = _.flatMap(
    interventions,
    'intervention_materials'
  )

  const materials = _.uniqBy(_.flatMap(interventions, 'materials'), 'id')

  log.info('marshalFromInterventions PRE', {
    interventionMaterials,
    materials,
  })

  const newMaterials = _.chain(interventionMaterials)
    .groupBy('material_id')
    .map((groupedMaterials) => {
      log.debug('beforeReduce', groupedMaterials)

      const aggreggatedMaterial = _.reduce(
        _.slice(groupedMaterials, 1),
        (acc, el) => {
          acc.quantity += el.quantity
          acc.unit_price += el.unit_price
          return acc
        },
        _.cloneDeep(groupedMaterials[0])
      )

      // find related material
      const material = _.find(materials, {
        id: aggreggatedMaterial.material_id,
      })

      const newMaterial = {
        ...aggreggatedMaterial,
        ..._.pick(material, [
          'code',
          'category',
          'measure_unit',
          'description',
        ]),
      }

      log.debug('afterReduce', newMaterial)

      return newMaterial
    })
    .value()

  log.info('marshalFromInterventions POST', newMaterials)

  return newMaterials
}
