import React from 'react'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { Logger } from 'aws-amplify'

const log = new Logger('LocaleDisplayArray')
const LocaleDisplayArray = ({
  array,
  localeAttribute,
  separator = ', ',
  nullText = '•',
}) => {
  const intl = useIntl()
  const formattedArray = _.map(array, (str) =>
    intl.formatMessage({ id: `${localeAttribute}.${str}` })
  )

  log.debug(formattedArray)
  return (
    <>{_.isEmpty(formattedArray) ? nullText : formattedArray.join(separator)}</>
  )
}

export default LocaleDisplayArray
