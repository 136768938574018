import React, { useContext } from 'react'

import { Logger } from 'aws-amplify'

import { SearchBox } from '../../.././Components'
import { CallsContext } from '../contexts'

const log = new Logger('CallSearch')

const CallSearch = ({ onSearch }) => {
  const { query, setQuery } = useContext(CallsContext)

  return (
    <SearchBox
      onSearch={(query) => {
        setQuery(query)
        onSearch(query)
      }}
      defaultValue={query}
      placeholder={`ui.calls.placeholders.search`}
    />
  )
}

export default CallSearch
