import React, { useContext } from 'react'
import { Logger } from 'aws-amplify'
import {
  Display,
  DisplayDateTime,
  LocaleDisplay,
} from '../../.././Components/Display'
import { StatusTag } from '../.././Interventions/components'
import { Button, Space, Table } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { CallContext } from '../contexts'
import { DisplayPrice } from '../../../Components/Display'
import _ from 'lodash'

const log = new Logger('Calls/components/InterventionsTable')

export const InterventionsTable = ({ dataSource }) => {
  const intl = useIntl()

  const { call } = useContext(CallContext)

  const columns = [
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.id',
      }),
      dataIndex: 'id',
      render: (_, record) => <Display value={record.id} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.typology',
      }),
      dataIndex: 'typology',
      render: (_, record) => (
        <LocaleDisplay
          localePath={`ui.interventions.enums.typologies`}
          value={record.typology}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.priority',
      }),
      dataIndex: 'priority',
      render: (_, record) => (
        <LocaleDisplay
          localePath={`ui.interventions.enums.priorities`}
          value={record.priority}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.expected_date',
      }),
      dataIndex: 'expected_date',
      render: (_, record) => (
        <DisplayDateTime value={record.expected_date} format={'DD/MM/YYYY'} />
      ),
    },
    {
      title: intl.formatMessage({ id: 'ui.interventions.attributes.status' }),
      dataIndex: 'status',
      render: (_, record) => (
        <StatusTag status={record.status}>
          <LocaleDisplay
            localePath={`ui.interventions.enums.statuses`}
            value={record.status}
          />
        </StatusTag>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.total_intervention',
      }),
      dataIndex: 'total_intervention',
      render: (x, record) => (
        <DisplayPrice value={_.get(record, 'total_intervention')} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.actions.title',
      }),
      key: 'action',
      render: (_, record) => (
        <>
          <Space>
            {/* DETAIL */}
            <Button
              icon={<EyeOutlined />}
              href={`/calls/${call.id}/interventions/${record.id}`}
            />
          </Space>
        </>
      ),
    },
  ]

  return <Table dataSource={dataSource} columns={columns} />
}
