// invoices context
import { createContext } from 'react'

export const InvoicesContext = createContext()
export const InvoicesContextProvider = InvoicesContext.Provider

export const InvoiceContext = createContext()
export const InvoiceContextProvider = InvoiceContext.Provider

export const InterventionsContext = createContext()
export const InterventionsContextProvider = InterventionsContext.Provider

export const MaterialsContext = createContext()
export const MaterialsContextProvider = MaterialsContext.Provider