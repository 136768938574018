import React, { useContext, useEffect, useState } from 'react'
import { CenteredSpinner } from '../.././Components'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'
import { InterventionContextProvider } from './contexts'
import { Card, Input, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { InterventionForm } from './form'
import FormFields from './form/FormFields'
import { marshalIntervention } from '../../utils'
import { StyledDivider } from '../../styled'
import {
  CloseInterventionModal,
  InterventionMaterialsTable,
} from './components'
import _ from 'lodash'
import AccountingDetail from './form/AccountingDetail'
import {InputField} from "../../Components/Fields";

const log = new Logger('InterventionDetail')

const Show = (props) => {
  const [loading, setLoading] = useState(false)

  log.verbose('Current props: ', props)

  const [intervention, setIntervention] = useState(
    marshalIntervention(props.intervention)
  )

  const [selectedMaterials, setSelectedMaterials] = useState(
    _.get(intervention, 'intervention_materials')
  )

  const [triggerCalculations, setTriggerCalculations] = useState(false)

  const [closeModalData, setCloseModalData] = useState({
    open: false,
  })

  const { setMode } = useContext(GlobalContext)

  const { facilities, call, materials } = props

  log.debug(
    'props, intervention, facilities, call, materials, selected materials',
    {
      props,
      intervention,
      facilities,
      call,
      materials,
      selectedMaterials,
    }
  )
  useEffect(() => {
    setMode('detail')
  }, [])

  return (
    <InterventionContextProvider
      value={{
        intervention,
        setIntervention,
        call,
        closeModalData,
        setCloseModalData,
        materials,
        selectedMaterials,
        setSelectedMaterials,
        triggerCalculations,
        setTriggerCalculations,
      }}
    >
      {loading ? (
        <CenteredSpinner />
      ) : (
        <>
          <InterventionForm
            title={
              <Typography.Title level={2}>
                <FormattedMessage id={'ui.interventions.detail'} />
              </Typography.Title>
            }
            {...props}
          >
            <Card>
              {/* Hidden Intervention ID */}
              <InputField name={'id'} hidden />
              <Input type={'hidden'} name={'_method'} value={'patch'} />
              <FormFields facilities={facilities} call={call} />
            </Card>

            {/*  Accounting Detail */}
            {!_.includes(
              ['to_schedule', 'in_progress'],
              _.get(intervention, 'status')
            ) && <AccountingDetail intervention={intervention} />}

            {/*  Materials Table */}
            {!_.isEmpty(_.get(intervention, 'intervention_materials')) && (
              <Card style={{ marginTop: '20px' }}>
                <StyledDivider orientation={'left'}>
                  <FormattedMessage
                    id={'ui.interventions.attributes.materials'}
                  />
                </StyledDivider>

                <InterventionMaterialsTable
                  dataSource={_.get(intervention, 'intervention_materials')}
                />
              </Card>
            )}
          </InterventionForm>

          {/*  Close Intervention Modal */}
          <CloseInterventionModal
            modalData={closeModalData}
            onCancel={() => {
              setCloseModalData({ open: false })
              // setSelectedMaterials([])
            }}
            initialValues={intervention}
            resetFields={
              !_.includes(
                ['terminated', 'invoiced'],
                _.get(intervention, 'status')
              )
            }
          />
        </>
      )}
    </InterventionContextProvider>
  )
}

export default Show
