import React, {useContext} from 'react'
import {
    GutteredRow,
} from '../../../.././Components'
import {Logger} from 'aws-amplify'
import {FormattedMessage, useIntl} from 'react-intl'
import {Col, Row} from 'antd'

import _ from 'lodash'
import {CustomerAddress} from '../components/CustomerAddress'
import {statuses} from '../../../../enums/customer'
import {GlobalContext} from '../../../../contexts'
import {StatusTag} from "../../components";
import {FieldEmail, FieldPhone, FieldVatNumber, InputField, TextAreaField} from "../../../../Components/Fields";
import {LocalSelect} from "../../../../Components/Select";
import {LocaleDisplay} from "../../../../Components/Display";

// initialize log
const log = new Logger('CustomerRegistry')

// Registry Form
export const RegistryForm = ({
  initialValues,
  legalForms,
  loading,
  readonly,
}) => {
  // inject intl
  const intl = useIntl()

  log.verbose('Initial values', initialValues)
  log.verbose('Legal Forms', legalForms)
  /*
                registered_office: {
                  address_type: "registered_office",
                  country: "ITA",
                },
                correspondence: {
                  address_type: "correspondence",
                  country: "ITA",
                },
               */

  const { mode } = useContext(GlobalContext)

  return (
    <>
      <GutteredRow>
        <Col xl={4}>
          {/* Business Name */}
          <InputField
            name={'business_name'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.business_name',
            })}
            type={'text'}
            required
          />
        </Col>

        <Col md={4}>
          {/* Legal Form */}
          <LocalSelect
            name={'legal_form'}
            label={'ui.customers.attributes.legal_form'}
            type={'text'}
            options={legalForms}
            localAttribute={'ui.customers.enums.legal_forms'}
            defaultValue={_.get(initialValues, 'legal_form', null)}
            required
          />
        </Col>

        <Col xl={4}>
          {/* TAX Code */}
          <InputField
            name={'tax_code'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.tax_code',
            })}
            type={'text'}
            required
          />
        </Col>

        <Col xl={4}>
          {/* VAT */}
          <FieldVatNumber
            name={'vat_number'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.vat_number',
            })}
          />
        </Col>

        {/*  Status */}
        {mode === 'edit' && (
          <Col xl={4}>
            <LocalSelect
              name={'status'}
              label={`ui.customers.attributes.status`}
              options={statuses}
              localAttribute={`ui.customers.enums.statuses`}
              defaultValue={_.get(initialValues, 'status', 'active')}
            />
          </Col>
        )}

        {mode === 'detail' && (
          <Col xl={4}>
            <Row style={{ flexDirection: 'column' }}>
              <Col style={{ paddingBottom: '8px', color: '#c8c5c5' }}>
                <FormattedMessage id={'ui.customers.attributes.status'} />
              </Col>
              <Col>
                <StatusTag status={_.get(initialValues, 'status')}>
                  <LocaleDisplay
                    localePath={'ui.customers.enums.statuses'}
                    value={_.get(initialValues, 'status')}
                  />
                </StatusTag>
              </Col>
            </Row>
          </Col>
        )}
      </GutteredRow>

      <GutteredRow>
        <Col xl={4}>
          {/* Email */}
          <FieldEmail
            name={'email'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.email',
            })}
          />
        </Col>
        <Col xl={4}>
          {/*  PEC mail */}
          <FieldEmail
            name={'certified_mail'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.certified_mail',
            })}
          />
        </Col>
        <Col xl={4}>
          {/* Telephone */}
          <FieldPhone
            name={'phone_1'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.phone_1',
            })}
          />
        </Col>
        <Col xl={4}>
          {/* Mobile */}
          <FieldPhone
            name={'phone_2'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.phone_2',
            })}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        <Col xl={8}>
          {/*  General notes */}
          <TextAreaField
            name={'general_notes'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.general_notes',
            })}
          />
        </Col>
        <Col xl={8}>
          {/* Invoice notes */}
          <TextAreaField
            name={'invoice_notes'}
            label={intl.formatMessage({
              id: 'ui.customers.attributes.invoice_notes',
            })}
          />
        </Col>
      </GutteredRow>

      {/*  Legal Address Section */}
      <CustomerAddress
        sectionTitle={'ui.customers.attributes.addresses.registered_office'}
        prefix={'registered_office'}
        railsNamePrefix={'addresses_attributes'}
        index={0}
        initialValues={_.get(initialValues, `registered_office`, null)}
        required
        loading={loading}
      />

      {/*  Correspondence Address Section */}
      <CustomerAddress
        sectionTitle={'ui.customers.attributes.addresses.correspondence'}
        prefix={'correspondence'}
        railsNamePrefix={'addresses_attributes'}
        index={1}
        initialValues={_.get(initialValues, `correspondence`, null)}
        loading={loading}
      />
    </>
  )

  /*
                Registry form inputs:
                Business_name: text
                internal_code: text
                legal_form: dropdown
                tax_code: text
                vat_number: text
                email: email
                pec: email
                phone_1 (alias: mobile): phonenumber / text?
                phone_2 (alias: cellular): phonenumber / text?
                general_notes: text / textarea
                invoice_notes: text / textarea
            
               */
}
