import React, { useContext, useEffect } from 'react'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import { GutteredRow } from '../../.././Components'
import { NormalMultipleSelect as MultipleSelect } from '../../.././Components/Select'

import { Col, Form } from 'antd'
import { MaterialsTable } from './index'
import { FormattedMessage } from 'react-intl'
import { InterventionContext } from '../contexts'

const log = new Logger('MaterialsSection')

const MaterialsSection = ({
  materials,
  disabled,
  setTotalMaterial,
  calcFunction,
}) => {
  const { selectedMaterials, setSelectedMaterials } =
    useContext(InterventionContext)

  log.info('Selected materials: ', selectedMaterials)

  const form = Form.useFormInstance()

  log.info({ formValues: form.getFieldsValue(true) })
  const handleMaterialsSelect = (selectedId, option) => {
    const selectedMaterial = _.find(materials, { id: selectedId })

    log.debug({ selectedId, option, selectedMaterial })

    setSelectedMaterials([...selectedMaterials, selectedMaterial])
  }

  // handle material deselect
  const handleMaterialsDeselect = (deselectedId) => {
    const newMaterials = _.reject(selectedMaterials, (m) => {
      return (
        _.get(m, 'id') === deselectedId ||
        _.get(m, 'material_id') === deselectedId
      )
    })

    log.debug({ deselectedId, newMaterials })
    setSelectedMaterials(newMaterials)
  }

  useEffect(() => {
    form.setFieldValue('materials', selectedMaterials)
    setTotalMaterial(_.sum(_.map(selectedMaterials, 'total')))
  }, [selectedMaterials])
  return (
    <>
      <GutteredRow
        style={{
          marginBottom: '20px',
        }}
      >
        <Col xl={24}>
          <FormattedMessage id={'ui.materials.list_name'} />
        </Col>
        <Col xl={24}>
          <MultipleSelect
            options={_.map(materials, (material, key) => {
              return {
                key,
                label: `${_.get(material, 'code')} - ${_.get(material, 'name')}`,
                value: _.get(material, 'id'),
              }
            })}
            disabled={disabled}
            onSelect={handleMaterialsSelect}
            onDeselect={handleMaterialsDeselect}
            defaultValue={_.map(selectedMaterials, 'material_id')}
          />
        </Col>
      </GutteredRow>

      {/*  Materials Table */}
      {_.size(selectedMaterials) > 0 && (
        <GutteredRow style={{ marginTop: '10px' }}>
          <Col xl={24}>
            <MaterialsTable
              dataSource={selectedMaterials}
              setTotalMaterial={setTotalMaterial}
              calcFunction={calcFunction}
            />
          </Col>
        </GutteredRow>
      )}
    </>
  )
}

export default MaterialsSection
