import React, { useEffect } from 'react'
import { message } from 'antd'

const Info = ({ info }) => {
  useEffect(() => {
    // show errors on top
    info && message.success(info)
  }, [info])

  return null
}

export default Info