import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'

dayjs.extend(customParseFormat)

export const marshalCall = (call) => {
  return {
    ...call,
    requested_at: dayjs(call.requested_at),
    reported_by: [call.reported_by],
    phone: marshalPhone(call.phone),
    contact_phone: marshalPhone(call.contact_phone),
    contact_person: [call.contact_person],
  }
}

const marshalPhone = (phone = '') => (_.isNil(phone) ? [] : _.split(phone, ','))
