// Single Select

import React, { useContext, useState } from 'react'
import { Form, Form as AntdForm, Input, Select } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'

const log = new Logger('LocalSelect')

const LocalSelect = ({
  name,
  options,
  label,
  railsName = name,
  onChange = undefined,
  required = false,
  defaultValue = null,
  localAttribute = null,
  disabled = undefined,
  useWatch = false,
  ...otherProps
}) => {
  const [value, setValue] = useState(defaultValue)

  // inject intl
  const intl = useIntl()

  const form = Form.useFormInstance()

  const builtOptions = _.map(options, (option, index) => ({
    key: index,
    label: intl.formatMessage({ id: `${localAttribute}.${option}` }),
    value: option,
  }))

  log.debug('selectedValue, built options', { value, builtOptions })

  const onSelect = (selectedValue) => {
    setValue(selectedValue)
    if (_.isFunction(onChange)) onChange()
  }

  const validateRequired = () => {
    return required
      ? [
          {
            required: true,
          },
        ]
      : ''
  }

  const { mode } = useContext(GlobalContext)

  return (
    <>
      {/* Hidden field for rails*/}
      <Input
        name={railsName}
        type={'hidden'}
        value={useWatch ? AntdForm.useWatch(name) : value}
      />
      <AntdForm.Item
        name={_.toPath(name)}
        label={<FormattedMessage id={label} />}
        required={required}
        rules={validateRequired()}
        {...otherProps}
      >
        <Select
          showSearch
          options={builtOptions}
          optionFilterProp={'label'}
          onSelect={onSelect}
          disabled={disabled || mode === 'detail'}
        />
      </AntdForm.Item>
    </>
  )
}

export default LocalSelect
