import React, { useContext } from 'react'

import { Logger } from 'aws-amplify'

import { SearchBox } from '@components/index'

import { DevicesContext } from '../contexts'

const log = new Logger('DeviceSearch')

const Search = ({ onSearch = () => {} }) => {
  const { query, setQuery } = useContext(DevicesContext)

  return (
    <SearchBox
      onSearch={(query) => {
        setQuery(query)
        onSearch(query)
      }}
      defaultValue={query}
      placeholder={`ui.devices.placeholders.search`}
    />
  )
}

export default Search
