import React, { useContext } from 'react'

import { Logger } from 'aws-amplify'

import { SearchBox } from '@components/index'

import { DeviceAlarmsContext } from '../contexts'

const log = new Logger('DeviceSearch')

const Search = ({ onSearch = () => {} }) => {
  const { query, setQuery } = useContext(DeviceAlarmsContext)

  return (
    <SearchBox
      onSearch={(query) => {
        setQuery(query)
        onSearch(query)
      }}
      defaultValue={query}
      placeholder={`ui.device_alarms.placeholders.search`}
    />
  )
}

export default Search
