import React from 'react'
import { StyledCenteredDiv } from '../styled'
import { Spinner } from './index'

const CenteredSpinner = (props) => (
  <StyledCenteredDiv>
    <Spinner />
  </StyledCenteredDiv>
)

export default CenteredSpinner;
