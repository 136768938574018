import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import * as F from '@utils/functions'
import { Button, Col, Modal } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { GutteredRow } from '@components/form-components'

import { INTERVENTION_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'
import { StyledContainer } from '@/styled'
import { prepareFilters } from '@/utils'

import InterventionSearch from './InterventionSearch'
import InterventionsTable from './InterventionsTable'

const log = new Logger('Invoices/components/Modal')

/**
 *
 * @param {Object} modalData
 * @property {boolean} modalData.open
 * @property {Array.<InterventionStrict>} modalData.interventions
 * @property {function} modalData.setInterventions
 * @property {function} modalData.onOk
 * @property {FacilityStrict} modalData.facility
 * @property {function} modalData.onCancel
 * @returns {JSX.Element}
 * @constructor
 */
const AddInterventionModal = ({ modalData }) => {
  const {
    facility,
    interventions,
    selectedInterventions,
    onOk,
    ...modalProps
  } = modalData

  /* search states */
  const [searchLoading, setSearchLoading] = useState(false)
  const [query, setQuery] = useState(null)
  const [filters, setFilters] = useState(
    prepareFilters({ status: ['terminated'] })
  )

  const [pagination, setPagination] = useState(DEFAULT_PAGER)

  const [filterInterventions, setFilterInterventions] = useState([])

  const [filterSelectedInterventions, setFilterSelectedInterventions] =
    useState([])

  const isTableChanged = useRef(true)

  log.debug('modal data, filterIntervention, search params', {
    modalData,
    filterInterventions,
    filterSelectedInterventions,
    searchParams: { query, facility, filters, pagination },
  })

  const handleTableChange = () => {
    isTableChanged.current = true
  }

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
  }

  // update dataSource and selection
  useEffect(() => {
    if (_.isEmpty(interventions)) return
    setFilterInterventions(
      _.uniqBy([...interventions, ...selectedInterventions], 'id')
    )
    setFilterSelectedInterventions(selectedInterventions)
  }, [interventions, selectedInterventions])

  // filter interventions
  useEffect(() => {
    if (modalProps.open && isTableChanged.current) {
      log.debug('filterIntervention START', filterInterventions)
      setSearchLoading(true)
      makeApiRequest(INTERVENTION_SEARCH, 'post', {
        body: {
          query,
          filters,
          call_closed: true,
          facility_id: _.get(facility, 'id'),
          serializer: 'InterventionExtendedSerializer',
        },
        queryParams: F.marshalPaginationTo(pagination),
      })
        .then(({ data, headers }) => {
          const newPagination = {
            ...pagination,
            ...F.marshalPaginationFrom(headers),
          }

          setPagination(newPagination)
          setFilterInterventions(data)
        })
        .finally(() => {
          setSearchLoading(false)
          isTableChanged.current = false
          log.debug('filterIntervention END', filterInterventions)
        })
    }
  }, [query, filters, isTableChanged.current])

  return (
    <Modal
      {...modalProps}
      title={<FormattedMessage id={'ui.modals.add_intervention.title'} />}
      footer={
        <Button
          type={'primary'}
          onClick={() => onOk(filterSelectedInterventions)}
        >
          <FormattedMessage id={'ui.modals.add_intervention.ok_text'} />
        </Button>
      }
      width={'70vw'}
      confirmLoading
    >
      <StyledContainer>
        {/*  Interventions Search*/}
        <GutteredRow>
          <Col xl={6}>
            <InterventionSearch setQuery={setQuery} />
          </Col>
        </GutteredRow>

        <GutteredRow>
          <Col xl={24}>
            <InterventionsTable
              pagination={{ ...pagination, onChange: handlePaginationChange }}
              onChange={handleTableChange}
              dataSource={filterInterventions}
              searchLoading={searchLoading}
              selectedInterventions={{
                filterSelectedInterventions,
                setFilterSelectedInterventions,
              }}
            />
          </Col>
        </GutteredRow>
      </StyledContainer>
    </Modal>
  )
}

export default AddInterventionModal
