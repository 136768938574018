import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import * as F from '@utils/functions'
import { isInvoiced } from '@utils/invoices'
import { Col } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { DisplayDetail } from '@components/Display'
import { DatePickerField, InputField, TextAreaField } from '@components/Fields'
import { LocalSelect, SingleSelect } from '@components/Select'

import { GutteredRow } from '@/Components'
import { FACILITY_SEARCH, INTERVENTION_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'
import { GlobalContext } from '@/contexts'
import { getNewOptions, prepareFilters } from '@/utils'

import MultipleSelect from '../../../Components/Select/MultipleSelect'
import {
  interventionTypologies,
  motivations,
  statuses,
} from '../../../enums/invoice'
import { Interventions } from '../components'
import { InvoiceContext } from '../contexts'

const log = new Logger('Invoices/form/FormFields')

const FormFields = () => {
  // states
  const { mode } = useContext(GlobalContext)
  const { invoice } = useContext(InvoiceContext)

  const [facilities, setFacilities] = useState([_.get(invoice, 'facility')])
  const [facility, setFacility] = useState(_.get(invoice, 'facility', null))
  const [searchLoading, setSearchLoading] = useState(false)
  const [selectedInterventions, setSelectedInterventions] = useState(
    _.get(invoice, 'interventions', [])
  )

  const [interventions, setInterventions] = useState(selectedInterventions)

  // default facility filters
  const facilityFilters = {
    invoice_condition: true,
    filters: [
      {
        field: 'interventions_present',
        values: [true],
      },
    ],
  }

  // fetch facilities on mount
  useEffect(() => {
    if (mode !== 'edit') {
      setSearchLoading(true)
      makeApiRequest(FACILITY_SEARCH, 'post', {
        body: { ...facilityFilters, serializer: 'strict' },
        queryParams: F.marshalPaginationTo({
          ...DEFAULT_PAGER,
          pageSize: 9999,
        }),
      })
        .then(({ data }) => setFacilities(data))
        .finally(() => setSearchLoading(false))
    }
  }, [])

  const fetch = _.debounce((query) => {
    setSearchLoading(true)
    makeApiRequest(FACILITY_SEARCH, 'post', {
      body: { ...facilityFilters, query, serializer: 'strict' },
      queryParams: F.marshalPaginationTo({
        ...DEFAULT_PAGER,
        pageSize: 9999,
      }),
    })
      .then(({ data }) => setFacilities(data))
      .finally(() => setSearchLoading(false))
  }, 500)

  // fetch interventions
  useEffect(() => {
    if (facility) {
      makeApiRequest(INTERVENTION_SEARCH, 'post', {
        body: {
          facility_id: _.get(facility, 'id'),
          call_closed: true,
          filters: prepareFilters({ status: ['terminated'] }),
          serializer: 'InterventionExtendedSerializer',
        },
        queryParams: F.marshalPaginationTo(DEFAULT_PAGER),
      }).then(({ data }) => {
        setInterventions(_.unionBy([...data, ...selectedInterventions], 'id'))
      })
    }
  }, [facility])

  const onFacilitySelect = (id) => {
    setFacility(_.find(facilities, { id }))
  }

  log.debug('Invoice, facilities, facility', {
    invoice,
    facilities,
    facility,
    interventions,
    selectedInterventions,
  })
  return (
    <>
      <GutteredRow>
        {/* Invoice ID*/}
        <Col xl={6}>
          <DisplayDetail
            label={<FormattedMessage id={'ui.invoices.form.labels.id'} />}
            value={_.get(invoice, 'id')}
          />
        </Col>

        {/*  Status */}
        <Col xl={6}>
          <LocalSelect
            name={'status'}
            label={'ui.invoices.form.labels.status'}
            required
            localAttribute={'ui.invoices.enums.statuses'}
            options={statuses}
            defaultValue={_.get(invoice, 'status', null)}
          />
        </Col>

        {/*  Invoice Date */}
        <Col xl={6}>
          <DatePickerField
            label={
              <FormattedMessage id={'ui.invoices.form.labels.invoice_date'} />
            }
            name={'invoice_date'}
            railsName={'invoice_date'}
            required
            initialValue={_.invoke(
              _.get(invoice, 'invoice_date'),
              'format',
              'YYYY-MM-DD'
            )}
            disabled={isInvoiced(invoice)}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        {/* Facility */}
        <Col xl={6}>
          <SingleSelect
            name={'facility_id'}
            label={'ui.invoices.form.labels.facility'}
            required
            options={getNewOptions(['business_name', 'id'])(facilities)}
            onSearch={fetch}
            loading={searchLoading}
            onSelect={onFacilitySelect}
            disabled={!_.isEmpty(selectedInterventions) || isInvoiced(invoice)}
            defaultValue={_.get(facility, 'id', null)}
          />
        </Col>

        {/*  Subject */}
        <Col xl={12}>
          <InputField
            name={'subject'}
            label={<FormattedMessage id={'ui.invoices.form.labels.subject'} />}
            disabled={isInvoiced(invoice)}
          />
        </Col>
      </GutteredRow>

      {!_.isEmpty(facility) && (
        <>
          <GutteredRow>
            {/* Intervention Typologies */}
            <Col xl={6}>
              <MultipleSelect
                name={'intervention_typologies'}
                label={'ui.invoices.form.labels.intervention_typologies'}
                railsName={'intervention_typologies[]'}
                disabled={isInvoiced(invoice)}
                options={getNewOptions([])(interventionTypologies)}
                defaultValue={_.get(invoice, 'intervention_typologies', [])}
                required
              />
            </Col>

            {/*  Reference */}
            <Col xl={6}>
              <InputField
                name={'reference'}
                label={
                  <FormattedMessage id={'ui.invoices.form.labels.reference'} />
                }
              />
            </Col>
          </GutteredRow>

          <GutteredRow>
            {/* Motivations */}
            <Col xl={12}>
              <MultipleSelect
                name={'motivations'}
                label={'ui.invoices.form.labels.motivations'}
                railsName={'motivations[]'}
                options={getNewOptions([])(motivations)}
                defaultValue={_.get(invoice, 'motivations', [])}
                required
                disabled={isInvoiced(invoice)}
              />
            </Col>
          </GutteredRow>

          <GutteredRow>
            {/*  Description */}
            <Col xl={12}>
              <TextAreaField
                name={'description'}
                label={
                  <FormattedMessage
                    id={'ui.invoices.form.labels.description'}
                  />
                }
                disabled={isInvoiced(invoice)}
              />
            </Col>
          </GutteredRow>
        </>
      )}

      {/* Facility Interventions Section  */}
      {(!_.isEmpty(interventions) ||
        (!_.isEmpty(selectedInterventions) && mode === 'edit')) && (
        <Interventions
          invoice={invoice}
          selectedInterventions={selectedInterventions}
          setSelectedInterventions={setSelectedInterventions}
          initialInterventions={{
            interventions,
            setInterventions,
          }}
          facility={facility}
        />
      )}
    </>
  )
}

export default FormFields
