import React, { useContext, useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import { CustomForm, GutteredRow } from '../../.././Components'
import { InterventionContext } from '../contexts'
import _ from 'lodash'
import { WarningOutlined } from '@ant-design/icons'
import { Col, Divider, Input, message } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  StyledBoldDetail,
  StyledDetailLabel,
  StyledDivider,
  StyledRightCol,
  StyledRightInput,
} from '../../../styled'
import {
  discountCoverage,
  fetchConstant,
  getWorkingTimes,
  isMaintenance,
} from '@/utils'
import styled from 'styled-components'
import { DocumentSection, MaterialsSection } from './index'

import { GlobalContext } from '@/contexts'
import * as s3 from '../../../lib'
import {
  DetailField,
  FieldCheckbox,
  FieldCurrency,
  FieldInputNumber,
  RangeTimePickerField,
} from '@components/Fields'
import * as R from 'ramda'
import * as F from '@utils/functions'

import { DisplayPrice } from '@components/Display'
// import { disableReduction } from '../functions'
import * as FIntervention from '@components/Interventions/functions'

const log = new Logger('CloseInterventionForm')

// Styled Total Paragraph
const StyledBigP = styled.p`
  font-size: 20px;
`

/**
 *
 * @param {Object} props
 * @param {FormInstance} props.form
 * @param {string} props.action
 * @param {Object} props.initialValues
 * @param {boolean} props.disabled
 * @returns
 */

const CloseInterventionForm = ({ form, action, initialValues, disabled }) => {
  const { intervention, materials } = useContext(InterventionContext)

  const { setLoading: setGlobalLoading } = useContext(GlobalContext)

  const { constants } = intervention

  // Constants
  const calcFieldNames = [
    'hq_distance',
    'route_duration',
    'morning_apprentice_times',
    'evening_apprentice_times',
    'morning_qualified_times',
    'evening_qualified_times',
    'morning_specialized_times',
    'evening_specialized_times',
    'qualified_workers',
    'apprentice_workers',
    'specialized_workers',
    'qualified_hourly_cost',
    'apprentice_hourly_cost',
    'specialized_hourly_cost',
    'km_reduction_flag',
    'fixed_fee_reduction_flag',
    'fixed_fee',
    'mileage_refund',
  ]

  const initCalcFields = _.reduce(
    calcFieldNames,
    (fields, propertyName) => {
      fields[propertyName] = _.get(intervention, propertyName)
      return fields
    },
    {}
  )

  /* Methods */

  // get form calc fields values
  const fieldsValues = () =>
    _.reduce(
      calcFieldNames,
      (fields, name) => {
        fields[name] = form.getFieldValue(name)
        return fields
      },
      {}
    )

  // calc fixed fee

  /*
                                                                                                                                                            IF (hq_distance <=20)
                                                                                                                                                                 costo_diritto_fisso_A_apprend * num_operai_apprend +
                                                                                                                                                                 costo_diritto_fisso_A_qualif * num_operai_qualif +
                                                                                                                                                                 costo_diritto_fisso_A_specializ * num_operai_specializ
                                                                                                                                                        
                                                                                                                                                        ELSE IF (hq_distance >20 && hq_distance<=50 )
                                                                                                                                                                 costo_diritto_fisso_B_apprend * num_operai_apprend +
                                                                                                                                                                 costo_diritto_fisso_B_qualif * num_operai_qualif +
                                                                                                                                                                 costo_diritto_fisso_B_specializ * num_operai_specializ	
                                                                                                                                                        
                                                                                                                                                        ELSE
                                                                                                                                                             (route_duration/60)*( costo_h_diritto_fisso_apprend*num_operai_apprend + 
                                                                                                                                                                                                     costo_h_diritto_fisso_qualif*num_operai_qualif + 
                                                                                                                                                                                                     costo_h_diritto_fisso_specializ*num_operai_specializ)
                                                                                                                                                         
                                                                                                                                                          */
  const calcFixedFee = (fields) => {
    if (_.get(intervention, 'typology') === 'maintenance_subscription') return 0
    // Fields
    const routeDuration = _.get(fields, 'route_duration')
    const hqDistance = _.get(fields, 'hq_distance')
    const fixedFeeReductionFlag = _.get(fields, 'fixed_fee_reduction_flag')
    const qualifiedWorkers = _.get(fields, 'qualified_workers')
    const apprenticeWorkers = _.get(fields, 'apprentice_workers')
    const specializedWorkers = _.get(fields, 'specialized_workers')

    let fixedFee = _.get(fields, 'fixed_fee')

    log.debug('calculating fixed fee...', {
      fixedFee,
      routeDuration,
      hqDistance,
      fixedFeeReductionFlag,
      qualifiedWorkers,
      apprenticeWorkers,
      specializedWorkers,
    })

    if (hqDistance <= 20) {
      fixedFee = _.sum([
        _.multiply(
          fetchConstant('default_qualified_costs.fixed_fee.a', constants),
          qualifiedWorkers
        ),
        _.multiply(
          fetchConstant('default_apprentice_costs.fixed_fee.a', constants),
          apprenticeWorkers
        ),
        _.multiply(
          fetchConstant('default_specialized_costs.fixed_fee.a', constants),
          specializedWorkers
        ),
      ])
    } else if (hqDistance > 20 && hqDistance <= 50) {
      fixedFee = _.sum([
        _.multiply(
          fetchConstant('default_qualified_costs.fixed_fee.b', constants),
          qualifiedWorkers
        ),
        _.multiply(
          fetchConstant('default_apprentice_costs.fixed_fee.b', constants),
          apprenticeWorkers
        ),
        _.multiply(
          fetchConstant('default_specialized_costs.fixed_fee.b', constants),
          specializedWorkers
        ),
      ])
    } else {
      fixedFee = _.multiply(
        _.divide(routeDuration, 60),
        _.sum([
          _.multiply(
            fetchConstant(
              'default_qualified_costs.fixed_fee.default',
              constants
            ),
            qualifiedWorkers
          ),
          _.multiply(
            fetchConstant(
              'default_apprentice_costs.fixed_fee.default',
              constants
            ),
            apprenticeWorkers
          ),
          _.multiply(
            fetchConstant(
              'default_specialized_costs.fixed_fee.default',
              constants
            ),
            specializedWorkers
          ),
        ])
      )
    }

    fixedFee = F.round(fixedFee)

    if (fixedFeeReductionFlag) {
      fixedFee = R.subtract(
        fixedFee,
        R.divide(
          R.multiply(
            fixedFee,
            fetchConstant('default_fixed_fee_reduction_percentage', constants)
          ),
          100
        )
      )
    }

    fixedFee = F.round(fixedFee)
    form.setFieldValue('fixed_fee', fixedFee)
    setFixedFee(fixedFee)
  }

  // calculate mileage_refund
  /*
                                                                                                                                                IF (hq_distance <=20)
                                                                                                                                                     RimbKm_A
                                                                                                                                            IF (hq_distance >20 && hq_distance<=50 ) 
                                                                                                                                                    RimbKm_B
                                                                                                                                            ELSE  
                                                                                                                                                    hq_distance * costo_km
                                                                                                                                               
                                                                                                                                            ** se usa la riduzione questo risultato va scontato del %rid_rimbKm
                                                                                                                                                        
                                                                                                                                            rimborso KM = rimborso KM - (rimborso KM * %rid_rimbKm
                                                                                                                                            
                                                                                                                                               */

  const calcMileageRefund = (fields) => {
    log.debug('calcMileageRefund PRE', { fields })
    if (_.get(intervention, 'typology') === 'maintenance_subscription') return 0

    const hqDistance = _.get(fields, 'hq_distance')
    const kmReductionFlag = _.get(fields, 'km_reduction_flag')

    let mileageRefund = _.get(fields, 'mileage_refund')

    if (hqDistance <= 20) {
      mileageRefund = fetchConstant('default_mileage_refund_costs.a', constants)
    } else if (hqDistance > 20 && hqDistance <= 50)
      mileageRefund = fetchConstant('default_mileage_refund_costs.b', constants)
    else
      mileageRefund = _.multiply(
        hqDistance,
        fetchConstant('km_cost', constants)
      )

    mileageRefund = F.round(mileageRefund)

    if (kmReductionFlag)
      mileageRefund = R.subtract(
        mileageRefund,
        R.divide(
          R.multiply(
            mileageRefund,
            fetchConstant(
              'default_mileage_refund_reduction_percentage',
              constants
            )
          ),
          100
        )
      )

    log.debug('calcMileageRefund POST', { mileageRefund })
    mileageRefund = F.round(mileageRefund)

    form.setFieldValue('mileage_refund', mileageRefund)
    return mileageRefund
  }

  /* Calculate Manpower Total 
                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                Ore man qualificate * Costo/orario qualif + 
                                                                                                                                                                                                                                                                                Ore man apprendista * Costo/orario apprendista + 
                                                                                                                                                                                                                                                                                Ore man specializzato * Costo/orario specializzato +
                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                               */
  const calculateManpowerTotal = (fields) => {
    const mileageRefund = calcMileageRefund(
      _.pick(fields, ['mileage_refund', 'hq_distance', 'km_reduction_flag'])
    )

    form.setFieldValue('mileage_refund', mileageRefund)

    // Qualified

    const qualifiedHourlyCost = _.get(fields, 'qualified_hourly_cost')
    const [qualifiedMinutes, qualifiedWorkingHours] = getWorkingTimes(
      _.get(fields, 'morning_qualified_times'),
      _.get(fields, 'evening_qualified_times')
    )

    const qualifiedWorkers = _.get(fields, 'qualified_workers')

    // Apprentice
    const apprenticeHourlyCost = _.get(fields, 'apprentice_hourly_cost')
    const [apprenticeMinutes, apprenticeWorkingHours] = getWorkingTimes(
      _.get(fields, 'morning_apprentice_times'),
      _.get(fields, 'evening_apprentice_times')
    )

    const apprenticeWorkers = _.get(fields, 'apprentice_workers')

    // Specialized
    const specializedHourlyCost = _.get(fields, 'specialized_hourly_cost')
    const [specializedMinutes, specializedWorkingHours] = getWorkingTimes(
      _.get(fields, 'morning_specialized_times'),
      _.get(fields, 'evening_specialized_times')
    )
    const specializedWorkers = _.get(fields, 'specialized_workers')

    log.debug({
      qualifiedWorkingHours,
      apprenticeWorkingHours,
      specializedWorkingHours,
    })

    // subscription coverage
    const subscriptionCoverage = _.get(intervention, 'subscription_coverage')

    const [qualifiedCoverage, isQualifiedCovered] = discountCoverage(
      qualifiedMinutes,
      subscriptionCoverage
    )

    const totalQualified = isMaintenance(intervention)
      ? isQualifiedCovered
        ? 0
        : _.round(
            _.multiply(
              _.multiply(qualifiedCoverage, qualifiedHourlyCost),
              qualifiedWorkers
            ),
            2
          ) || 0
      : _.round(
          _.multiply(
            _.multiply(qualifiedWorkingHours, qualifiedHourlyCost),
            qualifiedWorkers
          ),
          2
        ) || 0

    const [apprenticeCoverage, isApprenticeCovered] = discountCoverage(
      apprenticeMinutes,
      subscriptionCoverage
    )

    const totalApprentice = isMaintenance(intervention)
      ? isApprenticeCovered
        ? 0
        : _.round(
            _.multiply(
              _.multiply(apprenticeCoverage, apprenticeHourlyCost),
              apprenticeWorkers
            ),
            2
          ) || 0
      : _.round(
          _.multiply(
            _.multiply(apprenticeWorkingHours, apprenticeHourlyCost),
            apprenticeWorkers
          ),
          2
        ) || 0

    const [specializedCoverage, isSpecializedCovered] = discountCoverage(
      specializedMinutes,
      subscriptionCoverage
    )

    const totalSpecialized = isMaintenance(intervention)
      ? isSpecializedCovered
        ? 0
        : _.round(
            _.multiply(
              _.multiply(specializedCoverage, specializedHourlyCost),
              specializedWorkers
            ),
            2
          ) || 0
      : _.round(
          _.multiply(
            _.multiply(specializedWorkingHours, specializedHourlyCost),
            specializedWorkers
          ),
          2
        ) || 0

    let totalManpower = _.sum([
      totalQualified,
      totalApprentice,
      totalSpecialized,
    ])

    form.setFieldValue('total_qualified', totalQualified)
    form.setFieldValue('total_apprentice', totalApprentice)
    form.setFieldValue('total_specialized', totalSpecialized)

    log.debug('calculateManpowerTotal', {
      totalQualified,
      totalApprentice,
      totalSpecialized,
      totalManpower,
      subscriptionCoverage,
    })

    setTotalManpower(_.round(totalManpower, 2))
  }

  // calculate total intervention
  /* 
                                                                                                                                                                                                                                                                                                                                    Diritto Fisso + 
                                                                                                                                                                                                                                                                                                                                    Rimborso Km +
                                                                                                                                                                                                                                                                                                                                    Totale Manodopera 
                                                                                                                                                                                                                                                                                                                                    SUM ( quantità materiali * prezzo)
                                                                                                                                                                                                                                                                                                                                   */
  const calculateInterventionTotal = (
    fields,
    calculatedManpower,
    calculatedTotalMaterial,
    calculatedFixedFee
  ) => {
    calcFixedFee(fields)
    const mileageRefund = calcMileageRefund(
      _.pick(fields, ['mileage_refund', 'hq_distance', 'km_reduction_flag'])
    )

    log.debug('calculateInterventionTotal', {
      calculatedFixedFee,
      mileageRefund,
      calculatedManpower,
      calculatedTotalMaterial,
    })

    const subscriptionFee = _.get(intervention, 'subscription_fee')

    let calculatedTotalIntervention = _.sum([
      calculatedManpower,
      calculatedFixedFee,
      mileageRefund,
      calculatedTotalMaterial,
    ])

    if (isMaintenance(intervention))
      calculatedTotalIntervention = _.sum([
        calculatedTotalIntervention,
        subscriptionFee,
      ])

    setTotalIntervention(_.round(calculatedTotalIntervention, 2))
  }

  // States

  // inject intl
  const intl = useIntl()

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  /* states */

  const [fixedFee, setFixedFee] = useState(R.prop('fixed_fee', intervention))

  const [totalManpower, setTotalManpower] = useState(
    _.get(intervention, 'total_manpower')
  )

  const [totalIntervention, setTotalIntervention] = useState(
    _.get(intervention, 'total_intervention')
  )

  const [totalMaterial, setTotalMaterial] = useState(
    _.get(intervention, 'total_material')
  )

  const [document, setDocument] = useState(
    _.get(intervention, 'document', null)
  )

  const [fileList, setFileList] = useState(
    _.defaultTo(_.get(initialValues, 'dragger'), [])
  )

  log.debug({
    document,
    totalManpower,
    totalMaterial,
    totalIntervention,
    initialValues,
    action,
    disabled,
    fileList,
  })

  // marshal document for dataSource
  const marshalDocument = (document, storageUrl = null) => {
    if (_.isNil(document)) return document
    return {
      ...document,
      format: _.chain(document)
        .get('name')
        .split('.')
        .thru((v) => v[1].split('_'))
        .value()[0],
      storage_url: storageUrl,
      typology: 'technical_data_sheet',
    }
  }

  // upload document
  const upload = async () => {
    let res = null
    if (!_.isEmpty(fileList)) {
      res = await s3.uploadFile(
        fileList[0],
        `documents/interventions/${_.get(intervention, 'id')}`
      )

      if (_.get(res, '$metadata.httpStatusCode') >= 400) {
        message.error(res.message)
        return
      } else {
        message.success(intl.formatMessage({ id: 'messages.success.upload' }))
      }
    }

    const marshalledDocument = marshalDocument(
      fileList[0],
      _.get(res, 'storageUrl', null)
    )

    setDocument(marshalledDocument)
  }

  const onFinish = async (values) => {
    log.debug('Values', values)
    if (_.isNil(_.get(fileList, '0.internal_code'))) await upload()

    setTimeout(() => {
      window.document.getElementById('close-intervention-form').submit()
      setGlobalLoading(true)
    }, 100)
  }

  const onFinishFailed = (e) => {
    log.warn('Finish failed', e)
    setButtonType({
      type: 'dashed',
      icon: <WarningOutlined />,
      danger: true,
    })
  }

  const handleOnChange = () => {
    const fields = fieldsValues()

    log.verbose('on change...', { fields })

    calcFixedFee(fields)
    calculateManpowerTotal(fields)
  }

  // calculate intervention total
  useEffect(() => {
    calculateInterventionTotal(
      fieldsValues(),
      totalManpower,
      totalMaterial,
      fixedFee
    )
  }, [totalMaterial, totalManpower, fixedFee])

  // init calculate
  useEffect(() => {
    log.debug('initCalculate', initCalcFields)
    calculateManpowerTotal(initCalcFields)
  }, [])

  return (
    <CustomForm
      form={form}
      method={'post'}
      action={`/interventions/${_.get(intervention, 'id')}/${_.defaultTo(
        action,
        'close'
      )}`}
      name={`close-intervention-form`}
      onFinish={(values) => onFinish(values)}
      initialValues={initialValues}
      layout={'vertical'}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
    >
      {/*  Intervention Fields */}
      <GutteredRow style={{ marginBottom: '20px' }}>
        <Col md={12}>
          <StyledDetailLabel>
            {_.get(initialValues, 'id')} -{' '}
            {_.get(initialValues, 'business_name')}{' '}
          </StyledDetailLabel>
        </Col>
      </GutteredRow>
      <GutteredRow>
        {/*  HQ Distance*/}
        <Col md={6}>
          <StyledRightInput
            suffix={'km'}
            name={`hq_distance`}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.hq_distance',
            })}
          />
        </Col>

        {/*  Route Duration */}
        <Col md={6}>
          <StyledRightInput
            suffix={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.route_duration_suffix',
            })}
            name={`route_duration`}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.route_duration',
            })}
          />
        </Col>
      </GutteredRow>
      <GutteredRow>
        {/*  Fixed Fee */}
        <Col md={6}>
          <DetailField
            suffix={'€'}
            name={`fixed_fee`}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.fixed_fee',
            })}
          />
        </Col>

        {/*  Fixed Fee Reduction Checkbox */}
        <Col md={8}>
          <FieldCheckbox
            name={'fixed_fee_reduction_flag'}
            label={
              'ui.interventions.close_form.labels.fixed_fee_reduction_flag'
            }
            disabled={FIntervention.disableReduction(intervention) || disabled}
            onChange={() =>
              calculateInterventionTotal(
                fieldsValues(),
                totalManpower,
                totalMaterial,
                fixedFee
              )
            }
            checked={_.get(initialValues, 'fixed_fee_reduction_flag')}
          />
        </Col>
      </GutteredRow>
      <GutteredRow>
        {/*  Mileage Refund */}
        <Col md={6}>
          <DetailField
            suffix={'€'}
            name={`mileage_refund`}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.mileage_refund',
            })}
          />
        </Col>
        {/*  Km Reduction Checkbox */}
        <Col md={6}>
          <FieldCheckbox
            name={'km_reduction_flag'}
            label={'ui.interventions.close_form.labels.km_reduction_flag'}
            disabled={FIntervention.disableReduction(intervention) || disabled}
            onChange={() =>
              calculateInterventionTotal(
                fieldsValues(),
                totalManpower,
                totalMaterial,
                fixedFee
              )
            }
            checked={_.get(initialValues, 'km_reduction_flag')}
          />
        </Col>
      </GutteredRow>

      {isMaintenance(intervention) && (
        <GutteredRow>
          {/* Subscription Fee*/}
          <Col md={6}>
            <DetailField
              suffix={'€'}
              name={`subscription_fee`}
              label={intl.formatMessage({
                id: 'ui.interventions.close_form.labels.subscription_fee',
              })}
            />
          </Col>

          {/* Subscription Coverage */}
          <Col md={6}>
            <DetailField
              name={`subscription_coverage`}
              label={intl.formatMessage({
                id: 'ui.interventions.close_form.labels.subscription_coverage',
              })}
            />
          </Col>
        </GutteredRow>
      )}

      {/* APPRENTICE */}
      <GutteredRow>
        <Col md={20}>
          <StyledDivider orientation={'left'}>
            <FormattedMessage id={'ui.interventions.labels.apprentice'} />
          </StyledDivider>
        </Col>
      </GutteredRow>

      {/* Apprentice Fields */}
      <GutteredRow>
        {/* Morning Apprentice Working Time */}
        <Col md={5}>
          <RangeTimePickerField
            label={'ui.interventions.close_form.labels.morning_times'}
            name={'morning_apprentice_times'}
            railsNames={[
              'morning_start_apprentice_working_time',
              'morning_end_apprentice_working_time',
            ]}
            disabled={disabled}
            onChange={handleOnChange}
            // initialValue={}
          />
        </Col>

        {/* Evening Apprentice Working Time */}
        <Col md={5}>
          <RangeTimePickerField
            label={'ui.interventions.close_form.labels.evening_times'}
            name={'evening_apprentice_times'}
            railsNames={[
              'evening_start_apprentice_working_time',
              'evening_end_apprentice_working_time',
            ]}
            disabled={disabled}
            onChange={handleOnChange}
            // initialValue={}
          />
        </Col>

        {/* Apprentice Workers */}
        <Col md={5}>
          <FieldInputNumber
            name={`apprentice_workers`}
            railsName={`apprentice_workers`}
            disabled={disabled}
            initialValue={_.get(intervention, 'apprentice_workers')}
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.apprentice_workers'}
              />
            }
            onChange={handleOnChange}
            required
          />
        </Col>

        {/*  Apprentice Hourly Cost */}
        <Col md={5}>
          <FieldCurrency
            name={`apprentice_hourly_cost`}
            railsName={'apprentice_hourly_cost'}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.apprentice_hourly_cost',
            })}
            required
            initialValue={_.get(intervention, 'apprentice_hourly_cost')}
            disabled={disabled}
            onChange={handleOnChange}
          />
        </Col>

        {/*  Total Apprentice */}
        <Col md={4}>
          <DetailField
            suffix={'€'}
            name={`total_apprentice`}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.total',
            })}
          />
        </Col>
      </GutteredRow>

      {/* QUALIFIED */}
      <GutteredRow>
        <Col md={20}>
          <StyledDivider orientation={'left'}>
            <FormattedMessage id={'ui.interventions.labels.qualified'} />
          </StyledDivider>
        </Col>
      </GutteredRow>

      {/* Qualified Fields */}
      <GutteredRow>
        {/* Morning Qualified Working Time */}
        <Col md={5}>
          <RangeTimePickerField
            label={'ui.interventions.close_form.labels.morning_times'}
            name={'morning_qualified_times'}
            railsNames={[
              'morning_start_qualified_working_time',
              'morning_end_qualified_working_time',
            ]}
            disabled={disabled}
            onChange={handleOnChange}
            // initialValue={}
          />
        </Col>

        {/* Evening Qualified Working Time */}
        <Col xl={5}>
          <RangeTimePickerField
            label={'ui.interventions.close_form.labels.evening_times'}
            name={'evening_qualified_times'}
            railsNames={[
              'evening_start_qualified_working_time',
              'evening_end_qualified_working_time',
            ]}
            disabled={disabled}
            onChange={handleOnChange}
          />
        </Col>

        {/* Qualified Workers */}
        <Col md={5}>
          <FieldInputNumber
            name={`qualified_workers`}
            railsName={`qualified_workers`}
            disabled={disabled}
            initialValue={_.get(intervention, 'qualified_workers')}
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.qualified_workers'}
              />
            }
            onChange={handleOnChange}
            required
          />
        </Col>

        {/*  Qualified Hourly Cost */}
        <Col md={5}>
          <FieldCurrency
            name={`qualified_hourly_cost`}
            railsName={'qualified_hourly_cost'}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.qualified_hourly_cost',
            })}
            required
            initialValue={_.get(intervention, 'qualified_hourly_cost')}
            disabled={disabled}
            onChange={handleOnChange}
          />
        </Col>

        {/*  Total Qualified */}
        <Col md={4}>
          <DetailField
            suffix={'€'}
            name={`total_qualified`}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.total',
            })}
          />
        </Col>
      </GutteredRow>

      {/* SPECIALIZED */}
      <GutteredRow>
        <Col md={20}>
          <StyledDivider orientation={'left'}>
            <FormattedMessage id={'ui.interventions.labels.specialized'} />
          </StyledDivider>
        </Col>
      </GutteredRow>

      {/* Specialized Fields */}
      <GutteredRow>
        {/* Morning Specialized Working Time */}
        <Col md={5}>
          <RangeTimePickerField
            label={'ui.interventions.close_form.labels.morning_times'}
            name={'morning_specialized_times'}
            railsNames={[
              'morning_start_specialized_working_time',
              'morning_end_specialized_working_time',
            ]}
            disabled={disabled}
            onChange={handleOnChange}
            // initialValue={}
          />
        </Col>

        {/* Evening Specialized Working Time */}
        <Col md={5}>
          <RangeTimePickerField
            label={'ui.interventions.close_form.labels.evening_times'}
            name={'evening_specialized_times'}
            railsNames={[
              'evening_start_specialized_working_time',
              'evening_end_specialized_working_time',
            ]}
            disabled={disabled}
            onChange={handleOnChange}
            // initialValue={}
          />
        </Col>

        {/* Specialized Workers */}
        <Col md={5}>
          <FieldInputNumber
            name={`specialized_workers`}
            railsName={`specialized_workers`}
            disabled={disabled}
            initialValue={_.get(intervention, 'specialized_workers')}
            label={
              <FormattedMessage
                id={'ui.interventions.close_form.labels.specialized_workers'}
              />
            }
            onChange={handleOnChange}
            required
          />
        </Col>

        {/*  Specialized Hourly Cost */}
        <Col md={5}>
          <FieldCurrency
            name={`specialized_hourly_cost`}
            railsName={'specialized_hourly_cost'}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.specialized_hourly_cost',
            })}
            required
            initialValue={_.get(intervention, 'specialized_hourly_cost')}
            disabled={disabled}
            onChange={handleOnChange}
          />
        </Col>

        {/*  Total Specialized */}
        <Col md={4}>
          <DetailField
            suffix={'€'}
            name={`total_specialized`}
            label={intl.formatMessage({
              id: 'ui.interventions.close_form.labels.total',
            })}
          />
        </Col>
      </GutteredRow>
      <GutteredRow>
        <Divider />
      </GutteredRow>

      {/*Material Fields*/}
      <MaterialsSection
        setTotalMaterial={setTotalMaterial}
        materials={materials}
        disabled={disabled}
        calcFunction={handleOnChange}
      />

      {/* Document Section */}
      <DocumentSection
        document={document}
        setDocument={setDocument}
        marshalDocument={marshalDocument}
        fileList={fileList}
        setFileList={setFileList}
      />

      {/* Total Manpower */}
      <GutteredRow style={{ marginTop: '10px', marginBottom: '10px' }}>
        <StyledRightCol md={24}>
          <p>
            <StyledBoldDetail>
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total_manpower'}
              />
            </StyledBoldDetail>
            :
            <DisplayPrice value={totalManpower} />
          </p>
        </StyledRightCol>
      </GutteredRow>
      {/* Total Material */}
      <GutteredRow style={{ marginBottom: '10px' }}>
        <StyledRightCol md={24}>
          <p>
            <StyledBoldDetail>
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total_material'}
              />
            </StyledBoldDetail>
            :
            <DisplayPrice value={totalMaterial} />
          </p>
        </StyledRightCol>
      </GutteredRow>
      {/* Total Intervention */}
      <GutteredRow>
        <StyledRightCol md={24}>
          <StyledBigP>
            <StyledBoldDetail>
              <FormattedMessage
                id={'ui.interventions.close_form.labels.total'}
              />
            </StyledBoldDetail>
            :
            <DisplayPrice value={totalIntervention} />
          </StyledBigP>
        </StyledRightCol>
      </GutteredRow>
      {/*  Hidden Input for Total Intervention */}
      <Input
        type={'hidden'}
        name={'total_intervention'}
        value={totalIntervention}
      />
    </CustomForm>
  )
}

export default CloseInterventionForm
