import {useEffect} from "react";
import {withCookies} from "react-cookie";
import {Auth} from "aws-amplify"; // set jwt token cookie

// set jwt token cookie
/**
 * @todo replace with jwtAuth hook
 * @param props
 * @return {null}
 * @constructor
 */
const JwtCookie = (props) => {
  // set jwt token cookie
  useEffect(() => {
    const { cookies } = props

    Auth.currentSession().then((userSession) => {
      const jwtToken = userSession.idToken.jwtToken
      cookies.set('jwt_token', jwtToken)
    })
  }, [])
  return null
}

export default withCookies(JwtCookie)
