import React, { useContext } from 'react'

import { SearchBox } from '@/Components'

import { InvoicesContext } from '../contexts'

const InvoiceSearch = ({ onSearch }) => {
  const { query, setQuery } = useContext(InvoicesContext)

  return (
    <SearchBox
      onSearch={(query) => {
        setQuery(query)
        onSearch(query)
      }}
      defaultValue={query}
      placeholder={`ui.invoices.placeholders.search`}
    />
  )
}

export default InvoiceSearch
