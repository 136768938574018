import React from 'react'
import { FormattedNumber } from 'react-intl'
import _ from "lodash"

const DisplayPrice = ({
  value,
  currency = 'EUR',
  nullText = '•',
  suffix = undefined,
}) => {
  return (
    <>
      {!_.isNaN(value) && !_.isNil(value) ? (
        <>
          <FormattedNumber
            value={value}
            style={'currency'}
            currency={currency}
          />
          <span>{suffix}</span>
        </>
      ) : (
        <span>{nullText}</span>
      )}
    </>
  )
}

export default DisplayPrice
