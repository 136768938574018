import React from 'react'

import { Logger } from 'aws-amplify'
import _ from 'lodash'

const log = new Logger('Display')
const Display = ({ value, nullText = '•', suffix = null }) => {
  return !_.isNaN(value) && !_.isNil(value) && value !== '' ? (
    <span>
      {value} {suffix}
    </span>
  ) : (
    <span>{nullText}</span>
  )
}

export default Display
