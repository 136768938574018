import React, { useContext, useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'
import _ from 'lodash'
import { InvoiceContextProvider } from './contexts'
import { Card, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import InvoiceForm from './form/InvoiceForm'
import { InvoiceFields } from './form'
import { marshal } from '../../utils/invoices'

const log = new Logger('Invoices/New')

const New = (props) => {
  const { setMode } = useContext(GlobalContext)

  /* states */
  const [invoice, setInvoice] = useState(marshal(_.get(props, 'invoice', {})))

  log.debug('invoice', {
    invoice,
  })

  useEffect(() => {
    setMode('create')
  }, [])

  return (
    <InvoiceContextProvider
      value={{
        invoice,
        setInvoice,
      }}
    >
      <InvoiceForm
        title={
          <Typography.Title level={2}>
            <FormattedMessage id={'ui.invoices.actions.create'} />
          </Typography.Title>
        }
        {...props}
      >
        {/* Form Fields */}
        <Card>
          <InvoiceFields />
        </Card>
      </InvoiceForm>
    </InvoiceContextProvider>
  )
}

export default New
