import { GutteredRow } from '../../../Components'
import { Col, Input } from 'antd'
import { FieldCurrency } from '@components/Fields'
import { FormattedMessage } from 'react-intl'
import _, { round } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import {
  InterventionsContext,
  InvoiceContext,
  MaterialsContext,
} from '../contexts'
import { DisplayDetail, DisplayPrice } from '../../../Components/Display'
import { SingleSelect } from '../../../Components/Select'
import { getNewOptions } from '../../../utils'
import { vat } from '../../../enums/invoice'
import { StyledHorizontalDiv } from '../../../styled'
import styled from 'styled-components'
import { isInvoiced } from '../../../utils/invoices'
import * as R from 'ramda'

const log = new Logger('Invoices/form/CalculationFields')

const StyledBoldContainer = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: bold;
`

const CalculationFields = () => {
  const { invoice } = useContext(InvoiceContext)
  const { selectedInterventions, totalIntervention, totalSubscriptionFee } =
    useContext(InterventionsContext)
  const { materials } = useContext(MaterialsContext)

  const marshalTotalFields = (invoice) => {
    return {
      total: _.get(invoice, 'total_amount', 0),
      discountAmount: _.get(invoice, 'discount_amount', 0),
      vat: _.get(invoice, 'vat'),
    }
  }

  const [totalFields, setTotalFields] = useState(marshalTotalFields(invoice))

  // calculate material total
  const calculateMaterialTotal = (materials, subscriptionFee) => {
    let materialTotal = 0
    log.debug('calculateMaterialsTotal PRE', {
      materialTotal,
      materials,
      subscriptionFee,
    })

    // const roundNumber = R.flip(
    //   R.curry((value, precision) => _.round(value, precision))
    // )

    const roundNumber = R.curry((precision, value) => _.round(value, precision))

    materialTotal = R.pipe(
      R.reduce(
        (acc, el) =>
          R.sum([
            acc,
            R.multiply(
              R.propOr(0, 'quantity', el),
              R.propOr(0, 'unit_price', el)
            ),
          ]),
        0
      ),
      R.add(subscriptionFee),
      R.call(roundNumber(2))
    )(materials)

    log.debug('calculateMaterialsTotal POST', materialTotal)

    return _.round(materialTotal, 2)
  }

  const calculateTotal = (taxable, vat) => {
    log.info('calculateTotal PRE', {
      calculatedTotal: 0,
      taxable,
      vat,
    })

    let calculatedTotal = _.chain(taxable)
      .thru((value) => {
        let innerTotal
        const percentageValue = _.chain(value).multiply(vat).divide(100).value()
        innerTotal = _.sum([value, percentageValue])
        log.debug('added VAT', { innerTotal, vat })

        return innerTotal
      })
      .value()

    log.info('calculateTotal POST', calculatedTotal)

    return _.round(calculatedTotal, 2)
  }

  // calculate taxable
  const calculateTaxable = (netAmount, discountAmount = 0) =>
    _.subtract(netAmount, discountAmount)

  // calculate total of invoice
  useEffect(() => {
    const materialTotal = calculateMaterialTotal(
      materials,
      totalSubscriptionFee
    )
    const netAmount = _.sum([materialTotal, totalIntervention])
    const taxable = calculateTaxable(
      netAmount,
      _.get(totalFields, 'discountAmount')
    )

    const total = calculateTotal(taxable, _.get(totalFields, 'vat'))
    setTotalFields({
      ...totalFields,
      materialTotal,
      netAmount,
      taxable,
      total,
    })
  }, [
    totalSubscriptionFee,
    selectedInterventions,
    materials,
    totalIntervention,
    _.get(totalFields, 'vat'),
    _.get(totalFields, 'discountAmount'),
  ])

  log.info('details', { selectedInterventions, totalFields })

  return (
    <>
      {/* Materials Total */}
      <GutteredRow>
        <Col offset={19}>
          <DisplayDetail
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}
            flexDirection={'row'}
            label={
              <FormattedMessage
                id={'ui.invoices.form.labels.materials_total'}
              />
            }
            value={<DisplayPrice value={_.get(totalFields, 'materialTotal')} />}
          />
        </Col>
      </GutteredRow>

      {/* Net Amount */}
      <GutteredRow>
        <Col offset={19}>
          <DisplayDetail
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}
            flexDirection={'row'}
            label={
              <FormattedMessage id={'ui.invoices.form.labels.net_amount'} />
            }
            value={<DisplayPrice value={_.get(totalFields, 'netAmount')} />}
          />
        </Col>
      </GutteredRow>

      {/* Discount Amount */}
      <GutteredRow>
        <Col offset={19}>
          <StyledHorizontalDiv maxWidth={'50%'}>
            <FieldCurrency
              name={'discount_amount'}
              required
              railsName={'discount_amount'}
              label={
                <FormattedMessage
                  id={'ui.invoices.form.labels.discount_amount'}
                />
              }
              onChange={(form, value) =>
                setTotalFields({ ...totalFields, discountAmount: value })
              }
              initialValue={_.get(invoice, 'discount_amount', 0.0)}
              disabled={isInvoiced(invoice)}
            />
          </StyledHorizontalDiv>
        </Col>
      </GutteredRow>

      {/*  Taxable */}
      <GutteredRow>
        <Col offset={19}>
          <DisplayDetail
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}
            flexDirection={'row'}
            label={<FormattedMessage id={'ui.invoices.form.labels.taxable'} />}
            value={<DisplayPrice value={_.get(totalFields, 'taxable')} />}
          />
        </Col>
      </GutteredRow>

      {/*  VAT Select */}
      <GutteredRow>
        <Col offset={19} xl={3}>
          <StyledHorizontalDiv unset>
            <SingleSelect
              label={'ui.invoices.form.labels.vat'}
              name={'vat'}
              required
              // @ts-ignore
              options={getNewOptions(['key', 'value'])(vat)}
              onSelect={(value) =>
                setTotalFields({ ...totalFields, vat: _.toNumber(value) })
              }
              defaultValue={_.get(totalFields, 'vat')}
              disabled={isInvoiced(invoice)}
            />
          </StyledHorizontalDiv>
        </Col>
        <Col>
          <StyledBoldContainer fontSize={'16px'}>
            <DisplayPrice
              value={_.multiply(
                _.get(totalFields, 'taxable'),
                _.divide(_.get(totalFields, 'vat'), 100)
              )}
            />
          </StyledBoldContainer>
        </Col>
      </GutteredRow>

      {/* Total Amount */}
      <GutteredRow>
        <Col offset={19}>
          <DisplayDetail
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
            }}
            flexDirection={'row'}
            label={
              <FormattedMessage id={'ui.invoices.form.labels.total_amount'} />
            }
            value={<DisplayPrice value={_.get(totalFields, 'total')} />}
          />
        </Col>

        {/*  Hidden Total Amount Field */}
        <Input
          name={'total_amount'}
          type={'hidden'}
          value={_.get(totalFields, 'total')}
        />
      </GutteredRow>
    </>
  )
}

export default CalculationFields
