import React from 'react'
import { Logger } from 'aws-amplify'
import { Form, Modal } from 'antd'
import { FormattedMessage } from 'react-intl'
import { CloseInterventionForm } from './index'
import _ from 'lodash'

const log = new Logger('CloseInterventionModal')

const CloseInterventionModal = ({
  modalData,
  initialValues,
  onCancel,
  resetFields = false,
}) => {
  log.debug({ modalData, initialValues })
  const [form] = Form.useForm()

  // handle form submit
  const handleSubmit = () => {
    log.debug('Submitting form...')
    form
      .validateFields()
      .then((values) => {
        // form.resetFields()
        form.submit()
      })
      .catch((info) => {
        log.warn('Validate Failed:', info)
      })
  }

  return (
    <Modal
      closable={false}
      destroyOnClose
      open={modalData.open}
      title={<FormattedMessage id={'ui.interventions.modals.close.title'} />}
      okText={
        _.get(modalData, 'disabled', false) ? undefined : (
          <FormattedMessage id={'ui.interventions.modals.close.ok_text'} />
        )
      }
      cancelText={
        _.get(modalData, 'disabled', false) ? undefined : (
          <FormattedMessage id={'ui.interventions.modals.close.cancel'} />
        )
      }
      footer={_.get(modalData, 'disabled', false) ? null : undefined}
      onOk={handleSubmit}
      onCancel={() => {
        if (resetFields) form.resetFields()
        onCancel()
      }}
      width={'70vw'}
    >
      <CloseInterventionForm
        form={form}
        disabled={_.defaultTo(_.get(modalData, 'disabled'), false)}
        action={_.get(modalData, 'action')}
        initialValues={initialValues}
      />
    </Modal>
  )
}

export default CloseInterventionModal
