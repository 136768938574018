import * as R from 'ramda'

/**
 * Check if intervention is invoiced and also invoice is submitted.
 * @param {*} intervention
 * @returns {boolean}
 */
export const fullyInvoiced = (intervention) =>
  R.pipe(
    R.props(['status', 'invoice_status']),
    R.all(R.equals('invoiced'))
  )(intervention)

export const notInvoiced = (intervention) =>
  R.both(R.propEq('invoiced', 'status'), (i) =>
    R.not(R.propEq('invoiced', 'invoice_status')(i))
  )(intervention)
