import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Col, Form, Row, Tabs } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import equipment_vehicles from '../../../../../../../../lib/enums/enum_facility_equipment.json'
import { GutteredRow } from '../../../.././Components'
import { DisplayDetail, LocaleDisplay } from '../../../../Components/Display'
import { InputField, TextAreaField } from '../../../../Components/Fields'
import { LocalSelect, TagSelect } from '../../../../Components/Select'
import { GlobalContext } from '../../../../contexts'
import { statuses, typologies } from '../../../../enums/facility'
import { getYearlyInterventionsVal, initSubscription } from '../../../../utils'
import { StatusTag } from '../../components'
import { CustomerContext, FacilityContextProvider } from '../../contexts'
import { FacilityAddress } from '../components/FacilityAddress'
import CallsTable from './CallsTable'
import { DocumentsTable } from './DocumentsTable'
import { ReferentsTable } from './ReferentsTable'
import { SubscriptionForm } from './SubscriptionForm'

const log = new Logger('FacilityForm')

// Single facility form fields
export const FacilityForm = ({ prefix, index, initialValues, loading }) => {
  // inject intl
  const intl = useIntl()

  const { mode } = useContext(GlobalContext)
  const { customer, setCustomer, readonly } = useContext(CustomerContext)

  log.debug(`Rendering ${index} facility form`)
  log.debug('Facility initial values', initialValues)

  const items = [
    {
      key: 'referents',
      label: intl.formatMessage({ id: 'ui.facilities.phases.referents' }),
      children: (
        <ReferentsTable
          dataSource={_.get(initialValues, 'referents', [])}
          prefix={`${prefix}[referents_attributes]`}
          propKey={index}
        />
      ),
    },
    {
      key: 'documents',
      label: intl.formatMessage({ id: 'ui.facilities.phases.documents' }),
      children: (
        <DocumentsTable
          dataSource={_.get(initialValues, 'documents', [])}
          prefix={`${prefix}[documents_attributes]`}
        />
      ),
    },
    ...(readonly
      ? [
          {
            key: 'calls',
            label: intl.formatMessage({ id: 'ui.calls.list_name' }),
            children: (
              <CallsTable dataSource={_.get(initialValues, 'calls', [])} />
            ),
          },
        ]
      : []),
  ]

  // form instance
  const form = Form.useFormInstance()

  const basePath = _.toPath(`facilities.${index}`)
  const subscriptionPath = [...basePath, 'subscription']

  // calculate fee
  const calculateFee = () => {
    // Rope bell fields
    const ropeBellsCount = parseInt(
      form.getFieldValue([...basePath, 'rope_bells_count'])
    )
    log.verbose('Rope Bells count: ', ropeBellsCount)

    const ropeBellFee = parseFloat(
      form.getFieldValue([...subscriptionPath, 'rope_bell_fee'])
    )
    log.verbose('Rope Bell fee: ', ropeBellFee)

    // Electrified bell fields
    const electrifiedBellsCount = parseInt(
      form.getFieldValue([...basePath, 'electrified_bells_count'])
    )
    log.verbose('Electrified Bells count: ', electrifiedBellsCount)

    const electrifiedBellFee = parseFloat(
      form.getFieldValue([...subscriptionPath, 'electrified_bell_fee'])
    )
    log.verbose('Electrified Bell fee: ', electrifiedBellFee)

    const fixedFee = parseFloat(
      form.getFieldValue([...subscriptionPath, 'fixed_fee'])
    )
    log.verbose('Fixed fee: ', fixedFee)

    const equipmentVehiclesCharge = parseFloat(
      form.getFieldValue([...subscriptionPath, 'equipment_vehicles_charge'])
    )
    log.verbose('Equipment vehicles charge: ', equipmentVehiclesCharge)

    const simData = parseFloat(
      form.getFieldValue([...subscriptionPath, 'sim_data'])
    )
    log.verbose('Sim data: ', simData)

    const nInterventions = getYearlyInterventionsVal(
      form.getFieldValue([...subscriptionPath, 'yearly_interventions'])
    )
    log.verbose('N interventions: ', nInterventions)

    let calculatedFee =
      (ropeBellFee * ropeBellsCount +
        electrifiedBellFee * electrifiedBellsCount +
        (fixedFee + equipmentVehiclesCharge + simData)) *
      nInterventions

    if (_.isNaN(calculatedFee)) return 0
    return _.round(calculatedFee, 2)
  }

  // initial value for fee
  const [fee, setFee] = useState(calculateFee())

  log.debug('Current form values', form.getFieldsValue())

  return (
    <FacilityContextProvider value={{ facility: initialValues }}>
      <GutteredRow>
        {/* Name */}
        <Col xl={4}>
          <InputField
            name={`${index}.business_name`}
            railsName={`${prefix}[business_name]`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.business_name',
            })}
            required
          />
        </Col>

        {/* Latitude */}
        <Col xl={4}>
          <InputField
            name={`${index}.latitude`}
            railsName={`${prefix}[latitude]`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.latitude',
            })}
            required
          />
        </Col>

        {/* Longitude */}
        <Col xl={4}>
          <InputField
            name={`${index}.longitude`}
            railsName={`${prefix}[longitude]`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.longitude',
            })}
            required
          />
        </Col>

        {/* HQ Distance */}
        <Col xl={4}>
          <InputField
            name={`${index}.hq_distance`}
            railsName={`${prefix}[hq_distance]`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.hq_distance',
            })}
            required
          />
        </Col>

        {/* Route Duration */}
        <Col xl={4}>
          <InputField
            name={`${index}.route_duration`}
            railsName={`${prefix}[route_duration]`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.route_duration',
            })}
            required
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        {/* Rope Bells Count */}
        <Col xl={3}>
          <InputField
            name={`${index}.rope_bells_count`}
            railsName={`${prefix}[rope_bells_count]`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.rope_bells_count',
            })}
            type={'number'}
            required
            onChange={() => {
              const calculatedFee = calculateFee()
              log.debug('Calculated Fee', calculatedFee)
              setFee(_.defaultTo(calculatedFee, 0))
            }}
          />
        </Col>

        {/* Electrified Bells Count */}
        <Col xl={3}>
          <InputField
            name={`${index}.electrified_bells_count`}
            railsName={`${prefix}[electrified_bells_count]`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.electrified_bells_count',
            })}
            type={'number'}
            required
            onChange={() => {
              const calculatedFee = calculateFee()
              log.debug('Calculated Fee', calculatedFee)
              setFee(_.defaultTo(calculatedFee, 0))
            }}
          />
        </Col>

        {/* Typology */}
        <Col xl={4}>
          <LocalSelect
            name={`${index}.typology`}
            railsName={`${prefix}[typology]`}
            label={'ui.facilities.attributes.typology'}
            defaultValue={_.get(initialValues, 'typology')}
            options={typologies}
            localAttribute={'ui.facilities.enums.typologies'}
          />
        </Col>

        {/* Equipment vehicles */}
        <Col xl={6}>
          <TagSelect
            name={`${index}.required_equipment_vehicles`}
            railsName={`${prefix}[required_equipment_vehicles][]`}
            label={'ui.facilities.attributes.required_equipment_vehicles'}
            options={equipment_vehicles}
            defaultValue={_.get(
              initialValues,
              'required_equipment_vehicles',
              []
            )}
            disabled={mode === 'detail'}
          />
        </Col>

        {mode === 'edit' && (
          <Col xl={4}>
            <LocalSelect
              name={`${index}.status`}
              railsName={`${prefix}[status]`}
              label={`ui.facilities.attributes.status`}
              options={statuses}
              localAttribute={`ui.facilities.enums.statuses`}
              defaultValue={_.get(initialValues, 'status', 'active')}
            />
          </Col>
        )}

        {mode === 'detail' && (
          <Col xl={4}>
            <Row style={{ flexDirection: 'column' }}>
              <Col style={{ paddingBottom: '8px', color: '#c8c5c5' }}>
                <FormattedMessage id={'ui.facilities.attributes.status'} />
              </Col>
              <Col>
                <StatusTag status={_.get(initialValues, 'status')}>
                  <LocaleDisplay
                    localePath={'ui.facilities.enums.statuses'}
                    value={_.get(initialValues, 'status')}
                  />
                </StatusTag>
              </Col>
            </Row>
          </Col>
        )}
      </GutteredRow>

      <GutteredRow>
        <Col xl={10}>
          {/*  Address notes */}
          <TextAreaField
            name={`${index}.notes`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.notes',
            })}
            railsName={`${prefix}[notes]`}
          />
        </Col>
        <Col xl={10}>
          {/*  Technical notes */}
          <TextAreaField
            name={`${index}.technical_notes`}
            label={intl.formatMessage({
              id: 'ui.facilities.attributes.technical_notes',
            })}
            railsName={`${prefix}[technical_notes]`}
          />
        </Col>
      </GutteredRow>

      {/* Images Link */}
      <GutteredRow>
        {mode === 'detail' ? (
          <Col>
            <DisplayDetail
              value={R.unless(R.either(R.isNil, R.isEmpty), (value) => (
                <a target='_blank' href={value}>
                  {value}
                </a>
              ))(R.prop('images_link', initialValues))}
              label={
                <FormattedMessage id='ui.facilities.attributes.images_link' />
              }
            />
          </Col>
        ) : (
          <Col xl={10}>
            <InputField
              label={
                <FormattedMessage id='ui.facilities.attributes.images_link' />
              }
              name={`${index}.images_link`}
              railsName={`${prefix}[images_link]`}
            />
          </Col>
        )}
      </GutteredRow>

      {/*  Address Section */}
      <FacilityAddress
        index={index}
        prefix={'address'}
        railsNamePrefix={`${prefix}[address_attributes]`}
        initialValues={_.get(initialValues, 'address', null)}
        loading={loading}
      />

      {/* Optional */}
      <SubscriptionForm
        initialValues={_.get(initialValues, 'subscription', initSubscription)}
        sectionTitle={'ui.facilities.subscription_details'}
        index={index}
        prefix={`${prefix}[subscription_attributes]`}
        fee={fee}
        setFee={setFee}
      />

      {/* Hidden Facility ID */}
      {initialValues?.id && (
        <InputField hidden name={`${index}.id`} railsName={`${prefix}[id]`} />
      )}
      {/*  Referents / Docs Tabs */}
      <Tabs items={items} />
    </FacilityContextProvider>
  )
}
