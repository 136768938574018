import { Auth, Logger } from 'aws-amplify'
import axios from 'axios'
import * as R from 'ramda'

export * from './customers'
export * from './facilities'
export * from './documents'
export * from './calls'
export * from './interventions'
export * from './routes'
export * from './materials'
export * from './invoices'

const csrfToken = document.querySelector('[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const log = new Logger('api')

/**
 * @typedef ApiRequestOptions
 * @type {Object}
 * @property {Object} body
 * @property {?Object} queryParams
 */

/**
 * Make an API request providing required
 * @param {string} endpoint
 * @param {string} method
 * @param {?ApiRequestOptions} options
 */
export const makeApiRequest = async (
  endpoint,
  method,
  options = {
    body: {},
    queryParams: null,
  }
) => {
  log.debug('makeApiRequest.params', { endpoint, method, options })

  const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()

  const { body: data, queryParams: params } = options

  const headers = {
    Authorization: jwtToken,
  }

  log.debug('makeApiRequest', {
    data,
    params,
    headers,
  })

  return await axios({
    url: endpoint,
    headers,
    method,
    params,

    ...(R.toUpper(method) != 'GET' && {
      data,
    }),
  })
}
