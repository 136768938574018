import { Button, Card, Col, DatePicker, Divider } from 'antd'
import styled from 'styled-components'

import FieldInputNumber from '././Components/Fields/FieldInputNumber'
import { FieldCurrency, InputField, TimePickerField } from './Components/Fields'

export const StyledBeforeText = styled.div`
  height: 1px;
  border: 0.5px solid black;
`

export const StyledDivider = styled(Divider)`
  border-block-start: 0 rgba(5, 5, 5, 0.5) !important;
`

export const StyledDetailLabel = styled.span`
  color: rgb(45, 51, 71);
`

export const StyledBoldDetail = styled(StyledDetailLabel)`
  font-weight: bold;
`

export const StyledCard = styled(Card)`
  margin-top: 20px;
`

export const StyledCenteredDiv = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`
export const StyledRightInput = styled(InputField)`
  input {
    text-align: right !important;
  }
`

export const StyledInputNumber = styled(FieldInputNumber)`
  .ant-input-number {
    width: ${(props) => props.inputWidth || '100%'};
  }
`

export const StyledFieldCurrency = styled(FieldCurrency)`
  .ant-input-number-group-wrapper {
    width: 100%;
  }
`
export const StyledRightCol = styled(Col)`
  text-align: right;
`

export const StyledClickableSpan = styled.span`
  margin-right: 5px;
  transform: scale(1.1233);
  cursor: pointer;
`

export const StyledContainer = styled.div`
  width: 100%;

  > * {
    margin-bottom: 20px;
  }
`

export const StyledRightContainer = styled(StyledContainer)`
  display: flex;
  justify-content: flex-end;
`

export const StyledEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

export const StyledHorizontalDiv = styled.div`
  .ant-row.ant-form-item-row {
    flex-direction: row;
    align-items: center;
  }

  .ant-form-item-label {
    padding: 0;
    margin-right: 5px;
  }

  .ant-form-item-control {
    max-width: ${(props) => props.maxWidth || '100%'};
    width: ${(props) => (props.unset ? 'unset !important' : '100%')};
  }
`

export const StyledDetailTimePicker = styled(TimePickerField)`
  .ant-picker-input > input[disabled] {
    color: black !important;
  }
`

export const StyledCustomButton = styled(Button)`
  background-color: ${(props) => props.color};
  color: #fefefe !important;
  :hover {
    background: ${(props) => props.hoverColor} !important;
    color: #fefefe !important;
  }
`
