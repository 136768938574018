import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'

import { fetchBreakStage } from '..'

const log = new Logger('Routes/marshal')

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export const marshalRoute = (route) => {
  if (_.isNil(route.id))
    return {
      ...route,
      vehicles: [],
      team: [],
      interventions: [fetchBreakStage()],
    }
  else {
    return {
      ...route,
      scheduled_datetime: dayjs(route.scheduled_datetime),
      team: _.get(route, 'team', []) || [],
      vehicles: _.get(route, 'vehicles', []) || [],
      interventions: _.map(route.interventions, (i) =>
        marshalIntervention(
          i,
          dayjs(dayjs(route.scheduled_datetime).format('YYYY-MM-DD'))
        )
      ),
    }
  }
}

const marshalIntervention = (intervention, scheduled_date) => {
  let expectedEndTime = null
  if (!_.isNull(_.get(intervention, 'expected_end_time'))) {
    const end_time = dayjs
      .utc(_.get(intervention, `expected_end_time`), 'HH:mm')
      .local()

    expectedEndTime = scheduled_date
      .add(end_time.get('h'), 'h')
      .add(end_time.get('m'), 'm')
  }

  const scheduledTime = dayjs(_.get(intervention, 'scheduled_datetime'))
  const totalTime = _.invoke(expectedEndTime, 'diff', scheduledTime, 's')
  return {
    ...intervention,
    scheduled_time: scheduledTime,
    expected_end_time: expectedEndTime,
    totalTime,
  }
}
