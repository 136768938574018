// Single Select

import React, { useContext, useEffect, useState } from 'react'
import { Form as AntdForm, Input, Select } from 'antd'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { DownOutlined } from '@ant-design/icons'
import { GlobalContext } from '../../contexts'

const log = new Logger('MultipleSelect')

const MultipleSelect = ({
  name,
  options,
  label,
  railsName = name,
  required = false,
  defaultValue = [],
  disabled = false,
  onChange = undefined,
}) => {
  const [selectedValues, setSelectedValues] = useState(defaultValue)
  const handleChange = (value, options) => {
    log.debug(`select value: ${value} `)
    log.debug('selected options:', options)
    setSelectedValues(value)
  }

  useEffect(() => {
    if (_.isFunction(onChange)) onChange(selectedValues)
  }, [selectedValues])

  const { mode } = useContext(GlobalContext)

  return (
    <>
      {/* Hidden fields for rails */}
      {_.map(selectedValues, (v, index) => (
        <Input
          name={railsName}
          key={index}
          type={'hidden'}
          value={v}
          readOnly
        />
      ))}

      <AntdForm.Item
        name={_.toPath(name)}
        label={<FormattedMessage id={label} />}
        rules={[
          {
            required: required,
          },
        ]}
      >
        <Select
          mode={'multiple'}
          options={options}
          optionFilterProp={'label'}
          showSearch
          onChange={handleChange}
          suffixIcon={<DownOutlined />}
          showArrow
          disabled={_.defaultTo(disabled, mode === 'detail')}
        ></Select>
      </AntdForm.Item>
    </>
  )
}

export default MultipleSelect
