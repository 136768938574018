import {  GutteredRow } from '../../.././Components'
import { Col } from 'antd'
import { FormattedMessage } from 'react-intl/lib'
import dayjs from 'dayjs'
import React from 'react'
import _ from 'lodash'
import {Display, LocaleDisplay} from "../../../Components/Display";

const ModalInterventionDetail = ({ intervention }) => {
  return (
    <>
      <GutteredRow>
        <Col>
          <FormattedMessage id={`ui.routes.interventions.id`} />:
          <Display value={_.get(intervention, 'id')} />
        </Col>
      </GutteredRow>
      <GutteredRow>
        <Col>
          <FormattedMessage id={`ui.routes.interventions.expected_at`} />:
        </Col>
        <Col>{dayjs(intervention.expected_date).format('DD/MM/YYYY')}</Col>
      </GutteredRow>
      <GutteredRow>
        <Col>
          <FormattedMessage id={'ui.interventions.form.labels.typology'} />:
        </Col>
        <Col>
          <LocaleDisplay
            localePath={'ui.interventions.enums.typologies'}
            value={intervention.typology}
          />
        </Col>
      </GutteredRow>
      <GutteredRow>
        <Col>
          <FormattedMessage id={'ui.interventions.form.labels.notes'} />:
        </Col>
        <Col>
          <Display value={_.get(intervention, 'notes')} />
        </Col>
      </GutteredRow>
    </>
  )
}

export default ModalInterventionDetail
