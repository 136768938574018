import { getKeyByStorageUrl } from '../helpers'
import { S3_DOCS_BUCKET } from '../../config'

import _ from 'lodash'
import axios from 'axios'
import { Logger } from 'aws-amplify'
import * as s3 from "../../lib";

const log = new Logger('documents/marshal')
export const marshalForFileList = async (document) => {
  if (_.isNil(document)) return undefined
  const fileKey = getKeyByStorageUrl(
    _.get(document, 'storage_url'),
    S3_DOCS_BUCKET
  )

  const presignedUrl = await s3.getPresignedUrl(fileKey)

  return {
    ...document,
    url: presignedUrl,
  }
}
