import React, { useContext, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import * as FInterventions from '@utils/interventions/functions'
import { Button, Col, Dropdown, Form, Popconfirm, Row, Space } from 'antd'
import { Logger } from 'aws-amplify'
import _, { first } from 'lodash'
import * as R from 'ramda'
import styled from 'styled-components'

import { StyledCustomButton } from '@/styled'

import { useReferrer } from '../../../Components/Providers/ReferrerWrapperProvider'
import { GlobalContext } from '../../../contexts'
import { InterventionContext } from '../contexts'

const log = new Logger('InterventionFormHeader')
const StyledDiv = styled.div`
  margin-bottom: 20px;
`

const FormHeader = ({ title, mode, buttonType = {} }) => {
  const { setLoading: setGlobalLoading } = useContext(GlobalContext)
  const { intervention, closeModalData, setCloseModalData } =
    useContext(InterventionContext)

  const handleMenuClick = (e) => {
    log.debug('menu clicked', e)

    setCloseModalData({ ...closeModalData, open: true, action: e.key })
  }

  const firstLoad = useRef(true)

  const items = [
    {
      key: 'reschedule',
      label: 'Apri Continuazione',
      danger: true,
    },
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  const handleButtonClick = (e, disabled = false, action = 'close') => {
    log.debug('left button clicked', e)
    setCloseModalData({
      ...closeModalData,
      open: true,
      action,
      disabled,
    })
  }

  const { referrer, baseUrl, queryParams } = useReferrer()

  const buildReturnToList = () => {
    let backUrl

    const ref = R.propOr('', 'ref', queryParams)

    if (R.or(R.includes('invoices', ref), R.includes('routes', ref))) return ref

    backUrl = R.ifElse(
      R.includes('interventions'),
      R.always('/interventions'),
      R.identity
    )(referrer)

    backUrl = referrer ? backUrl : '/interventions'
    if (_.includes(referrer, 'routes')) {
      backUrl = `${baseUrl}?expanded_route=${_.get(intervention, 'route.id')}`
    }

    return backUrl
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
      return
    }
  }, [referrer])

  const showReschedule = R.and(
    R.includes(R.prop('status', intervention), [
      'in_progress',
      'accounted',
      'terminated',
    ]),
    R.not(R.prop('any_childs', intervention))
  )

  const backUrl = buildReturnToList()

  return (
    !firstLoad.current && (
      <>
        {title}
        <Row justify={'space-between'}>
          <Col>
            <StyledDiv>
              <Button
                type={'primary'}
                href={buildReturnToList()}
                onClick={() => setGlobalLoading(true)}
              >
                <FormattedMessage
                  id={'ui.interventions.actions.back_to_list'}
                />
              </Button>
            </StyledDiv>
          </Col>

          <Col
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Space align='start'>
              {R.includes(R.prop('status', intervention), [
                'in_progress',
                'to_schedule',
              ]) &&
                mode === 'detail' && (
                  <Button
                    type='primary'
                    href={
                      intervention.call_id
                        ? `/calls/${intervention.call_id}/interventions/${intervention.id}/edit?ref=${backUrl}`
                        : `/interventions/${intervention.id}/edit?ref=${backUrl}`
                    }
                  >
                    <FormattedMessage id={'ui.interventions.buttons.edit'} />
                  </Button>
                )}

              {mode !== 'detail' && (
                <Form.Item>
                  <Button {...buttonType} htmlType={'submit'}>
                    <FormattedMessage id={'ui.button.submit'} />
                  </Button>
                </Form.Item>
              )}

              {/* Account Intervention if in progress and on detail */}
              {mode === 'detail' &&
                _.get(intervention, 'status') === 'in_progress' && (
                  <>
                    <StyledCustomButton
                      color='#f3bb5d'
                      hoverColor='#aa8241'
                      href={`/interventions/${_.get(
                        intervention,
                        'id'
                      )}/unschedule`}
                      onClick={() => setGlobalLoading(true)}
                    >
                      <FormattedMessage
                        id={'ui.interventions.buttons.unschedule'}
                      />
                    </StyledCustomButton>

                    <StyledCustomButton
                      color='#9254de'
                      hoverColor='#663a9b'
                      onClick={() =>
                        setCloseModalData({
                          ...closeModalData,
                          open: true,
                          action: 'account',
                        })
                      }
                    >
                      <FormattedMessage
                        id={'ui.interventions.buttons.to_account'}
                      />
                    </StyledCustomButton>
                  </>
                )}

              {/* Show Reschedule */}
              {mode === 'detail' && showReschedule && (
                <Popconfirm
                  title={
                    <FormattedMessage id='ui.interventions.popconfirm.reschedule.title' />
                  }
                  description={
                    <FormattedMessage id='ui.interventions.popconfirm.reschedule.description' />
                  }
                  okButtonProps={{
                    href: `/interventions/${intervention.id}/reschedule`,
                    style: {
                      marginLeft: '5px',
                    },
                  }}
                  okText='Conferma'
                  onConfirm={() => setGlobalLoading(true)}
                >
                  <Button>
                    <span>Apri Continuazione</span>
                  </Button>
                </Popconfirm>
              )}

              {/* Edit accounting when terminated */}
              {mode === 'detail' &&
                R.either(
                  R.propEq('accounted', 'status'),
                  R.either(
                    R.propEq('terminated', 'status'),
                    FInterventions.notInvoiced
                  )
                )(intervention) && (
                  <Button
                    type={'primary'}
                    onClick={(e) => {
                      const baseAction =
                        intervention.status === 'terminated'
                          ? 'close'
                          : 'edit_account'

                      const action = R.includes('invoices', baseUrl)
                        ? R.concat(baseAction, `?ref=${baseUrl}`)
                        : baseAction

                      handleButtonClick(e, false, action)
                    }}
                  >
                    <FormattedMessage
                      id={'ui.interventions.buttons.edit_accounting'}
                    />
                  </Button>
                )}

              {mode === 'detail' &&
                _.get(intervention, 'status') === 'accounted' && (
                  <StyledCustomButton
                    color='#acb2c8'
                    hoverColor='#787c8c'
                    onClick={handleButtonClick}
                  >
                    <FormattedMessage id={'ui.interventions.buttons.close'} />
                  </StyledCustomButton>
                )}

              {/* Show accounting when invoiced */}
              {mode === 'detail' &&
                FInterventions.fullyInvoiced(intervention) && (
                  <Button onClick={(e) => handleButtonClick(e, true)}>
                    <FormattedMessage
                      id={'ui.interventions.buttons.show_accounting'}
                    />
                  </Button>
                )}
            </Space>
          </Col>
        </Row>
      </>
    )
  )
}

export default FormHeader
