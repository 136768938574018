import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Button, Col, DatePicker, Row, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import * as R from 'ramda'

import { CALL_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'

import { CenteredSpinner, GutteredRow } from '../.././Components'
import { GlobalContext } from '../../contexts'
import { StyledCard } from '../../styled'
import { CallSearch, CallsTable } from './components'
import { CallsContextProvider } from './contexts'

dayjs.extend(customParseFormat)

const { Title } = Typography
const { RangePicker } = DatePicker

const log = new Logger('Calls/Index')

const Index = (props) => {
  log.debug('Rendering calls view ...', props)

  const intl = useIntl()

  // get calls data
  const [calls, setCalls] = useState(props.calls)

  log.info('Calls data: ', calls)

  // loading state
  const [loading, setLoading] = useState(false)

  // search loading state
  const [searchLoading, setSearchLoading] = useState(false)

  // query
  const [query, setQuery] = useState(
    F.retrieveFiltersOr('', ['calls', 'query'])
  )
  // filters
  const [filters, setFilters] = useState(
    F.retrieveFiltersOr([], ['calls', 'filters'])
  )
  // ranges
  const [ranges, setRanges] = useState(
    F.retrieveFiltersOr([], ['calls', 'ranges'])
  )
  // orders
  const [orders, setOrders] = useState(
    F.retrieveFiltersOr([], ['calls', 'orders'])
  )

  const [pagination, setPagination] = useState(
    F.retrieveFiltersOr(DEFAULT_PAGER, ['calls', 'pagination'])
  )

  const { setMode } = useContext(GlobalContext)

  useEffect(() => {
    setMode('list')
  }, [])

  const handleRangeChange = (dates) => {
    if (_.isEmpty(dates)) {
      setRanges([])
      return
    }
    const startDate = dates[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const endDate = dates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')

    log.debug('parsed dates', { startDate, endDate })

    setRanges([
      {
        field: 'requested_at',
        from: startDate,
        to: endDate,
      },
    ])
  }

  const filterCalls = async () => {
    setSearchLoading(true)

    const { data, headers } = await makeApiRequest(CALL_SEARCH, 'post', {
      body: { query, filters, ranges, orders },
      queryParams: F.marshalPaginationTo(pagination),
    })

    const requestPagination = F.marshalPaginationFrom(headers)

    const newPagination = {
      ...pagination,
      ...requestPagination,
    }

    setPagination(newPagination)

    F.storeFilters({
      calls: { query, filters, ranges, orders, pagination: newPagination },
    })
    log.debug('filterCalls.search ', { pagination, data, headers })
    setSearchLoading(false)
    setCalls(data)
  }

  return loading ? (
    <CenteredSpinner />
  ) : (
    <CallsContextProvider
      value={{
        calls,
        setCalls,
        query,
        setQuery,
        filters,
        setFilters,
        ranges,
        orders,
        setOrders,
        pagination,
        setPagination,
        searchLoading,
        setSearchLoading,
      }}
    >
      <GutteredRow>
        <Col>
          <Title level={3}>
            <FormattedMessage id={'ui.calls.list_name'} />
          </Title>
        </Col>
      </GutteredRow>
      <Row justify={'space-between'}>
        <Col xl={10}>
          {/* calls search*/}
          <CallSearch
            onSearch={() => {
              setPagination({ total: pagination.total, ...DEFAULT_PAGER })
            }}
          />
        </Col>
        <Col xl={6}>
          {/*  range picker for filter*/}
          <RangePicker
            placeholder={[
              intl.formatMessage({ id: 'ui.placeholders.date.from' }),
              intl.formatMessage({ id: 'ui.placeholders.date.to' }),
            ]}
            defaultValue={F.rangeForPicker(R.head(ranges))}
            format={'DD-MM-YYYY'}
            onChange={handleRangeChange}
          />
        </Col>
        <Col
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type='primary'
            href='/calls/new'
            onClick={() => setLoading(!loading)}
          >
            <FormattedMessage id={'ui.calls.buttons.create'} />
          </Button>
        </Col>
      </Row>
      <StyledCard>
        <CallsTable dataSource={calls} searchCalls={filterCalls} />
      </StyledCard>
    </CallsContextProvider>
  )
}

export default Index
