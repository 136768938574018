import { Form as AntdForm, Input } from 'antd'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { StyledDatePicker } from '../../styled'
import { GlobalContext } from '../../contexts'

const log = new Logger('DatePickerField')
const DatePickerField = ({
  name,
  label,
  onChange = undefined,
  initialValue = null,
  placeholder = null,
  railsName = null,
  required = false,
  disabled = undefined,
}) => {
  log.verbose('Rendering date picker field ...')
  const intl = useIntl()
  const validateRequired = () => {
    return required
      ? [
          {
            required: true,
          },
        ]
      : ''
  }

  const [selectedDate, setSelectedDate] = useState(initialValue)

  const handleChange = (date, dateString) => {
    log.verbose('Selected date', date)
    log.verbose('Selected date string', dateString)
    const isoDate = _.invoke(date, 'format', 'YYYY-MM-DD')
    setSelectedDate(isoDate)

    if (_.isFunction(onChange)) onChange(date)
  }

  const { mode } = useContext(GlobalContext)

  return (
    <>
      <AntdForm.Item
        name={_.toPath(name)}
        label={label}
        required={required}
        rules={validateRequired()}
      >
        <StyledDatePicker
          disabled={_.defaultTo(disabled, mode === 'detail')}
          onChange={handleChange}
          format={'DD-MM-YYYY'}
        />
      </AntdForm.Item>
      <Input type={'hidden'} name={railsName} value={selectedDate} />
    </>
  )
}

export default DatePickerField
