import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { CustomForm } from './index'
import { Button, Form as AntdForm } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

const onFinish = async () => {
  await Auth.signOut()

  // submit form
  document.getElementById('signout-form').submit()
}

const SignOutForm = () => {
  const [loading, setLoading] = useState(false)

  return (
    <CustomForm
      method={'post'}
      action={'/auth/sign_out'}
      name={'signout-form'}
      onFinish={onFinish}
    >
      <AntdForm.Item
        style={{
          margin: 0,
        }}
      >
        <Button
          type={'primary'}
          htmlType={'submit'}
          icon={<LogoutOutlined />}
          loading={loading}
          onClick={() => setLoading(!loading)}
          style={{
            backgroundColor: '#001529',
          }}
        />
      </AntdForm.Item>
    </CustomForm>
  )
}

export default SignOutForm
