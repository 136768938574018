import React from 'react'
import { Tag } from 'antd'

const StatusTag = ({ status, children }) => {
  let color = null
  switch (status) {
    case 'to_schedule':
      color = '#F3BB5D'
      break
    case 'in_progress':
      color = '#4096FF'
      break
    case 'terminated':
      color = '#ACB2C8'
      break
    case 'invoiced':
      color = '#35C2A2'
      break
    case 'accounted':
      color = '#9254DE'
      break
  }
  return <Tag color={color}>{children}</Tag>
}

export default StatusTag
