import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Col, Row, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as R from 'ramda'

import { Ranges } from '@components/Search'
import { CenteredSpinner, GutteredRow } from '@components/index'

import { makeApiRequest } from '@/api'
import { DEVICE_ALARM_SEARCH } from '@/api/device_alarms'
import { DEFAULT_PAGER } from '@/config'
import { GlobalContext } from '@/contexts'
import { StyledCard } from '@/styled'

import { DeviceAlarmSearch, DeviceAlarmsTable } from './components'
import { DeviceAlarmsContextProvider } from './contexts'

dayjs.extend(customParseFormat)

const { Title } = Typography

const log = new Logger('DeviceAlarms/index')

const Index = (props) => {
  const intl = useIntl()

  const [deviceAlarms, setDeviceAlarms] = useState(
    R.propOr([], 'device_alarms', props)
  )

  log.info('props.device_alarms', deviceAlarms)

  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [query, setQuery] = useState(
    F.retrieveFiltersOr('', ['device_alarms', 'query'])
  )
  const [pagination, setPagination] = useState(
    F.retrieveFiltersOr(DEFAULT_PAGER, ['device_alarms', 'pagination'])
  )

  const [ranges, setRanges] = useState(
    F.retrieveFiltersOr([], ['device_alarms', 'ranges'])
  )

  const isTableChanged = useRef(true)

  const { setMode } = useContext(GlobalContext)

  useEffect(() => {
    setMode('list')
  }, [])

  const searchDeviceAlarms = async () => {
    setSearchLoading(true)
    const { data, headers } = await makeApiRequest(
      DEVICE_ALARM_SEARCH,
      'post',
      {
        body: { query, ranges },
        queryParams: F.marshalPaginationTo(pagination),
      }
    )

    const newPagination = {
      ...pagination,
      ...F.marshalPaginationFrom(headers),
    }

    F.storeFilters({
      device_alarms: {
        query,
        ranges,
        pagination: newPagination,
      },
    })

    log.debug('searchDevices.post', { data, headers })

    setPagination(newPagination)
    setSearchLoading(false)
    setDeviceAlarms(data)
  }

  return loading ? (
    <CenteredSpinner />
  ) : (
    <DeviceAlarmsContextProvider
      value={{
        deviceAlarms,
        searchLoading,
        setSearchLoading,
        query,
        setQuery,
        pagination,
        setPagination,
        setDeviceAlarms,
        ranges,
        setRanges,
        isTableChanged,
      }}
    >
      <GutteredRow>
        <Col>
          <Title level={3}>
            <FormattedMessage id={'ui.device_alarms.list_name'} />
          </Title>
        </Col>
      </GutteredRow>
      <Row justify={'space-between'}>
        <Col xl={10}>
          <DeviceAlarmSearch
            onSearch={() => {
              setPagination({ total: pagination.total, ...DEFAULT_PAGER })
              isTableChanged.current = true
            }}
          />
        </Col>
        <Col xl={6}>
          {/*  range picker for filter*/}
          <Ranges
            field={'timestamp'}
            defaultValue={F.rangeForPicker(R.head(ranges))}
            onChange={(dates) => {
              setRanges(dates)
              isTableChanged.current = true
            }}
          />
        </Col>
      </Row>
      <StyledCard>
        <DeviceAlarmsTable
          dataSource={deviceAlarms}
          search={searchDeviceAlarms}
        />
      </StyledCard>
    </DeviceAlarmsContextProvider>
  )
}

export default Index
