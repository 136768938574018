import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button, Col, Input, Space, Table, message } from 'antd'
import { Auth, Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import styled from 'styled-components'

import { GutteredRow, ModalDelete } from '../../../.././Components'
import DeleteRecord from '../../../.././Components/DeleteRecord'
import { Display, LocaleDisplay } from '../../../../Components/Display'
import { DOCUMENT_SEARCH, makeApiRequest } from '../../../../api'
import { S3_DOCS_BUCKET } from '../../../../config'
import { GlobalContext } from '../../../../contexts'
import { typologies } from '../../../../enums'
import * as s3 from '../../../../lib'
import {
  addPropertyToColumns,
  download,
  formatDate,
  getFileNameByKey,
  getKeyByStorageUrl,
} from '../../../../utils'
import {
  CustomerContext,
  DocumentsContextProvider,
  FacilityContext,
} from '../../contexts'
import DocumentsSearch from '../document/DocumentsSearch'
import { DocumentModal } from './DocumentModal'

dayjs.extend(customParseFormat)

const log = new Logger('DocumentsTable')

const StyledSearchDiv = styled.div`
  margin-bottom: 20px;
`

const StyledTableFooter = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`
/* Documents Table */
export const DocumentsTable = ({ dataSource, prefix }) => {
  // inject intl
  const intl = useIntl()

  const { customer, readonly } = useContext(CustomerContext)
  const { facility } = useContext(FacilityContext)

  log.debug('Facility', facility)

  // handle row and document delete
  const handleDelete = async (key) => {
    const selectedDocument = _.find(documents, (item, index) => index === key)
    log.debug('Selected document', selectedDocument)

    const newData = documents.filter((item, index) => index !== key)
    setDocuments(newData)
    setFileList([])

    return true
  }

  // handle download
  const handleDownload = async (record) => {
    log.verbose('Downloading file ...')
    const fileKey = getKeyByStorageUrl(record.storage_url, S3_DOCS_BUCKET)
    const presignedUrl = await s3.getPresignedUrl(fileKey)
    const fileName = getFileNameByKey(fileKey)

    try {
      await download(presignedUrl, fileName)
    } catch (err) {
      log.error('Error downloading file: ', err)
      message.error(`Error downloading file: ${err}`)
    }
  }
  // documents columns
  const columns = (() => {
    let columns = [
      {
        title: intl.formatMessage({ id: 'ui.documents.attributes.name' }),
        dataIndex: 'name',
        render: (_, record) => <Display value={record.name} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.documents.attributes.typology' }),
        dataIndex: 'typology',
        render: (_, record) => (
          <LocaleDisplay
            localePath={`ui.documents.enums.typologies`}
            value={record.typology}
          />
        ),
        ...(readonly
          ? {
              filters: _.map(typologies, (v) => {
                return {
                  text: intl.formatMessage({
                    id: `ui.documents.enums.typologies.${v}`,
                  }),
                  value: v,
                }
              }),
            }
          : []),
      },
      {
        title: intl.formatMessage({ id: 'ui.documents.attributes.format' }),
        dataIndex: 'format',
        render: (_, record) => (
          <LocaleDisplay
            localePath={`ui.documents.enums.formats`}
            value={record.format}
          />
        ),
      },
      {
        title: intl.formatMessage({ id: 'ui.documents.attributes.created_at' }),
        dataIndex: 'created_at',
        render: (_, record) => (
          <Display value={formatDate(record.created_at, 'DD/MM/YYYY')} />
        ),
      },

      {
        title: intl.formatMessage({
          id: 'ui.actions.title',
        }),
        key: 'action',
        render: (_, record, index) => (
          <>
            {/* ACTIONS */}
            <Space>
              {/* DELETE */}

              {!readonly && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setDeleteModalData({
                      open: true,
                      index,
                      document: record,
                    })
                  }}
                ></Button>
              )}

              {/* DOWNLOAD */}
              <Button
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record)}
                disabled={false}
              />
            </Space>
          </>
        ),
      },
    ]

    log.verbose('Adding responsive property to columns ...')
    return addPropertyToColumns(columns, 'responsive', ['md'])
  })()

  // modal state
  const [open, setOpen] = useState(false)

  // documents data source
  const [documents, setDocuments] = useState(dataSource ?? [])

  // search loading
  const [searchLoading, setSearchLoading] = useState(false)

  // delete modal state
  const [deleteModalData, setDeleteModalData] = useState({
    open: false,
  })

  // deleted documents
  const [deletedDocuments, setDeletedDocuments] = useState([])

  log.debug('Documents to delete', deletedDocuments)
  log.debug('Documents: ', documents)

  // documents file list
  const [fileList, setFileList] = useState([])

  // index for table key if id not provided
  const [index, setIndex] = useState(0)

  // marshal document for dataSource
  const marshalDocument = (document, storageUrl = null) => {
    log.debug('Current index', index)
    return {
      ...document,
      format: _.chain(document)
        .get('dragger[0].name')
        .split('.')
        .thru((v) => v[1].split('_'))
        .value()[0],
      storage_url: storageUrl,
      created_at: dayjs(_.get(document, 'created_at')),
      key: (() => {
        setIndex(index + 1)
        return index
      })(),
    }
  }

  const onCreate = async (document) => {
    log.info('Received form values...', document)

    let res = null
    if (!_.isEmpty(fileList)) {
      res = await s3.uploadFile(
        fileList[0],
        `documents/customers/${customer.id}/facilities`
      )

      if (_.get(res, '$metadata.httpStatusCode') >= 400) {
        message.error(res.message)
        return
      } else {
        message.success(intl.formatMessage({ id: 'messages.success.upload' }))
      }
    }

    setOpen(false)

    const marshalledDocument = marshalDocument(
      document,
      _.get(res, 'storageUrl', null)
    )

    setDocuments([...documents, marshalledDocument])
  }

  // current mode
  const { mode } = useContext(GlobalContext)

  // current filters
  const [filters, setFilters] = useState([])

  const [query, setQuery] = useState('')

  // other fields
  const [otherFields, setOtherFields] = useState({
    facility_id: mode === 'detail' && facility.id,
  })

  log.debug('Current other fields', otherFields)

  // handle table change
  const handleTableChange = async (
    pagination,
    selectedFilters,
    sorter,
    extra
  ) => {
    log.debug('Table filters', selectedFilters)
    log.debug('Extra', extra)

    const marshalledFilters = []
    if (!_.isEmpty(selectedFilters[Object.keys(selectedFilters)[0]])) {
      // filter table (filter: array of values)
      marshalledFilters.push({
        field: Object.keys(selectedFilters)[0],
        values: selectedFilters[Object.keys(selectedFilters)[0]],
      })
    }

    log.debug('Marshalled filters', marshalledFilters)
    setFilters(marshalledFilters)
  }

  // filter documents
  useEffect(() => {
    // filter documents

    if (mode !== 'detail') return
    const filterDocuments = async () => {
      const { data } = await makeApiRequest(DOCUMENT_SEARCH, 'post', {
        body: { filters, query, ...otherFields },
      })

      log.debug('filterDocuments.success', data)

      setDocuments(data)
    }
    filterDocuments()
  }, [filters, query])

  return (
    <DocumentsContextProvider
      value={{
        documents,
        setDocuments,
        searchLoading,
        setSearchLoading,
        readonly,
        query,
        setQuery,
      }}
    >
      {/* Documents Search */}
      {readonly && (
        <GutteredRow>
          <Col xl={8}>
            <StyledSearchDiv>
              <DocumentsSearch />
            </StyledSearchDiv>
          </Col>
        </GutteredRow>
      )}
      {/* Documents Table */}
      <Table
        bordered
        footer={() =>
          !readonly && (
            <StyledTableFooter>
              <Button type={'primary'} onClick={() => setOpen(true)}>
                <FormattedMessage id={'ui.documents.buttons.add'} />
              </Button>
            </StyledTableFooter>
          )
        }
        columns={columns}
        rowKey={(record) => record.id ?? 'key'}
        dataSource={documents}
        onChange={handleTableChange}
      />

      {/* Document Modal*/}
      <DocumentModal
        open={open}
        onCancel={() => setOpen(false)}
        onCreate={onCreate}
        fileList={fileList}
        setFileList={setFileList}
      />

      {/* Delete Document Modal */}
      <ModalDelete
        open={deleteModalData.open}
        recordType={<FormattedMessage id={'ui.documents.singular'} />}
        onCancel={() => setDeleteModalData({ open: false })}
        onOk={async () => {
          const document_id = _.get(deleteModalData.document, 'id')

          log.verbose('Deleting document with id ', document_id)

          if (!(await handleDelete(deleteModalData.index))) {
            setDeleteModalData({
              open: false,
            })
            return
          }

          log.verbose('Successfully deleted from s3..')
          if (document_id) {
            setDeletedDocuments([
              ...deletedDocuments,
              {
                index: deleteModalData.index,
                id: document_id,
                _destroy: true,
              },
            ])
          }

          setDeleteModalData({
            open: false,
          })
        }}
        data={deleteModalData.document}
        keys={['name', 'typology']}
        enumPairs={[['typology', 'ui.documents.enums.typologies']]}
        model={'documents'}
      />

      {/* Add hidden inputs for rails */}
      {documents.map((r, i) => {
        return _.map(r, (value, key) => (
          <Input
            name={`${prefix}[${i}][${key}]`}
            type={'hidden'}
            value={value}
            key={key}
          />
        ))
      })}

      {/* Add Hidden Record to delete documents*/}
      {deletedDocuments.map((d, key) => (
        <DeleteRecord key={key} record={d} prefix={`${prefix}[${d.index}]`} />
      ))}
    </DocumentsContextProvider>
  )
}
