import { Checkbox, Divider, Modal, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl/lib'
import { ModalInterventionDetail } from './index'

dayjs.extend(customParseFormat)
const log = new Logger('ModalFacility')

// Facility modal to display interventions
const ModalFacility = ({ modalData, onOk, onCancel }) => {
  const { interventions } = modalData

  const { facility } = modalData

  // selected interventions
  const [selectedInterventions, setSelectedInterventions] = useState(
    _.get(modalData, 'selectedInterventions', [])
  )

  // handle checkbox change
  const handleCheckChange = (newInterventions) => {
    log.debug('checkbox change', { newInterventions })
    setSelectedInterventions(
      _.map(newInterventions, (i) => _.set(i, 'status', 'in_progress'))
    )
  }

  log.debug('modal data, selectedInterventions', {
    modalData,
    selectedInterventions,
  })

  // handle modal ok
  const handleModalOk = () => {
    onOk(selectedInterventions)
  }

  useEffect(() => {
    setSelectedInterventions(_.get(modalData, 'selectedInterventions', []))
  }, [modalData])

  return (
    <Modal
      open={modalData.open}
      title={<span>{_.get(facility, 'business_name')}</span>}
      okText={<FormattedMessage id={'ui.modals.delete.ok_text'} />}
      onOk={handleModalOk}
      onCancel={() => {
        setSelectedInterventions([])
        onCancel()
      }}
      destroyOnClose
    >
      {interventions.length >= 1 && (
        <Checkbox.Group
          onChange={handleCheckChange}
          value={selectedInterventions}
        >
          <Space direction='vertical'>
            {_.map(interventions, (item, idx) => {
              return (
                <div key={idx}>
                  <Checkbox
                    value={item}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <ModalInterventionDetail intervention={item} />
                  </Checkbox>

                  {idx !== interventions.length - 1 && <Divider dashed />}
                </div>
              )
            })}
          </Space>
        </Checkbox.Group>
      )}
    </Modal>
  )
}

export default ModalFacility
