import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { Form as AntdForm, Input, InputNumber } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { GlobalContext } from '../../contexts'

const log = new Logger('FieldInputNumber')
const FieldInputNumber = ({
  name,
  label,
  onChange = undefined,
  initialValue = 0.0,
  placeholder = null,
  railsName = name,
  required = false,
  hidden = false,
  min = 0.0,
  max = undefined,
  step = 1,
  precision = undefined,
  disabled = undefined,
  useWatch = false,
  ...otherProps
}) => {
  const intl = useIntl()

  const [value, setValue] = useState(initialValue)

  const validateRequired = () => {
    return required
      ? [
          {
            required: true,
          },
        ]
      : ''
  }

  const form = AntdForm.useFormInstance()

  const handleOnChange = (value) => {
    setValue(value)
    if (_.isFunction(onChange)) onChange(form, value)
  }

  const { mode } = useContext(GlobalContext)

  return (
    <>
      <AntdForm.Item
        name={_.toPath(name)}
        label={label}
        hidden={hidden}
        required={required}
        rules={validateRequired()}
        {...otherProps}
      >
        <InputNumber
          placeholder={placeholder}
          onChange={handleOnChange}
          min={min}
          max={max}
          decimalSeparator={','}
          precision={precision}
          step={step}
          disabled={_.defaultTo(disabled, mode === 'detail')}
        />
      </AntdForm.Item>
      <Input
        name={railsName}
        type={'hidden'}
        value={useWatch ? AntdForm.useWatch(_.toPath(name)) : value}
      />
    </>
  )
}

export default FieldInputNumber
