import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { Form as AntdForm, Input } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { GlobalContext } from '../../contexts'

const log = new Logger('InputField')
const InputField = ({
  name,
  label,
  onChange = undefined,
  type = 'text',
  placeholder = null,
  railsName = null,
  required = false,
  hidden = false,
  preserve = true,
  disabled = undefined,
  suffix = undefined,
  shouldUpdate = false,
  ...otherProps
}) => {
  const intl = useIntl()
  const validateRequired = () => {
    return required
      ? [
          {
            required: true,
          },
        ]
      : ''
  }

  const form = AntdForm.useFormInstance()

  const handleOnChange = () => {
    if (_.isFunction(onChange)) onChange(form)
  }

  const { mode } = useContext(GlobalContext)

  return (
    <AntdForm.Item
      name={_.toPath(name)}
      label={label}
      hidden={hidden}
      required={required}
      rules={validateRequired()}
      preserve={preserve}
      shouldUpdate={shouldUpdate}
      {...otherProps}
    >
      <Input
        type={type}
        suffix={suffix}
        name={railsName ?? name}
        placeholder={placeholder}
        onChange={handleOnChange}
        disabled={_.defaultTo(disabled, mode === 'detail')}
      />
    </AntdForm.Item>
  )
}

export default InputField
