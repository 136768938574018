import { useIntl } from 'react-intl'
import {
  GutteredRow,
} from '../../../.././Components'
import React from 'react'
import { Logger } from 'aws-amplify'
import { Col, Form } from 'antd'
import { formats, typologies } from '../../../../enums'
import _ from 'lodash'
import {FieldUpload, InputField} from "../../../../Components/Fields";
import {LocalSelect} from "../../../../Components/Select";

const log = new Logger('DocumentForm')

export const DocumentForm = ({ initialValues, fileList, setFileList, form }) => {
  const intl = useIntl()

  log.debug('Current file list', fileList)

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  const normFile = (e) => {
    log.debug('Event on uploader: ', e)
    return _.isArray(e) ? e : e?.fileList
  }

  const handleDraggerChange = ({ fileList: newFileList }) => {
    log.verbose('Handling dragger change', newFileList)
    setFileList(newFileList)
  }

  return (
    <Form
      form={form}
      name={'document-form'}
      layout={'vertical'}
      validateMessages={validateMessages}
    >
      <GutteredRow>
        <Col xl={24}>
          <FieldUpload
            accept={_.map(formats, (format) => `.${format}`).toString()}
            beforeUpload={(f) => {
              setFileList([...fileList, f])
              return false
            }}
            normFile={normFile}
            maxCount={1}
            fileList={fileList}
            onChange={handleDraggerChange}
          />
        </Col>
      </GutteredRow>
      <GutteredRow>
        <Col xl={12}>
          {/* File Name */}
          <InputField
            name={'name'}
            label={intl.formatMessage({
              id: 'ui.documents.attributes.name',
            })}
            required
          />
        </Col>
        <Col md={12}>
          {/*  Typology */}
          <LocalSelect
            name={'typology'}
            options={typologies}
            label={'ui.documents.attributes.typology'}
            required
            localAttribute={'ui.documents.enums.typologies'}
          />
        </Col>
      </GutteredRow>
    </Form>
  )
}
