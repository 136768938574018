import React from 'react'
import { Logger } from 'aws-amplify'
import { Input } from 'antd'

const log = new Logger('DeleteRecord')
const DeleteRecord = ({ record, prefix }) => (
  <>
    <Input name={`${prefix}[id]`} type={'hidden'} value={record.id} />
    <Input
      name={`${prefix}[_destroy]`}
      type={'hidden'}
      value={record._destroy}
    />
  </>
)

export default DeleteRecord
