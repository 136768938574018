import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  AuditOutlined,
  BellOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MobileOutlined,
  PhoneOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons'
import '@aws-amplify/ui/dist/styles.css'
import { Button, Col, Layout, Menu, Row } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
// import styled components
import styled from 'styled-components'

import StyledEnv from '@components/Header'

import {
  CenteredSpinner,
  Errors,
  Info,
  JwtCookie,
  SignOutForm,
} from '././Components'
import { GlobalContextProvider } from './contexts'
import * as ViewComponents from './view-components'

const log = new Logger('App')

const { Header, Content, Footer, Sider } = Layout

// Logo image
const StyledLogoContainer = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
  }
`

// Application entry
const App = (props) => {
  log.debug('Rendering app...', props)

  // menu item clicked
  const onClick = ({ _, key }) => {
    if (key === 'helpdesk') return
    setLoading(!loading)
  }

  // loading state
  const [loading, setLoading] = useState(false)

  // destructure props to get component props
  const { view, version, ...componentProps } = props

  // Sidebar menu items
  const items = [
    {
      key: 'customers',
      icon: <UserOutlined />,
      label: (
        <a href={'/customers'}>
          <FormattedMessage id={'ui.customers.list_name'} />
        </a>
      ),
    },
    {
      key: 'calls',
      icon: <PhoneOutlined />,
      label: (
        <a href={'/calls'}>
          <FormattedMessage id={'ui.calls.list_name'} />
        </a>
      ),
    },
    {
      key: 'interventions',
      icon: <FileTextOutlined />,
      label: (
        <a href={'/interventions'}>
          <FormattedMessage id={'ui.interventions.list_name'} />
        </a>
      ),
    },
    {
      key: 'routes',
      icon: <DashboardOutlined />,
      label: (
        <a href={'/routes'}>
          <FormattedMessage id={'ui.routes.menu_voice'} />
        </a>
      ),
    },
    {
      key: 'materials',
      icon: <ToolOutlined />,
      label: (
        <a href={'/materials'}>
          <FormattedMessage id={'ui.materials.list_name'} />
        </a>
      ),
    },
    {
      key: 'invoices',
      icon: <AuditOutlined />,
      label: (
        <a href={'/invoices'}>
          <FormattedMessage id={'ui.invoices.list_name'} />
        </a>
      ),
    },
    {
      key: 'devices',
      icon: <MobileOutlined />,
      label: (
        <a href={'/devices'}>
          <FormattedMessage id={'ui.devices.list_name'} />
        </a>
      ),
    },
    {
      key: 'device_alarms',
      icon: <BellOutlined />,
      label: (
        <a href={'/device_alarms'}>
          <FormattedMessage id={'ui.device_alarms.list_name'} />
        </a>
      ),
    },
    {
      key: 'helpdesk',
      icon: <CustomerServiceOutlined />,
      label: (
        <a
          href={'https://archeido.atlassian.net/servicedesk/customer/portal/16'}
          target={'_blank'}
        >
          <FormattedMessage id={'ui.helpdesk.list_name'} />
        </a>
      ),
    },
  ]

  // menu collapse state
  const [collapsed, setCollapsed] = useState(true)

  const ViewComponent = ViewComponents[view] // view to render

  const [mode, setMode] = useState(null)

  log.debug('Current mode', mode)

  return (
    <GlobalContextProvider value={{ mode, setMode, loading, setLoading }}>
      <JwtCookie />

      <Info info={_.get(props, 'notice')} />
      <Errors errors={_.get(props, 'errors')} />

      <Layout hasSider>
        <Sider collapsible collapsed={collapsed} trigger={null}>
          <StyledLogoContainer>
            <img src={props.images.simet_logo} alt={'logo'} />
          </StyledLogoContainer>
          <Menu onClick={onClick} theme='dark' mode='inline' items={items} />
        </Sider>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <StyledEnv>{process.env.RAILS_ENV.toUpperCase()} MODE</StyledEnv>
          <Header
            style={{
              padding: 0,
            }}
          >
            <Row
              align={'middle'}
              justify={'space-between'}
              style={{
                margin: '0 10px',
              }}
            >
              <Col>
                <Button
                  type={'primary'}
                  style={{
                    backgroundColor: 'transparent',
                    boxShadow: 'unset',
                  }}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
              </Col>
              <Col flex={''}>
                <SignOutForm />
              </Col>
            </Row>
          </Header>

          <Content
            style={{
              margin: '24px 16px ',
              padding: 24,
            }}
          >
            {loading ? (
              <CenteredSpinner />
            ) : ViewComponent ? (
              <ViewComponent {...componentProps} />
            ) : (
              <p>Work in progress...</p>
            )}
          </Content>
          <Footer
            style={{
              textAlign: 'center',
              background: 'white',
            }}
          >
            Simet - ManPlanner © 2023 - Versione: {version}
          </Footer>
        </Layout>
      </Layout>
    </GlobalContextProvider>
  )
}

export default App
