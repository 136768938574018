import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  CloudDownloadOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import * as F from '@utils/functions'
import { Table as AntdTable, Button, Space, Tooltip, message } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { Display } from '@components/Display'
import DeleteDeviceModal from '@components/GenericDeleteForm'

import { DEVICE_RETRIEVE_CREDENTIALS } from '@/api/devices'

import { DevicesContext } from '../contexts'

const log = new Logger('Devices/Table')

const Table = ({ dataSource, search }) => {
  const intl = useIntl()
  const [deleteModalData, setDeleteModalData] = useState({ open: false })

  const firstLoad = useRef(true)
  const { searchLoading, query, pagination, setPagination, isTableChanged } =
    useContext(DevicesContext)

  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({
          id: 'ui.devices.attributes.serial_number',
        }),
        dataIndex: 'serial_number',
        key: 'serial_number',
        fixed: 'left',
        render: (value) => <Display value={value} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.devices.attributes.facility_business_name',
        }),
        dataIndex: 'facility_business_name',
        key: 'facility_business_name',
        render: (value) => <Display value={value} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.actions.title',
        }),
        key: 'action',
        fixed: 'right',
        render: (_, record) => (
          <>
            <Space>
              {/* IoT Core CTA */}

              {R.isNil(R.prop('thing_status', record)) && (
                <Tooltip
                  title={
                    <FormattedMessage id='ui.devices.tooltips.generate_credentials' />
                  }
                >
                  <Button
                    icon={<CloudDownloadOutlined />}
                    onClick={() => generateCredentials(record)}
                  />
                </Tooltip>
              )}

              <Button
                icon={<DeleteOutlined />}
                onClick={() =>
                  setDeleteModalData({
                    open: true,
                    record,
                  })
                }
              />
            </Space>
          </>
        ),
      },
    ]
  }, [])

  /**
   * @param {DeviceList} device
   */
  const generateCredentials = (device) => {
    log.debug('generateCredentials', device)

    try {
      const link = document.createElement('a')
      link.href = DEVICE_RETRIEVE_CREDENTIALS(device.id)
      link.download = ''
      link.click()

      setTimeout(() => {
        link.remove()
      }, 5000)

      message.success('Download in corso...')
    } catch (err) {
      message.error('Errore nel download')
      log.error(err)
    }
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    if (isTableChanged.current) {
      search()
      isTableChanged.current = false
    }
  }, [query, isTableChanged.current])

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
    isTableChanged.current = true
  }

  return (
    <>
      <AntdTable
        dataSource={dataSource}
        rowKey={'id'}
        columns={columns}
        pagination={{ ...pagination, onChange: handlePaginationChange }}
        scroll={{ x: true }}
        loading={{
          spinning: searchLoading,
          indicator: (
            <LoadingOutlined
              style={{
                fontSize: 40,
              }}
              spin
            />
          ),
        }}
      />

      <DeleteDeviceModal
        onCancel={() => setDeleteModalData({ open: false })}
        action={`/devices/${_.get(deleteModalData, 'record.id')}`}
        name={'delete-device-form'}
        model={'devices'}
        deleteModalData={deleteModalData}
      />
    </>
  )
}
export default Table
