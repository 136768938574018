export const vat = Object.freeze([
  {
    key: '10%',
    value: 10,
  },
  {
    key: '22%',
    value: 22,
  },
])
