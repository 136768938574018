import React from 'react'
import { useReferrer } from './Providers/ReferrerWrapperProvider'
import { HiddenField } from './Fields'

const ReferrerInput = ({ params }) => {
  const { baseUrl } = useReferrer()

  const parsedQueryParams = '?' + new URLSearchParams(params).toString()

  return (
    <HiddenField
      value={`${baseUrl}${parsedQueryParams}`}
      name={'request_referrer'}
    />
  )
}

export default ReferrerInput
