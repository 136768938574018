import React, { useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import { FormattedMessage } from 'react-intl'
import { InterventionsTable, Materials, SubscriptionDetail } from './index'
import { InterventionsContextProvider } from '../contexts'
import {
  StyledContainer,
  StyledDivider,
  StyledHorizontalDiv,
  StyledRightContainer,
} from '../../../styled'
import { Button, Col, Form } from 'antd'
import { AddInterventionModal } from './Modal'
import _ from 'lodash'
import { FieldCheckbox, HiddenField } from '../../../Components/Fields'
import { DisplayDetail, DisplayPrice } from '../../../Components/Display'
import { getTotalWithoutMaterials, isMaintenance } from '../../../utils'
import { GutteredRow } from '../../../Components'
import { isInvoiced } from '../../../utils/invoices'

const log = new Logger('Invoices/components/Interventions')

/**
 *
 * @param {Object} initialInterventions
 * @param {Array.<InterventionStrict>} initialInterventions.interventions
 * @return {JSX.Element}
 */

const Interventions = ({
  invoice,
  selectedInterventions,
  setSelectedInterventions,
  initialInterventions,
  facility,
}) => {
  // states
  const { interventions, setInterventions } = initialInterventions

  const [modalData, setModalData] = useState({
    open: false,
  })

  const [discountFlag, setDiscountFlag] = useState(
    _.defaultTo(_.get(invoice, 'discount_flag'), false)
  )

  const [totalIntervention, setTotalIntervention] = useState(0)

  const [totalSubscriptionFee, setTotalSubscriptionFee] = useState(0)
  
  log.info('Interventions', {
    interventions,
    selectedInterventions,
    facility,
    totalIntervention,
  })

  // handle modal confirm
  const handleModalConfirm = (values) => {
    setSelectedInterventions(values)
    setModalData({ open: false })
  }

  // handle delete intervention confirm
  const handleDeleteIntervention = (record) => {
    log.debug('handleDeleteIntervention CONFIRM ', record)
    const filteredSelectedInterventions = _.chain(selectedInterventions)
      .clone()
      .tap((value) => _.remove(value, { id: record.id }))
      .value()
    setSelectedInterventions(filteredSelectedInterventions)
  }

  // update total
  useEffect(() => {
    let total = _.reduce(
      selectedInterventions,
      (sum, i) => {
        sum += getTotalWithoutMaterials(facility, i)
        return sum
      },
      0
    )

    if (discountFlag) {
      const percentageValue = _.chain(total).multiply(5).divide(100)
      total = _.subtract(total, percentageValue)
    }

    setTotalIntervention(_.round(total, 2))
  }, [selectedInterventions, discountFlag])

  const form = Form.useFormInstance()

  // update form fields
  useEffect(() => {
    form.setFieldValue('interventions', selectedInterventions)
  }, [selectedInterventions])

  return (
    <InterventionsContextProvider
      value={{
        interventions,
        setInterventions,
        selectedInterventions,
        setSelectedInterventions,
        facility,
        totalIntervention,
        totalSubscriptionFee,
        setTotalSubscriptionFee
      }}
    >
      <StyledContainer>
        <StyledDivider orientation={'left'}>
          <FormattedMessage id={'ui.interventions.list_name'} />
        </StyledDivider>

        <InterventionsTable
          onDelete={handleDeleteIntervention}
          invoice={invoice}
        />

        {/*  Add Intervention Btn */}
        {!isInvoiced(invoice) && (
          <StyledRightContainer>
            <Button
              type={'primary'}
              onClick={() =>
                setModalData({
                  facility,
                  open: true,
                  interventions,
                  selectedInterventions,
                  onOk: handleModalConfirm,
                  destroyOnClose: _.isEmpty(selectedInterventions),
                  onCancel: () => setModalData({ open: false }),
                })
              }
            >
              <FormattedMessage
                id={'ui.invoices.form.buttons.add_intervention'}
              />
            </Button>
          </StyledRightContainer>
        )}

        {/* Total Intervention*/}
        <GutteredRow>
          <Col offset={19}>
            <DisplayDetail
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
              }}
              flexDirection={'row'}
              label={
                <FormattedMessage
                  id={'ui.invoices.form.labels.total_intervention'}
                />
              }
              value={<DisplayPrice value={totalIntervention} />}
            />
          </Col>
        </GutteredRow>

        {/*  5%  Discount Flag */}
        <GutteredRow>
          <Col offset={19}>
            <StyledHorizontalDiv unset>
              <FieldCheckbox
                label={'ui.invoices.form.labels.discount_flag'}
                name={'discount_flag'}
                checked={discountFlag}
                onChange={setDiscountFlag}
                disabled={isInvoiced(invoice)}
              />
            </StyledHorizontalDiv>
          </Col>
        </GutteredRow>

        {/* Subscription Detail */}
        {!_.isEmpty(selectedInterventions) &&
          !_.isNil(_.get(facility, 'subscription')) &&
          _.some(selectedInterventions, isMaintenance) && (
            <SubscriptionDetail
              interventions={selectedInterventions}
              facility={facility}
            />
          )}
        {/*  Materials Section */}
        {!_.isEmpty(selectedInterventions) && (
          <Materials interventions={selectedInterventions} />
        )}

        {/* Add Intervention Modal */}
        <AddInterventionModal modalData={modalData} />

        {/* Hidden Intervention IDS */}
        {_.map(selectedInterventions, (intervention) => {
          return (
            <HiddenField
              name={'intervention_ids[]'}
              value={_.get(intervention, 'id')}
            />
          )
        })}
      </StyledContainer>
    </InterventionsContextProvider>
  )
}

export default Interventions
