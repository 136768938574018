import React from 'react'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Display from "./Display";

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const log = new Logger('DisplayTime')
const DisplayTime = ({ value, format = 'HH:mm' }) => {
  const dayjsObj = dayjs(`2000-01-01 ${value}`)

  const currentTz = dayjs.tz.guess()

  const parsedValue = dayjsObj.tz(currentTz).format(format)

  log.verbose('currentTz, dayjs, local, Parsed time', {
    currentTz,
    dayjsObj,
    parsedValue,
  })
  return <Display value={parsedValue} />
}

export default DisplayTime
