import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Logger } from 'aws-amplify'
import { GutteredRow } from '../../../.././Components'
import { Col } from 'antd'
import {
  buildCityOptions,
  buildCountryOptions,
  buildPostalCodeOptions,
  buildStateOptions,
} from '../../../../utils'
import cities from '../../../../../../../../lib/enums/enum_cities.json'
import _ from 'lodash'
import states from '../../../../../../../../lib/enums/enum_states.json'
import countries from '../../../../../../../../lib/enums/enum_countries.json'
import { CustomerContext } from '../../contexts'
import {InputField} from "../../../../Components/Fields";
import {SingleSelect} from "../../../../Components/Select";

const log = new Logger('FacilityAddress')

export const FacilityAddress = ({
  prefix,
  index,
  sectionTitle,
  initialValues,
  loading,
  railsNamePrefix = name,
}) => {
  const intl = useIntl()

  log.verbose(`Rendering facility  address`)

  // selected city, selected postal code, selected state <- react states
  const [country, setCountry] = useState(_.get(initialValues, 'country', 'ITA'))

  const [city, setCity] = useState(_.get(initialValues, `city`, null))
  const [postalCode, setPostalCode] = useState(
    _.get(initialValues, `postal_code`, null)
  )
  const [state, setState] = useState(_.get(initialValues, `state`, null))

  const { readonly } = useContext(CustomerContext)

  return (
    <>
      <GutteredRow>
        <Col xl={6}>
          {/* Address Text */}
          <InputField
            name={`${index}.${prefix}.address_text`}
            label={intl.formatMessage({
              id: 'ui.addresses.attributes.address_text',
            })}
            type={'text'}
            railsName={`${railsNamePrefix}[address_text]`}
            required
          />
        </Col>
        <Col xl={6}>
          {/* House number */}
          <InputField
            name={`${index}.${prefix}.house_number`}
            label={intl.formatMessage({
              id: 'ui.addresses.attributes.house_number',
            })}
            type={'text'}
            railsName={`${railsNamePrefix}[house_number]`}
          />
        </Col>
        <Col xl={6}>
          {/* Intern number */}
          <InputField
            name={`${index}.${prefix}.intern_number`}
            label={intl.formatMessage({
              id: 'ui.addresses.attributes.intern_number',
            })}
            type={'text'}
            railsName={`${railsNamePrefix}[intern_number]`}
          />
        </Col>
      </GutteredRow>

      <GutteredRow>
        <Col md={4}>
          {/*  Country */}
          <SingleSelect
            name={`${index}.${prefix}.country`}
            label={'ui.addresses.attributes.country'}
            railsName={`${railsNamePrefix}[country]`}
            options={buildCountryOptions(countries, 'name', 'alpha3')}
            disabled={loading || readonly}
            defaultValue={country}
            onSelect={(value) => {
              setCountry(value)
              if (value != 'ITA') setState('EE')
            }}
            required
          />
        </Col>

        <Col xl={4}>
          {/* State */}
          <SingleSelect
            name={`${index}.${prefix}.state`}
            label={'ui.addresses.attributes.state'}
            type={'text'}
            railsName={`${railsNamePrefix}[state]`}
            options={buildStateOptions(states, 'ITA', 'state_code', country)}
            defaultValue={state}
            onSelect={(value) => setState(value)}
            required
            disabled={loading || readonly}
          />
        </Col>

        {state === 'EE' ? (
          <>
            <Col xl={4}>
              {/* City */}
              <InputField
                name={`${index}.${prefix}.city`}
                label={<FormattedMessage id={'ui.facilities.city'} />}
                railsName={`${railsNamePrefix}[city]`}
                required
              />
            </Col>
            <Col xl={4}>
              {/* Postal Code */}

              <InputField
                name={`${index}.${prefix}.postal_code`}
                label={
                  <FormattedMessage
                    id={'ui.addresses.attributes.postal_code'}
                  />
                }
                railsName={`${railsNamePrefix}[postal_code]`}
                required
              />
            </Col>
          </>
        ) : (
          <>
            <Col xl={4}>
              {/* City */}
              <SingleSelect
                name={`${index}.${prefix}.city`}
                label={'ui.facilities.city'}
                railsName={`${railsNamePrefix}[city]`}
                options={buildCityOptions(cities, 'ITA', 'ITA', state, true)}
                defaultValue={city}
                disabled={_.isNil(state) || loading || readonly}
                onSelect={(value) => {
                  setCity(value)
                }}
                required
              />
            </Col>

            <Col xl={4}>
              {/*  Fraction */}
              <InputField
                name={`${index}.${prefix}.fraction`}
                label={
                  <FormattedMessage id={'ui.addresses.attributes.fraction'} />
                }
                railsName={`${railsNamePrefix}[fraction]`}
              />
            </Col>

            <Col xl={4}>
              {/* Postal Code */}

              <SingleSelect
                name={`${index}.${prefix}.postal_code`}
                label={'ui.addresses.attributes.postal_code'}
                railsName={`${railsNamePrefix}[postal_code]`}
                options={buildPostalCodeOptions(cities, 'cap', 'cap', city)}
                defaultValue={postalCode}
                disabled={_.isNil(city) || loading || readonly}
                required
              />
            </Col>
          </>
        )}

        {/* Hidden Address ID*/}
        <InputField
          hidden
          name={`${index}.${prefix}.id`}
          railsName={`${railsNamePrefix}[id]`}
        />

        {/* Type hidden field */}
        <InputField
          hidden
          name={`${index}.${prefix}.address_type`}
          railsName={`${railsNamePrefix}[address_type]`}
        />
      </GutteredRow>
    </>
  )
}
