export const AWS_ACCESS_KEY = process.env.AWS_ACCESS_KEY
export const S3_DOCS_BUCKET = process.env.S3_DOCS_BUCKET.includes(
  process.env.RAILS_ENV
)
  ? process.env.S3_DOCS_BUCKET
  : `${process.env.RAILS_ENV}-${process.env.S3_DOCS_BUCKET}`

export const AWS_SECRET_ACCESS_KEY = process.env.AWS_SECRET_ACCESS_KEY
export const AWS_REGION = process.env.AWS_REGION

export const MAPS_API_KEY = process.env.MAPS_API_KEY

export const DEFAULT_PAGER = { current: 1, pageSize: 10 }
