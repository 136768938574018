import React, { useState } from 'react'
import { Input } from 'antd'
import { FormattedMessage } from 'react-intl'
import { CustomForm, ModalDelete } from '../../.././Components'

const DeleteForm = ({ action, name, deleteModalData, onCancel }) => {
  const onFinish = () => {
    document.getElementById(name).submit()
  }

  const [loading, setLoading] = useState(false)

  return (
    <CustomForm method={'post'} action={action} name={name} onFinish={onFinish}>
      <Input type={'hidden'} value={'delete'} name={'_method'} />

      <ModalDelete
        model={'routes'}
        onOk={onFinish}
        open={deleteModalData.open}
        onCancel={onCancel}
        recordType={<FormattedMessage id={'ui.routes.singular'} />}
        data={deleteModalData.record}
        // keys={['business_name']}
      />
    </CustomForm>
  )
}

export default DeleteForm
