import React from 'react'
import { useIntl } from 'react-intl'

import { Input } from 'antd'

const { Search } = Input

const SearchBox = ({
  onSearch,
  placeholder,
  allowClear = true,
  defaultValue = '',
}) => {
  // inject intl
  const intl = useIntl()

  return (
    <Search
      placeholder={intl.formatMessage({
        id: placeholder,
      })}
      defaultValue={defaultValue}
      onSearch={onSearch}
      allowClear={allowClear}
    />
  )
}
export default SearchBox
