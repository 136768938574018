import React, { useContext, useEffect, useState } from 'react'
import { CustomerForm } from './components'
import { CenteredSpinner } from '../.././Components'
import { Logger } from 'aws-amplify'
import { CustomerContextProvider } from './contexts'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Typography } from 'antd'
import { GlobalContext } from '../../contexts'

const log = new Logger('CustomerNew')

const New = (props) => {
  const [loading, setLoading] = useState(false)

  log.verbose('Current props: ', props)

  const [customer, setCustomer] = useState(_.get(props, 'customer', {}))

  const enums = _.get(props, 'enums', {})

  const { setMode } = useContext(GlobalContext)

  useEffect(() => {
    setMode('create')
  }, [])

  return (
    <CustomerContextProvider value={{ customer, setCustomer, enums }}>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <>
          <CustomerForm {...props}>
            <Typography.Title level={2}>
              <FormattedMessage id={`ui.customers.actions.create`} />
            </Typography.Title>
          </CustomerForm>
        </>
      )}
    </CustomerContextProvider>
  )
}

export default New
