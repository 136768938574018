/*
 * Route Helpers
 * */
import * as F from '@utils/functions'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { hasActiveSubscription, isMaintenance } from '..'

const log = new Logger('Routes/Helpers')

export const addSubscriptionTime = R.curry((startTime, stage) => {
  const minutes = R.pathOr(
    0,
    ['facility', 'subscription', 'coverage_minutes'],
    stage
  )

  log.debug('adding coverage time to endTime...', {
    startTime,
    minutes,
  })

  return R.assoc('expected_end_time', startTime.add(minutes, 'm'), stage)
})

export const addDuration = R.curry((startTime, duration, unit = 'm') =>
  startTime.add(duration, unit)
)

export const addRouteDuration = R.curry((legs, startTime, idx) =>
  R.pipe(
    R.pathOr(0, [idx, 'duration', 'value']),
    addDuration(startTime, R.__, 's')
  )(legs)
)

export const setPrevEndTime = R.curry((stages, idx) =>
  R.pathOr(null, [idx - 1, 'expected_end_time'], stages)
)

export const subscriptionEligible = (stage) =>
  hasActiveSubscription(R.prop('facility', stage)) && isMaintenance(stage)

/**
 * Get total time accumulated from every stage
 * @param {*} stages
 *
 * @returns
 */
export const getTotalTime = (stages) =>
  R.reduce(
    (acc, el) => {
      const endTime = R.prop('expected_end_time', el)
      const startTime = R.prop('scheduled_time', el)

      if (R.any(R.isNil, [endTime, startTime])) return acc

      const totalTime = endTime.diff(startTime, 's')
      return R.sum([acc, totalTime])
    },
    0,
    stages
  )

export const subscriptionCoverage = (stage) =>
  R.pathOr(0, ['facility', 'subscription', 'coverage_minutes'], stage)

export const mmToHh = (minutes) => F.round(R.divide(minutes, 60), 2)

export const hhToMm = (hours) => R.multiply(60, hours)
