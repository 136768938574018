import { Form as AntdForm, Input } from 'antd'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { checkVATNumber } from '../../utils/js-vat/jsvat'
import { GlobalContext } from '../../contexts'

const FieldVatNumber = ({
  name,
  label,
  placeholder = null,
  railsName = null,
}) => {
  const intl = useIntl()

  const validate = (_, value) => {
    if (!value) return Promise.resolve()
    if (checkVATNumber(value).valid_vat) return Promise.resolve()
    else {
      return Promise.reject(
        new Error(
          intl.formatMessage({ id: 'ui.validations.vat_number.invalid' })
        )
      )
    }
  }

  const { mode } = useContext(GlobalContext)

  return (
    <AntdForm.Item
      name={_.toPath(name)}
      label={label}
      rules={[
        {
          validator: validate,
        },
      ]}
    >
      <Input
        name={railsName ?? name}
        placeholder={placeholder}
        disabled={mode === 'detail'}
      />
    </AntdForm.Item>
  )
}

export default FieldVatNumber;
