import React, { useContext } from 'react'
import { Logger } from 'aws-amplify'
import { Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { GlobalContext } from '../../contexts'

const log = new Logger('Components/Button/LinkToButton')
const LinkToButton = ({ href }) => {
  const { setLoading: setGlobalLoading } = useContext(GlobalContext)

  return (
    <Button
      type={'primary'}
      icon={<RightOutlined />}
      href={href}
      onClick={() => setGlobalLoading(true)}
    />
  )
}

export default LinkToButton
