import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import * as F from '@utils/functions'
import { Table as AntdTable, Button, Space, message } from 'antd'
import { Auth, Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'

import { DisplayDateTime } from '@components/Display'

import { ROUTE_GENERATE_PDF, ROUTE_SEARCH, makeApiRequest } from '@/api'

import { zipDownload } from '../../../utils'
import { RoutesContext } from '../contexts'
import { DeleteForm, DisplayArray, InterventionsTable } from './index'

const log = new Logger('RoutesTable')

export const Table = ({ dataSource, searchRoutes }) => {
  // inject Intl instance
  const intl = useIntl()

  // first load ref
  const firstLoad = useRef(true)

  const vehiclesList = _.map(dataSource, 'vehicles')
  const teams = _.map(dataSource, 'team')

  // delete modal data
  const [deleteModalData, setDeleteModalData] = useState({ open: false })

  log.debug('Vehicles, teams', { vehiclesList, teams })

  const getColumns = () => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.scheduled_date',
        }),
        dataIndex: 'scheduled_datetime',
        fixed: true,
        render: (_, record) => (
          <DisplayDateTime
            value={record.scheduled_datetime}
            format={'DD/MM/YYYY'}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.team',
        }),
        dataIndex: 'team',
        render: (_, record) => <DisplayArray array={record.team} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.routes.attributes.vehicles',
        }),
        dataIndex: 'vehicles',
        render: (_, record) => <DisplayArray array={record.vehicles} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.actions.title',
        }),
        key: 'action',
        fixed: 'right',
        render: (x, record) => (
          <>
            <Space>
              {/* EDIT */}
              <Button
                icon={<EditOutlined />}
                href={`/routes/${record.id}/edit`}
              />

              {/* DOWNLOAD */}
              <Button
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record)}
              />

              {/*  DELETE */}
              {(_.every(
                _.get(record, 'interventions'),
                (i) =>
                  !_.includes(['accounted', 'terminated', 'invoiced'], i.status)
              ) ||
                _.isEmpty(_.get(record, 'interventions'))) && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setDeleteModalData({
                      open: true,
                      record,
                    })
                  }}
                />
              )}
            </Space>
          </>
        ),
      },
    ]

    log.debug('Adding responsive property to columns ...')
    return columns
  }

  const {
    setRoutes,
    isTableChanged,
    searchLoading,
    setSearchLoading,
    ranges,
    query,
    expandedRowKeys,
    setExpandedRowKeys,
    pagination,
    setPagination,
  } = useContext(RoutesContext)

  // handle zip download
  const handleDownload = async (route) => {
    log.debug('Downloading route zip...', { route })
    message.info('Generazione dei documenti in corso ...')
    const timezone = dayjs.tz.guess()

    const { data: generatedRoute } = await makeApiRequest(
      ROUTE_GENERATE_PDF(_.get(route, 'id')),
      'post',
      { body: { timezone } }
    )

    const routeUrl = _.get(generatedRoute, 'document.storage_url', null)

    const interventions = _.get(generatedRoute, 'interventions')

    let urls = []

    const interventionUrls = _.compact(
      _.map(interventions, (intervention) =>
        _.get(intervention, 'document.storage_url')
      )
    )

    if (!_.isNil(routeUrl)) {
      urls = [routeUrl, ...interventionUrls]
    } else {
      urls = [...interventionUrls]
    }

    const folderName = `PRG_${dayjs(
      _.get(generatedRoute, 'scheduled_datetime')
    ).format('DD_MM_YYYY')}`
    log.debug('urls, folder name', { urls, folderName, interventionUrls })

    await zipDownload(urls, `${folderName}`)
  }

  const handleTableChange = (pager) => {
    log.debug('handleTableChange.start', { pager })

    isTableChanged.current = true
    setPagination(pager)
  }

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    if (isTableChanged.current) {
      searchRoutes()
    }
  }, [query, ranges, isTableChanged.current])

  const columns = useMemo(() => getColumns(), [])

  log.info('Routes data source ... :', { dataSource, expandedRowKeys })

  const expandedRowRender = ({ interventions }, index) => {
    log.debug('Expanding row', { interventions, index })

    return <InterventionsTable dataSource={interventions} />
  }

  return (
    !firstLoad.current && (
      <>
        <AntdTable
          dataSource={dataSource}
          rowKey={'id'}
          columns={columns}
          pagination={{ ...pagination, onChange: handlePaginationChange }}
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            onExpandedRowsChange: setExpandedRowKeys,
          }}
          loading={{
            spinning: searchLoading,
            indicator: (
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
                spin
              />
            ),
          }}
          onChange={handleTableChange}
          scroll={{ x: true }}
        />

        <DeleteForm
          name={'delete-route'}
          deleteModalData={deleteModalData}
          action={`/routes/${_.get(deleteModalData, 'record.id')}`}
          onCancel={() => setDeleteModalData({ open: false })}
        />
      </>
    )
  )
}

export default Table
