import React from 'react'

import { Col, Form, Row } from 'antd'
import { Logger } from 'aws-amplify'
import styled from 'styled-components'

import { Display } from '../Display'
import InputField from './InputField'

const log = new Logger('DetailField')

const StyledDetailLabel = styled.span`
  color: rgba(0, 0, 0, 0.6);
`

const DetailField = ({ name, label, suffix = undefined }) => {
  const form = Form.useFormInstance()

  log.debug({ formValues: form.getFieldsValue(true) })

  const value = Form.useWatch(name, form)

  return (
    <>
      <Row style={{ flexDirection: 'column' }}>
        <Col style={{ marginBottom: '8px' }}>
          <StyledDetailLabel>
            <Display value={label} />
          </StyledDetailLabel>
        </Col>
        <Col style={{ paddingTop: '5px' }}>
          <Display value={value} suffix={suffix} />
        </Col>
      </Row>

      <InputField name={name} hidden disabled={false} />
    </>
  )
}

export default DetailField
