// Single Select
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Form as AntdForm, Input, Select } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { GlobalContext } from '../../contexts'

const log = new Logger('SingleSelect')

/**
 *
 * @callback onSelectCallback
 * @param selectedValue
 * @param option
 * @param setValue
 *
 * @param {Object} props
 * @property {onSelectCallback} props.onSelect
 * @property {string} props.name
 * @property {string} props.options
 * @property {string} props.label
 * @property {string} props.disabled
 * @property {string} props.railsName
 * @property {string} props.required
 * @property {string} props.defaultValue
 * @property {string} props.onSearch
 * @property {string} props.loading
 * @return {JSX.Element}
 */
const SingleSelect = ({
  name,
  options,
  label,
  onSelect = undefined,
  disabled = false,
  railsName = name,
  required = false,
  defaultValue = null,
  onSearch = undefined,
  loading = false,
}) => {
  const [value, setValue] = useState(defaultValue)

  /**
   *
   * @param selectedValue
   * @param option
   */
  const handleSelect = (selectedValue, option) => {
    setValue(selectedValue)
    if (_.isFunction(onSelect)) onSelect(selectedValue, option, setValue)
  }

  const validateRequired = () => {
    return required
      ? [
          {
            required: true,
          },
        ]
      : ''
  }

  const { mode } = useContext(GlobalContext)

  return (
    <>
      {/* Hidden field for rails*/}
      <Input name={railsName} type={'hidden'} value={value} />
      <AntdForm.Item
        name={_.toPath(name)}
        label={<FormattedMessage id={label} />}
        required={required}
        rules={validateRequired()}
      >
        <Select
          options={options}
          optionFilterProp={'label'}
          showSearch
          onSelect={handleSelect}
          onSearch={onSearch}
          loading={loading}
          disabled={disabled || mode === 'detail'}
        ></Select>
      </AntdForm.Item>
    </>
  )
}

export default SingleSelect
