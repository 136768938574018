import React from 'react'
import _ from 'lodash'
import { Logger } from 'aws-amplify'

const log = new Logger('DisplayArray')
const DisplayArray = ({ array, separator = ', ', nullText = '•' }) => {
  return <>{_.isEmpty(array) ? nullText : array.join(separator)}</>
}

export default DisplayArray
