import React, { useEffect } from 'react'

import { Form as AntdForm, Input } from 'antd'
import { Logger } from 'aws-amplify'

const log = new Logger('GenericForm')

const Form = (props) => {
  const { children, instantSubmit, name: formName } = props

  const token = document.querySelector('[name=csrf-token]').content

  log.debug('props: ', { props, token })

  // submit current form if instantSubmit is true
  useEffect(() => {
    if (instantSubmit) {
      document.getElementById(formName).submit()
    }
  }, [])

  return (
    <>
      <AntdForm {...props}>
        {children}
        <Input name={'authenticity_token'} type={'hidden'} value={token} />
      </AntdForm>
    </>
  )
}

export default Form
