import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'

import { LoadingOutlined } from '@ant-design/icons'
import * as F from '@utils/functions'
import { Table as AntdTable } from 'antd'
import { Logger } from 'aws-amplify'
import * as R from 'ramda'

import { Display, DisplayDateTime } from '@components/Display'

import { anomalies } from '@/enums/device_alarms'

import { DeviceAlarmsContext } from '../contexts'

const log = new Logger('DeviceAlarms/Table')

const Table = ({ dataSource, search }) => {
  const intl = useIntl()

  const firstLoad = useRef(true)
  const {
    searchLoading,
    query,
    ranges,
    pagination,
    setPagination,
    isTableChanged,
  } = useContext(DeviceAlarmsContext)

  const parseMessage = (message) => {
    log.debug('parseMessage.before', message)

    const parsedMessage = R.pipe(
      R.match(/\d/g),
      F.mapIndexed((value, index) =>
        R.unless(
          R.equals('0'),
          R.always(`Campana ${index + 1}: ${anomalies[value]}`)
        )(value)
      ),
      R.reject(R.equals('0'))
    )(message)

    log.debug('parseMessage.after', parsedMessage)
    return parsedMessage
  }

  const DisplayMessage = ({ value }) => {
    const displayValue = parseMessage(value)
    if (R.isNil(displayValue) || R.isEmpty(displayValue)) {
      return <Display value={displayValue} />
    }

    return (
      <>
        {R.map((message) => (
          <>
            <Display value={message} /> <br />
          </>
        ))(displayValue)}
      </>
    )
  }

  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({
          id: 'ui.device_alarms.attributes.timestamp',
        }),
        dataIndex: 'timestamp',
        key: 'timestamp',
        fixed: 'left',
        render: (value) => <DisplayDateTime value={value} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.device_alarms.attributes.device_serial_number',
        }),
        dataIndex: ['device', 'serial_number'],
        key: 'device_serial_number',
        render: (value) => <Display value={value} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.device_alarms.attributes.facility_business_name',
        }),
        dataIndex: ['facility', 'business_name'],
        key: 'facility_business_name',
        render: (value) => <Display value={value} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.device_alarms.attributes.message',
        }),
        dataIndex: 'message',
        key: 'message',
        render: (value) => <DisplayMessage value={value} />,
      },
    ]
  }, [])

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    if (isTableChanged.current) {
      search()
      isTableChanged.current = false
    }
  }, [query, ranges, isTableChanged.current])

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
    isTableChanged.current = true
  }

  return (
    <AntdTable
      dataSource={dataSource}
      rowKey={'id'}
      columns={columns}
      pagination={{ ...pagination, onChange: handlePaginationChange }}
      scroll={{ x: true }}
      loading={{
        spinning: searchLoading,
        indicator: (
          <LoadingOutlined
            style={{
              fontSize: 40,
            }}
            spin
          />
        ),
      }}
    />
  )
}
export default Table
