import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Button, Col, Row, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { INVOICE_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'

import { CenteredSpinner, GutteredRow } from '../.././Components'
import { GlobalContext } from '../../contexts'
import { StyledCard } from '../../styled'
import { InvoiceSearch, InvoicesTable } from './components'
import { InvoicesContextProvider } from './contexts'

const { Title } = Typography
const log = new Logger('InvoiceIndex')
const Index = (props) => {
  log.debug('Rendering with props...', { props })

  // inject intl
  const intl = useIntl()

  /* states */
  // set invoices state
  const [invoices, setInvoices] = useState(_.get(props, 'invoices', []))
  const [loading, setLoading] = useState(false)

  // search loading state
  const [searchLoading, setSearchLoading] = useState(false)
  // query
  const [query, setQuery] = useState(
    F.retrieveFiltersOr('', ['invoices', 'query'])
  )
  // filters
  const [filters, setFilters] = useState(
    F.retrieveFiltersOr([], ['invoices', 'filters'])
  )

  // orders
  const [orders, setOrders] = useState(
    F.retrieveFiltersOr([], ['invoices', 'orders'])
  )

  const [pagination, setPagination] = useState(
    F.retrieveFiltersOr(DEFAULT_PAGER, ['invoices', 'pagination'])
  )

  const { setMode } = useContext(GlobalContext)

  log.info('Invoice List:', invoices)

  useEffect(() => {
    setMode('list')
  }, [])

  const filterInvoices = async () => {
    setSearchLoading(true)
    const { data, headers } = await makeApiRequest(INVOICE_SEARCH, 'post', {
      body: {
        query,
        filters,
        orders,
      },
      queryParams: F.marshalPaginationTo(pagination),
    })

    const newPagination = {
      ...pagination,
      ...F.marshalPaginationFrom(headers),
    }

    F.storeFilters({
      invoices: { query, filters, orders, pagination: newPagination },
    })

    setPagination(newPagination)
    setInvoices(data)
    setSearchLoading(false)
  }

  return loading ? (
    <CenteredSpinner />
  ) : (
    <InvoicesContextProvider
      value={{
        invoices,
        setInvoices,
        query,
        setQuery,
        filters,
        setFilters,
        orders,
        setOrders,
        searchLoading,
        setSearchLoading,
        pagination,
        setPagination,
      }}
    >
      <GutteredRow>
        <Col>
          <Title level={3}>
            <FormattedMessage id={'ui.invoices.list_name'} />
          </Title>
        </Col>
      </GutteredRow>

      <Row justify={'space-between'}>
        <Col md={10}>
          {/* invoices search*/}
          <InvoiceSearch
            onSearch={() =>
              setPagination({ total: pagination.total, ...DEFAULT_PAGER })
            }
          />
        </Col>
        <Col md={6}></Col>
        <Col
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type='primary'
            href='/invoices/new'
            onClick={() => setLoading(!loading)}
          >
            <FormattedMessage id={'ui.invoices.buttons.create'} />
          </Button>
        </Col>
      </Row>
      <StyledCard>
        <InvoicesTable dataSource={invoices} searchInvoices={filterInvoices} />
      </StyledCard>
    </InvoicesContextProvider>
  )
}

export default Index
