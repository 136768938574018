import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { DeleteOutlined, MenuOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as FRoutes from '@utils/routes'
import { Card, Col, Row } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as R from 'ramda'
import styled from 'styled-components'

import { FieldInputNumber, TimePickerField } from '@components/Fields'
import { GutteredRow, HiddenInputRenderer } from '@components/index'

import { Display, LocaleDisplay } from '../../../Components/Display'
import {
  StyledBoldDetail,
  StyledDetailTimePicker,
  StyledInputNumber,
} from '../../../styled'
import { hasActiveSubscription, isBreak, isMaintenance } from '../../../utils'
import { addSubscriptionTime } from '../../../utils/routes/helpers'
import { RouteContext } from '../contexts'

const log = new Logger('SortableStage')

const StyledCard = styled(Card)`
  border: 1px solid black;

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .ant-card-body {
    padding: 12px !important;
  }
`

const StyledStartCol = styled(Col)`
  align-items: start;
  display: flex;
`

export default function SortableStage(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const stage = _.get(props, 'stage', {})

  const { formInstance, totalTimeUpdate } = props

  const { route, stages, setStages } = useContext(RouteContext)

  const handleDeleteStage = _.get(props, 'onDeleteClick', {})

  const prefix = _.get(props, 'prefix', '')

  const formPrefix = _.get(props, 'formPrefix', '')

  const [totalTime, setTotalTime] = useState(/** @type {number} */ (null))

  let totalTimeString

  if (!_.isNil(totalTime))
    totalTimeString = dayjs.duration(totalTime, 'seconds').format('H[h] m[m]')

  log.debug('props, Stage', {
    props,
    route,
    stage,
  })

  useEffect(() => {
    setTotalTime(
      FRoutes.getTotalTime([
        formInstance.getFieldValue([
          'interventions',
          R.split('.', formPrefix)[1],
        ]),
      ])
    )
  }, [totalTimeUpdate])

  return (
    <StyledCard ref={setNodeRef} style={style} {...attributes}>
      <Row>
        <Col xl={3}></Col>
        <Col xl={18}>
          {/* Stage Typology */}
          <StyledBoldDetail>
            {isBreak(stage) ? (
              <>
                <Display
                  value={
                    <FormattedMessage id={'ui.routes.form.labels.break'} />
                  }
                />
                {' - '}
                <Display value={totalTimeString} />
              </>
            ) : (
              <>
                <LocaleDisplay
                  style={{
                    fontWeight: 'bold',
                  }}
                  localePath={`ui.interventions.enums.typologies`}
                  value={_.get(stage, 'typology')}
                />
                {' - '}
                <Display value={_.get(stage, 'id')} />
              </>
            )}
          </StyledBoldDetail>
        </Col>
      </Row>
      <Row>
        {/* D&D icon */}
        <Col xl={3} style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Display value={_.indexOf(stages, stage) + 1} />
            <MenuOutlined
              {...listeners}
              style={{
                cursor: 'move',
              }}
            />
          </div>
        </Col>

        <StyledStartCol
          xl={18}
          style={{
            flexDirection: 'column',
          }}
        >
          {!isBreak(stage) && (
            <Row style={{ width: '100%' }}>
              {/* Facility Business Name */}
              <StyledStartCol>
                <span style={{ marginRight: '5px', width: '100%' }}>
                  {props.children}
                </span>

                <span style={{ whiteSpace: 'nowrap' }}>
                  <Display value={totalTimeString} />
                </span>
              </StyledStartCol>
            </Row>
          )}

          <Row style={{ width: '100%' }}>
            <StyledStartCol xl={24}>
              {/* Expected Duration */}

              <Row align={'middle'}>
                <Col md={24} xxl={10} style={{ marginBottom: '10px' }}>
                  <StyledInputNumber
                    label={
                      <FormattedMessage id='ui.routes.form.labels.expected_duration' />
                    }
                    step={'0.01'}
                    precision={2}
                    useWatch
                    inputWidth={'62%'}
                    name={`${formPrefix}.expected_duration`}
                    railsName={`${prefix}[expected_duration]`}
                    required
                    onChange={(form, value) => {
                      const clonedStages = R.clone(stages)

                      R.pipe(
                        R.findIndex(R.propEq(stage.id, 'id')),
                        (idx) =>
                          R.set(
                            R.lensPath([idx, 'expected_duration']),
                            value
                          )(clonedStages),
                        setStages
                      )(clonedStages)
                    }}
                  />
                </Col>

                {/* Scheduled Time */}
                <Col md={12} xxl={7}>
                  <StyledDetailTimePicker
                    placeholder={'HH:mm'}
                    label={'ui.routes.form.labels.scheduled_start_time'}
                    name={`${formPrefix}.scheduled_time`}
                    railsName={`${prefix}[scheduled_time]`}
                    disabled
                    useWatch
                    initialValue={_.get(stage, 'scheduled_time', null)}
                  />
                </Col>

                {/* Expected End Time */}
                <Col md={12} xxl={7}>
                  <StyledDetailTimePicker
                    placeholder={'HH:mm'}
                    label={'ui.routes.form.labels.expected_end_time'}
                    name={`${formPrefix}.expected_end_time`}
                    railsName={`${prefix}[expected_end_time]`}
                    useWatch
                    disabled
                    style={{ marginLeft: '10px' }}
                    initialValue={_.get(stage, 'expected_end_time', null)}
                  />
                </Col>
              </Row>
            </StyledStartCol>
          </Row>
        </StyledStartCol>

        {!_.get(stage, 'break') && (
          <Col
            xl={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DeleteOutlined
              style={{ color: 'red', cursor: 'pointer', fontSize: '16px' }}
              onClick={() => handleDeleteStage(stage)}
            />
          </Col>
        )}
      </Row>

      {/* Render break stage attributes*/}
      {isBreak(stage) && (
        <HiddenInputRenderer
          keys={['break', 'typology', 'priority']}
          list={[stage]}
          prefix={prefix}
          prefixWithoutIdx
        />
      )}
    </StyledCard>
  )
}
