import React, { useContext, useState } from 'react'

import { Logger } from 'aws-amplify'
import _ from 'lodash'

const log = new Logger('Providers/ReferrerWrapperProvider')

/**
 * @typedef {Object} ReferrerContextValue
 * @property {string|null} referrer - The referrer URL.
 * @property {Object} queryParams - The query parameters of the current URL.
 * @property {string|null} baseUrl - The base URL of the referrer.
 */

const ReferrerContext = React.createContext(
  /** @type {ReferrerContextValue} */ ({})
)

/**
 * Custom hook to access the referrer context.
 * @returns {ReferrerContextValue} The referrer context.
 */
export const useReferrer = () => useContext(ReferrerContext)

/**
 * Provider component that wraps its children and provides referrer context.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {JSX.Element} The JSX for the provider component.
 */
const ReferrerWrapperProvider = ({ children }) => {
  const urlParams = new URL(window.location.href).searchParams
  const [referrer, setReferrer] = useState(document.referrer)
  const [queryParams, setQueryParams] = useState(
    Object.fromEntries(urlParams.entries())
  )
  const [baseUrl, setBaseUrl] = useState(
    document.referrer ? new URL(document.referrer).pathname : null
  )

  log.debug('current referrer', { baseUrl, referrer, queryParams })

  return (
    <ReferrerContext.Provider
      value={{
        referrer,
        queryParams,
        baseUrl,
      }}
    >
      {children}
    </ReferrerContext.Provider>
  )
}

export default ReferrerWrapperProvider
