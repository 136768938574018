import React from 'react'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import { Input } from 'antd'

const logger = new Logger('HiddenInputRenderer')
const HiddenInputRenderer = ({
  list,
  prefix,
  keys,
  prefixWithoutIdx = false,
}) => {
  return (
    <>
      {_.map(list, (value, outerIdx) => {
        return _.map(keys, (prop, idx) => {
          return (
            <Input
              key={`${prop}-${idx}`}
              type={'hidden'}
              name={prefixWithoutIdx ? `${prefix}[${prop}]` : `${prefix}[${outerIdx}][${prop}]`}
              value={_.get(value, prop)}
            />
          )
        })
      })}
    </>
  )
}

export default HiddenInputRenderer
