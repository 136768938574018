import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import * as F from '@utils/functions'
import { Table as AntdTable, Button, Space, message } from 'antd'
import { Logger } from 'aws-amplify'
import _, { filter, first } from 'lodash'

import {
  Display,
  DisplayDateTime,
  DisplayPrice,
} from '../../.././Components/Display'
import { GenericDeleteForm as InvoiceDelete } from '../../../Components'
import { LocaleDisplay } from '../../../Components/Display'
import {
  INVOICE_GENERATE_PDF,
  INVOICE_SEARCH,
  makeApiRequest,
} from '../../../api'
import { S3_DOCS_BUCKET } from '../../../config'
import { statuses } from '../../../enums/invoice'
import * as s3 from '../../../lib'
import {
  download,
  getFileNameByKey,
  getKeyByStorageUrl,
  marshalSorterParams,
  prepareFilters,
} from '../../../utils'
import { InvoicesContext } from '../contexts'
import { StatusTag } from './index'

const log = new Logger('InvoicesTable')

export const Table = ({ dataSource, searchInvoices }) => {
  // inject Intl instance
  const intl = useIntl()

  const {
    searchLoading,
    setSearchLoading,
    setFilters,
    setOrders,
    query,
    filters,
    orders,
    setInvoices,
    pagination,
    setPagination,
  } = useContext(InvoicesContext)

  const firstLoad = useRef(true)
  const [deleteModalData, setDeleteModalData] = useState({ open: false })

  const getColumns = (intl) => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.invoices.attributes.id',
        }),
        fixed: true,
        dataIndex: 'id',
        render: (a, record) => <Display value={_.get(record, 'id')} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.invoices.attributes.facility',
        }),
        dataIndex: 'facility.business_name',
        render: (a, record) => (
          <Display value={_.get(record, 'facility.business_name')} />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.invoices.attributes.reference',
        }),
        dataIndex: 'reference',
        render: (value) => <Display value={value} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.invoices.attributes.invoice_date',
        }),
        dataIndex: 'invoice_date',
        render: (a, record) => (
          <DisplayDateTime
            value={_.get(record, 'invoice_date')}
            format={'DD/MM/YYYY'}
          />
        ),
        defaultSortOrder: F.orderToSort('invoice_date', orders),
        sorter: true,
      },
      {
        title: intl.formatMessage({
          id: 'ui.invoices.attributes.status',
        }),
        dataIndex: 'status',
        render: (a, record) => (
          <StatusTag status={record.status}>
            <LocaleDisplay
              value={record.status}
              localePath={'ui.invoices.enums.statuses'}
            />
          </StatusTag>
        ),
        defaultFilteredValue: F.getFilterValues('status', filters),
        filters: _.map(statuses, (v) => {
          return {
            text: intl.formatMessage({
              id: `ui.invoices.enums.statuses.${v}`,
            }),
            value: v,
          }
        }),
      },
      {
        title: intl.formatMessage({
          id: 'ui.invoices.attributes.total_amount',
        }),
        dataIndex: 'total_amount',
        render: (a, record) => (
          <DisplayPrice value={_.get(record, 'total_amount')} />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.invoices.actions.title',
        }),
        key: 'action',
        fixed: 'right',
        render: (_, record) => (
          <>
            {/* DETAIL */}
            <Space>
              {/* EDIT */}
              <Button
                href={`/invoices/${record.id}/edit`}
                icon={<EditOutlined />}
              />

              {/* DOWNLOAD */}
              <Button
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record)}
              />

              {/*  DELETE */}

              {record.status !== 'invoiced' && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    setDeleteModalData({
                      open: true,
                      record,
                    })
                  }
                />
              )}
            </Space>
          </>
        ),
      },
    ]

    return columns
  }

  log.info('Invoices data source ... :', dataSource)

  const handleDownload = async (invoice) => {
    log.info('Generating invoice...', invoice)
    message.info('Generazione della Fattura in corso...')

    const { data: generatedInvoice } = await makeApiRequest(
      INVOICE_GENERATE_PDF(_.get(invoice, 'id')),
      'post'
    )

    log.debug('generateInvoicePdf after', generatedInvoice)

    const document = _.get(generatedInvoice, 'document', {})
    const fileKey = getKeyByStorageUrl(document.storage_url, S3_DOCS_BUCKET)
    const presignedUrl = await s3.getPresignedUrl(fileKey)

    const fileName = getFileNameByKey(fileKey)

    try {
      await download(presignedUrl, fileName)
      message.success('File scaricato con successo!')
    } catch (err) {
      log.error('Error downloading file: ', err)
      message.error(`Error downloading file: ${err}`)
    }
  }

  const handleTableChange = (pager, selectedFilters, sorter) => {
    log.debug('handleTableChange PRE', { pager, selectedFilters, sorter })

    const newFilters = prepareFilters(selectedFilters)
    const orders = marshalSorterParams(sorter)
    log.debug('handleTableChange POST', { orders, newFilters })

    setPagination(pager)
    setFilters(newFilters)
    setOrders(orders)
  }

  const handlePaginationChange = (current, pageSize) => {
    setPagination(F.hasPaginationChanged(pagination, { current, pageSize }))
  }
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }

    searchInvoices()
  }, [query, filters, orders])

  const columns = useMemo(() => getColumns(intl), [])

  return (
    !firstLoad.current && (
      <>
        <AntdTable
          dataSource={dataSource}
          rowKey={'id'}
          columns={columns}
          pagination={{ ...pagination, onChange: handlePaginationChange }}
          scroll={{ x: true }}
          loading={{
            spinning: searchLoading,
            indicator: (
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
                spin
              />
            ),
          }}
          onChange={handleTableChange}
        />

        <InvoiceDelete
          deleteModalData={deleteModalData}
          action={`/invoices/${_.get(deleteModalData, 'record.id')}`}
          model={'invoices'}
          name={'delete-invoice-form'}
          onCancel={() => setDeleteModalData({ open: false })}
        />
      </>
    )
  )
}

export default Table
