import styled from 'styled-components'

const StyledEnv = styled.div`
  width: 100%;

  display: ${(props) => {
    if (
      process.env.RAILS_ENV !== 'staging' &&
      process.env.RAILS_ENV !== 'development'
    ) {
      return 'none'
    }
    return 'flex'
  }};
  font-size: 12px;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir Medium', sans-serif;
  color: white;
  height: ${(props) => {
    if (
      process.env.RAILS_ENV !== 'staging' &&
      process.env.RAILS_ENV !== 'development'
    ) {
      return '0px'
    }
    return '15px'
  }};
  background-color: ${(props) => {
    if (process.env.RAILS_ENV === 'development') {
      return '#AF2D1F'
    } else if (process.env.RAILS_ENV === 'staging') {
      return '#2092b5'
    }
    return '#00497C'
  }};
`

export default StyledEnv
