import React from 'react'

import { Tag } from 'antd'

const StatusTag = ({ status, children }) => {
  let color = null
  switch (status) {
    case 'open':
      color = 'green'
      break
    case 'closed':
      color = 'volcano'
      break
    case 'in_progress':
      color = 'processing'
      break
  }
  return <Tag color={color}>{children}</Tag>
}

export default StatusTag
