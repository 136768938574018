// Customer Contexts

import {createContext} from 'react'

// List CustomersContext
export const CustomersContext = createContext()
export const CustomersContextProvider = CustomersContext.Provider

// Create/Edit/Detail CustomerContext
export const CustomerContext = createContext()
export const CustomerContextProvider = CustomerContext.Provider

// Create/Edit/Detail FacilityContext
export const FacilityContext = createContext()
export const FacilityContextProvider = FacilityContext.Provider

// DocumentsContext
export const DocumentsContext = createContext()
export const DocumentsContextProvider = DocumentsContext.Provider
