import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { WarningOutlined } from '@ant-design/icons'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { CustomForm } from '../../.././Components'
import { GlobalContext } from '../../../contexts'
import { FormHeader } from '../components'
import { RouteContext } from '../contexts'

const log = new Logger('RouteForm')

const RouteForm = (props) => {
  // inject intl
  const intl = useIntl()

  const validateMessages = {
    required: intl.formatMessage({ id: `ui.validations.is_required` }),
  }

  const { title } = props
  const { route } = useContext(RouteContext)

  const { mode, setLoading: setGlobalLoading } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)

  // button type
  const [buttonType, setButtonType] = useState({
    type: 'primary',
    danger: false,
  })

  const onFinish = (values) => {
    log.debug('Values', values)
    setLoading(true)

    document.getElementById('route-form').submit()
  }

  const onFinishFailed = (e) => {
    log.warn('Finish failed', e)
    setButtonType({
      type: 'dashed',
      icon: <WarningOutlined />,
      danger: true,
    })
  }

  const hideSubmit = R.any(
    R.or(R.propEq('terminated', 'status'), R.propEq('invoiced', 'status'))
  )(R.prop('interventions', route))

  return (
    <CustomForm
      action={_.get(props, 'action', '/routes')}
      layout={'vertical'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={route}
      method={'post'}
      name={'route-form'}
      validateMessages={validateMessages}
      disabled={loading}
    >
      {/* Add Form header based on mode */}
      <FormHeader
        title={title}
        mode={mode}
        buttonType={buttonType}
        hideSubmit={hideSubmit}
      />

      {props.children}

      {/*  Duplicate Form header on bottom*/}
      <FormHeader
        mode={mode}
        buttonType={buttonType}
        rowStyle={{
          paddingTop: '10px',
        }}
        hideSubmit={hideSubmit}
      />
    </CustomForm>
  )
}

export default RouteForm
