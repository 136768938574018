export { default as InterventionsTable } from './Table'

export { default as StatusTag } from './StatusTag'

export { default as InterventionSearch } from './InterventionSearch'

export { default as FormHeader } from './FormHeader'

export { default as CloseInterventionModal } from './CloseInterventionModal'
export { default as CloseInterventionForm } from './CloseInterventionForm'

export { default as MaterialsSection } from './MaterialsSection'
export { default as MaterialsTable } from './MaterialsTable'
export { default as InterventionMaterialsTable } from './InterventionMaterialsTable'

export { default as InterventionFather } from './InterventionFather'
export { default as InterventionChild } from './InterventionChild'
export { default as InterventionCall } from './InterventionCall'

export { default as DocumentSection } from './DocumentSection'
