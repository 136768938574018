import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { Input } from 'antd'

// Context
import { DocumentsContext } from '../../contexts'

const { Search } = Input

const DocumentsSearch = () => {
  const intl = useIntl()

  const { setQuery, readonly } = useContext(DocumentsContext)

  const onSearch = (query) => setQuery(query)

  return (
    <Search
      placeholder={intl.formatMessage({
        id: 'ui.documents.placeholders.search',
      })}
      onSearch={onSearch}
      allowClear
      size={'middle'}
      disabled={!readonly}
    />
  )
}
export default DocumentsSearch
