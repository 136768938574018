import React, { useContext, useState } from 'react'
import { Logger } from 'aws-amplify'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, Col, Collapse, Form as AntdForm, Row } from 'antd'

import _ from 'lodash'
import styled from 'styled-components'
import { CustomerContext } from '../../contexts'
import { FacilityPanel } from './FacilityPanel'
import { DeleteOutlined } from '@ant-design/icons'
import DeleteRecord from '../../../.././Components/DeleteRecord'
import ModalDelete from '../../../.././Components/ModalDelete'

const { Panel } = Collapse
const { List } = AntdForm

const log = new Logger('FacilitiesForm')

const StyledAddDiv = styled.div`
  margin-top: 20px;
`

// Facilities Form
export const FacilitiesForm = (props) => {
  // inject intl
  const intl = useIntl()

  const { customer, enums, readonly } = useContext(CustomerContext)

  log.info('Current customer: ', customer)
  log.debug('Current enums: ', enums)

  // disable form submit
  const [disabled, setDisabled] = useState(true)

  const [loading, setLoading] = useState(false)

  const [initialValues, setInitialValues] = useState(customer.facilities)

  log.debug('Customer facilities', customer.facilities)

  // set panel activeKey
  const [activeKey, setActiveKey] = useState(null)

  const onPanelChange = (key) => {
    log.verbose('Panel change event', key)
    setActiveKey(key)
  }

  // state to save facilities to delete
  const [deletedFacilities, setDeletedFacilities] = useState([])
  const [modalData, setModalData] = useState({ open: false })

  // form instance
  const form = AntdForm.useFormInstance()

  log.debug('Facilities initial values', {
    initialValues,
    form: form.getFieldsValue(true),
    facilities: customer.facilities,
  })

  return (
    <>
      {/* Dynamic Form List */}
      <List name={'facilities'}>
        {(fields, { add, remove }) => (
          <>
            {!_.isEmpty(fields) && (
              <>
                <Collapse
                  accordion
                  activeKey={activeKey}
                  onChange={onPanelChange}
                >
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <FacilityPanel
                        key={key}
                        loading={props.loading}
                        initialValues={initialValues}
                        index={index}
                        extra={
                          !readonly && (
                            <DeleteOutlined
                              className='dynamic-delete-button'
                              onClick={(e) => {
                                e.stopPropagation()
                                setModalData({
                                  open: true,
                                  index,
                                  key,
                                  name,
                                })
                              }}
                            />
                          )
                        }
                      />
                    </>
                  ))}
                </Collapse>
                {/*  Delete Modal */}
                <ModalDelete
                  key={`delete-modal`}
                  open={modalData.open}
                  recordType={
                    <FormattedMessage id={'ui.facilities.singular'} />
                  }
                  onCancel={() => setModalData({ open: false })}
                  onOk={() => {
                    const { name, index } = modalData

                    log.debug(`Name`, name)

                    const facility_id = form.getFieldValue(
                      _.toPath(`facilities.${name}.id`)
                    )
                    log.debug('delete facility with id: ', facility_id)

                    // add facility to facilities to delete
                    if (facility_id) {
                      setDeletedFacilities([
                        ...deletedFacilities,
                        {
                          index,
                          id: facility_id,
                          _destroy: true,
                        },
                      ])
                    }

                    if (facility_id) {
                      log.debug('updating initial values ... ')

                      setInitialValues(
                        _.filter(
                          initialValues,
                          (v, idx) => v.id !== facility_id
                        )
                      )
                    }

                    remove(name)

                    setModalData({ open: false })
                  }}
                />
              </>
            )}

            {/* Button to add new Facility */}
            {!readonly && (
              <StyledAddDiv>
                <Row align={'middle'} justify={'center'}>
                  <Col>
                    <AntdForm.Item>
                      <Button
                        type={'primary'}
                        onClick={() => {
                          add({
                            customer_id: customer.id,
                            address: {
                              country: 'ITA',
                              address_type: 'physical',
                            },
                          })
                          setActiveKey(fields.length)
                          setDisabled(false)
                        }}
                      >
                        <FormattedMessage id={'ui.facilities.buttons.add'} />
                      </Button>
                    </AntdForm.Item>
                  </Col>
                </Row>
              </StyledAddDiv>
            )}
          </>
        )}
      </List>

      {/* Add Hidden Record to delete facilities*/}
      {deletedFacilities.map((f, key) => (
        <DeleteRecord
          key={key}
          record={f}
          prefix={`facilities_attributes[${f.index}]`}
          name={`facilities.${f.index}`}
        />
      ))}
    </>
  )
}
