// Tag Select

import React, { useContext, useState } from 'react'
import { Form as AntdForm, Input, Select } from 'antd'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'
import { FormattedMessage, useIntl } from 'react-intl'

const log = new Logger('TagSelect')

const TagSelect = ({
  name,
  options,
  label,
  tag_mode = 'tags',
  onChange = undefined,
  disabled = false,
  railsName = name,
  required = false,
  useWatch = false,
  defaultValue = [],
  customValidator = undefined,
  customRenderer = undefined,
}) => {
  const [value, setValue] = useState(defaultValue)

  const intl = useIntl()

  const form = AntdForm.useFormInstance()
  log.debug(`Current value`, value)
  const handleChange = (selectedValue, selectedOptions) => {
    log.debug(`Change event`, {
      selectedValue,
      selectedOptions,
    })

    if (tag_mode === 'tags') {
      setValue(selectedValue)
    }

    if (tag_mode === 'single') {
      if (selectedOptions?.length === 0 || selectedOptions?.length === 1) {
        log.debug(`Only one option selected`, {
          selectedValue,
        })

        if (_.isFunction(onChange)) onChange(_.first(selectedOptions), form)

        setValue(selectedValue)
      }
    }
  }

  const validateSelect = (rule, value) => {
    log.debug(`Custom validator`, { rule, value })

    if (value && value.length > 1) {
      return Promise.reject(
        new Error(
          intl.formatMessage({
            id: 'ui.validations.tag_select.single',
          })
        )
      )
    }

    return Promise.resolve()
  }

  const { mode } = useContext(GlobalContext)

  const rules = [
    {
      required,
    },
    ...(tag_mode === 'single'
      ? [
          {
            validator: validateSelect,
          },
        ]
      : []),
  ]

  if (customValidator) rules.push(...customValidator)

  log.debug(`Rules`, rules)

  return (
    <>
      {/* Custom hidden renderer */}
      {_.isFunction(customRenderer) ? (
        customRenderer(useWatch ? AntdForm.useWatch(_.toPath(name)) :  value, railsName)
      ) : (
        <>
          {/* Hidden field for rails*/}
          {tag_mode === 'single' && (
            <Input name={railsName} type={'hidden'} value={value[0]} />
          )}

          {tag_mode === 'tags' &&
            _.map(value, (v, idx) => (
              <Input key={idx} name={railsName} type={'hidden'} value={v} />
            ))}
        </>
      )}

      <AntdForm.Item
        name={_.toPath(name)}
        label={<FormattedMessage id={label} />}
        required={required}
        rules={rules}
      >
        <Select
          showArrow
          options={options}
          optionFilterProp={'label'}
          showSearch
          mode={'tags'}
          onChange={handleChange}
          disabled={disabled || mode === 'detail'}
        />
      </AntdForm.Item>
    </>
  )
}

export default TagSelect
