import React, { useContext } from 'react'
import { Button, Col, Form, Row, Space, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { GlobalContext } from '../../../contexts'
import { CallContext } from '../contexts'

const StyledDiv = styled.div`
  margin-bottom: 20px;
`

const FormHeader = ({ title, mode, buttonType = {} }) => {
  const { setGlobalLoading } = useContext(GlobalContext)
  const { call, setModalData } = useContext(CallContext)

  return (
    <>
      {mode === 'detail' ? (
        <>
          <Typography.Title level={2}>
            <FormattedMessage id={'ui.calls.detail'} />
          </Typography.Title>
          <Row justify={'space-between'}>
            <Col>
              <StyledDiv>
                <Button
                  type={'primary'}
                  href={'/calls'}
                  onClick={() => setGlobalLoading(true)}
                >
                  <FormattedMessage id={'ui.calls.actions.back_to_list'} />
                </Button>
              </StyledDiv>
            </Col>
            <Col>
              <Space>
                {/* removed until further instructions */}
                {/* CLOSE */}

                {/*{call.status !== 'closed' && (*/}
                {/*  <Button*/}
                {/*    danger*/}
                {/*    onClick={() => {*/}
                {/*      setModalData({*/}
                {/*        open: true,*/}
                {/*      })*/}
                {/*    }}*/}
                {/*    type={'primary'}*/}
                {/*  >*/}
                {/*    <FormattedMessage id={'ui.calls.buttons.close'} />*/}
                {/*  </Button>*/}
                {/*)}*/}

                {/* CREATE INTERVENTION */}
                {call.status !== 'closed' && (
                  <Button
                    type={'primary'}
                    href={`/calls/${call.id}/interventions/new`}
                  >
                    <FormattedMessage
                      id={'ui.calls.buttons.create_intervention'}
                    />
                  </Button>
                )}

                {/*  EDIT */}
                <Button type={'primary'} href={`/calls/${call.id}/edit`}>
                  <FormattedMessage id={'ui.calls.buttons.edit'} />
                </Button>
              </Space>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {title}
          <Row justify={'space-between'}>
            <Col>
              <StyledDiv>
                <Button
                  type={'primary'}
                  href={'/calls'}
                  onClick={() => setGlobalLoading(true)}
                >
                  <FormattedMessage id={'ui.calls.actions.back_to_list'} />
                </Button>
              </StyledDiv>
            </Col>
            <Col>
              <Form.Item>
                <Button {...buttonType} htmlType={'submit'}>
                  <FormattedMessage id={'ui.button.submit'} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default FormHeader
