[
  {
    "key": "1",
    "ITA": "ABANO TERME-MONTEORTONE",
    "state_code": "PD",
    "cab": "623207",
    "cap": "35031"
  },
  {
    "key": "2",
    "ITA": "ABANO TERME",
    "state_code": "PD",
    "cab": "623207",
    "cap": "35031"
  },
  {
    "key": "3",
    "ITA": "ABBADIA CERRETO",
    "state_code": "LO",
    "cab": "903609",
    "cap": "26834"
  },
  {
    "key": "4",
    "ITA": "ABBADIA LARIANA-CREBBIO",
    "state_code": "LC",
    "cab": "508101",
    "cap": "23821"
  },
  {
    "key": "5",
    "ITA": "ABBADIA LARIANA",
    "state_code": "LC",
    "cab": "508101",
    "cap": "23821"
  },
  {
    "key": "6",
    "ITA": "ABBADIA SAN SALVATORE",
    "state_code": "SI",
    "cab": "717504",
    "cap": "53021"
  },
  {
    "key": "7",
    "ITA": "ABBASANTA",
    "state_code": "OR",
    "cab": "877100",
    "cap": "9071"
  },
  {
    "key": "8",
    "ITA": "ABBATEGGIO",
    "state_code": "PE",
    "cab": "954008",
    "cap": "65020"
  },
  {
    "key": "9",
    "ITA": "ABBIATEGRASSO",
    "state_code": "MI",
    "cab": "323808",
    "cap": "20081"
  },
  {
    "key": "10",
    "ITA": "ABETONE-LE REGINE",
    "state_code": "PT",
    "cab": "703603",
    "cap": "51021"
  },
  {
    "key": "11",
    "ITA": "ABETONE",
    "state_code": "PT",
    "cab": "703603",
    "cap": "51021"
  },
  {
    "key": "12",
    "ITA": "ABRIOLA",
    "state_code": "PZ",
    "cab": "911800",
    "cap": "85010"
  },
  {
    "key": "13",
    "ITA": "ACATE",
    "state_code": "RG",
    "cab": "844308",
    "cap": "97011"
  },
  {
    "key": "14",
    "ITA": "ACCADIA",
    "state_code": "FG",
    "cab": "782607",
    "cap": "71021"
  },
  {
    "key": "15",
    "ITA": "ACCEGLIO",
    "state_code": "CN",
    "cab": "918201",
    "cap": "12021"
  },
  {
    "key": "16",
    "ITA": "ACCETTURA",
    "state_code": "MT",
    "cab": "802801",
    "cap": "75011"
  },
  {
    "key": "17",
    "ITA": "ACCIANO",
    "state_code": "AQ",
    "cab": "909408",
    "cap": "67020"
  },
  {
    "key": "18",
    "ITA": "ACCIANO-BEFFI",
    "state_code": "AQ",
    "cab": "909408",
    "cap": "67020"
  },
  {
    "key": "19",
    "ITA": "ACCIANO-ROCCAPRETURO",
    "state_code": "AQ",
    "cab": "909408",
    "cap": "67020"
  },
  {
    "key": "20",
    "ITA": "ACCUMOLI",
    "state_code": "RI",
    "cab": "734608",
    "cap": "2011"
  },
  {
    "key": "21",
    "ITA": "ACCUMOLI-GRISCIANO",
    "state_code": "RI",
    "cab": "734608",
    "cap": "2011"
  },
  {
    "key": "22",
    "ITA": "ACERENZA",
    "state_code": "PZ",
    "cab": "418400",
    "cap": "85011"
  },
  {
    "key": "23",
    "ITA": "ACERNO",
    "state_code": "SA",
    "cab": "767103",
    "cap": "84042"
  },
  {
    "key": "24",
    "ITA": "ACERRA-PEZZALUNGA",
    "state_code": "NA",
    "cab": "396804",
    "cap": "80011"
  },
  {
    "key": "25",
    "ITA": "ACERRA",
    "state_code": "NA",
    "cab": "396804",
    "cap": "80011"
  },
  {
    "key": "26",
    "ITA": "ACI BONACCORSI",
    "state_code": "CT",
    "cab": "838300",
    "cap": "95020"
  },
  {
    "key": "27",
    "ITA": "ACI CASTELLO-FICARAZZI",
    "state_code": "CT",
    "cab": "261008",
    "cap": "95021"
  },
  {
    "key": "28",
    "ITA": "ACI CASTELLO-CANNIZZARO",
    "state_code": "CT",
    "cab": "261008",
    "cap": "95021"
  },
  {
    "key": "29",
    "ITA": "ACI CASTELLO",
    "state_code": "CT",
    "cab": "261008",
    "cap": "95021"
  },
  {
    "key": "30",
    "ITA": "ACI CASTELLO-ACI TREZZA",
    "state_code": "CT",
    "cab": "261008",
    "cap": "95021"
  },
  {
    "key": "31",
    "ITA": "ACI CATENA-SAN NICOLO'",
    "state_code": "CT",
    "cab": "838409",
    "cap": "95022"
  },
  {
    "key": "32",
    "ITA": "ACI CATENA-NIZZETI",
    "state_code": "CT",
    "cab": "838409",
    "cap": "95022"
  },
  {
    "key": "33",
    "ITA": "ACI CATENA-EREMO SANT'ANNA",
    "state_code": "CT",
    "cab": "838409",
    "cap": "95022"
  },
  {
    "key": "34",
    "ITA": "ACI CATENA-ACI SAN FILIPPO",
    "state_code": "CT",
    "cab": "838409",
    "cap": "95022"
  },
  {
    "key": "35",
    "ITA": "ACI CATENA-VAMPOLIERI",
    "state_code": "CT",
    "cab": "838409",
    "cap": "95022"
  },
  {
    "key": "36",
    "ITA": "ACI CATENA",
    "state_code": "CT",
    "cab": "838409",
    "cap": "95022"
  },
  {
    "key": "37",
    "ITA": "ACI SANT'ANTONIO-LAVINAIO",
    "state_code": "CT",
    "cab": "838508",
    "cap": "95025"
  },
  {
    "key": "38",
    "ITA": "ACI SANT'ANTONIO-LAVINA",
    "state_code": "CT",
    "cab": "838508",
    "cap": "95025"
  },
  {
    "key": "39",
    "ITA": "ACI SANT'ANTONIO",
    "state_code": "CT",
    "cab": "838508",
    "cap": "95025"
  },
  {
    "key": "40",
    "ITA": "ACI SANT'ANTONIO-MONTEROSSO",
    "state_code": "CT",
    "cab": "838508",
    "cap": "95025"
  },
  {
    "key": "41",
    "ITA": "ACI SANT'ANTONIO-SANTA MARIA LA STELLA",
    "state_code": "CT",
    "cab": "838508",
    "cap": "95025"
  },
  {
    "key": "42",
    "ITA": "ACIREALE",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "43",
    "ITA": "ACIREALE-ACI PLATANI",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "44",
    "ITA": "ACIREALE-GUARDIA",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "45",
    "ITA": "ACIREALE-MANGANO",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "46",
    "ITA": "ACIREALE-PENNISI",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "47",
    "ITA": "ACIREALE-PIANO D'API",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "48",
    "ITA": "ACIREALE-POZZILLO",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "49",
    "ITA": "ACIREALE-SANTA MARIA DEGLI AMMALATI",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "50",
    "ITA": "ACIREALE-SANTA MARIA LA SCALA",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "51",
    "ITA": "ACIREALE-SANTA TECLA DI ACIREALE",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "52",
    "ITA": "ACIREALE-SCILLICHENTI",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "53",
    "ITA": "ACIREALE-STAZZO",
    "state_code": "CT",
    "cab": "262006",
    "cap": "95024"
  },
  {
    "key": "54",
    "ITA": "ACQUACANINA",
    "state_code": "MC",
    "cab": "941906",
    "cap": "62035"
  },
  {
    "key": "55",
    "ITA": "ACQUAFONDATA",
    "state_code": "FR",
    "cab": "948208",
    "cap": "3040"
  },
  {
    "key": "56",
    "ITA": "ACQUAFONDATA-CASALCASSINESE",
    "state_code": "FR",
    "cab": "948208",
    "cap": "3040"
  },
  {
    "key": "57",
    "ITA": "ACQUAFORMOSA",
    "state_code": "CS",
    "cab": "958504",
    "cap": "87010"
  },
  {
    "key": "58",
    "ITA": "ACQUAFREDDA",
    "state_code": "BS",
    "cab": "539809",
    "cap": "25010"
  },
  {
    "key": "59",
    "ITA": "ACQUALAGNA-POLE",
    "state_code": "PU",
    "cab": "681908",
    "cap": "61041"
  },
  {
    "key": "60",
    "ITA": "ACQUALAGNA-PETRICCIO",
    "state_code": "PU",
    "cab": "681908",
    "cap": "61041"
  },
  {
    "key": "61",
    "ITA": "ACQUALAGNA-FURLO",
    "state_code": "PU",
    "cab": "681908",
    "cap": "61041"
  },
  {
    "key": "62",
    "ITA": "ACQUALAGNA-BELLARIA",
    "state_code": "PU",
    "cab": "681908",
    "cap": "61041"
  },
  {
    "key": "63",
    "ITA": "ACQUALAGNA-ABBADIA DI NARO",
    "state_code": "PU",
    "cab": "681908",
    "cap": "61041"
  },
  {
    "key": "64",
    "ITA": "ACQUALAGNA",
    "state_code": "PU",
    "cab": "681908",
    "cap": "61041"
  },
  {
    "key": "65",
    "ITA": "ACQUANEGRA CREMONESE-FENGO",
    "state_code": "CR",
    "cab": "566109",
    "cap": "26020"
  },
  {
    "key": "66",
    "ITA": "ACQUANEGRA CREMONESE",
    "state_code": "CR",
    "cab": "566109",
    "cap": "26020"
  },
  {
    "key": "67",
    "ITA": "ACQUANEGRA SUL CHIESE",
    "state_code": "MN",
    "cab": "574301",
    "cap": "46011"
  },
  {
    "key": "68",
    "ITA": "ACQUANEGRA SUL CHIESE-MOSIO",
    "state_code": "MN",
    "cab": "574301",
    "cap": "46011"
  },
  {
    "key": "69",
    "ITA": "ACQUAPENDENTE",
    "state_code": "VT",
    "cab": "728600",
    "cap": "1021"
  },
  {
    "key": "70",
    "ITA": "ACQUAPENDENTE-TORRE ALFINA",
    "state_code": "VT",
    "cab": "728600",
    "cap": "1021"
  },
  {
    "key": "71",
    "ITA": "ACQUAPENDENTE-TREVINANO",
    "state_code": "VT",
    "cab": "728600",
    "cap": "1021"
  },
  {
    "key": "72",
    "ITA": "ACQUAPPESA",
    "state_code": "CS",
    "cab": "958512",
    "cap": "87020"
  },
  {
    "key": "73",
    "ITA": "ACQUAPPESA-ACQUAPPESA MARINA",
    "state_code": "CS",
    "cab": "958512",
    "cap": "87020"
  },
  {
    "key": "74",
    "ITA": "ACQUAPPESA-INTAVOLATA",
    "state_code": "CS",
    "cab": "958512",
    "cap": "87020"
  },
  {
    "key": "75",
    "ITA": "ACQUARICA DEL CAPO",
    "state_code": "LE",
    "cab": "794008",
    "cap": "73040"
  },
  {
    "key": "76",
    "ITA": "ACQUARO-LIMPIDI",
    "state_code": "VV",
    "cab": "912600",
    "cap": "89832"
  },
  {
    "key": "77",
    "ITA": "ACQUARO",
    "state_code": "VV",
    "cab": "912600",
    "cap": "89832"
  },
  {
    "key": "78",
    "ITA": "ACQUASANTA TERME",
    "state_code": "AP",
    "cab": "693408",
    "cap": "63041"
  },
  {
    "key": "79",
    "ITA": "ACQUASANTA TERME-PAGGESE",
    "state_code": "AP",
    "cab": "693408",
    "cap": "63041"
  },
  {
    "key": "80",
    "ITA": "ACQUASANTA TERME-PONTE D'ARLI",
    "state_code": "AP",
    "cab": "693408",
    "cap": "63041"
  },
  {
    "key": "81",
    "ITA": "ACQUASANTA TERME-POZZA DI ACQUASANTA",
    "state_code": "AP",
    "cab": "693408",
    "cap": "63041"
  },
  {
    "key": "82",
    "ITA": "ACQUASANTA TERME-QUINTODECIMO",
    "state_code": "AP",
    "cab": "693408",
    "cap": "63041"
  },
  {
    "key": "83",
    "ITA": "ACQUASANTA TERME-SAN MARTINO DI ACQUASANTA",
    "state_code": "AP",
    "cab": "693408",
    "cap": "63041"
  },
  {
    "key": "84",
    "ITA": "ACQUASANTA TERME-TALLACANO",
    "state_code": "AP",
    "cab": "693408",
    "cap": "63041"
  },
  {
    "key": "85",
    "ITA": "ACQUASPARTA-PORTARIA",
    "state_code": "TR",
    "cab": "725101",
    "cap": "5021"
  },
  {
    "key": "86",
    "ITA": "ACQUASPARTA-CASIGLIANO",
    "state_code": "TR",
    "cab": "725101",
    "cap": "5021"
  },
  {
    "key": "87",
    "ITA": "ACQUASPARTA",
    "state_code": "TR",
    "cab": "725101",
    "cap": "5021"
  },
  {
    "key": "88",
    "ITA": "ACQUAVIVA COLLECROCE",
    "state_code": "CB",
    "cab": "910406",
    "cap": "86030"
  },
  {
    "key": "89",
    "ITA": "ACQUAVIVA D'ISERNIA",
    "state_code": "IS",
    "cab": "955500",
    "cap": "86080"
  },
  {
    "key": "90",
    "ITA": "ACQUAVIVA DELLE FONTI",
    "state_code": "BA",
    "cab": "413005",
    "cap": "70021"
  },
  {
    "key": "91",
    "ITA": "ACQUAVIVA DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47892"
  },
  {
    "key": "92",
    "ITA": "ACQUAVIVA PICENA",
    "state_code": "AP",
    "cab": "693507",
    "cap": "63030"
  },
  {
    "key": "93",
    "ITA": "ACQUAVIVA PLATANI",
    "state_code": "CL",
    "cab": "832808",
    "cap": "93010"
  },
  {
    "key": "94",
    "ITA": "ACQUEDOLCI",
    "state_code": "ME",
    "cab": "826602",
    "cap": "98070"
  },
  {
    "key": "95",
    "ITA": "ACQUI TERME",
    "state_code": "AL",
    "cab": "479402",
    "cap": "15011"
  },
  {
    "key": "96",
    "ITA": "ACQUI TERME-MOIRANO",
    "state_code": "AL",
    "cab": "479402",
    "cap": "15011"
  },
  {
    "key": "97",
    "ITA": "ACRI-SERRICELLA",
    "state_code": "CS",
    "cab": "805804",
    "cap": "87041"
  },
  {
    "key": "98",
    "ITA": "ACRI-MONTAGNOLA",
    "state_code": "CS",
    "cab": "805804",
    "cap": "87041"
  },
  {
    "key": "99",
    "ITA": "ACRI-DUGLIA",
    "state_code": "CS",
    "cab": "805804",
    "cap": "87041"
  },
  {
    "key": "100",
    "ITA": "ACRI",
    "state_code": "CS",
    "cab": "805804",
    "cap": "87041"
  },
  {
    "key": "101",
    "ITA": "ACRI-SAN GIACOMO D'ACRI",
    "state_code": "CS",
    "cab": "805804",
    "cap": "87040"
  },
  {
    "key": "102",
    "ITA": "ACUTO",
    "state_code": "FR",
    "cab": "742601",
    "cap": "3010"
  },
  {
    "key": "103",
    "ITA": "ADELFIA",
    "state_code": "BA",
    "cab": "413104",
    "cap": "70010"
  },
  {
    "key": "104",
    "ITA": "ADELFIA-ADELFIA MONTRONE",
    "state_code": "BA",
    "cab": "413104",
    "cap": "70010"
  },
  {
    "key": "105",
    "ITA": "ADELFIA-ADELFIA CANNETO",
    "state_code": "BA",
    "cab": "413104",
    "cap": "70010"
  },
  {
    "key": "106",
    "ITA": "ADRANO",
    "state_code": "CT",
    "cab": "838607",
    "cap": "95031"
  },
  {
    "key": "107",
    "ITA": "ADRARA SAN MARTINO",
    "state_code": "BG",
    "cab": "693101",
    "cap": "24060"
  },
  {
    "key": "108",
    "ITA": "ADRARA SAN ROCCO",
    "state_code": "BG",
    "cab": "927723",
    "cap": "24060"
  },
  {
    "key": "109",
    "ITA": "ADRIA-FASANA POLESINE",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "110",
    "ITA": "ADRIA-PASSETTO",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "111",
    "ITA": "ADRIA-PIANTAMELON",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "112",
    "ITA": "ADRIA-VALLIERA",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "113",
    "ITA": "ADRIA",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "114",
    "ITA": "ADRIA-BARICETTA",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "115",
    "ITA": "ADRIA-BELLOMBRA",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "116",
    "ITA": "ADRIA-BOTTRIGHE",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "117",
    "ITA": "ADRIA-CA' EMO",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "118",
    "ITA": "ADRIA-CAVANELLA PO",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "119",
    "ITA": "ADRIA-CAVEDON",
    "state_code": "RO",
    "cab": "631200",
    "cap": "45011"
  },
  {
    "key": "120",
    "ITA": "ADRO-TORBIATO",
    "state_code": "BS",
    "cab": "539908",
    "cap": "25030"
  },
  {
    "key": "121",
    "ITA": "ADRO",
    "state_code": "BS",
    "cab": "539908",
    "cap": "25030"
  },
  {
    "key": "122",
    "ITA": "AFFI",
    "state_code": "VR",
    "cab": "858308",
    "cap": "37010"
  },
  {
    "key": "123",
    "ITA": "AFFILE",
    "state_code": "RM",
    "cab": "388504",
    "cap": "21"
  },
  {
    "key": "124",
    "ITA": "AFRAGOLA",
    "state_code": "NA",
    "cab": "396903",
    "cap": "80021"
  },
  {
    "key": "125",
    "ITA": "AFRICO",
    "state_code": "RC",
    "cab": "192237",
    "cap": "89030"
  },
  {
    "key": "126",
    "ITA": "AGAZZANO",
    "state_code": "PC",
    "cab": "651604",
    "cap": "29010"
  },
  {
    "key": "127",
    "ITA": "AGEROLA-SAN LAZZARO DI AGEROLA",
    "state_code": "NA",
    "cab": "397000",
    "cap": "80051"
  },
  {
    "key": "128",
    "ITA": "AGEROLA-PIANILLO",
    "state_code": "NA",
    "cab": "397000",
    "cap": "80051"
  },
  {
    "key": "129",
    "ITA": "AGEROLA-BOMERANO",
    "state_code": "NA",
    "cab": "397000",
    "cap": "80051"
  },
  {
    "key": "130",
    "ITA": "AGEROLA",
    "state_code": "NA",
    "cab": "397000",
    "cap": "80051"
  },
  {
    "key": "131",
    "ITA": "AGGIUS",
    "state_code": "OT",
    "cab": "871400",
    "cap": "7020"
  },
  {
    "key": "132",
    "ITA": "AGIRA",
    "state_code": "EN",
    "cab": "835702",
    "cap": "94011"
  },
  {
    "key": "133",
    "ITA": "AGLIANA-SAN MICHELE",
    "state_code": "PT",
    "cab": "703702",
    "cap": "51031"
  },
  {
    "key": "134",
    "ITA": "AGLIANA-SAN PIERO",
    "state_code": "PT",
    "cab": "703702",
    "cap": "51031"
  },
  {
    "key": "135",
    "ITA": "AGLIANA",
    "state_code": "PT",
    "cab": "703702",
    "cap": "51031"
  },
  {
    "key": "136",
    "ITA": "AGLIANO TERME-AGLIANO",
    "state_code": "AT",
    "cab": "472209",
    "cap": "14041"
  },
  {
    "key": "137",
    "ITA": "AGLIANO TERME",
    "state_code": "AT",
    "cab": "472209",
    "cap": "14041"
  },
  {
    "key": "138",
    "ITA": "AGLIE'",
    "state_code": "TO",
    "cab": "300004",
    "cap": "10011"
  },
  {
    "key": "139",
    "ITA": "AGLIENTU",
    "state_code": "OT",
    "cab": "875609",
    "cap": "7020"
  },
  {
    "key": "140",
    "ITA": "AGLIENTU-SAN FRANCESCO D'AGLIENTU",
    "state_code": "OT",
    "cab": "875609",
    "cap": "7020"
  },
  {
    "key": "141",
    "ITA": "AGNA",
    "state_code": "PD",
    "cab": "623306",
    "cap": "35021"
  },
  {
    "key": "142",
    "ITA": "AGNADELLO",
    "state_code": "CR",
    "cab": "566208",
    "cap": "26020"
  },
  {
    "key": "143",
    "ITA": "AGNANA CALABRA",
    "state_code": "RC",
    "cab": "959916",
    "cap": "89040"
  },
  {
    "key": "144",
    "ITA": "AGNONE-VILLA CANALE",
    "state_code": "IS",
    "cab": "780700",
    "cap": "86081"
  },
  {
    "key": "145",
    "ITA": "AGNONE",
    "state_code": "IS",
    "cab": "780700",
    "cap": "86081"
  },
  {
    "key": "146",
    "ITA": "AGNOSINE",
    "state_code": "BS",
    "cab": "540005",
    "cap": "25071"
  },
  {
    "key": "147",
    "ITA": "AGORDO",
    "state_code": "BL",
    "cab": "609800",
    "cap": "32021"
  },
  {
    "key": "148",
    "ITA": "AGOSTA",
    "state_code": "RM",
    "cab": "774703",
    "cap": "20"
  },
  {
    "key": "149",
    "ITA": "AGOSTA-MADONNA DELLA PACE",
    "state_code": "RM",
    "cab": "774703",
    "cap": "20"
  },
  {
    "key": "150",
    "ITA": "AGRA",
    "state_code": "VA",
    "cab": "924100",
    "cap": "21010"
  },
  {
    "key": "151",
    "ITA": "AGRATE BRIANZA",
    "state_code": "MB",
    "cab": "323907",
    "cap": "20864"
  },
  {
    "key": "152",
    "ITA": "AGRATE BRIANZA-OMATE",
    "state_code": "MB",
    "cab": "323907",
    "cap": "20864"
  },
  {
    "key": "153",
    "ITA": "AGRATE CONTURBIA",
    "state_code": "NO",
    "cab": "451104",
    "cap": "28010"
  },
  {
    "key": "154",
    "ITA": "AGRIGENTO-VILLASETA",
    "state_code": "AG",
    "cab": "166009",
    "cap": "92100"
  },
  {
    "key": "155",
    "ITA": "AGRIGENTO",
    "state_code": "AG",
    "cab": "166009",
    "cap": "92100"
  },
  {
    "key": "156",
    "ITA": "AGRIGENTO-BORGO LA LOGGIA",
    "state_code": "AG",
    "cab": "166009",
    "cap": "92100"
  },
  {
    "key": "157",
    "ITA": "AGRIGENTO-GIARDINA GALLOTTI",
    "state_code": "AG",
    "cab": "166009",
    "cap": "92100"
  },
  {
    "key": "158",
    "ITA": "AGRIGENTO-MONTAPERTO",
    "state_code": "AG",
    "cab": "166009",
    "cap": "92100"
  },
  {
    "key": "159",
    "ITA": "AGRIGENTO-SAN LEONE MOSE'",
    "state_code": "AG",
    "cab": "166009",
    "cap": "92100"
  },
  {
    "key": "160",
    "ITA": "AGRIGENTO-VILLAGGIO MOSE'",
    "state_code": "AG",
    "cab": "166009",
    "cap": "92100"
  },
  {
    "key": "161",
    "ITA": "AGROPOLI-AGROPOLI STAZIONE",
    "state_code": "SA",
    "cab": "760207",
    "cap": "84043"
  },
  {
    "key": "162",
    "ITA": "AGROPOLI",
    "state_code": "SA",
    "cab": "760207",
    "cap": "84043"
  },
  {
    "key": "163",
    "ITA": "AGUGLIANO",
    "state_code": "AN",
    "cab": "372201",
    "cap": "60020"
  },
  {
    "key": "164",
    "ITA": "AGUGLIANO-CASTEL D'EMILIO",
    "state_code": "AN",
    "cab": "372201",
    "cap": "60020"
  },
  {
    "key": "165",
    "ITA": "AGUGLIARO",
    "state_code": "VI",
    "cab": "600809",
    "cap": "36020"
  },
  {
    "key": "166",
    "ITA": "AICURZIO",
    "state_code": "MB",
    "cab": "891804",
    "cap": "20040"
  },
  {
    "key": "167",
    "ITA": "AIDOMAGGIORE",
    "state_code": "OR",
    "cab": "877209",
    "cap": "9070"
  },
  {
    "key": "168",
    "ITA": "AIDONE-BORGO BACCARATO",
    "state_code": "EN",
    "cab": "835801",
    "cap": "94010"
  },
  {
    "key": "169",
    "ITA": "AIDONE",
    "state_code": "EN",
    "cab": "835801",
    "cap": "94010"
  },
  {
    "key": "170",
    "ITA": "AIELLI",
    "state_code": "AQ",
    "cab": "909416",
    "cap": "67041"
  },
  {
    "key": "171",
    "ITA": "AIELLI-AIELLI STAZIONE",
    "state_code": "AQ",
    "cab": "909416",
    "cap": "67041"
  },
  {
    "key": "172",
    "ITA": "AIELLO CALABRO",
    "state_code": "CS",
    "cab": "805903",
    "cap": "87031"
  },
  {
    "key": "173",
    "ITA": "AIELLO DEL FRIULI",
    "state_code": "UD",
    "cab": "636001",
    "cap": "33041"
  },
  {
    "key": "174",
    "ITA": "AIELLO DEL FRIULI-JOANNIS",
    "state_code": "UD",
    "cab": "636001",
    "cap": "33041"
  },
  {
    "key": "175",
    "ITA": "AIELLO DEL SABATO-TAVERNOLA SAN FELICE",
    "state_code": "AV",
    "cab": "895003",
    "cap": "83020"
  },
  {
    "key": "176",
    "ITA": "AIELLO DEL SABATO",
    "state_code": "AV",
    "cab": "895003",
    "cap": "83020"
  },
  {
    "key": "177",
    "ITA": "AIETA",
    "state_code": "CS",
    "cab": "958520",
    "cap": "87020"
  },
  {
    "key": "178",
    "ITA": "AILANO",
    "state_code": "CE",
    "cab": "949107",
    "cap": "81010"
  },
  {
    "key": "179",
    "ITA": "AILOCHE",
    "state_code": "BI",
    "cab": "915603",
    "cap": "13861"
  },
  {
    "key": "180",
    "ITA": "AIRASCA",
    "state_code": "TO",
    "cab": "300103",
    "cap": "10060"
  },
  {
    "key": "181",
    "ITA": "AIROLA",
    "state_code": "BN",
    "cab": "752501",
    "cap": "82011"
  },
  {
    "key": "182",
    "ITA": "AIROLE",
    "state_code": "IM",
    "cab": "922203",
    "cap": "18030"
  },
  {
    "key": "183",
    "ITA": "AIRUNO",
    "state_code": "LC",
    "cab": "508200",
    "cap": "23881"
  },
  {
    "key": "184",
    "ITA": "AISONE",
    "state_code": "CN",
    "cab": "918219",
    "cap": "12010"
  },
  {
    "key": "185",
    "ITA": "ALA-SERRAVALLE",
    "state_code": "TN",
    "cab": "342709",
    "cap": "38061"
  },
  {
    "key": "186",
    "ITA": "ALA",
    "state_code": "TN",
    "cab": "342709",
    "cap": "38061"
  },
  {
    "key": "187",
    "ITA": "ALA-CHIZZOLA",
    "state_code": "TN",
    "cab": "342709",
    "cap": "38061"
  },
  {
    "key": "188",
    "ITA": "ALA-PILCANTE",
    "state_code": "TN",
    "cab": "342709",
    "cap": "38061"
  },
  {
    "key": "189",
    "ITA": "ALA-SANTA MARGHERITA",
    "state_code": "TN",
    "cab": "342709",
    "cap": "38061"
  },
  {
    "key": "190",
    "ITA": "ALA-SEGA DEI LESSINI",
    "state_code": "TN",
    "cab": "342709",
    "cap": "38061"
  },
  {
    "key": "191",
    "ITA": "ALA DI STURA-MONDRONE",
    "state_code": "TO",
    "cab": "300202",
    "cap": "10070"
  },
  {
    "key": "192",
    "ITA": "ALA DI STURA",
    "state_code": "TO",
    "cab": "300202",
    "cap": "10070"
  },
  {
    "key": "193",
    "ITA": "ALA' DEI SARDI",
    "state_code": "OT",
    "cab": "871509",
    "cap": "7020"
  },
  {
    "key": "194",
    "ITA": "ALAGNA",
    "state_code": "PV",
    "cab": "647503",
    "cap": "27020"
  },
  {
    "key": "195",
    "ITA": "ALAGNA VALSESIA",
    "state_code": "VC",
    "cab": "442301",
    "cap": "13021"
  },
  {
    "key": "196",
    "ITA": "ALANNO-ALANNO SCALO",
    "state_code": "PE",
    "cab": "771907",
    "cap": "65020"
  },
  {
    "key": "197",
    "ITA": "ALANNO-TICCHIONE",
    "state_code": "PE",
    "cab": "771907",
    "cap": "65020"
  },
  {
    "key": "198",
    "ITA": "ALANNO",
    "state_code": "PE",
    "cab": "771907",
    "cap": "65020"
  },
  {
    "key": "199",
    "ITA": "ALANO DI PIAVE",
    "state_code": "BL",
    "cab": "609909",
    "cap": "32031"
  },
  {
    "key": "200",
    "ITA": "ALANO DI PIAVE-COLMIRANO",
    "state_code": "BL",
    "cab": "609909",
    "cap": "32031"
  },
  {
    "key": "201",
    "ITA": "ALANO DI PIAVE-FENER",
    "state_code": "BL",
    "cab": "609909",
    "cap": "32031"
  },
  {
    "key": "202",
    "ITA": "ALASSIO-MOGLIO",
    "state_code": "SV",
    "cab": "492405",
    "cap": "17021"
  },
  {
    "key": "203",
    "ITA": "ALASSIO",
    "state_code": "SV",
    "cab": "492405",
    "cap": "17021"
  },
  {
    "key": "204",
    "ITA": "ALATRI-MONTE SAN MARINO",
    "state_code": "FR",
    "cab": "742700",
    "cap": "3011"
  },
  {
    "key": "205",
    "ITA": "ALATRI-TECCHIENA",
    "state_code": "FR",
    "cab": "742700",
    "cap": "3011"
  },
  {
    "key": "206",
    "ITA": "ALATRI-COLLELAVENA",
    "state_code": "FR",
    "cab": "742700",
    "cap": "3011"
  },
  {
    "key": "207",
    "ITA": "ALATRI",
    "state_code": "FR",
    "cab": "742700",
    "cap": "3011"
  },
  {
    "key": "208",
    "ITA": "ALBA",
    "state_code": "CN",
    "cab": "225003",
    "cap": "12051"
  },
  {
    "key": "209",
    "ITA": "ALBA-MUSSOTTO",
    "state_code": "CN",
    "cab": "225003",
    "cap": "12051"
  },
  {
    "key": "210",
    "ITA": "ALBA ADRIATICA",
    "state_code": "TE",
    "cab": "767202",
    "cap": "64011"
  },
  {
    "key": "211",
    "ITA": "ALBAGIARA",
    "state_code": "OR",
    "cab": "966622",
    "cap": "9090"
  },
  {
    "key": "212",
    "ITA": "ALBAIRATE",
    "state_code": "MI",
    "cab": "324004",
    "cap": "20080"
  },
  {
    "key": "213",
    "ITA": "ALBANELLA-MATINELLA",
    "state_code": "SA",
    "cab": "765701",
    "cap": "84044"
  },
  {
    "key": "214",
    "ITA": "ALBANELLA",
    "state_code": "SA",
    "cab": "765701",
    "cap": "84044"
  },
  {
    "key": "215",
    "ITA": "ALBANO DI LUCANIA",
    "state_code": "PZ",
    "cab": "418509",
    "cap": "85010"
  },
  {
    "key": "216",
    "ITA": "ALBANO LAZIALE-PAVONA STAZIONE",
    "state_code": "RM",
    "cab": "388603",
    "cap": "41"
  },
  {
    "key": "217",
    "ITA": "ALBANO LAZIALE-PAVONA",
    "state_code": "RM",
    "cab": "388603",
    "cap": "41"
  },
  {
    "key": "218",
    "ITA": "ALBANO LAZIALE",
    "state_code": "RM",
    "cab": "388603",
    "cap": "41"
  },
  {
    "key": "219",
    "ITA": "ALBANO LAZIALE-CECCHINA",
    "state_code": "RM",
    "cab": "388603",
    "cap": "41"
  },
  {
    "key": "220",
    "ITA": "ALBANO SANT'ALESSANDRO",
    "state_code": "BG",
    "cab": "524702",
    "cap": "24061"
  },
  {
    "key": "221",
    "ITA": "ALBANO VERCELLESE",
    "state_code": "VC",
    "cab": "915611",
    "cap": "13030"
  },
  {
    "key": "222",
    "ITA": "ALBAREDO ARNABOLDI",
    "state_code": "PV",
    "cab": "930115",
    "cap": "27040"
  },
  {
    "key": "223",
    "ITA": "ALBAREDO D'ADIGE",
    "state_code": "VR",
    "cab": "592303",
    "cap": "37041"
  },
  {
    "key": "224",
    "ITA": "ALBAREDO D'ADIGE-MICHELLORIE",
    "state_code": "VR",
    "cab": "592303",
    "cap": "37041"
  },
  {
    "key": "225",
    "ITA": "ALBAREDO D'ADIGE-CORIANO VERONESE",
    "state_code": "VR",
    "cab": "592303",
    "cap": "37041"
  },
  {
    "key": "226",
    "ITA": "ALBAREDO D'ADIGE-PRESINA",
    "state_code": "VR",
    "cab": "592303",
    "cap": "37041"
  },
  {
    "key": "227",
    "ITA": "ALBAREDO PER SAN MARCO",
    "state_code": "SO",
    "cab": "926907",
    "cap": "23010"
  },
  {
    "key": "228",
    "ITA": "ALBARETO",
    "state_code": "PR",
    "cab": "656108",
    "cap": "43051"
  },
  {
    "key": "229",
    "ITA": "ALBARETO-SAN QUIRICO",
    "state_code": "PR",
    "cab": "656108",
    "cap": "43051"
  },
  {
    "key": "230",
    "ITA": "ALBARETO-BERTORELLA",
    "state_code": "PR",
    "cab": "656108",
    "cap": "43051"
  },
  {
    "key": "231",
    "ITA": "ALBARETTO DELLA TORRE",
    "state_code": "CN",
    "cab": "918227",
    "cap": "12050"
  },
  {
    "key": "232",
    "ITA": "ALBAVILLA",
    "state_code": "CO",
    "cab": "508309",
    "cap": "22031"
  },
  {
    "key": "233",
    "ITA": "ALBENGA-BASTIA",
    "state_code": "SV",
    "cab": "492504",
    "cap": "17031"
  },
  {
    "key": "234",
    "ITA": "ALBENGA",
    "state_code": "SV",
    "cab": "492504",
    "cap": "17031"
  },
  {
    "key": "235",
    "ITA": "ALBENGA-CAMPOCHIESA",
    "state_code": "SV",
    "cab": "492504",
    "cap": "17031"
  },
  {
    "key": "236",
    "ITA": "ALBENGA-LECA",
    "state_code": "SV",
    "cab": "492504",
    "cap": "17031"
  },
  {
    "key": "237",
    "ITA": "ALBENGA-LUSIGNANO",
    "state_code": "SV",
    "cab": "492504",
    "cap": "17031"
  },
  {
    "key": "238",
    "ITA": "ALBENGA-SAN FEDELE",
    "state_code": "SV",
    "cab": "492504",
    "cap": "17031"
  },
  {
    "key": "239",
    "ITA": "ALBERA LIGURE",
    "state_code": "AL",
    "cab": "920801",
    "cap": "15060"
  },
  {
    "key": "240",
    "ITA": "ALBEROBELLO-COREGGIA",
    "state_code": "BA",
    "cab": "413203",
    "cap": "70011"
  },
  {
    "key": "241",
    "ITA": "ALBEROBELLO",
    "state_code": "BA",
    "cab": "413203",
    "cap": "70011"
  },
  {
    "key": "242",
    "ITA": "ALBERONA",
    "state_code": "FG",
    "cab": "956300",
    "cap": "71031"
  },
  {
    "key": "243",
    "ITA": "ALBESE CON CASSANO",
    "state_code": "CO",
    "cab": "508408",
    "cap": "22032"
  },
  {
    "key": "244",
    "ITA": "ALBETTONE",
    "state_code": "VI",
    "cab": "600908",
    "cap": "36020"
  },
  {
    "key": "245",
    "ITA": "ALBI-SAN GIOVANNI",
    "state_code": "CZ",
    "cab": "912618",
    "cap": "88055"
  },
  {
    "key": "246",
    "ITA": "ALBI-BUTURO",
    "state_code": "CZ",
    "cab": "912618",
    "cap": "88055"
  },
  {
    "key": "247",
    "ITA": "ALBI",
    "state_code": "CZ",
    "cab": "912618",
    "cap": "88055"
  },
  {
    "key": "248",
    "ITA": "ALBIANO",
    "state_code": "TN",
    "cab": "342808",
    "cap": "38041"
  },
  {
    "key": "249",
    "ITA": "ALBIANO D'IVREA",
    "state_code": "TO",
    "cab": "313502",
    "cap": "10010"
  },
  {
    "key": "250",
    "ITA": "ALBIATE",
    "state_code": "MB",
    "cab": "324103",
    "cap": "20847"
  },
  {
    "key": "251",
    "ITA": "ALBIDONA",
    "state_code": "CS",
    "cab": "811208",
    "cap": "87070"
  },
  {
    "key": "252",
    "ITA": "ALBIGNASEGO",
    "state_code": "PD",
    "cab": "623405",
    "cap": "35020"
  },
  {
    "key": "253",
    "ITA": "ALBIGNASEGO-MANDRIOLA",
    "state_code": "PD",
    "cab": "623405",
    "cap": "35020"
  },
  {
    "key": "254",
    "ITA": "ALBIGNASEGO-SANT'AGOSTINO",
    "state_code": "PD",
    "cab": "623405",
    "cap": "35020"
  },
  {
    "key": "255",
    "ITA": "ALBINEA-BORZANO",
    "state_code": "RE",
    "cab": "661306",
    "cap": "42020"
  },
  {
    "key": "256",
    "ITA": "ALBINEA",
    "state_code": "RE",
    "cab": "661306",
    "cap": "42020"
  },
  {
    "key": "257",
    "ITA": "ALBINO-DESENZANO AL SERIO",
    "state_code": "BG",
    "cab": "524801",
    "cap": "24021"
  },
  {
    "key": "258",
    "ITA": "ALBINO-VALL'ALTA",
    "state_code": "BG",
    "cab": "524801",
    "cap": "24021"
  },
  {
    "key": "259",
    "ITA": "ALBINO-COMENDUNO",
    "state_code": "BG",
    "cab": "524801",
    "cap": "24021"
  },
  {
    "key": "260",
    "ITA": "ALBINO-BONDO PETELLO",
    "state_code": "BG",
    "cab": "524801",
    "cap": "24021"
  },
  {
    "key": "261",
    "ITA": "ALBINO",
    "state_code": "BG",
    "cab": "524801",
    "cap": "24021"
  },
  {
    "key": "262",
    "ITA": "ALBINO-ABBAZIA",
    "state_code": "BG",
    "cab": "524801",
    "cap": "24021"
  },
  {
    "key": "263",
    "ITA": "ALBIOLO",
    "state_code": "CO",
    "cab": "508507",
    "cap": "22070"
  },
  {
    "key": "264",
    "ITA": "ALBISOLA SUPERIORE",
    "state_code": "SV",
    "cab": "492702",
    "cap": "17011"
  },
  {
    "key": "265",
    "ITA": "ALBISOLA SUPERIORE-ELLERA",
    "state_code": "SV",
    "cab": "492702",
    "cap": "17011"
  },
  {
    "key": "266",
    "ITA": "ALBISOLA SUPERIORE-ALBISOLA CAPO",
    "state_code": "SV",
    "cab": "492702",
    "cap": "17011"
  },
  {
    "key": "267",
    "ITA": "ALBISSOLA MARINA",
    "state_code": "SV",
    "cab": "492603",
    "cap": "17012"
  },
  {
    "key": "268",
    "ITA": "ALBIZZATE",
    "state_code": "VA",
    "cab": "499707",
    "cap": "21041"
  },
  {
    "key": "269",
    "ITA": "ALBONESE",
    "state_code": "PV",
    "cab": "930123",
    "cap": "27020"
  },
  {
    "key": "270",
    "ITA": "ALBOSAGGIA-MOIA",
    "state_code": "SO",
    "cab": "523902",
    "cap": "23010"
  },
  {
    "key": "271",
    "ITA": "ALBOSAGGIA-TORCHIONE",
    "state_code": "SO",
    "cab": "523902",
    "cap": "23010"
  },
  {
    "key": "272",
    "ITA": "ALBOSAGGIA",
    "state_code": "SO",
    "cab": "523902",
    "cap": "23010"
  },
  {
    "key": "273",
    "ITA": "ALBUGNANO",
    "state_code": "AT",
    "cab": "919902",
    "cap": "14022"
  },
  {
    "key": "274",
    "ITA": "ALBUZZANO",
    "state_code": "PV",
    "cab": "556100",
    "cap": "27010"
  },
  {
    "key": "275",
    "ITA": "ALCAMO",
    "state_code": "TP",
    "cab": "817809",
    "cap": "91011"
  },
  {
    "key": "276",
    "ITA": "ALCARA LI FUSI",
    "state_code": "ME",
    "cab": "826800",
    "cap": "98070"
  },
  {
    "key": "277",
    "ITA": "ALDENO",
    "state_code": "TN",
    "cab": "342907",
    "cap": "38060"
  },
  {
    "key": "278",
    "ITA": "ALDINO",
    "state_code": "BZ",
    "cab": "581405",
    "cap": "39040"
  },
  {
    "key": "279",
    "ITA": "ALDINO-OLMI",
    "state_code": "BZ",
    "cab": "581405",
    "cap": "39040"
  },
  {
    "key": "280",
    "ITA": "ALDINO-REDAGNO",
    "state_code": "BZ",
    "cab": "581405",
    "cap": "39040"
  },
  {
    "key": "281",
    "ITA": "ALES",
    "state_code": "OR",
    "cab": "855502",
    "cap": "9091"
  },
  {
    "key": "282",
    "ITA": "ALESSANDRIA-CASTELCERIOLO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "283",
    "ITA": "ALESSANDRIA-CRISTO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "284",
    "ITA": "ALESSANDRIA-LITTA PARODI",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "285",
    "ITA": "ALESSANDRIA-LOBBI",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "286",
    "ITA": "ALESSANDRIA-MANDROGNE",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "287",
    "ITA": "ALESSANDRIA-ORTI",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "288",
    "ITA": "ALESSANDRIA-SAN GIULIANO NUOVO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "289",
    "ITA": "ALESSANDRIA-SAN GIULIANO VECCHIO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "290",
    "ITA": "ALESSANDRIA-SAN MICHELE",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15040"
  },
  {
    "key": "291",
    "ITA": "ALESSANDRIA-SPINETTA MARENGO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "292",
    "ITA": "ALESSANDRIA-VALLE SAN BARTOLOMEO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15040"
  },
  {
    "key": "293",
    "ITA": "ALESSANDRIA-VALMADONNA",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15040"
  },
  {
    "key": "294",
    "ITA": "ALESSANDRIA-VILLA DEL FORO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15020"
  },
  {
    "key": "295",
    "ITA": "ALESSANDRIA-CASCINAGROSSA",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "296",
    "ITA": "ALESSANDRIA-CASALBAGLIANO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15020"
  },
  {
    "key": "297",
    "ITA": "ALESSANDRIA-CANTALUPO",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15014"
  },
  {
    "key": "298",
    "ITA": "ALESSANDRIA",
    "state_code": "AL",
    "cab": "104000",
    "cap": "15100"
  },
  {
    "key": "299",
    "ITA": "ALESSANDRIA DEL CARRETTO",
    "state_code": "CS",
    "cab": "958546",
    "cap": "87070"
  },
  {
    "key": "300",
    "ITA": "ALESSANDRIA DELLA ROCCA",
    "state_code": "AG",
    "cab": "827907",
    "cap": "92010"
  },
  {
    "key": "301",
    "ITA": "ALESSANO-MONTESARDO",
    "state_code": "LE",
    "cab": "794107",
    "cap": "73031"
  },
  {
    "key": "302",
    "ITA": "ALESSANO",
    "state_code": "LE",
    "cab": "794107",
    "cap": "73031"
  },
  {
    "key": "303",
    "ITA": "ALEZIO",
    "state_code": "LE",
    "cab": "794206",
    "cap": "73011"
  },
  {
    "key": "304",
    "ITA": "ALFANO",
    "state_code": "SA",
    "cab": "952226",
    "cap": "84040"
  },
  {
    "key": "305",
    "ITA": "ALFEDENA",
    "state_code": "AQ",
    "cab": "489005",
    "cap": "67030"
  },
  {
    "key": "306",
    "ITA": "ALFIANELLO",
    "state_code": "BS",
    "cab": "540104",
    "cap": "25020"
  },
  {
    "key": "307",
    "ITA": "ALFIANO NATTA-CARDONA",
    "state_code": "AL",
    "cab": "920819",
    "cap": "15021"
  },
  {
    "key": "308",
    "ITA": "ALFIANO NATTA-SANICO",
    "state_code": "AL",
    "cab": "920819",
    "cap": "15021"
  },
  {
    "key": "309",
    "ITA": "ALFIANO NATTA",
    "state_code": "AL",
    "cab": "920819",
    "cap": "15021"
  },
  {
    "key": "310",
    "ITA": "ALFONSINE",
    "state_code": "RA",
    "cab": "674804",
    "cap": "48011"
  },
  {
    "key": "311",
    "ITA": "ALGHERO",
    "state_code": "SS",
    "cab": "848903",
    "cap": "7041"
  },
  {
    "key": "312",
    "ITA": "ALGHERO-FERTILIA",
    "state_code": "SS",
    "cab": "848903",
    "cap": "7041"
  },
  {
    "key": "313",
    "ITA": "ALGHERO-MARISTELLA PORTO CONTE",
    "state_code": "SS",
    "cab": "848903",
    "cap": "7041"
  },
  {
    "key": "314",
    "ITA": "ALGHERO-SANTA MARIA LA PALMA",
    "state_code": "SS",
    "cab": "848903",
    "cap": "7041"
  },
  {
    "key": "315",
    "ITA": "ALGHERO-TRAMARIGLIO",
    "state_code": "SS",
    "cab": "848903",
    "cap": "7041"
  },
  {
    "key": "316",
    "ITA": "ALGUA",
    "state_code": "BG",
    "cab": "811802",
    "cap": "24010"
  },
  {
    "key": "317",
    "ITA": "ALI'",
    "state_code": "ME",
    "cab": "827600",
    "cap": "98020"
  },
  {
    "key": "318",
    "ITA": "ALI' TERME",
    "state_code": "ME",
    "cab": "858605",
    "cap": "98021"
  },
  {
    "key": "319",
    "ITA": "ALIA",
    "state_code": "PA",
    "cab": "430306",
    "cap": "90021"
  },
  {
    "key": "320",
    "ITA": "ALIANO",
    "state_code": "MT",
    "cab": "805101",
    "cap": "75010"
  },
  {
    "key": "321",
    "ITA": "ALICE BEL COLLE",
    "state_code": "AL",
    "cab": "676205",
    "cap": "15010"
  },
  {
    "key": "322",
    "ITA": "ALICE CASTELLO",
    "state_code": "VC",
    "cab": "442400",
    "cap": "13040"
  },
  {
    "key": "323",
    "ITA": "ALICE SUPERIORE-GAUNA",
    "state_code": "TO",
    "cab": "282954",
    "cap": "10010"
  },
  {
    "key": "324",
    "ITA": "ALICE SUPERIORE",
    "state_code": "TO",
    "cab": "282954",
    "cap": "10010"
  },
  {
    "key": "325",
    "ITA": "ALIFE-TOTARI",
    "state_code": "CE",
    "cab": "747600",
    "cap": "81011"
  },
  {
    "key": "326",
    "ITA": "ALIFE",
    "state_code": "CE",
    "cab": "747600",
    "cap": "81011"
  },
  {
    "key": "327",
    "ITA": "ALIMENA",
    "state_code": "PA",
    "cab": "430405",
    "cap": "90020"
  },
  {
    "key": "328",
    "ITA": "ALIMINUSA",
    "state_code": "PA",
    "cab": "437608",
    "cap": "90020"
  },
  {
    "key": "329",
    "ITA": "ALLAI",
    "state_code": "OR",
    "cab": "877308",
    "cap": "9080"
  },
  {
    "key": "330",
    "ITA": "ALLEGHE-CAPRILE",
    "state_code": "BL",
    "cab": "610006",
    "cap": "32022"
  },
  {
    "key": "331",
    "ITA": "ALLEGHE",
    "state_code": "BL",
    "cab": "610006",
    "cap": "32022"
  },
  {
    "key": "332",
    "ITA": "ALLEIN",
    "state_code": "AO",
    "cab": "902205",
    "cap": "11010"
  },
  {
    "key": "333",
    "ITA": "ALLERONA-STAZIONE DI ALLERONA",
    "state_code": "TR",
    "cab": "884304",
    "cap": "5011"
  },
  {
    "key": "334",
    "ITA": "ALLERONA",
    "state_code": "TR",
    "cab": "884304",
    "cap": "5011"
  },
  {
    "key": "335",
    "ITA": "ALLISTE",
    "state_code": "LE",
    "cab": "794305",
    "cap": "73040"
  },
  {
    "key": "336",
    "ITA": "ALLISTE-FELLINE",
    "state_code": "LE",
    "cab": "794305",
    "cap": "73040"
  },
  {
    "key": "337",
    "ITA": "ALLUMIERE-LA BIANCA",
    "state_code": "RM",
    "cab": "388702",
    "cap": "51"
  },
  {
    "key": "338",
    "ITA": "ALLUMIERE",
    "state_code": "RM",
    "cab": "388702",
    "cap": "51"
  },
  {
    "key": "339",
    "ITA": "ALLUVIONI CAMBIO'",
    "state_code": "AL",
    "cab": "920835",
    "cap": "15040"
  },
  {
    "key": "340",
    "ITA": "ALLUVIONI CAMBIO'-GRAVA",
    "state_code": "AL",
    "cab": "920835",
    "cap": "15040"
  },
  {
    "key": "341",
    "ITA": "ALME'",
    "state_code": "BG",
    "cab": "524900",
    "cap": "24011"
  },
  {
    "key": "342",
    "ITA": "ALMENNO SAN BARTOLOMEO",
    "state_code": "BG",
    "cab": "525006",
    "cap": "24030"
  },
  {
    "key": "343",
    "ITA": "ALMENNO SAN SALVATORE",
    "state_code": "BG",
    "cab": "525105",
    "cap": "24031"
  },
  {
    "key": "344",
    "ITA": "ALMESE-RIVERA",
    "state_code": "TO",
    "cab": "300301",
    "cap": "10040"
  },
  {
    "key": "345",
    "ITA": "ALMESE-MILANERE",
    "state_code": "TO",
    "cab": "300301",
    "cap": "10040"
  },
  {
    "key": "346",
    "ITA": "ALMESE",
    "state_code": "TO",
    "cab": "300301",
    "cap": "10040"
  },
  {
    "key": "347",
    "ITA": "ALONTE",
    "state_code": "VI",
    "cab": "933804",
    "cap": "36045"
  },
  {
    "key": "348",
    "ITA": "ALPAGO",
    "state_code": "BL",
    "cab": "882506",
    "cap": "32010"
  },
  {
    "key": "349",
    "ITA": "ALPETTE",
    "state_code": "TO",
    "cab": "284893",
    "cap": "10080"
  },
  {
    "key": "350",
    "ITA": "ALPIGNANO",
    "state_code": "TO",
    "cab": "300400",
    "cap": "10091"
  },
  {
    "key": "351",
    "ITA": "ALSENO-CASTELNUOVO FOGLIANI",
    "state_code": "PC",
    "cab": "651703",
    "cap": "29010"
  },
  {
    "key": "352",
    "ITA": "ALSENO-LUSURASCO",
    "state_code": "PC",
    "cab": "651703",
    "cap": "29010"
  },
  {
    "key": "353",
    "ITA": "ALSENO",
    "state_code": "PC",
    "cab": "651703",
    "cap": "29010"
  },
  {
    "key": "354",
    "ITA": "ALSENO-CHIARAVALLE",
    "state_code": "PC",
    "cab": "651703",
    "cap": "29010"
  },
  {
    "key": "355",
    "ITA": "ALSERIO",
    "state_code": "CO",
    "cab": "925107",
    "cap": "22040"
  },
  {
    "key": "356",
    "ITA": "ALTAMURA-CURTANIELLO",
    "state_code": "BA",
    "cab": "413302",
    "cap": "70022"
  },
  {
    "key": "357",
    "ITA": "ALTAMURA",
    "state_code": "BA",
    "cab": "413302",
    "cap": "70022"
  },
  {
    "key": "358",
    "ITA": "ALTAMURA-PARISI",
    "state_code": "BA",
    "cab": "413302",
    "cap": "70022"
  },
  {
    "key": "359",
    "ITA": "ALTARE-CADIBONA",
    "state_code": "SV",
    "cab": "492801",
    "cap": "17041"
  },
  {
    "key": "360",
    "ITA": "ALTARE",
    "state_code": "SV",
    "cab": "492801",
    "cap": "17041"
  },
  {
    "key": "361",
    "ITA": "ALTAVILLA IRPINA",
    "state_code": "AV",
    "cab": "759506",
    "cap": "83011"
  },
  {
    "key": "362",
    "ITA": "ALTAVILLA MILICIA",
    "state_code": "PA",
    "cab": "430504",
    "cap": "90010"
  },
  {
    "key": "363",
    "ITA": "ALTAVILLA MILICIA-TORRE NORMANNA",
    "state_code": "PA",
    "cab": "430504",
    "cap": "90010"
  },
  {
    "key": "364",
    "ITA": "ALTAVILLA MONFERRATO",
    "state_code": "AL",
    "cab": "479501",
    "cap": "15041"
  },
  {
    "key": "365",
    "ITA": "ALTAVILLA MONFERRATO-FRANCHINI",
    "state_code": "AL",
    "cab": "479501",
    "cap": "15041"
  },
  {
    "key": "366",
    "ITA": "ALTAVILLA SILENTINA",
    "state_code": "SA",
    "cab": "766006",
    "cap": "84045"
  },
  {
    "key": "367",
    "ITA": "ALTAVILLA SILENTINA-CERRELLI",
    "state_code": "SA",
    "cab": "766006",
    "cap": "84045"
  },
  {
    "key": "368",
    "ITA": "ALTAVILLA SILENTINA-BORGO CARILLIA",
    "state_code": "SA",
    "cab": "766006",
    "cap": "84045"
  },
  {
    "key": "369",
    "ITA": "ALTAVILLA VICENTINA-TAVERNELLE VICENTINA",
    "state_code": "VI",
    "cab": "601005",
    "cap": "36077"
  },
  {
    "key": "370",
    "ITA": "ALTAVILLA VICENTINA",
    "state_code": "VI",
    "cab": "601005",
    "cap": "36077"
  },
  {
    "key": "371",
    "ITA": "ALTAVILLA VICENTINA-VALMARANA",
    "state_code": "VI",
    "cab": "601005",
    "cap": "36077"
  },
  {
    "key": "372",
    "ITA": "ALTIDONA-MARINA DI ALTIDONA",
    "state_code": "FM",
    "cab": "697904",
    "cap": "63017"
  },
  {
    "key": "373",
    "ITA": "ALTIDONA",
    "state_code": "FM",
    "cab": "697904",
    "cap": "63017"
  },
  {
    "key": "374",
    "ITA": "ALTILIA",
    "state_code": "CS",
    "cab": "958553",
    "cap": "87040"
  },
  {
    "key": "375",
    "ITA": "ALTILIA-MAIONE",
    "state_code": "CS",
    "cab": "958553",
    "cap": "87040"
  },
  {
    "key": "376",
    "ITA": "ALTINO",
    "state_code": "CH",
    "cab": "779702",
    "cap": "66040"
  },
  {
    "key": "377",
    "ITA": "ALTINO-SELVA",
    "state_code": "CH",
    "cab": "779702",
    "cap": "66040"
  },
  {
    "key": "378",
    "ITA": "ALTISSIMO",
    "state_code": "VI",
    "cab": "459107",
    "cap": "36070"
  },
  {
    "key": "379",
    "ITA": "ALTISSIMO-MOLINO",
    "state_code": "VI",
    "cab": "459107",
    "cap": "36070"
  },
  {
    "key": "380",
    "ITA": "ALTIVOLE-CASELLE DI ALTIVOLE",
    "state_code": "TV",
    "cab": "614602",
    "cap": "31030"
  },
  {
    "key": "381",
    "ITA": "ALTIVOLE",
    "state_code": "TV",
    "cab": "614602",
    "cap": "31030"
  },
  {
    "key": "382",
    "ITA": "ALTIVOLE-SAN VITO DI ALTIVOLE",
    "state_code": "TV",
    "cab": "614602",
    "cap": "31030"
  },
  {
    "key": "383",
    "ITA": "ALTO",
    "state_code": "CN",
    "cab": "918235",
    "cap": "12070"
  },
  {
    "key": "384",
    "ITA": "ALTOFONTE",
    "state_code": "PA",
    "cab": "430603",
    "cap": "90030"
  },
  {
    "key": "385",
    "ITA": "ALTOFONTE-BLANDINO",
    "state_code": "PA",
    "cab": "430603",
    "cap": "90030"
  },
  {
    "key": "386",
    "ITA": "ALTOFONTE-PIANO MAGLIO",
    "state_code": "PA",
    "cab": "430603",
    "cap": "90030"
  },
  {
    "key": "387",
    "ITA": "ALTOMONTE",
    "state_code": "CS",
    "cab": "887901",
    "cap": "87042"
  },
  {
    "key": "388",
    "ITA": "ALTOPASCIO",
    "state_code": "LU",
    "cab": "700807",
    "cap": "55011"
  },
  {
    "key": "389",
    "ITA": "ALTOPASCIO-SPIANATE",
    "state_code": "LU",
    "cab": "700807",
    "cap": "55011"
  },
  {
    "key": "390",
    "ITA": "ALTOPASCIO-MARGINONE",
    "state_code": "LU",
    "cab": "700807",
    "cap": "55011"
  },
  {
    "key": "391",
    "ITA": "ALTOPASCIO-BADIA POZZEVERI",
    "state_code": "LU",
    "cab": "700807",
    "cap": "55011"
  },
  {
    "key": "392",
    "ITA": "ALVIANO",
    "state_code": "TR",
    "cab": "725200",
    "cap": "5020"
  },
  {
    "key": "393",
    "ITA": "ALVIGNANO",
    "state_code": "CE",
    "cab": "747709",
    "cap": "81012"
  },
  {
    "key": "394",
    "ITA": "ALVIGNANO-MARCIANO FREDDO",
    "state_code": "CE",
    "cab": "747709",
    "cap": "81012"
  },
  {
    "key": "395",
    "ITA": "ALVITO-CASTELLO D'ALVITO",
    "state_code": "FR",
    "cab": "742809",
    "cap": "3041"
  },
  {
    "key": "396",
    "ITA": "ALVITO-SANT'ONOFRIO",
    "state_code": "FR",
    "cab": "742809",
    "cap": "3041"
  },
  {
    "key": "397",
    "ITA": "ALVITO",
    "state_code": "FR",
    "cab": "742809",
    "cap": "3041"
  },
  {
    "key": "398",
    "ITA": "ALZANO LOMBARDO-NESE",
    "state_code": "BG",
    "cab": "525204",
    "cap": "24022"
  },
  {
    "key": "399",
    "ITA": "ALZANO LOMBARDO",
    "state_code": "BG",
    "cab": "525204",
    "cap": "24022"
  },
  {
    "key": "400",
    "ITA": "ALZANO SCRIVIA",
    "state_code": "AL",
    "cab": "920843",
    "cap": "15050"
  },
  {
    "key": "401",
    "ITA": "ALZATE BRIANZA-FABBRICA DURINI",
    "state_code": "CO",
    "cab": "508606",
    "cap": "22040"
  },
  {
    "key": "402",
    "ITA": "ALZATE BRIANZA",
    "state_code": "CO",
    "cab": "508606",
    "cap": "22040"
  },
  {
    "key": "403",
    "ITA": "AMALFI-LONE",
    "state_code": "SA",
    "cab": "760306",
    "cap": "84011"
  },
  {
    "key": "404",
    "ITA": "AMALFI-POGEROLA",
    "state_code": "SA",
    "cab": "760306",
    "cap": "84011"
  },
  {
    "key": "405",
    "ITA": "AMALFI",
    "state_code": "SA",
    "cab": "760306",
    "cap": "84011"
  },
  {
    "key": "406",
    "ITA": "AMALFI-PASTENA",
    "state_code": "SA",
    "cab": "760306",
    "cap": "84011"
  },
  {
    "key": "407",
    "ITA": "AMANDOLA",
    "state_code": "FM",
    "cab": "693606",
    "cap": "63021"
  },
  {
    "key": "408",
    "ITA": "AMANTEA",
    "state_code": "CS",
    "cab": "806000",
    "cap": "87032"
  },
  {
    "key": "409",
    "ITA": "AMANTEA-CORICA",
    "state_code": "CS",
    "cab": "806000",
    "cap": "87032"
  },
  {
    "key": "410",
    "ITA": "AMANTEA-CAMPORA SAN GIOVANNI",
    "state_code": "CS",
    "cab": "806000",
    "cap": "87032"
  },
  {
    "key": "411",
    "ITA": "AMANTEA-AMANTEA MARINA",
    "state_code": "CS",
    "cab": "806000",
    "cap": "87032"
  },
  {
    "key": "412",
    "ITA": "AMARO",
    "state_code": "UD",
    "cab": "936807",
    "cap": "33020"
  },
  {
    "key": "413",
    "ITA": "AMARONI",
    "state_code": "CZ",
    "cab": "912626",
    "cap": "88050"
  },
  {
    "key": "414",
    "ITA": "AMASENO",
    "state_code": "FR",
    "cab": "746909",
    "cap": "3021"
  },
  {
    "key": "415",
    "ITA": "AMATO",
    "state_code": "CZ",
    "cab": "912634",
    "cap": "88040"
  },
  {
    "key": "416",
    "ITA": "AMATRICE-SANTA GIUSTA",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2012"
  },
  {
    "key": "417",
    "ITA": "AMATRICE-SANTI LORENZO E FLAVIANO",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2012"
  },
  {
    "key": "418",
    "ITA": "AMATRICE-SCAI",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2010"
  },
  {
    "key": "419",
    "ITA": "AMATRICE-SOMMATI",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2012"
  },
  {
    "key": "420",
    "ITA": "AMATRICE-TORRITA",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2010"
  },
  {
    "key": "421",
    "ITA": "AMATRICE-PRETA",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2012"
  },
  {
    "key": "422",
    "ITA": "AMATRICE-COLLEMORESCO",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2012"
  },
  {
    "key": "423",
    "ITA": "AMATRICE",
    "state_code": "RI",
    "cab": "734707",
    "cap": "2012"
  },
  {
    "key": "424",
    "ITA": "AMBIVERE",
    "state_code": "BG",
    "cab": "780007",
    "cap": "24030"
  },
  {
    "key": "425",
    "ITA": "AMBLAR",
    "state_code": "TN",
    "cab": "904607",
    "cap": "38011"
  },
  {
    "key": "426",
    "ITA": "AMEGLIA",
    "state_code": "SP",
    "cab": "496703",
    "cap": "19031"
  },
  {
    "key": "427",
    "ITA": "AMEGLIA-FIUMARETTA DI AMEGLIA",
    "state_code": "SP",
    "cab": "496703",
    "cap": "19030"
  },
  {
    "key": "428",
    "ITA": "AMEGLIA-MONTEMARCELLO",
    "state_code": "SP",
    "cab": "496703",
    "cap": "19031"
  },
  {
    "key": "429",
    "ITA": "AMEGLIA-BOCCA DI MAGRA",
    "state_code": "SP",
    "cab": "496703",
    "cap": "19030"
  },
  {
    "key": "430",
    "ITA": "AMELIA",
    "state_code": "TR",
    "cab": "725309",
    "cap": "5022"
  },
  {
    "key": "431",
    "ITA": "AMELIA-MACCHIE",
    "state_code": "TR",
    "cab": "725309",
    "cap": "5022"
  },
  {
    "key": "432",
    "ITA": "AMELIA-MONTECAMPANO",
    "state_code": "TR",
    "cab": "725309",
    "cap": "5022"
  },
  {
    "key": "433",
    "ITA": "AMELIA-PORCHIANO",
    "state_code": "TR",
    "cab": "725309",
    "cap": "5022"
  },
  {
    "key": "434",
    "ITA": "AMELIA-FORNOLE",
    "state_code": "TR",
    "cab": "725309",
    "cap": "5022"
  },
  {
    "key": "435",
    "ITA": "AMENDOLARA",
    "state_code": "CS",
    "cab": "739102",
    "cap": "87071"
  },
  {
    "key": "436",
    "ITA": "AMENDOLARA-AMENDOLARA MARINA",
    "state_code": "CS",
    "cab": "739102",
    "cap": "87071"
  },
  {
    "key": "437",
    "ITA": "AMENO",
    "state_code": "NO",
    "cab": "916908",
    "cap": "28010"
  },
  {
    "key": "438",
    "ITA": "AMOROSI",
    "state_code": "BN",
    "cab": "752600",
    "cap": "82031"
  },
  {
    "key": "439",
    "ITA": "AMPEZZO",
    "state_code": "UD",
    "cab": "636100",
    "cap": "33021"
  },
  {
    "key": "440",
    "ITA": "ANACAPRI",
    "state_code": "NA",
    "cab": "397109",
    "cap": "80071"
  },
  {
    "key": "441",
    "ITA": "ANAGNI",
    "state_code": "FR",
    "cab": "742908",
    "cap": "3012"
  },
  {
    "key": "442",
    "ITA": "ANAGNI-OSTERIA DELLA FONTANA",
    "state_code": "FR",
    "cab": "742908",
    "cap": "3012"
  },
  {
    "key": "443",
    "ITA": "ANCARANO",
    "state_code": "TE",
    "cab": "767301",
    "cap": "64010"
  },
  {
    "key": "444",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60124"
  },
  {
    "key": "445",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60125"
  },
  {
    "key": "446",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60126"
  },
  {
    "key": "447",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60127"
  },
  {
    "key": "448",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60128"
  },
  {
    "key": "449",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60129"
  },
  {
    "key": "450",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60131"
  },
  {
    "key": "451",
    "ITA": "ANCONA-MONTESICURO",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60131"
  },
  {
    "key": "452",
    "ITA": "ANCONA-POGGIO",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60129"
  },
  {
    "key": "453",
    "ITA": "ANCONA-VARANO",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60129"
  },
  {
    "key": "454",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60123"
  },
  {
    "key": "455",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60122"
  },
  {
    "key": "456",
    "ITA": "ANCONA",
    "state_code": "AN",
    "cab": "26005",
    "cap": "60121"
  },
  {
    "key": "457",
    "ITA": "ANDALI",
    "state_code": "CZ",
    "cab": "912642",
    "cap": "88050"
  },
  {
    "key": "458",
    "ITA": "ANDALO",
    "state_code": "TN",
    "cab": "343004",
    "cap": "38010"
  },
  {
    "key": "459",
    "ITA": "ANDALO VALTELLINO",
    "state_code": "SO",
    "cab": "926923",
    "cap": "23014"
  },
  {
    "key": "460",
    "ITA": "ANDEZENO",
    "state_code": "TO",
    "cab": "313007",
    "cap": "10020"
  },
  {
    "key": "461",
    "ITA": "ANDORA-MARINA DI ANDORA",
    "state_code": "SV",
    "cab": "492900",
    "cap": "17051"
  },
  {
    "key": "462",
    "ITA": "ANDORA",
    "state_code": "SV",
    "cab": "492900",
    "cap": "17051"
  },
  {
    "key": "463",
    "ITA": "ANDORNO MICCA",
    "state_code": "BI",
    "cab": "442509",
    "cap": "13811"
  },
  {
    "key": "464",
    "ITA": "ANDRANO",
    "state_code": "LE",
    "cab": "794404",
    "cap": "73032"
  },
  {
    "key": "465",
    "ITA": "ANDRANO-CASTIGLIONE",
    "state_code": "LE",
    "cab": "794404",
    "cap": "73030"
  },
  {
    "key": "466",
    "ITA": "ANDRATE",
    "state_code": "TO",
    "cab": "284836",
    "cap": "10010"
  },
  {
    "key": "467",
    "ITA": "ANDREIS",
    "state_code": "PN",
    "cab": "938100",
    "cap": "33080"
  },
  {
    "key": "468",
    "ITA": "ANDRETTA-MATTINELLA",
    "state_code": "AV",
    "cab": "756403",
    "cap": "83040"
  },
  {
    "key": "469",
    "ITA": "ANDRETTA-ALVANO",
    "state_code": "AV",
    "cab": "756403",
    "cap": "83040"
  },
  {
    "key": "470",
    "ITA": "ANDRETTA",
    "state_code": "AV",
    "cab": "756403",
    "cap": "83040"
  },
  {
    "key": "471",
    "ITA": "ANDRIA-MONTEGROSSO",
    "state_code": "BT",
    "cab": "413401",
    "cap": "70031"
  },
  {
    "key": "472",
    "ITA": "ANDRIA",
    "state_code": "BT",
    "cab": "413401",
    "cap": "70031"
  },
  {
    "key": "473",
    "ITA": "ANDRIANO",
    "state_code": "BZ",
    "cab": "581504",
    "cap": "39010"
  },
  {
    "key": "474",
    "ITA": "ANELA",
    "state_code": "SS",
    "cab": "871608",
    "cap": "7010"
  },
  {
    "key": "475",
    "ITA": "ANFO",
    "state_code": "BS",
    "cab": "555300",
    "cap": "25070"
  },
  {
    "key": "476",
    "ITA": "ANGERA",
    "state_code": "VA",
    "cab": "499806",
    "cap": "21021"
  },
  {
    "key": "477",
    "ITA": "ANGHIARI",
    "state_code": "AR",
    "cab": "713107",
    "cap": "52031"
  },
  {
    "key": "478",
    "ITA": "ANGHIARI-SAN LEO DI ANGHIARI",
    "state_code": "AR",
    "cab": "713107",
    "cap": "52031"
  },
  {
    "key": "479",
    "ITA": "ANGIARI",
    "state_code": "VR",
    "cab": "599902",
    "cap": "37050"
  },
  {
    "key": "480",
    "ITA": "ANGOLO TERME",
    "state_code": "BS",
    "cab": "540203",
    "cap": "25040"
  },
  {
    "key": "481",
    "ITA": "ANGRI",
    "state_code": "SA",
    "cab": "760405",
    "cap": "84012"
  },
  {
    "key": "482",
    "ITA": "ANGROGNA",
    "state_code": "TO",
    "cab": "189795",
    "cap": "10060"
  },
  {
    "key": "483",
    "ITA": "ANGUILLARA SABAZIA",
    "state_code": "RM",
    "cab": "388801",
    "cap": "61"
  },
  {
    "key": "484",
    "ITA": "ANGUILLARA VENETA",
    "state_code": "PD",
    "cab": "623504",
    "cap": "35022"
  },
  {
    "key": "485",
    "ITA": "ANGUILLARA VENETA-BORGOFORTE",
    "state_code": "PD",
    "cab": "623504",
    "cap": "35022"
  },
  {
    "key": "486",
    "ITA": "ANNICCO-BARZANIGA",
    "state_code": "CR",
    "cab": "566307",
    "cap": "26021"
  },
  {
    "key": "487",
    "ITA": "ANNICCO",
    "state_code": "CR",
    "cab": "566307",
    "cap": "26021"
  },
  {
    "key": "488",
    "ITA": "ANNONE DI BRIANZA",
    "state_code": "LC",
    "cab": "674101",
    "cap": "23841"
  },
  {
    "key": "489",
    "ITA": "ANNONE VENETO-LONCON",
    "state_code": "VE",
    "cab": "359802",
    "cap": "30020"
  },
  {
    "key": "490",
    "ITA": "ANNONE VENETO",
    "state_code": "VE",
    "cab": "359802",
    "cap": "30020"
  },
  {
    "key": "491",
    "ITA": "ANOIA",
    "state_code": "RC",
    "cab": "192294",
    "cap": "89020"
  },
  {
    "key": "492",
    "ITA": "ANOIA-ANOIA INFERIORE",
    "state_code": "RC",
    "cab": "192294",
    "cap": "89020"
  },
  {
    "key": "493",
    "ITA": "ANOIA-ANOIA SUPERIORE",
    "state_code": "RC",
    "cab": "192294",
    "cap": "89020"
  },
  {
    "key": "494",
    "ITA": "ANTEGNATE",
    "state_code": "BG",
    "cab": "525303",
    "cap": "24051"
  },
  {
    "key": "495",
    "ITA": "ANTERIVO",
    "state_code": "BZ",
    "cab": "591800",
    "cap": "39040"
  },
  {
    "key": "496",
    "ITA": "ANTEY SAINT ANDRE'",
    "state_code": "AO",
    "cab": "315002",
    "cap": "11020"
  },
  {
    "key": "497",
    "ITA": "ANTEY SAINT ANDRE'-BUISSON",
    "state_code": "AO",
    "cab": "315002",
    "cap": "11020"
  },
  {
    "key": "498",
    "ITA": "ANTEY SAINT ANDRE'-FIERNAZ",
    "state_code": "AO",
    "cab": "315002",
    "cap": "11020"
  },
  {
    "key": "499",
    "ITA": "ANTICOLI CORRADO",
    "state_code": "RM",
    "cab": "280933",
    "cap": "22"
  },
  {
    "key": "500",
    "ITA": "ANTIGNANO",
    "state_code": "AT",
    "cab": "472308",
    "cap": "14010"
  },
  {
    "key": "501",
    "ITA": "ANTILLO",
    "state_code": "ME",
    "cab": "961235",
    "cap": "98030"
  },
  {
    "key": "502",
    "ITA": "ANTONIMINA",
    "state_code": "RC",
    "cab": "959932",
    "cap": "89040"
  },
  {
    "key": "503",
    "ITA": "ANTRODOCO",
    "state_code": "RI",
    "cab": "734806",
    "cap": "2013"
  },
  {
    "key": "504",
    "ITA": "ANTRONA SCHIERANCO-ANTRONAPIANA",
    "state_code": "VB",
    "cab": "916916",
    "cap": "28841"
  },
  {
    "key": "505",
    "ITA": "ANTRONA SCHIERANCO",
    "state_code": "VB",
    "cab": "916916",
    "cap": "28841"
  },
  {
    "key": "506",
    "ITA": "ANVERSA DEGLI ABRUZZI-CASTROVALVA",
    "state_code": "AQ",
    "cab": "909432",
    "cap": "67030"
  },
  {
    "key": "507",
    "ITA": "ANVERSA DEGLI ABRUZZI",
    "state_code": "AQ",
    "cab": "909432",
    "cap": "67030"
  },
  {
    "key": "508",
    "ITA": "ANZANO DEL PARCO",
    "state_code": "CO",
    "cab": "614503",
    "cap": "22040"
  },
  {
    "key": "509",
    "ITA": "ANZANO DI PUGLIA",
    "state_code": "FG",
    "cab": "886606",
    "cap": "71020"
  },
  {
    "key": "510",
    "ITA": "ANZI",
    "state_code": "PZ",
    "cab": "423905",
    "cap": "85010"
  },
  {
    "key": "511",
    "ITA": "ANZIO-LIDO DEI PINI",
    "state_code": "RM",
    "cab": "388900",
    "cap": "42"
  },
  {
    "key": "512",
    "ITA": "ANZIO-LAVINIO LIDO DI ENEA",
    "state_code": "RM",
    "cab": "388900",
    "cap": "42"
  },
  {
    "key": "513",
    "ITA": "ANZIO-COLONIA DI ANZIO",
    "state_code": "RM",
    "cab": "388900",
    "cap": "42"
  },
  {
    "key": "514",
    "ITA": "ANZIO",
    "state_code": "RM",
    "cab": "388900",
    "cap": "42"
  },
  {
    "key": "515",
    "ITA": "ANZOLA D'OSSOLA",
    "state_code": "VB",
    "cab": "916924",
    "cap": "28877"
  },
  {
    "key": "516",
    "ITA": "ANZOLA DELL'EMILIA",
    "state_code": "BO",
    "cab": "365809",
    "cap": "40011"
  },
  {
    "key": "517",
    "ITA": "ANZOLA DELL'EMILIA-LAVINO DI MEZZO",
    "state_code": "BO",
    "cab": "365809",
    "cap": "40011"
  },
  {
    "key": "518",
    "ITA": "ANZOLA DELL'EMILIA-SANTA MARIA IN STRADA",
    "state_code": "BO",
    "cab": "365809",
    "cap": "40011"
  },
  {
    "key": "519",
    "ITA": "ANZOLA DELL'EMILIA-SAN GIACOMO DEL MARTIGNONE",
    "state_code": "BO",
    "cab": "365809",
    "cap": "40011"
  },
  {
    "key": "520",
    "ITA": "AOSTA-EXCENEX",
    "state_code": "AO",
    "cab": "12005",
    "cap": "11100"
  },
  {
    "key": "521",
    "ITA": "AOSTA-SIGNAYES",
    "state_code": "AO",
    "cab": "12005",
    "cap": "11100"
  },
  {
    "key": "522",
    "ITA": "AOSTA-POROSSAN",
    "state_code": "AO",
    "cab": "12005",
    "cap": "11100"
  },
  {
    "key": "523",
    "ITA": "AOSTA-ARPUILLES",
    "state_code": "AO",
    "cab": "12005",
    "cap": "11100"
  },
  {
    "key": "524",
    "ITA": "AOSTA",
    "state_code": "AO",
    "cab": "12005",
    "cap": "11100"
  },
  {
    "key": "525",
    "ITA": "APECCHIO",
    "state_code": "PU",
    "cab": "682005",
    "cap": "61042"
  },
  {
    "key": "526",
    "ITA": "APECCHIO-SERRAVALLE DI CARDA",
    "state_code": "PU",
    "cab": "682005",
    "cap": "61042"
  },
  {
    "key": "527",
    "ITA": "APICE-APICE NUOVO",
    "state_code": "BN",
    "cab": "752709",
    "cap": "82021"
  },
  {
    "key": "528",
    "ITA": "APICE",
    "state_code": "BN",
    "cab": "752709",
    "cap": "82021"
  },
  {
    "key": "529",
    "ITA": "APIRO-FRONTALE",
    "state_code": "MC",
    "cab": "688002",
    "cap": "62021"
  },
  {
    "key": "530",
    "ITA": "APIRO",
    "state_code": "MC",
    "cab": "688002",
    "cap": "62021"
  },
  {
    "key": "531",
    "ITA": "APOLLOSA",
    "state_code": "BN",
    "cab": "950105",
    "cap": "82030"
  },
  {
    "key": "532",
    "ITA": "APPIANO GENTILE",
    "state_code": "CO",
    "cab": "508705",
    "cap": "22070"
  },
  {
    "key": "533",
    "ITA": "APPIANO SULLA STRADA DEL VINO",
    "state_code": "BZ",
    "cab": "581603",
    "cap": "39057"
  },
  {
    "key": "534",
    "ITA": "APPIANO SULLA STRADA DEL VINO-CORNAIANO",
    "state_code": "BZ",
    "cab": "581603",
    "cap": "39057"
  },
  {
    "key": "535",
    "ITA": "APPIANO SULLA STRADA DEL VINO-FRANGARTO",
    "state_code": "BZ",
    "cab": "581603",
    "cap": "39057"
  },
  {
    "key": "536",
    "ITA": "APPIANO SULLA STRADA DEL VINO-SAN MICHELE",
    "state_code": "BZ",
    "cab": "581603",
    "cap": "39057"
  },
  {
    "key": "537",
    "ITA": "APPIANO SULLA STRADA DEL VINO-SAN PAOLO",
    "state_code": "BZ",
    "cab": "581603",
    "cap": "39050"
  },
  {
    "key": "538",
    "ITA": "APPIGNANO",
    "state_code": "MC",
    "cab": "688101",
    "cap": "62010"
  },
  {
    "key": "539",
    "ITA": "APPIGNANO DEL TRONTO",
    "state_code": "AP",
    "cab": "884502",
    "cap": "63031"
  },
  {
    "key": "540",
    "ITA": "APRICA",
    "state_code": "SO",
    "cab": "520601",
    "cap": "23031"
  },
  {
    "key": "541",
    "ITA": "APRICALE",
    "state_code": "IM",
    "cab": "922211",
    "cap": "18035"
  },
  {
    "key": "542",
    "ITA": "APRICENA",
    "state_code": "FG",
    "cab": "782706",
    "cap": "71011"
  },
  {
    "key": "543",
    "ITA": "APRIGLIANO-CAMARDA DI APRIGLIANO",
    "state_code": "CS",
    "cab": "806109",
    "cap": "87051"
  },
  {
    "key": "544",
    "ITA": "APRIGLIANO",
    "state_code": "CS",
    "cab": "806109",
    "cap": "87051"
  },
  {
    "key": "545",
    "ITA": "APRIGLIANO-VICO",
    "state_code": "CS",
    "cab": "806109",
    "cap": "87051"
  },
  {
    "key": "546",
    "ITA": "APRILIA",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "547",
    "ITA": "APRILIA-CAMILLERI",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "548",
    "ITA": "APRILIA-CAMPO DI CARNE",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "549",
    "ITA": "APRILIA-CAMPOLEONE",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "550",
    "ITA": "APRILIA-CAMPOVERDE",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "551",
    "ITA": "APRILIA-VALLELATA",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "552",
    "ITA": "APRILIA-CASALAZARA",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "553",
    "ITA": "APRILIA-COGNA",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "554",
    "ITA": "APRILIA-FOSSIGNANO",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "555",
    "ITA": "APRILIA-PANTANELLE",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "556",
    "ITA": "APRILIA-TORRE DEL PADIGLIONE",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "557",
    "ITA": "APRILIA-CARANO",
    "state_code": "LT",
    "cab": "739201",
    "cap": "4011"
  },
  {
    "key": "558",
    "ITA": "AQUARA",
    "state_code": "SA",
    "cab": "760504",
    "cap": "84020"
  },
  {
    "key": "559",
    "ITA": "AQUILA DI ARROSCIA",
    "state_code": "IM",
    "cab": "922229",
    "cap": "18020"
  },
  {
    "key": "560",
    "ITA": "AQUILEIA-BELVEDERE",
    "state_code": "UD",
    "cab": "636209",
    "cap": "33051"
  },
  {
    "key": "561",
    "ITA": "AQUILEIA",
    "state_code": "UD",
    "cab": "636209",
    "cap": "33051"
  },
  {
    "key": "562",
    "ITA": "AQUILONIA",
    "state_code": "AV",
    "cab": "950923",
    "cap": "83041"
  },
  {
    "key": "563",
    "ITA": "AQUINO",
    "state_code": "FR",
    "cab": "743005",
    "cap": "3031"
  },
  {
    "key": "564",
    "ITA": "ARADEO",
    "state_code": "LE",
    "cab": "794503",
    "cap": "73040"
  },
  {
    "key": "565",
    "ITA": "ARAGONA",
    "state_code": "AG",
    "cab": "828004",
    "cap": "92021"
  },
  {
    "key": "566",
    "ITA": "ARAGONA-CALDARE",
    "state_code": "AG",
    "cab": "828004",
    "cap": "92021"
  },
  {
    "key": "567",
    "ITA": "ARAMENGO",
    "state_code": "AT",
    "cab": "919910",
    "cap": "14020"
  },
  {
    "key": "568",
    "ITA": "ARBA",
    "state_code": "PN",
    "cab": "496604",
    "cap": "33090"
  },
  {
    "key": "569",
    "ITA": "ARBA-COLLE",
    "state_code": "PN",
    "cab": "496604",
    "cap": "33090"
  },
  {
    "key": "570",
    "ITA": "ARBOREA",
    "state_code": "OR",
    "cab": "856302",
    "cap": "9092"
  },
  {
    "key": "571",
    "ITA": "ARBORIO",
    "state_code": "VC",
    "cab": "442608",
    "cap": "13031"
  },
  {
    "key": "572",
    "ITA": "ARBUS-INGURTOSU",
    "state_code": "SU",
    "cab": "438309",
    "cap": "9031"
  },
  {
    "key": "573",
    "ITA": "ARBUS-GENNAMARI",
    "state_code": "SU",
    "cab": "438309",
    "cap": "9031"
  },
  {
    "key": "574",
    "ITA": "ARBUS-SANT'ANTONIO DI SANTADI",
    "state_code": "SU",
    "cab": "438309",
    "cap": "9031"
  },
  {
    "key": "575",
    "ITA": "ARBUS",
    "state_code": "SU",
    "cab": "438309",
    "cap": "9031"
  },
  {
    "key": "576",
    "ITA": "ARCADE",
    "state_code": "TV",
    "cab": "614701",
    "cap": "31030"
  },
  {
    "key": "577",
    "ITA": "ARCE-ISOLETTA",
    "state_code": "FR",
    "cab": "743104",
    "cap": "3032"
  },
  {
    "key": "578",
    "ITA": "ARCE",
    "state_code": "FR",
    "cab": "743104",
    "cap": "3032"
  },
  {
    "key": "579",
    "ITA": "ARCENE",
    "state_code": "BG",
    "cab": "525402",
    "cap": "24040"
  },
  {
    "key": "580",
    "ITA": "ARCEVIA-AVACELLI",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "581",
    "ITA": "ARCEVIA-CASTIGLIONI",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "582",
    "ITA": "ARCEVIA-COSTA D'ARCEVIA",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "583",
    "ITA": "ARCEVIA-MONTEFORTINO",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "584",
    "ITA": "ARCEVIA-NIDASTORE",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "585",
    "ITA": "ARCEVIA-PALAZZO",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "586",
    "ITA": "ARCEVIA-PITICCHIO",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "587",
    "ITA": "ARCEVIA",
    "state_code": "AN",
    "cab": "372300",
    "cap": "60011"
  },
  {
    "key": "588",
    "ITA": "ARCHI",
    "state_code": "CH",
    "cab": "775502",
    "cap": "66044"
  },
  {
    "key": "589",
    "ITA": "ARCHI-PIANE D'ARCHI",
    "state_code": "CH",
    "cab": "775502",
    "cap": "66044"
  },
  {
    "key": "590",
    "ITA": "ARCIDOSSO",
    "state_code": "GR",
    "cab": "721605",
    "cap": "58031"
  },
  {
    "key": "591",
    "ITA": "ARCIDOSSO-BAGNOLI",
    "state_code": "GR",
    "cab": "721605",
    "cap": "58031"
  },
  {
    "key": "592",
    "ITA": "ARCIDOSSO-ZANCONA",
    "state_code": "GR",
    "cab": "721605",
    "cap": "58031"
  },
  {
    "key": "593",
    "ITA": "ARCIDOSSO-SALAIOLA",
    "state_code": "GR",
    "cab": "721605",
    "cap": "58031"
  },
  {
    "key": "594",
    "ITA": "ARCIDOSSO-STRIBUGLIANO",
    "state_code": "GR",
    "cab": "721605",
    "cap": "58031"
  },
  {
    "key": "595",
    "ITA": "ARCIDOSSO-MONTELATERONE",
    "state_code": "GR",
    "cab": "721605",
    "cap": "58031"
  },
  {
    "key": "596",
    "ITA": "ARCINAZZO ROMANO-ALTIPIANI DI ARCINAZZO",
    "state_code": "RM",
    "cab": "908020",
    "cap": "20"
  },
  {
    "key": "597",
    "ITA": "ARCINAZZO ROMANO",
    "state_code": "RM",
    "cab": "908020",
    "cap": "20"
  },
  {
    "key": "598",
    "ITA": "ARCISATE",
    "state_code": "VA",
    "cab": "499905",
    "cap": "21051"
  },
  {
    "key": "599",
    "ITA": "ARCISATE-BRENNO USERIA",
    "state_code": "VA",
    "cab": "499905",
    "cap": "21051"
  },
  {
    "key": "600",
    "ITA": "ARCO",
    "state_code": "TN",
    "cab": "343103",
    "cap": "38062"
  },
  {
    "key": "601",
    "ITA": "ARCO-SAN GIORGIO",
    "state_code": "TN",
    "cab": "343103",
    "cap": "38062"
  },
  {
    "key": "602",
    "ITA": "ARCO-OLTRESARCA",
    "state_code": "TN",
    "cab": "343103",
    "cap": "38062"
  },
  {
    "key": "603",
    "ITA": "ARCO-VIGNOLE",
    "state_code": "TN",
    "cab": "343103",
    "cap": "38062"
  },
  {
    "key": "604",
    "ITA": "ARCO-BOLOGNANO",
    "state_code": "TN",
    "cab": "343103",
    "cap": "38062"
  },
  {
    "key": "605",
    "ITA": "ARCOLA-ROMITO MAGRA",
    "state_code": "SP",
    "cab": "496802",
    "cap": "19021"
  },
  {
    "key": "606",
    "ITA": "ARCOLA",
    "state_code": "SP",
    "cab": "496802",
    "cap": "19021"
  },
  {
    "key": "607",
    "ITA": "ARCOLE",
    "state_code": "VR",
    "cab": "592402",
    "cap": "37040"
  },
  {
    "key": "608",
    "ITA": "ARCOLE-GAZZOLO",
    "state_code": "VR",
    "cab": "592402",
    "cap": "37040"
  },
  {
    "key": "609",
    "ITA": "ARCONATE",
    "state_code": "MI",
    "cab": "324202",
    "cap": "20020"
  },
  {
    "key": "610",
    "ITA": "ARCORE",
    "state_code": "MB",
    "cab": "324301",
    "cap": "20043"
  },
  {
    "key": "611",
    "ITA": "ARCUGNANO",
    "state_code": "VI",
    "cab": "883306",
    "cap": "36057"
  },
  {
    "key": "612",
    "ITA": "ARCUGNANO-NOGARAZZA",
    "state_code": "VI",
    "cab": "883306",
    "cap": "36057"
  },
  {
    "key": "613",
    "ITA": "ARCUGNANO-PIANEZZE DEL LAGO",
    "state_code": "VI",
    "cab": "883306",
    "cap": "36057"
  },
  {
    "key": "614",
    "ITA": "ARCUGNANO-TORMENO",
    "state_code": "VI",
    "cab": "883306",
    "cap": "36057"
  },
  {
    "key": "615",
    "ITA": "ARCUGNANO-TORRI D'ARCUGNANO",
    "state_code": "VI",
    "cab": "883306",
    "cap": "36057"
  },
  {
    "key": "616",
    "ITA": "ARDARA",
    "state_code": "SS",
    "cab": "871707",
    "cap": "7010"
  },
  {
    "key": "617",
    "ITA": "ARDAULI",
    "state_code": "OR",
    "cab": "877407",
    "cap": "9081"
  },
  {
    "key": "618",
    "ITA": "ARDEA",
    "state_code": "RM",
    "cab": "395400",
    "cap": "40"
  },
  {
    "key": "619",
    "ITA": "ARDEA-PONTE SULLA MOLETTA",
    "state_code": "RM",
    "cab": "395400",
    "cap": "40"
  },
  {
    "key": "620",
    "ITA": "ARDEA-MARINA DI ARDEA",
    "state_code": "RM",
    "cab": "395400",
    "cap": "40"
  },
  {
    "key": "621",
    "ITA": "ARDEA-TOR SAN LORENZO",
    "state_code": "RM",
    "cab": "395400",
    "cap": "40"
  },
  {
    "key": "622",
    "ITA": "ARDENNO",
    "state_code": "SO",
    "cab": "520700",
    "cap": "23011"
  },
  {
    "key": "623",
    "ITA": "ARDESIO",
    "state_code": "BG",
    "cab": "525501",
    "cap": "24020"
  },
  {
    "key": "624",
    "ITA": "ARDORE-SAN NICOLA",
    "state_code": "RC",
    "cab": "812701",
    "cap": "89031"
  },
  {
    "key": "625",
    "ITA": "ARDORE-BOMBILE",
    "state_code": "RC",
    "cab": "812701",
    "cap": "89031"
  },
  {
    "key": "626",
    "ITA": "ARDORE-ARDORE MARINA",
    "state_code": "RC",
    "cab": "812701",
    "cap": "89037"
  },
  {
    "key": "627",
    "ITA": "ARDORE",
    "state_code": "RC",
    "cab": "812701",
    "cap": "89031"
  },
  {
    "key": "628",
    "ITA": "ARENA",
    "state_code": "VV",
    "cab": "424507",
    "cap": "89832"
  },
  {
    "key": "629",
    "ITA": "ARENA PO",
    "state_code": "PV",
    "cab": "556209",
    "cap": "27040"
  },
  {
    "key": "630",
    "ITA": "ARENZANO",
    "state_code": "GE",
    "cab": "318303",
    "cap": "16011"
  },
  {
    "key": "631",
    "ITA": "ARENZANO-PINETA DI ARENZANO",
    "state_code": "GE",
    "cab": "318303",
    "cap": "16011"
  },
  {
    "key": "632",
    "ITA": "ARESE",
    "state_code": "MI",
    "cab": "324400",
    "cap": "20020"
  },
  {
    "key": "633",
    "ITA": "AREZZO-MOLIN NUOVO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "634",
    "ITA": "AREZZO-OLMO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "635",
    "ITA": "AREZZO-PALAZZO DEL PERO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "636",
    "ITA": "AREZZO-PATRIGNONE",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "637",
    "ITA": "AREZZO-PIEVE AL BAGNORO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "638",
    "ITA": "AREZZO-POGGIOLA",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "639",
    "ITA": "AREZZO-PONTE ALLA CHIASSA",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "640",
    "ITA": "AREZZO-PRATANTICO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "641",
    "ITA": "AREZZO-PUGLIA",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "642",
    "ITA": "AREZZO-QUARATA",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "643",
    "ITA": "AREZZO-RIGUTINO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "644",
    "ITA": "AREZZO-RUSCELLO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "645",
    "ITA": "AREZZO-SAN GIULIANO D'AREZZO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "646",
    "ITA": "AREZZO-STAGGIANO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "647",
    "ITA": "AREZZO-TREGOZZANO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "648",
    "ITA": "AREZZO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "649",
    "ITA": "AREZZO-ANTRIA",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "650",
    "ITA": "AREZZO-BATTIFOLLE",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "651",
    "ITA": "AREZZO-CECILIANO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "652",
    "ITA": "AREZZO-CHIASSA",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "653",
    "ITA": "AREZZO-INDICATORE",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "654",
    "ITA": "AREZZO-FRASSINETO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "655",
    "ITA": "AREZZO-GIOVI D'AREZZO",
    "state_code": "AR",
    "cab": "141002",
    "cap": "52100"
  },
  {
    "key": "656",
    "ITA": "ARGEGNO",
    "state_code": "CO",
    "cab": "508804",
    "cap": "22010"
  },
  {
    "key": "657",
    "ITA": "ARGELATO-CASADIO",
    "state_code": "BO",
    "cab": "365908",
    "cap": "40050"
  },
  {
    "key": "658",
    "ITA": "ARGELATO-CENTERGROSS",
    "state_code": "BO",
    "cab": "365908",
    "cap": "40050"
  },
  {
    "key": "659",
    "ITA": "ARGELATO-FUNO",
    "state_code": "BO",
    "cab": "365908",
    "cap": "40050"
  },
  {
    "key": "660",
    "ITA": "ARGELATO",
    "state_code": "BO",
    "cab": "365908",
    "cap": "40050"
  },
  {
    "key": "661",
    "ITA": "ARGENTA-LA FIORANA",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44011"
  },
  {
    "key": "662",
    "ITA": "ARGENTA-CAMPOTTO",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44011"
  },
  {
    "key": "663",
    "ITA": "ARGENTA-CONSANDOLO",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44011"
  },
  {
    "key": "664",
    "ITA": "ARGENTA-FILO",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44010"
  },
  {
    "key": "665",
    "ITA": "ARGENTA-BOCCALEONE",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44011"
  },
  {
    "key": "666",
    "ITA": "ARGENTA-BANDO",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44011"
  },
  {
    "key": "667",
    "ITA": "ARGENTA-TRAGHETTO",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44048"
  },
  {
    "key": "668",
    "ITA": "ARGENTA-SANTA MARIA CODIFIUME",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44048"
  },
  {
    "key": "669",
    "ITA": "ARGENTA-SAN NICOLO'",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44048"
  },
  {
    "key": "670",
    "ITA": "ARGENTA-SAN BIAGIO",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44016"
  },
  {
    "key": "671",
    "ITA": "ARGENTA-OSPITAL MONACALE",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44048"
  },
  {
    "key": "672",
    "ITA": "ARGENTA-MADONNA BOSCHI",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44014"
  },
  {
    "key": "673",
    "ITA": "ARGENTA-LONGASTRINO",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44014"
  },
  {
    "key": "674",
    "ITA": "ARGENTA",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44011"
  },
  {
    "key": "675",
    "ITA": "ARGENTA-ANITA",
    "state_code": "FE",
    "cab": "671701",
    "cap": "44014"
  },
  {
    "key": "676",
    "ITA": "ARGENTERA",
    "state_code": "CN",
    "cab": "918243",
    "cap": "12010"
  },
  {
    "key": "677",
    "ITA": "ARGENTERA-BERSEZIO",
    "state_code": "CN",
    "cab": "918243",
    "cap": "12010"
  },
  {
    "key": "678",
    "ITA": "ARGUELLO",
    "state_code": "CN",
    "cab": "918250",
    "cap": "12050"
  },
  {
    "key": "679",
    "ITA": "ARGUSTO",
    "state_code": "CZ",
    "cab": "912659",
    "cap": "88060"
  },
  {
    "key": "680",
    "ITA": "ARI",
    "state_code": "CH",
    "cab": "775601",
    "cap": "66010"
  },
  {
    "key": "681",
    "ITA": "ARI-SAN PIETRO",
    "state_code": "CH",
    "cab": "775601",
    "cap": "66010"
  },
  {
    "key": "682",
    "ITA": "ARIANO IRPINO",
    "state_code": "AV",
    "cab": "756502",
    "cap": "83031"
  },
  {
    "key": "683",
    "ITA": "ARIANO IRPINO-ORNETA",
    "state_code": "AV",
    "cab": "756502",
    "cap": "83031"
  },
  {
    "key": "684",
    "ITA": "ARIANO IRPINO-PALAZZISI",
    "state_code": "AV",
    "cab": "756502",
    "cap": "83031"
  },
  {
    "key": "685",
    "ITA": "ARIANO IRPINO-ARIANO SCALO",
    "state_code": "AV",
    "cab": "756502",
    "cap": "83031"
  },
  {
    "key": "686",
    "ITA": "ARIANO IRPINO-LA MANNA",
    "state_code": "AV",
    "cab": "756502",
    "cap": "83031"
  },
  {
    "key": "687",
    "ITA": "ARIANO NEL POLESINE",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "688",
    "ITA": "ARIANO NEL POLESINE-ARIANO",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "689",
    "ITA": "ARIANO NEL POLESINE-CROCIARA",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "690",
    "ITA": "ARIANO NEL POLESINE-GRILLARA",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "691",
    "ITA": "ARIANO NEL POLESINE-PIANO",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "692",
    "ITA": "ARIANO NEL POLESINE-SANTA MARIA IN PUNTA",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "693",
    "ITA": "ARIANO NEL POLESINE-RIVA'",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "694",
    "ITA": "ARIANO NEL POLESINE-SAN BASILIO",
    "state_code": "RO",
    "cab": "631309",
    "cap": "45012"
  },
  {
    "key": "695",
    "ITA": "ARICCIA-GALLORO",
    "state_code": "RM",
    "cab": "389007",
    "cap": "40"
  },
  {
    "key": "696",
    "ITA": "ARICCIA",
    "state_code": "RM",
    "cab": "389007",
    "cap": "40"
  },
  {
    "key": "697",
    "ITA": "ARIELLI",
    "state_code": "CH",
    "cab": "954511",
    "cap": "66030"
  },
  {
    "key": "698",
    "ITA": "ARIENZO",
    "state_code": "CE",
    "cab": "747808",
    "cap": "81021"
  },
  {
    "key": "699",
    "ITA": "ARIGNANO",
    "state_code": "TO",
    "cab": "282988",
    "cap": "10020"
  },
  {
    "key": "700",
    "ITA": "ARITZO-GIDILAU",
    "state_code": "NU",
    "cab": "864405",
    "cap": "8031"
  },
  {
    "key": "701",
    "ITA": "ARITZO",
    "state_code": "NU",
    "cab": "864405",
    "cap": "8031"
  },
  {
    "key": "702",
    "ITA": "ARIZZANO",
    "state_code": "VB",
    "cab": "816900",
    "cap": "28811"
  },
  {
    "key": "703",
    "ITA": "ARIZZANO-CISSANO",
    "state_code": "VB",
    "cab": "816900",
    "cap": "28811"
  },
  {
    "key": "704",
    "ITA": "ARIZZANO-CRESSEGLIO",
    "state_code": "VB",
    "cab": "816900",
    "cap": "28811"
  },
  {
    "key": "705",
    "ITA": "ARLENA DI CASTRO",
    "state_code": "VT",
    "cab": "771808",
    "cap": "1010"
  },
  {
    "key": "706",
    "ITA": "ARLUNO",
    "state_code": "MI",
    "cab": "324509",
    "cap": "20010"
  },
  {
    "key": "707",
    "ITA": "ARLUNO-ROGOROTTO",
    "state_code": "MI",
    "cab": "324509",
    "cap": "20010"
  },
  {
    "key": "708",
    "ITA": "ARMENO-SOVAZZA",
    "state_code": "NO",
    "cab": "451203",
    "cap": "28011"
  },
  {
    "key": "709",
    "ITA": "ARMENO-COIROMONTE",
    "state_code": "NO",
    "cab": "451203",
    "cap": "28011"
  },
  {
    "key": "710",
    "ITA": "ARMENO",
    "state_code": "NO",
    "cab": "451203",
    "cap": "28011"
  },
  {
    "key": "711",
    "ITA": "ARMENTO",
    "state_code": "PZ",
    "cab": "911826",
    "cap": "85010"
  },
  {
    "key": "712",
    "ITA": "ARMO",
    "state_code": "IM",
    "cab": "922237",
    "cap": "18026"
  },
  {
    "key": "713",
    "ITA": "ARMUNGIA",
    "state_code": "CA",
    "cab": "441303",
    "cap": "9040"
  },
  {
    "key": "714",
    "ITA": "ARNAD-VILLE",
    "state_code": "AO",
    "cab": "782102",
    "cap": "11020"
  },
  {
    "key": "715",
    "ITA": "ARNAD",
    "state_code": "AO",
    "cab": "782102",
    "cap": "11020"
  },
  {
    "key": "716",
    "ITA": "ARNAD-BARME",
    "state_code": "AO",
    "cab": "782102",
    "cap": "11020"
  },
  {
    "key": "717",
    "ITA": "ARNARA",
    "state_code": "FR",
    "cab": "743203",
    "cap": "3020"
  },
  {
    "key": "718",
    "ITA": "ARNASCO",
    "state_code": "SV",
    "cab": "923003",
    "cap": "17032"
  },
  {
    "key": "719",
    "ITA": "ARNESANO-RIESCI",
    "state_code": "LE",
    "cab": "794602",
    "cap": "73010"
  },
  {
    "key": "720",
    "ITA": "ARNESANO",
    "state_code": "LE",
    "cab": "794602",
    "cap": "73010"
  },
  {
    "key": "721",
    "ITA": "AROLA",
    "state_code": "VB",
    "cab": "916940",
    "cap": "28899"
  },
  {
    "key": "722",
    "ITA": "ARONA",
    "state_code": "NO",
    "cab": "451302",
    "cap": "28041"
  },
  {
    "key": "723",
    "ITA": "ARONA-DAGNENTE",
    "state_code": "NO",
    "cab": "451302",
    "cap": "28041"
  },
  {
    "key": "724",
    "ITA": "ARONA-MERCURAGO",
    "state_code": "NO",
    "cab": "451302",
    "cap": "28041"
  },
  {
    "key": "725",
    "ITA": "AROSIO",
    "state_code": "CO",
    "cab": "508903",
    "cap": "22060"
  },
  {
    "key": "726",
    "ITA": "ARPAIA",
    "state_code": "BN",
    "cab": "950113",
    "cap": "82011"
  },
  {
    "key": "727",
    "ITA": "ARPAISE-TERRANOVA",
    "state_code": "BN",
    "cab": "950121",
    "cap": "82010"
  },
  {
    "key": "728",
    "ITA": "ARPAISE",
    "state_code": "BN",
    "cab": "950121",
    "cap": "82010"
  },
  {
    "key": "729",
    "ITA": "ARPINO-SCAFFA",
    "state_code": "FR",
    "cab": "743302",
    "cap": "3033"
  },
  {
    "key": "730",
    "ITA": "ARPINO",
    "state_code": "FR",
    "cab": "743302",
    "cap": "3033"
  },
  {
    "key": "731",
    "ITA": "ARQUA' PETRARCA",
    "state_code": "PD",
    "cab": "635508",
    "cap": "35032"
  },
  {
    "key": "732",
    "ITA": "ARQUA' POLESINE",
    "state_code": "RO",
    "cab": "631408",
    "cap": "45031"
  },
  {
    "key": "733",
    "ITA": "ARQUA' POLESINE-CORNE'",
    "state_code": "RO",
    "cab": "631408",
    "cap": "45031"
  },
  {
    "key": "734",
    "ITA": "ARQUATA DEL TRONTO",
    "state_code": "AP",
    "cab": "693705",
    "cap": "63043"
  },
  {
    "key": "735",
    "ITA": "ARQUATA DEL TRONTO-CAPODACQUA",
    "state_code": "AP",
    "cab": "693705",
    "cap": "63043"
  },
  {
    "key": "736",
    "ITA": "ARQUATA DEL TRONTO-PESCARA DEL TRONTO",
    "state_code": "AP",
    "cab": "693705",
    "cap": "63043"
  },
  {
    "key": "737",
    "ITA": "ARQUATA DEL TRONTO-PRETARE",
    "state_code": "AP",
    "cab": "693705",
    "cap": "63043"
  },
  {
    "key": "738",
    "ITA": "ARQUATA DEL TRONTO-SPELONGA",
    "state_code": "AP",
    "cab": "693705",
    "cap": "63043"
  },
  {
    "key": "739",
    "ITA": "ARQUATA DEL TRONTO-TRISUNGO",
    "state_code": "AP",
    "cab": "693705",
    "cap": "63043"
  },
  {
    "key": "740",
    "ITA": "ARQUATA SCRIVIA",
    "state_code": "AL",
    "cab": "479600",
    "cap": "15061"
  },
  {
    "key": "741",
    "ITA": "ARRE",
    "state_code": "PD",
    "cab": "895409",
    "cap": "35020"
  },
  {
    "key": "742",
    "ITA": "ARRONE-CASTELDILAGO",
    "state_code": "TR",
    "cab": "725408",
    "cap": "5031"
  },
  {
    "key": "743",
    "ITA": "ARRONE-BUONACQUISTO",
    "state_code": "TR",
    "cab": "725408",
    "cap": "5031"
  },
  {
    "key": "744",
    "ITA": "ARRONE",
    "state_code": "TR",
    "cab": "725408",
    "cap": "5031"
  },
  {
    "key": "745",
    "ITA": "ARSAGO SEPRIO",
    "state_code": "VA",
    "cab": "882209",
    "cap": "21010"
  },
  {
    "key": "746",
    "ITA": "ARSIE'",
    "state_code": "BL",
    "cab": "610105",
    "cap": "32030"
  },
  {
    "key": "747",
    "ITA": "ARSIE'-ROCCA",
    "state_code": "BL",
    "cab": "610105",
    "cap": "32030"
  },
  {
    "key": "748",
    "ITA": "ARSIE'-MELLAME",
    "state_code": "BL",
    "cab": "610105",
    "cap": "32030"
  },
  {
    "key": "749",
    "ITA": "ARSIE'-FASTRO",
    "state_code": "BL",
    "cab": "610105",
    "cap": "32030"
  },
  {
    "key": "750",
    "ITA": "ARSIERO",
    "state_code": "VI",
    "cab": "601104",
    "cap": "36011"
  },
  {
    "key": "751",
    "ITA": "ARSIERO-CASTANA",
    "state_code": "VI",
    "cab": "601104",
    "cap": "36011"
  },
  {
    "key": "752",
    "ITA": "ARSITA",
    "state_code": "TE",
    "cab": "767400",
    "cap": "64031"
  },
  {
    "key": "753",
    "ITA": "ARSOLI",
    "state_code": "RM",
    "cab": "389106",
    "cap": "23"
  },
  {
    "key": "754",
    "ITA": "ARTA TERME",
    "state_code": "UD",
    "cab": "636308",
    "cap": "33022"
  },
  {
    "key": "755",
    "ITA": "ARTA TERME-PIANO D'ARTA",
    "state_code": "UD",
    "cab": "636308",
    "cap": "33022"
  },
  {
    "key": "756",
    "ITA": "ARTEGNA",
    "state_code": "UD",
    "cab": "636407",
    "cap": "33011"
  },
  {
    "key": "757",
    "ITA": "ARTENA-MACERE",
    "state_code": "RM",
    "cab": "389205",
    "cap": "31"
  },
  {
    "key": "758",
    "ITA": "ARTENA-COLUBRO",
    "state_code": "RM",
    "cab": "389205",
    "cap": "31"
  },
  {
    "key": "759",
    "ITA": "ARTENA",
    "state_code": "RM",
    "cab": "389205",
    "cap": "31"
  },
  {
    "key": "760",
    "ITA": "ARTOGNE",
    "state_code": "BS",
    "cab": "540302",
    "cap": "25040"
  },
  {
    "key": "761",
    "ITA": "ARVIER-LEVEROGNE",
    "state_code": "AO",
    "cab": "902221",
    "cap": "11011"
  },
  {
    "key": "762",
    "ITA": "ARVIER-PLANAVAL",
    "state_code": "AO",
    "cab": "902221",
    "cap": "11011"
  },
  {
    "key": "763",
    "ITA": "ARVIER",
    "state_code": "AO",
    "cab": "902221",
    "cap": "11011"
  },
  {
    "key": "764",
    "ITA": "ARZACHENA-CAPO FERRO",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "765",
    "ITA": "ARZACHENA-CANNIGIONE",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "766",
    "ITA": "ARZACHENA-CALA DI VOLPE",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "767",
    "ITA": "ARZACHENA-COSTA SMERALDA",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "768",
    "ITA": "ARZACHENA",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "769",
    "ITA": "ARZACHENA-BAIA SARDINIA",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "770",
    "ITA": "ARZACHENA-PIRAZZOLU",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "771",
    "ITA": "ARZACHENA-PORTO CERVO",
    "state_code": "OT",
    "cab": "849000",
    "cap": "7021"
  },
  {
    "key": "772",
    "ITA": "ARZAGO D'ADDA",
    "state_code": "BG",
    "cab": "525600",
    "cap": "24040"
  },
  {
    "key": "773",
    "ITA": "ARZANA",
    "state_code": "OG",
    "cab": "864504",
    "cap": "8040"
  },
  {
    "key": "774",
    "ITA": "ARZANO",
    "state_code": "NA",
    "cab": "397208",
    "cap": "80022"
  },
  {
    "key": "775",
    "ITA": "ARZENE-SAN LORENZO",
    "state_code": "PN",
    "cab": "884205",
    "cap": "33098"
  },
  {
    "key": "776",
    "ITA": "ARZENE",
    "state_code": "PN",
    "cab": "884205",
    "cap": "33098"
  },
  {
    "key": "777",
    "ITA": "ARZERGRANDE-VALLONGA",
    "state_code": "PD",
    "cab": "623603",
    "cap": "35020"
  },
  {
    "key": "778",
    "ITA": "ARZERGRANDE",
    "state_code": "PD",
    "cab": "623603",
    "cap": "35020"
  },
  {
    "key": "779",
    "ITA": "ARZIGNANO",
    "state_code": "VI",
    "cab": "601203",
    "cap": "36071"
  },
  {
    "key": "780",
    "ITA": "ARZIGNANO-PUGNELLO",
    "state_code": "VI",
    "cab": "601203",
    "cap": "36071"
  },
  {
    "key": "781",
    "ITA": "ARZIGNANO-TEZZE",
    "state_code": "VI",
    "cab": "601203",
    "cap": "36071"
  },
  {
    "key": "782",
    "ITA": "ASCEA-TERRADURA",
    "state_code": "SA",
    "cab": "760603",
    "cap": "84046"
  },
  {
    "key": "783",
    "ITA": "ASCEA-MARINA DI ASCEA",
    "state_code": "SA",
    "cab": "760603",
    "cap": "84046"
  },
  {
    "key": "784",
    "ITA": "ASCEA-MANDIA",
    "state_code": "SA",
    "cab": "760603",
    "cap": "84046"
  },
  {
    "key": "785",
    "ITA": "ASCEA",
    "state_code": "SA",
    "cab": "760603",
    "cap": "84046"
  },
  {
    "key": "786",
    "ITA": "ASCEA-CATONA",
    "state_code": "SA",
    "cab": "760603",
    "cap": "84046"
  },
  {
    "key": "787",
    "ITA": "ASCIANO-CHIUSURE",
    "state_code": "SI",
    "cab": "717603",
    "cap": "53041"
  },
  {
    "key": "788",
    "ITA": "ASCIANO-MONTE OLIVETO MAGGIORE",
    "state_code": "SI",
    "cab": "717603",
    "cap": "53041"
  },
  {
    "key": "789",
    "ITA": "ASCIANO-ARBIA",
    "state_code": "SI",
    "cab": "717603",
    "cap": "53041"
  },
  {
    "key": "790",
    "ITA": "ASCIANO",
    "state_code": "SI",
    "cab": "717603",
    "cap": "53041"
  },
  {
    "key": "791",
    "ITA": "ASCIANO-MONTE SANTE MARIE",
    "state_code": "SI",
    "cab": "717603",
    "cap": "53041"
  },
  {
    "key": "792",
    "ITA": "ASCOLI PICENO-CAMPOLUNGO",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "793",
    "ITA": "ASCOLI PICENO-CASTEL TROSINO",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "794",
    "ITA": "ASCOLI PICENO-LISCIANO DI COLLOTO",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "795",
    "ITA": "ASCOLI PICENO-MARINO DEL TRONTO",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "796",
    "ITA": "ASCOLI PICENO-MONTICELLI",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "797",
    "ITA": "ASCOLI PICENO-MOZZANO",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "798",
    "ITA": "ASCOLI PICENO-PIAGGE",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "799",
    "ITA": "ASCOLI PICENO-POGGIO DI BRETTA",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "800",
    "ITA": "ASCOLI PICENO-VENAGRANDE",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "801",
    "ITA": "ASCOLI PICENO",
    "state_code": "AP",
    "cab": "135004",
    "cap": "63100"
  },
  {
    "key": "802",
    "ITA": "ASCOLI SATRIANO-SAN CARLO D'ASCOLI",
    "state_code": "FG",
    "cab": "782805",
    "cap": "71022"
  },
  {
    "key": "803",
    "ITA": "ASCOLI SATRIANO",
    "state_code": "FG",
    "cab": "782805",
    "cap": "71022"
  },
  {
    "key": "804",
    "ITA": "ASCREA",
    "state_code": "RI",
    "cab": "947101",
    "cap": "2020"
  },
  {
    "key": "805",
    "ITA": "ASCREA-STIPES",
    "state_code": "RI",
    "cab": "947101",
    "cap": "2020"
  },
  {
    "key": "806",
    "ITA": "ASIAGO-RODEGHIERI",
    "state_code": "VI",
    "cab": "601302",
    "cap": "36012"
  },
  {
    "key": "807",
    "ITA": "ASIAGO-RIGONI",
    "state_code": "VI",
    "cab": "601302",
    "cap": "36012"
  },
  {
    "key": "808",
    "ITA": "ASIAGO",
    "state_code": "VI",
    "cab": "601302",
    "cap": "36012"
  },
  {
    "key": "809",
    "ITA": "ASIAGO-SASSO",
    "state_code": "VI",
    "cab": "601302",
    "cap": "36012"
  },
  {
    "key": "810",
    "ITA": "ASIGLIANO VENETO",
    "state_code": "VI",
    "cab": "601401",
    "cap": "36020"
  },
  {
    "key": "811",
    "ITA": "ASIGLIANO VERCELLESE",
    "state_code": "VC",
    "cab": "442707",
    "cap": "13032"
  },
  {
    "key": "812",
    "ITA": "ASOLA-CASTELNUOVO",
    "state_code": "MN",
    "cab": "574400",
    "cap": "46041"
  },
  {
    "key": "813",
    "ITA": "ASOLA-BARCHI DI ASOLA",
    "state_code": "MN",
    "cab": "574400",
    "cap": "46041"
  },
  {
    "key": "814",
    "ITA": "ASOLA",
    "state_code": "MN",
    "cab": "574400",
    "cap": "46041"
  },
  {
    "key": "815",
    "ITA": "ASOLO-PAGNANO",
    "state_code": "TV",
    "cab": "614800",
    "cap": "31011"
  },
  {
    "key": "816",
    "ITA": "ASOLO-CASELLA D'ASOLO",
    "state_code": "TV",
    "cab": "614800",
    "cap": "31011"
  },
  {
    "key": "817",
    "ITA": "ASOLO",
    "state_code": "TV",
    "cab": "614800",
    "cap": "31011"
  },
  {
    "key": "818",
    "ITA": "ASOLO-VILLA D'ASOLO",
    "state_code": "TV",
    "cab": "614800",
    "cap": "31011"
  },
  {
    "key": "819",
    "ITA": "ASSAGO",
    "state_code": "MI",
    "cab": "324608",
    "cap": "20090"
  },
  {
    "key": "820",
    "ITA": "ASSEMINI",
    "state_code": "CA",
    "cab": "438408",
    "cap": "9032"
  },
  {
    "key": "821",
    "ITA": "ASSEMINI-MACCHIAREDDU",
    "state_code": "CA",
    "cab": "438408",
    "cap": "9032"
  },
  {
    "key": "822",
    "ITA": "ASSISI-TORDANDREA",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "823",
    "ITA": "ASSISI-VIOLE DI ASSISI",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "824",
    "ITA": "ASSISI",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "825",
    "ITA": "ASSISI-ASSISI SANTUARIO",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "826",
    "ITA": "ASSISI-CASTELNUOVO",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "827",
    "ITA": "ASSISI-TORCHIAGINA",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "828",
    "ITA": "ASSISI-PETRIGNANO",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "829",
    "ITA": "ASSISI-RIVOTORTO",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "830",
    "ITA": "ASSISI-SAN VITALE",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "831",
    "ITA": "ASSISI-SANTA MARIA DEGLI ANGELI",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "832",
    "ITA": "ASSISI-PALAZZO",
    "state_code": "PG",
    "cab": "382705",
    "cap": "6081"
  },
  {
    "key": "833",
    "ITA": "ASSO",
    "state_code": "CO",
    "cab": "509000",
    "cap": "22033"
  },
  {
    "key": "834",
    "ITA": "ASSOLO",
    "state_code": "OR",
    "cab": "877506",
    "cap": "9080"
  },
  {
    "key": "835",
    "ITA": "ASSORO",
    "state_code": "EN",
    "cab": "835900",
    "cap": "94010"
  },
  {
    "key": "836",
    "ITA": "ASSORO-SAN GIORGIO",
    "state_code": "EN",
    "cab": "835900",
    "cap": "94010"
  },
  {
    "key": "837",
    "ITA": "ASTI-MONTEMARZO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "838",
    "ITA": "ASTI-POGGIO D'ASTI",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "839",
    "ITA": "ASTI-QUARTO D'ASTI",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14030"
  },
  {
    "key": "840",
    "ITA": "ASTI-REVIGNANO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14010"
  },
  {
    "key": "841",
    "ITA": "ASTI-SAN MARZANOTTO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "842",
    "ITA": "ASTI-SERRAVALLE",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14020"
  },
  {
    "key": "843",
    "ITA": "ASTI-SESSANT",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14020"
  },
  {
    "key": "844",
    "ITA": "ASTI-STAZIONE DI PORTACOMARO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "845",
    "ITA": "ASTI-VAGLIERANO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14010"
  },
  {
    "key": "846",
    "ITA": "ASTI-VALGERA",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "847",
    "ITA": "ASTI-VALLEANDONA",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14010"
  },
  {
    "key": "848",
    "ITA": "ASTI-MONTEGROSSO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14010"
  },
  {
    "key": "849",
    "ITA": "ASTI-MOMBARONE",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14020"
  },
  {
    "key": "850",
    "ITA": "ASTI-CASTIGLIONE",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14037"
  },
  {
    "key": "851",
    "ITA": "ASTI-VIATOSTO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "852",
    "ITA": "ASTI",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "853",
    "ITA": "ASTI-CASABIANCA",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "854",
    "ITA": "ASTI-VALLETANARO",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "855",
    "ITA": "ASTI-VARIGLIE",
    "state_code": "AT",
    "cab": "103002",
    "cap": "14100"
  },
  {
    "key": "856",
    "ITA": "ASUNI",
    "state_code": "OR",
    "cab": "877605",
    "cap": "9080"
  },
  {
    "key": "857",
    "ITA": "ATELETA",
    "state_code": "AQ",
    "cab": "404301",
    "cap": "67030"
  },
  {
    "key": "858",
    "ITA": "ATELLA",
    "state_code": "PZ",
    "cab": "891002",
    "cap": "85020"
  },
  {
    "key": "859",
    "ITA": "ATELLA-SANT'ANDREA",
    "state_code": "PZ",
    "cab": "891002",
    "cap": "85020"
  },
  {
    "key": "860",
    "ITA": "ATELLA-SANT'ILARIO",
    "state_code": "PZ",
    "cab": "891002",
    "cap": "85020"
  },
  {
    "key": "861",
    "ITA": "ATENA LUCANA-ATENA LUCANA SCALO",
    "state_code": "SA",
    "cab": "760702",
    "cap": "84030"
  },
  {
    "key": "862",
    "ITA": "ATENA LUCANA-TAVERNE",
    "state_code": "SA",
    "cab": "760702",
    "cap": "84030"
  },
  {
    "key": "863",
    "ITA": "ATENA LUCANA",
    "state_code": "SA",
    "cab": "760702",
    "cap": "84030"
  },
  {
    "key": "864",
    "ITA": "ATESSA",
    "state_code": "CH",
    "cab": "775700",
    "cap": "66041"
  },
  {
    "key": "865",
    "ITA": "ATESSA-MONTE MARCONE",
    "state_code": "CH",
    "cab": "775700",
    "cap": "66041"
  },
  {
    "key": "866",
    "ITA": "ATESSA-PIANA LA FARA",
    "state_code": "CH",
    "cab": "775700",
    "cap": "66041"
  },
  {
    "key": "867",
    "ITA": "ATESSA-PIAZZANO",
    "state_code": "CH",
    "cab": "775700",
    "cap": "66041"
  },
  {
    "key": "868",
    "ITA": "ATINA-ATINA INFERIORE",
    "state_code": "FR",
    "cab": "743401",
    "cap": "3042"
  },
  {
    "key": "869",
    "ITA": "ATINA",
    "state_code": "FR",
    "cab": "743401",
    "cap": "3042"
  },
  {
    "key": "870",
    "ITA": "ATINA-CASINO PICA",
    "state_code": "FR",
    "cab": "743401",
    "cap": "3042"
  },
  {
    "key": "871",
    "ITA": "ATRANI",
    "state_code": "SA",
    "cab": "952242",
    "cap": "84010"
  },
  {
    "key": "872",
    "ITA": "ATRI-SAN GIACOMO",
    "state_code": "TE",
    "cab": "767509",
    "cap": "64032"
  },
  {
    "key": "873",
    "ITA": "ATRI-TRECIMINIERE",
    "state_code": "TE",
    "cab": "767509",
    "cap": "64032"
  },
  {
    "key": "874",
    "ITA": "ATRI-FONTANELLE",
    "state_code": "TE",
    "cab": "767509",
    "cap": "64032"
  },
  {
    "key": "875",
    "ITA": "ATRI-CASOLI",
    "state_code": "TE",
    "cab": "767509",
    "cap": "64032"
  },
  {
    "key": "876",
    "ITA": "ATRI",
    "state_code": "TE",
    "cab": "767509",
    "cap": "64032"
  },
  {
    "key": "877",
    "ITA": "ATRI-SANTA MARGHERITA",
    "state_code": "TE",
    "cab": "767509",
    "cap": "64032"
  },
  {
    "key": "878",
    "ITA": "ATRIPALDA",
    "state_code": "AV",
    "cab": "756601",
    "cap": "83042"
  },
  {
    "key": "879",
    "ITA": "ATTIGLIANO",
    "state_code": "TR",
    "cab": "725507",
    "cap": "5012"
  },
  {
    "key": "880",
    "ITA": "ATTIMIS",
    "state_code": "UD",
    "cab": "636506",
    "cap": "33040"
  },
  {
    "key": "881",
    "ITA": "ATTIMIS-RACCHIUSO",
    "state_code": "UD",
    "cab": "636506",
    "cap": "33040"
  },
  {
    "key": "882",
    "ITA": "ATZARA",
    "state_code": "NU",
    "cab": "864603",
    "cap": "8030"
  },
  {
    "key": "883",
    "ITA": "AUDITORE",
    "state_code": "PU",
    "cab": "682104",
    "cap": "61020"
  },
  {
    "key": "884",
    "ITA": "AUDITORE-CASININA",
    "state_code": "PU",
    "cab": "682104",
    "cap": "61020"
  },
  {
    "key": "885",
    "ITA": "AUGUSTA-BRUCOLI",
    "state_code": "SR",
    "cab": "846204",
    "cap": "96011"
  },
  {
    "key": "886",
    "ITA": "AUGUSTA",
    "state_code": "SR",
    "cab": "846204",
    "cap": "96011"
  },
  {
    "key": "887",
    "ITA": "AULETTA",
    "state_code": "SA",
    "cab": "708206",
    "cap": "84031"
  },
  {
    "key": "888",
    "ITA": "AULLA",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "889",
    "ITA": "AULLA-ALBIANO MAGRA",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "890",
    "ITA": "AULLA-BIBOLA",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "891",
    "ITA": "AULLA-SERRICCIOLO",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "892",
    "ITA": "AULLA-CAPRIGLIOLA",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "893",
    "ITA": "AULLA-PALLERONE",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "894",
    "ITA": "AULLA-QUERCIA",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "895",
    "ITA": "AULLA-BIGLIOLO",
    "state_code": "MS",
    "cab": "698605",
    "cap": "54011"
  },
  {
    "key": "896",
    "ITA": "AURANO",
    "state_code": "VB",
    "cab": "916957",
    "cap": "28812"
  },
  {
    "key": "897",
    "ITA": "AURIGO",
    "state_code": "IM",
    "cab": "922245",
    "cap": "18020"
  },
  {
    "key": "898",
    "ITA": "AURONZO DI CADORE-MISURINA",
    "state_code": "BL",
    "cab": "610204",
    "cap": "32041"
  },
  {
    "key": "899",
    "ITA": "AURONZO DI CADORE-GIRALBA",
    "state_code": "BL",
    "cab": "610204",
    "cap": "32041"
  },
  {
    "key": "900",
    "ITA": "AURONZO DI CADORE",
    "state_code": "BL",
    "cab": "610204",
    "cap": "32041"
  },
  {
    "key": "901",
    "ITA": "AURONZO DI CADORE-REANE",
    "state_code": "BL",
    "cab": "610204",
    "cap": "32041"
  },
  {
    "key": "902",
    "ITA": "AUSONIA",
    "state_code": "FR",
    "cab": "382507",
    "cap": "3040"
  },
  {
    "key": "903",
    "ITA": "AUSONIA-SELVACAVA",
    "state_code": "FR",
    "cab": "382507",
    "cap": "3040"
  },
  {
    "key": "904",
    "ITA": "AUSTIS",
    "state_code": "NU",
    "cab": "864702",
    "cap": "8030"
  },
  {
    "key": "905",
    "ITA": "AVEGNO",
    "state_code": "GE",
    "cab": "903104",
    "cap": "16030"
  },
  {
    "key": "906",
    "ITA": "AVEGNO-TESTANA",
    "state_code": "GE",
    "cab": "903104",
    "cap": "16030"
  },
  {
    "key": "907",
    "ITA": "AVELENGO",
    "state_code": "BZ",
    "cab": "581702",
    "cap": "39010"
  },
  {
    "key": "908",
    "ITA": "AVELLA",
    "state_code": "AV",
    "cab": "758607",
    "cap": "83021"
  },
  {
    "key": "909",
    "ITA": "AVELLINO",
    "state_code": "AV",
    "cab": "151001",
    "cap": "83100"
  },
  {
    "key": "910",
    "ITA": "AVELLINO-VALLE PONTICELLI",
    "state_code": "AV",
    "cab": "151001",
    "cap": "83100"
  },
  {
    "key": "911",
    "ITA": "AVELLINO-PICARELLI",
    "state_code": "AV",
    "cab": "151001",
    "cap": "83100"
  },
  {
    "key": "912",
    "ITA": "AVELLINO-BELLIZZI IRPINO",
    "state_code": "AV",
    "cab": "151001",
    "cap": "83100"
  },
  {
    "key": "913",
    "ITA": "AVERARA",
    "state_code": "BG",
    "cab": "927749",
    "cap": "24010"
  },
  {
    "key": "914",
    "ITA": "AVERSA",
    "state_code": "CE",
    "cab": "747907",
    "cap": "81031"
  },
  {
    "key": "915",
    "ITA": "AVETRANA",
    "state_code": "TA",
    "cab": "788208",
    "cap": "74020"
  },
  {
    "key": "916",
    "ITA": "AVEZZANO-SANTUARIO DI PIETRACQUARIA",
    "state_code": "AQ",
    "cab": "404400",
    "cap": "67051"
  },
  {
    "key": "917",
    "ITA": "AVEZZANO-SAN PELINO",
    "state_code": "AQ",
    "cab": "404400",
    "cap": "67051"
  },
  {
    "key": "918",
    "ITA": "AVEZZANO-PATERNO",
    "state_code": "AQ",
    "cab": "404400",
    "cap": "67051"
  },
  {
    "key": "919",
    "ITA": "AVEZZANO-CESE",
    "state_code": "AQ",
    "cab": "404400",
    "cap": "67051"
  },
  {
    "key": "920",
    "ITA": "AVEZZANO",
    "state_code": "AQ",
    "cab": "404400",
    "cap": "67051"
  },
  {
    "key": "921",
    "ITA": "AVEZZANO-ANTROSANO",
    "state_code": "AQ",
    "cab": "404400",
    "cap": "67051"
  },
  {
    "key": "922",
    "ITA": "AVIANO-CASTELLO",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "923",
    "ITA": "AVIANO-SAN MARTINO DI CAMPAGNA",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "924",
    "ITA": "AVIANO-CORTINA",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "925",
    "ITA": "AVIANO-GIAIS",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "926",
    "ITA": "AVIANO-GLERA",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "927",
    "ITA": "AVIANO-MARSURE",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "928",
    "ITA": "AVIANO-SELVA",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "929",
    "ITA": "AVIANO",
    "state_code": "PN",
    "cab": "647701",
    "cap": "33081"
  },
  {
    "key": "930",
    "ITA": "AVIATICO",
    "state_code": "BG",
    "cab": "927756",
    "cap": "24020"
  },
  {
    "key": "931",
    "ITA": "AVIGLIANA",
    "state_code": "TO",
    "cab": "300509",
    "cap": "10051"
  },
  {
    "key": "932",
    "ITA": "AVIGLIANA-DRUBIAGLIO",
    "state_code": "TO",
    "cab": "300509",
    "cap": "10051"
  },
  {
    "key": "933",
    "ITA": "AVIGLIANA-GRANGIA",
    "state_code": "TO",
    "cab": "300509",
    "cap": "10051"
  },
  {
    "key": "934",
    "ITA": "AVIGLIANO",
    "state_code": "PZ",
    "cab": "418608",
    "cap": "85021"
  },
  {
    "key": "935",
    "ITA": "AVIGLIANO-PIANO DEL CONTE",
    "state_code": "PZ",
    "cab": "418608",
    "cap": "85020"
  },
  {
    "key": "936",
    "ITA": "AVIGLIANO-POSSIDENTE",
    "state_code": "PZ",
    "cab": "418608",
    "cap": "85020"
  },
  {
    "key": "937",
    "ITA": "AVIGLIANO-SANT'ANGELO",
    "state_code": "PZ",
    "cab": "418608",
    "cap": "85020"
  },
  {
    "key": "938",
    "ITA": "AVIGLIANO-CASTEL LAGOPESOLE",
    "state_code": "PZ",
    "cab": "418608",
    "cap": "85020"
  },
  {
    "key": "939",
    "ITA": "AVIGLIANO UMBRO",
    "state_code": "TR",
    "cab": "727701",
    "cap": "5020"
  },
  {
    "key": "940",
    "ITA": "AVIGLIANO UMBRO-DUNAROBBA",
    "state_code": "TR",
    "cab": "727701",
    "cap": "5020"
  },
  {
    "key": "941",
    "ITA": "AVIGLIANO UMBRO-SANTA RESTITUTA",
    "state_code": "TR",
    "cab": "727701",
    "cap": "5020"
  },
  {
    "key": "942",
    "ITA": "AVIGLIANO UMBRO-SISMANO",
    "state_code": "TR",
    "cab": "727701",
    "cap": "5020"
  },
  {
    "key": "943",
    "ITA": "AVIO",
    "state_code": "TN",
    "cab": "343202",
    "cap": "38063"
  },
  {
    "key": "944",
    "ITA": "AVIO-SABBIONARA",
    "state_code": "TN",
    "cab": "343202",
    "cap": "38063"
  },
  {
    "key": "945",
    "ITA": "AVIO-BORGHETTO ALL'ADIGE",
    "state_code": "TN",
    "cab": "343202",
    "cap": "38060"
  },
  {
    "key": "946",
    "ITA": "AVISE",
    "state_code": "AO",
    "cab": "902239",
    "cap": "11010"
  },
  {
    "key": "947",
    "ITA": "AVISE-RUNAZ",
    "state_code": "AO",
    "cab": "902239",
    "cap": "11010"
  },
  {
    "key": "948",
    "ITA": "AVOLA",
    "state_code": "SR",
    "cab": "846303",
    "cap": "96012"
  },
  {
    "key": "949",
    "ITA": "AVOLASCA",
    "state_code": "AL",
    "cab": "920850",
    "cap": "15050"
  },
  {
    "key": "950",
    "ITA": "AYAS-CHAMPOLUC",
    "state_code": "AO",
    "cab": "315101",
    "cap": "11020"
  },
  {
    "key": "951",
    "ITA": "AYAS-ANTAGNOD",
    "state_code": "AO",
    "cab": "315101",
    "cap": "11020"
  },
  {
    "key": "952",
    "ITA": "AYAS",
    "state_code": "AO",
    "cab": "315101",
    "cap": "11020"
  },
  {
    "key": "953",
    "ITA": "AYAS-LIGNOD",
    "state_code": "AO",
    "cab": "315101",
    "cap": "11020"
  },
  {
    "key": "954",
    "ITA": "AYAS-PERIASC",
    "state_code": "AO",
    "cab": "315101",
    "cap": "11020"
  },
  {
    "key": "955",
    "ITA": "AYMAVILLES",
    "state_code": "AO",
    "cab": "498907",
    "cap": "11010"
  },
  {
    "key": "956",
    "ITA": "AYMAVILLES-VIEYES",
    "state_code": "AO",
    "cab": "498907",
    "cap": "11010"
  },
  {
    "key": "957",
    "ITA": "AZEGLIO",
    "state_code": "TO",
    "cab": "300608",
    "cap": "10010"
  },
  {
    "key": "958",
    "ITA": "AZZANELLO",
    "state_code": "CR",
    "cab": "566406",
    "cap": "26010"
  },
  {
    "key": "959",
    "ITA": "AZZANO D'ASTI",
    "state_code": "AT",
    "cab": "919928",
    "cap": "14030"
  },
  {
    "key": "960",
    "ITA": "AZZANO DECIMO",
    "state_code": "PN",
    "cab": "647800",
    "cap": "33082"
  },
  {
    "key": "961",
    "ITA": "AZZANO DECIMO-TIEZZO",
    "state_code": "PN",
    "cab": "647800",
    "cap": "33082"
  },
  {
    "key": "962",
    "ITA": "AZZANO DECIMO-FAGNIGOLA",
    "state_code": "PN",
    "cab": "647800",
    "cap": "33082"
  },
  {
    "key": "963",
    "ITA": "AZZANO DECIMO-CORVA",
    "state_code": "PN",
    "cab": "647800",
    "cap": "33082"
  },
  {
    "key": "964",
    "ITA": "AZZANO MELLA",
    "state_code": "BS",
    "cab": "581009",
    "cap": "25020"
  },
  {
    "key": "965",
    "ITA": "AZZANO SAN PAOLO",
    "state_code": "BG",
    "cab": "525709",
    "cap": "24052"
  },
  {
    "key": "966",
    "ITA": "AZZATE",
    "state_code": "VA",
    "cab": "500009",
    "cap": "21022"
  },
  {
    "key": "967",
    "ITA": "AZZIO",
    "state_code": "VA",
    "cab": "924126",
    "cap": "21030"
  },
  {
    "key": "968",
    "ITA": "AZZONE",
    "state_code": "BG",
    "cab": "525808",
    "cap": "24020"
  },
  {
    "key": "969",
    "ITA": "AZZONE-DEZZO",
    "state_code": "BG",
    "cab": "525808",
    "cap": "24020"
  },
  {
    "key": "970",
    "ITA": "BACENO",
    "state_code": "VB",
    "cab": "451401",
    "cap": "28861"
  },
  {
    "key": "971",
    "ITA": "BACOLI-FUSARO",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "972",
    "ITA": "BACOLI-MILISCOLA",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "973",
    "ITA": "BACOLI-BAIA",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "974",
    "ITA": "BACOLI-TORREGAVETA",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "975",
    "ITA": "BACOLI",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "976",
    "ITA": "BACOLI-CUMA",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "977",
    "ITA": "BACOLI-CAPPELLA",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "978",
    "ITA": "BACOLI-MISENO",
    "state_code": "NA",
    "cab": "397307",
    "cap": "80070"
  },
  {
    "key": "979",
    "ITA": "BADALUCCO",
    "state_code": "IM",
    "cab": "449900",
    "cap": "18010"
  },
  {
    "key": "980",
    "ITA": "BADESI",
    "state_code": "OT",
    "cab": "876805",
    "cap": "7030"
  },
  {
    "key": "981",
    "ITA": "BADIA",
    "state_code": "BZ",
    "cab": "581801",
    "cap": "39036"
  },
  {
    "key": "982",
    "ITA": "BADIA-LA VILLA",
    "state_code": "BZ",
    "cab": "581801",
    "cap": "39030"
  },
  {
    "key": "983",
    "ITA": "BADIA-PEDRACES",
    "state_code": "BZ",
    "cab": "581801",
    "cap": "39036"
  },
  {
    "key": "984",
    "ITA": "BADIA-SAN CASSIANO",
    "state_code": "BZ",
    "cab": "581801",
    "cap": "39030"
  },
  {
    "key": "985",
    "ITA": "BADIA CALAVENA",
    "state_code": "VR",
    "cab": "592501",
    "cap": "37030"
  },
  {
    "key": "986",
    "ITA": "BADIA PAVESE",
    "state_code": "PV",
    "cab": "930131",
    "cap": "27010"
  },
  {
    "key": "987",
    "ITA": "BADIA POLESINE-COLOMBANO",
    "state_code": "RO",
    "cab": "631507",
    "cap": "45021"
  },
  {
    "key": "988",
    "ITA": "BADIA POLESINE-VILLA D'ADIGE",
    "state_code": "RO",
    "cab": "631507",
    "cap": "45021"
  },
  {
    "key": "989",
    "ITA": "BADIA POLESINE-SALVATERRA",
    "state_code": "RO",
    "cab": "631507",
    "cap": "45021"
  },
  {
    "key": "990",
    "ITA": "BADIA POLESINE",
    "state_code": "RO",
    "cab": "631507",
    "cap": "45021"
  },
  {
    "key": "991",
    "ITA": "BADIA TEDALDA-FRESCIANO",
    "state_code": "AR",
    "cab": "713206",
    "cap": "52030"
  },
  {
    "key": "992",
    "ITA": "BADIA TEDALDA-CA' RAFFAELLO",
    "state_code": "AR",
    "cab": "713206",
    "cap": "52030"
  },
  {
    "key": "993",
    "ITA": "BADIA TEDALDA",
    "state_code": "AR",
    "cab": "713206",
    "cap": "52032"
  },
  {
    "key": "994",
    "ITA": "BADOLATO-BADOLATO MARINA",
    "state_code": "CZ",
    "cab": "887000",
    "cap": "88060"
  },
  {
    "key": "995",
    "ITA": "BADOLATO",
    "state_code": "CZ",
    "cab": "887000",
    "cap": "88060"
  },
  {
    "key": "996",
    "ITA": "BAGALADI",
    "state_code": "RC",
    "cab": "959940",
    "cap": "89060"
  },
  {
    "key": "997",
    "ITA": "BAGHERIA",
    "state_code": "PA",
    "cab": "430702",
    "cap": "90011"
  },
  {
    "key": "998",
    "ITA": "BAGHERIA-ASPRA",
    "state_code": "PA",
    "cab": "430702",
    "cap": "90011"
  },
  {
    "key": "999",
    "ITA": "BAGNACAVALLO-ABBATESSE",
    "state_code": "RA",
    "cab": "674903",
    "cap": "48012"
  },
  {
    "key": "1000",
    "ITA": "BAGNACAVALLO-BONCELLINO",
    "state_code": "RA",
    "cab": "674903",
    "cap": "48012"
  },
  {
    "key": "1001",
    "ITA": "BAGNACAVALLO-GLORIE",
    "state_code": "RA",
    "cab": "674903",
    "cap": "48012"
  },
  {
    "key": "1002",
    "ITA": "BAGNACAVALLO-TRAVERSARA",
    "state_code": "RA",
    "cab": "674903",
    "cap": "48012"
  },
  {
    "key": "1003",
    "ITA": "BAGNACAVALLO-VILLANOVA",
    "state_code": "RA",
    "cab": "674903",
    "cap": "48012"
  },
  {
    "key": "1004",
    "ITA": "BAGNACAVALLO",
    "state_code": "RA",
    "cab": "674903",
    "cap": "48012"
  },
  {
    "key": "1005",
    "ITA": "BAGNACAVALLO-MASIERA",
    "state_code": "RA",
    "cab": "674903",
    "cap": "48012"
  },
  {
    "key": "1006",
    "ITA": "BAGNARA CALABRA-PORELLI DI BAGNARA",
    "state_code": "RC",
    "cab": "812800",
    "cap": "89011"
  },
  {
    "key": "1007",
    "ITA": "BAGNARA CALABRA-MARINELLA",
    "state_code": "RC",
    "cab": "812800",
    "cap": "89011"
  },
  {
    "key": "1008",
    "ITA": "BAGNARA CALABRA-CERAMIDA",
    "state_code": "RC",
    "cab": "812800",
    "cap": "89011"
  },
  {
    "key": "1009",
    "ITA": "BAGNARA CALABRA-SOLANO INFERIORE",
    "state_code": "RC",
    "cab": "812800",
    "cap": "89011"
  },
  {
    "key": "1010",
    "ITA": "BAGNARA CALABRA",
    "state_code": "RC",
    "cab": "812800",
    "cap": "89011"
  },
  {
    "key": "1011",
    "ITA": "BAGNARA CALABRA-PELLEGRINA",
    "state_code": "RC",
    "cab": "812800",
    "cap": "89011"
  },
  {
    "key": "1012",
    "ITA": "BAGNARA DI ROMAGNA",
    "state_code": "RA",
    "cab": "675009",
    "cap": "48010"
  },
  {
    "key": "1013",
    "ITA": "BAGNARIA",
    "state_code": "PV",
    "cab": "930149",
    "cap": "27050"
  },
  {
    "key": "1014",
    "ITA": "BAGNARIA ARSA-CASTIONS DELLE MURA",
    "state_code": "UD",
    "cab": "884007",
    "cap": "33050"
  },
  {
    "key": "1015",
    "ITA": "BAGNARIA ARSA-SEVEGLIANO",
    "state_code": "UD",
    "cab": "884007",
    "cap": "33050"
  },
  {
    "key": "1016",
    "ITA": "BAGNARIA ARSA",
    "state_code": "UD",
    "cab": "884007",
    "cap": "33050"
  },
  {
    "key": "1017",
    "ITA": "BAGNASCO",
    "state_code": "CN",
    "cab": "459404",
    "cap": "12071"
  },
  {
    "key": "1018",
    "ITA": "BAGNATICA",
    "state_code": "BG",
    "cab": "538702",
    "cap": "24060"
  },
  {
    "key": "1019",
    "ITA": "BAGNI DI LUCCA-PONTE A SERRAGLIO",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1020",
    "ITA": "BAGNI DI LUCCA-PIEVE DI CONTRONE",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1021",
    "ITA": "BAGNI DI LUCCA-SCESTA",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1022",
    "ITA": "BAGNI DI LUCCA-SAN CASSIANO",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1023",
    "ITA": "BAGNI DI LUCCA-MONTEFEGATESI",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1024",
    "ITA": "BAGNI DI LUCCA-LUCCHIO",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1025",
    "ITA": "BAGNI DI LUCCA-ISOLA",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1026",
    "ITA": "BAGNI DI LUCCA-FORNOLI",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1027",
    "ITA": "BAGNI DI LUCCA-CASABASCIANA",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1028",
    "ITA": "BAGNI DI LUCCA-BENABBIO",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1029",
    "ITA": "BAGNI DI LUCCA-BAGNI DI LUCCA VILLA",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1030",
    "ITA": "BAGNI DI LUCCA-BAGNI DI LUCCA PONTE",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1031",
    "ITA": "BAGNI DI LUCCA",
    "state_code": "LU",
    "cab": "700906",
    "cap": "55022"
  },
  {
    "key": "1032",
    "ITA": "BAGNO A RIPOLI-VALLINA",
    "state_code": "FI",
    "cab": "377200",
    "cap": "50012"
  },
  {
    "key": "1033",
    "ITA": "BAGNO A RIPOLI-ANTELLA",
    "state_code": "FI",
    "cab": "377200",
    "cap": "50012"
  },
  {
    "key": "1034",
    "ITA": "BAGNO A RIPOLI-CANDELI",
    "state_code": "FI",
    "cab": "377200",
    "cap": "50012"
  },
  {
    "key": "1035",
    "ITA": "BAGNO A RIPOLI-GRASSINA PONTE A EMA",
    "state_code": "FI",
    "cab": "377200",
    "cap": "50012"
  },
  {
    "key": "1036",
    "ITA": "BAGNO A RIPOLI-OSTERIA NUOVA",
    "state_code": "FI",
    "cab": "377200",
    "cap": "50012"
  },
  {
    "key": "1037",
    "ITA": "BAGNO A RIPOLI",
    "state_code": "FI",
    "cab": "377200",
    "cap": "50012"
  },
  {
    "key": "1038",
    "ITA": "BAGNO A RIPOLI-RIMAGGIO",
    "state_code": "FI",
    "cab": "377200",
    "cap": "50012"
  },
  {
    "key": "1039",
    "ITA": "BAGNO DI ROMAGNA-MONTE GUIDI",
    "state_code": "FC",
    "cab": "677005",
    "cap": "47018"
  },
  {
    "key": "1040",
    "ITA": "BAGNO DI ROMAGNA",
    "state_code": "FC",
    "cab": "677005",
    "cap": "47021"
  },
  {
    "key": "1041",
    "ITA": "BAGNO DI ROMAGNA-SAN PIERO IN BAGNO",
    "state_code": "FC",
    "cab": "677005",
    "cap": "47021"
  },
  {
    "key": "1042",
    "ITA": "BAGNO DI ROMAGNA-SELVAPIANA",
    "state_code": "FC",
    "cab": "677005",
    "cap": "47021"
  },
  {
    "key": "1043",
    "ITA": "BAGNO DI ROMAGNA-VESSA",
    "state_code": "FC",
    "cab": "677005",
    "cap": "47021"
  },
  {
    "key": "1044",
    "ITA": "BAGNOLI DEL TRIGNO",
    "state_code": "IS",
    "cab": "780809",
    "cap": "86091"
  },
  {
    "key": "1045",
    "ITA": "BAGNOLI DI SOPRA-LE CASETTE",
    "state_code": "PD",
    "cab": "623702",
    "cap": "35023"
  },
  {
    "key": "1046",
    "ITA": "BAGNOLI DI SOPRA-SAN SIRO",
    "state_code": "PD",
    "cab": "623702",
    "cap": "35023"
  },
  {
    "key": "1047",
    "ITA": "BAGNOLI DI SOPRA",
    "state_code": "PD",
    "cab": "623702",
    "cap": "35023"
  },
  {
    "key": "1048",
    "ITA": "BAGNOLI IRPINO-VILLAGGIO LACENO",
    "state_code": "AV",
    "cab": "756700",
    "cap": "83043"
  },
  {
    "key": "1049",
    "ITA": "BAGNOLI IRPINO",
    "state_code": "AV",
    "cab": "756700",
    "cap": "83043"
  },
  {
    "key": "1050",
    "ITA": "BAGNOLO CREMASCO",
    "state_code": "CR",
    "cab": "566505",
    "cap": "26010"
  },
  {
    "key": "1051",
    "ITA": "BAGNOLO DEL SALENTO",
    "state_code": "LE",
    "cab": "957605",
    "cap": "73020"
  },
  {
    "key": "1052",
    "ITA": "BAGNOLO DI PO",
    "state_code": "RO",
    "cab": "631606",
    "cap": "45022"
  },
  {
    "key": "1053",
    "ITA": "BAGNOLO IN PIANO",
    "state_code": "RE",
    "cab": "661405",
    "cap": "42011"
  },
  {
    "key": "1054",
    "ITA": "BAGNOLO MELLA",
    "state_code": "BS",
    "cab": "540401",
    "cap": "25021"
  },
  {
    "key": "1055",
    "ITA": "BAGNOLO PIEMONTE",
    "state_code": "CN",
    "cab": "459503",
    "cap": "12031"
  },
  {
    "key": "1056",
    "ITA": "BAGNOLO SAN VITO-SAN BIAGIO",
    "state_code": "MN",
    "cab": "574509",
    "cap": "46031"
  },
  {
    "key": "1057",
    "ITA": "BAGNOLO SAN VITO-SAN NICOLO' PO",
    "state_code": "MN",
    "cab": "574509",
    "cap": "46031"
  },
  {
    "key": "1058",
    "ITA": "BAGNOLO SAN VITO",
    "state_code": "MN",
    "cab": "574509",
    "cap": "46031"
  },
  {
    "key": "1059",
    "ITA": "BAGNONE",
    "state_code": "MS",
    "cab": "698704",
    "cap": "54021"
  },
  {
    "key": "1060",
    "ITA": "BAGNONE-TRESCHIETTO",
    "state_code": "MS",
    "cab": "698704",
    "cap": "54021"
  },
  {
    "key": "1061",
    "ITA": "BAGNONE-GABBIANA",
    "state_code": "MS",
    "cab": "698704",
    "cap": "54021"
  },
  {
    "key": "1062",
    "ITA": "BAGNONE-CORLAGA",
    "state_code": "MS",
    "cab": "698704",
    "cap": "54021"
  },
  {
    "key": "1063",
    "ITA": "BAGNOREGIO",
    "state_code": "VT",
    "cab": "728709",
    "cap": "1022"
  },
  {
    "key": "1064",
    "ITA": "BAGNOREGIO-VETRIOLO",
    "state_code": "VT",
    "cab": "728709",
    "cap": "1022"
  },
  {
    "key": "1065",
    "ITA": "BAGNOREGIO-CIVITA",
    "state_code": "VT",
    "cab": "728709",
    "cap": "1022"
  },
  {
    "key": "1066",
    "ITA": "BAGNOREGIO-CASTEL CELLESI",
    "state_code": "VT",
    "cab": "728709",
    "cap": "1022"
  },
  {
    "key": "1067",
    "ITA": "BAGOLINO-PONTE CAFFARO",
    "state_code": "BS",
    "cab": "540500",
    "cap": "25070"
  },
  {
    "key": "1068",
    "ITA": "BAGOLINO",
    "state_code": "BS",
    "cab": "540500",
    "cap": "25072"
  },
  {
    "key": "1069",
    "ITA": "BAIA E LATINA",
    "state_code": "CE",
    "cab": "949115",
    "cap": "81010"
  },
  {
    "key": "1070",
    "ITA": "BAIA E LATINA-LATINA DI BAIA",
    "state_code": "CE",
    "cab": "949115",
    "cap": "81010"
  },
  {
    "key": "1071",
    "ITA": "BAIANO",
    "state_code": "AV",
    "cab": "756809",
    "cap": "83022"
  },
  {
    "key": "1072",
    "ITA": "BAIRO",
    "state_code": "TO",
    "cab": "283010",
    "cap": "10010"
  },
  {
    "key": "1073",
    "ITA": "BAISO-CASINO LEVIZZANO",
    "state_code": "RE",
    "cab": "661504",
    "cap": "42031"
  },
  {
    "key": "1074",
    "ITA": "BAISO",
    "state_code": "RE",
    "cab": "661504",
    "cap": "42031"
  },
  {
    "key": "1075",
    "ITA": "BAJARDO",
    "state_code": "IM",
    "cab": "922260",
    "cap": "18031"
  },
  {
    "key": "1076",
    "ITA": "BALANGERO",
    "state_code": "TO",
    "cab": "313601",
    "cap": "10070"
  },
  {
    "key": "1077",
    "ITA": "BALDICHIERI D'ASTI",
    "state_code": "AT",
    "cab": "472407",
    "cap": "14011"
  },
  {
    "key": "1078",
    "ITA": "BALDISSERO CANAVESE",
    "state_code": "TO",
    "cab": "283044",
    "cap": "10080"
  },
  {
    "key": "1079",
    "ITA": "BALDISSERO D'ALBA",
    "state_code": "CN",
    "cab": "459602",
    "cap": "12040"
  },
  {
    "key": "1080",
    "ITA": "BALDISSERO TORINESE",
    "state_code": "TO",
    "cab": "459206",
    "cap": "10020"
  },
  {
    "key": "1081",
    "ITA": "BALDISSERO TORINESE-RIVODORA",
    "state_code": "TO",
    "cab": "459206",
    "cap": "10099"
  },
  {
    "key": "1082",
    "ITA": "BALESTRATE-FOCE",
    "state_code": "PA",
    "cab": "430801",
    "cap": "90041"
  },
  {
    "key": "1083",
    "ITA": "BALESTRATE",
    "state_code": "PA",
    "cab": "430801",
    "cap": "90041"
  },
  {
    "key": "1084",
    "ITA": "BALESTRINO",
    "state_code": "SV",
    "cab": "923011",
    "cap": "17020"
  },
  {
    "key": "1085",
    "ITA": "BALLABIO",
    "state_code": "LC",
    "cab": "509109",
    "cap": "23811"
  },
  {
    "key": "1086",
    "ITA": "BALLAO",
    "state_code": "CA",
    "cab": "441402",
    "cap": "9040"
  },
  {
    "key": "1087",
    "ITA": "BALME",
    "state_code": "TO",
    "cab": "283077",
    "cap": "10070"
  },
  {
    "key": "1088",
    "ITA": "BALMUCCIA",
    "state_code": "VC",
    "cab": "915629",
    "cap": "13020"
  },
  {
    "key": "1089",
    "ITA": "BALOCCO",
    "state_code": "VC",
    "cab": "915637",
    "cap": "13040"
  },
  {
    "key": "1090",
    "ITA": "BALSORANO-BALSORANO NUOVO",
    "state_code": "AQ",
    "cab": "404509",
    "cap": "67052"
  },
  {
    "key": "1091",
    "ITA": "BALSORANO",
    "state_code": "AQ",
    "cab": "404509",
    "cap": "67052"
  },
  {
    "key": "1092",
    "ITA": "BALSORANO-RIDOTTI",
    "state_code": "AQ",
    "cab": "404509",
    "cap": "67052"
  },
  {
    "key": "1093",
    "ITA": "BALVANO",
    "state_code": "PZ",
    "cab": "424101",
    "cap": "85050"
  },
  {
    "key": "1094",
    "ITA": "BALZOLA",
    "state_code": "AL",
    "cab": "479709",
    "cap": "15031"
  },
  {
    "key": "1095",
    "ITA": "BANARI",
    "state_code": "SS",
    "cab": "871806",
    "cap": "7040"
  },
  {
    "key": "1096",
    "ITA": "BANCHETTE",
    "state_code": "TO",
    "cab": "312504",
    "cap": "10010"
  },
  {
    "key": "1097",
    "ITA": "BANNIO ANZINO",
    "state_code": "VB",
    "cab": "451500",
    "cap": "28871"
  },
  {
    "key": "1098",
    "ITA": "BANZI",
    "state_code": "PZ",
    "cab": "423608",
    "cap": "85010"
  },
  {
    "key": "1099",
    "ITA": "BAONE-RIVADOLMO",
    "state_code": "PD",
    "cab": "893800",
    "cap": "35030"
  },
  {
    "key": "1100",
    "ITA": "BAONE",
    "state_code": "PD",
    "cab": "893800",
    "cap": "35030"
  },
  {
    "key": "1101",
    "ITA": "BARADILI",
    "state_code": "OR",
    "cab": "966689",
    "cap": "9090"
  },
  {
    "key": "1102",
    "ITA": "BARAGIANO",
    "state_code": "PZ",
    "cab": "418707",
    "cap": "85050"
  },
  {
    "key": "1103",
    "ITA": "BARAGIANO-SCALO DI BARAGIANO",
    "state_code": "PZ",
    "cab": "418707",
    "cap": "85050"
  },
  {
    "key": "1104",
    "ITA": "BARANELLO",
    "state_code": "CB",
    "cab": "396002",
    "cap": "86011"
  },
  {
    "key": "1105",
    "ITA": "BARANO D'ISCHIA-BUONOPANE",
    "state_code": "NA",
    "cab": "397406",
    "cap": "80070"
  },
  {
    "key": "1106",
    "ITA": "BARANO D'ISCHIA",
    "state_code": "NA",
    "cab": "397406",
    "cap": "80070"
  },
  {
    "key": "1107",
    "ITA": "BARANO D'ISCHIA-TESTACCIO D'ISCHIA",
    "state_code": "NA",
    "cab": "397406",
    "cap": "80070"
  },
  {
    "key": "1108",
    "ITA": "BARANZATE",
    "state_code": "MI",
    "cab": "852202",
    "cap": "20021"
  },
  {
    "key": "1109",
    "ITA": "BARASSO",
    "state_code": "VA",
    "cab": "820001",
    "cap": "21020"
  },
  {
    "key": "1110",
    "ITA": "BARATILI SAN PIETRO",
    "state_code": "OR",
    "cab": "877704",
    "cap": "9070"
  },
  {
    "key": "1111",
    "ITA": "BARBANIA",
    "state_code": "TO",
    "cab": "300707",
    "cap": "10070"
  },
  {
    "key": "1112",
    "ITA": "BARBARA",
    "state_code": "AN",
    "cab": "372409",
    "cap": "60010"
  },
  {
    "key": "1113",
    "ITA": "BARBARANO ROMANO",
    "state_code": "VT",
    "cab": "728808",
    "cap": "1010"
  },
  {
    "key": "1114",
    "ITA": "BARBARANO VICENTINO-PONTE DI BARBARANO",
    "state_code": "VI",
    "cab": "601500",
    "cap": "36021"
  },
  {
    "key": "1115",
    "ITA": "BARBARANO VICENTINO",
    "state_code": "VI",
    "cab": "601500",
    "cap": "36021"
  },
  {
    "key": "1116",
    "ITA": "BARBARESCO",
    "state_code": "CN",
    "cab": "376905",
    "cap": "12050"
  },
  {
    "key": "1117",
    "ITA": "BARBARIGA",
    "state_code": "BS",
    "cab": "540609",
    "cap": "25030"
  },
  {
    "key": "1118",
    "ITA": "BARBATA",
    "state_code": "BG",
    "cab": "811901",
    "cap": "24040"
  },
  {
    "key": "1119",
    "ITA": "BARBERINO DI MUGELLO",
    "state_code": "FI",
    "cab": "377309",
    "cap": "50031"
  },
  {
    "key": "1120",
    "ITA": "BARBERINO DI MUGELLO-CAFAGGIOLO",
    "state_code": "FI",
    "cab": "377309",
    "cap": "50031"
  },
  {
    "key": "1121",
    "ITA": "BARBERINO DI MUGELLO-CAVALLINA",
    "state_code": "FI",
    "cab": "377309",
    "cap": "50031"
  },
  {
    "key": "1122",
    "ITA": "BARBERINO DI MUGELLO-GALLIANO",
    "state_code": "FI",
    "cab": "377309",
    "cap": "50031"
  },
  {
    "key": "1123",
    "ITA": "BARBERINO DI MUGELLO-MONTECARELLI",
    "state_code": "FI",
    "cab": "377309",
    "cap": "50031"
  },
  {
    "key": "1124",
    "ITA": "BARBERINO VAL D'ELSA-MARCIALLA",
    "state_code": "FI",
    "cab": "377408",
    "cap": "50021"
  },
  {
    "key": "1125",
    "ITA": "BARBERINO VAL D'ELSA-VICO D'ELSA",
    "state_code": "FI",
    "cab": "377408",
    "cap": "50021"
  },
  {
    "key": "1126",
    "ITA": "BARBERINO VAL D'ELSA",
    "state_code": "FI",
    "cab": "377408",
    "cap": "50021"
  },
  {
    "key": "1127",
    "ITA": "BARBIANELLO",
    "state_code": "PV",
    "cab": "930156",
    "cap": "27041"
  },
  {
    "key": "1128",
    "ITA": "BARBIANO",
    "state_code": "BZ",
    "cab": "581900",
    "cap": "39040"
  },
  {
    "key": "1129",
    "ITA": "BARBONA",
    "state_code": "PD",
    "cab": "935734",
    "cap": "35040"
  },
  {
    "key": "1130",
    "ITA": "BARCELLONA POZZO DI GOTTO-ACQUAFICARA",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1131",
    "ITA": "BARCELLONA POZZO DI GOTTO",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1132",
    "ITA": "BARCELLONA POZZO DI GOTTO-SANT'ANTONINO CONVENTO",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1133",
    "ITA": "BARCELLONA POZZO DI GOTTO-CALDERA'",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1134",
    "ITA": "BARCELLONA POZZO DI GOTTO-SANT'ANTONIO",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1135",
    "ITA": "BARCELLONA POZZO DI GOTTO-CANNISTRA'",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1136",
    "ITA": "BARCELLONA POZZO DI GOTTO-LA GALA",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1137",
    "ITA": "BARCELLONA POZZO DI GOTTO-SAN PAOLO",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1138",
    "ITA": "BARCELLONA POZZO DI GOTTO-POZZO DI GOTTO",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1139",
    "ITA": "BARCELLONA POZZO DI GOTTO-PORTO SALVO",
    "state_code": "ME",
    "cab": "820704",
    "cap": "98051"
  },
  {
    "key": "1140",
    "ITA": "BARCHI",
    "state_code": "PU",
    "cab": "682203",
    "cap": "61040"
  },
  {
    "key": "1141",
    "ITA": "BARCIS",
    "state_code": "PN",
    "cab": "938134",
    "cap": "33080"
  },
  {
    "key": "1142",
    "ITA": "BARD",
    "state_code": "AO",
    "cab": "902254",
    "cap": "11020"
  },
  {
    "key": "1143",
    "ITA": "BARDELLO",
    "state_code": "VA",
    "cab": "924142",
    "cap": "21020"
  },
  {
    "key": "1144",
    "ITA": "BARDI-SANTA GIUSTINA",
    "state_code": "PR",
    "cab": "656207",
    "cap": "43032"
  },
  {
    "key": "1145",
    "ITA": "BARDI-GRAVAGO",
    "state_code": "PR",
    "cab": "656207",
    "cap": "43032"
  },
  {
    "key": "1146",
    "ITA": "BARDI-PIONE",
    "state_code": "PR",
    "cab": "656207",
    "cap": "43032"
  },
  {
    "key": "1147",
    "ITA": "BARDI",
    "state_code": "PR",
    "cab": "656207",
    "cap": "43032"
  },
  {
    "key": "1148",
    "ITA": "BARDINETO",
    "state_code": "SV",
    "cab": "493007",
    "cap": "17057"
  },
  {
    "key": "1149",
    "ITA": "BARDOLINO-CISANO",
    "state_code": "VR",
    "cab": "592600",
    "cap": "37011"
  },
  {
    "key": "1150",
    "ITA": "BARDOLINO-CALMASINO",
    "state_code": "VR",
    "cab": "592600",
    "cap": "37011"
  },
  {
    "key": "1151",
    "ITA": "BARDOLINO",
    "state_code": "VR",
    "cab": "592600",
    "cap": "37011"
  },
  {
    "key": "1152",
    "ITA": "BARDONECCHIA",
    "state_code": "TO",
    "cab": "300806",
    "cap": "10052"
  },
  {
    "key": "1153",
    "ITA": "BARDONECCHIA-BORGONOVO BARDONECCHIA",
    "state_code": "TO",
    "cab": "300806",
    "cap": "10052"
  },
  {
    "key": "1154",
    "ITA": "BAREGGIO",
    "state_code": "MI",
    "cab": "324707",
    "cap": "20010"
  },
  {
    "key": "1155",
    "ITA": "BARENGO",
    "state_code": "NO",
    "cab": "674200",
    "cap": "28010"
  },
  {
    "key": "1156",
    "ITA": "BARESSA",
    "state_code": "OR",
    "cab": "877803",
    "cap": "9090"
  },
  {
    "key": "1157",
    "ITA": "BARETE",
    "state_code": "AQ",
    "cab": "909440",
    "cap": "67010"
  },
  {
    "key": "1158",
    "ITA": "BARGA-FORNACI DI BARGA",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1159",
    "ITA": "BARGA-MOLOGNO",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1160",
    "ITA": "BARGA",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1161",
    "ITA": "BARGA-CASTELVECCHIO PASCOLI",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1162",
    "ITA": "BARGA-FILECCHIO",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1163",
    "ITA": "BARGA-TIGLIO",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1164",
    "ITA": "BARGA-SOMMOCOLONIA",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1165",
    "ITA": "BARGA-PONTE ALL'ANIA",
    "state_code": "LU",
    "cab": "701003",
    "cap": "55051"
  },
  {
    "key": "1166",
    "ITA": "BARGAGLI",
    "state_code": "GE",
    "cab": "318402",
    "cap": "16021"
  },
  {
    "key": "1167",
    "ITA": "BARGE",
    "state_code": "CN",
    "cab": "459701",
    "cap": "12032"
  },
  {
    "key": "1168",
    "ITA": "BARGE-GALLEANE",
    "state_code": "CN",
    "cab": "459701",
    "cap": "12032"
  },
  {
    "key": "1169",
    "ITA": "BARGHE",
    "state_code": "BS",
    "cab": "555706",
    "cap": "25070"
  },
  {
    "key": "1170",
    "ITA": "BARI-PALESE",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70128"
  },
  {
    "key": "1171",
    "ITA": "BARI-SANTO SPIRITO",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70127"
  },
  {
    "key": "1172",
    "ITA": "BARI-TORRE A MARE",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70126"
  },
  {
    "key": "1173",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70121"
  },
  {
    "key": "1174",
    "ITA": "BARI-JAPIGIA",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70126"
  },
  {
    "key": "1175",
    "ITA": "BARI-LOSETO",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70129"
  },
  {
    "key": "1176",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70122"
  },
  {
    "key": "1177",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70123"
  },
  {
    "key": "1178",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70124"
  },
  {
    "key": "1179",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70125"
  },
  {
    "key": "1180",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70126"
  },
  {
    "key": "1181",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70127"
  },
  {
    "key": "1182",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70128"
  },
  {
    "key": "1183",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70129"
  },
  {
    "key": "1184",
    "ITA": "BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70131"
  },
  {
    "key": "1185",
    "ITA": "BARI-CARBONARA DI BARI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70131"
  },
  {
    "key": "1186",
    "ITA": "BARI-CARRASSI",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70125"
  },
  {
    "key": "1187",
    "ITA": "BARI-CEGLIE DEL CAMPO",
    "state_code": "BA",
    "cab": "40006",
    "cap": "70129"
  },
  {
    "key": "1188",
    "ITA": "BARI SARDO",
    "state_code": "OG",
    "cab": "864801",
    "cap": "8042"
  },
  {
    "key": "1189",
    "ITA": "BARIANO",
    "state_code": "BG",
    "cab": "525907",
    "cap": "24050"
  },
  {
    "key": "1190",
    "ITA": "BARICELLA-BOSCHI",
    "state_code": "BO",
    "cab": "366005",
    "cap": "40052"
  },
  {
    "key": "1191",
    "ITA": "BARICELLA-SAN GABRIELE",
    "state_code": "BO",
    "cab": "366005",
    "cap": "40052"
  },
  {
    "key": "1192",
    "ITA": "BARICELLA-MONDONUOVO",
    "state_code": "BO",
    "cab": "366005",
    "cap": "40052"
  },
  {
    "key": "1193",
    "ITA": "BARICELLA",
    "state_code": "BO",
    "cab": "366005",
    "cap": "40052"
  },
  {
    "key": "1194",
    "ITA": "BARILE",
    "state_code": "PZ",
    "cab": "418806",
    "cap": "85022"
  },
  {
    "key": "1195",
    "ITA": "BARISCIANO",
    "state_code": "AQ",
    "cab": "408807",
    "cap": "67021"
  },
  {
    "key": "1196",
    "ITA": "BARISCIANO-PICENZE",
    "state_code": "AQ",
    "cab": "408807",
    "cap": "67021"
  },
  {
    "key": "1197",
    "ITA": "BARLASSINA",
    "state_code": "MB",
    "cab": "324806",
    "cap": "20030"
  },
  {
    "key": "1198",
    "ITA": "BARLETTA",
    "state_code": "BT",
    "cab": "413500",
    "cap": "70051"
  },
  {
    "key": "1199",
    "ITA": "BARNI",
    "state_code": "CO",
    "cab": "925131",
    "cap": "22030"
  },
  {
    "key": "1200",
    "ITA": "BAROLO",
    "state_code": "CN",
    "cab": "459800",
    "cap": "12060"
  },
  {
    "key": "1201",
    "ITA": "BARONE CANAVESE",
    "state_code": "TO",
    "cab": "190157",
    "cap": "10010"
  },
  {
    "key": "1202",
    "ITA": "BARONISSI-SARAGNANO",
    "state_code": "SA",
    "cab": "760801",
    "cap": "84081"
  },
  {
    "key": "1203",
    "ITA": "BARONISSI-SAVA",
    "state_code": "SA",
    "cab": "760801",
    "cap": "84081"
  },
  {
    "key": "1204",
    "ITA": "BARONISSI-CAPRECANO",
    "state_code": "SA",
    "cab": "760801",
    "cap": "84081"
  },
  {
    "key": "1205",
    "ITA": "BARONISSI-ANTESSANO",
    "state_code": "SA",
    "cab": "760801",
    "cap": "84081"
  },
  {
    "key": "1206",
    "ITA": "BARONISSI-ACQUAMELA",
    "state_code": "SA",
    "cab": "760801",
    "cap": "84081"
  },
  {
    "key": "1207",
    "ITA": "BARONISSI-FUSARA",
    "state_code": "SA",
    "cab": "760801",
    "cap": "84081"
  },
  {
    "key": "1208",
    "ITA": "BARONISSI",
    "state_code": "SA",
    "cab": "760801",
    "cap": "84081"
  },
  {
    "key": "1209",
    "ITA": "BARRAFRANCA",
    "state_code": "EN",
    "cab": "836007",
    "cap": "94012"
  },
  {
    "key": "1210",
    "ITA": "BARRALI",
    "state_code": "CA",
    "cab": "441501",
    "cap": "9040"
  },
  {
    "key": "1211",
    "ITA": "BARREA",
    "state_code": "AQ",
    "cab": "408708",
    "cap": "67030"
  },
  {
    "key": "1212",
    "ITA": "BARUMINI",
    "state_code": "SU",
    "cab": "441600",
    "cap": "9021"
  },
  {
    "key": "1213",
    "ITA": "BARZAGO",
    "state_code": "LC",
    "cab": "892604",
    "cap": "23890"
  },
  {
    "key": "1214",
    "ITA": "BARZANA",
    "state_code": "BG",
    "cab": "265819",
    "cap": "24030"
  },
  {
    "key": "1215",
    "ITA": "BARZANO'",
    "state_code": "LC",
    "cab": "509208",
    "cap": "23891"
  },
  {
    "key": "1216",
    "ITA": "BARZIO",
    "state_code": "LC",
    "cab": "509307",
    "cap": "23816"
  },
  {
    "key": "1217",
    "ITA": "BASALUZZO",
    "state_code": "AL",
    "cab": "479808",
    "cap": "15060"
  },
  {
    "key": "1218",
    "ITA": "BASCAPE'",
    "state_code": "PV",
    "cab": "556308",
    "cap": "27010"
  },
  {
    "key": "1219",
    "ITA": "BASCHI",
    "state_code": "TR",
    "cab": "725606",
    "cap": "5023"
  },
  {
    "key": "1220",
    "ITA": "BASCHI-MORRE",
    "state_code": "TR",
    "cab": "725606",
    "cap": "5020"
  },
  {
    "key": "1221",
    "ITA": "BASCHI-CIVITELLA DEL LAGO",
    "state_code": "TR",
    "cab": "725606",
    "cap": "5023"
  },
  {
    "key": "1222",
    "ITA": "BASCHI-ACQUALORETO",
    "state_code": "TR",
    "cab": "725606",
    "cap": "5023"
  },
  {
    "key": "1223",
    "ITA": "BASCIANO",
    "state_code": "TE",
    "cab": "771105",
    "cap": "64030"
  },
  {
    "key": "1224",
    "ITA": "BASELGA DI PINE'-SAN MAURO",
    "state_code": "TN",
    "cab": "343301",
    "cap": "38042"
  },
  {
    "key": "1225",
    "ITA": "BASELGA DI PINE'-MONTAGNAGA",
    "state_code": "TN",
    "cab": "343301",
    "cap": "38042"
  },
  {
    "key": "1226",
    "ITA": "BASELGA DI PINE'-FAIDA",
    "state_code": "TN",
    "cab": "343301",
    "cap": "38042"
  },
  {
    "key": "1227",
    "ITA": "BASELGA DI PINE'-CAMPOLONGO",
    "state_code": "TN",
    "cab": "343301",
    "cap": "38042"
  },
  {
    "key": "1228",
    "ITA": "BASELGA DI PINE'",
    "state_code": "TN",
    "cab": "343301",
    "cap": "38042"
  },
  {
    "key": "1229",
    "ITA": "BASELGA DI PINE'-RIZZOLAGA",
    "state_code": "TN",
    "cab": "343301",
    "cap": "38042"
  },
  {
    "key": "1230",
    "ITA": "BASELICE",
    "state_code": "BN",
    "cab": "756106",
    "cap": "82020"
  },
  {
    "key": "1231",
    "ITA": "BASIANO",
    "state_code": "MI",
    "cab": "341909",
    "cap": "20060"
  },
  {
    "key": "1232",
    "ITA": "BASICO'",
    "state_code": "ME",
    "cab": "961243",
    "cap": "98060"
  },
  {
    "key": "1233",
    "ITA": "BASIGLIO",
    "state_code": "MI",
    "cab": "342105",
    "cap": "20080"
  },
  {
    "key": "1234",
    "ITA": "BASILIANO-BLESSANO",
    "state_code": "UD",
    "cab": "636605",
    "cap": "33031"
  },
  {
    "key": "1235",
    "ITA": "BASILIANO-ORGNANO",
    "state_code": "UD",
    "cab": "636605",
    "cap": "33031"
  },
  {
    "key": "1236",
    "ITA": "BASILIANO-VARIANO",
    "state_code": "UD",
    "cab": "636605",
    "cap": "33031"
  },
  {
    "key": "1237",
    "ITA": "BASILIANO-BASAGLIAPENTA",
    "state_code": "UD",
    "cab": "636605",
    "cap": "33031"
  },
  {
    "key": "1238",
    "ITA": "BASILIANO-VILLAORBA",
    "state_code": "UD",
    "cab": "636605",
    "cap": "33031"
  },
  {
    "key": "1239",
    "ITA": "BASILIANO-VISSANDONE",
    "state_code": "UD",
    "cab": "636605",
    "cap": "33031"
  },
  {
    "key": "1240",
    "ITA": "BASILIANO",
    "state_code": "UD",
    "cab": "636605",
    "cap": "33031"
  },
  {
    "key": "1241",
    "ITA": "BASSANO BRESCIANO",
    "state_code": "BS",
    "cab": "540708",
    "cap": "25020"
  },
  {
    "key": "1242",
    "ITA": "BASSANO DEL GRAPPA-CAMPESE",
    "state_code": "VI",
    "cab": "601609",
    "cap": "36061"
  },
  {
    "key": "1243",
    "ITA": "BASSANO DEL GRAPPA",
    "state_code": "VI",
    "cab": "601609",
    "cap": "36061"
  },
  {
    "key": "1244",
    "ITA": "BASSANO DEL GRAPPA-VALROVINA",
    "state_code": "VI",
    "cab": "601609",
    "cap": "36061"
  },
  {
    "key": "1245",
    "ITA": "BASSANO IN TEVERINA",
    "state_code": "VT",
    "cab": "734202",
    "cap": "1030"
  },
  {
    "key": "1246",
    "ITA": "BASSANO ROMANO",
    "state_code": "VT",
    "cab": "728907",
    "cap": "1030"
  },
  {
    "key": "1247",
    "ITA": "BASSIANO",
    "state_code": "LT",
    "cab": "739300",
    "cap": "4010"
  },
  {
    "key": "1248",
    "ITA": "BASSIGNANA-FIONDI",
    "state_code": "AL",
    "cab": "479907",
    "cap": "15042"
  },
  {
    "key": "1249",
    "ITA": "BASSIGNANA",
    "state_code": "AL",
    "cab": "479907",
    "cap": "15042"
  },
  {
    "key": "1250",
    "ITA": "BASSIGNANA-MUGARONE",
    "state_code": "AL",
    "cab": "479907",
    "cap": "15042"
  },
  {
    "key": "1251",
    "ITA": "BASTIA-COSTANO",
    "state_code": "PG",
    "cab": "382804",
    "cap": "6083"
  },
  {
    "key": "1252",
    "ITA": "BASTIA-OSPEDALICCHIO",
    "state_code": "PG",
    "cab": "382804",
    "cap": "6083"
  },
  {
    "key": "1253",
    "ITA": "BASTIA",
    "state_code": "PG",
    "cab": "382804",
    "cap": "6083"
  },
  {
    "key": "1254",
    "ITA": "BASTIA MONDOVI'",
    "state_code": "CN",
    "cab": "450908",
    "cap": "12060"
  },
  {
    "key": "1255",
    "ITA": "BASTIDA DE' DOSSI",
    "state_code": "PV",
    "cab": "930164",
    "cap": "27050"
  },
  {
    "key": "1256",
    "ITA": "BASTIDA PANCARANA",
    "state_code": "PV",
    "cab": "930172",
    "cap": "27050"
  },
  {
    "key": "1257",
    "ITA": "BASTIGLIA",
    "state_code": "MO",
    "cab": "666503",
    "cap": "41030"
  },
  {
    "key": "1258",
    "ITA": "BATTAGLIA TERME",
    "state_code": "PD",
    "cab": "623801",
    "cap": "35041"
  },
  {
    "key": "1259",
    "ITA": "BATTIFOLLO",
    "state_code": "CN",
    "cab": "918284",
    "cap": "12070"
  },
  {
    "key": "1260",
    "ITA": "BATTIPAGLIA",
    "state_code": "SA",
    "cab": "760900",
    "cap": "84091"
  },
  {
    "key": "1261",
    "ITA": "BATTIPAGLIA-BELVEDERE DI BATTIPAGLIA",
    "state_code": "SA",
    "cab": "760900",
    "cap": "84091"
  },
  {
    "key": "1262",
    "ITA": "BATTIPAGLIA-SANTA LUCIA DI BATTIPAGLIA",
    "state_code": "SA",
    "cab": "760900",
    "cap": "84091"
  },
  {
    "key": "1263",
    "ITA": "BATTIPAGLIA-SANT'ANNA",
    "state_code": "SA",
    "cab": "760900",
    "cap": "84091"
  },
  {
    "key": "1264",
    "ITA": "BATTUDA",
    "state_code": "PV",
    "cab": "930180",
    "cap": "27020"
  },
  {
    "key": "1265",
    "ITA": "BAUCINA",
    "state_code": "PA",
    "cab": "430900",
    "cap": "90020"
  },
  {
    "key": "1266",
    "ITA": "BAULADU",
    "state_code": "OR",
    "cab": "877902",
    "cap": "9070"
  },
  {
    "key": "1267",
    "ITA": "BAUNEI",
    "state_code": "OG",
    "cab": "864900",
    "cap": "8040"
  },
  {
    "key": "1268",
    "ITA": "BAUNEI-SANTA MARIA NAVARRESE",
    "state_code": "OG",
    "cab": "864900",
    "cap": "8040"
  },
  {
    "key": "1269",
    "ITA": "BAVENO",
    "state_code": "VB",
    "cab": "451609",
    "cap": "28831"
  },
  {
    "key": "1270",
    "ITA": "BAVENO-FERIOLO",
    "state_code": "VB",
    "cab": "451609",
    "cap": "28831"
  },
  {
    "key": "1271",
    "ITA": "BAZZANO",
    "state_code": "BO",
    "cab": "366104",
    "cap": "40053"
  },
  {
    "key": "1272",
    "ITA": "BEDERO VALCUVIA",
    "state_code": "VA",
    "cab": "924159",
    "cap": "21039"
  },
  {
    "key": "1273",
    "ITA": "BEDIZZOLE",
    "state_code": "BS",
    "cab": "540807",
    "cap": "25081"
  },
  {
    "key": "1274",
    "ITA": "BEDIZZOLE-CAMPAGNOLA",
    "state_code": "BS",
    "cab": "540807",
    "cap": "25081"
  },
  {
    "key": "1275",
    "ITA": "BEDOLLO-REGNANA",
    "state_code": "TN",
    "cab": "343400",
    "cap": "38043"
  },
  {
    "key": "1276",
    "ITA": "BEDOLLO",
    "state_code": "TN",
    "cab": "343400",
    "cap": "38043"
  },
  {
    "key": "1277",
    "ITA": "BEDOLLO-BRUSAGO",
    "state_code": "TN",
    "cab": "343400",
    "cap": "38043"
  },
  {
    "key": "1278",
    "ITA": "BEDONIA-DRUSCO",
    "state_code": "PR",
    "cab": "656306",
    "cap": "43041"
  },
  {
    "key": "1279",
    "ITA": "BEDONIA-PONTESTRAMBO",
    "state_code": "PR",
    "cab": "656306",
    "cap": "43041"
  },
  {
    "key": "1280",
    "ITA": "BEDONIA-PONTECENO",
    "state_code": "PR",
    "cab": "656306",
    "cap": "43041"
  },
  {
    "key": "1281",
    "ITA": "BEDONIA-MOLINO DELL'ANZOLA",
    "state_code": "PR",
    "cab": "656306",
    "cap": "43041"
  },
  {
    "key": "1282",
    "ITA": "BEDONIA",
    "state_code": "PR",
    "cab": "656306",
    "cap": "43041"
  },
  {
    "key": "1283",
    "ITA": "BEDONIA-MASANTI DI SOTTO",
    "state_code": "PR",
    "cab": "656306",
    "cap": "43041"
  },
  {
    "key": "1284",
    "ITA": "BEDULITA",
    "state_code": "BG",
    "cab": "927798",
    "cap": "24030"
  },
  {
    "key": "1285",
    "ITA": "BEE",
    "state_code": "VB",
    "cab": "916973",
    "cap": "28813"
  },
  {
    "key": "1286",
    "ITA": "BEINASCO-BORGARETTO",
    "state_code": "TO",
    "cab": "300905",
    "cap": "10092"
  },
  {
    "key": "1287",
    "ITA": "BEINASCO",
    "state_code": "TO",
    "cab": "300905",
    "cap": "10092"
  },
  {
    "key": "1288",
    "ITA": "BEINETTE",
    "state_code": "CN",
    "cab": "459909",
    "cap": "12081"
  },
  {
    "key": "1289",
    "ITA": "BELCASTRO",
    "state_code": "CZ",
    "cab": "912675",
    "cap": "88050"
  },
  {
    "key": "1290",
    "ITA": "BELFIORE",
    "state_code": "VR",
    "cab": "592709",
    "cap": "37050"
  },
  {
    "key": "1291",
    "ITA": "BELFORTE ALL'ISAURO",
    "state_code": "PU",
    "cab": "782508",
    "cap": "61026"
  },
  {
    "key": "1292",
    "ITA": "BELFORTE DEL CHIENTI",
    "state_code": "MC",
    "cab": "692509",
    "cap": "62020"
  },
  {
    "key": "1293",
    "ITA": "BELFORTE MONFERRATO",
    "state_code": "AL",
    "cab": "920868",
    "cap": "15070"
  },
  {
    "key": "1294",
    "ITA": "BELGIOIOSO",
    "state_code": "PV",
    "cab": "556407",
    "cap": "27011"
  },
  {
    "key": "1295",
    "ITA": "BELGIRATE",
    "state_code": "VB",
    "cab": "916981",
    "cap": "28832"
  },
  {
    "key": "1296",
    "ITA": "BELLA",
    "state_code": "PZ",
    "cab": "418905",
    "cap": "85051"
  },
  {
    "key": "1297",
    "ITA": "BELLA-SANT'ANTONIO CASALINI",
    "state_code": "PZ",
    "cab": "418905",
    "cap": "85051"
  },
  {
    "key": "1298",
    "ITA": "BELLA-SAN CATALDO",
    "state_code": "PZ",
    "cab": "418905",
    "cap": "85021"
  },
  {
    "key": "1299",
    "ITA": "BELLAGIO-SAN GIOVANNI DI BELLAGIO",
    "state_code": "CO",
    "cab": "509406",
    "cap": "22021"
  },
  {
    "key": "1300",
    "ITA": "BELLAGIO",
    "state_code": "CO",
    "cab": "509406",
    "cap": "22021"
  },
  {
    "key": "1301",
    "ITA": "BELLANO",
    "state_code": "LC",
    "cab": "509505",
    "cap": "23822"
  },
  {
    "key": "1302",
    "ITA": "BELLANTE",
    "state_code": "TE",
    "cab": "767608",
    "cap": "64020"
  },
  {
    "key": "1303",
    "ITA": "BELLANTE-BELLANTE STAZIONE",
    "state_code": "TE",
    "cab": "767608",
    "cap": "64020"
  },
  {
    "key": "1304",
    "ITA": "BELLANTE-RIPATTONI",
    "state_code": "TE",
    "cab": "767608",
    "cap": "64020"
  },
  {
    "key": "1305",
    "ITA": "BELLARIA IGEA MARINA",
    "state_code": "RN",
    "cab": "677104",
    "cap": "47814"
  },
  {
    "key": "1306",
    "ITA": "BELLARIA IGEA MARINA-IGEA MARINA",
    "state_code": "RN",
    "cab": "677104",
    "cap": "47814"
  },
  {
    "key": "1307",
    "ITA": "BELLARIA IGEA MARINA-BELLARIA",
    "state_code": "RN",
    "cab": "677104",
    "cap": "47814"
  },
  {
    "key": "1308",
    "ITA": "BELLEGRA",
    "state_code": "RM",
    "cab": "389304",
    "cap": "30"
  },
  {
    "key": "1309",
    "ITA": "BELLINO",
    "state_code": "CN",
    "cab": "918292",
    "cap": "12020"
  },
  {
    "key": "1310",
    "ITA": "BELLINZAGO LOMBARDO-VILLA FORNACI",
    "state_code": "MI",
    "cab": "341305",
    "cap": "20060"
  },
  {
    "key": "1311",
    "ITA": "BELLINZAGO LOMBARDO",
    "state_code": "MI",
    "cab": "341305",
    "cap": "20060"
  },
  {
    "key": "1312",
    "ITA": "BELLINZAGO NOVARESE",
    "state_code": "NO",
    "cab": "451708",
    "cap": "28043"
  },
  {
    "key": "1313",
    "ITA": "BELLIZZI",
    "state_code": "SA",
    "cab": "893909",
    "cap": "84092"
  },
  {
    "key": "1314",
    "ITA": "BELLIZZI-BIVIO",
    "state_code": "SA",
    "cab": "893909",
    "cap": "84092"
  },
  {
    "key": "1315",
    "ITA": "BELLONA",
    "state_code": "CE",
    "cab": "751701",
    "cap": "81041"
  },
  {
    "key": "1316",
    "ITA": "BELLOSGUARDO",
    "state_code": "SA",
    "cab": "952267",
    "cap": "84020"
  },
  {
    "key": "1317",
    "ITA": "BELLUNO",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1318",
    "ITA": "BELLUNO-ANTOLE",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1319",
    "ITA": "BELLUNO-VISOME",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1320",
    "ITA": "BELLUNO-BES",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1321",
    "ITA": "BELLUNO-BOLZANO DI BELLUNO",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1322",
    "ITA": "BELLUNO-CALEIPO",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1323",
    "ITA": "BELLUNO-CASTION",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1324",
    "ITA": "BELLUNO-FIAMMOI",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1325",
    "ITA": "BELLUNO-LEVEGO",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1326",
    "ITA": "BELLUNO-SAFFORZE",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1327",
    "ITA": "BELLUNO-SALCE",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1328",
    "ITA": "BELLUNO-SOIS",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1329",
    "ITA": "BELLUNO-SOSSAI",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1330",
    "ITA": "BELLUNO-TISOI",
    "state_code": "BL",
    "cab": "119008",
    "cap": "32100"
  },
  {
    "key": "1331",
    "ITA": "BELLUSCO",
    "state_code": "MB",
    "cab": "324905",
    "cap": "20040"
  },
  {
    "key": "1332",
    "ITA": "BELMONTE CALABRO",
    "state_code": "CS",
    "cab": "178194",
    "cap": "87033"
  },
  {
    "key": "1333",
    "ITA": "BELMONTE CALABRO-BELMONTE CALABRO MARINA",
    "state_code": "CS",
    "cab": "178194",
    "cap": "87033"
  },
  {
    "key": "1334",
    "ITA": "BELMONTE CALABRO-VADI",
    "state_code": "CS",
    "cab": "178194",
    "cap": "87033"
  },
  {
    "key": "1335",
    "ITA": "BELMONTE CASTELLO",
    "state_code": "FR",
    "cab": "185728",
    "cap": "3040"
  },
  {
    "key": "1336",
    "ITA": "BELMONTE DEL SANNIO",
    "state_code": "IS",
    "cab": "186387",
    "cap": "86080"
  },
  {
    "key": "1337",
    "ITA": "BELMONTE IN SABINA",
    "state_code": "RI",
    "cab": "193102",
    "cap": "2020"
  },
  {
    "key": "1338",
    "ITA": "BELMONTE MEZZAGNO",
    "state_code": "PA",
    "cab": "431007",
    "cap": "90031"
  },
  {
    "key": "1339",
    "ITA": "BELMONTE PICENO",
    "state_code": "FM",
    "cab": "198481",
    "cap": "63029"
  },
  {
    "key": "1340",
    "ITA": "BELPASSO",
    "state_code": "CT",
    "cab": "838706",
    "cap": "95032"
  },
  {
    "key": "1341",
    "ITA": "BELPASSO-BORRELLO DI CATANIA",
    "state_code": "CT",
    "cab": "838706",
    "cap": "95032"
  },
  {
    "key": "1342",
    "ITA": "BELPASSO-PALAZZOLO",
    "state_code": "CT",
    "cab": "838706",
    "cap": "95032"
  },
  {
    "key": "1343",
    "ITA": "BELPASSO-PIANO TAVOLA",
    "state_code": "CT",
    "cab": "838706",
    "cap": "95032"
  },
  {
    "key": "1344",
    "ITA": "BELPASSO-VILLAGGIO LE GINESTRE",
    "state_code": "CT",
    "cab": "838706",
    "cap": "95032"
  },
  {
    "key": "1345",
    "ITA": "BELPASSO-VILLAGGIO DEL PINO",
    "state_code": "CT",
    "cab": "838706",
    "cap": "95032"
  },
  {
    "key": "1346",
    "ITA": "BELSITO",
    "state_code": "CS",
    "cab": "958595",
    "cap": "87030"
  },
  {
    "key": "1347",
    "ITA": "BELVEDERE DI SPINELLO",
    "state_code": "KR",
    "cab": "424606",
    "cap": "88824"
  },
  {
    "key": "1348",
    "ITA": "BELVEDERE LANGHE",
    "state_code": "CN",
    "cab": "918300",
    "cap": "12060"
  },
  {
    "key": "1349",
    "ITA": "BELVEDERE MARITTIMO-LAISE",
    "state_code": "CS",
    "cab": "806208",
    "cap": "87021"
  },
  {
    "key": "1350",
    "ITA": "BELVEDERE MARITTIMO-MARINA DI BELVEDERE MARITTIMO",
    "state_code": "CS",
    "cab": "806208",
    "cap": "87021"
  },
  {
    "key": "1351",
    "ITA": "BELVEDERE MARITTIMO",
    "state_code": "CS",
    "cab": "806208",
    "cap": "87021"
  },
  {
    "key": "1352",
    "ITA": "BELVEDERE OSTRENSE",
    "state_code": "AN",
    "cab": "372508",
    "cap": "60030"
  },
  {
    "key": "1353",
    "ITA": "BELVEGLIO",
    "state_code": "AT",
    "cab": "919936",
    "cap": "14040"
  },
  {
    "key": "1354",
    "ITA": "BELVI",
    "state_code": "NU",
    "cab": "865006",
    "cap": "8030"
  },
  {
    "key": "1355",
    "ITA": "BEMA",
    "state_code": "SO",
    "cab": "926931",
    "cap": "23010"
  },
  {
    "key": "1356",
    "ITA": "BENE LARIO",
    "state_code": "CO",
    "cab": "925156",
    "cap": "22010"
  },
  {
    "key": "1357",
    "ITA": "BENE VAGIENNA",
    "state_code": "CN",
    "cab": "460006",
    "cap": "12041"
  },
  {
    "key": "1358",
    "ITA": "BENE VAGIENNA-ISOLA",
    "state_code": "CN",
    "cab": "460006",
    "cap": "12041"
  },
  {
    "key": "1359",
    "ITA": "BENESTARE-BELLORO",
    "state_code": "RC",
    "cab": "816702",
    "cap": "89030"
  },
  {
    "key": "1360",
    "ITA": "BENESTARE",
    "state_code": "RC",
    "cab": "816702",
    "cap": "89030"
  },
  {
    "key": "1361",
    "ITA": "BENETUTTI",
    "state_code": "SS",
    "cab": "871905",
    "cap": "7010"
  },
  {
    "key": "1362",
    "ITA": "BENEVELLO",
    "state_code": "CN",
    "cab": "918318",
    "cap": "12050"
  },
  {
    "key": "1363",
    "ITA": "BENEVENTO",
    "state_code": "BN",
    "cab": "150003",
    "cap": "82100"
  },
  {
    "key": "1364",
    "ITA": "BENNA",
    "state_code": "BI",
    "cab": "915645",
    "cap": "13871"
  },
  {
    "key": "1365",
    "ITA": "BENTIVOGLIO",
    "state_code": "BO",
    "cab": "366203",
    "cap": "40010"
  },
  {
    "key": "1366",
    "ITA": "BENTIVOGLIO-INTERPORTO BENTIVOGLIO",
    "state_code": "BO",
    "cab": "366203",
    "cap": "40010"
  },
  {
    "key": "1367",
    "ITA": "BENTIVOGLIO-SAN MARINO",
    "state_code": "BO",
    "cab": "366203",
    "cap": "40010"
  },
  {
    "key": "1368",
    "ITA": "BENTIVOGLIO-SANTA MARIA IN DUNO",
    "state_code": "BO",
    "cab": "366203",
    "cap": "40010"
  },
  {
    "key": "1369",
    "ITA": "BERBENNO-PONTE GIURINO",
    "state_code": "BG",
    "cab": "526004",
    "cap": "24030"
  },
  {
    "key": "1370",
    "ITA": "BERBENNO",
    "state_code": "BG",
    "cab": "526004",
    "cap": "24030"
  },
  {
    "key": "1371",
    "ITA": "BERBENNO DI VALTELLINA",
    "state_code": "SO",
    "cab": "520809",
    "cap": "23010"
  },
  {
    "key": "1372",
    "ITA": "BERBENNO DI VALTELLINA-PEDEMONTE",
    "state_code": "SO",
    "cab": "520809",
    "cap": "23010"
  },
  {
    "key": "1373",
    "ITA": "BERBENNO DI VALTELLINA-SAN PIETRO DI BERBENNO",
    "state_code": "SO",
    "cab": "520809",
    "cap": "23010"
  },
  {
    "key": "1374",
    "ITA": "BERCETO-GHIARE",
    "state_code": "PR",
    "cab": "656405",
    "cap": "43042"
  },
  {
    "key": "1375",
    "ITA": "BERCETO-FUGAZZOLO",
    "state_code": "PR",
    "cab": "656405",
    "cap": "43042"
  },
  {
    "key": "1376",
    "ITA": "BERCETO-CASTELLONCHIO",
    "state_code": "PR",
    "cab": "656405",
    "cap": "43042"
  },
  {
    "key": "1377",
    "ITA": "BERCETO-CASASELVATICA",
    "state_code": "PR",
    "cab": "656405",
    "cap": "43042"
  },
  {
    "key": "1378",
    "ITA": "BERCETO",
    "state_code": "PR",
    "cab": "656405",
    "cap": "43042"
  },
  {
    "key": "1379",
    "ITA": "BERCETO-BERGOTTO",
    "state_code": "PR",
    "cab": "656405",
    "cap": "43042"
  },
  {
    "key": "1380",
    "ITA": "BERCHIDDA",
    "state_code": "OT",
    "cab": "872002",
    "cap": "7022"
  },
  {
    "key": "1381",
    "ITA": "BEREGAZZO CON FIGLIARO-FIGLIARO",
    "state_code": "CO",
    "cab": "787705",
    "cap": "22070"
  },
  {
    "key": "1382",
    "ITA": "BEREGAZZO CON FIGLIARO-BEREGAZZO",
    "state_code": "CO",
    "cab": "787705",
    "cap": "22070"
  },
  {
    "key": "1383",
    "ITA": "BEREGAZZO CON FIGLIARO",
    "state_code": "CO",
    "cab": "787705",
    "cap": "22070"
  },
  {
    "key": "1384",
    "ITA": "BEREGUARDO",
    "state_code": "PV",
    "cab": "556506",
    "cap": "27021"
  },
  {
    "key": "1385",
    "ITA": "BERGAMASCO",
    "state_code": "AL",
    "cab": "480004",
    "cap": "15022"
  },
  {
    "key": "1386",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24122"
  },
  {
    "key": "1387",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24129"
  },
  {
    "key": "1388",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24128"
  },
  {
    "key": "1389",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24127"
  },
  {
    "key": "1390",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24126"
  },
  {
    "key": "1391",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24121"
  },
  {
    "key": "1392",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24123"
  },
  {
    "key": "1393",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24124"
  },
  {
    "key": "1394",
    "ITA": "BERGAMO",
    "state_code": "BG",
    "cab": "111005",
    "cap": "24125"
  },
  {
    "key": "1395",
    "ITA": "BERGANTINO",
    "state_code": "RO",
    "cab": "631705",
    "cap": "45032"
  },
  {
    "key": "1396",
    "ITA": "BERGEGGI",
    "state_code": "SV",
    "cab": "923029",
    "cap": "17028"
  },
  {
    "key": "1397",
    "ITA": "BERGOLO",
    "state_code": "CN",
    "cab": "918326",
    "cap": "12074"
  },
  {
    "key": "1398",
    "ITA": "BERLINGO",
    "state_code": "BS",
    "cab": "676403",
    "cap": "25030"
  },
  {
    "key": "1399",
    "ITA": "BERNALDA",
    "state_code": "MT",
    "cab": "802900",
    "cap": "75012"
  },
  {
    "key": "1400",
    "ITA": "BERNALDA-METAPONTO",
    "state_code": "MT",
    "cab": "802900",
    "cap": "75012"
  },
  {
    "key": "1401",
    "ITA": "BERNALDA-SERRA MARINA",
    "state_code": "MT",
    "cab": "802900",
    "cap": "75012"
  },
  {
    "key": "1402",
    "ITA": "BERNALDA-METAPONTO LIDO",
    "state_code": "MT",
    "cab": "802900",
    "cap": "75012"
  },
  {
    "key": "1403",
    "ITA": "BERNAREGGIO",
    "state_code": "MB",
    "cab": "325001",
    "cap": "20044"
  },
  {
    "key": "1404",
    "ITA": "BERNAREGGIO-VILLANOVA",
    "state_code": "MB",
    "cab": "325001",
    "cap": "20044"
  },
  {
    "key": "1405",
    "ITA": "BERNATE TICINO-CASATE",
    "state_code": "MI",
    "cab": "325100",
    "cap": "20010"
  },
  {
    "key": "1406",
    "ITA": "BERNATE TICINO",
    "state_code": "MI",
    "cab": "325100",
    "cap": "20010"
  },
  {
    "key": "1407",
    "ITA": "BERNEZZO-SAN ROCCO DI BERNEZZO",
    "state_code": "CN",
    "cab": "470708",
    "cap": "12010"
  },
  {
    "key": "1408",
    "ITA": "BERNEZZO",
    "state_code": "CN",
    "cab": "470708",
    "cap": "12010"
  },
  {
    "key": "1409",
    "ITA": "BERRA-SERRAVALLE",
    "state_code": "FE",
    "cab": "671800",
    "cap": "44030"
  },
  {
    "key": "1410",
    "ITA": "BERRA-COLOGNA",
    "state_code": "FE",
    "cab": "671800",
    "cap": "44030"
  },
  {
    "key": "1411",
    "ITA": "BERRA",
    "state_code": "FE",
    "cab": "671800",
    "cap": "44033"
  },
  {
    "key": "1412",
    "ITA": "BERSONE",
    "state_code": "TN",
    "cab": "904615",
    "cap": "38085"
  },
  {
    "key": "1413",
    "ITA": "BERTINORO-PANIGHINA",
    "state_code": "FC",
    "cab": "677203",
    "cap": "47032"
  },
  {
    "key": "1414",
    "ITA": "BERTINORO",
    "state_code": "FC",
    "cab": "677203",
    "cap": "47032"
  },
  {
    "key": "1415",
    "ITA": "BERTINORO-CAPOCOLLE",
    "state_code": "FC",
    "cab": "677203",
    "cap": "47032"
  },
  {
    "key": "1416",
    "ITA": "BERTINORO-COLLINELLO",
    "state_code": "FC",
    "cab": "677203",
    "cap": "47032"
  },
  {
    "key": "1417",
    "ITA": "BERTINORO-FRATTA TERME",
    "state_code": "FC",
    "cab": "677203",
    "cap": "47032"
  },
  {
    "key": "1418",
    "ITA": "BERTINORO-POLENTA",
    "state_code": "FC",
    "cab": "677203",
    "cap": "47032"
  },
  {
    "key": "1419",
    "ITA": "BERTINORO-SANTA MARIA NUOVA",
    "state_code": "FC",
    "cab": "677203",
    "cap": "47032"
  },
  {
    "key": "1420",
    "ITA": "BERTIOLO",
    "state_code": "UD",
    "cab": "636704",
    "cap": "33032"
  },
  {
    "key": "1421",
    "ITA": "BERTIOLO-POZZECCO",
    "state_code": "UD",
    "cab": "636704",
    "cap": "33032"
  },
  {
    "key": "1422",
    "ITA": "BERTONICO",
    "state_code": "LO",
    "cab": "903658",
    "cap": "26821"
  },
  {
    "key": "1423",
    "ITA": "BERZANO DI SAN PIETRO",
    "state_code": "AT",
    "cab": "919944",
    "cap": "14020"
  },
  {
    "key": "1424",
    "ITA": "BERZANO DI TORTONA",
    "state_code": "AL",
    "cab": "920876",
    "cap": "15050"
  },
  {
    "key": "1425",
    "ITA": "BERZO DEMO-FORNO ALLIONE",
    "state_code": "BS",
    "cab": "771709",
    "cap": "25040"
  },
  {
    "key": "1426",
    "ITA": "BERZO DEMO",
    "state_code": "BS",
    "cab": "771709",
    "cap": "25040"
  },
  {
    "key": "1427",
    "ITA": "BERZO INFERIORE",
    "state_code": "BS",
    "cab": "554808",
    "cap": "25040"
  },
  {
    "key": "1428",
    "ITA": "BERZO SAN FERMO",
    "state_code": "BG",
    "cab": "927806",
    "cap": "24060"
  },
  {
    "key": "1429",
    "ITA": "BESANA IN BRIANZA-VILLA RAVERIO",
    "state_code": "MB",
    "cab": "325209",
    "cap": "20842"
  },
  {
    "key": "1430",
    "ITA": "BESANA IN BRIANZA-ZOCCORINO",
    "state_code": "MB",
    "cab": "325209",
    "cap": "20842"
  },
  {
    "key": "1431",
    "ITA": "BESANA IN BRIANZA",
    "state_code": "MB",
    "cab": "325209",
    "cap": "20842"
  },
  {
    "key": "1432",
    "ITA": "BESANO",
    "state_code": "VA",
    "cab": "671503",
    "cap": "21050"
  },
  {
    "key": "1433",
    "ITA": "BESATE",
    "state_code": "MI",
    "cab": "325308",
    "cap": "20080"
  },
  {
    "key": "1434",
    "ITA": "BESENELLO",
    "state_code": "TN",
    "cab": "343509",
    "cap": "38060"
  },
  {
    "key": "1435",
    "ITA": "BESENZONE",
    "state_code": "PC",
    "cab": "938605",
    "cap": "29010"
  },
  {
    "key": "1436",
    "ITA": "BESNATE",
    "state_code": "VA",
    "cab": "500108",
    "cap": "21010"
  },
  {
    "key": "1437",
    "ITA": "BESOZZO",
    "state_code": "VA",
    "cab": "500207",
    "cap": "21023"
  },
  {
    "key": "1438",
    "ITA": "BESSUDE",
    "state_code": "SS",
    "cab": "872101",
    "cap": "7040"
  },
  {
    "key": "1439",
    "ITA": "BETTOLA-SAN BERNARDINO",
    "state_code": "PC",
    "cab": "651802",
    "cap": "29021"
  },
  {
    "key": "1440",
    "ITA": "BETTOLA-GROPPODUCALE",
    "state_code": "PC",
    "cab": "651802",
    "cap": "29021"
  },
  {
    "key": "1441",
    "ITA": "BETTOLA-BRAMAIANO",
    "state_code": "PC",
    "cab": "651802",
    "cap": "29021"
  },
  {
    "key": "1442",
    "ITA": "BETTOLA",
    "state_code": "PC",
    "cab": "651802",
    "cap": "29021"
  },
  {
    "key": "1443",
    "ITA": "BETTOLA-SAN GIOVANNI",
    "state_code": "PC",
    "cab": "651802",
    "cap": "29021"
  },
  {
    "key": "1444",
    "ITA": "BETTONA-PASSAGGIO",
    "state_code": "PG",
    "cab": "382903",
    "cap": "6084"
  },
  {
    "key": "1445",
    "ITA": "BETTONA",
    "state_code": "PG",
    "cab": "382903",
    "cap": "6084"
  },
  {
    "key": "1446",
    "ITA": "BEURA CARDEZZA",
    "state_code": "VB",
    "cab": "916999",
    "cap": "28851"
  },
  {
    "key": "1447",
    "ITA": "BEURA CARDEZZA-CUZZEGO",
    "state_code": "VB",
    "cab": "916999",
    "cap": "28851"
  },
  {
    "key": "1448",
    "ITA": "BEURA CARDEZZA-BEURA",
    "state_code": "VB",
    "cab": "916999",
    "cap": "28851"
  },
  {
    "key": "1449",
    "ITA": "BEVAGNA",
    "state_code": "PG",
    "cab": "383000",
    "cap": "6031"
  },
  {
    "key": "1450",
    "ITA": "BEVAGNA-CANTALUPO",
    "state_code": "PG",
    "cab": "383000",
    "cap": "6031"
  },
  {
    "key": "1451",
    "ITA": "BEVERINO-PADIVARMA",
    "state_code": "SP",
    "cab": "496901",
    "cap": "19020"
  },
  {
    "key": "1452",
    "ITA": "BEVERINO",
    "state_code": "SP",
    "cab": "496901",
    "cap": "19020"
  },
  {
    "key": "1453",
    "ITA": "BEVERINO-CAVANELLA VARA",
    "state_code": "SP",
    "cab": "496901",
    "cap": "19020"
  },
  {
    "key": "1454",
    "ITA": "BEVILACQUA-MAREGA",
    "state_code": "VR",
    "cab": "600502",
    "cap": "37040"
  },
  {
    "key": "1455",
    "ITA": "BEVILACQUA",
    "state_code": "VR",
    "cab": "600502",
    "cap": "37040"
  },
  {
    "key": "1456",
    "ITA": "BEZZECCA",
    "state_code": "TN",
    "cab": "343608",
    "cap": "38060"
  },
  {
    "key": "1457",
    "ITA": "BIANCAVILLA",
    "state_code": "CT",
    "cab": "838805",
    "cap": "95033"
  },
  {
    "key": "1458",
    "ITA": "BIANCHI",
    "state_code": "CS",
    "cab": "958603",
    "cap": "87050"
  },
  {
    "key": "1459",
    "ITA": "BIANCO",
    "state_code": "RC",
    "cab": "812909",
    "cap": "89032"
  },
  {
    "key": "1460",
    "ITA": "BIANCO-PARDESCA",
    "state_code": "RC",
    "cab": "812909",
    "cap": "89032"
  },
  {
    "key": "1461",
    "ITA": "BIANDRATE",
    "state_code": "NO",
    "cab": "451807",
    "cap": "28061"
  },
  {
    "key": "1462",
    "ITA": "BIANDRONNO",
    "state_code": "VA",
    "cab": "500306",
    "cap": "21024"
  },
  {
    "key": "1463",
    "ITA": "BIANZANO",
    "state_code": "BG",
    "cab": "927814",
    "cap": "24060"
  },
  {
    "key": "1464",
    "ITA": "BIANZE'",
    "state_code": "VC",
    "cab": "442806",
    "cap": "13041"
  },
  {
    "key": "1465",
    "ITA": "BIANZONE",
    "state_code": "SO",
    "cab": "524108",
    "cap": "23030"
  },
  {
    "key": "1466",
    "ITA": "BIASSONO",
    "state_code": "MB",
    "cab": "325407",
    "cap": "20046"
  },
  {
    "key": "1467",
    "ITA": "BIBBIANO",
    "state_code": "RE",
    "cab": "661603",
    "cap": "42021"
  },
  {
    "key": "1468",
    "ITA": "BIBBIANO-BARCO",
    "state_code": "RE",
    "cab": "661603",
    "cap": "42021"
  },
  {
    "key": "1469",
    "ITA": "BIBBIENA-PARTINA",
    "state_code": "AR",
    "cab": "713305",
    "cap": "52010"
  },
  {
    "key": "1470",
    "ITA": "BIBBIENA-SERRAVALLE",
    "state_code": "AR",
    "cab": "713305",
    "cap": "52010"
  },
  {
    "key": "1471",
    "ITA": "BIBBIENA-SOCI",
    "state_code": "AR",
    "cab": "713305",
    "cap": "52010"
  },
  {
    "key": "1472",
    "ITA": "BIBBIENA-BIBBIENA STAZIONE",
    "state_code": "AR",
    "cab": "713305",
    "cap": "52011"
  },
  {
    "key": "1473",
    "ITA": "BIBBIENA-BANZENA",
    "state_code": "AR",
    "cab": "713305",
    "cap": "52011"
  },
  {
    "key": "1474",
    "ITA": "BIBBIENA",
    "state_code": "AR",
    "cab": "713305",
    "cap": "52011"
  },
  {
    "key": "1475",
    "ITA": "BIBBONA-LA CALIFORNIA",
    "state_code": "LI",
    "cab": "706309",
    "cap": "57020"
  },
  {
    "key": "1476",
    "ITA": "BIBBONA",
    "state_code": "LI",
    "cab": "706309",
    "cap": "57020"
  },
  {
    "key": "1477",
    "ITA": "BIBIANA",
    "state_code": "TO",
    "cab": "301002",
    "cap": "10060"
  },
  {
    "key": "1478",
    "ITA": "BICCARI",
    "state_code": "FG",
    "cab": "782904",
    "cap": "71032"
  },
  {
    "key": "1479",
    "ITA": "BICCARI-BERARDINONE",
    "state_code": "FG",
    "cab": "782904",
    "cap": "71032"
  },
  {
    "key": "1480",
    "ITA": "BICINICCO-FELETTIS",
    "state_code": "UD",
    "cab": "489500",
    "cap": "33050"
  },
  {
    "key": "1481",
    "ITA": "BICINICCO",
    "state_code": "UD",
    "cab": "489500",
    "cap": "33050"
  },
  {
    "key": "1482",
    "ITA": "BIDONI'",
    "state_code": "OR",
    "cab": "966721",
    "cap": "9080"
  },
  {
    "key": "1483",
    "ITA": "BIELLA-OROPA",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1484",
    "ITA": "BIELLA",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1485",
    "ITA": "BIELLA-BARAZZETTO",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1486",
    "ITA": "BIELLA-CHIAVAZZA",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1487",
    "ITA": "BIELLA-COSSILA",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1488",
    "ITA": "BIELLA-FAVARO",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1489",
    "ITA": "BIELLA-PAVIGNANO",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1490",
    "ITA": "BIELLA-VANDORNO",
    "state_code": "BI",
    "cab": "223008",
    "cap": "13900"
  },
  {
    "key": "1491",
    "ITA": "BIENNO",
    "state_code": "BS",
    "cab": "540906",
    "cap": "25040"
  },
  {
    "key": "1492",
    "ITA": "BIENO",
    "state_code": "TN",
    "cab": "889303",
    "cap": "38050"
  },
  {
    "key": "1493",
    "ITA": "BIENTINA-QUATTRO STRADE",
    "state_code": "PI",
    "cab": "708800",
    "cap": "56031"
  },
  {
    "key": "1494",
    "ITA": "BIENTINA",
    "state_code": "PI",
    "cab": "708800",
    "cap": "56031"
  },
  {
    "key": "1495",
    "ITA": "BIGARELLO",
    "state_code": "MN",
    "cab": "738609",
    "cap": "46030"
  },
  {
    "key": "1496",
    "ITA": "BIGARELLO-GAZZO",
    "state_code": "MN",
    "cab": "738609",
    "cap": "46030"
  },
  {
    "key": "1497",
    "ITA": "BIGARELLO-STRADELLA",
    "state_code": "MN",
    "cab": "738609",
    "cap": "46030"
  },
  {
    "key": "1498",
    "ITA": "BINAGO",
    "state_code": "CO",
    "cab": "509604",
    "cap": "22070"
  },
  {
    "key": "1499",
    "ITA": "BINASCO",
    "state_code": "MI",
    "cab": "325506",
    "cap": "20082"
  },
  {
    "key": "1500",
    "ITA": "BINETTO",
    "state_code": "BA",
    "cab": "418004",
    "cap": "70020"
  },
  {
    "key": "1501",
    "ITA": "BIOGLIO",
    "state_code": "BI",
    "cab": "915652",
    "cap": "13841"
  },
  {
    "key": "1502",
    "ITA": "BIONAZ",
    "state_code": "AO",
    "cab": "902262",
    "cap": "11010"
  },
  {
    "key": "1503",
    "ITA": "BIONE-SAN FAUSTINO",
    "state_code": "BS",
    "cab": "724906",
    "cap": "25070"
  },
  {
    "key": "1504",
    "ITA": "BIONE",
    "state_code": "BS",
    "cab": "724906",
    "cap": "25070"
  },
  {
    "key": "1505",
    "ITA": "BIRORI",
    "state_code": "NU",
    "cab": "965475",
    "cap": "8010"
  },
  {
    "key": "1506",
    "ITA": "BISACCIA",
    "state_code": "AV",
    "cab": "756908",
    "cap": "83044"
  },
  {
    "key": "1507",
    "ITA": "BISACCIA-PIANO REGOLATORE",
    "state_code": "AV",
    "cab": "756908",
    "cap": "83044"
  },
  {
    "key": "1508",
    "ITA": "BISACCIA-BISACCIA NUOVA",
    "state_code": "AV",
    "cab": "756908",
    "cap": "83044"
  },
  {
    "key": "1509",
    "ITA": "BISACQUINO",
    "state_code": "PA",
    "cab": "431106",
    "cap": "90032"
  },
  {
    "key": "1510",
    "ITA": "BISCEGLIE",
    "state_code": "BT",
    "cab": "413609",
    "cap": "70052"
  },
  {
    "key": "1511",
    "ITA": "BISEGNA-SAN SEBASTIANO",
    "state_code": "AQ",
    "cab": "909473",
    "cap": "67050"
  },
  {
    "key": "1512",
    "ITA": "BISEGNA",
    "state_code": "AQ",
    "cab": "909473",
    "cap": "67050"
  },
  {
    "key": "1513",
    "ITA": "BISENTI",
    "state_code": "TE",
    "cab": "767707",
    "cap": "64033"
  },
  {
    "key": "1514",
    "ITA": "BISIGNANO",
    "state_code": "CS",
    "cab": "806307",
    "cap": "87043"
  },
  {
    "key": "1515",
    "ITA": "BISTAGNO",
    "state_code": "AL",
    "cab": "480103",
    "cap": "15012"
  },
  {
    "key": "1516",
    "ITA": "BISUSCHIO",
    "state_code": "VA",
    "cab": "506709",
    "cap": "21050"
  },
  {
    "key": "1517",
    "ITA": "BITETTO",
    "state_code": "BA",
    "cab": "413708",
    "cap": "70020"
  },
  {
    "key": "1518",
    "ITA": "BITONTO-PALOMBAIO",
    "state_code": "BA",
    "cab": "413807",
    "cap": "70032"
  },
  {
    "key": "1519",
    "ITA": "BITONTO-MARIOTTO",
    "state_code": "BA",
    "cab": "413807",
    "cap": "70032"
  },
  {
    "key": "1520",
    "ITA": "BITONTO",
    "state_code": "BA",
    "cab": "413807",
    "cap": "70032"
  },
  {
    "key": "1521",
    "ITA": "BITRITTO",
    "state_code": "BA",
    "cab": "413906",
    "cap": "70020"
  },
  {
    "key": "1522",
    "ITA": "BITTI",
    "state_code": "NU",
    "cab": "852509",
    "cap": "8021"
  },
  {
    "key": "1523",
    "ITA": "BIVONA",
    "state_code": "AG",
    "cab": "828103",
    "cap": "92010"
  },
  {
    "key": "1524",
    "ITA": "BIVONGI",
    "state_code": "RC",
    "cab": "959965",
    "cap": "89040"
  },
  {
    "key": "1525",
    "ITA": "BIZZARONE",
    "state_code": "CO",
    "cab": "509703",
    "cap": "22020"
  },
  {
    "key": "1526",
    "ITA": "BLEGGIO INFERIORE-PONTE ARCHE",
    "state_code": "TN",
    "cab": "343707",
    "cap": "38071"
  },
  {
    "key": "1527",
    "ITA": "BLEGGIO INFERIORE",
    "state_code": "TN",
    "cab": "343707",
    "cap": "38071"
  },
  {
    "key": "1528",
    "ITA": "BLEGGIO SUPERIORE-LARIDO",
    "state_code": "TN",
    "cab": "343806",
    "cap": "38071"
  },
  {
    "key": "1529",
    "ITA": "BLEGGIO SUPERIORE-MARAZZONE",
    "state_code": "TN",
    "cab": "343806",
    "cap": "38071"
  },
  {
    "key": "1530",
    "ITA": "BLEGGIO SUPERIORE-BIVEDO",
    "state_code": "TN",
    "cab": "343806",
    "cap": "38071"
  },
  {
    "key": "1531",
    "ITA": "BLEGGIO SUPERIORE",
    "state_code": "TN",
    "cab": "343806",
    "cap": "38071"
  },
  {
    "key": "1532",
    "ITA": "BLELLO",
    "state_code": "BG",
    "cab": "927822",
    "cap": "24010"
  },
  {
    "key": "1533",
    "ITA": "BLERA-CIVITELLA CESI",
    "state_code": "VT",
    "cab": "729004",
    "cap": "1010"
  },
  {
    "key": "1534",
    "ITA": "BLERA",
    "state_code": "VT",
    "cab": "729004",
    "cap": "1010"
  },
  {
    "key": "1535",
    "ITA": "BLESSAGNO",
    "state_code": "CO",
    "cab": "925172",
    "cap": "22028"
  },
  {
    "key": "1536",
    "ITA": "BLEVIO",
    "state_code": "CO",
    "cab": "925180",
    "cap": "22020"
  },
  {
    "key": "1537",
    "ITA": "BLUFI",
    "state_code": "PA",
    "cab": "437905",
    "cap": "90020"
  },
  {
    "key": "1538",
    "ITA": "BOARA PISANI",
    "state_code": "PD",
    "cab": "882704",
    "cap": "35040"
  },
  {
    "key": "1539",
    "ITA": "BOBBIO-VACCAREZZA",
    "state_code": "PC",
    "cab": "651901",
    "cap": "29022"
  },
  {
    "key": "1540",
    "ITA": "BOBBIO-SANTA MARIA",
    "state_code": "PC",
    "cab": "651901",
    "cap": "29022"
  },
  {
    "key": "1541",
    "ITA": "BOBBIO-PASSO PENICE",
    "state_code": "PC",
    "cab": "651901",
    "cap": "29022"
  },
  {
    "key": "1542",
    "ITA": "BOBBIO-MEZZANO SCOTTI",
    "state_code": "PC",
    "cab": "651901",
    "cap": "29022"
  },
  {
    "key": "1543",
    "ITA": "BOBBIO-CECI",
    "state_code": "PC",
    "cab": "651901",
    "cap": "29022"
  },
  {
    "key": "1544",
    "ITA": "BOBBIO",
    "state_code": "PC",
    "cab": "651901",
    "cap": "29022"
  },
  {
    "key": "1545",
    "ITA": "BOBBIO-CASSOLO",
    "state_code": "PC",
    "cab": "651901",
    "cap": "29022"
  },
  {
    "key": "1546",
    "ITA": "BOBBIO PELLICE",
    "state_code": "TO",
    "cab": "580803",
    "cap": "10060"
  },
  {
    "key": "1547",
    "ITA": "BOCA",
    "state_code": "NO",
    "cab": "451906",
    "cap": "28010"
  },
  {
    "key": "1548",
    "ITA": "BOCCHIGLIERO",
    "state_code": "CS",
    "cab": "958611",
    "cap": "87060"
  },
  {
    "key": "1549",
    "ITA": "BOCCIOLETO-FERVENTO",
    "state_code": "VC",
    "cab": "442905",
    "cap": "13022"
  },
  {
    "key": "1550",
    "ITA": "BOCCIOLETO",
    "state_code": "VC",
    "cab": "442905",
    "cap": "13022"
  },
  {
    "key": "1551",
    "ITA": "BOCENAGO",
    "state_code": "TN",
    "cab": "904631",
    "cap": "38080"
  },
  {
    "key": "1552",
    "ITA": "BODIO LOMNAGO-LOMNAGO",
    "state_code": "VA",
    "cab": "702803",
    "cap": "21020"
  },
  {
    "key": "1553",
    "ITA": "BODIO LOMNAGO-BODIO",
    "state_code": "VA",
    "cab": "702803",
    "cap": "21020"
  },
  {
    "key": "1554",
    "ITA": "BODIO LOMNAGO",
    "state_code": "VA",
    "cab": "702803",
    "cap": "21020"
  },
  {
    "key": "1555",
    "ITA": "BOFFALORA D'ADDA",
    "state_code": "LO",
    "cab": "325605",
    "cap": "26811"
  },
  {
    "key": "1556",
    "ITA": "BOFFALORA SOPRA TICINO",
    "state_code": "MI",
    "cab": "325704",
    "cap": "20010"
  },
  {
    "key": "1557",
    "ITA": "BOGLIASCO-SAN BERNARDO",
    "state_code": "GE",
    "cab": "318501",
    "cap": "16031"
  },
  {
    "key": "1558",
    "ITA": "BOGLIASCO-POGGIO FAVARO",
    "state_code": "GE",
    "cab": "318501",
    "cap": "16031"
  },
  {
    "key": "1559",
    "ITA": "BOGLIASCO",
    "state_code": "GE",
    "cab": "318501",
    "cap": "16031"
  },
  {
    "key": "1560",
    "ITA": "BOGNANCO-FONTI",
    "state_code": "VB",
    "cab": "452003",
    "cap": "28842"
  },
  {
    "key": "1561",
    "ITA": "BOGNANCO",
    "state_code": "VB",
    "cab": "452003",
    "cap": "28842"
  },
  {
    "key": "1562",
    "ITA": "BOGOGNO",
    "state_code": "NO",
    "cab": "917005",
    "cap": "28010"
  },
  {
    "key": "1563",
    "ITA": "BOISSANO",
    "state_code": "SV",
    "cab": "835504",
    "cap": "17054"
  },
  {
    "key": "1564",
    "ITA": "BOJANO-CASTELLONE",
    "state_code": "CB",
    "cab": "409706",
    "cap": "86021"
  },
  {
    "key": "1565",
    "ITA": "BOJANO",
    "state_code": "CB",
    "cab": "409706",
    "cap": "86021"
  },
  {
    "key": "1566",
    "ITA": "BOJANO-MONTEVERDE",
    "state_code": "CB",
    "cab": "409706",
    "cap": "86021"
  },
  {
    "key": "1567",
    "ITA": "BOLANO",
    "state_code": "SP",
    "cab": "497008",
    "cap": "19020"
  },
  {
    "key": "1568",
    "ITA": "BOLANO-CARPENA",
    "state_code": "SP",
    "cab": "497008",
    "cap": "19020"
  },
  {
    "key": "1569",
    "ITA": "BOLANO-CEPARANA",
    "state_code": "SP",
    "cab": "497008",
    "cap": "19020"
  },
  {
    "key": "1570",
    "ITA": "BOLBENO",
    "state_code": "TN",
    "cab": "904649",
    "cap": "38079"
  },
  {
    "key": "1571",
    "ITA": "BOLGARE",
    "state_code": "BG",
    "cab": "538207",
    "cap": "24060"
  },
  {
    "key": "1572",
    "ITA": "BOLLATE",
    "state_code": "MI",
    "cab": "201004",
    "cap": "20021"
  },
  {
    "key": "1573",
    "ITA": "BOLLATE-CASSINA NUOVA",
    "state_code": "MI",
    "cab": "201004",
    "cap": "20021"
  },
  {
    "key": "1574",
    "ITA": "BOLLENGO",
    "state_code": "TO",
    "cab": "364307",
    "cap": "10012"
  },
  {
    "key": "1575",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40141"
  },
  {
    "key": "1576",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40139"
  },
  {
    "key": "1577",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40138"
  },
  {
    "key": "1578",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40137"
  },
  {
    "key": "1579",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40136"
  },
  {
    "key": "1580",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40135"
  },
  {
    "key": "1581",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40134"
  },
  {
    "key": "1582",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40133"
  },
  {
    "key": "1583",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40132"
  },
  {
    "key": "1584",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40131"
  },
  {
    "key": "1585",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40129"
  },
  {
    "key": "1586",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40128"
  },
  {
    "key": "1587",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40127"
  },
  {
    "key": "1588",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40125"
  },
  {
    "key": "1589",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40124"
  },
  {
    "key": "1590",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40123"
  },
  {
    "key": "1591",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40122"
  },
  {
    "key": "1592",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40121"
  },
  {
    "key": "1593",
    "ITA": "BOLOGNA",
    "state_code": "BO",
    "cab": "24000",
    "cap": "40126"
  },
  {
    "key": "1594",
    "ITA": "BOLOGNANO",
    "state_code": "PE",
    "cab": "954016",
    "cap": "65020"
  },
  {
    "key": "1595",
    "ITA": "BOLOGNANO-PIANO D'ORTA",
    "state_code": "PE",
    "cab": "954016",
    "cap": "65020"
  },
  {
    "key": "1596",
    "ITA": "BOLOGNANO-MUSELLARO",
    "state_code": "PE",
    "cab": "954016",
    "cap": "65020"
  },
  {
    "key": "1597",
    "ITA": "BOLOGNETTA",
    "state_code": "PA",
    "cab": "431205",
    "cap": "90030"
  },
  {
    "key": "1598",
    "ITA": "BOLOGNOLA",
    "state_code": "MC",
    "cab": "941922",
    "cap": "62035"
  },
  {
    "key": "1599",
    "ITA": "BOLOTANA",
    "state_code": "NU",
    "cab": "852608",
    "cap": "8011"
  },
  {
    "key": "1600",
    "ITA": "BOLSENA",
    "state_code": "VT",
    "cab": "729103",
    "cap": "1023"
  },
  {
    "key": "1601",
    "ITA": "BOLTIERE",
    "state_code": "BG",
    "cab": "538801",
    "cap": "24040"
  },
  {
    "key": "1602",
    "ITA": "BOLZANO",
    "state_code": "BZ",
    "cab": "116004",
    "cap": "39100"
  },
  {
    "key": "1603",
    "ITA": "BOLZANO-CASTELFIRMIANO",
    "state_code": "BZ",
    "cab": "116004",
    "cap": "39100"
  },
  {
    "key": "1604",
    "ITA": "BOLZANO NOVARESE",
    "state_code": "NO",
    "cab": "917013",
    "cap": "28010"
  },
  {
    "key": "1605",
    "ITA": "BOLZANO VICENTINO",
    "state_code": "VI",
    "cab": "601708",
    "cap": "36050"
  },
  {
    "key": "1606",
    "ITA": "BOLZANO VICENTINO-OSPEDALETTO",
    "state_code": "VI",
    "cab": "601708",
    "cap": "36050"
  },
  {
    "key": "1607",
    "ITA": "BOLZANO VICENTINO-LISIERA",
    "state_code": "VI",
    "cab": "601708",
    "cap": "36050"
  },
  {
    "key": "1608",
    "ITA": "BOMARZO-MUGNANO",
    "state_code": "VT",
    "cab": "729202",
    "cap": "1020"
  },
  {
    "key": "1609",
    "ITA": "BOMARZO",
    "state_code": "VT",
    "cab": "729202",
    "cap": "1020"
  },
  {
    "key": "1610",
    "ITA": "BOMBA",
    "state_code": "CH",
    "cab": "954529",
    "cap": "66042"
  },
  {
    "key": "1611",
    "ITA": "BOMPENSIERE",
    "state_code": "CL",
    "cab": "832907",
    "cap": "93010"
  },
  {
    "key": "1612",
    "ITA": "BOMPIETRO",
    "state_code": "PA",
    "cab": "431304",
    "cap": "90020"
  },
  {
    "key": "1613",
    "ITA": "BOMPIETRO-LOCATI",
    "state_code": "PA",
    "cab": "431304",
    "cap": "90020"
  },
  {
    "key": "1614",
    "ITA": "BOMPORTO-SOLARA",
    "state_code": "MO",
    "cab": "666602",
    "cap": "41030"
  },
  {
    "key": "1615",
    "ITA": "BOMPORTO-GORGHETTO",
    "state_code": "MO",
    "cab": "666602",
    "cap": "41030"
  },
  {
    "key": "1616",
    "ITA": "BOMPORTO",
    "state_code": "MO",
    "cab": "666602",
    "cap": "41030"
  },
  {
    "key": "1617",
    "ITA": "BOMPORTO-SORBARA",
    "state_code": "MO",
    "cab": "666602",
    "cap": "41030"
  },
  {
    "key": "1618",
    "ITA": "BONARCADO",
    "state_code": "OR",
    "cab": "878009",
    "cap": "9070"
  },
  {
    "key": "1619",
    "ITA": "BONASSOLA",
    "state_code": "SP",
    "cab": "497107",
    "cap": "19011"
  },
  {
    "key": "1620",
    "ITA": "BONATE SOPRA-GHIAIE",
    "state_code": "BG",
    "cab": "538900",
    "cap": "24040"
  },
  {
    "key": "1621",
    "ITA": "BONATE SOPRA",
    "state_code": "BG",
    "cab": "538900",
    "cap": "24040"
  },
  {
    "key": "1622",
    "ITA": "BONATE SOTTO",
    "state_code": "BG",
    "cab": "526103",
    "cap": "24040"
  },
  {
    "key": "1623",
    "ITA": "BONAVIGO",
    "state_code": "VR",
    "cab": "600601",
    "cap": "37040"
  },
  {
    "key": "1624",
    "ITA": "BONAVIGO-ORTI",
    "state_code": "VR",
    "cab": "600601",
    "cap": "37040"
  },
  {
    "key": "1625",
    "ITA": "BONDENO-STELLATA",
    "state_code": "FE",
    "cab": "671909",
    "cap": "44012"
  },
  {
    "key": "1626",
    "ITA": "BONDENO-OSPITALE",
    "state_code": "FE",
    "cab": "671909",
    "cap": "44012"
  },
  {
    "key": "1627",
    "ITA": "BONDENO-PILASTRI",
    "state_code": "FE",
    "cab": "671909",
    "cap": "44012"
  },
  {
    "key": "1628",
    "ITA": "BONDENO-SCORTICHINO",
    "state_code": "FE",
    "cab": "671909",
    "cap": "44012"
  },
  {
    "key": "1629",
    "ITA": "BONDENO-GAVELLO",
    "state_code": "FE",
    "cab": "671909",
    "cap": "44012"
  },
  {
    "key": "1630",
    "ITA": "BONDENO-BURANA",
    "state_code": "FE",
    "cab": "671909",
    "cap": "44012"
  },
  {
    "key": "1631",
    "ITA": "BONDENO",
    "state_code": "FE",
    "cab": "671909",
    "cap": "44012"
  },
  {
    "key": "1632",
    "ITA": "BONDO",
    "state_code": "TN",
    "cab": "904656",
    "cap": "38081"
  },
  {
    "key": "1633",
    "ITA": "BONDONE-BAITONI",
    "state_code": "TN",
    "cab": "343905",
    "cap": "38080"
  },
  {
    "key": "1634",
    "ITA": "BONDONE",
    "state_code": "TN",
    "cab": "343905",
    "cap": "38080"
  },
  {
    "key": "1635",
    "ITA": "BONEA",
    "state_code": "BN",
    "cab": "950147",
    "cap": "82013"
  },
  {
    "key": "1636",
    "ITA": "BONEFRO",
    "state_code": "CB",
    "cab": "409805",
    "cap": "86041"
  },
  {
    "key": "1637",
    "ITA": "BONEMERSE",
    "state_code": "CR",
    "cab": "703405",
    "cap": "26040"
  },
  {
    "key": "1638",
    "ITA": "BONIFATI",
    "state_code": "CS",
    "cab": "693309",
    "cap": "87020"
  },
  {
    "key": "1639",
    "ITA": "BONIFATI-CITTADELLA DEL CAPO",
    "state_code": "CS",
    "cab": "693309",
    "cap": "87020"
  },
  {
    "key": "1640",
    "ITA": "BONIFATI-TORREVECCHIA",
    "state_code": "CS",
    "cab": "693309",
    "cap": "87020"
  },
  {
    "key": "1641",
    "ITA": "BONITO-MORRONI",
    "state_code": "AV",
    "cab": "950949",
    "cap": "83032"
  },
  {
    "key": "1642",
    "ITA": "BONITO",
    "state_code": "AV",
    "cab": "950949",
    "cap": "83032"
  },
  {
    "key": "1643",
    "ITA": "BONNANARO",
    "state_code": "SS",
    "cab": "872200",
    "cap": "7043"
  },
  {
    "key": "1644",
    "ITA": "BONO",
    "state_code": "SS",
    "cab": "849109",
    "cap": "7011"
  },
  {
    "key": "1645",
    "ITA": "BONORVA",
    "state_code": "SS",
    "cab": "849208",
    "cap": "7012"
  },
  {
    "key": "1646",
    "ITA": "BONORVA-SANTA LUCIA",
    "state_code": "SS",
    "cab": "849208",
    "cap": "7012"
  },
  {
    "key": "1647",
    "ITA": "BONORVA-REBECCU",
    "state_code": "SS",
    "cab": "849208",
    "cap": "7012"
  },
  {
    "key": "1648",
    "ITA": "BONVICINO",
    "state_code": "CN",
    "cab": "918342",
    "cap": "12060"
  },
  {
    "key": "1649",
    "ITA": "BORBONA-VALLEMARE",
    "state_code": "RI",
    "cab": "734905",
    "cap": "2010"
  },
  {
    "key": "1650",
    "ITA": "BORBONA",
    "state_code": "RI",
    "cab": "734905",
    "cap": "2010"
  },
  {
    "key": "1651",
    "ITA": "BORCA DI CADORE",
    "state_code": "BL",
    "cab": "610303",
    "cap": "32040"
  },
  {
    "key": "1652",
    "ITA": "BORCA DI CADORE-VILLAGGIO TURISTICO DI BORCA DI CADORE",
    "state_code": "BL",
    "cab": "610303",
    "cap": "32040"
  },
  {
    "key": "1653",
    "ITA": "BORDANO",
    "state_code": "UD",
    "cab": "896100",
    "cap": "33010"
  },
  {
    "key": "1654",
    "ITA": "BORDIGHERA-PIANI DI BORGHETTO",
    "state_code": "IM",
    "cab": "489609",
    "cap": "18012"
  },
  {
    "key": "1655",
    "ITA": "BORDIGHERA-BORDIGHERA ALTA",
    "state_code": "IM",
    "cab": "489609",
    "cap": "18012"
  },
  {
    "key": "1656",
    "ITA": "BORDIGHERA",
    "state_code": "IM",
    "cab": "489609",
    "cap": "18012"
  },
  {
    "key": "1657",
    "ITA": "BORDIGHERA-BORGHETTO SAN NICOLO'",
    "state_code": "IM",
    "cab": "489609",
    "cap": "18012"
  },
  {
    "key": "1658",
    "ITA": "BORDOLANO",
    "state_code": "CR",
    "cab": "566604",
    "cap": "26020"
  },
  {
    "key": "1659",
    "ITA": "BORE",
    "state_code": "PR",
    "cab": "656504",
    "cap": "43030"
  },
  {
    "key": "1660",
    "ITA": "BORETTO",
    "state_code": "RE",
    "cab": "661702",
    "cap": "42022"
  },
  {
    "key": "1661",
    "ITA": "BORGARELLO",
    "state_code": "PV",
    "cab": "698308",
    "cap": "27010"
  },
  {
    "key": "1662",
    "ITA": "BORGARO TORINESE",
    "state_code": "TO",
    "cab": "301101",
    "cap": "10071"
  },
  {
    "key": "1663",
    "ITA": "BORGETTO",
    "state_code": "PA",
    "cab": "431403",
    "cap": "90042"
  },
  {
    "key": "1664",
    "ITA": "BORGHETTO D'ARROSCIA",
    "state_code": "IM",
    "cab": "922278",
    "cap": "18020"
  },
  {
    "key": "1665",
    "ITA": "BORGHETTO DI BORBERA",
    "state_code": "AL",
    "cab": "480202",
    "cap": "15060"
  },
  {
    "key": "1666",
    "ITA": "BORGHETTO DI BORBERA-PERSI",
    "state_code": "AL",
    "cab": "480202",
    "cap": "15060"
  },
  {
    "key": "1667",
    "ITA": "BORGHETTO DI BORBERA-TORRE DE' RATTI",
    "state_code": "AL",
    "cab": "480202",
    "cap": "15060"
  },
  {
    "key": "1668",
    "ITA": "BORGHETTO DI VARA",
    "state_code": "SP",
    "cab": "775106",
    "cap": "19020"
  },
  {
    "key": "1669",
    "ITA": "BORGHETTO LODIGIANO",
    "state_code": "LO",
    "cab": "325803",
    "cap": "26812"
  },
  {
    "key": "1670",
    "ITA": "BORGHETTO LODIGIANO-CASONI",
    "state_code": "LO",
    "cab": "325803",
    "cap": "26812"
  },
  {
    "key": "1671",
    "ITA": "BORGHETTO SANTO SPIRITO",
    "state_code": "SV",
    "cab": "493106",
    "cap": "17052"
  },
  {
    "key": "1672",
    "ITA": "BORGHI-SAN GIOVANNI IN GALILEA",
    "state_code": "FC",
    "cab": "677302",
    "cap": "47030"
  },
  {
    "key": "1673",
    "ITA": "BORGHI-LO STRADONE",
    "state_code": "FC",
    "cab": "677302",
    "cap": "47030"
  },
  {
    "key": "1674",
    "ITA": "BORGHI-SAN MARTINO IN CONVERSETO",
    "state_code": "FC",
    "cab": "677302",
    "cap": "47030"
  },
  {
    "key": "1675",
    "ITA": "BORGHI",
    "state_code": "FC",
    "cab": "677302",
    "cap": "47030"
  },
  {
    "key": "1676",
    "ITA": "BORGIA-ROCCELLETTA",
    "state_code": "CZ",
    "cab": "424705",
    "cap": "88021"
  },
  {
    "key": "1677",
    "ITA": "BORGIA",
    "state_code": "CZ",
    "cab": "424705",
    "cap": "88021"
  },
  {
    "key": "1678",
    "ITA": "BORGIALLO",
    "state_code": "TO",
    "cab": "283101",
    "cap": "10080"
  },
  {
    "key": "1679",
    "ITA": "BORGIO VEREZZI-BORGIO",
    "state_code": "SV",
    "cab": "493205",
    "cap": "17022"
  },
  {
    "key": "1680",
    "ITA": "BORGIO VEREZZI",
    "state_code": "SV",
    "cab": "493205",
    "cap": "17022"
  },
  {
    "key": "1681",
    "ITA": "BORGO A MOZZANO-ANCHIANO",
    "state_code": "LU",
    "cab": "701102",
    "cap": "55023"
  },
  {
    "key": "1682",
    "ITA": "BORGO A MOZZANO-CHIFENTI",
    "state_code": "LU",
    "cab": "701102",
    "cap": "55023"
  },
  {
    "key": "1683",
    "ITA": "BORGO A MOZZANO-CORSAGNA",
    "state_code": "LU",
    "cab": "701102",
    "cap": "55023"
  },
  {
    "key": "1684",
    "ITA": "BORGO A MOZZANO-DIECIMO",
    "state_code": "LU",
    "cab": "701102",
    "cap": "55023"
  },
  {
    "key": "1685",
    "ITA": "BORGO A MOZZANO-GIOVIANO",
    "state_code": "LU",
    "cab": "701102",
    "cap": "55023"
  },
  {
    "key": "1686",
    "ITA": "BORGO A MOZZANO-VALDOTTAVO",
    "state_code": "LU",
    "cab": "701102",
    "cap": "55023"
  },
  {
    "key": "1687",
    "ITA": "BORGO A MOZZANO",
    "state_code": "LU",
    "cab": "701102",
    "cap": "55023"
  },
  {
    "key": "1688",
    "ITA": "BORGO D'ALE",
    "state_code": "VC",
    "cab": "443002",
    "cap": "13040"
  },
  {
    "key": "1689",
    "ITA": "BORGO DI TERZO",
    "state_code": "BG",
    "cab": "526202",
    "cap": "24060"
  },
  {
    "key": "1690",
    "ITA": "BORGO MAGGIORE DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47893"
  },
  {
    "key": "1691",
    "ITA": "BORGO PACE-LAMOLI",
    "state_code": "PU",
    "cab": "660803",
    "cap": "61040"
  },
  {
    "key": "1692",
    "ITA": "BORGO PACE",
    "state_code": "PU",
    "cab": "660803",
    "cap": "61040"
  },
  {
    "key": "1693",
    "ITA": "BORGO PRIOLO",
    "state_code": "PV",
    "cab": "793802",
    "cap": "27040"
  },
  {
    "key": "1694",
    "ITA": "BORGO SAN DALMAZZO-MARTINETTO DEL RAME",
    "state_code": "CN",
    "cab": "460105",
    "cap": "12011"
  },
  {
    "key": "1695",
    "ITA": "BORGO SAN DALMAZZO-ARADOLO LA BRUNA",
    "state_code": "CN",
    "cab": "460105",
    "cap": "12011"
  },
  {
    "key": "1696",
    "ITA": "BORGO SAN DALMAZZO",
    "state_code": "CN",
    "cab": "460105",
    "cap": "12011"
  },
  {
    "key": "1697",
    "ITA": "BORGO SAN GIACOMO",
    "state_code": "BS",
    "cab": "541003",
    "cap": "25022"
  },
  {
    "key": "1698",
    "ITA": "BORGO SAN GIACOMO-PADERNELLO",
    "state_code": "BS",
    "cab": "541003",
    "cap": "25022"
  },
  {
    "key": "1699",
    "ITA": "BORGO SAN GIACOMO-FARFENGO",
    "state_code": "BS",
    "cab": "541003",
    "cap": "25022"
  },
  {
    "key": "1700",
    "ITA": "BORGO SAN GIACOMO-MOTELLA",
    "state_code": "BS",
    "cab": "541003",
    "cap": "25022"
  },
  {
    "key": "1701",
    "ITA": "BORGO SAN GIOVANNI",
    "state_code": "LO",
    "cab": "325902",
    "cap": "26851"
  },
  {
    "key": "1702",
    "ITA": "BORGO SAN LORENZO-PANICAGLIA",
    "state_code": "FI",
    "cab": "377507",
    "cap": "50032"
  },
  {
    "key": "1703",
    "ITA": "BORGO SAN LORENZO-LUCO MUGELLO",
    "state_code": "FI",
    "cab": "377507",
    "cap": "50032"
  },
  {
    "key": "1704",
    "ITA": "BORGO SAN LORENZO-CASAGLIA",
    "state_code": "FI",
    "cab": "377507",
    "cap": "50034"
  },
  {
    "key": "1705",
    "ITA": "BORGO SAN LORENZO-POLCANTO",
    "state_code": "FI",
    "cab": "377507",
    "cap": "50032"
  },
  {
    "key": "1706",
    "ITA": "BORGO SAN LORENZO",
    "state_code": "FI",
    "cab": "377507",
    "cap": "50032"
  },
  {
    "key": "1707",
    "ITA": "BORGO SAN LORENZO-RONTA",
    "state_code": "FI",
    "cab": "377507",
    "cap": "50032"
  },
  {
    "key": "1708",
    "ITA": "BORGO SAN MARTINO",
    "state_code": "AL",
    "cab": "480301",
    "cap": "15032"
  },
  {
    "key": "1709",
    "ITA": "BORGO SAN SIRO",
    "state_code": "PV",
    "cab": "651406",
    "cap": "27020"
  },
  {
    "key": "1710",
    "ITA": "BORGO TICINO",
    "state_code": "NO",
    "cab": "452300",
    "cap": "28040"
  },
  {
    "key": "1711",
    "ITA": "BORGO TOSSIGNANO",
    "state_code": "BO",
    "cab": "366302",
    "cap": "40021"
  },
  {
    "key": "1712",
    "ITA": "BORGO TOSSIGNANO-TOSSIGNANO",
    "state_code": "BO",
    "cab": "366302",
    "cap": "40021"
  },
  {
    "key": "1713",
    "ITA": "BORGO VAL DI TARO-PORCIGATONE",
    "state_code": "PR",
    "cab": "656603",
    "cap": "43043"
  },
  {
    "key": "1714",
    "ITA": "BORGO VAL DI TARO-TIEDOLI",
    "state_code": "PR",
    "cab": "656603",
    "cap": "43043"
  },
  {
    "key": "1715",
    "ITA": "BORGO VAL DI TARO",
    "state_code": "PR",
    "cab": "656603",
    "cap": "43043"
  },
  {
    "key": "1716",
    "ITA": "BORGO VAL DI TARO-OSTIA PARMENSE",
    "state_code": "PR",
    "cab": "656603",
    "cap": "43043"
  },
  {
    "key": "1717",
    "ITA": "BORGO VAL DI TARO-PONTOLO",
    "state_code": "PR",
    "cab": "656603",
    "cap": "43043"
  },
  {
    "key": "1718",
    "ITA": "BORGO VALSUGANA-BORGO",
    "state_code": "TN",
    "cab": "344002",
    "cap": "38051"
  },
  {
    "key": "1719",
    "ITA": "BORGO VALSUGANA-OLLE",
    "state_code": "TN",
    "cab": "344002",
    "cap": "38051"
  },
  {
    "key": "1720",
    "ITA": "BORGO VALSUGANA",
    "state_code": "TN",
    "cab": "344002",
    "cap": "38051"
  },
  {
    "key": "1721",
    "ITA": "BORGO VELINO",
    "state_code": "RI",
    "cab": "655308",
    "cap": "2010"
  },
  {
    "key": "1722",
    "ITA": "BORGO VERCELLI",
    "state_code": "VC",
    "cab": "443200",
    "cap": "13012"
  },
  {
    "key": "1723",
    "ITA": "BORGOFORTE-BOCCADIGANDA",
    "state_code": "MN",
    "cab": "574608",
    "cap": "46030"
  },
  {
    "key": "1724",
    "ITA": "BORGOFORTE-SAN CATALDO",
    "state_code": "MN",
    "cab": "574608",
    "cap": "46030"
  },
  {
    "key": "1725",
    "ITA": "BORGOFORTE-ROMANORE",
    "state_code": "MN",
    "cab": "574608",
    "cap": "46030"
  },
  {
    "key": "1726",
    "ITA": "BORGOFORTE",
    "state_code": "MN",
    "cab": "574608",
    "cap": "46030"
  },
  {
    "key": "1727",
    "ITA": "BORGOFRANCO D'IVREA",
    "state_code": "TO",
    "cab": "301200",
    "cap": "10013"
  },
  {
    "key": "1728",
    "ITA": "BORGOFRANCO D'IVREA-BAIO DORA",
    "state_code": "TO",
    "cab": "301200",
    "cap": "10010"
  },
  {
    "key": "1729",
    "ITA": "BORGOFRANCO SUL PO",
    "state_code": "MN",
    "cab": "717405",
    "cap": "46020"
  },
  {
    "key": "1730",
    "ITA": "BORGOLAVEZZARO",
    "state_code": "NO",
    "cab": "452102",
    "cap": "28071"
  },
  {
    "key": "1731",
    "ITA": "BORGOMALE",
    "state_code": "CN",
    "cab": "918359",
    "cap": "12050"
  },
  {
    "key": "1732",
    "ITA": "BORGOMANERO-SAN MARCO DI BORGOMANERO",
    "state_code": "NO",
    "cab": "452201",
    "cap": "28021"
  },
  {
    "key": "1733",
    "ITA": "BORGOMANERO-VERGANO DI BORGOMANERO",
    "state_code": "NO",
    "cab": "452201",
    "cap": "28021"
  },
  {
    "key": "1734",
    "ITA": "BORGOMANERO-SANTA CRISTINA DI BORGOMANERO",
    "state_code": "NO",
    "cab": "452201",
    "cap": "28021"
  },
  {
    "key": "1735",
    "ITA": "BORGOMANERO",
    "state_code": "NO",
    "cab": "452201",
    "cap": "28021"
  },
  {
    "key": "1736",
    "ITA": "BORGOMARO",
    "state_code": "IM",
    "cab": "922286",
    "cap": "18021"
  },
  {
    "key": "1737",
    "ITA": "BORGOMASINO",
    "state_code": "TO",
    "cab": "314500",
    "cap": "10031"
  },
  {
    "key": "1738",
    "ITA": "BORGONE SUSA",
    "state_code": "TO",
    "cab": "301309",
    "cap": "10050"
  },
  {
    "key": "1739",
    "ITA": "BORGONOVO VAL TIDONE",
    "state_code": "PC",
    "cab": "652008",
    "cap": "29011"
  },
  {
    "key": "1740",
    "ITA": "BORGONOVO VAL TIDONE-CASTELNUOVO",
    "state_code": "PC",
    "cab": "652008",
    "cap": "29011"
  },
  {
    "key": "1741",
    "ITA": "BORGORATTO ALESSANDRINO",
    "state_code": "AL",
    "cab": "920884",
    "cap": "15013"
  },
  {
    "key": "1742",
    "ITA": "BORGORATTO MORMOROLO",
    "state_code": "PV",
    "cab": "930214",
    "cap": "27040"
  },
  {
    "key": "1743",
    "ITA": "BORGORICCO-SAN MICHELE DELLE BADESSE",
    "state_code": "PD",
    "cab": "623900",
    "cap": "35010"
  },
  {
    "key": "1744",
    "ITA": "BORGORICCO",
    "state_code": "PD",
    "cab": "623900",
    "cap": "35010"
  },
  {
    "key": "1745",
    "ITA": "BORGOROSE",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2021"
  },
  {
    "key": "1746",
    "ITA": "BORGOROSE-COLLEMAGGIORE",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2028"
  },
  {
    "key": "1747",
    "ITA": "BORGOROSE-CORVARO",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2021"
  },
  {
    "key": "1748",
    "ITA": "BORGOROSE-GROTTI DI BORGOROSE",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2021"
  },
  {
    "key": "1749",
    "ITA": "BORGOROSE-POGGIOVALLE",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2021"
  },
  {
    "key": "1750",
    "ITA": "BORGOROSE-SANT'ANATOLIA",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2021"
  },
  {
    "key": "1751",
    "ITA": "BORGOROSE-TORANO",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2021"
  },
  {
    "key": "1752",
    "ITA": "BORGOROSE-VILLEROSE",
    "state_code": "RI",
    "cab": "735001",
    "cap": "2021"
  },
  {
    "key": "1753",
    "ITA": "BORGOSATOLLO",
    "state_code": "BS",
    "cab": "541102",
    "cap": "25010"
  },
  {
    "key": "1754",
    "ITA": "BORGOSESIA-ISOLELLA",
    "state_code": "VC",
    "cab": "443101",
    "cap": "13011"
  },
  {
    "key": "1755",
    "ITA": "BORGOSESIA-BETTOLE SESIA",
    "state_code": "VC",
    "cab": "443101",
    "cap": "13011"
  },
  {
    "key": "1756",
    "ITA": "BORGOSESIA",
    "state_code": "VC",
    "cab": "443101",
    "cap": "13011"
  },
  {
    "key": "1757",
    "ITA": "BORMIDA",
    "state_code": "SV",
    "cab": "923045",
    "cap": "17045"
  },
  {
    "key": "1758",
    "ITA": "BORMIO",
    "state_code": "SO",
    "cab": "520908",
    "cap": "23032"
  },
  {
    "key": "1759",
    "ITA": "BORMIO-PASSO STELVIO",
    "state_code": "SO",
    "cab": "520908",
    "cap": "23032"
  },
  {
    "key": "1760",
    "ITA": "BORNASCO",
    "state_code": "PV",
    "cab": "838003",
    "cap": "27010"
  },
  {
    "key": "1761",
    "ITA": "BORNO",
    "state_code": "BS",
    "cab": "541201",
    "cap": "25042"
  },
  {
    "key": "1762",
    "ITA": "BORONEDDU",
    "state_code": "OR",
    "cab": "966747",
    "cap": "9080"
  },
  {
    "key": "1763",
    "ITA": "BORORE",
    "state_code": "NU",
    "cab": "865105",
    "cap": "8016"
  },
  {
    "key": "1764",
    "ITA": "BORRELLO",
    "state_code": "CH",
    "cab": "954537",
    "cap": "66040"
  },
  {
    "key": "1765",
    "ITA": "BORRIANA",
    "state_code": "BI",
    "cab": "820506",
    "cap": "13872"
  },
  {
    "key": "1766",
    "ITA": "BORSO DEL GRAPPA-SANT'EULALIA",
    "state_code": "TV",
    "cab": "614909",
    "cap": "31030"
  },
  {
    "key": "1767",
    "ITA": "BORSO DEL GRAPPA",
    "state_code": "TV",
    "cab": "614909",
    "cap": "31030"
  },
  {
    "key": "1768",
    "ITA": "BORSO DEL GRAPPA-SEMONZO",
    "state_code": "TV",
    "cab": "614909",
    "cap": "31030"
  },
  {
    "key": "1769",
    "ITA": "BORTIGALI",
    "state_code": "NU",
    "cab": "865204",
    "cap": "8012"
  },
  {
    "key": "1770",
    "ITA": "BORTIGALI-MULARGIA",
    "state_code": "NU",
    "cab": "865204",
    "cap": "8012"
  },
  {
    "key": "1771",
    "ITA": "BORTIGIADAS",
    "state_code": "OT",
    "cab": "872309",
    "cap": "7030"
  },
  {
    "key": "1772",
    "ITA": "BORUTTA",
    "state_code": "SS",
    "cab": "872408",
    "cap": "7040"
  },
  {
    "key": "1773",
    "ITA": "BORZONASCA-GIAIETTE",
    "state_code": "GE",
    "cab": "318600",
    "cap": "16041"
  },
  {
    "key": "1774",
    "ITA": "BORZONASCA-PRATO SOPRALACROCE",
    "state_code": "GE",
    "cab": "318600",
    "cap": "16041"
  },
  {
    "key": "1775",
    "ITA": "BORZONASCA",
    "state_code": "GE",
    "cab": "318600",
    "cap": "16041"
  },
  {
    "key": "1776",
    "ITA": "BORZONASCA-BERTIGARO",
    "state_code": "GE",
    "cab": "318600",
    "cap": "16041"
  },
  {
    "key": "1777",
    "ITA": "BORZONASCA-BRIZZOLARA",
    "state_code": "GE",
    "cab": "318600",
    "cap": "16041"
  },
  {
    "key": "1778",
    "ITA": "BOSA-TURAS",
    "state_code": "OR",
    "cab": "852707",
    "cap": "8013"
  },
  {
    "key": "1779",
    "ITA": "BOSA-BOSA MARINA",
    "state_code": "OR",
    "cab": "852707",
    "cap": "8013"
  },
  {
    "key": "1780",
    "ITA": "BOSA",
    "state_code": "OR",
    "cab": "852707",
    "cap": "8013"
  },
  {
    "key": "1781",
    "ITA": "BOSARO",
    "state_code": "RO",
    "cab": "734301",
    "cap": "45033"
  },
  {
    "key": "1782",
    "ITA": "BOSCHI SANT'ANNA",
    "state_code": "VR",
    "cab": "782300",
    "cap": "37040"
  },
  {
    "key": "1783",
    "ITA": "BOSCO CHIESANUOVA",
    "state_code": "VR",
    "cab": "592808",
    "cap": "37021"
  },
  {
    "key": "1784",
    "ITA": "BOSCO CHIESANUOVA-CORBIOLO",
    "state_code": "VR",
    "cab": "592808",
    "cap": "37021"
  },
  {
    "key": "1785",
    "ITA": "BOSCO CHIESANUOVA-LUGHEZZANO",
    "state_code": "VR",
    "cab": "592808",
    "cap": "37021"
  },
  {
    "key": "1786",
    "ITA": "BOSCO MARENGO-POLLASTRA",
    "state_code": "AL",
    "cab": "480400",
    "cap": "15062"
  },
  {
    "key": "1787",
    "ITA": "BOSCO MARENGO",
    "state_code": "AL",
    "cab": "480400",
    "cap": "15062"
  },
  {
    "key": "1788",
    "ITA": "BOSCO MARENGO-DONNA",
    "state_code": "AL",
    "cab": "480400",
    "cap": "15062"
  },
  {
    "key": "1789",
    "ITA": "BOSCONERO",
    "state_code": "TO",
    "cab": "313700",
    "cap": "10080"
  },
  {
    "key": "1790",
    "ITA": "BOSCOREALE-MARCHESA",
    "state_code": "NA",
    "cab": "397505",
    "cap": "80041"
  },
  {
    "key": "1791",
    "ITA": "BOSCOREALE",
    "state_code": "NA",
    "cab": "397505",
    "cap": "80041"
  },
  {
    "key": "1792",
    "ITA": "BOSCOTRECASE",
    "state_code": "NA",
    "cab": "397604",
    "cap": "80042"
  },
  {
    "key": "1793",
    "ITA": "BOSENTINO",
    "state_code": "TN",
    "cab": "904664",
    "cap": "38049"
  },
  {
    "key": "1794",
    "ITA": "BOSENTINO-MIGAZZONE",
    "state_code": "TN",
    "cab": "904664",
    "cap": "38049"
  },
  {
    "key": "1795",
    "ITA": "BOSIA",
    "state_code": "CN",
    "cab": "918367",
    "cap": "12050"
  },
  {
    "key": "1796",
    "ITA": "BOSIO",
    "state_code": "AL",
    "cab": "480509",
    "cap": "15060"
  },
  {
    "key": "1797",
    "ITA": "BOSISIO PARINI",
    "state_code": "LC",
    "cab": "509802",
    "cap": "23842"
  },
  {
    "key": "1798",
    "ITA": "BOSNASCO",
    "state_code": "PV",
    "cab": "930248",
    "cap": "27040"
  },
  {
    "key": "1799",
    "ITA": "BOSSICO",
    "state_code": "BG",
    "cab": "927863",
    "cap": "24060"
  },
  {
    "key": "1800",
    "ITA": "BOSSOLASCO",
    "state_code": "CN",
    "cab": "460204",
    "cap": "12060"
  },
  {
    "key": "1801",
    "ITA": "BOTRICELLO",
    "state_code": "CZ",
    "cab": "424804",
    "cap": "88070"
  },
  {
    "key": "1802",
    "ITA": "BOTRUGNO",
    "state_code": "LE",
    "cab": "801902",
    "cap": "73020"
  },
  {
    "key": "1803",
    "ITA": "BOTTANUCO",
    "state_code": "BG",
    "cab": "526301",
    "cap": "24040"
  },
  {
    "key": "1804",
    "ITA": "BOTTICINO-SAN GALLO",
    "state_code": "BS",
    "cab": "541300",
    "cap": "25082"
  },
  {
    "key": "1805",
    "ITA": "BOTTICINO-BOTTICINO SERA",
    "state_code": "BS",
    "cab": "541300",
    "cap": "25082"
  },
  {
    "key": "1806",
    "ITA": "BOTTICINO",
    "state_code": "BS",
    "cab": "541300",
    "cap": "25082"
  },
  {
    "key": "1807",
    "ITA": "BOTTICINO-BOTTICINO MATTINA",
    "state_code": "BS",
    "cab": "541300",
    "cap": "25082"
  },
  {
    "key": "1808",
    "ITA": "BOTTIDDA",
    "state_code": "SS",
    "cab": "872507",
    "cap": "7010"
  },
  {
    "key": "1809",
    "ITA": "BOVA",
    "state_code": "RC",
    "cab": "192419",
    "cap": "89033"
  },
  {
    "key": "1810",
    "ITA": "BOVA MARINA",
    "state_code": "RC",
    "cab": "813105",
    "cap": "89035"
  },
  {
    "key": "1811",
    "ITA": "BOVALINO",
    "state_code": "RC",
    "cab": "813006",
    "cap": "89034"
  },
  {
    "key": "1812",
    "ITA": "BOVALINO-BOSCO SANT'IPPOLITO",
    "state_code": "RC",
    "cab": "813006",
    "cap": "89034"
  },
  {
    "key": "1813",
    "ITA": "BOVALINO-BOVALINO MARINA",
    "state_code": "RC",
    "cab": "813006",
    "cap": "89034"
  },
  {
    "key": "1814",
    "ITA": "BOVALINO-BOVALINO SUPERIORE",
    "state_code": "RC",
    "cab": "813006",
    "cap": "89034"
  },
  {
    "key": "1815",
    "ITA": "BOVEGNO",
    "state_code": "BS",
    "cab": "541409",
    "cap": "25061"
  },
  {
    "key": "1816",
    "ITA": "BOVES-CERATI",
    "state_code": "CN",
    "cab": "460303",
    "cap": "12012"
  },
  {
    "key": "1817",
    "ITA": "BOVES",
    "state_code": "CN",
    "cab": "460303",
    "cap": "12012"
  },
  {
    "key": "1818",
    "ITA": "BOVES-MELLANA",
    "state_code": "CN",
    "cab": "460303",
    "cap": "12012"
  },
  {
    "key": "1819",
    "ITA": "BOVES-FONTANELLE",
    "state_code": "CN",
    "cab": "460303",
    "cap": "12012"
  },
  {
    "key": "1820",
    "ITA": "BOVES-RIVOIRA",
    "state_code": "CN",
    "cab": "460303",
    "cap": "12012"
  },
  {
    "key": "1821",
    "ITA": "BOVES-SAN GIACOMO",
    "state_code": "CN",
    "cab": "460303",
    "cap": "12012"
  },
  {
    "key": "1822",
    "ITA": "BOVEZZO",
    "state_code": "BS",
    "cab": "541508",
    "cap": "25073"
  },
  {
    "key": "1823",
    "ITA": "BOVILLE ERNICA-ANTICA COLLE PISCIOSO",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1824",
    "ITA": "BOVILLE ERNICA-BRECCIARO",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1825",
    "ITA": "BOVILLE ERNICA-CASAVITOLA",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1826",
    "ITA": "BOVILLE ERNICA-COLLE CAMPANO",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1827",
    "ITA": "BOVILLE ERNICA-ROTABILE",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1828",
    "ITA": "BOVILLE ERNICA-SCRIMA",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1829",
    "ITA": "BOVILLE ERNICA",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1830",
    "ITA": "BOVILLE ERNICA-MOZZANO TORRETTA",
    "state_code": "FR",
    "cab": "743500",
    "cap": "3022"
  },
  {
    "key": "1831",
    "ITA": "BOVINO",
    "state_code": "FG",
    "cab": "783001",
    "cap": "71023"
  },
  {
    "key": "1832",
    "ITA": "BOVISIO MASCIAGO-BOVISIO",
    "state_code": "MB",
    "cab": "326009",
    "cap": "20030"
  },
  {
    "key": "1833",
    "ITA": "BOVISIO MASCIAGO",
    "state_code": "MB",
    "cab": "326009",
    "cap": "20030"
  },
  {
    "key": "1834",
    "ITA": "BOVISIO MASCIAGO-MASCIAGO",
    "state_code": "MB",
    "cab": "326009",
    "cap": "20030"
  },
  {
    "key": "1835",
    "ITA": "BOVOLENTA",
    "state_code": "PD",
    "cab": "624007",
    "cap": "35024"
  },
  {
    "key": "1836",
    "ITA": "BOVOLONE-VILLAFONTANA",
    "state_code": "VR",
    "cab": "592907",
    "cap": "37051"
  },
  {
    "key": "1837",
    "ITA": "BOVOLONE",
    "state_code": "VR",
    "cab": "592907",
    "cap": "37051"
  },
  {
    "key": "1838",
    "ITA": "BOZZOLE",
    "state_code": "AL",
    "cab": "920892",
    "cap": "15040"
  },
  {
    "key": "1839",
    "ITA": "BOZZOLO",
    "state_code": "MN",
    "cab": "574707",
    "cap": "46012"
  },
  {
    "key": "1840",
    "ITA": "BRA",
    "state_code": "CN",
    "cab": "460402",
    "cap": "12042"
  },
  {
    "key": "1841",
    "ITA": "BRA-POLLENZO",
    "state_code": "CN",
    "cab": "460402",
    "cap": "12042"
  },
  {
    "key": "1842",
    "ITA": "BRA-BANDITO",
    "state_code": "CN",
    "cab": "460402",
    "cap": "12042"
  },
  {
    "key": "1843",
    "ITA": "BRACCA",
    "state_code": "BG",
    "cab": "265876",
    "cap": "24010"
  },
  {
    "key": "1844",
    "ITA": "BRACCIANO-CASTEL GIULIANO",
    "state_code": "RM",
    "cab": "389403",
    "cap": "62"
  },
  {
    "key": "1845",
    "ITA": "BRACCIANO-VIGNA DI VALLE",
    "state_code": "RM",
    "cab": "389403",
    "cap": "62"
  },
  {
    "key": "1846",
    "ITA": "BRACCIANO",
    "state_code": "RM",
    "cab": "389403",
    "cap": "62"
  },
  {
    "key": "1847",
    "ITA": "BRACCIANO-RINASCENTE",
    "state_code": "RM",
    "cab": "389403",
    "cap": "62"
  },
  {
    "key": "1848",
    "ITA": "BRACIGLIANO-SAN NAZARIO",
    "state_code": "SA",
    "cab": "895805",
    "cap": "84082"
  },
  {
    "key": "1849",
    "ITA": "BRACIGLIANO",
    "state_code": "SA",
    "cab": "895805",
    "cap": "84082"
  },
  {
    "key": "1850",
    "ITA": "BRACIGLIANO-MANZI",
    "state_code": "SA",
    "cab": "895805",
    "cap": "84082"
  },
  {
    "key": "1851",
    "ITA": "BRAIES",
    "state_code": "BZ",
    "cab": "582007",
    "cap": "39030"
  },
  {
    "key": "1852",
    "ITA": "BRALLO DI PREGOLA",
    "state_code": "PV",
    "cab": "793703",
    "cap": "27050"
  },
  {
    "key": "1853",
    "ITA": "BRANCALEONE-GALATI",
    "state_code": "RC",
    "cab": "813204",
    "cap": "89036"
  },
  {
    "key": "1854",
    "ITA": "BRANCALEONE",
    "state_code": "RC",
    "cab": "813204",
    "cap": "89036"
  },
  {
    "key": "1855",
    "ITA": "BRANCALEONE-BRANCALEONE MARINA",
    "state_code": "RC",
    "cab": "813204",
    "cap": "89036"
  },
  {
    "key": "1856",
    "ITA": "BRANCALEONE-CAPO SPARTIVENTO",
    "state_code": "RC",
    "cab": "813204",
    "cap": "89036"
  },
  {
    "key": "1857",
    "ITA": "BRANDICO",
    "state_code": "BS",
    "cab": "556001",
    "cap": "25030"
  },
  {
    "key": "1858",
    "ITA": "BRANDIZZO",
    "state_code": "TO",
    "cab": "301408",
    "cap": "10032"
  },
  {
    "key": "1859",
    "ITA": "BRANZI",
    "state_code": "BG",
    "cab": "526400",
    "cap": "24010"
  },
  {
    "key": "1860",
    "ITA": "BRAONE",
    "state_code": "BS",
    "cab": "793604",
    "cap": "25040"
  },
  {
    "key": "1861",
    "ITA": "BREBBIA",
    "state_code": "VA",
    "cab": "500405",
    "cap": "21020"
  },
  {
    "key": "1862",
    "ITA": "BREDA DI PIAVE-VACIL",
    "state_code": "TV",
    "cab": "615005",
    "cap": "31030"
  },
  {
    "key": "1863",
    "ITA": "BREDA DI PIAVE-SALETTO",
    "state_code": "TV",
    "cab": "615005",
    "cap": "31030"
  },
  {
    "key": "1864",
    "ITA": "BREDA DI PIAVE-PERO",
    "state_code": "TV",
    "cab": "615005",
    "cap": "31030"
  },
  {
    "key": "1865",
    "ITA": "BREDA DI PIAVE",
    "state_code": "TV",
    "cab": "615005",
    "cap": "31030"
  },
  {
    "key": "1866",
    "ITA": "BREDA DI PIAVE-SAN BARTOLOMEO",
    "state_code": "TV",
    "cab": "615005",
    "cap": "31030"
  },
  {
    "key": "1867",
    "ITA": "BREGANO",
    "state_code": "VA",
    "cab": "924191",
    "cap": "21020"
  },
  {
    "key": "1868",
    "ITA": "BREGANZE-MIRABELLA",
    "state_code": "VI",
    "cab": "601807",
    "cap": "36042"
  },
  {
    "key": "1869",
    "ITA": "BREGANZE-MARAGNOLE",
    "state_code": "VI",
    "cab": "601807",
    "cap": "36042"
  },
  {
    "key": "1870",
    "ITA": "BREGANZE",
    "state_code": "VI",
    "cab": "601807",
    "cap": "36042"
  },
  {
    "key": "1871",
    "ITA": "BREGNANO-PUGINATE",
    "state_code": "CO",
    "cab": "518605",
    "cap": "22070"
  },
  {
    "key": "1872",
    "ITA": "BREGNANO",
    "state_code": "CO",
    "cab": "518605",
    "cap": "22070"
  },
  {
    "key": "1873",
    "ITA": "BREGNANO-SAN MICHELE",
    "state_code": "CO",
    "cab": "518605",
    "cap": "22070"
  },
  {
    "key": "1874",
    "ITA": "BREGNANO-SAN GIORGIO",
    "state_code": "CO",
    "cab": "518605",
    "cap": "22070"
  },
  {
    "key": "1875",
    "ITA": "BREGUZZO",
    "state_code": "TN",
    "cab": "344101",
    "cap": "38081"
  },
  {
    "key": "1876",
    "ITA": "BREIA",
    "state_code": "VC",
    "cab": "915678",
    "cap": "13020"
  },
  {
    "key": "1877",
    "ITA": "BREMBATE",
    "state_code": "BG",
    "cab": "526509",
    "cap": "24041"
  },
  {
    "key": "1878",
    "ITA": "BREMBATE-GRIGNANO",
    "state_code": "BG",
    "cab": "526509",
    "cap": "24041"
  },
  {
    "key": "1879",
    "ITA": "BREMBATE DI SOPRA",
    "state_code": "BG",
    "cab": "526608",
    "cap": "24030"
  },
  {
    "key": "1880",
    "ITA": "BREMBILLA-SAN GOTTARDO",
    "state_code": "BG",
    "cab": "526707",
    "cap": "24012"
  },
  {
    "key": "1881",
    "ITA": "BREMBILLA-LAXOLO",
    "state_code": "BG",
    "cab": "526707",
    "cap": "24012"
  },
  {
    "key": "1882",
    "ITA": "BREMBILLA",
    "state_code": "BG",
    "cab": "526707",
    "cap": "24012"
  },
  {
    "key": "1883",
    "ITA": "BREMBIO",
    "state_code": "LO",
    "cab": "326108",
    "cap": "26822"
  },
  {
    "key": "1884",
    "ITA": "BREME",
    "state_code": "PV",
    "cab": "556605",
    "cap": "27020"
  },
  {
    "key": "1885",
    "ITA": "BRENDOLA",
    "state_code": "VI",
    "cab": "601906",
    "cap": "36040"
  },
  {
    "key": "1886",
    "ITA": "BRENDOLA-VO'",
    "state_code": "VI",
    "cab": "601906",
    "cap": "36040"
  },
  {
    "key": "1887",
    "ITA": "BRENNA",
    "state_code": "CO",
    "cab": "742403",
    "cap": "22040"
  },
  {
    "key": "1888",
    "ITA": "BRENNERO",
    "state_code": "BZ",
    "cab": "582106",
    "cap": "39041"
  },
  {
    "key": "1889",
    "ITA": "BRENNERO-COLLE ISARCO",
    "state_code": "BZ",
    "cab": "582106",
    "cap": "39041"
  },
  {
    "key": "1890",
    "ITA": "BRENO-PESCARZO",
    "state_code": "BS",
    "cab": "541607",
    "cap": "25043"
  },
  {
    "key": "1891",
    "ITA": "BRENO",
    "state_code": "BS",
    "cab": "541607",
    "cap": "25043"
  },
  {
    "key": "1892",
    "ITA": "BRENO-ASTRIO",
    "state_code": "BS",
    "cab": "541607",
    "cap": "25043"
  },
  {
    "key": "1893",
    "ITA": "BRENTA",
    "state_code": "VA",
    "cab": "924209",
    "cap": "21030"
  },
  {
    "key": "1894",
    "ITA": "BRENTINO BELLUNO-BELLUNO VERONESE",
    "state_code": "VR",
    "cab": "600700",
    "cap": "37020"
  },
  {
    "key": "1895",
    "ITA": "BRENTINO BELLUNO-RIVALTA",
    "state_code": "VR",
    "cab": "600700",
    "cap": "37020"
  },
  {
    "key": "1896",
    "ITA": "BRENTINO BELLUNO",
    "state_code": "VR",
    "cab": "600700",
    "cap": "37020"
  },
  {
    "key": "1897",
    "ITA": "BRENTONICO-CASTIONE",
    "state_code": "TN",
    "cab": "344200",
    "cap": "38060"
  },
  {
    "key": "1898",
    "ITA": "BRENTONICO",
    "state_code": "TN",
    "cab": "344200",
    "cap": "38060"
  },
  {
    "key": "1899",
    "ITA": "BRENTONICO-CROSANO",
    "state_code": "TN",
    "cab": "344200",
    "cap": "38060"
  },
  {
    "key": "1900",
    "ITA": "BRENZONE-MAGUGNANO",
    "state_code": "VR",
    "cab": "593004",
    "cap": "37010"
  },
  {
    "key": "1901",
    "ITA": "BRENZONE-CASTELLETTO DI BRENZONE",
    "state_code": "VR",
    "cab": "593004",
    "cap": "37010"
  },
  {
    "key": "1902",
    "ITA": "BRENZONE",
    "state_code": "VR",
    "cab": "593004",
    "cap": "37010"
  },
  {
    "key": "1903",
    "ITA": "BRESCELLO",
    "state_code": "RE",
    "cab": "661801",
    "cap": "42041"
  },
  {
    "key": "1904",
    "ITA": "BRESCELLO-LENTIGIONE",
    "state_code": "RE",
    "cab": "661801",
    "cap": "42041"
  },
  {
    "key": "1905",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25131"
  },
  {
    "key": "1906",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25132"
  },
  {
    "key": "1907",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25133"
  },
  {
    "key": "1908",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25134"
  },
  {
    "key": "1909",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25135"
  },
  {
    "key": "1910",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25136"
  },
  {
    "key": "1911",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25129"
  },
  {
    "key": "1912",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25128"
  },
  {
    "key": "1913",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25126"
  },
  {
    "key": "1914",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25124"
  },
  {
    "key": "1915",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25123"
  },
  {
    "key": "1916",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25122"
  },
  {
    "key": "1917",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25121"
  },
  {
    "key": "1918",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25127"
  },
  {
    "key": "1919",
    "ITA": "BRESCIA",
    "state_code": "BS",
    "cab": "112003",
    "cap": "25125"
  },
  {
    "key": "1920",
    "ITA": "BRESIMO",
    "state_code": "TN",
    "cab": "358705",
    "cap": "38020"
  },
  {
    "key": "1921",
    "ITA": "BRESSANA BOTTARONE-BRESSANA",
    "state_code": "PV",
    "cab": "556704",
    "cap": "27042"
  },
  {
    "key": "1922",
    "ITA": "BRESSANA BOTTARONE",
    "state_code": "PV",
    "cab": "556704",
    "cap": "27042"
  },
  {
    "key": "1923",
    "ITA": "BRESSANA BOTTARONE-BOTTARONE",
    "state_code": "PV",
    "cab": "556704",
    "cap": "27042"
  },
  {
    "key": "1924",
    "ITA": "BRESSANONE-SANT'ANDREA IN MONTE",
    "state_code": "BZ",
    "cab": "582205",
    "cap": "39042"
  },
  {
    "key": "1925",
    "ITA": "BRESSANONE",
    "state_code": "BZ",
    "cab": "582205",
    "cap": "39042"
  },
  {
    "key": "1926",
    "ITA": "BRESSANONE-EORES",
    "state_code": "BZ",
    "cab": "582205",
    "cap": "39042"
  },
  {
    "key": "1927",
    "ITA": "BRESSANONE-ALBES",
    "state_code": "BZ",
    "cab": "582205",
    "cap": "39042"
  },
  {
    "key": "1928",
    "ITA": "BRESSANVIDO",
    "state_code": "VI",
    "cab": "609206",
    "cap": "36050"
  },
  {
    "key": "1929",
    "ITA": "BRESSANVIDO-POIANELLA",
    "state_code": "VI",
    "cab": "609206",
    "cap": "36050"
  },
  {
    "key": "1930",
    "ITA": "BRESSO",
    "state_code": "MI",
    "cab": "326207",
    "cap": "20091"
  },
  {
    "key": "1931",
    "ITA": "BREZ",
    "state_code": "TN",
    "cab": "344309",
    "cap": "38021"
  },
  {
    "key": "1932",
    "ITA": "BREZZO DI BEDERO",
    "state_code": "VA",
    "cab": "924217",
    "cap": "21010"
  },
  {
    "key": "1933",
    "ITA": "BRIAGLIA",
    "state_code": "CN",
    "cab": "918375",
    "cap": "12080"
  },
  {
    "key": "1934",
    "ITA": "BRIATICO-SCICONI",
    "state_code": "VV",
    "cab": "424903",
    "cap": "89817"
  },
  {
    "key": "1935",
    "ITA": "BRIATICO-SAN COSTANTINO",
    "state_code": "VV",
    "cab": "424903",
    "cap": "89817"
  },
  {
    "key": "1936",
    "ITA": "BRIATICO-POTENZONI",
    "state_code": "VV",
    "cab": "424903",
    "cap": "89817"
  },
  {
    "key": "1937",
    "ITA": "BRIATICO-PARADISONI",
    "state_code": "VV",
    "cab": "424903",
    "cap": "89817"
  },
  {
    "key": "1938",
    "ITA": "BRIATICO",
    "state_code": "VV",
    "cab": "424903",
    "cap": "89817"
  },
  {
    "key": "1939",
    "ITA": "BRICHERASIO",
    "state_code": "TO",
    "cab": "301507",
    "cap": "10060"
  },
  {
    "key": "1940",
    "ITA": "BRIENNO",
    "state_code": "CO",
    "cab": "925214",
    "cap": "22010"
  },
  {
    "key": "1941",
    "ITA": "BRIENZA",
    "state_code": "PZ",
    "cab": "419002",
    "cap": "85050"
  },
  {
    "key": "1942",
    "ITA": "BRIGA ALTA-PIAGGIA",
    "state_code": "CN",
    "cab": "918383",
    "cap": "18025"
  },
  {
    "key": "1943",
    "ITA": "BRIGA ALTA",
    "state_code": "CN",
    "cab": "918383",
    "cap": "18025"
  },
  {
    "key": "1944",
    "ITA": "BRIGA NOVARESE",
    "state_code": "NO",
    "cab": "452409",
    "cap": "28010"
  },
  {
    "key": "1945",
    "ITA": "BRIGNANO FRASCATA",
    "state_code": "AL",
    "cab": "480608",
    "cap": "15050"
  },
  {
    "key": "1946",
    "ITA": "BRIGNANO GERA D'ADDA",
    "state_code": "BG",
    "cab": "526806",
    "cap": "24053"
  },
  {
    "key": "1947",
    "ITA": "BRINDISI-TUTURANO",
    "state_code": "BR",
    "cab": "159004",
    "cap": "72020"
  },
  {
    "key": "1948",
    "ITA": "BRINDISI",
    "state_code": "BR",
    "cab": "159004",
    "cap": "72100"
  },
  {
    "key": "1949",
    "ITA": "BRINDISI-BRINDISI CASALE",
    "state_code": "BR",
    "cab": "159004",
    "cap": "72100"
  },
  {
    "key": "1950",
    "ITA": "BRINDISI MONTAGNA",
    "state_code": "PZ",
    "cab": "911867",
    "cap": "85010"
  },
  {
    "key": "1951",
    "ITA": "BRINZIO",
    "state_code": "VA",
    "cab": "924225",
    "cap": "21030"
  },
  {
    "key": "1952",
    "ITA": "BRIONA",
    "state_code": "NO",
    "cab": "917021",
    "cap": "28072"
  },
  {
    "key": "1953",
    "ITA": "BRIONA-SAN BERNARDINO",
    "state_code": "NO",
    "cab": "917021",
    "cap": "28072"
  },
  {
    "key": "1954",
    "ITA": "BRIONE",
    "state_code": "BS",
    "cab": "929299",
    "cap": "25060"
  },
  {
    "key": "1955",
    "ITA": "BRIOSCO-CAPRIANO",
    "state_code": "MB",
    "cab": "326306",
    "cap": "20040"
  },
  {
    "key": "1956",
    "ITA": "BRIOSCO-FORNACI",
    "state_code": "MB",
    "cab": "326306",
    "cap": "20040"
  },
  {
    "key": "1957",
    "ITA": "BRIOSCO",
    "state_code": "MB",
    "cab": "326306",
    "cap": "20040"
  },
  {
    "key": "1958",
    "ITA": "BRISIGHELLA-SAN CASSIANO",
    "state_code": "RA",
    "cab": "675108",
    "cap": "48013"
  },
  {
    "key": "1959",
    "ITA": "BRISIGHELLA-MONTEROMANO",
    "state_code": "RA",
    "cab": "675108",
    "cap": "48013"
  },
  {
    "key": "1960",
    "ITA": "BRISIGHELLA",
    "state_code": "RA",
    "cab": "675108",
    "cap": "48013"
  },
  {
    "key": "1961",
    "ITA": "BRISIGHELLA-FOGNANO",
    "state_code": "RA",
    "cab": "675108",
    "cap": "48013"
  },
  {
    "key": "1962",
    "ITA": "BRISIGHELLA-MARZENO",
    "state_code": "RA",
    "cab": "675108",
    "cap": "48013"
  },
  {
    "key": "1963",
    "ITA": "BRISIGHELLA-SAN MARTINO IN GATTARA",
    "state_code": "RA",
    "cab": "675108",
    "cap": "48013"
  },
  {
    "key": "1964",
    "ITA": "BRISIGHELLA-ZATTAGLIA",
    "state_code": "RA",
    "cab": "675108",
    "cap": "48013"
  },
  {
    "key": "1965",
    "ITA": "BRISSAGO VALTRAVAGLIA",
    "state_code": "VA",
    "cab": "924233",
    "cap": "21030"
  },
  {
    "key": "1966",
    "ITA": "BRISSOGNE-ETABLOZ",
    "state_code": "AO",
    "cab": "902270",
    "cap": "11020"
  },
  {
    "key": "1967",
    "ITA": "BRISSOGNE-NEYRAN",
    "state_code": "AO",
    "cab": "902270",
    "cap": "11020"
  },
  {
    "key": "1968",
    "ITA": "BRISSOGNE",
    "state_code": "AO",
    "cab": "902270",
    "cap": "11020"
  },
  {
    "key": "1969",
    "ITA": "BRITTOLI",
    "state_code": "PE",
    "cab": "954024",
    "cap": "65010"
  },
  {
    "key": "1970",
    "ITA": "BRIVIO-BEVERATE",
    "state_code": "LC",
    "cab": "509901",
    "cap": "23883"
  },
  {
    "key": "1971",
    "ITA": "BRIVIO",
    "state_code": "LC",
    "cab": "509901",
    "cap": "23883"
  },
  {
    "key": "1972",
    "ITA": "BROCCOSTELLA",
    "state_code": "FR",
    "cab": "747006",
    "cap": "3030"
  },
  {
    "key": "1973",
    "ITA": "BROCCOSTELLA-MADONNA DELLA STELLA",
    "state_code": "FR",
    "cab": "747006",
    "cap": "3030"
  },
  {
    "key": "1974",
    "ITA": "BROGLIANO",
    "state_code": "VI",
    "cab": "602003",
    "cap": "36070"
  },
  {
    "key": "1975",
    "ITA": "BROGNATURO",
    "state_code": "VV",
    "cab": "912683",
    "cap": "89822"
  },
  {
    "key": "1976",
    "ITA": "BROLO",
    "state_code": "ME",
    "cab": "820803",
    "cap": "98061"
  },
  {
    "key": "1977",
    "ITA": "BRONDELLO",
    "state_code": "CN",
    "cab": "918391",
    "cap": "12030"
  },
  {
    "key": "1978",
    "ITA": "BRONI",
    "state_code": "PV",
    "cab": "556803",
    "cap": "27043"
  },
  {
    "key": "1979",
    "ITA": "BRONTE",
    "state_code": "CT",
    "cab": "838904",
    "cap": "95034"
  },
  {
    "key": "1980",
    "ITA": "BRONZOLO",
    "state_code": "BZ",
    "cab": "582304",
    "cap": "39051"
  },
  {
    "key": "1981",
    "ITA": "BROSSASCO",
    "state_code": "CN",
    "cab": "471003",
    "cap": "12020"
  },
  {
    "key": "1982",
    "ITA": "BROSSO",
    "state_code": "TO",
    "cab": "283135",
    "cap": "10080"
  },
  {
    "key": "1983",
    "ITA": "BROVELLO CARPUGNINO",
    "state_code": "VB",
    "cab": "917039",
    "cap": "28833"
  },
  {
    "key": "1984",
    "ITA": "BROZOLO",
    "state_code": "TO",
    "cab": "283168",
    "cap": "10020"
  },
  {
    "key": "1985",
    "ITA": "BRUGHERIO",
    "state_code": "MB",
    "cab": "326405",
    "cap": "20047"
  },
  {
    "key": "1986",
    "ITA": "BRUGHERIO-SAN DAMIANO",
    "state_code": "MB",
    "cab": "326405",
    "cap": "20047"
  },
  {
    "key": "1987",
    "ITA": "BRUGINE",
    "state_code": "PD",
    "cab": "630103",
    "cap": "35020"
  },
  {
    "key": "1988",
    "ITA": "BRUGINE-CAMPAGNOLA",
    "state_code": "PD",
    "cab": "630103",
    "cap": "35020"
  },
  {
    "key": "1989",
    "ITA": "BRUGNATO",
    "state_code": "SP",
    "cab": "497206",
    "cap": "19020"
  },
  {
    "key": "1990",
    "ITA": "BRUGNERA",
    "state_code": "PN",
    "cab": "647909",
    "cap": "33070"
  },
  {
    "key": "1991",
    "ITA": "BRUGNERA-MARON",
    "state_code": "PN",
    "cab": "647909",
    "cap": "33070"
  },
  {
    "key": "1992",
    "ITA": "BRUGNERA-TAMAI",
    "state_code": "PN",
    "cab": "647909",
    "cap": "33070"
  },
  {
    "key": "1993",
    "ITA": "BRUINO-VILLAGGIO LA QUERCIA",
    "state_code": "TO",
    "cab": "301606",
    "cap": "10090"
  },
  {
    "key": "1994",
    "ITA": "BRUINO",
    "state_code": "TO",
    "cab": "301606",
    "cap": "10090"
  },
  {
    "key": "1995",
    "ITA": "BRUMANO",
    "state_code": "BG",
    "cab": "927889",
    "cap": "24037"
  },
  {
    "key": "1996",
    "ITA": "BRUNATE",
    "state_code": "CO",
    "cab": "925222",
    "cap": "22034"
  },
  {
    "key": "1997",
    "ITA": "BRUNELLO",
    "state_code": "VA",
    "cab": "924241",
    "cap": "21020"
  },
  {
    "key": "1998",
    "ITA": "BRUNICO",
    "state_code": "BZ",
    "cab": "582403",
    "cap": "39031"
  },
  {
    "key": "1999",
    "ITA": "BRUNICO-RISCONE",
    "state_code": "BZ",
    "cab": "582403",
    "cap": "39031"
  },
  {
    "key": "2000",
    "ITA": "BRUNICO-TEODONE",
    "state_code": "BZ",
    "cab": "582403",
    "cap": "39031"
  },
  {
    "key": "2001",
    "ITA": "BRUNO",
    "state_code": "AT",
    "cab": "919951",
    "cap": "14046"
  },
  {
    "key": "2002",
    "ITA": "BRUSAPORTO",
    "state_code": "BG",
    "cab": "891408",
    "cap": "24060"
  },
  {
    "key": "2003",
    "ITA": "BRUSASCO",
    "state_code": "TO",
    "cab": "301705",
    "cap": "10020"
  },
  {
    "key": "2004",
    "ITA": "BRUSASCO-MARCORENGO",
    "state_code": "TO",
    "cab": "301705",
    "cap": "10020"
  },
  {
    "key": "2005",
    "ITA": "BRUSCIANO",
    "state_code": "NA",
    "cab": "397703",
    "cap": "80031"
  },
  {
    "key": "2006",
    "ITA": "BRUSIMPIANO",
    "state_code": "VA",
    "cab": "924258",
    "cap": "21050"
  },
  {
    "key": "2007",
    "ITA": "BRUSNENGO",
    "state_code": "BI",
    "cab": "443309",
    "cap": "13862"
  },
  {
    "key": "2008",
    "ITA": "BRUSSON-EXTREPIERAZ",
    "state_code": "AO",
    "cab": "315200",
    "cap": "11022"
  },
  {
    "key": "2009",
    "ITA": "BRUSSON-ARCESAZ",
    "state_code": "AO",
    "cab": "315200",
    "cap": "11022"
  },
  {
    "key": "2010",
    "ITA": "BRUSSON",
    "state_code": "AO",
    "cab": "315200",
    "cap": "11022"
  },
  {
    "key": "2011",
    "ITA": "BRUZOLO",
    "state_code": "TO",
    "cab": "790907",
    "cap": "10050"
  },
  {
    "key": "2012",
    "ITA": "BRUZZANO ZEFFIRIO-MOTTICELLA",
    "state_code": "RC",
    "cab": "179937",
    "cap": "89030"
  },
  {
    "key": "2013",
    "ITA": "BRUZZANO ZEFFIRIO",
    "state_code": "RC",
    "cab": "179937",
    "cap": "89030"
  },
  {
    "key": "2014",
    "ITA": "BUBBIANO",
    "state_code": "MI",
    "cab": "903666",
    "cap": "20080"
  },
  {
    "key": "2015",
    "ITA": "BUBBIO",
    "state_code": "AT",
    "cab": "472506",
    "cap": "14051"
  },
  {
    "key": "2016",
    "ITA": "BUCCHERI",
    "state_code": "SR",
    "cab": "846402",
    "cap": "96010"
  },
  {
    "key": "2017",
    "ITA": "BUCCHERI-RIZZOLO",
    "state_code": "SR",
    "cab": "846402",
    "cap": "96010"
  },
  {
    "key": "2018",
    "ITA": "BUCCHIANICO-COLLE SANT'ANTONIO",
    "state_code": "CH",
    "cab": "775809",
    "cap": "66011"
  },
  {
    "key": "2019",
    "ITA": "BUCCHIANICO",
    "state_code": "CH",
    "cab": "775809",
    "cap": "66011"
  },
  {
    "key": "2020",
    "ITA": "BUCCIANO",
    "state_code": "BN",
    "cab": "950154",
    "cap": "82010"
  },
  {
    "key": "2021",
    "ITA": "BUCCINASCO-ROMANO BANCO",
    "state_code": "MI",
    "cab": "326504",
    "cap": "20090"
  },
  {
    "key": "2022",
    "ITA": "BUCCINASCO",
    "state_code": "MI",
    "cab": "326504",
    "cap": "20090"
  },
  {
    "key": "2023",
    "ITA": "BUCCINO-BUCCINO STAZIONE",
    "state_code": "SA",
    "cab": "766105",
    "cap": "84021"
  },
  {
    "key": "2024",
    "ITA": "BUCCINO",
    "state_code": "SA",
    "cab": "766105",
    "cap": "84021"
  },
  {
    "key": "2025",
    "ITA": "BUCCINO-TUFARIELLO",
    "state_code": "SA",
    "cab": "766105",
    "cap": "84021"
  },
  {
    "key": "2026",
    "ITA": "BUCINE-PIETRAVIVA",
    "state_code": "AR",
    "cab": "713404",
    "cap": "52020"
  },
  {
    "key": "2027",
    "ITA": "BUCINE-TORRE",
    "state_code": "AR",
    "cab": "713404",
    "cap": "52021"
  },
  {
    "key": "2028",
    "ITA": "BUCINE-CAPANNOLE",
    "state_code": "AR",
    "cab": "713404",
    "cap": "52021"
  },
  {
    "key": "2029",
    "ITA": "BUCINE-BADIA AGNANO",
    "state_code": "AR",
    "cab": "713404",
    "cap": "52021"
  },
  {
    "key": "2030",
    "ITA": "BUCINE-AMBRA",
    "state_code": "AR",
    "cab": "713404",
    "cap": "52020"
  },
  {
    "key": "2031",
    "ITA": "BUCINE",
    "state_code": "AR",
    "cab": "713404",
    "cap": "52021"
  },
  {
    "key": "2032",
    "ITA": "BUDDUSO'",
    "state_code": "OT",
    "cab": "849307",
    "cap": "7020"
  },
  {
    "key": "2033",
    "ITA": "BUDOIA-SANTA LUCIA DI BUDOIA",
    "state_code": "PN",
    "cab": "650903",
    "cap": "33070"
  },
  {
    "key": "2034",
    "ITA": "BUDOIA-DARDAGO",
    "state_code": "PN",
    "cab": "650903",
    "cap": "33070"
  },
  {
    "key": "2035",
    "ITA": "BUDOIA",
    "state_code": "PN",
    "cab": "650903",
    "cap": "33070"
  },
  {
    "key": "2036",
    "ITA": "BUDONI-SAN PIETRO",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2037",
    "ITA": "BUDONI-SAN SILVESTRO",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2038",
    "ITA": "BUDONI-S'ISCALA",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2039",
    "ITA": "BUDONI-SOLITA'",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2040",
    "ITA": "BUDONI-STRUGAS",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2041",
    "ITA": "BUDONI-SU LINALVU",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2042",
    "ITA": "BUDONI-TAMARISPA",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2043",
    "ITA": "BUDONI-TANAUNELLA",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2044",
    "ITA": "BUDONI",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2045",
    "ITA": "BUDONI-AGRUSTOS",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2046",
    "ITA": "BUDONI-BERRUILES",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2047",
    "ITA": "BUDONI-BIRGALAVO'",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2048",
    "ITA": "BUDONI-LIMPIDDU",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2049",
    "ITA": "BUDONI-LOTTURAI",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2050",
    "ITA": "BUDONI-LUDDUI",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2051",
    "ITA": "BUDONI-LUTTUNI",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2052",
    "ITA": "BUDONI-MAIORCA",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2053",
    "ITA": "BUDONI-MALAMORI'",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2054",
    "ITA": "BUDONI-MURISCUVO'",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2055",
    "ITA": "BUDONI-SAN LORENZO",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2056",
    "ITA": "BUDONI-SAN GAVINO",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2057",
    "ITA": "BUDONI-PEDRA E CUPA",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2058",
    "ITA": "BUDONI-NUDITTA",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2059",
    "ITA": "BUDONI-OTTIOLU",
    "state_code": "OT",
    "cab": "854307",
    "cap": "8020"
  },
  {
    "key": "2060",
    "ITA": "BUDRIO-VEDRANA",
    "state_code": "BO",
    "cab": "366401",
    "cap": "40054"
  },
  {
    "key": "2061",
    "ITA": "BUDRIO",
    "state_code": "BO",
    "cab": "366401",
    "cap": "40054"
  },
  {
    "key": "2062",
    "ITA": "BUDRIO-BAGNAROLA",
    "state_code": "BO",
    "cab": "366401",
    "cap": "40054"
  },
  {
    "key": "2063",
    "ITA": "BUDRIO-MEZZOLARA",
    "state_code": "BO",
    "cab": "366401",
    "cap": "40054"
  },
  {
    "key": "2064",
    "ITA": "BUDRIO-RICCARDINA",
    "state_code": "BO",
    "cab": "366401",
    "cap": "40054"
  },
  {
    "key": "2065",
    "ITA": "BUGGERRU",
    "state_code": "SU",
    "cab": "441709",
    "cap": "9010"
  },
  {
    "key": "2066",
    "ITA": "BUGGIANO-BORGO A BUGGIANO",
    "state_code": "PT",
    "cab": "703801",
    "cap": "51011"
  },
  {
    "key": "2067",
    "ITA": "BUGGIANO",
    "state_code": "PT",
    "cab": "703801",
    "cap": "51011"
  },
  {
    "key": "2068",
    "ITA": "BUGLIO IN MONTE-VILLAPINTA",
    "state_code": "SO",
    "cab": "282715",
    "cap": "23010"
  },
  {
    "key": "2069",
    "ITA": "BUGLIO IN MONTE",
    "state_code": "SO",
    "cab": "282715",
    "cap": "23010"
  },
  {
    "key": "2070",
    "ITA": "BUGNARA-TORRE DEI NOLFI",
    "state_code": "AQ",
    "cab": "909481",
    "cap": "67030"
  },
  {
    "key": "2071",
    "ITA": "BUGNARA",
    "state_code": "AQ",
    "cab": "909481",
    "cap": "67030"
  },
  {
    "key": "2072",
    "ITA": "BUGUGGIATE",
    "state_code": "VA",
    "cab": "507004",
    "cap": "21020"
  },
  {
    "key": "2073",
    "ITA": "BUJA-SANTO STEFANO DI BUJA",
    "state_code": "UD",
    "cab": "636803",
    "cap": "33030"
  },
  {
    "key": "2074",
    "ITA": "BUJA-URBIGNACCO",
    "state_code": "UD",
    "cab": "636803",
    "cap": "33030"
  },
  {
    "key": "2075",
    "ITA": "BUJA",
    "state_code": "UD",
    "cab": "636803",
    "cap": "33030"
  },
  {
    "key": "2076",
    "ITA": "BUJA-MADONNA DI BUJA",
    "state_code": "UD",
    "cab": "636803",
    "cap": "33030"
  },
  {
    "key": "2077",
    "ITA": "BUJA-AVILLA",
    "state_code": "UD",
    "cab": "636803",
    "cap": "33030"
  },
  {
    "key": "2078",
    "ITA": "BULCIAGO",
    "state_code": "LC",
    "cab": "510008",
    "cap": "23892"
  },
  {
    "key": "2079",
    "ITA": "BULGAROGRASSO",
    "state_code": "CO",
    "cab": "510107",
    "cap": "22070"
  },
  {
    "key": "2080",
    "ITA": "BULTEI",
    "state_code": "SS",
    "cab": "872606",
    "cap": "7010"
  },
  {
    "key": "2081",
    "ITA": "BULZI",
    "state_code": "SS",
    "cab": "872705",
    "cap": "7030"
  },
  {
    "key": "2082",
    "ITA": "BUONABITACOLO",
    "state_code": "SA",
    "cab": "761007",
    "cap": "84032"
  },
  {
    "key": "2083",
    "ITA": "BUONALBERGO",
    "state_code": "BN",
    "cab": "885400",
    "cap": "82020"
  },
  {
    "key": "2084",
    "ITA": "BUONCONVENTO",
    "state_code": "SI",
    "cab": "717702",
    "cap": "53022"
  },
  {
    "key": "2085",
    "ITA": "BUONVICINO",
    "state_code": "CS",
    "cab": "958637",
    "cap": "87020"
  },
  {
    "key": "2086",
    "ITA": "BURAGO DI MOLGORA",
    "state_code": "MB",
    "cab": "326603",
    "cap": "20040"
  },
  {
    "key": "2087",
    "ITA": "BURCEI",
    "state_code": "CA",
    "cab": "441808",
    "cap": "9040"
  },
  {
    "key": "2088",
    "ITA": "BURGIO",
    "state_code": "AG",
    "cab": "828202",
    "cap": "92010"
  },
  {
    "key": "2089",
    "ITA": "BURGOS-FORESTA BURGOS",
    "state_code": "SS",
    "cab": "872804",
    "cap": "7010"
  },
  {
    "key": "2090",
    "ITA": "BURGOS",
    "state_code": "SS",
    "cab": "872804",
    "cap": "7010"
  },
  {
    "key": "2091",
    "ITA": "BURIASCO",
    "state_code": "TO",
    "cab": "313809",
    "cap": "10060"
  },
  {
    "key": "2092",
    "ITA": "BUROLO",
    "state_code": "TO",
    "cab": "412700",
    "cap": "10010"
  },
  {
    "key": "2093",
    "ITA": "BURONZO",
    "state_code": "VC",
    "cab": "443408",
    "cap": "13040"
  },
  {
    "key": "2094",
    "ITA": "BUSACHI",
    "state_code": "OR",
    "cab": "878108",
    "cap": "9082"
  },
  {
    "key": "2095",
    "ITA": "BUSALLA",
    "state_code": "GE",
    "cab": "318709",
    "cap": "16012"
  },
  {
    "key": "2096",
    "ITA": "BUSALLA-SARISSOLA",
    "state_code": "GE",
    "cab": "318709",
    "cap": "16012"
  },
  {
    "key": "2097",
    "ITA": "BUSALLA-CAMARZA",
    "state_code": "GE",
    "cab": "318709",
    "cap": "16012"
  },
  {
    "key": "2098",
    "ITA": "BUSANA",
    "state_code": "RE",
    "cab": "661900",
    "cap": "42032"
  },
  {
    "key": "2099",
    "ITA": "BUSANA-CERVAREZZA",
    "state_code": "RE",
    "cab": "661900",
    "cap": "42032"
  },
  {
    "key": "2100",
    "ITA": "BUSANO",
    "state_code": "TO",
    "cab": "283192",
    "cap": "10080"
  },
  {
    "key": "2101",
    "ITA": "BUSCA-SAN CHIAFFREDO",
    "state_code": "CN",
    "cab": "460501",
    "cap": "12022"
  },
  {
    "key": "2102",
    "ITA": "BUSCA-CASTELLETTO BUSCA",
    "state_code": "CN",
    "cab": "460501",
    "cap": "12022"
  },
  {
    "key": "2103",
    "ITA": "BUSCA",
    "state_code": "CN",
    "cab": "460501",
    "cap": "12022"
  },
  {
    "key": "2104",
    "ITA": "BUSCATE",
    "state_code": "MI",
    "cab": "326702",
    "cap": "20010"
  },
  {
    "key": "2105",
    "ITA": "BUSCEMI",
    "state_code": "SR",
    "cab": "846501",
    "cap": "96010"
  },
  {
    "key": "2106",
    "ITA": "BUSETO PALIZZOLO",
    "state_code": "TP",
    "cab": "817908",
    "cap": "91012"
  },
  {
    "key": "2107",
    "ITA": "BUSETO PALIZZOLO-BATTAGLIA",
    "state_code": "TP",
    "cab": "817908",
    "cap": "91012"
  },
  {
    "key": "2108",
    "ITA": "BUSETO PALIZZOLO-BRUCA",
    "state_code": "TP",
    "cab": "817908",
    "cap": "91012"
  },
  {
    "key": "2109",
    "ITA": "BUSNAGO",
    "state_code": "MB",
    "cab": "890400",
    "cap": "20040"
  },
  {
    "key": "2110",
    "ITA": "BUSSERO",
    "state_code": "MI",
    "cab": "326801",
    "cap": "20060"
  },
  {
    "key": "2111",
    "ITA": "BUSSETO",
    "state_code": "PR",
    "cab": "656702",
    "cap": "43011"
  },
  {
    "key": "2112",
    "ITA": "BUSSETO-RONCOLE VERDI",
    "state_code": "PR",
    "cab": "656702",
    "cap": "43011"
  },
  {
    "key": "2113",
    "ITA": "BUSSI SUL TIRINO-BUSSI OFFICINE",
    "state_code": "PE",
    "cab": "772004",
    "cap": "65022"
  },
  {
    "key": "2114",
    "ITA": "BUSSI SUL TIRINO",
    "state_code": "PE",
    "cab": "772004",
    "cap": "65022"
  },
  {
    "key": "2115",
    "ITA": "BUSSO",
    "state_code": "CB",
    "cab": "910422",
    "cap": "86010"
  },
  {
    "key": "2116",
    "ITA": "BUSSOLENGO",
    "state_code": "VR",
    "cab": "593103",
    "cap": "37012"
  },
  {
    "key": "2117",
    "ITA": "BUSSOLENGO-SAN VITO AL MANTICO",
    "state_code": "VR",
    "cab": "593103",
    "cap": "37012"
  },
  {
    "key": "2118",
    "ITA": "BUSSOLENO-FORESTO",
    "state_code": "TO",
    "cab": "301804",
    "cap": "10053"
  },
  {
    "key": "2119",
    "ITA": "BUSSOLENO",
    "state_code": "TO",
    "cab": "301804",
    "cap": "10053"
  },
  {
    "key": "2120",
    "ITA": "BUSTO ARSIZIO-BORSANO",
    "state_code": "VA",
    "cab": "228007",
    "cap": "21052"
  },
  {
    "key": "2121",
    "ITA": "BUSTO ARSIZIO",
    "state_code": "VA",
    "cab": "228007",
    "cap": "21052"
  },
  {
    "key": "2122",
    "ITA": "BUSTO ARSIZIO-SACCONAGO",
    "state_code": "VA",
    "cab": "228007",
    "cap": "21052"
  },
  {
    "key": "2123",
    "ITA": "BUSTO GAROLFO",
    "state_code": "MI",
    "cab": "326900",
    "cap": "20020"
  },
  {
    "key": "2124",
    "ITA": "BUTERA",
    "state_code": "CL",
    "cab": "833004",
    "cap": "93011"
  },
  {
    "key": "2125",
    "ITA": "BUTI-LA CROCE",
    "state_code": "PI",
    "cab": "708909",
    "cap": "56032"
  },
  {
    "key": "2126",
    "ITA": "BUTI-CASCINE",
    "state_code": "PI",
    "cab": "708909",
    "cap": "56032"
  },
  {
    "key": "2127",
    "ITA": "BUTI",
    "state_code": "PI",
    "cab": "708909",
    "cap": "56032"
  },
  {
    "key": "2128",
    "ITA": "BUTTAPIETRA",
    "state_code": "VR",
    "cab": "600007",
    "cap": "37060"
  },
  {
    "key": "2129",
    "ITA": "BUTTAPIETRA-BOVO",
    "state_code": "VR",
    "cab": "600007",
    "cap": "37060"
  },
  {
    "key": "2130",
    "ITA": "BUTTAPIETRA-MARCHESINO",
    "state_code": "VR",
    "cab": "600007",
    "cap": "37060"
  },
  {
    "key": "2131",
    "ITA": "BUTTIGLIERA ALTA",
    "state_code": "TO",
    "cab": "301903",
    "cap": "10090"
  },
  {
    "key": "2132",
    "ITA": "BUTTIGLIERA ALTA-FERRIERA",
    "state_code": "TO",
    "cab": "301903",
    "cap": "10090"
  },
  {
    "key": "2133",
    "ITA": "BUTTIGLIERA D'ASTI",
    "state_code": "AT",
    "cab": "472605",
    "cap": "14021"
  },
  {
    "key": "2134",
    "ITA": "BUTTRIO",
    "state_code": "UD",
    "cab": "636902",
    "cap": "33042"
  },
  {
    "key": "2135",
    "ITA": "CA' D'ANDREA",
    "state_code": "CR",
    "cab": "931519",
    "cap": "26030"
  },
  {
    "key": "2136",
    "ITA": "CABELLA LIGURE",
    "state_code": "AL",
    "cab": "480707",
    "cap": "15060"
  },
  {
    "key": "2137",
    "ITA": "CABELLA LIGURE-COSOLA DI CABELLA",
    "state_code": "AL",
    "cab": "480707",
    "cap": "15060"
  },
  {
    "key": "2138",
    "ITA": "CABIATE",
    "state_code": "CO",
    "cab": "510206",
    "cap": "22060"
  },
  {
    "key": "2139",
    "ITA": "CABRAS-SOLANAS",
    "state_code": "OR",
    "cab": "855601",
    "cap": "9072"
  },
  {
    "key": "2140",
    "ITA": "CABRAS",
    "state_code": "OR",
    "cab": "855601",
    "cap": "9072"
  },
  {
    "key": "2141",
    "ITA": "CACCAMO-SAN GIOVANNI LI GRECI",
    "state_code": "PA",
    "cab": "431502",
    "cap": "90012"
  },
  {
    "key": "2142",
    "ITA": "CACCAMO-SAMBUCHI",
    "state_code": "PA",
    "cab": "431502",
    "cap": "90012"
  },
  {
    "key": "2143",
    "ITA": "CACCAMO",
    "state_code": "PA",
    "cab": "431502",
    "cap": "90012"
  },
  {
    "key": "2144",
    "ITA": "CACCURI",
    "state_code": "KR",
    "cab": "887109",
    "cap": "88833"
  },
  {
    "key": "2145",
    "ITA": "CADEGLIANO VICONAGO",
    "state_code": "VA",
    "cab": "924274",
    "cap": "21031"
  },
  {
    "key": "2146",
    "ITA": "CADEGLIANO VICONAGO-CADEGLIANO",
    "state_code": "VA",
    "cab": "924274",
    "cap": "21031"
  },
  {
    "key": "2147",
    "ITA": "CADEGLIANO VICONAGO-VICONAGO",
    "state_code": "VA",
    "cab": "924274",
    "cap": "21031"
  },
  {
    "key": "2148",
    "ITA": "CADELBOSCO DI SOPRA-ARGINE",
    "state_code": "RE",
    "cab": "662007",
    "cap": "42023"
  },
  {
    "key": "2149",
    "ITA": "CADELBOSCO DI SOPRA-CADELBOSCO DI SOTTO",
    "state_code": "RE",
    "cab": "662007",
    "cap": "42023"
  },
  {
    "key": "2150",
    "ITA": "CADELBOSCO DI SOPRA",
    "state_code": "RE",
    "cab": "662007",
    "cap": "42023"
  },
  {
    "key": "2151",
    "ITA": "CADEO-ROVELETO",
    "state_code": "PC",
    "cab": "652107",
    "cap": "29010"
  },
  {
    "key": "2152",
    "ITA": "CADEO-FONTANA FREDDA",
    "state_code": "PC",
    "cab": "652107",
    "cap": "29010"
  },
  {
    "key": "2153",
    "ITA": "CADEO",
    "state_code": "PC",
    "cab": "652107",
    "cap": "29010"
  },
  {
    "key": "2154",
    "ITA": "CADERZONE",
    "state_code": "TN",
    "cab": "904698",
    "cap": "38080"
  },
  {
    "key": "2155",
    "ITA": "CADONEGHE-MEJANIGA",
    "state_code": "PD",
    "cab": "624106",
    "cap": "35010"
  },
  {
    "key": "2156",
    "ITA": "CADONEGHE",
    "state_code": "PD",
    "cab": "624106",
    "cap": "35010"
  },
  {
    "key": "2157",
    "ITA": "CADORAGO-BULGORELLO",
    "state_code": "CO",
    "cab": "510305",
    "cap": "22071"
  },
  {
    "key": "2158",
    "ITA": "CADORAGO-CASLINO AL PIANO",
    "state_code": "CO",
    "cab": "510305",
    "cap": "22071"
  },
  {
    "key": "2159",
    "ITA": "CADORAGO",
    "state_code": "CO",
    "cab": "510305",
    "cap": "22071"
  },
  {
    "key": "2160",
    "ITA": "CADREZZATE",
    "state_code": "VA",
    "cab": "924282",
    "cap": "21020"
  },
  {
    "key": "2161",
    "ITA": "CAERANO DI SAN MARCO",
    "state_code": "TV",
    "cab": "615104",
    "cap": "31031"
  },
  {
    "key": "2162",
    "ITA": "CAFASSE-MONASTEROLO",
    "state_code": "TO",
    "cab": "302000",
    "cap": "10070"
  },
  {
    "key": "2163",
    "ITA": "CAFASSE",
    "state_code": "TO",
    "cab": "302000",
    "cap": "10070"
  },
  {
    "key": "2164",
    "ITA": "CAGGIANO",
    "state_code": "SA",
    "cab": "761106",
    "cap": "84030"
  },
  {
    "key": "2165",
    "ITA": "CAGLI-SECCHIANO",
    "state_code": "PU",
    "cab": "682302",
    "cap": "61043"
  },
  {
    "key": "2166",
    "ITA": "CAGLI",
    "state_code": "PU",
    "cab": "682302",
    "cap": "61043"
  },
  {
    "key": "2167",
    "ITA": "CAGLI-ACQUAVIVA MARCHE",
    "state_code": "PU",
    "cab": "682302",
    "cap": "61043"
  },
  {
    "key": "2168",
    "ITA": "CAGLI-PIANELLO",
    "state_code": "PU",
    "cab": "682302",
    "cap": "61043"
  },
  {
    "key": "2169",
    "ITA": "CAGLI-SMIRRA",
    "state_code": "PU",
    "cab": "682302",
    "cap": "61043"
  },
  {
    "key": "2170",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9124"
  },
  {
    "key": "2171",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9134"
  },
  {
    "key": "2172",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9131"
  },
  {
    "key": "2173",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9129"
  },
  {
    "key": "2174",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9128"
  },
  {
    "key": "2175",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9121"
  },
  {
    "key": "2176",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9122"
  },
  {
    "key": "2177",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9123"
  },
  {
    "key": "2178",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9125"
  },
  {
    "key": "2179",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9126"
  },
  {
    "key": "2180",
    "ITA": "CAGLIARI",
    "state_code": "CA",
    "cab": "48009",
    "cap": "9127"
  },
  {
    "key": "2181",
    "ITA": "CAGLIO",
    "state_code": "CO",
    "cab": "925230",
    "cap": "22030"
  },
  {
    "key": "2182",
    "ITA": "CAGNANO AMITERNO",
    "state_code": "AQ",
    "cab": "404608",
    "cap": "67012"
  },
  {
    "key": "2183",
    "ITA": "CAGNANO AMITERNO-SAN GIOVANNI",
    "state_code": "AQ",
    "cab": "404608",
    "cap": "67012"
  },
  {
    "key": "2184",
    "ITA": "CAGNANO VARANO",
    "state_code": "FG",
    "cab": "783100",
    "cap": "71010"
  },
  {
    "key": "2185",
    "ITA": "CAGNO",
    "state_code": "CO",
    "cab": "925248",
    "cap": "22070"
  },
  {
    "key": "2186",
    "ITA": "CAGNO'",
    "state_code": "TN",
    "cab": "889402",
    "cap": "38028"
  },
  {
    "key": "2187",
    "ITA": "CAIANELLO",
    "state_code": "CE",
    "cab": "949131",
    "cap": "81059"
  },
  {
    "key": "2188",
    "ITA": "CAIANELLO-MONTANO",
    "state_code": "CE",
    "cab": "949131",
    "cap": "81059"
  },
  {
    "key": "2189",
    "ITA": "CAIANELLO-SANTA LUCIA",
    "state_code": "CE",
    "cab": "949131",
    "cap": "81059"
  },
  {
    "key": "2190",
    "ITA": "CAIAZZO",
    "state_code": "CE",
    "cab": "748004",
    "cap": "81013"
  },
  {
    "key": "2191",
    "ITA": "CAIAZZO-SAN GIOVANNI E PAOLO",
    "state_code": "CE",
    "cab": "748004",
    "cap": "81013"
  },
  {
    "key": "2192",
    "ITA": "CAINES",
    "state_code": "BZ",
    "cab": "932715",
    "cap": "39010"
  },
  {
    "key": "2193",
    "ITA": "CAINO",
    "state_code": "BS",
    "cab": "837906",
    "cap": "25070"
  },
  {
    "key": "2194",
    "ITA": "CAIOLO",
    "state_code": "SO",
    "cab": "926964",
    "cap": "23010"
  },
  {
    "key": "2195",
    "ITA": "CAIRANO",
    "state_code": "AV",
    "cab": "950956",
    "cap": "83040"
  },
  {
    "key": "2196",
    "ITA": "CAIRATE-BOLLADELLO",
    "state_code": "VA",
    "cab": "500504",
    "cap": "21050"
  },
  {
    "key": "2197",
    "ITA": "CAIRATE",
    "state_code": "VA",
    "cab": "500504",
    "cap": "21050"
  },
  {
    "key": "2198",
    "ITA": "CAIRO MONTENOTTE-SAN GIUSEPPE",
    "state_code": "SV",
    "cab": "493304",
    "cap": "17014"
  },
  {
    "key": "2199",
    "ITA": "CAIRO MONTENOTTE-FERRANIA",
    "state_code": "SV",
    "cab": "493304",
    "cap": "17014"
  },
  {
    "key": "2200",
    "ITA": "CAIRO MONTENOTTE-BRAGNO",
    "state_code": "SV",
    "cab": "493304",
    "cap": "17014"
  },
  {
    "key": "2201",
    "ITA": "CAIRO MONTENOTTE",
    "state_code": "SV",
    "cab": "493304",
    "cap": "17014"
  },
  {
    "key": "2202",
    "ITA": "CAIRO MONTENOTTE-ROCCHETTA CAIRO",
    "state_code": "SV",
    "cab": "493304",
    "cap": "17014"
  },
  {
    "key": "2203",
    "ITA": "CAIVANO",
    "state_code": "NA",
    "cab": "397802",
    "cap": "80023"
  },
  {
    "key": "2204",
    "ITA": "CAIVANO-PASCAROLA",
    "state_code": "NA",
    "cab": "397802",
    "cap": "80023"
  },
  {
    "key": "2205",
    "ITA": "CALABRITTO-QUAGLIETTA",
    "state_code": "AV",
    "cab": "759001",
    "cap": "83040"
  },
  {
    "key": "2206",
    "ITA": "CALABRITTO",
    "state_code": "AV",
    "cab": "759001",
    "cap": "83040"
  },
  {
    "key": "2207",
    "ITA": "CALALZO DI CADORE",
    "state_code": "BL",
    "cab": "610402",
    "cap": "32042"
  },
  {
    "key": "2208",
    "ITA": "CALAMANDRANA",
    "state_code": "AT",
    "cab": "472704",
    "cap": "14042"
  },
  {
    "key": "2209",
    "ITA": "CALAMONACI",
    "state_code": "AG",
    "cab": "828301",
    "cap": "92010"
  },
  {
    "key": "2210",
    "ITA": "CALANGIANUS",
    "state_code": "OT",
    "cab": "849406",
    "cap": "7023"
  },
  {
    "key": "2211",
    "ITA": "CALANNA",
    "state_code": "RC",
    "cab": "959999",
    "cap": "89050"
  },
  {
    "key": "2212",
    "ITA": "CALANNA-VILLA MESA",
    "state_code": "RC",
    "cab": "959999",
    "cap": "89050"
  },
  {
    "key": "2213",
    "ITA": "CALASCA CASTIGLIONE-CASTIGLIONE",
    "state_code": "VB",
    "cab": "917047",
    "cap": "28873"
  },
  {
    "key": "2214",
    "ITA": "CALASCA CASTIGLIONE",
    "state_code": "VB",
    "cab": "917047",
    "cap": "28873"
  },
  {
    "key": "2215",
    "ITA": "CALASCIBETTA-CACCHIAMO",
    "state_code": "EN",
    "cab": "836106",
    "cap": "94010"
  },
  {
    "key": "2216",
    "ITA": "CALASCIBETTA",
    "state_code": "EN",
    "cab": "836106",
    "cap": "94010"
  },
  {
    "key": "2217",
    "ITA": "CALASCIO",
    "state_code": "AQ",
    "cab": "909499",
    "cap": "67020"
  },
  {
    "key": "2218",
    "ITA": "CALASETTA",
    "state_code": "SU",
    "cab": "441907",
    "cap": "9011"
  },
  {
    "key": "2219",
    "ITA": "CALASETTA-CUSSORGIA",
    "state_code": "SU",
    "cab": "441907",
    "cap": "9011"
  },
  {
    "key": "2220",
    "ITA": "CALATABIANO-PASTERIA",
    "state_code": "CT",
    "cab": "839001",
    "cap": "95011"
  },
  {
    "key": "2221",
    "ITA": "CALATABIANO",
    "state_code": "CT",
    "cab": "839001",
    "cap": "95011"
  },
  {
    "key": "2222",
    "ITA": "CALATAFIMI SEGESTA-SASI",
    "state_code": "TP",
    "cab": "818005",
    "cap": "91013"
  },
  {
    "key": "2223",
    "ITA": "CALATAFIMI SEGESTA",
    "state_code": "TP",
    "cab": "818005",
    "cap": "91013"
  },
  {
    "key": "2224",
    "ITA": "CALATAFIMI SEGESTA-CALATAFIMI",
    "state_code": "TP",
    "cab": "818005",
    "cap": "91013"
  },
  {
    "key": "2225",
    "ITA": "CALAVINO-SARCHE",
    "state_code": "TN",
    "cab": "344408",
    "cap": "38072"
  },
  {
    "key": "2226",
    "ITA": "CALAVINO",
    "state_code": "TN",
    "cab": "344408",
    "cap": "38072"
  },
  {
    "key": "2227",
    "ITA": "CALCATA-CALCATA NUOVA",
    "state_code": "VT",
    "cab": "946723",
    "cap": "1030"
  },
  {
    "key": "2228",
    "ITA": "CALCATA",
    "state_code": "VT",
    "cab": "946723",
    "cap": "1030"
  },
  {
    "key": "2229",
    "ITA": "CALCERANICA AL LAGO",
    "state_code": "TN",
    "cab": "344507",
    "cap": "38050"
  },
  {
    "key": "2230",
    "ITA": "CALCI-MONTEMAGNO",
    "state_code": "PI",
    "cab": "709006",
    "cap": "56011"
  },
  {
    "key": "2231",
    "ITA": "CALCI-GABELLA",
    "state_code": "PI",
    "cab": "709006",
    "cap": "56011"
  },
  {
    "key": "2232",
    "ITA": "CALCI",
    "state_code": "PI",
    "cab": "709006",
    "cap": "56011"
  },
  {
    "key": "2233",
    "ITA": "CALCI-CASTELMAGGIORE",
    "state_code": "PI",
    "cab": "709006",
    "cap": "56011"
  },
  {
    "key": "2234",
    "ITA": "CALCIANO",
    "state_code": "MT",
    "cab": "958116",
    "cap": "75010"
  },
  {
    "key": "2235",
    "ITA": "CALCINAIA-FORNACETTE",
    "state_code": "PI",
    "cab": "709105",
    "cap": "56012"
  },
  {
    "key": "2236",
    "ITA": "CALCINAIA",
    "state_code": "PI",
    "cab": "709105",
    "cap": "56012"
  },
  {
    "key": "2237",
    "ITA": "CALCINATE",
    "state_code": "BG",
    "cab": "526905",
    "cap": "24050"
  },
  {
    "key": "2238",
    "ITA": "CALCINATO-PONTE SAN MARCO",
    "state_code": "BS",
    "cab": "541706",
    "cap": "25011"
  },
  {
    "key": "2239",
    "ITA": "CALCINATO",
    "state_code": "BS",
    "cab": "541706",
    "cap": "25011"
  },
  {
    "key": "2240",
    "ITA": "CALCINATO-CALCINATELLO",
    "state_code": "BS",
    "cab": "541706",
    "cap": "25011"
  },
  {
    "key": "2241",
    "ITA": "CALCIO",
    "state_code": "BG",
    "cab": "527002",
    "cap": "24054"
  },
  {
    "key": "2242",
    "ITA": "CALCO",
    "state_code": "LC",
    "cab": "510404",
    "cap": "23885"
  },
  {
    "key": "2243",
    "ITA": "CALCO-ARLATE",
    "state_code": "LC",
    "cab": "510404",
    "cap": "23885"
  },
  {
    "key": "2244",
    "ITA": "CALDARO SULLA STRADA DEL VINO",
    "state_code": "BZ",
    "cab": "582502",
    "cap": "39052"
  },
  {
    "key": "2245",
    "ITA": "CALDAROLA-VALCIMARRA",
    "state_code": "MC",
    "cab": "688200",
    "cap": "62020"
  },
  {
    "key": "2246",
    "ITA": "CALDAROLA",
    "state_code": "MC",
    "cab": "688200",
    "cap": "62020"
  },
  {
    "key": "2247",
    "ITA": "CALDERARA DI RENO-LIPPO",
    "state_code": "BO",
    "cab": "366500",
    "cap": "40012"
  },
  {
    "key": "2248",
    "ITA": "CALDERARA DI RENO-BARGELLINO",
    "state_code": "BO",
    "cab": "366500",
    "cap": "40012"
  },
  {
    "key": "2249",
    "ITA": "CALDERARA DI RENO",
    "state_code": "BO",
    "cab": "366500",
    "cap": "40012"
  },
  {
    "key": "2250",
    "ITA": "CALDERARA DI RENO-LONGARA",
    "state_code": "BO",
    "cab": "366500",
    "cap": "40012"
  },
  {
    "key": "2251",
    "ITA": "CALDERARA DI RENO-TAVERNELLE D'EMILIA",
    "state_code": "BO",
    "cab": "366500",
    "cap": "40012"
  },
  {
    "key": "2252",
    "ITA": "CALDES",
    "state_code": "TN",
    "cab": "344606",
    "cap": "38022"
  },
  {
    "key": "2253",
    "ITA": "CALDES-BOZZANA",
    "state_code": "TN",
    "cab": "344606",
    "cap": "38022"
  },
  {
    "key": "2254",
    "ITA": "CALDIERO-CALDIERINO",
    "state_code": "VR",
    "cab": "593202",
    "cap": "37042"
  },
  {
    "key": "2255",
    "ITA": "CALDIERO",
    "state_code": "VR",
    "cab": "593202",
    "cap": "37042"
  },
  {
    "key": "2256",
    "ITA": "CALDOGNO-CRESOLE",
    "state_code": "VI",
    "cab": "602102",
    "cap": "36030"
  },
  {
    "key": "2257",
    "ITA": "CALDOGNO",
    "state_code": "VI",
    "cab": "602102",
    "cap": "36030"
  },
  {
    "key": "2258",
    "ITA": "CALDOGNO-RETTORGOLE",
    "state_code": "VI",
    "cab": "602102",
    "cap": "36030"
  },
  {
    "key": "2259",
    "ITA": "CALDONAZZO",
    "state_code": "TN",
    "cab": "344705",
    "cap": "38052"
  },
  {
    "key": "2260",
    "ITA": "CALENDASCO-SAN NICOLO' A TREBBIA",
    "state_code": "PC",
    "cab": "652206",
    "cap": "29010"
  },
  {
    "key": "2261",
    "ITA": "CALENDASCO",
    "state_code": "PC",
    "cab": "652206",
    "cap": "29010"
  },
  {
    "key": "2262",
    "ITA": "CALENZANO-CROCI DI CALENZANO",
    "state_code": "FI",
    "cab": "377606",
    "cap": "50041"
  },
  {
    "key": "2263",
    "ITA": "CALENZANO-CARRAIA",
    "state_code": "FI",
    "cab": "377606",
    "cap": "50041"
  },
  {
    "key": "2264",
    "ITA": "CALENZANO",
    "state_code": "FI",
    "cab": "377606",
    "cap": "50041"
  },
  {
    "key": "2265",
    "ITA": "CALENZANO-SETTIMELLO",
    "state_code": "FI",
    "cab": "377606",
    "cap": "50041"
  },
  {
    "key": "2266",
    "ITA": "CALESTANO-RAVARANO",
    "state_code": "PR",
    "cab": "656801",
    "cap": "43030"
  },
  {
    "key": "2267",
    "ITA": "CALESTANO-MARZOLARA",
    "state_code": "PR",
    "cab": "656801",
    "cap": "43030"
  },
  {
    "key": "2268",
    "ITA": "CALESTANO",
    "state_code": "PR",
    "cab": "656801",
    "cap": "43030"
  },
  {
    "key": "2269",
    "ITA": "CALICE AL CORNOVIGLIO",
    "state_code": "SP",
    "cab": "497305",
    "cap": "19020"
  },
  {
    "key": "2270",
    "ITA": "CALICE LIGURE",
    "state_code": "SV",
    "cab": "923052",
    "cap": "17020"
  },
  {
    "key": "2271",
    "ITA": "CALIMERA",
    "state_code": "LE",
    "cab": "794701",
    "cap": "73021"
  },
  {
    "key": "2272",
    "ITA": "CALITRI",
    "state_code": "AV",
    "cab": "757005",
    "cap": "83045"
  },
  {
    "key": "2273",
    "ITA": "CALIZZANO-CARAGNA",
    "state_code": "SV",
    "cab": "493403",
    "cap": "17057"
  },
  {
    "key": "2274",
    "ITA": "CALIZZANO",
    "state_code": "SV",
    "cab": "493403",
    "cap": "17057"
  },
  {
    "key": "2275",
    "ITA": "CALLABIANA",
    "state_code": "BI",
    "cab": "915686",
    "cap": "13821"
  },
  {
    "key": "2276",
    "ITA": "CALLIANO-SAN DESIDERIO",
    "state_code": "AT",
    "cab": "472803",
    "cap": "14031"
  },
  {
    "key": "2277",
    "ITA": "CALLIANO",
    "state_code": "AT",
    "cab": "472803",
    "cap": "14031"
  },
  {
    "key": "2278",
    "ITA": "CALLIANO",
    "state_code": "TN",
    "cab": "344804",
    "cap": "38060"
  },
  {
    "key": "2279",
    "ITA": "CALOLZIOCORTE",
    "state_code": "LC",
    "cab": "527101",
    "cap": "23801"
  },
  {
    "key": "2280",
    "ITA": "CALOLZIOCORTE-ROSSINO",
    "state_code": "LC",
    "cab": "527101",
    "cap": "23801"
  },
  {
    "key": "2281",
    "ITA": "CALOPEZZATI",
    "state_code": "CS",
    "cab": "323204",
    "cap": "87060"
  },
  {
    "key": "2282",
    "ITA": "CALOPEZZATI-MARINELLA",
    "state_code": "CS",
    "cab": "323204",
    "cap": "87060"
  },
  {
    "key": "2283",
    "ITA": "CALOSSO",
    "state_code": "AT",
    "cab": "472902",
    "cap": "14052"
  },
  {
    "key": "2284",
    "ITA": "CALOVETO",
    "state_code": "CS",
    "cab": "958652",
    "cap": "87060"
  },
  {
    "key": "2285",
    "ITA": "CALTABELLOTTA",
    "state_code": "AG",
    "cab": "828400",
    "cap": "92010"
  },
  {
    "key": "2286",
    "ITA": "CALTABELLOTTA-SANT'ANNA",
    "state_code": "AG",
    "cab": "828400",
    "cap": "92010"
  },
  {
    "key": "2287",
    "ITA": "CALTAGIRONE-SANTO PIETRO",
    "state_code": "CT",
    "cab": "839100",
    "cap": "95041"
  },
  {
    "key": "2288",
    "ITA": "CALTAGIRONE-GRANIERI",
    "state_code": "CT",
    "cab": "839100",
    "cap": "95041"
  },
  {
    "key": "2289",
    "ITA": "CALTAGIRONE",
    "state_code": "CT",
    "cab": "839100",
    "cap": "95041"
  },
  {
    "key": "2290",
    "ITA": "CALTANISSETTA-FAVARELLA",
    "state_code": "CL",
    "cab": "167007",
    "cap": "93100"
  },
  {
    "key": "2291",
    "ITA": "CALTANISSETTA-BORGO PETILIA",
    "state_code": "CL",
    "cab": "167007",
    "cap": "93100"
  },
  {
    "key": "2292",
    "ITA": "CALTANISSETTA",
    "state_code": "CL",
    "cab": "167007",
    "cap": "93100"
  },
  {
    "key": "2293",
    "ITA": "CALTANISSETTA-VILLAGGIO SANTA BARBARA",
    "state_code": "CL",
    "cab": "167007",
    "cap": "93100"
  },
  {
    "key": "2294",
    "ITA": "CALTAVUTURO",
    "state_code": "PA",
    "cab": "431601",
    "cap": "90022"
  },
  {
    "key": "2295",
    "ITA": "CALTIGNAGA",
    "state_code": "NO",
    "cab": "457903",
    "cap": "28010"
  },
  {
    "key": "2296",
    "ITA": "CALTIGNAGA-SOLOGNO",
    "state_code": "NO",
    "cab": "457903",
    "cap": "28010"
  },
  {
    "key": "2297",
    "ITA": "CALTO",
    "state_code": "RO",
    "cab": "936419",
    "cap": "45030"
  },
  {
    "key": "2298",
    "ITA": "CALTRANO",
    "state_code": "VI",
    "cab": "602201",
    "cap": "36030"
  },
  {
    "key": "2299",
    "ITA": "CALUSCO D'ADDA",
    "state_code": "BG",
    "cab": "527200",
    "cap": "24033"
  },
  {
    "key": "2300",
    "ITA": "CALUSO-RODALLO",
    "state_code": "TO",
    "cab": "302109",
    "cap": "10014"
  },
  {
    "key": "2301",
    "ITA": "CALUSO-ARE'",
    "state_code": "TO",
    "cab": "302109",
    "cap": "10014"
  },
  {
    "key": "2302",
    "ITA": "CALUSO",
    "state_code": "TO",
    "cab": "302109",
    "cap": "10014"
  },
  {
    "key": "2303",
    "ITA": "CALUSO-VALLO",
    "state_code": "TO",
    "cab": "302109",
    "cap": "10014"
  },
  {
    "key": "2304",
    "ITA": "CALVAGESE DELLA RIVIERA-CARZAGO RIVIERA",
    "state_code": "BS",
    "cab": "541805",
    "cap": "25080"
  },
  {
    "key": "2305",
    "ITA": "CALVAGESE DELLA RIVIERA",
    "state_code": "BS",
    "cab": "541805",
    "cap": "25080"
  },
  {
    "key": "2306",
    "ITA": "CALVANICO",
    "state_code": "SA",
    "cab": "952291",
    "cap": "84080"
  },
  {
    "key": "2307",
    "ITA": "CALVATONE",
    "state_code": "CR",
    "cab": "566703",
    "cap": "26030"
  },
  {
    "key": "2308",
    "ITA": "CALVELLO",
    "state_code": "PZ",
    "cab": "419101",
    "cap": "85010"
  },
  {
    "key": "2309",
    "ITA": "CALVENE",
    "state_code": "VI",
    "cab": "651109",
    "cap": "36030"
  },
  {
    "key": "2310",
    "ITA": "CALVENZANO",
    "state_code": "BG",
    "cab": "527309",
    "cap": "24040"
  },
  {
    "key": "2311",
    "ITA": "CALVERA",
    "state_code": "PZ",
    "cab": "911875",
    "cap": "85030"
  },
  {
    "key": "2312",
    "ITA": "CALVI-CUBANTE",
    "state_code": "BN",
    "cab": "752808",
    "cap": "82018"
  },
  {
    "key": "2313",
    "ITA": "CALVI",
    "state_code": "BN",
    "cab": "752808",
    "cap": "82018"
  },
  {
    "key": "2314",
    "ITA": "CALVI DELL'UMBRIA",
    "state_code": "TR",
    "cab": "725705",
    "cap": "5032"
  },
  {
    "key": "2315",
    "ITA": "CALVI DELL'UMBRIA-SANTA MARIA DELLA NEVE",
    "state_code": "TR",
    "cab": "725705",
    "cap": "5032"
  },
  {
    "key": "2316",
    "ITA": "CALVI RISORTA-VISCIANO",
    "state_code": "CE",
    "cab": "751800",
    "cap": "81042"
  },
  {
    "key": "2317",
    "ITA": "CALVI RISORTA",
    "state_code": "CE",
    "cab": "751800",
    "cap": "81042"
  },
  {
    "key": "2318",
    "ITA": "CALVIGNANO",
    "state_code": "PV",
    "cab": "930263",
    "cap": "27040"
  },
  {
    "key": "2319",
    "ITA": "CALVIGNASCO",
    "state_code": "MI",
    "cab": "802702",
    "cap": "20080"
  },
  {
    "key": "2320",
    "ITA": "CALVISANO",
    "state_code": "BS",
    "cab": "541904",
    "cap": "25012"
  },
  {
    "key": "2321",
    "ITA": "CALVISANO-MEZZANE",
    "state_code": "BS",
    "cab": "541904",
    "cap": "25012"
  },
  {
    "key": "2322",
    "ITA": "CALVISANO-VIADANA",
    "state_code": "BS",
    "cab": "541904",
    "cap": "25012"
  },
  {
    "key": "2323",
    "ITA": "CALVISANO-MALPAGA",
    "state_code": "BS",
    "cab": "541904",
    "cap": "25012"
  },
  {
    "key": "2324",
    "ITA": "CALVIZZANO",
    "state_code": "NA",
    "cab": "376608",
    "cap": "80012"
  },
  {
    "key": "2325",
    "ITA": "CAMAGNA MONFERRATO",
    "state_code": "AL",
    "cab": "480806",
    "cap": "15030"
  },
  {
    "key": "2326",
    "ITA": "CAMAIORE-PEDONA",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2327",
    "ITA": "CAMAIORE-NOCCHI",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2328",
    "ITA": "CAMAIORE-MONTEMAGNO",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2329",
    "ITA": "CAMAIORE-MONTEBELLO",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2330",
    "ITA": "CAMAIORE-LIDO DI CAMAIORE",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2331",
    "ITA": "CAMAIORE-VALPROMARO",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2332",
    "ITA": "CAMAIORE-CASOLI",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2333",
    "ITA": "CAMAIORE-PIEVE",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2334",
    "ITA": "CAMAIORE-VADO",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2335",
    "ITA": "CAMAIORE-CAPEZZANO PIANORE",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2336",
    "ITA": "CAMAIORE",
    "state_code": "LU",
    "cab": "246009",
    "cap": "55041"
  },
  {
    "key": "2337",
    "ITA": "CAMAIRAGO",
    "state_code": "LO",
    "cab": "903690",
    "cap": "26823"
  },
  {
    "key": "2338",
    "ITA": "CAMANDONA",
    "state_code": "BI",
    "cab": "915694",
    "cap": "13821"
  },
  {
    "key": "2339",
    "ITA": "CAMANDONA-PIANEZZE",
    "state_code": "BI",
    "cab": "915694",
    "cap": "13821"
  },
  {
    "key": "2340",
    "ITA": "CAMASTRA",
    "state_code": "AG",
    "cab": "828509",
    "cap": "92020"
  },
  {
    "key": "2341",
    "ITA": "CAMBIAGO",
    "state_code": "MI",
    "cab": "327007",
    "cap": "20040"
  },
  {
    "key": "2342",
    "ITA": "CAMBIAGO-TORRAZZA DEI MANDELLI",
    "state_code": "MI",
    "cab": "327007",
    "cap": "20040"
  },
  {
    "key": "2343",
    "ITA": "CAMBIANO-MADONNA DELLA SCALA",
    "state_code": "TO",
    "cab": "302208",
    "cap": "10020"
  },
  {
    "key": "2344",
    "ITA": "CAMBIANO",
    "state_code": "TO",
    "cab": "302208",
    "cap": "10020"
  },
  {
    "key": "2345",
    "ITA": "CAMBIASCA",
    "state_code": "VB",
    "cab": "471102",
    "cap": "28814"
  },
  {
    "key": "2346",
    "ITA": "CAMBURZANO",
    "state_code": "BI",
    "cab": "417709",
    "cap": "13891"
  },
  {
    "key": "2347",
    "ITA": "CAMERANA",
    "state_code": "CN",
    "cab": "492207",
    "cap": "12072"
  },
  {
    "key": "2348",
    "ITA": "CAMERANO-ASPIO TERME",
    "state_code": "AN",
    "cab": "372607",
    "cap": "60021"
  },
  {
    "key": "2349",
    "ITA": "CAMERANO",
    "state_code": "AN",
    "cab": "372607",
    "cap": "60021"
  },
  {
    "key": "2350",
    "ITA": "CAMERANO CASASCO",
    "state_code": "AT",
    "cab": "286799",
    "cap": "14020"
  },
  {
    "key": "2351",
    "ITA": "CAMERATA CORNELLO",
    "state_code": "BG",
    "cab": "927905",
    "cap": "24010"
  },
  {
    "key": "2352",
    "ITA": "CAMERATA NUOVA",
    "state_code": "RM",
    "cab": "908038",
    "cap": "20"
  },
  {
    "key": "2353",
    "ITA": "CAMERATA PICENA",
    "state_code": "AN",
    "cab": "376400",
    "cap": "60020"
  },
  {
    "key": "2354",
    "ITA": "CAMERI",
    "state_code": "NO",
    "cab": "452508",
    "cap": "28062"
  },
  {
    "key": "2355",
    "ITA": "CAMERI-CAMERI AERONAUTICA",
    "state_code": "NO",
    "cab": "452508",
    "cap": "28062"
  },
  {
    "key": "2356",
    "ITA": "CAMERINO-MORRO",
    "state_code": "MC",
    "cab": "688309",
    "cap": "62032"
  },
  {
    "key": "2357",
    "ITA": "CAMERINO-POLVERINA",
    "state_code": "MC",
    "cab": "688309",
    "cap": "62032"
  },
  {
    "key": "2358",
    "ITA": "CAMERINO-SAN LUCA",
    "state_code": "MC",
    "cab": "688309",
    "cap": "62032"
  },
  {
    "key": "2359",
    "ITA": "CAMERINO",
    "state_code": "MC",
    "cab": "688309",
    "cap": "62032"
  },
  {
    "key": "2360",
    "ITA": "CAMERINO-MERGNANO SAN SAVINO",
    "state_code": "MC",
    "cab": "688309",
    "cap": "62032"
  },
  {
    "key": "2361",
    "ITA": "CAMEROTA-LICUSATI",
    "state_code": "SA",
    "cab": "761205",
    "cap": "84050"
  },
  {
    "key": "2362",
    "ITA": "CAMEROTA-LENTISCOSA",
    "state_code": "SA",
    "cab": "761205",
    "cap": "84050"
  },
  {
    "key": "2363",
    "ITA": "CAMEROTA-MARINA DI CAMEROTA",
    "state_code": "SA",
    "cab": "761205",
    "cap": "84059"
  },
  {
    "key": "2364",
    "ITA": "CAMEROTA",
    "state_code": "SA",
    "cab": "761205",
    "cap": "84040"
  },
  {
    "key": "2365",
    "ITA": "CAMIGLIANO",
    "state_code": "CE",
    "cab": "188532",
    "cap": "81050"
  },
  {
    "key": "2366",
    "ITA": "CAMINATA",
    "state_code": "PC",
    "cab": "938613",
    "cap": "29010"
  },
  {
    "key": "2367",
    "ITA": "CAMINI",
    "state_code": "RC",
    "cab": "960005",
    "cap": "89040"
  },
  {
    "key": "2368",
    "ITA": "CAMINO-BRUSASCHETTO",
    "state_code": "AL",
    "cab": "480905",
    "cap": "15020"
  },
  {
    "key": "2369",
    "ITA": "CAMINO",
    "state_code": "AL",
    "cab": "480905",
    "cap": "15020"
  },
  {
    "key": "2370",
    "ITA": "CAMINO-CASTEL SAN PIETRO",
    "state_code": "AL",
    "cab": "480905",
    "cap": "15020"
  },
  {
    "key": "2371",
    "ITA": "CAMINO AL TAGLIAMENTO",
    "state_code": "UD",
    "cab": "637009",
    "cap": "33030"
  },
  {
    "key": "2372",
    "ITA": "CAMISANO",
    "state_code": "CR",
    "cab": "566802",
    "cap": "26010"
  },
  {
    "key": "2373",
    "ITA": "CAMISANO VICENTINO",
    "state_code": "VI",
    "cab": "602300",
    "cap": "36043"
  },
  {
    "key": "2374",
    "ITA": "CAMMARATA-BORGO CALLEA",
    "state_code": "AG",
    "cab": "828608",
    "cap": "92022"
  },
  {
    "key": "2375",
    "ITA": "CAMMARATA",
    "state_code": "AG",
    "cab": "828608",
    "cap": "92022"
  },
  {
    "key": "2376",
    "ITA": "CAMO",
    "state_code": "CN",
    "cab": "918425",
    "cap": "12050"
  },
  {
    "key": "2377",
    "ITA": "CAMOGLI-SAN FRUTTUOSO",
    "state_code": "GE",
    "cab": "318808",
    "cap": "16032"
  },
  {
    "key": "2378",
    "ITA": "CAMOGLI-SAN ROCCO",
    "state_code": "GE",
    "cab": "318808",
    "cap": "16032"
  },
  {
    "key": "2379",
    "ITA": "CAMOGLI-RUTA",
    "state_code": "GE",
    "cab": "318808",
    "cap": "16032"
  },
  {
    "key": "2380",
    "ITA": "CAMOGLI",
    "state_code": "GE",
    "cab": "318808",
    "cap": "16032"
  },
  {
    "key": "2381",
    "ITA": "CAMPAGNA-SERRADARCE",
    "state_code": "SA",
    "cab": "761304",
    "cap": "84022"
  },
  {
    "key": "2382",
    "ITA": "CAMPAGNA-QUADRIVIO",
    "state_code": "SA",
    "cab": "761304",
    "cap": "84020"
  },
  {
    "key": "2383",
    "ITA": "CAMPAGNA",
    "state_code": "SA",
    "cab": "761304",
    "cap": "84022"
  },
  {
    "key": "2384",
    "ITA": "CAMPAGNA-PUGLIETTA",
    "state_code": "SA",
    "cab": "761304",
    "cap": "84022"
  },
  {
    "key": "2385",
    "ITA": "CAMPAGNA LUPIA",
    "state_code": "VE",
    "cab": "359901",
    "cap": "30010"
  },
  {
    "key": "2386",
    "ITA": "CAMPAGNA LUPIA-LOVA",
    "state_code": "VE",
    "cab": "359901",
    "cap": "30010"
  },
  {
    "key": "2387",
    "ITA": "CAMPAGNA LUPIA-LUGHETTO",
    "state_code": "VE",
    "cab": "359901",
    "cap": "30010"
  },
  {
    "key": "2388",
    "ITA": "CAMPAGNANO DI ROMA",
    "state_code": "RM",
    "cab": "389502",
    "cap": "63"
  },
  {
    "key": "2389",
    "ITA": "CAMPAGNATICO",
    "state_code": "GR",
    "cab": "721704",
    "cap": "58042"
  },
  {
    "key": "2390",
    "ITA": "CAMPAGNATICO-ARCILLE",
    "state_code": "GR",
    "cab": "721704",
    "cap": "58042"
  },
  {
    "key": "2391",
    "ITA": "CAMPAGNATICO-MONTORSAIO",
    "state_code": "GR",
    "cab": "721704",
    "cap": "58042"
  },
  {
    "key": "2392",
    "ITA": "CAMPAGNOLA CREMASCA",
    "state_code": "CR",
    "cab": "837708",
    "cap": "26010"
  },
  {
    "key": "2393",
    "ITA": "CAMPAGNOLA EMILIA",
    "state_code": "RE",
    "cab": "662106",
    "cap": "42012"
  },
  {
    "key": "2394",
    "ITA": "CAMPANA",
    "state_code": "CS",
    "cab": "471508",
    "cap": "87061"
  },
  {
    "key": "2395",
    "ITA": "CAMPARADA",
    "state_code": "MB",
    "cab": "852103",
    "cap": "20050"
  },
  {
    "key": "2396",
    "ITA": "CAMPEGINE",
    "state_code": "RE",
    "cab": "662205",
    "cap": "42040"
  },
  {
    "key": "2397",
    "ITA": "CAMPEGINE-CAPRARA",
    "state_code": "RE",
    "cab": "662205",
    "cap": "42040"
  },
  {
    "key": "2398",
    "ITA": "CAMPELLO SUL CLITUNNO",
    "state_code": "PG",
    "cab": "383109",
    "cap": "6042"
  },
  {
    "key": "2399",
    "ITA": "CAMPERTOGNO",
    "state_code": "VC",
    "cab": "443507",
    "cap": "13023"
  },
  {
    "key": "2400",
    "ITA": "CAMPI BISENZIO",
    "state_code": "FI",
    "cab": "214007",
    "cap": "50013"
  },
  {
    "key": "2401",
    "ITA": "CAMPI BISENZIO-CAPALLE",
    "state_code": "FI",
    "cab": "214007",
    "cap": "50013"
  },
  {
    "key": "2402",
    "ITA": "CAMPI BISENZIO-SAN PIERO A PONTI",
    "state_code": "FI",
    "cab": "214007",
    "cap": "50013"
  },
  {
    "key": "2403",
    "ITA": "CAMPI BISENZIO-SANT'ANGELO",
    "state_code": "FI",
    "cab": "214007",
    "cap": "50013"
  },
  {
    "key": "2404",
    "ITA": "CAMPI BISENZIO-IL ROSI",
    "state_code": "FI",
    "cab": "214007",
    "cap": "50013"
  },
  {
    "key": "2405",
    "ITA": "CAMPI BISENZIO-SAN DONNINO",
    "state_code": "FI",
    "cab": "214007",
    "cap": "50013"
  },
  {
    "key": "2406",
    "ITA": "CAMPI SALENTINA",
    "state_code": "LE",
    "cab": "794800",
    "cap": "73012"
  },
  {
    "key": "2407",
    "ITA": "CAMPIGLIA CERVO",
    "state_code": "BI",
    "cab": "443606",
    "cap": "13812"
  },
  {
    "key": "2408",
    "ITA": "CAMPIGLIA DEI BERICI",
    "state_code": "VI",
    "cab": "602409",
    "cap": "36020"
  },
  {
    "key": "2409",
    "ITA": "CAMPIGLIA MARITTIMA",
    "state_code": "LI",
    "cab": "706408",
    "cap": "57021"
  },
  {
    "key": "2410",
    "ITA": "CAMPIGLIA MARITTIMA-STAZIONE DI CAMPIGLIA MARITTIMA",
    "state_code": "LI",
    "cab": "706408",
    "cap": "57021"
  },
  {
    "key": "2411",
    "ITA": "CAMPIGLIA MARITTIMA-VENTURINA",
    "state_code": "LI",
    "cab": "706408",
    "cap": "57021"
  },
  {
    "key": "2412",
    "ITA": "CAMPIGLIONE FENILE-CAMPIGLIONE",
    "state_code": "TO",
    "cab": "666305",
    "cap": "10060"
  },
  {
    "key": "2413",
    "ITA": "CAMPIGLIONE FENILE",
    "state_code": "TO",
    "cab": "666305",
    "cap": "10060"
  },
  {
    "key": "2414",
    "ITA": "CAMPIONE D'ITALIA",
    "state_code": "CO",
    "cab": "510503",
    "cap": "22060"
  },
  {
    "key": "2415",
    "ITA": "CAMPITELLO DI FASSA",
    "state_code": "TN",
    "cab": "344903",
    "cap": "38031"
  },
  {
    "key": "2416",
    "ITA": "CAMPLI-GARRUFO DI CAMPLI",
    "state_code": "TE",
    "cab": "767806",
    "cap": "64012"
  },
  {
    "key": "2417",
    "ITA": "CAMPLI-PATERNO",
    "state_code": "TE",
    "cab": "767806",
    "cap": "64012"
  },
  {
    "key": "2418",
    "ITA": "CAMPLI-PIANCARANI",
    "state_code": "TE",
    "cab": "767806",
    "cap": "64012"
  },
  {
    "key": "2419",
    "ITA": "CAMPLI-CAMPOVALANO",
    "state_code": "TE",
    "cab": "767806",
    "cap": "64012"
  },
  {
    "key": "2420",
    "ITA": "CAMPLI",
    "state_code": "TE",
    "cab": "767806",
    "cap": "64012"
  },
  {
    "key": "2421",
    "ITA": "CAMPLI-SANT'ONOFRIO",
    "state_code": "TE",
    "cab": "767806",
    "cap": "64012"
  },
  {
    "key": "2422",
    "ITA": "CAMPO CALABRO",
    "state_code": "RC",
    "cab": "888701",
    "cap": "89052"
  },
  {
    "key": "2423",
    "ITA": "CAMPO DI GIOVE",
    "state_code": "AQ",
    "cab": "404707",
    "cap": "67030"
  },
  {
    "key": "2424",
    "ITA": "CAMPO DI TRENS-MULES",
    "state_code": "BZ",
    "cab": "582601",
    "cap": "39040"
  },
  {
    "key": "2425",
    "ITA": "CAMPO DI TRENS-PRUNO",
    "state_code": "BZ",
    "cab": "582601",
    "cap": "39040"
  },
  {
    "key": "2426",
    "ITA": "CAMPO DI TRENS-STILVES",
    "state_code": "BZ",
    "cab": "582601",
    "cap": "39040"
  },
  {
    "key": "2427",
    "ITA": "CAMPO DI TRENS",
    "state_code": "BZ",
    "cab": "582601",
    "cap": "39040"
  },
  {
    "key": "2428",
    "ITA": "CAMPO LIGURE",
    "state_code": "GE",
    "cab": "318907",
    "cap": "16013"
  },
  {
    "key": "2429",
    "ITA": "CAMPO NELL'ELBA-PIANOSA",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2430",
    "ITA": "CAMPO NELL'ELBA-SECCHETO",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2431",
    "ITA": "CAMPO NELL'ELBA-SANT'ILARIO",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2432",
    "ITA": "CAMPO NELL'ELBA-SAN PIERO IN CAMPO",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2433",
    "ITA": "CAMPO NELL'ELBA-CAVOLI",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2434",
    "ITA": "CAMPO NELL'ELBA-MARINA DI CAMPO",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2435",
    "ITA": "CAMPO NELL'ELBA-LA PILA",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2436",
    "ITA": "CAMPO NELL'ELBA",
    "state_code": "LI",
    "cab": "706507",
    "cap": "57034"
  },
  {
    "key": "2437",
    "ITA": "CAMPO SAN MARTINO",
    "state_code": "PD",
    "cab": "624403",
    "cap": "35010"
  },
  {
    "key": "2438",
    "ITA": "CAMPO SAN MARTINO-MARSANGO",
    "state_code": "PD",
    "cab": "624403",
    "cap": "35010"
  },
  {
    "key": "2439",
    "ITA": "CAMPO TURES",
    "state_code": "BZ",
    "cab": "582700",
    "cap": "39032"
  },
  {
    "key": "2440",
    "ITA": "CAMPO TURES-MOLINI DI TURES",
    "state_code": "BZ",
    "cab": "582700",
    "cap": "39032"
  },
  {
    "key": "2441",
    "ITA": "CAMPO TURES-RIVA DI TURES",
    "state_code": "BZ",
    "cab": "582700",
    "cap": "39032"
  },
  {
    "key": "2442",
    "ITA": "CAMPO TURES-ACERETO",
    "state_code": "BZ",
    "cab": "582700",
    "cap": "39032"
  },
  {
    "key": "2443",
    "ITA": "CAMPOBASSO-SANTO STEFANO",
    "state_code": "CB",
    "cab": "38000",
    "cap": "86100"
  },
  {
    "key": "2444",
    "ITA": "CAMPOBASSO",
    "state_code": "CB",
    "cab": "38000",
    "cap": "86100"
  },
  {
    "key": "2445",
    "ITA": "CAMPOBELLO DI LICATA",
    "state_code": "AG",
    "cab": "828707",
    "cap": "92023"
  },
  {
    "key": "2446",
    "ITA": "CAMPOBELLO DI MAZARA",
    "state_code": "TP",
    "cab": "818104",
    "cap": "91021"
  },
  {
    "key": "2447",
    "ITA": "CAMPOBELLO DI MAZARA-TRE FONTANE",
    "state_code": "TP",
    "cab": "818104",
    "cap": "91021"
  },
  {
    "key": "2448",
    "ITA": "CAMPOBELLO DI MAZARA-GRANITOLA TORRETTA",
    "state_code": "TP",
    "cab": "818104",
    "cap": "91020"
  },
  {
    "key": "2449",
    "ITA": "CAMPOCHIARO",
    "state_code": "CB",
    "cab": "910430",
    "cap": "86020"
  },
  {
    "key": "2450",
    "ITA": "CAMPODARSEGO-SANT'ANDREA DI CAMPODARSEGO",
    "state_code": "PD",
    "cab": "624205",
    "cap": "35011"
  },
  {
    "key": "2451",
    "ITA": "CAMPODARSEGO",
    "state_code": "PD",
    "cab": "624205",
    "cap": "35011"
  },
  {
    "key": "2452",
    "ITA": "CAMPODENNO",
    "state_code": "TN",
    "cab": "345009",
    "cap": "38010"
  },
  {
    "key": "2453",
    "ITA": "CAMPODIMELE",
    "state_code": "LT",
    "cab": "947804",
    "cap": "4020"
  },
  {
    "key": "2454",
    "ITA": "CAMPODIPIETRA",
    "state_code": "CB",
    "cab": "910448",
    "cap": "86010"
  },
  {
    "key": "2455",
    "ITA": "CAMPODOLCINO",
    "state_code": "SO",
    "cab": "521005",
    "cap": "23021"
  },
  {
    "key": "2456",
    "ITA": "CAMPODOLCINO-FRACISCIO",
    "state_code": "SO",
    "cab": "521005",
    "cap": "23021"
  },
  {
    "key": "2457",
    "ITA": "CAMPODORO",
    "state_code": "PD",
    "cab": "892000",
    "cap": "35010"
  },
  {
    "key": "2458",
    "ITA": "CAMPOFELICE DI FITALIA",
    "state_code": "PA",
    "cab": "438200",
    "cap": "90030"
  },
  {
    "key": "2459",
    "ITA": "CAMPOFELICE DI ROCCELLA",
    "state_code": "PA",
    "cab": "431700",
    "cap": "90010"
  },
  {
    "key": "2460",
    "ITA": "CAMPOFILONE",
    "state_code": "FM",
    "cab": "698001",
    "cap": "63016"
  },
  {
    "key": "2461",
    "ITA": "CAMPOFIORITO",
    "state_code": "PA",
    "cab": "431809",
    "cap": "90030"
  },
  {
    "key": "2462",
    "ITA": "CAMPOFORMIDO-BRESSA",
    "state_code": "UD",
    "cab": "637108",
    "cap": "33030"
  },
  {
    "key": "2463",
    "ITA": "CAMPOFORMIDO",
    "state_code": "UD",
    "cab": "637108",
    "cap": "33030"
  },
  {
    "key": "2464",
    "ITA": "CAMPOFORMIDO-BASALDELLA",
    "state_code": "UD",
    "cab": "637108",
    "cap": "33030"
  },
  {
    "key": "2465",
    "ITA": "CAMPOFRANCO",
    "state_code": "CL",
    "cab": "833103",
    "cap": "93010"
  },
  {
    "key": "2466",
    "ITA": "CAMPOGALLIANO-SALICETO BUZZALINO",
    "state_code": "MO",
    "cab": "666701",
    "cap": "41011"
  },
  {
    "key": "2467",
    "ITA": "CAMPOGALLIANO-PANZANO",
    "state_code": "MO",
    "cab": "666701",
    "cap": "41011"
  },
  {
    "key": "2468",
    "ITA": "CAMPOGALLIANO",
    "state_code": "MO",
    "cab": "666701",
    "cap": "41011"
  },
  {
    "key": "2469",
    "ITA": "CAMPOLATTARO",
    "state_code": "BN",
    "cab": "950170",
    "cap": "82020"
  },
  {
    "key": "2470",
    "ITA": "CAMPOLI APPENNINO",
    "state_code": "FR",
    "cab": "747501",
    "cap": "3030"
  },
  {
    "key": "2471",
    "ITA": "CAMPOLI DEL MONTE TABURNO",
    "state_code": "BN",
    "cab": "950188",
    "cap": "82030"
  },
  {
    "key": "2472",
    "ITA": "CAMPOLIETO",
    "state_code": "CB",
    "cab": "910455",
    "cap": "86040"
  },
  {
    "key": "2473",
    "ITA": "CAMPOLONGO AL TORRE",
    "state_code": "UD",
    "cab": "832501",
    "cap": "33040"
  },
  {
    "key": "2474",
    "ITA": "CAMPOLONGO AL TORRE-CAVENZANO",
    "state_code": "UD",
    "cab": "832501",
    "cap": "33040"
  },
  {
    "key": "2475",
    "ITA": "CAMPOLONGO MAGGIORE-LIETTOLI",
    "state_code": "VE",
    "cab": "360008",
    "cap": "30010"
  },
  {
    "key": "2476",
    "ITA": "CAMPOLONGO MAGGIORE-BOJON",
    "state_code": "VE",
    "cab": "360008",
    "cap": "30010"
  },
  {
    "key": "2477",
    "ITA": "CAMPOLONGO MAGGIORE",
    "state_code": "VE",
    "cab": "360008",
    "cap": "30010"
  },
  {
    "key": "2478",
    "ITA": "CAMPOLONGO SUL BRENTA",
    "state_code": "VI",
    "cab": "285999",
    "cap": "36020"
  },
  {
    "key": "2479",
    "ITA": "CAMPOMAGGIORE",
    "state_code": "PZ",
    "cab": "911883",
    "cap": "85010"
  },
  {
    "key": "2480",
    "ITA": "CAMPOMARINO",
    "state_code": "CB",
    "cab": "409904",
    "cap": "86042"
  },
  {
    "key": "2481",
    "ITA": "CAMPOMARINO-LIDO DI CAMPOMARINO",
    "state_code": "CB",
    "cab": "409904",
    "cap": "86042"
  },
  {
    "key": "2482",
    "ITA": "CAMPOMARINO-NUOVA CLITERNIA",
    "state_code": "CB",
    "cab": "409904",
    "cap": "86042"
  },
  {
    "key": "2483",
    "ITA": "CAMPOMORONE-LANGASCO",
    "state_code": "GE",
    "cab": "319004",
    "cap": "16014"
  },
  {
    "key": "2484",
    "ITA": "CAMPOMORONE",
    "state_code": "GE",
    "cab": "319004",
    "cap": "16014"
  },
  {
    "key": "2485",
    "ITA": "CAMPOMORONE-ISOVERDE",
    "state_code": "GE",
    "cab": "319004",
    "cap": "16014"
  },
  {
    "key": "2486",
    "ITA": "CAMPONOGARA",
    "state_code": "VE",
    "cab": "360107",
    "cap": "30010"
  },
  {
    "key": "2487",
    "ITA": "CAMPORA",
    "state_code": "SA",
    "cab": "952309",
    "cap": "84040"
  },
  {
    "key": "2488",
    "ITA": "CAMPOREALE",
    "state_code": "PA",
    "cab": "431908",
    "cap": "90043"
  },
  {
    "key": "2489",
    "ITA": "CAMPORGIANO-POGGIO",
    "state_code": "LU",
    "cab": "701201",
    "cap": "55031"
  },
  {
    "key": "2490",
    "ITA": "CAMPORGIANO-FILICAIA",
    "state_code": "LU",
    "cab": "701201",
    "cap": "55031"
  },
  {
    "key": "2491",
    "ITA": "CAMPORGIANO",
    "state_code": "LU",
    "cab": "701201",
    "cap": "55031"
  },
  {
    "key": "2492",
    "ITA": "CAMPOROSSO",
    "state_code": "IM",
    "cab": "491209",
    "cap": "18033"
  },
  {
    "key": "2493",
    "ITA": "CAMPOROSSO-CAMPOROSSO MARE",
    "state_code": "IM",
    "cab": "491209",
    "cap": "18033"
  },
  {
    "key": "2494",
    "ITA": "CAMPOROTONDO DI FIASTRONE",
    "state_code": "MC",
    "cab": "941930",
    "cap": "62020"
  },
  {
    "key": "2495",
    "ITA": "CAMPOROTONDO ETNEO",
    "state_code": "CT",
    "cab": "843508",
    "cap": "95040"
  },
  {
    "key": "2496",
    "ITA": "CAMPOSAMPIERO",
    "state_code": "PD",
    "cab": "624304",
    "cap": "35012"
  },
  {
    "key": "2497",
    "ITA": "CAMPOSAMPIERO-RUSTEGA",
    "state_code": "PD",
    "cab": "624304",
    "cap": "35012"
  },
  {
    "key": "2498",
    "ITA": "CAMPOSANO",
    "state_code": "NA",
    "cab": "371708",
    "cap": "80030"
  },
  {
    "key": "2499",
    "ITA": "CAMPOSANTO",
    "state_code": "MO",
    "cab": "666800",
    "cap": "41031"
  },
  {
    "key": "2500",
    "ITA": "CAMPOSPINOSO-CASENOVE",
    "state_code": "PV",
    "cab": "930271",
    "cap": "27040"
  },
  {
    "key": "2501",
    "ITA": "CAMPOSPINOSO",
    "state_code": "PV",
    "cab": "930271",
    "cap": "27040"
  },
  {
    "key": "2502",
    "ITA": "CAMPOTOSTO",
    "state_code": "AQ",
    "cab": "404806",
    "cap": "67013"
  },
  {
    "key": "2503",
    "ITA": "CAMPOTOSTO-ORTOLANO",
    "state_code": "AQ",
    "cab": "404806",
    "cap": "67013"
  },
  {
    "key": "2504",
    "ITA": "CAMPOTOSTO-POGGIO CANCELLI",
    "state_code": "AQ",
    "cab": "404806",
    "cap": "67013"
  },
  {
    "key": "2505",
    "ITA": "CAMPOTOSTO-MASCIONI",
    "state_code": "AQ",
    "cab": "404806",
    "cap": "67013"
  },
  {
    "key": "2506",
    "ITA": "CAMUGNANO-BARGI",
    "state_code": "BO",
    "cab": "366609",
    "cap": "40032"
  },
  {
    "key": "2507",
    "ITA": "CAMUGNANO",
    "state_code": "BO",
    "cab": "366609",
    "cap": "40032"
  },
  {
    "key": "2508",
    "ITA": "CAMUGNANO-PONTE DI VERZUNO",
    "state_code": "BO",
    "cab": "366609",
    "cap": "40032"
  },
  {
    "key": "2509",
    "ITA": "CANAL SAN BOVO-GOBBERA",
    "state_code": "TN",
    "cab": "345108",
    "cap": "38050"
  },
  {
    "key": "2510",
    "ITA": "CANAL SAN BOVO-PRADE",
    "state_code": "TN",
    "cab": "345108",
    "cap": "38050"
  },
  {
    "key": "2511",
    "ITA": "CANAL SAN BOVO",
    "state_code": "TN",
    "cab": "345108",
    "cap": "38050"
  },
  {
    "key": "2512",
    "ITA": "CANAL SAN BOVO-CAORIA",
    "state_code": "TN",
    "cab": "345108",
    "cap": "38050"
  },
  {
    "key": "2513",
    "ITA": "CANALE",
    "state_code": "CN",
    "cab": "460600",
    "cap": "12043"
  },
  {
    "key": "2514",
    "ITA": "CANALE-VALPONE",
    "state_code": "CN",
    "cab": "460600",
    "cap": "12043"
  },
  {
    "key": "2515",
    "ITA": "CANALE D'AGORDO",
    "state_code": "BL",
    "cab": "660704",
    "cap": "32020"
  },
  {
    "key": "2516",
    "ITA": "CANALE MONTERANO",
    "state_code": "RM",
    "cab": "389601",
    "cap": "60"
  },
  {
    "key": "2517",
    "ITA": "CANALE MONTERANO-BAGNI DI STIGLIANO",
    "state_code": "RM",
    "cab": "389601",
    "cap": "60"
  },
  {
    "key": "2518",
    "ITA": "CANALE MONTERANO-MONTEVIRGINIO",
    "state_code": "RM",
    "cab": "389601",
    "cap": "60"
  },
  {
    "key": "2519",
    "ITA": "CANARO",
    "state_code": "RO",
    "cab": "631804",
    "cap": "45034"
  },
  {
    "key": "2520",
    "ITA": "CANARO-PAVIOLE",
    "state_code": "RO",
    "cab": "631804",
    "cap": "45034"
  },
  {
    "key": "2521",
    "ITA": "CANAZEI",
    "state_code": "TN",
    "cab": "345207",
    "cap": "38032"
  },
  {
    "key": "2522",
    "ITA": "CANAZEI-PENIA",
    "state_code": "TN",
    "cab": "345207",
    "cap": "38032"
  },
  {
    "key": "2523",
    "ITA": "CANAZEI-ALBA DI CANAZEI",
    "state_code": "TN",
    "cab": "345207",
    "cap": "38032"
  },
  {
    "key": "2524",
    "ITA": "CANAZEI-SASS PORDOI",
    "state_code": "TN",
    "cab": "345207",
    "cap": "38032"
  },
  {
    "key": "2525",
    "ITA": "CANCELLARA",
    "state_code": "PZ",
    "cab": "419200",
    "cap": "85010"
  },
  {
    "key": "2526",
    "ITA": "CANCELLO ED ARNONE-ARNONE",
    "state_code": "CE",
    "cab": "748103",
    "cap": "81030"
  },
  {
    "key": "2527",
    "ITA": "CANCELLO ED ARNONE",
    "state_code": "CE",
    "cab": "748103",
    "cap": "81030"
  },
  {
    "key": "2528",
    "ITA": "CANCELLO ED ARNONE-CANCELLO",
    "state_code": "CE",
    "cab": "748103",
    "cap": "81030"
  },
  {
    "key": "2529",
    "ITA": "CANDA",
    "state_code": "RO",
    "cab": "635201",
    "cap": "45020"
  },
  {
    "key": "2530",
    "ITA": "CANDELA",
    "state_code": "FG",
    "cab": "783209",
    "cap": "71024"
  },
  {
    "key": "2531",
    "ITA": "CANDELO",
    "state_code": "BI",
    "cab": "443705",
    "cap": "13878"
  },
  {
    "key": "2532",
    "ITA": "CANDIA CANAVESE",
    "state_code": "TO",
    "cab": "666107",
    "cap": "10010"
  },
  {
    "key": "2533",
    "ITA": "CANDIA LOMELLINA",
    "state_code": "PV",
    "cab": "556902",
    "cap": "27031"
  },
  {
    "key": "2534",
    "ITA": "CANDIANA",
    "state_code": "PD",
    "cab": "624502",
    "cap": "35020"
  },
  {
    "key": "2535",
    "ITA": "CANDIDA",
    "state_code": "AV",
    "cab": "950972",
    "cap": "83040"
  },
  {
    "key": "2536",
    "ITA": "CANDIDONI",
    "state_code": "RC",
    "cab": "960021",
    "cap": "89020"
  },
  {
    "key": "2537",
    "ITA": "CANDIOLO",
    "state_code": "TO",
    "cab": "302307",
    "cap": "10060"
  },
  {
    "key": "2538",
    "ITA": "CANEGRATE",
    "state_code": "MI",
    "cab": "327106",
    "cap": "20010"
  },
  {
    "key": "2539",
    "ITA": "CANELLI-CANELLI RECAPITO GANCIA",
    "state_code": "AT",
    "cab": "473009",
    "cap": "14053"
  },
  {
    "key": "2540",
    "ITA": "CANELLI-SANT'ANTONIO",
    "state_code": "AT",
    "cab": "473009",
    "cap": "14053"
  },
  {
    "key": "2541",
    "ITA": "CANELLI",
    "state_code": "AT",
    "cab": "473009",
    "cap": "14053"
  },
  {
    "key": "2542",
    "ITA": "CANEPINA",
    "state_code": "VT",
    "cab": "729301",
    "cap": "1030"
  },
  {
    "key": "2543",
    "ITA": "CANEVA",
    "state_code": "PN",
    "cab": "648006",
    "cap": "33070"
  },
  {
    "key": "2544",
    "ITA": "CANEVA-SARONE",
    "state_code": "PN",
    "cab": "648006",
    "cap": "33070"
  },
  {
    "key": "2545",
    "ITA": "CANEVA-STEVENA'",
    "state_code": "PN",
    "cab": "648006",
    "cap": "33070"
  },
  {
    "key": "2546",
    "ITA": "CANEVINO",
    "state_code": "PV",
    "cab": "930289",
    "cap": "27040"
  },
  {
    "key": "2547",
    "ITA": "CANICATTI'",
    "state_code": "AG",
    "cab": "828806",
    "cap": "92024"
  },
  {
    "key": "2548",
    "ITA": "CANICATTINI BAGNI",
    "state_code": "SR",
    "cab": "846600",
    "cap": "96010"
  },
  {
    "key": "2549",
    "ITA": "CANINO-MUSIGNANO",
    "state_code": "VT",
    "cab": "729400",
    "cap": "1011"
  },
  {
    "key": "2550",
    "ITA": "CANINO",
    "state_code": "VT",
    "cab": "729400",
    "cap": "1011"
  },
  {
    "key": "2551",
    "ITA": "CANISCHIO",
    "state_code": "TO",
    "cab": "283226",
    "cap": "10080"
  },
  {
    "key": "2552",
    "ITA": "CANISTRO-CANISTRO INFERIORE",
    "state_code": "AQ",
    "cab": "909507",
    "cap": "67050"
  },
  {
    "key": "2553",
    "ITA": "CANISTRO",
    "state_code": "AQ",
    "cab": "909507",
    "cap": "67050"
  },
  {
    "key": "2554",
    "ITA": "CANISTRO-CANISTRO SUPERIORE",
    "state_code": "AQ",
    "cab": "909507",
    "cap": "67050"
  },
  {
    "key": "2555",
    "ITA": "CANNA",
    "state_code": "CS",
    "cab": "958678",
    "cap": "87070"
  },
  {
    "key": "2556",
    "ITA": "CANNALONGA",
    "state_code": "SA",
    "cab": "952317",
    "cap": "84040"
  },
  {
    "key": "2557",
    "ITA": "CANNARA",
    "state_code": "PG",
    "cab": "383208",
    "cap": "6033"
  },
  {
    "key": "2558",
    "ITA": "CANNERO RIVIERA",
    "state_code": "VB",
    "cab": "452607",
    "cap": "28821"
  },
  {
    "key": "2559",
    "ITA": "CANNETO PAVESE-CANNETO",
    "state_code": "PV",
    "cab": "478909",
    "cap": "27044"
  },
  {
    "key": "2560",
    "ITA": "CANNETO PAVESE",
    "state_code": "PV",
    "cab": "478909",
    "cap": "27044"
  },
  {
    "key": "2561",
    "ITA": "CANNETO SULL'OGLIO",
    "state_code": "MN",
    "cab": "574806",
    "cap": "46013"
  },
  {
    "key": "2562",
    "ITA": "CANNOBIO",
    "state_code": "VB",
    "cab": "452706",
    "cap": "28822"
  },
  {
    "key": "2563",
    "ITA": "CANNOLE",
    "state_code": "LE",
    "cab": "724807",
    "cap": "73020"
  },
  {
    "key": "2564",
    "ITA": "CANOLO-CANOLO NUOVA",
    "state_code": "RC",
    "cab": "960039",
    "cap": "89040"
  },
  {
    "key": "2565",
    "ITA": "CANOLO",
    "state_code": "RC",
    "cab": "960039",
    "cap": "89040"
  },
  {
    "key": "2566",
    "ITA": "CANONICA D'ADDA",
    "state_code": "BG",
    "cab": "527408",
    "cap": "24040"
  },
  {
    "key": "2567",
    "ITA": "CANOSA DI PUGLIA-LOCONIA",
    "state_code": "BT",
    "cab": "414003",
    "cap": "70053"
  },
  {
    "key": "2568",
    "ITA": "CANOSA DI PUGLIA",
    "state_code": "BT",
    "cab": "414003",
    "cap": "70053"
  },
  {
    "key": "2569",
    "ITA": "CANOSA SANNITA",
    "state_code": "CH",
    "cab": "687608",
    "cap": "66010"
  },
  {
    "key": "2570",
    "ITA": "CANOSIO",
    "state_code": "CN",
    "cab": "918433",
    "cap": "12020"
  },
  {
    "key": "2571",
    "ITA": "CANOSSA",
    "state_code": "RE",
    "cab": "939405",
    "cap": "42026"
  },
  {
    "key": "2572",
    "ITA": "CANOSSA-CIANO D'ENZA",
    "state_code": "RE",
    "cab": "939405",
    "cap": "42026"
  },
  {
    "key": "2573",
    "ITA": "CANOSSA-COMPIANO D'ENZA",
    "state_code": "RE",
    "cab": "939405",
    "cap": "42026"
  },
  {
    "key": "2574",
    "ITA": "CANSANO",
    "state_code": "AQ",
    "cab": "909515",
    "cap": "67030"
  },
  {
    "key": "2575",
    "ITA": "CANTAGALLO-USELLA",
    "state_code": "PO",
    "cab": "907204",
    "cap": "59025"
  },
  {
    "key": "2576",
    "ITA": "CANTAGALLO",
    "state_code": "PO",
    "cab": "907204",
    "cap": "59025"
  },
  {
    "key": "2577",
    "ITA": "CANTAGALLO-LUICCIANA",
    "state_code": "PO",
    "cab": "907204",
    "cap": "59025"
  },
  {
    "key": "2578",
    "ITA": "CANTAGALLO-IL FABBRO",
    "state_code": "PO",
    "cab": "907204",
    "cap": "59025"
  },
  {
    "key": "2579",
    "ITA": "CANTAGALLO-CARMIGNANELLO",
    "state_code": "PO",
    "cab": "907204",
    "cap": "59025"
  },
  {
    "key": "2580",
    "ITA": "CANTALICE-SAN LIBERATO",
    "state_code": "RI",
    "cab": "735100",
    "cap": "2014"
  },
  {
    "key": "2581",
    "ITA": "CANTALICE-FANTAUZZI",
    "state_code": "RI",
    "cab": "735100",
    "cap": "2014"
  },
  {
    "key": "2582",
    "ITA": "CANTALICE",
    "state_code": "RI",
    "cab": "735100",
    "cap": "2014"
  },
  {
    "key": "2583",
    "ITA": "CANTALUPA",
    "state_code": "TO",
    "cab": "189977",
    "cap": "10060"
  },
  {
    "key": "2584",
    "ITA": "CANTALUPO IN SABINA",
    "state_code": "RI",
    "cab": "735209",
    "cap": "2040"
  },
  {
    "key": "2585",
    "ITA": "CANTALUPO LIGURE",
    "state_code": "AL",
    "cab": "835108",
    "cap": "15060"
  },
  {
    "key": "2586",
    "ITA": "CANTALUPO NEL SANNIO",
    "state_code": "IS",
    "cab": "955526",
    "cap": "86092"
  },
  {
    "key": "2587",
    "ITA": "CANTARANA",
    "state_code": "AT",
    "cab": "919977",
    "cap": "14010"
  },
  {
    "key": "2588",
    "ITA": "CANTELLO",
    "state_code": "VA",
    "cab": "500603",
    "cap": "21050"
  },
  {
    "key": "2589",
    "ITA": "CANTELLO-GAGGIOLO",
    "state_code": "VA",
    "cab": "500603",
    "cap": "21050"
  },
  {
    "key": "2590",
    "ITA": "CANTERANO",
    "state_code": "RM",
    "cab": "908046",
    "cap": "20"
  },
  {
    "key": "2591",
    "ITA": "CANTIANO",
    "state_code": "PU",
    "cab": "682401",
    "cap": "61044"
  },
  {
    "key": "2592",
    "ITA": "CANTIANO-PONTERICCIOLI",
    "state_code": "PU",
    "cab": "682401",
    "cap": "61044"
  },
  {
    "key": "2593",
    "ITA": "CANTIANO-CHIASERNA",
    "state_code": "PU",
    "cab": "682401",
    "cap": "61044"
  },
  {
    "key": "2594",
    "ITA": "CANTOIRA",
    "state_code": "TO",
    "cab": "302406",
    "cap": "10070"
  },
  {
    "key": "2595",
    "ITA": "CANTU'-CANTU' ASNAGO",
    "state_code": "CO",
    "cab": "510602",
    "cap": "22063"
  },
  {
    "key": "2596",
    "ITA": "CANTU'-CASCINA AMATA",
    "state_code": "CO",
    "cab": "510602",
    "cap": "22063"
  },
  {
    "key": "2597",
    "ITA": "CANTU'-MIRABELLO DI CANTU'",
    "state_code": "CO",
    "cab": "510602",
    "cap": "22063"
  },
  {
    "key": "2598",
    "ITA": "CANTU'-VIGHIZZOLO DI CANTU'",
    "state_code": "CO",
    "cab": "510602",
    "cap": "22063"
  },
  {
    "key": "2599",
    "ITA": "CANTU'",
    "state_code": "CO",
    "cab": "510602",
    "cap": "22063"
  },
  {
    "key": "2600",
    "ITA": "CANZANO-VALLE CANZANO",
    "state_code": "TE",
    "cab": "767905",
    "cap": "64020"
  },
  {
    "key": "2601",
    "ITA": "CANZANO",
    "state_code": "TE",
    "cab": "767905",
    "cap": "64020"
  },
  {
    "key": "2602",
    "ITA": "CANZO",
    "state_code": "CO",
    "cab": "510701",
    "cap": "22035"
  },
  {
    "key": "2603",
    "ITA": "CAORLE",
    "state_code": "VE",
    "cab": "360206",
    "cap": "30021"
  },
  {
    "key": "2604",
    "ITA": "CAORLE-PORTO SANTA MARGHERITA",
    "state_code": "VE",
    "cab": "360206",
    "cap": "30021"
  },
  {
    "key": "2605",
    "ITA": "CAORLE-SAN GAETANO",
    "state_code": "VE",
    "cab": "360206",
    "cap": "30021"
  },
  {
    "key": "2606",
    "ITA": "CAORLE-SAN GIORGIO DI LIVENZA",
    "state_code": "VE",
    "cab": "360206",
    "cap": "30020"
  },
  {
    "key": "2607",
    "ITA": "CAORLE-CA' CORNIANI",
    "state_code": "VE",
    "cab": "360206",
    "cap": "30021"
  },
  {
    "key": "2608",
    "ITA": "CAORSO",
    "state_code": "PC",
    "cab": "652305",
    "cap": "29012"
  },
  {
    "key": "2609",
    "ITA": "CAPACCIO-VUCCOLO DI MAIORANO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2610",
    "ITA": "CAPACCIO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2611",
    "ITA": "CAPACCIO-BORGO NUOVO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2612",
    "ITA": "CAPACCIO-CAFASSO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2613",
    "ITA": "CAPACCIO-CAPACCIO SCALO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2614",
    "ITA": "CAPACCIO-GROMOLA",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2615",
    "ITA": "CAPACCIO-LAURA",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2616",
    "ITA": "CAPACCIO-LICINELLA",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2617",
    "ITA": "CAPACCIO-PAESTUM",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2618",
    "ITA": "CAPACCIO-PONTE BARIZZO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2619",
    "ITA": "CAPACCIO-RETTIFILO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2620",
    "ITA": "CAPACCIO-SANTA VENERE",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2621",
    "ITA": "CAPACCIO-TORRE DI PAESTUM",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2622",
    "ITA": "CAPACCIO-VANNULLO",
    "state_code": "SA",
    "cab": "761403",
    "cap": "84047"
  },
  {
    "key": "2623",
    "ITA": "CAPACI",
    "state_code": "PA",
    "cab": "432005",
    "cap": "90040"
  },
  {
    "key": "2624",
    "ITA": "CAPALBIO-CHIARONE",
    "state_code": "GR",
    "cab": "721803",
    "cap": "58011"
  },
  {
    "key": "2625",
    "ITA": "CAPALBIO-CAPALBIO STAZIONE",
    "state_code": "GR",
    "cab": "721803",
    "cap": "58011"
  },
  {
    "key": "2626",
    "ITA": "CAPALBIO-BORGO CARIGE",
    "state_code": "GR",
    "cab": "721803",
    "cap": "58011"
  },
  {
    "key": "2627",
    "ITA": "CAPALBIO",
    "state_code": "GR",
    "cab": "721803",
    "cap": "58011"
  },
  {
    "key": "2628",
    "ITA": "CAPANNOLI-SAN PIETRO BELVEDERE",
    "state_code": "PI",
    "cab": "709204",
    "cap": "56033"
  },
  {
    "key": "2629",
    "ITA": "CAPANNOLI",
    "state_code": "PI",
    "cab": "709204",
    "cap": "56033"
  },
  {
    "key": "2630",
    "ITA": "CAPANNORI-SAN GINESE",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55061"
  },
  {
    "key": "2631",
    "ITA": "CAPANNORI-SEGROMIGNO IN MONTE",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55018"
  },
  {
    "key": "2632",
    "ITA": "CAPANNORI-SEGROMIGNO IN PIANO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55018"
  },
  {
    "key": "2633",
    "ITA": "CAPANNORI-SAN GENNARO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55010"
  },
  {
    "key": "2634",
    "ITA": "CAPANNORI-ZONE",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55012"
  },
  {
    "key": "2635",
    "ITA": "CAPANNORI-VORNO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55060"
  },
  {
    "key": "2636",
    "ITA": "CAPANNORI-SAN COLOMBANO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55018"
  },
  {
    "key": "2637",
    "ITA": "CAPANNORI-RUOTA",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55062"
  },
  {
    "key": "2638",
    "ITA": "CAPANNORI",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55012"
  },
  {
    "key": "2639",
    "ITA": "CAPANNORI-BADIA CANTIGNANO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55060"
  },
  {
    "key": "2640",
    "ITA": "CAPANNORI-CAMIGLIANO SANTA GEMMA",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55010"
  },
  {
    "key": "2641",
    "ITA": "CAPANNORI-CARRAIA",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55061"
  },
  {
    "key": "2642",
    "ITA": "CAPANNORI-COLLE DI COMPITO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55062"
  },
  {
    "key": "2643",
    "ITA": "CAPANNORI-GRAGNANO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55010"
  },
  {
    "key": "2644",
    "ITA": "CAPANNORI-GUAMO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55060"
  },
  {
    "key": "2645",
    "ITA": "CAPANNORI-LAMMARI",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55013"
  },
  {
    "key": "2646",
    "ITA": "CAPANNORI-LAPPATO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55010"
  },
  {
    "key": "2647",
    "ITA": "CAPANNORI-LUNATA",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55012"
  },
  {
    "key": "2648",
    "ITA": "CAPANNORI-MARLIA",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55014"
  },
  {
    "key": "2649",
    "ITA": "CAPANNORI-MASSA MACINAIA",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55060"
  },
  {
    "key": "2650",
    "ITA": "CAPANNORI-MATRAIA",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55018"
  },
  {
    "key": "2651",
    "ITA": "CAPANNORI-PIEVE DI COMPITO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55062"
  },
  {
    "key": "2652",
    "ITA": "CAPANNORI-PIEVE SAN PAOLO",
    "state_code": "LU",
    "cab": "247007",
    "cap": "55012"
  },
  {
    "key": "2653",
    "ITA": "CAPENA",
    "state_code": "RM",
    "cab": "389700",
    "cap": "60"
  },
  {
    "key": "2654",
    "ITA": "CAPENA-BIVIO DI CAPANELLE",
    "state_code": "RM",
    "cab": "389700",
    "cap": "60"
  },
  {
    "key": "2655",
    "ITA": "CAPERGNANICA",
    "state_code": "CR",
    "cab": "566901",
    "cap": "26010"
  },
  {
    "key": "2656",
    "ITA": "CAPESTRANO",
    "state_code": "AQ",
    "cab": "404905",
    "cap": "67022"
  },
  {
    "key": "2657",
    "ITA": "CAPIAGO INTIMIANO-INTIMIANO",
    "state_code": "CO",
    "cab": "510800",
    "cap": "22070"
  },
  {
    "key": "2658",
    "ITA": "CAPIAGO INTIMIANO-CAPIAGO",
    "state_code": "CO",
    "cab": "510800",
    "cap": "22070"
  },
  {
    "key": "2659",
    "ITA": "CAPIAGO INTIMIANO",
    "state_code": "CO",
    "cab": "510800",
    "cap": "22070"
  },
  {
    "key": "2660",
    "ITA": "CAPISTRANO",
    "state_code": "VV",
    "cab": "912709",
    "cap": "89818"
  },
  {
    "key": "2661",
    "ITA": "CAPISTRELLO",
    "state_code": "AQ",
    "cab": "405001",
    "cap": "67053"
  },
  {
    "key": "2662",
    "ITA": "CAPISTRELLO-CORCUMELLO",
    "state_code": "AQ",
    "cab": "405001",
    "cap": "67053"
  },
  {
    "key": "2663",
    "ITA": "CAPISTRELLO-PESCOCANALE",
    "state_code": "AQ",
    "cab": "405001",
    "cap": "67053"
  },
  {
    "key": "2664",
    "ITA": "CAPITIGNANO",
    "state_code": "AQ",
    "cab": "909523",
    "cap": "67014"
  },
  {
    "key": "2665",
    "ITA": "CAPIZZI",
    "state_code": "ME",
    "cab": "820902",
    "cap": "98031"
  },
  {
    "key": "2666",
    "ITA": "CAPIZZONE",
    "state_code": "BG",
    "cab": "927913",
    "cap": "24030"
  },
  {
    "key": "2667",
    "ITA": "CAPIZZONE-CAMONEONE",
    "state_code": "BG",
    "cab": "927913",
    "cap": "24030"
  },
  {
    "key": "2668",
    "ITA": "CAPO D'ORLANDO-SCAFA",
    "state_code": "ME",
    "cab": "821009",
    "cap": "98071"
  },
  {
    "key": "2669",
    "ITA": "CAPO D'ORLANDO",
    "state_code": "ME",
    "cab": "821009",
    "cap": "98071"
  },
  {
    "key": "2670",
    "ITA": "CAPO D'ORLANDO-PIANA DI CAPO D'ORLANDO",
    "state_code": "ME",
    "cab": "821009",
    "cap": "98071"
  },
  {
    "key": "2671",
    "ITA": "CAPO DI PONTE",
    "state_code": "BS",
    "cab": "542001",
    "cap": "25044"
  },
  {
    "key": "2672",
    "ITA": "CAPODIMONTE",
    "state_code": "VT",
    "cab": "729509",
    "cap": "1010"
  },
  {
    "key": "2673",
    "ITA": "CAPODRISE",
    "state_code": "CE",
    "cab": "898007",
    "cap": "81020"
  },
  {
    "key": "2674",
    "ITA": "CAPOLIVERI",
    "state_code": "LI",
    "cab": "706606",
    "cap": "57031"
  },
  {
    "key": "2675",
    "ITA": "CAPOLONA",
    "state_code": "AR",
    "cab": "713503",
    "cap": "52010"
  },
  {
    "key": "2676",
    "ITA": "CAPONAGO",
    "state_code": "MB",
    "cab": "327205",
    "cap": "20040"
  },
  {
    "key": "2677",
    "ITA": "CAPORCIANO",
    "state_code": "AQ",
    "cab": "909531",
    "cap": "67020"
  },
  {
    "key": "2678",
    "ITA": "CAPOSELE-MATERDOMINI",
    "state_code": "AV",
    "cab": "757104",
    "cap": "83040"
  },
  {
    "key": "2679",
    "ITA": "CAPOSELE",
    "state_code": "AV",
    "cab": "757104",
    "cap": "83040"
  },
  {
    "key": "2680",
    "ITA": "CAPOTERRA-SAN LEONE",
    "state_code": "CA",
    "cab": "440800",
    "cap": "9012"
  },
  {
    "key": "2681",
    "ITA": "CAPOTERRA-POGGIO DEI PINI",
    "state_code": "CA",
    "cab": "440800",
    "cap": "9012"
  },
  {
    "key": "2682",
    "ITA": "CAPOTERRA-LA MADDALENA",
    "state_code": "CA",
    "cab": "440800",
    "cap": "9012"
  },
  {
    "key": "2683",
    "ITA": "CAPOTERRA",
    "state_code": "CA",
    "cab": "440800",
    "cap": "9012"
  },
  {
    "key": "2684",
    "ITA": "CAPOVALLE",
    "state_code": "BS",
    "cab": "771501",
    "cap": "25070"
  },
  {
    "key": "2685",
    "ITA": "CAPPADOCIA",
    "state_code": "AQ",
    "cab": "909549",
    "cap": "67060"
  },
  {
    "key": "2686",
    "ITA": "CAPPADOCIA-PETRELLA LIRI",
    "state_code": "AQ",
    "cab": "909549",
    "cap": "67060"
  },
  {
    "key": "2687",
    "ITA": "CAPPADOCIA-VERRECCHIE",
    "state_code": "AQ",
    "cab": "909549",
    "cap": "67060"
  },
  {
    "key": "2688",
    "ITA": "CAPPELLA CANTONE",
    "state_code": "CR",
    "cab": "931535",
    "cap": "26020"
  },
  {
    "key": "2689",
    "ITA": "CAPPELLA DE' PICENARDI",
    "state_code": "CR",
    "cab": "931543",
    "cap": "26030"
  },
  {
    "key": "2690",
    "ITA": "CAPPELLA MAGGIORE",
    "state_code": "TV",
    "cab": "615203",
    "cap": "31012"
  },
  {
    "key": "2691",
    "ITA": "CAPPELLA MAGGIORE-ANZANO",
    "state_code": "TV",
    "cab": "615203",
    "cap": "31012"
  },
  {
    "key": "2692",
    "ITA": "CAPPELLE SUL TAVO",
    "state_code": "PE",
    "cab": "772103",
    "cap": "65010"
  },
  {
    "key": "2693",
    "ITA": "CAPRACOTTA",
    "state_code": "IS",
    "cab": "780908",
    "cap": "86082"
  },
  {
    "key": "2694",
    "ITA": "CAPRAIA E LIMITE",
    "state_code": "FI",
    "cab": "377705",
    "cap": "50050"
  },
  {
    "key": "2695",
    "ITA": "CAPRAIA E LIMITE-CAPRAIA",
    "state_code": "FI",
    "cab": "377705",
    "cap": "50050"
  },
  {
    "key": "2696",
    "ITA": "CAPRAIA E LIMITE-LIMITE SULL'ARNO",
    "state_code": "FI",
    "cab": "377705",
    "cap": "50050"
  },
  {
    "key": "2697",
    "ITA": "CAPRAIA ISOLA",
    "state_code": "LI",
    "cab": "706705",
    "cap": "57032"
  },
  {
    "key": "2698",
    "ITA": "CAPRALBA",
    "state_code": "CR",
    "cab": "567008",
    "cap": "26010"
  },
  {
    "key": "2699",
    "ITA": "CAPRANICA",
    "state_code": "VT",
    "cab": "729608",
    "cap": "1012"
  },
  {
    "key": "2700",
    "ITA": "CAPRANICA-VICO MATRINO",
    "state_code": "VT",
    "cab": "729608",
    "cap": "1012"
  },
  {
    "key": "2701",
    "ITA": "CAPRANICA PRENESTINA-GUADAGNOLO",
    "state_code": "RM",
    "cab": "908053",
    "cap": "30"
  },
  {
    "key": "2702",
    "ITA": "CAPRANICA PRENESTINA",
    "state_code": "RM",
    "cab": "908053",
    "cap": "30"
  },
  {
    "key": "2703",
    "ITA": "CAPRARICA DI LECCE",
    "state_code": "LE",
    "cab": "794909",
    "cap": "73010"
  },
  {
    "key": "2704",
    "ITA": "CAPRAROLA",
    "state_code": "VT",
    "cab": "729707",
    "cap": "1032"
  },
  {
    "key": "2705",
    "ITA": "CAPRAUNA",
    "state_code": "CN",
    "cab": "918441",
    "cap": "12070"
  },
  {
    "key": "2706",
    "ITA": "CAPRESE MICHELANGELO",
    "state_code": "AR",
    "cab": "713602",
    "cap": "52033"
  },
  {
    "key": "2707",
    "ITA": "CAPREZZO",
    "state_code": "VB",
    "cab": "917070",
    "cap": "28815"
  },
  {
    "key": "2708",
    "ITA": "CAPRI-MARINA GRANDE DI CAPRI",
    "state_code": "NA",
    "cab": "397901",
    "cap": "80073"
  },
  {
    "key": "2709",
    "ITA": "CAPRI",
    "state_code": "NA",
    "cab": "397901",
    "cap": "80073"
  },
  {
    "key": "2710",
    "ITA": "CAPRI LEONE-ROCCA DI CAPRI LEONE",
    "state_code": "ME",
    "cab": "821108",
    "cap": "98070"
  },
  {
    "key": "2711",
    "ITA": "CAPRI LEONE",
    "state_code": "ME",
    "cab": "821108",
    "cap": "98070"
  },
  {
    "key": "2712",
    "ITA": "CAPRIANA",
    "state_code": "TN",
    "cab": "345306",
    "cap": "38030"
  },
  {
    "key": "2713",
    "ITA": "CAPRIANO DEL COLLE",
    "state_code": "BS",
    "cab": "542100",
    "cap": "25020"
  },
  {
    "key": "2714",
    "ITA": "CAPRIATA D'ORBA",
    "state_code": "AL",
    "cab": "481002",
    "cap": "15060"
  },
  {
    "key": "2715",
    "ITA": "CAPRIATE SAN GERVASIO",
    "state_code": "BG",
    "cab": "527507",
    "cap": "24042"
  },
  {
    "key": "2716",
    "ITA": "CAPRIATE SAN GERVASIO-CRESPI D'ADDA",
    "state_code": "BG",
    "cab": "527507",
    "cap": "24042"
  },
  {
    "key": "2717",
    "ITA": "CAPRIATE SAN GERVASIO-SAN GERVASIO D'ADDA",
    "state_code": "BG",
    "cab": "527507",
    "cap": "24042"
  },
  {
    "key": "2718",
    "ITA": "CAPRIATI A VOLTURNO",
    "state_code": "CE",
    "cab": "949172",
    "cap": "81014"
  },
  {
    "key": "2719",
    "ITA": "CAPRIE-NOVARETTO",
    "state_code": "TO",
    "cab": "189910",
    "cap": "10040"
  },
  {
    "key": "2720",
    "ITA": "CAPRIE",
    "state_code": "TO",
    "cab": "189910",
    "cap": "10040"
  },
  {
    "key": "2721",
    "ITA": "CAPRIGLIA IRPINA",
    "state_code": "AV",
    "cab": "950980",
    "cap": "83010"
  },
  {
    "key": "2722",
    "ITA": "CAPRIGLIA IRPINA-SAN FELICE",
    "state_code": "AV",
    "cab": "950980",
    "cap": "83010"
  },
  {
    "key": "2723",
    "ITA": "CAPRIGLIO",
    "state_code": "AT",
    "cab": "919985",
    "cap": "14014"
  },
  {
    "key": "2724",
    "ITA": "CAPRILE",
    "state_code": "BI",
    "cab": "915710",
    "cap": "13864"
  },
  {
    "key": "2725",
    "ITA": "CAPRINO BERGAMASCO",
    "state_code": "BG",
    "cab": "527606",
    "cap": "24030"
  },
  {
    "key": "2726",
    "ITA": "CAPRINO BERGAMASCO-CAPRINO",
    "state_code": "BG",
    "cab": "527606",
    "cap": "24030"
  },
  {
    "key": "2727",
    "ITA": "CAPRINO BERGAMASCO-CELANA",
    "state_code": "BG",
    "cab": "527606",
    "cap": "24030"
  },
  {
    "key": "2728",
    "ITA": "CAPRINO BERGAMASCO-SANT'ANTONIO",
    "state_code": "BG",
    "cab": "527606",
    "cap": "24030"
  },
  {
    "key": "2729",
    "ITA": "CAPRINO VERONESE",
    "state_code": "VR",
    "cab": "593301",
    "cap": "37013"
  },
  {
    "key": "2730",
    "ITA": "CAPRINO VERONESE-SPIAZZI",
    "state_code": "VR",
    "cab": "593301",
    "cap": "37013"
  },
  {
    "key": "2731",
    "ITA": "CAPRINO VERONESE-BOI",
    "state_code": "VR",
    "cab": "593301",
    "cap": "37013"
  },
  {
    "key": "2732",
    "ITA": "CAPRINO VERONESE-PESINA",
    "state_code": "VR",
    "cab": "593301",
    "cap": "37013"
  },
  {
    "key": "2733",
    "ITA": "CAPRIOLO",
    "state_code": "BS",
    "cab": "542209",
    "cap": "25031"
  },
  {
    "key": "2734",
    "ITA": "CAPRIVA DEL FRIULI",
    "state_code": "GO",
    "cab": "645408",
    "cap": "34070"
  },
  {
    "key": "2735",
    "ITA": "CAPUA-SANT'ANGELO IN FORMIS",
    "state_code": "CE",
    "cab": "748202",
    "cap": "81043"
  },
  {
    "key": "2736",
    "ITA": "CAPUA",
    "state_code": "CE",
    "cab": "748202",
    "cap": "81043"
  },
  {
    "key": "2737",
    "ITA": "CAPURSO",
    "state_code": "BA",
    "cab": "414102",
    "cap": "70010"
  },
  {
    "key": "2738",
    "ITA": "CAPURSO-SAN PIETRO",
    "state_code": "BA",
    "cab": "414102",
    "cap": "70010"
  },
  {
    "key": "2739",
    "ITA": "CAPURSO-SUPERGA",
    "state_code": "BA",
    "cab": "414102",
    "cap": "70010"
  },
  {
    "key": "2740",
    "ITA": "CARAFFA DEL BIANCO",
    "state_code": "RC",
    "cab": "960047",
    "cap": "89030"
  },
  {
    "key": "2741",
    "ITA": "CARAFFA DI CATANZARO",
    "state_code": "CZ",
    "cab": "912717",
    "cap": "88050"
  },
  {
    "key": "2742",
    "ITA": "CARAGLIO",
    "state_code": "CN",
    "cab": "460709",
    "cap": "12023"
  },
  {
    "key": "2743",
    "ITA": "CARAMAGNA PIEMONTE",
    "state_code": "CN",
    "cab": "460808",
    "cap": "12030"
  },
  {
    "key": "2744",
    "ITA": "CARAMANICO TERME-SAN TOMMASO",
    "state_code": "PE",
    "cab": "772202",
    "cap": "65023"
  },
  {
    "key": "2745",
    "ITA": "CARAMANICO TERME",
    "state_code": "PE",
    "cab": "772202",
    "cap": "65023"
  },
  {
    "key": "2746",
    "ITA": "CARANO",
    "state_code": "TN",
    "cab": "345405",
    "cap": "38033"
  },
  {
    "key": "2747",
    "ITA": "CARAPELLE",
    "state_code": "FG",
    "cab": "787309",
    "cap": "71041"
  },
  {
    "key": "2748",
    "ITA": "CARAPELLE CALVISIO",
    "state_code": "AQ",
    "cab": "909556",
    "cap": "67020"
  },
  {
    "key": "2749",
    "ITA": "CARASCO-GRAVEGLIA",
    "state_code": "GE",
    "cab": "319103",
    "cap": "16042"
  },
  {
    "key": "2750",
    "ITA": "CARASCO-RIVAROLA",
    "state_code": "GE",
    "cab": "319103",
    "cap": "16042"
  },
  {
    "key": "2751",
    "ITA": "CARASCO",
    "state_code": "GE",
    "cab": "319103",
    "cap": "16042"
  },
  {
    "key": "2752",
    "ITA": "CARASSAI",
    "state_code": "AP",
    "cab": "693804",
    "cap": "63030"
  },
  {
    "key": "2753",
    "ITA": "CARATE BRIANZA-AGLIATE",
    "state_code": "MB",
    "cab": "327304",
    "cap": "20048"
  },
  {
    "key": "2754",
    "ITA": "CARATE BRIANZA",
    "state_code": "MB",
    "cab": "327304",
    "cap": "20048"
  },
  {
    "key": "2755",
    "ITA": "CARATE URIO",
    "state_code": "CO",
    "cab": "925255",
    "cap": "22010"
  },
  {
    "key": "2756",
    "ITA": "CARATE URIO-URIO",
    "state_code": "CO",
    "cab": "925255",
    "cap": "22010"
  },
  {
    "key": "2757",
    "ITA": "CARAVAGGIO-VIDALENGO",
    "state_code": "BG",
    "cab": "527705",
    "cap": "24043"
  },
  {
    "key": "2758",
    "ITA": "CARAVAGGIO",
    "state_code": "BG",
    "cab": "527705",
    "cap": "24043"
  },
  {
    "key": "2759",
    "ITA": "CARAVAGGIO-MASANO",
    "state_code": "BG",
    "cab": "527705",
    "cap": "24043"
  },
  {
    "key": "2760",
    "ITA": "CARAVATE",
    "state_code": "VA",
    "cab": "507608",
    "cap": "21032"
  },
  {
    "key": "2761",
    "ITA": "CARAVINO-MASINO",
    "state_code": "TO",
    "cab": "313403",
    "cap": "10010"
  },
  {
    "key": "2762",
    "ITA": "CARAVINO",
    "state_code": "TO",
    "cab": "313403",
    "cap": "10010"
  },
  {
    "key": "2763",
    "ITA": "CARAVONICA",
    "state_code": "IM",
    "cab": "922302",
    "cap": "18020"
  },
  {
    "key": "2764",
    "ITA": "CARBOGNANO",
    "state_code": "VT",
    "cab": "729806",
    "cap": "1030"
  },
  {
    "key": "2765",
    "ITA": "CARBONARA AL TICINO",
    "state_code": "PV",
    "cab": "780601",
    "cap": "27020"
  },
  {
    "key": "2766",
    "ITA": "CARBONARA DI NOLA",
    "state_code": "NA",
    "cab": "908822",
    "cap": "80030"
  },
  {
    "key": "2767",
    "ITA": "CARBONARA DI PO",
    "state_code": "MN",
    "cab": "574905",
    "cap": "46020"
  },
  {
    "key": "2768",
    "ITA": "CARBONARA SCRIVIA",
    "state_code": "AL",
    "cab": "920918",
    "cap": "15050"
  },
  {
    "key": "2769",
    "ITA": "CARBONATE",
    "state_code": "CO",
    "cab": "896001",
    "cap": "22070"
  },
  {
    "key": "2770",
    "ITA": "CARBONE",
    "state_code": "PZ",
    "cab": "911891",
    "cap": "85030"
  },
  {
    "key": "2771",
    "ITA": "CARBONERA",
    "state_code": "TV",
    "cab": "615302",
    "cap": "31030"
  },
  {
    "key": "2772",
    "ITA": "CARBONERA-MIGNAGOLA",
    "state_code": "TV",
    "cab": "615302",
    "cap": "31030"
  },
  {
    "key": "2773",
    "ITA": "CARBONERA-VASCON",
    "state_code": "TV",
    "cab": "615302",
    "cap": "31050"
  },
  {
    "key": "2774",
    "ITA": "CARBONIA-BACU ABIS",
    "state_code": "SU",
    "cab": "438507",
    "cap": "9010"
  },
  {
    "key": "2775",
    "ITA": "CARBONIA",
    "state_code": "SU",
    "cab": "438507",
    "cap": "9013"
  },
  {
    "key": "2776",
    "ITA": "CARBONIA-CORTOGHIANA",
    "state_code": "SU",
    "cab": "438507",
    "cap": "9010"
  },
  {
    "key": "2777",
    "ITA": "CARBONIA-SERBARIU",
    "state_code": "SU",
    "cab": "438507",
    "cap": "9013"
  },
  {
    "key": "2778",
    "ITA": "CARBONIA-SIRAI",
    "state_code": "SU",
    "cab": "438507",
    "cap": "9013"
  },
  {
    "key": "2779",
    "ITA": "CARBONIA-BARBUSI",
    "state_code": "SU",
    "cab": "438507",
    "cap": "9013"
  },
  {
    "key": "2780",
    "ITA": "CARCARE",
    "state_code": "SV",
    "cab": "493502",
    "cap": "17043"
  },
  {
    "key": "2781",
    "ITA": "CARCERI",
    "state_code": "PD",
    "cab": "624601",
    "cap": "35040"
  },
  {
    "key": "2782",
    "ITA": "CARCOFORO",
    "state_code": "VC",
    "cab": "915728",
    "cap": "13026"
  },
  {
    "key": "2783",
    "ITA": "CARDANO AL CAMPO",
    "state_code": "VA",
    "cab": "500702",
    "cap": "21010"
  },
  {
    "key": "2784",
    "ITA": "CARDE'",
    "state_code": "CN",
    "cab": "460907",
    "cap": "12030"
  },
  {
    "key": "2785",
    "ITA": "CARDEDU",
    "state_code": "OG",
    "cab": "966226",
    "cap": "8040"
  },
  {
    "key": "2786",
    "ITA": "CARDETO",
    "state_code": "RC",
    "cab": "960054",
    "cap": "89060"
  },
  {
    "key": "2787",
    "ITA": "CARDINALE-NOVALBA",
    "state_code": "CZ",
    "cab": "700302",
    "cap": "88062"
  },
  {
    "key": "2788",
    "ITA": "CARDINALE",
    "state_code": "CZ",
    "cab": "700302",
    "cap": "88062"
  },
  {
    "key": "2789",
    "ITA": "CARDITO-CARDITELLO",
    "state_code": "NA",
    "cab": "398008",
    "cap": "80024"
  },
  {
    "key": "2790",
    "ITA": "CARDITO",
    "state_code": "NA",
    "cab": "398008",
    "cap": "80024"
  },
  {
    "key": "2791",
    "ITA": "CAREGGINE",
    "state_code": "LU",
    "cab": "943407",
    "cap": "55030"
  },
  {
    "key": "2792",
    "ITA": "CAREMA",
    "state_code": "TO",
    "cab": "283259",
    "cap": "10010"
  },
  {
    "key": "2793",
    "ITA": "CARENNO",
    "state_code": "LC",
    "cab": "538603",
    "cap": "23802"
  },
  {
    "key": "2794",
    "ITA": "CARENTINO",
    "state_code": "AL",
    "cab": "920926",
    "cap": "15026"
  },
  {
    "key": "2795",
    "ITA": "CARERI-NATILE NUOVO",
    "state_code": "RC",
    "cab": "960062",
    "cap": "89030"
  },
  {
    "key": "2796",
    "ITA": "CARERI-NATILE VECCHIO",
    "state_code": "RC",
    "cab": "960062",
    "cap": "89030"
  },
  {
    "key": "2797",
    "ITA": "CARERI",
    "state_code": "RC",
    "cab": "960062",
    "cap": "89030"
  },
  {
    "key": "2798",
    "ITA": "CARESANA",
    "state_code": "VC",
    "cab": "443804",
    "cap": "13010"
  },
  {
    "key": "2799",
    "ITA": "CARESANABLOT",
    "state_code": "VC",
    "cab": "449504",
    "cap": "13030"
  },
  {
    "key": "2800",
    "ITA": "CAREZZANO",
    "state_code": "AL",
    "cab": "920934",
    "cap": "15051"
  },
  {
    "key": "2801",
    "ITA": "CARFIZZI",
    "state_code": "KR",
    "cab": "912733",
    "cap": "88817"
  },
  {
    "key": "2802",
    "ITA": "CARGEGHE",
    "state_code": "SS",
    "cab": "872903",
    "cap": "7030"
  },
  {
    "key": "2803",
    "ITA": "CARIATI-CARIATI MARINA",
    "state_code": "CS",
    "cab": "806406",
    "cap": "87062"
  },
  {
    "key": "2804",
    "ITA": "CARIATI",
    "state_code": "CS",
    "cab": "806406",
    "cap": "87062"
  },
  {
    "key": "2805",
    "ITA": "CARIFE",
    "state_code": "AV",
    "cab": "950998",
    "cap": "83040"
  },
  {
    "key": "2806",
    "ITA": "CARIGNANO-CERETTO",
    "state_code": "TO",
    "cab": "302505",
    "cap": "10041"
  },
  {
    "key": "2807",
    "ITA": "CARIGNANO",
    "state_code": "TO",
    "cab": "302505",
    "cap": "10041"
  },
  {
    "key": "2808",
    "ITA": "CARIMATE-MONTESOLARO",
    "state_code": "CO",
    "cab": "510909",
    "cap": "22060"
  },
  {
    "key": "2809",
    "ITA": "CARIMATE",
    "state_code": "CO",
    "cab": "510909",
    "cap": "22060"
  },
  {
    "key": "2810",
    "ITA": "CARINARO",
    "state_code": "CE",
    "cab": "471706",
    "cap": "81032"
  },
  {
    "key": "2811",
    "ITA": "CARINI-VILLAGRAZIA DI CARINI",
    "state_code": "PA",
    "cab": "432104",
    "cap": "90044"
  },
  {
    "key": "2812",
    "ITA": "CARINI",
    "state_code": "PA",
    "cab": "432104",
    "cap": "90044"
  },
  {
    "key": "2813",
    "ITA": "CARINOLA-CASANOVA",
    "state_code": "CE",
    "cab": "748301",
    "cap": "81030"
  },
  {
    "key": "2814",
    "ITA": "CARINOLA-CASALE DI CARINOLA",
    "state_code": "CE",
    "cab": "748301",
    "cap": "81030"
  },
  {
    "key": "2815",
    "ITA": "CARINOLA",
    "state_code": "CE",
    "cab": "748301",
    "cap": "81030"
  },
  {
    "key": "2816",
    "ITA": "CARINOLA-NOCELLETO",
    "state_code": "CE",
    "cab": "748301",
    "cap": "81030"
  },
  {
    "key": "2817",
    "ITA": "CARISIO-FORNACE CROCICCHIO",
    "state_code": "VC",
    "cab": "712406",
    "cap": "13040"
  },
  {
    "key": "2818",
    "ITA": "CARISIO",
    "state_code": "VC",
    "cab": "712406",
    "cap": "13040"
  },
  {
    "key": "2819",
    "ITA": "CARISOLO",
    "state_code": "TN",
    "cab": "359208",
    "cap": "38080"
  },
  {
    "key": "2820",
    "ITA": "CARLANTINO",
    "state_code": "FG",
    "cab": "956334",
    "cap": "71030"
  },
  {
    "key": "2821",
    "ITA": "CARLAZZO-SAN PIETRO SOVERA",
    "state_code": "CO",
    "cab": "520106",
    "cap": "22010"
  },
  {
    "key": "2822",
    "ITA": "CARLAZZO-PIANO PORLEZZA",
    "state_code": "CO",
    "cab": "520106",
    "cap": "22010"
  },
  {
    "key": "2823",
    "ITA": "CARLAZZO",
    "state_code": "CO",
    "cab": "520106",
    "cap": "22010"
  },
  {
    "key": "2824",
    "ITA": "CARLENTINI-PEDAGAGGI",
    "state_code": "SR",
    "cab": "846709",
    "cap": "96013"
  },
  {
    "key": "2825",
    "ITA": "CARLENTINI-BORGO RIZZA",
    "state_code": "SR",
    "cab": "846709",
    "cap": "96013"
  },
  {
    "key": "2826",
    "ITA": "CARLENTINI",
    "state_code": "SR",
    "cab": "846709",
    "cap": "96013"
  },
  {
    "key": "2827",
    "ITA": "CARLINO",
    "state_code": "UD",
    "cab": "645200",
    "cap": "33050"
  },
  {
    "key": "2828",
    "ITA": "CARLINO-CASALE DELLA MADONNA",
    "state_code": "UD",
    "cab": "645200",
    "cap": "33050"
  },
  {
    "key": "2829",
    "ITA": "CARLOFORTE",
    "state_code": "SU",
    "cab": "438606",
    "cap": "9014"
  },
  {
    "key": "2830",
    "ITA": "CARLOPOLI",
    "state_code": "CZ",
    "cab": "887208",
    "cap": "88040"
  },
  {
    "key": "2831",
    "ITA": "CARLOPOLI-CASTAGNA",
    "state_code": "CZ",
    "cab": "887208",
    "cap": "88040"
  },
  {
    "key": "2832",
    "ITA": "CARMAGNOLA-SAN MICHELE E GRATO",
    "state_code": "TO",
    "cab": "302604",
    "cap": "10022"
  },
  {
    "key": "2833",
    "ITA": "CARMAGNOLA-SAN BERNARDO CARMAGNOLA",
    "state_code": "TO",
    "cab": "302604",
    "cap": "10022"
  },
  {
    "key": "2834",
    "ITA": "CARMAGNOLA",
    "state_code": "TO",
    "cab": "302604",
    "cap": "10022"
  },
  {
    "key": "2835",
    "ITA": "CARMAGNOLA-BORGO SALSASIO",
    "state_code": "TO",
    "cab": "302604",
    "cap": "10022"
  },
  {
    "key": "2836",
    "ITA": "CARMAGNOLA-CAVALLERI",
    "state_code": "TO",
    "cab": "302604",
    "cap": "10022"
  },
  {
    "key": "2837",
    "ITA": "CARMAGNOLA-FUMERI",
    "state_code": "TO",
    "cab": "302604",
    "cap": "10022"
  },
  {
    "key": "2838",
    "ITA": "CARMIANO-MAGLIANO",
    "state_code": "LE",
    "cab": "795005",
    "cap": "73041"
  },
  {
    "key": "2839",
    "ITA": "CARMIANO",
    "state_code": "LE",
    "cab": "795005",
    "cap": "73041"
  },
  {
    "key": "2840",
    "ITA": "CARMIGNANO",
    "state_code": "PO",
    "cab": "377804",
    "cap": "59015"
  },
  {
    "key": "2841",
    "ITA": "CARMIGNANO-BACCHERETO",
    "state_code": "PO",
    "cab": "377804",
    "cap": "59011"
  },
  {
    "key": "2842",
    "ITA": "CARMIGNANO-ARTIMINO",
    "state_code": "PO",
    "cab": "377804",
    "cap": "59015"
  },
  {
    "key": "2843",
    "ITA": "CARMIGNANO-COMEANA",
    "state_code": "PO",
    "cab": "377804",
    "cap": "59015"
  },
  {
    "key": "2844",
    "ITA": "CARMIGNANO-SEANO",
    "state_code": "PO",
    "cab": "377804",
    "cap": "59011"
  },
  {
    "key": "2845",
    "ITA": "CARMIGNANO-POGGIO ALLA MALVA",
    "state_code": "PO",
    "cab": "377804",
    "cap": "59015"
  },
  {
    "key": "2846",
    "ITA": "CARMIGNANO-LA SERRA",
    "state_code": "PO",
    "cab": "377804",
    "cap": "59015"
  },
  {
    "key": "2847",
    "ITA": "CARMIGNANO DI BRENTA",
    "state_code": "PD",
    "cab": "624700",
    "cap": "35010"
  },
  {
    "key": "2848",
    "ITA": "CARMIGNANO DI BRENTA-CAMAZZOLE",
    "state_code": "PD",
    "cab": "624700",
    "cap": "35010"
  },
  {
    "key": "2849",
    "ITA": "CARNAGO",
    "state_code": "VA",
    "cab": "500801",
    "cap": "21040"
  },
  {
    "key": "2850",
    "ITA": "CARNAGO-ROVATE",
    "state_code": "VA",
    "cab": "500801",
    "cap": "21040"
  },
  {
    "key": "2851",
    "ITA": "CARNATE",
    "state_code": "MB",
    "cab": "327403",
    "cap": "20040"
  },
  {
    "key": "2852",
    "ITA": "CAROBBIO DEGLI ANGELI",
    "state_code": "BG",
    "cab": "539601",
    "cap": "24060"
  },
  {
    "key": "2853",
    "ITA": "CAROLEI",
    "state_code": "CS",
    "cab": "806505",
    "cap": "87030"
  },
  {
    "key": "2854",
    "ITA": "CAROLEI-VADUE",
    "state_code": "CS",
    "cab": "806505",
    "cap": "87030"
  },
  {
    "key": "2855",
    "ITA": "CARONA",
    "state_code": "BG",
    "cab": "812008",
    "cap": "24010"
  },
  {
    "key": "2856",
    "ITA": "CARONIA-CANNETO DI CARONIA",
    "state_code": "ME",
    "cab": "821207",
    "cap": "98072"
  },
  {
    "key": "2857",
    "ITA": "CARONIA-MARINA DI CARONIA",
    "state_code": "ME",
    "cab": "821207",
    "cap": "98072"
  },
  {
    "key": "2858",
    "ITA": "CARONIA",
    "state_code": "ME",
    "cab": "821207",
    "cap": "98072"
  },
  {
    "key": "2859",
    "ITA": "CARONNO PERTUSELLA",
    "state_code": "VA",
    "cab": "500900",
    "cap": "21042"
  },
  {
    "key": "2860",
    "ITA": "CARONNO VARESINO",
    "state_code": "VA",
    "cab": "882308",
    "cap": "21040"
  },
  {
    "key": "2861",
    "ITA": "CAROSINO",
    "state_code": "TA",
    "cab": "788307",
    "cap": "74021"
  },
  {
    "key": "2862",
    "ITA": "CAROVIGNO-SERRANOVA",
    "state_code": "BR",
    "cab": "791400",
    "cap": "72012"
  },
  {
    "key": "2863",
    "ITA": "CAROVIGNO",
    "state_code": "BR",
    "cab": "791400",
    "cap": "72012"
  },
  {
    "key": "2864",
    "ITA": "CAROVILLI",
    "state_code": "IS",
    "cab": "781005",
    "cap": "86083"
  },
  {
    "key": "2865",
    "ITA": "CAROVILLI-CASTIGLIONE",
    "state_code": "IS",
    "cab": "781005",
    "cap": "86083"
  },
  {
    "key": "2866",
    "ITA": "CARPANETO PIACENTINO-REZZANO",
    "state_code": "PC",
    "cab": "652404",
    "cap": "29013"
  },
  {
    "key": "2867",
    "ITA": "CARPANETO PIACENTINO",
    "state_code": "PC",
    "cab": "652404",
    "cap": "29013"
  },
  {
    "key": "2868",
    "ITA": "CARPANZANO",
    "state_code": "CS",
    "cab": "958686",
    "cap": "87050"
  },
  {
    "key": "2869",
    "ITA": "CARPASIO",
    "state_code": "IM",
    "cab": "922310",
    "cap": "18010"
  },
  {
    "key": "2870",
    "ITA": "CARPEGNA",
    "state_code": "PU",
    "cab": "682500",
    "cap": "61021"
  },
  {
    "key": "2871",
    "ITA": "CARPENEDOLO",
    "state_code": "BS",
    "cab": "542308",
    "cap": "25013"
  },
  {
    "key": "2872",
    "ITA": "CARPENETO",
    "state_code": "AL",
    "cab": "481101",
    "cap": "15071"
  },
  {
    "key": "2873",
    "ITA": "CARPI-SAN MARTINO SECCHIA",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2874",
    "ITA": "CARPI-SAN MARINO",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2875",
    "ITA": "CARPI-MIGLIARINA",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2876",
    "ITA": "CARPI-GARGALLO",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2877",
    "ITA": "CARPI-FOSSOLI",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2878",
    "ITA": "CARPI-CORTILE",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2879",
    "ITA": "CARPI-SANTA CROCE",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2880",
    "ITA": "CARPI",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2881",
    "ITA": "CARPI-BUDRIONE",
    "state_code": "MO",
    "cab": "233007",
    "cap": "41012"
  },
  {
    "key": "2882",
    "ITA": "CARPIANO",
    "state_code": "MI",
    "cab": "327502",
    "cap": "20080"
  },
  {
    "key": "2883",
    "ITA": "CARPIGNANO SALENTINO-SERRANO",
    "state_code": "LE",
    "cab": "795104",
    "cap": "73020"
  },
  {
    "key": "2884",
    "ITA": "CARPIGNANO SALENTINO",
    "state_code": "LE",
    "cab": "795104",
    "cap": "73020"
  },
  {
    "key": "2885",
    "ITA": "CARPIGNANO SESIA",
    "state_code": "NO",
    "cab": "452805",
    "cap": "28064"
  },
  {
    "key": "2886",
    "ITA": "CARPINETI",
    "state_code": "RE",
    "cab": "662304",
    "cap": "42033"
  },
  {
    "key": "2887",
    "ITA": "CARPINETI-MAROLA",
    "state_code": "RE",
    "cab": "662304",
    "cap": "42033"
  },
  {
    "key": "2888",
    "ITA": "CARPINETI-PANTANO",
    "state_code": "RE",
    "cab": "662304",
    "cap": "42033"
  },
  {
    "key": "2889",
    "ITA": "CARPINETI-VALESTRA",
    "state_code": "RE",
    "cab": "662304",
    "cap": "42033"
  },
  {
    "key": "2890",
    "ITA": "CARPINETI-SAVOGNATICA",
    "state_code": "RE",
    "cab": "662304",
    "cap": "42033"
  },
  {
    "key": "2891",
    "ITA": "CARPINETO DELLA NORA",
    "state_code": "PE",
    "cab": "954032",
    "cap": "65010"
  },
  {
    "key": "2892",
    "ITA": "CARPINETO ROMANO",
    "state_code": "RM",
    "cab": "389809",
    "cap": "32"
  },
  {
    "key": "2893",
    "ITA": "CARPINETO SINELLO",
    "state_code": "CH",
    "cab": "954560",
    "cap": "66030"
  },
  {
    "key": "2894",
    "ITA": "CARPINO",
    "state_code": "FG",
    "cab": "783308",
    "cap": "71010"
  },
  {
    "key": "2895",
    "ITA": "CARPINONE",
    "state_code": "IS",
    "cab": "955534",
    "cap": "86093"
  },
  {
    "key": "2896",
    "ITA": "CARRARA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2897",
    "ITA": "CARRARA-AVENZA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2898",
    "ITA": "CARRARA-TORANO",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2899",
    "ITA": "CARRARA-SORGNANO",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2900",
    "ITA": "CARRARA-MISEGLIA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2901",
    "ITA": "CARRARA-MARINA DI CARRARA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2902",
    "ITA": "CARRARA-GRAGNANA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2903",
    "ITA": "CARRARA-BEDIZZANO",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2904",
    "ITA": "CARRARA-BERGIOLA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2905",
    "ITA": "CARRARA-CASTELPOGGIO",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2906",
    "ITA": "CARRARA-CODENA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2907",
    "ITA": "CARRARA-COLONNATA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2908",
    "ITA": "CARRARA-FONTIA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2909",
    "ITA": "CARRARA-FOSSOLA",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2910",
    "ITA": "CARRARA-FOSSONE",
    "state_code": "MS",
    "cab": "245001",
    "cap": "54033"
  },
  {
    "key": "2911",
    "ITA": "CARRE'",
    "state_code": "VI",
    "cab": "883405",
    "cap": "36010"
  },
  {
    "key": "2912",
    "ITA": "CARREGA LIGURE",
    "state_code": "AL",
    "cab": "920942",
    "cap": "15060"
  },
  {
    "key": "2913",
    "ITA": "CARRO",
    "state_code": "SP",
    "cab": "923714",
    "cap": "19012"
  },
  {
    "key": "2914",
    "ITA": "CARRODANO-CASTELLO",
    "state_code": "SP",
    "cab": "923722",
    "cap": "19020"
  },
  {
    "key": "2915",
    "ITA": "CARRODANO",
    "state_code": "SP",
    "cab": "923722",
    "cap": "19020"
  },
  {
    "key": "2916",
    "ITA": "CARRODANO-MATTARANA",
    "state_code": "SP",
    "cab": "923722",
    "cap": "19020"
  },
  {
    "key": "2917",
    "ITA": "CARROSIO",
    "state_code": "AL",
    "cab": "687509",
    "cap": "15060"
  },
  {
    "key": "2918",
    "ITA": "CARRU'",
    "state_code": "CN",
    "cab": "461004",
    "cap": "12061"
  },
  {
    "key": "2919",
    "ITA": "CARSOLI-MONTESABINESE",
    "state_code": "AQ",
    "cab": "405100",
    "cap": "67061"
  },
  {
    "key": "2920",
    "ITA": "CARSOLI-POGGIO CINOLFO",
    "state_code": "AQ",
    "cab": "405100",
    "cap": "67061"
  },
  {
    "key": "2921",
    "ITA": "CARSOLI-PIETRASECCA",
    "state_code": "AQ",
    "cab": "405100",
    "cap": "67061"
  },
  {
    "key": "2922",
    "ITA": "CARSOLI",
    "state_code": "AQ",
    "cab": "405100",
    "cap": "67061"
  },
  {
    "key": "2923",
    "ITA": "CARSOLI-COLLI DI MONTEBOVE",
    "state_code": "AQ",
    "cab": "405100",
    "cap": "67061"
  },
  {
    "key": "2924",
    "ITA": "CARSOLI-VILLA ROMANA",
    "state_code": "AQ",
    "cab": "405100",
    "cap": "67061"
  },
  {
    "key": "2925",
    "ITA": "CARSOLI-TUFO DI CARSOLI",
    "state_code": "AQ",
    "cab": "405100",
    "cap": "67061"
  },
  {
    "key": "2926",
    "ITA": "CARTIGLIANO",
    "state_code": "VI",
    "cab": "602508",
    "cap": "36050"
  },
  {
    "key": "2927",
    "ITA": "CARTIGNANO",
    "state_code": "CN",
    "cab": "918458",
    "cap": "12020"
  },
  {
    "key": "2928",
    "ITA": "CARTOCETO",
    "state_code": "PU",
    "cab": "682609",
    "cap": "61030"
  },
  {
    "key": "2929",
    "ITA": "CARTOCETO-LUCREZIA",
    "state_code": "PU",
    "cab": "682609",
    "cap": "61030"
  },
  {
    "key": "2930",
    "ITA": "CARTOSIO",
    "state_code": "AL",
    "cab": "920967",
    "cap": "15015"
  },
  {
    "key": "2931",
    "ITA": "CARTURA-CAGNOLA",
    "state_code": "PD",
    "cab": "624809",
    "cap": "35025"
  },
  {
    "key": "2932",
    "ITA": "CARTURA",
    "state_code": "PD",
    "cab": "624809",
    "cap": "35025"
  },
  {
    "key": "2933",
    "ITA": "CARUGATE",
    "state_code": "MI",
    "cab": "327601",
    "cap": "20061"
  },
  {
    "key": "2934",
    "ITA": "CARUGO",
    "state_code": "CO",
    "cab": "511006",
    "cap": "22060"
  },
  {
    "key": "2935",
    "ITA": "CARUNCHIO",
    "state_code": "CH",
    "cab": "954578",
    "cap": "66050"
  },
  {
    "key": "2936",
    "ITA": "CARVICO",
    "state_code": "BG",
    "cab": "527804",
    "cap": "24030"
  },
  {
    "key": "2937",
    "ITA": "CARZANO",
    "state_code": "TN",
    "cab": "790709",
    "cap": "38050"
  },
  {
    "key": "2938",
    "ITA": "CASABONA",
    "state_code": "KR",
    "cab": "425009",
    "cap": "88822"
  },
  {
    "key": "2939",
    "ITA": "CASABONA-ZINGA",
    "state_code": "KR",
    "cab": "425009",
    "cap": "88822"
  },
  {
    "key": "2940",
    "ITA": "CASACALENDA",
    "state_code": "CB",
    "cab": "410001",
    "cap": "86043"
  },
  {
    "key": "2941",
    "ITA": "CASACANDITELLA-SEMIVICOLI",
    "state_code": "CH",
    "cab": "954586",
    "cap": "66010"
  },
  {
    "key": "2942",
    "ITA": "CASACANDITELLA",
    "state_code": "CH",
    "cab": "954586",
    "cap": "66010"
  },
  {
    "key": "2943",
    "ITA": "CASAGIOVE",
    "state_code": "CE",
    "cab": "748400",
    "cap": "81022"
  },
  {
    "key": "2944",
    "ITA": "CASAL CERMELLI",
    "state_code": "AL",
    "cab": "481200",
    "cap": "15072"
  },
  {
    "key": "2945",
    "ITA": "CASAL CERMELLI-PORTANOVA",
    "state_code": "AL",
    "cab": "481200",
    "cap": "15072"
  },
  {
    "key": "2946",
    "ITA": "CASAL DI PRINCIPE",
    "state_code": "CE",
    "cab": "748509",
    "cap": "81033"
  },
  {
    "key": "2947",
    "ITA": "CASAL VELINO-PONTE",
    "state_code": "SA",
    "cab": "478404",
    "cap": "84040"
  },
  {
    "key": "2948",
    "ITA": "CASAL VELINO-MARINA DI CASAL VELINO",
    "state_code": "SA",
    "cab": "478404",
    "cap": "84040"
  },
  {
    "key": "2949",
    "ITA": "CASAL VELINO-ACQUAVELLA",
    "state_code": "SA",
    "cab": "478404",
    "cap": "84040"
  },
  {
    "key": "2950",
    "ITA": "CASAL VELINO",
    "state_code": "SA",
    "cab": "478404",
    "cap": "84040"
  },
  {
    "key": "2951",
    "ITA": "CASALANGUIDA",
    "state_code": "CH",
    "cab": "954594",
    "cap": "66031"
  },
  {
    "key": "2952",
    "ITA": "CASALATTICO",
    "state_code": "FR",
    "cab": "287904",
    "cap": "3030"
  },
  {
    "key": "2953",
    "ITA": "CASALBELTRAME",
    "state_code": "NO",
    "cab": "275362",
    "cap": "28060"
  },
  {
    "key": "2954",
    "ITA": "CASALBORDINO-MIRACOLI",
    "state_code": "CH",
    "cab": "775908",
    "cap": "66021"
  },
  {
    "key": "2955",
    "ITA": "CASALBORDINO-CASALBORDINO STAZIONE",
    "state_code": "CH",
    "cab": "775908",
    "cap": "66021"
  },
  {
    "key": "2956",
    "ITA": "CASALBORDINO",
    "state_code": "CH",
    "cab": "775908",
    "cap": "66021"
  },
  {
    "key": "2957",
    "ITA": "CASALBORE",
    "state_code": "AV",
    "cab": "687707",
    "cap": "83034"
  },
  {
    "key": "2958",
    "ITA": "CASALBORGONE",
    "state_code": "TO",
    "cab": "302703",
    "cap": "10020"
  },
  {
    "key": "2959",
    "ITA": "CASALBUONO",
    "state_code": "SA",
    "cab": "496208",
    "cap": "84030"
  },
  {
    "key": "2960",
    "ITA": "CASALBUTTANO ED UNITI-CASALBUTTANO",
    "state_code": "CR",
    "cab": "567107",
    "cap": "26011"
  },
  {
    "key": "2961",
    "ITA": "CASALBUTTANO ED UNITI",
    "state_code": "CR",
    "cab": "567107",
    "cap": "26011"
  },
  {
    "key": "2962",
    "ITA": "CASALCIPRANO",
    "state_code": "CB",
    "cab": "910463",
    "cap": "86010"
  },
  {
    "key": "2963",
    "ITA": "CASALDUNI",
    "state_code": "BN",
    "cab": "950196",
    "cap": "82027"
  },
  {
    "key": "2964",
    "ITA": "CASALE CORTE CERRO-SANT'ANNA",
    "state_code": "VB",
    "cab": "452904",
    "cap": "28881"
  },
  {
    "key": "2965",
    "ITA": "CASALE CORTE CERRO-RAMATE",
    "state_code": "VB",
    "cab": "452904",
    "cap": "28881"
  },
  {
    "key": "2966",
    "ITA": "CASALE CORTE CERRO-GABBIO",
    "state_code": "VB",
    "cab": "452904",
    "cap": "28881"
  },
  {
    "key": "2967",
    "ITA": "CASALE CORTE CERRO-CEREDA",
    "state_code": "VB",
    "cab": "452904",
    "cap": "28881"
  },
  {
    "key": "2968",
    "ITA": "CASALE CORTE CERRO",
    "state_code": "VB",
    "cab": "452904",
    "cap": "28881"
  },
  {
    "key": "2969",
    "ITA": "CASALE CREMASCO VIDOLASCO-CASALE CREMASCO",
    "state_code": "CR",
    "cab": "567206",
    "cap": "26010"
  },
  {
    "key": "2970",
    "ITA": "CASALE CREMASCO VIDOLASCO",
    "state_code": "CR",
    "cab": "567206",
    "cap": "26010"
  },
  {
    "key": "2971",
    "ITA": "CASALE DI SCODOSIA",
    "state_code": "PD",
    "cab": "624908",
    "cap": "35040"
  },
  {
    "key": "2972",
    "ITA": "CASALE LITTA",
    "state_code": "VA",
    "cab": "834903",
    "cap": "21020"
  },
  {
    "key": "2973",
    "ITA": "CASALE LITTA-BERNATE",
    "state_code": "VA",
    "cab": "834903",
    "cap": "21020"
  },
  {
    "key": "2974",
    "ITA": "CASALE LITTA-VILLADOSIA",
    "state_code": "VA",
    "cab": "834903",
    "cap": "21020"
  },
  {
    "key": "2975",
    "ITA": "CASALE MARITTIMO",
    "state_code": "PI",
    "cab": "709303",
    "cap": "56040"
  },
  {
    "key": "2976",
    "ITA": "CASALE MONFERRATO-RONCAGLIA",
    "state_code": "AL",
    "cab": "226001",
    "cap": "15033"
  },
  {
    "key": "2977",
    "ITA": "CASALE MONFERRATO-POZZO SANT'EVASIO",
    "state_code": "AL",
    "cab": "226001",
    "cap": "15033"
  },
  {
    "key": "2978",
    "ITA": "CASALE MONFERRATO-TERRANOVA",
    "state_code": "AL",
    "cab": "226001",
    "cap": "15033"
  },
  {
    "key": "2979",
    "ITA": "CASALE MONFERRATO-POPOLO",
    "state_code": "AL",
    "cab": "226001",
    "cap": "15033"
  },
  {
    "key": "2980",
    "ITA": "CASALE MONFERRATO-SAN GERMANO",
    "state_code": "AL",
    "cab": "226001",
    "cap": "15033"
  },
  {
    "key": "2981",
    "ITA": "CASALE MONFERRATO-SANTA MARIA DEL TEMPIO",
    "state_code": "AL",
    "cab": "226001",
    "cap": "15033"
  },
  {
    "key": "2982",
    "ITA": "CASALE MONFERRATO",
    "state_code": "AL",
    "cab": "226001",
    "cap": "15033"
  },
  {
    "key": "2983",
    "ITA": "CASALE SUL SILE-LUGHIGNANO",
    "state_code": "TV",
    "cab": "615401",
    "cap": "31032"
  },
  {
    "key": "2984",
    "ITA": "CASALE SUL SILE-CONSCIO",
    "state_code": "TV",
    "cab": "615401",
    "cap": "31032"
  },
  {
    "key": "2985",
    "ITA": "CASALE SUL SILE",
    "state_code": "TV",
    "cab": "615401",
    "cap": "31032"
  },
  {
    "key": "2986",
    "ITA": "CASALECCHIO DI RENO",
    "state_code": "BO",
    "cab": "366708",
    "cap": "40033"
  },
  {
    "key": "2987",
    "ITA": "CASALECCHIO DI RENO-CANTAGALLO",
    "state_code": "BO",
    "cab": "366708",
    "cap": "40033"
  },
  {
    "key": "2988",
    "ITA": "CASALECCHIO DI RENO-CERETOLO",
    "state_code": "BO",
    "cab": "366708",
    "cap": "40033"
  },
  {
    "key": "2989",
    "ITA": "CASALEGGIO BOIRO",
    "state_code": "AL",
    "cab": "920975",
    "cap": "15070"
  },
  {
    "key": "2990",
    "ITA": "CASALEGGIO NOVARA",
    "state_code": "NO",
    "cab": "917096",
    "cap": "28060"
  },
  {
    "key": "2991",
    "ITA": "CASALEONE",
    "state_code": "VR",
    "cab": "593400",
    "cap": "37052"
  },
  {
    "key": "2992",
    "ITA": "CASALETTO CEREDANO",
    "state_code": "CR",
    "cab": "567305",
    "cap": "26010"
  },
  {
    "key": "2993",
    "ITA": "CASALETTO DI SOPRA",
    "state_code": "CR",
    "cab": "931550",
    "cap": "26014"
  },
  {
    "key": "2994",
    "ITA": "CASALETTO LODIGIANO",
    "state_code": "LO",
    "cab": "903716",
    "cap": "26852"
  },
  {
    "key": "2995",
    "ITA": "CASALETTO LODIGIANO-MAIRANO",
    "state_code": "LO",
    "cab": "903716",
    "cap": "26852"
  },
  {
    "key": "2996",
    "ITA": "CASALETTO SPARTANO-BATTAGLIA",
    "state_code": "SA",
    "cab": "952333",
    "cap": "84030"
  },
  {
    "key": "2997",
    "ITA": "CASALETTO SPARTANO",
    "state_code": "SA",
    "cab": "952333",
    "cap": "84030"
  },
  {
    "key": "2998",
    "ITA": "CASALETTO VAPRIO",
    "state_code": "CR",
    "cab": "573600",
    "cap": "26010"
  },
  {
    "key": "2999",
    "ITA": "CASALFIUMANESE-SAN MARTINO IN PEDRIOLO",
    "state_code": "BO",
    "cab": "366807",
    "cap": "40024"
  },
  {
    "key": "3000",
    "ITA": "CASALFIUMANESE-SASSOLEONE",
    "state_code": "BO",
    "cab": "366807",
    "cap": "40022"
  },
  {
    "key": "3001",
    "ITA": "CASALFIUMANESE-CARSEGGIO",
    "state_code": "BO",
    "cab": "366807",
    "cap": "40025"
  },
  {
    "key": "3002",
    "ITA": "CASALFIUMANESE",
    "state_code": "BO",
    "cab": "366807",
    "cap": "40020"
  },
  {
    "key": "3003",
    "ITA": "CASALGRANDE-VEGGIA",
    "state_code": "RE",
    "cab": "662403",
    "cap": "42013"
  },
  {
    "key": "3004",
    "ITA": "CASALGRANDE-SALVATERRA",
    "state_code": "RE",
    "cab": "662403",
    "cap": "42013"
  },
  {
    "key": "3005",
    "ITA": "CASALGRANDE",
    "state_code": "RE",
    "cab": "662403",
    "cap": "42013"
  },
  {
    "key": "3006",
    "ITA": "CASALGRANDE-VILLALUNGA",
    "state_code": "RE",
    "cab": "662403",
    "cap": "42013"
  },
  {
    "key": "3007",
    "ITA": "CASALGRASSO",
    "state_code": "CN",
    "cab": "461103",
    "cap": "12030"
  },
  {
    "key": "3008",
    "ITA": "CASALINCONTRADA",
    "state_code": "CH",
    "cab": "843805",
    "cap": "66012"
  },
  {
    "key": "3009",
    "ITA": "CASALINO-CAMERIANO",
    "state_code": "NO",
    "cab": "453001",
    "cap": "28060"
  },
  {
    "key": "3010",
    "ITA": "CASALINO-ORFENGO",
    "state_code": "NO",
    "cab": "453001",
    "cap": "28060"
  },
  {
    "key": "3011",
    "ITA": "CASALINO",
    "state_code": "NO",
    "cab": "453001",
    "cap": "28060"
  },
  {
    "key": "3012",
    "ITA": "CASALMAGGIORE-VICOMOSCANO",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3013",
    "ITA": "CASALMAGGIORE-VICOBONEGHISIO",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3014",
    "ITA": "CASALMAGGIORE",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3015",
    "ITA": "CASALMAGGIORE-AGOIOLO",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3016",
    "ITA": "CASALMAGGIORE-CASALBELLOTTO",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3017",
    "ITA": "CASALMAGGIORE-QUATTROCASE",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3018",
    "ITA": "CASALMAGGIORE-RONCADELLO",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3019",
    "ITA": "CASALMAGGIORE-VICOBELLIGNANO",
    "state_code": "CR",
    "cab": "567404",
    "cap": "26041"
  },
  {
    "key": "3020",
    "ITA": "CASALMAIOCCO",
    "state_code": "LO",
    "cab": "738708",
    "cap": "26831"
  },
  {
    "key": "3021",
    "ITA": "CASALMAIOCCO-COLOGNO",
    "state_code": "LO",
    "cab": "738708",
    "cap": "26831"
  },
  {
    "key": "3022",
    "ITA": "CASALMORANO",
    "state_code": "CR",
    "cab": "567503",
    "cap": "26020"
  },
  {
    "key": "3023",
    "ITA": "CASALMORO",
    "state_code": "MN",
    "cab": "575001",
    "cap": "46040"
  },
  {
    "key": "3024",
    "ITA": "CASALNOCETO",
    "state_code": "AL",
    "cab": "481309",
    "cap": "15052"
  },
  {
    "key": "3025",
    "ITA": "CASALNUOVO DI NAPOLI",
    "state_code": "NA",
    "cab": "398107",
    "cap": "80013"
  },
  {
    "key": "3026",
    "ITA": "CASALNUOVO DI NAPOLI-CASAREA",
    "state_code": "NA",
    "cab": "398107",
    "cap": "80013"
  },
  {
    "key": "3027",
    "ITA": "CASALNUOVO DI NAPOLI-TAVERNANOVA",
    "state_code": "NA",
    "cab": "398107",
    "cap": "80013"
  },
  {
    "key": "3028",
    "ITA": "CASALNUOVO DI NAPOLI-LICIGNANO DI NAPOLI",
    "state_code": "NA",
    "cab": "398107",
    "cap": "80013"
  },
  {
    "key": "3029",
    "ITA": "CASALNUOVO MONTEROTARO",
    "state_code": "FG",
    "cab": "783407",
    "cap": "71033"
  },
  {
    "key": "3030",
    "ITA": "CASALOLDO",
    "state_code": "MN",
    "cab": "575100",
    "cap": "46040"
  },
  {
    "key": "3031",
    "ITA": "CASALPUSTERLENGO",
    "state_code": "LO",
    "cab": "327700",
    "cap": "26841"
  },
  {
    "key": "3032",
    "ITA": "CASALPUSTERLENGO-ZORLESCO",
    "state_code": "LO",
    "cab": "327700",
    "cap": "26841"
  },
  {
    "key": "3033",
    "ITA": "CASALROMANO-FONTANELLA GRAZIOLI",
    "state_code": "MN",
    "cab": "575209",
    "cap": "46040"
  },
  {
    "key": "3034",
    "ITA": "CASALROMANO",
    "state_code": "MN",
    "cab": "575209",
    "cap": "46040"
  },
  {
    "key": "3035",
    "ITA": "CASALSERUGO",
    "state_code": "PD",
    "cab": "625004",
    "cap": "35020"
  },
  {
    "key": "3036",
    "ITA": "CASALUCE",
    "state_code": "CE",
    "cab": "376509",
    "cap": "81030"
  },
  {
    "key": "3037",
    "ITA": "CASALVECCHIO DI PUGLIA",
    "state_code": "FG",
    "cab": "783506",
    "cap": "71030"
  },
  {
    "key": "3038",
    "ITA": "CASALVECCHIO SICULO",
    "state_code": "ME",
    "cab": "890103",
    "cap": "98032"
  },
  {
    "key": "3039",
    "ITA": "CASALVECCHIO SICULO-MISITANO SUPERIORE",
    "state_code": "ME",
    "cab": "890103",
    "cap": "98032"
  },
  {
    "key": "3040",
    "ITA": "CASALVECCHIO SICULO-MISITANO INFERIORE",
    "state_code": "ME",
    "cab": "890103",
    "cap": "98032"
  },
  {
    "key": "3041",
    "ITA": "CASALVIERI-ROSELLI",
    "state_code": "FR",
    "cab": "743609",
    "cap": "3034"
  },
  {
    "key": "3042",
    "ITA": "CASALVIERI-PURGATORIO",
    "state_code": "FR",
    "cab": "743609",
    "cap": "3034"
  },
  {
    "key": "3043",
    "ITA": "CASALVIERI",
    "state_code": "FR",
    "cab": "743609",
    "cap": "3034"
  },
  {
    "key": "3044",
    "ITA": "CASALVOLONE",
    "state_code": "NO",
    "cab": "453100",
    "cap": "28060"
  },
  {
    "key": "3045",
    "ITA": "CASALZUIGNO",
    "state_code": "VA",
    "cab": "924324",
    "cap": "21030"
  },
  {
    "key": "3046",
    "ITA": "CASAMARCIANO",
    "state_code": "NA",
    "cab": "908830",
    "cap": "80032"
  },
  {
    "key": "3047",
    "ITA": "CASAMASSIMA",
    "state_code": "BA",
    "cab": "414201",
    "cap": "70010"
  },
  {
    "key": "3048",
    "ITA": "CASAMICCIOLA TERME",
    "state_code": "NA",
    "cab": "398206",
    "cap": "80074"
  },
  {
    "key": "3049",
    "ITA": "CASANDRINO",
    "state_code": "NA",
    "cab": "885806",
    "cap": "80025"
  },
  {
    "key": "3050",
    "ITA": "CASANOVA ELVO",
    "state_code": "VC",
    "cab": "915751",
    "cap": "13030"
  },
  {
    "key": "3051",
    "ITA": "CASANOVA LERRONE",
    "state_code": "SV",
    "cab": "923060",
    "cap": "17033"
  },
  {
    "key": "3052",
    "ITA": "CASANOVA LONATI",
    "state_code": "PV",
    "cab": "930313",
    "cap": "27041"
  },
  {
    "key": "3053",
    "ITA": "CASAPE",
    "state_code": "RM",
    "cab": "908061",
    "cap": "10"
  },
  {
    "key": "3054",
    "ITA": "CASAPESENNA",
    "state_code": "CE",
    "cab": "898304",
    "cap": "81030"
  },
  {
    "key": "3055",
    "ITA": "CASAPINTA",
    "state_code": "BI",
    "cab": "915769",
    "cap": "13866"
  },
  {
    "key": "3056",
    "ITA": "CASAPROTA-COLLELUNGO",
    "state_code": "RI",
    "cab": "735308",
    "cap": "2030"
  },
  {
    "key": "3057",
    "ITA": "CASAPROTA",
    "state_code": "RI",
    "cab": "735308",
    "cap": "2030"
  },
  {
    "key": "3058",
    "ITA": "CASAPULLA",
    "state_code": "CE",
    "cab": "748608",
    "cap": "81020"
  },
  {
    "key": "3059",
    "ITA": "CASARANO",
    "state_code": "LE",
    "cab": "795203",
    "cap": "73042"
  },
  {
    "key": "3060",
    "ITA": "CASARGO",
    "state_code": "LC",
    "cab": "511105",
    "cap": "23831"
  },
  {
    "key": "3061",
    "ITA": "CASARILE",
    "state_code": "MI",
    "cab": "894105",
    "cap": "20080"
  },
  {
    "key": "3062",
    "ITA": "CASARSA DELLA DELIZIA",
    "state_code": "PN",
    "cab": "648105",
    "cap": "33072"
  },
  {
    "key": "3063",
    "ITA": "CASARSA DELLA DELIZIA-SAN GIOVANNI DI CASARSA",
    "state_code": "PN",
    "cab": "648105",
    "cap": "33072"
  },
  {
    "key": "3064",
    "ITA": "CASARZA LIGURE-BARGONE",
    "state_code": "GE",
    "cab": "319202",
    "cap": "16030"
  },
  {
    "key": "3065",
    "ITA": "CASARZA LIGURE",
    "state_code": "GE",
    "cab": "319202",
    "cap": "16030"
  },
  {
    "key": "3066",
    "ITA": "CASASCO",
    "state_code": "AL",
    "cab": "920983",
    "cap": "15050"
  },
  {
    "key": "3067",
    "ITA": "CASASCO D'INTELVI",
    "state_code": "CO",
    "cab": "925289",
    "cap": "22022"
  },
  {
    "key": "3068",
    "ITA": "CASATENOVO-VALAPERTA",
    "state_code": "LC",
    "cab": "511204",
    "cap": "23880"
  },
  {
    "key": "3069",
    "ITA": "CASATENOVO-RIMOLDO",
    "state_code": "LC",
    "cab": "511204",
    "cap": "23880"
  },
  {
    "key": "3070",
    "ITA": "CASATENOVO-ROGOREDO",
    "state_code": "LC",
    "cab": "511204",
    "cap": "23880"
  },
  {
    "key": "3071",
    "ITA": "CASATENOVO-GALGIANA",
    "state_code": "LC",
    "cab": "511204",
    "cap": "23880"
  },
  {
    "key": "3072",
    "ITA": "CASATENOVO-CAMPOFIORENZO",
    "state_code": "LC",
    "cab": "511204",
    "cap": "23880"
  },
  {
    "key": "3073",
    "ITA": "CASATENOVO-CALIFORNIA",
    "state_code": "LC",
    "cab": "511204",
    "cap": "23880"
  },
  {
    "key": "3074",
    "ITA": "CASATENOVO",
    "state_code": "LC",
    "cab": "511204",
    "cap": "23880"
  },
  {
    "key": "3075",
    "ITA": "CASATISMA",
    "state_code": "PV",
    "cab": "930321",
    "cap": "27040"
  },
  {
    "key": "3076",
    "ITA": "CASAVATORE",
    "state_code": "NA",
    "cab": "398305",
    "cap": "80020"
  },
  {
    "key": "3077",
    "ITA": "CASAVATORE-PARCO DELLE ACACIE",
    "state_code": "NA",
    "cab": "398305",
    "cap": "80020"
  },
  {
    "key": "3078",
    "ITA": "CASAZZA",
    "state_code": "BG",
    "cab": "527903",
    "cap": "24060"
  },
  {
    "key": "3079",
    "ITA": "CASCIA-CHIAVANO",
    "state_code": "PG",
    "cab": "383307",
    "cap": "6043"
  },
  {
    "key": "3080",
    "ITA": "CASCIA-MALTIGNANO DI CASCIA",
    "state_code": "PG",
    "cab": "383307",
    "cap": "6043"
  },
  {
    "key": "3081",
    "ITA": "CASCIA",
    "state_code": "PG",
    "cab": "383307",
    "cap": "6043"
  },
  {
    "key": "3082",
    "ITA": "CASCIAGO",
    "state_code": "VA",
    "cab": "507202",
    "cap": "21020"
  },
  {
    "key": "3083",
    "ITA": "CASCIANA TERME",
    "state_code": "PI",
    "cab": "709402",
    "cap": "56034"
  },
  {
    "key": "3084",
    "ITA": "CASCINA-SAN LORENZO A PAGNATICO",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56021"
  },
  {
    "key": "3085",
    "ITA": "CASCINA-SAN FREDIANO A SETTIMO",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56021"
  },
  {
    "key": "3086",
    "ITA": "CASCINA-SAN CASCIANO",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56021"
  },
  {
    "key": "3087",
    "ITA": "CASCINA-SAN BENEDETTO A SETTIMO",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56021"
  },
  {
    "key": "3088",
    "ITA": "CASCINA-RIPOLI",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56023"
  },
  {
    "key": "3089",
    "ITA": "CASCINA-NAVACCHIO",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56023"
  },
  {
    "key": "3090",
    "ITA": "CASCINA-MUSIGLIANO",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56023"
  },
  {
    "key": "3091",
    "ITA": "CASCINA-MONTIONE",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56023"
  },
  {
    "key": "3092",
    "ITA": "CASCINA-MARCIANA",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56021"
  },
  {
    "key": "3093",
    "ITA": "CASCINA-LATIGNANO",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56021"
  },
  {
    "key": "3094",
    "ITA": "CASCINA",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56021"
  },
  {
    "key": "3095",
    "ITA": "CASCINA-SAN LORENZO ALLE CORTI",
    "state_code": "PI",
    "cab": "709501",
    "cap": "56023"
  },
  {
    "key": "3096",
    "ITA": "CASCINETTE D'IVREA",
    "state_code": "TO",
    "cab": "791004",
    "cap": "10010"
  },
  {
    "key": "3097",
    "ITA": "CASEI GEROLA-CASEI",
    "state_code": "PV",
    "cab": "557009",
    "cap": "27050"
  },
  {
    "key": "3098",
    "ITA": "CASEI GEROLA",
    "state_code": "PV",
    "cab": "557009",
    "cap": "27050"
  },
  {
    "key": "3099",
    "ITA": "CASELETTE",
    "state_code": "TO",
    "cab": "302802",
    "cap": "10040"
  },
  {
    "key": "3100",
    "ITA": "CASELLA",
    "state_code": "GE",
    "cab": "319301",
    "cap": "16015"
  },
  {
    "key": "3101",
    "ITA": "CASELLE IN PITTARI",
    "state_code": "SA",
    "cab": "952358",
    "cap": "84030"
  },
  {
    "key": "3102",
    "ITA": "CASELLE LANDI",
    "state_code": "LO",
    "cab": "327809",
    "cap": "26842"
  },
  {
    "key": "3103",
    "ITA": "CASELLE LURANI",
    "state_code": "LO",
    "cab": "274886",
    "cap": "26853"
  },
  {
    "key": "3104",
    "ITA": "CASELLE TORINESE-MAPPANO",
    "state_code": "TO",
    "cab": "302901",
    "cap": "10072"
  },
  {
    "key": "3105",
    "ITA": "CASELLE TORINESE",
    "state_code": "TO",
    "cab": "302901",
    "cap": "10072"
  },
  {
    "key": "3106",
    "ITA": "CASERTA-TUORO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3107",
    "ITA": "CASERTA-TREDICI",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3108",
    "ITA": "CASERTA-STATURANO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3109",
    "ITA": "CASERTA-SANTA BARBARA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3110",
    "ITA": "CASERTA-SAN LEUCIO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3111",
    "ITA": "CASERTA-SAN CLEMENTE DI CASERTA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3112",
    "ITA": "CASERTA-SALA DI CASERTA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3113",
    "ITA": "CASERTA-PUCCIANIELLO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3114",
    "ITA": "CASERTA-CASERTAVECCHIA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3115",
    "ITA": "CASERTA-BRIANO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3116",
    "ITA": "CASERTA-CASOLLA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3117",
    "ITA": "CASERTA-CENTURANO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3118",
    "ITA": "CASERTA-VACCHERIA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3119",
    "ITA": "CASERTA-FALCIANO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3120",
    "ITA": "CASERTA-ERCOLE",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3121",
    "ITA": "CASERTA-CASOLA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3122",
    "ITA": "CASERTA-MEZZANO",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3123",
    "ITA": "CASERTA",
    "state_code": "CE",
    "cab": "149005",
    "cap": "81100"
  },
  {
    "key": "3124",
    "ITA": "CASIER",
    "state_code": "TV",
    "cab": "615500",
    "cap": "31030"
  },
  {
    "key": "3125",
    "ITA": "CASIER-DOSSON",
    "state_code": "TV",
    "cab": "615500",
    "cap": "31030"
  },
  {
    "key": "3126",
    "ITA": "CASIGNANA",
    "state_code": "RC",
    "cab": "960070",
    "cap": "89030"
  },
  {
    "key": "3127",
    "ITA": "CASINA-PAULLO DI CASINA",
    "state_code": "RE",
    "cab": "662502",
    "cap": "42034"
  },
  {
    "key": "3128",
    "ITA": "CASINA-TRINITA'",
    "state_code": "RE",
    "cab": "662502",
    "cap": "42034"
  },
  {
    "key": "3129",
    "ITA": "CASINA",
    "state_code": "RE",
    "cab": "662502",
    "cap": "42034"
  },
  {
    "key": "3130",
    "ITA": "CASIRATE D'ADDA",
    "state_code": "BG",
    "cab": "528000",
    "cap": "24040"
  },
  {
    "key": "3131",
    "ITA": "CASLINO D'ERBA",
    "state_code": "CO",
    "cab": "832303",
    "cap": "22030"
  },
  {
    "key": "3132",
    "ITA": "CASNATE CON BERNATE",
    "state_code": "CO",
    "cab": "519504",
    "cap": "22070"
  },
  {
    "key": "3133",
    "ITA": "CASNATE CON BERNATE-CASNATE",
    "state_code": "CO",
    "cab": "519504",
    "cap": "22070"
  },
  {
    "key": "3134",
    "ITA": "CASNIGO",
    "state_code": "BG",
    "cab": "528109",
    "cap": "24020"
  },
  {
    "key": "3135",
    "ITA": "CASOLA DI NAPOLI",
    "state_code": "NA",
    "cab": "908855",
    "cap": "80050"
  },
  {
    "key": "3136",
    "ITA": "CASOLA IN LUNIGIANA-CODIPONTE",
    "state_code": "MS",
    "cab": "698803",
    "cap": "54014"
  },
  {
    "key": "3137",
    "ITA": "CASOLA IN LUNIGIANA",
    "state_code": "MS",
    "cab": "698803",
    "cap": "54014"
  },
  {
    "key": "3138",
    "ITA": "CASOLA IN LUNIGIANA-REGNANO",
    "state_code": "MS",
    "cab": "698803",
    "cap": "54014"
  },
  {
    "key": "3139",
    "ITA": "CASOLA IN LUNIGIANA-EQUI TERME",
    "state_code": "MS",
    "cab": "698803",
    "cap": "54014"
  },
  {
    "key": "3140",
    "ITA": "CASOLA VALSENIO",
    "state_code": "RA",
    "cab": "675207",
    "cap": "48010"
  },
  {
    "key": "3141",
    "ITA": "CASOLE BRUZIO",
    "state_code": "CS",
    "cab": "728204",
    "cap": "87050"
  },
  {
    "key": "3142",
    "ITA": "CASOLE D'ELSA-PIEVESCOLA",
    "state_code": "SI",
    "cab": "717801",
    "cap": "53031"
  },
  {
    "key": "3143",
    "ITA": "CASOLE D'ELSA-MONTEGUIDI",
    "state_code": "SI",
    "cab": "717801",
    "cap": "53031"
  },
  {
    "key": "3144",
    "ITA": "CASOLE D'ELSA",
    "state_code": "SI",
    "cab": "717801",
    "cap": "53031"
  },
  {
    "key": "3145",
    "ITA": "CASOLI-SELVA PIANA",
    "state_code": "CH",
    "cab": "776005",
    "cap": "66043"
  },
  {
    "key": "3146",
    "ITA": "CASOLI",
    "state_code": "CH",
    "cab": "776005",
    "cap": "66043"
  },
  {
    "key": "3147",
    "ITA": "CASORATE PRIMO",
    "state_code": "PV",
    "cab": "557108",
    "cap": "27022"
  },
  {
    "key": "3148",
    "ITA": "CASORATE SEMPIONE",
    "state_code": "VA",
    "cab": "501007",
    "cap": "21011"
  },
  {
    "key": "3149",
    "ITA": "CASOREZZO",
    "state_code": "MI",
    "cab": "327908",
    "cap": "20010"
  },
  {
    "key": "3150",
    "ITA": "CASORIA-ARPINO",
    "state_code": "NA",
    "cab": "398404",
    "cap": "80026"
  },
  {
    "key": "3151",
    "ITA": "CASORIA",
    "state_code": "NA",
    "cab": "398404",
    "cap": "80026"
  },
  {
    "key": "3152",
    "ITA": "CASORZO",
    "state_code": "AT",
    "cab": "473108",
    "cap": "14032"
  },
  {
    "key": "3153",
    "ITA": "CASPERIA",
    "state_code": "RI",
    "cab": "735407",
    "cap": "2041"
  },
  {
    "key": "3154",
    "ITA": "CASPOGGIO",
    "state_code": "SO",
    "cab": "523506",
    "cap": "23020"
  },
  {
    "key": "3155",
    "ITA": "CASSACCO",
    "state_code": "UD",
    "cab": "644104",
    "cap": "33010"
  },
  {
    "key": "3156",
    "ITA": "CASSAGO BRIANZA",
    "state_code": "LC",
    "cab": "511303",
    "cap": "23893"
  },
  {
    "key": "3157",
    "ITA": "CASSANO ALLO IONIO",
    "state_code": "CS",
    "cab": "806604",
    "cap": "87011"
  },
  {
    "key": "3158",
    "ITA": "CASSANO ALLO IONIO-DORIA",
    "state_code": "CS",
    "cab": "806604",
    "cap": "87011"
  },
  {
    "key": "3159",
    "ITA": "CASSANO ALLO IONIO-LATTUGHELLE",
    "state_code": "CS",
    "cab": "806604",
    "cap": "87011"
  },
  {
    "key": "3160",
    "ITA": "CASSANO ALLO IONIO-LAUROPOLI",
    "state_code": "CS",
    "cab": "806604",
    "cap": "87011"
  },
  {
    "key": "3161",
    "ITA": "CASSANO ALLO IONIO-SIBARI",
    "state_code": "CS",
    "cab": "806604",
    "cap": "87011"
  },
  {
    "key": "3162",
    "ITA": "CASSANO ALLO IONIO-PIANOSCAFO",
    "state_code": "CS",
    "cab": "806604",
    "cap": "87011"
  },
  {
    "key": "3163",
    "ITA": "CASSANO ALLO IONIO-SIBARI STAZIONE",
    "state_code": "CS",
    "cab": "806604",
    "cap": "87011"
  },
  {
    "key": "3164",
    "ITA": "CASSANO D'ADDA-GROPPELLO D'ADDA",
    "state_code": "MI",
    "cab": "328005",
    "cap": "20062"
  },
  {
    "key": "3165",
    "ITA": "CASSANO D'ADDA-CASCINE SAN PIETRO",
    "state_code": "MI",
    "cab": "328005",
    "cap": "20062"
  },
  {
    "key": "3166",
    "ITA": "CASSANO D'ADDA",
    "state_code": "MI",
    "cab": "328005",
    "cap": "20062"
  },
  {
    "key": "3167",
    "ITA": "CASSANO DELLE MURGE",
    "state_code": "BA",
    "cab": "414300",
    "cap": "70020"
  },
  {
    "key": "3168",
    "ITA": "CASSANO IRPINO",
    "state_code": "AV",
    "cab": "951012",
    "cap": "83040"
  },
  {
    "key": "3169",
    "ITA": "CASSANO MAGNAGO",
    "state_code": "VA",
    "cab": "501106",
    "cap": "21012"
  },
  {
    "key": "3170",
    "ITA": "CASSANO SPINOLA",
    "state_code": "AL",
    "cab": "481408",
    "cap": "15063"
  },
  {
    "key": "3171",
    "ITA": "CASSANO VALCUVIA",
    "state_code": "VA",
    "cab": "924340",
    "cap": "21030"
  },
  {
    "key": "3172",
    "ITA": "CASSARO",
    "state_code": "SR",
    "cab": "846808",
    "cap": "96010"
  },
  {
    "key": "3173",
    "ITA": "CASSIGLIO",
    "state_code": "BG",
    "cab": "927954",
    "cap": "24010"
  },
  {
    "key": "3174",
    "ITA": "CASSINA DE' PECCHI",
    "state_code": "MI",
    "cab": "328104",
    "cap": "20060"
  },
  {
    "key": "3175",
    "ITA": "CASSINA DE' PECCHI-SANT'AGATA MARTESANA",
    "state_code": "MI",
    "cab": "328104",
    "cap": "20060"
  },
  {
    "key": "3176",
    "ITA": "CASSINA RIZZARDI",
    "state_code": "CO",
    "cab": "565408",
    "cap": "22070"
  },
  {
    "key": "3177",
    "ITA": "CASSINA VALSASSINA",
    "state_code": "LC",
    "cab": "925321",
    "cap": "23817"
  },
  {
    "key": "3178",
    "ITA": "CASSINASCO",
    "state_code": "AT",
    "cab": "919993",
    "cap": "14050"
  },
  {
    "key": "3179",
    "ITA": "CASSINE",
    "state_code": "AL",
    "cab": "481507",
    "cap": "15016"
  },
  {
    "key": "3180",
    "ITA": "CASSINE-CARANZANO",
    "state_code": "AL",
    "cab": "481507",
    "cap": "15016"
  },
  {
    "key": "3181",
    "ITA": "CASSINE-GAVONATA",
    "state_code": "AL",
    "cab": "481507",
    "cap": "15016"
  },
  {
    "key": "3182",
    "ITA": "CASSINELLE",
    "state_code": "AL",
    "cab": "920991",
    "cap": "15070"
  },
  {
    "key": "3183",
    "ITA": "CASSINELLE-BANDITA",
    "state_code": "AL",
    "cab": "920991",
    "cap": "15070"
  },
  {
    "key": "3184",
    "ITA": "CASSINETTA DI LUGAGNANO",
    "state_code": "MI",
    "cab": "492306",
    "cap": "20081"
  },
  {
    "key": "3185",
    "ITA": "CASSINO-MONTECASSINO",
    "state_code": "FR",
    "cab": "743708",
    "cap": "3043"
  },
  {
    "key": "3186",
    "ITA": "CASSINO-CAPPELLA MORRONE",
    "state_code": "FR",
    "cab": "743708",
    "cap": "3043"
  },
  {
    "key": "3187",
    "ITA": "CASSINO-SAN BARTOLOMEO",
    "state_code": "FR",
    "cab": "743708",
    "cap": "3043"
  },
  {
    "key": "3188",
    "ITA": "CASSINO-SANT'ANGELO IN THEODICE",
    "state_code": "FR",
    "cab": "743708",
    "cap": "3043"
  },
  {
    "key": "3189",
    "ITA": "CASSINO",
    "state_code": "FR",
    "cab": "743708",
    "cap": "3043"
  },
  {
    "key": "3190",
    "ITA": "CASSINO-CAIRA",
    "state_code": "FR",
    "cab": "743708",
    "cap": "3043"
  },
  {
    "key": "3191",
    "ITA": "CASSOLA-SAN ZENO",
    "state_code": "VI",
    "cab": "602607",
    "cap": "36022"
  },
  {
    "key": "3192",
    "ITA": "CASSOLA",
    "state_code": "VI",
    "cab": "602607",
    "cap": "36022"
  },
  {
    "key": "3193",
    "ITA": "CASSOLA-SAN GIUSEPPE",
    "state_code": "VI",
    "cab": "602607",
    "cap": "36022"
  },
  {
    "key": "3194",
    "ITA": "CASSOLNOVO",
    "state_code": "PV",
    "cab": "557207",
    "cap": "27023"
  },
  {
    "key": "3195",
    "ITA": "CASTAGNARO-MENA'",
    "state_code": "VR",
    "cab": "593509",
    "cap": "37043"
  },
  {
    "key": "3196",
    "ITA": "CASTAGNARO",
    "state_code": "VR",
    "cab": "593509",
    "cap": "37043"
  },
  {
    "key": "3197",
    "ITA": "CASTAGNETO CARDUCCI-SAN GUIDO",
    "state_code": "LI",
    "cab": "706804",
    "cap": "57022"
  },
  {
    "key": "3198",
    "ITA": "CASTAGNETO CARDUCCI-DONORATICO",
    "state_code": "LI",
    "cab": "706804",
    "cap": "57022"
  },
  {
    "key": "3199",
    "ITA": "CASTAGNETO CARDUCCI-BOLGHERI",
    "state_code": "LI",
    "cab": "706804",
    "cap": "57022"
  },
  {
    "key": "3200",
    "ITA": "CASTAGNETO CARDUCCI",
    "state_code": "LI",
    "cab": "706804",
    "cap": "57022"
  },
  {
    "key": "3201",
    "ITA": "CASTAGNETO CARDUCCI-MARINA DI CASTAGNETO CARDUCCI",
    "state_code": "LI",
    "cab": "706804",
    "cap": "57022"
  },
  {
    "key": "3202",
    "ITA": "CASTAGNETO PO",
    "state_code": "TO",
    "cab": "283283",
    "cap": "10090"
  },
  {
    "key": "3203",
    "ITA": "CASTAGNITO",
    "state_code": "CN",
    "cab": "470203",
    "cap": "12050"
  },
  {
    "key": "3204",
    "ITA": "CASTAGNITO-BARACCONE",
    "state_code": "CN",
    "cab": "470203",
    "cap": "12050"
  },
  {
    "key": "3205",
    "ITA": "CASTAGNOLE DELLE LANZE-SAN BARTOLOMEO LANZE",
    "state_code": "AT",
    "cab": "473207",
    "cap": "14054"
  },
  {
    "key": "3206",
    "ITA": "CASTAGNOLE DELLE LANZE",
    "state_code": "AT",
    "cab": "473207",
    "cap": "14054"
  },
  {
    "key": "3207",
    "ITA": "CASTAGNOLE DELLE LANZE-OLMO",
    "state_code": "AT",
    "cab": "473207",
    "cap": "14054"
  },
  {
    "key": "3208",
    "ITA": "CASTAGNOLE MONFERRATO-VALENZANI",
    "state_code": "AT",
    "cab": "473306",
    "cap": "14030"
  },
  {
    "key": "3209",
    "ITA": "CASTAGNOLE MONFERRATO",
    "state_code": "AT",
    "cab": "473306",
    "cap": "14030"
  },
  {
    "key": "3210",
    "ITA": "CASTAGNOLE PIEMONTE",
    "state_code": "TO",
    "cab": "314609",
    "cap": "10060"
  },
  {
    "key": "3211",
    "ITA": "CASTANA",
    "state_code": "PV",
    "cab": "930339",
    "cap": "27040"
  },
  {
    "key": "3212",
    "ITA": "CASTANO PRIMO",
    "state_code": "MI",
    "cab": "328203",
    "cap": "20022"
  },
  {
    "key": "3213",
    "ITA": "CASTEGGIO",
    "state_code": "PV",
    "cab": "557306",
    "cap": "27045"
  },
  {
    "key": "3214",
    "ITA": "CASTEGNATO",
    "state_code": "BS",
    "cab": "542407",
    "cap": "25045"
  },
  {
    "key": "3215",
    "ITA": "CASTEGNERO",
    "state_code": "VI",
    "cab": "602706",
    "cap": "36020"
  },
  {
    "key": "3216",
    "ITA": "CASTEGNERO-PONTE DI CASTEGNERO",
    "state_code": "VI",
    "cab": "602706",
    "cap": "36020"
  },
  {
    "key": "3217",
    "ITA": "CASTEGNERO-VILLAGANZERLA",
    "state_code": "VI",
    "cab": "602706",
    "cap": "36020"
  },
  {
    "key": "3218",
    "ITA": "CASTEL BARONIA",
    "state_code": "AV",
    "cab": "951020",
    "cap": "83040"
  },
  {
    "key": "3219",
    "ITA": "CASTEL BOGLIONE",
    "state_code": "AT",
    "cab": "920009",
    "cap": "14040"
  },
  {
    "key": "3220",
    "ITA": "CASTEL BOLOGNESE",
    "state_code": "RA",
    "cab": "675306",
    "cap": "48014"
  },
  {
    "key": "3221",
    "ITA": "CASTEL CAMPAGNANO",
    "state_code": "CE",
    "cab": "949206",
    "cap": "81010"
  },
  {
    "key": "3222",
    "ITA": "CASTEL CAMPAGNANO-SQUILLE",
    "state_code": "CE",
    "cab": "949206",
    "cap": "81010"
  },
  {
    "key": "3223",
    "ITA": "CASTEL CASTAGNA",
    "state_code": "TE",
    "cab": "734509",
    "cap": "64030"
  },
  {
    "key": "3224",
    "ITA": "CASTEL COLONNA",
    "state_code": "AN",
    "cab": "906818",
    "cap": "60010"
  },
  {
    "key": "3225",
    "ITA": "CASTEL CONDINO",
    "state_code": "TN",
    "cab": "345504",
    "cap": "38082"
  },
  {
    "key": "3226",
    "ITA": "CASTEL D'AIANO-SANTA MARIA DI LABANTE",
    "state_code": "BO",
    "cab": "366906",
    "cap": "40034"
  },
  {
    "key": "3227",
    "ITA": "CASTEL D'AIANO-ROCCA DI ROFFENO",
    "state_code": "BO",
    "cab": "366906",
    "cap": "40034"
  },
  {
    "key": "3228",
    "ITA": "CASTEL D'AIANO",
    "state_code": "BO",
    "cab": "366906",
    "cap": "40034"
  },
  {
    "key": "3229",
    "ITA": "CASTEL D'AIANO-VILLA D'AIANO",
    "state_code": "BO",
    "cab": "366906",
    "cap": "40034"
  },
  {
    "key": "3230",
    "ITA": "CASTEL D'ARIO",
    "state_code": "MN",
    "cab": "575407",
    "cap": "46033"
  },
  {
    "key": "3231",
    "ITA": "CASTEL D'AZZANO-BECCACIVETTA",
    "state_code": "VR",
    "cab": "593608",
    "cap": "37060"
  },
  {
    "key": "3232",
    "ITA": "CASTEL D'AZZANO",
    "state_code": "VR",
    "cab": "593608",
    "cap": "37060"
  },
  {
    "key": "3233",
    "ITA": "CASTEL D'AZZANO-AZZANO",
    "state_code": "VR",
    "cab": "593608",
    "cap": "37060"
  },
  {
    "key": "3234",
    "ITA": "CASTEL DEL GIUDICE",
    "state_code": "IS",
    "cab": "955542",
    "cap": "86080"
  },
  {
    "key": "3235",
    "ITA": "CASTEL DEL MONTE",
    "state_code": "AQ",
    "cab": "909564",
    "cap": "67023"
  },
  {
    "key": "3236",
    "ITA": "CASTEL DEL PIANO-MONTEGIOVI",
    "state_code": "GR",
    "cab": "721902",
    "cap": "58033"
  },
  {
    "key": "3237",
    "ITA": "CASTEL DEL PIANO-MONTENERO",
    "state_code": "GR",
    "cab": "721902",
    "cap": "58033"
  },
  {
    "key": "3238",
    "ITA": "CASTEL DEL PIANO",
    "state_code": "GR",
    "cab": "721902",
    "cap": "58033"
  },
  {
    "key": "3239",
    "ITA": "CASTEL DEL RIO",
    "state_code": "BO",
    "cab": "367003",
    "cap": "40022"
  },
  {
    "key": "3240",
    "ITA": "CASTEL DEL RIO-GIUGNOLA",
    "state_code": "BO",
    "cab": "367003",
    "cap": "40022"
  },
  {
    "key": "3241",
    "ITA": "CASTEL DEL RIO-MORADUCCIO",
    "state_code": "BO",
    "cab": "367003",
    "cap": "40022"
  },
  {
    "key": "3242",
    "ITA": "CASTEL DI CASIO",
    "state_code": "BO",
    "cab": "906404",
    "cap": "40030"
  },
  {
    "key": "3243",
    "ITA": "CASTEL DI CASIO-BADI",
    "state_code": "BO",
    "cab": "906404",
    "cap": "40030"
  },
  {
    "key": "3244",
    "ITA": "CASTEL DI CASIO-BERZANTINA",
    "state_code": "BO",
    "cab": "906404",
    "cap": "40030"
  },
  {
    "key": "3245",
    "ITA": "CASTEL DI CASIO-PIAN DI CASALE",
    "state_code": "BO",
    "cab": "906404",
    "cap": "40030"
  },
  {
    "key": "3246",
    "ITA": "CASTEL DI CASIO-SUVIANA",
    "state_code": "BO",
    "cab": "906404",
    "cap": "40030"
  },
  {
    "key": "3247",
    "ITA": "CASTEL DI IERI",
    "state_code": "AQ",
    "cab": "909572",
    "cap": "67020"
  },
  {
    "key": "3248",
    "ITA": "CASTEL DI IUDICA",
    "state_code": "CT",
    "cab": "839209",
    "cap": "95040"
  },
  {
    "key": "3249",
    "ITA": "CASTEL DI IUDICA-CARRUBBO",
    "state_code": "CT",
    "cab": "839209",
    "cap": "95040"
  },
  {
    "key": "3250",
    "ITA": "CASTEL DI IUDICA-GIUMARRA",
    "state_code": "CT",
    "cab": "839209",
    "cap": "95040"
  },
  {
    "key": "3251",
    "ITA": "CASTEL DI IUDICA-CINQUEGRANA",
    "state_code": "CT",
    "cab": "839209",
    "cap": "95040"
  },
  {
    "key": "3252",
    "ITA": "CASTEL DI LAMA",
    "state_code": "AP",
    "cab": "693903",
    "cap": "63031"
  },
  {
    "key": "3253",
    "ITA": "CASTEL DI LAMA-VILLA SANT'ANTONIO",
    "state_code": "AP",
    "cab": "693903",
    "cap": "63031"
  },
  {
    "key": "3254",
    "ITA": "CASTEL DI LAMA-PIATTONI",
    "state_code": "AP",
    "cab": "693903",
    "cap": "63031"
  },
  {
    "key": "3255",
    "ITA": "CASTEL DI LAMA-CASTEL DI LAMA STAZIONE",
    "state_code": "AP",
    "cab": "693903",
    "cap": "63031"
  },
  {
    "key": "3256",
    "ITA": "CASTEL DI LAMA-CASTEL DI LAMA PIATTONI",
    "state_code": "AP",
    "cab": "693903",
    "cap": "63031"
  },
  {
    "key": "3257",
    "ITA": "CASTEL DI LUCIO",
    "state_code": "ME",
    "cab": "478800",
    "cap": "98070"
  },
  {
    "key": "3258",
    "ITA": "CASTEL DI SANGRO-ROCCACINQUEMIGLIA",
    "state_code": "AQ",
    "cab": "405209",
    "cap": "67031"
  },
  {
    "key": "3259",
    "ITA": "CASTEL DI SANGRO",
    "state_code": "AQ",
    "cab": "405209",
    "cap": "67031"
  },
  {
    "key": "3260",
    "ITA": "CASTEL DI SASSO-CISTERNA",
    "state_code": "CE",
    "cab": "949214",
    "cap": "81040"
  },
  {
    "key": "3261",
    "ITA": "CASTEL DI SASSO",
    "state_code": "CE",
    "cab": "949214",
    "cap": "81040"
  },
  {
    "key": "3262",
    "ITA": "CASTEL DI TORA",
    "state_code": "RI",
    "cab": "947135",
    "cap": "2020"
  },
  {
    "key": "3263",
    "ITA": "CASTEL FOCOGNANO-PIEVE A SOCANA",
    "state_code": "AR",
    "cab": "713701",
    "cap": "52016"
  },
  {
    "key": "3264",
    "ITA": "CASTEL FOCOGNANO-SALUTIO",
    "state_code": "AR",
    "cab": "713701",
    "cap": "52010"
  },
  {
    "key": "3265",
    "ITA": "CASTEL FOCOGNANO-RASSINA",
    "state_code": "AR",
    "cab": "713701",
    "cap": "52016"
  },
  {
    "key": "3266",
    "ITA": "CASTEL FOCOGNANO",
    "state_code": "AR",
    "cab": "713701",
    "cap": "52016"
  },
  {
    "key": "3267",
    "ITA": "CASTEL FRENTANO",
    "state_code": "CH",
    "cab": "776104",
    "cap": "66032"
  },
  {
    "key": "3268",
    "ITA": "CASTEL GABBIANO",
    "state_code": "CR",
    "cab": "931584",
    "cap": "26010"
  },
  {
    "key": "3269",
    "ITA": "CASTEL GANDOLFO-LAGHETTO DI CASTEL GANDOLFO",
    "state_code": "RM",
    "cab": "389908",
    "cap": "40"
  },
  {
    "key": "3270",
    "ITA": "CASTEL GANDOLFO",
    "state_code": "RM",
    "cab": "389908",
    "cap": "40"
  },
  {
    "key": "3271",
    "ITA": "CASTEL GIORGIO",
    "state_code": "TR",
    "cab": "725804",
    "cap": "5013"
  },
  {
    "key": "3272",
    "ITA": "CASTEL GOFFREDO",
    "state_code": "MN",
    "cab": "575506",
    "cap": "46042"
  },
  {
    "key": "3273",
    "ITA": "CASTEL GUELFO DI BOLOGNA",
    "state_code": "BO",
    "cab": "367102",
    "cap": "40023"
  },
  {
    "key": "3274",
    "ITA": "CASTEL MADAMA",
    "state_code": "RM",
    "cab": "390005",
    "cap": "24"
  },
  {
    "key": "3275",
    "ITA": "CASTEL MAGGIORE-TREBBO",
    "state_code": "BO",
    "cab": "367409",
    "cap": "40013"
  },
  {
    "key": "3276",
    "ITA": "CASTEL MAGGIORE",
    "state_code": "BO",
    "cab": "367409",
    "cap": "40013"
  },
  {
    "key": "3277",
    "ITA": "CASTEL MAGGIORE-PROGRESSO",
    "state_code": "BO",
    "cab": "367409",
    "cap": "40013"
  },
  {
    "key": "3278",
    "ITA": "CASTEL MAGGIORE-VILLA SALINA",
    "state_code": "BO",
    "cab": "367409",
    "cap": "40013"
  },
  {
    "key": "3279",
    "ITA": "CASTEL MELLA",
    "state_code": "BS",
    "cab": "542605",
    "cap": "25030"
  },
  {
    "key": "3280",
    "ITA": "CASTEL MORRONE-ANNUNZIATA",
    "state_code": "CE",
    "cab": "898502",
    "cap": "81020"
  },
  {
    "key": "3281",
    "ITA": "CASTEL MORRONE",
    "state_code": "CE",
    "cab": "898502",
    "cap": "81020"
  },
  {
    "key": "3282",
    "ITA": "CASTEL RITALDI-BRUNA",
    "state_code": "PG",
    "cab": "383406",
    "cap": "6044"
  },
  {
    "key": "3283",
    "ITA": "CASTEL RITALDI",
    "state_code": "PG",
    "cab": "383406",
    "cap": "6044"
  },
  {
    "key": "3284",
    "ITA": "CASTEL ROCCHERO",
    "state_code": "AT",
    "cab": "920033",
    "cap": "14044"
  },
  {
    "key": "3285",
    "ITA": "CASTEL ROZZONE",
    "state_code": "BG",
    "cab": "528307",
    "cap": "24040"
  },
  {
    "key": "3286",
    "ITA": "CASTEL SAN GIORGIO-LANZARA",
    "state_code": "SA",
    "cab": "761601",
    "cap": "84083"
  },
  {
    "key": "3287",
    "ITA": "CASTEL SAN GIORGIO-FIMIANI",
    "state_code": "SA",
    "cab": "761601",
    "cap": "84083"
  },
  {
    "key": "3288",
    "ITA": "CASTEL SAN GIORGIO-CASTELLUCCIO",
    "state_code": "SA",
    "cab": "761601",
    "cap": "84083"
  },
  {
    "key": "3289",
    "ITA": "CASTEL SAN GIORGIO",
    "state_code": "SA",
    "cab": "761601",
    "cap": "84083"
  },
  {
    "key": "3290",
    "ITA": "CASTEL SAN GIOVANNI-FONTANA PRADOSA",
    "state_code": "PC",
    "cab": "652602",
    "cap": "29015"
  },
  {
    "key": "3291",
    "ITA": "CASTEL SAN GIOVANNI-GANAGHELLO",
    "state_code": "PC",
    "cab": "652602",
    "cap": "29015"
  },
  {
    "key": "3292",
    "ITA": "CASTEL SAN GIOVANNI",
    "state_code": "PC",
    "cab": "652602",
    "cap": "29015"
  },
  {
    "key": "3293",
    "ITA": "CASTEL SAN GIOVANNI-CRETA",
    "state_code": "PC",
    "cab": "652602",
    "cap": "29015"
  },
  {
    "key": "3294",
    "ITA": "CASTEL SAN LORENZO",
    "state_code": "SA",
    "cab": "766600",
    "cap": "84049"
  },
  {
    "key": "3295",
    "ITA": "CASTEL SAN NICCOLO'-BORGO ALLA COLLINA",
    "state_code": "AR",
    "cab": "713909",
    "cap": "52018"
  },
  {
    "key": "3296",
    "ITA": "CASTEL SAN NICCOLO'",
    "state_code": "AR",
    "cab": "713909",
    "cap": "52018"
  },
  {
    "key": "3297",
    "ITA": "CASTEL SAN NICCOLO'-STRADA",
    "state_code": "AR",
    "cab": "713909",
    "cap": "52018"
  },
  {
    "key": "3298",
    "ITA": "CASTEL SAN PIETRO ROMANO",
    "state_code": "RM",
    "cab": "908079",
    "cap": "30"
  },
  {
    "key": "3299",
    "ITA": "CASTEL SAN PIETRO TERME",
    "state_code": "BO",
    "cab": "367508",
    "cap": "40024"
  },
  {
    "key": "3300",
    "ITA": "CASTEL SAN PIETRO TERME-OSTERIA GRANDE",
    "state_code": "BO",
    "cab": "367508",
    "cap": "40060"
  },
  {
    "key": "3301",
    "ITA": "CASTEL SAN PIETRO TERME-GALLO",
    "state_code": "BO",
    "cab": "367508",
    "cap": "40060"
  },
  {
    "key": "3302",
    "ITA": "CASTEL SAN PIETRO TERME-GAIANA",
    "state_code": "BO",
    "cab": "367508",
    "cap": "40024"
  },
  {
    "key": "3303",
    "ITA": "CASTEL SAN VINCENZO",
    "state_code": "IS",
    "cab": "955575",
    "cap": "86071"
  },
  {
    "key": "3304",
    "ITA": "CASTEL SANT'ANGELO",
    "state_code": "RI",
    "cab": "947143",
    "cap": "2010"
  },
  {
    "key": "3305",
    "ITA": "CASTEL SANT'ANGELO-CANETRA DI CASTEL SANT'ANGELO",
    "state_code": "RI",
    "cab": "947143",
    "cap": "2010"
  },
  {
    "key": "3306",
    "ITA": "CASTEL SANT'ELIA",
    "state_code": "VT",
    "cab": "729905",
    "cap": "1030"
  },
  {
    "key": "3307",
    "ITA": "CASTEL VISCARDO-PIANLUNGO",
    "state_code": "TR",
    "cab": "725903",
    "cap": "5014"
  },
  {
    "key": "3308",
    "ITA": "CASTEL VISCARDO",
    "state_code": "TR",
    "cab": "725903",
    "cap": "5014"
  },
  {
    "key": "3309",
    "ITA": "CASTEL VISCARDO-MONTERUBIAGLIO",
    "state_code": "TR",
    "cab": "725903",
    "cap": "5014"
  },
  {
    "key": "3310",
    "ITA": "CASTEL VITTORIO",
    "state_code": "IM",
    "cab": "922336",
    "cap": "18030"
  },
  {
    "key": "3311",
    "ITA": "CASTEL VOLTURNO",
    "state_code": "CE",
    "cab": "748707",
    "cap": "81030"
  },
  {
    "key": "3312",
    "ITA": "CASTEL VOLTURNO-VILLAGGIO COPPOLA PINETAMARE",
    "state_code": "CE",
    "cab": "748707",
    "cap": "81030"
  },
  {
    "key": "3313",
    "ITA": "CASTELBALDO",
    "state_code": "PD",
    "cab": "625103",
    "cap": "35040"
  },
  {
    "key": "3314",
    "ITA": "CASTELBELFORTE",
    "state_code": "MN",
    "cab": "575308",
    "cap": "46032"
  },
  {
    "key": "3315",
    "ITA": "CASTELBELLINO-STAZIONE",
    "state_code": "AN",
    "cab": "372706",
    "cap": "60030"
  },
  {
    "key": "3316",
    "ITA": "CASTELBELLINO",
    "state_code": "AN",
    "cab": "372706",
    "cap": "60030"
  },
  {
    "key": "3317",
    "ITA": "CASTELBELLO CIARDES-MONTEFRANCO",
    "state_code": "BZ",
    "cab": "582809",
    "cap": "39020"
  },
  {
    "key": "3318",
    "ITA": "CASTELBELLO CIARDES-CIARDES",
    "state_code": "BZ",
    "cab": "582809",
    "cap": "39020"
  },
  {
    "key": "3319",
    "ITA": "CASTELBELLO CIARDES-CASTELBELLO",
    "state_code": "BZ",
    "cab": "582809",
    "cap": "39020"
  },
  {
    "key": "3320",
    "ITA": "CASTELBELLO CIARDES",
    "state_code": "BZ",
    "cab": "582809",
    "cap": "39020"
  },
  {
    "key": "3321",
    "ITA": "CASTELBIANCO",
    "state_code": "SV",
    "cab": "923078",
    "cap": "17030"
  },
  {
    "key": "3322",
    "ITA": "CASTELBOTTACCIO",
    "state_code": "CB",
    "cab": "910471",
    "cap": "86030"
  },
  {
    "key": "3323",
    "ITA": "CASTELBUONO",
    "state_code": "PA",
    "cab": "432203",
    "cap": "90013"
  },
  {
    "key": "3324",
    "ITA": "CASTELCIVITA-SERRA DI CASTELCIVITA",
    "state_code": "SA",
    "cab": "409201",
    "cap": "84020"
  },
  {
    "key": "3325",
    "ITA": "CASTELCIVITA",
    "state_code": "SA",
    "cab": "409201",
    "cap": "84020"
  },
  {
    "key": "3326",
    "ITA": "CASTELCOVATI",
    "state_code": "BS",
    "cab": "542506",
    "cap": "25030"
  },
  {
    "key": "3327",
    "ITA": "CASTELCUCCO",
    "state_code": "TV",
    "cab": "674507",
    "cap": "31030"
  },
  {
    "key": "3328",
    "ITA": "CASTELDACCIA",
    "state_code": "PA",
    "cab": "432302",
    "cap": "90014"
  },
  {
    "key": "3329",
    "ITA": "CASTELDELCI",
    "state_code": "PU",
    "cab": "941427",
    "cap": "61010"
  },
  {
    "key": "3330",
    "ITA": "CASTELDELFINO",
    "state_code": "CN",
    "cab": "461202",
    "cap": "12020"
  },
  {
    "key": "3331",
    "ITA": "CASTELDIDONE",
    "state_code": "CR",
    "cab": "931576",
    "cap": "26030"
  },
  {
    "key": "3332",
    "ITA": "CASTELFIDARDO-ACQUAVIVA VILLA MUSONE",
    "state_code": "AN",
    "cab": "372805",
    "cap": "60022"
  },
  {
    "key": "3333",
    "ITA": "CASTELFIDARDO",
    "state_code": "AN",
    "cab": "372805",
    "cap": "60022"
  },
  {
    "key": "3334",
    "ITA": "CASTELFIDARDO-SAN ROCCHETTO",
    "state_code": "AN",
    "cab": "372805",
    "cap": "60022"
  },
  {
    "key": "3335",
    "ITA": "CASTELFIORENTINO",
    "state_code": "FI",
    "cab": "377903",
    "cap": "50051"
  },
  {
    "key": "3336",
    "ITA": "CASTELFIORENTINO-DOGANA",
    "state_code": "FI",
    "cab": "377903",
    "cap": "50051"
  },
  {
    "key": "3337",
    "ITA": "CASTELFIORENTINO-GRANAIOLO",
    "state_code": "FI",
    "cab": "377903",
    "cap": "50051"
  },
  {
    "key": "3338",
    "ITA": "CASTELFIORENTINO-PETRAZZI",
    "state_code": "FI",
    "cab": "377903",
    "cap": "50051"
  },
  {
    "key": "3339",
    "ITA": "CASTELFIORENTINO-CAMBIANO",
    "state_code": "FI",
    "cab": "377903",
    "cap": "50051"
  },
  {
    "key": "3340",
    "ITA": "CASTELFIORENTINO-CASTELNUOVO D'ELSA",
    "state_code": "FI",
    "cab": "377903",
    "cap": "50051"
  },
  {
    "key": "3341",
    "ITA": "CASTELFONDO",
    "state_code": "TN",
    "cab": "345603",
    "cap": "38020"
  },
  {
    "key": "3342",
    "ITA": "CASTELFORTE",
    "state_code": "LT",
    "cab": "739409",
    "cap": "4021"
  },
  {
    "key": "3343",
    "ITA": "CASTELFORTE-FORME DI SUIO",
    "state_code": "LT",
    "cab": "739409",
    "cap": "4021"
  },
  {
    "key": "3344",
    "ITA": "CASTELFORTE-SAN CATALDO",
    "state_code": "LT",
    "cab": "739409",
    "cap": "4021"
  },
  {
    "key": "3345",
    "ITA": "CASTELFORTE-SUIO TERME",
    "state_code": "LT",
    "cab": "739409",
    "cap": "4021"
  },
  {
    "key": "3346",
    "ITA": "CASTELFRANCI",
    "state_code": "AV",
    "cab": "951038",
    "cap": "83040"
  },
  {
    "key": "3347",
    "ITA": "CASTELFRANCO DI SOPRA",
    "state_code": "AR",
    "cab": "713800",
    "cap": "52020"
  },
  {
    "key": "3348",
    "ITA": "CASTELFRANCO DI SOTTO",
    "state_code": "PI",
    "cab": "709600",
    "cap": "56022"
  },
  {
    "key": "3349",
    "ITA": "CASTELFRANCO DI SOTTO-ORENTANO",
    "state_code": "PI",
    "cab": "709600",
    "cap": "56020"
  },
  {
    "key": "3350",
    "ITA": "CASTELFRANCO DI SOTTO-VILLA CAMPANILE",
    "state_code": "PI",
    "cab": "709600",
    "cap": "56020"
  },
  {
    "key": "3351",
    "ITA": "CASTELFRANCO EMILIA",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3352",
    "ITA": "CASTELFRANCO EMILIA-CAVAZZONA",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3353",
    "ITA": "CASTELFRANCO EMILIA-GAGGIO DI PIANO",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3354",
    "ITA": "CASTELFRANCO EMILIA-MANZOLINO",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3355",
    "ITA": "CASTELFRANCO EMILIA-PIUMAZZO",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3356",
    "ITA": "CASTELFRANCO EMILIA-RASTELLINO",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3357",
    "ITA": "CASTELFRANCO EMILIA-RECOVATO",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3358",
    "ITA": "CASTELFRANCO EMILIA-RIOLO",
    "state_code": "MO",
    "cab": "666909",
    "cap": "41013"
  },
  {
    "key": "3359",
    "ITA": "CASTELFRANCO IN MISCANO",
    "state_code": "BN",
    "cab": "752907",
    "cap": "82022"
  },
  {
    "key": "3360",
    "ITA": "CASTELFRANCO PIANDISCO''",
    "state_code": "AR",
    "cab": "54536",
    "cap": "52026"
  },
  {
    "key": "3361",
    "ITA": "CASTELFRANCO VENETO-SALVAROSA",
    "state_code": "TV",
    "cab": "615609",
    "cap": "31033"
  },
  {
    "key": "3362",
    "ITA": "CASTELFRANCO VENETO-SALVATRONDA",
    "state_code": "TV",
    "cab": "615609",
    "cap": "31033"
  },
  {
    "key": "3363",
    "ITA": "CASTELFRANCO VENETO-VILLARAZZO",
    "state_code": "TV",
    "cab": "615609",
    "cap": "31033"
  },
  {
    "key": "3364",
    "ITA": "CASTELFRANCO VENETO-SANT'ANDREA",
    "state_code": "TV",
    "cab": "615609",
    "cap": "31033"
  },
  {
    "key": "3365",
    "ITA": "CASTELFRANCO VENETO",
    "state_code": "TV",
    "cab": "615609",
    "cap": "31033"
  },
  {
    "key": "3366",
    "ITA": "CASTELGOMBERTO",
    "state_code": "VI",
    "cab": "602805",
    "cap": "36070"
  },
  {
    "key": "3367",
    "ITA": "CASTELGRANDE",
    "state_code": "PZ",
    "cab": "419309",
    "cap": "85050"
  },
  {
    "key": "3368",
    "ITA": "CASTELGUGLIELMO",
    "state_code": "RO",
    "cab": "631903",
    "cap": "45020"
  },
  {
    "key": "3369",
    "ITA": "CASTELGUIDONE",
    "state_code": "CH",
    "cab": "954610",
    "cap": "66040"
  },
  {
    "key": "3370",
    "ITA": "CASTELL'ALFERO-CALLIANETTO",
    "state_code": "AT",
    "cab": "473405",
    "cap": "14033"
  },
  {
    "key": "3371",
    "ITA": "CASTELL'ALFERO",
    "state_code": "AT",
    "cab": "473405",
    "cap": "14033"
  },
  {
    "key": "3372",
    "ITA": "CASTELL'ALFERO-CASTELL'ALFERO STAZIONE",
    "state_code": "AT",
    "cab": "473405",
    "cap": "14033"
  },
  {
    "key": "3373",
    "ITA": "CASTELL'ARQUATO",
    "state_code": "PC",
    "cab": "652503",
    "cap": "29014"
  },
  {
    "key": "3374",
    "ITA": "CASTELL'ARQUATO-VIGOLO MARCHESE",
    "state_code": "PC",
    "cab": "652503",
    "cap": "29014"
  },
  {
    "key": "3375",
    "ITA": "CASTELL'AZZARA",
    "state_code": "GR",
    "cab": "722009",
    "cap": "58034"
  },
  {
    "key": "3376",
    "ITA": "CASTELL'AZZARA-SELVENA",
    "state_code": "GR",
    "cab": "722009",
    "cap": "58034"
  },
  {
    "key": "3377",
    "ITA": "CASTELL'UMBERTO-SFARANDA",
    "state_code": "ME",
    "cab": "821306",
    "cap": "98070"
  },
  {
    "key": "3378",
    "ITA": "CASTELL'UMBERTO",
    "state_code": "ME",
    "cab": "821306",
    "cap": "98070"
  },
  {
    "key": "3379",
    "ITA": "CASTELLABATE-SANTA MARIA",
    "state_code": "SA",
    "cab": "761502",
    "cap": "84048"
  },
  {
    "key": "3380",
    "ITA": "CASTELLABATE",
    "state_code": "SA",
    "cab": "761502",
    "cap": "84048"
  },
  {
    "key": "3381",
    "ITA": "CASTELLABATE-SAN MARCO",
    "state_code": "SA",
    "cab": "761502",
    "cap": "84048"
  },
  {
    "key": "3382",
    "ITA": "CASTELLABATE-OGLIASTRO MARINA",
    "state_code": "SA",
    "cab": "761502",
    "cap": "84048"
  },
  {
    "key": "3383",
    "ITA": "CASTELLAFIUME",
    "state_code": "AQ",
    "cab": "909580",
    "cap": "67050"
  },
  {
    "key": "3384",
    "ITA": "CASTELLAFIUME-PAGLIARA",
    "state_code": "AQ",
    "cab": "909580",
    "cap": "67050"
  },
  {
    "key": "3385",
    "ITA": "CASTELLALTO-CASTELBASSO",
    "state_code": "TE",
    "cab": "768002",
    "cap": "64020"
  },
  {
    "key": "3386",
    "ITA": "CASTELLALTO-CASEMOLINO",
    "state_code": "TE",
    "cab": "768002",
    "cap": "64020"
  },
  {
    "key": "3387",
    "ITA": "CASTELLALTO",
    "state_code": "TE",
    "cab": "768002",
    "cap": "64020"
  },
  {
    "key": "3388",
    "ITA": "CASTELLALTO-CASTELNUOVO VOMANO",
    "state_code": "TE",
    "cab": "768002",
    "cap": "64020"
  },
  {
    "key": "3389",
    "ITA": "CASTELLALTO-VILLA ZACCHEO",
    "state_code": "TE",
    "cab": "768002",
    "cap": "64020"
  },
  {
    "key": "3390",
    "ITA": "CASTELLALTO-PETRICCIONE",
    "state_code": "TE",
    "cab": "768002",
    "cap": "64020"
  },
  {
    "key": "3391",
    "ITA": "CASTELLAMMARE DEL GOLFO",
    "state_code": "TP",
    "cab": "818203",
    "cap": "91014"
  },
  {
    "key": "3392",
    "ITA": "CASTELLAMMARE DEL GOLFO-SCOPELLO",
    "state_code": "TP",
    "cab": "818203",
    "cap": "91014"
  },
  {
    "key": "3393",
    "ITA": "CASTELLAMMARE DEL GOLFO-BALATA DI BAIDA",
    "state_code": "TP",
    "cab": "818203",
    "cap": "91010"
  },
  {
    "key": "3394",
    "ITA": "CASTELLAMMARE DEL GOLFO-CASTELLO INICI",
    "state_code": "TP",
    "cab": "818203",
    "cap": "91014"
  },
  {
    "key": "3395",
    "ITA": "CASTELLAMMARE DI STABIA-SCANZANO",
    "state_code": "NA",
    "cab": "221002",
    "cap": "80053"
  },
  {
    "key": "3396",
    "ITA": "CASTELLAMMARE DI STABIA-QUISISANA",
    "state_code": "NA",
    "cab": "221002",
    "cap": "80053"
  },
  {
    "key": "3397",
    "ITA": "CASTELLAMMARE DI STABIA",
    "state_code": "NA",
    "cab": "221002",
    "cap": "80053"
  },
  {
    "key": "3398",
    "ITA": "CASTELLAMMARE DI STABIA-PONTE DELLA PERSICA",
    "state_code": "NA",
    "cab": "221002",
    "cap": "80053"
  },
  {
    "key": "3399",
    "ITA": "CASTELLAMONTE",
    "state_code": "TO",
    "cab": "303008",
    "cap": "10081"
  },
  {
    "key": "3400",
    "ITA": "CASTELLAMONTE-SAN GIOVANNI CANAVESE",
    "state_code": "TO",
    "cab": "303008",
    "cap": "10010"
  },
  {
    "key": "3401",
    "ITA": "CASTELLAMONTE-CAMPO CANAVESE",
    "state_code": "TO",
    "cab": "303008",
    "cap": "10081"
  },
  {
    "key": "3402",
    "ITA": "CASTELLAMONTE-MURIAGLIO",
    "state_code": "TO",
    "cab": "303008",
    "cap": "10081"
  },
  {
    "key": "3403",
    "ITA": "CASTELLANA GROTTE",
    "state_code": "BA",
    "cab": "414409",
    "cap": "70013"
  },
  {
    "key": "3404",
    "ITA": "CASTELLANA SICULA-CALCARELLI",
    "state_code": "PA",
    "cab": "432401",
    "cap": "90020"
  },
  {
    "key": "3405",
    "ITA": "CASTELLANA SICULA",
    "state_code": "PA",
    "cab": "432401",
    "cap": "90020"
  },
  {
    "key": "3406",
    "ITA": "CASTELLANA SICULA-NOCIAZZI",
    "state_code": "PA",
    "cab": "432401",
    "cap": "90020"
  },
  {
    "key": "3407",
    "ITA": "CASTELLANETA",
    "state_code": "TA",
    "cab": "788406",
    "cap": "74011"
  },
  {
    "key": "3408",
    "ITA": "CASTELLANETA-MARINA DI CASTELLANETA",
    "state_code": "TA",
    "cab": "788406",
    "cap": "74011"
  },
  {
    "key": "3409",
    "ITA": "CASTELLANETA-CASE PERRONE",
    "state_code": "TA",
    "cab": "788406",
    "cap": "74011"
  },
  {
    "key": "3410",
    "ITA": "CASTELLANIA",
    "state_code": "AL",
    "cab": "921007",
    "cap": "15051"
  },
  {
    "key": "3411",
    "ITA": "CASTELLANZA",
    "state_code": "VA",
    "cab": "501205",
    "cap": "21053"
  },
  {
    "key": "3412",
    "ITA": "CASTELLAR",
    "state_code": "CN",
    "cab": "918474",
    "cap": "12030"
  },
  {
    "key": "3413",
    "ITA": "CASTELLAR GUIDOBONO",
    "state_code": "AL",
    "cab": "921015",
    "cap": "15050"
  },
  {
    "key": "3414",
    "ITA": "CASTELLARANO-ROTEGLIA",
    "state_code": "RE",
    "cab": "662601",
    "cap": "42014"
  },
  {
    "key": "3415",
    "ITA": "CASTELLARANO-CADIROGGIO",
    "state_code": "RE",
    "cab": "662601",
    "cap": "42014"
  },
  {
    "key": "3416",
    "ITA": "CASTELLARANO",
    "state_code": "RE",
    "cab": "662601",
    "cap": "42014"
  },
  {
    "key": "3417",
    "ITA": "CASTELLARO",
    "state_code": "IM",
    "cab": "922328",
    "cap": "18011"
  },
  {
    "key": "3418",
    "ITA": "CASTELLAVAZZO",
    "state_code": "BL",
    "cab": "700609",
    "cap": "32010"
  },
  {
    "key": "3419",
    "ITA": "CASTELLAZZO BORMIDA",
    "state_code": "AL",
    "cab": "481606",
    "cap": "15073"
  },
  {
    "key": "3420",
    "ITA": "CASTELLAZZO NOVARESE",
    "state_code": "NO",
    "cab": "917104",
    "cap": "28060"
  },
  {
    "key": "3421",
    "ITA": "CASTELLEONE",
    "state_code": "CR",
    "cab": "567602",
    "cap": "26012"
  },
  {
    "key": "3422",
    "ITA": "CASTELLEONE DI SUASA",
    "state_code": "AN",
    "cab": "372904",
    "cap": "60010"
  },
  {
    "key": "3423",
    "ITA": "CASTELLERO",
    "state_code": "AT",
    "cab": "920017",
    "cap": "14013"
  },
  {
    "key": "3424",
    "ITA": "CASTELLETTO CERVO",
    "state_code": "BI",
    "cab": "915785",
    "cap": "13851"
  },
  {
    "key": "3425",
    "ITA": "CASTELLETTO D'ERRO",
    "state_code": "AL",
    "cab": "921023",
    "cap": "15010"
  },
  {
    "key": "3426",
    "ITA": "CASTELLETTO D'ORBA",
    "state_code": "AL",
    "cab": "481705",
    "cap": "15060"
  },
  {
    "key": "3427",
    "ITA": "CASTELLETTO DI BRANDUZZO",
    "state_code": "PV",
    "cab": "930347",
    "cap": "27040"
  },
  {
    "key": "3428",
    "ITA": "CASTELLETTO DI BRANDUZZO-CASTELLETTO PO",
    "state_code": "PV",
    "cab": "930347",
    "cap": "27040"
  },
  {
    "key": "3429",
    "ITA": "CASTELLETTO MERLI",
    "state_code": "AL",
    "cab": "921031",
    "cap": "15020"
  },
  {
    "key": "3430",
    "ITA": "CASTELLETTO MOLINA",
    "state_code": "AT",
    "cab": "920025",
    "cap": "14040"
  },
  {
    "key": "3431",
    "ITA": "CASTELLETTO MONFERRATO",
    "state_code": "AL",
    "cab": "921049",
    "cap": "15040"
  },
  {
    "key": "3432",
    "ITA": "CASTELLETTO MONFERRATO-GIARDINETTO",
    "state_code": "AL",
    "cab": "921049",
    "cap": "15040"
  },
  {
    "key": "3433",
    "ITA": "CASTELLETTO SOPRA TICINO",
    "state_code": "NO",
    "cab": "453209",
    "cap": "28053"
  },
  {
    "key": "3434",
    "ITA": "CASTELLETTO STURA",
    "state_code": "CN",
    "cab": "461301",
    "cap": "12040"
  },
  {
    "key": "3435",
    "ITA": "CASTELLETTO UZZONE",
    "state_code": "CN",
    "cab": "918482",
    "cap": "12070"
  },
  {
    "key": "3436",
    "ITA": "CASTELLETTO UZZONE-SCALETTA UZZONE",
    "state_code": "CN",
    "cab": "918482",
    "cap": "12070"
  },
  {
    "key": "3437",
    "ITA": "CASTELLI-COLLEDORO",
    "state_code": "TE",
    "cab": "768101",
    "cap": "64041"
  },
  {
    "key": "3438",
    "ITA": "CASTELLI",
    "state_code": "TE",
    "cab": "768101",
    "cap": "64041"
  },
  {
    "key": "3439",
    "ITA": "CASTELLI CALEPIO-CASTEL DE' CONTI",
    "state_code": "BG",
    "cab": "528208",
    "cap": "24060"
  },
  {
    "key": "3440",
    "ITA": "CASTELLI CALEPIO-CIVIDINO",
    "state_code": "BG",
    "cab": "528208",
    "cap": "24060"
  },
  {
    "key": "3441",
    "ITA": "CASTELLI CALEPIO",
    "state_code": "BG",
    "cab": "528208",
    "cap": "24060"
  },
  {
    "key": "3442",
    "ITA": "CASTELLINA IN CHIANTI-FONTERUTOLI",
    "state_code": "SI",
    "cab": "717900",
    "cap": "53011"
  },
  {
    "key": "3443",
    "ITA": "CASTELLINA IN CHIANTI",
    "state_code": "SI",
    "cab": "717900",
    "cap": "53011"
  },
  {
    "key": "3444",
    "ITA": "CASTELLINA MARITTIMA",
    "state_code": "PI",
    "cab": "709709",
    "cap": "56040"
  },
  {
    "key": "3445",
    "ITA": "CASTELLINA MARITTIMA-LE BADIE",
    "state_code": "PI",
    "cab": "709709",
    "cap": "56040"
  },
  {
    "key": "3446",
    "ITA": "CASTELLINALDO",
    "state_code": "CN",
    "cab": "720805",
    "cap": "12050"
  },
  {
    "key": "3447",
    "ITA": "CASTELLINO DEL BIFERNO",
    "state_code": "CB",
    "cab": "910489",
    "cap": "86020"
  },
  {
    "key": "3448",
    "ITA": "CASTELLINO TANARO",
    "state_code": "CN",
    "cab": "918508",
    "cap": "12060"
  },
  {
    "key": "3449",
    "ITA": "CASTELLIRI",
    "state_code": "FR",
    "cab": "747204",
    "cap": "3030"
  },
  {
    "key": "3450",
    "ITA": "CASTELLO CABIAGLIO",
    "state_code": "VA",
    "cab": "924357",
    "cap": "21030"
  },
  {
    "key": "3451",
    "ITA": "CASTELLO D'AGOGNA",
    "state_code": "PV",
    "cab": "564401",
    "cap": "27030"
  },
  {
    "key": "3452",
    "ITA": "CASTELLO D'ARGILE",
    "state_code": "BO",
    "cab": "367201",
    "cap": "40050"
  },
  {
    "key": "3453",
    "ITA": "CASTELLO D'ARGILE-VENEZZANO",
    "state_code": "BO",
    "cab": "367201",
    "cap": "40050"
  },
  {
    "key": "3454",
    "ITA": "CASTELLO DEL MATESE",
    "state_code": "CE",
    "cab": "949222",
    "cap": "81016"
  },
  {
    "key": "3455",
    "ITA": "CASTELLO DELL'ACQUA",
    "state_code": "SO",
    "cab": "926980",
    "cap": "23030"
  },
  {
    "key": "3456",
    "ITA": "CASTELLO DI ANNONE-MONFALLITO",
    "state_code": "AT",
    "cab": "473504",
    "cap": "14034"
  },
  {
    "key": "3457",
    "ITA": "CASTELLO DI ANNONE",
    "state_code": "AT",
    "cab": "473504",
    "cap": "14034"
  },
  {
    "key": "3458",
    "ITA": "CASTELLO DI BRIANZA",
    "state_code": "LC",
    "cab": "925339",
    "cap": "23884"
  },
  {
    "key": "3459",
    "ITA": "CASTELLO DI BRIANZA-CARAVERIO",
    "state_code": "LC",
    "cab": "925339",
    "cap": "23884"
  },
  {
    "key": "3460",
    "ITA": "CASTELLO DI BRIANZA-COLOGNA",
    "state_code": "LC",
    "cab": "925339",
    "cap": "23884"
  },
  {
    "key": "3461",
    "ITA": "CASTELLO DI CISTERNA",
    "state_code": "NA",
    "cab": "403907",
    "cap": "80030"
  },
  {
    "key": "3462",
    "ITA": "CASTELLO DI GODEGO",
    "state_code": "TV",
    "cab": "615708",
    "cap": "31030"
  },
  {
    "key": "3463",
    "ITA": "CASTELLO DI SERRAVALLE",
    "state_code": "BO",
    "cab": "367300",
    "cap": "40050"
  },
  {
    "key": "3464",
    "ITA": "CASTELLO DI SERRAVALLE-CASTELLETTO",
    "state_code": "BO",
    "cab": "367300",
    "cap": "40050"
  },
  {
    "key": "3465",
    "ITA": "CASTELLO LAVAZZO-PODENZOI",
    "state_code": "BL",
    "cab": "700609",
    "cap": "32010"
  },
  {
    "key": "3466",
    "ITA": "CASTELLO LAVAZZO-CODISSAGO",
    "state_code": "BL",
    "cab": "700609",
    "cap": "32010"
  },
  {
    "key": "3467",
    "ITA": "CASTELLO MOLINA DI FIEMME",
    "state_code": "TN",
    "cab": "345702",
    "cap": "38030"
  },
  {
    "key": "3468",
    "ITA": "CASTELLO MOLINA DI FIEMME-CASTELLO DI FIEMME",
    "state_code": "TN",
    "cab": "345702",
    "cap": "38030"
  },
  {
    "key": "3469",
    "ITA": "CASTELLO MOLINA DI FIEMME-MOLINA",
    "state_code": "TN",
    "cab": "345702",
    "cap": "38030"
  },
  {
    "key": "3470",
    "ITA": "CASTELLO MOLINA DI FIEMME-STRAMENTIZZO NUOVO",
    "state_code": "TN",
    "cab": "345702",
    "cap": "38030"
  },
  {
    "key": "3471",
    "ITA": "CASTELLO TESINO",
    "state_code": "TN",
    "cab": "345801",
    "cap": "38053"
  },
  {
    "key": "3472",
    "ITA": "CASTELLUCCHIO-SARGINESCO",
    "state_code": "MN",
    "cab": "575605",
    "cap": "46014"
  },
  {
    "key": "3473",
    "ITA": "CASTELLUCCHIO-OSPITALETTO MANTOVANO",
    "state_code": "MN",
    "cab": "575605",
    "cap": "46014"
  },
  {
    "key": "3474",
    "ITA": "CASTELLUCCHIO",
    "state_code": "MN",
    "cab": "575605",
    "cap": "46014"
  },
  {
    "key": "3475",
    "ITA": "CASTELLUCCIO DEI SAURI",
    "state_code": "FG",
    "cab": "787408",
    "cap": "71025"
  },
  {
    "key": "3476",
    "ITA": "CASTELLUCCIO INFERIORE",
    "state_code": "PZ",
    "cab": "419408",
    "cap": "85040"
  },
  {
    "key": "3477",
    "ITA": "CASTELLUCCIO SUPERIORE",
    "state_code": "PZ",
    "cab": "911917",
    "cap": "85040"
  },
  {
    "key": "3478",
    "ITA": "CASTELLUCCIO VALMAGGIORE",
    "state_code": "FG",
    "cab": "635904",
    "cap": "71020"
  },
  {
    "key": "3479",
    "ITA": "CASTELMAGNO",
    "state_code": "CN",
    "cab": "918516",
    "cap": "12020"
  },
  {
    "key": "3480",
    "ITA": "CASTELMARTE",
    "state_code": "CO",
    "cab": "925347",
    "cap": "22030"
  },
  {
    "key": "3481",
    "ITA": "CASTELMASSA",
    "state_code": "RO",
    "cab": "632000",
    "cap": "45035"
  },
  {
    "key": "3482",
    "ITA": "CASTELMAURO",
    "state_code": "CB",
    "cab": "410100",
    "cap": "86031"
  },
  {
    "key": "3483",
    "ITA": "CASTELMEZZANO",
    "state_code": "PZ",
    "cab": "911925",
    "cap": "85010"
  },
  {
    "key": "3484",
    "ITA": "CASTELMOLA",
    "state_code": "ME",
    "cab": "858704",
    "cap": "98030"
  },
  {
    "key": "3485",
    "ITA": "CASTELNOVETTO",
    "state_code": "PV",
    "cab": "930362",
    "cap": "27030"
  },
  {
    "key": "3486",
    "ITA": "CASTELNOVO BARIANO",
    "state_code": "RO",
    "cab": "632109",
    "cap": "45030"
  },
  {
    "key": "3487",
    "ITA": "CASTELNOVO BARIANO-SAN PIETRO POLESINE",
    "state_code": "RO",
    "cab": "632109",
    "cap": "45030"
  },
  {
    "key": "3488",
    "ITA": "CASTELNOVO DEL FRIULI",
    "state_code": "PN",
    "cab": "292680",
    "cap": "33090"
  },
  {
    "key": "3489",
    "ITA": "CASTELNOVO DI SOTTO",
    "state_code": "RE",
    "cab": "662700",
    "cap": "42024"
  },
  {
    "key": "3490",
    "ITA": "CASTELNOVO DI SOTTO-MELETOLE",
    "state_code": "RE",
    "cab": "662700",
    "cap": "42024"
  },
  {
    "key": "3491",
    "ITA": "CASTELNOVO NE' MONTI-GATTA",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3492",
    "ITA": "CASTELNOVO NE' MONTI",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3493",
    "ITA": "CASTELNOVO NE' MONTI-CASALE",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3494",
    "ITA": "CASTELNOVO NE' MONTI-CASTELNUOVO NE' MONTI",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3495",
    "ITA": "CASTELNOVO NE' MONTI-CROCE",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3496",
    "ITA": "CASTELNOVO NE' MONTI-FELINA",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3497",
    "ITA": "CASTELNOVO NE' MONTI-MONTEDURO",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3498",
    "ITA": "CASTELNOVO NE' MONTI-VILLABERZA",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3499",
    "ITA": "CASTELNOVO NE' MONTI-VOLOGNO DI SOTTO",
    "state_code": "RE",
    "cab": "662809",
    "cap": "42035"
  },
  {
    "key": "3500",
    "ITA": "CASTELNUOVO",
    "state_code": "TN",
    "cab": "345900",
    "cap": "38050"
  },
  {
    "key": "3501",
    "ITA": "CASTELNUOVO BELBO",
    "state_code": "AT",
    "cab": "473603",
    "cap": "14043"
  },
  {
    "key": "3502",
    "ITA": "CASTELNUOVO BERARDENGA-PIANELLA",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3503",
    "ITA": "CASTELNUOVO BERARDENGA-VAGLIAGLI",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3504",
    "ITA": "CASTELNUOVO BERARDENGA-SAN GUSME'",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3505",
    "ITA": "CASTELNUOVO BERARDENGA-QUERCEGROSSA",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3506",
    "ITA": "CASTELNUOVO BERARDENGA-PONTE A BOZZONE",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3507",
    "ITA": "CASTELNUOVO BERARDENGA",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3508",
    "ITA": "CASTELNUOVO BERARDENGA-CASETTA",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3509",
    "ITA": "CASTELNUOVO BERARDENGA-MONTEAPERTI",
    "state_code": "SI",
    "cab": "718007",
    "cap": "53019"
  },
  {
    "key": "3510",
    "ITA": "CASTELNUOVO BOCCA D'ADDA",
    "state_code": "LO",
    "cab": "328302",
    "cap": "26843"
  },
  {
    "key": "3511",
    "ITA": "CASTELNUOVO BORMIDA",
    "state_code": "AL",
    "cab": "481804",
    "cap": "15017"
  },
  {
    "key": "3512",
    "ITA": "CASTELNUOVO BOZZENTE",
    "state_code": "CO",
    "cab": "925354",
    "cap": "22070"
  },
  {
    "key": "3513",
    "ITA": "CASTELNUOVO CALCEA",
    "state_code": "AT",
    "cab": "473702",
    "cap": "14040"
  },
  {
    "key": "3514",
    "ITA": "CASTELNUOVO CILENTO",
    "state_code": "SA",
    "cab": "766808",
    "cap": "84040"
  },
  {
    "key": "3515",
    "ITA": "CASTELNUOVO CILENTO-CASTELNUOVO VALLO STAZIONE",
    "state_code": "SA",
    "cab": "766808",
    "cap": "84040"
  },
  {
    "key": "3516",
    "ITA": "CASTELNUOVO CILENTO-VELINA",
    "state_code": "SA",
    "cab": "766808",
    "cap": "84040"
  },
  {
    "key": "3517",
    "ITA": "CASTELNUOVO DEL GARDA",
    "state_code": "VR",
    "cab": "593707",
    "cap": "37014"
  },
  {
    "key": "3518",
    "ITA": "CASTELNUOVO DEL GARDA-CAVALCASELLE",
    "state_code": "VR",
    "cab": "593707",
    "cap": "37014"
  },
  {
    "key": "3519",
    "ITA": "CASTELNUOVO DEL GARDA-SANDRA'",
    "state_code": "VR",
    "cab": "593707",
    "cap": "37014"
  },
  {
    "key": "3520",
    "ITA": "CASTELNUOVO DEL GARDA-OLIOSI",
    "state_code": "VR",
    "cab": "593707",
    "cap": "37014"
  },
  {
    "key": "3521",
    "ITA": "CASTELNUOVO DELLA DAUNIA",
    "state_code": "FG",
    "cab": "783605",
    "cap": "71034"
  },
  {
    "key": "3522",
    "ITA": "CASTELNUOVO DI CEVA",
    "state_code": "CN",
    "cab": "918524",
    "cap": "12070"
  },
  {
    "key": "3523",
    "ITA": "CASTELNUOVO DI CONZA-CENTRO URBANO",
    "state_code": "SA",
    "cab": "952382",
    "cap": "84020"
  },
  {
    "key": "3524",
    "ITA": "CASTELNUOVO DI CONZA",
    "state_code": "SA",
    "cab": "952382",
    "cap": "84020"
  },
  {
    "key": "3525",
    "ITA": "CASTELNUOVO DI FARFA",
    "state_code": "RI",
    "cab": "735506",
    "cap": "2031"
  },
  {
    "key": "3526",
    "ITA": "CASTELNUOVO DI GARFAGNANA",
    "state_code": "LU",
    "cab": "701300",
    "cap": "55032"
  },
  {
    "key": "3527",
    "ITA": "CASTELNUOVO DI GARFAGNANA-PALLEROSO",
    "state_code": "LU",
    "cab": "701300",
    "cap": "55032"
  },
  {
    "key": "3528",
    "ITA": "CASTELNUOVO DI PORTO-TERRAZZE",
    "state_code": "RM",
    "cab": "390104",
    "cap": "60"
  },
  {
    "key": "3529",
    "ITA": "CASTELNUOVO DI PORTO-PONTE STORTO",
    "state_code": "RM",
    "cab": "390104",
    "cap": "60"
  },
  {
    "key": "3530",
    "ITA": "CASTELNUOVO DI PORTO-GIRARDI",
    "state_code": "RM",
    "cab": "390104",
    "cap": "60"
  },
  {
    "key": "3531",
    "ITA": "CASTELNUOVO DI PORTO",
    "state_code": "RM",
    "cab": "390104",
    "cap": "60"
  },
  {
    "key": "3532",
    "ITA": "CASTELNUOVO DI PORTO-BELLAVISTA",
    "state_code": "RM",
    "cab": "390104",
    "cap": "60"
  },
  {
    "key": "3533",
    "ITA": "CASTELNUOVO DI VAL DI CECINA",
    "state_code": "PI",
    "cab": "709808",
    "cap": "56041"
  },
  {
    "key": "3534",
    "ITA": "CASTELNUOVO DI VAL DI CECINA-MONTECASTELLI PISANO",
    "state_code": "PI",
    "cab": "709808",
    "cap": "56041"
  },
  {
    "key": "3535",
    "ITA": "CASTELNUOVO DI VAL DI CECINA-SASSO PISANO",
    "state_code": "PI",
    "cab": "709808",
    "cap": "56041"
  },
  {
    "key": "3536",
    "ITA": "CASTELNUOVO DON BOSCO",
    "state_code": "AT",
    "cab": "473801",
    "cap": "14022"
  },
  {
    "key": "3537",
    "ITA": "CASTELNUOVO DON BOSCO-BECCHI",
    "state_code": "AT",
    "cab": "473801",
    "cap": "14022"
  },
  {
    "key": "3538",
    "ITA": "CASTELNUOVO DON BOSCO-MONDONIO SAN DOMENICO SAVIO",
    "state_code": "AT",
    "cab": "473801",
    "cap": "14022"
  },
  {
    "key": "3539",
    "ITA": "CASTELNUOVO MAGRA-COLOMBIERA",
    "state_code": "SP",
    "cab": "497404",
    "cap": "19033"
  },
  {
    "key": "3540",
    "ITA": "CASTELNUOVO MAGRA-MOLICCIARA",
    "state_code": "SP",
    "cab": "497404",
    "cap": "19033"
  },
  {
    "key": "3541",
    "ITA": "CASTELNUOVO MAGRA",
    "state_code": "SP",
    "cab": "497404",
    "cap": "19033"
  },
  {
    "key": "3542",
    "ITA": "CASTELNUOVO NIGRA-VILLA CASTELNUOVO",
    "state_code": "TO",
    "cab": "190454",
    "cap": "10080"
  },
  {
    "key": "3543",
    "ITA": "CASTELNUOVO NIGRA",
    "state_code": "TO",
    "cab": "190454",
    "cap": "10080"
  },
  {
    "key": "3544",
    "ITA": "CASTELNUOVO PARANO",
    "state_code": "FR",
    "cab": "948281",
    "cap": "3040"
  },
  {
    "key": "3545",
    "ITA": "CASTELNUOVO RANGONE-MONTALE",
    "state_code": "MO",
    "cab": "667006",
    "cap": "41051"
  },
  {
    "key": "3546",
    "ITA": "CASTELNUOVO RANGONE",
    "state_code": "MO",
    "cab": "667006",
    "cap": "41051"
  },
  {
    "key": "3547",
    "ITA": "CASTELNUOVO SCRIVIA",
    "state_code": "AL",
    "cab": "481903",
    "cap": "15053"
  },
  {
    "key": "3548",
    "ITA": "CASTELPAGANO",
    "state_code": "BN",
    "cab": "950204",
    "cap": "82024"
  },
  {
    "key": "3549",
    "ITA": "CASTELPETROSO-INDIPRETE",
    "state_code": "IS",
    "cab": "396309",
    "cap": "86090"
  },
  {
    "key": "3550",
    "ITA": "CASTELPETROSO-PASTENA",
    "state_code": "IS",
    "cab": "396309",
    "cap": "86090"
  },
  {
    "key": "3551",
    "ITA": "CASTELPETROSO",
    "state_code": "IS",
    "cab": "396309",
    "cap": "86090"
  },
  {
    "key": "3552",
    "ITA": "CASTELPETROSO-GUASTO",
    "state_code": "IS",
    "cab": "396309",
    "cap": "86090"
  },
  {
    "key": "3553",
    "ITA": "CASTELPIZZUTO",
    "state_code": "IS",
    "cab": "955567",
    "cap": "86090"
  },
  {
    "key": "3554",
    "ITA": "CASTELPLANIO-MACINE",
    "state_code": "AN",
    "cab": "373001",
    "cap": "60031"
  },
  {
    "key": "3555",
    "ITA": "CASTELPLANIO-CASTELPLANIO STAZIONE",
    "state_code": "AN",
    "cab": "373001",
    "cap": "60031"
  },
  {
    "key": "3556",
    "ITA": "CASTELPLANIO",
    "state_code": "AN",
    "cab": "373001",
    "cap": "60031"
  },
  {
    "key": "3557",
    "ITA": "CASTELPLANIO-BORGO LORETO",
    "state_code": "AN",
    "cab": "373001",
    "cap": "60031"
  },
  {
    "key": "3558",
    "ITA": "CASTELPOTO",
    "state_code": "BN",
    "cab": "950212",
    "cap": "82030"
  },
  {
    "key": "3559",
    "ITA": "CASTELRAIMONDO-CRISPIERO",
    "state_code": "MC",
    "cab": "688408",
    "cap": "62022"
  },
  {
    "key": "3560",
    "ITA": "CASTELRAIMONDO",
    "state_code": "MC",
    "cab": "688408",
    "cap": "62022"
  },
  {
    "key": "3561",
    "ITA": "CASTELROTTO-SIUSI",
    "state_code": "BZ",
    "cab": "231001",
    "cap": "39040"
  },
  {
    "key": "3562",
    "ITA": "CASTELROTTO-ALPE DI SIUSI",
    "state_code": "BZ",
    "cab": "231001",
    "cap": "39040"
  },
  {
    "key": "3563",
    "ITA": "CASTELROTTO",
    "state_code": "BZ",
    "cab": "231001",
    "cap": "39040"
  },
  {
    "key": "3564",
    "ITA": "CASTELSANTANGELO SUL NERA",
    "state_code": "MC",
    "cab": "688507",
    "cap": "62039"
  },
  {
    "key": "3565",
    "ITA": "CASTELSARACENO-MIRALDO",
    "state_code": "PZ",
    "cab": "911933",
    "cap": "85031"
  },
  {
    "key": "3566",
    "ITA": "CASTELSARACENO-FRUSCI",
    "state_code": "PZ",
    "cab": "911933",
    "cap": "85031"
  },
  {
    "key": "3567",
    "ITA": "CASTELSARACENO",
    "state_code": "PZ",
    "cab": "911933",
    "cap": "85031"
  },
  {
    "key": "3568",
    "ITA": "CASTELSARDO",
    "state_code": "SS",
    "cab": "851204",
    "cap": "7031"
  },
  {
    "key": "3569",
    "ITA": "CASTELSARDO-LU BAGNU",
    "state_code": "SS",
    "cab": "851204",
    "cap": "7031"
  },
  {
    "key": "3570",
    "ITA": "CASTELSEPRIO",
    "state_code": "VA",
    "cab": "924365",
    "cap": "21050"
  },
  {
    "key": "3571",
    "ITA": "CASTELSILANO",
    "state_code": "KR",
    "cab": "912758",
    "cap": "88834"
  },
  {
    "key": "3572",
    "ITA": "CASTELSPINA",
    "state_code": "AL",
    "cab": "921056",
    "cap": "15070"
  },
  {
    "key": "3573",
    "ITA": "CASTELTERMINI",
    "state_code": "AG",
    "cab": "828905",
    "cap": "92025"
  },
  {
    "key": "3574",
    "ITA": "CASTELTERMINI-ZOLFARE",
    "state_code": "AG",
    "cab": "828905",
    "cap": "92025"
  },
  {
    "key": "3575",
    "ITA": "CASTELVECCANA",
    "state_code": "VA",
    "cab": "674408",
    "cap": "21010"
  },
  {
    "key": "3576",
    "ITA": "CASTELVECCANA-NASCA",
    "state_code": "VA",
    "cab": "674408",
    "cap": "21010"
  },
  {
    "key": "3577",
    "ITA": "CASTELVECCANA-CASTELLO",
    "state_code": "VA",
    "cab": "674408",
    "cap": "21010"
  },
  {
    "key": "3578",
    "ITA": "CASTELVECCANA-CALDE'",
    "state_code": "VA",
    "cab": "674408",
    "cap": "21010"
  },
  {
    "key": "3579",
    "ITA": "CASTELVECCHIO CALVISIO",
    "state_code": "AQ",
    "cab": "909598",
    "cap": "67020"
  },
  {
    "key": "3580",
    "ITA": "CASTELVECCHIO DI ROCCA BARBENA",
    "state_code": "SV",
    "cab": "923086",
    "cap": "17034"
  },
  {
    "key": "3581",
    "ITA": "CASTELVECCHIO SUBEQUO",
    "state_code": "AQ",
    "cab": "405308",
    "cap": "67024"
  },
  {
    "key": "3582",
    "ITA": "CASTELVENERE",
    "state_code": "BN",
    "cab": "372102",
    "cap": "82037"
  },
  {
    "key": "3583",
    "ITA": "CASTELVERDE",
    "state_code": "CR",
    "cab": "567701",
    "cap": "26022"
  },
  {
    "key": "3584",
    "ITA": "CASTELVERDE-COSTA SANT'ABRAMO",
    "state_code": "CR",
    "cab": "567701",
    "cap": "26022"
  },
  {
    "key": "3585",
    "ITA": "CASTELVERDE-SAN MARTINO IN BELISETO",
    "state_code": "CR",
    "cab": "567701",
    "cap": "26022"
  },
  {
    "key": "3586",
    "ITA": "CASTELVERRINO",
    "state_code": "IS",
    "cab": "955583",
    "cap": "86080"
  },
  {
    "key": "3587",
    "ITA": "CASTELVETERE IN VAL FORTORE",
    "state_code": "BN",
    "cab": "950238",
    "cap": "82023"
  },
  {
    "key": "3588",
    "ITA": "CASTELVETERE SUL CALORE",
    "state_code": "AV",
    "cab": "951046",
    "cap": "83040"
  },
  {
    "key": "3589",
    "ITA": "CASTELVETRANO-MARINELLA",
    "state_code": "TP",
    "cab": "818302",
    "cap": "91022"
  },
  {
    "key": "3590",
    "ITA": "CASTELVETRANO",
    "state_code": "TP",
    "cab": "818302",
    "cap": "91022"
  },
  {
    "key": "3591",
    "ITA": "CASTELVETRANO-TRISCINA",
    "state_code": "TP",
    "cab": "818302",
    "cap": "91022"
  },
  {
    "key": "3592",
    "ITA": "CASTELVETRO DI MODENA-CA' DI SOLA",
    "state_code": "MO",
    "cab": "667105",
    "cap": "41014"
  },
  {
    "key": "3593",
    "ITA": "CASTELVETRO DI MODENA-SOLIGNANO NUOVO",
    "state_code": "MO",
    "cab": "667105",
    "cap": "41014"
  },
  {
    "key": "3594",
    "ITA": "CASTELVETRO DI MODENA-LEVIZZANO RANGONE",
    "state_code": "MO",
    "cab": "667105",
    "cap": "41014"
  },
  {
    "key": "3595",
    "ITA": "CASTELVETRO DI MODENA",
    "state_code": "MO",
    "cab": "667105",
    "cap": "41014"
  },
  {
    "key": "3596",
    "ITA": "CASTELVETRO PIACENTINO-SAN GIULIANO",
    "state_code": "PC",
    "cab": "652701",
    "cap": "29010"
  },
  {
    "key": "3597",
    "ITA": "CASTELVETRO PIACENTINO-MEZZANO CHITANTOLO",
    "state_code": "PC",
    "cab": "652701",
    "cap": "29010"
  },
  {
    "key": "3598",
    "ITA": "CASTELVETRO PIACENTINO",
    "state_code": "PC",
    "cab": "652701",
    "cap": "29010"
  },
  {
    "key": "3599",
    "ITA": "CASTELVISCONTI",
    "state_code": "CR",
    "cab": "931592",
    "cap": "26010"
  },
  {
    "key": "3600",
    "ITA": "CASTENASO",
    "state_code": "BO",
    "cab": "367607",
    "cap": "40055"
  },
  {
    "key": "3601",
    "ITA": "CASTENASO-FIESSO",
    "state_code": "BO",
    "cab": "367607",
    "cap": "40055"
  },
  {
    "key": "3602",
    "ITA": "CASTENASO-VILLANOVA",
    "state_code": "BO",
    "cab": "367607",
    "cap": "40055"
  },
  {
    "key": "3603",
    "ITA": "CASTENEDOLO",
    "state_code": "BS",
    "cab": "542704",
    "cap": "25014"
  },
  {
    "key": "3604",
    "ITA": "CASTENEDOLO-CAPODIMONTE",
    "state_code": "BS",
    "cab": "542704",
    "cap": "25014"
  },
  {
    "key": "3605",
    "ITA": "CASTIADAS",
    "state_code": "CA",
    "cab": "724609",
    "cap": "9040"
  },
  {
    "key": "3606",
    "ITA": "CASTIGLION FIBOCCHI",
    "state_code": "AR",
    "cab": "714006",
    "cap": "52029"
  },
  {
    "key": "3607",
    "ITA": "CASTIGLION FIORENTINO-MANCIANO",
    "state_code": "AR",
    "cab": "714105",
    "cap": "52043"
  },
  {
    "key": "3608",
    "ITA": "CASTIGLION FIORENTINO",
    "state_code": "AR",
    "cab": "714105",
    "cap": "52043"
  },
  {
    "key": "3609",
    "ITA": "CASTIGLION FIORENTINO-MONTECCHIO",
    "state_code": "AR",
    "cab": "714105",
    "cap": "52043"
  },
  {
    "key": "3610",
    "ITA": "CASTIGLIONE A CASAURIA",
    "state_code": "PE",
    "cab": "954040",
    "cap": "65020"
  },
  {
    "key": "3611",
    "ITA": "CASTIGLIONE CHIAVARESE",
    "state_code": "GE",
    "cab": "319400",
    "cap": "16030"
  },
  {
    "key": "3612",
    "ITA": "CASTIGLIONE CHIAVARESE-VELVA",
    "state_code": "GE",
    "cab": "319400",
    "cap": "16030"
  },
  {
    "key": "3613",
    "ITA": "CASTIGLIONE COSENTINO",
    "state_code": "CS",
    "cab": "958702",
    "cap": "87040"
  },
  {
    "key": "3614",
    "ITA": "CASTIGLIONE D'ADDA",
    "state_code": "LO",
    "cab": "328401",
    "cap": "26823"
  },
  {
    "key": "3615",
    "ITA": "CASTIGLIONE D'INTELVI",
    "state_code": "CO",
    "cab": "511402",
    "cap": "22023"
  },
  {
    "key": "3616",
    "ITA": "CASTIGLIONE D'ORCIA-BAGNI SAN FILIPPO",
    "state_code": "SI",
    "cab": "718106",
    "cap": "53023"
  },
  {
    "key": "3617",
    "ITA": "CASTIGLIONE D'ORCIA-CAMPIGLIA D'ORCIA",
    "state_code": "SI",
    "cab": "718106",
    "cap": "53023"
  },
  {
    "key": "3618",
    "ITA": "CASTIGLIONE D'ORCIA-VIVO D'ORCIA",
    "state_code": "SI",
    "cab": "718106",
    "cap": "53023"
  },
  {
    "key": "3619",
    "ITA": "CASTIGLIONE D'ORCIA-GALLINA",
    "state_code": "SI",
    "cab": "718106",
    "cap": "53023"
  },
  {
    "key": "3620",
    "ITA": "CASTIGLIONE D'ORCIA",
    "state_code": "SI",
    "cab": "718106",
    "cap": "53023"
  },
  {
    "key": "3621",
    "ITA": "CASTIGLIONE DEI PEPOLI-CREDA",
    "state_code": "BO",
    "cab": "367706",
    "cap": "40035"
  },
  {
    "key": "3622",
    "ITA": "CASTIGLIONE DEI PEPOLI-BARAGAZZA",
    "state_code": "BO",
    "cab": "367706",
    "cap": "40035"
  },
  {
    "key": "3623",
    "ITA": "CASTIGLIONE DEI PEPOLI",
    "state_code": "BO",
    "cab": "367706",
    "cap": "40035"
  },
  {
    "key": "3624",
    "ITA": "CASTIGLIONE DEI PEPOLI-LAGARO",
    "state_code": "BO",
    "cab": "367706",
    "cap": "40035"
  },
  {
    "key": "3625",
    "ITA": "CASTIGLIONE DEL GENOVESI",
    "state_code": "SA",
    "cab": "952408",
    "cap": "84090"
  },
  {
    "key": "3626",
    "ITA": "CASTIGLIONE DEL LAGO-GIOIELLA",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6061"
  },
  {
    "key": "3627",
    "ITA": "CASTIGLIONE DEL LAGO",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6061"
  },
  {
    "key": "3628",
    "ITA": "CASTIGLIONE DEL LAGO-MACCHIE",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6060"
  },
  {
    "key": "3629",
    "ITA": "CASTIGLIONE DEL LAGO-VILLASTRADA",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6060"
  },
  {
    "key": "3630",
    "ITA": "CASTIGLIONE DEL LAGO-SAN FATUCCHIO",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6060"
  },
  {
    "key": "3631",
    "ITA": "CASTIGLIONE DEL LAGO-POZZUOLO",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6061"
  },
  {
    "key": "3632",
    "ITA": "CASTIGLIONE DEL LAGO-PANICAROLA",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6060"
  },
  {
    "key": "3633",
    "ITA": "CASTIGLIONE DEL LAGO-PETRIGNANO DEL LAGO",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6061"
  },
  {
    "key": "3634",
    "ITA": "CASTIGLIONE DEL LAGO-PORTO",
    "state_code": "PG",
    "cab": "383505",
    "cap": "6061"
  },
  {
    "key": "3635",
    "ITA": "CASTIGLIONE DELLA PESCAIA",
    "state_code": "GR",
    "cab": "722108",
    "cap": "58043"
  },
  {
    "key": "3636",
    "ITA": "CASTIGLIONE DELLA PESCAIA-TIRLI",
    "state_code": "GR",
    "cab": "722108",
    "cap": "58043"
  },
  {
    "key": "3637",
    "ITA": "CASTIGLIONE DELLA PESCAIA-VETULONIA",
    "state_code": "GR",
    "cab": "722108",
    "cap": "58043"
  },
  {
    "key": "3638",
    "ITA": "CASTIGLIONE DELLA PESCAIA-BURIANO",
    "state_code": "GR",
    "cab": "722108",
    "cap": "58043"
  },
  {
    "key": "3639",
    "ITA": "CASTIGLIONE DELLA PESCAIA-PUNTA ALA",
    "state_code": "GR",
    "cab": "722108",
    "cap": "58043"
  },
  {
    "key": "3640",
    "ITA": "CASTIGLIONE DELLA PESCAIA-RIVA DEL SOLE",
    "state_code": "GR",
    "cab": "722108",
    "cap": "58043"
  },
  {
    "key": "3641",
    "ITA": "CASTIGLIONE DELLE STIVIERE",
    "state_code": "MN",
    "cab": "575704",
    "cap": "46043"
  },
  {
    "key": "3642",
    "ITA": "CASTIGLIONE DELLE STIVIERE-GOZZOLINA",
    "state_code": "MN",
    "cab": "575704",
    "cap": "46043"
  },
  {
    "key": "3643",
    "ITA": "CASTIGLIONE DI GARFAGNANA-SAN PELLEGRINO",
    "state_code": "LU",
    "cab": "698209",
    "cap": "55033"
  },
  {
    "key": "3644",
    "ITA": "CASTIGLIONE DI GARFAGNANA-VALBONA",
    "state_code": "LU",
    "cab": "698209",
    "cap": "55033"
  },
  {
    "key": "3645",
    "ITA": "CASTIGLIONE DI GARFAGNANA-CHIOZZA",
    "state_code": "LU",
    "cab": "698209",
    "cap": "55033"
  },
  {
    "key": "3646",
    "ITA": "CASTIGLIONE DI GARFAGNANA-CERAGETO",
    "state_code": "LU",
    "cab": "698209",
    "cap": "55033"
  },
  {
    "key": "3647",
    "ITA": "CASTIGLIONE DI GARFAGNANA",
    "state_code": "LU",
    "cab": "698209",
    "cap": "55033"
  },
  {
    "key": "3648",
    "ITA": "CASTIGLIONE DI SICILIA-VERZELLA",
    "state_code": "CT",
    "cab": "839308",
    "cap": "95012"
  },
  {
    "key": "3649",
    "ITA": "CASTIGLIONE DI SICILIA-SOLICCHIATA",
    "state_code": "CT",
    "cab": "839308",
    "cap": "95012"
  },
  {
    "key": "3650",
    "ITA": "CASTIGLIONE DI SICILIA-PASSOPISCIARO",
    "state_code": "CT",
    "cab": "839308",
    "cap": "95012"
  },
  {
    "key": "3651",
    "ITA": "CASTIGLIONE DI SICILIA",
    "state_code": "CT",
    "cab": "839308",
    "cap": "95012"
  },
  {
    "key": "3652",
    "ITA": "CASTIGLIONE DI SICILIA-MITOGIO",
    "state_code": "CT",
    "cab": "839308",
    "cap": "95012"
  },
  {
    "key": "3653",
    "ITA": "CASTIGLIONE FALLETTO",
    "state_code": "CN",
    "cab": "817106",
    "cap": "12060"
  },
  {
    "key": "3654",
    "ITA": "CASTIGLIONE IN TEVERINA",
    "state_code": "VT",
    "cab": "730002",
    "cap": "1024"
  },
  {
    "key": "3655",
    "ITA": "CASTIGLIONE IN TEVERINA-SERMUGNANO",
    "state_code": "VT",
    "cab": "730002",
    "cap": "1024"
  },
  {
    "key": "3656",
    "ITA": "CASTIGLIONE MESSER MARINO",
    "state_code": "CH",
    "cab": "776203",
    "cap": "66033"
  },
  {
    "key": "3657",
    "ITA": "CASTIGLIONE MESSER RAIMONDO",
    "state_code": "TE",
    "cab": "768200",
    "cap": "64034"
  },
  {
    "key": "3658",
    "ITA": "CASTIGLIONE MESSER RAIMONDO-APPIGNANO",
    "state_code": "TE",
    "cab": "768200",
    "cap": "64034"
  },
  {
    "key": "3659",
    "ITA": "CASTIGLIONE OLONA-GORNATE SUPERIORE",
    "state_code": "VA",
    "cab": "501304",
    "cap": "21043"
  },
  {
    "key": "3660",
    "ITA": "CASTIGLIONE OLONA",
    "state_code": "VA",
    "cab": "501304",
    "cap": "21043"
  },
  {
    "key": "3661",
    "ITA": "CASTIGLIONE TINELLA-SANTUARIO TINELLA",
    "state_code": "CN",
    "cab": "461400",
    "cap": "12053"
  },
  {
    "key": "3662",
    "ITA": "CASTIGLIONE TINELLA",
    "state_code": "CN",
    "cab": "461400",
    "cap": "12053"
  },
  {
    "key": "3663",
    "ITA": "CASTIGLIONE TORINESE",
    "state_code": "TO",
    "cab": "303107",
    "cap": "10090"
  },
  {
    "key": "3664",
    "ITA": "CASTIGNANO",
    "state_code": "AP",
    "cab": "694000",
    "cap": "63032"
  },
  {
    "key": "3665",
    "ITA": "CASTIGNANO-RIPABERARDA",
    "state_code": "AP",
    "cab": "694000",
    "cap": "63032"
  },
  {
    "key": "3666",
    "ITA": "CASTILENTI",
    "state_code": "TE",
    "cab": "768309",
    "cap": "64035"
  },
  {
    "key": "3667",
    "ITA": "CASTILENTI-VILLA SAN ROMUALDO",
    "state_code": "TE",
    "cab": "768309",
    "cap": "64035"
  },
  {
    "key": "3668",
    "ITA": "CASTINO",
    "state_code": "CN",
    "cab": "802207",
    "cap": "12050"
  },
  {
    "key": "3669",
    "ITA": "CASTIONE ANDEVENNO",
    "state_code": "SO",
    "cab": "791301",
    "cap": "23012"
  },
  {
    "key": "3670",
    "ITA": "CASTIONE DELLA PRESOLANA-BRATTO",
    "state_code": "BG",
    "cab": "528406",
    "cap": "24020"
  },
  {
    "key": "3671",
    "ITA": "CASTIONE DELLA PRESOLANA",
    "state_code": "BG",
    "cab": "528406",
    "cap": "24020"
  },
  {
    "key": "3672",
    "ITA": "CASTIONS DI STRADA",
    "state_code": "UD",
    "cab": "637207",
    "cap": "33050"
  },
  {
    "key": "3673",
    "ITA": "CASTIRAGA VIDARDO",
    "state_code": "LO",
    "cab": "856567",
    "cap": "26866"
  },
  {
    "key": "3674",
    "ITA": "CASTIRAGA VIDARDO-VIDARDO",
    "state_code": "LO",
    "cab": "856567",
    "cap": "26866"
  },
  {
    "key": "3675",
    "ITA": "CASTO",
    "state_code": "BS",
    "cab": "542803",
    "cap": "25070"
  },
  {
    "key": "3676",
    "ITA": "CASTORANO",
    "state_code": "AP",
    "cab": "564906",
    "cap": "63030"
  },
  {
    "key": "3677",
    "ITA": "CASTORANO-SAN SILVESTRO",
    "state_code": "AP",
    "cab": "564906",
    "cap": "63030"
  },
  {
    "key": "3678",
    "ITA": "CASTREZZATO",
    "state_code": "BS",
    "cab": "542902",
    "cap": "25030"
  },
  {
    "key": "3679",
    "ITA": "CASTRI DI LECCE",
    "state_code": "LE",
    "cab": "795302",
    "cap": "73020"
  },
  {
    "key": "3680",
    "ITA": "CASTRIGNANO DE' GRECI",
    "state_code": "LE",
    "cab": "795401",
    "cap": "73020"
  },
  {
    "key": "3681",
    "ITA": "CASTRIGNANO DEL CAPO-SALIGNANO",
    "state_code": "LE",
    "cab": "795500",
    "cap": "73040"
  },
  {
    "key": "3682",
    "ITA": "CASTRIGNANO DEL CAPO-MARINA DI LEUCA",
    "state_code": "LE",
    "cab": "795500",
    "cap": "73040"
  },
  {
    "key": "3683",
    "ITA": "CASTRIGNANO DEL CAPO-LEUCA",
    "state_code": "LE",
    "cab": "795500",
    "cap": "73040"
  },
  {
    "key": "3684",
    "ITA": "CASTRIGNANO DEL CAPO",
    "state_code": "LE",
    "cab": "795500",
    "cap": "73040"
  },
  {
    "key": "3685",
    "ITA": "CASTRIGNANO DEL CAPO-GIULIANO DI LECCE",
    "state_code": "LE",
    "cab": "795500",
    "cap": "73040"
  },
  {
    "key": "3686",
    "ITA": "CASTRO-FONDERIA DI LOVERE",
    "state_code": "BG",
    "cab": "264846",
    "cap": "24063"
  },
  {
    "key": "3687",
    "ITA": "CASTRO",
    "state_code": "BG",
    "cab": "264846",
    "cap": "24063"
  },
  {
    "key": "3688",
    "ITA": "CASTRO-CASTRO MARINA",
    "state_code": "LE",
    "cab": "801704",
    "cap": "73030"
  },
  {
    "key": "3689",
    "ITA": "CASTRO",
    "state_code": "LE",
    "cab": "801704",
    "cap": "73030"
  },
  {
    "key": "3690",
    "ITA": "CASTRO DEI VOLSCI-MADONNA DEL PIANO",
    "state_code": "FR",
    "cab": "743807",
    "cap": "3020"
  },
  {
    "key": "3691",
    "ITA": "CASTRO DEI VOLSCI-SAN SOSIO",
    "state_code": "FR",
    "cab": "743807",
    "cap": "3020"
  },
  {
    "key": "3692",
    "ITA": "CASTRO DEI VOLSCI",
    "state_code": "FR",
    "cab": "743807",
    "cap": "3020"
  },
  {
    "key": "3693",
    "ITA": "CASTROCARO TERME E TERRA DEL SOLE-TERRA DEL SOLE",
    "state_code": "FC",
    "cab": "677401",
    "cap": "47011"
  },
  {
    "key": "3694",
    "ITA": "CASTROCARO TERME E TERRA DEL SOLE-PIEVE SALUTARE",
    "state_code": "FC",
    "cab": "677401",
    "cap": "47011"
  },
  {
    "key": "3695",
    "ITA": "CASTROCARO TERME E TERRA DEL SOLE",
    "state_code": "FC",
    "cab": "677401",
    "cap": "47011"
  },
  {
    "key": "3696",
    "ITA": "CASTROCIELO",
    "state_code": "FR",
    "cab": "382606",
    "cap": "3030"
  },
  {
    "key": "3697",
    "ITA": "CASTROFILIPPO",
    "state_code": "AG",
    "cab": "829002",
    "cap": "92020"
  },
  {
    "key": "3698",
    "ITA": "CASTROLIBERO-ORTOMATERA",
    "state_code": "CS",
    "cab": "888008",
    "cap": "87040"
  },
  {
    "key": "3699",
    "ITA": "CASTROLIBERO-ANDREOTTA",
    "state_code": "CS",
    "cab": "888008",
    "cap": "87040"
  },
  {
    "key": "3700",
    "ITA": "CASTROLIBERO",
    "state_code": "CS",
    "cab": "888008",
    "cap": "87040"
  },
  {
    "key": "3701",
    "ITA": "CASTRONNO",
    "state_code": "VA",
    "cab": "501403",
    "cap": "21040"
  },
  {
    "key": "3702",
    "ITA": "CASTRONNO-CASCINE MAGGIO",
    "state_code": "VA",
    "cab": "501403",
    "cap": "21040"
  },
  {
    "key": "3703",
    "ITA": "CASTRONUOVO DI SANT'ANDREA",
    "state_code": "PZ",
    "cab": "911941",
    "cap": "85030"
  },
  {
    "key": "3704",
    "ITA": "CASTRONUOVO DI SICILIA",
    "state_code": "PA",
    "cab": "432500",
    "cap": "90030"
  },
  {
    "key": "3705",
    "ITA": "CASTROPIGNANO-ROCCASPROMONTE",
    "state_code": "CB",
    "cab": "910497",
    "cap": "86010"
  },
  {
    "key": "3706",
    "ITA": "CASTROPIGNANO",
    "state_code": "CB",
    "cab": "910497",
    "cap": "86010"
  },
  {
    "key": "3707",
    "ITA": "CASTROREALE",
    "state_code": "ME",
    "cab": "858803",
    "cap": "98053"
  },
  {
    "key": "3708",
    "ITA": "CASTROREALE-BAFIA",
    "state_code": "ME",
    "cab": "858803",
    "cap": "98053"
  },
  {
    "key": "3709",
    "ITA": "CASTROREALE-PROTONOTARO",
    "state_code": "ME",
    "cab": "858803",
    "cap": "98053"
  },
  {
    "key": "3710",
    "ITA": "CASTROREGIO",
    "state_code": "CS",
    "cab": "958728",
    "cap": "87070"
  },
  {
    "key": "3711",
    "ITA": "CASTROREGIO-FARNETA",
    "state_code": "CS",
    "cab": "958728",
    "cap": "87070"
  },
  {
    "key": "3712",
    "ITA": "CASTROVILLARI",
    "state_code": "CS",
    "cab": "806703",
    "cap": "87012"
  },
  {
    "key": "3713",
    "ITA": "CASTROVILLARI-VIGNE DI CASTROVILLARI",
    "state_code": "CS",
    "cab": "806703",
    "cap": "87012"
  },
  {
    "key": "3714",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95127"
  },
  {
    "key": "3715",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95125"
  },
  {
    "key": "3716",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95128"
  },
  {
    "key": "3717",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95129"
  },
  {
    "key": "3718",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95126"
  },
  {
    "key": "3719",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95124"
  },
  {
    "key": "3720",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95123"
  },
  {
    "key": "3721",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95122"
  },
  {
    "key": "3722",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95121"
  },
  {
    "key": "3723",
    "ITA": "CATANIA",
    "state_code": "CT",
    "cab": "169003",
    "cap": "95131"
  },
  {
    "key": "3724",
    "ITA": "CATANZARO-PONTEGRANDE",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3725",
    "ITA": "CATANZARO-CATANZARO LIDO",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3726",
    "ITA": "CATANZARO-CATANZARO SALA",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3727",
    "ITA": "CATANZARO-CAVA DI CATANZARO",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3728",
    "ITA": "CATANZARO",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3729",
    "ITA": "CATANZARO-SIANO",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3730",
    "ITA": "CATANZARO-SANTA MARIA DI CATANZARO",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3731",
    "ITA": "CATANZARO-SANT'ELIA",
    "state_code": "CZ",
    "cab": "44008",
    "cap": "88100"
  },
  {
    "key": "3732",
    "ITA": "CATENANUOVA",
    "state_code": "EN",
    "cab": "836205",
    "cap": "94010"
  },
  {
    "key": "3733",
    "ITA": "CATIGNANO",
    "state_code": "PE",
    "cab": "772301",
    "cap": "65011"
  },
  {
    "key": "3734",
    "ITA": "CATTOLICA",
    "state_code": "RN",
    "cab": "677500",
    "cap": "47841"
  },
  {
    "key": "3735",
    "ITA": "CATTOLICA ERACLEA",
    "state_code": "AG",
    "cab": "829101",
    "cap": "92011"
  },
  {
    "key": "3736",
    "ITA": "CAULONIA-URSINI",
    "state_code": "RC",
    "cab": "813303",
    "cap": "89040"
  },
  {
    "key": "3737",
    "ITA": "CAULONIA",
    "state_code": "RC",
    "cab": "813303",
    "cap": "89041"
  },
  {
    "key": "3738",
    "ITA": "CAULONIA-CAMPOLI DI CAULONIA",
    "state_code": "RC",
    "cab": "813303",
    "cap": "89040"
  },
  {
    "key": "3739",
    "ITA": "CAULONIA-CAULONIA MARINA",
    "state_code": "RC",
    "cab": "813303",
    "cap": "89040"
  },
  {
    "key": "3740",
    "ITA": "CAULONIA-SAN NICOLA DI CAULONIA",
    "state_code": "RC",
    "cab": "813303",
    "cap": "89040"
  },
  {
    "key": "3741",
    "ITA": "CAUTANO-CACCIANO",
    "state_code": "BN",
    "cab": "950246",
    "cap": "82030"
  },
  {
    "key": "3742",
    "ITA": "CAUTANO",
    "state_code": "BN",
    "cab": "950246",
    "cap": "82030"
  },
  {
    "key": "3743",
    "ITA": "CAVA DE' TIRRENI-SANTA LUCIA DI CAVA",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3744",
    "ITA": "CAVA DE' TIRRENI-SAN PIETRO DI CAVA",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3745",
    "ITA": "CAVA DE' TIRRENI-PREGIATO",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3746",
    "ITA": "CAVA DE' TIRRENI-PASSIANO",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3747",
    "ITA": "CAVA DE' TIRRENI-MARINI",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3748",
    "ITA": "CAVA DE' TIRRENI",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3749",
    "ITA": "CAVA DE' TIRRENI-CORPO DI CAVA",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3750",
    "ITA": "CAVA DE' TIRRENI-BADIA DI CAVA DE' TIRRENI",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3751",
    "ITA": "CAVA DE' TIRRENI-ARCARA",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3752",
    "ITA": "CAVA DE' TIRRENI-ALESSIA",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3753",
    "ITA": "CAVA DE' TIRRENI-DUPINO",
    "state_code": "SA",
    "cab": "761700",
    "cap": "84013"
  },
  {
    "key": "3754",
    "ITA": "CAVA MANARA-TRE RE",
    "state_code": "PV",
    "cab": "557405",
    "cap": "27051"
  },
  {
    "key": "3755",
    "ITA": "CAVA MANARA-MEZZANA CORTI",
    "state_code": "PV",
    "cab": "557405",
    "cap": "27051"
  },
  {
    "key": "3756",
    "ITA": "CAVA MANARA",
    "state_code": "PV",
    "cab": "557405",
    "cap": "27051"
  },
  {
    "key": "3757",
    "ITA": "CAVACURTA",
    "state_code": "LO",
    "cab": "903773",
    "cap": "26844"
  },
  {
    "key": "3758",
    "ITA": "CAVAGLIA'",
    "state_code": "BI",
    "cab": "443903",
    "cap": "13881"
  },
  {
    "key": "3759",
    "ITA": "CAVAGLIETTO",
    "state_code": "NO",
    "cab": "917112",
    "cap": "28010"
  },
  {
    "key": "3760",
    "ITA": "CAVAGLIO D'AGOGNA",
    "state_code": "NO",
    "cab": "453308",
    "cap": "28010"
  },
  {
    "key": "3761",
    "ITA": "CAVAGLIO SPOCCIA",
    "state_code": "VB",
    "cab": "917120",
    "cap": "28825"
  },
  {
    "key": "3762",
    "ITA": "CAVAGLIO SPOCCIA-PONTE DI FALMENTA",
    "state_code": "VB",
    "cab": "917120",
    "cap": "28825"
  },
  {
    "key": "3763",
    "ITA": "CAVAGNOLO",
    "state_code": "TO",
    "cab": "377101",
    "cap": "10020"
  },
  {
    "key": "3764",
    "ITA": "CAVAION VERONESE",
    "state_code": "VR",
    "cab": "593806",
    "cap": "37010"
  },
  {
    "key": "3765",
    "ITA": "CAVAION VERONESE-SEGA",
    "state_code": "VR",
    "cab": "593806",
    "cap": "37010"
  },
  {
    "key": "3766",
    "ITA": "CAVALESE-MASI DI CAVALESE",
    "state_code": "TN",
    "cab": "346007",
    "cap": "38033"
  },
  {
    "key": "3767",
    "ITA": "CAVALESE",
    "state_code": "TN",
    "cab": "346007",
    "cap": "38033"
  },
  {
    "key": "3768",
    "ITA": "CAVALLASCA",
    "state_code": "CO",
    "cab": "787804",
    "cap": "22020"
  },
  {
    "key": "3769",
    "ITA": "CAVALLERLEONE",
    "state_code": "CN",
    "cab": "574004",
    "cap": "12030"
  },
  {
    "key": "3770",
    "ITA": "CAVALLERMAGGIORE",
    "state_code": "CN",
    "cab": "461509",
    "cap": "12030"
  },
  {
    "key": "3771",
    "ITA": "CAVALLERMAGGIORE-MADONNA DEL PILONE",
    "state_code": "CN",
    "cab": "461509",
    "cap": "12030"
  },
  {
    "key": "3772",
    "ITA": "CAVALLINO-CASTROMEDIANO",
    "state_code": "LE",
    "cab": "795609",
    "cap": "73020"
  },
  {
    "key": "3773",
    "ITA": "CAVALLINO",
    "state_code": "LE",
    "cab": "795609",
    "cap": "73020"
  },
  {
    "key": "3774",
    "ITA": "CAVALLINO TREPORTI-CA' BALLARIN",
    "state_code": "VE",
    "cab": "832105",
    "cap": "30013"
  },
  {
    "key": "3775",
    "ITA": "CAVALLINO TREPORTI-TREPORTI",
    "state_code": "VE",
    "cab": "832105",
    "cap": "30013"
  },
  {
    "key": "3776",
    "ITA": "CAVALLINO TREPORTI-CAVALLINO",
    "state_code": "VE",
    "cab": "832105",
    "cap": "30013"
  },
  {
    "key": "3777",
    "ITA": "CAVALLINO TREPORTI-CA' SAVIO",
    "state_code": "VE",
    "cab": "832105",
    "cap": "30013"
  },
  {
    "key": "3778",
    "ITA": "CAVALLINO TREPORTI-CA' PASQUALI",
    "state_code": "VE",
    "cab": "832105",
    "cap": "30013"
  },
  {
    "key": "3779",
    "ITA": "CAVALLINO TREPORTI",
    "state_code": "VE",
    "cab": "832105",
    "cap": "30013"
  },
  {
    "key": "3780",
    "ITA": "CAVALLIRIO",
    "state_code": "NO",
    "cab": "843706",
    "cap": "28010"
  },
  {
    "key": "3781",
    "ITA": "CAVARENO",
    "state_code": "TN",
    "cab": "346106",
    "cap": "38011"
  },
  {
    "key": "3782",
    "ITA": "CAVARGNA",
    "state_code": "CO",
    "cab": "925370",
    "cap": "22010"
  },
  {
    "key": "3783",
    "ITA": "CAVARIA CON PREMEZZO",
    "state_code": "VA",
    "cab": "501502",
    "cap": "21044"
  },
  {
    "key": "3784",
    "ITA": "CAVARZERE-VILLAGGIO BUSONERA",
    "state_code": "VE",
    "cab": "360305",
    "cap": "30014"
  },
  {
    "key": "3785",
    "ITA": "CAVARZERE-SAN PIETRO",
    "state_code": "VE",
    "cab": "360305",
    "cap": "30014"
  },
  {
    "key": "3786",
    "ITA": "CAVARZERE-ROTTANOVA",
    "state_code": "VE",
    "cab": "360305",
    "cap": "30014"
  },
  {
    "key": "3787",
    "ITA": "CAVARZERE",
    "state_code": "VE",
    "cab": "360305",
    "cap": "30014"
  },
  {
    "key": "3788",
    "ITA": "CAVARZERE-BOSCOCHIARO",
    "state_code": "VE",
    "cab": "360305",
    "cap": "30014"
  },
  {
    "key": "3789",
    "ITA": "CAVASO DEL TOMBA",
    "state_code": "TV",
    "cab": "615807",
    "cap": "31034"
  },
  {
    "key": "3790",
    "ITA": "CAVASSO NUOVO",
    "state_code": "PN",
    "cab": "938167",
    "cap": "33092"
  },
  {
    "key": "3791",
    "ITA": "CAVATORE",
    "state_code": "AL",
    "cab": "921064",
    "cap": "15010"
  },
  {
    "key": "3792",
    "ITA": "CAVAZZO CARNICO",
    "state_code": "UD",
    "cab": "835207",
    "cap": "33020"
  },
  {
    "key": "3793",
    "ITA": "CAVE-SAN BARTOLOMEO",
    "state_code": "RM",
    "cab": "390203",
    "cap": "33"
  },
  {
    "key": "3794",
    "ITA": "CAVE",
    "state_code": "RM",
    "cab": "390203",
    "cap": "33"
  },
  {
    "key": "3795",
    "ITA": "CAVEDAGO",
    "state_code": "TN",
    "cab": "359406",
    "cap": "38010"
  },
  {
    "key": "3796",
    "ITA": "CAVEDINE-STRAVINO",
    "state_code": "TN",
    "cab": "346205",
    "cap": "38073"
  },
  {
    "key": "3797",
    "ITA": "CAVEDINE",
    "state_code": "TN",
    "cab": "346205",
    "cap": "38073"
  },
  {
    "key": "3798",
    "ITA": "CAVEDINE-VIGO",
    "state_code": "TN",
    "cab": "346205",
    "cap": "38073"
  },
  {
    "key": "3799",
    "ITA": "CAVENAGO D'ADDA",
    "state_code": "LO",
    "cab": "328500",
    "cap": "26824"
  },
  {
    "key": "3800",
    "ITA": "CAVENAGO DI BRIANZA",
    "state_code": "MB",
    "cab": "328609",
    "cap": "20040"
  },
  {
    "key": "3801",
    "ITA": "CAVERNAGO",
    "state_code": "BG",
    "cab": "539007",
    "cap": "24050"
  },
  {
    "key": "3802",
    "ITA": "CAVERNAGO-MALPAGA",
    "state_code": "BG",
    "cab": "539007",
    "cap": "24050"
  },
  {
    "key": "3803",
    "ITA": "CAVEZZO-VILLA MOTTA",
    "state_code": "MO",
    "cab": "667204",
    "cap": "41032"
  },
  {
    "key": "3804",
    "ITA": "CAVEZZO-MOTTA",
    "state_code": "MO",
    "cab": "667204",
    "cap": "41032"
  },
  {
    "key": "3805",
    "ITA": "CAVEZZO",
    "state_code": "MO",
    "cab": "667204",
    "cap": "41032"
  },
  {
    "key": "3806",
    "ITA": "CAVIZZANA",
    "state_code": "TN",
    "cab": "904748",
    "cap": "38022"
  },
  {
    "key": "3807",
    "ITA": "CAVOUR",
    "state_code": "TO",
    "cab": "303206",
    "cap": "10061"
  },
  {
    "key": "3808",
    "ITA": "CAVRIAGO",
    "state_code": "RE",
    "cab": "662908",
    "cap": "42025"
  },
  {
    "key": "3809",
    "ITA": "CAVRIANA",
    "state_code": "MN",
    "cab": "575803",
    "cap": "46040"
  },
  {
    "key": "3810",
    "ITA": "CAVRIGLIA-VACCHERECCIA",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3811",
    "ITA": "CAVRIGLIA-SANTA BARBARA",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3812",
    "ITA": "CAVRIGLIA-SAN CIPRIANO",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3813",
    "ITA": "CAVRIGLIA-NERI",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3814",
    "ITA": "CAVRIGLIA-MONTEGONZI",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3815",
    "ITA": "CAVRIGLIA-MONASTERO",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3816",
    "ITA": "CAVRIGLIA-MELETO",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3817",
    "ITA": "CAVRIGLIA",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3818",
    "ITA": "CAVRIGLIA-CASTELNUOVO DEI SABBIONI",
    "state_code": "AR",
    "cab": "714204",
    "cap": "52022"
  },
  {
    "key": "3819",
    "ITA": "CAZZAGO BRABBIA",
    "state_code": "VA",
    "cab": "924381",
    "cap": "21020"
  },
  {
    "key": "3820",
    "ITA": "CAZZAGO SAN MARTINO-PEDROCCA",
    "state_code": "BS",
    "cab": "543009",
    "cap": "25046"
  },
  {
    "key": "3821",
    "ITA": "CAZZAGO SAN MARTINO-CALINO",
    "state_code": "BS",
    "cab": "543009",
    "cap": "25046"
  },
  {
    "key": "3822",
    "ITA": "CAZZAGO SAN MARTINO",
    "state_code": "BS",
    "cab": "543009",
    "cap": "25046"
  },
  {
    "key": "3823",
    "ITA": "CAZZAGO SAN MARTINO-BORNATO",
    "state_code": "BS",
    "cab": "543009",
    "cap": "25046"
  },
  {
    "key": "3824",
    "ITA": "CAZZANO DI TRAMIGNA",
    "state_code": "VR",
    "cab": "593905",
    "cap": "37030"
  },
  {
    "key": "3825",
    "ITA": "CAZZANO SANT'ANDREA",
    "state_code": "BG",
    "cab": "528505",
    "cap": "24026"
  },
  {
    "key": "3826",
    "ITA": "CECCANO",
    "state_code": "FR",
    "cab": "743906",
    "cap": "3023"
  },
  {
    "key": "3827",
    "ITA": "CECIMA",
    "state_code": "PV",
    "cab": "930370",
    "cap": "27050"
  },
  {
    "key": "3828",
    "ITA": "CECINA-SAN PIETRO IN PALAZZI",
    "state_code": "LI",
    "cab": "706903",
    "cap": "57023"
  },
  {
    "key": "3829",
    "ITA": "CECINA-CECINA MARINA",
    "state_code": "LI",
    "cab": "706903",
    "cap": "57023"
  },
  {
    "key": "3830",
    "ITA": "CECINA",
    "state_code": "LI",
    "cab": "706903",
    "cap": "57023"
  },
  {
    "key": "3831",
    "ITA": "CEDEGOLO",
    "state_code": "BS",
    "cab": "543108",
    "cap": "25051"
  },
  {
    "key": "3832",
    "ITA": "CEDRASCO",
    "state_code": "SO",
    "cab": "927004",
    "cap": "23010"
  },
  {
    "key": "3833",
    "ITA": "CEFALA' DIANA",
    "state_code": "PA",
    "cab": "437707",
    "cap": "90030"
  },
  {
    "key": "3834",
    "ITA": "CEFALU'-SANT'AMBROGIO",
    "state_code": "PA",
    "cab": "432609",
    "cap": "90015"
  },
  {
    "key": "3835",
    "ITA": "CEFALU'-GIBILMANNA",
    "state_code": "PA",
    "cab": "432609",
    "cap": "90015"
  },
  {
    "key": "3836",
    "ITA": "CEFALU'",
    "state_code": "PA",
    "cab": "432609",
    "cap": "90015"
  },
  {
    "key": "3837",
    "ITA": "CEGGIA",
    "state_code": "VE",
    "cab": "360404",
    "cap": "30022"
  },
  {
    "key": "3838",
    "ITA": "CEGLIE MESSAPICA",
    "state_code": "BR",
    "cab": "791509",
    "cap": "72013"
  },
  {
    "key": "3839",
    "ITA": "CELANO",
    "state_code": "AQ",
    "cab": "405407",
    "cap": "67043"
  },
  {
    "key": "3840",
    "ITA": "CELENZA SUL TRIGNO",
    "state_code": "CH",
    "cab": "776302",
    "cap": "66050"
  },
  {
    "key": "3841",
    "ITA": "CELENZA VALFORTORE",
    "state_code": "FG",
    "cab": "783704",
    "cap": "71035"
  },
  {
    "key": "3842",
    "ITA": "CELICO-FAGO DEL SOLDATO",
    "state_code": "CS",
    "cab": "958736",
    "cap": "87052"
  },
  {
    "key": "3843",
    "ITA": "CELICO",
    "state_code": "CS",
    "cab": "958736",
    "cap": "87053"
  },
  {
    "key": "3844",
    "ITA": "CELLA DATI",
    "state_code": "CR",
    "cab": "660605",
    "cap": "26040"
  },
  {
    "key": "3845",
    "ITA": "CELLA MONTE",
    "state_code": "AL",
    "cab": "482000",
    "cap": "15034"
  },
  {
    "key": "3846",
    "ITA": "CELLAMARE",
    "state_code": "BA",
    "cab": "414508",
    "cap": "70010"
  },
  {
    "key": "3847",
    "ITA": "CELLARA",
    "state_code": "CS",
    "cab": "958744",
    "cap": "87050"
  },
  {
    "key": "3848",
    "ITA": "CELLARENGO",
    "state_code": "AT",
    "cab": "920041",
    "cap": "14010"
  },
  {
    "key": "3849",
    "ITA": "CELLATICA",
    "state_code": "BS",
    "cab": "543207",
    "cap": "25060"
  },
  {
    "key": "3850",
    "ITA": "CELLATICA-FANTASINA",
    "state_code": "BS",
    "cab": "543207",
    "cap": "25060"
  },
  {
    "key": "3851",
    "ITA": "CELLE DI BULGHERIA",
    "state_code": "SA",
    "cab": "952416",
    "cap": "84040"
  },
  {
    "key": "3852",
    "ITA": "CELLE DI BULGHERIA-PODERIA",
    "state_code": "SA",
    "cab": "952416",
    "cap": "84040"
  },
  {
    "key": "3853",
    "ITA": "CELLE DI MACRA",
    "state_code": "CN",
    "cab": "918565",
    "cap": "12020"
  },
  {
    "key": "3854",
    "ITA": "CELLE DI SAN VITO",
    "state_code": "FG",
    "cab": "956367",
    "cap": "71020"
  },
  {
    "key": "3855",
    "ITA": "CELLE ENOMONDO",
    "state_code": "AT",
    "cab": "920058",
    "cap": "14010"
  },
  {
    "key": "3856",
    "ITA": "CELLE LIGURE",
    "state_code": "SV",
    "cab": "493601",
    "cap": "17015"
  },
  {
    "key": "3857",
    "ITA": "CELLENO",
    "state_code": "VT",
    "cab": "730101",
    "cap": "1020"
  },
  {
    "key": "3858",
    "ITA": "CELLERE",
    "state_code": "VT",
    "cab": "730200",
    "cap": "1010"
  },
  {
    "key": "3859",
    "ITA": "CELLINO ATTANASIO",
    "state_code": "TE",
    "cab": "768408",
    "cap": "64036"
  },
  {
    "key": "3860",
    "ITA": "CELLINO ATTANASIO-SCORRANO",
    "state_code": "TE",
    "cab": "768408",
    "cap": "64036"
  },
  {
    "key": "3861",
    "ITA": "CELLINO SAN MARCO",
    "state_code": "BR",
    "cab": "791608",
    "cap": "72020"
  },
  {
    "key": "3862",
    "ITA": "CELLIO",
    "state_code": "VC",
    "cab": "915793",
    "cap": "13024"
  },
  {
    "key": "3863",
    "ITA": "CELLOLE",
    "state_code": "CE",
    "cab": "751404",
    "cap": "81030"
  },
  {
    "key": "3864",
    "ITA": "CELLOLE-BAIA DOMIZIA",
    "state_code": "CE",
    "cab": "751404",
    "cap": "81030"
  },
  {
    "key": "3865",
    "ITA": "CEMBRA",
    "state_code": "TN",
    "cab": "346304",
    "cap": "38034"
  },
  {
    "key": "3866",
    "ITA": "CENADI",
    "state_code": "CZ",
    "cab": "912766",
    "cap": "88067"
  },
  {
    "key": "3867",
    "ITA": "CENATE SOPRA-CASCO",
    "state_code": "BG",
    "cab": "832600",
    "cap": "24060"
  },
  {
    "key": "3868",
    "ITA": "CENATE SOPRA",
    "state_code": "BG",
    "cab": "832600",
    "cap": "24060"
  },
  {
    "key": "3869",
    "ITA": "CENATE SOTTO",
    "state_code": "BG",
    "cab": "318105",
    "cap": "24069"
  },
  {
    "key": "3870",
    "ITA": "CENATE SOTTO-CENATE DI SOTTO",
    "state_code": "BG",
    "cab": "318105",
    "cap": "24069"
  },
  {
    "key": "3871",
    "ITA": "CENCENIGHE AGORDINO",
    "state_code": "BL",
    "cab": "614008",
    "cap": "32020"
  },
  {
    "key": "3872",
    "ITA": "CENE",
    "state_code": "BG",
    "cab": "528604",
    "cap": "24020"
  },
  {
    "key": "3873",
    "ITA": "CENESELLI",
    "state_code": "RO",
    "cab": "632208",
    "cap": "45030"
  },
  {
    "key": "3874",
    "ITA": "CENGIO",
    "state_code": "SV",
    "cab": "493700",
    "cap": "17056"
  },
  {
    "key": "3875",
    "ITA": "CENTA SAN NICOLO'",
    "state_code": "TN",
    "cab": "904755",
    "cap": "38040"
  },
  {
    "key": "3876",
    "ITA": "CENTALLO-SAN BIAGIO",
    "state_code": "CN",
    "cab": "461608",
    "cap": "12044"
  },
  {
    "key": "3877",
    "ITA": "CENTALLO-ROATA CHIUSANI",
    "state_code": "CN",
    "cab": "461608",
    "cap": "12044"
  },
  {
    "key": "3878",
    "ITA": "CENTALLO",
    "state_code": "CN",
    "cab": "461608",
    "cap": "12044"
  },
  {
    "key": "3879",
    "ITA": "CENTO-BUONACOMPRA",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44041"
  },
  {
    "key": "3880",
    "ITA": "CENTO-ALBERONE",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44042"
  },
  {
    "key": "3881",
    "ITA": "CENTO",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44042"
  },
  {
    "key": "3882",
    "ITA": "CENTO-CASUMARO",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44041"
  },
  {
    "key": "3883",
    "ITA": "CENTO-RENO CENTESE",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44041"
  },
  {
    "key": "3884",
    "ITA": "CENTO-RENAZZO",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44045"
  },
  {
    "key": "3885",
    "ITA": "CENTO-PENZALE",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44042"
  },
  {
    "key": "3886",
    "ITA": "CENTO-DODICI MORELLI",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44045"
  },
  {
    "key": "3887",
    "ITA": "CENTO-CORPO RENO",
    "state_code": "FE",
    "cab": "234005",
    "cap": "44042"
  },
  {
    "key": "3888",
    "ITA": "CENTOLA",
    "state_code": "SA",
    "cab": "761809",
    "cap": "84051"
  },
  {
    "key": "3889",
    "ITA": "CENTOLA-SAN NICOLA",
    "state_code": "SA",
    "cab": "761809",
    "cap": "84051"
  },
  {
    "key": "3890",
    "ITA": "CENTOLA-SAN SEVERINO",
    "state_code": "SA",
    "cab": "761809",
    "cap": "84051"
  },
  {
    "key": "3891",
    "ITA": "CENTOLA-PALINURO",
    "state_code": "SA",
    "cab": "761809",
    "cap": "84051"
  },
  {
    "key": "3892",
    "ITA": "CENTOLA-FORIA",
    "state_code": "SA",
    "cab": "761809",
    "cap": "84051"
  },
  {
    "key": "3893",
    "ITA": "CENTRACHE",
    "state_code": "CZ",
    "cab": "912774",
    "cap": "88067"
  },
  {
    "key": "3894",
    "ITA": "CENTURIPE",
    "state_code": "EN",
    "cab": "836304",
    "cap": "94010"
  },
  {
    "key": "3895",
    "ITA": "CEPAGATTI-VALLEMARE",
    "state_code": "PE",
    "cab": "772400",
    "cap": "65012"
  },
  {
    "key": "3896",
    "ITA": "CEPAGATTI",
    "state_code": "PE",
    "cab": "772400",
    "cap": "65012"
  },
  {
    "key": "3897",
    "ITA": "CEPAGATTI-VILLAREIA",
    "state_code": "PE",
    "cab": "772400",
    "cap": "65012"
  },
  {
    "key": "3898",
    "ITA": "CEPAGATTI-VILLANOVA",
    "state_code": "PE",
    "cab": "772400",
    "cap": "65012"
  },
  {
    "key": "3899",
    "ITA": "CEPPALONI-RIPABIANCA",
    "state_code": "BN",
    "cab": "755504",
    "cap": "82010"
  },
  {
    "key": "3900",
    "ITA": "CEPPALONI-BELTIGLIO",
    "state_code": "BN",
    "cab": "755504",
    "cap": "82010"
  },
  {
    "key": "3901",
    "ITA": "CEPPALONI",
    "state_code": "BN",
    "cab": "755504",
    "cap": "82010"
  },
  {
    "key": "3902",
    "ITA": "CEPPALONI-SAN GIOVANNI DI CEPPALONI",
    "state_code": "BN",
    "cab": "755504",
    "cap": "82010"
  },
  {
    "key": "3903",
    "ITA": "CEPPO MORELLI",
    "state_code": "VB",
    "cab": "917146",
    "cap": "28875"
  },
  {
    "key": "3904",
    "ITA": "CEPRANO",
    "state_code": "FR",
    "cab": "744003",
    "cap": "3024"
  },
  {
    "key": "3905",
    "ITA": "CERAMI",
    "state_code": "EN",
    "cab": "836403",
    "cap": "94010"
  },
  {
    "key": "3906",
    "ITA": "CERANESI-GEO",
    "state_code": "GE",
    "cab": "837500",
    "cap": "16014"
  },
  {
    "key": "3907",
    "ITA": "CERANESI",
    "state_code": "GE",
    "cab": "837500",
    "cap": "16014"
  },
  {
    "key": "3908",
    "ITA": "CERANESI-FERRIERA",
    "state_code": "GE",
    "cab": "837500",
    "cap": "16014"
  },
  {
    "key": "3909",
    "ITA": "CERANESI-GUARDIA",
    "state_code": "GE",
    "cab": "837500",
    "cap": "16014"
  },
  {
    "key": "3910",
    "ITA": "CERANESI-PONTASSO",
    "state_code": "GE",
    "cab": "837500",
    "cap": "16014"
  },
  {
    "key": "3911",
    "ITA": "CERANO",
    "state_code": "NO",
    "cab": "453407",
    "cap": "28065"
  },
  {
    "key": "3912",
    "ITA": "CERANO D'INTELVI",
    "state_code": "CO",
    "cab": "925388",
    "cap": "22020"
  },
  {
    "key": "3913",
    "ITA": "CERANOVA",
    "state_code": "PV",
    "cab": "930388",
    "cap": "27010"
  },
  {
    "key": "3914",
    "ITA": "CERASO",
    "state_code": "SA",
    "cab": "896308",
    "cap": "84052"
  },
  {
    "key": "3915",
    "ITA": "CERASO-MASSASCUSA",
    "state_code": "SA",
    "cab": "896308",
    "cap": "84052"
  },
  {
    "key": "3916",
    "ITA": "CERASO-SAN BIASE",
    "state_code": "SA",
    "cab": "896308",
    "cap": "84052"
  },
  {
    "key": "3917",
    "ITA": "CERASO-SAN SUMINO",
    "state_code": "SA",
    "cab": "896308",
    "cap": "84052"
  },
  {
    "key": "3918",
    "ITA": "CERASO-SANTA BARBARA",
    "state_code": "SA",
    "cab": "896308",
    "cap": "84052"
  },
  {
    "key": "3919",
    "ITA": "CERCEMAGGIORE",
    "state_code": "CB",
    "cab": "410209",
    "cap": "86012"
  },
  {
    "key": "3920",
    "ITA": "CERCENASCO",
    "state_code": "TO",
    "cab": "580902",
    "cap": "10060"
  },
  {
    "key": "3921",
    "ITA": "CERCEPICCOLA",
    "state_code": "CB",
    "cab": "910505",
    "cap": "86010"
  },
  {
    "key": "3922",
    "ITA": "CERCHIARA DI CALABRIA-PIANA DI CERCHIARA",
    "state_code": "CS",
    "cab": "888107",
    "cap": "87070"
  },
  {
    "key": "3923",
    "ITA": "CERCHIARA DI CALABRIA",
    "state_code": "CS",
    "cab": "888107",
    "cap": "87070"
  },
  {
    "key": "3924",
    "ITA": "CERCHIO",
    "state_code": "AQ",
    "cab": "408302",
    "cap": "67044"
  },
  {
    "key": "3925",
    "ITA": "CERCINO",
    "state_code": "SO",
    "cab": "927012",
    "cap": "23016"
  },
  {
    "key": "3926",
    "ITA": "CERCIVENTO",
    "state_code": "UD",
    "cab": "936880",
    "cap": "33020"
  },
  {
    "key": "3927",
    "ITA": "CERCOLA-CARAVITA",
    "state_code": "NA",
    "cab": "398503",
    "cap": "80040"
  },
  {
    "key": "3928",
    "ITA": "CERCOLA",
    "state_code": "NA",
    "cab": "398503",
    "cap": "80040"
  },
  {
    "key": "3929",
    "ITA": "CERDA",
    "state_code": "PA",
    "cab": "432708",
    "cap": "90010"
  },
  {
    "key": "3930",
    "ITA": "CEREA-ASPARETTO",
    "state_code": "VR",
    "cab": "594002",
    "cap": "37053"
  },
  {
    "key": "3931",
    "ITA": "CEREA",
    "state_code": "VR",
    "cab": "594002",
    "cap": "37053"
  },
  {
    "key": "3932",
    "ITA": "CEREA-CHERUBINE",
    "state_code": "VR",
    "cab": "594002",
    "cap": "37053"
  },
  {
    "key": "3933",
    "ITA": "CEREGNANO-LAMA PEZZOLI",
    "state_code": "RO",
    "cab": "632307",
    "cap": "45010"
  },
  {
    "key": "3934",
    "ITA": "CEREGNANO",
    "state_code": "RO",
    "cab": "632307",
    "cap": "45010"
  },
  {
    "key": "3935",
    "ITA": "CEREGNANO-CANALE",
    "state_code": "RO",
    "cab": "632307",
    "cap": "45010"
  },
  {
    "key": "3936",
    "ITA": "CEREGNANO-LAMA POLESINE",
    "state_code": "RO",
    "cab": "632307",
    "cap": "45010"
  },
  {
    "key": "3937",
    "ITA": "CERENZIA",
    "state_code": "KR",
    "cab": "912782",
    "cap": "88833"
  },
  {
    "key": "3938",
    "ITA": "CERES",
    "state_code": "TO",
    "cab": "303305",
    "cap": "10070"
  },
  {
    "key": "3939",
    "ITA": "CERES-PROCARIA",
    "state_code": "TO",
    "cab": "303305",
    "cap": "10070"
  },
  {
    "key": "3940",
    "ITA": "CERESARA",
    "state_code": "MN",
    "cab": "575902",
    "cap": "46040"
  },
  {
    "key": "3941",
    "ITA": "CERESETO",
    "state_code": "AL",
    "cab": "482109",
    "cap": "15020"
  },
  {
    "key": "3942",
    "ITA": "CERESOLE ALBA",
    "state_code": "CN",
    "cab": "461707",
    "cap": "12040"
  },
  {
    "key": "3943",
    "ITA": "CERESOLE REALE",
    "state_code": "TO",
    "cab": "283317",
    "cap": "10080"
  },
  {
    "key": "3944",
    "ITA": "CERETE",
    "state_code": "BG",
    "cab": "928002",
    "cap": "24020"
  },
  {
    "key": "3945",
    "ITA": "CERETE-CERETE BASSO",
    "state_code": "BG",
    "cab": "928002",
    "cap": "24020"
  },
  {
    "key": "3946",
    "ITA": "CERETTO LOMELLINA",
    "state_code": "PV",
    "cab": "930396",
    "cap": "27030"
  },
  {
    "key": "3947",
    "ITA": "CERGNAGO",
    "state_code": "PV",
    "cab": "930404",
    "cap": "27020"
  },
  {
    "key": "3948",
    "ITA": "CERIALE",
    "state_code": "SV",
    "cab": "493809",
    "cap": "17023"
  },
  {
    "key": "3949",
    "ITA": "CERIANA",
    "state_code": "IM",
    "cab": "489708",
    "cap": "18034"
  },
  {
    "key": "3950",
    "ITA": "CERIANO LAGHETTO",
    "state_code": "MB",
    "cab": "328708",
    "cap": "20020"
  },
  {
    "key": "3951",
    "ITA": "CERIANO LAGHETTO-DAL POZZO",
    "state_code": "MB",
    "cab": "328708",
    "cap": "20020"
  },
  {
    "key": "3952",
    "ITA": "CERIGNALE-PONTE ORGANASCO",
    "state_code": "PC",
    "cab": "938621",
    "cap": "29020"
  },
  {
    "key": "3953",
    "ITA": "CERIGNALE",
    "state_code": "PC",
    "cab": "938621",
    "cap": "29020"
  },
  {
    "key": "3954",
    "ITA": "CERIGNOLA-TRESSANTI",
    "state_code": "FG",
    "cab": "783803",
    "cap": "71042"
  },
  {
    "key": "3955",
    "ITA": "CERIGNOLA-MOSCHELLA",
    "state_code": "FG",
    "cab": "783803",
    "cap": "71042"
  },
  {
    "key": "3956",
    "ITA": "CERIGNOLA",
    "state_code": "FG",
    "cab": "783803",
    "cap": "71042"
  },
  {
    "key": "3957",
    "ITA": "CERIGNOLA-BORGO LIBERTA'",
    "state_code": "FG",
    "cab": "783803",
    "cap": "71040"
  },
  {
    "key": "3958",
    "ITA": "CERISANO",
    "state_code": "CS",
    "cab": "958769",
    "cap": "87044"
  },
  {
    "key": "3959",
    "ITA": "CERMENATE",
    "state_code": "CO",
    "cab": "511501",
    "cap": "22072"
  },
  {
    "key": "3960",
    "ITA": "CERMES",
    "state_code": "BZ",
    "cab": "582908",
    "cap": "39010"
  },
  {
    "key": "3961",
    "ITA": "CERMIGNANO",
    "state_code": "TE",
    "cab": "771204",
    "cap": "64037"
  },
  {
    "key": "3962",
    "ITA": "CERMIGNANO-POGGIO DELLE ROSE",
    "state_code": "TE",
    "cab": "771204",
    "cap": "64037"
  },
  {
    "key": "3963",
    "ITA": "CERMIGNANO-MONTEGUALTIERI",
    "state_code": "TE",
    "cab": "771204",
    "cap": "64037"
  },
  {
    "key": "3964",
    "ITA": "CERNOBBIO",
    "state_code": "CO",
    "cab": "511600",
    "cap": "22012"
  },
  {
    "key": "3965",
    "ITA": "CERNUSCO LOMBARDONE",
    "state_code": "LC",
    "cab": "511709",
    "cap": "23870"
  },
  {
    "key": "3966",
    "ITA": "CERNUSCO SUL NAVIGLIO",
    "state_code": "MI",
    "cab": "328807",
    "cap": "20063"
  },
  {
    "key": "3967",
    "ITA": "CERRETO CASTELLO",
    "state_code": "BI",
    "cab": "915801",
    "cap": "13852"
  },
  {
    "key": "3968",
    "ITA": "CERRETO D'ASTI",
    "state_code": "AT",
    "cab": "920066",
    "cap": "14020"
  },
  {
    "key": "3969",
    "ITA": "CERRETO D'ESI",
    "state_code": "AN",
    "cab": "373100",
    "cap": "60043"
  },
  {
    "key": "3970",
    "ITA": "CERRETO DI SPOLETO-BORGO CERRETO",
    "state_code": "PG",
    "cab": "383604",
    "cap": "6041"
  },
  {
    "key": "3971",
    "ITA": "CERRETO DI SPOLETO",
    "state_code": "PG",
    "cab": "383604",
    "cap": "6041"
  },
  {
    "key": "3972",
    "ITA": "CERRETO DI SPOLETO-TRIPONZO",
    "state_code": "PG",
    "cab": "383604",
    "cap": "6041"
  },
  {
    "key": "3973",
    "ITA": "CERRETO DI SPOLETO-BUGIANO",
    "state_code": "PG",
    "cab": "383604",
    "cap": "6041"
  },
  {
    "key": "3974",
    "ITA": "CERRETO GRUE",
    "state_code": "AL",
    "cab": "921072",
    "cap": "15050"
  },
  {
    "key": "3975",
    "ITA": "CERRETO GUIDI",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3976",
    "ITA": "CERRETO GUIDI-BASSA",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3977",
    "ITA": "CERRETO GUIDI-GAVENA",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3978",
    "ITA": "CERRETO GUIDI-LAZZERETTO",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3979",
    "ITA": "CERRETO GUIDI-PIEVE A RIPOLI",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3980",
    "ITA": "CERRETO GUIDI-PONTE DI MASINO",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3981",
    "ITA": "CERRETO GUIDI-SAN ZIO",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3982",
    "ITA": "CERRETO GUIDI-STABBIA",
    "state_code": "FI",
    "cab": "378000",
    "cap": "50050"
  },
  {
    "key": "3983",
    "ITA": "CERRETO LANGHE",
    "state_code": "CN",
    "cab": "918573",
    "cap": "12050"
  },
  {
    "key": "3984",
    "ITA": "CERRETO LAZIALE",
    "state_code": "RM",
    "cab": "908087",
    "cap": "20"
  },
  {
    "key": "3985",
    "ITA": "CERRETO SANNITA",
    "state_code": "BN",
    "cab": "753004",
    "cap": "82032"
  },
  {
    "key": "3986",
    "ITA": "CERRINA MONFERRATO",
    "state_code": "AL",
    "cab": "482208",
    "cap": "15020"
  },
  {
    "key": "3987",
    "ITA": "CERRINA MONFERRATO-MONTALERO",
    "state_code": "AL",
    "cab": "482208",
    "cap": "15020"
  },
  {
    "key": "3988",
    "ITA": "CERRINA MONFERRATO-VALLE CERRINA",
    "state_code": "AL",
    "cab": "482208",
    "cap": "15020"
  },
  {
    "key": "3989",
    "ITA": "CERRIONE-VERGNASCO",
    "state_code": "BI",
    "cab": "444000",
    "cap": "13882"
  },
  {
    "key": "3990",
    "ITA": "CERRIONE-MAGNONEVOLO",
    "state_code": "BI",
    "cab": "444000",
    "cap": "13882"
  },
  {
    "key": "3991",
    "ITA": "CERRIONE",
    "state_code": "BI",
    "cab": "444000",
    "cap": "13882"
  },
  {
    "key": "3992",
    "ITA": "CERRO AL LAMBRO-RIOZZO",
    "state_code": "MI",
    "cab": "322909",
    "cap": "20070"
  },
  {
    "key": "3993",
    "ITA": "CERRO AL LAMBRO",
    "state_code": "MI",
    "cab": "322909",
    "cap": "20070"
  },
  {
    "key": "3994",
    "ITA": "CERRO AL VOLTURNO-CUPONE",
    "state_code": "IS",
    "cab": "955591",
    "cap": "86072"
  },
  {
    "key": "3995",
    "ITA": "CERRO AL VOLTURNO",
    "state_code": "IS",
    "cab": "955591",
    "cap": "86072"
  },
  {
    "key": "3996",
    "ITA": "CERRO MAGGIORE",
    "state_code": "MI",
    "cab": "328906",
    "cap": "20023"
  },
  {
    "key": "3997",
    "ITA": "CERRO MAGGIORE-CANTALUPO",
    "state_code": "MI",
    "cab": "328906",
    "cap": "20023"
  },
  {
    "key": "3998",
    "ITA": "CERRO TANARO",
    "state_code": "AT",
    "cab": "920074",
    "cap": "14030"
  },
  {
    "key": "3999",
    "ITA": "CERRO VERONESE",
    "state_code": "VR",
    "cab": "892208",
    "cap": "37020"
  },
  {
    "key": "4000",
    "ITA": "CERSOSIMO",
    "state_code": "PZ",
    "cab": "911958",
    "cap": "85030"
  },
  {
    "key": "4001",
    "ITA": "CERTALDO-FIANO",
    "state_code": "FI",
    "cab": "378109",
    "cap": "50052"
  },
  {
    "key": "4002",
    "ITA": "CERTALDO",
    "state_code": "FI",
    "cab": "378109",
    "cap": "50052"
  },
  {
    "key": "4003",
    "ITA": "CERTOSA DI PAVIA-TORRIANO",
    "state_code": "PV",
    "cab": "557504",
    "cap": "27012"
  },
  {
    "key": "4004",
    "ITA": "CERTOSA DI PAVIA",
    "state_code": "PV",
    "cab": "557504",
    "cap": "27012"
  },
  {
    "key": "4005",
    "ITA": "CERTOSA DI PAVIA-TORRE DEL MANGANO",
    "state_code": "PV",
    "cab": "557504",
    "cap": "27012"
  },
  {
    "key": "4006",
    "ITA": "CERVA",
    "state_code": "CZ",
    "cab": "267799",
    "cap": "88050"
  },
  {
    "key": "4007",
    "ITA": "CERVARA DI ROMA",
    "state_code": "RM",
    "cab": "908095",
    "cap": "20"
  },
  {
    "key": "4008",
    "ITA": "CERVARESE SANTA CROCE-FOSSONA",
    "state_code": "PD",
    "cab": "631101",
    "cap": "35030"
  },
  {
    "key": "4009",
    "ITA": "CERVARESE SANTA CROCE",
    "state_code": "PD",
    "cab": "631101",
    "cap": "35030"
  },
  {
    "key": "4010",
    "ITA": "CERVARESE SANTA CROCE-MONTEMERLO",
    "state_code": "PD",
    "cab": "631101",
    "cap": "35030"
  },
  {
    "key": "4011",
    "ITA": "CERVARO-PASTENELLE",
    "state_code": "FR",
    "cab": "744102",
    "cap": "3044"
  },
  {
    "key": "4012",
    "ITA": "CERVARO-SANTA LUCIA",
    "state_code": "FR",
    "cab": "744102",
    "cap": "3044"
  },
  {
    "key": "4013",
    "ITA": "CERVARO-PACITTI",
    "state_code": "FR",
    "cab": "744102",
    "cap": "3044"
  },
  {
    "key": "4014",
    "ITA": "CERVARO-SPRUMARO",
    "state_code": "FR",
    "cab": "744102",
    "cap": "3044"
  },
  {
    "key": "4015",
    "ITA": "CERVARO",
    "state_code": "FR",
    "cab": "744102",
    "cap": "3044"
  },
  {
    "key": "4016",
    "ITA": "CERVASCA-SAN DEFENDENTE",
    "state_code": "CN",
    "cab": "470302",
    "cap": "12010"
  },
  {
    "key": "4017",
    "ITA": "CERVASCA",
    "state_code": "CN",
    "cab": "470302",
    "cap": "12010"
  },
  {
    "key": "4018",
    "ITA": "CERVASCA-SANTA CROCE",
    "state_code": "CN",
    "cab": "470302",
    "cap": "12010"
  },
  {
    "key": "4019",
    "ITA": "CERVATTO",
    "state_code": "VC",
    "cab": "915819",
    "cap": "13025"
  },
  {
    "key": "4020",
    "ITA": "CERVENO",
    "state_code": "BS",
    "cab": "929323",
    "cap": "25040"
  },
  {
    "key": "4021",
    "ITA": "CERVERE",
    "state_code": "CN",
    "cab": "461806",
    "cap": "12040"
  },
  {
    "key": "4022",
    "ITA": "CERVESINA",
    "state_code": "PV",
    "cab": "557603",
    "cap": "27050"
  },
  {
    "key": "4023",
    "ITA": "CERVETERI-BORGO SAN MARTINO DI CERVETERI",
    "state_code": "RM",
    "cab": "390302",
    "cap": "50"
  },
  {
    "key": "4024",
    "ITA": "CERVETERI-CERENOVA",
    "state_code": "RM",
    "cab": "390302",
    "cap": "52"
  },
  {
    "key": "4025",
    "ITA": "CERVETERI-CERI",
    "state_code": "RM",
    "cab": "390302",
    "cap": "50"
  },
  {
    "key": "4026",
    "ITA": "CERVETERI-MARINA DI CERVETERI",
    "state_code": "RM",
    "cab": "390302",
    "cap": "52"
  },
  {
    "key": "4027",
    "ITA": "CERVETERI-STAZIONE DI FURBARA",
    "state_code": "RM",
    "cab": "390302",
    "cap": "52"
  },
  {
    "key": "4028",
    "ITA": "CERVETERI",
    "state_code": "RM",
    "cab": "390302",
    "cap": "52"
  },
  {
    "key": "4029",
    "ITA": "CERVIA-PISIGNANO",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4030",
    "ITA": "CERVIA-CASTIGLIONE",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4031",
    "ITA": "CERVIA-CANNUZZO",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4032",
    "ITA": "CERVIA-MADONNA DEGLI ANGELI",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4033",
    "ITA": "CERVIA-PINARELLA",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4034",
    "ITA": "CERVIA-CERVIA MILANO MARITTIMA",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4035",
    "ITA": "CERVIA-VILLA INFERNO",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4036",
    "ITA": "CERVIA-SAVIO",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4037",
    "ITA": "CERVIA",
    "state_code": "RA",
    "cab": "236000",
    "cap": "48015"
  },
  {
    "key": "4038",
    "ITA": "CERVICATI",
    "state_code": "CS",
    "cab": "958777",
    "cap": "87010"
  },
  {
    "key": "4039",
    "ITA": "CERVIGNANO D'ADDA",
    "state_code": "LO",
    "cab": "614305",
    "cap": "26832"
  },
  {
    "key": "4040",
    "ITA": "CERVIGNANO DEL FRIULI-STRASSOLDO",
    "state_code": "UD",
    "cab": "637306",
    "cap": "33052"
  },
  {
    "key": "4041",
    "ITA": "CERVIGNANO DEL FRIULI",
    "state_code": "UD",
    "cab": "637306",
    "cap": "33052"
  },
  {
    "key": "4042",
    "ITA": "CERVINARA",
    "state_code": "AV",
    "cab": "757203",
    "cap": "83012"
  },
  {
    "key": "4043",
    "ITA": "CERVINARA-IOFFREDO",
    "state_code": "AV",
    "cab": "757203",
    "cap": "83012"
  },
  {
    "key": "4044",
    "ITA": "CERVINARA-TRESCINE",
    "state_code": "AV",
    "cab": "757203",
    "cap": "83012"
  },
  {
    "key": "4045",
    "ITA": "CERVINO",
    "state_code": "CE",
    "cab": "751602",
    "cap": "81023"
  },
  {
    "key": "4046",
    "ITA": "CERVINO-MESSERCOLA",
    "state_code": "CE",
    "cab": "751602",
    "cap": "81023"
  },
  {
    "key": "4047",
    "ITA": "CERVINO-FORCHIA DI CERVINO",
    "state_code": "CE",
    "cab": "751602",
    "cap": "81023"
  },
  {
    "key": "4048",
    "ITA": "CERVO",
    "state_code": "IM",
    "cab": "489807",
    "cap": "18010"
  },
  {
    "key": "4049",
    "ITA": "CERZETO-SAN GIACOMO",
    "state_code": "CS",
    "cab": "958785",
    "cap": "87040"
  },
  {
    "key": "4050",
    "ITA": "CERZETO-CAVALLERIZZO",
    "state_code": "CS",
    "cab": "958785",
    "cap": "87040"
  },
  {
    "key": "4051",
    "ITA": "CERZETO",
    "state_code": "CS",
    "cab": "958785",
    "cap": "87040"
  },
  {
    "key": "4052",
    "ITA": "CESA",
    "state_code": "CE",
    "cab": "898106",
    "cap": "81030"
  },
  {
    "key": "4053",
    "ITA": "CESANA BRIANZA",
    "state_code": "LC",
    "cab": "520304",
    "cap": "23861"
  },
  {
    "key": "4054",
    "ITA": "CESANA TORINESE-BOUSSON",
    "state_code": "TO",
    "cab": "303404",
    "cap": "10054"
  },
  {
    "key": "4055",
    "ITA": "CESANA TORINESE",
    "state_code": "TO",
    "cab": "303404",
    "cap": "10054"
  },
  {
    "key": "4056",
    "ITA": "CESANA TORINESE-SOLOMIAC",
    "state_code": "TO",
    "cab": "303404",
    "cap": "10054"
  },
  {
    "key": "4057",
    "ITA": "CESANO BOSCONE",
    "state_code": "MI",
    "cab": "329003",
    "cap": "20090"
  },
  {
    "key": "4058",
    "ITA": "CESANO MADERNO-BINZAGO",
    "state_code": "MB",
    "cab": "329102",
    "cap": "20031"
  },
  {
    "key": "4059",
    "ITA": "CESANO MADERNO-VILLAGGIO SNIA",
    "state_code": "MB",
    "cab": "329102",
    "cap": "20031"
  },
  {
    "key": "4060",
    "ITA": "CESANO MADERNO-CASSINA SAVINA",
    "state_code": "MB",
    "cab": "329102",
    "cap": "20031"
  },
  {
    "key": "4061",
    "ITA": "CESANO MADERNO",
    "state_code": "MB",
    "cab": "329102",
    "cap": "20031"
  },
  {
    "key": "4062",
    "ITA": "CESARA",
    "state_code": "VB",
    "cab": "917153",
    "cap": "28891"
  },
  {
    "key": "4063",
    "ITA": "CESARO'",
    "state_code": "ME",
    "cab": "821405",
    "cap": "98033"
  },
  {
    "key": "4064",
    "ITA": "CESATE",
    "state_code": "MI",
    "cab": "329201",
    "cap": "20020"
  },
  {
    "key": "4065",
    "ITA": "CESENA",
    "state_code": "FC",
    "cab": "0",
    "cap": "47521"
  },
  {
    "key": "4066",
    "ITA": "CESENA-TORRE DEL MORO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4067",
    "ITA": "CESENA-TESSELLO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4068",
    "ITA": "CESENA-SETTECROCIARI",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4069",
    "ITA": "CESENA-SANT'EGIDIO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4070",
    "ITA": "CESENA-SAN VITTORE",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4071",
    "ITA": "CESENA-SAN GIORGIO DI CESENA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4072",
    "ITA": "CESENA-SAN MAURO IN VALLE",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4073",
    "ITA": "CESENA-SAN CARLO DI CESENA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4074",
    "ITA": "CESENA-SAIANO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4075",
    "ITA": "CESENA-ROVERSANO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4076",
    "ITA": "CESENA-RONTA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4077",
    "ITA": "CESENA-RIO MARANO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4078",
    "ITA": "CESENA-PIEVESESTINA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4079",
    "ITA": "CESENA-MARTORANO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4080",
    "ITA": "CESENA-MADONNA DELL ULIVO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4081",
    "ITA": "CESENA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47522"
  },
  {
    "key": "4082",
    "ITA": "CESENA-AIE",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4083",
    "ITA": "CESENA-BORELLO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4084",
    "ITA": "CESENA-BULGARIA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4085",
    "ITA": "CESENA-CALISESE",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4086",
    "ITA": "CESENA-CASE SCUOLA VECCHIA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4087",
    "ITA": "CESENA-CELLETTA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4088",
    "ITA": "CESENA-CESUOLA",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4089",
    "ITA": "CESENA-DIEGARO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4090",
    "ITA": "CESENA-GATTOLINO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4091",
    "ITA": "CESENA-LIZZANO",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4092",
    "ITA": "CESENA-MACERONE",
    "state_code": "FC",
    "cab": "239004",
    "cap": "47023"
  },
  {
    "key": "4093",
    "ITA": "CESENATICO-SALA",
    "state_code": "FC",
    "cab": "240002",
    "cap": "47042"
  },
  {
    "key": "4094",
    "ITA": "CESENATICO-VILLALTA",
    "state_code": "FC",
    "cab": "240002",
    "cap": "47042"
  },
  {
    "key": "4095",
    "ITA": "CESENATICO-BAGNAROLA",
    "state_code": "FC",
    "cab": "240002",
    "cap": "47042"
  },
  {
    "key": "4096",
    "ITA": "CESENATICO",
    "state_code": "FC",
    "cab": "240002",
    "cap": "47042"
  },
  {
    "key": "4097",
    "ITA": "CESINALI",
    "state_code": "AV",
    "cab": "951053",
    "cap": "83020"
  },
  {
    "key": "4098",
    "ITA": "CESIO",
    "state_code": "IM",
    "cab": "922344",
    "cap": "18022"
  },
  {
    "key": "4099",
    "ITA": "CESIO-CARTARI",
    "state_code": "IM",
    "cab": "922344",
    "cap": "18022"
  },
  {
    "key": "4100",
    "ITA": "CESIO-ARZENO D'ONEGLIA",
    "state_code": "IM",
    "cab": "922344",
    "cap": "18022"
  },
  {
    "key": "4101",
    "ITA": "CESIOMAGGIORE-BUSCHE",
    "state_code": "BL",
    "cab": "610501",
    "cap": "32030"
  },
  {
    "key": "4102",
    "ITA": "CESIOMAGGIORE-SORANZEN",
    "state_code": "BL",
    "cab": "610501",
    "cap": "32030"
  },
  {
    "key": "4103",
    "ITA": "CESIOMAGGIORE",
    "state_code": "BL",
    "cab": "610501",
    "cap": "32030"
  },
  {
    "key": "4104",
    "ITA": "CESSALTO",
    "state_code": "TV",
    "cab": "615906",
    "cap": "31040"
  },
  {
    "key": "4105",
    "ITA": "CESSANITI-FAVELLONI",
    "state_code": "VV",
    "cab": "912808",
    "cap": "89816"
  },
  {
    "key": "4106",
    "ITA": "CESSANITI-SAN CONO",
    "state_code": "VV",
    "cab": "912808",
    "cap": "89816"
  },
  {
    "key": "4107",
    "ITA": "CESSANITI-PANNACONI",
    "state_code": "VV",
    "cab": "912808",
    "cap": "89816"
  },
  {
    "key": "4108",
    "ITA": "CESSANITI",
    "state_code": "VV",
    "cab": "912808",
    "cap": "89816"
  },
  {
    "key": "4109",
    "ITA": "CESSAPALOMBO",
    "state_code": "MC",
    "cab": "941948",
    "cap": "62020"
  },
  {
    "key": "4110",
    "ITA": "CESSOLE",
    "state_code": "AT",
    "cab": "920082",
    "cap": "14050"
  },
  {
    "key": "4111",
    "ITA": "CETARA",
    "state_code": "SA",
    "cab": "765503",
    "cap": "84010"
  },
  {
    "key": "4112",
    "ITA": "CETO",
    "state_code": "BS",
    "cab": "555607",
    "cap": "25040"
  },
  {
    "key": "4113",
    "ITA": "CETO-BADETTO",
    "state_code": "BS",
    "cab": "555607",
    "cap": "25040"
  },
  {
    "key": "4114",
    "ITA": "CETONA-PIAZZE",
    "state_code": "SI",
    "cab": "718205",
    "cap": "53040"
  },
  {
    "key": "4115",
    "ITA": "CETONA",
    "state_code": "SI",
    "cab": "718205",
    "cap": "53040"
  },
  {
    "key": "4116",
    "ITA": "CETRARO-SAN FILIPPO",
    "state_code": "CS",
    "cab": "806802",
    "cap": "87022"
  },
  {
    "key": "4117",
    "ITA": "CETRARO-SANT'ANGELO",
    "state_code": "CS",
    "cab": "806802",
    "cap": "87022"
  },
  {
    "key": "4118",
    "ITA": "CETRARO",
    "state_code": "CS",
    "cab": "806802",
    "cap": "87022"
  },
  {
    "key": "4119",
    "ITA": "CETRARO-BATTENDIERI",
    "state_code": "CS",
    "cab": "806802",
    "cap": "87022"
  },
  {
    "key": "4120",
    "ITA": "CETRARO-CETRARO MARINA",
    "state_code": "CS",
    "cab": "806802",
    "cap": "87022"
  },
  {
    "key": "4121",
    "ITA": "CEVA",
    "state_code": "CN",
    "cab": "461905",
    "cap": "12073"
  },
  {
    "key": "4122",
    "ITA": "CEVO",
    "state_code": "BS",
    "cab": "543306",
    "cap": "25040"
  },
  {
    "key": "4123",
    "ITA": "CHALLAND SAINT ANSELME",
    "state_code": "AO",
    "cab": "902288",
    "cap": "11020"
  },
  {
    "key": "4124",
    "ITA": "CHALLAND SAINT VICTOR",
    "state_code": "AO",
    "cab": "902296",
    "cap": "11020"
  },
  {
    "key": "4125",
    "ITA": "CHAMBAVE",
    "state_code": "AO",
    "cab": "496505",
    "cap": "11023"
  },
  {
    "key": "4126",
    "ITA": "CHAMOIS",
    "state_code": "AO",
    "cab": "902312",
    "cap": "11020"
  },
  {
    "key": "4127",
    "ITA": "CHAMPDEPRAZ",
    "state_code": "AO",
    "cab": "902320",
    "cap": "11020"
  },
  {
    "key": "4128",
    "ITA": "CHAMPORCHER",
    "state_code": "AO",
    "cab": "315309",
    "cap": "11020"
  },
  {
    "key": "4129",
    "ITA": "CHARVENSOD-PONT SUAZ",
    "state_code": "AO",
    "cab": "364406",
    "cap": "11020"
  },
  {
    "key": "4130",
    "ITA": "CHARVENSOD",
    "state_code": "AO",
    "cab": "364406",
    "cap": "11020"
  },
  {
    "key": "4131",
    "ITA": "CHARVENSOD-PEROULAZ",
    "state_code": "AO",
    "cab": "364406",
    "cap": "11020"
  },
  {
    "key": "4132",
    "ITA": "CHARVENSOD-PLAN FELINAZ",
    "state_code": "AO",
    "cab": "364406",
    "cap": "11020"
  },
  {
    "key": "4133",
    "ITA": "CHATILLON-USSEL",
    "state_code": "AO",
    "cab": "315408",
    "cap": "11024"
  },
  {
    "key": "4134",
    "ITA": "CHATILLON",
    "state_code": "AO",
    "cab": "315408",
    "cap": "11024"
  },
  {
    "key": "4135",
    "ITA": "CHERASCO-BRICCO FAVOLE",
    "state_code": "CN",
    "cab": "462002",
    "cap": "12062"
  },
  {
    "key": "4136",
    "ITA": "CHERASCO",
    "state_code": "CN",
    "cab": "462002",
    "cap": "12062"
  },
  {
    "key": "4137",
    "ITA": "CHERASCO-RORETO",
    "state_code": "CN",
    "cab": "462002",
    "cap": "12062"
  },
  {
    "key": "4138",
    "ITA": "CHEREMULE",
    "state_code": "SS",
    "cab": "873000",
    "cap": "7040"
  },
  {
    "key": "4139",
    "ITA": "CHIALAMBERTO",
    "state_code": "TO",
    "cab": "283424",
    "cap": "10070"
  },
  {
    "key": "4140",
    "ITA": "CHIAMPO",
    "state_code": "VI",
    "cab": "602904",
    "cap": "36072"
  },
  {
    "key": "4141",
    "ITA": "CHIANCHE",
    "state_code": "AV",
    "cab": "951061",
    "cap": "83010"
  },
  {
    "key": "4142",
    "ITA": "CHIANCIANO TERME",
    "state_code": "SI",
    "cab": "718304",
    "cap": "53042"
  },
  {
    "key": "4143",
    "ITA": "CHIANNI",
    "state_code": "PI",
    "cab": "709907",
    "cap": "56034"
  },
  {
    "key": "4144",
    "ITA": "CHIANNI-RIVALTO",
    "state_code": "PI",
    "cab": "709907",
    "cap": "56034"
  },
  {
    "key": "4145",
    "ITA": "CHIANOCCO",
    "state_code": "TO",
    "cab": "312900",
    "cap": "10050"
  },
  {
    "key": "4146",
    "ITA": "CHIANOCCO-VERNETTO",
    "state_code": "TO",
    "cab": "312900",
    "cap": "10050"
  },
  {
    "key": "4147",
    "ITA": "CHIANOCCO-ZOIE",
    "state_code": "TO",
    "cab": "312900",
    "cap": "10050"
  },
  {
    "key": "4148",
    "ITA": "CHIARAMONTE GULFI",
    "state_code": "RG",
    "cab": "844407",
    "cap": "97012"
  },
  {
    "key": "4149",
    "ITA": "CHIARAMONTE GULFI-ROCCAZZO",
    "state_code": "RG",
    "cab": "844407",
    "cap": "97010"
  },
  {
    "key": "4150",
    "ITA": "CHIARAMONTI",
    "state_code": "SS",
    "cab": "873109",
    "cap": "7030"
  },
  {
    "key": "4151",
    "ITA": "CHIARANO",
    "state_code": "TV",
    "cab": "616003",
    "cap": "31040"
  },
  {
    "key": "4152",
    "ITA": "CHIARANO-FOSSALTA MAGGIORE",
    "state_code": "TV",
    "cab": "616003",
    "cap": "31040"
  },
  {
    "key": "4153",
    "ITA": "CHIARAVALLE",
    "state_code": "AN",
    "cab": "373209",
    "cap": "60033"
  },
  {
    "key": "4154",
    "ITA": "CHIARAVALLE-GRANCETTA",
    "state_code": "AN",
    "cab": "373209",
    "cap": "60033"
  },
  {
    "key": "4155",
    "ITA": "CHIARAVALLE CENTRALE",
    "state_code": "CZ",
    "cab": "425108",
    "cap": "88064"
  },
  {
    "key": "4156",
    "ITA": "CHIARI",
    "state_code": "BS",
    "cab": "543405",
    "cap": "25032"
  },
  {
    "key": "4157",
    "ITA": "CHIAROMONTE",
    "state_code": "PZ",
    "cab": "419507",
    "cap": "85032"
  },
  {
    "key": "4158",
    "ITA": "CHIAUCI",
    "state_code": "IS",
    "cab": "186536",
    "cap": "86097"
  },
  {
    "key": "4159",
    "ITA": "CHIAVARI-CAPERANA",
    "state_code": "GE",
    "cab": "319509",
    "cap": "16043"
  },
  {
    "key": "4160",
    "ITA": "CHIAVARI",
    "state_code": "GE",
    "cab": "319509",
    "cap": "16043"
  },
  {
    "key": "4161",
    "ITA": "CHIAVARI-SANT'ANDREA DI ROVERETO",
    "state_code": "GE",
    "cab": "319509",
    "cap": "16043"
  },
  {
    "key": "4162",
    "ITA": "CHIAVENNA",
    "state_code": "SO",
    "cab": "521104",
    "cap": "23022"
  },
  {
    "key": "4163",
    "ITA": "CHIAVENNA-BETTE",
    "state_code": "SO",
    "cab": "521104",
    "cap": "23022"
  },
  {
    "key": "4164",
    "ITA": "CHIAVERANO",
    "state_code": "TO",
    "cab": "303503",
    "cap": "10010"
  },
  {
    "key": "4165",
    "ITA": "CHIENES-CASTELDARNE",
    "state_code": "BZ",
    "cab": "583005",
    "cap": "39030"
  },
  {
    "key": "4166",
    "ITA": "CHIENES",
    "state_code": "BZ",
    "cab": "583005",
    "cap": "39030"
  },
  {
    "key": "4167",
    "ITA": "CHIENES-SAN SIGISMONDO",
    "state_code": "BZ",
    "cab": "583005",
    "cap": "39030"
  },
  {
    "key": "4168",
    "ITA": "CHIERI-PESSIONE",
    "state_code": "TO",
    "cab": "303602",
    "cap": "10023"
  },
  {
    "key": "4169",
    "ITA": "CHIERI",
    "state_code": "TO",
    "cab": "303602",
    "cap": "10023"
  },
  {
    "key": "4170",
    "ITA": "CHIES D'ALPAGO-LAMOSANO",
    "state_code": "BL",
    "cab": "738500",
    "cap": "32010"
  },
  {
    "key": "4171",
    "ITA": "CHIES D'ALPAGO",
    "state_code": "BL",
    "cab": "738500",
    "cap": "32010"
  },
  {
    "key": "4172",
    "ITA": "CHIESA IN VALMALENCO-PRIMOLO",
    "state_code": "SO",
    "cab": "521203",
    "cap": "23023"
  },
  {
    "key": "4173",
    "ITA": "CHIESA IN VALMALENCO",
    "state_code": "SO",
    "cab": "521203",
    "cap": "23023"
  },
  {
    "key": "4174",
    "ITA": "CHIESA IN VALMALENCO-CHIAREGGIO",
    "state_code": "SO",
    "cab": "521203",
    "cap": "23023"
  },
  {
    "key": "4175",
    "ITA": "CHIESANUOVA",
    "state_code": "TO",
    "cab": "190009",
    "cap": "10080"
  },
  {
    "key": "4176",
    "ITA": "CHIESANUOVA DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47894"
  },
  {
    "key": "4177",
    "ITA": "CHIESINA UZZANESE-CHIESANUOVA",
    "state_code": "PT",
    "cab": "705400",
    "cap": "51013"
  },
  {
    "key": "4178",
    "ITA": "CHIESINA UZZANESE",
    "state_code": "PT",
    "cab": "705400",
    "cap": "51013"
  },
  {
    "key": "4179",
    "ITA": "CHIETI-TRICALLE",
    "state_code": "CH",
    "cab": "155002",
    "cap": "66100"
  },
  {
    "key": "4180",
    "ITA": "CHIETI",
    "state_code": "CH",
    "cab": "155002",
    "cap": "66100"
  },
  {
    "key": "4181",
    "ITA": "CHIETI-BRECCIAROLA",
    "state_code": "CH",
    "cab": "155002",
    "cap": "66100"
  },
  {
    "key": "4182",
    "ITA": "CHIETI-CHIETI SCALO",
    "state_code": "CH",
    "cab": "155002",
    "cap": "66100"
  },
  {
    "key": "4183",
    "ITA": "CHIEUTI",
    "state_code": "FG",
    "cab": "783902",
    "cap": "71010"
  },
  {
    "key": "4184",
    "ITA": "CHIEUTI-CHIEUTI SCALO",
    "state_code": "FG",
    "cab": "783902",
    "cap": "71010"
  },
  {
    "key": "4185",
    "ITA": "CHIEVE",
    "state_code": "CR",
    "cab": "567800",
    "cap": "26010"
  },
  {
    "key": "4186",
    "ITA": "CHIGNOLO D'ISOLA",
    "state_code": "BG",
    "cab": "742304",
    "cap": "24040"
  },
  {
    "key": "4187",
    "ITA": "CHIGNOLO PO",
    "state_code": "PV",
    "cab": "557702",
    "cap": "27013"
  },
  {
    "key": "4188",
    "ITA": "CHIGNOLO PO-LAMBRINIA",
    "state_code": "PV",
    "cab": "557702",
    "cap": "27013"
  },
  {
    "key": "4189",
    "ITA": "CHIOGGIA",
    "state_code": "VE",
    "cab": "209007",
    "cap": "30015"
  },
  {
    "key": "4190",
    "ITA": "CHIOGGIA-CA' BIANCA",
    "state_code": "VE",
    "cab": "209007",
    "cap": "30015"
  },
  {
    "key": "4191",
    "ITA": "CHIOGGIA-SANT'ANNA",
    "state_code": "VE",
    "cab": "209007",
    "cap": "30015"
  },
  {
    "key": "4192",
    "ITA": "CHIOGGIA-CAVANELLA D'ADIGE",
    "state_code": "VE",
    "cab": "209007",
    "cap": "30015"
  },
  {
    "key": "4193",
    "ITA": "CHIOGGIA-SOTTOMARINA",
    "state_code": "VE",
    "cab": "209007",
    "cap": "30015"
  },
  {
    "key": "4194",
    "ITA": "CHIOGGIA-VALLI",
    "state_code": "VE",
    "cab": "209007",
    "cap": "30015"
  },
  {
    "key": "4195",
    "ITA": "CHIOMONTE",
    "state_code": "TO",
    "cab": "647305",
    "cap": "10050"
  },
  {
    "key": "4196",
    "ITA": "CHIONS-VILLOTTA",
    "state_code": "PN",
    "cab": "648204",
    "cap": "33083"
  },
  {
    "key": "4197",
    "ITA": "CHIONS-TAIEDO",
    "state_code": "PN",
    "cab": "648204",
    "cap": "33083"
  },
  {
    "key": "4198",
    "ITA": "CHIONS",
    "state_code": "PN",
    "cab": "648204",
    "cap": "33083"
  },
  {
    "key": "4199",
    "ITA": "CHIOPRIS VISCONE-CHIOPRIS",
    "state_code": "UD",
    "cab": "899104",
    "cap": "33048"
  },
  {
    "key": "4200",
    "ITA": "CHIOPRIS VISCONE",
    "state_code": "UD",
    "cab": "899104",
    "cap": "33048"
  },
  {
    "key": "4201",
    "ITA": "CHITIGNANO",
    "state_code": "AR",
    "cab": "714303",
    "cap": "52010"
  },
  {
    "key": "4202",
    "ITA": "CHIUDUNO",
    "state_code": "BG",
    "cab": "528703",
    "cap": "24060"
  },
  {
    "key": "4203",
    "ITA": "CHIUPPANO",
    "state_code": "VI",
    "cab": "603001",
    "cap": "36010"
  },
  {
    "key": "4204",
    "ITA": "CHIURO-CASTIONETTO",
    "state_code": "SO",
    "cab": "521302",
    "cap": "23030"
  },
  {
    "key": "4205",
    "ITA": "CHIURO",
    "state_code": "SO",
    "cab": "521302",
    "cap": "23030"
  },
  {
    "key": "4206",
    "ITA": "CHIUSA",
    "state_code": "BZ",
    "cab": "583104",
    "cap": "39043"
  },
  {
    "key": "4207",
    "ITA": "CHIUSA-LAZFONS",
    "state_code": "BZ",
    "cab": "583104",
    "cap": "39043"
  },
  {
    "key": "4208",
    "ITA": "CHIUSA-GUDON",
    "state_code": "BZ",
    "cab": "583104",
    "cap": "39043"
  },
  {
    "key": "4209",
    "ITA": "CHIUSA DI PESIO-SAN BARTOLOMEO",
    "state_code": "CN",
    "cab": "462101",
    "cap": "12013"
  },
  {
    "key": "4210",
    "ITA": "CHIUSA DI PESIO",
    "state_code": "CN",
    "cab": "462101",
    "cap": "12013"
  },
  {
    "key": "4211",
    "ITA": "CHIUSA DI SAN MICHELE",
    "state_code": "TO",
    "cab": "687202",
    "cap": "10050"
  },
  {
    "key": "4212",
    "ITA": "CHIUSA SCLAFANI-SAN CARLO",
    "state_code": "PA",
    "cab": "432807",
    "cap": "90033"
  },
  {
    "key": "4213",
    "ITA": "CHIUSA SCLAFANI",
    "state_code": "PA",
    "cab": "432807",
    "cap": "90033"
  },
  {
    "key": "4214",
    "ITA": "CHIUSAFORTE",
    "state_code": "UD",
    "cab": "644906",
    "cap": "33010"
  },
  {
    "key": "4215",
    "ITA": "CHIUSANICO",
    "state_code": "IM",
    "cab": "922351",
    "cap": "18027"
  },
  {
    "key": "4216",
    "ITA": "CHIUSANO D'ASTI",
    "state_code": "AT",
    "cab": "920090",
    "cap": "14025"
  },
  {
    "key": "4217",
    "ITA": "CHIUSANO DI SAN DOMENICO",
    "state_code": "AV",
    "cab": "951079",
    "cap": "83040"
  },
  {
    "key": "4218",
    "ITA": "CHIUSAVECCHIA",
    "state_code": "IM",
    "cab": "489906",
    "cap": "18027"
  },
  {
    "key": "4219",
    "ITA": "CHIUSDINO",
    "state_code": "SI",
    "cab": "718403",
    "cap": "53012"
  },
  {
    "key": "4220",
    "ITA": "CHIUSDINO-CICIANO",
    "state_code": "SI",
    "cab": "718403",
    "cap": "53012"
  },
  {
    "key": "4221",
    "ITA": "CHIUSDINO-FROSINI",
    "state_code": "SI",
    "cab": "718403",
    "cap": "53012"
  },
  {
    "key": "4222",
    "ITA": "CHIUSDINO-MONTALCINELLO",
    "state_code": "SI",
    "cab": "718403",
    "cap": "53012"
  },
  {
    "key": "4223",
    "ITA": "CHIUSI-CHIUSI SCALO",
    "state_code": "SI",
    "cab": "718502",
    "cap": "53043"
  },
  {
    "key": "4224",
    "ITA": "CHIUSI",
    "state_code": "SI",
    "cab": "718502",
    "cap": "53043"
  },
  {
    "key": "4225",
    "ITA": "CHIUSI-MONTALLESE",
    "state_code": "SI",
    "cab": "718502",
    "cap": "53043"
  },
  {
    "key": "4226",
    "ITA": "CHIUSI DELLA VERNA-LA VERNA",
    "state_code": "AR",
    "cab": "714402",
    "cap": "52010"
  },
  {
    "key": "4227",
    "ITA": "CHIUSI DELLA VERNA-CORSALONE",
    "state_code": "AR",
    "cab": "714402",
    "cap": "52010"
  },
  {
    "key": "4228",
    "ITA": "CHIUSI DELLA VERNA-BIFORCO",
    "state_code": "AR",
    "cab": "714402",
    "cap": "52010"
  },
  {
    "key": "4229",
    "ITA": "CHIUSI DELLA VERNA",
    "state_code": "AR",
    "cab": "714402",
    "cap": "52010"
  },
  {
    "key": "4230",
    "ITA": "CHIVASSO-BOSCHETTO",
    "state_code": "TO",
    "cab": "303701",
    "cap": "10034"
  },
  {
    "key": "4231",
    "ITA": "CHIVASSO-CASTELROSSO",
    "state_code": "TO",
    "cab": "303701",
    "cap": "10034"
  },
  {
    "key": "4232",
    "ITA": "CHIVASSO-TORASSI",
    "state_code": "TO",
    "cab": "303701",
    "cap": "10034"
  },
  {
    "key": "4233",
    "ITA": "CHIVASSO",
    "state_code": "TO",
    "cab": "303701",
    "cap": "10034"
  },
  {
    "key": "4234",
    "ITA": "CIAMPINO-CASABIANCA",
    "state_code": "RM",
    "cab": "395509",
    "cap": "43"
  },
  {
    "key": "4235",
    "ITA": "CIAMPINO",
    "state_code": "RM",
    "cab": "395509",
    "cap": "43"
  },
  {
    "key": "4236",
    "ITA": "CIAMPINO-CIAMPINO AEROPORTO",
    "state_code": "RM",
    "cab": "395509",
    "cap": "43"
  },
  {
    "key": "4237",
    "ITA": "CIANCIANA",
    "state_code": "AG",
    "cab": "829200",
    "cap": "92012"
  },
  {
    "key": "4238",
    "ITA": "CIBIANA DI CADORE",
    "state_code": "BL",
    "cab": "717207",
    "cap": "32040"
  },
  {
    "key": "4239",
    "ITA": "CIBIANA DI CADORE-MASARIE'",
    "state_code": "BL",
    "cab": "717207",
    "cap": "32040"
  },
  {
    "key": "4240",
    "ITA": "CICAGNA-MONLEONE",
    "state_code": "GE",
    "cab": "319608",
    "cap": "16044"
  },
  {
    "key": "4241",
    "ITA": "CICAGNA",
    "state_code": "GE",
    "cab": "319608",
    "cap": "16044"
  },
  {
    "key": "4242",
    "ITA": "CICALA",
    "state_code": "CZ",
    "cab": "912816",
    "cap": "88040"
  },
  {
    "key": "4243",
    "ITA": "CICCIANO",
    "state_code": "NA",
    "cab": "398602",
    "cap": "80033"
  },
  {
    "key": "4244",
    "ITA": "CICERALE-MONTE CICERALE",
    "state_code": "SA",
    "cab": "952440",
    "cap": "84053"
  },
  {
    "key": "4245",
    "ITA": "CICERALE",
    "state_code": "SA",
    "cab": "952440",
    "cap": "84053"
  },
  {
    "key": "4246",
    "ITA": "CICILIANO",
    "state_code": "RM",
    "cab": "908103",
    "cap": "20"
  },
  {
    "key": "4247",
    "ITA": "CICOGNOLO",
    "state_code": "CR",
    "cab": "567909",
    "cap": "26030"
  },
  {
    "key": "4248",
    "ITA": "CICONIO",
    "state_code": "TO",
    "cab": "190090",
    "cap": "10080"
  },
  {
    "key": "4249",
    "ITA": "CIGLIANO",
    "state_code": "VC",
    "cab": "444109",
    "cap": "13043"
  },
  {
    "key": "4250",
    "ITA": "CIGLIE'",
    "state_code": "CN",
    "cab": "918599",
    "cap": "12060"
  },
  {
    "key": "4251",
    "ITA": "CIGOGNOLA-VALLESCUROPASSO",
    "state_code": "PV",
    "cab": "930412",
    "cap": "27040"
  },
  {
    "key": "4252",
    "ITA": "CIGOGNOLA",
    "state_code": "PV",
    "cab": "930412",
    "cap": "27040"
  },
  {
    "key": "4253",
    "ITA": "CIGOLE",
    "state_code": "BS",
    "cab": "555508",
    "cap": "25020"
  },
  {
    "key": "4254",
    "ITA": "CILAVEGNA",
    "state_code": "PV",
    "cab": "557801",
    "cap": "27024"
  },
  {
    "key": "4255",
    "ITA": "CIMADOLMO-SAN MICHELE DI PIAVE",
    "state_code": "TV",
    "cab": "622308",
    "cap": "31010"
  },
  {
    "key": "4256",
    "ITA": "CIMADOLMO",
    "state_code": "TV",
    "cab": "622308",
    "cap": "31010"
  },
  {
    "key": "4257",
    "ITA": "CIMBERGO",
    "state_code": "BS",
    "cab": "929356",
    "cap": "25050"
  },
  {
    "key": "4258",
    "ITA": "CIMEGO",
    "state_code": "TN",
    "cab": "346403",
    "cap": "38082"
  },
  {
    "key": "4259",
    "ITA": "CIMINA'",
    "state_code": "RC",
    "cab": "960088",
    "cap": "89040"
  },
  {
    "key": "4260",
    "ITA": "CIMINNA",
    "state_code": "PA",
    "cab": "432906",
    "cap": "90023"
  },
  {
    "key": "4261",
    "ITA": "CIMITILE",
    "state_code": "NA",
    "cab": "398701",
    "cap": "80030"
  },
  {
    "key": "4262",
    "ITA": "CIMOLAIS",
    "state_code": "PN",
    "cab": "938175",
    "cap": "33080"
  },
  {
    "key": "4263",
    "ITA": "CIMONE",
    "state_code": "TN",
    "cab": "346502",
    "cap": "38060"
  },
  {
    "key": "4264",
    "ITA": "CINAGLIO",
    "state_code": "AT",
    "cab": "920108",
    "cap": "14020"
  },
  {
    "key": "4265",
    "ITA": "CINETO ROMANO",
    "state_code": "RM",
    "cab": "908111",
    "cap": "20"
  },
  {
    "key": "4266",
    "ITA": "CINGIA DE' BOTTI",
    "state_code": "CR",
    "cab": "568006",
    "cap": "26042"
  },
  {
    "key": "4267",
    "ITA": "CINGOLI",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4268",
    "ITA": "CINGOLI-GROTTACCIA",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4269",
    "ITA": "CINGOLI-MOSCOSI",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4270",
    "ITA": "CINGOLI-TORRE",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4271",
    "ITA": "CINGOLI-STRADA",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4272",
    "ITA": "CINGOLI-AVENALE",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4273",
    "ITA": "CINGOLI-SAN VITTORE",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4274",
    "ITA": "CINGOLI-TROVIGGIANO",
    "state_code": "MC",
    "cab": "688606",
    "cap": "62011"
  },
  {
    "key": "4275",
    "ITA": "CINIGIANO-SASSO D'OMBRONE",
    "state_code": "GR",
    "cab": "722207",
    "cap": "58044"
  },
  {
    "key": "4276",
    "ITA": "CINIGIANO-MONTICELLO AMIATA",
    "state_code": "GR",
    "cab": "722207",
    "cap": "58044"
  },
  {
    "key": "4277",
    "ITA": "CINIGIANO-CASTIGLIONCELLO BANDINI",
    "state_code": "GR",
    "cab": "722207",
    "cap": "58044"
  },
  {
    "key": "4278",
    "ITA": "CINIGIANO",
    "state_code": "GR",
    "cab": "722207",
    "cap": "58044"
  },
  {
    "key": "4279",
    "ITA": "CINISELLO BALSAMO",
    "state_code": "MI",
    "cab": "329300",
    "cap": "20092"
  },
  {
    "key": "4280",
    "ITA": "CINISI",
    "state_code": "PA",
    "cab": "433003",
    "cap": "90045"
  },
  {
    "key": "4281",
    "ITA": "CINISI-PUNTA RAISI AEROPORTO",
    "state_code": "PA",
    "cab": "433003",
    "cap": "90045"
  },
  {
    "key": "4282",
    "ITA": "CINO",
    "state_code": "SO",
    "cab": "927020",
    "cap": "23010"
  },
  {
    "key": "4283",
    "ITA": "CINQUEFRONDI",
    "state_code": "RC",
    "cab": "813402",
    "cap": "89021"
  },
  {
    "key": "4284",
    "ITA": "CINTANO",
    "state_code": "TO",
    "cab": "283457",
    "cap": "10080"
  },
  {
    "key": "4285",
    "ITA": "CINTE TESINO",
    "state_code": "TN",
    "cab": "358804",
    "cap": "38050"
  },
  {
    "key": "4286",
    "ITA": "CINTO CAOMAGGIORE",
    "state_code": "VE",
    "cab": "360503",
    "cap": "30020"
  },
  {
    "key": "4287",
    "ITA": "CINTO EUGANEO",
    "state_code": "PD",
    "cab": "935809",
    "cap": "35030"
  },
  {
    "key": "4288",
    "ITA": "CINZANO",
    "state_code": "TO",
    "cab": "284711",
    "cap": "10090"
  },
  {
    "key": "4289",
    "ITA": "CIORLANO-TORCINO",
    "state_code": "CE",
    "cab": "949263",
    "cap": "81010"
  },
  {
    "key": "4290",
    "ITA": "CIORLANO",
    "state_code": "CE",
    "cab": "949263",
    "cap": "81010"
  },
  {
    "key": "4291",
    "ITA": "CIPRESSA-LINGUEGLIETTA",
    "state_code": "IM",
    "cab": "922369",
    "cap": "18017"
  },
  {
    "key": "4292",
    "ITA": "CIPRESSA",
    "state_code": "IM",
    "cab": "922369",
    "cap": "18017"
  },
  {
    "key": "4293",
    "ITA": "CIRCELLO",
    "state_code": "BN",
    "cab": "755603",
    "cap": "82020"
  },
  {
    "key": "4294",
    "ITA": "CIRIE'",
    "state_code": "TO",
    "cab": "303800",
    "cap": "10073"
  },
  {
    "key": "4295",
    "ITA": "CIRIE'-DEVESI",
    "state_code": "TO",
    "cab": "303800",
    "cap": "10073"
  },
  {
    "key": "4296",
    "ITA": "CIRIGLIANO",
    "state_code": "MT",
    "cab": "958124",
    "cap": "75010"
  },
  {
    "key": "4297",
    "ITA": "CIRIMIDO",
    "state_code": "CO",
    "cab": "898700",
    "cap": "22070"
  },
  {
    "key": "4298",
    "ITA": "CIRO'",
    "state_code": "KR",
    "cab": "425207",
    "cap": "88813"
  },
  {
    "key": "4299",
    "ITA": "CIRO' MARINA",
    "state_code": "KR",
    "cab": "425306",
    "cap": "88811"
  },
  {
    "key": "4300",
    "ITA": "CIS",
    "state_code": "TN",
    "cab": "363903",
    "cap": "38020"
  },
  {
    "key": "4301",
    "ITA": "CISANO BERGAMASCO",
    "state_code": "BG",
    "cab": "528802",
    "cap": "24034"
  },
  {
    "key": "4302",
    "ITA": "CISANO SUL NEVA",
    "state_code": "SV",
    "cab": "493908",
    "cap": "17035"
  },
  {
    "key": "4303",
    "ITA": "CISERANO",
    "state_code": "BG",
    "cab": "528901",
    "cap": "24040"
  },
  {
    "key": "4304",
    "ITA": "CISLAGO",
    "state_code": "VA",
    "cab": "501601",
    "cap": "21040"
  },
  {
    "key": "4305",
    "ITA": "CISLAGO-MASSINA",
    "state_code": "VA",
    "cab": "501601",
    "cap": "21040"
  },
  {
    "key": "4306",
    "ITA": "CISLIANO",
    "state_code": "MI",
    "cab": "329409",
    "cap": "20080"
  },
  {
    "key": "4307",
    "ITA": "CISMON DEL GRAPPA",
    "state_code": "VI",
    "cab": "603100",
    "cap": "36020"
  },
  {
    "key": "4308",
    "ITA": "CISMON DEL GRAPPA-PRIMOLANO",
    "state_code": "VI",
    "cab": "603100",
    "cap": "36020"
  },
  {
    "key": "4309",
    "ITA": "CISON DI VALMARINO",
    "state_code": "TV",
    "cab": "622407",
    "cap": "31030"
  },
  {
    "key": "4310",
    "ITA": "CISON DI VALMARINO-TOVENA",
    "state_code": "TV",
    "cab": "622407",
    "cap": "31030"
  },
  {
    "key": "4311",
    "ITA": "CISSONE",
    "state_code": "CN",
    "cab": "918607",
    "cap": "12050"
  },
  {
    "key": "4312",
    "ITA": "CISTERNA D'ASTI",
    "state_code": "AT",
    "cab": "473900",
    "cap": "14010"
  },
  {
    "key": "4313",
    "ITA": "CISTERNA D'ASTI-SAN MATTEO",
    "state_code": "AT",
    "cab": "473900",
    "cap": "14010"
  },
  {
    "key": "4314",
    "ITA": "CISTERNA DI LATINA-LE CASTELLA",
    "state_code": "LT",
    "cab": "739508",
    "cap": "4012"
  },
  {
    "key": "4315",
    "ITA": "CISTERNA DI LATINA",
    "state_code": "LT",
    "cab": "739508",
    "cap": "4012"
  },
  {
    "key": "4316",
    "ITA": "CISTERNA DI LATINA-BORGO FLORA",
    "state_code": "LT",
    "cab": "739508",
    "cap": "4012"
  },
  {
    "key": "4317",
    "ITA": "CISTERNINO",
    "state_code": "BR",
    "cab": "791707",
    "cap": "72014"
  },
  {
    "key": "4318",
    "ITA": "CISTERNINO-CARANNA",
    "state_code": "BR",
    "cab": "791707",
    "cap": "72014"
  },
  {
    "key": "4319",
    "ITA": "CISTERNINO-CASALINI",
    "state_code": "BR",
    "cab": "791707",
    "cap": "72014"
  },
  {
    "key": "4320",
    "ITA": "CITERNA-FIGHILLE",
    "state_code": "PG",
    "cab": "383703",
    "cap": "6010"
  },
  {
    "key": "4321",
    "ITA": "CITERNA",
    "state_code": "PG",
    "cab": "383703",
    "cap": "6010"
  },
  {
    "key": "4322",
    "ITA": "CITTA' DEL VATICANO",
    "state_code": "EE",
    "cab": "908442",
    "cap": "120"
  },
  {
    "key": "4323",
    "ITA": "CITTA' DELLA PIEVE-SALCI",
    "state_code": "PG",
    "cab": "383802",
    "cap": "6062"
  },
  {
    "key": "4324",
    "ITA": "CITTA' DELLA PIEVE-PONTICELLI",
    "state_code": "PG",
    "cab": "383802",
    "cap": "6062"
  },
  {
    "key": "4325",
    "ITA": "CITTA' DELLA PIEVE-PO BANDINO",
    "state_code": "PG",
    "cab": "383802",
    "cap": "6062"
  },
  {
    "key": "4326",
    "ITA": "CITTA' DELLA PIEVE",
    "state_code": "PG",
    "cab": "383802",
    "cap": "6062"
  },
  {
    "key": "4327",
    "ITA": "CITTA' DELLA PIEVE-MOIANO",
    "state_code": "PG",
    "cab": "383802",
    "cap": "6062"
  },
  {
    "key": "4328",
    "ITA": "CITTA' DI CASTELLO-TRESTINA",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6018"
  },
  {
    "key": "4329",
    "ITA": "CITTA' DI CASTELLO-SAN SECONDO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4330",
    "ITA": "CITTA' DI CASTELLO-SAN MAIANO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4331",
    "ITA": "CITTA' DI CASTELLO-SAN LEO BASTIA",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6018"
  },
  {
    "key": "4332",
    "ITA": "CITTA' DI CASTELLO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4333",
    "ITA": "CITTA' DI CASTELLO-BADIA PETROIA",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6018"
  },
  {
    "key": "4334",
    "ITA": "CITTA' DI CASTELLO-CERBARA",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4335",
    "ITA": "CITTA' DI CASTELLO-CINQUEMIGLIA",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4336",
    "ITA": "CITTA' DI CASTELLO-FRACCANO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4337",
    "ITA": "CITTA' DI CASTELLO-LERCHI",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4338",
    "ITA": "CITTA' DI CASTELLO-LUGNANO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6018"
  },
  {
    "key": "4339",
    "ITA": "CITTA' DI CASTELLO-MORRA",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6010"
  },
  {
    "key": "4340",
    "ITA": "CITTA' DI CASTELLO-MUCCIGNANO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6010"
  },
  {
    "key": "4341",
    "ITA": "CITTA' DI CASTELLO-PETRELLE",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6018"
  },
  {
    "key": "4342",
    "ITA": "CITTA' DI CASTELLO-PIOSINA",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4343",
    "ITA": "CITTA' DI CASTELLO-PISTRINO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6018"
  },
  {
    "key": "4344",
    "ITA": "CITTA' DI CASTELLO-PROMANO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4345",
    "ITA": "CITTA' DI CASTELLO-RIOSECCO",
    "state_code": "PG",
    "cab": "216002",
    "cap": "6012"
  },
  {
    "key": "4346",
    "ITA": "CITTA' SANT'ANGELO-VILLA CIPRESSO",
    "state_code": "PE",
    "cab": "772509",
    "cap": "65013"
  },
  {
    "key": "4347",
    "ITA": "CITTA' SANT'ANGELO-SAN MARTINO BASSA",
    "state_code": "PE",
    "cab": "772509",
    "cap": "65013"
  },
  {
    "key": "4348",
    "ITA": "CITTA' SANT'ANGELO-MARINA",
    "state_code": "PE",
    "cab": "772509",
    "cap": "65013"
  },
  {
    "key": "4349",
    "ITA": "CITTA' SANT'ANGELO",
    "state_code": "PE",
    "cab": "772509",
    "cap": "65013"
  },
  {
    "key": "4350",
    "ITA": "CITTADELLA",
    "state_code": "PD",
    "cab": "625202",
    "cap": "35013"
  },
  {
    "key": "4351",
    "ITA": "CITTADELLA-LAGHI",
    "state_code": "PD",
    "cab": "625202",
    "cap": "35013"
  },
  {
    "key": "4352",
    "ITA": "CITTADELLA-SANTA CROCE BIGOLINA",
    "state_code": "PD",
    "cab": "625202",
    "cap": "35013"
  },
  {
    "key": "4353",
    "ITA": "CITTADUCALE-SANTA RUFINA",
    "state_code": "RI",
    "cab": "735605",
    "cap": "2010"
  },
  {
    "key": "4354",
    "ITA": "CITTADUCALE-GROTTI",
    "state_code": "RI",
    "cab": "735605",
    "cap": "2015"
  },
  {
    "key": "4355",
    "ITA": "CITTADUCALE",
    "state_code": "RI",
    "cab": "735605",
    "cap": "2015"
  },
  {
    "key": "4356",
    "ITA": "CITTANOVA",
    "state_code": "RC",
    "cab": "813501",
    "cap": "89022"
  },
  {
    "key": "4357",
    "ITA": "CITTAREALE-SANTA CROCE",
    "state_code": "RI",
    "cab": "735704",
    "cap": "2010"
  },
  {
    "key": "4358",
    "ITA": "CITTAREALE",
    "state_code": "RI",
    "cab": "735704",
    "cap": "2010"
  },
  {
    "key": "4359",
    "ITA": "CITTIGLIO",
    "state_code": "VA",
    "cab": "501700",
    "cap": "21033"
  },
  {
    "key": "4360",
    "ITA": "CIVATE",
    "state_code": "LC",
    "cab": "511808",
    "cap": "23862"
  },
  {
    "key": "4361",
    "ITA": "CIVENNA",
    "state_code": "CO",
    "cab": "511907",
    "cap": "22030"
  },
  {
    "key": "4362",
    "ITA": "CIVEZZA",
    "state_code": "IM",
    "cab": "922377",
    "cap": "18017"
  },
  {
    "key": "4363",
    "ITA": "CIVEZZANO",
    "state_code": "TN",
    "cab": "346601",
    "cap": "38045"
  },
  {
    "key": "4364",
    "ITA": "CIVEZZANO-SEREGNANO",
    "state_code": "TN",
    "cab": "346601",
    "cap": "38045"
  },
  {
    "key": "4365",
    "ITA": "CIVIASCO",
    "state_code": "VC",
    "cab": "915827",
    "cap": "13010"
  },
  {
    "key": "4366",
    "ITA": "CIVIDALE DEL FRIULI",
    "state_code": "UD",
    "cab": "637405",
    "cap": "33043"
  },
  {
    "key": "4367",
    "ITA": "CIVIDALE DEL FRIULI-PURGESSIMO",
    "state_code": "UD",
    "cab": "637405",
    "cap": "33043"
  },
  {
    "key": "4368",
    "ITA": "CIVIDALE DEL FRIULI-SANGUARZO",
    "state_code": "UD",
    "cab": "637405",
    "cap": "33043"
  },
  {
    "key": "4369",
    "ITA": "CIVIDATE AL PIANO",
    "state_code": "BG",
    "cab": "529008",
    "cap": "24050"
  },
  {
    "key": "4370",
    "ITA": "CIVIDATE CAMUNO",
    "state_code": "BS",
    "cab": "543504",
    "cap": "25040"
  },
  {
    "key": "4371",
    "ITA": "CIVITA",
    "state_code": "CS",
    "cab": "958793",
    "cap": "87010"
  },
  {
    "key": "4372",
    "ITA": "CIVITA CASTELLANA",
    "state_code": "VT",
    "cab": "730309",
    "cap": "1033"
  },
  {
    "key": "4373",
    "ITA": "CIVITA CASTELLANA-BORGHETTO",
    "state_code": "VT",
    "cab": "730309",
    "cap": "1033"
  },
  {
    "key": "4374",
    "ITA": "CIVITA CASTELLANA-CIVITA CASTELLANA STAZIONE",
    "state_code": "VT",
    "cab": "730309",
    "cap": "1033"
  },
  {
    "key": "4375",
    "ITA": "CIVITA D'ANTINO",
    "state_code": "AQ",
    "cab": "909614",
    "cap": "67050"
  },
  {
    "key": "4376",
    "ITA": "CIVITA D'ANTINO-PERO DEI SANTI",
    "state_code": "AQ",
    "cab": "909614",
    "cap": "67050"
  },
  {
    "key": "4377",
    "ITA": "CIVITACAMPOMARANO",
    "state_code": "CB",
    "cab": "910513",
    "cap": "86030"
  },
  {
    "key": "4378",
    "ITA": "CIVITALUPARELLA",
    "state_code": "CH",
    "cab": "954628",
    "cap": "66040"
  },
  {
    "key": "4379",
    "ITA": "CIVITANOVA DEL SANNIO",
    "state_code": "IS",
    "cab": "186684",
    "cap": "86094"
  },
  {
    "key": "4380",
    "ITA": "CIVITANOVA MARCHE-SANTA MARIA APPARENTE",
    "state_code": "MC",
    "cab": "688705",
    "cap": "62012"
  },
  {
    "key": "4381",
    "ITA": "CIVITANOVA MARCHE-FONTESPINA",
    "state_code": "MC",
    "cab": "688705",
    "cap": "62012"
  },
  {
    "key": "4382",
    "ITA": "CIVITANOVA MARCHE-CIVITANOVA ALTA",
    "state_code": "MC",
    "cab": "688705",
    "cap": "62012"
  },
  {
    "key": "4383",
    "ITA": "CIVITANOVA MARCHE",
    "state_code": "MC",
    "cab": "688705",
    "cap": "62012"
  },
  {
    "key": "4384",
    "ITA": "CIVITAQUANA",
    "state_code": "PE",
    "cab": "772608",
    "cap": "65010"
  },
  {
    "key": "4385",
    "ITA": "CIVITAVECCHIA-AURELIA",
    "state_code": "RM",
    "cab": "390401",
    "cap": "53"
  },
  {
    "key": "4386",
    "ITA": "CIVITAVECCHIA",
    "state_code": "RM",
    "cab": "390401",
    "cap": "53"
  },
  {
    "key": "4387",
    "ITA": "CIVITELLA ALFEDENA",
    "state_code": "AQ",
    "cab": "405506",
    "cap": "67030"
  },
  {
    "key": "4388",
    "ITA": "CIVITELLA CASANOVA-VESTEA",
    "state_code": "PE",
    "cab": "772707",
    "cap": "65010"
  },
  {
    "key": "4389",
    "ITA": "CIVITELLA CASANOVA",
    "state_code": "PE",
    "cab": "772707",
    "cap": "65010"
  },
  {
    "key": "4390",
    "ITA": "CIVITELLA D'AGLIANO-SAN MICHELE IN TEVERINA",
    "state_code": "VT",
    "cab": "730408",
    "cap": "1020"
  },
  {
    "key": "4391",
    "ITA": "CIVITELLA D'AGLIANO",
    "state_code": "VT",
    "cab": "730408",
    "cap": "1020"
  },
  {
    "key": "4392",
    "ITA": "CIVITELLA D'AGLIANO-CASENUOVE",
    "state_code": "VT",
    "cab": "730408",
    "cap": "1020"
  },
  {
    "key": "4393",
    "ITA": "CIVITELLA DEL TRONTO-VILLA PASSO",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4394",
    "ITA": "CIVITELLA DEL TRONTO-VILLA LEMPA",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4395",
    "ITA": "CIVITELLA DEL TRONTO-VILLA FAVALE",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4396",
    "ITA": "CIVITELLA DEL TRONTO-ROCCHE DI CIVITELLA",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4397",
    "ITA": "CIVITELLA DEL TRONTO-CERQUETO DEL TRONTO",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4398",
    "ITA": "CIVITELLA DEL TRONTO-PONZANO",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4399",
    "ITA": "CIVITELLA DEL TRONTO-RIPE CIVITELLA DEL TRONTO",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4400",
    "ITA": "CIVITELLA DEL TRONTO",
    "state_code": "TE",
    "cab": "768507",
    "cap": "64010"
  },
  {
    "key": "4401",
    "ITA": "CIVITELLA DI ROMAGNA-VOLTRE",
    "state_code": "FC",
    "cab": "677609",
    "cap": "47012"
  },
  {
    "key": "4402",
    "ITA": "CIVITELLA DI ROMAGNA-NESPOLI",
    "state_code": "FC",
    "cab": "677609",
    "cap": "47012"
  },
  {
    "key": "4403",
    "ITA": "CIVITELLA DI ROMAGNA-CUSERCOLI",
    "state_code": "FC",
    "cab": "677609",
    "cap": "47012"
  },
  {
    "key": "4404",
    "ITA": "CIVITELLA DI ROMAGNA",
    "state_code": "FC",
    "cab": "677609",
    "cap": "47012"
  },
  {
    "key": "4405",
    "ITA": "CIVITELLA IN VAL DI CHIANA",
    "state_code": "AR",
    "cab": "714501",
    "cap": "52041"
  },
  {
    "key": "4406",
    "ITA": "CIVITELLA IN VAL DI CHIANA-BADIA AL PINO",
    "state_code": "AR",
    "cab": "714501",
    "cap": "52041"
  },
  {
    "key": "4407",
    "ITA": "CIVITELLA IN VAL DI CHIANA-CIGGIANO",
    "state_code": "AR",
    "cab": "714501",
    "cap": "52041"
  },
  {
    "key": "4408",
    "ITA": "CIVITELLA IN VAL DI CHIANA-PIEVE AL TOPPO",
    "state_code": "AR",
    "cab": "714501",
    "cap": "52041"
  },
  {
    "key": "4409",
    "ITA": "CIVITELLA IN VAL DI CHIANA-TEGOLETO",
    "state_code": "AR",
    "cab": "714501",
    "cap": "52041"
  },
  {
    "key": "4410",
    "ITA": "CIVITELLA IN VAL DI CHIANA-VICIOMAGGIO",
    "state_code": "AR",
    "cab": "714501",
    "cap": "52041"
  },
  {
    "key": "4411",
    "ITA": "CIVITELLA MESSER RAIMONDO",
    "state_code": "CH",
    "cab": "954636",
    "cap": "66010"
  },
  {
    "key": "4412",
    "ITA": "CIVITELLA PAGANICO-MONTE ANTICO",
    "state_code": "GR",
    "cab": "722306",
    "cap": "58045"
  },
  {
    "key": "4413",
    "ITA": "CIVITELLA PAGANICO-STAZIONE DI MONTE ANTICO",
    "state_code": "GR",
    "cab": "722306",
    "cap": "58045"
  },
  {
    "key": "4414",
    "ITA": "CIVITELLA PAGANICO-CIVITELLA MARITTIMA",
    "state_code": "GR",
    "cab": "722306",
    "cap": "58045"
  },
  {
    "key": "4415",
    "ITA": "CIVITELLA PAGANICO-CASALE DI PARI",
    "state_code": "GR",
    "cab": "722306",
    "cap": "58045"
  },
  {
    "key": "4416",
    "ITA": "CIVITELLA PAGANICO",
    "state_code": "GR",
    "cab": "722306",
    "cap": "58045"
  },
  {
    "key": "4417",
    "ITA": "CIVITELLA PAGANICO-PARI",
    "state_code": "GR",
    "cab": "722306",
    "cap": "58045"
  },
  {
    "key": "4418",
    "ITA": "CIVITELLA PAGANICO-PAGANICO",
    "state_code": "GR",
    "cab": "722306",
    "cap": "58045"
  },
  {
    "key": "4419",
    "ITA": "CIVITELLA ROVETO",
    "state_code": "AQ",
    "cab": "405605",
    "cap": "67054"
  },
  {
    "key": "4420",
    "ITA": "CIVITELLA ROVETO-META",
    "state_code": "AQ",
    "cab": "405605",
    "cap": "67054"
  },
  {
    "key": "4421",
    "ITA": "CIVITELLA SAN PAOLO",
    "state_code": "RM",
    "cab": "390500",
    "cap": "60"
  },
  {
    "key": "4422",
    "ITA": "CIVO",
    "state_code": "SO",
    "cab": "927038",
    "cap": "23010"
  },
  {
    "key": "4423",
    "ITA": "CIVO-CEVO",
    "state_code": "SO",
    "cab": "927038",
    "cap": "23010"
  },
  {
    "key": "4424",
    "ITA": "CLAINO CON OSTENO",
    "state_code": "CO",
    "cab": "925412",
    "cap": "22010"
  },
  {
    "key": "4425",
    "ITA": "CLAUT",
    "state_code": "PN",
    "cab": "648303",
    "cap": "33080"
  },
  {
    "key": "4426",
    "ITA": "CLAUZETTO",
    "state_code": "PN",
    "cab": "648402",
    "cap": "33090"
  },
  {
    "key": "4427",
    "ITA": "CLAVESANA",
    "state_code": "CN",
    "cab": "488908",
    "cap": "12060"
  },
  {
    "key": "4428",
    "ITA": "CLAVESANA-MADONNA DELLA NEVE",
    "state_code": "CN",
    "cab": "488908",
    "cap": "12060"
  },
  {
    "key": "4429",
    "ITA": "CLAVIERE",
    "state_code": "TO",
    "cab": "303909",
    "cap": "10050"
  },
  {
    "key": "4430",
    "ITA": "CLES-MECHEL",
    "state_code": "TN",
    "cab": "346700",
    "cap": "38023"
  },
  {
    "key": "4431",
    "ITA": "CLES-CALTRON",
    "state_code": "TN",
    "cab": "346700",
    "cap": "38023"
  },
  {
    "key": "4432",
    "ITA": "CLES",
    "state_code": "TN",
    "cab": "346700",
    "cap": "38023"
  },
  {
    "key": "4433",
    "ITA": "CLETO-SAVUTO",
    "state_code": "CS",
    "cab": "178251",
    "cap": "87030"
  },
  {
    "key": "4434",
    "ITA": "CLETO",
    "state_code": "CS",
    "cab": "178251",
    "cap": "87030"
  },
  {
    "key": "4435",
    "ITA": "CLIVIO",
    "state_code": "VA",
    "cab": "507301",
    "cap": "21050"
  },
  {
    "key": "4436",
    "ITA": "CLOZ",
    "state_code": "TN",
    "cab": "346809",
    "cap": "38020"
  },
  {
    "key": "4437",
    "ITA": "CLUSONE",
    "state_code": "BG",
    "cab": "529107",
    "cap": "24023"
  },
  {
    "key": "4438",
    "ITA": "COASSOLO TORINESE",
    "state_code": "TO",
    "cab": "574103",
    "cap": "10070"
  },
  {
    "key": "4439",
    "ITA": "COAZZE",
    "state_code": "TO",
    "cab": "304006",
    "cap": "10050"
  },
  {
    "key": "4440",
    "ITA": "COAZZOLO",
    "state_code": "AT",
    "cab": "920116",
    "cap": "14054"
  },
  {
    "key": "4441",
    "ITA": "COCCAGLIO",
    "state_code": "BS",
    "cab": "543603",
    "cap": "25030"
  },
  {
    "key": "4442",
    "ITA": "COCCONATO",
    "state_code": "AT",
    "cab": "474007",
    "cap": "14023"
  },
  {
    "key": "4443",
    "ITA": "COCCONATO-COCCONITO VIGNARETTO",
    "state_code": "AT",
    "cab": "474007",
    "cap": "14023"
  },
  {
    "key": "4444",
    "ITA": "COCQUIO TREVISAGO-COCQUIO",
    "state_code": "VA",
    "cab": "506808",
    "cap": "21034"
  },
  {
    "key": "4445",
    "ITA": "COCQUIO TREVISAGO",
    "state_code": "VA",
    "cab": "506808",
    "cap": "21034"
  },
  {
    "key": "4446",
    "ITA": "COCQUIO TREVISAGO-CALDANA",
    "state_code": "VA",
    "cab": "506808",
    "cap": "21034"
  },
  {
    "key": "4447",
    "ITA": "COCQUIO TREVISAGO-TREVISAGO",
    "state_code": "VA",
    "cab": "506808",
    "cap": "21034"
  },
  {
    "key": "4448",
    "ITA": "COCULLO",
    "state_code": "AQ",
    "cab": "909622",
    "cap": "67030"
  },
  {
    "key": "4449",
    "ITA": "CODEVIGO",
    "state_code": "PD",
    "cab": "625301",
    "cap": "35020"
  },
  {
    "key": "4450",
    "ITA": "CODEVIGO-CONCHE",
    "state_code": "PD",
    "cab": "625301",
    "cap": "35020"
  },
  {
    "key": "4451",
    "ITA": "CODEVILLA",
    "state_code": "PV",
    "cab": "557900",
    "cap": "27050"
  },
  {
    "key": "4452",
    "ITA": "CODIGORO",
    "state_code": "FE",
    "cab": "672006",
    "cap": "44021"
  },
  {
    "key": "4453",
    "ITA": "CODIGORO-MEZZOGORO",
    "state_code": "FE",
    "cab": "672006",
    "cap": "44021"
  },
  {
    "key": "4454",
    "ITA": "CODIGORO-POMPOSA",
    "state_code": "FE",
    "cab": "672006",
    "cap": "44021"
  },
  {
    "key": "4455",
    "ITA": "CODIGORO-PONTELANGORINO",
    "state_code": "FE",
    "cab": "672006",
    "cap": "44021"
  },
  {
    "key": "4456",
    "ITA": "CODIGORO-PONTEMAODINO",
    "state_code": "FE",
    "cab": "672006",
    "cap": "44021"
  },
  {
    "key": "4457",
    "ITA": "CODOGNE'-CIMETTA",
    "state_code": "TV",
    "cab": "616102",
    "cap": "31013"
  },
  {
    "key": "4458",
    "ITA": "CODOGNE'-ROVERBASSO",
    "state_code": "TV",
    "cab": "616102",
    "cap": "31013"
  },
  {
    "key": "4459",
    "ITA": "CODOGNE'",
    "state_code": "TV",
    "cab": "616102",
    "cap": "31013"
  },
  {
    "key": "4460",
    "ITA": "CODOGNO",
    "state_code": "LO",
    "cab": "329508",
    "cap": "26845"
  },
  {
    "key": "4461",
    "ITA": "CODROIPO-GORICIZZA E POZZO",
    "state_code": "UD",
    "cab": "637504",
    "cap": "33033"
  },
  {
    "key": "4462",
    "ITA": "CODROIPO-BIAUZZO",
    "state_code": "UD",
    "cab": "637504",
    "cap": "33033"
  },
  {
    "key": "4463",
    "ITA": "CODROIPO-BEANO",
    "state_code": "UD",
    "cab": "637504",
    "cap": "33033"
  },
  {
    "key": "4464",
    "ITA": "CODROIPO",
    "state_code": "UD",
    "cab": "637504",
    "cap": "33033"
  },
  {
    "key": "4465",
    "ITA": "CODROIPO-LONCA",
    "state_code": "UD",
    "cab": "637504",
    "cap": "33033"
  },
  {
    "key": "4466",
    "ITA": "CODROIPO-RIVOLTO",
    "state_code": "UD",
    "cab": "637504",
    "cap": "33033"
  },
  {
    "key": "4467",
    "ITA": "CODRONGIANOS",
    "state_code": "SS",
    "cab": "873208",
    "cap": "7040"
  },
  {
    "key": "4468",
    "ITA": "COGGIOLA",
    "state_code": "BI",
    "cab": "444208",
    "cap": "13863"
  },
  {
    "key": "4469",
    "ITA": "COGLIATE",
    "state_code": "MB",
    "cab": "329607",
    "cap": "20020"
  },
  {
    "key": "4470",
    "ITA": "COGNE",
    "state_code": "AO",
    "cab": "315507",
    "cap": "11012"
  },
  {
    "key": "4471",
    "ITA": "COGNE-EPINEL",
    "state_code": "AO",
    "cab": "315507",
    "cap": "11012"
  },
  {
    "key": "4472",
    "ITA": "COGNE-GIMILLIAN",
    "state_code": "AO",
    "cab": "315507",
    "cap": "11012"
  },
  {
    "key": "4473",
    "ITA": "COGOLETO-SCIARBORASCA",
    "state_code": "GE",
    "cab": "319707",
    "cap": "16016"
  },
  {
    "key": "4474",
    "ITA": "COGOLETO",
    "state_code": "GE",
    "cab": "319707",
    "cap": "16016"
  },
  {
    "key": "4475",
    "ITA": "COGOLETO-LERCA",
    "state_code": "GE",
    "cab": "319707",
    "cap": "16016"
  },
  {
    "key": "4476",
    "ITA": "COGOLLO DEL CENGIO",
    "state_code": "VI",
    "cab": "883504",
    "cap": "36010"
  },
  {
    "key": "4477",
    "ITA": "COGORNO-SAN SALVATORE",
    "state_code": "GE",
    "cab": "319806",
    "cap": "16030"
  },
  {
    "key": "4478",
    "ITA": "COGORNO",
    "state_code": "GE",
    "cab": "319806",
    "cap": "16030"
  },
  {
    "key": "4479",
    "ITA": "COLAZZA",
    "state_code": "NO",
    "cab": "917161",
    "cap": "28010"
  },
  {
    "key": "4480",
    "ITA": "COLBORDOLO",
    "state_code": "PU",
    "cab": "682708",
    "cap": "61022"
  },
  {
    "key": "4481",
    "ITA": "COLBORDOLO-MORCIOLA",
    "state_code": "PU",
    "cab": "682708",
    "cap": "61022"
  },
  {
    "key": "4482",
    "ITA": "COLBORDOLO-BOTTEGA",
    "state_code": "PU",
    "cab": "682708",
    "cap": "61022"
  },
  {
    "key": "4483",
    "ITA": "COLERE",
    "state_code": "BG",
    "cab": "539700",
    "cap": "24020"
  },
  {
    "key": "4484",
    "ITA": "COLERE-CANTONIERA DELLA PRESOLANA",
    "state_code": "BG",
    "cab": "539700",
    "cap": "24020"
  },
  {
    "key": "4485",
    "ITA": "COLFELICE-VILLA FELICE",
    "state_code": "FR",
    "cab": "387704",
    "cap": "3030"
  },
  {
    "key": "4486",
    "ITA": "COLFELICE-COLDRAGONE",
    "state_code": "FR",
    "cab": "387704",
    "cap": "3030"
  },
  {
    "key": "4487",
    "ITA": "COLFELICE",
    "state_code": "FR",
    "cab": "387704",
    "cap": "3030"
  },
  {
    "key": "4488",
    "ITA": "COLI",
    "state_code": "PC",
    "cab": "712604",
    "cap": "29020"
  },
  {
    "key": "4489",
    "ITA": "COLI-PERINO",
    "state_code": "PC",
    "cab": "712604",
    "cap": "29020"
  },
  {
    "key": "4490",
    "ITA": "COLICO-COLICO PIANO",
    "state_code": "LC",
    "cab": "512004",
    "cap": "23823"
  },
  {
    "key": "4491",
    "ITA": "COLICO",
    "state_code": "LC",
    "cab": "512004",
    "cap": "23823"
  },
  {
    "key": "4492",
    "ITA": "COLLAGNA",
    "state_code": "RE",
    "cab": "663104",
    "cap": "42037"
  },
  {
    "key": "4493",
    "ITA": "COLLAGNA-CERRETO ALPI",
    "state_code": "RE",
    "cab": "663104",
    "cap": "42037"
  },
  {
    "key": "4494",
    "ITA": "COLLALTO SABINO",
    "state_code": "RI",
    "cab": "735803",
    "cap": "2022"
  },
  {
    "key": "4495",
    "ITA": "COLLARMELE",
    "state_code": "AQ",
    "cab": "909630",
    "cap": "67040"
  },
  {
    "key": "4496",
    "ITA": "COLLAZZONE-COLLEPEPE",
    "state_code": "PG",
    "cab": "383901",
    "cap": "6050"
  },
  {
    "key": "4497",
    "ITA": "COLLAZZONE-CASALALTA",
    "state_code": "PG",
    "cab": "383901",
    "cap": "6050"
  },
  {
    "key": "4498",
    "ITA": "COLLAZZONE-PIEDICOLLE",
    "state_code": "PG",
    "cab": "383901",
    "cap": "6050"
  },
  {
    "key": "4499",
    "ITA": "COLLAZZONE",
    "state_code": "PG",
    "cab": "383901",
    "cap": "6050"
  },
  {
    "key": "4500",
    "ITA": "COLLE BRIANZA-NAVA",
    "state_code": "LC",
    "cab": "925420",
    "cap": "23886"
  },
  {
    "key": "4501",
    "ITA": "COLLE BRIANZA",
    "state_code": "LC",
    "cab": "925420",
    "cap": "23886"
  },
  {
    "key": "4502",
    "ITA": "COLLE D'ANCHISE",
    "state_code": "CB",
    "cab": "910521",
    "cap": "86020"
  },
  {
    "key": "4503",
    "ITA": "COLLE DI TORA",
    "state_code": "RI",
    "cab": "947150",
    "cap": "2020"
  },
  {
    "key": "4504",
    "ITA": "COLLE DI VAL D'ELSA-GRACCIANO DI COLLE VAL D'ELSA",
    "state_code": "SI",
    "cab": "718601",
    "cap": "53034"
  },
  {
    "key": "4505",
    "ITA": "COLLE DI VAL D'ELSA-CAMPIGLIA",
    "state_code": "SI",
    "cab": "718601",
    "cap": "53034"
  },
  {
    "key": "4506",
    "ITA": "COLLE DI VAL D'ELSA",
    "state_code": "SI",
    "cab": "718601",
    "cap": "53034"
  },
  {
    "key": "4507",
    "ITA": "COLLE DI VAL D'ELSA-QUARTAIA",
    "state_code": "SI",
    "cab": "718601",
    "cap": "53034"
  },
  {
    "key": "4508",
    "ITA": "COLLE SAN MAGNO",
    "state_code": "FR",
    "cab": "178822",
    "cap": "3030"
  },
  {
    "key": "4509",
    "ITA": "COLLE SANNITA",
    "state_code": "BN",
    "cab": "753103",
    "cap": "82024"
  },
  {
    "key": "4510",
    "ITA": "COLLE SANNITA-DECORATA",
    "state_code": "BN",
    "cab": "753103",
    "cap": "82024"
  },
  {
    "key": "4511",
    "ITA": "COLLE SANTA LUCIA",
    "state_code": "BL",
    "cab": "934646",
    "cap": "32020"
  },
  {
    "key": "4512",
    "ITA": "COLLE UMBERTO-SAN MARTINO",
    "state_code": "TV",
    "cab": "623108",
    "cap": "31014"
  },
  {
    "key": "4513",
    "ITA": "COLLE UMBERTO",
    "state_code": "TV",
    "cab": "623108",
    "cap": "31014"
  },
  {
    "key": "4514",
    "ITA": "COLLEBEATO",
    "state_code": "BS",
    "cab": "543702",
    "cap": "25060"
  },
  {
    "key": "4515",
    "ITA": "COLLECCHIO-OZZANO TARO",
    "state_code": "PR",
    "cab": "656900",
    "cap": "43044"
  },
  {
    "key": "4516",
    "ITA": "COLLECCHIO-SAN MARTINO SINZANO",
    "state_code": "PR",
    "cab": "656900",
    "cap": "43044"
  },
  {
    "key": "4517",
    "ITA": "COLLECCHIO-MADREGOLO",
    "state_code": "PR",
    "cab": "656900",
    "cap": "43044"
  },
  {
    "key": "4518",
    "ITA": "COLLECCHIO-GAIANO",
    "state_code": "PR",
    "cab": "656900",
    "cap": "43044"
  },
  {
    "key": "4519",
    "ITA": "COLLECCHIO",
    "state_code": "PR",
    "cab": "656900",
    "cap": "43044"
  },
  {
    "key": "4520",
    "ITA": "COLLECORVINO-SANTA LUCIA",
    "state_code": "PE",
    "cab": "772806",
    "cap": "65010"
  },
  {
    "key": "4521",
    "ITA": "COLLECORVINO-CONGIUNTI",
    "state_code": "PE",
    "cab": "772806",
    "cap": "65010"
  },
  {
    "key": "4522",
    "ITA": "COLLECORVINO-BARBERI",
    "state_code": "PE",
    "cab": "772806",
    "cap": "65010"
  },
  {
    "key": "4523",
    "ITA": "COLLECORVINO",
    "state_code": "PE",
    "cab": "772806",
    "cap": "65010"
  },
  {
    "key": "4524",
    "ITA": "COLLEDARA",
    "state_code": "TE",
    "cab": "365205",
    "cap": "64042"
  },
  {
    "key": "4525",
    "ITA": "COLLEDARA-VILLA PETTO",
    "state_code": "TE",
    "cab": "365205",
    "cap": "64042"
  },
  {
    "key": "4526",
    "ITA": "COLLEDARA-ORNANO GRANDE",
    "state_code": "TE",
    "cab": "365205",
    "cap": "64042"
  },
  {
    "key": "4527",
    "ITA": "COLLEDIMACINE",
    "state_code": "CH",
    "cab": "954644",
    "cap": "66010"
  },
  {
    "key": "4528",
    "ITA": "COLLEDIMEZZO",
    "state_code": "CH",
    "cab": "954651",
    "cap": "66040"
  },
  {
    "key": "4529",
    "ITA": "COLLEFERRO",
    "state_code": "RM",
    "cab": "390609",
    "cap": "34"
  },
  {
    "key": "4530",
    "ITA": "COLLEFERRO-COLLEFERRO SCALO",
    "state_code": "RM",
    "cab": "390609",
    "cap": "34"
  },
  {
    "key": "4531",
    "ITA": "COLLEGIOVE",
    "state_code": "RI",
    "cab": "947168",
    "cap": "2020"
  },
  {
    "key": "4532",
    "ITA": "COLLEGNO-REGINA MARGHERITA",
    "state_code": "TO",
    "cab": "304105",
    "cap": "10093"
  },
  {
    "key": "4533",
    "ITA": "COLLEGNO",
    "state_code": "TO",
    "cab": "304105",
    "cap": "10093"
  },
  {
    "key": "4534",
    "ITA": "COLLEGNO-SAVONERA",
    "state_code": "TO",
    "cab": "304105",
    "cap": "10093"
  },
  {
    "key": "4535",
    "ITA": "COLLEGNO-LEUMANN",
    "state_code": "TO",
    "cab": "304105",
    "cap": "10093"
  },
  {
    "key": "4536",
    "ITA": "COLLEGNO-BORGATA PARADISO DI COLLEGNO",
    "state_code": "TO",
    "cab": "304105",
    "cap": "10093"
  },
  {
    "key": "4537",
    "ITA": "COLLELONGO",
    "state_code": "AQ",
    "cab": "405704",
    "cap": "67050"
  },
  {
    "key": "4538",
    "ITA": "COLLEPARDO",
    "state_code": "FR",
    "cab": "948315",
    "cap": "3010"
  },
  {
    "key": "4539",
    "ITA": "COLLEPARDO-CERTOSA DI TRISULTI",
    "state_code": "FR",
    "cab": "948315",
    "cap": "3010"
  },
  {
    "key": "4540",
    "ITA": "COLLEPASSO",
    "state_code": "LE",
    "cab": "795708",
    "cap": "73040"
  },
  {
    "key": "4541",
    "ITA": "COLLEPIETRO",
    "state_code": "AQ",
    "cab": "909648",
    "cap": "67020"
  },
  {
    "key": "4542",
    "ITA": "COLLERETTO CASTELNUOVO",
    "state_code": "TO",
    "cab": "283481",
    "cap": "10080"
  },
  {
    "key": "4543",
    "ITA": "COLLERETTO GIACOSA",
    "state_code": "TO",
    "cab": "285155",
    "cap": "10010"
  },
  {
    "key": "4544",
    "ITA": "COLLESALVETTI-CROCINO",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57014"
  },
  {
    "key": "4545",
    "ITA": "COLLESALVETTI-GUASTICCE",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57017"
  },
  {
    "key": "4546",
    "ITA": "COLLESALVETTI-NUGOLA NUOVA",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57017"
  },
  {
    "key": "4547",
    "ITA": "COLLESALVETTI-PARRANA SAN GIUSTO",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57014"
  },
  {
    "key": "4548",
    "ITA": "COLLESALVETTI-CASTELL'ANSELMO",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57014"
  },
  {
    "key": "4549",
    "ITA": "COLLESALVETTI-STAGNO",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57017"
  },
  {
    "key": "4550",
    "ITA": "COLLESALVETTI-VICARELLO",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57014"
  },
  {
    "key": "4551",
    "ITA": "COLLESALVETTI",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57014"
  },
  {
    "key": "4552",
    "ITA": "COLLESALVETTI-COLOGNOLE",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57014"
  },
  {
    "key": "4553",
    "ITA": "COLLESALVETTI-PARRANA SAN MARTINO",
    "state_code": "LI",
    "cab": "250001",
    "cap": "57014"
  },
  {
    "key": "4554",
    "ITA": "COLLESANO",
    "state_code": "PA",
    "cab": "433102",
    "cap": "90016"
  },
  {
    "key": "4555",
    "ITA": "COLLETORTO",
    "state_code": "CB",
    "cab": "411801",
    "cap": "86044"
  },
  {
    "key": "4556",
    "ITA": "COLLEVECCHIO",
    "state_code": "RI",
    "cab": "735902",
    "cap": "2042"
  },
  {
    "key": "4557",
    "ITA": "COLLI A VOLTURNO",
    "state_code": "IS",
    "cab": "885301",
    "cap": "86073"
  },
  {
    "key": "4558",
    "ITA": "COLLI DEL TRONTO",
    "state_code": "AP",
    "cab": "697805",
    "cap": "63030"
  },
  {
    "key": "4559",
    "ITA": "COLLI DEL TRONTO-VILLA SAN GIUSEPPE",
    "state_code": "AP",
    "cab": "697805",
    "cap": "63030"
  },
  {
    "key": "4560",
    "ITA": "COLLI SUL VELINO",
    "state_code": "RI",
    "cab": "947176",
    "cap": "2010"
  },
  {
    "key": "4561",
    "ITA": "COLLIANO",
    "state_code": "SA",
    "cab": "491605",
    "cap": "84020"
  },
  {
    "key": "4562",
    "ITA": "COLLINAS",
    "state_code": "SU",
    "cab": "442004",
    "cap": "9020"
  },
  {
    "key": "4563",
    "ITA": "COLLIO-SAN COLOMBANO",
    "state_code": "BS",
    "cab": "543801",
    "cap": "25060"
  },
  {
    "key": "4564",
    "ITA": "COLLIO",
    "state_code": "BS",
    "cab": "543801",
    "cap": "25060"
  },
  {
    "key": "4565",
    "ITA": "COLLOBIANO",
    "state_code": "VC",
    "cab": "915835",
    "cap": "13030"
  },
  {
    "key": "4566",
    "ITA": "COLLOREDO DI MONTE ALBANO-MELS",
    "state_code": "UD",
    "cab": "645002",
    "cap": "33010"
  },
  {
    "key": "4567",
    "ITA": "COLLOREDO DI MONTE ALBANO-LAUZZANA",
    "state_code": "UD",
    "cab": "645002",
    "cap": "33010"
  },
  {
    "key": "4568",
    "ITA": "COLLOREDO DI MONTE ALBANO-CAPORIACCO",
    "state_code": "UD",
    "cab": "645002",
    "cap": "33010"
  },
  {
    "key": "4569",
    "ITA": "COLLOREDO DI MONTE ALBANO",
    "state_code": "UD",
    "cab": "645002",
    "cap": "33010"
  },
  {
    "key": "4570",
    "ITA": "COLMURANO",
    "state_code": "MC",
    "cab": "688804",
    "cap": "62020"
  },
  {
    "key": "4571",
    "ITA": "COLOBRARO",
    "state_code": "MT",
    "cab": "803007",
    "cap": "75021"
  },
  {
    "key": "4572",
    "ITA": "COLOGNA VENETA-SULE'",
    "state_code": "VR",
    "cab": "594101",
    "cap": "37044"
  },
  {
    "key": "4573",
    "ITA": "COLOGNA VENETA-SAN SEBASTIANO",
    "state_code": "VR",
    "cab": "594101",
    "cap": "37044"
  },
  {
    "key": "4574",
    "ITA": "COLOGNA VENETA",
    "state_code": "VR",
    "cab": "594101",
    "cap": "37044"
  },
  {
    "key": "4575",
    "ITA": "COLOGNA VENETA-BALDARIA",
    "state_code": "VR",
    "cab": "594101",
    "cap": "37044"
  },
  {
    "key": "4576",
    "ITA": "COLOGNE",
    "state_code": "BS",
    "cab": "543900",
    "cap": "25033"
  },
  {
    "key": "4577",
    "ITA": "COLOGNO AL SERIO",
    "state_code": "BG",
    "cab": "529206",
    "cap": "24055"
  },
  {
    "key": "4578",
    "ITA": "COLOGNO MONZESE",
    "state_code": "MI",
    "cab": "329706",
    "cap": "20093"
  },
  {
    "key": "4579",
    "ITA": "COLOGNO MONZESE-SAN MAURIZIO AL LAMBRO",
    "state_code": "MI",
    "cab": "329706",
    "cap": "20093"
  },
  {
    "key": "4580",
    "ITA": "COLOGNOLA AI COLLI-MONTANARA",
    "state_code": "VR",
    "cab": "594200",
    "cap": "37030"
  },
  {
    "key": "4581",
    "ITA": "COLOGNOLA AI COLLI-STRA'",
    "state_code": "VR",
    "cab": "594200",
    "cap": "37030"
  },
  {
    "key": "4582",
    "ITA": "COLOGNOLA AI COLLI-SAN VITTORE",
    "state_code": "VR",
    "cab": "594200",
    "cap": "37030"
  },
  {
    "key": "4583",
    "ITA": "COLOGNOLA AI COLLI",
    "state_code": "VR",
    "cab": "594200",
    "cap": "37030"
  },
  {
    "key": "4584",
    "ITA": "COLONNA",
    "state_code": "RM",
    "cab": "390708",
    "cap": "30"
  },
  {
    "key": "4585",
    "ITA": "COLONNA-COLONNA STAZIONE",
    "state_code": "RM",
    "cab": "390708",
    "cap": "30"
  },
  {
    "key": "4586",
    "ITA": "COLONNELLA",
    "state_code": "TE",
    "cab": "768606",
    "cap": "64010"
  },
  {
    "key": "4587",
    "ITA": "COLONNO",
    "state_code": "CO",
    "cab": "925438",
    "cap": "22010"
  },
  {
    "key": "4588",
    "ITA": "COLORINA",
    "state_code": "SO",
    "cab": "927046",
    "cap": "23010"
  },
  {
    "key": "4589",
    "ITA": "COLORNO",
    "state_code": "PR",
    "cab": "657007",
    "cap": "43052"
  },
  {
    "key": "4590",
    "ITA": "COLOSIMI",
    "state_code": "CS",
    "cab": "958819",
    "cap": "87050"
  },
  {
    "key": "4591",
    "ITA": "COLTURANO",
    "state_code": "MI",
    "cab": "275032",
    "cap": "20060"
  },
  {
    "key": "4592",
    "ITA": "COLTURANO-BALBIANO",
    "state_code": "MI",
    "cab": "275032",
    "cap": "20060"
  },
  {
    "key": "4593",
    "ITA": "COLVERDE",
    "state_code": "CO",
    "cab": "854687",
    "cap": "22041"
  },
  {
    "key": "4594",
    "ITA": "COLZATE",
    "state_code": "BG",
    "cab": "538009",
    "cap": "24020"
  },
  {
    "key": "4595",
    "ITA": "COMABBIO",
    "state_code": "VA",
    "cab": "924415",
    "cap": "21020"
  },
  {
    "key": "4596",
    "ITA": "COMACCHIO-VACCOLINO",
    "state_code": "FE",
    "cab": "235002",
    "cap": "44020"
  },
  {
    "key": "4597",
    "ITA": "COMACCHIO-SAN GIUSEPPE",
    "state_code": "FE",
    "cab": "235002",
    "cap": "44020"
  },
  {
    "key": "4598",
    "ITA": "COMACCHIO-PORTO GARIBALDI",
    "state_code": "FE",
    "cab": "235002",
    "cap": "44029"
  },
  {
    "key": "4599",
    "ITA": "COMACCHIO",
    "state_code": "FE",
    "cab": "235002",
    "cap": "44022"
  },
  {
    "key": "4600",
    "ITA": "COMACCHIO-VOLANIA",
    "state_code": "FE",
    "cab": "235002",
    "cap": "44022"
  },
  {
    "key": "4601",
    "ITA": "COMACCHIO-LIDO DI SPINA",
    "state_code": "FE",
    "cab": "235002",
    "cap": "44029"
  },
  {
    "key": "4602",
    "ITA": "COMACCHIO-LIDO DEGLI ESTENSI",
    "state_code": "FE",
    "cab": "235002",
    "cap": "44029"
  },
  {
    "key": "4603",
    "ITA": "COMANO",
    "state_code": "MS",
    "cab": "698902",
    "cap": "54015"
  },
  {
    "key": "4604",
    "ITA": "COMANO-CRESPIANO",
    "state_code": "MS",
    "cab": "698902",
    "cap": "54015"
  },
  {
    "key": "4605",
    "ITA": "COMAZZO",
    "state_code": "LO",
    "cab": "780205",
    "cap": "26833"
  },
  {
    "key": "4606",
    "ITA": "COMEGLIANS",
    "state_code": "UD",
    "cab": "637603",
    "cap": "33023"
  },
  {
    "key": "4607",
    "ITA": "COMELICO SUPERIORE",
    "state_code": "BL",
    "cab": "610600",
    "cap": "32040"
  },
  {
    "key": "4608",
    "ITA": "COMELICO SUPERIORE-PADOLA",
    "state_code": "BL",
    "cab": "610600",
    "cap": "32040"
  },
  {
    "key": "4609",
    "ITA": "COMELICO SUPERIORE-DOSOLEDO",
    "state_code": "BL",
    "cab": "610600",
    "cap": "32040"
  },
  {
    "key": "4610",
    "ITA": "COMELICO SUPERIORE-CASAMAZZAGNO",
    "state_code": "BL",
    "cab": "610600",
    "cap": "32040"
  },
  {
    "key": "4611",
    "ITA": "COMELICO SUPERIORE-CANDIDE",
    "state_code": "BL",
    "cab": "610600",
    "cap": "32040"
  },
  {
    "key": "4612",
    "ITA": "COMERIO",
    "state_code": "VA",
    "cab": "501809",
    "cap": "21025"
  },
  {
    "key": "4613",
    "ITA": "COMEZZANO CIZZAGO",
    "state_code": "BS",
    "cab": "544007",
    "cap": "25030"
  },
  {
    "key": "4614",
    "ITA": "COMEZZANO CIZZAGO-COMEZZANO",
    "state_code": "BS",
    "cab": "544007",
    "cap": "25030"
  },
  {
    "key": "4615",
    "ITA": "COMEZZANO CIZZAGO-CIZZAGO",
    "state_code": "BS",
    "cab": "544007",
    "cap": "25030"
  },
  {
    "key": "4616",
    "ITA": "COMIGNAGO",
    "state_code": "NO",
    "cab": "917179",
    "cap": "28060"
  },
  {
    "key": "4617",
    "ITA": "COMISO-PEDALINO",
    "state_code": "RG",
    "cab": "844506",
    "cap": "97013"
  },
  {
    "key": "4618",
    "ITA": "COMISO",
    "state_code": "RG",
    "cab": "844506",
    "cap": "97013"
  },
  {
    "key": "4619",
    "ITA": "COMITINI",
    "state_code": "AG",
    "cab": "832006",
    "cap": "92020"
  },
  {
    "key": "4620",
    "ITA": "COMIZIANO",
    "state_code": "NA",
    "cab": "908871",
    "cap": "80030"
  },
  {
    "key": "4621",
    "ITA": "COMIZIANO-GALLO",
    "state_code": "NA",
    "cab": "908871",
    "cap": "80030"
  },
  {
    "key": "4622",
    "ITA": "COMMESSAGGIO",
    "state_code": "MN",
    "cab": "576009",
    "cap": "46010"
  },
  {
    "key": "4623",
    "ITA": "COMMEZZADURA",
    "state_code": "TN",
    "cab": "346908",
    "cap": "38020"
  },
  {
    "key": "4624",
    "ITA": "COMMEZZADURA-DEGGIANO",
    "state_code": "TN",
    "cab": "346908",
    "cap": "38020"
  },
  {
    "key": "4625",
    "ITA": "COMMEZZADURA-MESTRIAGO",
    "state_code": "TN",
    "cab": "346908",
    "cap": "38020"
  },
  {
    "key": "4626",
    "ITA": "COMO-TAVERNOLA",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4627",
    "ITA": "COMO-REBBIO",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4628",
    "ITA": "COMO-PONTE CHIASSO",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4629",
    "ITA": "COMO-LORA",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4630",
    "ITA": "COMO-ALBATE",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4631",
    "ITA": "COMO-BRECCIA",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4632",
    "ITA": "COMO-CAMERLATA",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4633",
    "ITA": "COMO-CAMNAGO VOLTA",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4634",
    "ITA": "COMO-CIVIGLIO",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4635",
    "ITA": "COMO-MONTE OLIMPINO",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4636",
    "ITA": "COMO",
    "state_code": "CO",
    "cab": "109009",
    "cap": "22100"
  },
  {
    "key": "4637",
    "ITA": "COMPIANO",
    "state_code": "PR",
    "cab": "660506",
    "cap": "43053"
  },
  {
    "key": "4638",
    "ITA": "COMPIANO-CERESETO",
    "state_code": "PR",
    "cab": "660506",
    "cap": "43053"
  },
  {
    "key": "4639",
    "ITA": "COMPIANO-STRELA",
    "state_code": "PR",
    "cab": "660506",
    "cap": "43053"
  },
  {
    "key": "4640",
    "ITA": "COMUN NUOVO",
    "state_code": "BG",
    "cab": "529305",
    "cap": "24040"
  },
  {
    "key": "4641",
    "ITA": "COMUNANZA",
    "state_code": "AP",
    "cab": "694109",
    "cap": "63044"
  },
  {
    "key": "4642",
    "ITA": "COMUNANZA-CROCE DI CASALE",
    "state_code": "AP",
    "cab": "694109",
    "cap": "63044"
  },
  {
    "key": "4643",
    "ITA": "CONA-CANTARANA",
    "state_code": "VE",
    "cab": "360602",
    "cap": "30010"
  },
  {
    "key": "4644",
    "ITA": "CONA",
    "state_code": "VE",
    "cab": "360602",
    "cap": "30010"
  },
  {
    "key": "4645",
    "ITA": "CONA-PEGOLOTTE",
    "state_code": "VE",
    "cab": "360602",
    "cap": "30010"
  },
  {
    "key": "4646",
    "ITA": "CONCA CASALE",
    "state_code": "IS",
    "cab": "186114",
    "cap": "86070"
  },
  {
    "key": "4647",
    "ITA": "CONCA DEI MARINI",
    "state_code": "SA",
    "cab": "952465",
    "cap": "84010"
  },
  {
    "key": "4648",
    "ITA": "CONCA DELLA CAMPANIA-CAVE",
    "state_code": "CE",
    "cab": "949271",
    "cap": "81044"
  },
  {
    "key": "4649",
    "ITA": "CONCA DELLA CAMPANIA",
    "state_code": "CE",
    "cab": "949271",
    "cap": "81044"
  },
  {
    "key": "4650",
    "ITA": "CONCA DELLA CAMPANIA-ORCHI",
    "state_code": "CE",
    "cab": "949271",
    "cap": "81044"
  },
  {
    "key": "4651",
    "ITA": "CONCAMARISE",
    "state_code": "VR",
    "cab": "594309",
    "cap": "37050"
  },
  {
    "key": "4652",
    "ITA": "CONCAMARISE-PIAZZA",
    "state_code": "VR",
    "cab": "594309",
    "cap": "37050"
  },
  {
    "key": "4653",
    "ITA": "CONCEI",
    "state_code": "TN",
    "cab": "890707",
    "cap": "38060"
  },
  {
    "key": "4654",
    "ITA": "CONCERVIANO",
    "state_code": "RI",
    "cab": "947184",
    "cap": "2020"
  },
  {
    "key": "4655",
    "ITA": "CONCERVIANO-VACCARECCIA",
    "state_code": "RI",
    "cab": "947184",
    "cap": "2020"
  },
  {
    "key": "4656",
    "ITA": "CONCESIO",
    "state_code": "BS",
    "cab": "544106",
    "cap": "25062"
  },
  {
    "key": "4657",
    "ITA": "CONCESIO-SAN VIGILIO",
    "state_code": "BS",
    "cab": "544106",
    "cap": "25062"
  },
  {
    "key": "4658",
    "ITA": "CONCO",
    "state_code": "VI",
    "cab": "603209",
    "cap": "36062"
  },
  {
    "key": "4659",
    "ITA": "CONCO-FONTANELLE",
    "state_code": "VI",
    "cab": "603209",
    "cap": "36062"
  },
  {
    "key": "4660",
    "ITA": "CONCORDIA SAGITTARIA",
    "state_code": "VE",
    "cab": "360701",
    "cap": "30023"
  },
  {
    "key": "4661",
    "ITA": "CONCORDIA SAGITTARIA-SINDACALE",
    "state_code": "VE",
    "cab": "360701",
    "cap": "30023"
  },
  {
    "key": "4662",
    "ITA": "CONCORDIA SULLA SECCHIA-SAN GIOVANNI",
    "state_code": "MO",
    "cab": "667303",
    "cap": "41033"
  },
  {
    "key": "4663",
    "ITA": "CONCORDIA SULLA SECCHIA",
    "state_code": "MO",
    "cab": "667303",
    "cap": "41033"
  },
  {
    "key": "4664",
    "ITA": "CONCORDIA SULLA SECCHIA-FOSSA",
    "state_code": "MO",
    "cab": "667303",
    "cap": "41033"
  },
  {
    "key": "4665",
    "ITA": "CONCORDIA SULLA SECCHIA-VALLALTA",
    "state_code": "MO",
    "cab": "667303",
    "cap": "41033"
  },
  {
    "key": "4666",
    "ITA": "CONCORDIA SULLA SECCHIA-SANTA CATERINA",
    "state_code": "MO",
    "cab": "667303",
    "cap": "41033"
  },
  {
    "key": "4667",
    "ITA": "CONCOREZZO",
    "state_code": "MB",
    "cab": "329805",
    "cap": "20049"
  },
  {
    "key": "4668",
    "ITA": "CONDINO",
    "state_code": "TN",
    "cab": "347005",
    "cap": "38083"
  },
  {
    "key": "4669",
    "ITA": "CONDOFURI-SAN CARLO",
    "state_code": "RC",
    "cab": "179994",
    "cap": "89030"
  },
  {
    "key": "4670",
    "ITA": "CONDOFURI",
    "state_code": "RC",
    "cab": "179994",
    "cap": "89030"
  },
  {
    "key": "4671",
    "ITA": "CONDOFURI-CONDOFURI MARINA",
    "state_code": "RC",
    "cab": "179994",
    "cap": "89030"
  },
  {
    "key": "4672",
    "ITA": "CONDOVE",
    "state_code": "TO",
    "cab": "304204",
    "cap": "10055"
  },
  {
    "key": "4673",
    "ITA": "CONDRO'",
    "state_code": "ME",
    "cab": "961292",
    "cap": "98040"
  },
  {
    "key": "4674",
    "ITA": "CONEGLIANO-SCOMIGO",
    "state_code": "TV",
    "cab": "616201",
    "cap": "31015"
  },
  {
    "key": "4675",
    "ITA": "CONEGLIANO",
    "state_code": "TV",
    "cab": "616201",
    "cap": "31015"
  },
  {
    "key": "4676",
    "ITA": "CONEGLIANO-COLLALBRIGO",
    "state_code": "TV",
    "cab": "616201",
    "cap": "31015"
  },
  {
    "key": "4677",
    "ITA": "CONFIENZA",
    "state_code": "PV",
    "cab": "558007",
    "cap": "27030"
  },
  {
    "key": "4678",
    "ITA": "CONFIGNI",
    "state_code": "RI",
    "cab": "947192",
    "cap": "2040"
  },
  {
    "key": "4679",
    "ITA": "CONFLENTI-CONFLENTI INFERIORE",
    "state_code": "CZ",
    "cab": "912824",
    "cap": "88040"
  },
  {
    "key": "4680",
    "ITA": "CONFLENTI",
    "state_code": "CZ",
    "cab": "912824",
    "cap": "88040"
  },
  {
    "key": "4681",
    "ITA": "CONFLENTI-SAN MAZZEO",
    "state_code": "CZ",
    "cab": "912824",
    "cap": "88040"
  },
  {
    "key": "4682",
    "ITA": "CONIOLO",
    "state_code": "AL",
    "cab": "921080",
    "cap": "15030"
  },
  {
    "key": "4683",
    "ITA": "CONSELICE",
    "state_code": "RA",
    "cab": "675405",
    "cap": "48017"
  },
  {
    "key": "4684",
    "ITA": "CONSELICE-LAVEZZOLA",
    "state_code": "RA",
    "cab": "675405",
    "cap": "48017"
  },
  {
    "key": "4685",
    "ITA": "CONSELICE-SAN PATRIZIO",
    "state_code": "RA",
    "cab": "675405",
    "cap": "48017"
  },
  {
    "key": "4686",
    "ITA": "CONSELVE",
    "state_code": "PD",
    "cab": "625400",
    "cap": "35026"
  },
  {
    "key": "4687",
    "ITA": "CONSIGLIO DI RUMO-SAN GREGORIO",
    "state_code": "CO",
    "cab": "925446",
    "cap": "22010"
  },
  {
    "key": "4688",
    "ITA": "CONSIGLIO DI RUMO",
    "state_code": "CO",
    "cab": "925446",
    "cap": "22010"
  },
  {
    "key": "4689",
    "ITA": "CONTESSA ENTELLINA",
    "state_code": "PA",
    "cab": "433201",
    "cap": "90030"
  },
  {
    "key": "4690",
    "ITA": "CONTIGLIANO-MONTISOLA",
    "state_code": "RI",
    "cab": "736009",
    "cap": "2045"
  },
  {
    "key": "4691",
    "ITA": "CONTIGLIANO",
    "state_code": "RI",
    "cab": "736009",
    "cap": "2043"
  },
  {
    "key": "4692",
    "ITA": "CONTIGLIANO-SAN FILIPPO",
    "state_code": "RI",
    "cab": "736009",
    "cap": "2043"
  },
  {
    "key": "4693",
    "ITA": "CONTRADA",
    "state_code": "AV",
    "cab": "396408",
    "cap": "83020"
  },
  {
    "key": "4694",
    "ITA": "CONTROGUERRA",
    "state_code": "TE",
    "cab": "768705",
    "cap": "64010"
  },
  {
    "key": "4695",
    "ITA": "CONTRONE",
    "state_code": "SA",
    "cab": "952473",
    "cap": "84020"
  },
  {
    "key": "4696",
    "ITA": "CONTURSI TERME",
    "state_code": "SA",
    "cab": "761908",
    "cap": "84024"
  },
  {
    "key": "4697",
    "ITA": "CONTURSI TERME-BAGNI DI CONTURSI",
    "state_code": "SA",
    "cab": "761908",
    "cap": "84024"
  },
  {
    "key": "4698",
    "ITA": "CONVERSANO",
    "state_code": "BA",
    "cab": "414607",
    "cap": "70014"
  },
  {
    "key": "4699",
    "ITA": "CONVERSANO-TRIGGIANELLO",
    "state_code": "BA",
    "cab": "414607",
    "cap": "70014"
  },
  {
    "key": "4700",
    "ITA": "CONZA DELLA CAMPANIA",
    "state_code": "AV",
    "cab": "858001",
    "cap": "83040"
  },
  {
    "key": "4701",
    "ITA": "CONZANO-SAN MAURIZIO",
    "state_code": "AL",
    "cab": "921098",
    "cap": "15030"
  },
  {
    "key": "4702",
    "ITA": "CONZANO",
    "state_code": "AL",
    "cab": "921098",
    "cap": "15030"
  },
  {
    "key": "4703",
    "ITA": "COPERTINO",
    "state_code": "LE",
    "cab": "795807",
    "cap": "73043"
  },
  {
    "key": "4704",
    "ITA": "COPIANO",
    "state_code": "PV",
    "cab": "558106",
    "cap": "27010"
  },
  {
    "key": "4705",
    "ITA": "COPPARO-ZENZALINO",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4706",
    "ITA": "COPPARO",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4707",
    "ITA": "COPPARO-AMBROGIO",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4708",
    "ITA": "COPPARO-CESTA",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4709",
    "ITA": "COPPARO-COCCANILE",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4710",
    "ITA": "COPPARO-FOSSALTA",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4711",
    "ITA": "COPPARO-GRADIZZA",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4712",
    "ITA": "COPPARO-SABBIONCELLO SAN PIETRO",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4713",
    "ITA": "COPPARO-SABBIONCELLO SAN VITTORE",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4714",
    "ITA": "COPPARO-SALETTA",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4715",
    "ITA": "COPPARO-TAMARA",
    "state_code": "FE",
    "cab": "672105",
    "cap": "44034"
  },
  {
    "key": "4716",
    "ITA": "CORANA",
    "state_code": "PV",
    "cab": "558205",
    "cap": "27050"
  },
  {
    "key": "4717",
    "ITA": "CORANA-GHIAIE",
    "state_code": "PV",
    "cab": "558205",
    "cap": "27050"
  },
  {
    "key": "4718",
    "ITA": "CORATO",
    "state_code": "BA",
    "cab": "414706",
    "cap": "70033"
  },
  {
    "key": "4719",
    "ITA": "CORBARA",
    "state_code": "SA",
    "cab": "712802",
    "cap": "84010"
  },
  {
    "key": "4720",
    "ITA": "CORBETTA-CERELLO",
    "state_code": "MI",
    "cab": "329904",
    "cap": "20011"
  },
  {
    "key": "4721",
    "ITA": "CORBETTA",
    "state_code": "MI",
    "cab": "329904",
    "cap": "20011"
  },
  {
    "key": "4722",
    "ITA": "CORBETTA-BATTUELLO",
    "state_code": "MI",
    "cab": "329904",
    "cap": "20011"
  },
  {
    "key": "4723",
    "ITA": "CORBOLA",
    "state_code": "RO",
    "cab": "632505",
    "cap": "45015"
  },
  {
    "key": "4724",
    "ITA": "CORCHIANO",
    "state_code": "VT",
    "cab": "730507",
    "cap": "1030"
  },
  {
    "key": "4725",
    "ITA": "CORCIANO-MANTIGNANA",
    "state_code": "PG",
    "cab": "384008",
    "cap": "6073"
  },
  {
    "key": "4726",
    "ITA": "CORCIANO-SAN MARIANO",
    "state_code": "PG",
    "cab": "384008",
    "cap": "6070"
  },
  {
    "key": "4727",
    "ITA": "CORCIANO",
    "state_code": "PG",
    "cab": "384008",
    "cap": "6073"
  },
  {
    "key": "4728",
    "ITA": "CORCIANO-ELLERA",
    "state_code": "PG",
    "cab": "384008",
    "cap": "6070"
  },
  {
    "key": "4729",
    "ITA": "CORDENONS-MUSIL",
    "state_code": "PN",
    "cab": "648501",
    "cap": "33084"
  },
  {
    "key": "4730",
    "ITA": "CORDENONS-VILLA D'ARCO",
    "state_code": "PN",
    "cab": "648501",
    "cap": "33084"
  },
  {
    "key": "4731",
    "ITA": "CORDENONS",
    "state_code": "PN",
    "cab": "648501",
    "cap": "33084"
  },
  {
    "key": "4732",
    "ITA": "CORDIGNANO-VILLA DI VILLA",
    "state_code": "TV",
    "cab": "616300",
    "cap": "31016"
  },
  {
    "key": "4733",
    "ITA": "CORDIGNANO",
    "state_code": "TV",
    "cab": "616300",
    "cap": "31016"
  },
  {
    "key": "4734",
    "ITA": "CORDOVADO",
    "state_code": "PN",
    "cab": "648600",
    "cap": "33075"
  },
  {
    "key": "4735",
    "ITA": "COREDO",
    "state_code": "TN",
    "cab": "347104",
    "cap": "38010"
  },
  {
    "key": "4736",
    "ITA": "COREGLIA ANTELMINELLI",
    "state_code": "LU",
    "cab": "701409",
    "cap": "55025"
  },
  {
    "key": "4737",
    "ITA": "COREGLIA ANTELMINELLI-CALAVORNO",
    "state_code": "LU",
    "cab": "701409",
    "cap": "55025"
  },
  {
    "key": "4738",
    "ITA": "COREGLIA ANTELMINELLI-GHIVIZZANO",
    "state_code": "LU",
    "cab": "701409",
    "cap": "55025"
  },
  {
    "key": "4739",
    "ITA": "COREGLIA ANTELMINELLI-PIANO DI COREGLIA",
    "state_code": "LU",
    "cab": "701409",
    "cap": "55025"
  },
  {
    "key": "4740",
    "ITA": "COREGLIA ANTELMINELLI-TEREGLIO",
    "state_code": "LU",
    "cab": "701409",
    "cap": "55025"
  },
  {
    "key": "4741",
    "ITA": "COREGLIA LIGURE",
    "state_code": "GE",
    "cab": "903120",
    "cap": "16040"
  },
  {
    "key": "4742",
    "ITA": "CORENO AUSONIO",
    "state_code": "FR",
    "cab": "948331",
    "cap": "3040"
  },
  {
    "key": "4743",
    "ITA": "CORFINIO",
    "state_code": "AQ",
    "cab": "884908",
    "cap": "67030"
  },
  {
    "key": "4744",
    "ITA": "CORI",
    "state_code": "LT",
    "cab": "739607",
    "cap": "4010"
  },
  {
    "key": "4745",
    "ITA": "CORI-GIULIANELLO",
    "state_code": "LT",
    "cab": "739607",
    "cap": "4010"
  },
  {
    "key": "4746",
    "ITA": "CORIANO",
    "state_code": "RN",
    "cab": "677708",
    "cap": "47853"
  },
  {
    "key": "4747",
    "ITA": "CORIANO-CERASOLO",
    "state_code": "RN",
    "cab": "677708",
    "cap": "47853"
  },
  {
    "key": "4748",
    "ITA": "CORIANO-OSPEDALETTO",
    "state_code": "RN",
    "cab": "677708",
    "cap": "47853"
  },
  {
    "key": "4749",
    "ITA": "CORIANO-SANT'ANDREA IN BESANIGO",
    "state_code": "RN",
    "cab": "677708",
    "cap": "47853"
  },
  {
    "key": "4750",
    "ITA": "CORIGLIANO CALABRO-VILLAGGIO FRASSO",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87064"
  },
  {
    "key": "4751",
    "ITA": "CORIGLIANO CALABRO",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87064"
  },
  {
    "key": "4752",
    "ITA": "CORIGLIANO CALABRO-CANTINELLA",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87060"
  },
  {
    "key": "4753",
    "ITA": "CORIGLIANO CALABRO-CORIGLIANO CALABRO STAZIONE",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87064"
  },
  {
    "key": "4754",
    "ITA": "CORIGLIANO CALABRO-CORIGLIANO SCALO",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87064"
  },
  {
    "key": "4755",
    "ITA": "CORIGLIANO CALABRO-FABRIZIO",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87064"
  },
  {
    "key": "4756",
    "ITA": "CORIGLIANO CALABRO-MARINA DI SCHIAVONEA",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87064"
  },
  {
    "key": "4757",
    "ITA": "CORIGLIANO CALABRO-SCHIAVONEA",
    "state_code": "CS",
    "cab": "806901",
    "cap": "87064"
  },
  {
    "key": "4758",
    "ITA": "CORIGLIANO D'OTRANTO",
    "state_code": "LE",
    "cab": "795906",
    "cap": "73022"
  },
  {
    "key": "4759",
    "ITA": "CORINALDO",
    "state_code": "AN",
    "cab": "373308",
    "cap": "60013"
  },
  {
    "key": "4760",
    "ITA": "CORIO-BENNE",
    "state_code": "TO",
    "cab": "304303",
    "cap": "10070"
  },
  {
    "key": "4761",
    "ITA": "CORIO",
    "state_code": "TO",
    "cab": "304303",
    "cap": "10070"
  },
  {
    "key": "4762",
    "ITA": "CORLEONE-FICUZZA",
    "state_code": "PA",
    "cab": "433300",
    "cap": "90034"
  },
  {
    "key": "4763",
    "ITA": "CORLEONE",
    "state_code": "PA",
    "cab": "433300",
    "cap": "90034"
  },
  {
    "key": "4764",
    "ITA": "CORLETO MONFORTE",
    "state_code": "SA",
    "cab": "952499",
    "cap": "84020"
  },
  {
    "key": "4765",
    "ITA": "CORLETO PERTICARA",
    "state_code": "PZ",
    "cab": "419606",
    "cap": "85012"
  },
  {
    "key": "4766",
    "ITA": "CORMANO",
    "state_code": "MI",
    "cab": "330001",
    "cap": "20032"
  },
  {
    "key": "4767",
    "ITA": "CORMANO-BRUSUGLIO",
    "state_code": "MI",
    "cab": "330001",
    "cap": "20032"
  },
  {
    "key": "4768",
    "ITA": "CORMANO-OSPITALETTO",
    "state_code": "MI",
    "cab": "330001",
    "cap": "20032"
  },
  {
    "key": "4769",
    "ITA": "CORMONS",
    "state_code": "GO",
    "cab": "645507",
    "cap": "34071"
  },
  {
    "key": "4770",
    "ITA": "CORMONS-BRAZZANO",
    "state_code": "GO",
    "cab": "645507",
    "cap": "34071"
  },
  {
    "key": "4771",
    "ITA": "CORMONS-BORGNANO",
    "state_code": "GO",
    "cab": "645507",
    "cap": "34071"
  },
  {
    "key": "4772",
    "ITA": "CORNA IMAGNA",
    "state_code": "BG",
    "cab": "928044",
    "cap": "24030"
  },
  {
    "key": "4773",
    "ITA": "CORNALBA",
    "state_code": "BG",
    "cab": "928838",
    "cap": "24017"
  },
  {
    "key": "4774",
    "ITA": "CORNALE",
    "state_code": "PV",
    "cab": "930420",
    "cap": "27050"
  },
  {
    "key": "4775",
    "ITA": "CORNAREDO-SAN PIETRO ALL'OLMO",
    "state_code": "MI",
    "cab": "330100",
    "cap": "20010"
  },
  {
    "key": "4776",
    "ITA": "CORNAREDO",
    "state_code": "MI",
    "cab": "330100",
    "cap": "20010"
  },
  {
    "key": "4777",
    "ITA": "CORNATE D'ADDA-PORTO D'ADDA",
    "state_code": "MB",
    "cab": "330209",
    "cap": "20040"
  },
  {
    "key": "4778",
    "ITA": "CORNATE D'ADDA-COLNAGO",
    "state_code": "MB",
    "cab": "330209",
    "cap": "20040"
  },
  {
    "key": "4779",
    "ITA": "CORNATE D'ADDA",
    "state_code": "MB",
    "cab": "330209",
    "cap": "20040"
  },
  {
    "key": "4780",
    "ITA": "CORNEDO ALL'ISARCO",
    "state_code": "BZ",
    "cab": "583203",
    "cap": "39053"
  },
  {
    "key": "4781",
    "ITA": "CORNEDO ALL'ISARCO-SAN VALENTINO IN CAMPO",
    "state_code": "BZ",
    "cab": "583203",
    "cap": "39053"
  },
  {
    "key": "4782",
    "ITA": "CORNEDO ALL'ISARCO-COLLEPIETRA",
    "state_code": "BZ",
    "cab": "583203",
    "cap": "39053"
  },
  {
    "key": "4783",
    "ITA": "CORNEDO ALL'ISARCO-CARDANO",
    "state_code": "BZ",
    "cab": "583203",
    "cap": "39053"
  },
  {
    "key": "4784",
    "ITA": "CORNEDO ALL'ISARCO-PRATO ALL'ISARCO",
    "state_code": "BZ",
    "cab": "583203",
    "cap": "39053"
  },
  {
    "key": "4785",
    "ITA": "CORNEDO ALL'ISARCO-CONTRADA VAL D'EGA",
    "state_code": "BZ",
    "cab": "583203",
    "cap": "39053"
  },
  {
    "key": "4786",
    "ITA": "CORNEDO VICENTINO",
    "state_code": "VI",
    "cab": "603308",
    "cap": "36073"
  },
  {
    "key": "4787",
    "ITA": "CORNEDO VICENTINO-CEREDA",
    "state_code": "VI",
    "cab": "603308",
    "cap": "36073"
  },
  {
    "key": "4788",
    "ITA": "CORNEGLIANO LAUDENSE-MUZZA SANT'ANGELO",
    "state_code": "LO",
    "cab": "805309",
    "cap": "26854"
  },
  {
    "key": "4789",
    "ITA": "CORNEGLIANO LAUDENSE",
    "state_code": "LO",
    "cab": "805309",
    "cap": "26854"
  },
  {
    "key": "4790",
    "ITA": "CORNELIANO D'ALBA",
    "state_code": "CN",
    "cab": "462200",
    "cap": "12040"
  },
  {
    "key": "4791",
    "ITA": "CORNIGLIO",
    "state_code": "PR",
    "cab": "657106",
    "cap": "43021"
  },
  {
    "key": "4792",
    "ITA": "CORNIGLIO-BEDUZZO",
    "state_code": "PR",
    "cab": "657106",
    "cap": "43020"
  },
  {
    "key": "4793",
    "ITA": "CORNIGLIO-BOSCO",
    "state_code": "PR",
    "cab": "657106",
    "cap": "43021"
  },
  {
    "key": "4794",
    "ITA": "CORNIGLIO-VESTOLA GHIARE",
    "state_code": "PR",
    "cab": "657106",
    "cap": "43021"
  },
  {
    "key": "4795",
    "ITA": "CORNO DI ROSAZZO",
    "state_code": "UD",
    "cab": "637702",
    "cap": "33040"
  },
  {
    "key": "4796",
    "ITA": "CORNO GIOVINE",
    "state_code": "LO",
    "cab": "565101",
    "cap": "26846"
  },
  {
    "key": "4797",
    "ITA": "CORNOVECCHIO",
    "state_code": "LO",
    "cab": "903849",
    "cap": "26842"
  },
  {
    "key": "4798",
    "ITA": "CORNUDA",
    "state_code": "TV",
    "cab": "616409",
    "cap": "31041"
  },
  {
    "key": "4799",
    "ITA": "CORREGGIO-FOSDONDO",
    "state_code": "RE",
    "cab": "663203",
    "cap": "42015"
  },
  {
    "key": "4800",
    "ITA": "CORREGGIO-SAN MARTINO",
    "state_code": "RE",
    "cab": "663203",
    "cap": "42015"
  },
  {
    "key": "4801",
    "ITA": "CORREGGIO-PRATO",
    "state_code": "RE",
    "cab": "663203",
    "cap": "42015"
  },
  {
    "key": "4802",
    "ITA": "CORREGGIO",
    "state_code": "RE",
    "cab": "663203",
    "cap": "42015"
  },
  {
    "key": "4803",
    "ITA": "CORREGGIO-BUDRIO",
    "state_code": "RE",
    "cab": "663203",
    "cap": "42015"
  },
  {
    "key": "4804",
    "ITA": "CORREZZANA",
    "state_code": "MB",
    "cab": "275065",
    "cap": "20050"
  },
  {
    "key": "4805",
    "ITA": "CORREZZOLA-CIVE'",
    "state_code": "PD",
    "cab": "630202",
    "cap": "35020"
  },
  {
    "key": "4806",
    "ITA": "CORREZZOLA",
    "state_code": "PD",
    "cab": "630202",
    "cap": "35020"
  },
  {
    "key": "4807",
    "ITA": "CORRIDO",
    "state_code": "CO",
    "cab": "925453",
    "cap": "22010"
  },
  {
    "key": "4808",
    "ITA": "CORRIDONIA-PASSO DEL BIDOLLO",
    "state_code": "MC",
    "cab": "688903",
    "cap": "62014"
  },
  {
    "key": "4809",
    "ITA": "CORRIDONIA-COLBUCCARO",
    "state_code": "MC",
    "cab": "688903",
    "cap": "62014"
  },
  {
    "key": "4810",
    "ITA": "CORRIDONIA",
    "state_code": "MC",
    "cab": "688903",
    "cap": "62014"
  },
  {
    "key": "4811",
    "ITA": "CORRIDONIA-SAN CLAUDIO",
    "state_code": "MC",
    "cab": "688903",
    "cap": "62014"
  },
  {
    "key": "4812",
    "ITA": "CORROPOLI",
    "state_code": "TE",
    "cab": "768804",
    "cap": "64013"
  },
  {
    "key": "4813",
    "ITA": "CORSANO",
    "state_code": "LE",
    "cab": "796003",
    "cap": "73033"
  },
  {
    "key": "4814",
    "ITA": "CORSICO",
    "state_code": "MI",
    "cab": "330308",
    "cap": "20094"
  },
  {
    "key": "4815",
    "ITA": "CORSIONE",
    "state_code": "AT",
    "cab": "920132",
    "cap": "14020"
  },
  {
    "key": "4816",
    "ITA": "CORTACCIA SULLA STRADA DEL VINO",
    "state_code": "BZ",
    "cab": "583302",
    "cap": "39040"
  },
  {
    "key": "4817",
    "ITA": "CORTALE",
    "state_code": "CZ",
    "cab": "429506",
    "cap": "88020"
  },
  {
    "key": "4818",
    "ITA": "CORTANDONE",
    "state_code": "AT",
    "cab": "920140",
    "cap": "14013"
  },
  {
    "key": "4819",
    "ITA": "CORTANZE",
    "state_code": "AT",
    "cab": "920157",
    "cap": "14020"
  },
  {
    "key": "4820",
    "ITA": "CORTAZZONE",
    "state_code": "AT",
    "cab": "920165",
    "cap": "14010"
  },
  {
    "key": "4821",
    "ITA": "CORTE BRUGNATELLA-MARSAGLIA",
    "state_code": "PC",
    "cab": "713008",
    "cap": "29020"
  },
  {
    "key": "4822",
    "ITA": "CORTE BRUGNATELLA",
    "state_code": "PC",
    "cab": "713008",
    "cap": "29020"
  },
  {
    "key": "4823",
    "ITA": "CORTE DE' CORTESI CON CIGNONE-CORTE DE' CORTESI",
    "state_code": "CR",
    "cab": "568105",
    "cap": "26020"
  },
  {
    "key": "4824",
    "ITA": "CORTE DE' CORTESI CON CIGNONE-CIGNONE",
    "state_code": "CR",
    "cab": "568105",
    "cap": "26020"
  },
  {
    "key": "4825",
    "ITA": "CORTE DE' CORTESI CON CIGNONE",
    "state_code": "CR",
    "cab": "568105",
    "cap": "26020"
  },
  {
    "key": "4826",
    "ITA": "CORTE DE' FRATI",
    "state_code": "CR",
    "cab": "568204",
    "cap": "26010"
  },
  {
    "key": "4827",
    "ITA": "CORTE FRANCA-COLOMBARO",
    "state_code": "BS",
    "cab": "544205",
    "cap": "25040"
  },
  {
    "key": "4828",
    "ITA": "CORTE FRANCA",
    "state_code": "BS",
    "cab": "544205",
    "cap": "25040"
  },
  {
    "key": "4829",
    "ITA": "CORTE FRANCA-BONOMELLI",
    "state_code": "BS",
    "cab": "544205",
    "cap": "25040"
  },
  {
    "key": "4830",
    "ITA": "CORTE FRANCA-BORGONATO",
    "state_code": "BS",
    "cab": "544205",
    "cap": "25040"
  },
  {
    "key": "4831",
    "ITA": "CORTE FRANCA-TIMOLINE",
    "state_code": "BS",
    "cab": "544205",
    "cap": "25040"
  },
  {
    "key": "4832",
    "ITA": "CORTE FRANCA-NIGOLINE",
    "state_code": "BS",
    "cab": "544205",
    "cap": "25040"
  },
  {
    "key": "4833",
    "ITA": "CORTE PALASIO",
    "state_code": "LO",
    "cab": "330407",
    "cap": "26834"
  },
  {
    "key": "4834",
    "ITA": "CORTE PALASIO-CADILANA",
    "state_code": "LO",
    "cab": "330407",
    "cap": "26834"
  },
  {
    "key": "4835",
    "ITA": "CORTE PALASIO-TERRAVERDE",
    "state_code": "LO",
    "cab": "330407",
    "cap": "26834"
  },
  {
    "key": "4836",
    "ITA": "CORTEMAGGIORE",
    "state_code": "PC",
    "cab": "652800",
    "cap": "29016"
  },
  {
    "key": "4837",
    "ITA": "CORTEMILIA",
    "state_code": "CN",
    "cab": "462309",
    "cap": "12074"
  },
  {
    "key": "4838",
    "ITA": "CORTENO GOLGI",
    "state_code": "BS",
    "cab": "544304",
    "cap": "25040"
  },
  {
    "key": "4839",
    "ITA": "CORTENO GOLGI-SANTICOLO",
    "state_code": "BS",
    "cab": "544304",
    "cap": "25040"
  },
  {
    "key": "4840",
    "ITA": "CORTENO GOLGI-GALLENO",
    "state_code": "BS",
    "cab": "544304",
    "cap": "25040"
  },
  {
    "key": "4841",
    "ITA": "CORTENOVA-BINDO",
    "state_code": "LC",
    "cab": "519306",
    "cap": "23813"
  },
  {
    "key": "4842",
    "ITA": "CORTENOVA",
    "state_code": "LC",
    "cab": "519306",
    "cap": "23813"
  },
  {
    "key": "4843",
    "ITA": "CORTENUOVA",
    "state_code": "BG",
    "cab": "538306",
    "cap": "24050"
  },
  {
    "key": "4844",
    "ITA": "CORTEOLONA",
    "state_code": "PV",
    "cab": "558304",
    "cap": "27014"
  },
  {
    "key": "4845",
    "ITA": "CORTIGLIONE",
    "state_code": "AT",
    "cab": "920173",
    "cap": "14040"
  },
  {
    "key": "4846",
    "ITA": "CORTINA D'AMPEZZO-VEROCAI",
    "state_code": "BL",
    "cab": "610709",
    "cap": "32043"
  },
  {
    "key": "4847",
    "ITA": "CORTINA D'AMPEZZO-ACQUABONA",
    "state_code": "BL",
    "cab": "610709",
    "cap": "32043"
  },
  {
    "key": "4848",
    "ITA": "CORTINA D'AMPEZZO-ZUEL",
    "state_code": "BL",
    "cab": "610709",
    "cap": "32043"
  },
  {
    "key": "4849",
    "ITA": "CORTINA D'AMPEZZO",
    "state_code": "BL",
    "cab": "610709",
    "cap": "32043"
  },
  {
    "key": "4850",
    "ITA": "CORTINA SULLA STRADA DEL VINO",
    "state_code": "BZ",
    "cab": "932723",
    "cap": "39040"
  },
  {
    "key": "4851",
    "ITA": "CORTINO-PAGLIAROLI",
    "state_code": "TE",
    "cab": "768903",
    "cap": "64040"
  },
  {
    "key": "4852",
    "ITA": "CORTINO",
    "state_code": "TE",
    "cab": "768903",
    "cap": "64040"
  },
  {
    "key": "4853",
    "ITA": "CORTINO-PADULA",
    "state_code": "TE",
    "cab": "768903",
    "cap": "64040"
  },
  {
    "key": "4854",
    "ITA": "CORTONA-TERONTOLA STAZIONE",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4855",
    "ITA": "CORTONA-TERONTOLA",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4856",
    "ITA": "CORTONA-CAMUCIA",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4857",
    "ITA": "CORTONA",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4858",
    "ITA": "CORTONA-SANT'ANDREA DI SORBELLO",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4859",
    "ITA": "CORTONA-FRATTA",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4860",
    "ITA": "CORTONA-MONTANARE",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4861",
    "ITA": "CORTONA-MERCATALE",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4862",
    "ITA": "CORTONA-CAPEZZINE",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4863",
    "ITA": "CORTONA-CENTOIA",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4864",
    "ITA": "CORTONA-SANTA CATERINA",
    "state_code": "AR",
    "cab": "254003",
    "cap": "52044"
  },
  {
    "key": "4865",
    "ITA": "CORVARA",
    "state_code": "PE",
    "cab": "954057",
    "cap": "65020"
  },
  {
    "key": "4866",
    "ITA": "CORVARA IN BADIA",
    "state_code": "BZ",
    "cab": "583401",
    "cap": "39033"
  },
  {
    "key": "4867",
    "ITA": "CORVARA IN BADIA-COLFOSCO",
    "state_code": "BZ",
    "cab": "583401",
    "cap": "39033"
  },
  {
    "key": "4868",
    "ITA": "CORVINO SAN QUIRICO",
    "state_code": "PV",
    "cab": "930438",
    "cap": "27050"
  },
  {
    "key": "4869",
    "ITA": "CORVINO SAN QUIRICO-FUMO",
    "state_code": "PV",
    "cab": "930438",
    "cap": "27050"
  },
  {
    "key": "4870",
    "ITA": "CORZANO",
    "state_code": "BS",
    "cab": "838201",
    "cap": "25030"
  },
  {
    "key": "4871",
    "ITA": "COSEANO-CISTERNA",
    "state_code": "UD",
    "cab": "637801",
    "cap": "33030"
  },
  {
    "key": "4872",
    "ITA": "COSEANO",
    "state_code": "UD",
    "cab": "637801",
    "cap": "33030"
  },
  {
    "key": "4873",
    "ITA": "COSENZA-SANT'IPPOLITO DI COSENZA",
    "state_code": "CS",
    "cab": "162008",
    "cap": "87100"
  },
  {
    "key": "4874",
    "ITA": "COSENZA-DONNICI SUPERIORE",
    "state_code": "CS",
    "cab": "162008",
    "cap": "87100"
  },
  {
    "key": "4875",
    "ITA": "COSENZA-DONNICI INFERIORE",
    "state_code": "CS",
    "cab": "162008",
    "cap": "87100"
  },
  {
    "key": "4876",
    "ITA": "COSENZA-BORGO PARTENOPE",
    "state_code": "CS",
    "cab": "162008",
    "cap": "87100"
  },
  {
    "key": "4877",
    "ITA": "COSENZA",
    "state_code": "CS",
    "cab": "162008",
    "cap": "87100"
  },
  {
    "key": "4878",
    "ITA": "COSIO DI ARROSCIA",
    "state_code": "IM",
    "cab": "179085",
    "cap": "18023"
  },
  {
    "key": "4879",
    "ITA": "COSIO VALTELLINO-SACCO",
    "state_code": "SO",
    "cab": "523704",
    "cap": "23013"
  },
  {
    "key": "4880",
    "ITA": "COSIO VALTELLINO-COSIO STAZIONE",
    "state_code": "SO",
    "cab": "523704",
    "cap": "23013"
  },
  {
    "key": "4881",
    "ITA": "COSIO VALTELLINO-REGOLEDO",
    "state_code": "SO",
    "cab": "523704",
    "cap": "23013"
  },
  {
    "key": "4882",
    "ITA": "COSIO VALTELLINO",
    "state_code": "SO",
    "cab": "523704",
    "cap": "23013"
  },
  {
    "key": "4883",
    "ITA": "COSOLETO",
    "state_code": "RC",
    "cab": "960104",
    "cap": "89050"
  },
  {
    "key": "4884",
    "ITA": "COSOLETO-SITIZANO",
    "state_code": "RC",
    "cab": "960104",
    "cap": "89050"
  },
  {
    "key": "4885",
    "ITA": "COSSANO BELBO-SANTA LIBERA",
    "state_code": "CN",
    "cab": "470906",
    "cap": "12054"
  },
  {
    "key": "4886",
    "ITA": "COSSANO BELBO",
    "state_code": "CN",
    "cab": "470906",
    "cap": "12054"
  },
  {
    "key": "4887",
    "ITA": "COSSANO CANAVESE",
    "state_code": "TO",
    "cab": "665901",
    "cap": "10010"
  },
  {
    "key": "4888",
    "ITA": "COSSATO-PONTE GUELPA",
    "state_code": "BI",
    "cab": "444307",
    "cap": "13836"
  },
  {
    "key": "4889",
    "ITA": "COSSATO",
    "state_code": "BI",
    "cab": "444307",
    "cap": "13836"
  },
  {
    "key": "4890",
    "ITA": "COSSATO-AGLIETTI",
    "state_code": "BI",
    "cab": "444307",
    "cap": "13836"
  },
  {
    "key": "4891",
    "ITA": "COSSATO-CASTELLENGO",
    "state_code": "BI",
    "cab": "444307",
    "cap": "13836"
  },
  {
    "key": "4892",
    "ITA": "COSSERIA",
    "state_code": "SV",
    "cab": "923094",
    "cap": "17017"
  },
  {
    "key": "4893",
    "ITA": "COSSIGNANO",
    "state_code": "AP",
    "cab": "694208",
    "cap": "63030"
  },
  {
    "key": "4894",
    "ITA": "COSSOGNO",
    "state_code": "VB",
    "cab": "917187",
    "cap": "28801"
  },
  {
    "key": "4895",
    "ITA": "COSSOINE",
    "state_code": "SS",
    "cab": "873307",
    "cap": "7010"
  },
  {
    "key": "4896",
    "ITA": "COSSOMBRATO",
    "state_code": "AT",
    "cab": "920181",
    "cap": "14020"
  },
  {
    "key": "4897",
    "ITA": "COSTA DE' NOBILI",
    "state_code": "PV",
    "cab": "930446",
    "cap": "27010"
  },
  {
    "key": "4898",
    "ITA": "COSTA DI MEZZATE",
    "state_code": "BG",
    "cab": "895102",
    "cap": "24060"
  },
  {
    "key": "4899",
    "ITA": "COSTA DI ROVIGO",
    "state_code": "RO",
    "cab": "632604",
    "cap": "45023"
  },
  {
    "key": "4900",
    "ITA": "COSTA MASNAGA",
    "state_code": "LC",
    "cab": "512103",
    "cap": "23845"
  },
  {
    "key": "4901",
    "ITA": "COSTA MASNAGA-CAMISASCA",
    "state_code": "LC",
    "cab": "512103",
    "cap": "23845"
  },
  {
    "key": "4902",
    "ITA": "COSTA SERINA",
    "state_code": "BG",
    "cab": "812107",
    "cap": "24010"
  },
  {
    "key": "4903",
    "ITA": "COSTA VALLE IMAGNA",
    "state_code": "BG",
    "cab": "928069",
    "cap": "24030"
  },
  {
    "key": "4904",
    "ITA": "COSTA VESCOVATO",
    "state_code": "AL",
    "cab": "921106",
    "cap": "15050"
  },
  {
    "key": "4905",
    "ITA": "COSTA VOLPINO",
    "state_code": "BG",
    "cab": "529404",
    "cap": "24062"
  },
  {
    "key": "4906",
    "ITA": "COSTABISSARA",
    "state_code": "VI",
    "cab": "603407",
    "cap": "36030"
  },
  {
    "key": "4907",
    "ITA": "COSTABISSARA-MOTTA",
    "state_code": "VI",
    "cab": "603407",
    "cap": "36030"
  },
  {
    "key": "4908",
    "ITA": "COSTACCIARO",
    "state_code": "PG",
    "cab": "384107",
    "cap": "6021"
  },
  {
    "key": "4909",
    "ITA": "COSTACCIARO-VILLA COL DEI CANALI",
    "state_code": "PG",
    "cab": "384107",
    "cap": "6021"
  },
  {
    "key": "4910",
    "ITA": "COSTANZANA",
    "state_code": "VC",
    "cab": "687806",
    "cap": "13033"
  },
  {
    "key": "4911",
    "ITA": "COSTARAINERA",
    "state_code": "IM",
    "cab": "922393",
    "cap": "18017"
  },
  {
    "key": "4912",
    "ITA": "COSTERMANO-ALBARE' STAZIONE",
    "state_code": "VR",
    "cab": "600205",
    "cap": "37010"
  },
  {
    "key": "4913",
    "ITA": "COSTERMANO",
    "state_code": "VR",
    "cab": "600205",
    "cap": "37010"
  },
  {
    "key": "4914",
    "ITA": "COSTERMANO-CASTION VERONESE",
    "state_code": "VR",
    "cab": "600205",
    "cap": "37010"
  },
  {
    "key": "4915",
    "ITA": "COSTIGLIOLE D'ASTI",
    "state_code": "AT",
    "cab": "474106",
    "cap": "14055"
  },
  {
    "key": "4916",
    "ITA": "COSTIGLIOLE D'ASTI-BOGLIETTO",
    "state_code": "AT",
    "cab": "474106",
    "cap": "14055"
  },
  {
    "key": "4917",
    "ITA": "COSTIGLIOLE D'ASTI-MOTTA",
    "state_code": "AT",
    "cab": "474106",
    "cap": "14050"
  },
  {
    "key": "4918",
    "ITA": "COSTIGLIOLE SALUZZO",
    "state_code": "CN",
    "cab": "462408",
    "cap": "12024"
  },
  {
    "key": "4919",
    "ITA": "COTIGNOLA-BARBIANO",
    "state_code": "RA",
    "cab": "675504",
    "cap": "48010"
  },
  {
    "key": "4920",
    "ITA": "COTIGNOLA",
    "state_code": "RA",
    "cab": "675504",
    "cap": "48010"
  },
  {
    "key": "4921",
    "ITA": "COTIGNOLA-SAN SEVERO",
    "state_code": "RA",
    "cab": "675504",
    "cap": "48010"
  },
  {
    "key": "4922",
    "ITA": "COTRONEI",
    "state_code": "KR",
    "cab": "425405",
    "cap": "88836"
  },
  {
    "key": "4923",
    "ITA": "COTTANELLO",
    "state_code": "RI",
    "cab": "736108",
    "cap": "2040"
  },
  {
    "key": "4924",
    "ITA": "COURMAYEUR-DOLONNE",
    "state_code": "AO",
    "cab": "315606",
    "cap": "11013"
  },
  {
    "key": "4925",
    "ITA": "COURMAYEUR-ENTREVES",
    "state_code": "AO",
    "cab": "315606",
    "cap": "11013"
  },
  {
    "key": "4926",
    "ITA": "COURMAYEUR-LA SAXE",
    "state_code": "AO",
    "cab": "315606",
    "cap": "11013"
  },
  {
    "key": "4927",
    "ITA": "COURMAYEUR",
    "state_code": "AO",
    "cab": "315606",
    "cap": "11013"
  },
  {
    "key": "4928",
    "ITA": "COVO",
    "state_code": "BG",
    "cab": "529503",
    "cap": "24050"
  },
  {
    "key": "4929",
    "ITA": "COZZO",
    "state_code": "PV",
    "cab": "930453",
    "cap": "27030"
  },
  {
    "key": "4930",
    "ITA": "CRACO",
    "state_code": "MT",
    "cab": "958132",
    "cap": "75010"
  },
  {
    "key": "4931",
    "ITA": "CRACO-PESCHIERA",
    "state_code": "MT",
    "cab": "958132",
    "cap": "75010"
  },
  {
    "key": "4932",
    "ITA": "CRANDOLA VALSASSINA",
    "state_code": "LC",
    "cab": "925461",
    "cap": "23832"
  },
  {
    "key": "4933",
    "ITA": "CRAVAGLIANA-FERRERA",
    "state_code": "VC",
    "cab": "915850",
    "cap": "13020"
  },
  {
    "key": "4934",
    "ITA": "CRAVAGLIANA",
    "state_code": "VC",
    "cab": "915850",
    "cap": "13020"
  },
  {
    "key": "4935",
    "ITA": "CRAVANZANA",
    "state_code": "CN",
    "cab": "418301",
    "cap": "12050"
  },
  {
    "key": "4936",
    "ITA": "CRAVEGGIA",
    "state_code": "VB",
    "cab": "917195",
    "cap": "28852"
  },
  {
    "key": "4937",
    "ITA": "CRAVEGGIA-VOCOGNO",
    "state_code": "VB",
    "cab": "917195",
    "cap": "28852"
  },
  {
    "key": "4938",
    "ITA": "CREAZZO-OLMO",
    "state_code": "VI",
    "cab": "603506",
    "cap": "36051"
  },
  {
    "key": "4939",
    "ITA": "CREAZZO",
    "state_code": "VI",
    "cab": "603506",
    "cap": "36051"
  },
  {
    "key": "4940",
    "ITA": "CRECCHIO",
    "state_code": "CH",
    "cab": "776401",
    "cap": "66014"
  },
  {
    "key": "4941",
    "ITA": "CRECCHIO-VILLA TUCCI",
    "state_code": "CH",
    "cab": "776401",
    "cap": "66014"
  },
  {
    "key": "4942",
    "ITA": "CREDARO",
    "state_code": "BG",
    "cab": "539106",
    "cap": "24060"
  },
  {
    "key": "4943",
    "ITA": "CREDERA RUBBIANO",
    "state_code": "CR",
    "cab": "568303",
    "cap": "26010"
  },
  {
    "key": "4944",
    "ITA": "CREDERA RUBBIANO-CREDERA",
    "state_code": "CR",
    "cab": "568303",
    "cap": "26010"
  },
  {
    "key": "4945",
    "ITA": "CREDERA RUBBIANO-ROVERETO",
    "state_code": "CR",
    "cab": "568303",
    "cap": "26010"
  },
  {
    "key": "4946",
    "ITA": "CREDERA RUBBIANO-RUBBIANO",
    "state_code": "CR",
    "cab": "568303",
    "cap": "26010"
  },
  {
    "key": "4947",
    "ITA": "CREMA-SANTA MARIA DELLA CROCE",
    "state_code": "CR",
    "cab": "568402",
    "cap": "26013"
  },
  {
    "key": "4948",
    "ITA": "CREMA-SABBIONI",
    "state_code": "CR",
    "cab": "568402",
    "cap": "26013"
  },
  {
    "key": "4949",
    "ITA": "CREMA-OMBRIANO",
    "state_code": "CR",
    "cab": "568402",
    "cap": "26013"
  },
  {
    "key": "4950",
    "ITA": "CREMA-SANTO STEFANO IN VAIRANO",
    "state_code": "CR",
    "cab": "568402",
    "cap": "26013"
  },
  {
    "key": "4951",
    "ITA": "CREMA",
    "state_code": "CR",
    "cab": "568402",
    "cap": "26013"
  },
  {
    "key": "4952",
    "ITA": "CREMELLA",
    "state_code": "LC",
    "cab": "520403",
    "cap": "23894"
  },
  {
    "key": "4953",
    "ITA": "CREMENAGA",
    "state_code": "VA",
    "cab": "924423",
    "cap": "21030"
  },
  {
    "key": "4954",
    "ITA": "CREMENO",
    "state_code": "LC",
    "cab": "512202",
    "cap": "23814"
  },
  {
    "key": "4955",
    "ITA": "CREMENO-MAGGIO",
    "state_code": "LC",
    "cab": "512202",
    "cap": "23814"
  },
  {
    "key": "4956",
    "ITA": "CREMIA",
    "state_code": "CO",
    "cab": "925487",
    "cap": "22010"
  },
  {
    "key": "4957",
    "ITA": "CREMOLINO",
    "state_code": "AL",
    "cab": "921114",
    "cap": "15010"
  },
  {
    "key": "4958",
    "ITA": "CREMONA-MARISTELLA",
    "state_code": "CR",
    "cab": "114009",
    "cap": "26100"
  },
  {
    "key": "4959",
    "ITA": "CREMONA",
    "state_code": "CR",
    "cab": "114009",
    "cap": "26100"
  },
  {
    "key": "4960",
    "ITA": "CREMONA-CAVA TIGOZZI",
    "state_code": "CR",
    "cab": "114009",
    "cap": "26100"
  },
  {
    "key": "4961",
    "ITA": "CREMONA-BOSCHETTO",
    "state_code": "CR",
    "cab": "114009",
    "cap": "26100"
  },
  {
    "key": "4962",
    "ITA": "CREMONA-MIGLIARO",
    "state_code": "CR",
    "cab": "114009",
    "cap": "26100"
  },
  {
    "key": "4963",
    "ITA": "CREMONA-SAN FELICE",
    "state_code": "CR",
    "cab": "114009",
    "cap": "26100"
  },
  {
    "key": "4964",
    "ITA": "CREMOSANO",
    "state_code": "CR",
    "cab": "573402",
    "cap": "26010"
  },
  {
    "key": "4965",
    "ITA": "CRESCENTINO-SAN GENUARIO",
    "state_code": "VC",
    "cab": "444406",
    "cap": "13044"
  },
  {
    "key": "4966",
    "ITA": "CRESCENTINO",
    "state_code": "VC",
    "cab": "444406",
    "cap": "13044"
  },
  {
    "key": "4967",
    "ITA": "CRESCENTINO-SAN SILVESTRO",
    "state_code": "VC",
    "cab": "444406",
    "cap": "13044"
  },
  {
    "key": "4968",
    "ITA": "CRESPADORO-FERRAZZA",
    "state_code": "VI",
    "cab": "609305",
    "cap": "36070"
  },
  {
    "key": "4969",
    "ITA": "CRESPADORO",
    "state_code": "VI",
    "cab": "609305",
    "cap": "36070"
  },
  {
    "key": "4970",
    "ITA": "CRESPANO DEL GRAPPA",
    "state_code": "TV",
    "cab": "616508",
    "cap": "31017"
  },
  {
    "key": "4971",
    "ITA": "CRESPELLANO-PRAGATTO",
    "state_code": "BO",
    "cab": "367805",
    "cap": "40056"
  },
  {
    "key": "4972",
    "ITA": "CRESPELLANO-MUFFA",
    "state_code": "BO",
    "cab": "367805",
    "cap": "40056"
  },
  {
    "key": "4973",
    "ITA": "CRESPELLANO-CALCARA",
    "state_code": "BO",
    "cab": "367805",
    "cap": "40056"
  },
  {
    "key": "4974",
    "ITA": "CRESPELLANO",
    "state_code": "BO",
    "cab": "367805",
    "cap": "40056"
  },
  {
    "key": "4975",
    "ITA": "CRESPIATICA",
    "state_code": "LO",
    "cab": "330506",
    "cap": "26835"
  },
  {
    "key": "4976",
    "ITA": "CRESPINA-CENAIA",
    "state_code": "PI",
    "cab": "710004",
    "cap": "56040"
  },
  {
    "key": "4977",
    "ITA": "CRESPINA",
    "state_code": "PI",
    "cab": "710004",
    "cap": "56040"
  },
  {
    "key": "4978",
    "ITA": "CRESPINO",
    "state_code": "RO",
    "cab": "632703",
    "cap": "45030"
  },
  {
    "key": "4979",
    "ITA": "CRESSA",
    "state_code": "NO",
    "cab": "430009",
    "cap": "28012"
  },
  {
    "key": "4980",
    "ITA": "CREVACUORE",
    "state_code": "BI",
    "cab": "444505",
    "cap": "13864"
  },
  {
    "key": "4981",
    "ITA": "CREVALCORE",
    "state_code": "BO",
    "cab": "367904",
    "cap": "40014"
  },
  {
    "key": "4982",
    "ITA": "CREVALCORE-PALATA PEPOLI",
    "state_code": "BO",
    "cab": "367904",
    "cap": "40014"
  },
  {
    "key": "4983",
    "ITA": "CREVALCORE-CASELLE",
    "state_code": "BO",
    "cab": "367904",
    "cap": "40014"
  },
  {
    "key": "4984",
    "ITA": "CREVOLADOSSOLA-PREGLIA",
    "state_code": "VB",
    "cab": "457705",
    "cap": "28865"
  },
  {
    "key": "4985",
    "ITA": "CREVOLADOSSOLA",
    "state_code": "VB",
    "cab": "457705",
    "cap": "28865"
  },
  {
    "key": "4986",
    "ITA": "CRISPANO",
    "state_code": "NA",
    "cab": "403709",
    "cap": "80020"
  },
  {
    "key": "4987",
    "ITA": "CRISPIANO",
    "state_code": "TA",
    "cab": "788505",
    "cap": "74012"
  },
  {
    "key": "4988",
    "ITA": "CRISSOLO",
    "state_code": "CN",
    "cab": "698407",
    "cap": "12030"
  },
  {
    "key": "4989",
    "ITA": "CROCEFIESCHI",
    "state_code": "GE",
    "cab": "903138",
    "cap": "16010"
  },
  {
    "key": "4990",
    "ITA": "CROCETTA DEL MONTELLO",
    "state_code": "TV",
    "cab": "616607",
    "cap": "31035"
  },
  {
    "key": "4991",
    "ITA": "CROCETTA DEL MONTELLO-CIANO",
    "state_code": "TV",
    "cab": "616607",
    "cap": "31035"
  },
  {
    "key": "4992",
    "ITA": "CRODO",
    "state_code": "VB",
    "cab": "453506",
    "cap": "28862"
  },
  {
    "key": "4993",
    "ITA": "CROGNALETO-MACCHIA VOMANO",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "4994",
    "ITA": "CROGNALETO-POGGIO UMBRICCHIO",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "4995",
    "ITA": "CROGNALETO-SAN GIORGIO",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "4996",
    "ITA": "CROGNALETO-TOTTEA",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "4997",
    "ITA": "CROGNALETO-CESACASTINA",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "4998",
    "ITA": "CROGNALETO-CERVARO",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "4999",
    "ITA": "CROGNALETO",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "5000",
    "ITA": "CROGNALETO-NERITO",
    "state_code": "TE",
    "cab": "769000",
    "cap": "64043"
  },
  {
    "key": "5001",
    "ITA": "CROPALATI",
    "state_code": "CS",
    "cab": "958827",
    "cap": "87060"
  },
  {
    "key": "5002",
    "ITA": "CROPANI-CUTURELLA",
    "state_code": "CZ",
    "cab": "429605",
    "cap": "88050"
  },
  {
    "key": "5003",
    "ITA": "CROPANI-CROPANI MARINA",
    "state_code": "CZ",
    "cab": "429605",
    "cap": "88050"
  },
  {
    "key": "5004",
    "ITA": "CROPANI",
    "state_code": "CZ",
    "cab": "429605",
    "cap": "88051"
  },
  {
    "key": "5005",
    "ITA": "CROSA",
    "state_code": "BI",
    "cab": "915868",
    "cap": "13853"
  },
  {
    "key": "5006",
    "ITA": "CROSIA-MIRTO",
    "state_code": "CS",
    "cab": "807008",
    "cap": "87060"
  },
  {
    "key": "5007",
    "ITA": "CROSIA",
    "state_code": "CS",
    "cab": "807008",
    "cap": "87060"
  },
  {
    "key": "5008",
    "ITA": "CROSIO DELLA VALLE",
    "state_code": "VA",
    "cab": "924431",
    "cap": "21020"
  },
  {
    "key": "5009",
    "ITA": "CROTONE",
    "state_code": "KR",
    "cab": "222000",
    "cap": "88900"
  },
  {
    "key": "5010",
    "ITA": "CROTONE-VILLAGGIO BUCCHI",
    "state_code": "KR",
    "cab": "222000",
    "cap": "88900"
  },
  {
    "key": "5011",
    "ITA": "CROTONE-PAPANICE",
    "state_code": "KR",
    "cab": "222000",
    "cap": "88900"
  },
  {
    "key": "5012",
    "ITA": "CROTTA D'ADDA",
    "state_code": "CR",
    "cab": "931626",
    "cap": "26020"
  },
  {
    "key": "5013",
    "ITA": "CROVA",
    "state_code": "VC",
    "cab": "915876",
    "cap": "13040"
  },
  {
    "key": "5014",
    "ITA": "CROVIANA",
    "state_code": "TN",
    "cab": "904797",
    "cap": "38027"
  },
  {
    "key": "5015",
    "ITA": "CRUCOLI",
    "state_code": "KR",
    "cab": "429001",
    "cap": "88812"
  },
  {
    "key": "5016",
    "ITA": "CRUCOLI-TORRETTA",
    "state_code": "KR",
    "cab": "429001",
    "cap": "88812"
  },
  {
    "key": "5017",
    "ITA": "CUASSO AL MONTE-CUASSO AL PIANO",
    "state_code": "VA",
    "cab": "924449",
    "cap": "21050"
  },
  {
    "key": "5018",
    "ITA": "CUASSO AL MONTE",
    "state_code": "VA",
    "cab": "924449",
    "cap": "21050"
  },
  {
    "key": "5019",
    "ITA": "CUASSO AL MONTE-CAVAGNANO",
    "state_code": "VA",
    "cab": "924449",
    "cap": "21050"
  },
  {
    "key": "5020",
    "ITA": "CUCCARO MONFERRATO",
    "state_code": "AL",
    "cab": "921122",
    "cap": "15040"
  },
  {
    "key": "5021",
    "ITA": "CUCCARO VETERE",
    "state_code": "SA",
    "cab": "952507",
    "cap": "84050"
  },
  {
    "key": "5022",
    "ITA": "CUCCIAGO",
    "state_code": "CO",
    "cab": "519603",
    "cap": "22060"
  },
  {
    "key": "5023",
    "ITA": "CUCEGLIO",
    "state_code": "TO",
    "cab": "283515",
    "cap": "10090"
  },
  {
    "key": "5024",
    "ITA": "CUGGIONO",
    "state_code": "MI",
    "cab": "330605",
    "cap": "20012"
  },
  {
    "key": "5025",
    "ITA": "CUGLIATE FABIASCO-FABIASCO",
    "state_code": "VA",
    "cab": "181396",
    "cap": "21030"
  },
  {
    "key": "5026",
    "ITA": "CUGLIATE FABIASCO-CUGLIATE",
    "state_code": "VA",
    "cab": "181396",
    "cap": "21030"
  },
  {
    "key": "5027",
    "ITA": "CUGLIATE FABIASCO",
    "state_code": "VA",
    "cab": "181396",
    "cap": "21030"
  },
  {
    "key": "5028",
    "ITA": "CUGLIERI-TORRE DEL POZZO",
    "state_code": "OR",
    "cab": "855700",
    "cap": "9073"
  },
  {
    "key": "5029",
    "ITA": "CUGLIERI-SANTA CATERINA",
    "state_code": "OR",
    "cab": "855700",
    "cap": "9073"
  },
  {
    "key": "5030",
    "ITA": "CUGLIERI-S'ARCHITTU",
    "state_code": "OR",
    "cab": "855700",
    "cap": "9073"
  },
  {
    "key": "5031",
    "ITA": "CUGLIERI",
    "state_code": "OR",
    "cab": "855700",
    "cap": "9073"
  },
  {
    "key": "5032",
    "ITA": "CUGNOLI",
    "state_code": "PE",
    "cab": "772905",
    "cap": "65020"
  },
  {
    "key": "5033",
    "ITA": "CUMIANA",
    "state_code": "TO",
    "cab": "304402",
    "cap": "10040"
  },
  {
    "key": "5034",
    "ITA": "CUMIANA-BIVIO CUMIANA",
    "state_code": "TO",
    "cab": "304402",
    "cap": "10040"
  },
  {
    "key": "5035",
    "ITA": "CUMIGNANO SUL NAVIGLIO",
    "state_code": "CR",
    "cab": "931634",
    "cap": "26020"
  },
  {
    "key": "5036",
    "ITA": "CUNARDO",
    "state_code": "VA",
    "cab": "501908",
    "cap": "21035"
  },
  {
    "key": "5037",
    "ITA": "CUNEO",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5038",
    "ITA": "CUNEO-BORGO GESSO",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5039",
    "ITA": "CUNEO-BORGO SAN GIUSEPPE",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5040",
    "ITA": "CUNEO-CONFRERIA",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5041",
    "ITA": "CUNEO-MADONNA DELLE GRAZIE",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5042",
    "ITA": "CUNEO-MADONNA DELL'OLMO",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5043",
    "ITA": "CUNEO-PASSATORE",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5044",
    "ITA": "CUNEO-ROATA ROSSI",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5045",
    "ITA": "CUNEO-RONCHI",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5046",
    "ITA": "CUNEO-SAN BENIGNO",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5047",
    "ITA": "CUNEO-SAN ROCCO CASTAGNARETTA",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5048",
    "ITA": "CUNEO-SPINETTA",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5049",
    "ITA": "CUNEO-SAN PIETRO DEL GALLO",
    "state_code": "CN",
    "cab": "102004",
    "cap": "12100"
  },
  {
    "key": "5050",
    "ITA": "CUNEVO",
    "state_code": "TN",
    "cab": "359505",
    "cap": "38010"
  },
  {
    "key": "5051",
    "ITA": "CUNICO",
    "state_code": "AT",
    "cab": "920199",
    "cap": "14026"
  },
  {
    "key": "5052",
    "ITA": "CUORGNE'",
    "state_code": "TO",
    "cab": "304501",
    "cap": "10082"
  },
  {
    "key": "5053",
    "ITA": "CUORGNE'-SALTO CANAVESE",
    "state_code": "TO",
    "cab": "304501",
    "cap": "10082"
  },
  {
    "key": "5054",
    "ITA": "CUORGNE'-PRIACCO",
    "state_code": "TO",
    "cab": "304501",
    "cap": "10082"
  },
  {
    "key": "5055",
    "ITA": "CUPELLO",
    "state_code": "CH",
    "cab": "776500",
    "cap": "66051"
  },
  {
    "key": "5056",
    "ITA": "CUPRA MARITTIMA",
    "state_code": "AP",
    "cab": "694307",
    "cap": "63012"
  },
  {
    "key": "5057",
    "ITA": "CUPRAMONTANA",
    "state_code": "AN",
    "cab": "373407",
    "cap": "60034"
  },
  {
    "key": "5058",
    "ITA": "CURA CARPIGNANO",
    "state_code": "PV",
    "cab": "478503",
    "cap": "27010"
  },
  {
    "key": "5059",
    "ITA": "CURCURIS",
    "state_code": "OR",
    "cab": "967273",
    "cap": "9090"
  },
  {
    "key": "5060",
    "ITA": "CUREGGIO",
    "state_code": "NO",
    "cab": "412601",
    "cap": "28060"
  },
  {
    "key": "5061",
    "ITA": "CURIGLIA CON MONTEVIASCO",
    "state_code": "VA",
    "cab": "924464",
    "cap": "21010"
  },
  {
    "key": "5062",
    "ITA": "CURINGA",
    "state_code": "CZ",
    "cab": "425504",
    "cap": "88022"
  },
  {
    "key": "5063",
    "ITA": "CURINGA-ACCONIA",
    "state_code": "CZ",
    "cab": "425504",
    "cap": "88022"
  },
  {
    "key": "5064",
    "ITA": "CURINO",
    "state_code": "BI",
    "cab": "915884",
    "cap": "13865"
  },
  {
    "key": "5065",
    "ITA": "CURNO",
    "state_code": "BG",
    "cab": "529602",
    "cap": "24035"
  },
  {
    "key": "5066",
    "ITA": "CURON VENOSTA",
    "state_code": "BZ",
    "cab": "583500",
    "cap": "39027"
  },
  {
    "key": "5067",
    "ITA": "CURON VENOSTA-RESIA",
    "state_code": "BZ",
    "cab": "583500",
    "cap": "39027"
  },
  {
    "key": "5068",
    "ITA": "CURON VENOSTA-SAN VALENTINO ALLA MUTA",
    "state_code": "BZ",
    "cab": "583500",
    "cap": "39027"
  },
  {
    "key": "5069",
    "ITA": "CURSI",
    "state_code": "LE",
    "cab": "796102",
    "cap": "73020"
  },
  {
    "key": "5070",
    "ITA": "CURSOLO ORASSO-AIRETTA",
    "state_code": "VB",
    "cab": "917237",
    "cap": "28827"
  },
  {
    "key": "5071",
    "ITA": "CURSOLO ORASSO",
    "state_code": "VB",
    "cab": "917237",
    "cap": "28827"
  },
  {
    "key": "5072",
    "ITA": "CURTAROLO",
    "state_code": "PD",
    "cab": "625509",
    "cap": "35010"
  },
  {
    "key": "5073",
    "ITA": "CURTAROLO-SANTA MARIA DI NON",
    "state_code": "PD",
    "cab": "625509",
    "cap": "35010"
  },
  {
    "key": "5074",
    "ITA": "CURTAROLO-PIEVE",
    "state_code": "PD",
    "cab": "625509",
    "cap": "35010"
  },
  {
    "key": "5075",
    "ITA": "CURTATONE-SAN SILVESTRO",
    "state_code": "MN",
    "cab": "576108",
    "cap": "46010"
  },
  {
    "key": "5076",
    "ITA": "CURTATONE-MONTANARA",
    "state_code": "MN",
    "cab": "576108",
    "cap": "46010"
  },
  {
    "key": "5077",
    "ITA": "CURTATONE",
    "state_code": "MN",
    "cab": "576108",
    "cap": "46010"
  },
  {
    "key": "5078",
    "ITA": "CURTATONE-BUSCOLDO",
    "state_code": "MN",
    "cab": "576108",
    "cap": "46010"
  },
  {
    "key": "5079",
    "ITA": "CURTATONE-VILLAGGIO EREMO",
    "state_code": "MN",
    "cab": "576108",
    "cap": "46010"
  },
  {
    "key": "5080",
    "ITA": "CURTATONE-LEVATA",
    "state_code": "MN",
    "cab": "576108",
    "cap": "46010"
  },
  {
    "key": "5081",
    "ITA": "CURTATONE-GRAZIE",
    "state_code": "MN",
    "cab": "576108",
    "cap": "46010"
  },
  {
    "key": "5082",
    "ITA": "CURTI",
    "state_code": "CE",
    "cab": "751909",
    "cap": "81040"
  },
  {
    "key": "5083",
    "ITA": "CUSAGO-MONZORO",
    "state_code": "MI",
    "cab": "330704",
    "cap": "20090"
  },
  {
    "key": "5084",
    "ITA": "CUSAGO",
    "state_code": "MI",
    "cab": "330704",
    "cap": "20090"
  },
  {
    "key": "5085",
    "ITA": "CUSANO MILANINO",
    "state_code": "MI",
    "cab": "330803",
    "cap": "20095"
  },
  {
    "key": "5086",
    "ITA": "CUSANO MILANINO-MILANINO",
    "state_code": "MI",
    "cab": "330803",
    "cap": "20095"
  },
  {
    "key": "5087",
    "ITA": "CUSANO MUTRI",
    "state_code": "BN",
    "cab": "753202",
    "cap": "82033"
  },
  {
    "key": "5088",
    "ITA": "CUSANO MUTRI-CIVITELLA LICINIO",
    "state_code": "BN",
    "cab": "753202",
    "cap": "82033"
  },
  {
    "key": "5089",
    "ITA": "CUSINO",
    "state_code": "CO",
    "cab": "925503",
    "cap": "22010"
  },
  {
    "key": "5090",
    "ITA": "CUSIO",
    "state_code": "BG",
    "cab": "928085",
    "cap": "24010"
  },
  {
    "key": "5091",
    "ITA": "CUSTONACI-PURGATORIO",
    "state_code": "TP",
    "cab": "818401",
    "cap": "91015"
  },
  {
    "key": "5092",
    "ITA": "CUSTONACI",
    "state_code": "TP",
    "cab": "818401",
    "cap": "91015"
  },
  {
    "key": "5093",
    "ITA": "CUSTONACI-SPERONE DI CUSTONACI",
    "state_code": "TP",
    "cab": "818401",
    "cap": "91015"
  },
  {
    "key": "5094",
    "ITA": "CUTIGLIANO-PIAN DEGLI ONTANI",
    "state_code": "PT",
    "cab": "703900",
    "cap": "51024"
  },
  {
    "key": "5095",
    "ITA": "CUTIGLIANO-PIANOSINATICO",
    "state_code": "PT",
    "cab": "703900",
    "cap": "51024"
  },
  {
    "key": "5096",
    "ITA": "CUTIGLIANO",
    "state_code": "PT",
    "cab": "703900",
    "cap": "51024"
  },
  {
    "key": "5097",
    "ITA": "CUTRO",
    "state_code": "KR",
    "cab": "425603",
    "cap": "88842"
  },
  {
    "key": "5098",
    "ITA": "CUTRO-STECCATO DI CUTRO",
    "state_code": "KR",
    "cab": "425603",
    "cap": "88842"
  },
  {
    "key": "5099",
    "ITA": "CUTRO-SAN LEONARDO DI CUTRO",
    "state_code": "KR",
    "cab": "425603",
    "cap": "88842"
  },
  {
    "key": "5100",
    "ITA": "CUTROFIANO",
    "state_code": "LE",
    "cab": "796201",
    "cap": "73020"
  },
  {
    "key": "5101",
    "ITA": "CUVEGLIO",
    "state_code": "VA",
    "cab": "502005",
    "cap": "21030"
  },
  {
    "key": "5102",
    "ITA": "CUVIO",
    "state_code": "VA",
    "cab": "502104",
    "cap": "21030"
  },
  {
    "key": "5103",
    "ITA": "DAIANO",
    "state_code": "TN",
    "cab": "450106",
    "cap": "38030"
  },
  {
    "key": "5104",
    "ITA": "DAIRAGO",
    "state_code": "MI",
    "cab": "330902",
    "cap": "20020"
  },
  {
    "key": "5105",
    "ITA": "DALMINE",
    "state_code": "BG",
    "cab": "529701",
    "cap": "24044"
  },
  {
    "key": "5106",
    "ITA": "DALMINE-SFORZATICA",
    "state_code": "BG",
    "cab": "529701",
    "cap": "24044"
  },
  {
    "key": "5107",
    "ITA": "DALMINE-SABBIO BERGAMASCO",
    "state_code": "BG",
    "cab": "529701",
    "cap": "24044"
  },
  {
    "key": "5108",
    "ITA": "DAMBEL",
    "state_code": "TN",
    "cab": "347203",
    "cap": "38010"
  },
  {
    "key": "5109",
    "ITA": "DANTA DI CADORE",
    "state_code": "BL",
    "cab": "700708",
    "cap": "32040"
  },
  {
    "key": "5110",
    "ITA": "DAONE",
    "state_code": "TN",
    "cab": "372003",
    "cap": "38080"
  },
  {
    "key": "5111",
    "ITA": "DARE'",
    "state_code": "TN",
    "cab": "904839",
    "cap": "38080"
  },
  {
    "key": "5112",
    "ITA": "DARFO BOARIO TERME",
    "state_code": "BS",
    "cab": "544403",
    "cap": "25047"
  },
  {
    "key": "5113",
    "ITA": "DARFO BOARIO TERME-BOARIO TERME",
    "state_code": "BS",
    "cab": "544403",
    "cap": "25041"
  },
  {
    "key": "5114",
    "ITA": "DARFO BOARIO TERME-DARFO",
    "state_code": "BS",
    "cab": "544403",
    "cap": "25047"
  },
  {
    "key": "5115",
    "ITA": "DARFO BOARIO TERME-ERBANNO",
    "state_code": "BS",
    "cab": "544403",
    "cap": "25041"
  },
  {
    "key": "5116",
    "ITA": "DARFO BOARIO TERME-GORZONE",
    "state_code": "BS",
    "cab": "544403",
    "cap": "25047"
  },
  {
    "key": "5117",
    "ITA": "DASA'",
    "state_code": "VV",
    "cab": "428904",
    "cap": "89832"
  },
  {
    "key": "5118",
    "ITA": "DAVAGNA",
    "state_code": "GE",
    "cab": "681601",
    "cap": "16022"
  },
  {
    "key": "5119",
    "ITA": "DAVAGNA-SCOFFERA",
    "state_code": "GE",
    "cab": "681601",
    "cap": "16022"
  },
  {
    "key": "5120",
    "ITA": "DAVAGNA-MORANEGO",
    "state_code": "GE",
    "cab": "681601",
    "cap": "16022"
  },
  {
    "key": "5121",
    "ITA": "DAVAGNA-MECO",
    "state_code": "GE",
    "cab": "681601",
    "cap": "16022"
  },
  {
    "key": "5122",
    "ITA": "DAVERIO",
    "state_code": "VA",
    "cab": "507400",
    "cap": "21020"
  },
  {
    "key": "5123",
    "ITA": "DAVOLI",
    "state_code": "CZ",
    "cab": "428508",
    "cap": "88060"
  },
  {
    "key": "5124",
    "ITA": "DAVOLI-MARINA DI DAVOLI",
    "state_code": "CZ",
    "cab": "428508",
    "cap": "88060"
  },
  {
    "key": "5125",
    "ITA": "DAZIO",
    "state_code": "SO",
    "cab": "927061",
    "cap": "23010"
  },
  {
    "key": "5126",
    "ITA": "DECIMOMANNU",
    "state_code": "CA",
    "cab": "438705",
    "cap": "9033"
  },
  {
    "key": "5127",
    "ITA": "DECIMOPUTZU",
    "state_code": "CA",
    "cab": "442103",
    "cap": "9010"
  },
  {
    "key": "5128",
    "ITA": "DECOLLATURA-SAN BERNARDO",
    "state_code": "CZ",
    "cab": "887307",
    "cap": "88041"
  },
  {
    "key": "5129",
    "ITA": "DECOLLATURA",
    "state_code": "CZ",
    "cab": "887307",
    "cap": "88041"
  },
  {
    "key": "5130",
    "ITA": "DECOLLATURA-ADAMI",
    "state_code": "CZ",
    "cab": "887307",
    "cap": "88041"
  },
  {
    "key": "5131",
    "ITA": "DECOLLATURA-CERRISI",
    "state_code": "CZ",
    "cab": "887307",
    "cap": "88041"
  },
  {
    "key": "5132",
    "ITA": "DEGO",
    "state_code": "SV",
    "cab": "494005",
    "cap": "17058"
  },
  {
    "key": "5133",
    "ITA": "DEIVA MARINA",
    "state_code": "SP",
    "cab": "497503",
    "cap": "19013"
  },
  {
    "key": "5134",
    "ITA": "DELEBIO",
    "state_code": "SO",
    "cab": "521401",
    "cap": "23014"
  },
  {
    "key": "5135",
    "ITA": "DELIA",
    "state_code": "CL",
    "cab": "833202",
    "cap": "93010"
  },
  {
    "key": "5136",
    "ITA": "DELIANUOVA",
    "state_code": "RC",
    "cab": "813600",
    "cap": "89012"
  },
  {
    "key": "5137",
    "ITA": "DELICETO",
    "state_code": "FG",
    "cab": "784009",
    "cap": "71026"
  },
  {
    "key": "5138",
    "ITA": "DELLO",
    "state_code": "BS",
    "cab": "544502",
    "cap": "25020"
  },
  {
    "key": "5139",
    "ITA": "DELLO-QUINZANELLO",
    "state_code": "BS",
    "cab": "544502",
    "cap": "25020"
  },
  {
    "key": "5140",
    "ITA": "DELLO-CORTICELLE PIEVE",
    "state_code": "BS",
    "cab": "544502",
    "cap": "25020"
  },
  {
    "key": "5141",
    "ITA": "DEMONTE-FESTIONA",
    "state_code": "CN",
    "cab": "462507",
    "cap": "12014"
  },
  {
    "key": "5142",
    "ITA": "DEMONTE",
    "state_code": "CN",
    "cab": "462507",
    "cap": "12014"
  },
  {
    "key": "5143",
    "ITA": "DENICE",
    "state_code": "AL",
    "cab": "921130",
    "cap": "15010"
  },
  {
    "key": "5144",
    "ITA": "DENNO",
    "state_code": "TN",
    "cab": "347302",
    "cap": "38010"
  },
  {
    "key": "5145",
    "ITA": "DERNICE",
    "state_code": "AL",
    "cab": "921148",
    "cap": "15056"
  },
  {
    "key": "5146",
    "ITA": "DEROVERE",
    "state_code": "CR",
    "cab": "931642",
    "cap": "26040"
  },
  {
    "key": "5147",
    "ITA": "DERUTA",
    "state_code": "PG",
    "cab": "384206",
    "cap": "6053"
  },
  {
    "key": "5148",
    "ITA": "DERUTA-CASALINA",
    "state_code": "PG",
    "cab": "384206",
    "cap": "6051"
  },
  {
    "key": "5149",
    "ITA": "DERUTA-SANT'ANGELO DI CELLE",
    "state_code": "PG",
    "cab": "384206",
    "cap": "6053"
  },
  {
    "key": "5150",
    "ITA": "DERUTA-RIPABIANCA",
    "state_code": "PG",
    "cab": "384206",
    "cap": "6051"
  },
  {
    "key": "5151",
    "ITA": "DERUTA-SAN NICCOLO' DI CELLE",
    "state_code": "PG",
    "cab": "384206",
    "cap": "6053"
  },
  {
    "key": "5152",
    "ITA": "DERUTA-PONTE NUOVO",
    "state_code": "PG",
    "cab": "384206",
    "cap": "6053"
  },
  {
    "key": "5153",
    "ITA": "DERVIO",
    "state_code": "LC",
    "cab": "512301",
    "cap": "23824"
  },
  {
    "key": "5154",
    "ITA": "DESANA",
    "state_code": "VC",
    "cab": "444604",
    "cap": "13034"
  },
  {
    "key": "5155",
    "ITA": "DESENZANO DEL GARDA-RIVOLTELLA",
    "state_code": "BS",
    "cab": "544601",
    "cap": "25010"
  },
  {
    "key": "5156",
    "ITA": "DESENZANO DEL GARDA",
    "state_code": "BS",
    "cab": "544601",
    "cap": "25015"
  },
  {
    "key": "5157",
    "ITA": "DESENZANO DEL GARDA-SAN MARTINO DELLA BATTAGLIA",
    "state_code": "BS",
    "cab": "544601",
    "cap": "25010"
  },
  {
    "key": "5158",
    "ITA": "DESIO",
    "state_code": "MB",
    "cab": "331009",
    "cap": "20033"
  },
  {
    "key": "5159",
    "ITA": "DESULO",
    "state_code": "NU",
    "cab": "865303",
    "cap": "8032"
  },
  {
    "key": "5160",
    "ITA": "DIAMANTE-CIRELLA",
    "state_code": "CS",
    "cab": "807107",
    "cap": "87023"
  },
  {
    "key": "5161",
    "ITA": "DIAMANTE",
    "state_code": "CS",
    "cab": "807107",
    "cap": "87023"
  },
  {
    "key": "5162",
    "ITA": "DIANO ARENTINO",
    "state_code": "IM",
    "cab": "922401",
    "cap": "18013"
  },
  {
    "key": "5163",
    "ITA": "DIANO CASTELLO",
    "state_code": "IM",
    "cab": "922419",
    "cap": "18013"
  },
  {
    "key": "5164",
    "ITA": "DIANO D'ALBA",
    "state_code": "CN",
    "cab": "462606",
    "cap": "12055"
  },
  {
    "key": "5165",
    "ITA": "DIANO D'ALBA-VALLE TALLORIA",
    "state_code": "CN",
    "cab": "462606",
    "cap": "12055"
  },
  {
    "key": "5166",
    "ITA": "DIANO D'ALBA-RICCA",
    "state_code": "CN",
    "cab": "462606",
    "cap": "12055"
  },
  {
    "key": "5167",
    "ITA": "DIANO D'ALBA-SAN ROCCO CHERASCA",
    "state_code": "CN",
    "cab": "462606",
    "cap": "12055"
  },
  {
    "key": "5168",
    "ITA": "DIANO MARINA",
    "state_code": "IM",
    "cab": "490003",
    "cap": "18013"
  },
  {
    "key": "5169",
    "ITA": "DIANO SAN PIETRO",
    "state_code": "IM",
    "cab": "922427",
    "cap": "18013"
  },
  {
    "key": "5170",
    "ITA": "DICOMANO-SANDETOLE",
    "state_code": "FI",
    "cab": "378208",
    "cap": "50062"
  },
  {
    "key": "5171",
    "ITA": "DICOMANO",
    "state_code": "FI",
    "cab": "378208",
    "cap": "50062"
  },
  {
    "key": "5172",
    "ITA": "DIGNANO",
    "state_code": "UD",
    "cab": "637900",
    "cap": "33030"
  },
  {
    "key": "5173",
    "ITA": "DIGNANO-CARPACCO",
    "state_code": "UD",
    "cab": "637900",
    "cap": "33030"
  },
  {
    "key": "5174",
    "ITA": "DIGNANO-VIDULIS",
    "state_code": "UD",
    "cab": "637900",
    "cap": "33030"
  },
  {
    "key": "5175",
    "ITA": "DIMARO",
    "state_code": "TN",
    "cab": "347401",
    "cap": "38025"
  },
  {
    "key": "5176",
    "ITA": "DINAMI",
    "state_code": "VV",
    "cab": "912899",
    "cap": "89833"
  },
  {
    "key": "5177",
    "ITA": "DINAMI-MONSORETO",
    "state_code": "VV",
    "cab": "912899",
    "cap": "89833"
  },
  {
    "key": "5178",
    "ITA": "DINAMI-MELICUCCA' DI DINAMI",
    "state_code": "VV",
    "cab": "912899",
    "cap": "89833"
  },
  {
    "key": "5179",
    "ITA": "DIPIGNANO-TESSANO",
    "state_code": "CS",
    "cab": "807206",
    "cap": "87045"
  },
  {
    "key": "5180",
    "ITA": "DIPIGNANO",
    "state_code": "CS",
    "cab": "807206",
    "cap": "87045"
  },
  {
    "key": "5181",
    "ITA": "DIPIGNANO-LAURIGNANO",
    "state_code": "CS",
    "cab": "807206",
    "cap": "87045"
  },
  {
    "key": "5182",
    "ITA": "DISO-MARITTIMA",
    "state_code": "LE",
    "cab": "796300",
    "cap": "73030"
  },
  {
    "key": "5183",
    "ITA": "DISO",
    "state_code": "LE",
    "cab": "796300",
    "cap": "73030"
  },
  {
    "key": "5184",
    "ITA": "DIVIGNANO",
    "state_code": "NO",
    "cab": "917245",
    "cap": "28010"
  },
  {
    "key": "5185",
    "ITA": "DIZZASCO",
    "state_code": "CO",
    "cab": "925511",
    "cap": "22020"
  },
  {
    "key": "5186",
    "ITA": "DOBBIACO",
    "state_code": "BZ",
    "cab": "583609",
    "cap": "39034"
  },
  {
    "key": "5187",
    "ITA": "DOBERDÒ DEL LAGO",
    "state_code": "GO",
    "cab": "85320",
    "cap": "33070"
  },
  {
    "key": "5188",
    "ITA": "DOBERDO' DEL LAGO-MARCOTTINI",
    "state_code": "GO",
    "cab": "645606",
    "cap": "34070"
  },
  {
    "key": "5189",
    "ITA": "DOBERDO' DEL LAGO-JAMIANO",
    "state_code": "GO",
    "cab": "645606",
    "cap": "34070"
  },
  {
    "key": "5190",
    "ITA": "DOBERDO' DEL LAGO",
    "state_code": "GO",
    "cab": "645606",
    "cap": "34070"
  },
  {
    "key": "5191",
    "ITA": "DOGANA DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47891"
  },
  {
    "key": "5192",
    "ITA": "DOGLIANI",
    "state_code": "CN",
    "cab": "462705",
    "cap": "12063"
  },
  {
    "key": "5193",
    "ITA": "DOGLIOLA",
    "state_code": "CH",
    "cab": "954669",
    "cap": "66050"
  },
  {
    "key": "5194",
    "ITA": "DOGNA",
    "state_code": "UD",
    "cab": "936922",
    "cap": "33010"
  },
  {
    "key": "5195",
    "ITA": "DOLCE'",
    "state_code": "VR",
    "cab": "594408",
    "cap": "37020"
  },
  {
    "key": "5196",
    "ITA": "DOLCE'-VOLARGNE",
    "state_code": "VR",
    "cab": "594408",
    "cap": "37020"
  },
  {
    "key": "5197",
    "ITA": "DOLCE'-PERI",
    "state_code": "VR",
    "cab": "594408",
    "cap": "37020"
  },
  {
    "key": "5198",
    "ITA": "DOLCEACQUA",
    "state_code": "IM",
    "cab": "490102",
    "cap": "18035"
  },
  {
    "key": "5199",
    "ITA": "DOLCEDO",
    "state_code": "IM",
    "cab": "450007",
    "cap": "18020"
  },
  {
    "key": "5200",
    "ITA": "DOLEGNA DEL COLLIO-VENCO'",
    "state_code": "GO",
    "cab": "817403",
    "cap": "34070"
  },
  {
    "key": "5201",
    "ITA": "DOLEGNA DEL COLLIO-MERNICCO",
    "state_code": "GO",
    "cab": "817403",
    "cap": "34070"
  },
  {
    "key": "5202",
    "ITA": "DOLEGNA DEL COLLIO-SCRIO'",
    "state_code": "GO",
    "cab": "817403",
    "cap": "34070"
  },
  {
    "key": "5203",
    "ITA": "DOLEGNA DEL COLLIO-LONZANO",
    "state_code": "GO",
    "cab": "817403",
    "cap": "34070"
  },
  {
    "key": "5204",
    "ITA": "DOLEGNA DEL COLLIO",
    "state_code": "GO",
    "cab": "817403",
    "cap": "34070"
  },
  {
    "key": "5205",
    "ITA": "DOLIANOVA",
    "state_code": "CA",
    "cab": "438804",
    "cap": "9041"
  },
  {
    "key": "5206",
    "ITA": "DOLO-SAMBRUSON",
    "state_code": "VE",
    "cab": "360800",
    "cap": "30031"
  },
  {
    "key": "5207",
    "ITA": "DOLO-ARINO",
    "state_code": "VE",
    "cab": "360800",
    "cap": "30031"
  },
  {
    "key": "5208",
    "ITA": "DOLO",
    "state_code": "VE",
    "cab": "360800",
    "cap": "30031"
  },
  {
    "key": "5209",
    "ITA": "DOLZAGO",
    "state_code": "LC",
    "cab": "512400",
    "cap": "23843"
  },
  {
    "key": "5210",
    "ITA": "DOMAGNANO DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47891"
  },
  {
    "key": "5211",
    "ITA": "DOMANICO",
    "state_code": "CS",
    "cab": "958835",
    "cap": "87030"
  },
  {
    "key": "5212",
    "ITA": "DOMASO",
    "state_code": "CO",
    "cab": "512509",
    "cap": "22013"
  },
  {
    "key": "5213",
    "ITA": "DOMEGGE DI CADORE",
    "state_code": "BL",
    "cab": "610808",
    "cap": "32040"
  },
  {
    "key": "5214",
    "ITA": "DOMEGGE DI CADORE-VALLESELLA",
    "state_code": "BL",
    "cab": "610808",
    "cap": "32040"
  },
  {
    "key": "5215",
    "ITA": "DOMICELLA-CASOLA",
    "state_code": "AV",
    "cab": "951103",
    "cap": "83020"
  },
  {
    "key": "5216",
    "ITA": "DOMICELLA",
    "state_code": "AV",
    "cab": "951103",
    "cap": "83020"
  },
  {
    "key": "5217",
    "ITA": "DOMODOSSOLA",
    "state_code": "VB",
    "cab": "453605",
    "cap": "28845"
  },
  {
    "key": "5218",
    "ITA": "DOMUS DE MARIA",
    "state_code": "CA",
    "cab": "442202",
    "cap": "9010"
  },
  {
    "key": "5219",
    "ITA": "DOMUSNOVAS",
    "state_code": "SU",
    "cab": "858902",
    "cap": "9015"
  },
  {
    "key": "5220",
    "ITA": "DON",
    "state_code": "TN",
    "cab": "904847",
    "cap": "38011"
  },
  {
    "key": "5221",
    "ITA": "DONATO",
    "state_code": "BI",
    "cab": "915892",
    "cap": "13893"
  },
  {
    "key": "5222",
    "ITA": "DONGO",
    "state_code": "CO",
    "cab": "512608",
    "cap": "22014"
  },
  {
    "key": "5223",
    "ITA": "DONNAS",
    "state_code": "AO",
    "cab": "315705",
    "cap": "11020"
  },
  {
    "key": "5224",
    "ITA": "DONNAS-GRAND VERT",
    "state_code": "AO",
    "cab": "315705",
    "cap": "11020"
  },
  {
    "key": "5225",
    "ITA": "DONORI'",
    "state_code": "CA",
    "cab": "859009",
    "cap": "9040"
  },
  {
    "key": "5226",
    "ITA": "DORGALI-CALA GONONE",
    "state_code": "NU",
    "cab": "852806",
    "cap": "8022"
  },
  {
    "key": "5227",
    "ITA": "DORGALI",
    "state_code": "NU",
    "cab": "852806",
    "cap": "8022"
  },
  {
    "key": "5228",
    "ITA": "DORIO",
    "state_code": "LC",
    "cab": "925529",
    "cap": "23824"
  },
  {
    "key": "5229",
    "ITA": "DORMELLETTO",
    "state_code": "NO",
    "cab": "453704",
    "cap": "28040"
  },
  {
    "key": "5230",
    "ITA": "DORNO",
    "state_code": "PV",
    "cab": "558403",
    "cap": "27020"
  },
  {
    "key": "5231",
    "ITA": "DORSINO-TAVODO",
    "state_code": "TN",
    "cab": "904854",
    "cap": "38070"
  },
  {
    "key": "5232",
    "ITA": "DORSINO",
    "state_code": "TN",
    "cab": "904854",
    "cap": "38070"
  },
  {
    "key": "5233",
    "ITA": "DORZANO",
    "state_code": "BI",
    "cab": "915900",
    "cap": "13881"
  },
  {
    "key": "5234",
    "ITA": "DOSOLO",
    "state_code": "MN",
    "cab": "576207",
    "cap": "46030"
  },
  {
    "key": "5235",
    "ITA": "DOSOLO-CORREGGIOVERDE",
    "state_code": "MN",
    "cab": "576207",
    "cap": "46030"
  },
  {
    "key": "5236",
    "ITA": "DOSOLO-VILLASTRADA",
    "state_code": "MN",
    "cab": "576207",
    "cap": "46030"
  },
  {
    "key": "5237",
    "ITA": "DOSSENA",
    "state_code": "BG",
    "cab": "728006",
    "cap": "24010"
  },
  {
    "key": "5238",
    "ITA": "DOSSO DEL LIRO",
    "state_code": "CO",
    "cab": "925537",
    "cap": "22010"
  },
  {
    "key": "5239",
    "ITA": "DOUES",
    "state_code": "AO",
    "cab": "902346",
    "cap": "11010"
  },
  {
    "key": "5240",
    "ITA": "DOVADOLA-SAN RUFFILLO",
    "state_code": "FC",
    "cab": "677807",
    "cap": "47013"
  },
  {
    "key": "5241",
    "ITA": "DOVADOLA",
    "state_code": "FC",
    "cab": "677807",
    "cap": "47013"
  },
  {
    "key": "5242",
    "ITA": "DOVERA",
    "state_code": "CR",
    "cab": "568501",
    "cap": "26010"
  },
  {
    "key": "5243",
    "ITA": "DOZZA-TOSCANELLA",
    "state_code": "BO",
    "cab": "368001",
    "cap": "40060"
  },
  {
    "key": "5244",
    "ITA": "DOZZA",
    "state_code": "BO",
    "cab": "368001",
    "cap": "40060"
  },
  {
    "key": "5245",
    "ITA": "DRAGONI",
    "state_code": "CE",
    "cab": "949297",
    "cap": "81010"
  },
  {
    "key": "5246",
    "ITA": "DRAGONI-SAN GIORGIO",
    "state_code": "CE",
    "cab": "949297",
    "cap": "81010"
  },
  {
    "key": "5247",
    "ITA": "DRAGONI-MAIORANO DI MONTE",
    "state_code": "CE",
    "cab": "949297",
    "cap": "81040"
  },
  {
    "key": "5248",
    "ITA": "DRAPIA-GASPONI",
    "state_code": "VV",
    "cab": "912907",
    "cap": "89862"
  },
  {
    "key": "5249",
    "ITA": "DRAPIA-CARIA",
    "state_code": "VV",
    "cab": "912907",
    "cap": "89862"
  },
  {
    "key": "5250",
    "ITA": "DRAPIA-BRATTIRO'",
    "state_code": "VV",
    "cab": "912907",
    "cap": "89862"
  },
  {
    "key": "5251",
    "ITA": "DRAPIA",
    "state_code": "VV",
    "cab": "912907",
    "cap": "89862"
  },
  {
    "key": "5252",
    "ITA": "DRENA",
    "state_code": "TN",
    "cab": "347500",
    "cap": "38074"
  },
  {
    "key": "5253",
    "ITA": "DRENCHIA",
    "state_code": "UD",
    "cab": "936930",
    "cap": "33040"
  },
  {
    "key": "5254",
    "ITA": "DRENCHIA-PACIUG",
    "state_code": "UD",
    "cab": "936930",
    "cap": "33040"
  },
  {
    "key": "5255",
    "ITA": "DRESANO",
    "state_code": "MI",
    "cab": "342204",
    "cap": "20070"
  },
  {
    "key": "5256",
    "ITA": "DREZZO",
    "state_code": "CO",
    "cab": "925545",
    "cap": "22020"
  },
  {
    "key": "5257",
    "ITA": "DRIZZONA",
    "state_code": "CR",
    "cab": "931659",
    "cap": "26034"
  },
  {
    "key": "5258",
    "ITA": "DRO-CENIGA",
    "state_code": "TN",
    "cab": "347609",
    "cap": "38074"
  },
  {
    "key": "5259",
    "ITA": "DRO-PIETRAMURATA",
    "state_code": "TN",
    "cab": "347609",
    "cap": "38074"
  },
  {
    "key": "5260",
    "ITA": "DRO",
    "state_code": "TN",
    "cab": "347609",
    "cap": "38074"
  },
  {
    "key": "5261",
    "ITA": "DRONERO",
    "state_code": "CN",
    "cab": "462804",
    "cap": "12025"
  },
  {
    "key": "5262",
    "ITA": "DRUENTO",
    "state_code": "TO",
    "cab": "304600",
    "cap": "10040"
  },
  {
    "key": "5263",
    "ITA": "DRUOGNO",
    "state_code": "VB",
    "cab": "782409",
    "cap": "28853"
  },
  {
    "key": "5264",
    "ITA": "DUALCHI",
    "state_code": "NU",
    "cab": "865402",
    "cap": "8010"
  },
  {
    "key": "5265",
    "ITA": "DUBINO",
    "state_code": "SO",
    "cab": "521500",
    "cap": "23015"
  },
  {
    "key": "5266",
    "ITA": "DUBINO-NUOVA OLONIO",
    "state_code": "SO",
    "cab": "521500",
    "cap": "23015"
  },
  {
    "key": "5267",
    "ITA": "DUE CARRARE",
    "state_code": "PD",
    "cab": "700005",
    "cap": "35020"
  },
  {
    "key": "5268",
    "ITA": "DUE CARRARE-TERRADURA",
    "state_code": "PD",
    "cab": "700005",
    "cap": "35020"
  },
  {
    "key": "5269",
    "ITA": "DUE CARRARE-CARRARA SANTO STEFANO",
    "state_code": "PD",
    "cab": "700005",
    "cap": "35020"
  },
  {
    "key": "5270",
    "ITA": "DUE CARRARE-CARRARA SAN GIORGIO",
    "state_code": "PD",
    "cab": "700005",
    "cap": "35020"
  },
  {
    "key": "5271",
    "ITA": "DUEVILLE-POVOLARO",
    "state_code": "VI",
    "cab": "603605",
    "cap": "36031"
  },
  {
    "key": "5272",
    "ITA": "DUEVILLE",
    "state_code": "VI",
    "cab": "603605",
    "cap": "36031"
  },
  {
    "key": "5273",
    "ITA": "DUGENTA",
    "state_code": "BN",
    "cab": "753301",
    "cap": "82030"
  },
  {
    "key": "5274",
    "ITA": "DUINO AURISINA",
    "state_code": "TS",
    "cab": "364604",
    "cap": "34011"
  },
  {
    "key": "5275",
    "ITA": "DUINO AURISINA-AURISINA",
    "state_code": "TS",
    "cab": "364604",
    "cap": "34011"
  },
  {
    "key": "5276",
    "ITA": "DUINO AURISINA-DUINO",
    "state_code": "TS",
    "cab": "364604",
    "cap": "34011"
  },
  {
    "key": "5277",
    "ITA": "DUINO AURISINA-SAN PELAGIO",
    "state_code": "TS",
    "cab": "364604",
    "cap": "34011"
  },
  {
    "key": "5278",
    "ITA": "DUINO AURISINA-SISTIANA",
    "state_code": "TS",
    "cab": "364604",
    "cap": "34011"
  },
  {
    "key": "5279",
    "ITA": "DUINO AURISINA-VILLAGGIO DEL PESCATORE",
    "state_code": "TS",
    "cab": "364604",
    "cap": "34011"
  },
  {
    "key": "5280",
    "ITA": "DUINO AURISINA-VISOGLIANO",
    "state_code": "TS",
    "cab": "364604",
    "cap": "34011"
  },
  {
    "key": "5281",
    "ITA": "DUMENZA",
    "state_code": "VA",
    "cab": "924480",
    "cap": "21010"
  },
  {
    "key": "5282",
    "ITA": "DUMENZA-DUE COSSANI",
    "state_code": "VA",
    "cab": "924480",
    "cap": "21010"
  },
  {
    "key": "5283",
    "ITA": "DUNO",
    "state_code": "VA",
    "cab": "924498",
    "cap": "21030"
  },
  {
    "key": "5284",
    "ITA": "DURAZZANO",
    "state_code": "BN",
    "cab": "492108",
    "cap": "82015"
  },
  {
    "key": "5285",
    "ITA": "DURONIA",
    "state_code": "CB",
    "cab": "910547",
    "cap": "86020"
  },
  {
    "key": "5286",
    "ITA": "DUSINO SAN MICHELE-DUSINO",
    "state_code": "AT",
    "cab": "920207",
    "cap": "14010"
  },
  {
    "key": "5287",
    "ITA": "DUSINO SAN MICHELE",
    "state_code": "AT",
    "cab": "920207",
    "cap": "14010"
  },
  {
    "key": "5288",
    "ITA": "EBOLI",
    "state_code": "SA",
    "cab": "762005",
    "cap": "84025"
  },
  {
    "key": "5289",
    "ITA": "EBOLI-SANTA CECILIA DI EBOLI",
    "state_code": "SA",
    "cab": "762005",
    "cap": "84025"
  },
  {
    "key": "5290",
    "ITA": "EBOLI-CORNO D'ORO",
    "state_code": "SA",
    "cab": "762005",
    "cap": "84025"
  },
  {
    "key": "5291",
    "ITA": "EBOLI-BIVIO SANTA CECILIA",
    "state_code": "SA",
    "cab": "762005",
    "cap": "84025"
  },
  {
    "key": "5292",
    "ITA": "EDOLO",
    "state_code": "BS",
    "cab": "544700",
    "cap": "25048"
  },
  {
    "key": "5293",
    "ITA": "EDOLO-CORTENEDOLO",
    "state_code": "BS",
    "cab": "544700",
    "cap": "25048"
  },
  {
    "key": "5294",
    "ITA": "EGNA-LAGHETTI",
    "state_code": "BZ",
    "cab": "583708",
    "cap": "39044"
  },
  {
    "key": "5295",
    "ITA": "EGNA",
    "state_code": "BZ",
    "cab": "583708",
    "cap": "39044"
  },
  {
    "key": "5296",
    "ITA": "ELICE",
    "state_code": "PE",
    "cab": "371609",
    "cap": "65010"
  },
  {
    "key": "5297",
    "ITA": "ELINI",
    "state_code": "OG",
    "cab": "965533",
    "cap": "8040"
  },
  {
    "key": "5298",
    "ITA": "ELLO",
    "state_code": "LC",
    "cab": "189100",
    "cap": "23848"
  },
  {
    "key": "5299",
    "ITA": "ELMAS",
    "state_code": "CA",
    "cab": "412304",
    "cap": "9030"
  },
  {
    "key": "5300",
    "ITA": "ELVA",
    "state_code": "CN",
    "cab": "918656",
    "cap": "12020"
  },
  {
    "key": "5301",
    "ITA": "EMARESE",
    "state_code": "AO",
    "cab": "902353",
    "cap": "11020"
  },
  {
    "key": "5302",
    "ITA": "EMPOLI",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5303",
    "ITA": "EMPOLI-BRUSCIANA",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5304",
    "ITA": "EMPOLI-CASE NUOVE",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5305",
    "ITA": "EMPOLI-FONTANELLA",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5306",
    "ITA": "EMPOLI-SANT'ANDREA",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5307",
    "ITA": "EMPOLI-MONTERAPPOLI",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5308",
    "ITA": "EMPOLI-OSTERIA BIANCA",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5309",
    "ITA": "EMPOLI-PONTE A ELSA",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5310",
    "ITA": "EMPOLI-MARCIGNANA",
    "state_code": "FI",
    "cab": "378307",
    "cap": "50053"
  },
  {
    "key": "5311",
    "ITA": "ENDINE GAIANO",
    "state_code": "BG",
    "cab": "529800",
    "cap": "24060"
  },
  {
    "key": "5312",
    "ITA": "ENDINE GAIANO-ENDINE",
    "state_code": "BG",
    "cab": "529800",
    "cap": "24060"
  },
  {
    "key": "5313",
    "ITA": "ENDINE GAIANO-PIANGAIANO",
    "state_code": "BG",
    "cab": "529800",
    "cap": "24060"
  },
  {
    "key": "5314",
    "ITA": "ENEGO-STONER",
    "state_code": "VI",
    "cab": "603704",
    "cap": "36052"
  },
  {
    "key": "5315",
    "ITA": "ENEGO",
    "state_code": "VI",
    "cab": "603704",
    "cap": "36052"
  },
  {
    "key": "5316",
    "ITA": "ENEMONZO-QUINIS",
    "state_code": "UD",
    "cab": "638007",
    "cap": "33020"
  },
  {
    "key": "5317",
    "ITA": "ENEMONZO",
    "state_code": "UD",
    "cab": "638007",
    "cap": "33020"
  },
  {
    "key": "5318",
    "ITA": "ENNA-PERGUSA",
    "state_code": "EN",
    "cab": "168005",
    "cap": "94100"
  },
  {
    "key": "5319",
    "ITA": "ENNA",
    "state_code": "EN",
    "cab": "168005",
    "cap": "94100"
  },
  {
    "key": "5320",
    "ITA": "ENNA-BORGO CASCINO",
    "state_code": "EN",
    "cab": "168005",
    "cap": "94100"
  },
  {
    "key": "5321",
    "ITA": "ENTRACQUE",
    "state_code": "CN",
    "cab": "462903",
    "cap": "12010"
  },
  {
    "key": "5322",
    "ITA": "ENTRATICO",
    "state_code": "BG",
    "cab": "837807",
    "cap": "24060"
  },
  {
    "key": "5323",
    "ITA": "ENVIE",
    "state_code": "CN",
    "cab": "463000",
    "cap": "12030"
  },
  {
    "key": "5324",
    "ITA": "EPISCOPIA",
    "state_code": "PZ",
    "cab": "716704",
    "cap": "85033"
  },
  {
    "key": "5325",
    "ITA": "ERACLEA",
    "state_code": "VE",
    "cab": "360909",
    "cap": "30020"
  },
  {
    "key": "5326",
    "ITA": "ERACLEA-ERACLEA MARE",
    "state_code": "VE",
    "cab": "360909",
    "cap": "30020"
  },
  {
    "key": "5327",
    "ITA": "ERACLEA-TORRE DI FINE",
    "state_code": "VE",
    "cab": "360909",
    "cap": "30020"
  },
  {
    "key": "5328",
    "ITA": "ERACLEA-STRETTI",
    "state_code": "VE",
    "cab": "360909",
    "cap": "30020"
  },
  {
    "key": "5329",
    "ITA": "ERACLEA-PONTE CREPALDO",
    "state_code": "VE",
    "cab": "360909",
    "cap": "30020"
  },
  {
    "key": "5330",
    "ITA": "ERBA",
    "state_code": "CO",
    "cab": "512707",
    "cap": "22036"
  },
  {
    "key": "5331",
    "ITA": "ERBA-ARCELLASCO",
    "state_code": "CO",
    "cab": "512707",
    "cap": "22036"
  },
  {
    "key": "5332",
    "ITA": "ERBE'",
    "state_code": "VR",
    "cab": "594507",
    "cap": "37060"
  },
  {
    "key": "5333",
    "ITA": "ERBEZZO",
    "state_code": "VR",
    "cab": "774802",
    "cap": "37020"
  },
  {
    "key": "5334",
    "ITA": "ERBUSCO",
    "state_code": "BS",
    "cab": "544809",
    "cap": "25030"
  },
  {
    "key": "5335",
    "ITA": "ERBUSCO-VILLA PEDERGNANO",
    "state_code": "BS",
    "cab": "544809",
    "cap": "25030"
  },
  {
    "key": "5336",
    "ITA": "ERBUSCO-ZOCCO",
    "state_code": "BS",
    "cab": "544809",
    "cap": "25030"
  },
  {
    "key": "5337",
    "ITA": "ERCHIE",
    "state_code": "BR",
    "cab": "791806",
    "cap": "72020"
  },
  {
    "key": "5338",
    "ITA": "ERCOLANO",
    "state_code": "NA",
    "cab": "401406",
    "cap": "80056"
  },
  {
    "key": "5339",
    "ITA": "ERCOLANO-RESINA",
    "state_code": "NA",
    "cab": "401406",
    "cap": "80056"
  },
  {
    "key": "5340",
    "ITA": "ERICE-PIZZOLUNGO",
    "state_code": "TP",
    "cab": "818500",
    "cap": "91016"
  },
  {
    "key": "5341",
    "ITA": "ERICE-SAN GIULIANO TRENTAPIEDI",
    "state_code": "TP",
    "cab": "818500",
    "cap": "91016"
  },
  {
    "key": "5342",
    "ITA": "ERICE-RIGALETTA",
    "state_code": "TP",
    "cab": "818500",
    "cap": "91016"
  },
  {
    "key": "5343",
    "ITA": "ERICE-NAPOLA",
    "state_code": "TP",
    "cab": "818500",
    "cap": "91016"
  },
  {
    "key": "5344",
    "ITA": "ERICE-CASA SANTA",
    "state_code": "TP",
    "cab": "818500",
    "cap": "91016"
  },
  {
    "key": "5345",
    "ITA": "ERICE-BALLATA",
    "state_code": "TP",
    "cab": "818500",
    "cap": "91016"
  },
  {
    "key": "5346",
    "ITA": "ERICE",
    "state_code": "TP",
    "cab": "818500",
    "cap": "91016"
  },
  {
    "key": "5347",
    "ITA": "ERLI",
    "state_code": "SV",
    "cab": "923102",
    "cap": "17030"
  },
  {
    "key": "5348",
    "ITA": "ERTO E CASSO",
    "state_code": "PN",
    "cab": "938183",
    "cap": "33080"
  },
  {
    "key": "5349",
    "ITA": "ERULA",
    "state_code": "SS",
    "cab": "724500",
    "cap": "7030"
  },
  {
    "key": "5350",
    "ITA": "ERVE",
    "state_code": "LC",
    "cab": "928119",
    "cap": "23805"
  },
  {
    "key": "5351",
    "ITA": "ESANATOGLIA",
    "state_code": "MC",
    "cab": "689000",
    "cap": "62024"
  },
  {
    "key": "5352",
    "ITA": "ESCALAPLANO",
    "state_code": "CA",
    "cab": "865501",
    "cap": "8043"
  },
  {
    "key": "5353",
    "ITA": "ESCOLCA",
    "state_code": "CA",
    "cab": "854406",
    "cap": "8030"
  },
  {
    "key": "5354",
    "ITA": "ESINE",
    "state_code": "BS",
    "cab": "544908",
    "cap": "25040"
  },
  {
    "key": "5355",
    "ITA": "ESINE-PLEMO",
    "state_code": "BS",
    "cab": "544908",
    "cap": "25040"
  },
  {
    "key": "5356",
    "ITA": "ESINE-SACCA",
    "state_code": "BS",
    "cab": "544908",
    "cap": "25040"
  },
  {
    "key": "5357",
    "ITA": "ESINO LARIO",
    "state_code": "LC",
    "cab": "519009",
    "cap": "23825"
  },
  {
    "key": "5358",
    "ITA": "ESPERIA",
    "state_code": "FR",
    "cab": "746404",
    "cap": "3045"
  },
  {
    "key": "5359",
    "ITA": "ESPERIA-MONTICELLI",
    "state_code": "FR",
    "cab": "746404",
    "cap": "3045"
  },
  {
    "key": "5360",
    "ITA": "ESPERIA-ESPERIA INFERIORE",
    "state_code": "FR",
    "cab": "746404",
    "cap": "3045"
  },
  {
    "key": "5361",
    "ITA": "ESPORLATU",
    "state_code": "SS",
    "cab": "873406",
    "cap": "7010"
  },
  {
    "key": "5362",
    "ITA": "ESTE",
    "state_code": "PD",
    "cab": "625608",
    "cap": "35042"
  },
  {
    "key": "5363",
    "ITA": "ESTE-PILASTRO",
    "state_code": "PD",
    "cab": "625608",
    "cap": "35042"
  },
  {
    "key": "5364",
    "ITA": "ESTE-DESERTO",
    "state_code": "PD",
    "cab": "625608",
    "cap": "35042"
  },
  {
    "key": "5365",
    "ITA": "ESTERZILI",
    "state_code": "CA",
    "cab": "865600",
    "cap": "8030"
  },
  {
    "key": "5366",
    "ITA": "ETROUBLES",
    "state_code": "AO",
    "cab": "315804",
    "cap": "11014"
  },
  {
    "key": "5367",
    "ITA": "EUPILIO-GALLIANO",
    "state_code": "CO",
    "cab": "703108",
    "cap": "22030"
  },
  {
    "key": "5368",
    "ITA": "EUPILIO-CORNENO",
    "state_code": "CO",
    "cab": "703108",
    "cap": "22030"
  },
  {
    "key": "5369",
    "ITA": "EUPILIO-PENZANO",
    "state_code": "CO",
    "cab": "703108",
    "cap": "22030"
  },
  {
    "key": "5370",
    "ITA": "EUPILIO",
    "state_code": "CO",
    "cab": "703108",
    "cap": "22030"
  },
  {
    "key": "5371",
    "ITA": "EXILLES",
    "state_code": "TO",
    "cab": "190363",
    "cap": "10050"
  },
  {
    "key": "5372",
    "ITA": "FABBRICA CURONE-GARADASSI",
    "state_code": "AL",
    "cab": "921155",
    "cap": "15054"
  },
  {
    "key": "5373",
    "ITA": "FABBRICA CURONE",
    "state_code": "AL",
    "cab": "921155",
    "cap": "15054"
  },
  {
    "key": "5374",
    "ITA": "FABBRICA CURONE-CALDIROLA",
    "state_code": "AL",
    "cab": "921155",
    "cap": "15054"
  },
  {
    "key": "5375",
    "ITA": "FABBRICHE DI VALLICO",
    "state_code": "LU",
    "cab": "274373",
    "cap": "55020"
  },
  {
    "key": "5376",
    "ITA": "FABBRICHE DI VERGEMOLI",
    "state_code": "LU",
    "cab": "54783",
    "cap": "55021"
  },
  {
    "key": "5377",
    "ITA": "FABBRICO",
    "state_code": "RE",
    "cab": "663302",
    "cap": "42042"
  },
  {
    "key": "5378",
    "ITA": "FABRIANO-CAMPODONICO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5379",
    "ITA": "FABRIANO-ATTIGGIO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5380",
    "ITA": "FABRIANO-MARISCHIO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5381",
    "ITA": "FABRIANO-MELANO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5382",
    "ITA": "FABRIANO-NEBBIANO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5383",
    "ITA": "FABRIANO-ROCCHETTA DI FABRIANO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5384",
    "ITA": "FABRIANO-SAN DONATO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5385",
    "ITA": "FABRIANO-SAN MICHELE",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5386",
    "ITA": "FABRIANO-SANT'ELIA",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5387",
    "ITA": "FABRIANO-SERRADICA",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5388",
    "ITA": "FABRIANO-CANCELLI",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5389",
    "ITA": "FABRIANO-CASTELLETTA",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5390",
    "ITA": "FABRIANO-COLLAMATO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5391",
    "ITA": "FABRIANO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5392",
    "ITA": "FABRIANO-ALBACINA",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60040"
  },
  {
    "key": "5393",
    "ITA": "FABRIANO-ARGIGNANO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5394",
    "ITA": "FABRIANO-BORGO TUFICO",
    "state_code": "AN",
    "cab": "211003",
    "cap": "60044"
  },
  {
    "key": "5395",
    "ITA": "FABRICA DI ROMA",
    "state_code": "VT",
    "cab": "730606",
    "cap": "1034"
  },
  {
    "key": "5396",
    "ITA": "FABRICA DI ROMA-REGOLELLI",
    "state_code": "VT",
    "cab": "730606",
    "cap": "1034"
  },
  {
    "key": "5397",
    "ITA": "FABRIZIA",
    "state_code": "VV",
    "cab": "425702",
    "cap": "89823"
  },
  {
    "key": "5398",
    "ITA": "FABRO-CARNAIOLA",
    "state_code": "TR",
    "cab": "726000",
    "cap": "5015"
  },
  {
    "key": "5399",
    "ITA": "FABRO-FABRO SCALO",
    "state_code": "TR",
    "cab": "726000",
    "cap": "5015"
  },
  {
    "key": "5400",
    "ITA": "FABRO",
    "state_code": "TR",
    "cab": "726000",
    "cap": "5015"
  },
  {
    "key": "5401",
    "ITA": "FAEDIS-CAMPEGLIO",
    "state_code": "UD",
    "cab": "638106",
    "cap": "33040"
  },
  {
    "key": "5402",
    "ITA": "FAEDIS",
    "state_code": "UD",
    "cab": "638106",
    "cap": "33040"
  },
  {
    "key": "5403",
    "ITA": "FAEDO",
    "state_code": "TN",
    "cab": "359604",
    "cap": "38010"
  },
  {
    "key": "5404",
    "ITA": "FAEDO VALTELLINO",
    "state_code": "SO",
    "cab": "927079",
    "cap": "23020"
  },
  {
    "key": "5405",
    "ITA": "FAENZA-SARNA",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5406",
    "ITA": "FAENZA",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5407",
    "ITA": "FAENZA-CASTEL RANIERO",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5408",
    "ITA": "FAENZA-CELLE",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5409",
    "ITA": "FAENZA-COSINA",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5410",
    "ITA": "FAENZA-ERRANO",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5411",
    "ITA": "FAENZA-FOSSOLO",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5412",
    "ITA": "FAENZA-GRANAROLO",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5413",
    "ITA": "FAENZA-MEZZENO",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5414",
    "ITA": "FAENZA-PRADA",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5415",
    "ITA": "FAENZA-REDA",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5416",
    "ITA": "FAENZA-SAN MARTINO",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5417",
    "ITA": "FAENZA-SAN SILVESTRO",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5418",
    "ITA": "FAENZA-SANTA LUCIA DELLE SPIANATE",
    "state_code": "RA",
    "cab": "237008",
    "cap": "48018"
  },
  {
    "key": "5419",
    "ITA": "FAETANO DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47896"
  },
  {
    "key": "5420",
    "ITA": "FAETO",
    "state_code": "FG",
    "cab": "956375",
    "cap": "71020"
  },
  {
    "key": "5421",
    "ITA": "FAGAGNA",
    "state_code": "UD",
    "cab": "638205",
    "cap": "33034"
  },
  {
    "key": "5422",
    "ITA": "FAGAGNA-CICONICCO",
    "state_code": "UD",
    "cab": "638205",
    "cap": "33034"
  },
  {
    "key": "5423",
    "ITA": "FAGAGNA-MADRISIO",
    "state_code": "UD",
    "cab": "638205",
    "cap": "33034"
  },
  {
    "key": "5424",
    "ITA": "FAGGETO LARIO",
    "state_code": "CO",
    "cab": "925586",
    "cap": "22020"
  },
  {
    "key": "5425",
    "ITA": "FAGGETO LARIO-LEMNA",
    "state_code": "CO",
    "cab": "925586",
    "cap": "22020"
  },
  {
    "key": "5426",
    "ITA": "FAGGIANO",
    "state_code": "TA",
    "cab": "788604",
    "cap": "74020"
  },
  {
    "key": "5427",
    "ITA": "FAGNANO ALTO",
    "state_code": "AQ",
    "cab": "909663",
    "cap": "67020"
  },
  {
    "key": "5428",
    "ITA": "FAGNANO CASTELLO",
    "state_code": "CS",
    "cab": "807305",
    "cap": "87013"
  },
  {
    "key": "5429",
    "ITA": "FAGNANO OLONA-BERGORO",
    "state_code": "VA",
    "cab": "502203",
    "cap": "21054"
  },
  {
    "key": "5430",
    "ITA": "FAGNANO OLONA",
    "state_code": "VA",
    "cab": "502203",
    "cap": "21054"
  },
  {
    "key": "5431",
    "ITA": "FAI DELLA PAGANELLA-CIMA PAGANELLA",
    "state_code": "TN",
    "cab": "347708",
    "cap": "38010"
  },
  {
    "key": "5432",
    "ITA": "FAI DELLA PAGANELLA",
    "state_code": "TN",
    "cab": "347708",
    "cap": "38010"
  },
  {
    "key": "5433",
    "ITA": "FAICCHIO",
    "state_code": "BN",
    "cab": "755207",
    "cap": "82030"
  },
  {
    "key": "5434",
    "ITA": "FAICCHIO-MASSA",
    "state_code": "BN",
    "cab": "755207",
    "cap": "82030"
  },
  {
    "key": "5435",
    "ITA": "FALCADE",
    "state_code": "BL",
    "cab": "610907",
    "cap": "32020"
  },
  {
    "key": "5436",
    "ITA": "FALCADE-CAVIOLA",
    "state_code": "BL",
    "cab": "610907",
    "cap": "32020"
  },
  {
    "key": "5437",
    "ITA": "FALCADE-PIE' FALCADE",
    "state_code": "BL",
    "cab": "610907",
    "cap": "32020"
  },
  {
    "key": "5438",
    "ITA": "FALCIANO DEL MASSICO",
    "state_code": "CE",
    "cab": "898205",
    "cap": "81030"
  },
  {
    "key": "5439",
    "ITA": "FALCIANO DI REPUBBLICA DI S.MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47896"
  },
  {
    "key": "5440",
    "ITA": "FALCONARA ALBANESE",
    "state_code": "CS",
    "cab": "958843",
    "cap": "87030"
  },
  {
    "key": "5441",
    "ITA": "FALCONARA ALBANESE-TORREMEZZO DI FALCONARA",
    "state_code": "CS",
    "cab": "958843",
    "cap": "87030"
  },
  {
    "key": "5442",
    "ITA": "FALCONARA MARITTIMA-FALCONARA ALTA",
    "state_code": "AN",
    "cab": "373506",
    "cap": "60015"
  },
  {
    "key": "5443",
    "ITA": "FALCONARA MARITTIMA-CASTELFERRETTI",
    "state_code": "AN",
    "cab": "373506",
    "cap": "60015"
  },
  {
    "key": "5444",
    "ITA": "FALCONARA MARITTIMA",
    "state_code": "AN",
    "cab": "373506",
    "cap": "60015"
  },
  {
    "key": "5445",
    "ITA": "FALCONE",
    "state_code": "ME",
    "cab": "821504",
    "cap": "98060"
  },
  {
    "key": "5446",
    "ITA": "FALCONE-BELVEDERE",
    "state_code": "ME",
    "cab": "821504",
    "cap": "98060"
  },
  {
    "key": "5447",
    "ITA": "FALERIA",
    "state_code": "VT",
    "cab": "730705",
    "cap": "1030"
  },
  {
    "key": "5448",
    "ITA": "FALERNA-FALERNA SCALO",
    "state_code": "CZ",
    "cab": "428805",
    "cap": "88040"
  },
  {
    "key": "5449",
    "ITA": "FALERNA-CASTIGLIONE MARITTIMO",
    "state_code": "CZ",
    "cab": "428805",
    "cap": "88040"
  },
  {
    "key": "5450",
    "ITA": "FALERNA",
    "state_code": "CZ",
    "cab": "428805",
    "cap": "88042"
  },
  {
    "key": "5451",
    "ITA": "FALERONE",
    "state_code": "FM",
    "cab": "694406",
    "cap": "63020"
  },
  {
    "key": "5452",
    "ITA": "FALERONE-PIANE",
    "state_code": "FM",
    "cab": "694406",
    "cap": "63020"
  },
  {
    "key": "5453",
    "ITA": "FALLO",
    "state_code": "CH",
    "cab": "955146",
    "cap": "66040"
  },
  {
    "key": "5454",
    "ITA": "FALMENTA",
    "state_code": "VB",
    "cab": "917260",
    "cap": "28827"
  },
  {
    "key": "5455",
    "ITA": "FALOPPIO-CAMNAGO",
    "state_code": "CO",
    "cab": "892703",
    "cap": "22020"
  },
  {
    "key": "5456",
    "ITA": "FALOPPIO-BOSCONE",
    "state_code": "CO",
    "cab": "892703",
    "cap": "22020"
  },
  {
    "key": "5457",
    "ITA": "FALOPPIO",
    "state_code": "CO",
    "cab": "892703",
    "cap": "22020"
  },
  {
    "key": "5458",
    "ITA": "FALOPPIO-GAGGINO",
    "state_code": "CO",
    "cab": "892703",
    "cap": "22020"
  },
  {
    "key": "5459",
    "ITA": "FALVATERRA",
    "state_code": "FR",
    "cab": "948356",
    "cap": "3020"
  },
  {
    "key": "5460",
    "ITA": "FALZES",
    "state_code": "BZ",
    "cab": "583807",
    "cap": "39030"
  },
  {
    "key": "5461",
    "ITA": "FANANO-OSPITALE",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5462",
    "ITA": "FANANO-LOTTA",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5463",
    "ITA": "FANANO-TRIGNANO",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5464",
    "ITA": "FANANO-TRENTINO NEL FRIGNANO",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5465",
    "ITA": "FANANO-SERRAZZONE",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5466",
    "ITA": "FANANO",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5467",
    "ITA": "FANANO-CANEVARE",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5468",
    "ITA": "FANANO-FELLICAROLO",
    "state_code": "MO",
    "cab": "667402",
    "cap": "41021"
  },
  {
    "key": "5469",
    "ITA": "FANNA",
    "state_code": "PN",
    "cab": "651000",
    "cap": "33092"
  },
  {
    "key": "5470",
    "ITA": "FANO",
    "state_code": "PU",
    "cab": "243006",
    "cap": "61032"
  },
  {
    "key": "5471",
    "ITA": "FANO-BELLOCCHI",
    "state_code": "PU",
    "cab": "243006",
    "cap": "61032"
  },
  {
    "key": "5472",
    "ITA": "FANO-CUCCURANO",
    "state_code": "PU",
    "cab": "243006",
    "cap": "61032"
  },
  {
    "key": "5473",
    "ITA": "FANO-FENILE",
    "state_code": "PU",
    "cab": "243006",
    "cap": "61032"
  },
  {
    "key": "5474",
    "ITA": "FANO ADRIANO-CERQUETO",
    "state_code": "TE",
    "cab": "953646",
    "cap": "64044"
  },
  {
    "key": "5475",
    "ITA": "FANO ADRIANO",
    "state_code": "TE",
    "cab": "953646",
    "cap": "64044"
  },
  {
    "key": "5476",
    "ITA": "FARA FILIORUM PETRI",
    "state_code": "CH",
    "cab": "776609",
    "cap": "66010"
  },
  {
    "key": "5477",
    "ITA": "FARA GERA D'ADDA-BADALASCO",
    "state_code": "BG",
    "cab": "529909",
    "cap": "24045"
  },
  {
    "key": "5478",
    "ITA": "FARA GERA D'ADDA",
    "state_code": "BG",
    "cab": "529909",
    "cap": "24045"
  },
  {
    "key": "5479",
    "ITA": "FARA IN SABINA",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5480",
    "ITA": "FARA IN SABINA-BORGO QUINZIO",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5481",
    "ITA": "FARA IN SABINA-TALOCCI",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5482",
    "ITA": "FARA IN SABINA-PRIME CASE",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5483",
    "ITA": "FARA IN SABINA-PASSO CORESE",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5484",
    "ITA": "FARA IN SABINA-FARFA",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5485",
    "ITA": "FARA IN SABINA-CORESE TERRA",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5486",
    "ITA": "FARA IN SABINA-COLTODINO",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5487",
    "ITA": "FARA IN SABINA-CANNETO",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5488",
    "ITA": "FARA IN SABINA-BORGO SALARIO",
    "state_code": "RI",
    "cab": "736207",
    "cap": "2032"
  },
  {
    "key": "5489",
    "ITA": "FARA NOVARESE",
    "state_code": "NO",
    "cab": "453803",
    "cap": "28073"
  },
  {
    "key": "5490",
    "ITA": "FARA OLIVANA CON SOLA-FARA OLIVANA",
    "state_code": "BG",
    "cab": "895201",
    "cap": "24058"
  },
  {
    "key": "5491",
    "ITA": "FARA OLIVANA CON SOLA-SOLA",
    "state_code": "BG",
    "cab": "895201",
    "cap": "24058"
  },
  {
    "key": "5492",
    "ITA": "FARA OLIVANA CON SOLA",
    "state_code": "BG",
    "cab": "895201",
    "cap": "24058"
  },
  {
    "key": "5493",
    "ITA": "FARA SAN MARTINO",
    "state_code": "CH",
    "cab": "776708",
    "cap": "66015"
  },
  {
    "key": "5494",
    "ITA": "FARA VICENTINO",
    "state_code": "VI",
    "cab": "603803",
    "cap": "36030"
  },
  {
    "key": "5495",
    "ITA": "FARA VICENTINO-SAN GIORGIO DI PERLENA",
    "state_code": "VI",
    "cab": "603803",
    "cap": "36030"
  },
  {
    "key": "5496",
    "ITA": "FARDELLA",
    "state_code": "PZ",
    "cab": "911974",
    "cap": "85034"
  },
  {
    "key": "5497",
    "ITA": "FARIGLIANO",
    "state_code": "CN",
    "cab": "463109",
    "cap": "12060"
  },
  {
    "key": "5498",
    "ITA": "FARINDOLA",
    "state_code": "PE",
    "cab": "773002",
    "cap": "65010"
  },
  {
    "key": "5499",
    "ITA": "FARINI-GROPPALLO",
    "state_code": "PC",
    "cab": "652909",
    "cap": "29023"
  },
  {
    "key": "5500",
    "ITA": "FARINI",
    "state_code": "PC",
    "cab": "652909",
    "cap": "29023"
  },
  {
    "key": "5501",
    "ITA": "FARINI-LE MOLINE",
    "state_code": "PC",
    "cab": "652909",
    "cap": "29023"
  },
  {
    "key": "5502",
    "ITA": "FARINI-MARETO",
    "state_code": "PC",
    "cab": "652909",
    "cap": "29023"
  },
  {
    "key": "5503",
    "ITA": "FARNESE",
    "state_code": "VT",
    "cab": "730804",
    "cap": "1010"
  },
  {
    "key": "5504",
    "ITA": "FARRA D'ALPAGO",
    "state_code": "BL",
    "cab": "611004",
    "cap": "32016"
  },
  {
    "key": "5505",
    "ITA": "FARRA D'ALPAGO-SANTA CROCE",
    "state_code": "BL",
    "cab": "611004",
    "cap": "32016"
  },
  {
    "key": "5506",
    "ITA": "FARRA D'ALPAGO-SPERT",
    "state_code": "BL",
    "cab": "611004",
    "cap": "32016"
  },
  {
    "key": "5507",
    "ITA": "FARRA D'ISONZO",
    "state_code": "GO",
    "cab": "645705",
    "cap": "34072"
  },
  {
    "key": "5508",
    "ITA": "FARRA DI SOLIGO-COL SAN MARTINO",
    "state_code": "TV",
    "cab": "616706",
    "cap": "31010"
  },
  {
    "key": "5509",
    "ITA": "FARRA DI SOLIGO",
    "state_code": "TV",
    "cab": "616706",
    "cap": "31010"
  },
  {
    "key": "5510",
    "ITA": "FARRA DI SOLIGO-SOLIGO",
    "state_code": "TV",
    "cab": "616706",
    "cap": "31010"
  },
  {
    "key": "5511",
    "ITA": "FASANO-MARINA DI SAVELLETRI",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72015"
  },
  {
    "key": "5512",
    "ITA": "FASANO-TORRE CANNE",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72016"
  },
  {
    "key": "5513",
    "ITA": "FASANO-STAZIONE DI FASANO",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72015"
  },
  {
    "key": "5514",
    "ITA": "FASANO-SELVA",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72015"
  },
  {
    "key": "5515",
    "ITA": "FASANO-SAVELLETRI",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72015"
  },
  {
    "key": "5516",
    "ITA": "FASANO-PEZZE DI GRECO",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72010"
  },
  {
    "key": "5517",
    "ITA": "FASANO-MONTALBANO",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72016"
  },
  {
    "key": "5518",
    "ITA": "FASANO-MADONNA POZZO GUACITO",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72016"
  },
  {
    "key": "5519",
    "ITA": "FASANO-LAMIE DI OLIMPIE",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72015"
  },
  {
    "key": "5520",
    "ITA": "FASANO",
    "state_code": "BR",
    "cab": "258004",
    "cap": "72015"
  },
  {
    "key": "5521",
    "ITA": "FASCIA",
    "state_code": "GE",
    "cab": "903153",
    "cap": "16020"
  },
  {
    "key": "5522",
    "ITA": "FASCIA-CASSINGHENO",
    "state_code": "GE",
    "cab": "903153",
    "cap": "16020"
  },
  {
    "key": "5523",
    "ITA": "FAUGLIA-LUCIANA",
    "state_code": "PI",
    "cab": "710103",
    "cap": "56043"
  },
  {
    "key": "5524",
    "ITA": "FAUGLIA",
    "state_code": "PI",
    "cab": "710103",
    "cap": "56043"
  },
  {
    "key": "5525",
    "ITA": "FAULE",
    "state_code": "CN",
    "cab": "918664",
    "cap": "12030"
  },
  {
    "key": "5526",
    "ITA": "FAVALE DI MALVARO",
    "state_code": "GE",
    "cab": "903161",
    "cap": "16040"
  },
  {
    "key": "5527",
    "ITA": "FAVARA",
    "state_code": "AG",
    "cab": "829309",
    "cap": "92026"
  },
  {
    "key": "5528",
    "ITA": "FAVER",
    "state_code": "TN",
    "cab": "347807",
    "cap": "38030"
  },
  {
    "key": "5529",
    "ITA": "FAVIGNANA-MARETTIMO",
    "state_code": "TP",
    "cab": "818609",
    "cap": "91010"
  },
  {
    "key": "5530",
    "ITA": "FAVIGNANA-LEVANZO",
    "state_code": "TP",
    "cab": "818609",
    "cap": "91023"
  },
  {
    "key": "5531",
    "ITA": "FAVIGNANA",
    "state_code": "TP",
    "cab": "818609",
    "cap": "91023"
  },
  {
    "key": "5532",
    "ITA": "FAVRIA",
    "state_code": "TO",
    "cab": "304709",
    "cap": "10083"
  },
  {
    "key": "5533",
    "ITA": "FEISOGLIO",
    "state_code": "CN",
    "cab": "323600",
    "cap": "12050"
  },
  {
    "key": "5534",
    "ITA": "FELETTO",
    "state_code": "TO",
    "cab": "314401",
    "cap": "10080"
  },
  {
    "key": "5535",
    "ITA": "FELINO",
    "state_code": "PR",
    "cab": "657205",
    "cap": "43035"
  },
  {
    "key": "5536",
    "ITA": "FELINO-SANT'ILARIO DI BAGANZA",
    "state_code": "PR",
    "cab": "657205",
    "cap": "43035"
  },
  {
    "key": "5537",
    "ITA": "FELINO-SAN MICHELE DI TIORRE",
    "state_code": "PR",
    "cab": "657205",
    "cap": "43030"
  },
  {
    "key": "5538",
    "ITA": "FELITTO",
    "state_code": "SA",
    "cab": "952515",
    "cap": "84055"
  },
  {
    "key": "5539",
    "ITA": "FELIZZANO",
    "state_code": "AL",
    "cab": "482307",
    "cap": "15023"
  },
  {
    "key": "5540",
    "ITA": "FELONICA",
    "state_code": "MN",
    "cab": "576306",
    "cap": "46022"
  },
  {
    "key": "5541",
    "ITA": "FELTRE-VILLABRUNA",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5542",
    "ITA": "FELTRE-VILLAPAIERA",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5543",
    "ITA": "FELTRE-UMIN",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5544",
    "ITA": "FELTRE-TOMO",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5545",
    "ITA": "FELTRE-MUGNAI",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5546",
    "ITA": "FELTRE",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5547",
    "ITA": "FELTRE-ANZU'",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5548",
    "ITA": "FELTRE-FOEN",
    "state_code": "BL",
    "cab": "611103",
    "cap": "32032"
  },
  {
    "key": "5549",
    "ITA": "FENEGRO'",
    "state_code": "CO",
    "cab": "520205",
    "cap": "22070"
  },
  {
    "key": "5550",
    "ITA": "FENESTRELLE-MENTOULLES",
    "state_code": "TO",
    "cab": "304808",
    "cap": "10060"
  },
  {
    "key": "5551",
    "ITA": "FENESTRELLE-PRA' CATINAT",
    "state_code": "TO",
    "cab": "304808",
    "cap": "10060"
  },
  {
    "key": "5552",
    "ITA": "FENESTRELLE",
    "state_code": "TO",
    "cab": "304808",
    "cap": "10060"
  },
  {
    "key": "5553",
    "ITA": "FENIS",
    "state_code": "AO",
    "cab": "317404",
    "cap": "11020"
  },
  {
    "key": "5554",
    "ITA": "FENIS-CHEZ CROISET",
    "state_code": "AO",
    "cab": "317404",
    "cap": "11020"
  },
  {
    "key": "5555",
    "ITA": "FERENTILLO",
    "state_code": "TR",
    "cab": "726109",
    "cap": "5034"
  },
  {
    "key": "5556",
    "ITA": "FERENTINO-TOFE",
    "state_code": "FR",
    "cab": "744201",
    "cap": "3013"
  },
  {
    "key": "5557",
    "ITA": "FERENTINO-PORCIANO",
    "state_code": "FR",
    "cab": "744201",
    "cap": "3013"
  },
  {
    "key": "5558",
    "ITA": "FERENTINO-FERENTINO STAZIONE",
    "state_code": "FR",
    "cab": "744201",
    "cap": "3013"
  },
  {
    "key": "5559",
    "ITA": "FERENTINO",
    "state_code": "FR",
    "cab": "744201",
    "cap": "3013"
  },
  {
    "key": "5560",
    "ITA": "FERLA",
    "state_code": "SR",
    "cab": "846907",
    "cap": "96010"
  },
  {
    "key": "5561",
    "ITA": "FERMIGNANO",
    "state_code": "PU",
    "cab": "682807",
    "cap": "61033"
  },
  {
    "key": "5562",
    "ITA": "FERMO-SALVANO",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5563",
    "ITA": "FERMO-SAN MARCO",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5564",
    "ITA": "FERMO-SAN TOMMASO TRE ARCHI",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5565",
    "ITA": "FERMO-TORRE DI PALME",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5566",
    "ITA": "FERMO-PONTE ETE",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5567",
    "ITA": "FERMO-MOLINI DI TENNA",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5568",
    "ITA": "FERMO-MARINA PALMENSE",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5569",
    "ITA": "FERMO-LIDO DI FERMO",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5570",
    "ITA": "FERMO-CAPODARCO",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5571",
    "ITA": "FERMO-CAMPIGLIONE",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5572",
    "ITA": "FERMO",
    "state_code": "FM",
    "cab": "694515",
    "cap": "63900"
  },
  {
    "key": "5573",
    "ITA": "FERMO-CALDARETTE",
    "state_code": "FM",
    "cab": "694505",
    "cap": "63023"
  },
  {
    "key": "5574",
    "ITA": "FERNO",
    "state_code": "VA",
    "cab": "502302",
    "cap": "21010"
  },
  {
    "key": "5575",
    "ITA": "FERNO-SAN MACARIO",
    "state_code": "VA",
    "cab": "502302",
    "cap": "21010"
  },
  {
    "key": "5576",
    "ITA": "FEROLETO ANTICO",
    "state_code": "CZ",
    "cab": "912923",
    "cap": "88040"
  },
  {
    "key": "5577",
    "ITA": "FEROLETO ANTICO-IEVOLI",
    "state_code": "CZ",
    "cab": "912923",
    "cap": "88040"
  },
  {
    "key": "5578",
    "ITA": "FEROLETO DELLA CHIESA-PLAESANO",
    "state_code": "RC",
    "cab": "960112",
    "cap": "89050"
  },
  {
    "key": "5579",
    "ITA": "FEROLETO DELLA CHIESA",
    "state_code": "RC",
    "cab": "960112",
    "cap": "89050"
  },
  {
    "key": "5580",
    "ITA": "FERRANDINA-MACCHIA",
    "state_code": "MT",
    "cab": "803106",
    "cap": "75013"
  },
  {
    "key": "5581",
    "ITA": "FERRANDINA",
    "state_code": "MT",
    "cab": "803106",
    "cap": "75013"
  },
  {
    "key": "5582",
    "ITA": "FERRARA-FRANCOLINO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5583",
    "ITA": "FERRARA-VICONOVO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5584",
    "ITA": "FERRARA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5585",
    "ITA": "FERRARA-BAURA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5586",
    "ITA": "FERRARA-BOARA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5587",
    "ITA": "FERRARA-CASAGLIA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5588",
    "ITA": "FERRARA-CASSANA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5589",
    "ITA": "FERRARA-CHIESUOL DEL FOSSO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5590",
    "ITA": "FERRARA-COCOMARO DI CONA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5591",
    "ITA": "FERRARA-CODREA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5592",
    "ITA": "FERRARA-CONA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5593",
    "ITA": "FERRARA-CORLO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5594",
    "ITA": "FERRARA-DENORE",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5595",
    "ITA": "FERRARA-FOSSA D'ALBERO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5596",
    "ITA": "FERRARA-FOSSANOVA SAN MARCO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5597",
    "ITA": "FERRARA-VILLANOVA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5598",
    "ITA": "FERRARA-GAIBANA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5599",
    "ITA": "FERRARA-GAIBANELLA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5600",
    "ITA": "FERRARA-MARRARA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5601",
    "ITA": "FERRARA-MONESTIROLO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5602",
    "ITA": "FERRARA-MONTALBANO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5603",
    "ITA": "FERRARA-PONTEGRADELLA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5604",
    "ITA": "FERRARA-PONTELAGOSCURO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5605",
    "ITA": "FERRARA-POROTTO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5606",
    "ITA": "FERRARA-QUARTESANA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5607",
    "ITA": "FERRARA-RAVALLE",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5608",
    "ITA": "FERRARA-SAN BARTOLOMEO IN BOSCO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5609",
    "ITA": "FERRARA-SAN MARTINO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5610",
    "ITA": "FERRARA-SANT'EDIGIO",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5611",
    "ITA": "FERRARA-TORRE DELLA FOSSA",
    "state_code": "FE",
    "cab": "130005",
    "cap": "44100"
  },
  {
    "key": "5612",
    "ITA": "FERRARA DI MONTE BALDO",
    "state_code": "VR",
    "cab": "933309",
    "cap": "37020"
  },
  {
    "key": "5613",
    "ITA": "FERRAZZANO",
    "state_code": "CB",
    "cab": "396101",
    "cap": "86010"
  },
  {
    "key": "5614",
    "ITA": "FERRERA DI VARESE",
    "state_code": "VA",
    "cab": "924506",
    "cap": "21030"
  },
  {
    "key": "5615",
    "ITA": "FERRERA ERBOGNONE",
    "state_code": "PV",
    "cab": "558502",
    "cap": "27032"
  },
  {
    "key": "5616",
    "ITA": "FERRERE",
    "state_code": "AT",
    "cab": "474205",
    "cap": "14012"
  },
  {
    "key": "5617",
    "ITA": "FERRIERE-BRUGNETO",
    "state_code": "PC",
    "cab": "653006",
    "cap": "29024"
  },
  {
    "key": "5618",
    "ITA": "FERRIERE",
    "state_code": "PC",
    "cab": "653006",
    "cap": "29024"
  },
  {
    "key": "5619",
    "ITA": "FERRIERE-CENTENARO CASTELLO",
    "state_code": "PC",
    "cab": "653006",
    "cap": "29024"
  },
  {
    "key": "5620",
    "ITA": "FERRIERE-TORRIO SOPRA",
    "state_code": "PC",
    "cab": "653006",
    "cap": "29024"
  },
  {
    "key": "5621",
    "ITA": "FERRIERE-TORRIO CASETTA",
    "state_code": "PC",
    "cab": "653006",
    "cap": "29024"
  },
  {
    "key": "5622",
    "ITA": "FERRIERE-SALSOMINORE",
    "state_code": "PC",
    "cab": "653006",
    "cap": "29024"
  },
  {
    "key": "5623",
    "ITA": "FERRUZZANO-CANALELLO",
    "state_code": "RC",
    "cab": "960120",
    "cap": "89030"
  },
  {
    "key": "5624",
    "ITA": "FERRUZZANO",
    "state_code": "RC",
    "cab": "960120",
    "cap": "89030"
  },
  {
    "key": "5625",
    "ITA": "FIAMIGNANO-SANT'IPPOLITO",
    "state_code": "RI",
    "cab": "736306",
    "cap": "2023"
  },
  {
    "key": "5626",
    "ITA": "FIAMIGNANO-SANT'AGAPITO",
    "state_code": "RI",
    "cab": "736306",
    "cap": "2023"
  },
  {
    "key": "5627",
    "ITA": "FIAMIGNANO-SANTA LUCIA",
    "state_code": "RI",
    "cab": "736306",
    "cap": "2023"
  },
  {
    "key": "5628",
    "ITA": "FIAMIGNANO-PESCHIETA",
    "state_code": "RI",
    "cab": "736306",
    "cap": "2023"
  },
  {
    "key": "5629",
    "ITA": "FIAMIGNANO",
    "state_code": "RI",
    "cab": "736306",
    "cap": "2023"
  },
  {
    "key": "5630",
    "ITA": "FIANO",
    "state_code": "TO",
    "cab": "313908",
    "cap": "10070"
  },
  {
    "key": "5631",
    "ITA": "FIANO ROMANO",
    "state_code": "RM",
    "cab": "390807",
    "cap": "65"
  },
  {
    "key": "5632",
    "ITA": "FIANO ROMANO-FERONIA",
    "state_code": "RM",
    "cab": "390807",
    "cap": "65"
  },
  {
    "key": "5633",
    "ITA": "FIASTRA-FIEGNI",
    "state_code": "MC",
    "cab": "689109",
    "cap": "62035"
  },
  {
    "key": "5634",
    "ITA": "FIASTRA",
    "state_code": "MC",
    "cab": "689109",
    "cap": "62035"
  },
  {
    "key": "5635",
    "ITA": "FIAVE'-BALLINO",
    "state_code": "TN",
    "cab": "347906",
    "cap": "38075"
  },
  {
    "key": "5636",
    "ITA": "FIAVE'",
    "state_code": "TN",
    "cab": "347906",
    "cap": "38075"
  },
  {
    "key": "5637",
    "ITA": "FICARAZZI",
    "state_code": "PA",
    "cab": "433409",
    "cap": "90010"
  },
  {
    "key": "5638",
    "ITA": "FICAROLO",
    "state_code": "RO",
    "cab": "632901",
    "cap": "45036"
  },
  {
    "key": "5639",
    "ITA": "FICARRA",
    "state_code": "ME",
    "cab": "821603",
    "cap": "98062"
  },
  {
    "key": "5640",
    "ITA": "FICULLE",
    "state_code": "TR",
    "cab": "726208",
    "cap": "5016"
  },
  {
    "key": "5641",
    "ITA": "FICULLE-SALA",
    "state_code": "TR",
    "cab": "726208",
    "cap": "5016"
  },
  {
    "key": "5642",
    "ITA": "FIDENZA-PIEVE DI CUSIGNANO",
    "state_code": "PR",
    "cab": "657304",
    "cap": "43036"
  },
  {
    "key": "5643",
    "ITA": "FIDENZA-COGOLONCHIO",
    "state_code": "PR",
    "cab": "657304",
    "cap": "43036"
  },
  {
    "key": "5644",
    "ITA": "FIDENZA-CHIUSA FERRANDA",
    "state_code": "PR",
    "cab": "657304",
    "cap": "43036"
  },
  {
    "key": "5645",
    "ITA": "FIDENZA-CASTIONE MARCHESI",
    "state_code": "PR",
    "cab": "657304",
    "cap": "43036"
  },
  {
    "key": "5646",
    "ITA": "FIDENZA",
    "state_code": "PR",
    "cab": "657304",
    "cap": "43036"
  },
  {
    "key": "5647",
    "ITA": "FIE' ALLO SCILIAR",
    "state_code": "BZ",
    "cab": "583906",
    "cap": "39050"
  },
  {
    "key": "5648",
    "ITA": "FIE' ALLO SCILIAR-AICA DI FIE'",
    "state_code": "BZ",
    "cab": "583906",
    "cap": "39050"
  },
  {
    "key": "5649",
    "ITA": "FIERA DI PRIMIERO",
    "state_code": "TN",
    "cab": "348003",
    "cap": "38054"
  },
  {
    "key": "5650",
    "ITA": "FIEROZZO",
    "state_code": "TN",
    "cab": "820605",
    "cap": "38050"
  },
  {
    "key": "5651",
    "ITA": "FIEROZZO-SAN FRANCESCO",
    "state_code": "TN",
    "cab": "820605",
    "cap": "38050"
  },
  {
    "key": "5652",
    "ITA": "FIESCO",
    "state_code": "CR",
    "cab": "564609",
    "cap": "26010"
  },
  {
    "key": "5653",
    "ITA": "FIESOLE-TRESPIANO",
    "state_code": "FI",
    "cab": "378406",
    "cap": "50010"
  },
  {
    "key": "5654",
    "ITA": "FIESOLE-SAN DOMENICO DI FIESOLE",
    "state_code": "FI",
    "cab": "378406",
    "cap": "50014"
  },
  {
    "key": "5655",
    "ITA": "FIESOLE-PIAN DI MUGNONE",
    "state_code": "FI",
    "cab": "378406",
    "cap": "50014"
  },
  {
    "key": "5656",
    "ITA": "FIESOLE-COMPIOBBI",
    "state_code": "FI",
    "cab": "378406",
    "cap": "50061"
  },
  {
    "key": "5657",
    "ITA": "FIESOLE-CALDINE",
    "state_code": "FI",
    "cab": "378406",
    "cap": "50010"
  },
  {
    "key": "5658",
    "ITA": "FIESOLE",
    "state_code": "FI",
    "cab": "378406",
    "cap": "50014"
  },
  {
    "key": "5659",
    "ITA": "FIESSE",
    "state_code": "BS",
    "cab": "545004",
    "cap": "25020"
  },
  {
    "key": "5660",
    "ITA": "FIESSO D'ARTICO",
    "state_code": "VE",
    "cab": "361006",
    "cap": "30032"
  },
  {
    "key": "5661",
    "ITA": "FIESSO UMBERTIANO",
    "state_code": "RO",
    "cab": "633008",
    "cap": "45024"
  },
  {
    "key": "5662",
    "ITA": "FIGINO SERENZA",
    "state_code": "CO",
    "cab": "512806",
    "cap": "22060"
  },
  {
    "key": "5663",
    "ITA": "FIGLINE VALDARNO",
    "state_code": "FI",
    "cab": "378505",
    "cap": "50063"
  },
  {
    "key": "5664",
    "ITA": "FIGLINE VEGLIATURO",
    "state_code": "CS",
    "cab": "958850",
    "cap": "87050"
  },
  {
    "key": "5665",
    "ITA": "FILACCIANO",
    "state_code": "RM",
    "cab": "908129",
    "cap": "60"
  },
  {
    "key": "5666",
    "ITA": "FILADELFIA-SCARRO",
    "state_code": "VV",
    "cab": "425801",
    "cap": "89814"
  },
  {
    "key": "5667",
    "ITA": "FILADELFIA-MONTESORO",
    "state_code": "VV",
    "cab": "425801",
    "cap": "89814"
  },
  {
    "key": "5668",
    "ITA": "FILADELFIA",
    "state_code": "VV",
    "cab": "425801",
    "cap": "89814"
  },
  {
    "key": "5669",
    "ITA": "FILAGO",
    "state_code": "BG",
    "cab": "530006",
    "cap": "24040"
  },
  {
    "key": "5670",
    "ITA": "FILANDARI",
    "state_code": "VV",
    "cab": "705905",
    "cap": "89841"
  },
  {
    "key": "5671",
    "ITA": "FILANDARI-ARZONA",
    "state_code": "VV",
    "cab": "705905",
    "cap": "89841"
  },
  {
    "key": "5672",
    "ITA": "FILANDARI-PIZZINNI",
    "state_code": "VV",
    "cab": "705905",
    "cap": "89841"
  },
  {
    "key": "5673",
    "ITA": "FILATTIERA",
    "state_code": "MS",
    "cab": "699009",
    "cap": "54023"
  },
  {
    "key": "5674",
    "ITA": "FILATTIERA-CANTIERE",
    "state_code": "MS",
    "cab": "699009",
    "cap": "54023"
  },
  {
    "key": "5675",
    "ITA": "FILATTIERA-PONTICELLO",
    "state_code": "MS",
    "cab": "699009",
    "cap": "54023"
  },
  {
    "key": "5676",
    "ITA": "FILATTIERA-SCORCETOLI",
    "state_code": "MS",
    "cab": "699009",
    "cap": "54023"
  },
  {
    "key": "5677",
    "ITA": "FILETTINO",
    "state_code": "FR",
    "cab": "744300",
    "cap": "3010"
  },
  {
    "key": "5678",
    "ITA": "FILETTO",
    "state_code": "CH",
    "cab": "954677",
    "cap": "66030"
  },
  {
    "key": "5679",
    "ITA": "FILIANO-STERPITO DI SOPRA",
    "state_code": "PZ",
    "cab": "419705",
    "cap": "85020"
  },
  {
    "key": "5680",
    "ITA": "FILIANO-SCALERA",
    "state_code": "PZ",
    "cab": "419705",
    "cap": "85020"
  },
  {
    "key": "5681",
    "ITA": "FILIANO-DRAGONETTI",
    "state_code": "PZ",
    "cab": "419705",
    "cap": "85020"
  },
  {
    "key": "5682",
    "ITA": "FILIANO",
    "state_code": "PZ",
    "cab": "419705",
    "cap": "85020"
  },
  {
    "key": "5683",
    "ITA": "FILIANO-STERPITO DI SOTTO",
    "state_code": "PZ",
    "cab": "419705",
    "cap": "85020"
  },
  {
    "key": "5684",
    "ITA": "FILIGHERA",
    "state_code": "PV",
    "cab": "930479",
    "cap": "27010"
  },
  {
    "key": "5685",
    "ITA": "FILIGNANO",
    "state_code": "IS",
    "cab": "186650",
    "cap": "86074"
  },
  {
    "key": "5686",
    "ITA": "FILIGNANO-CERASUOLO",
    "state_code": "IS",
    "cab": "186650",
    "cap": "86074"
  },
  {
    "key": "5687",
    "ITA": "FILOGASO",
    "state_code": "VV",
    "cab": "191775",
    "cap": "89843"
  },
  {
    "key": "5688",
    "ITA": "FILOTTRANO",
    "state_code": "AN",
    "cab": "373605",
    "cap": "60024"
  },
  {
    "key": "5689",
    "ITA": "FILOTTRANO-MONTORO",
    "state_code": "AN",
    "cab": "373605",
    "cap": "60024"
  },
  {
    "key": "5690",
    "ITA": "FINALE EMILIA-RENO FINALESE",
    "state_code": "MO",
    "cab": "667501",
    "cap": "41034"
  },
  {
    "key": "5691",
    "ITA": "FINALE EMILIA",
    "state_code": "MO",
    "cab": "667501",
    "cap": "41034"
  },
  {
    "key": "5692",
    "ITA": "FINALE EMILIA-MASSA FINALESE",
    "state_code": "MO",
    "cab": "667501",
    "cap": "41035"
  },
  {
    "key": "5693",
    "ITA": "FINALE LIGURE-FINALBORGO",
    "state_code": "SV",
    "cab": "494104",
    "cap": "17024"
  },
  {
    "key": "5694",
    "ITA": "FINALE LIGURE-GORRA",
    "state_code": "SV",
    "cab": "494104",
    "cap": "17024"
  },
  {
    "key": "5695",
    "ITA": "FINALE LIGURE-VARIGOTTI",
    "state_code": "SV",
    "cab": "494104",
    "cap": "17024"
  },
  {
    "key": "5696",
    "ITA": "FINALE LIGURE",
    "state_code": "SV",
    "cab": "494104",
    "cap": "17024"
  },
  {
    "key": "5697",
    "ITA": "FINO DEL MONTE",
    "state_code": "BG",
    "cab": "817007",
    "cap": "24020"
  },
  {
    "key": "5698",
    "ITA": "FINO MORNASCO",
    "state_code": "CO",
    "cab": "512905",
    "cap": "22073"
  },
  {
    "key": "5699",
    "ITA": "FINO MORNASCO-ANDRATE",
    "state_code": "CO",
    "cab": "512905",
    "cap": "22073"
  },
  {
    "key": "5700",
    "ITA": "FINO MORNASCO-MOLINETTO",
    "state_code": "CO",
    "cab": "512905",
    "cap": "22073"
  },
  {
    "key": "5701",
    "ITA": "FIORANO AL SERIO",
    "state_code": "BG",
    "cab": "530105",
    "cap": "24020"
  },
  {
    "key": "5702",
    "ITA": "FIORANO CANAVESE",
    "state_code": "TO",
    "cab": "283549",
    "cap": "10010"
  },
  {
    "key": "5703",
    "ITA": "FIORANO MODENESE",
    "state_code": "MO",
    "cab": "667600",
    "cap": "41042"
  },
  {
    "key": "5704",
    "ITA": "FIORANO MODENESE-SPEZZANO",
    "state_code": "MO",
    "cab": "667600",
    "cap": "41042"
  },
  {
    "key": "5705",
    "ITA": "FIORANO MODENESE-UBERSETTO",
    "state_code": "MO",
    "cab": "667600",
    "cap": "41042"
  },
  {
    "key": "5706",
    "ITA": "FIORDIMONTE",
    "state_code": "MC",
    "cab": "941955",
    "cap": "62035"
  },
  {
    "key": "5707",
    "ITA": "FIORENTINO DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47897"
  },
  {
    "key": "5708",
    "ITA": "FIORENZUOLA D'ARDA",
    "state_code": "PC",
    "cab": "653105",
    "cap": "29017"
  },
  {
    "key": "5709",
    "ITA": "FIORENZUOLA D'ARDA-BASELICADUCE",
    "state_code": "PC",
    "cab": "653105",
    "cap": "29017"
  },
  {
    "key": "5710",
    "ITA": "FIORENZUOLA D'ARDA-SAN PROTASO",
    "state_code": "PC",
    "cab": "653105",
    "cap": "29017"
  },
  {
    "key": "5711",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50137"
  },
  {
    "key": "5712",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50121"
  },
  {
    "key": "5713",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50122"
  },
  {
    "key": "5714",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50123"
  },
  {
    "key": "5715",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50124"
  },
  {
    "key": "5716",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50125"
  },
  {
    "key": "5717",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50126"
  },
  {
    "key": "5718",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50127"
  },
  {
    "key": "5719",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50129"
  },
  {
    "key": "5720",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50131"
  },
  {
    "key": "5721",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50132"
  },
  {
    "key": "5722",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50133"
  },
  {
    "key": "5723",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50134"
  },
  {
    "key": "5724",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50135"
  },
  {
    "key": "5725",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50139"
  },
  {
    "key": "5726",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50141"
  },
  {
    "key": "5727",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50142"
  },
  {
    "key": "5728",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50143"
  },
  {
    "key": "5729",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50144"
  },
  {
    "key": "5730",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50136"
  },
  {
    "key": "5731",
    "ITA": "FIRENZE-GALLUZZO",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50124"
  },
  {
    "key": "5732",
    "ITA": "FIRENZE",
    "state_code": "FI",
    "cab": "28001",
    "cap": "50145"
  },
  {
    "key": "5733",
    "ITA": "FIRENZUOLA-RIFREDO",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5734",
    "ITA": "FIRENZUOLA-TRAVERSA",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5735",
    "ITA": "FIRENZUOLA-PIETRAMALA",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5736",
    "ITA": "FIRENZUOLA-PIANCALDOLI",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5737",
    "ITA": "FIRENZUOLA-FILIGARE",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5738",
    "ITA": "FIRENZUOLA-COVIGLIAIO",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5739",
    "ITA": "FIRENZUOLA-CORNACCHIAIA",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5740",
    "ITA": "FIRENZUOLA-CONIALE",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5741",
    "ITA": "FIRENZUOLA",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5742",
    "ITA": "FIRENZUOLA-BRUSCOLI",
    "state_code": "FI",
    "cab": "378604",
    "cap": "50033"
  },
  {
    "key": "5743",
    "ITA": "FIRMO",
    "state_code": "CS",
    "cab": "958868",
    "cap": "87010"
  },
  {
    "key": "5744",
    "ITA": "FISCIANO-PENTA",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5745",
    "ITA": "FISCIANO-PIZZOLANO",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5746",
    "ITA": "FISCIANO-SETTEFICHI",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5747",
    "ITA": "FISCIANO-BOLANO",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5748",
    "ITA": "FISCIANO-LANCUSI",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5749",
    "ITA": "FISCIANO-GAIANO",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5750",
    "ITA": "FISCIANO",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5751",
    "ITA": "FISCIANO-VILLA",
    "state_code": "SA",
    "cab": "762104",
    "cap": "84084"
  },
  {
    "key": "5752",
    "ITA": "FIUGGI",
    "state_code": "FR",
    "cab": "744409",
    "cap": "3014"
  },
  {
    "key": "5753",
    "ITA": "FIUGGI-FIUGGI FONTE",
    "state_code": "FR",
    "cab": "744409",
    "cap": "3014"
  },
  {
    "key": "5754",
    "ITA": "FIUMALBO-DOGANA NUOVA",
    "state_code": "MO",
    "cab": "667709",
    "cap": "41022"
  },
  {
    "key": "5755",
    "ITA": "FIUMALBO",
    "state_code": "MO",
    "cab": "667709",
    "cap": "41022"
  },
  {
    "key": "5756",
    "ITA": "FIUMALBO-FAIDELLO",
    "state_code": "MO",
    "cab": "667709",
    "cap": "41022"
  },
  {
    "key": "5757",
    "ITA": "FIUMARA",
    "state_code": "RC",
    "cab": "960138",
    "cap": "89050"
  },
  {
    "key": "5758",
    "ITA": "FIUME VENETO-BANNIA",
    "state_code": "PN",
    "cab": "648709",
    "cap": "33080"
  },
  {
    "key": "5759",
    "ITA": "FIUME VENETO",
    "state_code": "PN",
    "cab": "648709",
    "cap": "33080"
  },
  {
    "key": "5760",
    "ITA": "FIUME VENETO-CIMPELLO",
    "state_code": "PN",
    "cab": "648709",
    "cap": "33080"
  },
  {
    "key": "5761",
    "ITA": "FIUMEDINISI",
    "state_code": "ME",
    "cab": "889808",
    "cap": "98022"
  },
  {
    "key": "5762",
    "ITA": "FIUMEFREDDO BRUZIO-REGGIO",
    "state_code": "CS",
    "cab": "958876",
    "cap": "87030"
  },
  {
    "key": "5763",
    "ITA": "FIUMEFREDDO BRUZIO-MARINA DI FIUMEFREDDO BRUZIO",
    "state_code": "CS",
    "cab": "958876",
    "cap": "87030"
  },
  {
    "key": "5764",
    "ITA": "FIUMEFREDDO BRUZIO",
    "state_code": "CS",
    "cab": "958876",
    "cap": "87030"
  },
  {
    "key": "5765",
    "ITA": "FIUMEFREDDO BRUZIO-SCORNAVACCA",
    "state_code": "CS",
    "cab": "958876",
    "cap": "87030"
  },
  {
    "key": "5766",
    "ITA": "FIUMEFREDDO BRUZIO-STAZIONE DI FIUMEFREDDO BRUZIO",
    "state_code": "CS",
    "cab": "958876",
    "cap": "87030"
  },
  {
    "key": "5767",
    "ITA": "FIUMEFREDDO BRUZIO-SAN BIASE",
    "state_code": "CS",
    "cab": "958876",
    "cap": "87030"
  },
  {
    "key": "5768",
    "ITA": "FIUMEFREDDO DI SICILIA",
    "state_code": "CT",
    "cab": "839407",
    "cap": "95013"
  },
  {
    "key": "5769",
    "ITA": "FIUMICELLO-PAPARIANO",
    "state_code": "UD",
    "cab": "638304",
    "cap": "33050"
  },
  {
    "key": "5770",
    "ITA": "FIUMICELLO",
    "state_code": "UD",
    "cab": "638304",
    "cap": "33050"
  },
  {
    "key": "5771",
    "ITA": "FIUMICELLO-SAN VALENTINO",
    "state_code": "UD",
    "cab": "638304",
    "cap": "33050"
  },
  {
    "key": "5772",
    "ITA": "FIUMICINO-FREGENE",
    "state_code": "RM",
    "cab": "496307",
    "cap": "54"
  },
  {
    "key": "5773",
    "ITA": "FIUMICINO-ISOLA SACRA",
    "state_code": "RM",
    "cab": "496307",
    "cap": "54"
  },
  {
    "key": "5774",
    "ITA": "FIUMICINO-MACCARESE",
    "state_code": "RM",
    "cab": "496307",
    "cap": "57"
  },
  {
    "key": "5775",
    "ITA": "FIUMICINO-MALAGROTTA",
    "state_code": "RM",
    "cab": "496307",
    "cap": "57"
  },
  {
    "key": "5776",
    "ITA": "FIUMICINO-PANTANO DI GRANO",
    "state_code": "RM",
    "cab": "496307",
    "cap": "57"
  },
  {
    "key": "5777",
    "ITA": "FIUMICINO-PASSO OSCURO",
    "state_code": "RM",
    "cab": "496307",
    "cap": "50"
  },
  {
    "key": "5778",
    "ITA": "FIUMICINO-TESTA DI LEPRE",
    "state_code": "RM",
    "cab": "496307",
    "cap": "50"
  },
  {
    "key": "5779",
    "ITA": "FIUMICINO-TORRIMPIETRA",
    "state_code": "RM",
    "cab": "496307",
    "cap": "50"
  },
  {
    "key": "5780",
    "ITA": "FIUMICINO-FOCENE",
    "state_code": "RM",
    "cab": "496307",
    "cap": "54"
  },
  {
    "key": "5781",
    "ITA": "FIUMICINO-FIUMICINO AEROPORTO",
    "state_code": "RM",
    "cab": "496307",
    "cap": "54"
  },
  {
    "key": "5782",
    "ITA": "FIUMICINO",
    "state_code": "RM",
    "cab": "496307",
    "cap": "54"
  },
  {
    "key": "5783",
    "ITA": "FIUMICINO-ARA NOVA",
    "state_code": "RM",
    "cab": "496307",
    "cap": "50"
  },
  {
    "key": "5784",
    "ITA": "FIUMINATA-FONTE DI BRESCIA",
    "state_code": "MC",
    "cab": "689208",
    "cap": "62025"
  },
  {
    "key": "5785",
    "ITA": "FIUMINATA",
    "state_code": "MC",
    "cab": "689208",
    "cap": "62025"
  },
  {
    "key": "5786",
    "ITA": "FIUMINATA-MASSA",
    "state_code": "MC",
    "cab": "689208",
    "cap": "62025"
  },
  {
    "key": "5787",
    "ITA": "FIVIZZANO-GASSANO",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5788",
    "ITA": "FIVIZZANO-GRAGNOLA",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5789",
    "ITA": "FIVIZZANO-MONCIGOLI",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5790",
    "ITA": "FIVIZZANO-MONZONE",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5791",
    "ITA": "FIVIZZANO-ROMETTA",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5792",
    "ITA": "FIVIZZANO-SAN TERENZO MONTI",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5793",
    "ITA": "FIVIZZANO-SASSALBO",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5794",
    "ITA": "FIVIZZANO-SOLIERA",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5795",
    "ITA": "FIVIZZANO-TENERANO",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5796",
    "ITA": "FIVIZZANO-VINCA",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5797",
    "ITA": "FIVIZZANO-COLLA",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5798",
    "ITA": "FIVIZZANO-CESERANO",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5799",
    "ITA": "FIVIZZANO-CAMPIGLIONE",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5800",
    "ITA": "FIVIZZANO",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5801",
    "ITA": "FIVIZZANO-AGNINO",
    "state_code": "MS",
    "cab": "699108",
    "cap": "54013"
  },
  {
    "key": "5802",
    "ITA": "FLAIBANO",
    "state_code": "UD",
    "cab": "638403",
    "cap": "33030"
  },
  {
    "key": "5803",
    "ITA": "FLAVON",
    "state_code": "TN",
    "cab": "348102",
    "cap": "38010"
  },
  {
    "key": "5804",
    "ITA": "FLERO",
    "state_code": "BS",
    "cab": "545103",
    "cap": "25020"
  },
  {
    "key": "5805",
    "ITA": "FLORESTA",
    "state_code": "ME",
    "cab": "827105",
    "cap": "98030"
  },
  {
    "key": "5806",
    "ITA": "FLORIDIA",
    "state_code": "SR",
    "cab": "847004",
    "cap": "96014"
  },
  {
    "key": "5807",
    "ITA": "FLORINAS",
    "state_code": "SS",
    "cab": "873505",
    "cap": "7030"
  },
  {
    "key": "5808",
    "ITA": "FLUMERI",
    "state_code": "AV",
    "cab": "759209",
    "cap": "83040"
  },
  {
    "key": "5809",
    "ITA": "FLUMINIMAGGIORE",
    "state_code": "SU",
    "cab": "859108",
    "cap": "9010"
  },
  {
    "key": "5810",
    "ITA": "FLUSSIO",
    "state_code": "OR",
    "cab": "865709",
    "cap": "8010"
  },
  {
    "key": "5811",
    "ITA": "FOBELLO",
    "state_code": "VC",
    "cab": "444703",
    "cap": "13025"
  },
  {
    "key": "5812",
    "ITA": "FOGGIA-ARPINOVA",
    "state_code": "FG",
    "cab": "157008",
    "cap": "71100"
  },
  {
    "key": "5813",
    "ITA": "FOGGIA-CERVARO",
    "state_code": "FG",
    "cab": "157008",
    "cap": "71100"
  },
  {
    "key": "5814",
    "ITA": "FOGGIA-RIGNANO GARGANICO SCALO",
    "state_code": "FG",
    "cab": "157008",
    "cap": "71100"
  },
  {
    "key": "5815",
    "ITA": "FOGGIA-SEGEZIA",
    "state_code": "FG",
    "cab": "157008",
    "cap": "71100"
  },
  {
    "key": "5816",
    "ITA": "FOGGIA-TAVERNOLA",
    "state_code": "FG",
    "cab": "157008",
    "cap": "71100"
  },
  {
    "key": "5817",
    "ITA": "FOGGIA",
    "state_code": "FG",
    "cab": "157008",
    "cap": "71100"
  },
  {
    "key": "5818",
    "ITA": "FOGGIA-INCORONATA",
    "state_code": "FG",
    "cab": "157008",
    "cap": "71100"
  },
  {
    "key": "5819",
    "ITA": "FOGLIANISE",
    "state_code": "BN",
    "cab": "753400",
    "cap": "82030"
  },
  {
    "key": "5820",
    "ITA": "FOGLIANO REDIPUGLIA",
    "state_code": "GO",
    "cab": "646901",
    "cap": "34070"
  },
  {
    "key": "5821",
    "ITA": "FOGLIANO REDIPUGLIA-POLAZZO",
    "state_code": "GO",
    "cab": "646901",
    "cap": "34070"
  },
  {
    "key": "5822",
    "ITA": "FOGLIANO REDIPUGLIA-REDIPUGLIA",
    "state_code": "GO",
    "cab": "646901",
    "cap": "34070"
  },
  {
    "key": "5823",
    "ITA": "FOGLIZZO",
    "state_code": "TO",
    "cab": "304907",
    "cap": "10090"
  },
  {
    "key": "5824",
    "ITA": "FOIANO DELLA CHIANA-POZZO DELLA CHIANA",
    "state_code": "AR",
    "cab": "714600",
    "cap": "52045"
  },
  {
    "key": "5825",
    "ITA": "FOIANO DELLA CHIANA",
    "state_code": "AR",
    "cab": "714600",
    "cap": "52045"
  },
  {
    "key": "5826",
    "ITA": "FOIANO DI VAL FORTORE",
    "state_code": "BN",
    "cab": "838102",
    "cap": "82020"
  },
  {
    "key": "5827",
    "ITA": "FOLGARIA-SERRADA",
    "state_code": "TN",
    "cab": "348201",
    "cap": "38064"
  },
  {
    "key": "5828",
    "ITA": "FOLGARIA-SAN SEBASTIANO",
    "state_code": "TN",
    "cab": "348201",
    "cap": "38064"
  },
  {
    "key": "5829",
    "ITA": "FOLGARIA-NOSELLARI",
    "state_code": "TN",
    "cab": "348201",
    "cap": "38064"
  },
  {
    "key": "5830",
    "ITA": "FOLGARIA-MEZZOMONTE DI SOTTO",
    "state_code": "TN",
    "cab": "348201",
    "cap": "38064"
  },
  {
    "key": "5831",
    "ITA": "FOLGARIA-MEZZOMONTE DI SOPRA",
    "state_code": "TN",
    "cab": "348201",
    "cap": "38064"
  },
  {
    "key": "5832",
    "ITA": "FOLGARIA-CARBONARE",
    "state_code": "TN",
    "cab": "348201",
    "cap": "38064"
  },
  {
    "key": "5833",
    "ITA": "FOLGARIA",
    "state_code": "TN",
    "cab": "348201",
    "cap": "38064"
  },
  {
    "key": "5834",
    "ITA": "FOLIGNANO-VILLA PIGNA",
    "state_code": "AP",
    "cab": "697607",
    "cap": "63040"
  },
  {
    "key": "5835",
    "ITA": "FOLIGNANO-PIANE DI MORRO",
    "state_code": "AP",
    "cab": "697607",
    "cap": "63040"
  },
  {
    "key": "5836",
    "ITA": "FOLIGNANO-PIGNA BASSA",
    "state_code": "AP",
    "cab": "697607",
    "cap": "63040"
  },
  {
    "key": "5837",
    "ITA": "FOLIGNANO",
    "state_code": "AP",
    "cab": "697607",
    "cap": "63040"
  },
  {
    "key": "5838",
    "ITA": "FOLIGNO-FIAMENGA",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5839",
    "ITA": "FOLIGNO-PALE",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5840",
    "ITA": "FOLIGNO-PERTICANI",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5841",
    "ITA": "FOLIGNO-PIEVE FANONICA",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5842",
    "ITA": "FOLIGNO-RASIGLIA",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5843",
    "ITA": "FOLIGNO-SAN GIOVANNI PROFIAMMA",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5844",
    "ITA": "FOLIGNO-SANT'ERACLIO",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5845",
    "ITA": "FOLIGNO-SCAFALI",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5846",
    "ITA": "FOLIGNO-SCANZANO",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5847",
    "ITA": "FOLIGNO-SCOPOLI",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5848",
    "ITA": "FOLIGNO-STERPETE",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5849",
    "ITA": "FOLIGNO-UPPELLO",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5850",
    "ITA": "FOLIGNO-VERCHIANO",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5851",
    "ITA": "FOLIGNO",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5852",
    "ITA": "FOLIGNO-ANNIFO",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5853",
    "ITA": "FOLIGNO-BELFIORE",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5854",
    "ITA": "FOLIGNO-CAPODACQUA",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5855",
    "ITA": "FOLIGNO-CASENOVE",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5856",
    "ITA": "FOLIGNO-COLFIORITO",
    "state_code": "PG",
    "cab": "217000",
    "cap": "6034"
  },
  {
    "key": "5857",
    "ITA": "FOLLINA-VALMARENO",
    "state_code": "TV",
    "cab": "616805",
    "cap": "31051"
  },
  {
    "key": "5858",
    "ITA": "FOLLINA",
    "state_code": "TV",
    "cab": "616805",
    "cap": "31051"
  },
  {
    "key": "5859",
    "ITA": "FOLLINA-PEDEGUARDA",
    "state_code": "TV",
    "cab": "616805",
    "cap": "31051"
  },
  {
    "key": "5860",
    "ITA": "FOLLO",
    "state_code": "SP",
    "cab": "497602",
    "cap": "19020"
  },
  {
    "key": "5861",
    "ITA": "FOLLO-BASTREMOLI",
    "state_code": "SP",
    "cab": "497602",
    "cap": "19020"
  },
  {
    "key": "5862",
    "ITA": "FOLLO-PIANA BATTOLLA",
    "state_code": "SP",
    "cab": "497602",
    "cap": "19020"
  },
  {
    "key": "5863",
    "ITA": "FOLLO-PIANO DI FOLLO",
    "state_code": "SP",
    "cab": "497602",
    "cap": "19020"
  },
  {
    "key": "5864",
    "ITA": "FOLLO-SAN MARTINO DI DURASCA",
    "state_code": "SP",
    "cab": "497602",
    "cap": "19020"
  },
  {
    "key": "5865",
    "ITA": "FOLLO-TIVEGNA",
    "state_code": "SP",
    "cab": "497602",
    "cap": "19020"
  },
  {
    "key": "5866",
    "ITA": "FOLLO-VALDURASCA",
    "state_code": "SP",
    "cab": "497602",
    "cap": "19020"
  },
  {
    "key": "5867",
    "ITA": "FOLLONICA-PRATO RANIERI",
    "state_code": "GR",
    "cab": "722405",
    "cap": "58022"
  },
  {
    "key": "5868",
    "ITA": "FOLLONICA",
    "state_code": "GR",
    "cab": "722405",
    "cap": "58022"
  },
  {
    "key": "5869",
    "ITA": "FOMBIO-RETEGNO",
    "state_code": "LO",
    "cab": "331108",
    "cap": "26861"
  },
  {
    "key": "5870",
    "ITA": "FOMBIO",
    "state_code": "LO",
    "cab": "331108",
    "cap": "26861"
  },
  {
    "key": "5871",
    "ITA": "FONDACHELLI FANTINA-FANTINA",
    "state_code": "ME",
    "cab": "821702",
    "cap": "98050"
  },
  {
    "key": "5872",
    "ITA": "FONDACHELLI FANTINA-RUBINO",
    "state_code": "ME",
    "cab": "821702",
    "cap": "98050"
  },
  {
    "key": "5873",
    "ITA": "FONDACHELLI FANTINA-EVANGELISTI",
    "state_code": "ME",
    "cab": "821702",
    "cap": "98050"
  },
  {
    "key": "5874",
    "ITA": "FONDACHELLI FANTINA",
    "state_code": "ME",
    "cab": "821702",
    "cap": "98050"
  },
  {
    "key": "5875",
    "ITA": "FONDI-SAN MAGNO",
    "state_code": "LT",
    "cab": "739706",
    "cap": "4022"
  },
  {
    "key": "5876",
    "ITA": "FONDI-SALTO DI FONDI",
    "state_code": "LT",
    "cab": "739706",
    "cap": "4022"
  },
  {
    "key": "5877",
    "ITA": "FONDI",
    "state_code": "LT",
    "cab": "739706",
    "cap": "4022"
  },
  {
    "key": "5878",
    "ITA": "FONDO",
    "state_code": "TN",
    "cab": "348300",
    "cap": "38013"
  },
  {
    "key": "5879",
    "ITA": "FONDO-TRET",
    "state_code": "TN",
    "cab": "348300",
    "cap": "38013"
  },
  {
    "key": "5880",
    "ITA": "FONDO-VASIO",
    "state_code": "TN",
    "cab": "348300",
    "cap": "38013"
  },
  {
    "key": "5881",
    "ITA": "FONNI",
    "state_code": "NU",
    "cab": "852905",
    "cap": "8023"
  },
  {
    "key": "5882",
    "ITA": "FONTAINEMORE",
    "state_code": "AO",
    "cab": "902379",
    "cap": "11020"
  },
  {
    "key": "5883",
    "ITA": "FONTANA LIRI",
    "state_code": "FR",
    "cab": "744508",
    "cap": "3035"
  },
  {
    "key": "5884",
    "ITA": "FONTANA LIRI-COLLEFONTANA",
    "state_code": "FR",
    "cab": "744508",
    "cap": "3035"
  },
  {
    "key": "5885",
    "ITA": "FONTANA LIRI-FONTANA LIRI SUPERIORE",
    "state_code": "FR",
    "cab": "744508",
    "cap": "3035"
  },
  {
    "key": "5886",
    "ITA": "FONTANA LIRI-SAN PAOLO",
    "state_code": "FR",
    "cab": "744508",
    "cap": "3035"
  },
  {
    "key": "5887",
    "ITA": "FONTANAFREDDA",
    "state_code": "PN",
    "cab": "648808",
    "cap": "33074"
  },
  {
    "key": "5888",
    "ITA": "FONTANAFREDDA-NAVE",
    "state_code": "PN",
    "cab": "648808",
    "cap": "33074"
  },
  {
    "key": "5889",
    "ITA": "FONTANAFREDDA-VIGONOVO",
    "state_code": "PN",
    "cab": "648808",
    "cap": "33074"
  },
  {
    "key": "5890",
    "ITA": "FONTANAFREDDA-CEOLINI",
    "state_code": "PN",
    "cab": "648808",
    "cap": "33074"
  },
  {
    "key": "5891",
    "ITA": "FONTANAROSA",
    "state_code": "AV",
    "cab": "895508",
    "cap": "83040"
  },
  {
    "key": "5892",
    "ITA": "FONTANELICE",
    "state_code": "BO",
    "cab": "368100",
    "cap": "40025"
  },
  {
    "key": "5893",
    "ITA": "FONTANELLA",
    "state_code": "BG",
    "cab": "530204",
    "cap": "24056"
  },
  {
    "key": "5894",
    "ITA": "FONTANELLATO",
    "state_code": "PR",
    "cab": "657403",
    "cap": "43012"
  },
  {
    "key": "5895",
    "ITA": "FONTANELLATO-PAROLA",
    "state_code": "PR",
    "cab": "657403",
    "cap": "43012"
  },
  {
    "key": "5896",
    "ITA": "FONTANELLE",
    "state_code": "TV",
    "cab": "616904",
    "cap": "31043"
  },
  {
    "key": "5897",
    "ITA": "FONTANELLE-LUTRANO",
    "state_code": "TV",
    "cab": "616904",
    "cap": "31043"
  },
  {
    "key": "5898",
    "ITA": "FONTANETO D'AGOGNA",
    "state_code": "NO",
    "cab": "453902",
    "cap": "28010"
  },
  {
    "key": "5899",
    "ITA": "FONTANETTO PO",
    "state_code": "VC",
    "cab": "444802",
    "cap": "13040"
  },
  {
    "key": "5900",
    "ITA": "FONTANIGORDA",
    "state_code": "GE",
    "cab": "319905",
    "cap": "16023"
  },
  {
    "key": "5901",
    "ITA": "FONTANIGORDA-CANALE",
    "state_code": "GE",
    "cab": "319905",
    "cap": "16023"
  },
  {
    "key": "5902",
    "ITA": "FONTANIGORDA-CASONI",
    "state_code": "GE",
    "cab": "319905",
    "cap": "16023"
  },
  {
    "key": "5903",
    "ITA": "FONTANILE",
    "state_code": "AT",
    "cab": "920215",
    "cap": "14044"
  },
  {
    "key": "5904",
    "ITA": "FONTANIVA",
    "state_code": "PD",
    "cab": "625707",
    "cap": "35014"
  },
  {
    "key": "5905",
    "ITA": "FONTE-ONE'",
    "state_code": "TV",
    "cab": "617001",
    "cap": "31010"
  },
  {
    "key": "5906",
    "ITA": "FONTE",
    "state_code": "TV",
    "cab": "617001",
    "cap": "31010"
  },
  {
    "key": "5907",
    "ITA": "FONTE-FONTE ALTO",
    "state_code": "TV",
    "cab": "617001",
    "cap": "31010"
  },
  {
    "key": "5908",
    "ITA": "FONTE NUOVA-SANTA LUCIA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "13"
  },
  {
    "key": "5909",
    "ITA": "FONTE NUOVA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "13"
  },
  {
    "key": "5910",
    "ITA": "FONTE NUOVA-TOR LUPARA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "13"
  },
  {
    "key": "5911",
    "ITA": "FONTECCHIO",
    "state_code": "AQ",
    "cab": "909671",
    "cap": "67020"
  },
  {
    "key": "5912",
    "ITA": "FONTECHIARI",
    "state_code": "FR",
    "cab": "288050",
    "cap": "3030"
  },
  {
    "key": "5913",
    "ITA": "FONTEGRECA",
    "state_code": "CE",
    "cab": "949305",
    "cap": "81014"
  },
  {
    "key": "5914",
    "ITA": "FONTENO",
    "state_code": "BG",
    "cab": "928143",
    "cap": "24060"
  },
  {
    "key": "5915",
    "ITA": "FONTEVIVO-PONTE TARO",
    "state_code": "PR",
    "cab": "657502",
    "cap": "43010"
  },
  {
    "key": "5916",
    "ITA": "FONTEVIVO",
    "state_code": "PR",
    "cab": "657502",
    "cap": "43010"
  },
  {
    "key": "5917",
    "ITA": "FONTEVIVO-BIANCONESE",
    "state_code": "PR",
    "cab": "657502",
    "cap": "43010"
  },
  {
    "key": "5918",
    "ITA": "FONTEVIVO-CASTELGUELFO",
    "state_code": "PR",
    "cab": "657502",
    "cap": "43010"
  },
  {
    "key": "5919",
    "ITA": "FONZASO",
    "state_code": "BL",
    "cab": "611202",
    "cap": "32030"
  },
  {
    "key": "5920",
    "ITA": "FONZASO-ARTEN",
    "state_code": "BL",
    "cab": "611202",
    "cap": "32030"
  },
  {
    "key": "5921",
    "ITA": "FOPPOLO",
    "state_code": "BG",
    "cab": "530303",
    "cap": "24010"
  },
  {
    "key": "5922",
    "ITA": "FORANO",
    "state_code": "RI",
    "cab": "736405",
    "cap": "2044"
  },
  {
    "key": "5923",
    "ITA": "FORANO-GAVIGNANO SABINO",
    "state_code": "RI",
    "cab": "736405",
    "cap": "2044"
  },
  {
    "key": "5924",
    "ITA": "FORCE",
    "state_code": "AP",
    "cab": "694604",
    "cap": "63045"
  },
  {
    "key": "5925",
    "ITA": "FORCHIA",
    "state_code": "BN",
    "cab": "950303",
    "cap": "82011"
  },
  {
    "key": "5926",
    "ITA": "FORCOLA",
    "state_code": "SO",
    "cab": "927087",
    "cap": "23010"
  },
  {
    "key": "5927",
    "ITA": "FORCOLA-SIRTA",
    "state_code": "SO",
    "cab": "927087",
    "cap": "23010"
  },
  {
    "key": "5928",
    "ITA": "FORDONGIANUS",
    "state_code": "OR",
    "cab": "878207",
    "cap": "9083"
  },
  {
    "key": "5929",
    "ITA": "FORENZA",
    "state_code": "PZ",
    "cab": "419804",
    "cap": "85023"
  },
  {
    "key": "5930",
    "ITA": "FORESTO SPARSO",
    "state_code": "BG",
    "cab": "734004",
    "cap": "24060"
  },
  {
    "key": "5931",
    "ITA": "FORGARIA NEL FRIULI",
    "state_code": "UD",
    "cab": "644005",
    "cap": "33030"
  },
  {
    "key": "5932",
    "ITA": "FORGARIA NEL FRIULI-CORNINO",
    "state_code": "UD",
    "cab": "644005",
    "cap": "33030"
  },
  {
    "key": "5933",
    "ITA": "FORGARIA NEL FRIULI-FLAGOGNA",
    "state_code": "UD",
    "cab": "644005",
    "cap": "33030"
  },
  {
    "key": "5934",
    "ITA": "FORINO-PETRURO",
    "state_code": "AV",
    "cab": "759902",
    "cap": "83020"
  },
  {
    "key": "5935",
    "ITA": "FORINO",
    "state_code": "AV",
    "cab": "759902",
    "cap": "83020"
  },
  {
    "key": "5936",
    "ITA": "FORINO-CELZI",
    "state_code": "AV",
    "cab": "759902",
    "cap": "83020"
  },
  {
    "key": "5937",
    "ITA": "FORIO",
    "state_code": "NA",
    "cab": "398800",
    "cap": "80075"
  },
  {
    "key": "5938",
    "ITA": "FORIO-MONTERONE",
    "state_code": "NA",
    "cab": "398800",
    "cap": "80075"
  },
  {
    "key": "5939",
    "ITA": "FORIO-PANZA",
    "state_code": "NA",
    "cab": "398800",
    "cap": "80075"
  },
  {
    "key": "5940",
    "ITA": "FORLI'-VILLANOVA",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5941",
    "ITA": "FORLI'",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5942",
    "ITA": "FORLI'-BARISANO",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5943",
    "ITA": "FORLI'-CARPENA",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5944",
    "ITA": "FORLI'-CARPINELLO",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5945",
    "ITA": "FORLI'-CAVA",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5946",
    "ITA": "FORLI'-PIANTA",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5947",
    "ITA": "FORLI'-RONCADELLO",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5948",
    "ITA": "FORLI'-RONCO",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5949",
    "ITA": "FORLI'-ROVERE",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5950",
    "ITA": "FORLI'-SAN LORENZO IN NOCETO",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5951",
    "ITA": "FORLI'-SAN MARTINO IN STRADA",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5952",
    "ITA": "FORLI'-SAN VARANO",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5953",
    "ITA": "FORLI'-VECCHIAZZANO",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5954",
    "ITA": "FORLI'-VILLAFRANCA",
    "state_code": "FC",
    "cab": "132001",
    "cap": "47100"
  },
  {
    "key": "5955",
    "ITA": "FORLI' DEL SANNIO",
    "state_code": "IS",
    "cab": "781104",
    "cap": "86084"
  },
  {
    "key": "5956",
    "ITA": "FORLI' DEL SANNIO-VANDRA",
    "state_code": "IS",
    "cab": "781104",
    "cap": "86084"
  },
  {
    "key": "5957",
    "ITA": "FORLIMPOPOLI-SELBAGNONE",
    "state_code": "FC",
    "cab": "677906",
    "cap": "47034"
  },
  {
    "key": "5958",
    "ITA": "FORLIMPOPOLI",
    "state_code": "FC",
    "cab": "677906",
    "cap": "47034"
  },
  {
    "key": "5959",
    "ITA": "FORMAZZA",
    "state_code": "VB",
    "cab": "917278",
    "cap": "28863"
  },
  {
    "key": "5960",
    "ITA": "FORMELLO",
    "state_code": "RM",
    "cab": "390906",
    "cap": "60"
  },
  {
    "key": "5961",
    "ITA": "FORMELLO-LE RUGHE",
    "state_code": "RM",
    "cab": "390906",
    "cap": "60"
  },
  {
    "key": "5962",
    "ITA": "FORMIA-MARANOLA",
    "state_code": "LT",
    "cab": "739805",
    "cap": "4023"
  },
  {
    "key": "5963",
    "ITA": "FORMIA-PENITRO",
    "state_code": "LT",
    "cab": "739805",
    "cap": "4023"
  },
  {
    "key": "5964",
    "ITA": "FORMIA-TRIVIO DI FORMIA",
    "state_code": "LT",
    "cab": "739805",
    "cap": "4023"
  },
  {
    "key": "5965",
    "ITA": "FORMIA-CASTELLONORATO",
    "state_code": "LT",
    "cab": "739805",
    "cap": "4023"
  },
  {
    "key": "5966",
    "ITA": "FORMIA-VINDICIO DI FORMIA",
    "state_code": "LT",
    "cab": "739805",
    "cap": "4023"
  },
  {
    "key": "5967",
    "ITA": "FORMIA",
    "state_code": "LT",
    "cab": "739805",
    "cap": "4023"
  },
  {
    "key": "5968",
    "ITA": "FORMIA-ACQUATRAVERSA DI FORMIA",
    "state_code": "LT",
    "cab": "739805",
    "cap": "4023"
  },
  {
    "key": "5969",
    "ITA": "FORMICOLA",
    "state_code": "CE",
    "cab": "949313",
    "cap": "81040"
  },
  {
    "key": "5970",
    "ITA": "FORMIGARA",
    "state_code": "CR",
    "cab": "931675",
    "cap": "26020"
  },
  {
    "key": "5971",
    "ITA": "FORMIGINE-MAGRETA",
    "state_code": "MO",
    "cab": "667808",
    "cap": "41043"
  },
  {
    "key": "5972",
    "ITA": "FORMIGINE-CORLO DI FORMIGINE",
    "state_code": "MO",
    "cab": "667808",
    "cap": "41043"
  },
  {
    "key": "5973",
    "ITA": "FORMIGINE-COLOMBARO",
    "state_code": "MO",
    "cab": "667808",
    "cap": "41043"
  },
  {
    "key": "5974",
    "ITA": "FORMIGINE",
    "state_code": "MO",
    "cab": "667808",
    "cap": "41043"
  },
  {
    "key": "5975",
    "ITA": "FORMIGINE-CASINALBO",
    "state_code": "MO",
    "cab": "667808",
    "cap": "41043"
  },
  {
    "key": "5976",
    "ITA": "FORMIGLIANA",
    "state_code": "VC",
    "cab": "915918",
    "cap": "13030"
  },
  {
    "key": "5977",
    "ITA": "FORMIGNANA-BRAZZOLO",
    "state_code": "FE",
    "cab": "672204",
    "cap": "44035"
  },
  {
    "key": "5978",
    "ITA": "FORMIGNANA",
    "state_code": "FE",
    "cab": "672204",
    "cap": "44035"
  },
  {
    "key": "5979",
    "ITA": "FORNACE",
    "state_code": "TN",
    "cab": "348409",
    "cap": "38040"
  },
  {
    "key": "5980",
    "ITA": "FORNELLI",
    "state_code": "IS",
    "cab": "186593",
    "cap": "86070"
  },
  {
    "key": "5981",
    "ITA": "FORNI AVOLTRI",
    "state_code": "UD",
    "cab": "638502",
    "cap": "33020"
  },
  {
    "key": "5982",
    "ITA": "FORNI DI SOPRA",
    "state_code": "UD",
    "cab": "638601",
    "cap": "33024"
  },
  {
    "key": "5983",
    "ITA": "FORNI DI SOTTO",
    "state_code": "UD",
    "cab": "638700",
    "cap": "33020"
  },
  {
    "key": "5984",
    "ITA": "FORNO CANAVESE",
    "state_code": "TO",
    "cab": "305003",
    "cap": "10084"
  },
  {
    "key": "5985",
    "ITA": "FORNO DI ZOLDO-DOZZA DI ZOLDO",
    "state_code": "BL",
    "cab": "611301",
    "cap": "32012"
  },
  {
    "key": "5986",
    "ITA": "FORNO DI ZOLDO-DONT",
    "state_code": "BL",
    "cab": "611301",
    "cap": "32012"
  },
  {
    "key": "5987",
    "ITA": "FORNO DI ZOLDO",
    "state_code": "BL",
    "cab": "611301",
    "cap": "32012"
  },
  {
    "key": "5988",
    "ITA": "FORNOVO DI TARO-RICCO'",
    "state_code": "PR",
    "cab": "657601",
    "cap": "43045"
  },
  {
    "key": "5989",
    "ITA": "FORNOVO DI TARO",
    "state_code": "PR",
    "cab": "657601",
    "cap": "43045"
  },
  {
    "key": "5990",
    "ITA": "FORNOVO DI TARO-NEVIANO DE' ROSSI",
    "state_code": "PR",
    "cab": "657601",
    "cap": "43045"
  },
  {
    "key": "5991",
    "ITA": "FORNOVO DI TARO-PIANTONIA",
    "state_code": "PR",
    "cab": "657601",
    "cap": "43045"
  },
  {
    "key": "5992",
    "ITA": "FORNOVO DI TARO-SIVIZZANO",
    "state_code": "PR",
    "cab": "657601",
    "cap": "43045"
  },
  {
    "key": "5993",
    "ITA": "FORNOVO SAN GIOVANNI",
    "state_code": "BG",
    "cab": "530402",
    "cap": "24040"
  },
  {
    "key": "5994",
    "ITA": "FORTE DEI MARMI",
    "state_code": "LU",
    "cab": "701508",
    "cap": "55042"
  },
  {
    "key": "5995",
    "ITA": "FORTEZZA",
    "state_code": "BZ",
    "cab": "584003",
    "cap": "39045"
  },
  {
    "key": "5996",
    "ITA": "FORTUNAGO",
    "state_code": "PV",
    "cab": "930487",
    "cap": "27040"
  },
  {
    "key": "5997",
    "ITA": "FORZA D'AGRO'",
    "state_code": "ME",
    "cab": "961326",
    "cap": "98030"
  },
  {
    "key": "5998",
    "ITA": "FOSCIANDORA",
    "state_code": "LU",
    "cab": "943431",
    "cap": "55020"
  },
  {
    "key": "5999",
    "ITA": "FOSDINOVO-MELARA",
    "state_code": "MS",
    "cab": "699207",
    "cap": "54035"
  },
  {
    "key": "6000",
    "ITA": "FOSDINOVO-TENDOLA",
    "state_code": "MS",
    "cab": "699207",
    "cap": "54035"
  },
  {
    "key": "6001",
    "ITA": "FOSDINOVO-CANIPAROLA",
    "state_code": "MS",
    "cab": "699207",
    "cap": "54035"
  },
  {
    "key": "6002",
    "ITA": "FOSDINOVO-BORGHETTO",
    "state_code": "MS",
    "cab": "699207",
    "cap": "54035"
  },
  {
    "key": "6003",
    "ITA": "FOSDINOVO",
    "state_code": "MS",
    "cab": "699207",
    "cap": "54035"
  },
  {
    "key": "6004",
    "ITA": "FOSDINOVO-MARCIASO",
    "state_code": "MS",
    "cab": "699207",
    "cap": "54035"
  },
  {
    "key": "6005",
    "ITA": "FOSSA",
    "state_code": "AQ",
    "cab": "909689",
    "cap": "67020"
  },
  {
    "key": "6006",
    "ITA": "FOSSACESIA-FOSSACESIA MARINA",
    "state_code": "CH",
    "cab": "776807",
    "cap": "66022"
  },
  {
    "key": "6007",
    "ITA": "FOSSACESIA-SCORCIOSA",
    "state_code": "CH",
    "cab": "776807",
    "cap": "66022"
  },
  {
    "key": "6008",
    "ITA": "FOSSACESIA",
    "state_code": "CH",
    "cab": "776807",
    "cap": "66022"
  },
  {
    "key": "6009",
    "ITA": "FOSSALTA DI PIAVE",
    "state_code": "VE",
    "cab": "361105",
    "cap": "30020"
  },
  {
    "key": "6010",
    "ITA": "FOSSALTA DI PORTOGRUARO-VILLANOVA SANTA MARGHERITA",
    "state_code": "VE",
    "cab": "361204",
    "cap": "30025"
  },
  {
    "key": "6011",
    "ITA": "FOSSALTA DI PORTOGRUARO",
    "state_code": "VE",
    "cab": "361204",
    "cap": "30025"
  },
  {
    "key": "6012",
    "ITA": "FOSSALTA DI PORTOGRUARO-FRATTA",
    "state_code": "VE",
    "cab": "361204",
    "cap": "30025"
  },
  {
    "key": "6013",
    "ITA": "FOSSALTO",
    "state_code": "CB",
    "cab": "910562",
    "cap": "86020"
  },
  {
    "key": "6014",
    "ITA": "FOSSANO-TAGLIATA",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6015",
    "ITA": "FOSSANO-PIOVANI",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6016",
    "ITA": "FOSSANO-SAN SEBASTIANO",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6017",
    "ITA": "FOSSANO-SAN VITTORE",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6018",
    "ITA": "FOSSANO-MURAZZO",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6019",
    "ITA": "FOSSANO-MADDALENE",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6020",
    "ITA": "FOSSANO-GERBO",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6021",
    "ITA": "FOSSANO",
    "state_code": "CN",
    "cab": "463208",
    "cap": "12045"
  },
  {
    "key": "6022",
    "ITA": "FOSSATO DI VICO",
    "state_code": "PG",
    "cab": "384305",
    "cap": "6022"
  },
  {
    "key": "6023",
    "ITA": "FOSSATO DI VICO-FOSSATO DI VICO STAZIONE",
    "state_code": "PG",
    "cab": "384305",
    "cap": "6022"
  },
  {
    "key": "6024",
    "ITA": "FOSSATO DI VICO-OSTERIA DEL GATTO",
    "state_code": "PG",
    "cab": "384305",
    "cap": "6022"
  },
  {
    "key": "6025",
    "ITA": "FOSSATO DI VICO-PURELLO",
    "state_code": "PG",
    "cab": "384305",
    "cap": "6022"
  },
  {
    "key": "6026",
    "ITA": "FOSSATO SERRALTA",
    "state_code": "CZ",
    "cab": "912956",
    "cap": "88050"
  },
  {
    "key": "6027",
    "ITA": "FOSSO'",
    "state_code": "VE",
    "cab": "361303",
    "cap": "30030"
  },
  {
    "key": "6028",
    "ITA": "FOSSO'-SANDON",
    "state_code": "VE",
    "cab": "361303",
    "cap": "30030"
  },
  {
    "key": "6029",
    "ITA": "FOSSOMBRONE-ISOLA DI FANO",
    "state_code": "PU",
    "cab": "682906",
    "cap": "61034"
  },
  {
    "key": "6030",
    "ITA": "FOSSOMBRONE-CALMAZZO",
    "state_code": "PU",
    "cab": "682906",
    "cap": "61034"
  },
  {
    "key": "6031",
    "ITA": "FOSSOMBRONE",
    "state_code": "PU",
    "cab": "682906",
    "cap": "61034"
  },
  {
    "key": "6032",
    "ITA": "FOZA",
    "state_code": "VI",
    "cab": "738203",
    "cap": "36010"
  },
  {
    "key": "6033",
    "ITA": "FRABOSA SOPRANA-FONTANE",
    "state_code": "CN",
    "cab": "463307",
    "cap": "12082"
  },
  {
    "key": "6034",
    "ITA": "FRABOSA SOPRANA-CORSAGLIA",
    "state_code": "CN",
    "cab": "463307",
    "cap": "12082"
  },
  {
    "key": "6035",
    "ITA": "FRABOSA SOPRANA-BOSSEA",
    "state_code": "CN",
    "cab": "463307",
    "cap": "12082"
  },
  {
    "key": "6036",
    "ITA": "FRABOSA SOPRANA",
    "state_code": "CN",
    "cab": "463307",
    "cap": "12082"
  },
  {
    "key": "6037",
    "ITA": "FRABOSA SOTTANA-GOSI",
    "state_code": "CN",
    "cab": "463406",
    "cap": "12083"
  },
  {
    "key": "6038",
    "ITA": "FRABOSA SOTTANA",
    "state_code": "CN",
    "cab": "463406",
    "cap": "12083"
  },
  {
    "key": "6039",
    "ITA": "FRACONALTO",
    "state_code": "AL",
    "cab": "921163",
    "cap": "15060"
  },
  {
    "key": "6040",
    "ITA": "FRACONALTO-CASTAGNOLA",
    "state_code": "AL",
    "cab": "921163",
    "cap": "15060"
  },
  {
    "key": "6041",
    "ITA": "FRAGAGNANO",
    "state_code": "TA",
    "cab": "788703",
    "cap": "74022"
  },
  {
    "key": "6042",
    "ITA": "FRAGNETO L'ABATE",
    "state_code": "BN",
    "cab": "950311",
    "cap": "82020"
  },
  {
    "key": "6043",
    "ITA": "FRAGNETO MONFORTE",
    "state_code": "BN",
    "cab": "753509",
    "cap": "82020"
  },
  {
    "key": "6044",
    "ITA": "FRAINE",
    "state_code": "CH",
    "cab": "954685",
    "cap": "66050"
  },
  {
    "key": "6045",
    "ITA": "FRAMURA",
    "state_code": "SP",
    "cab": "923730",
    "cap": "19014"
  },
  {
    "key": "6046",
    "ITA": "FRANCAVILLA AL MARE",
    "state_code": "CH",
    "cab": "776906",
    "cap": "66023"
  },
  {
    "key": "6047",
    "ITA": "FRANCAVILLA AL MARE-FRANCAVILLA AL MARE RIONE FORO",
    "state_code": "CH",
    "cab": "776906",
    "cap": "66023"
  },
  {
    "key": "6048",
    "ITA": "FRANCAVILLA ANGITOLA",
    "state_code": "VV",
    "cab": "912964",
    "cap": "89815"
  },
  {
    "key": "6049",
    "ITA": "FRANCAVILLA BISIO",
    "state_code": "AL",
    "cab": "921171",
    "cap": "15060"
  },
  {
    "key": "6050",
    "ITA": "FRANCAVILLA D'ETE",
    "state_code": "FM",
    "cab": "635409",
    "cap": "63010"
  },
  {
    "key": "6051",
    "ITA": "FRANCAVILLA DI SICILIA",
    "state_code": "ME",
    "cab": "821801",
    "cap": "98034"
  },
  {
    "key": "6052",
    "ITA": "FRANCAVILLA FONTANA-CAPECE",
    "state_code": "BR",
    "cab": "791905",
    "cap": "72021"
  },
  {
    "key": "6053",
    "ITA": "FRANCAVILLA FONTANA",
    "state_code": "BR",
    "cab": "791905",
    "cap": "72021"
  },
  {
    "key": "6054",
    "ITA": "FRANCAVILLA IN SINNI",
    "state_code": "PZ",
    "cab": "419903",
    "cap": "85034"
  },
  {
    "key": "6055",
    "ITA": "FRANCAVILLA MARITTIMA",
    "state_code": "CS",
    "cab": "581207",
    "cap": "87072"
  },
  {
    "key": "6056",
    "ITA": "FRANCICA",
    "state_code": "VV",
    "cab": "912972",
    "cap": "89851"
  },
  {
    "key": "6057",
    "ITA": "FRANCOFONTE",
    "state_code": "SR",
    "cab": "847103",
    "cap": "96015"
  },
  {
    "key": "6058",
    "ITA": "FRANCOLISE-SANT'ANDREA DEL PIZZONE",
    "state_code": "CE",
    "cab": "893404",
    "cap": "81050"
  },
  {
    "key": "6059",
    "ITA": "FRANCOLISE",
    "state_code": "CE",
    "cab": "893404",
    "cap": "81050"
  },
  {
    "key": "6060",
    "ITA": "FRANCOLISE-CIAMPRISCO",
    "state_code": "CE",
    "cab": "893404",
    "cap": "81050"
  },
  {
    "key": "6061",
    "ITA": "FRANCOLISE-MONTANARO",
    "state_code": "CE",
    "cab": "893404",
    "cap": "81050"
  },
  {
    "key": "6062",
    "ITA": "FRASCARO",
    "state_code": "AL",
    "cab": "921189",
    "cap": "15010"
  },
  {
    "key": "6063",
    "ITA": "FRASCAROLO",
    "state_code": "PV",
    "cab": "558601",
    "cap": "27030"
  },
  {
    "key": "6064",
    "ITA": "FRASCATI",
    "state_code": "RM",
    "cab": "391003",
    "cap": "44"
  },
  {
    "key": "6065",
    "ITA": "FRASCATI-VERMICINO",
    "state_code": "RM",
    "cab": "391003",
    "cap": "44"
  },
  {
    "key": "6066",
    "ITA": "FRASCINETO-EIANINA",
    "state_code": "CS",
    "cab": "708503",
    "cap": "87010"
  },
  {
    "key": "6067",
    "ITA": "FRASCINETO",
    "state_code": "CS",
    "cab": "708503",
    "cap": "87010"
  },
  {
    "key": "6068",
    "ITA": "FRASSILONGO",
    "state_code": "TN",
    "cab": "904888",
    "cap": "38050"
  },
  {
    "key": "6069",
    "ITA": "FRASSINELLE POLESINE",
    "state_code": "RO",
    "cab": "793307",
    "cap": "45030"
  },
  {
    "key": "6070",
    "ITA": "FRASSINELLE POLESINE-CHIESA",
    "state_code": "RO",
    "cab": "793307",
    "cap": "45030"
  },
  {
    "key": "6071",
    "ITA": "FRASSINELLO MONFERRATO",
    "state_code": "AL",
    "cab": "482406",
    "cap": "15035"
  },
  {
    "key": "6072",
    "ITA": "FRASSINETO PO",
    "state_code": "AL",
    "cab": "482505",
    "cap": "15040"
  },
  {
    "key": "6073",
    "ITA": "FRASSINETTO",
    "state_code": "TO",
    "cab": "190330",
    "cap": "10080"
  },
  {
    "key": "6074",
    "ITA": "FRASSINO",
    "state_code": "CN",
    "cab": "918680",
    "cap": "12020"
  },
  {
    "key": "6075",
    "ITA": "FRASSINORO-ROVOLO",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6076",
    "ITA": "FRASSINORO-ROMANORO",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6077",
    "ITA": "FRASSINORO-RICCOVOLTO",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6078",
    "ITA": "FRASSINORO-PIANDELAGOTTI",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6079",
    "ITA": "FRASSINORO-SASSATELLA",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6080",
    "ITA": "FRASSINORO-FONTANALUCCIA",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6081",
    "ITA": "FRASSINORO-CARGEDOLO",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6082",
    "ITA": "FRASSINORO",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6083",
    "ITA": "FRASSINORO-MADONNA DI PIETRAVOLTA",
    "state_code": "MO",
    "cab": "667907",
    "cap": "41044"
  },
  {
    "key": "6084",
    "ITA": "FRASSO SABINO",
    "state_code": "RI",
    "cab": "371500",
    "cap": "2030"
  },
  {
    "key": "6085",
    "ITA": "FRASSO TELESINO",
    "state_code": "BN",
    "cab": "755306",
    "cap": "82030"
  },
  {
    "key": "6086",
    "ITA": "FRATTA POLESINE",
    "state_code": "RO",
    "cab": "633107",
    "cap": "45025"
  },
  {
    "key": "6087",
    "ITA": "FRATTA TODINA",
    "state_code": "PG",
    "cab": "384404",
    "cap": "6054"
  },
  {
    "key": "6088",
    "ITA": "FRATTAMAGGIORE",
    "state_code": "NA",
    "cab": "398909",
    "cap": "80027"
  },
  {
    "key": "6089",
    "ITA": "FRATTAMINORE",
    "state_code": "NA",
    "cab": "403501",
    "cap": "80020"
  },
  {
    "key": "6090",
    "ITA": "FRATTE ROSA",
    "state_code": "PU",
    "cab": "683003",
    "cap": "61040"
  },
  {
    "key": "6091",
    "ITA": "FRAZZANO'",
    "state_code": "ME",
    "cab": "371401",
    "cap": "98070"
  },
  {
    "key": "6092",
    "ITA": "FREGONA",
    "state_code": "TV",
    "cab": "478107",
    "cap": "31010"
  },
  {
    "key": "6093",
    "ITA": "FREGONA-OSIGO",
    "state_code": "TV",
    "cab": "478107",
    "cap": "31010"
  },
  {
    "key": "6094",
    "ITA": "FRESAGRANDINARIA",
    "state_code": "CH",
    "cab": "954693",
    "cap": "66050"
  },
  {
    "key": "6095",
    "ITA": "FRESONARA",
    "state_code": "AL",
    "cab": "482604",
    "cap": "15064"
  },
  {
    "key": "6096",
    "ITA": "FRIGENTO-PAGLIARA",
    "state_code": "AV",
    "cab": "895607",
    "cap": "83040"
  },
  {
    "key": "6097",
    "ITA": "FRIGENTO-PILA AI PIANI",
    "state_code": "AV",
    "cab": "895607",
    "cap": "83040"
  },
  {
    "key": "6098",
    "ITA": "FRIGENTO",
    "state_code": "AV",
    "cab": "895607",
    "cap": "83040"
  },
  {
    "key": "6099",
    "ITA": "FRIGNANO",
    "state_code": "CE",
    "cab": "748806",
    "cap": "81030"
  },
  {
    "key": "6100",
    "ITA": "FRINCO",
    "state_code": "AT",
    "cab": "474304",
    "cap": "14030"
  },
  {
    "key": "6101",
    "ITA": "FRISA-GUASTAMEROLI",
    "state_code": "CH",
    "cab": "777003",
    "cap": "66030"
  },
  {
    "key": "6102",
    "ITA": "FRISA",
    "state_code": "CH",
    "cab": "777003",
    "cap": "66030"
  },
  {
    "key": "6103",
    "ITA": "FRISANCO",
    "state_code": "PN",
    "cab": "938209",
    "cap": "33080"
  },
  {
    "key": "6104",
    "ITA": "FRISANCO-POFFABRO",
    "state_code": "PN",
    "cab": "938209",
    "cap": "33080"
  },
  {
    "key": "6105",
    "ITA": "FRONT",
    "state_code": "TO",
    "cab": "283572",
    "cap": "10070"
  },
  {
    "key": "6106",
    "ITA": "FRONTINO",
    "state_code": "PU",
    "cab": "278333",
    "cap": "61021"
  },
  {
    "key": "6107",
    "ITA": "FRONTONE",
    "state_code": "PU",
    "cab": "683102",
    "cap": "61040"
  },
  {
    "key": "6108",
    "ITA": "FROSINONE-MADONNA DELLA NEVE",
    "state_code": "FR",
    "cab": "148007",
    "cap": "3100"
  },
  {
    "key": "6109",
    "ITA": "FROSINONE-DE MATTEIS",
    "state_code": "FR",
    "cab": "148007",
    "cap": "3100"
  },
  {
    "key": "6110",
    "ITA": "FROSINONE",
    "state_code": "FR",
    "cab": "148007",
    "cap": "3100"
  },
  {
    "key": "6111",
    "ITA": "FROSOLONE",
    "state_code": "IS",
    "cab": "781203",
    "cap": "86095"
  },
  {
    "key": "6112",
    "ITA": "FROSOLONE-SAN PIETRO IN VALLE",
    "state_code": "IS",
    "cab": "781203",
    "cap": "86095"
  },
  {
    "key": "6113",
    "ITA": "FROSSASCO",
    "state_code": "TO",
    "cab": "314005",
    "cap": "10060"
  },
  {
    "key": "6114",
    "ITA": "FRUGAROLO",
    "state_code": "AL",
    "cab": "482703",
    "cap": "15065"
  },
  {
    "key": "6115",
    "ITA": "FUBINE",
    "state_code": "AL",
    "cab": "482802",
    "cap": "15043"
  },
  {
    "key": "6116",
    "ITA": "FUCECCHIO",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6117",
    "ITA": "FUCECCHIO-GALLENO",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6118",
    "ITA": "FUCECCHIO-LE BOTTEGHE",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6119",
    "ITA": "FUCECCHIO-MASSARELLA",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6120",
    "ITA": "FUCECCHIO-PONTE A CAPPIANO",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6121",
    "ITA": "FUCECCHIO-QUERCE",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6122",
    "ITA": "FUCECCHIO-SAN PIERINO",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6123",
    "ITA": "FUCECCHIO-TORRE",
    "state_code": "FI",
    "cab": "378703",
    "cap": "50054"
  },
  {
    "key": "6124",
    "ITA": "FUIPIANO VALLE IMAGNA",
    "state_code": "BG",
    "cab": "928168",
    "cap": "24030"
  },
  {
    "key": "6125",
    "ITA": "FUMANE-CAVALO",
    "state_code": "VR",
    "cab": "892307",
    "cap": "37022"
  },
  {
    "key": "6126",
    "ITA": "FUMANE",
    "state_code": "VR",
    "cab": "892307",
    "cap": "37022"
  },
  {
    "key": "6127",
    "ITA": "FUMANE-BREONIO",
    "state_code": "VR",
    "cab": "892307",
    "cap": "37022"
  },
  {
    "key": "6128",
    "ITA": "FUMONE-MADONNA DELLE GRAZIE",
    "state_code": "FR",
    "cab": "896605",
    "cap": "3010"
  },
  {
    "key": "6129",
    "ITA": "FUMONE",
    "state_code": "FR",
    "cab": "896605",
    "cap": "3010"
  },
  {
    "key": "6130",
    "ITA": "FUNES",
    "state_code": "BZ",
    "cab": "584102",
    "cap": "39040"
  },
  {
    "key": "6131",
    "ITA": "FUNES-SAN PIETRO",
    "state_code": "BZ",
    "cab": "584102",
    "cap": "39040"
  },
  {
    "key": "6132",
    "ITA": "FURCI",
    "state_code": "CH",
    "cab": "954701",
    "cap": "66050"
  },
  {
    "key": "6133",
    "ITA": "FURCI SICULO",
    "state_code": "ME",
    "cab": "821900",
    "cap": "98023"
  },
  {
    "key": "6134",
    "ITA": "FURNARI-TONNARELLA",
    "state_code": "ME",
    "cab": "822007",
    "cap": "98054"
  },
  {
    "key": "6135",
    "ITA": "FURNARI",
    "state_code": "ME",
    "cab": "822007",
    "cap": "98054"
  },
  {
    "key": "6136",
    "ITA": "FURORE",
    "state_code": "SA",
    "cab": "952523",
    "cap": "84010"
  },
  {
    "key": "6137",
    "ITA": "FURORE-SAN MICHELE",
    "state_code": "SA",
    "cab": "952523",
    "cap": "84010"
  },
  {
    "key": "6138",
    "ITA": "FURTEI",
    "state_code": "SU",
    "cab": "859207",
    "cap": "9040"
  },
  {
    "key": "6139",
    "ITA": "FUSCALDO-MARINA DI FUSCALDO",
    "state_code": "CS",
    "cab": "807404",
    "cap": "87024"
  },
  {
    "key": "6140",
    "ITA": "FUSCALDO-CARIGLIO",
    "state_code": "CS",
    "cab": "807404",
    "cap": "87024"
  },
  {
    "key": "6141",
    "ITA": "FUSCALDO",
    "state_code": "CS",
    "cab": "807404",
    "cap": "87024"
  },
  {
    "key": "6142",
    "ITA": "FUSCALDO-SCARCELLI",
    "state_code": "CS",
    "cab": "807404",
    "cap": "87024"
  },
  {
    "key": "6143",
    "ITA": "FUSIGNANO",
    "state_code": "RA",
    "cab": "675603",
    "cap": "48010"
  },
  {
    "key": "6144",
    "ITA": "FUSINE",
    "state_code": "SO",
    "cab": "927095",
    "cap": "23010"
  },
  {
    "key": "6145",
    "ITA": "FUTANI",
    "state_code": "SA",
    "cab": "952531",
    "cap": "84050"
  },
  {
    "key": "6146",
    "ITA": "FUTANI-SORVARO",
    "state_code": "SA",
    "cab": "952531",
    "cap": "84050"
  },
  {
    "key": "6147",
    "ITA": "GABBIONETA BINANUOVA-GABBIONETA",
    "state_code": "CR",
    "cab": "931683",
    "cap": "26030"
  },
  {
    "key": "6148",
    "ITA": "GABBIONETA BINANUOVA-BINANUOVA",
    "state_code": "CR",
    "cab": "931683",
    "cap": "26030"
  },
  {
    "key": "6149",
    "ITA": "GABBIONETA BINANUOVA",
    "state_code": "CR",
    "cab": "931683",
    "cap": "26030"
  },
  {
    "key": "6150",
    "ITA": "GABIANO-VARENGO",
    "state_code": "AL",
    "cab": "482901",
    "cap": "15020"
  },
  {
    "key": "6151",
    "ITA": "GABIANO-CANTAVENNA",
    "state_code": "AL",
    "cab": "482901",
    "cap": "15020"
  },
  {
    "key": "6152",
    "ITA": "GABIANO",
    "state_code": "AL",
    "cab": "482901",
    "cap": "15020"
  },
  {
    "key": "6153",
    "ITA": "GABICCE MARE-CASE BADIOLI",
    "state_code": "PU",
    "cab": "683201",
    "cap": "61011"
  },
  {
    "key": "6154",
    "ITA": "GABICCE MARE",
    "state_code": "PU",
    "cab": "683201",
    "cap": "61011"
  },
  {
    "key": "6155",
    "ITA": "GABY",
    "state_code": "AO",
    "cab": "294041",
    "cap": "11020"
  },
  {
    "key": "6156",
    "ITA": "GADESCO PIEVE DELMONA-CA' DE' MARI",
    "state_code": "CR",
    "cab": "573204",
    "cap": "26030"
  },
  {
    "key": "6157",
    "ITA": "GADESCO PIEVE DELMONA-ARDOLE SAN MARINO",
    "state_code": "CR",
    "cab": "573204",
    "cap": "26030"
  },
  {
    "key": "6158",
    "ITA": "GADESCO PIEVE DELMONA",
    "state_code": "CR",
    "cab": "573204",
    "cap": "26030"
  },
  {
    "key": "6159",
    "ITA": "GADONI",
    "state_code": "NU",
    "cab": "865808",
    "cap": "8030"
  },
  {
    "key": "6160",
    "ITA": "GAETA",
    "state_code": "LT",
    "cab": "739904",
    "cap": "4024"
  },
  {
    "key": "6161",
    "ITA": "GAGGI",
    "state_code": "ME",
    "cab": "827709",
    "cap": "98030"
  },
  {
    "key": "6162",
    "ITA": "GAGGIANO-SAN VITO",
    "state_code": "MI",
    "cab": "331207",
    "cap": "20083"
  },
  {
    "key": "6163",
    "ITA": "GAGGIANO-VIGANO",
    "state_code": "MI",
    "cab": "331207",
    "cap": "20083"
  },
  {
    "key": "6164",
    "ITA": "GAGGIANO",
    "state_code": "MI",
    "cab": "331207",
    "cap": "20083"
  },
  {
    "key": "6165",
    "ITA": "GAGGIO MONTANO-SILLA",
    "state_code": "BO",
    "cab": "368209",
    "cap": "40041"
  },
  {
    "key": "6166",
    "ITA": "GAGGIO MONTANO-BOMBIANA",
    "state_code": "BO",
    "cab": "368209",
    "cap": "40041"
  },
  {
    "key": "6167",
    "ITA": "GAGGIO MONTANO",
    "state_code": "BO",
    "cab": "368209",
    "cap": "40041"
  },
  {
    "key": "6168",
    "ITA": "GAGGIO MONTANO-SANTA MARIA VILLIANA",
    "state_code": "BO",
    "cab": "368209",
    "cap": "40041"
  },
  {
    "key": "6169",
    "ITA": "GAGGIO MONTANO-MARANO",
    "state_code": "BO",
    "cab": "368209",
    "cap": "40041"
  },
  {
    "key": "6170",
    "ITA": "GAGLIANICO",
    "state_code": "BI",
    "cab": "444901",
    "cap": "13894"
  },
  {
    "key": "6171",
    "ITA": "GAGLIANO ATERNO",
    "state_code": "AQ",
    "cab": "909697",
    "cap": "67020"
  },
  {
    "key": "6172",
    "ITA": "GAGLIANO CASTELFERRATO",
    "state_code": "EN",
    "cab": "836502",
    "cap": "94010"
  },
  {
    "key": "6173",
    "ITA": "GAGLIANO DEL CAPO-SAN DANA",
    "state_code": "LE",
    "cab": "796409",
    "cap": "73034"
  },
  {
    "key": "6174",
    "ITA": "GAGLIANO DEL CAPO",
    "state_code": "LE",
    "cab": "796409",
    "cap": "73034"
  },
  {
    "key": "6175",
    "ITA": "GAGLIANO DEL CAPO-ARIGLIANO",
    "state_code": "LE",
    "cab": "796409",
    "cap": "73034"
  },
  {
    "key": "6176",
    "ITA": "GAGLIATO",
    "state_code": "CZ",
    "cab": "912980",
    "cap": "88060"
  },
  {
    "key": "6177",
    "ITA": "GAGLIOLE",
    "state_code": "MC",
    "cab": "771600",
    "cap": "62022"
  },
  {
    "key": "6178",
    "ITA": "GAIARINE-ALBINA",
    "state_code": "TV",
    "cab": "617100",
    "cap": "31018"
  },
  {
    "key": "6179",
    "ITA": "GAIARINE-FRANCENIGO",
    "state_code": "TV",
    "cab": "617100",
    "cap": "31018"
  },
  {
    "key": "6180",
    "ITA": "GAIARINE-CAMPOMOLINO",
    "state_code": "TV",
    "cab": "617100",
    "cap": "31018"
  },
  {
    "key": "6181",
    "ITA": "GAIARINE",
    "state_code": "TV",
    "cab": "617100",
    "cap": "31018"
  },
  {
    "key": "6182",
    "ITA": "GAIBA",
    "state_code": "RO",
    "cab": "728501",
    "cap": "45030"
  },
  {
    "key": "6183",
    "ITA": "GAIOLA",
    "state_code": "CN",
    "cab": "424408",
    "cap": "12010"
  },
  {
    "key": "6184",
    "ITA": "GAIOLE IN CHIANTI",
    "state_code": "SI",
    "cab": "718700",
    "cap": "53013"
  },
  {
    "key": "6185",
    "ITA": "GAIOLE IN CHIANTI-AMA",
    "state_code": "SI",
    "cab": "718700",
    "cap": "53013"
  },
  {
    "key": "6186",
    "ITA": "GAIOLE IN CHIANTI-NUSENNA",
    "state_code": "SI",
    "cab": "718700",
    "cap": "53013"
  },
  {
    "key": "6187",
    "ITA": "GAIOLE IN CHIANTI-LECCHI",
    "state_code": "SI",
    "cab": "718700",
    "cap": "53013"
  },
  {
    "key": "6188",
    "ITA": "GAIOLE IN CHIANTI-MONTI",
    "state_code": "SI",
    "cab": "718700",
    "cap": "53013"
  },
  {
    "key": "6189",
    "ITA": "GAIOLE IN CHIANTI-CASTAGNOLI",
    "state_code": "SI",
    "cab": "718700",
    "cap": "53013"
  },
  {
    "key": "6190",
    "ITA": "GAIRO-GAIRO SANT'ELENA",
    "state_code": "OG",
    "cab": "865907",
    "cap": "8040"
  },
  {
    "key": "6191",
    "ITA": "GAIRO",
    "state_code": "OG",
    "cab": "865907",
    "cap": "8040"
  },
  {
    "key": "6192",
    "ITA": "GAIRO-TAQUISARA",
    "state_code": "OG",
    "cab": "865907",
    "cap": "8040"
  },
  {
    "key": "6193",
    "ITA": "GAIS",
    "state_code": "BZ",
    "cab": "584201",
    "cap": "39030"
  },
  {
    "key": "6194",
    "ITA": "GAIS-VILLA OTTONE",
    "state_code": "BZ",
    "cab": "584201",
    "cap": "39030"
  },
  {
    "key": "6195",
    "ITA": "GALATI MAMERTINO",
    "state_code": "ME",
    "cab": "822106",
    "cap": "98070"
  },
  {
    "key": "6196",
    "ITA": "GALATI MAMERTINO-SAN BASILIO",
    "state_code": "ME",
    "cab": "822106",
    "cap": "98070"
  },
  {
    "key": "6197",
    "ITA": "GALATINA",
    "state_code": "LE",
    "cab": "796508",
    "cap": "73013"
  },
  {
    "key": "6198",
    "ITA": "GALATINA-NOHA",
    "state_code": "LE",
    "cab": "796508",
    "cap": "73013"
  },
  {
    "key": "6199",
    "ITA": "GALATINA-SANTA BARBARA",
    "state_code": "LE",
    "cab": "796508",
    "cap": "73013"
  },
  {
    "key": "6200",
    "ITA": "GALATINA-COLLEMETO",
    "state_code": "LE",
    "cab": "796508",
    "cap": "73013"
  },
  {
    "key": "6201",
    "ITA": "GALATONE",
    "state_code": "LE",
    "cab": "796607",
    "cap": "73044"
  },
  {
    "key": "6202",
    "ITA": "GALATRO",
    "state_code": "RC",
    "cab": "888800",
    "cap": "89054"
  },
  {
    "key": "6203",
    "ITA": "GALBIATE-VERGANO VILLA",
    "state_code": "LC",
    "cab": "513002",
    "cap": "23851"
  },
  {
    "key": "6204",
    "ITA": "GALBIATE",
    "state_code": "LC",
    "cab": "513002",
    "cap": "23851"
  },
  {
    "key": "6205",
    "ITA": "GALBIATE-SALA AL BARRO",
    "state_code": "LC",
    "cab": "513002",
    "cap": "23851"
  },
  {
    "key": "6206",
    "ITA": "GALEATA-STRADA SAN ZENO",
    "state_code": "FC",
    "cab": "678003",
    "cap": "47010"
  },
  {
    "key": "6207",
    "ITA": "GALEATA",
    "state_code": "FC",
    "cab": "678003",
    "cap": "47010"
  },
  {
    "key": "6208",
    "ITA": "GALGAGNANO",
    "state_code": "LO",
    "cab": "903872",
    "cap": "26832"
  },
  {
    "key": "6209",
    "ITA": "GALLARATE-CEDRATE",
    "state_code": "VA",
    "cab": "502401",
    "cap": "21013"
  },
  {
    "key": "6210",
    "ITA": "GALLARATE",
    "state_code": "VA",
    "cab": "502401",
    "cap": "21013"
  },
  {
    "key": "6211",
    "ITA": "GALLARATE-CRENNA",
    "state_code": "VA",
    "cab": "502401",
    "cap": "21013"
  },
  {
    "key": "6212",
    "ITA": "GALLESE",
    "state_code": "VT",
    "cab": "730903",
    "cap": "1035"
  },
  {
    "key": "6213",
    "ITA": "GALLESE-SCALO TEVERINA",
    "state_code": "VT",
    "cab": "730903",
    "cap": "1035"
  },
  {
    "key": "6214",
    "ITA": "GALLIATE",
    "state_code": "NO",
    "cab": "454009",
    "cap": "28066"
  },
  {
    "key": "6215",
    "ITA": "GALLIATE LOMBARDO",
    "state_code": "VA",
    "cab": "924514",
    "cap": "21020"
  },
  {
    "key": "6216",
    "ITA": "GALLIAVOLA",
    "state_code": "PV",
    "cab": "930495",
    "cap": "27034"
  },
  {
    "key": "6217",
    "ITA": "GALLICANO",
    "state_code": "LU",
    "cab": "701607",
    "cap": "55027"
  },
  {
    "key": "6218",
    "ITA": "GALLICANO-FIATTONE",
    "state_code": "LU",
    "cab": "701607",
    "cap": "55027"
  },
  {
    "key": "6219",
    "ITA": "GALLICANO-TRASSILICO",
    "state_code": "LU",
    "cab": "701607",
    "cap": "55027"
  },
  {
    "key": "6220",
    "ITA": "GALLICANO-TURRITECAVA",
    "state_code": "LU",
    "cab": "701607",
    "cap": "55027"
  },
  {
    "key": "6221",
    "ITA": "GALLICANO NEL LAZIO",
    "state_code": "RM",
    "cab": "391102",
    "cap": "10"
  },
  {
    "key": "6222",
    "ITA": "GALLICCHIO",
    "state_code": "PZ",
    "cab": "911982",
    "cap": "85010"
  },
  {
    "key": "6223",
    "ITA": "GALLIERA-SAN VENANZIO",
    "state_code": "BO",
    "cab": "368308",
    "cap": "40015"
  },
  {
    "key": "6224",
    "ITA": "GALLIERA-GALLIERA FRAZIONE",
    "state_code": "BO",
    "cab": "368308",
    "cap": "40015"
  },
  {
    "key": "6225",
    "ITA": "GALLIERA-BOSCO",
    "state_code": "BO",
    "cab": "368308",
    "cap": "40015"
  },
  {
    "key": "6226",
    "ITA": "GALLIERA",
    "state_code": "BO",
    "cab": "368308",
    "cap": "40015"
  },
  {
    "key": "6227",
    "ITA": "GALLIERA-SAN VINCENZO",
    "state_code": "BO",
    "cab": "368308",
    "cap": "40015"
  },
  {
    "key": "6228",
    "ITA": "GALLIERA VENETA",
    "state_code": "PD",
    "cab": "625806",
    "cap": "35015"
  },
  {
    "key": "6229",
    "ITA": "GALLINARO",
    "state_code": "FR",
    "cab": "288084",
    "cap": "3040"
  },
  {
    "key": "6230",
    "ITA": "GALLIO",
    "state_code": "VI",
    "cab": "603902",
    "cap": "36032"
  },
  {
    "key": "6231",
    "ITA": "GALLIPOLI",
    "state_code": "LE",
    "cab": "796706",
    "cap": "73014"
  },
  {
    "key": "6232",
    "ITA": "GALLO MATESE",
    "state_code": "CE",
    "cab": "949339",
    "cap": "81010"
  },
  {
    "key": "6233",
    "ITA": "GALLO MATESE-VALLELUNGA",
    "state_code": "CE",
    "cab": "949339",
    "cap": "81010"
  },
  {
    "key": "6234",
    "ITA": "GALLODORO",
    "state_code": "ME",
    "cab": "961359",
    "cap": "98030"
  },
  {
    "key": "6235",
    "ITA": "GALLUCCIO",
    "state_code": "CE",
    "cab": "751107",
    "cap": "81044"
  },
  {
    "key": "6236",
    "ITA": "GALLUCCIO-VAGLIE",
    "state_code": "CE",
    "cab": "751107",
    "cap": "81044"
  },
  {
    "key": "6237",
    "ITA": "GALLUCCIO-SIPICCIANO",
    "state_code": "CE",
    "cab": "751107",
    "cap": "81044"
  },
  {
    "key": "6238",
    "ITA": "GALLUCCIO-SAN CLEMENTE",
    "state_code": "CE",
    "cab": "751107",
    "cap": "81044"
  },
  {
    "key": "6239",
    "ITA": "GALTELLI",
    "state_code": "NU",
    "cab": "866004",
    "cap": "8020"
  },
  {
    "key": "6240",
    "ITA": "GALZIGNANO TERME-VALSANZIBIO",
    "state_code": "PD",
    "cab": "857706",
    "cap": "35030"
  },
  {
    "key": "6241",
    "ITA": "GALZIGNANO TERME",
    "state_code": "PD",
    "cab": "857706",
    "cap": "35030"
  },
  {
    "key": "6242",
    "ITA": "GALZIGNANO TERME-GALZIGNANO",
    "state_code": "PD",
    "cab": "857706",
    "cap": "35030"
  },
  {
    "key": "6243",
    "ITA": "GAMALERO",
    "state_code": "AL",
    "cab": "921197",
    "cap": "15010"
  },
  {
    "key": "6244",
    "ITA": "GAMBARA",
    "state_code": "BS",
    "cab": "545202",
    "cap": "25020"
  },
  {
    "key": "6245",
    "ITA": "GAMBARANA",
    "state_code": "PV",
    "cab": "930503",
    "cap": "27030"
  },
  {
    "key": "6246",
    "ITA": "GAMBASCA",
    "state_code": "CN",
    "cab": "918706",
    "cap": "12030"
  },
  {
    "key": "6247",
    "ITA": "GAMBASSI TERME-IL CASTAGNO",
    "state_code": "FI",
    "cab": "378802",
    "cap": "50050"
  },
  {
    "key": "6248",
    "ITA": "GAMBASSI TERME-BADIA A CERRETO",
    "state_code": "FI",
    "cab": "378802",
    "cap": "50050"
  },
  {
    "key": "6249",
    "ITA": "GAMBASSI TERME",
    "state_code": "FI",
    "cab": "378802",
    "cap": "50050"
  },
  {
    "key": "6250",
    "ITA": "GAMBASSI TERME-VARNA",
    "state_code": "FI",
    "cab": "378802",
    "cap": "50050"
  },
  {
    "key": "6251",
    "ITA": "GAMBATESA",
    "state_code": "CB",
    "cab": "410308",
    "cap": "86013"
  },
  {
    "key": "6252",
    "ITA": "GAMBELLARA",
    "state_code": "VI",
    "cab": "604009",
    "cap": "36053"
  },
  {
    "key": "6253",
    "ITA": "GAMBERALE",
    "state_code": "CH",
    "cab": "954719",
    "cap": "66040"
  },
  {
    "key": "6254",
    "ITA": "GAMBETTOLA",
    "state_code": "FC",
    "cab": "678102",
    "cap": "47035"
  },
  {
    "key": "6255",
    "ITA": "GAMBOLO'-GARBANA",
    "state_code": "PV",
    "cab": "558700",
    "cap": "27033"
  },
  {
    "key": "6256",
    "ITA": "GAMBOLO'",
    "state_code": "PV",
    "cab": "558700",
    "cap": "27025"
  },
  {
    "key": "6257",
    "ITA": "GAMBUGLIANO",
    "state_code": "VI",
    "cab": "775007",
    "cap": "36050"
  },
  {
    "key": "6258",
    "ITA": "GANDELLINO",
    "state_code": "BG",
    "cab": "530501",
    "cap": "24020"
  },
  {
    "key": "6259",
    "ITA": "GANDINO",
    "state_code": "BG",
    "cab": "530600",
    "cap": "24024"
  },
  {
    "key": "6260",
    "ITA": "GANDOSSO-TOLARI",
    "state_code": "BG",
    "cab": "928176",
    "cap": "24060"
  },
  {
    "key": "6261",
    "ITA": "GANDOSSO-CELATICA",
    "state_code": "BG",
    "cab": "928176",
    "cap": "24060"
  },
  {
    "key": "6262",
    "ITA": "GANDOSSO",
    "state_code": "BG",
    "cab": "928176",
    "cap": "24060"
  },
  {
    "key": "6263",
    "ITA": "GANGI",
    "state_code": "PA",
    "cab": "433508",
    "cap": "90024"
  },
  {
    "key": "6264",
    "ITA": "GARAGUSO",
    "state_code": "MT",
    "cab": "958140",
    "cap": "75010"
  },
  {
    "key": "6265",
    "ITA": "GARBAGNA",
    "state_code": "AL",
    "cab": "483008",
    "cap": "15050"
  },
  {
    "key": "6266",
    "ITA": "GARBAGNA NOVARESE",
    "state_code": "NO",
    "cab": "917286",
    "cap": "28070"
  },
  {
    "key": "6267",
    "ITA": "GARBAGNATE MILANESE",
    "state_code": "MI",
    "cab": "331306",
    "cap": "20024"
  },
  {
    "key": "6268",
    "ITA": "GARBAGNATE MILANESE-VILLAGGIO GARBAGNATE",
    "state_code": "MI",
    "cab": "331306",
    "cap": "20024"
  },
  {
    "key": "6269",
    "ITA": "GARBAGNATE MONASTERO",
    "state_code": "LC",
    "cab": "892802",
    "cap": "23846"
  },
  {
    "key": "6270",
    "ITA": "GARBAGNATE MONASTERO-BRONGIO",
    "state_code": "LC",
    "cab": "892802",
    "cap": "23846"
  },
  {
    "key": "6271",
    "ITA": "GARDA",
    "state_code": "VR",
    "cab": "594606",
    "cap": "37016"
  },
  {
    "key": "6272",
    "ITA": "GARDONE RIVIERA-MONTECUCCO",
    "state_code": "BS",
    "cab": "545301",
    "cap": "25083"
  },
  {
    "key": "6273",
    "ITA": "GARDONE RIVIERA-SAN MICHELE",
    "state_code": "BS",
    "cab": "545301",
    "cap": "25083"
  },
  {
    "key": "6274",
    "ITA": "GARDONE RIVIERA-FASANO DEL GARDA",
    "state_code": "BS",
    "cab": "545301",
    "cap": "25083"
  },
  {
    "key": "6275",
    "ITA": "GARDONE RIVIERA",
    "state_code": "BS",
    "cab": "545301",
    "cap": "25083"
  },
  {
    "key": "6276",
    "ITA": "GARDONE VAL TROMPIA-MAGNO",
    "state_code": "BS",
    "cab": "545400",
    "cap": "25063"
  },
  {
    "key": "6277",
    "ITA": "GARDONE VAL TROMPIA",
    "state_code": "BS",
    "cab": "545400",
    "cap": "25063"
  },
  {
    "key": "6278",
    "ITA": "GARESSIO-GARESSIO BORGO PONTE",
    "state_code": "CN",
    "cab": "463505",
    "cap": "12075"
  },
  {
    "key": "6279",
    "ITA": "GARESSIO-TRAPPA",
    "state_code": "CN",
    "cab": "463505",
    "cap": "12075"
  },
  {
    "key": "6280",
    "ITA": "GARESSIO-GARESSIO BORGO PIAVE",
    "state_code": "CN",
    "cab": "463505",
    "cap": "12075"
  },
  {
    "key": "6281",
    "ITA": "GARESSIO",
    "state_code": "CN",
    "cab": "463505",
    "cap": "12075"
  },
  {
    "key": "6282",
    "ITA": "GARESSIO-CERISOLA",
    "state_code": "CN",
    "cab": "463505",
    "cap": "12075"
  },
  {
    "key": "6283",
    "ITA": "GARGALLO",
    "state_code": "NO",
    "cab": "364208",
    "cap": "28010"
  },
  {
    "key": "6284",
    "ITA": "GARGAZZONE",
    "state_code": "BZ",
    "cab": "584300",
    "cap": "39010"
  },
  {
    "key": "6285",
    "ITA": "GARGNANO-BOGLIACO",
    "state_code": "BS",
    "cab": "545509",
    "cap": "25084"
  },
  {
    "key": "6286",
    "ITA": "GARGNANO-NAVAZZO",
    "state_code": "BS",
    "cab": "545509",
    "cap": "25084"
  },
  {
    "key": "6287",
    "ITA": "GARGNANO",
    "state_code": "BS",
    "cab": "545509",
    "cap": "25084"
  },
  {
    "key": "6288",
    "ITA": "GARLASCO-MADONNA DELLE BOZZOLE",
    "state_code": "PV",
    "cab": "558809",
    "cap": "27026"
  },
  {
    "key": "6289",
    "ITA": "GARLASCO-SAN BIAGIO",
    "state_code": "PV",
    "cab": "558809",
    "cap": "27026"
  },
  {
    "key": "6290",
    "ITA": "GARLASCO",
    "state_code": "PV",
    "cab": "558809",
    "cap": "27026"
  },
  {
    "key": "6291",
    "ITA": "GARLATE",
    "state_code": "LC",
    "cab": "892901",
    "cap": "23852"
  },
  {
    "key": "6292",
    "ITA": "GARLENDA",
    "state_code": "SV",
    "cab": "923110",
    "cap": "17033"
  },
  {
    "key": "6293",
    "ITA": "GARLENDA-VILLAFRANCA",
    "state_code": "SV",
    "cab": "923110",
    "cap": "17033"
  },
  {
    "key": "6294",
    "ITA": "GARNIGA TERME",
    "state_code": "TN",
    "cab": "348508",
    "cap": "38060"
  },
  {
    "key": "6295",
    "ITA": "GARZENO",
    "state_code": "CO",
    "cab": "565309",
    "cap": "22010"
  },
  {
    "key": "6296",
    "ITA": "GARZIGLIANA",
    "state_code": "TO",
    "cab": "190421",
    "cap": "10060"
  },
  {
    "key": "6297",
    "ITA": "GASPERINA",
    "state_code": "CZ",
    "cab": "912998",
    "cap": "88060"
  },
  {
    "key": "6298",
    "ITA": "GASSINO TORINESE-BUSSOLINO",
    "state_code": "TO",
    "cab": "305102",
    "cap": "10090"
  },
  {
    "key": "6299",
    "ITA": "GASSINO TORINESE",
    "state_code": "TO",
    "cab": "305102",
    "cap": "10090"
  },
  {
    "key": "6300",
    "ITA": "GATTATICO-PRATICELLO",
    "state_code": "RE",
    "cab": "663401",
    "cap": "42043"
  },
  {
    "key": "6301",
    "ITA": "GATTATICO",
    "state_code": "RE",
    "cab": "663401",
    "cap": "42043"
  },
  {
    "key": "6302",
    "ITA": "GATTATICO-TANETO",
    "state_code": "RE",
    "cab": "663401",
    "cap": "42043"
  },
  {
    "key": "6303",
    "ITA": "GATTEO",
    "state_code": "FC",
    "cab": "678201",
    "cap": "47043"
  },
  {
    "key": "6304",
    "ITA": "GATTEO-GATTEO A MARE",
    "state_code": "FC",
    "cab": "678201",
    "cap": "47043"
  },
  {
    "key": "6305",
    "ITA": "GATTEO-SANT'ANGELO IN SALUTE",
    "state_code": "FC",
    "cab": "678201",
    "cap": "47043"
  },
  {
    "key": "6306",
    "ITA": "GATTICO",
    "state_code": "NO",
    "cab": "454108",
    "cap": "28013"
  },
  {
    "key": "6307",
    "ITA": "GATTINARA",
    "state_code": "VC",
    "cab": "445007",
    "cap": "13045"
  },
  {
    "key": "6308",
    "ITA": "GAVARDO-SOPRAPONTE",
    "state_code": "BS",
    "cab": "545608",
    "cap": "25085"
  },
  {
    "key": "6309",
    "ITA": "GAVARDO-SAN BIAGIO",
    "state_code": "BS",
    "cab": "545608",
    "cap": "25085"
  },
  {
    "key": "6310",
    "ITA": "GAVARDO-SAN GIACOMO",
    "state_code": "BS",
    "cab": "545608",
    "cap": "25085"
  },
  {
    "key": "6311",
    "ITA": "GAVARDO",
    "state_code": "BS",
    "cab": "545608",
    "cap": "25085"
  },
  {
    "key": "6312",
    "ITA": "GAVAZZANA",
    "state_code": "AL",
    "cab": "921205",
    "cap": "15063"
  },
  {
    "key": "6313",
    "ITA": "GAVELLO",
    "state_code": "RO",
    "cab": "633206",
    "cap": "45010"
  },
  {
    "key": "6314",
    "ITA": "GAVERINA TERME",
    "state_code": "BG",
    "cab": "928184",
    "cap": "24060"
  },
  {
    "key": "6315",
    "ITA": "GAVI",
    "state_code": "AL",
    "cab": "483107",
    "cap": "15066"
  },
  {
    "key": "6316",
    "ITA": "GAVIGNANO",
    "state_code": "RM",
    "cab": "908137",
    "cap": "30"
  },
  {
    "key": "6317",
    "ITA": "GAVIRATE",
    "state_code": "VA",
    "cab": "502500",
    "cap": "21026"
  },
  {
    "key": "6318",
    "ITA": "GAVIRATE-OLTRONA AL LAGO",
    "state_code": "VA",
    "cab": "502500",
    "cap": "21026"
  },
  {
    "key": "6319",
    "ITA": "GAVOI",
    "state_code": "NU",
    "cab": "853002",
    "cap": "8020"
  },
  {
    "key": "6320",
    "ITA": "GAVORRANO-POTASSA",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6321",
    "ITA": "GAVORRANO-MINIERA",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6322",
    "ITA": "GAVORRANO-GRILLI",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58020"
  },
  {
    "key": "6323",
    "ITA": "GAVORRANO-GIUNCARICO",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6324",
    "ITA": "GAVORRANO-FILARE",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6325",
    "ITA": "GAVORRANO-RAVI",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6326",
    "ITA": "GAVORRANO-CALDANA",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6327",
    "ITA": "GAVORRANO-BOSCHETTO",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6328",
    "ITA": "GAVORRANO-BIVIO DI RAVI",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6329",
    "ITA": "GAVORRANO-BAGNO DI GAVORRANO",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6330",
    "ITA": "GAVORRANO",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58023"
  },
  {
    "key": "6331",
    "ITA": "GAVORRANO-CASTEL DI PIETRA",
    "state_code": "GR",
    "cab": "722504",
    "cap": "58027"
  },
  {
    "key": "6332",
    "ITA": "GAZOLDO DEGLI IPPOLITI",
    "state_code": "MN",
    "cab": "576405",
    "cap": "46040"
  },
  {
    "key": "6333",
    "ITA": "GAZZADA SCHIANNO",
    "state_code": "VA",
    "cab": "502609",
    "cap": "21045"
  },
  {
    "key": "6334",
    "ITA": "GAZZADA SCHIANNO-GAZZADA",
    "state_code": "VA",
    "cab": "502609",
    "cap": "21045"
  },
  {
    "key": "6335",
    "ITA": "GAZZADA SCHIANNO-SCHIANNO",
    "state_code": "VA",
    "cab": "502609",
    "cap": "21045"
  },
  {
    "key": "6336",
    "ITA": "GAZZANIGA-OREZZO",
    "state_code": "BG",
    "cab": "530709",
    "cap": "24025"
  },
  {
    "key": "6337",
    "ITA": "GAZZANIGA",
    "state_code": "BG",
    "cab": "530709",
    "cap": "24025"
  },
  {
    "key": "6338",
    "ITA": "GAZZO",
    "state_code": "PD",
    "cab": "625905",
    "cap": "35010"
  },
  {
    "key": "6339",
    "ITA": "GAZZO VERONESE-SAN PIETRO IN VALLE",
    "state_code": "VR",
    "cab": "594705",
    "cap": "37060"
  },
  {
    "key": "6340",
    "ITA": "GAZZO VERONESE-RONCANOVA",
    "state_code": "VR",
    "cab": "594705",
    "cap": "37060"
  },
  {
    "key": "6341",
    "ITA": "GAZZO VERONESE-MACCACARI",
    "state_code": "VR",
    "cab": "594705",
    "cap": "37060"
  },
  {
    "key": "6342",
    "ITA": "GAZZO VERONESE-CORREZZO",
    "state_code": "VR",
    "cab": "594705",
    "cap": "37060"
  },
  {
    "key": "6343",
    "ITA": "GAZZO VERONESE",
    "state_code": "VR",
    "cab": "594705",
    "cap": "37060"
  },
  {
    "key": "6344",
    "ITA": "GAZZOLA",
    "state_code": "PC",
    "cab": "938654",
    "cap": "29010"
  },
  {
    "key": "6345",
    "ITA": "GAZZOLA-REZZANELLO",
    "state_code": "PC",
    "cab": "938654",
    "cap": "29010"
  },
  {
    "key": "6346",
    "ITA": "GAZZUOLO",
    "state_code": "MN",
    "cab": "576504",
    "cap": "46010"
  },
  {
    "key": "6347",
    "ITA": "GAZZUOLO-BELFORTE",
    "state_code": "MN",
    "cab": "576504",
    "cap": "46010"
  },
  {
    "key": "6348",
    "ITA": "GELA",
    "state_code": "CL",
    "cab": "833301",
    "cap": "93012"
  },
  {
    "key": "6349",
    "ITA": "GEMMANO",
    "state_code": "RN",
    "cab": "941005",
    "cap": "47855"
  },
  {
    "key": "6350",
    "ITA": "GEMMANO-ONFERNO",
    "state_code": "RN",
    "cab": "941005",
    "cap": "47855"
  },
  {
    "key": "6351",
    "ITA": "GEMONA DEL FRIULI-GEMONA PIOVEGA",
    "state_code": "UD",
    "cab": "638809",
    "cap": "33013"
  },
  {
    "key": "6352",
    "ITA": "GEMONA DEL FRIULI-OSPEDALETTO DI GEMONA",
    "state_code": "UD",
    "cab": "638809",
    "cap": "33013"
  },
  {
    "key": "6353",
    "ITA": "GEMONA DEL FRIULI",
    "state_code": "UD",
    "cab": "638809",
    "cap": "33013"
  },
  {
    "key": "6354",
    "ITA": "GEMONIO",
    "state_code": "VA",
    "cab": "502708",
    "cap": "21036"
  },
  {
    "key": "6355",
    "ITA": "GENAZZANO",
    "state_code": "RM",
    "cab": "391201",
    "cap": "30"
  },
  {
    "key": "6356",
    "ITA": "GENGA",
    "state_code": "AN",
    "cab": "373704",
    "cap": "60040"
  },
  {
    "key": "6357",
    "ITA": "GENGA-TRINQUELLI",
    "state_code": "AN",
    "cab": "373704",
    "cap": "60040"
  },
  {
    "key": "6358",
    "ITA": "GENGA-GENGA STAZIONE",
    "state_code": "AN",
    "cab": "373704",
    "cap": "60040"
  },
  {
    "key": "6359",
    "ITA": "GENGA-COLLEPONI",
    "state_code": "AN",
    "cab": "373704",
    "cap": "60040"
  },
  {
    "key": "6360",
    "ITA": "GENIVOLTA",
    "state_code": "CR",
    "cab": "568600",
    "cap": "26020"
  },
  {
    "key": "6361",
    "ITA": "GENOLA",
    "state_code": "CN",
    "cab": "463604",
    "cap": "12040"
  },
  {
    "key": "6362",
    "ITA": "GENONI",
    "state_code": "OR",
    "cab": "866103",
    "cap": "8030"
  },
  {
    "key": "6363",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16100"
  },
  {
    "key": "6364",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16158"
  },
  {
    "key": "6365",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16157"
  },
  {
    "key": "6366",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16159"
  },
  {
    "key": "6367",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16161"
  },
  {
    "key": "6368",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16162"
  },
  {
    "key": "6369",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16163"
  },
  {
    "key": "6370",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16164"
  },
  {
    "key": "6371",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16165"
  },
  {
    "key": "6372",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16166"
  },
  {
    "key": "6373",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16167"
  },
  {
    "key": "6374",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16121"
  },
  {
    "key": "6375",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16122"
  },
  {
    "key": "6376",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16123"
  },
  {
    "key": "6377",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16124"
  },
  {
    "key": "6378",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16125"
  },
  {
    "key": "6379",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16126"
  },
  {
    "key": "6380",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16127"
  },
  {
    "key": "6381",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16128"
  },
  {
    "key": "6382",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16129"
  },
  {
    "key": "6383",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16131"
  },
  {
    "key": "6384",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16132"
  },
  {
    "key": "6385",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16133"
  },
  {
    "key": "6386",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16134"
  },
  {
    "key": "6387",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16135"
  },
  {
    "key": "6388",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16136"
  },
  {
    "key": "6389",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16137"
  },
  {
    "key": "6390",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16138"
  },
  {
    "key": "6391",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16139"
  },
  {
    "key": "6392",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16141"
  },
  {
    "key": "6393",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16142"
  },
  {
    "key": "6394",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16143"
  },
  {
    "key": "6395",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16144"
  },
  {
    "key": "6396",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16145"
  },
  {
    "key": "6397",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16146"
  },
  {
    "key": "6398",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16147"
  },
  {
    "key": "6399",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16148"
  },
  {
    "key": "6400",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16149"
  },
  {
    "key": "6401",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16151"
  },
  {
    "key": "6402",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16152"
  },
  {
    "key": "6403",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16153"
  },
  {
    "key": "6404",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16154"
  },
  {
    "key": "6405",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16155"
  },
  {
    "key": "6406",
    "ITA": "GENOVA",
    "state_code": "GE",
    "cab": "14001",
    "cap": "16156"
  },
  {
    "key": "6407",
    "ITA": "GENURI",
    "state_code": "SU",
    "cab": "914655",
    "cap": "9020"
  },
  {
    "key": "6408",
    "ITA": "GENZANO DI LUCANIA",
    "state_code": "PZ",
    "cab": "420000",
    "cap": "85013"
  },
  {
    "key": "6409",
    "ITA": "GENZANO DI ROMA-LANDI",
    "state_code": "RM",
    "cab": "391300",
    "cap": "45"
  },
  {
    "key": "6410",
    "ITA": "GENZANO DI ROMA-PEDICA",
    "state_code": "RM",
    "cab": "391300",
    "cap": "45"
  },
  {
    "key": "6411",
    "ITA": "GENZANO DI ROMA",
    "state_code": "RM",
    "cab": "391300",
    "cap": "45"
  },
  {
    "key": "6412",
    "ITA": "GENZONE",
    "state_code": "PV",
    "cab": "930511",
    "cap": "27014"
  },
  {
    "key": "6413",
    "ITA": "GERA LARIO",
    "state_code": "CO",
    "cab": "513101",
    "cap": "22010"
  },
  {
    "key": "6414",
    "ITA": "GERACE",
    "state_code": "RC",
    "cab": "609602",
    "cap": "89040"
  },
  {
    "key": "6415",
    "ITA": "GERACI SICULO",
    "state_code": "PA",
    "cab": "433607",
    "cap": "90010"
  },
  {
    "key": "6416",
    "ITA": "GERANO",
    "state_code": "RM",
    "cab": "908145",
    "cap": "25"
  },
  {
    "key": "6417",
    "ITA": "GERENZAGO",
    "state_code": "PV",
    "cab": "278184",
    "cap": "27010"
  },
  {
    "key": "6418",
    "ITA": "GERENZANO",
    "state_code": "VA",
    "cab": "502807",
    "cap": "21040"
  },
  {
    "key": "6419",
    "ITA": "GERGEI",
    "state_code": "CA",
    "cab": "866202",
    "cap": "8030"
  },
  {
    "key": "6420",
    "ITA": "GERMAGNANO",
    "state_code": "TO",
    "cab": "285098",
    "cap": "10070"
  },
  {
    "key": "6421",
    "ITA": "GERMAGNO",
    "state_code": "VB",
    "cab": "917302",
    "cap": "28887"
  },
  {
    "key": "6422",
    "ITA": "GERMASINO",
    "state_code": "CO",
    "cab": "925644",
    "cap": "22010"
  },
  {
    "key": "6423",
    "ITA": "GERMIGNAGA",
    "state_code": "VA",
    "cab": "502906",
    "cap": "21010"
  },
  {
    "key": "6424",
    "ITA": "GEROCARNE",
    "state_code": "VV",
    "cab": "913004",
    "cap": "89831"
  },
  {
    "key": "6425",
    "ITA": "GEROCARNE-SANT'ANGELO",
    "state_code": "VV",
    "cab": "913004",
    "cap": "89831"
  },
  {
    "key": "6426",
    "ITA": "GEROCARNE-CIANO",
    "state_code": "VV",
    "cab": "913004",
    "cap": "89831"
  },
  {
    "key": "6427",
    "ITA": "GEROLA ALTA",
    "state_code": "SO",
    "cab": "927103",
    "cap": "23010"
  },
  {
    "key": "6428",
    "ITA": "GEROSA",
    "state_code": "BG",
    "cab": "928192",
    "cap": "24010"
  },
  {
    "key": "6429",
    "ITA": "GERRE DE' CAPRIOLI",
    "state_code": "CR",
    "cab": "931709",
    "cap": "26040"
  },
  {
    "key": "6430",
    "ITA": "GESICO",
    "state_code": "CA",
    "cab": "859306",
    "cap": "9040"
  },
  {
    "key": "6431",
    "ITA": "GESSATE",
    "state_code": "MI",
    "cab": "331405",
    "cap": "20060"
  },
  {
    "key": "6432",
    "ITA": "GESSOPALENA",
    "state_code": "CH",
    "cab": "488502",
    "cap": "66010"
  },
  {
    "key": "6433",
    "ITA": "GESTURI",
    "state_code": "SU",
    "cab": "859405",
    "cap": "9020"
  },
  {
    "key": "6434",
    "ITA": "GESUALDO",
    "state_code": "AV",
    "cab": "758706",
    "cap": "83040"
  },
  {
    "key": "6435",
    "ITA": "GHEDI",
    "state_code": "BS",
    "cab": "545707",
    "cap": "25016"
  },
  {
    "key": "6436",
    "ITA": "GHEMME",
    "state_code": "NO",
    "cab": "454207",
    "cap": "28074"
  },
  {
    "key": "6437",
    "ITA": "GHIFFA",
    "state_code": "VB",
    "cab": "802504",
    "cap": "28823"
  },
  {
    "key": "6438",
    "ITA": "GHIFFA-CARGIAGO",
    "state_code": "VB",
    "cab": "802504",
    "cap": "28823"
  },
  {
    "key": "6439",
    "ITA": "GHIFFA-SUSELLO",
    "state_code": "VB",
    "cab": "802504",
    "cap": "28823"
  },
  {
    "key": "6440",
    "ITA": "GHILARZA-ZURI",
    "state_code": "OR",
    "cab": "855809",
    "cap": "9074"
  },
  {
    "key": "6441",
    "ITA": "GHILARZA",
    "state_code": "OR",
    "cab": "855809",
    "cap": "9074"
  },
  {
    "key": "6442",
    "ITA": "GHISALBA",
    "state_code": "BG",
    "cab": "530808",
    "cap": "24050"
  },
  {
    "key": "6443",
    "ITA": "GHISLARENGO",
    "state_code": "VC",
    "cab": "915926",
    "cap": "13030"
  },
  {
    "key": "6444",
    "ITA": "GIACCIANO CON BARUCHELLA-ZELO",
    "state_code": "RO",
    "cab": "633305",
    "cap": "45020"
  },
  {
    "key": "6445",
    "ITA": "GIACCIANO CON BARUCHELLA",
    "state_code": "RO",
    "cab": "633305",
    "cap": "45020"
  },
  {
    "key": "6446",
    "ITA": "GIACCIANO CON BARUCHELLA-BARUCHELLA",
    "state_code": "RO",
    "cab": "633305",
    "cap": "45020"
  },
  {
    "key": "6447",
    "ITA": "GIAGLIONE",
    "state_code": "TO",
    "cab": "190397",
    "cap": "10050"
  },
  {
    "key": "6448",
    "ITA": "GIANICO",
    "state_code": "BS",
    "cab": "364505",
    "cap": "25040"
  },
  {
    "key": "6449",
    "ITA": "GIANO DELL'UMBRIA",
    "state_code": "PG",
    "cab": "384503",
    "cap": "6030"
  },
  {
    "key": "6450",
    "ITA": "GIANO DELL'UMBRIA-BASTARDO",
    "state_code": "PG",
    "cab": "384503",
    "cap": "6030"
  },
  {
    "key": "6451",
    "ITA": "GIANO VETUSTO",
    "state_code": "CE",
    "cab": "188565",
    "cap": "81042"
  },
  {
    "key": "6452",
    "ITA": "GIANO VETUSTO-POZZILLO",
    "state_code": "CE",
    "cab": "188565",
    "cap": "81042"
  },
  {
    "key": "6453",
    "ITA": "GIARDINELLO",
    "state_code": "PA",
    "cab": "437509",
    "cap": "90040"
  },
  {
    "key": "6454",
    "ITA": "GIARDINI NAXOS",
    "state_code": "ME",
    "cab": "822205",
    "cap": "98035"
  },
  {
    "key": "6455",
    "ITA": "GIARDINI NAXOS-NAXOS",
    "state_code": "ME",
    "cab": "822205",
    "cap": "98035"
  },
  {
    "key": "6456",
    "ITA": "GIARDINI NAXOS-PALLIO",
    "state_code": "ME",
    "cab": "822205",
    "cap": "98035"
  },
  {
    "key": "6457",
    "ITA": "GIAROLE",
    "state_code": "AL",
    "cab": "483206",
    "cap": "15036"
  },
  {
    "key": "6458",
    "ITA": "GIARRATANA",
    "state_code": "RG",
    "cab": "844605",
    "cap": "97010"
  },
  {
    "key": "6459",
    "ITA": "GIARRE",
    "state_code": "CT",
    "cab": "839506",
    "cap": "95014"
  },
  {
    "key": "6460",
    "ITA": "GIARRE-ALTARELLO",
    "state_code": "CT",
    "cab": "839506",
    "cap": "95014"
  },
  {
    "key": "6461",
    "ITA": "GIARRE-CARRUBA",
    "state_code": "CT",
    "cab": "839506",
    "cap": "95014"
  },
  {
    "key": "6462",
    "ITA": "GIARRE-MACCHIA DI GIARRE",
    "state_code": "CT",
    "cab": "839506",
    "cap": "95014"
  },
  {
    "key": "6463",
    "ITA": "GIARRE-SAN GIOVANNI",
    "state_code": "CT",
    "cab": "839506",
    "cap": "95014"
  },
  {
    "key": "6464",
    "ITA": "GIARRE-TREPUNTI",
    "state_code": "CT",
    "cab": "839506",
    "cap": "95014"
  },
  {
    "key": "6465",
    "ITA": "GIARRE-SAN LEONARDELLO",
    "state_code": "CT",
    "cab": "839506",
    "cap": "95014"
  },
  {
    "key": "6466",
    "ITA": "GIAVE",
    "state_code": "SS",
    "cab": "873604",
    "cap": "7010"
  },
  {
    "key": "6467",
    "ITA": "GIAVENO-SELVAGGIO",
    "state_code": "TO",
    "cab": "305201",
    "cap": "10094"
  },
  {
    "key": "6468",
    "ITA": "GIAVENO-PONTEPIETRA",
    "state_code": "TO",
    "cab": "305201",
    "cap": "10094"
  },
  {
    "key": "6469",
    "ITA": "GIAVENO",
    "state_code": "TO",
    "cab": "305201",
    "cap": "10094"
  },
  {
    "key": "6470",
    "ITA": "GIAVERA DEL MONTELLO-CUSIGNANA",
    "state_code": "TV",
    "cab": "617209",
    "cap": "31040"
  },
  {
    "key": "6471",
    "ITA": "GIAVERA DEL MONTELLO-SANTI ANGELI",
    "state_code": "TV",
    "cab": "617209",
    "cap": "31040"
  },
  {
    "key": "6472",
    "ITA": "GIAVERA DEL MONTELLO",
    "state_code": "TV",
    "cab": "617209",
    "cap": "31040"
  },
  {
    "key": "6473",
    "ITA": "GIBA-VILLARIOS",
    "state_code": "SU",
    "cab": "859504",
    "cap": "9010"
  },
  {
    "key": "6474",
    "ITA": "GIBA",
    "state_code": "SU",
    "cab": "859504",
    "cap": "9010"
  },
  {
    "key": "6475",
    "ITA": "GIBELLINA-VILLAGGIO MADONNA DELLE GRAZIE",
    "state_code": "TP",
    "cab": "818708",
    "cap": "91024"
  },
  {
    "key": "6476",
    "ITA": "GIBELLINA",
    "state_code": "TP",
    "cab": "818708",
    "cap": "91024"
  },
  {
    "key": "6477",
    "ITA": "GIFFLENGA",
    "state_code": "BI",
    "cab": "915934",
    "cap": "13874"
  },
  {
    "key": "6478",
    "ITA": "GIFFONE",
    "state_code": "RC",
    "cab": "960161",
    "cap": "89020"
  },
  {
    "key": "6479",
    "ITA": "GIFFONI SEI CASALI-CAPITIGNANO",
    "state_code": "SA",
    "cab": "886101",
    "cap": "84090"
  },
  {
    "key": "6480",
    "ITA": "GIFFONI SEI CASALI",
    "state_code": "SA",
    "cab": "886101",
    "cap": "84090"
  },
  {
    "key": "6481",
    "ITA": "GIFFONI SEI CASALI-PREPEZZANO",
    "state_code": "SA",
    "cab": "886101",
    "cap": "84090"
  },
  {
    "key": "6482",
    "ITA": "GIFFONI SEI CASALI-SIETI",
    "state_code": "SA",
    "cab": "886101",
    "cap": "84090"
  },
  {
    "key": "6483",
    "ITA": "GIFFONI VALLE PIANA-CURTI",
    "state_code": "SA",
    "cab": "762203",
    "cap": "84095"
  },
  {
    "key": "6484",
    "ITA": "GIFFONI VALLE PIANA",
    "state_code": "SA",
    "cab": "762203",
    "cap": "84095"
  },
  {
    "key": "6485",
    "ITA": "GIFFONI VALLE PIANA-MERCATO",
    "state_code": "SA",
    "cab": "762203",
    "cap": "84095"
  },
  {
    "key": "6486",
    "ITA": "GIFFONI VALLE PIANA-SANTA CATERINA",
    "state_code": "SA",
    "cab": "762203",
    "cap": "84095"
  },
  {
    "key": "6487",
    "ITA": "GIFFONI VALLE PIANA-SAN GIOVANNI",
    "state_code": "SA",
    "cab": "762203",
    "cap": "84095"
  },
  {
    "key": "6488",
    "ITA": "GIFFONI VALLE PIANA-ORNITO",
    "state_code": "SA",
    "cab": "762203",
    "cap": "84095"
  },
  {
    "key": "6489",
    "ITA": "GIGNESE",
    "state_code": "VB",
    "cab": "917328",
    "cap": "28836"
  },
  {
    "key": "6490",
    "ITA": "GIGNESE-VEZZO",
    "state_code": "VB",
    "cab": "917328",
    "cap": "28836"
  },
  {
    "key": "6491",
    "ITA": "GIGNOD",
    "state_code": "AO",
    "cab": "902395",
    "cap": "11010"
  },
  {
    "key": "6492",
    "ITA": "GILDONE",
    "state_code": "CB",
    "cab": "910570",
    "cap": "86010"
  },
  {
    "key": "6493",
    "ITA": "GIMIGLIANO-CAVORA'",
    "state_code": "CZ",
    "cab": "738104",
    "cap": "88045"
  },
  {
    "key": "6494",
    "ITA": "GIMIGLIANO-GIMIGLIANO INFERIORE",
    "state_code": "CZ",
    "cab": "738104",
    "cap": "88045"
  },
  {
    "key": "6495",
    "ITA": "GIMIGLIANO",
    "state_code": "CZ",
    "cab": "738104",
    "cap": "88045"
  },
  {
    "key": "6496",
    "ITA": "GINESTRA",
    "state_code": "PZ",
    "cab": "192203",
    "cap": "85020"
  },
  {
    "key": "6497",
    "ITA": "GINESTRA DEGLI SCHIAVONI",
    "state_code": "BN",
    "cab": "187906",
    "cap": "82020"
  },
  {
    "key": "6498",
    "ITA": "GINOSA",
    "state_code": "TA",
    "cab": "788802",
    "cap": "74013"
  },
  {
    "key": "6499",
    "ITA": "GINOSA-MARINA DI GINOSA",
    "state_code": "TA",
    "cab": "788802",
    "cap": "74025"
  },
  {
    "key": "6500",
    "ITA": "GIOI",
    "state_code": "SA",
    "cab": "952556",
    "cap": "84056"
  },
  {
    "key": "6501",
    "ITA": "GIOI-CARDILE",
    "state_code": "SA",
    "cab": "952556",
    "cap": "84056"
  },
  {
    "key": "6502",
    "ITA": "GIOIA DEI MARSI",
    "state_code": "AQ",
    "cab": "405803",
    "cap": "67055"
  },
  {
    "key": "6503",
    "ITA": "GIOIA DEI MARSI-CASALI D'ASCHI",
    "state_code": "AQ",
    "cab": "405803",
    "cap": "67055"
  },
  {
    "key": "6504",
    "ITA": "GIOIA DEL COLLE",
    "state_code": "BA",
    "cab": "414805",
    "cap": "70023"
  },
  {
    "key": "6505",
    "ITA": "GIOIA DEL COLLE-MURGIA",
    "state_code": "BA",
    "cab": "414805",
    "cap": "70023"
  },
  {
    "key": "6506",
    "ITA": "GIOIA SANNITICA-CARATTANO",
    "state_code": "CE",
    "cab": "949362",
    "cap": "81010"
  },
  {
    "key": "6507",
    "ITA": "GIOIA SANNITICA",
    "state_code": "CE",
    "cab": "949362",
    "cap": "81010"
  },
  {
    "key": "6508",
    "ITA": "GIOIA SANNITICA-CALVISI",
    "state_code": "CE",
    "cab": "949362",
    "cap": "81010"
  },
  {
    "key": "6509",
    "ITA": "GIOIA TAURO",
    "state_code": "RC",
    "cab": "813709",
    "cap": "89013"
  },
  {
    "key": "6510",
    "ITA": "GIOIA TAURO-MARINA DI GIOIA TAURO",
    "state_code": "RC",
    "cab": "813709",
    "cap": "89013"
  },
  {
    "key": "6511",
    "ITA": "GIOIOSA IONICA",
    "state_code": "RC",
    "cab": "813808",
    "cap": "89042"
  },
  {
    "key": "6512",
    "ITA": "GIOIOSA MAREA-MAGARO",
    "state_code": "ME",
    "cab": "822304",
    "cap": "98063"
  },
  {
    "key": "6513",
    "ITA": "GIOIOSA MAREA-SAN GIORGIO",
    "state_code": "ME",
    "cab": "822304",
    "cap": "98063"
  },
  {
    "key": "6514",
    "ITA": "GIOIOSA MAREA",
    "state_code": "ME",
    "cab": "822304",
    "cap": "98063"
  },
  {
    "key": "6515",
    "ITA": "GIOVE",
    "state_code": "TR",
    "cab": "726307",
    "cap": "5024"
  },
  {
    "key": "6516",
    "ITA": "GIOVINAZZO",
    "state_code": "BA",
    "cab": "414904",
    "cap": "70054"
  },
  {
    "key": "6517",
    "ITA": "GIOVO-PALU'",
    "state_code": "TN",
    "cab": "348607",
    "cap": "38030"
  },
  {
    "key": "6518",
    "ITA": "GIOVO-VERLA",
    "state_code": "TN",
    "cab": "348607",
    "cap": "38030"
  },
  {
    "key": "6519",
    "ITA": "GIOVO",
    "state_code": "TN",
    "cab": "348607",
    "cap": "38030"
  },
  {
    "key": "6520",
    "ITA": "GIRASOLE",
    "state_code": "OG",
    "cab": "965632",
    "cap": "8040"
  },
  {
    "key": "6521",
    "ITA": "GIRIFALCO",
    "state_code": "CZ",
    "cab": "425900",
    "cap": "88024"
  },
  {
    "key": "6522",
    "ITA": "GIRONICO",
    "state_code": "CO",
    "cab": "925651",
    "cap": "22020"
  },
  {
    "key": "6523",
    "ITA": "GIRONICO-GIRONICO AL PIANO",
    "state_code": "CO",
    "cab": "925651",
    "cap": "22020"
  },
  {
    "key": "6524",
    "ITA": "GISSI",
    "state_code": "CH",
    "cab": "777102",
    "cap": "66052"
  },
  {
    "key": "6525",
    "ITA": "GIUGGIANELLO",
    "state_code": "LE",
    "cab": "957639",
    "cap": "73030"
  },
  {
    "key": "6526",
    "ITA": "GIUGLIANO IN CAMPANIA-VARCATURO",
    "state_code": "NA",
    "cab": "399006",
    "cap": "80014"
  },
  {
    "key": "6527",
    "ITA": "GIUGLIANO IN CAMPANIA",
    "state_code": "NA",
    "cab": "399006",
    "cap": "80014"
  },
  {
    "key": "6528",
    "ITA": "GIUGLIANO IN CAMPANIA-LAGO PATRIA",
    "state_code": "NA",
    "cab": "399006",
    "cap": "80014"
  },
  {
    "key": "6529",
    "ITA": "GIULIANA",
    "state_code": "PA",
    "cab": "433706",
    "cap": "90030"
  },
  {
    "key": "6530",
    "ITA": "GIULIANO DI ROMA",
    "state_code": "FR",
    "cab": "896704",
    "cap": "3020"
  },
  {
    "key": "6531",
    "ITA": "GIULIANO TEATINO",
    "state_code": "CH",
    "cab": "777201",
    "cap": "66010"
  },
  {
    "key": "6532",
    "ITA": "GIULIANOVA",
    "state_code": "TE",
    "cab": "769109",
    "cap": "64021"
  },
  {
    "key": "6533",
    "ITA": "GIULIANOVA-GIULIANOVA SPIAGGIA",
    "state_code": "TE",
    "cab": "769109",
    "cap": "64021"
  },
  {
    "key": "6534",
    "ITA": "GIULIANOVA-COLLERANESCO",
    "state_code": "TE",
    "cab": "769109",
    "cap": "64021"
  },
  {
    "key": "6535",
    "ITA": "GIUNCUGNANO",
    "state_code": "LU",
    "cab": "943449",
    "cap": "55030"
  },
  {
    "key": "6536",
    "ITA": "GIUNCUGNANO-MAGLIANO",
    "state_code": "LU",
    "cab": "943449",
    "cap": "55030"
  },
  {
    "key": "6537",
    "ITA": "GIUNGANO",
    "state_code": "SA",
    "cab": "952564",
    "cap": "84050"
  },
  {
    "key": "6538",
    "ITA": "GIURDIGNANO",
    "state_code": "LE",
    "cab": "957647",
    "cap": "73020"
  },
  {
    "key": "6539",
    "ITA": "GIUSSAGO-TURAGO BORDONE",
    "state_code": "PV",
    "cab": "558908",
    "cap": "27010"
  },
  {
    "key": "6540",
    "ITA": "GIUSSAGO-GUINZANO",
    "state_code": "PV",
    "cab": "558908",
    "cap": "27010"
  },
  {
    "key": "6541",
    "ITA": "GIUSSAGO",
    "state_code": "PV",
    "cab": "558908",
    "cap": "27010"
  },
  {
    "key": "6542",
    "ITA": "GIUSSANO-PAINA",
    "state_code": "MB",
    "cab": "331504",
    "cap": "20034"
  },
  {
    "key": "6543",
    "ITA": "GIUSSANO",
    "state_code": "MB",
    "cab": "331504",
    "cap": "20034"
  },
  {
    "key": "6544",
    "ITA": "GIUSTENICE",
    "state_code": "SV",
    "cab": "923128",
    "cap": "17027"
  },
  {
    "key": "6545",
    "ITA": "GIUSTINO",
    "state_code": "TN",
    "cab": "904896",
    "cap": "38086"
  },
  {
    "key": "6546",
    "ITA": "GIUSVALLA",
    "state_code": "SV",
    "cab": "923136",
    "cap": "17010"
  },
  {
    "key": "6547",
    "ITA": "GIVOLETTO",
    "state_code": "TO",
    "cab": "728303",
    "cap": "10040"
  },
  {
    "key": "6548",
    "ITA": "GIZZERIA",
    "state_code": "CZ",
    "cab": "429100",
    "cap": "88040"
  },
  {
    "key": "6549",
    "ITA": "GIZZERIA-GIZZERIA LIDO",
    "state_code": "CZ",
    "cab": "429100",
    "cap": "88040"
  },
  {
    "key": "6550",
    "ITA": "GLORENZA",
    "state_code": "BZ",
    "cab": "584409",
    "cap": "39020"
  },
  {
    "key": "6551",
    "ITA": "GODEGA DI SANT'URBANO",
    "state_code": "TV",
    "cab": "617308",
    "cap": "31010"
  },
  {
    "key": "6552",
    "ITA": "GODEGA DI SANT'URBANO-PIANZANO",
    "state_code": "TV",
    "cab": "617308",
    "cap": "31010"
  },
  {
    "key": "6553",
    "ITA": "GODEGA DI SANT'URBANO-BIBANO",
    "state_code": "TV",
    "cab": "617308",
    "cap": "31010"
  },
  {
    "key": "6554",
    "ITA": "GODIASCO",
    "state_code": "PV",
    "cab": "559005",
    "cap": "27052"
  },
  {
    "key": "6555",
    "ITA": "GODIASCO-SALICE TERME",
    "state_code": "PV",
    "cab": "559005",
    "cap": "27052"
  },
  {
    "key": "6556",
    "ITA": "GODRANO",
    "state_code": "PA",
    "cab": "437806",
    "cap": "90030"
  },
  {
    "key": "6557",
    "ITA": "GOITO",
    "state_code": "MN",
    "cab": "576603",
    "cap": "46044"
  },
  {
    "key": "6558",
    "ITA": "GOITO-SOLAROLO",
    "state_code": "MN",
    "cab": "576603",
    "cap": "46044"
  },
  {
    "key": "6559",
    "ITA": "GOITO-CERLONGO",
    "state_code": "MN",
    "cab": "576603",
    "cap": "46044"
  },
  {
    "key": "6560",
    "ITA": "GOLASECCA",
    "state_code": "VA",
    "cab": "503003",
    "cap": "21010"
  },
  {
    "key": "6561",
    "ITA": "GOLFERENZO",
    "state_code": "PV",
    "cab": "930537",
    "cap": "27047"
  },
  {
    "key": "6562",
    "ITA": "GOLFO ARANCI",
    "state_code": "OT",
    "cab": "851105",
    "cap": "7020"
  },
  {
    "key": "6563",
    "ITA": "GOMBITO",
    "state_code": "CR",
    "cab": "573808",
    "cap": "26020"
  },
  {
    "key": "6564",
    "ITA": "GONARS",
    "state_code": "UD",
    "cab": "638908",
    "cap": "33050"
  },
  {
    "key": "6565",
    "ITA": "GONARS-ONTAGNANO",
    "state_code": "UD",
    "cab": "638908",
    "cap": "33050"
  },
  {
    "key": "6566",
    "ITA": "GONI",
    "state_code": "CA",
    "cab": "914697",
    "cap": "9040"
  },
  {
    "key": "6567",
    "ITA": "GONNESA",
    "state_code": "SU",
    "cab": "859603",
    "cap": "9010"
  },
  {
    "key": "6568",
    "ITA": "GONNESA-NURAXI FIGUS",
    "state_code": "SU",
    "cab": "859603",
    "cap": "9010"
  },
  {
    "key": "6569",
    "ITA": "GONNOSCODINA",
    "state_code": "OR",
    "cab": "966770",
    "cap": "9090"
  },
  {
    "key": "6570",
    "ITA": "GONNOSFANADIGA",
    "state_code": "SU",
    "cab": "438903",
    "cap": "9035"
  },
  {
    "key": "6571",
    "ITA": "GONNOSNO'",
    "state_code": "OR",
    "cab": "878306",
    "cap": "9090"
  },
  {
    "key": "6572",
    "ITA": "GONNOSTRAMATZA",
    "state_code": "OR",
    "cab": "878405",
    "cap": "9093"
  },
  {
    "key": "6573",
    "ITA": "GONZAGA",
    "state_code": "MN",
    "cab": "576702",
    "cap": "46023"
  },
  {
    "key": "6574",
    "ITA": "GONZAGA-BONDENO DI GONZAGA",
    "state_code": "MN",
    "cab": "576702",
    "cap": "46023"
  },
  {
    "key": "6575",
    "ITA": "GONZAGA-PALIDANO",
    "state_code": "MN",
    "cab": "576702",
    "cap": "46023"
  },
  {
    "key": "6576",
    "ITA": "GORDONA",
    "state_code": "SO",
    "cab": "837609",
    "cap": "23020"
  },
  {
    "key": "6577",
    "ITA": "GORGA",
    "state_code": "RM",
    "cab": "908152",
    "cap": "30"
  },
  {
    "key": "6578",
    "ITA": "GORGO AL MONTICANO",
    "state_code": "TV",
    "cab": "622902",
    "cap": "31040"
  },
  {
    "key": "6579",
    "ITA": "GORGOGLIONE",
    "state_code": "MT",
    "cab": "805002",
    "cap": "75010"
  },
  {
    "key": "6580",
    "ITA": "GORGONZOLA",
    "state_code": "MI",
    "cab": "331603",
    "cap": "20064"
  },
  {
    "key": "6581",
    "ITA": "GORIANO SICOLI",
    "state_code": "AQ",
    "cab": "909705",
    "cap": "67030"
  },
  {
    "key": "6582",
    "ITA": "GORIZIA-OSLAVIA",
    "state_code": "GO",
    "cab": "124008",
    "cap": "34170"
  },
  {
    "key": "6583",
    "ITA": "GORIZIA-LUCINICO",
    "state_code": "GO",
    "cab": "124008",
    "cap": "34170"
  },
  {
    "key": "6584",
    "ITA": "GORIZIA",
    "state_code": "GO",
    "cab": "124008",
    "cap": "34170"
  },
  {
    "key": "6585",
    "ITA": "GORIZIA-PIUMA",
    "state_code": "GO",
    "cab": "124008",
    "cap": "34170"
  },
  {
    "key": "6586",
    "ITA": "GORIZIA-PIEDIMONTE DEL CALVARIO",
    "state_code": "GO",
    "cab": "124008",
    "cap": "34170"
  },
  {
    "key": "6587",
    "ITA": "GORLA MAGGIORE",
    "state_code": "VA",
    "cab": "503102",
    "cap": "21050"
  },
  {
    "key": "6588",
    "ITA": "GORLA MINORE",
    "state_code": "VA",
    "cab": "503201",
    "cap": "21055"
  },
  {
    "key": "6589",
    "ITA": "GORLAGO",
    "state_code": "BG",
    "cab": "530907",
    "cap": "24060"
  },
  {
    "key": "6590",
    "ITA": "GORLE",
    "state_code": "BG",
    "cab": "531004",
    "cap": "24020"
  },
  {
    "key": "6591",
    "ITA": "GORNATE OLONA",
    "state_code": "VA",
    "cab": "507707",
    "cap": "21040"
  },
  {
    "key": "6592",
    "ITA": "GORNO-VILLASSIO",
    "state_code": "BG",
    "cab": "531103",
    "cap": "24020"
  },
  {
    "key": "6593",
    "ITA": "GORNO",
    "state_code": "BG",
    "cab": "531103",
    "cap": "24020"
  },
  {
    "key": "6594",
    "ITA": "GORO-GORINO",
    "state_code": "FE",
    "cab": "673806",
    "cap": "44020"
  },
  {
    "key": "6595",
    "ITA": "GORO",
    "state_code": "FE",
    "cab": "673806",
    "cap": "44020"
  },
  {
    "key": "6596",
    "ITA": "GORRETO",
    "state_code": "GE",
    "cab": "903179",
    "cap": "16020"
  },
  {
    "key": "6597",
    "ITA": "GORRETO-FONTANAROSSA",
    "state_code": "GE",
    "cab": "903179",
    "cap": "16020"
  },
  {
    "key": "6598",
    "ITA": "GORZEGNO",
    "state_code": "CN",
    "cab": "918714",
    "cap": "12070"
  },
  {
    "key": "6599",
    "ITA": "GOSALDO-TISER",
    "state_code": "BL",
    "cab": "700500",
    "cap": "32020"
  },
  {
    "key": "6600",
    "ITA": "GOSALDO",
    "state_code": "BL",
    "cab": "700500",
    "cap": "32020"
  },
  {
    "key": "6601",
    "ITA": "GOSSOLENGO-SETTIMA",
    "state_code": "PC",
    "cab": "653204",
    "cap": "29020"
  },
  {
    "key": "6602",
    "ITA": "GOSSOLENGO",
    "state_code": "PC",
    "cab": "653204",
    "cap": "29020"
  },
  {
    "key": "6603",
    "ITA": "GOSSOLENGO-QUARTO",
    "state_code": "PC",
    "cab": "653204",
    "cap": "29020"
  },
  {
    "key": "6604",
    "ITA": "GOTTASECCA",
    "state_code": "CN",
    "cab": "918722",
    "cap": "12070"
  },
  {
    "key": "6605",
    "ITA": "GOTTOLENGO",
    "state_code": "BS",
    "cab": "545806",
    "cap": "25023"
  },
  {
    "key": "6606",
    "ITA": "GOVONE-CANOVE",
    "state_code": "CN",
    "cab": "463703",
    "cap": "12040"
  },
  {
    "key": "6607",
    "ITA": "GOVONE",
    "state_code": "CN",
    "cab": "463703",
    "cap": "12040"
  },
  {
    "key": "6608",
    "ITA": "GOZZANO",
    "state_code": "NO",
    "cab": "454306",
    "cap": "28024"
  },
  {
    "key": "6609",
    "ITA": "GRADARA",
    "state_code": "PU",
    "cab": "683300",
    "cap": "61012"
  },
  {
    "key": "6610",
    "ITA": "GRADARA-FANANO",
    "state_code": "PU",
    "cab": "683300",
    "cap": "61012"
  },
  {
    "key": "6611",
    "ITA": "GRADISCA D'ISONZO",
    "state_code": "GO",
    "cab": "645804",
    "cap": "34072"
  },
  {
    "key": "6612",
    "ITA": "GRADO-FOSSALON",
    "state_code": "GO",
    "cab": "645903",
    "cap": "34073"
  },
  {
    "key": "6613",
    "ITA": "GRADO-GRADO CITTA' GIARDINO",
    "state_code": "GO",
    "cab": "645903",
    "cap": "34073"
  },
  {
    "key": "6614",
    "ITA": "GRADO-GRADO PINETA",
    "state_code": "GO",
    "cab": "645903",
    "cap": "34073"
  },
  {
    "key": "6615",
    "ITA": "GRADO",
    "state_code": "GO",
    "cab": "645903",
    "cap": "34073"
  },
  {
    "key": "6616",
    "ITA": "GRADO-ROTTA PRIMERO",
    "state_code": "GO",
    "cab": "645903",
    "cap": "34073"
  },
  {
    "key": "6617",
    "ITA": "GRADOLI",
    "state_code": "VT",
    "cab": "731000",
    "cap": "1010"
  },
  {
    "key": "6618",
    "ITA": "GRAFFIGNANA",
    "state_code": "LO",
    "cab": "331702",
    "cap": "26813"
  },
  {
    "key": "6619",
    "ITA": "GRAFFIGNANO",
    "state_code": "VT",
    "cab": "731109",
    "cap": "1020"
  },
  {
    "key": "6620",
    "ITA": "GRAFFIGNANO-SIPICCIANO",
    "state_code": "VT",
    "cab": "731109",
    "cap": "1020"
  },
  {
    "key": "6621",
    "ITA": "GRAGLIA-GRAGLIA SANTUARIO",
    "state_code": "BI",
    "cab": "445106",
    "cap": "13895"
  },
  {
    "key": "6622",
    "ITA": "GRAGLIA",
    "state_code": "BI",
    "cab": "445106",
    "cap": "13895"
  },
  {
    "key": "6623",
    "ITA": "GRAGLIA-GRAGLIA BAGNI",
    "state_code": "BI",
    "cab": "445106",
    "cap": "13895"
  },
  {
    "key": "6624",
    "ITA": "GRAGNANO",
    "state_code": "NA",
    "cab": "399105",
    "cap": "80054"
  },
  {
    "key": "6625",
    "ITA": "GRAGNANO-CAPRILE",
    "state_code": "NA",
    "cab": "399105",
    "cap": "80054"
  },
  {
    "key": "6626",
    "ITA": "GRAGNANO TREBBIENSE-CASALIGGIO",
    "state_code": "PC",
    "cab": "653303",
    "cap": "29010"
  },
  {
    "key": "6627",
    "ITA": "GRAGNANO TREBBIENSE-CAMPREMOLDO SOPRA",
    "state_code": "PC",
    "cab": "653303",
    "cap": "29010"
  },
  {
    "key": "6628",
    "ITA": "GRAGNANO TREBBIENSE",
    "state_code": "PC",
    "cab": "653303",
    "cap": "29010"
  },
  {
    "key": "6629",
    "ITA": "GRAGNANO TREBBIENSE-CAMPREMOLDO SOTTO",
    "state_code": "PC",
    "cab": "653303",
    "cap": "29010"
  },
  {
    "key": "6630",
    "ITA": "GRAMMICHELE",
    "state_code": "CT",
    "cab": "839605",
    "cap": "95042"
  },
  {
    "key": "6631",
    "ITA": "GRANA",
    "state_code": "AT",
    "cab": "474403",
    "cap": "14031"
  },
  {
    "key": "6632",
    "ITA": "GRANAGLIONE",
    "state_code": "BO",
    "cab": "368407",
    "cap": "40045"
  },
  {
    "key": "6633",
    "ITA": "GRANAGLIONE-PONTE DELLA VENTURINA",
    "state_code": "BO",
    "cab": "368407",
    "cap": "40045"
  },
  {
    "key": "6634",
    "ITA": "GRANAGLIONE-MOLINO DEL PALLONE",
    "state_code": "BO",
    "cab": "368407",
    "cap": "40045"
  },
  {
    "key": "6635",
    "ITA": "GRANAGLIONE-CASA FORLAI",
    "state_code": "BO",
    "cab": "368407",
    "cap": "40045"
  },
  {
    "key": "6636",
    "ITA": "GRANAGLIONE-CASA CALISTRI",
    "state_code": "BO",
    "cab": "368407",
    "cap": "40045"
  },
  {
    "key": "6637",
    "ITA": "GRANAGLIONE-BORGO CAPANNE",
    "state_code": "BO",
    "cab": "368407",
    "cap": "40045"
  },
  {
    "key": "6638",
    "ITA": "GRANAGLIONE-VIZZERO",
    "state_code": "BO",
    "cab": "368407",
    "cap": "40045"
  },
  {
    "key": "6639",
    "ITA": "GRANAROLO DELL'EMILIA-LOVOLETO",
    "state_code": "BO",
    "cab": "368506",
    "cap": "40057"
  },
  {
    "key": "6640",
    "ITA": "GRANAROLO DELL'EMILIA-FABBRERIA DI CADRIANA",
    "state_code": "BO",
    "cab": "368506",
    "cap": "40057"
  },
  {
    "key": "6641",
    "ITA": "GRANAROLO DELL'EMILIA-QUARTO INFERIORE",
    "state_code": "BO",
    "cab": "368506",
    "cap": "40057"
  },
  {
    "key": "6642",
    "ITA": "GRANAROLO DELL'EMILIA",
    "state_code": "BO",
    "cab": "368506",
    "cap": "40057"
  },
  {
    "key": "6643",
    "ITA": "GRANCONA-PEDERIVA",
    "state_code": "VI",
    "cab": "883603",
    "cap": "36040"
  },
  {
    "key": "6644",
    "ITA": "GRANCONA",
    "state_code": "VI",
    "cab": "883603",
    "cap": "36040"
  },
  {
    "key": "6645",
    "ITA": "GRANDATE",
    "state_code": "CO",
    "cab": "513200",
    "cap": "22070"
  },
  {
    "key": "6646",
    "ITA": "GRANDOLA ED UNITI",
    "state_code": "CO",
    "cab": "925669",
    "cap": "22010"
  },
  {
    "key": "6647",
    "ITA": "GRANDOLA ED UNITI-CODOGNA",
    "state_code": "CO",
    "cab": "925669",
    "cap": "22010"
  },
  {
    "key": "6648",
    "ITA": "GRANITI",
    "state_code": "ME",
    "cab": "961367",
    "cap": "98036"
  },
  {
    "key": "6649",
    "ITA": "GRANOZZO CON MONTICELLO",
    "state_code": "NO",
    "cab": "917336",
    "cap": "28060"
  },
  {
    "key": "6650",
    "ITA": "GRANOZZO CON MONTICELLO-GRANOZZO",
    "state_code": "NO",
    "cab": "917336",
    "cap": "28060"
  },
  {
    "key": "6651",
    "ITA": "GRANTOLA",
    "state_code": "VA",
    "cab": "924530",
    "cap": "21030"
  },
  {
    "key": "6652",
    "ITA": "GRANTORTO",
    "state_code": "PD",
    "cab": "890905",
    "cap": "35010"
  },
  {
    "key": "6653",
    "ITA": "GRANZE",
    "state_code": "PD",
    "cab": "489104",
    "cap": "35040"
  },
  {
    "key": "6654",
    "ITA": "GRASSANO",
    "state_code": "MT",
    "cab": "803205",
    "cap": "75014"
  },
  {
    "key": "6655",
    "ITA": "GRASSOBBIO",
    "state_code": "BG",
    "cab": "531202",
    "cap": "24050"
  },
  {
    "key": "6656",
    "ITA": "GRATTERI",
    "state_code": "PA",
    "cab": "438101",
    "cap": "90010"
  },
  {
    "key": "6657",
    "ITA": "GRAUNO",
    "state_code": "TN",
    "cab": "890608",
    "cap": "38030"
  },
  {
    "key": "6658",
    "ITA": "GRAVEDONA",
    "state_code": "CO",
    "cab": "513309",
    "cap": "22015"
  },
  {
    "key": "6659",
    "ITA": "GRAVELLONA LOMELLINA",
    "state_code": "PV",
    "cab": "559104",
    "cap": "27020"
  },
  {
    "key": "6660",
    "ITA": "GRAVELLONA TOCE",
    "state_code": "VB",
    "cab": "454405",
    "cap": "28883"
  },
  {
    "key": "6661",
    "ITA": "GRAVELLONA TOCE-GRANEROLO",
    "state_code": "VB",
    "cab": "454405",
    "cap": "28883"
  },
  {
    "key": "6662",
    "ITA": "GRAVELLONA TOCE-PEDEMONTE",
    "state_code": "VB",
    "cab": "454405",
    "cap": "28883"
  },
  {
    "key": "6663",
    "ITA": "GRAVERE",
    "state_code": "TO",
    "cab": "189761",
    "cap": "10050"
  },
  {
    "key": "6664",
    "ITA": "GRAVINA DI CATANIA",
    "state_code": "CT",
    "cab": "839704",
    "cap": "95030"
  },
  {
    "key": "6665",
    "ITA": "GRAVINA IN PUGLIA-MURGETTA",
    "state_code": "BA",
    "cab": "415000",
    "cap": "70024"
  },
  {
    "key": "6666",
    "ITA": "GRAVINA IN PUGLIA",
    "state_code": "BA",
    "cab": "415000",
    "cap": "70024"
  },
  {
    "key": "6667",
    "ITA": "GRAVINA IN PUGLIA-DOLCECANTO",
    "state_code": "BA",
    "cab": "415000",
    "cap": "70024"
  },
  {
    "key": "6668",
    "ITA": "GRAZZANISE",
    "state_code": "CE",
    "cab": "751206",
    "cap": "81046"
  },
  {
    "key": "6669",
    "ITA": "GRAZZANISE-BORGO APPIO",
    "state_code": "CE",
    "cab": "751206",
    "cap": "81046"
  },
  {
    "key": "6670",
    "ITA": "GRAZZANISE-BREZZA",
    "state_code": "CE",
    "cab": "751206",
    "cap": "81046"
  },
  {
    "key": "6671",
    "ITA": "GRAZZANO BADOGLIO",
    "state_code": "AT",
    "cab": "474502",
    "cap": "14035"
  },
  {
    "key": "6672",
    "ITA": "GRECCIO-LIMITI DI GRECCIO",
    "state_code": "RI",
    "cab": "736504",
    "cap": "2045"
  },
  {
    "key": "6673",
    "ITA": "GRECCIO",
    "state_code": "RI",
    "cab": "736504",
    "cap": "2045"
  },
  {
    "key": "6674",
    "ITA": "GRECI",
    "state_code": "AV",
    "cab": "951160",
    "cap": "83030"
  },
  {
    "key": "6675",
    "ITA": "GREGGIO",
    "state_code": "VC",
    "cab": "915942",
    "cap": "13030"
  },
  {
    "key": "6676",
    "ITA": "GREMIASCO",
    "state_code": "AL",
    "cab": "921213",
    "cap": "15056"
  },
  {
    "key": "6677",
    "ITA": "GRESSAN",
    "state_code": "AO",
    "cab": "315903",
    "cap": "11020"
  },
  {
    "key": "6678",
    "ITA": "GRESSONEY LA TRINITE'",
    "state_code": "AO",
    "cab": "565903",
    "cap": "11020"
  },
  {
    "key": "6679",
    "ITA": "GRESSONEY SAINT JEAN",
    "state_code": "AO",
    "cab": "316000",
    "cap": "11025"
  },
  {
    "key": "6680",
    "ITA": "GREVE IN CHIANTI-PANZANO",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50022"
  },
  {
    "key": "6681",
    "ITA": "GREVE IN CHIANTI-SAN POLO IN CHIANTI",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50022"
  },
  {
    "key": "6682",
    "ITA": "GREVE IN CHIANTI-STRADA IN CHIANTI",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50027"
  },
  {
    "key": "6683",
    "ITA": "GREVE IN CHIANTI-LUCOLENA",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50022"
  },
  {
    "key": "6684",
    "ITA": "GREVE IN CHIANTI-LAMOLE",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50022"
  },
  {
    "key": "6685",
    "ITA": "GREVE IN CHIANTI-CHIOCCHIO",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50027"
  },
  {
    "key": "6686",
    "ITA": "GREVE IN CHIANTI",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50022"
  },
  {
    "key": "6687",
    "ITA": "GREVE IN CHIANTI-PASSO DEI PECORAI",
    "state_code": "FI",
    "cab": "378901",
    "cap": "50027"
  },
  {
    "key": "6688",
    "ITA": "GREZZAGO",
    "state_code": "MI",
    "cab": "835009",
    "cap": "20056"
  },
  {
    "key": "6689",
    "ITA": "GREZZANA-STALLAVENA",
    "state_code": "VR",
    "cab": "594804",
    "cap": "37023"
  },
  {
    "key": "6690",
    "ITA": "GREZZANA-ROMAGNANO",
    "state_code": "VR",
    "cab": "594804",
    "cap": "37023"
  },
  {
    "key": "6691",
    "ITA": "GREZZANA-LUGO DI GREZZANA",
    "state_code": "VR",
    "cab": "594804",
    "cap": "37023"
  },
  {
    "key": "6692",
    "ITA": "GREZZANA-AZZAGO",
    "state_code": "VR",
    "cab": "594804",
    "cap": "37023"
  },
  {
    "key": "6693",
    "ITA": "GREZZANA",
    "state_code": "VR",
    "cab": "594804",
    "cap": "37023"
  },
  {
    "key": "6694",
    "ITA": "GRIANTE",
    "state_code": "CO",
    "cab": "513408",
    "cap": "22011"
  },
  {
    "key": "6695",
    "ITA": "GRIANTE-CADENABBIA",
    "state_code": "CO",
    "cab": "513408",
    "cap": "22011"
  },
  {
    "key": "6696",
    "ITA": "GRICIGNANO DI AVERSA",
    "state_code": "CE",
    "cab": "898601",
    "cap": "81030"
  },
  {
    "key": "6697",
    "ITA": "GRIGNASCO",
    "state_code": "NO",
    "cab": "454504",
    "cap": "28075"
  },
  {
    "key": "6698",
    "ITA": "GRIGNO",
    "state_code": "TN",
    "cab": "348706",
    "cap": "38055"
  },
  {
    "key": "6699",
    "ITA": "GRIGNO-SELVA",
    "state_code": "TN",
    "cab": "348706",
    "cap": "38055"
  },
  {
    "key": "6700",
    "ITA": "GRIGNO-TEZZE",
    "state_code": "TN",
    "cab": "348706",
    "cap": "38055"
  },
  {
    "key": "6701",
    "ITA": "GRIMACCO-CLODIG",
    "state_code": "UD",
    "cab": "936948",
    "cap": "33040"
  },
  {
    "key": "6702",
    "ITA": "GRIMACCO",
    "state_code": "UD",
    "cab": "936948",
    "cap": "33040"
  },
  {
    "key": "6703",
    "ITA": "GRIMALDI",
    "state_code": "CS",
    "cab": "807503",
    "cap": "87034"
  },
  {
    "key": "6704",
    "ITA": "GRINZANE CAVOUR-GALLO",
    "state_code": "CN",
    "cab": "463802",
    "cap": "12060"
  },
  {
    "key": "6705",
    "ITA": "GRINZANE CAVOUR",
    "state_code": "CN",
    "cab": "463802",
    "cap": "12060"
  },
  {
    "key": "6706",
    "ITA": "GRISIGNANO DI ZOCCO",
    "state_code": "VI",
    "cab": "604108",
    "cap": "36040"
  },
  {
    "key": "6707",
    "ITA": "GRISIGNANO DI ZOCCO-POIANA DI GRANFION",
    "state_code": "VI",
    "cab": "604108",
    "cap": "36040"
  },
  {
    "key": "6708",
    "ITA": "GRISOLIA",
    "state_code": "CS",
    "cab": "958900",
    "cap": "87020"
  },
  {
    "key": "6709",
    "ITA": "GRIZZANA MORANDI-PIANDISETTA",
    "state_code": "BO",
    "cab": "906412",
    "cap": "40030"
  },
  {
    "key": "6710",
    "ITA": "GRIZZANA MORANDI",
    "state_code": "BO",
    "cab": "906412",
    "cap": "40030"
  },
  {
    "key": "6711",
    "ITA": "GRIZZANA MORANDI-PIOPPE DI SALVARO",
    "state_code": "BO",
    "cab": "906412",
    "cap": "40030"
  },
  {
    "key": "6712",
    "ITA": "GROGNARDO",
    "state_code": "AL",
    "cab": "921221",
    "cap": "15010"
  },
  {
    "key": "6713",
    "ITA": "GROMO",
    "state_code": "BG",
    "cab": "531301",
    "cap": "24020"
  },
  {
    "key": "6714",
    "ITA": "GROMO-BOARIO",
    "state_code": "BG",
    "cab": "531301",
    "cap": "24020"
  },
  {
    "key": "6715",
    "ITA": "GRONDONA",
    "state_code": "AL",
    "cab": "921239",
    "cap": "15060"
  },
  {
    "key": "6716",
    "ITA": "GRONE",
    "state_code": "BG",
    "cab": "928200",
    "cap": "24060"
  },
  {
    "key": "6717",
    "ITA": "GRONTARDO-LEVATA",
    "state_code": "CR",
    "cab": "568709",
    "cap": "26044"
  },
  {
    "key": "6718",
    "ITA": "GRONTARDO",
    "state_code": "CR",
    "cab": "568709",
    "cap": "26044"
  },
  {
    "key": "6719",
    "ITA": "GROPELLO CAIROLI",
    "state_code": "PV",
    "cab": "559203",
    "cap": "27027"
  },
  {
    "key": "6720",
    "ITA": "GROPPARELLO-SARIANO",
    "state_code": "PC",
    "cab": "653402",
    "cap": "29025"
  },
  {
    "key": "6721",
    "ITA": "GROPPARELLO",
    "state_code": "PC",
    "cab": "653402",
    "cap": "29025"
  },
  {
    "key": "6722",
    "ITA": "GROPPARELLO-GROPPOVISDOMO",
    "state_code": "PC",
    "cab": "653402",
    "cap": "29025"
  },
  {
    "key": "6723",
    "ITA": "GROSCAVALLO-BONZO",
    "state_code": "TO",
    "cab": "283606",
    "cap": "10070"
  },
  {
    "key": "6724",
    "ITA": "GROSCAVALLO",
    "state_code": "TO",
    "cab": "283606",
    "cap": "10070"
  },
  {
    "key": "6725",
    "ITA": "GROSCAVALLO-FORNO ALPI GRAIE",
    "state_code": "TO",
    "cab": "283606",
    "cap": "10070"
  },
  {
    "key": "6726",
    "ITA": "GROSIO",
    "state_code": "SO",
    "cab": "521609",
    "cap": "23033"
  },
  {
    "key": "6727",
    "ITA": "GROSIO-TIOLO",
    "state_code": "SO",
    "cab": "521609",
    "cap": "23033"
  },
  {
    "key": "6728",
    "ITA": "GROSIO-RAVOLEDO",
    "state_code": "SO",
    "cab": "521609",
    "cap": "23033"
  },
  {
    "key": "6729",
    "ITA": "GROSOTTO",
    "state_code": "SO",
    "cab": "521708",
    "cap": "23034"
  },
  {
    "key": "6730",
    "ITA": "GROSSETO-SANTA MARIA DI RISPESCIA",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6731",
    "ITA": "GROSSETO-ROSELLE TERME",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6732",
    "ITA": "GROSSETO-RISPESCIA",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6733",
    "ITA": "GROSSETO-MONTEPESCALI STAZIONE",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6734",
    "ITA": "GROSSETO-MONTEPESCALI",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6735",
    "ITA": "GROSSETO-MARINA DI GROSSETO",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6736",
    "ITA": "GROSSETO-LE STIACCIOLE",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6737",
    "ITA": "GROSSETO-ISTIA D'OMBRONE",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6738",
    "ITA": "GROSSETO-BRACCAGNI",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6739",
    "ITA": "GROSSETO-BATIGNANO",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6740",
    "ITA": "GROSSETO-BAGNO ROSELLE",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6741",
    "ITA": "GROSSETO",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6742",
    "ITA": "GROSSETO-ALBERESE",
    "state_code": "GR",
    "cab": "143008",
    "cap": "58100"
  },
  {
    "key": "6743",
    "ITA": "GROSSO",
    "state_code": "TO",
    "cab": "181081",
    "cap": "10070"
  },
  {
    "key": "6744",
    "ITA": "GROTTAFERRATA-POGGIO TULLIANO",
    "state_code": "RM",
    "cab": "391409",
    "cap": "46"
  },
  {
    "key": "6745",
    "ITA": "GROTTAFERRATA",
    "state_code": "RM",
    "cab": "391409",
    "cap": "46"
  },
  {
    "key": "6746",
    "ITA": "GROTTAGLIE",
    "state_code": "TA",
    "cab": "788901",
    "cap": "74023"
  },
  {
    "key": "6747",
    "ITA": "GROTTAMINARDA",
    "state_code": "AV",
    "cab": "757302",
    "cap": "83035"
  },
  {
    "key": "6748",
    "ITA": "GROTTAMINARDA-CARPIGNANO",
    "state_code": "AV",
    "cab": "757302",
    "cap": "83035"
  },
  {
    "key": "6749",
    "ITA": "GROTTAMMARE",
    "state_code": "AP",
    "cab": "694703",
    "cap": "63013"
  },
  {
    "key": "6750",
    "ITA": "GROTTAMMARE-ISCHIA",
    "state_code": "AP",
    "cab": "694703",
    "cap": "63013"
  },
  {
    "key": "6751",
    "ITA": "GROTTAZZOLINA",
    "state_code": "FM",
    "cab": "694802",
    "cap": "63024"
  },
  {
    "key": "6752",
    "ITA": "GROTTE",
    "state_code": "AG",
    "cab": "829408",
    "cap": "92020"
  },
  {
    "key": "6753",
    "ITA": "GROTTE DI CASTRO",
    "state_code": "VT",
    "cab": "731208",
    "cap": "1025"
  },
  {
    "key": "6754",
    "ITA": "GROTTERIA-CROCE FERRATA",
    "state_code": "RC",
    "cab": "813907",
    "cap": "89043"
  },
  {
    "key": "6755",
    "ITA": "GROTTERIA",
    "state_code": "RC",
    "cab": "813907",
    "cap": "89043"
  },
  {
    "key": "6756",
    "ITA": "GROTTOLE",
    "state_code": "MT",
    "cab": "803304",
    "cap": "75010"
  },
  {
    "key": "6757",
    "ITA": "GROTTOLELLA",
    "state_code": "AV",
    "cab": "951178",
    "cap": "83010"
  },
  {
    "key": "6758",
    "ITA": "GRUARO",
    "state_code": "VE",
    "cab": "363804",
    "cap": "30020"
  },
  {
    "key": "6759",
    "ITA": "GRUARO-GIAI",
    "state_code": "VE",
    "cab": "363804",
    "cap": "30020"
  },
  {
    "key": "6760",
    "ITA": "GRUARO-BAGNARA",
    "state_code": "VE",
    "cab": "363804",
    "cap": "30020"
  },
  {
    "key": "6761",
    "ITA": "GRUGLIASCO",
    "state_code": "TO",
    "cab": "305300",
    "cap": "10095"
  },
  {
    "key": "6762",
    "ITA": "GRUGLIASCO-LESNA",
    "state_code": "TO",
    "cab": "305300",
    "cap": "10095"
  },
  {
    "key": "6763",
    "ITA": "GRUGLIASCO-GERBIDO",
    "state_code": "TO",
    "cab": "305300",
    "cap": "10095"
  },
  {
    "key": "6764",
    "ITA": "GRUMELLO CREMONESE ED UNITI-GRUMELLO CREMONESE",
    "state_code": "CR",
    "cab": "568808",
    "cap": "26023"
  },
  {
    "key": "6765",
    "ITA": "GRUMELLO CREMONESE ED UNITI-FARFENGO",
    "state_code": "CR",
    "cab": "568808",
    "cap": "26023"
  },
  {
    "key": "6766",
    "ITA": "GRUMELLO CREMONESE ED UNITI",
    "state_code": "CR",
    "cab": "568808",
    "cap": "26023"
  },
  {
    "key": "6767",
    "ITA": "GRUMELLO DEL MONTE",
    "state_code": "BG",
    "cab": "531400",
    "cap": "24064"
  },
  {
    "key": "6768",
    "ITA": "GRUMENTO NOVA",
    "state_code": "PZ",
    "cab": "911990",
    "cap": "85050"
  },
  {
    "key": "6769",
    "ITA": "GRUMES",
    "state_code": "TN",
    "cab": "348805",
    "cap": "38030"
  },
  {
    "key": "6770",
    "ITA": "GRUMO APPULA",
    "state_code": "BA",
    "cab": "415109",
    "cap": "70025"
  },
  {
    "key": "6771",
    "ITA": "GRUMO NEVANO",
    "state_code": "NA",
    "cab": "399204",
    "cap": "80028"
  },
  {
    "key": "6772",
    "ITA": "GRUMOLO DELLE ABBADESSE",
    "state_code": "VI",
    "cab": "604207",
    "cap": "36040"
  },
  {
    "key": "6773",
    "ITA": "GUAGNANO-VILLA BALDASSARRI",
    "state_code": "LE",
    "cab": "796805",
    "cap": "73010"
  },
  {
    "key": "6774",
    "ITA": "GUAGNANO",
    "state_code": "LE",
    "cab": "796805",
    "cap": "73010"
  },
  {
    "key": "6775",
    "ITA": "GUALDO",
    "state_code": "MC",
    "cab": "689307",
    "cap": "62020"
  },
  {
    "key": "6776",
    "ITA": "GUALDO CATTANEO-POZZO",
    "state_code": "PG",
    "cab": "384602",
    "cap": "6035"
  },
  {
    "key": "6777",
    "ITA": "GUALDO CATTANEO-SAN TERENZIANO",
    "state_code": "PG",
    "cab": "384602",
    "cap": "6035"
  },
  {
    "key": "6778",
    "ITA": "GUALDO CATTANEO-MARCELLANO",
    "state_code": "PG",
    "cab": "384602",
    "cap": "6035"
  },
  {
    "key": "6779",
    "ITA": "GUALDO CATTANEO",
    "state_code": "PG",
    "cab": "384602",
    "cap": "6035"
  },
  {
    "key": "6780",
    "ITA": "GUALDO CATTANEO-COLLESECCO",
    "state_code": "PG",
    "cab": "384602",
    "cap": "6035"
  },
  {
    "key": "6781",
    "ITA": "GUALDO TADINO-MORANO MADONNUCCIA",
    "state_code": "PG",
    "cab": "384701",
    "cap": "6023"
  },
  {
    "key": "6782",
    "ITA": "GUALDO TADINO-CERQUETO",
    "state_code": "PG",
    "cab": "384701",
    "cap": "6023"
  },
  {
    "key": "6783",
    "ITA": "GUALDO TADINO-SAN PELLEGRINO",
    "state_code": "PG",
    "cab": "384701",
    "cap": "6023"
  },
  {
    "key": "6784",
    "ITA": "GUALDO TADINO-PIEVE DI COMPRESSETO",
    "state_code": "PG",
    "cab": "384701",
    "cap": "6023"
  },
  {
    "key": "6785",
    "ITA": "GUALDO TADINO",
    "state_code": "PG",
    "cab": "384701",
    "cap": "6023"
  },
  {
    "key": "6786",
    "ITA": "GUALTIERI-SANTA VITTORIA",
    "state_code": "RE",
    "cab": "663500",
    "cap": "42044"
  },
  {
    "key": "6787",
    "ITA": "GUALTIERI",
    "state_code": "RE",
    "cab": "663500",
    "cap": "42044"
  },
  {
    "key": "6788",
    "ITA": "GUALTIERI-PIEVE SALICETO",
    "state_code": "RE",
    "cab": "663500",
    "cap": "42044"
  },
  {
    "key": "6789",
    "ITA": "GUALTIERI SICAMINO'-SOCCORSO",
    "state_code": "ME",
    "cab": "822403",
    "cap": "98040"
  },
  {
    "key": "6790",
    "ITA": "GUALTIERI SICAMINO'",
    "state_code": "ME",
    "cab": "822403",
    "cap": "98040"
  },
  {
    "key": "6791",
    "ITA": "GUAMAGGIORE",
    "state_code": "CA",
    "cab": "859702",
    "cap": "9040"
  },
  {
    "key": "6792",
    "ITA": "GUANZATE",
    "state_code": "CO",
    "cab": "513507",
    "cap": "22070"
  },
  {
    "key": "6793",
    "ITA": "GUARCINO",
    "state_code": "FR",
    "cab": "744607",
    "cap": "3016"
  },
  {
    "key": "6794",
    "ITA": "GUARCINO-CAMPOCATINO",
    "state_code": "FR",
    "cab": "744607",
    "cap": "3016"
  },
  {
    "key": "6795",
    "ITA": "GUARDA VENETA",
    "state_code": "RO",
    "cab": "782201",
    "cap": "45030"
  },
  {
    "key": "6796",
    "ITA": "GUARDABOSONE",
    "state_code": "VC",
    "cab": "915959",
    "cap": "13010"
  },
  {
    "key": "6797",
    "ITA": "GUARDAMIGLIO",
    "state_code": "LO",
    "cab": "331801",
    "cap": "26862"
  },
  {
    "key": "6798",
    "ITA": "GUARDAVALLE-GUARDAVALLE MARINA",
    "state_code": "CZ",
    "cab": "426007",
    "cap": "88060"
  },
  {
    "key": "6799",
    "ITA": "GUARDAVALLE",
    "state_code": "CZ",
    "cab": "426007",
    "cap": "88065"
  },
  {
    "key": "6800",
    "ITA": "GUARDEA",
    "state_code": "TR",
    "cab": "726406",
    "cap": "5025"
  },
  {
    "key": "6801",
    "ITA": "GUARDIA LOMBARDI",
    "state_code": "AV",
    "cab": "858100",
    "cap": "83040"
  },
  {
    "key": "6802",
    "ITA": "GUARDIA PERTICARA",
    "state_code": "PZ",
    "cab": "912006",
    "cap": "85010"
  },
  {
    "key": "6803",
    "ITA": "GUARDIA PIEMONTESE-GUARDIA PIEMONTESE MARINA",
    "state_code": "CS",
    "cab": "807602",
    "cap": "87020"
  },
  {
    "key": "6804",
    "ITA": "GUARDIA PIEMONTESE-GUARDIA PIEMONTESE TERME",
    "state_code": "CS",
    "cab": "807602",
    "cap": "87020"
  },
  {
    "key": "6805",
    "ITA": "GUARDIA PIEMONTESE",
    "state_code": "CS",
    "cab": "807602",
    "cap": "87020"
  },
  {
    "key": "6806",
    "ITA": "GUARDIA SANFRAMONDI",
    "state_code": "BN",
    "cab": "753608",
    "cap": "82034"
  },
  {
    "key": "6807",
    "ITA": "GUARDIAGRELE-PIANO DELLE FONTI",
    "state_code": "CH",
    "cab": "777300",
    "cap": "66016"
  },
  {
    "key": "6808",
    "ITA": "GUARDIAGRELE-COMINO",
    "state_code": "CH",
    "cab": "777300",
    "cap": "66016"
  },
  {
    "key": "6809",
    "ITA": "GUARDIAGRELE-CAPOROSSO",
    "state_code": "CH",
    "cab": "777300",
    "cap": "66016"
  },
  {
    "key": "6810",
    "ITA": "GUARDIAGRELE",
    "state_code": "CH",
    "cab": "777300",
    "cap": "66016"
  },
  {
    "key": "6811",
    "ITA": "GUARDIAGRELE-VILLA SAN VINCENZO",
    "state_code": "CH",
    "cab": "777300",
    "cap": "66016"
  },
  {
    "key": "6812",
    "ITA": "GUARDIALFIERA",
    "state_code": "CB",
    "cab": "910588",
    "cap": "86030"
  },
  {
    "key": "6813",
    "ITA": "GUARDIAREGIA",
    "state_code": "CB",
    "cab": "910596",
    "cap": "86014"
  },
  {
    "key": "6814",
    "ITA": "GUARDISTALLO",
    "state_code": "PI",
    "cab": "710202",
    "cap": "56040"
  },
  {
    "key": "6815",
    "ITA": "GUARENE-CASTELROTTO",
    "state_code": "CN",
    "cab": "412502",
    "cap": "12050"
  },
  {
    "key": "6816",
    "ITA": "GUARENE",
    "state_code": "CN",
    "cab": "412502",
    "cap": "12050"
  },
  {
    "key": "6817",
    "ITA": "GUARENE-VACCHERIA",
    "state_code": "CN",
    "cab": "412502",
    "cap": "12050"
  },
  {
    "key": "6818",
    "ITA": "GUASILA",
    "state_code": "CA",
    "cab": "859801",
    "cap": "9040"
  },
  {
    "key": "6819",
    "ITA": "GUASTALLA-SAN GIROLAMO",
    "state_code": "RE",
    "cab": "663609",
    "cap": "42016"
  },
  {
    "key": "6820",
    "ITA": "GUASTALLA",
    "state_code": "RE",
    "cab": "663609",
    "cap": "42016"
  },
  {
    "key": "6821",
    "ITA": "GUASTALLA-PIEVE",
    "state_code": "RE",
    "cab": "663609",
    "cap": "42016"
  },
  {
    "key": "6822",
    "ITA": "GUAZZORA",
    "state_code": "AL",
    "cab": "921247",
    "cap": "15050"
  },
  {
    "key": "6823",
    "ITA": "GUBBIO-CAICAMBIUCCI",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6824",
    "ITA": "GUBBIO",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6825",
    "ITA": "GUBBIO-CASAMORCIA",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6826",
    "ITA": "GUBBIO-CIPOLLETO",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6827",
    "ITA": "GUBBIO-COLPALOMBO",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6828",
    "ITA": "GUBBIO-MOCAIANA",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6829",
    "ITA": "GUBBIO-PADULE",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6830",
    "ITA": "GUBBIO-PONTE D'ASSI",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6831",
    "ITA": "GUBBIO-SAN BENEDETTO VECCHIO",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6832",
    "ITA": "GUBBIO-SCRITTO",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6833",
    "ITA": "GUBBIO-SEMONTE",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6834",
    "ITA": "GUBBIO-STAZIONE DI PADULE",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6835",
    "ITA": "GUBBIO-TORRE CALZOLARI",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6020"
  },
  {
    "key": "6836",
    "ITA": "GUBBIO-BRANCA",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6020"
  },
  {
    "key": "6837",
    "ITA": "GUBBIO-CAMPOREGGIANO",
    "state_code": "PG",
    "cab": "384800",
    "cap": "6024"
  },
  {
    "key": "6838",
    "ITA": "GUDO VISCONTI",
    "state_code": "MI",
    "cab": "805200",
    "cap": "20088"
  },
  {
    "key": "6839",
    "ITA": "GUGLIONESI",
    "state_code": "CB",
    "cab": "410407",
    "cap": "86034"
  },
  {
    "key": "6840",
    "ITA": "GUIDIZZOLO",
    "state_code": "MN",
    "cab": "576801",
    "cap": "46040"
  },
  {
    "key": "6841",
    "ITA": "GUIDONIA MONTECELIO-VILLANOVA",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6842",
    "ITA": "GUIDONIA MONTECELIO-VILLALBA",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6843",
    "ITA": "GUIDONIA MONTECELIO-SETTEVILLE",
    "state_code": "RM",
    "cab": "391508",
    "cap": "10"
  },
  {
    "key": "6844",
    "ITA": "GUIDONIA MONTECELIO-MONTECELIO",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6845",
    "ITA": "GUIDONIA MONTECELIO-LA BOTTE",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6846",
    "ITA": "GUIDONIA MONTECELIO-GUIDONIA",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6847",
    "ITA": "GUIDONIA MONTECELIO-COLLE VERDE",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6848",
    "ITA": "GUIDONIA MONTECELIO-ALBUCCIONE",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6849",
    "ITA": "GUIDONIA MONTECELIO",
    "state_code": "RM",
    "cab": "391508",
    "cap": "12"
  },
  {
    "key": "6850",
    "ITA": "GUIGLIA",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6851",
    "ITA": "GUIGLIA-CASTELLINO DELLE FORMICHE",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6852",
    "ITA": "GUIGLIA-GAINAZZO",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6853",
    "ITA": "GUIGLIA-SAMONE",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6854",
    "ITA": "GUIGLIA-PIEVE TREBBIO",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6855",
    "ITA": "GUIGLIA-ROCCA MALATINA",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6856",
    "ITA": "GUIGLIA-ROCCHETTA",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6857",
    "ITA": "GUIGLIA-MONTEORSELLO",
    "state_code": "MO",
    "cab": "668004",
    "cap": "41052"
  },
  {
    "key": "6858",
    "ITA": "GUILMI",
    "state_code": "CH",
    "cab": "954735",
    "cap": "66050"
  },
  {
    "key": "6859",
    "ITA": "GURRO",
    "state_code": "VB",
    "cab": "917344",
    "cap": "28828"
  },
  {
    "key": "6860",
    "ITA": "GUSPINI-MONTEVECCHIO",
    "state_code": "SU",
    "cab": "439000",
    "cap": "9030"
  },
  {
    "key": "6861",
    "ITA": "GUSPINI",
    "state_code": "SU",
    "cab": "439000",
    "cap": "9036"
  },
  {
    "key": "6862",
    "ITA": "GUSSAGO-MANDOLOSSA",
    "state_code": "BS",
    "cab": "545905",
    "cap": "25064"
  },
  {
    "key": "6863",
    "ITA": "GUSSAGO",
    "state_code": "BS",
    "cab": "545905",
    "cap": "25064"
  },
  {
    "key": "6864",
    "ITA": "GUSSAGO-PIAZZA",
    "state_code": "BS",
    "cab": "545905",
    "cap": "25064"
  },
  {
    "key": "6865",
    "ITA": "GUSSAGO-RONCO",
    "state_code": "BS",
    "cab": "545905",
    "cap": "25064"
  },
  {
    "key": "6866",
    "ITA": "GUSSOLA",
    "state_code": "CR",
    "cab": "568907",
    "cap": "26040"
  },
  {
    "key": "6867",
    "ITA": "HONE",
    "state_code": "AO",
    "cab": "317206",
    "cap": "11020"
  },
  {
    "key": "6868",
    "ITA": "IDRO",
    "state_code": "BS",
    "cab": "546002",
    "cap": "25074"
  },
  {
    "key": "6869",
    "ITA": "IDRO-CRONE",
    "state_code": "BS",
    "cab": "546002",
    "cap": "25074"
  },
  {
    "key": "6870",
    "ITA": "IESOLO-LIDO DI IESOLO",
    "state_code": "VE",
    "cab": "361402",
    "cap": "30016"
  },
  {
    "key": "6871",
    "ITA": "IESOLO",
    "state_code": "VE",
    "cab": "361402",
    "cap": "30016"
  },
  {
    "key": "6872",
    "ITA": "IGLESIAS-SAN BENEDETTO",
    "state_code": "SU",
    "cab": "439109",
    "cap": "9016"
  },
  {
    "key": "6873",
    "ITA": "IGLESIAS-NEBIDA",
    "state_code": "SU",
    "cab": "439109",
    "cap": "9016"
  },
  {
    "key": "6874",
    "ITA": "IGLESIAS-BINDUA",
    "state_code": "SU",
    "cab": "439109",
    "cap": "9016"
  },
  {
    "key": "6875",
    "ITA": "IGLESIAS",
    "state_code": "SU",
    "cab": "439109",
    "cap": "9016"
  },
  {
    "key": "6876",
    "ITA": "IGLESIAS-MONTEPONI",
    "state_code": "SU",
    "cab": "439109",
    "cap": "9016"
  },
  {
    "key": "6877",
    "ITA": "IGLIANO",
    "state_code": "CN",
    "cab": "918748",
    "cap": "12060"
  },
  {
    "key": "6878",
    "ITA": "ILBONO",
    "state_code": "OG",
    "cab": "866301",
    "cap": "8040"
  },
  {
    "key": "6879",
    "ITA": "ILLASI",
    "state_code": "VR",
    "cab": "594903",
    "cap": "37031"
  },
  {
    "key": "6880",
    "ITA": "ILLASI-CELLORE",
    "state_code": "VR",
    "cab": "594903",
    "cap": "37031"
  },
  {
    "key": "6881",
    "ITA": "ILLORAI",
    "state_code": "SS",
    "cab": "873703",
    "cap": "7010"
  },
  {
    "key": "6882",
    "ITA": "IMBERSAGO",
    "state_code": "LC",
    "cab": "805507",
    "cap": "23898"
  },
  {
    "key": "6883",
    "ITA": "IMER",
    "state_code": "TN",
    "cab": "348904",
    "cap": "38050"
  },
  {
    "key": "6884",
    "ITA": "IMOLA",
    "state_code": "BO",
    "cab": "210005",
    "cap": "40026"
  },
  {
    "key": "6885",
    "ITA": "IMOLA-PIRATELLO",
    "state_code": "BO",
    "cab": "210005",
    "cap": "40026"
  },
  {
    "key": "6886",
    "ITA": "IMOLA-PONTICELLI",
    "state_code": "BO",
    "cab": "210005",
    "cap": "40026"
  },
  {
    "key": "6887",
    "ITA": "IMOLA-SPAZZATE SASSATELLI",
    "state_code": "BO",
    "cab": "210005",
    "cap": "40026"
  },
  {
    "key": "6888",
    "ITA": "IMOLA-SASSO MORELLI",
    "state_code": "BO",
    "cab": "210005",
    "cap": "40026"
  },
  {
    "key": "6889",
    "ITA": "IMOLA-SESTO IMOLESE",
    "state_code": "BO",
    "cab": "210005",
    "cap": "40026"
  },
  {
    "key": "6890",
    "ITA": "IMOLA-SAN PROSPERO",
    "state_code": "BO",
    "cab": "210005",
    "cap": "40026"
  },
  {
    "key": "6891",
    "ITA": "IMPERIA",
    "state_code": "IM",
    "cab": "105007",
    "cap": "18100"
  },
  {
    "key": "6892",
    "ITA": "IMPERIA-BORGO PRIMO",
    "state_code": "IM",
    "cab": "105007",
    "cap": "18100"
  },
  {
    "key": "6893",
    "ITA": "IMPERIA-CARAMAGNA LIGURE",
    "state_code": "IM",
    "cab": "105007",
    "cap": "18100"
  },
  {
    "key": "6894",
    "ITA": "IMPERIA-CASTELVECCHIO DI SANTA MARIA MAGGIORE",
    "state_code": "IM",
    "cab": "105007",
    "cap": "18100"
  },
  {
    "key": "6895",
    "ITA": "IMPERIA-ONEGLIA",
    "state_code": "IM",
    "cab": "105007",
    "cap": "18100"
  },
  {
    "key": "6896",
    "ITA": "IMPERIA-PORTO MAURIZIO",
    "state_code": "IM",
    "cab": "105007",
    "cap": "18100"
  },
  {
    "key": "6897",
    "ITA": "IMPERIA-RIVA S. STEFANO",
    "state_code": "IM",
    "cab": "105007",
    "cap": "18018"
  },
  {
    "key": "6898",
    "ITA": "IMPRUNETA",
    "state_code": "FI",
    "cab": "379008",
    "cap": "50023"
  },
  {
    "key": "6899",
    "ITA": "IMPRUNETA-BOTTAI",
    "state_code": "FI",
    "cab": "379008",
    "cap": "50023"
  },
  {
    "key": "6900",
    "ITA": "IMPRUNETA-MONTE ORIOLO",
    "state_code": "FI",
    "cab": "379008",
    "cap": "50023"
  },
  {
    "key": "6901",
    "ITA": "IMPRUNETA-POZZOLATICO",
    "state_code": "FI",
    "cab": "379008",
    "cap": "50023"
  },
  {
    "key": "6902",
    "ITA": "IMPRUNETA-TAVARNUZZE",
    "state_code": "FI",
    "cab": "379008",
    "cap": "50023"
  },
  {
    "key": "6903",
    "ITA": "INARZO",
    "state_code": "VA",
    "cab": "924548",
    "cap": "21020"
  },
  {
    "key": "6904",
    "ITA": "INCISA IN VAL D'ARNO",
    "state_code": "FI",
    "cab": "379107",
    "cap": "50064"
  },
  {
    "key": "6905",
    "ITA": "INCISA IN VAL D'ARNO-LOPPIANO",
    "state_code": "FI",
    "cab": "379107",
    "cap": "50064"
  },
  {
    "key": "6906",
    "ITA": "INCISA SCAPACCINO",
    "state_code": "AT",
    "cab": "474601",
    "cap": "14045"
  },
  {
    "key": "6907",
    "ITA": "INCISA SCAPACCINO-GHIARE",
    "state_code": "AT",
    "cab": "474601",
    "cap": "14045"
  },
  {
    "key": "6908",
    "ITA": "INCISA SCAPACCINO-MADONNA",
    "state_code": "AT",
    "cab": "474601",
    "cap": "14045"
  },
  {
    "key": "6909",
    "ITA": "INCUDINE",
    "state_code": "BS",
    "cab": "929380",
    "cap": "25040"
  },
  {
    "key": "6910",
    "ITA": "INDUNO OLONA",
    "state_code": "VA",
    "cab": "503300",
    "cap": "21056"
  },
  {
    "key": "6911",
    "ITA": "INGRIA",
    "state_code": "TO",
    "cab": "283630",
    "cap": "10080"
  },
  {
    "key": "6912",
    "ITA": "INTRAGNA",
    "state_code": "VB",
    "cab": "917351",
    "cap": "28816"
  },
  {
    "key": "6913",
    "ITA": "INTROBIO",
    "state_code": "LC",
    "cab": "513606",
    "cap": "23815"
  },
  {
    "key": "6914",
    "ITA": "INTROD",
    "state_code": "AO",
    "cab": "902429",
    "cap": "11010"
  },
  {
    "key": "6915",
    "ITA": "INTROD-PLAN D'INTROD",
    "state_code": "AO",
    "cab": "902429",
    "cap": "11010"
  },
  {
    "key": "6916",
    "ITA": "INTRODACQUA",
    "state_code": "AQ",
    "cab": "405902",
    "cap": "67030"
  },
  {
    "key": "6917",
    "ITA": "INTROZZO",
    "state_code": "LC",
    "cab": "925685",
    "cap": "23835"
  },
  {
    "key": "6918",
    "ITA": "INVERIGO",
    "state_code": "CO",
    "cab": "513705",
    "cap": "22044"
  },
  {
    "key": "6919",
    "ITA": "INVERIGO-ROMANO' BRIANZA",
    "state_code": "CO",
    "cab": "513705",
    "cap": "22044"
  },
  {
    "key": "6920",
    "ITA": "INVERIGO-CREMNAGO",
    "state_code": "CO",
    "cab": "513705",
    "cap": "22044"
  },
  {
    "key": "6921",
    "ITA": "INVERNO E MONTELEONE",
    "state_code": "PV",
    "cab": "930545",
    "cap": "27010"
  },
  {
    "key": "6922",
    "ITA": "INVERNO E MONTELEONE-MONTELEONE",
    "state_code": "PV",
    "cab": "930545",
    "cap": "27010"
  },
  {
    "key": "6923",
    "ITA": "INVERNO E MONTELEONE-INVERNO",
    "state_code": "PV",
    "cab": "930545",
    "cap": "27010"
  },
  {
    "key": "6924",
    "ITA": "INVERSO PINASCA",
    "state_code": "TO",
    "cab": "190215",
    "cap": "10060"
  },
  {
    "key": "6925",
    "ITA": "INVERUNO",
    "state_code": "MI",
    "cab": "331900",
    "cap": "20010"
  },
  {
    "key": "6926",
    "ITA": "INVERUNO-FURATO",
    "state_code": "MI",
    "cab": "331900",
    "cap": "20010"
  },
  {
    "key": "6927",
    "ITA": "INVORIO",
    "state_code": "NO",
    "cab": "454603",
    "cap": "28045"
  },
  {
    "key": "6928",
    "ITA": "INZAGO",
    "state_code": "MI",
    "cab": "332007",
    "cap": "20065"
  },
  {
    "key": "6929",
    "ITA": "IRGOLI",
    "state_code": "NU",
    "cab": "866400",
    "cap": "8020"
  },
  {
    "key": "6930",
    "ITA": "IRMA",
    "state_code": "BS",
    "cab": "929398",
    "cap": "25061"
  },
  {
    "key": "6931",
    "ITA": "IRSINA",
    "state_code": "MT",
    "cab": "803403",
    "cap": "75022"
  },
  {
    "key": "6932",
    "ITA": "IRSINA-TACCONE",
    "state_code": "MT",
    "cab": "803403",
    "cap": "75022"
  },
  {
    "key": "6933",
    "ITA": "ISASCA",
    "state_code": "CN",
    "cab": "918755",
    "cap": "12020"
  },
  {
    "key": "6934",
    "ITA": "ISCA SULLO IONIO-ISCA MARINA",
    "state_code": "CZ",
    "cab": "913046",
    "cap": "88060"
  },
  {
    "key": "6935",
    "ITA": "ISCA SULLO IONIO",
    "state_code": "CZ",
    "cab": "913046",
    "cap": "88060"
  },
  {
    "key": "6936",
    "ITA": "ISCHIA",
    "state_code": "NA",
    "cab": "399303",
    "cap": "80077"
  },
  {
    "key": "6937",
    "ITA": "ISCHIA-ISCHIA PONTE",
    "state_code": "NA",
    "cab": "399303",
    "cap": "80077"
  },
  {
    "key": "6938",
    "ITA": "ISCHIA-SANT'ANTUONO D'ISCHIA",
    "state_code": "NA",
    "cab": "399303",
    "cap": "80077"
  },
  {
    "key": "6939",
    "ITA": "ISCHIA-ISCHIA SAN MICHELE",
    "state_code": "NA",
    "cab": "399303",
    "cap": "80077"
  },
  {
    "key": "6940",
    "ITA": "ISCHIA-PIEDIMONTE D'ISCHIA",
    "state_code": "NA",
    "cab": "399303",
    "cap": "80077"
  },
  {
    "key": "6941",
    "ITA": "ISCHIA-ISCHIA PORTO",
    "state_code": "NA",
    "cab": "399303",
    "cap": "80077"
  },
  {
    "key": "6942",
    "ITA": "ISCHIA DI CASTRO",
    "state_code": "VT",
    "cab": "731307",
    "cap": "1010"
  },
  {
    "key": "6943",
    "ITA": "ISCHITELLA-FOCE VARANO",
    "state_code": "FG",
    "cab": "784108",
    "cap": "71010"
  },
  {
    "key": "6944",
    "ITA": "ISCHITELLA",
    "state_code": "FG",
    "cab": "784108",
    "cap": "71010"
  },
  {
    "key": "6945",
    "ITA": "ISEO",
    "state_code": "BS",
    "cab": "546101",
    "cap": "25049"
  },
  {
    "key": "6946",
    "ITA": "ISEO-CLUSANE",
    "state_code": "BS",
    "cab": "546101",
    "cap": "25049"
  },
  {
    "key": "6947",
    "ITA": "ISEO-PILZONE",
    "state_code": "BS",
    "cab": "546101",
    "cap": "25049"
  },
  {
    "key": "6948",
    "ITA": "ISERA",
    "state_code": "TN",
    "cab": "349001",
    "cap": "38060"
  },
  {
    "key": "6949",
    "ITA": "ISERA-CORNALE'",
    "state_code": "TN",
    "cab": "349001",
    "cap": "38060"
  },
  {
    "key": "6950",
    "ITA": "ISERNIA",
    "state_code": "IS",
    "cab": "156000",
    "cap": "86170"
  },
  {
    "key": "6951",
    "ITA": "ISERNIA-CASTELROMANO",
    "state_code": "IS",
    "cab": "156000",
    "cap": "86170"
  },
  {
    "key": "6952",
    "ITA": "ISILI",
    "state_code": "CA",
    "cab": "853101",
    "cap": "8033"
  },
  {
    "key": "6953",
    "ITA": "ISNELLO",
    "state_code": "PA",
    "cab": "433805",
    "cap": "90010"
  },
  {
    "key": "6954",
    "ITA": "ISOLA D'ASTI",
    "state_code": "AT",
    "cab": "474700",
    "cap": "14057"
  },
  {
    "key": "6955",
    "ITA": "ISOLA D'ASTI-MOLINI D'ISOLA",
    "state_code": "AT",
    "cab": "474700",
    "cap": "14057"
  },
  {
    "key": "6956",
    "ITA": "ISOLA D'ASTI-PIANO",
    "state_code": "AT",
    "cab": "474700",
    "cap": "14057"
  },
  {
    "key": "6957",
    "ITA": "ISOLA DEL CANTONE",
    "state_code": "GE",
    "cab": "320002",
    "cap": "16017"
  },
  {
    "key": "6958",
    "ITA": "ISOLA DEL GIGLIO",
    "state_code": "GR",
    "cab": "722603",
    "cap": "58012"
  },
  {
    "key": "6959",
    "ITA": "ISOLA DEL GIGLIO-GIANNUTRI",
    "state_code": "GR",
    "cab": "722603",
    "cap": "58019"
  },
  {
    "key": "6960",
    "ITA": "ISOLA DEL GIGLIO-GIGLIO PORTO",
    "state_code": "GR",
    "cab": "722603",
    "cap": "58012"
  },
  {
    "key": "6961",
    "ITA": "ISOLA DEL GIGLIO-GIGLIO CASTELLO",
    "state_code": "GR",
    "cab": "722603",
    "cap": "58012"
  },
  {
    "key": "6962",
    "ITA": "ISOLA DEL GIGLIO-GIGLIO CAMPESE",
    "state_code": "GR",
    "cab": "722603",
    "cap": "58012"
  },
  {
    "key": "6963",
    "ITA": "ISOLA DEL GRAN SASSO D'ITALIA",
    "state_code": "TE",
    "cab": "769208",
    "cap": "64045"
  },
  {
    "key": "6964",
    "ITA": "ISOLA DEL GRAN SASSO D'ITALIA-CERCHIARA",
    "state_code": "TE",
    "cab": "769208",
    "cap": "64045"
  },
  {
    "key": "6965",
    "ITA": "ISOLA DEL GRAN SASSO D'ITALIA-SAN GABRIELE DELL'ADDOLORATA",
    "state_code": "TE",
    "cab": "769208",
    "cap": "64045"
  },
  {
    "key": "6966",
    "ITA": "ISOLA DEL GRAN SASSO D'ITALIA-FORCA DI VALLE",
    "state_code": "TE",
    "cab": "769208",
    "cap": "64045"
  },
  {
    "key": "6967",
    "ITA": "ISOLA DEL GRAN SASSO D'ITALIA-FANO A CORNO",
    "state_code": "TE",
    "cab": "769208",
    "cap": "64045"
  },
  {
    "key": "6968",
    "ITA": "ISOLA DEL LIRI",
    "state_code": "FR",
    "cab": "744706",
    "cap": "3036"
  },
  {
    "key": "6969",
    "ITA": "ISOLA DEL PIANO",
    "state_code": "PU",
    "cab": "782003",
    "cap": "61030"
  },
  {
    "key": "6970",
    "ITA": "ISOLA DELLA SCALA",
    "state_code": "VR",
    "cab": "595009",
    "cap": "37063"
  },
  {
    "key": "6971",
    "ITA": "ISOLA DELLA SCALA-PELLEGRINA",
    "state_code": "VR",
    "cab": "595009",
    "cap": "37063"
  },
  {
    "key": "6972",
    "ITA": "ISOLA DELLA SCALA-TARMASSIA",
    "state_code": "VR",
    "cab": "595009",
    "cap": "37063"
  },
  {
    "key": "6973",
    "ITA": "ISOLA DELLE FEMMINE",
    "state_code": "PA",
    "cab": "433904",
    "cap": "90040"
  },
  {
    "key": "6974",
    "ITA": "ISOLA DI CAPO RIZZUTO",
    "state_code": "KR",
    "cab": "426106",
    "cap": "88841"
  },
  {
    "key": "6975",
    "ITA": "ISOLA DI CAPO RIZZUTO-LE CASTELLA",
    "state_code": "KR",
    "cab": "426106",
    "cap": "88841"
  },
  {
    "key": "6976",
    "ITA": "ISOLA DI CAPO RIZZUTO-SANT'ANNA",
    "state_code": "KR",
    "cab": "426106",
    "cap": "88841"
  },
  {
    "key": "6977",
    "ITA": "ISOLA DI FONDRA",
    "state_code": "BG",
    "cab": "928218",
    "cap": "24010"
  },
  {
    "key": "6978",
    "ITA": "ISOLA DI FONDRA-FONDRA",
    "state_code": "BG",
    "cab": "928218",
    "cap": "24010"
  },
  {
    "key": "6979",
    "ITA": "ISOLA DOVARESE",
    "state_code": "CR",
    "cab": "569004",
    "cap": "26031"
  },
  {
    "key": "6980",
    "ITA": "ISOLA RIZZA",
    "state_code": "VR",
    "cab": "595108",
    "cap": "37050"
  },
  {
    "key": "6981",
    "ITA": "ISOLA SANT'ANTONIO",
    "state_code": "AL",
    "cab": "483305",
    "cap": "15050"
  },
  {
    "key": "6982",
    "ITA": "ISOLA VICENTINA-CASTELNOVO",
    "state_code": "VI",
    "cab": "604306",
    "cap": "36033"
  },
  {
    "key": "6983",
    "ITA": "ISOLA VICENTINA",
    "state_code": "VI",
    "cab": "604306",
    "cap": "36033"
  },
  {
    "key": "6984",
    "ITA": "ISOLABELLA",
    "state_code": "TO",
    "cab": "900688",
    "cap": "10046"
  },
  {
    "key": "6985",
    "ITA": "ISOLABONA",
    "state_code": "IM",
    "cab": "922443",
    "cap": "18035"
  },
  {
    "key": "6986",
    "ITA": "ISOLE TREMITI-SAN DOMINO",
    "state_code": "FG",
    "cab": "784207",
    "cap": "71040"
  },
  {
    "key": "6987",
    "ITA": "ISOLE TREMITI-SAN NICOLA DI TREMITI",
    "state_code": "FG",
    "cab": "784207",
    "cap": "71040"
  },
  {
    "key": "6988",
    "ITA": "ISOLE TREMITI",
    "state_code": "FG",
    "cab": "784207",
    "cap": "71040"
  },
  {
    "key": "6989",
    "ITA": "ISORELLA",
    "state_code": "BS",
    "cab": "546200",
    "cap": "25010"
  },
  {
    "key": "6990",
    "ITA": "ISPANI-CAPITELLO",
    "state_code": "SA",
    "cab": "952572",
    "cap": "84050"
  },
  {
    "key": "6991",
    "ITA": "ISPANI",
    "state_code": "SA",
    "cab": "952572",
    "cap": "84050"
  },
  {
    "key": "6992",
    "ITA": "ISPICA",
    "state_code": "RG",
    "cab": "844704",
    "cap": "97014"
  },
  {
    "key": "6993",
    "ITA": "ISPRA-BARZA",
    "state_code": "VA",
    "cab": "503409",
    "cap": "21027"
  },
  {
    "key": "6994",
    "ITA": "ISPRA-ISPRA CENTRO EURATOM",
    "state_code": "VA",
    "cab": "503409",
    "cap": "21027"
  },
  {
    "key": "6995",
    "ITA": "ISPRA",
    "state_code": "VA",
    "cab": "503409",
    "cap": "21027"
  },
  {
    "key": "6996",
    "ITA": "ISSIGLIO",
    "state_code": "TO",
    "cab": "283663",
    "cap": "10080"
  },
  {
    "key": "6997",
    "ITA": "ISSIME",
    "state_code": "AO",
    "cab": "902437",
    "cap": "11020"
  },
  {
    "key": "6998",
    "ITA": "ISSO",
    "state_code": "BG",
    "cab": "805408",
    "cap": "24040"
  },
  {
    "key": "6999",
    "ITA": "ISSOGNE",
    "state_code": "AO",
    "cab": "317701",
    "cap": "11020"
  },
  {
    "key": "7000",
    "ITA": "ISSOGNE-LA PLACE",
    "state_code": "AO",
    "cab": "317701",
    "cap": "11020"
  },
  {
    "key": "7001",
    "ITA": "ISTRANA",
    "state_code": "TV",
    "cab": "617407",
    "cap": "31036"
  },
  {
    "key": "7002",
    "ITA": "ISTRANA-SALA",
    "state_code": "TV",
    "cab": "617407",
    "cap": "31036"
  },
  {
    "key": "7003",
    "ITA": "ISTRANA-PEZZAN",
    "state_code": "TV",
    "cab": "617407",
    "cap": "31036"
  },
  {
    "key": "7004",
    "ITA": "ISTRANA-OSPEDALETTO",
    "state_code": "TV",
    "cab": "617407",
    "cap": "31036"
  },
  {
    "key": "7005",
    "ITA": "ITALA",
    "state_code": "ME",
    "cab": "827303",
    "cap": "98025"
  },
  {
    "key": "7006",
    "ITA": "ITALA-ITALA MARINA",
    "state_code": "ME",
    "cab": "827303",
    "cap": "98025"
  },
  {
    "key": "7007",
    "ITA": "ITRI",
    "state_code": "LT",
    "cab": "740001",
    "cap": "4020"
  },
  {
    "key": "7008",
    "ITA": "ITTIREDDU",
    "state_code": "SS",
    "cab": "873802",
    "cap": "7010"
  },
  {
    "key": "7009",
    "ITA": "ITTIRI",
    "state_code": "SS",
    "cab": "849505",
    "cap": "7044"
  },
  {
    "key": "7010",
    "ITA": "IVANO FRACENA",
    "state_code": "TN",
    "cab": "904912",
    "cap": "38059"
  },
  {
    "key": "7011",
    "ITA": "IVREA",
    "state_code": "TO",
    "cab": "305409",
    "cap": "10015"
  },
  {
    "key": "7012",
    "ITA": "IVREA-SAN BERNARDO D'IVREA",
    "state_code": "TO",
    "cab": "305409",
    "cap": "10015"
  },
  {
    "key": "7013",
    "ITA": "IVREA-TORRE BALFREDO",
    "state_code": "TO",
    "cab": "305409",
    "cap": "10015"
  },
  {
    "key": "7014",
    "ITA": "IZANO",
    "state_code": "CR",
    "cab": "573501",
    "cap": "26010"
  },
  {
    "key": "7015",
    "ITA": "JACURSO",
    "state_code": "CZ",
    "cab": "913053",
    "cap": "88020"
  },
  {
    "key": "7016",
    "ITA": "JELSI",
    "state_code": "CB",
    "cab": "412007",
    "cap": "86015"
  },
  {
    "key": "7017",
    "ITA": "JENNE",
    "state_code": "RM",
    "cab": "908160",
    "cap": "20"
  },
  {
    "key": "7018",
    "ITA": "JERAGO CON ORAGO",
    "state_code": "VA",
    "cab": "503508",
    "cap": "21040"
  },
  {
    "key": "7019",
    "ITA": "JERZU",
    "state_code": "OG",
    "cab": "853200",
    "cap": "8044"
  },
  {
    "key": "7020",
    "ITA": "JESI",
    "state_code": "AN",
    "cab": "212001",
    "cap": "60035"
  },
  {
    "key": "7021",
    "ITA": "JOLANDA DI SAVOIA",
    "state_code": "FE",
    "cab": "672303",
    "cap": "44037"
  },
  {
    "key": "7022",
    "ITA": "JONADI-NAO",
    "state_code": "VV",
    "cab": "190934",
    "cap": "89851"
  },
  {
    "key": "7023",
    "ITA": "JONADI",
    "state_code": "VV",
    "cab": "190934",
    "cap": "89851"
  },
  {
    "key": "7024",
    "ITA": "JOPPOLO-CARONITI",
    "state_code": "VV",
    "cab": "913061",
    "cap": "89863"
  },
  {
    "key": "7025",
    "ITA": "JOPPOLO-COCCORINO",
    "state_code": "VV",
    "cab": "913061",
    "cap": "89863"
  },
  {
    "key": "7026",
    "ITA": "JOPPOLO",
    "state_code": "VV",
    "cab": "913061",
    "cap": "89863"
  },
  {
    "key": "7027",
    "ITA": "JOPPOLO GIANCAXIO",
    "state_code": "AG",
    "cab": "829507",
    "cap": "92010"
  },
  {
    "key": "7028",
    "ITA": "JOVENCAN",
    "state_code": "AO",
    "cab": "902452",
    "cap": "11020"
  },
  {
    "key": "7029",
    "ITA": "L'AQUILA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7030",
    "ITA": "L'AQUILA-TEMPERA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7031",
    "ITA": "L'AQUILA-SASSA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7032",
    "ITA": "L'AQUILA-SANTI",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7033",
    "ITA": "L'AQUILA-SAN VITTORINO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7034",
    "ITA": "L'AQUILA-ARAGNO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7035",
    "ITA": "L'AQUILA-ARISCHIA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7036",
    "ITA": "L'AQUILA-ASSERGI",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7037",
    "ITA": "L'AQUILA-BAGNO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7038",
    "ITA": "L'AQUILA-BAZZANO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7039",
    "ITA": "L'AQUILA-CAMARDA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7040",
    "ITA": "L'AQUILA-CAMPO IMPERATORE",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7041",
    "ITA": "L'AQUILA-COLLE DI ROIO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7042",
    "ITA": "L'AQUILA-COLLEBRINCIONI",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7043",
    "ITA": "L'AQUILA-COPPITO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7044",
    "ITA": "L'AQUILA-FORCELLA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7045",
    "ITA": "L'AQUILA-MONTICCHIO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7046",
    "ITA": "L'AQUILA-ONNA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7047",
    "ITA": "L'AQUILA-PAGANICA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7048",
    "ITA": "L'AQUILA-PAGLIARE",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7049",
    "ITA": "L'AQUILA-PIANOLA",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7050",
    "ITA": "L'AQUILA-PILE",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7051",
    "ITA": "L'AQUILA-POGGIO DI ROIO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7052",
    "ITA": "L'AQUILA-PRETURO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7053",
    "ITA": "L'AQUILA-ROIO PIANO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7054",
    "ITA": "L'AQUILA-SAN BENEDETTO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7055",
    "ITA": "L'AQUILA-SAN GREGORIO",
    "state_code": "AQ",
    "cab": "36004",
    "cap": "67100"
  },
  {
    "key": "7056",
    "ITA": "LA CASSA",
    "state_code": "TO",
    "cab": "284778",
    "cap": "10040"
  },
  {
    "key": "7057",
    "ITA": "LA LOGGIA",
    "state_code": "TO",
    "cab": "305508",
    "cap": "10040"
  },
  {
    "key": "7058",
    "ITA": "LA MADDALENA",
    "state_code": "OT",
    "cab": "849604",
    "cap": "7024"
  },
  {
    "key": "7059",
    "ITA": "LA MADDALENA-MONETA",
    "state_code": "OT",
    "cab": "849604",
    "cap": "7024"
  },
  {
    "key": "7060",
    "ITA": "LA MADDALENA-ISOLA DI CAPRERA",
    "state_code": "OT",
    "cab": "849604",
    "cap": "7024"
  },
  {
    "key": "7061",
    "ITA": "LA MAGDELEINE",
    "state_code": "AO",
    "cab": "902460",
    "cap": "11020"
  },
  {
    "key": "7062",
    "ITA": "LA MORRA-RIVALTA",
    "state_code": "CN",
    "cab": "464008",
    "cap": "12064"
  },
  {
    "key": "7063",
    "ITA": "LA MORRA",
    "state_code": "CN",
    "cab": "464008",
    "cap": "12064"
  },
  {
    "key": "7064",
    "ITA": "LA SALLE",
    "state_code": "AO",
    "cab": "317602",
    "cap": "11015"
  },
  {
    "key": "7065",
    "ITA": "LA SALLE-DERBY",
    "state_code": "AO",
    "cab": "317602",
    "cap": "11015"
  },
  {
    "key": "7066",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19123"
  },
  {
    "key": "7067",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19136"
  },
  {
    "key": "7068",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19135"
  },
  {
    "key": "7069",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19134"
  },
  {
    "key": "7070",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19133"
  },
  {
    "key": "7071",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19132"
  },
  {
    "key": "7072",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19131"
  },
  {
    "key": "7073",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19126"
  },
  {
    "key": "7074",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19121"
  },
  {
    "key": "7075",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19122"
  },
  {
    "key": "7076",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19137"
  },
  {
    "key": "7077",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19124"
  },
  {
    "key": "7078",
    "ITA": "LA SPEZIA",
    "state_code": "SP",
    "cab": "107003",
    "cap": "19125"
  },
  {
    "key": "7079",
    "ITA": "LA THUILE",
    "state_code": "AO",
    "cab": "316109",
    "cap": "11016"
  },
  {
    "key": "7080",
    "ITA": "LA VALLE-PEDEROA",
    "state_code": "BZ",
    "cab": "591305",
    "cap": "39030"
  },
  {
    "key": "7081",
    "ITA": "LA VALLE",
    "state_code": "BZ",
    "cab": "591305",
    "cap": "39030"
  },
  {
    "key": "7082",
    "ITA": "LA VALLE AGORDINA",
    "state_code": "BL",
    "cab": "934687",
    "cap": "32020"
  },
  {
    "key": "7083",
    "ITA": "LA VALLETTA BRIANZA",
    "state_code": "LC",
    "cab": "238880",
    "cap": "23888"
  },
  {
    "key": "7084",
    "ITA": "LABICO",
    "state_code": "RM",
    "cab": "884809",
    "cap": "30"
  },
  {
    "key": "7085",
    "ITA": "LABRO",
    "state_code": "RI",
    "cab": "947218",
    "cap": "2010"
  },
  {
    "key": "7086",
    "ITA": "LACCHIARELLA",
    "state_code": "MI",
    "cab": "332106",
    "cap": "20084"
  },
  {
    "key": "7087",
    "ITA": "LACCO AMENO",
    "state_code": "NA",
    "cab": "399402",
    "cap": "80076"
  },
  {
    "key": "7088",
    "ITA": "LACEDONIA",
    "state_code": "AV",
    "cab": "757401",
    "cap": "83046"
  },
  {
    "key": "7089",
    "ITA": "LACES-COLDRANO",
    "state_code": "BZ",
    "cab": "584508",
    "cap": "39021"
  },
  {
    "key": "7090",
    "ITA": "LACES-TARRES",
    "state_code": "BZ",
    "cab": "584508",
    "cap": "39021"
  },
  {
    "key": "7091",
    "ITA": "LACES-MORTER",
    "state_code": "BZ",
    "cab": "584508",
    "cap": "39021"
  },
  {
    "key": "7092",
    "ITA": "LACES",
    "state_code": "BZ",
    "cab": "584508",
    "cap": "39021"
  },
  {
    "key": "7093",
    "ITA": "LACONI-SU LAU",
    "state_code": "OR",
    "cab": "866509",
    "cap": "8034"
  },
  {
    "key": "7094",
    "ITA": "LACONI-TRAIDODINI",
    "state_code": "OR",
    "cab": "866509",
    "cap": "8034"
  },
  {
    "key": "7095",
    "ITA": "LACONI",
    "state_code": "OR",
    "cab": "866509",
    "cap": "8034"
  },
  {
    "key": "7096",
    "ITA": "LACONI-CRASTU",
    "state_code": "OR",
    "cab": "866509",
    "cap": "8034"
  },
  {
    "key": "7097",
    "ITA": "LACONI-SANTA SOFIA",
    "state_code": "OR",
    "cab": "866509",
    "cap": "8034"
  },
  {
    "key": "7098",
    "ITA": "LADISPOLI-MARINA SAN NICOLA",
    "state_code": "RM",
    "cab": "395301",
    "cap": "55"
  },
  {
    "key": "7099",
    "ITA": "LADISPOLI-PALO",
    "state_code": "RM",
    "cab": "395301",
    "cap": "55"
  },
  {
    "key": "7100",
    "ITA": "LADISPOLI",
    "state_code": "RM",
    "cab": "395301",
    "cap": "55"
  },
  {
    "key": "7101",
    "ITA": "LAERRU",
    "state_code": "SS",
    "cab": "873901",
    "cap": "7030"
  },
  {
    "key": "7102",
    "ITA": "LAGANADI",
    "state_code": "RC",
    "cab": "960179",
    "cap": "89050"
  },
  {
    "key": "7103",
    "ITA": "LAGHI",
    "state_code": "VI",
    "cab": "933929",
    "cap": "36010"
  },
  {
    "key": "7104",
    "ITA": "LAGLIO",
    "state_code": "CO",
    "cab": "925693",
    "cap": "22010"
  },
  {
    "key": "7105",
    "ITA": "LAGNASCO",
    "state_code": "CN",
    "cab": "463901",
    "cap": "12030"
  },
  {
    "key": "7106",
    "ITA": "LAGO-GRECI",
    "state_code": "CS",
    "cab": "807701",
    "cap": "87035"
  },
  {
    "key": "7107",
    "ITA": "LAGO-TERRATI",
    "state_code": "CS",
    "cab": "807701",
    "cap": "87035"
  },
  {
    "key": "7108",
    "ITA": "LAGO-ARIA DI LUPI",
    "state_code": "CS",
    "cab": "807701",
    "cap": "87035"
  },
  {
    "key": "7109",
    "ITA": "LAGO",
    "state_code": "CS",
    "cab": "807701",
    "cap": "87035"
  },
  {
    "key": "7110",
    "ITA": "LAGONEGRO",
    "state_code": "PZ",
    "cab": "420109",
    "cap": "85042"
  },
  {
    "key": "7111",
    "ITA": "LAGOSANTO-MAROZZO",
    "state_code": "FE",
    "cab": "672402",
    "cap": "44023"
  },
  {
    "key": "7112",
    "ITA": "LAGOSANTO",
    "state_code": "FE",
    "cab": "672402",
    "cap": "44023"
  },
  {
    "key": "7113",
    "ITA": "LAGUNDO",
    "state_code": "BZ",
    "cab": "584607",
    "cap": "39022"
  },
  {
    "key": "7114",
    "ITA": "LAIGUEGLIA",
    "state_code": "SV",
    "cab": "494203",
    "cap": "17053"
  },
  {
    "key": "7115",
    "ITA": "LAINATE-BARBAIANA",
    "state_code": "MI",
    "cab": "332205",
    "cap": "20020"
  },
  {
    "key": "7116",
    "ITA": "LAINATE",
    "state_code": "MI",
    "cab": "332205",
    "cap": "20020"
  },
  {
    "key": "7117",
    "ITA": "LAINO",
    "state_code": "CO",
    "cab": "925701",
    "cap": "22020"
  },
  {
    "key": "7118",
    "ITA": "LAINO BORGO",
    "state_code": "CS",
    "cab": "716803",
    "cap": "87014"
  },
  {
    "key": "7119",
    "ITA": "LAINO CASTELLO",
    "state_code": "CS",
    "cab": "958926",
    "cap": "87015"
  },
  {
    "key": "7120",
    "ITA": "LAION",
    "state_code": "BZ",
    "cab": "584706",
    "cap": "39040"
  },
  {
    "key": "7121",
    "ITA": "LAION-SAN PIETRO LAION",
    "state_code": "BZ",
    "cab": "584706",
    "cap": "39040"
  },
  {
    "key": "7122",
    "ITA": "LAIVES",
    "state_code": "BZ",
    "cab": "584805",
    "cap": "39055"
  },
  {
    "key": "7123",
    "ITA": "LAIVES-SAN GIACOMO",
    "state_code": "BZ",
    "cab": "584805",
    "cap": "39055"
  },
  {
    "key": "7124",
    "ITA": "LAIVES-PINETA",
    "state_code": "BZ",
    "cab": "584805",
    "cap": "39055"
  },
  {
    "key": "7125",
    "ITA": "LAIVES-LA COSTA",
    "state_code": "BZ",
    "cab": "584805",
    "cap": "39055"
  },
  {
    "key": "7126",
    "ITA": "LAJATICO",
    "state_code": "PI",
    "cab": "710301",
    "cap": "56030"
  },
  {
    "key": "7127",
    "ITA": "LAJATICO-ORCIATICO",
    "state_code": "PI",
    "cab": "710301",
    "cap": "56030"
  },
  {
    "key": "7128",
    "ITA": "LALLIO",
    "state_code": "BG",
    "cab": "531509",
    "cap": "24040"
  },
  {
    "key": "7129",
    "ITA": "LALLIO-GRUMELLO DEL PIANO",
    "state_code": "BG",
    "cab": "531509",
    "cap": "24040"
  },
  {
    "key": "7130",
    "ITA": "LAMA DEI PELIGNI",
    "state_code": "CH",
    "cab": "777409",
    "cap": "66010"
  },
  {
    "key": "7131",
    "ITA": "LAMA MOCOGNO",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7132",
    "ITA": "LAMA MOCOGNO-BARIGAZZO",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7133",
    "ITA": "LAMA MOCOGNO-CADIGNANO",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7134",
    "ITA": "LAMA MOCOGNO-LA SANTONA",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7135",
    "ITA": "LAMA MOCOGNO-VAGLIO",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7136",
    "ITA": "LAMA MOCOGNO-MOCOGNO",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7137",
    "ITA": "LAMA MOCOGNO-MONTECENERE",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7138",
    "ITA": "LAMA MOCOGNO-PIANORSO",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7139",
    "ITA": "LAMA MOCOGNO-SASSOSTORNO",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7140",
    "ITA": "LAMA MOCOGNO-LAMA",
    "state_code": "MO",
    "cab": "668103",
    "cap": "41023"
  },
  {
    "key": "7141",
    "ITA": "LAMBRUGO",
    "state_code": "CO",
    "cab": "513804",
    "cap": "22045"
  },
  {
    "key": "7142",
    "ITA": "LAMEZIA TERME",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7143",
    "ITA": "LAMEZIA TERME-ACQUAFREDDA",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7144",
    "ITA": "LAMEZIA TERME-BELLA DI LAMEZIA TERME",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7145",
    "ITA": "LAMEZIA TERME-CARONTE",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7146",
    "ITA": "LAMEZIA TERME-FRONTI",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7147",
    "ITA": "LAMEZIA TERME-ZANGARONA",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7148",
    "ITA": "LAMEZIA TERME-NICASTRO",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7149",
    "ITA": "LAMEZIA TERME-SAMBIASE",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7150",
    "ITA": "LAMEZIA TERME-SAN PIETRO LAMETINO",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7151",
    "ITA": "LAMEZIA TERME-SANTA EUFEMIA LAMEZIA",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7152",
    "ITA": "LAMEZIA TERME-GABELLA",
    "state_code": "CZ",
    "cab": "428409",
    "cap": "88046"
  },
  {
    "key": "7153",
    "ITA": "LAMON-ARINA",
    "state_code": "BL",
    "cab": "611400",
    "cap": "32033"
  },
  {
    "key": "7154",
    "ITA": "LAMON",
    "state_code": "BL",
    "cab": "611400",
    "cap": "32033"
  },
  {
    "key": "7155",
    "ITA": "LAMPEDUSA E LINOSA-LAMPEDUSA",
    "state_code": "AG",
    "cab": "829606",
    "cap": "92010"
  },
  {
    "key": "7156",
    "ITA": "LAMPEDUSA E LINOSA-LINOSA",
    "state_code": "AG",
    "cab": "829606",
    "cap": "92010"
  },
  {
    "key": "7157",
    "ITA": "LAMPEDUSA E LINOSA",
    "state_code": "AG",
    "cab": "829606",
    "cap": "92010"
  },
  {
    "key": "7158",
    "ITA": "LAMPORECCHIO",
    "state_code": "PT",
    "cab": "704007",
    "cap": "51035"
  },
  {
    "key": "7159",
    "ITA": "LAMPORECCHIO-MASTROMARCO",
    "state_code": "PT",
    "cab": "704007",
    "cap": "51035"
  },
  {
    "key": "7160",
    "ITA": "LAMPORECCHIO-ORBIGNANO",
    "state_code": "PT",
    "cab": "704007",
    "cap": "51035"
  },
  {
    "key": "7161",
    "ITA": "LAMPORECCHIO-PORCIANO",
    "state_code": "PT",
    "cab": "704007",
    "cap": "51035"
  },
  {
    "key": "7162",
    "ITA": "LAMPORECCHIO-SAN BARONTO",
    "state_code": "PT",
    "cab": "704007",
    "cap": "51035"
  },
  {
    "key": "7163",
    "ITA": "LAMPORO",
    "state_code": "VC",
    "cab": "445205",
    "cap": "13046"
  },
  {
    "key": "7164",
    "ITA": "LANA-PAVICOLO",
    "state_code": "BZ",
    "cab": "584904",
    "cap": "39011"
  },
  {
    "key": "7165",
    "ITA": "LANA",
    "state_code": "BZ",
    "cab": "584904",
    "cap": "39011"
  },
  {
    "key": "7166",
    "ITA": "LANA-MONTE SAN VIGILIO",
    "state_code": "BZ",
    "cab": "584904",
    "cap": "39011"
  },
  {
    "key": "7167",
    "ITA": "LANCIANO",
    "state_code": "CH",
    "cab": "777508",
    "cap": "66034"
  },
  {
    "key": "7168",
    "ITA": "LANCIANO-SANT'AMATO",
    "state_code": "CH",
    "cab": "777508",
    "cap": "66034"
  },
  {
    "key": "7169",
    "ITA": "LANCIANO-RIZZACORNO",
    "state_code": "CH",
    "cab": "777508",
    "cap": "66034"
  },
  {
    "key": "7170",
    "ITA": "LANCIANO-VILLA ELCE",
    "state_code": "CH",
    "cab": "777508",
    "cap": "66034"
  },
  {
    "key": "7171",
    "ITA": "LANCIANO-MADONNA DEL CARMINE",
    "state_code": "CH",
    "cab": "777508",
    "cap": "66034"
  },
  {
    "key": "7172",
    "ITA": "LANCIANO-NASUTI",
    "state_code": "CH",
    "cab": "777508",
    "cap": "66034"
  },
  {
    "key": "7173",
    "ITA": "LANDIONA",
    "state_code": "NO",
    "cab": "917369",
    "cap": "28064"
  },
  {
    "key": "7174",
    "ITA": "LANDRIANO",
    "state_code": "PV",
    "cab": "559302",
    "cap": "27015"
  },
  {
    "key": "7175",
    "ITA": "LANGHIRANO-COZZANO",
    "state_code": "PR",
    "cab": "657700",
    "cap": "43013"
  },
  {
    "key": "7176",
    "ITA": "LANGHIRANO-PASTORELLO",
    "state_code": "PR",
    "cab": "657700",
    "cap": "43013"
  },
  {
    "key": "7177",
    "ITA": "LANGHIRANO-PILASTRO",
    "state_code": "PR",
    "cab": "657700",
    "cap": "43010"
  },
  {
    "key": "7178",
    "ITA": "LANGHIRANO-RIANO",
    "state_code": "PR",
    "cab": "657700",
    "cap": "43013"
  },
  {
    "key": "7179",
    "ITA": "LANGHIRANO",
    "state_code": "PR",
    "cab": "657700",
    "cap": "43013"
  },
  {
    "key": "7180",
    "ITA": "LANGHIRANO-TORRECHIARA",
    "state_code": "PR",
    "cab": "657700",
    "cap": "43010"
  },
  {
    "key": "7181",
    "ITA": "LANGOSCO",
    "state_code": "PV",
    "cab": "559401",
    "cap": "27030"
  },
  {
    "key": "7182",
    "ITA": "LANUSEI",
    "state_code": "OG",
    "cab": "853309",
    "cap": "8045"
  },
  {
    "key": "7183",
    "ITA": "LANUVIO-PASCOLARE",
    "state_code": "RM",
    "cab": "391607",
    "cap": "40"
  },
  {
    "key": "7184",
    "ITA": "LANUVIO",
    "state_code": "RM",
    "cab": "391607",
    "cap": "40"
  },
  {
    "key": "7185",
    "ITA": "LANZADA",
    "state_code": "SO",
    "cab": "521906",
    "cap": "23020"
  },
  {
    "key": "7186",
    "ITA": "LANZO D'INTELVI-SCARIA",
    "state_code": "CO",
    "cab": "513903",
    "cap": "22024"
  },
  {
    "key": "7187",
    "ITA": "LANZO D'INTELVI",
    "state_code": "CO",
    "cab": "513903",
    "cap": "22024"
  },
  {
    "key": "7188",
    "ITA": "LANZO TORINESE",
    "state_code": "TO",
    "cab": "305607",
    "cap": "10074"
  },
  {
    "key": "7189",
    "ITA": "LAPEDONA",
    "state_code": "FM",
    "cab": "489401",
    "cap": "63026"
  },
  {
    "key": "7190",
    "ITA": "LAPIO",
    "state_code": "AV",
    "cab": "951194",
    "cap": "83030"
  },
  {
    "key": "7191",
    "ITA": "LAPPANO",
    "state_code": "CS",
    "cab": "958934",
    "cap": "87050"
  },
  {
    "key": "7192",
    "ITA": "LARCIANO",
    "state_code": "PT",
    "cab": "704106",
    "cap": "51036"
  },
  {
    "key": "7193",
    "ITA": "LARCIANO-CASTELMARTINI",
    "state_code": "PT",
    "cab": "704106",
    "cap": "51036"
  },
  {
    "key": "7194",
    "ITA": "LARCIANO-SAN ROCCO",
    "state_code": "PT",
    "cab": "704106",
    "cap": "51036"
  },
  {
    "key": "7195",
    "ITA": "LARDARO",
    "state_code": "TN",
    "cab": "904920",
    "cap": "38087"
  },
  {
    "key": "7196",
    "ITA": "LARDIRAGO",
    "state_code": "PV",
    "cab": "559500",
    "cap": "27016"
  },
  {
    "key": "7197",
    "ITA": "LARI-LAVAIANO",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7198",
    "ITA": "LARI",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7199",
    "ITA": "LARI-CASCIANA ALTA",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7200",
    "ITA": "LARI-CEVOLI",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7201",
    "ITA": "LARI-USIGLIANO",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7202",
    "ITA": "LARI-LE CASINE",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7203",
    "ITA": "LARI-PERIGNANO",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7204",
    "ITA": "LARI-SPINELLI",
    "state_code": "PI",
    "cab": "252007",
    "cap": "56035"
  },
  {
    "key": "7205",
    "ITA": "LARIANO",
    "state_code": "RM",
    "cab": "395202",
    "cap": "40"
  },
  {
    "key": "7206",
    "ITA": "LARINO",
    "state_code": "CB",
    "cab": "410506",
    "cap": "86035"
  },
  {
    "key": "7207",
    "ITA": "LAS PLASSAS",
    "state_code": "SU",
    "cab": "914739",
    "cap": "9020"
  },
  {
    "key": "7208",
    "ITA": "LASA",
    "state_code": "BZ",
    "cab": "585000",
    "cap": "39023"
  },
  {
    "key": "7209",
    "ITA": "LASA-TANAS",
    "state_code": "BZ",
    "cab": "585000",
    "cap": "39023"
  },
  {
    "key": "7210",
    "ITA": "LASA-ORIS",
    "state_code": "BZ",
    "cab": "585000",
    "cap": "39023"
  },
  {
    "key": "7211",
    "ITA": "LASA-CENGLES",
    "state_code": "BZ",
    "cab": "585000",
    "cap": "39023"
  },
  {
    "key": "7212",
    "ITA": "LASCARI",
    "state_code": "PA",
    "cab": "434001",
    "cap": "90010"
  },
  {
    "key": "7213",
    "ITA": "LASCARI-LASCARI SCALO",
    "state_code": "PA",
    "cab": "434001",
    "cap": "90010"
  },
  {
    "key": "7214",
    "ITA": "LASINO-MADRUZZO",
    "state_code": "TN",
    "cab": "349100",
    "cap": "38076"
  },
  {
    "key": "7215",
    "ITA": "LASINO-CASTEL MADRUZZO",
    "state_code": "TN",
    "cab": "349100",
    "cap": "38076"
  },
  {
    "key": "7216",
    "ITA": "LASINO",
    "state_code": "TN",
    "cab": "349100",
    "cap": "38076"
  },
  {
    "key": "7217",
    "ITA": "LASNIGO",
    "state_code": "CO",
    "cab": "925719",
    "cap": "22030"
  },
  {
    "key": "7218",
    "ITA": "LASTEBASSE",
    "state_code": "VI",
    "cab": "933937",
    "cap": "36040"
  },
  {
    "key": "7219",
    "ITA": "LASTRA A SIGNA-GINESTRA FIORENTINA",
    "state_code": "FI",
    "cab": "379206",
    "cap": "50055"
  },
  {
    "key": "7220",
    "ITA": "LASTRA A SIGNA",
    "state_code": "FI",
    "cab": "379206",
    "cap": "50055"
  },
  {
    "key": "7221",
    "ITA": "LASTRA A SIGNA-BRUCIANESI",
    "state_code": "FI",
    "cab": "379206",
    "cap": "50055"
  },
  {
    "key": "7222",
    "ITA": "LASTRA A SIGNA-PORTO DI MEZZO",
    "state_code": "FI",
    "cab": "379206",
    "cap": "50055"
  },
  {
    "key": "7223",
    "ITA": "LASTRA A SIGNA-MALMANTILE",
    "state_code": "FI",
    "cab": "379206",
    "cap": "50055"
  },
  {
    "key": "7224",
    "ITA": "LASTRA A SIGNA-PONTE A SIGNA",
    "state_code": "FI",
    "cab": "379206",
    "cap": "50055"
  },
  {
    "key": "7225",
    "ITA": "LATERA",
    "state_code": "VT",
    "cab": "733808",
    "cap": "1010"
  },
  {
    "key": "7226",
    "ITA": "LATERINA",
    "state_code": "AR",
    "cab": "714709",
    "cap": "52020"
  },
  {
    "key": "7227",
    "ITA": "LATERINA-PONTICINO",
    "state_code": "AR",
    "cab": "714709",
    "cap": "52020"
  },
  {
    "key": "7228",
    "ITA": "LATERZA",
    "state_code": "TA",
    "cab": "789008",
    "cap": "74014"
  },
  {
    "key": "7229",
    "ITA": "LATIANO",
    "state_code": "BR",
    "cab": "792002",
    "cap": "72022"
  },
  {
    "key": "7230",
    "ITA": "LATINA-BORGO PIAVE",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7231",
    "ITA": "LATINA-LE FERRIERE",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7232",
    "ITA": "LATINA",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7233",
    "ITA": "LATINA-BORGO BAINSIZZA",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7234",
    "ITA": "LATINA-BORGO CARSO",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4010"
  },
  {
    "key": "7235",
    "ITA": "LATINA-BORGO FAITI",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7236",
    "ITA": "LATINA-BORGO GRAPPA",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7237",
    "ITA": "LATINA-BORGO ISONZO",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7238",
    "ITA": "LATINA-BORGO MONTELLO",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7239",
    "ITA": "LATINA-TOR TRE PONTI",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4013"
  },
  {
    "key": "7240",
    "ITA": "LATINA-BORGO PODGORA",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4010"
  },
  {
    "key": "7241",
    "ITA": "LATINA-BORGO SABOTINO",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7242",
    "ITA": "LATINA-BORGO SAN MICHELE",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7243",
    "ITA": "LATINA-FOCE VERDE",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7244",
    "ITA": "LATINA-FOGLIANO",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7245",
    "ITA": "LATINA-FORO APPIO",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4100"
  },
  {
    "key": "7246",
    "ITA": "LATINA-LATINA SCALO",
    "state_code": "LT",
    "cab": "147009",
    "cap": "4013"
  },
  {
    "key": "7247",
    "ITA": "LATISANA-PERTEGADA",
    "state_code": "UD",
    "cab": "639005",
    "cap": "33050"
  },
  {
    "key": "7248",
    "ITA": "LATISANA-GORGO",
    "state_code": "UD",
    "cab": "639005",
    "cap": "33053"
  },
  {
    "key": "7249",
    "ITA": "LATISANA",
    "state_code": "UD",
    "cab": "639005",
    "cap": "33053"
  },
  {
    "key": "7250",
    "ITA": "LATRONICO-MAGNANO",
    "state_code": "PZ",
    "cab": "420208",
    "cap": "85043"
  },
  {
    "key": "7251",
    "ITA": "LATRONICO-MILEO",
    "state_code": "PZ",
    "cab": "420208",
    "cap": "85043"
  },
  {
    "key": "7252",
    "ITA": "LATRONICO",
    "state_code": "PZ",
    "cab": "420208",
    "cap": "85043"
  },
  {
    "key": "7253",
    "ITA": "LATRONICO-AGROMONTE",
    "state_code": "PZ",
    "cab": "420208",
    "cap": "85043"
  },
  {
    "key": "7254",
    "ITA": "LATRONICO-CERRI",
    "state_code": "PZ",
    "cab": "420208",
    "cap": "85043"
  },
  {
    "key": "7255",
    "ITA": "LATTARICO-REGINA",
    "state_code": "CS",
    "cab": "888206",
    "cap": "87010"
  },
  {
    "key": "7256",
    "ITA": "LATTARICO",
    "state_code": "CS",
    "cab": "888206",
    "cap": "87010"
  },
  {
    "key": "7257",
    "ITA": "LAUCO-CHIASSIS",
    "state_code": "UD",
    "cab": "181206",
    "cap": "33029"
  },
  {
    "key": "7258",
    "ITA": "LAUCO-TRAVA",
    "state_code": "UD",
    "cab": "181206",
    "cap": "33029"
  },
  {
    "key": "7259",
    "ITA": "LAUCO",
    "state_code": "UD",
    "cab": "181206",
    "cap": "33029"
  },
  {
    "key": "7260",
    "ITA": "LAUREANA CILENTO",
    "state_code": "SA",
    "cab": "952580",
    "cap": "84050"
  },
  {
    "key": "7261",
    "ITA": "LAUREANA CILENTO-MATONTI",
    "state_code": "SA",
    "cab": "952580",
    "cap": "84050"
  },
  {
    "key": "7262",
    "ITA": "LAUREANA DI BORRELLO-STELLETANONE",
    "state_code": "RC",
    "cab": "814004",
    "cap": "89023"
  },
  {
    "key": "7263",
    "ITA": "LAUREANA DI BORRELLO-BELLANTONE",
    "state_code": "RC",
    "cab": "814004",
    "cap": "89023"
  },
  {
    "key": "7264",
    "ITA": "LAUREANA DI BORRELLO",
    "state_code": "RC",
    "cab": "814004",
    "cap": "89023"
  },
  {
    "key": "7265",
    "ITA": "LAUREGNO",
    "state_code": "BZ",
    "cab": "585109",
    "cap": "39040"
  },
  {
    "key": "7266",
    "ITA": "LAURENZANA",
    "state_code": "PZ",
    "cab": "420307",
    "cap": "85014"
  },
  {
    "key": "7267",
    "ITA": "LAURIA-COGLIANDRINO",
    "state_code": "PZ",
    "cab": "420406",
    "cap": "85044"
  },
  {
    "key": "7268",
    "ITA": "LAURIA-GALDO",
    "state_code": "PZ",
    "cab": "420406",
    "cap": "85044"
  },
  {
    "key": "7269",
    "ITA": "LAURIA",
    "state_code": "PZ",
    "cab": "420406",
    "cap": "85044"
  },
  {
    "key": "7270",
    "ITA": "LAURIA-LAURIA INFERIORE",
    "state_code": "PZ",
    "cab": "420406",
    "cap": "85044"
  },
  {
    "key": "7271",
    "ITA": "LAURIA-SELUCI",
    "state_code": "PZ",
    "cab": "420406",
    "cap": "85044"
  },
  {
    "key": "7272",
    "ITA": "LAURIA-LAURIA SUPERIORE",
    "state_code": "PZ",
    "cab": "420406",
    "cap": "85044"
  },
  {
    "key": "7273",
    "ITA": "LAURIA-PECORONE",
    "state_code": "PZ",
    "cab": "420406",
    "cap": "85044"
  },
  {
    "key": "7274",
    "ITA": "LAURIANO",
    "state_code": "TO",
    "cab": "381905",
    "cap": "10020"
  },
  {
    "key": "7275",
    "ITA": "LAURINO",
    "state_code": "SA",
    "cab": "766402",
    "cap": "84057"
  },
  {
    "key": "7276",
    "ITA": "LAURINO-VILLA LITTORIO",
    "state_code": "SA",
    "cab": "766402",
    "cap": "84057"
  },
  {
    "key": "7277",
    "ITA": "LAURITO",
    "state_code": "SA",
    "cab": "952606",
    "cap": "84050"
  },
  {
    "key": "7278",
    "ITA": "LAURO",
    "state_code": "AV",
    "cab": "897405",
    "cap": "83023"
  },
  {
    "key": "7279",
    "ITA": "LAURO-FONTENOVELLA",
    "state_code": "AV",
    "cab": "897405",
    "cap": "83023"
  },
  {
    "key": "7280",
    "ITA": "LAURO-MIGLIANO",
    "state_code": "AV",
    "cab": "897405",
    "cap": "83023"
  },
  {
    "key": "7281",
    "ITA": "LAVAGNA-CAVI",
    "state_code": "GE",
    "cab": "320101",
    "cap": "16033"
  },
  {
    "key": "7282",
    "ITA": "LAVAGNA",
    "state_code": "GE",
    "cab": "320101",
    "cap": "16033"
  },
  {
    "key": "7283",
    "ITA": "LAVAGNO-SAN BRICCIO",
    "state_code": "VR",
    "cab": "595207",
    "cap": "37030"
  },
  {
    "key": "7284",
    "ITA": "LAVAGNO",
    "state_code": "VR",
    "cab": "595207",
    "cap": "37030"
  },
  {
    "key": "7285",
    "ITA": "LAVAGNO-SAN PIETRO",
    "state_code": "VR",
    "cab": "595207",
    "cap": "37030"
  },
  {
    "key": "7286",
    "ITA": "LAVAGNO-VAGO",
    "state_code": "VR",
    "cab": "595207",
    "cap": "37030"
  },
  {
    "key": "7287",
    "ITA": "LAVARONE",
    "state_code": "TN",
    "cab": "349209",
    "cap": "38046"
  },
  {
    "key": "7288",
    "ITA": "LAVARONE-CAPPELLA",
    "state_code": "TN",
    "cab": "349209",
    "cap": "38046"
  },
  {
    "key": "7289",
    "ITA": "LAVARONE-GIONGHI",
    "state_code": "TN",
    "cab": "349209",
    "cap": "38046"
  },
  {
    "key": "7290",
    "ITA": "LAVELLO",
    "state_code": "PZ",
    "cab": "420505",
    "cap": "85024"
  },
  {
    "key": "7291",
    "ITA": "LAVELLO-GAUDIANO",
    "state_code": "PZ",
    "cab": "420505",
    "cap": "85024"
  },
  {
    "key": "7292",
    "ITA": "LAVENA PONTE TRESA",
    "state_code": "VA",
    "cab": "503607",
    "cap": "21037"
  },
  {
    "key": "7293",
    "ITA": "LAVENA PONTE TRESA-PONTE TRESA",
    "state_code": "VA",
    "cab": "503607",
    "cap": "21037"
  },
  {
    "key": "7294",
    "ITA": "LAVENO MOMBELLO",
    "state_code": "VA",
    "cab": "503706",
    "cap": "21014"
  },
  {
    "key": "7295",
    "ITA": "LAVENO MOMBELLO-LAVENO",
    "state_code": "VA",
    "cab": "503706",
    "cap": "21014"
  },
  {
    "key": "7296",
    "ITA": "LAVENO MOMBELLO-MOMBELLO",
    "state_code": "VA",
    "cab": "503706",
    "cap": "21014"
  },
  {
    "key": "7297",
    "ITA": "LAVENONE",
    "state_code": "BS",
    "cab": "488809",
    "cap": "25074"
  },
  {
    "key": "7298",
    "ITA": "LAVIANO",
    "state_code": "SA",
    "cab": "886200",
    "cap": "84020"
  },
  {
    "key": "7299",
    "ITA": "LAVIS-NAVE SAN FELICE",
    "state_code": "TN",
    "cab": "349308",
    "cap": "38015"
  },
  {
    "key": "7300",
    "ITA": "LAVIS-PRESSANO",
    "state_code": "TN",
    "cab": "349308",
    "cap": "38015"
  },
  {
    "key": "7301",
    "ITA": "LAVIS",
    "state_code": "TN",
    "cab": "349308",
    "cap": "38015"
  },
  {
    "key": "7302",
    "ITA": "LAZISE-COLA'",
    "state_code": "VR",
    "cab": "595306",
    "cap": "37017"
  },
  {
    "key": "7303",
    "ITA": "LAZISE",
    "state_code": "VR",
    "cab": "595306",
    "cap": "37017"
  },
  {
    "key": "7304",
    "ITA": "LAZISE-PACENGO",
    "state_code": "VR",
    "cab": "595306",
    "cap": "37017"
  },
  {
    "key": "7305",
    "ITA": "LAZZATE",
    "state_code": "MB",
    "cab": "332304",
    "cap": "20020"
  },
  {
    "key": "7306",
    "ITA": "LECCE",
    "state_code": "LE",
    "cab": "160002",
    "cap": "73100"
  },
  {
    "key": "7307",
    "ITA": "LECCE-FRIGOLE",
    "state_code": "LE",
    "cab": "160002",
    "cap": "73010"
  },
  {
    "key": "7308",
    "ITA": "LECCE-SAN CATALDO",
    "state_code": "LE",
    "cab": "160002",
    "cap": "73100"
  },
  {
    "key": "7309",
    "ITA": "LECCE-TORRE CHIANCA",
    "state_code": "LE",
    "cab": "160002",
    "cap": "73100"
  },
  {
    "key": "7310",
    "ITA": "LECCE NEI MARSI",
    "state_code": "AQ",
    "cab": "909713",
    "cap": "67050"
  },
  {
    "key": "7311",
    "ITA": "LECCO",
    "state_code": "LC",
    "cab": "229005",
    "cap": "23900"
  },
  {
    "key": "7312",
    "ITA": "LEDRO",
    "state_code": "TN",
    "cab": "721407",
    "cap": "38067"
  },
  {
    "key": "7313",
    "ITA": "LEDRO-BEZZECCA",
    "state_code": "TN",
    "cab": "721407",
    "cap": "38060"
  },
  {
    "key": "7314",
    "ITA": "LEFFE",
    "state_code": "BG",
    "cab": "531608",
    "cap": "24026"
  },
  {
    "key": "7315",
    "ITA": "LEGGIUNO",
    "state_code": "VA",
    "cab": "503805",
    "cap": "21038"
  },
  {
    "key": "7316",
    "ITA": "LEGNAGO",
    "state_code": "VR",
    "cab": "595405",
    "cap": "37045"
  },
  {
    "key": "7317",
    "ITA": "LEGNAGO-CASETTE",
    "state_code": "VR",
    "cab": "595405",
    "cap": "37045"
  },
  {
    "key": "7318",
    "ITA": "LEGNAGO-GALLESE",
    "state_code": "VR",
    "cab": "595405",
    "cap": "37045"
  },
  {
    "key": "7319",
    "ITA": "LEGNAGO-SAN PIETRO DI LEGNAGO",
    "state_code": "VR",
    "cab": "595405",
    "cap": "37045"
  },
  {
    "key": "7320",
    "ITA": "LEGNAGO-TERRANEGRA",
    "state_code": "VR",
    "cab": "595405",
    "cap": "37045"
  },
  {
    "key": "7321",
    "ITA": "LEGNAGO-VANGADIZZA",
    "state_code": "VR",
    "cab": "595405",
    "cap": "37045"
  },
  {
    "key": "7322",
    "ITA": "LEGNANO",
    "state_code": "MI",
    "cab": "202002",
    "cap": "20025"
  },
  {
    "key": "7323",
    "ITA": "LEGNARO",
    "state_code": "PD",
    "cab": "626002",
    "cap": "35020"
  },
  {
    "key": "7324",
    "ITA": "LEGNARO-CASONE",
    "state_code": "PD",
    "cab": "626002",
    "cap": "35020"
  },
  {
    "key": "7325",
    "ITA": "LEI",
    "state_code": "NU",
    "cab": "866608",
    "cap": "8010"
  },
  {
    "key": "7326",
    "ITA": "LEINI'",
    "state_code": "TO",
    "cab": "305706",
    "cap": "10040"
  },
  {
    "key": "7327",
    "ITA": "LEINI'-TEDESCHI",
    "state_code": "TO",
    "cab": "305706",
    "cap": "10040"
  },
  {
    "key": "7328",
    "ITA": "LEIVI",
    "state_code": "GE",
    "cab": "903187",
    "cap": "16040"
  },
  {
    "key": "7329",
    "ITA": "LEMIE",
    "state_code": "TO",
    "cab": "283697",
    "cap": "10070"
  },
  {
    "key": "7330",
    "ITA": "LENDINARA",
    "state_code": "RO",
    "cab": "633404",
    "cap": "45026"
  },
  {
    "key": "7331",
    "ITA": "LENDINARA-BARBUGLIO",
    "state_code": "RO",
    "cab": "633404",
    "cap": "45026"
  },
  {
    "key": "7332",
    "ITA": "LENDINARA-RAMODIPALO RASA",
    "state_code": "RO",
    "cab": "633404",
    "cap": "45026"
  },
  {
    "key": "7333",
    "ITA": "LENDINARA-SAGUEDO",
    "state_code": "RO",
    "cab": "633404",
    "cap": "45026"
  },
  {
    "key": "7334",
    "ITA": "LENI",
    "state_code": "ME",
    "cab": "961383",
    "cap": "98050"
  },
  {
    "key": "7335",
    "ITA": "LENNA",
    "state_code": "BG",
    "cab": "928234",
    "cap": "24010"
  },
  {
    "key": "7336",
    "ITA": "LENNO",
    "state_code": "CO",
    "cab": "514000",
    "cap": "22016"
  },
  {
    "key": "7337",
    "ITA": "LENO",
    "state_code": "BS",
    "cab": "546309",
    "cap": "25024"
  },
  {
    "key": "7338",
    "ITA": "LENO-PORZANO",
    "state_code": "BS",
    "cab": "546309",
    "cap": "25024"
  },
  {
    "key": "7339",
    "ITA": "LENO-CASTELLETTO",
    "state_code": "BS",
    "cab": "546309",
    "cap": "25024"
  },
  {
    "key": "7340",
    "ITA": "LENOLA-VALLE BERNARDO",
    "state_code": "LT",
    "cab": "740100",
    "cap": "4025"
  },
  {
    "key": "7341",
    "ITA": "LENOLA",
    "state_code": "LT",
    "cab": "740100",
    "cap": "4025"
  },
  {
    "key": "7342",
    "ITA": "LENTA",
    "state_code": "VC",
    "cab": "445304",
    "cap": "13035"
  },
  {
    "key": "7343",
    "ITA": "LENTATE SUL SEVESO",
    "state_code": "MB",
    "cab": "332403",
    "cap": "20030"
  },
  {
    "key": "7344",
    "ITA": "LENTATE SUL SEVESO-CAMNAGO",
    "state_code": "MB",
    "cab": "332403",
    "cap": "20030"
  },
  {
    "key": "7345",
    "ITA": "LENTATE SUL SEVESO-CIMNAGO",
    "state_code": "MB",
    "cab": "332403",
    "cap": "20030"
  },
  {
    "key": "7346",
    "ITA": "LENTELLA",
    "state_code": "CH",
    "cab": "954743",
    "cap": "66050"
  },
  {
    "key": "7347",
    "ITA": "LENTIAI",
    "state_code": "BL",
    "cab": "611509",
    "cap": "32020"
  },
  {
    "key": "7348",
    "ITA": "LENTIAI-RONCHENA",
    "state_code": "BL",
    "cab": "611509",
    "cap": "32020"
  },
  {
    "key": "7349",
    "ITA": "LENTIAI-VILLAPIANA",
    "state_code": "BL",
    "cab": "611509",
    "cap": "32020"
  },
  {
    "key": "7350",
    "ITA": "LENTINI",
    "state_code": "SR",
    "cab": "847202",
    "cap": "96016"
  },
  {
    "key": "7351",
    "ITA": "LEONESSA-ALBANETO",
    "state_code": "RI",
    "cab": "736603",
    "cap": "2016"
  },
  {
    "key": "7352",
    "ITA": "LEONESSA-PIEDELPOGGIO",
    "state_code": "RI",
    "cab": "736603",
    "cap": "2016"
  },
  {
    "key": "7353",
    "ITA": "LEONESSA-SAN CLEMENTE DI LEONESSA",
    "state_code": "RI",
    "cab": "736603",
    "cap": "2016"
  },
  {
    "key": "7354",
    "ITA": "LEONESSA-VILLA BIGIONI",
    "state_code": "RI",
    "cab": "736603",
    "cap": "2016"
  },
  {
    "key": "7355",
    "ITA": "LEONESSA-VINDOLI",
    "state_code": "RI",
    "cab": "736603",
    "cap": "2016"
  },
  {
    "key": "7356",
    "ITA": "LEONESSA",
    "state_code": "RI",
    "cab": "736603",
    "cap": "2016"
  },
  {
    "key": "7357",
    "ITA": "LEONESSA-TERZONE",
    "state_code": "RI",
    "cab": "736603",
    "cap": "2016"
  },
  {
    "key": "7358",
    "ITA": "LEONFORTE",
    "state_code": "EN",
    "cab": "836601",
    "cap": "94013"
  },
  {
    "key": "7359",
    "ITA": "LEPORANO",
    "state_code": "TA",
    "cab": "790600",
    "cap": "74020"
  },
  {
    "key": "7360",
    "ITA": "LEPORANO-LEPORANO MARINA",
    "state_code": "TA",
    "cab": "790600",
    "cap": "74020"
  },
  {
    "key": "7361",
    "ITA": "LEQUILE",
    "state_code": "LE",
    "cab": "796904",
    "cap": "73010"
  },
  {
    "key": "7362",
    "ITA": "LEQUILE-DRAGONI",
    "state_code": "LE",
    "cab": "796904",
    "cap": "73010"
  },
  {
    "key": "7363",
    "ITA": "LEQUIO BERRIA",
    "state_code": "CN",
    "cab": "918763",
    "cap": "12050"
  },
  {
    "key": "7364",
    "ITA": "LEQUIO TANARO",
    "state_code": "CN",
    "cab": "918771",
    "cap": "12060"
  },
  {
    "key": "7365",
    "ITA": "LERCARA FRIDDI",
    "state_code": "PA",
    "cab": "434100",
    "cap": "90025"
  },
  {
    "key": "7366",
    "ITA": "LERICI-TELLARO",
    "state_code": "SP",
    "cab": "497701",
    "cap": "19032"
  },
  {
    "key": "7367",
    "ITA": "LERICI-SERRA",
    "state_code": "SP",
    "cab": "497701",
    "cap": "19032"
  },
  {
    "key": "7368",
    "ITA": "LERICI-SAN TERENZO",
    "state_code": "SP",
    "cab": "497701",
    "cap": "19032"
  },
  {
    "key": "7369",
    "ITA": "LERICI-PUGLIOLA",
    "state_code": "SP",
    "cab": "497701",
    "cap": "19032"
  },
  {
    "key": "7370",
    "ITA": "LERICI-FIASCHERINO",
    "state_code": "SP",
    "cab": "497701",
    "cap": "19032"
  },
  {
    "key": "7371",
    "ITA": "LERICI",
    "state_code": "SP",
    "cab": "497701",
    "cap": "19032"
  },
  {
    "key": "7372",
    "ITA": "LERMA",
    "state_code": "AL",
    "cab": "488106",
    "cap": "15070"
  },
  {
    "key": "7373",
    "ITA": "LESA",
    "state_code": "NO",
    "cab": "454702",
    "cap": "28040"
  },
  {
    "key": "7374",
    "ITA": "LESEGNO",
    "state_code": "CN",
    "cab": "698506",
    "cap": "12076"
  },
  {
    "key": "7375",
    "ITA": "LESIGNANO DE' BAGNI-MULAZZANO PONTE",
    "state_code": "PR",
    "cab": "657809",
    "cap": "43037"
  },
  {
    "key": "7376",
    "ITA": "LESIGNANO DE' BAGNI-SAN MICHELE CAVANA",
    "state_code": "PR",
    "cab": "657809",
    "cap": "43037"
  },
  {
    "key": "7377",
    "ITA": "LESIGNANO DE' BAGNI-SANTA MARIA DEL PIANO",
    "state_code": "PR",
    "cab": "657809",
    "cap": "43037"
  },
  {
    "key": "7378",
    "ITA": "LESIGNANO DE' BAGNI",
    "state_code": "PR",
    "cab": "657809",
    "cap": "43037"
  },
  {
    "key": "7379",
    "ITA": "LESINA",
    "state_code": "FG",
    "cab": "784306",
    "cap": "71010"
  },
  {
    "key": "7380",
    "ITA": "LESINA-RIPALTA",
    "state_code": "FG",
    "cab": "784306",
    "cap": "71010"
  },
  {
    "key": "7381",
    "ITA": "LESMO-PEREGALLO",
    "state_code": "MB",
    "cab": "332502",
    "cap": "20050"
  },
  {
    "key": "7382",
    "ITA": "LESMO",
    "state_code": "MB",
    "cab": "332502",
    "cap": "20050"
  },
  {
    "key": "7383",
    "ITA": "LESSOLO",
    "state_code": "TO",
    "cab": "305805",
    "cap": "10010"
  },
  {
    "key": "7384",
    "ITA": "LESSOLO-CALEA",
    "state_code": "TO",
    "cab": "305805",
    "cap": "10010"
  },
  {
    "key": "7385",
    "ITA": "LESSONA",
    "state_code": "BI",
    "cab": "491308",
    "cap": "13853"
  },
  {
    "key": "7386",
    "ITA": "LESSONA-CAPOVILLA",
    "state_code": "BI",
    "cab": "491308",
    "cap": "13853"
  },
  {
    "key": "7387",
    "ITA": "LESSONA-CASTELLO",
    "state_code": "BI",
    "cab": "491308",
    "cap": "13853"
  },
  {
    "key": "7388",
    "ITA": "LESTIZZA-SCLAUNICCO",
    "state_code": "UD",
    "cab": "644203",
    "cap": "33050"
  },
  {
    "key": "7389",
    "ITA": "LESTIZZA-NESPOLEDO",
    "state_code": "UD",
    "cab": "644203",
    "cap": "33050"
  },
  {
    "key": "7390",
    "ITA": "LESTIZZA-GALLERIANO",
    "state_code": "UD",
    "cab": "644203",
    "cap": "33050"
  },
  {
    "key": "7391",
    "ITA": "LESTIZZA",
    "state_code": "UD",
    "cab": "644203",
    "cap": "33050"
  },
  {
    "key": "7392",
    "ITA": "LESTIZZA-SANTA MARIA",
    "state_code": "UD",
    "cab": "644203",
    "cap": "33050"
  },
  {
    "key": "7393",
    "ITA": "LETINO",
    "state_code": "CE",
    "cab": "949396",
    "cap": "81010"
  },
  {
    "key": "7394",
    "ITA": "LETOJANNI",
    "state_code": "ME",
    "cab": "822502",
    "cap": "98037"
  },
  {
    "key": "7395",
    "ITA": "LETTERE-PIAZZA ROMA",
    "state_code": "NA",
    "cab": "492009",
    "cap": "80050"
  },
  {
    "key": "7396",
    "ITA": "LETTERE",
    "state_code": "NA",
    "cab": "492009",
    "cap": "80050"
  },
  {
    "key": "7397",
    "ITA": "LETTERE-SAN NICOLA",
    "state_code": "NA",
    "cab": "492009",
    "cap": "80050"
  },
  {
    "key": "7398",
    "ITA": "LETTOMANOPPELLO",
    "state_code": "PE",
    "cab": "773101",
    "cap": "65020"
  },
  {
    "key": "7399",
    "ITA": "LETTOPALENA",
    "state_code": "CH",
    "cab": "954750",
    "cap": "66010"
  },
  {
    "key": "7400",
    "ITA": "LEVANTO-MONTALE",
    "state_code": "SP",
    "cab": "497800",
    "cap": "19015"
  },
  {
    "key": "7401",
    "ITA": "LEVANTO",
    "state_code": "SP",
    "cab": "497800",
    "cap": "19015"
  },
  {
    "key": "7402",
    "ITA": "LEVATE",
    "state_code": "BG",
    "cab": "538405",
    "cap": "24040"
  },
  {
    "key": "7403",
    "ITA": "LEVERANO",
    "state_code": "LE",
    "cab": "797001",
    "cap": "73045"
  },
  {
    "key": "7404",
    "ITA": "LEVICE",
    "state_code": "CN",
    "cab": "918797",
    "cap": "12070"
  },
  {
    "key": "7405",
    "ITA": "LEVICO TERME-BARCO",
    "state_code": "TN",
    "cab": "349407",
    "cap": "38056"
  },
  {
    "key": "7406",
    "ITA": "LEVICO TERME",
    "state_code": "TN",
    "cab": "349407",
    "cap": "38056"
  },
  {
    "key": "7407",
    "ITA": "LEVONE",
    "state_code": "TO",
    "cab": "283721",
    "cap": "10070"
  },
  {
    "key": "7408",
    "ITA": "LEZZENO",
    "state_code": "CO",
    "cab": "514109",
    "cap": "22025"
  },
  {
    "key": "7409",
    "ITA": "LIBERI",
    "state_code": "CE",
    "cab": "949404",
    "cap": "81040"
  },
  {
    "key": "7410",
    "ITA": "LIBRIZZI-COLLA MAFFONE",
    "state_code": "ME",
    "cab": "827808",
    "cap": "98064"
  },
  {
    "key": "7411",
    "ITA": "LIBRIZZI",
    "state_code": "ME",
    "cab": "827808",
    "cap": "98064"
  },
  {
    "key": "7412",
    "ITA": "LIBRIZZI-NASIDI",
    "state_code": "ME",
    "cab": "827808",
    "cap": "98064"
  },
  {
    "key": "7413",
    "ITA": "LICATA",
    "state_code": "AG",
    "cab": "829705",
    "cap": "92027"
  },
  {
    "key": "7414",
    "ITA": "LICCIANA NARDI",
    "state_code": "MS",
    "cab": "699306",
    "cap": "54016"
  },
  {
    "key": "7415",
    "ITA": "LICCIANA NARDI-TERRAROSSA",
    "state_code": "MS",
    "cab": "699306",
    "cap": "54016"
  },
  {
    "key": "7416",
    "ITA": "LICCIANA NARDI-TAVERNELLE",
    "state_code": "MS",
    "cab": "699306",
    "cap": "54016"
  },
  {
    "key": "7417",
    "ITA": "LICCIANA NARDI-MONTI",
    "state_code": "MS",
    "cab": "699306",
    "cap": "54016"
  },
  {
    "key": "7418",
    "ITA": "LICENZA-CIVITELLA",
    "state_code": "RM",
    "cab": "908186",
    "cap": "26"
  },
  {
    "key": "7419",
    "ITA": "LICENZA",
    "state_code": "RM",
    "cab": "908186",
    "cap": "26"
  },
  {
    "key": "7420",
    "ITA": "LICODIA EUBEA",
    "state_code": "CT",
    "cab": "839803",
    "cap": "95040"
  },
  {
    "key": "7421",
    "ITA": "LIERNA",
    "state_code": "LC",
    "cab": "514208",
    "cap": "23827"
  },
  {
    "key": "7422",
    "ITA": "LIGNANA",
    "state_code": "VC",
    "cab": "915975",
    "cap": "13034"
  },
  {
    "key": "7423",
    "ITA": "LIGNANO SABBIADORO-LIGNANO PINETA",
    "state_code": "UD",
    "cab": "639104",
    "cap": "33054"
  },
  {
    "key": "7424",
    "ITA": "LIGNANO SABBIADORO",
    "state_code": "UD",
    "cab": "639104",
    "cap": "33054"
  },
  {
    "key": "7425",
    "ITA": "LIGONCHIO",
    "state_code": "RE",
    "cab": "663708",
    "cap": "42039"
  },
  {
    "key": "7426",
    "ITA": "LIGONCHIO-CINQUECERRI",
    "state_code": "RE",
    "cab": "663708",
    "cap": "42039"
  },
  {
    "key": "7427",
    "ITA": "LIGOSULLO",
    "state_code": "UD",
    "cab": "936971",
    "cap": "33020"
  },
  {
    "key": "7428",
    "ITA": "LILLIANES",
    "state_code": "AO",
    "cab": "902486",
    "cap": "11020"
  },
  {
    "key": "7429",
    "ITA": "LIMANA",
    "state_code": "BL",
    "cab": "611608",
    "cap": "32020"
  },
  {
    "key": "7430",
    "ITA": "LIMANA-DUSSOI",
    "state_code": "BL",
    "cab": "611608",
    "cap": "32020"
  },
  {
    "key": "7431",
    "ITA": "LIMATOLA",
    "state_code": "BN",
    "cab": "756205",
    "cap": "82030"
  },
  {
    "key": "7432",
    "ITA": "LIMATOLA-GIARDONI",
    "state_code": "BN",
    "cab": "756205",
    "cap": "82030"
  },
  {
    "key": "7433",
    "ITA": "LIMATOLA-BIANCANO",
    "state_code": "BN",
    "cab": "756205",
    "cap": "82030"
  },
  {
    "key": "7434",
    "ITA": "LIMATOLA-AVE GRATIA PLENA",
    "state_code": "BN",
    "cab": "756205",
    "cap": "82030"
  },
  {
    "key": "7435",
    "ITA": "LIMBADI-MANDARADONI",
    "state_code": "VV",
    "cab": "802306",
    "cap": "89844"
  },
  {
    "key": "7436",
    "ITA": "LIMBADI-CARONI",
    "state_code": "VV",
    "cab": "802306",
    "cap": "89844"
  },
  {
    "key": "7437",
    "ITA": "LIMBADI",
    "state_code": "VV",
    "cab": "802306",
    "cap": "89844"
  },
  {
    "key": "7438",
    "ITA": "LIMBADI-MOTTA FILOCASTRO",
    "state_code": "VV",
    "cab": "802306",
    "cap": "89844"
  },
  {
    "key": "7439",
    "ITA": "LIMBIATE-MOMBELLO",
    "state_code": "MB",
    "cab": "332601",
    "cap": "20051"
  },
  {
    "key": "7440",
    "ITA": "LIMBIATE-VILLAGGIO DEI GIOVI",
    "state_code": "MB",
    "cab": "332601",
    "cap": "20051"
  },
  {
    "key": "7441",
    "ITA": "LIMBIATE-VILLAGGIO DEL SOLE",
    "state_code": "MB",
    "cab": "332601",
    "cap": "20051"
  },
  {
    "key": "7442",
    "ITA": "LIMBIATE",
    "state_code": "MB",
    "cab": "332601",
    "cap": "20812"
  },
  {
    "key": "7443",
    "ITA": "LIMENA",
    "state_code": "PD",
    "cab": "626101",
    "cap": "35010"
  },
  {
    "key": "7444",
    "ITA": "LIMENA-PONTEROTTO",
    "state_code": "PD",
    "cab": "626101",
    "cap": "35010"
  },
  {
    "key": "7445",
    "ITA": "LIMIDO COMASCO",
    "state_code": "CO",
    "cab": "382002",
    "cap": "22070"
  },
  {
    "key": "7446",
    "ITA": "LIMIDO COMASCO-CASCINA RESTELLI",
    "state_code": "CO",
    "cab": "382002",
    "cap": "22070"
  },
  {
    "key": "7447",
    "ITA": "LIMINA",
    "state_code": "ME",
    "cab": "961409",
    "cap": "98030"
  },
  {
    "key": "7448",
    "ITA": "LIMONE PIEMONTE",
    "state_code": "CN",
    "cab": "464107",
    "cap": "12015"
  },
  {
    "key": "7449",
    "ITA": "LIMONE SUL GARDA",
    "state_code": "BS",
    "cab": "546408",
    "cap": "25010"
  },
  {
    "key": "7450",
    "ITA": "LIMOSANO",
    "state_code": "CB",
    "cab": "910612",
    "cap": "86022"
  },
  {
    "key": "7451",
    "ITA": "LINAROLO",
    "state_code": "PV",
    "cab": "559609",
    "cap": "27010"
  },
  {
    "key": "7452",
    "ITA": "LINAROLO-SAN LEONARDO",
    "state_code": "PV",
    "cab": "559609",
    "cap": "27010"
  },
  {
    "key": "7453",
    "ITA": "LINGUAGLOSSA",
    "state_code": "CT",
    "cab": "839902",
    "cap": "95015"
  },
  {
    "key": "7454",
    "ITA": "LIONI",
    "state_code": "AV",
    "cab": "757500",
    "cap": "83047"
  },
  {
    "key": "7455",
    "ITA": "LIPARI-VULCANO PORTO",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7456",
    "ITA": "LIPARI-STROMBOLI",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7457",
    "ITA": "LIPARI-QUATTROPANI",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98055"
  },
  {
    "key": "7458",
    "ITA": "LIPARI-PIANOCONTE",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98055"
  },
  {
    "key": "7459",
    "ITA": "LIPARI-PECORINI A MARE",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7460",
    "ITA": "LIPARI-PANAREA",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7461",
    "ITA": "LIPARI-GINOSTRA",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7462",
    "ITA": "LIPARI-CANNETO",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98055"
  },
  {
    "key": "7463",
    "ITA": "LIPARI-ALICUDI PORTO",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7464",
    "ITA": "LIPARI-ACQUACALDA",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7465",
    "ITA": "LIPARI",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98055"
  },
  {
    "key": "7466",
    "ITA": "LIPARI-FILICUDI PORTO",
    "state_code": "ME",
    "cab": "260000",
    "cap": "98050"
  },
  {
    "key": "7467",
    "ITA": "LIPOMO",
    "state_code": "CO",
    "cab": "514307",
    "cap": "22030"
  },
  {
    "key": "7468",
    "ITA": "LIRIO",
    "state_code": "PV",
    "cab": "930552",
    "cap": "27040"
  },
  {
    "key": "7469",
    "ITA": "LISCATE",
    "state_code": "MI",
    "cab": "894204",
    "cap": "20060"
  },
  {
    "key": "7470",
    "ITA": "LISCIA",
    "state_code": "CH",
    "cab": "954768",
    "cap": "66050"
  },
  {
    "key": "7471",
    "ITA": "LISCIANO NICCONE",
    "state_code": "PG",
    "cab": "384909",
    "cap": "6060"
  },
  {
    "key": "7472",
    "ITA": "LISIGNAGO",
    "state_code": "TN",
    "cab": "349506",
    "cap": "38030"
  },
  {
    "key": "7473",
    "ITA": "LISIO",
    "state_code": "CN",
    "cab": "918805",
    "cap": "12070"
  },
  {
    "key": "7474",
    "ITA": "LISSONE",
    "state_code": "MB",
    "cab": "332700",
    "cap": "20035"
  },
  {
    "key": "7475",
    "ITA": "LISSONE-SANTA MARGHERITA",
    "state_code": "MB",
    "cab": "332700",
    "cap": "20035"
  },
  {
    "key": "7476",
    "ITA": "LIVERI",
    "state_code": "NA",
    "cab": "908913",
    "cap": "80030"
  },
  {
    "key": "7477",
    "ITA": "LIVIGNO",
    "state_code": "SO",
    "cab": "522003",
    "cap": "23030"
  },
  {
    "key": "7478",
    "ITA": "LIVIGNO-TREPALLE",
    "state_code": "SO",
    "cab": "522003",
    "cap": "23030"
  },
  {
    "key": "7479",
    "ITA": "LIVINALLONGO DEL COL DI LANA",
    "state_code": "BL",
    "cab": "611707",
    "cap": "32020"
  },
  {
    "key": "7480",
    "ITA": "LIVINALLONGO DEL COL DI LANA-ARABBA",
    "state_code": "BL",
    "cab": "611707",
    "cap": "32020"
  },
  {
    "key": "7481",
    "ITA": "LIVO",
    "state_code": "CO",
    "cab": "925735",
    "cap": "22010"
  },
  {
    "key": "7482",
    "ITA": "LIVO",
    "state_code": "TN",
    "cab": "349605",
    "cap": "38020"
  },
  {
    "key": "7483",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57128"
  },
  {
    "key": "7484",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57121"
  },
  {
    "key": "7485",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57122"
  },
  {
    "key": "7486",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57123"
  },
  {
    "key": "7487",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57124"
  },
  {
    "key": "7488",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57125"
  },
  {
    "key": "7489",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57126"
  },
  {
    "key": "7490",
    "ITA": "LIVORNO",
    "state_code": "LI",
    "cab": "139006",
    "cap": "57127"
  },
  {
    "key": "7491",
    "ITA": "LIVORNO FERRARIS",
    "state_code": "VC",
    "cab": "445403",
    "cap": "13046"
  },
  {
    "key": "7492",
    "ITA": "LIVRAGA",
    "state_code": "LO",
    "cab": "332809",
    "cap": "26814"
  },
  {
    "key": "7493",
    "ITA": "LIZZANELLO",
    "state_code": "LE",
    "cab": "797100",
    "cap": "73023"
  },
  {
    "key": "7494",
    "ITA": "LIZZANELLO-MERINE",
    "state_code": "LE",
    "cab": "797100",
    "cap": "73023"
  },
  {
    "key": "7495",
    "ITA": "LIZZANO",
    "state_code": "TA",
    "cab": "789107",
    "cap": "74020"
  },
  {
    "key": "7496",
    "ITA": "LIZZANO IN BELVEDERE",
    "state_code": "BO",
    "cab": "368605",
    "cap": "40042"
  },
  {
    "key": "7497",
    "ITA": "LIZZANO IN BELVEDERE-FARNETO DI LIZZANO",
    "state_code": "BO",
    "cab": "368605",
    "cap": "40042"
  },
  {
    "key": "7498",
    "ITA": "LIZZANO IN BELVEDERE-MONTEACUTO DELLE ALPI",
    "state_code": "BO",
    "cab": "368605",
    "cap": "40042"
  },
  {
    "key": "7499",
    "ITA": "LIZZANO IN BELVEDERE-PIANACCIO",
    "state_code": "BO",
    "cab": "368605",
    "cap": "40042"
  },
  {
    "key": "7500",
    "ITA": "LIZZANO IN BELVEDERE-QUERCIOLA",
    "state_code": "BO",
    "cab": "368605",
    "cap": "40042"
  },
  {
    "key": "7501",
    "ITA": "LIZZANO IN BELVEDERE-ROCCA CORNETA",
    "state_code": "BO",
    "cab": "368605",
    "cap": "40042"
  },
  {
    "key": "7502",
    "ITA": "LIZZANO IN BELVEDERE-VIDICIATICO",
    "state_code": "BO",
    "cab": "368605",
    "cap": "40042"
  },
  {
    "key": "7503",
    "ITA": "LOANO",
    "state_code": "SV",
    "cab": "494302",
    "cap": "17025"
  },
  {
    "key": "7504",
    "ITA": "LOAZZOLO",
    "state_code": "AT",
    "cab": "920223",
    "cap": "14051"
  },
  {
    "key": "7505",
    "ITA": "LOCANA-FORNOLOSA",
    "state_code": "TO",
    "cab": "305904",
    "cap": "10080"
  },
  {
    "key": "7506",
    "ITA": "LOCANA-ROSONE",
    "state_code": "TO",
    "cab": "305904",
    "cap": "10080"
  },
  {
    "key": "7507",
    "ITA": "LOCANA-CASETTI",
    "state_code": "TO",
    "cab": "305904",
    "cap": "10080"
  },
  {
    "key": "7508",
    "ITA": "LOCANA",
    "state_code": "TO",
    "cab": "305904",
    "cap": "10080"
  },
  {
    "key": "7509",
    "ITA": "LOCATE DI TRIULZI",
    "state_code": "MI",
    "cab": "332908",
    "cap": "20085"
  },
  {
    "key": "7510",
    "ITA": "LOCATE VARESINO",
    "state_code": "CO",
    "cab": "514406",
    "cap": "22070"
  },
  {
    "key": "7511",
    "ITA": "LOCATELLO",
    "state_code": "BG",
    "cab": "928259",
    "cap": "24030"
  },
  {
    "key": "7512",
    "ITA": "LOCERI",
    "state_code": "OG",
    "cab": "866707",
    "cap": "8040"
  },
  {
    "key": "7513",
    "ITA": "LOCOROTONDO-SAN MARCO",
    "state_code": "BA",
    "cab": "415208",
    "cap": "70010"
  },
  {
    "key": "7514",
    "ITA": "LOCOROTONDO",
    "state_code": "BA",
    "cab": "415208",
    "cap": "70010"
  },
  {
    "key": "7515",
    "ITA": "LOCOROTONDO-TRITO",
    "state_code": "BA",
    "cab": "415208",
    "cap": "70010"
  },
  {
    "key": "7516",
    "ITA": "LOCRI-MOSCHETTA",
    "state_code": "RC",
    "cab": "814103",
    "cap": "89044"
  },
  {
    "key": "7517",
    "ITA": "LOCRI",
    "state_code": "RC",
    "cab": "814103",
    "cap": "89044"
  },
  {
    "key": "7518",
    "ITA": "LOCRI-MERICI",
    "state_code": "RC",
    "cab": "814103",
    "cap": "89044"
  },
  {
    "key": "7519",
    "ITA": "LOCULI",
    "state_code": "NU",
    "cab": "965699",
    "cap": "8020"
  },
  {
    "key": "7520",
    "ITA": "LODE'",
    "state_code": "NU",
    "cab": "866806",
    "cap": "8020"
  },
  {
    "key": "7521",
    "ITA": "LODI",
    "state_code": "LO",
    "cab": "203000",
    "cap": "26900"
  },
  {
    "key": "7522",
    "ITA": "LODI-SAN GRATO",
    "state_code": "LO",
    "cab": "203000",
    "cap": "26900"
  },
  {
    "key": "7523",
    "ITA": "LODI VECCHIO",
    "state_code": "LO",
    "cab": "333005",
    "cap": "26855"
  },
  {
    "key": "7524",
    "ITA": "LODINE",
    "state_code": "NU",
    "cab": "966234",
    "cap": "8020"
  },
  {
    "key": "7525",
    "ITA": "LODRINO",
    "state_code": "BS",
    "cab": "555201",
    "cap": "25060"
  },
  {
    "key": "7526",
    "ITA": "LOGRATO",
    "state_code": "BS",
    "cab": "546507",
    "cap": "25030"
  },
  {
    "key": "7527",
    "ITA": "LOIANO",
    "state_code": "BO",
    "cab": "368704",
    "cap": "40050"
  },
  {
    "key": "7528",
    "ITA": "LOIRI PORTO SAN PAOLO-VACCILEDDI",
    "state_code": "OT",
    "cab": "819904",
    "cap": "7020"
  },
  {
    "key": "7529",
    "ITA": "LOIRI PORTO SAN PAOLO-PORTO SAN PAOLO",
    "state_code": "OT",
    "cab": "819904",
    "cap": "7020"
  },
  {
    "key": "7530",
    "ITA": "LOIRI PORTO SAN PAOLO-LOIRI",
    "state_code": "OT",
    "cab": "819904",
    "cap": "7020"
  },
  {
    "key": "7531",
    "ITA": "LOIRI PORTO SAN PAOLO",
    "state_code": "OT",
    "cab": "819904",
    "cap": "7020"
  },
  {
    "key": "7532",
    "ITA": "LOMAGNA",
    "state_code": "LC",
    "cab": "514505",
    "cap": "23871"
  },
  {
    "key": "7533",
    "ITA": "LOMASO-LUNDO",
    "state_code": "TN",
    "cab": "349704",
    "cap": "38070"
  },
  {
    "key": "7534",
    "ITA": "LOMASO-VIGO LOMASO",
    "state_code": "TN",
    "cab": "349704",
    "cap": "38070"
  },
  {
    "key": "7535",
    "ITA": "LOMASO-BAGNI DI COMANO",
    "state_code": "TN",
    "cab": "349704",
    "cap": "38070"
  },
  {
    "key": "7536",
    "ITA": "LOMASO",
    "state_code": "TN",
    "cab": "349704",
    "cap": "38070"
  },
  {
    "key": "7537",
    "ITA": "LOMAZZO",
    "state_code": "CO",
    "cab": "514604",
    "cap": "22074"
  },
  {
    "key": "7538",
    "ITA": "LOMAZZO-MANERA",
    "state_code": "CO",
    "cab": "514604",
    "cap": "22074"
  },
  {
    "key": "7539",
    "ITA": "LOMBARDORE",
    "state_code": "TO",
    "cab": "314104",
    "cap": "10040"
  },
  {
    "key": "7540",
    "ITA": "LOMBRIASCO",
    "state_code": "TO",
    "cab": "450304",
    "cap": "10040"
  },
  {
    "key": "7541",
    "ITA": "LOMELLO",
    "state_code": "PV",
    "cab": "559708",
    "cap": "27034"
  },
  {
    "key": "7542",
    "ITA": "LONA LASES",
    "state_code": "TN",
    "cab": "349803",
    "cap": "38040"
  },
  {
    "key": "7543",
    "ITA": "LONA LASES-LASES",
    "state_code": "TN",
    "cab": "349803",
    "cap": "38040"
  },
  {
    "key": "7544",
    "ITA": "LONA LASES-LONA",
    "state_code": "TN",
    "cab": "349803",
    "cap": "38040"
  },
  {
    "key": "7545",
    "ITA": "LONATE CEPPINO",
    "state_code": "VA",
    "cab": "882407",
    "cap": "21050"
  },
  {
    "key": "7546",
    "ITA": "LONATE POZZOLO",
    "state_code": "VA",
    "cab": "503904",
    "cap": "21015"
  },
  {
    "key": "7547",
    "ITA": "LONATE POZZOLO-TORNAVENTO",
    "state_code": "VA",
    "cab": "503904",
    "cap": "21015"
  },
  {
    "key": "7548",
    "ITA": "LONATE POZZOLO-SANT'ANTONINO TICINO",
    "state_code": "VA",
    "cab": "503904",
    "cap": "21015"
  },
  {
    "key": "7549",
    "ITA": "LONATO-CENTENARO",
    "state_code": "BS",
    "cab": "546606",
    "cap": "25017"
  },
  {
    "key": "7550",
    "ITA": "LONATO",
    "state_code": "BS",
    "cab": "546606",
    "cap": "25017"
  },
  {
    "key": "7551",
    "ITA": "LONATO-ESENTA",
    "state_code": "BS",
    "cab": "546606",
    "cap": "25017"
  },
  {
    "key": "7552",
    "ITA": "LONATO-SEDENA",
    "state_code": "BS",
    "cab": "546606",
    "cap": "25017"
  },
  {
    "key": "7553",
    "ITA": "LONDA",
    "state_code": "FI",
    "cab": "379305",
    "cap": "50060"
  },
  {
    "key": "7554",
    "ITA": "LONGANO",
    "state_code": "IS",
    "cab": "186239",
    "cap": "86090"
  },
  {
    "key": "7555",
    "ITA": "LONGARE-LUMIGNANO",
    "state_code": "VI",
    "cab": "604405",
    "cap": "36023"
  },
  {
    "key": "7556",
    "ITA": "LONGARE",
    "state_code": "VI",
    "cab": "604405",
    "cap": "36023"
  },
  {
    "key": "7557",
    "ITA": "LONGARE-BUGANO",
    "state_code": "VI",
    "cab": "604405",
    "cap": "36023"
  },
  {
    "key": "7558",
    "ITA": "LONGARONE",
    "state_code": "BL",
    "cab": "611806",
    "cap": "32013"
  },
  {
    "key": "7559",
    "ITA": "LONGARONE-IGNE",
    "state_code": "BL",
    "cab": "611806",
    "cap": "32013"
  },
  {
    "key": "7560",
    "ITA": "LONGARONE-FORTOGNA",
    "state_code": "BL",
    "cab": "611806",
    "cap": "32013"
  },
  {
    "key": "7561",
    "ITA": "LONGHENA",
    "state_code": "BS",
    "cab": "812503",
    "cap": "25030"
  },
  {
    "key": "7562",
    "ITA": "LONGI",
    "state_code": "ME",
    "cab": "822601",
    "cap": "98070"
  },
  {
    "key": "7563",
    "ITA": "LONGIANO-BUDRIO",
    "state_code": "FC",
    "cab": "678300",
    "cap": "47020"
  },
  {
    "key": "7564",
    "ITA": "LONGIANO",
    "state_code": "FC",
    "cab": "678300",
    "cap": "47020"
  },
  {
    "key": "7565",
    "ITA": "LONGOBARDI-LONGOBARDI MARINA",
    "state_code": "CS",
    "cab": "958959",
    "cap": "87030"
  },
  {
    "key": "7566",
    "ITA": "LONGOBARDI",
    "state_code": "CS",
    "cab": "958959",
    "cap": "87030"
  },
  {
    "key": "7567",
    "ITA": "LONGOBUCCO",
    "state_code": "CS",
    "cab": "807800",
    "cap": "87066"
  },
  {
    "key": "7568",
    "ITA": "LONGOBUCCO-DESTRO",
    "state_code": "CS",
    "cab": "807800",
    "cap": "87060"
  },
  {
    "key": "7569",
    "ITA": "LONGONE AL SEGRINO",
    "state_code": "CO",
    "cab": "925743",
    "cap": "22030"
  },
  {
    "key": "7570",
    "ITA": "LONGONE SABINO-ROCCARANIERI",
    "state_code": "RI",
    "cab": "947226",
    "cap": "2020"
  },
  {
    "key": "7571",
    "ITA": "LONGONE SABINO",
    "state_code": "RI",
    "cab": "947226",
    "cap": "2020"
  },
  {
    "key": "7572",
    "ITA": "LONIGO-ALMISANO",
    "state_code": "VI",
    "cab": "604504",
    "cap": "36045"
  },
  {
    "key": "7573",
    "ITA": "LONIGO",
    "state_code": "VI",
    "cab": "604504",
    "cap": "36045"
  },
  {
    "key": "7574",
    "ITA": "LONIGO-BAGNOLO",
    "state_code": "VI",
    "cab": "604504",
    "cap": "36045"
  },
  {
    "key": "7575",
    "ITA": "LORANZE'-PEDANEA",
    "state_code": "TO",
    "cab": "190066",
    "cap": "10010"
  },
  {
    "key": "7576",
    "ITA": "LORANZE'",
    "state_code": "TO",
    "cab": "190066",
    "cap": "10010"
  },
  {
    "key": "7577",
    "ITA": "LOREGGIA",
    "state_code": "PD",
    "cab": "630301",
    "cap": "35010"
  },
  {
    "key": "7578",
    "ITA": "LOREGGIA-LOREGGIOLA",
    "state_code": "PD",
    "cab": "630301",
    "cap": "35010"
  },
  {
    "key": "7579",
    "ITA": "LOREGLIA",
    "state_code": "VB",
    "cab": "917377",
    "cap": "28893"
  },
  {
    "key": "7580",
    "ITA": "LORENZAGO DI CADORE-VILLAPICCOLA",
    "state_code": "BL",
    "cab": "611905",
    "cap": "32040"
  },
  {
    "key": "7581",
    "ITA": "LORENZAGO DI CADORE",
    "state_code": "BL",
    "cab": "611905",
    "cap": "32040"
  },
  {
    "key": "7582",
    "ITA": "LORENZANA",
    "state_code": "PI",
    "cab": "710400",
    "cap": "56043"
  },
  {
    "key": "7583",
    "ITA": "LOREO",
    "state_code": "RO",
    "cab": "633503",
    "cap": "45017"
  },
  {
    "key": "7584",
    "ITA": "LORETO",
    "state_code": "AN",
    "cab": "373803",
    "cap": "60025"
  },
  {
    "key": "7585",
    "ITA": "LORETO-VILLA MUSONE",
    "state_code": "AN",
    "cab": "373803",
    "cap": "60025"
  },
  {
    "key": "7586",
    "ITA": "LORETO-LORETO STAZIONE",
    "state_code": "AN",
    "cab": "373803",
    "cap": "60025"
  },
  {
    "key": "7587",
    "ITA": "LORETO APRUTINO",
    "state_code": "PE",
    "cab": "773200",
    "cap": "65014"
  },
  {
    "key": "7588",
    "ITA": "LORIA",
    "state_code": "TV",
    "cab": "617506",
    "cap": "31037"
  },
  {
    "key": "7589",
    "ITA": "LORIA-RAMON CAMPAGNA",
    "state_code": "TV",
    "cab": "617506",
    "cap": "31037"
  },
  {
    "key": "7590",
    "ITA": "LORIA-CASTIONE",
    "state_code": "TV",
    "cab": "617506",
    "cap": "31037"
  },
  {
    "key": "7591",
    "ITA": "LORIA-BESSICA",
    "state_code": "TV",
    "cab": "617506",
    "cap": "31037"
  },
  {
    "key": "7592",
    "ITA": "LORO CIUFFENNA",
    "state_code": "AR",
    "cab": "714808",
    "cap": "52024"
  },
  {
    "key": "7593",
    "ITA": "LORO CIUFFENNA-SAN GIUSTINO VALDARNO",
    "state_code": "AR",
    "cab": "714808",
    "cap": "52024"
  },
  {
    "key": "7594",
    "ITA": "LORO PICENO",
    "state_code": "MC",
    "cab": "689406",
    "cap": "62020"
  },
  {
    "key": "7595",
    "ITA": "LORSICA",
    "state_code": "GE",
    "cab": "903195",
    "cap": "16045"
  },
  {
    "key": "7596",
    "ITA": "LOSINE",
    "state_code": "BS",
    "cab": "929430",
    "cap": "25050"
  },
  {
    "key": "7597",
    "ITA": "LOTZORAI-TANCAU SUL MARE",
    "state_code": "OG",
    "cab": "866905",
    "cap": "8040"
  },
  {
    "key": "7598",
    "ITA": "LOTZORAI",
    "state_code": "OG",
    "cab": "866905",
    "cap": "8040"
  },
  {
    "key": "7599",
    "ITA": "LOVERE",
    "state_code": "BG",
    "cab": "531707",
    "cap": "24065"
  },
  {
    "key": "7600",
    "ITA": "LOVERO",
    "state_code": "SO",
    "cab": "927129",
    "cap": "23030"
  },
  {
    "key": "7601",
    "ITA": "LOZIO",
    "state_code": "BS",
    "cab": "546705",
    "cap": "25040"
  },
  {
    "key": "7602",
    "ITA": "LOZZA",
    "state_code": "VA",
    "cab": "924563",
    "cap": "21040"
  },
  {
    "key": "7603",
    "ITA": "LOZZO ATESTINO",
    "state_code": "PD",
    "cab": "626200",
    "cap": "35034"
  },
  {
    "key": "7604",
    "ITA": "LOZZO ATESTINO-LANZETTA",
    "state_code": "PD",
    "cab": "626200",
    "cap": "35034"
  },
  {
    "key": "7605",
    "ITA": "LOZZO DI CADORE",
    "state_code": "BL",
    "cab": "612002",
    "cap": "32040"
  },
  {
    "key": "7606",
    "ITA": "LOZZOLO",
    "state_code": "VC",
    "cab": "915983",
    "cap": "13045"
  },
  {
    "key": "7607",
    "ITA": "LU",
    "state_code": "AL",
    "cab": "483404",
    "cap": "15040"
  },
  {
    "key": "7608",
    "ITA": "LUBRIANO",
    "state_code": "VT",
    "cab": "681700",
    "cap": "1020"
  },
  {
    "key": "7609",
    "ITA": "LUCCA",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7610",
    "ITA": "LUCCA-CERASOMMA",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7611",
    "ITA": "LUCCA-FAGNANO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7612",
    "ITA": "LUCCA-GATTAIOLA",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7613",
    "ITA": "LUCCA-MAGGIANO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7614",
    "ITA": "LUCCA-MASSA PISANA",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7615",
    "ITA": "LUCCA-MONTE SAN QUIRICO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7616",
    "ITA": "LUCCA-MONTUOLO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7617",
    "ITA": "LUCCA-MUTIGLIANO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7618",
    "ITA": "LUCCA-NOZZANO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7619",
    "ITA": "LUCCA-PIAGGIONE",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7620",
    "ITA": "LUCCA-PICCIORANA",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7621",
    "ITA": "LUCCA-PONTE A MORIANO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7622",
    "ITA": "LUCCA-PONTE DEL GIGLIO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7623",
    "ITA": "LUCCA-PONTE SAN PIETRO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7624",
    "ITA": "LUCCA-PONTETETTO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7625",
    "ITA": "LUCCA-SALTOCCHIO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7626",
    "ITA": "LUCCA-SAN CONCORDIO DI MORIANO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7627",
    "ITA": "LUCCA-SAN LORENZO DI MORIANO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7628",
    "ITA": "LUCCA-SAN PIETRO A VICO",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7629",
    "ITA": "LUCCA-SANTA MARIA DEL GIUDICE",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7630",
    "ITA": "LUCCA-VINCHIANA",
    "state_code": "LU",
    "cab": "137000",
    "cap": "55100"
  },
  {
    "key": "7631",
    "ITA": "LUCCA SICULA",
    "state_code": "AG",
    "cab": "829804",
    "cap": "92010"
  },
  {
    "key": "7632",
    "ITA": "LUCERA",
    "state_code": "FG",
    "cab": "784405",
    "cap": "71036"
  },
  {
    "key": "7633",
    "ITA": "LUCERA-PALMORI",
    "state_code": "FG",
    "cab": "784405",
    "cap": "71036"
  },
  {
    "key": "7634",
    "ITA": "LUCIGNANO",
    "state_code": "AR",
    "cab": "714907",
    "cap": "52046"
  },
  {
    "key": "7635",
    "ITA": "LUCINASCO",
    "state_code": "IM",
    "cab": "922450",
    "cap": "18020"
  },
  {
    "key": "7636",
    "ITA": "LUCITO",
    "state_code": "CB",
    "cab": "910620",
    "cap": "86030"
  },
  {
    "key": "7637",
    "ITA": "LUCO DEI MARSI",
    "state_code": "AQ",
    "cab": "406009",
    "cap": "67056"
  },
  {
    "key": "7638",
    "ITA": "LUCOLI-CASAMAINA",
    "state_code": "AQ",
    "cab": "909721",
    "cap": "67045"
  },
  {
    "key": "7639",
    "ITA": "LUCOLI",
    "state_code": "AQ",
    "cab": "909721",
    "cap": "67045"
  },
  {
    "key": "7640",
    "ITA": "LUCOLI-VILLE DI LUCOLI",
    "state_code": "AQ",
    "cab": "909721",
    "cap": "67045"
  },
  {
    "key": "7641",
    "ITA": "LUGAGNANO VAL D'ARDA",
    "state_code": "PC",
    "cab": "653501",
    "cap": "29018"
  },
  {
    "key": "7642",
    "ITA": "LUGAGNANO VAL D'ARDA-RUSTIGAZZO",
    "state_code": "PC",
    "cab": "653501",
    "cap": "29018"
  },
  {
    "key": "7643",
    "ITA": "LUGAGNANO VAL D'ARDA-CHIAVENNA ROCCHETTA",
    "state_code": "PC",
    "cab": "653501",
    "cap": "29018"
  },
  {
    "key": "7644",
    "ITA": "LUGNACCO",
    "state_code": "TO",
    "cab": "283754",
    "cap": "10080"
  },
  {
    "key": "7645",
    "ITA": "LUGNANO IN TEVERINA",
    "state_code": "TR",
    "cab": "726505",
    "cap": "5020"
  },
  {
    "key": "7646",
    "ITA": "LUGO-BELRICETTO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7647",
    "ITA": "LUGO-GIOVECCA",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7648",
    "ITA": "LUGO-PASSOGATTO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7649",
    "ITA": "LUGO-SAN BERNARDINO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7650",
    "ITA": "LUGO-SAN LORENZO DI LUGO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7651",
    "ITA": "LUGO-SANTA MARIA IN FABRIAGO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7652",
    "ITA": "LUGO-VILLA SAN MARTINO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7653",
    "ITA": "LUGO-VOLTANA",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7654",
    "ITA": "LUGO-ASCENSIONE",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7655",
    "ITA": "LUGO-SAN POTITO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7656",
    "ITA": "LUGO",
    "state_code": "RA",
    "cab": "238006",
    "cap": "48022"
  },
  {
    "key": "7657",
    "ITA": "LUGO DI VICENZA",
    "state_code": "VI",
    "cab": "604603",
    "cap": "36030"
  },
  {
    "key": "7658",
    "ITA": "LUINO",
    "state_code": "VA",
    "cab": "504001",
    "cap": "21016"
  },
  {
    "key": "7659",
    "ITA": "LUINO-CREVA",
    "state_code": "VA",
    "cab": "504001",
    "cap": "21016"
  },
  {
    "key": "7660",
    "ITA": "LUINO-VOLDOMINO",
    "state_code": "VA",
    "cab": "504001",
    "cap": "21016"
  },
  {
    "key": "7661",
    "ITA": "LUINO-POPPINO",
    "state_code": "VA",
    "cab": "504001",
    "cap": "21016"
  },
  {
    "key": "7662",
    "ITA": "LUISAGO",
    "state_code": "CO",
    "cab": "478701",
    "cap": "22070"
  },
  {
    "key": "7663",
    "ITA": "LUISAGO-PORTICHETTO",
    "state_code": "CO",
    "cab": "478701",
    "cap": "22070"
  },
  {
    "key": "7664",
    "ITA": "LULA",
    "state_code": "NU",
    "cab": "867002",
    "cap": "8020"
  },
  {
    "key": "7665",
    "ITA": "LUMARZO",
    "state_code": "GE",
    "cab": "903203",
    "cap": "16024"
  },
  {
    "key": "7666",
    "ITA": "LUMEZZANE-SAN SEBASTIANO",
    "state_code": "BS",
    "cab": "546804",
    "cap": "25065"
  },
  {
    "key": "7667",
    "ITA": "LUMEZZANE-PIEVE",
    "state_code": "BS",
    "cab": "546804",
    "cap": "25065"
  },
  {
    "key": "7668",
    "ITA": "LUMEZZANE",
    "state_code": "BS",
    "cab": "546804",
    "cap": "25065"
  },
  {
    "key": "7669",
    "ITA": "LUMEZZANE-SANT'APOLLONIO",
    "state_code": "BS",
    "cab": "546804",
    "cap": "25065"
  },
  {
    "key": "7670",
    "ITA": "LUNAMATRONA",
    "state_code": "SU",
    "cab": "859900",
    "cap": "9022"
  },
  {
    "key": "7671",
    "ITA": "LUNANO",
    "state_code": "PU",
    "cab": "858506",
    "cap": "61026"
  },
  {
    "key": "7672",
    "ITA": "LUNGAVILLA",
    "state_code": "PV",
    "cab": "559807",
    "cap": "27053"
  },
  {
    "key": "7673",
    "ITA": "LUNGRO",
    "state_code": "CS",
    "cab": "807909",
    "cap": "87010"
  },
  {
    "key": "7674",
    "ITA": "LUOGOSANO",
    "state_code": "AV",
    "cab": "951210",
    "cap": "83040"
  },
  {
    "key": "7675",
    "ITA": "LUOGOSANTO",
    "state_code": "OT",
    "cab": "874008",
    "cap": "7020"
  },
  {
    "key": "7676",
    "ITA": "LUPARA",
    "state_code": "CB",
    "cab": "910638",
    "cap": "86030"
  },
  {
    "key": "7677",
    "ITA": "LURAGO D'ERBA",
    "state_code": "CO",
    "cab": "514703",
    "cap": "22040"
  },
  {
    "key": "7678",
    "ITA": "LURAGO MARINONE",
    "state_code": "CO",
    "cab": "893008",
    "cap": "22070"
  },
  {
    "key": "7679",
    "ITA": "LURANO",
    "state_code": "BG",
    "cab": "738401",
    "cap": "24050"
  },
  {
    "key": "7680",
    "ITA": "LURAS",
    "state_code": "OT",
    "cab": "874107",
    "cap": "7025"
  },
  {
    "key": "7681",
    "ITA": "LURATE CACCIVIO",
    "state_code": "CO",
    "cab": "514802",
    "cap": "22075"
  },
  {
    "key": "7682",
    "ITA": "LUSCIANO",
    "state_code": "CE",
    "cab": "752006",
    "cap": "81030"
  },
  {
    "key": "7683",
    "ITA": "LUSERNA",
    "state_code": "TN",
    "cab": "349902",
    "cap": "38040"
  },
  {
    "key": "7684",
    "ITA": "LUSERNA SAN GIOVANNI-LUSERNA",
    "state_code": "TO",
    "cab": "306001",
    "cap": "10062"
  },
  {
    "key": "7685",
    "ITA": "LUSERNA SAN GIOVANNI-AIRALI",
    "state_code": "TO",
    "cab": "306001",
    "cap": "10062"
  },
  {
    "key": "7686",
    "ITA": "LUSERNA SAN GIOVANNI",
    "state_code": "TO",
    "cab": "306001",
    "cap": "10062"
  },
  {
    "key": "7687",
    "ITA": "LUSERNETTA",
    "state_code": "TO",
    "cab": "900787",
    "cap": "10060"
  },
  {
    "key": "7688",
    "ITA": "LUSEVERA-VEDRONZA",
    "state_code": "UD",
    "cab": "936989",
    "cap": "33010"
  },
  {
    "key": "7689",
    "ITA": "LUSEVERA",
    "state_code": "UD",
    "cab": "936989",
    "cap": "33010"
  },
  {
    "key": "7690",
    "ITA": "LUSIA",
    "state_code": "RO",
    "cab": "633602",
    "cap": "45020"
  },
  {
    "key": "7691",
    "ITA": "LUSIA-CA MOROSINI",
    "state_code": "RO",
    "cab": "633602",
    "cap": "45020"
  },
  {
    "key": "7692",
    "ITA": "LUSIA-CAVAZZANA",
    "state_code": "RO",
    "cab": "633602",
    "cap": "45020"
  },
  {
    "key": "7693",
    "ITA": "LUSIANA",
    "state_code": "VI",
    "cab": "604702",
    "cap": "36046"
  },
  {
    "key": "7694",
    "ITA": "LUSIANA-SANTA CATERINA",
    "state_code": "VI",
    "cab": "604702",
    "cap": "36046"
  },
  {
    "key": "7695",
    "ITA": "LUSIGLIE'",
    "state_code": "TO",
    "cab": "283788",
    "cap": "10080"
  },
  {
    "key": "7696",
    "ITA": "LUSON",
    "state_code": "BZ",
    "cab": "585208",
    "cap": "39040"
  },
  {
    "key": "7697",
    "ITA": "LUSTRA",
    "state_code": "SA",
    "cab": "952622",
    "cap": "84050"
  },
  {
    "key": "7698",
    "ITA": "LUVINATE",
    "state_code": "VA",
    "cab": "924571",
    "cap": "21020"
  },
  {
    "key": "7699",
    "ITA": "LUZZANA",
    "state_code": "BG",
    "cab": "928275",
    "cap": "24069"
  },
  {
    "key": "7700",
    "ITA": "LUZZARA-CODISOTTO",
    "state_code": "RE",
    "cab": "663807",
    "cap": "42045"
  },
  {
    "key": "7701",
    "ITA": "LUZZARA-CASONI",
    "state_code": "RE",
    "cab": "663807",
    "cap": "42045"
  },
  {
    "key": "7702",
    "ITA": "LUZZARA-VILLAROTTA",
    "state_code": "RE",
    "cab": "663807",
    "cap": "42045"
  },
  {
    "key": "7703",
    "ITA": "LUZZARA",
    "state_code": "RE",
    "cab": "663807",
    "cap": "42045"
  },
  {
    "key": "7704",
    "ITA": "LUZZI",
    "state_code": "CS",
    "cab": "808006",
    "cap": "87040"
  },
  {
    "key": "7705",
    "ITA": "LUZZI-DEPOSITO DI LUZZI",
    "state_code": "CS",
    "cab": "808006",
    "cap": "87040"
  },
  {
    "key": "7706",
    "ITA": "LUZZI-TIMPARELLO",
    "state_code": "CS",
    "cab": "808006",
    "cap": "87040"
  },
  {
    "key": "7707",
    "ITA": "MACCAGNO",
    "state_code": "VA",
    "cab": "507103",
    "cap": "21061"
  },
  {
    "key": "7708",
    "ITA": "MACCASTORNA",
    "state_code": "LO",
    "cab": "903914",
    "cap": "26843"
  },
  {
    "key": "7709",
    "ITA": "MACCHIA D'ISERNIA",
    "state_code": "IS",
    "cab": "185967",
    "cap": "86070"
  },
  {
    "key": "7710",
    "ITA": "MACCHIA VALFORTORE",
    "state_code": "CB",
    "cab": "910646",
    "cap": "86040"
  },
  {
    "key": "7711",
    "ITA": "MACCHIAGODENA",
    "state_code": "IS",
    "cab": "781401",
    "cap": "86096"
  },
  {
    "key": "7712",
    "ITA": "MACCHIAGODENA-INCORONATA",
    "state_code": "IS",
    "cab": "781401",
    "cap": "86096"
  },
  {
    "key": "7713",
    "ITA": "MACELLO",
    "state_code": "TO",
    "cab": "190033",
    "cap": "10060"
  },
  {
    "key": "7714",
    "ITA": "MACERATA-VILLA POTENZA",
    "state_code": "MC",
    "cab": "134007",
    "cap": "62100"
  },
  {
    "key": "7715",
    "ITA": "MACERATA-SFORZACOSTA",
    "state_code": "MC",
    "cab": "134007",
    "cap": "62100"
  },
  {
    "key": "7716",
    "ITA": "MACERATA-PIEDIRIPA",
    "state_code": "MC",
    "cab": "134007",
    "cap": "62100"
  },
  {
    "key": "7717",
    "ITA": "MACERATA-MADONNA DEL MONTE",
    "state_code": "MC",
    "cab": "134007",
    "cap": "62100"
  },
  {
    "key": "7718",
    "ITA": "MACERATA-CORRIDONIA STAZIONE",
    "state_code": "MC",
    "cab": "134007",
    "cap": "62100"
  },
  {
    "key": "7719",
    "ITA": "MACERATA",
    "state_code": "MC",
    "cab": "134007",
    "cap": "62100"
  },
  {
    "key": "7720",
    "ITA": "MACERATA CAMPANIA-CATURANO",
    "state_code": "CE",
    "cab": "751305",
    "cap": "81047"
  },
  {
    "key": "7721",
    "ITA": "MACERATA CAMPANIA",
    "state_code": "CE",
    "cab": "751305",
    "cap": "81047"
  },
  {
    "key": "7722",
    "ITA": "MACERATA FELTRIA",
    "state_code": "PU",
    "cab": "683409",
    "cap": "61023"
  },
  {
    "key": "7723",
    "ITA": "MACHERIO",
    "state_code": "MB",
    "cab": "333104",
    "cap": "20050"
  },
  {
    "key": "7724",
    "ITA": "MACLODIO",
    "state_code": "BS",
    "cab": "895300",
    "cap": "25030"
  },
  {
    "key": "7725",
    "ITA": "MACOMER",
    "state_code": "NU",
    "cab": "853408",
    "cap": "8015"
  },
  {
    "key": "7726",
    "ITA": "MACRA",
    "state_code": "CN",
    "cab": "918813",
    "cap": "12020"
  },
  {
    "key": "7727",
    "ITA": "MACUGNAGA",
    "state_code": "VB",
    "cab": "454801",
    "cap": "28876"
  },
  {
    "key": "7728",
    "ITA": "MACUGNAGA-BORCA",
    "state_code": "VB",
    "cab": "454801",
    "cap": "28876"
  },
  {
    "key": "7729",
    "ITA": "MACUGNAGA-PESTARENA",
    "state_code": "VB",
    "cab": "454801",
    "cap": "28876"
  },
  {
    "key": "7730",
    "ITA": "MADDALONI-MONTEDECORO",
    "state_code": "CE",
    "cab": "748905",
    "cap": "81024"
  },
  {
    "key": "7731",
    "ITA": "MADDALONI-GROTTICELLA",
    "state_code": "CE",
    "cab": "748905",
    "cap": "81024"
  },
  {
    "key": "7732",
    "ITA": "MADDALONI",
    "state_code": "CE",
    "cab": "748905",
    "cap": "81024"
  },
  {
    "key": "7733",
    "ITA": "MADESIMO-MONTESPLUGA",
    "state_code": "SO",
    "cab": "521807",
    "cap": "23024"
  },
  {
    "key": "7734",
    "ITA": "MADESIMO-PIANAZZO",
    "state_code": "SO",
    "cab": "521807",
    "cap": "23024"
  },
  {
    "key": "7735",
    "ITA": "MADESIMO-ISOLA",
    "state_code": "SO",
    "cab": "521807",
    "cap": "23024"
  },
  {
    "key": "7736",
    "ITA": "MADESIMO",
    "state_code": "SO",
    "cab": "521807",
    "cap": "23024"
  },
  {
    "key": "7737",
    "ITA": "MADIGNANO",
    "state_code": "CR",
    "cab": "573303",
    "cap": "26020"
  },
  {
    "key": "7738",
    "ITA": "MADONE",
    "state_code": "BG",
    "cab": "531806",
    "cap": "24040"
  },
  {
    "key": "7739",
    "ITA": "MADONNA DEL SASSO",
    "state_code": "VB",
    "cab": "917385",
    "cap": "28894"
  },
  {
    "key": "7740",
    "ITA": "MAENZA",
    "state_code": "LT",
    "cab": "740209",
    "cap": "4010"
  },
  {
    "key": "7741",
    "ITA": "MAFALDA",
    "state_code": "CB",
    "cab": "411900",
    "cap": "86030"
  },
  {
    "key": "7742",
    "ITA": "MAGASA",
    "state_code": "BS",
    "cab": "929455",
    "cap": "25080"
  },
  {
    "key": "7743",
    "ITA": "MAGENTA",
    "state_code": "MI",
    "cab": "333203",
    "cap": "20013"
  },
  {
    "key": "7744",
    "ITA": "MAGENTA-PONTE NUOVO",
    "state_code": "MI",
    "cab": "333203",
    "cap": "20013"
  },
  {
    "key": "7745",
    "ITA": "MAGGIORA",
    "state_code": "NO",
    "cab": "458406",
    "cap": "28014"
  },
  {
    "key": "7746",
    "ITA": "MAGHERNO",
    "state_code": "PV",
    "cab": "559906",
    "cap": "27010"
  },
  {
    "key": "7747",
    "ITA": "MAGIONE-VILLA",
    "state_code": "PG",
    "cab": "385005",
    "cap": "6063"
  },
  {
    "key": "7748",
    "ITA": "MAGIONE",
    "state_code": "PG",
    "cab": "385005",
    "cap": "6063"
  },
  {
    "key": "7749",
    "ITA": "MAGIONE-AGELLO",
    "state_code": "PG",
    "cab": "385005",
    "cap": "6063"
  },
  {
    "key": "7750",
    "ITA": "MAGIONE-SAN FELICIANO",
    "state_code": "PG",
    "cab": "385005",
    "cap": "6063"
  },
  {
    "key": "7751",
    "ITA": "MAGIONE-SAN SAVINO",
    "state_code": "PG",
    "cab": "385005",
    "cap": "6063"
  },
  {
    "key": "7752",
    "ITA": "MAGIONE-SANT'ARCANGELO",
    "state_code": "PG",
    "cab": "385005",
    "cap": "6063"
  },
  {
    "key": "7753",
    "ITA": "MAGIONE-SOCCORSO",
    "state_code": "PG",
    "cab": "385005",
    "cap": "6063"
  },
  {
    "key": "7754",
    "ITA": "MAGISANO",
    "state_code": "CZ",
    "cab": "913087",
    "cap": "88050"
  },
  {
    "key": "7755",
    "ITA": "MAGISANO-SAN PIETRO",
    "state_code": "CZ",
    "cab": "913087",
    "cap": "88050"
  },
  {
    "key": "7756",
    "ITA": "MAGLIANO ALFIERI",
    "state_code": "CN",
    "cab": "377002",
    "cap": "12050"
  },
  {
    "key": "7757",
    "ITA": "MAGLIANO ALFIERI-SANT'ANTONIO",
    "state_code": "CN",
    "cab": "377002",
    "cap": "12050"
  },
  {
    "key": "7758",
    "ITA": "MAGLIANO ALPI",
    "state_code": "CN",
    "cab": "464206",
    "cap": "12060"
  },
  {
    "key": "7759",
    "ITA": "MAGLIANO ALPI-MAGLIANO ALPI SOPRANO",
    "state_code": "CN",
    "cab": "464206",
    "cap": "12060"
  },
  {
    "key": "7760",
    "ITA": "MAGLIANO ALPI-MAGLIANO ALPI SOTTANO",
    "state_code": "CN",
    "cab": "464206",
    "cap": "12060"
  },
  {
    "key": "7761",
    "ITA": "MAGLIANO DE' MARSI",
    "state_code": "AQ",
    "cab": "406108",
    "cap": "67062"
  },
  {
    "key": "7762",
    "ITA": "MAGLIANO DE' MARSI-MARANO DEI MARSI",
    "state_code": "AQ",
    "cab": "406108",
    "cap": "67062"
  },
  {
    "key": "7763",
    "ITA": "MAGLIANO DE' MARSI-ROSCIOLO DEI MARSI",
    "state_code": "AQ",
    "cab": "406108",
    "cap": "67062"
  },
  {
    "key": "7764",
    "ITA": "MAGLIANO DI TENNA",
    "state_code": "FM",
    "cab": "565002",
    "cap": "63025"
  },
  {
    "key": "7765",
    "ITA": "MAGLIANO IN TOSCANA-MONTIANO",
    "state_code": "GR",
    "cab": "722702",
    "cap": "58051"
  },
  {
    "key": "7766",
    "ITA": "MAGLIANO IN TOSCANA-PERETA",
    "state_code": "GR",
    "cab": "722702",
    "cap": "58051"
  },
  {
    "key": "7767",
    "ITA": "MAGLIANO IN TOSCANA",
    "state_code": "GR",
    "cab": "722702",
    "cap": "58051"
  },
  {
    "key": "7768",
    "ITA": "MAGLIANO ROMANO",
    "state_code": "RM",
    "cab": "908194",
    "cap": "60"
  },
  {
    "key": "7769",
    "ITA": "MAGLIANO SABINA-FOGLIA",
    "state_code": "RI",
    "cab": "736702",
    "cap": "2046"
  },
  {
    "key": "7770",
    "ITA": "MAGLIANO SABINA",
    "state_code": "RI",
    "cab": "736702",
    "cap": "2046"
  },
  {
    "key": "7771",
    "ITA": "MAGLIANO VETERE",
    "state_code": "SA",
    "cab": "952630",
    "cap": "84050"
  },
  {
    "key": "7772",
    "ITA": "MAGLIANO VETERE-CAPIZZO",
    "state_code": "SA",
    "cab": "952630",
    "cap": "84050"
  },
  {
    "key": "7773",
    "ITA": "MAGLIANO VETERE-MAGLIANO NUOVO",
    "state_code": "SA",
    "cab": "952630",
    "cap": "84050"
  },
  {
    "key": "7774",
    "ITA": "MAGLIE-MORIGINO",
    "state_code": "LE",
    "cab": "797209",
    "cap": "73024"
  },
  {
    "key": "7775",
    "ITA": "MAGLIE",
    "state_code": "LE",
    "cab": "797209",
    "cap": "73024"
  },
  {
    "key": "7776",
    "ITA": "MAGLIOLO",
    "state_code": "SV",
    "cab": "923144",
    "cap": "17020"
  },
  {
    "key": "7777",
    "ITA": "MAGLIONE",
    "state_code": "TO",
    "cab": "283812",
    "cap": "10030"
  },
  {
    "key": "7778",
    "ITA": "MAGNACAVALLO",
    "state_code": "MN",
    "cab": "576900",
    "cap": "46020"
  },
  {
    "key": "7779",
    "ITA": "MAGNAGO-BIENATE",
    "state_code": "MI",
    "cab": "333302",
    "cap": "20020"
  },
  {
    "key": "7780",
    "ITA": "MAGNAGO",
    "state_code": "MI",
    "cab": "333302",
    "cap": "20020"
  },
  {
    "key": "7781",
    "ITA": "MAGNANO",
    "state_code": "BI",
    "cab": "915991",
    "cap": "13887"
  },
  {
    "key": "7782",
    "ITA": "MAGNANO IN RIVIERA-BORGO ZURINI",
    "state_code": "UD",
    "cab": "645309",
    "cap": "33010"
  },
  {
    "key": "7783",
    "ITA": "MAGNANO IN RIVIERA",
    "state_code": "UD",
    "cab": "645309",
    "cap": "33010"
  },
  {
    "key": "7784",
    "ITA": "MAGOMADAS-SA LUMENERA",
    "state_code": "OR",
    "cab": "867101",
    "cap": "8010"
  },
  {
    "key": "7785",
    "ITA": "MAGOMADAS",
    "state_code": "OR",
    "cab": "867101",
    "cap": "8010"
  },
  {
    "key": "7786",
    "ITA": "MAGRE' SULLA STRADA DEL VINO",
    "state_code": "BZ",
    "cab": "585307",
    "cap": "39040"
  },
  {
    "key": "7787",
    "ITA": "MAGREGLIO",
    "state_code": "CO",
    "cab": "519108",
    "cap": "22030"
  },
  {
    "key": "7788",
    "ITA": "MAIDA",
    "state_code": "CZ",
    "cab": "426205",
    "cap": "88025"
  },
  {
    "key": "7789",
    "ITA": "MAIERA'",
    "state_code": "CS",
    "cab": "958967",
    "cap": "87020"
  },
  {
    "key": "7790",
    "ITA": "MAIERATO",
    "state_code": "VV",
    "cab": "635706",
    "cap": "89843"
  },
  {
    "key": "7791",
    "ITA": "MAIOLATI SPONTINI",
    "state_code": "AN",
    "cab": "373902",
    "cap": "60030"
  },
  {
    "key": "7792",
    "ITA": "MAIOLATI SPONTINI-MOIE",
    "state_code": "AN",
    "cab": "373902",
    "cap": "60030"
  },
  {
    "key": "7793",
    "ITA": "MAIOLO",
    "state_code": "PU",
    "cab": "941468",
    "cap": "61010"
  },
  {
    "key": "7794",
    "ITA": "MAIORI-ERCHIE",
    "state_code": "SA",
    "cab": "762302",
    "cap": "84010"
  },
  {
    "key": "7795",
    "ITA": "MAIORI",
    "state_code": "SA",
    "cab": "762302",
    "cap": "84010"
  },
  {
    "key": "7796",
    "ITA": "MAIRAGO-BASIASCO",
    "state_code": "LO",
    "cab": "780304",
    "cap": "26825"
  },
  {
    "key": "7797",
    "ITA": "MAIRAGO",
    "state_code": "LO",
    "cab": "780304",
    "cap": "26825"
  },
  {
    "key": "7798",
    "ITA": "MAIRANO",
    "state_code": "BS",
    "cab": "546903",
    "cap": "25030"
  },
  {
    "key": "7799",
    "ITA": "MAIRANO-PIEVEDIZIO",
    "state_code": "BS",
    "cab": "546903",
    "cap": "25030"
  },
  {
    "key": "7800",
    "ITA": "MAISSANA",
    "state_code": "SP",
    "cab": "923748",
    "cap": "19010"
  },
  {
    "key": "7801",
    "ITA": "MAISSANA-TORZA",
    "state_code": "SP",
    "cab": "923748",
    "cap": "19010"
  },
  {
    "key": "7802",
    "ITA": "MAJANO",
    "state_code": "UD",
    "cab": "639203",
    "cap": "33030"
  },
  {
    "key": "7803",
    "ITA": "MAJANO-SAN TOMASO",
    "state_code": "UD",
    "cab": "639203",
    "cap": "33030"
  },
  {
    "key": "7804",
    "ITA": "MALAGNINO",
    "state_code": "CR",
    "cab": "931741",
    "cap": "26030"
  },
  {
    "key": "7805",
    "ITA": "MALALBERGO",
    "state_code": "BO",
    "cab": "368803",
    "cap": "40051"
  },
  {
    "key": "7806",
    "ITA": "MALALBERGO-ALTEDO",
    "state_code": "BO",
    "cab": "368803",
    "cap": "40051"
  },
  {
    "key": "7807",
    "ITA": "MALALBERGO-CASONI",
    "state_code": "BO",
    "cab": "368803",
    "cap": "40051"
  },
  {
    "key": "7808",
    "ITA": "MALALBERGO-PEGOLA",
    "state_code": "BO",
    "cab": "368803",
    "cap": "40051"
  },
  {
    "key": "7809",
    "ITA": "MALBORGHETTO VALBRUNA-MALBORGHETTO",
    "state_code": "UD",
    "cab": "937003",
    "cap": "33010"
  },
  {
    "key": "7810",
    "ITA": "MALBORGHETTO VALBRUNA",
    "state_code": "UD",
    "cab": "937003",
    "cap": "33010"
  },
  {
    "key": "7811",
    "ITA": "MALBORGHETTO VALBRUNA-UGOVIZZA",
    "state_code": "UD",
    "cab": "937003",
    "cap": "33010"
  },
  {
    "key": "7812",
    "ITA": "MALBORGHETTO VALBRUNA-VALBRUNA",
    "state_code": "UD",
    "cab": "937003",
    "cap": "33010"
  },
  {
    "key": "7813",
    "ITA": "MALCESINE",
    "state_code": "VR",
    "cab": "595504",
    "cap": "37018"
  },
  {
    "key": "7814",
    "ITA": "MALCESINE-CASSONE",
    "state_code": "VR",
    "cab": "595504",
    "cap": "37018"
  },
  {
    "key": "7815",
    "ITA": "MALE'",
    "state_code": "TN",
    "cab": "350009",
    "cap": "38027"
  },
  {
    "key": "7816",
    "ITA": "MALEGNO",
    "state_code": "BS",
    "cab": "547000",
    "cap": "25053"
  },
  {
    "key": "7817",
    "ITA": "MALEO",
    "state_code": "LO",
    "cab": "333401",
    "cap": "26847"
  },
  {
    "key": "7818",
    "ITA": "MALESCO-FINERO",
    "state_code": "VB",
    "cab": "454900",
    "cap": "28854"
  },
  {
    "key": "7819",
    "ITA": "MALESCO",
    "state_code": "VB",
    "cab": "454900",
    "cap": "28854"
  },
  {
    "key": "7820",
    "ITA": "MALESCO-ZORNASCO",
    "state_code": "VB",
    "cab": "454900",
    "cap": "28854"
  },
  {
    "key": "7821",
    "ITA": "MALETTO",
    "state_code": "CT",
    "cab": "840009",
    "cap": "95035"
  },
  {
    "key": "7822",
    "ITA": "MALFA",
    "state_code": "ME",
    "cab": "822700",
    "cap": "98050"
  },
  {
    "key": "7823",
    "ITA": "MALGESSO",
    "state_code": "VA",
    "cab": "924597",
    "cap": "21020"
  },
  {
    "key": "7824",
    "ITA": "MALGRATE",
    "state_code": "LC",
    "cab": "519702",
    "cap": "23864"
  },
  {
    "key": "7825",
    "ITA": "MALITO",
    "state_code": "CS",
    "cab": "958975",
    "cap": "87030"
  },
  {
    "key": "7826",
    "ITA": "MALLARE",
    "state_code": "SV",
    "cab": "923151",
    "cap": "17045"
  },
  {
    "key": "7827",
    "ITA": "MALLES VENOSTA-MAZIA",
    "state_code": "BZ",
    "cab": "585406",
    "cap": "39024"
  },
  {
    "key": "7828",
    "ITA": "MALLES VENOSTA-TARCES",
    "state_code": "BZ",
    "cab": "585406",
    "cap": "39024"
  },
  {
    "key": "7829",
    "ITA": "MALLES VENOSTA-PLANOL",
    "state_code": "BZ",
    "cab": "585406",
    "cap": "39024"
  },
  {
    "key": "7830",
    "ITA": "MALLES VENOSTA-LAUDES",
    "state_code": "BZ",
    "cab": "585406",
    "cap": "39024"
  },
  {
    "key": "7831",
    "ITA": "MALLES VENOSTA-BURGUSIO",
    "state_code": "BZ",
    "cab": "585406",
    "cap": "39024"
  },
  {
    "key": "7832",
    "ITA": "MALLES VENOSTA",
    "state_code": "BZ",
    "cab": "585406",
    "cap": "39024"
  },
  {
    "key": "7833",
    "ITA": "MALNATE-SAN SALVATORE",
    "state_code": "VA",
    "cab": "504100",
    "cap": "21046"
  },
  {
    "key": "7834",
    "ITA": "MALNATE",
    "state_code": "VA",
    "cab": "504100",
    "cap": "21046"
  },
  {
    "key": "7835",
    "ITA": "MALO-SAN TOMIO",
    "state_code": "VI",
    "cab": "604801",
    "cap": "36034"
  },
  {
    "key": "7836",
    "ITA": "MALO",
    "state_code": "VI",
    "cab": "604801",
    "cap": "36034"
  },
  {
    "key": "7837",
    "ITA": "MALONNO",
    "state_code": "BS",
    "cab": "547109",
    "cap": "25040"
  },
  {
    "key": "7838",
    "ITA": "MALOSCO",
    "state_code": "TN",
    "cab": "904938",
    "cap": "38013"
  },
  {
    "key": "7839",
    "ITA": "MALTIGNANO",
    "state_code": "AP",
    "cab": "382309",
    "cap": "63040"
  },
  {
    "key": "7840",
    "ITA": "MALTIGNANO-CASELLE",
    "state_code": "AP",
    "cab": "382309",
    "cap": "63040"
  },
  {
    "key": "7841",
    "ITA": "MALVAGNA",
    "state_code": "ME",
    "cab": "961417",
    "cap": "98030"
  },
  {
    "key": "7842",
    "ITA": "MALVICINO",
    "state_code": "AL",
    "cab": "921262",
    "cap": "15015"
  },
  {
    "key": "7843",
    "ITA": "MALVITO",
    "state_code": "CS",
    "cab": "888305",
    "cap": "87010"
  },
  {
    "key": "7844",
    "ITA": "MAMMOLA",
    "state_code": "RC",
    "cab": "814202",
    "cap": "89045"
  },
  {
    "key": "7845",
    "ITA": "MAMOIADA",
    "state_code": "NU",
    "cab": "867200",
    "cap": "8024"
  },
  {
    "key": "7846",
    "ITA": "MANCIANO-SATURNIA",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58014"
  },
  {
    "key": "7847",
    "ITA": "MANCIANO-POGGIO CAPANNE",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58014"
  },
  {
    "key": "7848",
    "ITA": "MANCIANO-SAN MARTINO SUL FIORA",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58014"
  },
  {
    "key": "7849",
    "ITA": "MANCIANO-POGGIO MURELLA",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58014"
  },
  {
    "key": "7850",
    "ITA": "MANCIANO-PODERI DI MONTEMERANO",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58014"
  },
  {
    "key": "7851",
    "ITA": "MANCIANO-MONTEMERANO",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58014"
  },
  {
    "key": "7852",
    "ITA": "MANCIANO-MARSILIANA",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58010"
  },
  {
    "key": "7853",
    "ITA": "MANCIANO",
    "state_code": "GR",
    "cab": "722801",
    "cap": "58014"
  },
  {
    "key": "7854",
    "ITA": "MANDANICI",
    "state_code": "ME",
    "cab": "889204",
    "cap": "98020"
  },
  {
    "key": "7855",
    "ITA": "MANDAS",
    "state_code": "CA",
    "cab": "860007",
    "cap": "9040"
  },
  {
    "key": "7856",
    "ITA": "MANDATORICCIO",
    "state_code": "CS",
    "cab": "808105",
    "cap": "87060"
  },
  {
    "key": "7857",
    "ITA": "MANDELA",
    "state_code": "RM",
    "cab": "908202",
    "cap": "20"
  },
  {
    "key": "7858",
    "ITA": "MANDELLO DEL LARIO",
    "state_code": "LC",
    "cab": "514901",
    "cap": "23826"
  },
  {
    "key": "7859",
    "ITA": "MANDELLO VITTA",
    "state_code": "NO",
    "cab": "917401",
    "cap": "28060"
  },
  {
    "key": "7860",
    "ITA": "MANDURIA",
    "state_code": "TA",
    "cab": "789206",
    "cap": "74024"
  },
  {
    "key": "7861",
    "ITA": "MANDURIA-SAN PIETRO IN BEVAGNA",
    "state_code": "TA",
    "cab": "789206",
    "cap": "74024"
  },
  {
    "key": "7862",
    "ITA": "MANDURIA-SPECCHIARICA",
    "state_code": "TA",
    "cab": "789206",
    "cap": "74024"
  },
  {
    "key": "7863",
    "ITA": "MANDURIA-UGGIANO MONTEFUSCO",
    "state_code": "TA",
    "cab": "789206",
    "cap": "74024"
  },
  {
    "key": "7864",
    "ITA": "MANERBA DEL GARDA-SOLAROLO",
    "state_code": "BS",
    "cab": "547208",
    "cap": "25080"
  },
  {
    "key": "7865",
    "ITA": "MANERBA DEL GARDA",
    "state_code": "BS",
    "cab": "547208",
    "cap": "25080"
  },
  {
    "key": "7866",
    "ITA": "MANERBIO",
    "state_code": "BS",
    "cab": "547307",
    "cap": "25025"
  },
  {
    "key": "7867",
    "ITA": "MANFREDONIA-SIPONTO",
    "state_code": "FG",
    "cab": "784504",
    "cap": "71043"
  },
  {
    "key": "7868",
    "ITA": "MANFREDONIA",
    "state_code": "FG",
    "cab": "784504",
    "cap": "71043"
  },
  {
    "key": "7869",
    "ITA": "MANFREDONIA-MEZZANONE",
    "state_code": "FG",
    "cab": "784504",
    "cap": "71040"
  },
  {
    "key": "7870",
    "ITA": "MANGO-SAN DONATO",
    "state_code": "CN",
    "cab": "464305",
    "cap": "12056"
  },
  {
    "key": "7871",
    "ITA": "MANGO",
    "state_code": "CN",
    "cab": "464305",
    "cap": "12056"
  },
  {
    "key": "7872",
    "ITA": "MANGONE-PIAN DEL LAGO",
    "state_code": "CS",
    "cab": "958991",
    "cap": "87050"
  },
  {
    "key": "7873",
    "ITA": "MANGONE",
    "state_code": "CS",
    "cab": "958991",
    "cap": "87050"
  },
  {
    "key": "7874",
    "ITA": "MANIACE",
    "state_code": "CT",
    "cab": "843607",
    "cap": "95030"
  },
  {
    "key": "7875",
    "ITA": "MANIAGO-CAMPAGNA",
    "state_code": "PN",
    "cab": "648907",
    "cap": "33085"
  },
  {
    "key": "7876",
    "ITA": "MANIAGO",
    "state_code": "PN",
    "cab": "648907",
    "cap": "33085"
  },
  {
    "key": "7877",
    "ITA": "MANOCALZATI",
    "state_code": "AV",
    "cab": "651208",
    "cap": "83030"
  },
  {
    "key": "7878",
    "ITA": "MANOCALZATI-SAN BARBATO",
    "state_code": "AV",
    "cab": "651208",
    "cap": "83030"
  },
  {
    "key": "7879",
    "ITA": "MANOPPELLO-RIPACORBARIA",
    "state_code": "PE",
    "cab": "773309",
    "cap": "65024"
  },
  {
    "key": "7880",
    "ITA": "MANOPPELLO-MANOPPELLO SCALO",
    "state_code": "PE",
    "cab": "773309",
    "cap": "65024"
  },
  {
    "key": "7881",
    "ITA": "MANOPPELLO",
    "state_code": "PE",
    "cab": "773309",
    "cap": "65024"
  },
  {
    "key": "7882",
    "ITA": "MANSUE'",
    "state_code": "TV",
    "cab": "622506",
    "cap": "31040"
  },
  {
    "key": "7883",
    "ITA": "MANTA",
    "state_code": "CN",
    "cab": "464404",
    "cap": "12030"
  },
  {
    "key": "7884",
    "ITA": "MANTELLO",
    "state_code": "SO",
    "cab": "927137",
    "cap": "23016"
  },
  {
    "key": "7885",
    "ITA": "MANTOVA-LUNETTA",
    "state_code": "MN",
    "cab": "115006",
    "cap": "46100"
  },
  {
    "key": "7886",
    "ITA": "MANTOVA-FRASSINO MANTOVANO",
    "state_code": "MN",
    "cab": "115006",
    "cap": "46100"
  },
  {
    "key": "7887",
    "ITA": "MANTOVA-FORMIGOSA",
    "state_code": "MN",
    "cab": "115006",
    "cap": "46100"
  },
  {
    "key": "7888",
    "ITA": "MANTOVA-BORGOVIRGILIANA",
    "state_code": "MN",
    "cab": "115006",
    "cap": "46100"
  },
  {
    "key": "7889",
    "ITA": "MANTOVA",
    "state_code": "MN",
    "cab": "115006",
    "cap": "46100"
  },
  {
    "key": "7890",
    "ITA": "MANZANO",
    "state_code": "UD",
    "cab": "639302",
    "cap": "33044"
  },
  {
    "key": "7891",
    "ITA": "MANZIANA",
    "state_code": "RM",
    "cab": "391706",
    "cap": "66"
  },
  {
    "key": "7892",
    "ITA": "MANZIANA-QUADRONI",
    "state_code": "RM",
    "cab": "391706",
    "cap": "66"
  },
  {
    "key": "7893",
    "ITA": "MAPELLO",
    "state_code": "BG",
    "cab": "531905",
    "cap": "24030"
  },
  {
    "key": "7894",
    "ITA": "MARA",
    "state_code": "SS",
    "cab": "874206",
    "cap": "7010"
  },
  {
    "key": "7895",
    "ITA": "MARACALAGONIS",
    "state_code": "CA",
    "cab": "860106",
    "cap": "9040"
  },
  {
    "key": "7896",
    "ITA": "MARANELLO",
    "state_code": "MO",
    "cab": "668202",
    "cap": "41053"
  },
  {
    "key": "7897",
    "ITA": "MARANELLO-GORZANO",
    "state_code": "MO",
    "cab": "668202",
    "cap": "41053"
  },
  {
    "key": "7898",
    "ITA": "MARANELLO-POZZA",
    "state_code": "MO",
    "cab": "668202",
    "cap": "41053"
  },
  {
    "key": "7899",
    "ITA": "MARANELLO-TORRE MAINA",
    "state_code": "MO",
    "cab": "668202",
    "cap": "41053"
  },
  {
    "key": "7900",
    "ITA": "MARANO DI NAPOLI-SAN ROCCO",
    "state_code": "NA",
    "cab": "399501",
    "cap": "80016"
  },
  {
    "key": "7901",
    "ITA": "MARANO DI NAPOLI-TORRE PISCITELLI",
    "state_code": "NA",
    "cab": "399501",
    "cap": "80016"
  },
  {
    "key": "7902",
    "ITA": "MARANO DI NAPOLI",
    "state_code": "NA",
    "cab": "399501",
    "cap": "80016"
  },
  {
    "key": "7903",
    "ITA": "MARANO DI VALPOLICELLA",
    "state_code": "VR",
    "cab": "600304",
    "cap": "37020"
  },
  {
    "key": "7904",
    "ITA": "MARANO DI VALPOLICELLA-VALGATARA",
    "state_code": "VR",
    "cab": "600304",
    "cap": "37020"
  },
  {
    "key": "7905",
    "ITA": "MARANO EQUO",
    "state_code": "RM",
    "cab": "908210",
    "cap": "20"
  },
  {
    "key": "7906",
    "ITA": "MARANO LAGUNARE",
    "state_code": "UD",
    "cab": "639401",
    "cap": "33050"
  },
  {
    "key": "7907",
    "ITA": "MARANO MARCHESATO",
    "state_code": "CS",
    "cab": "959007",
    "cap": "87040"
  },
  {
    "key": "7908",
    "ITA": "MARANO PRINCIPATO",
    "state_code": "CS",
    "cab": "959015",
    "cap": "87040"
  },
  {
    "key": "7909",
    "ITA": "MARANO SUL PANARO",
    "state_code": "MO",
    "cab": "668301",
    "cap": "41054"
  },
  {
    "key": "7910",
    "ITA": "MARANO SUL PANARO-FESTA'",
    "state_code": "MO",
    "cab": "668301",
    "cap": "41054"
  },
  {
    "key": "7911",
    "ITA": "MARANO SUL PANARO-VILLA BIANCA",
    "state_code": "MO",
    "cab": "668301",
    "cap": "41054"
  },
  {
    "key": "7912",
    "ITA": "MARANO SUL PANARO-OSPITALETTO",
    "state_code": "MO",
    "cab": "668301",
    "cap": "41054"
  },
  {
    "key": "7913",
    "ITA": "MARANO TICINO",
    "state_code": "NO",
    "cab": "458307",
    "cap": "28040"
  },
  {
    "key": "7914",
    "ITA": "MARANO VICENTINO",
    "state_code": "VI",
    "cab": "604900",
    "cap": "36035"
  },
  {
    "key": "7915",
    "ITA": "MARANZANA",
    "state_code": "AT",
    "cab": "920231",
    "cap": "14040"
  },
  {
    "key": "7916",
    "ITA": "MARATEA-MASSA",
    "state_code": "PZ",
    "cab": "420604",
    "cap": "85046"
  },
  {
    "key": "7917",
    "ITA": "MARATEA",
    "state_code": "PZ",
    "cab": "420604",
    "cap": "85046"
  },
  {
    "key": "7918",
    "ITA": "MARATEA-ACQUAFREDDA",
    "state_code": "PZ",
    "cab": "420604",
    "cap": "85046"
  },
  {
    "key": "7919",
    "ITA": "MARATEA-FIUMICELLO SANTA VENERE",
    "state_code": "PZ",
    "cab": "420604",
    "cap": "85046"
  },
  {
    "key": "7920",
    "ITA": "MARATEA-MARATEA PORTO",
    "state_code": "PZ",
    "cab": "420604",
    "cap": "85046"
  },
  {
    "key": "7921",
    "ITA": "MARCALLO CON CASONE-CASONE",
    "state_code": "MI",
    "cab": "333500",
    "cap": "20010"
  },
  {
    "key": "7922",
    "ITA": "MARCALLO CON CASONE",
    "state_code": "MI",
    "cab": "333500",
    "cap": "20010"
  },
  {
    "key": "7923",
    "ITA": "MARCARIA-OSPITALETTO",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7924",
    "ITA": "MARCARIA-SAN MICHELE IN BOSCO",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7925",
    "ITA": "MARCARIA-GABBIANA",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7926",
    "ITA": "MARCARIA-CESOLE",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7927",
    "ITA": "MARCARIA",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7928",
    "ITA": "MARCARIA-CAMPITELLO",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7929",
    "ITA": "MARCARIA-CANICOSSA",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7930",
    "ITA": "MARCARIA-CASATICO",
    "state_code": "MN",
    "cab": "577007",
    "cap": "46010"
  },
  {
    "key": "7931",
    "ITA": "MARCEDUSA",
    "state_code": "CZ",
    "cab": "913103",
    "cap": "88050"
  },
  {
    "key": "7932",
    "ITA": "MARCELLINA",
    "state_code": "RM",
    "cab": "391805",
    "cap": "10"
  },
  {
    "key": "7933",
    "ITA": "MARCELLINARA",
    "state_code": "CZ",
    "cab": "887406",
    "cap": "88044"
  },
  {
    "key": "7934",
    "ITA": "MARCETELLI",
    "state_code": "RI",
    "cab": "947234",
    "cap": "2020"
  },
  {
    "key": "7935",
    "ITA": "MARCHENO-BROZZO",
    "state_code": "BS",
    "cab": "547406",
    "cap": "25060"
  },
  {
    "key": "7936",
    "ITA": "MARCHENO",
    "state_code": "BS",
    "cab": "547406",
    "cap": "25060"
  },
  {
    "key": "7937",
    "ITA": "MARCHIROLO",
    "state_code": "VA",
    "cab": "504209",
    "cap": "21030"
  },
  {
    "key": "7938",
    "ITA": "MARCIANA-PROCCHIO",
    "state_code": "LI",
    "cab": "707000",
    "cap": "57030"
  },
  {
    "key": "7939",
    "ITA": "MARCIANA-POMONTE",
    "state_code": "LI",
    "cab": "707000",
    "cap": "57030"
  },
  {
    "key": "7940",
    "ITA": "MARCIANA-POGGIO",
    "state_code": "LI",
    "cab": "707000",
    "cap": "57030"
  },
  {
    "key": "7941",
    "ITA": "MARCIANA",
    "state_code": "LI",
    "cab": "707000",
    "cap": "57030"
  },
  {
    "key": "7942",
    "ITA": "MARCIANA MARINA",
    "state_code": "LI",
    "cab": "707109",
    "cap": "57033"
  },
  {
    "key": "7943",
    "ITA": "MARCIANISE-CANTONE",
    "state_code": "CE",
    "cab": "749002",
    "cap": "81025"
  },
  {
    "key": "7944",
    "ITA": "MARCIANISE",
    "state_code": "CE",
    "cab": "749002",
    "cap": "81025"
  },
  {
    "key": "7945",
    "ITA": "MARCIANO DELLA CHIANA",
    "state_code": "AR",
    "cab": "715003",
    "cap": "52047"
  },
  {
    "key": "7946",
    "ITA": "MARCIANO DELLA CHIANA-CESA",
    "state_code": "AR",
    "cab": "715003",
    "cap": "52047"
  },
  {
    "key": "7947",
    "ITA": "MARCIANO DELLA CHIANA-MARCIANO",
    "state_code": "AR",
    "cab": "715003",
    "cap": "52047"
  },
  {
    "key": "7948",
    "ITA": "MARCIGNAGO",
    "state_code": "PV",
    "cab": "560003",
    "cap": "27020"
  },
  {
    "key": "7949",
    "ITA": "MARCON",
    "state_code": "VE",
    "cab": "361501",
    "cap": "30020"
  },
  {
    "key": "7950",
    "ITA": "MARCON-GAGGIO",
    "state_code": "VE",
    "cab": "361501",
    "cap": "30020"
  },
  {
    "key": "7951",
    "ITA": "MARCON-SAN LIBERALE",
    "state_code": "VE",
    "cab": "361501",
    "cap": "30020"
  },
  {
    "key": "7952",
    "ITA": "MAREBBE",
    "state_code": "BZ",
    "cab": "585505",
    "cap": "39030"
  },
  {
    "key": "7953",
    "ITA": "MAREBBE-SAN VIGILIO",
    "state_code": "BZ",
    "cab": "585505",
    "cap": "39030"
  },
  {
    "key": "7954",
    "ITA": "MAREBBE-LONGEGA",
    "state_code": "BZ",
    "cab": "585505",
    "cap": "39030"
  },
  {
    "key": "7955",
    "ITA": "MARENE",
    "state_code": "CN",
    "cab": "464503",
    "cap": "12030"
  },
  {
    "key": "7956",
    "ITA": "MARENO DI PIAVE",
    "state_code": "TV",
    "cab": "617605",
    "cap": "31010"
  },
  {
    "key": "7957",
    "ITA": "MARENO DI PIAVE-SANTA MARIA DEL PIAVE",
    "state_code": "TV",
    "cab": "617605",
    "cap": "31010"
  },
  {
    "key": "7958",
    "ITA": "MARENTINO",
    "state_code": "TO",
    "cab": "285064",
    "cap": "10020"
  },
  {
    "key": "7959",
    "ITA": "MARETTO",
    "state_code": "AT",
    "cab": "920249",
    "cap": "14018"
  },
  {
    "key": "7960",
    "ITA": "MARGARITA",
    "state_code": "CN",
    "cab": "464602",
    "cap": "12040"
  },
  {
    "key": "7961",
    "ITA": "MARGHERITA DI SAVOIA",
    "state_code": "BT",
    "cab": "784603",
    "cap": "71044"
  },
  {
    "key": "7962",
    "ITA": "MARGNO",
    "state_code": "LC",
    "cab": "925792",
    "cap": "23832"
  },
  {
    "key": "7963",
    "ITA": "MARIANA MANTOVANA",
    "state_code": "MN",
    "cab": "932327",
    "cap": "46010"
  },
  {
    "key": "7964",
    "ITA": "MARIANO COMENSE",
    "state_code": "CO",
    "cab": "515007",
    "cap": "22066"
  },
  {
    "key": "7965",
    "ITA": "MARIANO COMENSE-PERTICATO",
    "state_code": "CO",
    "cab": "515007",
    "cap": "22066"
  },
  {
    "key": "7966",
    "ITA": "MARIANO DEL FRIULI",
    "state_code": "GO",
    "cab": "646000",
    "cap": "34070"
  },
  {
    "key": "7967",
    "ITA": "MARIANO DEL FRIULI-CORONA",
    "state_code": "GO",
    "cab": "646000",
    "cap": "34070"
  },
  {
    "key": "7968",
    "ITA": "MARIANOPOLI",
    "state_code": "CL",
    "cab": "833400",
    "cap": "93010"
  },
  {
    "key": "7969",
    "ITA": "MARIGLIANELLA",
    "state_code": "NA",
    "cab": "647404",
    "cap": "80030"
  },
  {
    "key": "7970",
    "ITA": "MARIGLIANO",
    "state_code": "NA",
    "cab": "399600",
    "cap": "80034"
  },
  {
    "key": "7971",
    "ITA": "MARIGLIANO-CASAFERRO",
    "state_code": "NA",
    "cab": "399600",
    "cap": "80034"
  },
  {
    "key": "7972",
    "ITA": "MARIGLIANO-SELVA",
    "state_code": "NA",
    "cab": "399600",
    "cap": "80034"
  },
  {
    "key": "7973",
    "ITA": "MARIGLIANO-FAIBANO",
    "state_code": "NA",
    "cab": "399600",
    "cap": "80034"
  },
  {
    "key": "7974",
    "ITA": "MARIGLIANO-LAUSDOMINI",
    "state_code": "NA",
    "cab": "399600",
    "cap": "80034"
  },
  {
    "key": "7975",
    "ITA": "MARINA DI GIOIOSA IONICA",
    "state_code": "RC",
    "cab": "814301",
    "cap": "89046"
  },
  {
    "key": "7976",
    "ITA": "MARINEO",
    "state_code": "PA",
    "cab": "434209",
    "cap": "90035"
  },
  {
    "key": "7977",
    "ITA": "MARINO-SANTA MARIA DELLE MOLE",
    "state_code": "RM",
    "cab": "219006",
    "cap": "40"
  },
  {
    "key": "7978",
    "ITA": "MARINO-FRATTOCCHIE",
    "state_code": "RM",
    "cab": "219006",
    "cap": "40"
  },
  {
    "key": "7979",
    "ITA": "MARINO",
    "state_code": "RM",
    "cab": "219006",
    "cap": "47"
  },
  {
    "key": "7980",
    "ITA": "MARLENGO",
    "state_code": "BZ",
    "cab": "585604",
    "cap": "39020"
  },
  {
    "key": "7981",
    "ITA": "MARLIANA-MONTAGNANA",
    "state_code": "PT",
    "cab": "704205",
    "cap": "51030"
  },
  {
    "key": "7982",
    "ITA": "MARLIANA-AVAGLIO",
    "state_code": "PT",
    "cab": "704205",
    "cap": "51010"
  },
  {
    "key": "7983",
    "ITA": "MARLIANA-CASORE DEL MONTE",
    "state_code": "PT",
    "cab": "704205",
    "cap": "51010"
  },
  {
    "key": "7984",
    "ITA": "MARLIANA-MOMIGNO",
    "state_code": "PT",
    "cab": "704205",
    "cap": "51030"
  },
  {
    "key": "7985",
    "ITA": "MARLIANA",
    "state_code": "PT",
    "cab": "704205",
    "cap": "51010"
  },
  {
    "key": "7986",
    "ITA": "MARLIANA-SERRA PISTOIESE",
    "state_code": "PT",
    "cab": "704205",
    "cap": "51010"
  },
  {
    "key": "7987",
    "ITA": "MARMENTINO",
    "state_code": "BS",
    "cab": "929463",
    "cap": "25060"
  },
  {
    "key": "7988",
    "ITA": "MARMIROLO-POZZOLO",
    "state_code": "MN",
    "cab": "577106",
    "cap": "46045"
  },
  {
    "key": "7989",
    "ITA": "MARMIROLO-MARENGO",
    "state_code": "MN",
    "cab": "577106",
    "cap": "46045"
  },
  {
    "key": "7990",
    "ITA": "MARMIROLO",
    "state_code": "MN",
    "cab": "577106",
    "cap": "46045"
  },
  {
    "key": "7991",
    "ITA": "MARMORA",
    "state_code": "CN",
    "cab": "918839",
    "cap": "12020"
  },
  {
    "key": "7992",
    "ITA": "MARNATE",
    "state_code": "VA",
    "cab": "504308",
    "cap": "21050"
  },
  {
    "key": "7993",
    "ITA": "MARONE",
    "state_code": "BS",
    "cab": "547505",
    "cap": "25054"
  },
  {
    "key": "7994",
    "ITA": "MAROPATI",
    "state_code": "RC",
    "cab": "635607",
    "cap": "89020"
  },
  {
    "key": "7995",
    "ITA": "MAROPATI-TRITANTI",
    "state_code": "RC",
    "cab": "635607",
    "cap": "89020"
  },
  {
    "key": "7996",
    "ITA": "MAROSTICA-VALLONARA",
    "state_code": "VI",
    "cab": "605006",
    "cap": "36063"
  },
  {
    "key": "7997",
    "ITA": "MAROSTICA",
    "state_code": "VI",
    "cab": "605006",
    "cap": "36063"
  },
  {
    "key": "7998",
    "ITA": "MAROSTICA-CROSARA",
    "state_code": "VI",
    "cab": "605006",
    "cap": "36063"
  },
  {
    "key": "7999",
    "ITA": "MAROSTICA-VALLE SAN FLORIANO",
    "state_code": "VI",
    "cab": "605006",
    "cap": "36063"
  },
  {
    "key": "8000",
    "ITA": "MARRADI-CRESPINO DEL LAMONE",
    "state_code": "FI",
    "cab": "379404",
    "cap": "50034"
  },
  {
    "key": "8001",
    "ITA": "MARRADI",
    "state_code": "FI",
    "cab": "379404",
    "cap": "50034"
  },
  {
    "key": "8002",
    "ITA": "MARRADI-LUTIRANO",
    "state_code": "FI",
    "cab": "379404",
    "cap": "50034"
  },
  {
    "key": "8003",
    "ITA": "MARRUBIU-SANT'ANNA",
    "state_code": "OR",
    "cab": "855908",
    "cap": "9094"
  },
  {
    "key": "8004",
    "ITA": "MARRUBIU",
    "state_code": "OR",
    "cab": "855908",
    "cap": "9094"
  },
  {
    "key": "8005",
    "ITA": "MARSAGLIA",
    "state_code": "CN",
    "cab": "918847",
    "cap": "12060"
  },
  {
    "key": "8006",
    "ITA": "MARSALA-CIAVOLOTTO",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8007",
    "ITA": "MARSALA-PAOLINI",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8008",
    "ITA": "MARSALA-MATAROCCO",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8009",
    "ITA": "MARSALA-RAGATTISI",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8010",
    "ITA": "MARSALA-SANTO PADRE DELLE PERRIERE",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8011",
    "ITA": "MARSALA-SPAGNOLA",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8012",
    "ITA": "MARSALA-STRASATTI DI MARSALA",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8013",
    "ITA": "MARSALA-CIAVOLO",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8014",
    "ITA": "MARSALA-BUFALATA",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8015",
    "ITA": "MARSALA",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8016",
    "ITA": "MARSALA-TERRENOVE BAMBINA",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8017",
    "ITA": "MARSALA-TABACCARO",
    "state_code": "TP",
    "cab": "259002",
    "cap": "91025"
  },
  {
    "key": "8018",
    "ITA": "MARSCIANO-SAN BIAGIO DELLA VALLE",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6072"
  },
  {
    "key": "8019",
    "ITA": "MARSCIANO-PAPIANO",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6055"
  },
  {
    "key": "8020",
    "ITA": "MARSCIANO-MIGLIANO",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6072"
  },
  {
    "key": "8021",
    "ITA": "MARSCIANO-MERCATELLO",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6072"
  },
  {
    "key": "8022",
    "ITA": "MARSCIANO-COMPIGNANO",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6055"
  },
  {
    "key": "8023",
    "ITA": "MARSCIANO-CASTIGLIONE DELLA VALLE",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6072"
  },
  {
    "key": "8024",
    "ITA": "MARSCIANO-BADIOLA",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6072"
  },
  {
    "key": "8025",
    "ITA": "MARSCIANO",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6055"
  },
  {
    "key": "8026",
    "ITA": "MARSCIANO-SPINA",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6072"
  },
  {
    "key": "8027",
    "ITA": "MARSCIANO-SAN VALENTINO",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6055"
  },
  {
    "key": "8028",
    "ITA": "MARSCIANO-PIEVE CAINA",
    "state_code": "PG",
    "cab": "385104",
    "cap": "6072"
  },
  {
    "key": "8029",
    "ITA": "MARSICO NUOVO-PERGOLA",
    "state_code": "PZ",
    "cab": "420703",
    "cap": "85052"
  },
  {
    "key": "8030",
    "ITA": "MARSICO NUOVO-GALAINO",
    "state_code": "PZ",
    "cab": "420703",
    "cap": "85052"
  },
  {
    "key": "8031",
    "ITA": "MARSICO NUOVO",
    "state_code": "PZ",
    "cab": "420703",
    "cap": "85052"
  },
  {
    "key": "8032",
    "ITA": "MARSICOVETERE",
    "state_code": "PZ",
    "cab": "420802",
    "cap": "85050"
  },
  {
    "key": "8033",
    "ITA": "MARSICOVETERE-VILLA D'AGRI",
    "state_code": "PZ",
    "cab": "420802",
    "cap": "85050"
  },
  {
    "key": "8034",
    "ITA": "MARTA",
    "state_code": "VT",
    "cab": "731406",
    "cap": "1010"
  },
  {
    "key": "8035",
    "ITA": "MARTANO",
    "state_code": "LE",
    "cab": "797308",
    "cap": "73025"
  },
  {
    "key": "8036",
    "ITA": "MARTELLAGO-OLMO",
    "state_code": "VE",
    "cab": "361600",
    "cap": "30030"
  },
  {
    "key": "8037",
    "ITA": "MARTELLAGO-MAERNE",
    "state_code": "VE",
    "cab": "361600",
    "cap": "30030"
  },
  {
    "key": "8038",
    "ITA": "MARTELLAGO",
    "state_code": "VE",
    "cab": "361600",
    "cap": "30030"
  },
  {
    "key": "8039",
    "ITA": "MARTELLO",
    "state_code": "BZ",
    "cab": "585703",
    "cap": "39020"
  },
  {
    "key": "8040",
    "ITA": "MARTIGNACCO-TORREANO DI MARTIGNACCO",
    "state_code": "UD",
    "cab": "639500",
    "cap": "33035"
  },
  {
    "key": "8041",
    "ITA": "MARTIGNACCO-NOGAREDO DI PRATO",
    "state_code": "UD",
    "cab": "639500",
    "cap": "33035"
  },
  {
    "key": "8042",
    "ITA": "MARTIGNACCO",
    "state_code": "UD",
    "cab": "639500",
    "cap": "33035"
  },
  {
    "key": "8043",
    "ITA": "MARTIGNANA DI PO",
    "state_code": "CR",
    "cab": "573907",
    "cap": "26040"
  },
  {
    "key": "8044",
    "ITA": "MARTIGNANO",
    "state_code": "LE",
    "cab": "793406",
    "cap": "73020"
  },
  {
    "key": "8045",
    "ITA": "MARTINA FRANCA-SPECCHIA TARANTINA",
    "state_code": "TA",
    "cab": "789305",
    "cap": "74015"
  },
  {
    "key": "8046",
    "ITA": "MARTINA FRANCA-SAN PAOLO",
    "state_code": "TA",
    "cab": "789305",
    "cap": "74015"
  },
  {
    "key": "8047",
    "ITA": "MARTINA FRANCA-LANZO DI MARTINA FRANCA",
    "state_code": "TA",
    "cab": "789305",
    "cap": "74015"
  },
  {
    "key": "8048",
    "ITA": "MARTINA FRANCA-CARPARI",
    "state_code": "TA",
    "cab": "789305",
    "cap": "74015"
  },
  {
    "key": "8049",
    "ITA": "MARTINA FRANCA",
    "state_code": "TA",
    "cab": "789305",
    "cap": "74015"
  },
  {
    "key": "8050",
    "ITA": "MARTINENGO",
    "state_code": "BG",
    "cab": "532002",
    "cap": "24057"
  },
  {
    "key": "8051",
    "ITA": "MARTINIANA PO",
    "state_code": "CN",
    "cab": "918854",
    "cap": "12030"
  },
  {
    "key": "8052",
    "ITA": "MARTINSICURO",
    "state_code": "TE",
    "cab": "771006",
    "cap": "64014"
  },
  {
    "key": "8053",
    "ITA": "MARTINSICURO-VILLA ROSA",
    "state_code": "TE",
    "cab": "771006",
    "cap": "64014"
  },
  {
    "key": "8054",
    "ITA": "MARTIRANO",
    "state_code": "CZ",
    "cab": "913129",
    "cap": "88040"
  },
  {
    "key": "8055",
    "ITA": "MARTIRANO LOMBARDO",
    "state_code": "CZ",
    "cab": "614206",
    "cap": "88040"
  },
  {
    "key": "8056",
    "ITA": "MARTIS",
    "state_code": "SS",
    "cab": "874305",
    "cap": "7030"
  },
  {
    "key": "8057",
    "ITA": "MARTONE",
    "state_code": "RC",
    "cab": "960195",
    "cap": "89040"
  },
  {
    "key": "8058",
    "ITA": "MARUDO",
    "state_code": "LO",
    "cab": "903930",
    "cap": "26866"
  },
  {
    "key": "8059",
    "ITA": "MARUGGIO",
    "state_code": "TA",
    "cab": "789404",
    "cap": "74020"
  },
  {
    "key": "8060",
    "ITA": "MARZABOTTO-PIAN DI VENOLA",
    "state_code": "BO",
    "cab": "368902",
    "cap": "40043"
  },
  {
    "key": "8061",
    "ITA": "MARZABOTTO-LAMA DI SETTA",
    "state_code": "BO",
    "cab": "368902",
    "cap": "40043"
  },
  {
    "key": "8062",
    "ITA": "MARZABOTTO",
    "state_code": "BO",
    "cab": "368902",
    "cap": "40043"
  },
  {
    "key": "8063",
    "ITA": "MARZABOTTO-LAMA DI RENO",
    "state_code": "BO",
    "cab": "368902",
    "cap": "40043"
  },
  {
    "key": "8064",
    "ITA": "MARZANO",
    "state_code": "PV",
    "cab": "930560",
    "cap": "27010"
  },
  {
    "key": "8065",
    "ITA": "MARZANO APPIO",
    "state_code": "CE",
    "cab": "365304",
    "cap": "81035"
  },
  {
    "key": "8066",
    "ITA": "MARZANO APPIO-AMEGLIO",
    "state_code": "CE",
    "cab": "365304",
    "cap": "81035"
  },
  {
    "key": "8067",
    "ITA": "MARZANO APPIO-GROTTOLA",
    "state_code": "CE",
    "cab": "365304",
    "cap": "81035"
  },
  {
    "key": "8068",
    "ITA": "MARZANO APPIO-CAMPAGNOLA",
    "state_code": "CE",
    "cab": "365304",
    "cap": "81035"
  },
  {
    "key": "8069",
    "ITA": "MARZANO DI NOLA",
    "state_code": "AV",
    "cab": "951236",
    "cap": "83020"
  },
  {
    "key": "8070",
    "ITA": "MARZI",
    "state_code": "CS",
    "cab": "959023",
    "cap": "87050"
  },
  {
    "key": "8071",
    "ITA": "MARZIO",
    "state_code": "VA",
    "cab": "924605",
    "cap": "21030"
  },
  {
    "key": "8072",
    "ITA": "MASAINAS",
    "state_code": "SU",
    "cab": "915264",
    "cap": "9010"
  },
  {
    "key": "8073",
    "ITA": "MASATE",
    "state_code": "MI",
    "cab": "342303",
    "cap": "20060"
  },
  {
    "key": "8074",
    "ITA": "MASCALI",
    "state_code": "CT",
    "cab": "840108",
    "cap": "95016"
  },
  {
    "key": "8075",
    "ITA": "MASCALI-NUNZIATA",
    "state_code": "CT",
    "cab": "840108",
    "cap": "95016"
  },
  {
    "key": "8076",
    "ITA": "MASCALI-PORTOSALVO",
    "state_code": "CT",
    "cab": "840108",
    "cap": "95016"
  },
  {
    "key": "8077",
    "ITA": "MASCALI-SANTA VENERA",
    "state_code": "CT",
    "cab": "840108",
    "cap": "95016"
  },
  {
    "key": "8078",
    "ITA": "MASCALI-CARRABBA",
    "state_code": "CT",
    "cab": "840108",
    "cap": "95016"
  },
  {
    "key": "8079",
    "ITA": "MASCALI-PUNTALAZZO",
    "state_code": "CT",
    "cab": "840108",
    "cap": "95010"
  },
  {
    "key": "8080",
    "ITA": "MASCALUCIA",
    "state_code": "CT",
    "cab": "840207",
    "cap": "95030"
  },
  {
    "key": "8081",
    "ITA": "MASCHITO",
    "state_code": "PZ",
    "cab": "420901",
    "cap": "85020"
  },
  {
    "key": "8082",
    "ITA": "MASCIAGO PRIMO",
    "state_code": "VA",
    "cab": "924613",
    "cap": "21030"
  },
  {
    "key": "8083",
    "ITA": "MASER",
    "state_code": "TV",
    "cab": "617704",
    "cap": "31010"
  },
  {
    "key": "8084",
    "ITA": "MASER-CRESPIGNAGA",
    "state_code": "TV",
    "cab": "617704",
    "cap": "31010"
  },
  {
    "key": "8085",
    "ITA": "MASER-MULIPARTE",
    "state_code": "TV",
    "cab": "617704",
    "cap": "31010"
  },
  {
    "key": "8086",
    "ITA": "MASERA",
    "state_code": "VB",
    "cab": "917427",
    "cap": "28855"
  },
  {
    "key": "8087",
    "ITA": "MASERA' DI PADOVA-BERTIPAGLIA",
    "state_code": "PD",
    "cab": "626309",
    "cap": "35020"
  },
  {
    "key": "8088",
    "ITA": "MASERA' DI PADOVA",
    "state_code": "PD",
    "cab": "626309",
    "cap": "35020"
  },
  {
    "key": "8089",
    "ITA": "MASERADA SUL PIAVE-CANDELU'",
    "state_code": "TV",
    "cab": "617803",
    "cap": "31052"
  },
  {
    "key": "8090",
    "ITA": "MASERADA SUL PIAVE",
    "state_code": "TV",
    "cab": "617803",
    "cap": "31052"
  },
  {
    "key": "8091",
    "ITA": "MASERADA SUL PIAVE-VARAGO",
    "state_code": "TV",
    "cab": "617803",
    "cap": "31052"
  },
  {
    "key": "8092",
    "ITA": "MASI-COLOMBARE",
    "state_code": "PD",
    "cab": "882803",
    "cap": "35040"
  },
  {
    "key": "8093",
    "ITA": "MASI",
    "state_code": "PD",
    "cab": "882803",
    "cap": "35040"
  },
  {
    "key": "8094",
    "ITA": "MASI TORELLO-MASI SAN GIACOMO",
    "state_code": "FE",
    "cab": "672501",
    "cap": "44020"
  },
  {
    "key": "8095",
    "ITA": "MASI TORELLO",
    "state_code": "FE",
    "cab": "672501",
    "cap": "44020"
  },
  {
    "key": "8096",
    "ITA": "MASIO-ABBAZIA",
    "state_code": "AL",
    "cab": "483503",
    "cap": "15024"
  },
  {
    "key": "8097",
    "ITA": "MASIO",
    "state_code": "AL",
    "cab": "483503",
    "cap": "15024"
  },
  {
    "key": "8098",
    "ITA": "MASLIANICO",
    "state_code": "CO",
    "cab": "515106",
    "cap": "22026"
  },
  {
    "key": "8099",
    "ITA": "MASON VICENTINO",
    "state_code": "VI",
    "cab": "605105",
    "cap": "36064"
  },
  {
    "key": "8100",
    "ITA": "MASON VICENTINO-VILLARASPA",
    "state_code": "VI",
    "cab": "605105",
    "cap": "36064"
  },
  {
    "key": "8101",
    "ITA": "MASONE",
    "state_code": "GE",
    "cab": "320200",
    "cap": "16010"
  },
  {
    "key": "8102",
    "ITA": "MASSA-TURANO",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8103",
    "ITA": "MASSA-RONCHI",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8104",
    "ITA": "MASSA-QUERCIOLI",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8105",
    "ITA": "MASSA-MIRTETO",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8106",
    "ITA": "MASSA-MARINA DI MASSA",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8107",
    "ITA": "MASSA-ALTAGNANA",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8108",
    "ITA": "MASSA-CANEVARA",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8109",
    "ITA": "MASSA-CASETTE",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8110",
    "ITA": "MASSA-FORNO",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8111",
    "ITA": "MASSA",
    "state_code": "MS",
    "cab": "136002",
    "cap": "54100"
  },
  {
    "key": "8112",
    "ITA": "MASSA D'ALBE-FORME",
    "state_code": "AQ",
    "cab": "909739",
    "cap": "67050"
  },
  {
    "key": "8113",
    "ITA": "MASSA D'ALBE-ALBE",
    "state_code": "AQ",
    "cab": "909739",
    "cap": "67050"
  },
  {
    "key": "8114",
    "ITA": "MASSA D'ALBE",
    "state_code": "AQ",
    "cab": "909739",
    "cap": "67050"
  },
  {
    "key": "8115",
    "ITA": "MASSA D'ALBE-CORONA",
    "state_code": "AQ",
    "cab": "909739",
    "cap": "67050"
  },
  {
    "key": "8116",
    "ITA": "MASSA DI SOMMA",
    "state_code": "NA",
    "cab": "909044",
    "cap": "80040"
  },
  {
    "key": "8117",
    "ITA": "MASSA E COZZILE-MARGINE COPERTA",
    "state_code": "PT",
    "cab": "704304",
    "cap": "51010"
  },
  {
    "key": "8118",
    "ITA": "MASSA E COZZILE",
    "state_code": "PT",
    "cab": "704304",
    "cap": "51010"
  },
  {
    "key": "8119",
    "ITA": "MASSA E COZZILE-TRAVERSAGNA",
    "state_code": "PT",
    "cab": "704304",
    "cap": "51010"
  },
  {
    "key": "8120",
    "ITA": "MASSA FERMANA",
    "state_code": "FM",
    "cab": "698100",
    "cap": "63020"
  },
  {
    "key": "8121",
    "ITA": "MASSA FISCAGLIA",
    "state_code": "FE",
    "cab": "672600",
    "cap": "44025"
  },
  {
    "key": "8122",
    "ITA": "MASSA LOMBARDA-VILLA SERRAGLIO",
    "state_code": "RA",
    "cab": "675702",
    "cap": "48024"
  },
  {
    "key": "8123",
    "ITA": "MASSA LOMBARDA",
    "state_code": "RA",
    "cab": "675702",
    "cap": "48024"
  },
  {
    "key": "8124",
    "ITA": "MASSA LUBRENSE-TERMINI",
    "state_code": "NA",
    "cab": "399709",
    "cap": "80061"
  },
  {
    "key": "8125",
    "ITA": "MASSA LUBRENSE-NERANO",
    "state_code": "NA",
    "cab": "399709",
    "cap": "80061"
  },
  {
    "key": "8126",
    "ITA": "MASSA LUBRENSE-MONTICCHIO DI MASSA LUBRENSE",
    "state_code": "NA",
    "cab": "399709",
    "cap": "80061"
  },
  {
    "key": "8127",
    "ITA": "MASSA LUBRENSE",
    "state_code": "NA",
    "cab": "399709",
    "cap": "80061"
  },
  {
    "key": "8128",
    "ITA": "MASSA LUBRENSE-SANT'AGATA SUI DUE GOLFI",
    "state_code": "NA",
    "cab": "399709",
    "cap": "80061"
  },
  {
    "key": "8129",
    "ITA": "MASSA MARITTIMA-GHIRLANDA",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58024"
  },
  {
    "key": "8130",
    "ITA": "MASSA MARITTIMA-CAPANNE",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58024"
  },
  {
    "key": "8131",
    "ITA": "MASSA MARITTIMA",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58024"
  },
  {
    "key": "8132",
    "ITA": "MASSA MARITTIMA-VALPIANA",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58024"
  },
  {
    "key": "8133",
    "ITA": "MASSA MARITTIMA-TATTI",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58040"
  },
  {
    "key": "8134",
    "ITA": "MASSA MARITTIMA-PRATA",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58024"
  },
  {
    "key": "8135",
    "ITA": "MASSA MARITTIMA-NICCIOLETA",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58024"
  },
  {
    "key": "8136",
    "ITA": "MASSA MARITTIMA-MONTEBAMBOLI",
    "state_code": "GR",
    "cab": "722900",
    "cap": "58024"
  },
  {
    "key": "8137",
    "ITA": "MASSA MARTANA-COLPETRAZZO",
    "state_code": "PG",
    "cab": "385203",
    "cap": "6056"
  },
  {
    "key": "8138",
    "ITA": "MASSA MARTANA",
    "state_code": "PG",
    "cab": "385203",
    "cap": "6056"
  },
  {
    "key": "8139",
    "ITA": "MASSA MARTANA-VIEPRI",
    "state_code": "PG",
    "cab": "385203",
    "cap": "6056"
  },
  {
    "key": "8140",
    "ITA": "MASSA MARTANA-VILLA SAN FAUSTINO",
    "state_code": "PG",
    "cab": "385203",
    "cap": "6056"
  },
  {
    "key": "8141",
    "ITA": "MASSAFRA",
    "state_code": "TA",
    "cab": "789503",
    "cap": "74016"
  },
  {
    "key": "8142",
    "ITA": "MASSALENGO-MOTTA VIGANA",
    "state_code": "LO",
    "cab": "342402",
    "cap": "26815"
  },
  {
    "key": "8143",
    "ITA": "MASSALENGO",
    "state_code": "LO",
    "cab": "342402",
    "cap": "26815"
  },
  {
    "key": "8144",
    "ITA": "MASSANZAGO-CA' BAGLIONI",
    "state_code": "PD",
    "cab": "893305",
    "cap": "35010"
  },
  {
    "key": "8145",
    "ITA": "MASSANZAGO",
    "state_code": "PD",
    "cab": "893305",
    "cap": "35010"
  },
  {
    "key": "8146",
    "ITA": "MASSAROSA-PIANO DI MOMMIO",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55040"
  },
  {
    "key": "8147",
    "ITA": "MASSAROSA-STIAVA",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55040"
  },
  {
    "key": "8148",
    "ITA": "MASSAROSA-QUIESA",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55054"
  },
  {
    "key": "8149",
    "ITA": "MASSAROSA-PIEVE A ELICI",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55054"
  },
  {
    "key": "8150",
    "ITA": "MASSAROSA-GUALDO",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55054"
  },
  {
    "key": "8151",
    "ITA": "MASSAROSA",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55054"
  },
  {
    "key": "8152",
    "ITA": "MASSAROSA-BOZZANO",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55054"
  },
  {
    "key": "8153",
    "ITA": "MASSAROSA-CORSANICO",
    "state_code": "LU",
    "cab": "701706",
    "cap": "55040"
  },
  {
    "key": "8154",
    "ITA": "MASSAZZA",
    "state_code": "BI",
    "cab": "445502",
    "cap": "13873"
  },
  {
    "key": "8155",
    "ITA": "MASSELLO",
    "state_code": "TO",
    "cab": "900837",
    "cap": "10060"
  },
  {
    "key": "8156",
    "ITA": "MASSERANO",
    "state_code": "BI",
    "cab": "445601",
    "cap": "13866"
  },
  {
    "key": "8157",
    "ITA": "MASSIGNANO",
    "state_code": "AP",
    "cab": "802603",
    "cap": "63010"
  },
  {
    "key": "8158",
    "ITA": "MASSIMENO",
    "state_code": "TN",
    "cab": "904946",
    "cap": "38086"
  },
  {
    "key": "8159",
    "ITA": "MASSIMINO",
    "state_code": "SV",
    "cab": "923169",
    "cap": "12071"
  },
  {
    "key": "8160",
    "ITA": "MASSINO VISCONTI",
    "state_code": "NO",
    "cab": "471201",
    "cap": "28040"
  },
  {
    "key": "8161",
    "ITA": "MASSIOLA",
    "state_code": "VB",
    "cab": "917443",
    "cap": "28895"
  },
  {
    "key": "8162",
    "ITA": "MASSIOLA-FORNO DI OMEGNA",
    "state_code": "VB",
    "cab": "917443",
    "cap": "28895"
  },
  {
    "key": "8163",
    "ITA": "MASULLAS",
    "state_code": "OR",
    "cab": "878504",
    "cap": "9090"
  },
  {
    "key": "8164",
    "ITA": "MATELICA",
    "state_code": "MC",
    "cab": "689505",
    "cap": "62024"
  },
  {
    "key": "8165",
    "ITA": "MATELICA-COLFERRAIO",
    "state_code": "MC",
    "cab": "689505",
    "cap": "62024"
  },
  {
    "key": "8166",
    "ITA": "MATERA",
    "state_code": "MT",
    "cab": "161000",
    "cap": "75100"
  },
  {
    "key": "8167",
    "ITA": "MATERA-VENUSIO",
    "state_code": "MT",
    "cab": "161000",
    "cap": "75100"
  },
  {
    "key": "8168",
    "ITA": "MATERA-LA MARTELLA",
    "state_code": "MT",
    "cab": "161000",
    "cap": "75100"
  },
  {
    "key": "8169",
    "ITA": "MATHI",
    "state_code": "TO",
    "cab": "306100",
    "cap": "10075"
  },
  {
    "key": "8170",
    "ITA": "MATINO",
    "state_code": "LE",
    "cab": "797407",
    "cap": "73046"
  },
  {
    "key": "8171",
    "ITA": "MATRICE",
    "state_code": "CB",
    "cab": "910661",
    "cap": "86030"
  },
  {
    "key": "8172",
    "ITA": "MATTIE",
    "state_code": "TO",
    "cab": "190306",
    "cap": "10050"
  },
  {
    "key": "8173",
    "ITA": "MATTINATA",
    "state_code": "FG",
    "cab": "784702",
    "cap": "71030"
  },
  {
    "key": "8174",
    "ITA": "MAZARA DEL VALLO",
    "state_code": "TP",
    "cab": "818807",
    "cap": "91026"
  },
  {
    "key": "8175",
    "ITA": "MAZARA DEL VALLO-BORGATA COSTIERA",
    "state_code": "TP",
    "cab": "818807",
    "cap": "91020"
  },
  {
    "key": "8176",
    "ITA": "MAZZANO-MOLINETTO",
    "state_code": "BS",
    "cab": "547604",
    "cap": "25080"
  },
  {
    "key": "8177",
    "ITA": "MAZZANO",
    "state_code": "BS",
    "cab": "547604",
    "cap": "25080"
  },
  {
    "key": "8178",
    "ITA": "MAZZANO-CILIVERGHE",
    "state_code": "BS",
    "cab": "547604",
    "cap": "25080"
  },
  {
    "key": "8179",
    "ITA": "MAZZANO ROMANO",
    "state_code": "RM",
    "cab": "395707",
    "cap": "60"
  },
  {
    "key": "8180",
    "ITA": "MAZZARINO",
    "state_code": "CL",
    "cab": "833509",
    "cap": "93013"
  },
  {
    "key": "8181",
    "ITA": "MAZZARRA' SANT'ANDREA",
    "state_code": "ME",
    "cab": "822809",
    "cap": "98056"
  },
  {
    "key": "8182",
    "ITA": "MAZZARRONE",
    "state_code": "CT",
    "cab": "843409",
    "cap": "95040"
  },
  {
    "key": "8183",
    "ITA": "MAZZE'",
    "state_code": "TO",
    "cab": "306209",
    "cap": "10035"
  },
  {
    "key": "8184",
    "ITA": "MAZZE'-CASALE",
    "state_code": "TO",
    "cab": "306209",
    "cap": "10035"
  },
  {
    "key": "8185",
    "ITA": "MAZZE'-TONENGO DI MAZZE'",
    "state_code": "TO",
    "cab": "306209",
    "cap": "10035"
  },
  {
    "key": "8186",
    "ITA": "MAZZIN-CAMPESTRIN",
    "state_code": "TN",
    "cab": "904953",
    "cap": "38030"
  },
  {
    "key": "8187",
    "ITA": "MAZZIN",
    "state_code": "TN",
    "cab": "904953",
    "cap": "38030"
  },
  {
    "key": "8188",
    "ITA": "MAZZO DI VALTELLINA",
    "state_code": "SO",
    "cab": "522102",
    "cap": "23030"
  },
  {
    "key": "8189",
    "ITA": "MEANA DI SUSA",
    "state_code": "TO",
    "cab": "189852",
    "cap": "10050"
  },
  {
    "key": "8190",
    "ITA": "MEANA SARDO",
    "state_code": "NU",
    "cab": "867309",
    "cap": "8030"
  },
  {
    "key": "8191",
    "ITA": "MEDA",
    "state_code": "MB",
    "cab": "333609",
    "cap": "20036"
  },
  {
    "key": "8192",
    "ITA": "MEDE",
    "state_code": "PV",
    "cab": "560102",
    "cap": "27035"
  },
  {
    "key": "8193",
    "ITA": "MEDEA",
    "state_code": "GO",
    "cab": "937722",
    "cap": "34076"
  },
  {
    "key": "8194",
    "ITA": "MEDESANO",
    "state_code": "PR",
    "cab": "657908",
    "cap": "43014"
  },
  {
    "key": "8195",
    "ITA": "MEDESANO-FELEGARA",
    "state_code": "PR",
    "cab": "657908",
    "cap": "43040"
  },
  {
    "key": "8196",
    "ITA": "MEDESANO-RAMIOLA",
    "state_code": "PR",
    "cab": "657908",
    "cap": "43040"
  },
  {
    "key": "8197",
    "ITA": "MEDESANO-SANT'ANDREA BAGNI",
    "state_code": "PR",
    "cab": "657908",
    "cap": "43048"
  },
  {
    "key": "8198",
    "ITA": "MEDESANO-VARANO MARCHESI",
    "state_code": "PR",
    "cab": "657908",
    "cap": "43048"
  },
  {
    "key": "8199",
    "ITA": "MEDICINA-SANT'ANTONIO",
    "state_code": "BO",
    "cab": "369009",
    "cap": "40059"
  },
  {
    "key": "8200",
    "ITA": "MEDICINA-VILLA FONTANA",
    "state_code": "BO",
    "cab": "369009",
    "cap": "40059"
  },
  {
    "key": "8201",
    "ITA": "MEDICINA-GANZANIGO",
    "state_code": "BO",
    "cab": "369009",
    "cap": "40059"
  },
  {
    "key": "8202",
    "ITA": "MEDICINA-BUDA",
    "state_code": "BO",
    "cab": "369009",
    "cap": "40059"
  },
  {
    "key": "8203",
    "ITA": "MEDICINA",
    "state_code": "BO",
    "cab": "369009",
    "cap": "40059"
  },
  {
    "key": "8204",
    "ITA": "MEDICINA-PORTONOVO",
    "state_code": "BO",
    "cab": "369009",
    "cap": "40059"
  },
  {
    "key": "8205",
    "ITA": "MEDIGLIA-VIGLIANO",
    "state_code": "MI",
    "cab": "333708",
    "cap": "20060"
  },
  {
    "key": "8206",
    "ITA": "MEDIGLIA-TRIGINTO",
    "state_code": "MI",
    "cab": "333708",
    "cap": "20060"
  },
  {
    "key": "8207",
    "ITA": "MEDIGLIA-ROBBIANO",
    "state_code": "MI",
    "cab": "333708",
    "cap": "20060"
  },
  {
    "key": "8208",
    "ITA": "MEDIGLIA",
    "state_code": "MI",
    "cab": "333708",
    "cap": "20060"
  },
  {
    "key": "8209",
    "ITA": "MEDIGLIA-BUSTIGHERA",
    "state_code": "MI",
    "cab": "333708",
    "cap": "20060"
  },
  {
    "key": "8210",
    "ITA": "MEDIGLIA-MOMBRETTO",
    "state_code": "MI",
    "cab": "333708",
    "cap": "20060"
  },
  {
    "key": "8211",
    "ITA": "MEDOLAGO",
    "state_code": "BG",
    "cab": "728402",
    "cap": "24030"
  },
  {
    "key": "8212",
    "ITA": "MEDOLE",
    "state_code": "MN",
    "cab": "577205",
    "cap": "46046"
  },
  {
    "key": "8213",
    "ITA": "MEDOLLA-VILLAFRANCA",
    "state_code": "MO",
    "cab": "668400",
    "cap": "41036"
  },
  {
    "key": "8214",
    "ITA": "MEDOLLA",
    "state_code": "MO",
    "cab": "668400",
    "cap": "41036"
  },
  {
    "key": "8215",
    "ITA": "MEDOLLA-CAMURANA",
    "state_code": "MO",
    "cab": "668400",
    "cap": "41036"
  },
  {
    "key": "8216",
    "ITA": "MEDUNA DI LIVENZA",
    "state_code": "TV",
    "cab": "623009",
    "cap": "31040"
  },
  {
    "key": "8217",
    "ITA": "MEDUNO",
    "state_code": "PN",
    "cab": "649004",
    "cap": "33092"
  },
  {
    "key": "8218",
    "ITA": "MEGLIADINO SAN FIDENZIO-SAN FIDENZIO",
    "state_code": "PD",
    "cab": "417907",
    "cap": "35040"
  },
  {
    "key": "8219",
    "ITA": "MEGLIADINO SAN FIDENZIO",
    "state_code": "PD",
    "cab": "417907",
    "cap": "35040"
  },
  {
    "key": "8220",
    "ITA": "MEGLIADINO SAN VITALE",
    "state_code": "PD",
    "cab": "630400",
    "cap": "35040"
  },
  {
    "key": "8221",
    "ITA": "MEINA-GHEVIO",
    "state_code": "NO",
    "cab": "455006",
    "cap": "28046"
  },
  {
    "key": "8222",
    "ITA": "MEINA",
    "state_code": "NO",
    "cab": "455006",
    "cap": "28046"
  },
  {
    "key": "8223",
    "ITA": "MEL-VILLA DI VILLA",
    "state_code": "BL",
    "cab": "612101",
    "cap": "32026"
  },
  {
    "key": "8224",
    "ITA": "MEL",
    "state_code": "BL",
    "cab": "612101",
    "cap": "32026"
  },
  {
    "key": "8225",
    "ITA": "MELARA-SANTO STEFANO",
    "state_code": "RO",
    "cab": "633701",
    "cap": "45037"
  },
  {
    "key": "8226",
    "ITA": "MELARA",
    "state_code": "RO",
    "cab": "633701",
    "cap": "45037"
  },
  {
    "key": "8227",
    "ITA": "MELAZZO",
    "state_code": "AL",
    "cab": "921270",
    "cap": "15010"
  },
  {
    "key": "8228",
    "ITA": "MELDOLA-VITIGNANO",
    "state_code": "FC",
    "cab": "678409",
    "cap": "47014"
  },
  {
    "key": "8229",
    "ITA": "MELDOLA-TEODORANO",
    "state_code": "FC",
    "cab": "678409",
    "cap": "47014"
  },
  {
    "key": "8230",
    "ITA": "MELDOLA",
    "state_code": "FC",
    "cab": "678409",
    "cap": "47014"
  },
  {
    "key": "8231",
    "ITA": "MELDOLA-PARA",
    "state_code": "FC",
    "cab": "678409",
    "cap": "47014"
  },
  {
    "key": "8232",
    "ITA": "MELDOLA-RICO'",
    "state_code": "FC",
    "cab": "678409",
    "cap": "47014"
  },
  {
    "key": "8233",
    "ITA": "MELDOLA-SAN COLOMBANO",
    "state_code": "FC",
    "cab": "678409",
    "cap": "47014"
  },
  {
    "key": "8234",
    "ITA": "MELE",
    "state_code": "GE",
    "cab": "450403",
    "cap": "16010"
  },
  {
    "key": "8235",
    "ITA": "MELE-ACQUASANTA",
    "state_code": "GE",
    "cab": "450403",
    "cap": "16010"
  },
  {
    "key": "8236",
    "ITA": "MELEGNANO",
    "state_code": "MI",
    "cab": "333807",
    "cap": "20077"
  },
  {
    "key": "8237",
    "ITA": "MELENDUGNO-BORGAGNE",
    "state_code": "LE",
    "cab": "797506",
    "cap": "73026"
  },
  {
    "key": "8238",
    "ITA": "MELENDUGNO-ROCCA VECCHIA",
    "state_code": "LE",
    "cab": "797506",
    "cap": "73026"
  },
  {
    "key": "8239",
    "ITA": "MELENDUGNO-SAN FOCA",
    "state_code": "LE",
    "cab": "797506",
    "cap": "73026"
  },
  {
    "key": "8240",
    "ITA": "MELENDUGNO",
    "state_code": "LE",
    "cab": "797506",
    "cap": "73026"
  },
  {
    "key": "8241",
    "ITA": "MELENDUGNO-TORRE DELL'ORSO",
    "state_code": "LE",
    "cab": "797506",
    "cap": "73026"
  },
  {
    "key": "8242",
    "ITA": "MELETI",
    "state_code": "LO",
    "cab": "333906",
    "cap": "26843"
  },
  {
    "key": "8243",
    "ITA": "MELFI",
    "state_code": "PZ",
    "cab": "421008",
    "cap": "85025"
  },
  {
    "key": "8244",
    "ITA": "MELFI-FOGGIANO",
    "state_code": "PZ",
    "cab": "421008",
    "cap": "85025"
  },
  {
    "key": "8245",
    "ITA": "MELFI-LEONESSA DI MELFI",
    "state_code": "PZ",
    "cab": "421008",
    "cap": "85025"
  },
  {
    "key": "8246",
    "ITA": "MELICUCCA'",
    "state_code": "RC",
    "cab": "960203",
    "cap": "89020"
  },
  {
    "key": "8247",
    "ITA": "MELICUCCO",
    "state_code": "RC",
    "cab": "816405",
    "cap": "89020"
  },
  {
    "key": "8248",
    "ITA": "MELILLI-VILLASMUNDO",
    "state_code": "SR",
    "cab": "847301",
    "cap": "96010"
  },
  {
    "key": "8249",
    "ITA": "MELILLI",
    "state_code": "SR",
    "cab": "847301",
    "cap": "96010"
  },
  {
    "key": "8250",
    "ITA": "MELILLI-MARINA DI MELILLI",
    "state_code": "SR",
    "cab": "847301",
    "cap": "96010"
  },
  {
    "key": "8251",
    "ITA": "MELISSA-TORRE MELISSA",
    "state_code": "KR",
    "cab": "429209",
    "cap": "88814"
  },
  {
    "key": "8252",
    "ITA": "MELISSA",
    "state_code": "KR",
    "cab": "429209",
    "cap": "88814"
  },
  {
    "key": "8253",
    "ITA": "MELISSANO",
    "state_code": "LE",
    "cab": "797605",
    "cap": "73040"
  },
  {
    "key": "8254",
    "ITA": "MELITO DI NAPOLI",
    "state_code": "NA",
    "cab": "399808",
    "cap": "80017"
  },
  {
    "key": "8255",
    "ITA": "MELITO DI PORTO SALVO",
    "state_code": "RC",
    "cab": "814400",
    "cap": "89063"
  },
  {
    "key": "8256",
    "ITA": "MELITO DI PORTO SALVO-CAREDIA",
    "state_code": "RC",
    "cab": "814400",
    "cap": "89063"
  },
  {
    "key": "8257",
    "ITA": "MELITO DI PORTO SALVO-PENTEDATTILO",
    "state_code": "RC",
    "cab": "814400",
    "cap": "89063"
  },
  {
    "key": "8258",
    "ITA": "MELITO DI PORTO SALVO-LACCO",
    "state_code": "RC",
    "cab": "814400",
    "cap": "89063"
  },
  {
    "key": "8259",
    "ITA": "MELITO DI PORTO SALVO-PRUNELLA",
    "state_code": "RC",
    "cab": "814400",
    "cap": "89063"
  },
  {
    "key": "8260",
    "ITA": "MELITO DI PORTO SALVO-ANNA'",
    "state_code": "RC",
    "cab": "814400",
    "cap": "89063"
  },
  {
    "key": "8261",
    "ITA": "MELITO IRPINO",
    "state_code": "AV",
    "cab": "951244",
    "cap": "83030"
  },
  {
    "key": "8262",
    "ITA": "MELIZZANO",
    "state_code": "BN",
    "cab": "950352",
    "cap": "82030"
  },
  {
    "key": "8263",
    "ITA": "MELIZZANO-TORELLO",
    "state_code": "BN",
    "cab": "950352",
    "cap": "82030"
  },
  {
    "key": "8264",
    "ITA": "MELLE",
    "state_code": "CN",
    "cab": "918862",
    "cap": "12020"
  },
  {
    "key": "8265",
    "ITA": "MELLO",
    "state_code": "SO",
    "cab": "927145",
    "cap": "23010"
  },
  {
    "key": "8266",
    "ITA": "MELPIGNANO",
    "state_code": "LE",
    "cab": "957662",
    "cap": "73020"
  },
  {
    "key": "8267",
    "ITA": "MELTINA",
    "state_code": "BZ",
    "cab": "585802",
    "cap": "39010"
  },
  {
    "key": "8268",
    "ITA": "MELZO",
    "state_code": "MI",
    "cab": "334003",
    "cap": "20066"
  },
  {
    "key": "8269",
    "ITA": "MENAGGIO",
    "state_code": "CO",
    "cab": "515205",
    "cap": "22017"
  },
  {
    "key": "8270",
    "ITA": "MENAROLA",
    "state_code": "SO",
    "cab": "927152",
    "cap": "23020"
  },
  {
    "key": "8271",
    "ITA": "MENCONICO",
    "state_code": "PV",
    "cab": "930578",
    "cap": "27050"
  },
  {
    "key": "8272",
    "ITA": "MENCONICO-COLLEGIO",
    "state_code": "PV",
    "cab": "930578",
    "cap": "27050"
  },
  {
    "key": "8273",
    "ITA": "MENDATICA",
    "state_code": "IM",
    "cab": "922468",
    "cap": "18025"
  },
  {
    "key": "8274",
    "ITA": "MENDICINO-ROSARIO",
    "state_code": "CS",
    "cab": "700203",
    "cap": "87040"
  },
  {
    "key": "8275",
    "ITA": "MENDICINO-SAN BARTOLO",
    "state_code": "CS",
    "cab": "700203",
    "cap": "87040"
  },
  {
    "key": "8276",
    "ITA": "MENDICINO-TIVOLILLE",
    "state_code": "CS",
    "cab": "700203",
    "cap": "87040"
  },
  {
    "key": "8277",
    "ITA": "MENDICINO",
    "state_code": "CS",
    "cab": "700203",
    "cap": "87040"
  },
  {
    "key": "8278",
    "ITA": "MENFI",
    "state_code": "AG",
    "cab": "829903",
    "cap": "92013"
  },
  {
    "key": "8279",
    "ITA": "MENTANA",
    "state_code": "RM",
    "cab": "391904",
    "cap": "13"
  },
  {
    "key": "8280",
    "ITA": "MENTANA-CASTELCHIODATO",
    "state_code": "RM",
    "cab": "391904",
    "cap": "13"
  },
  {
    "key": "8281",
    "ITA": "MEOLO-LOSSON DELLA BATTAGLIA",
    "state_code": "VE",
    "cab": "361709",
    "cap": "30020"
  },
  {
    "key": "8282",
    "ITA": "MEOLO",
    "state_code": "VE",
    "cab": "361709",
    "cap": "30020"
  },
  {
    "key": "8283",
    "ITA": "MERANA",
    "state_code": "AL",
    "cab": "921288",
    "cap": "15010"
  },
  {
    "key": "8284",
    "ITA": "MERANO",
    "state_code": "BZ",
    "cab": "585901",
    "cap": "39012"
  },
  {
    "key": "8285",
    "ITA": "MERANO-BORGO VITTORIA",
    "state_code": "BZ",
    "cab": "585901",
    "cap": "39012"
  },
  {
    "key": "8286",
    "ITA": "MERANO-SINIGO",
    "state_code": "BZ",
    "cab": "585901",
    "cap": "39012"
  },
  {
    "key": "8287",
    "ITA": "MERATE",
    "state_code": "LC",
    "cab": "515304",
    "cap": "23807"
  },
  {
    "key": "8288",
    "ITA": "MERATE-CASSINA FRA MARTINO",
    "state_code": "LC",
    "cab": "515304",
    "cap": "23807"
  },
  {
    "key": "8289",
    "ITA": "MERCALLO",
    "state_code": "VA",
    "cab": "507806",
    "cap": "21020"
  },
  {
    "key": "8290",
    "ITA": "MERCATELLO SUL METAURO",
    "state_code": "PU",
    "cab": "683508",
    "cap": "61040"
  },
  {
    "key": "8291",
    "ITA": "MERCATINO CONCA",
    "state_code": "PU",
    "cab": "683607",
    "cap": "61013"
  },
  {
    "key": "8292",
    "ITA": "MERCATINO CONCA-PIANDICASTELLO",
    "state_code": "PU",
    "cab": "683607",
    "cap": "61013"
  },
  {
    "key": "8293",
    "ITA": "MERCATO SAN SEVERINO",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8294",
    "ITA": "MERCATO SAN SEVERINO-ACQUAROLA",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8295",
    "ITA": "MERCATO SAN SEVERINO-CARIFI",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8296",
    "ITA": "MERCATO SAN SEVERINO-CIORANI",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8297",
    "ITA": "MERCATO SAN SEVERINO-CURTERI",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8298",
    "ITA": "MERCATO SAN SEVERINO-MONTICELLI",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8299",
    "ITA": "MERCATO SAN SEVERINO-PANDOLA",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8300",
    "ITA": "MERCATO SAN SEVERINO-PIAZZA DEL GALDO",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8301",
    "ITA": "MERCATO SAN SEVERINO-SANT'ANGELO",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8302",
    "ITA": "MERCATO SAN SEVERINO-SANT'EUSTACHIO",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8303",
    "ITA": "MERCATO SAN SEVERINO-SPIANO",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8304",
    "ITA": "MERCATO SAN SEVERINO-TORELLO",
    "state_code": "SA",
    "cab": "762401",
    "cap": "84085"
  },
  {
    "key": "8305",
    "ITA": "MERCATO SARACENO-PIAVOLA",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8306",
    "ITA": "MERCATO SARACENO-TORNANO",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8307",
    "ITA": "MERCATO SARACENO-MONTE CASTELLO",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8308",
    "ITA": "MERCATO SARACENO-LINARO",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8309",
    "ITA": "MERCATO SARACENO-CIOLA",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8310",
    "ITA": "MERCATO SARACENO-CELLA",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8311",
    "ITA": "MERCATO SARACENO-BORA BASSA",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8312",
    "ITA": "MERCATO SARACENO-BACCIOLINO",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8313",
    "ITA": "MERCATO SARACENO",
    "state_code": "FC",
    "cab": "678508",
    "cap": "47025"
  },
  {
    "key": "8314",
    "ITA": "MERCENASCO-VILLATE",
    "state_code": "TO",
    "cab": "190488",
    "cap": "10010"
  },
  {
    "key": "8315",
    "ITA": "MERCENASCO",
    "state_code": "TO",
    "cab": "190488",
    "cap": "10010"
  },
  {
    "key": "8316",
    "ITA": "MERCOGLIANO-TORRETTE",
    "state_code": "AV",
    "cab": "757609",
    "cap": "83013"
  },
  {
    "key": "8317",
    "ITA": "MERCOGLIANO-SANTUARIO DI MONTEVERGINE",
    "state_code": "AV",
    "cab": "757609",
    "cap": "83013"
  },
  {
    "key": "8318",
    "ITA": "MERCOGLIANO",
    "state_code": "AV",
    "cab": "757609",
    "cap": "83013"
  },
  {
    "key": "8319",
    "ITA": "MERCOGLIANO-TORELLI",
    "state_code": "AV",
    "cab": "757609",
    "cap": "83013"
  },
  {
    "key": "8320",
    "ITA": "MERETO DI TOMBA-SAN MARCO",
    "state_code": "UD",
    "cab": "639609",
    "cap": "33036"
  },
  {
    "key": "8321",
    "ITA": "MERETO DI TOMBA-TOMBA",
    "state_code": "UD",
    "cab": "639609",
    "cap": "33036"
  },
  {
    "key": "8322",
    "ITA": "MERETO DI TOMBA-PLASENCIS",
    "state_code": "UD",
    "cab": "639609",
    "cap": "33036"
  },
  {
    "key": "8323",
    "ITA": "MERETO DI TOMBA-PANTIANICCO",
    "state_code": "UD",
    "cab": "639609",
    "cap": "33036"
  },
  {
    "key": "8324",
    "ITA": "MERETO DI TOMBA",
    "state_code": "UD",
    "cab": "639609",
    "cap": "33036"
  },
  {
    "key": "8325",
    "ITA": "MERGO",
    "state_code": "AN",
    "cab": "906826",
    "cap": "60030"
  },
  {
    "key": "8326",
    "ITA": "MERGO-ANGELI DI MERGO",
    "state_code": "AN",
    "cab": "906826",
    "cap": "60030"
  },
  {
    "key": "8327",
    "ITA": "MERGOZZO",
    "state_code": "VB",
    "cab": "455105",
    "cap": "28802"
  },
  {
    "key": "8328",
    "ITA": "MERGOZZO-ALBO",
    "state_code": "VB",
    "cab": "455105",
    "cap": "28802"
  },
  {
    "key": "8329",
    "ITA": "MERI'",
    "state_code": "ME",
    "cab": "827402",
    "cap": "98040"
  },
  {
    "key": "8330",
    "ITA": "MERLARA",
    "state_code": "PD",
    "cab": "626408",
    "cap": "35040"
  },
  {
    "key": "8331",
    "ITA": "MERLINO",
    "state_code": "LO",
    "cab": "780403",
    "cap": "26833"
  },
  {
    "key": "8332",
    "ITA": "MERONE",
    "state_code": "CO",
    "cab": "515403",
    "cap": "22046"
  },
  {
    "key": "8333",
    "ITA": "MESAGNE",
    "state_code": "BR",
    "cab": "792101",
    "cap": "72023"
  },
  {
    "key": "8334",
    "ITA": "MESE",
    "state_code": "SO",
    "cab": "927160",
    "cap": "23020"
  },
  {
    "key": "8335",
    "ITA": "MESENZANA",
    "state_code": "VA",
    "cab": "727909",
    "cap": "21030"
  },
  {
    "key": "8336",
    "ITA": "MESERO",
    "state_code": "MI",
    "cab": "334102",
    "cap": "20010"
  },
  {
    "key": "8337",
    "ITA": "MESOLA-MASSENZATICA",
    "state_code": "FE",
    "cab": "672709",
    "cap": "44026"
  },
  {
    "key": "8338",
    "ITA": "MESOLA-BOSCO MESOLA",
    "state_code": "FE",
    "cab": "672709",
    "cap": "44026"
  },
  {
    "key": "8339",
    "ITA": "MESOLA-ARIANO FERRARESE",
    "state_code": "FE",
    "cab": "672709",
    "cap": "44026"
  },
  {
    "key": "8340",
    "ITA": "MESOLA",
    "state_code": "FE",
    "cab": "672709",
    "cap": "44026"
  },
  {
    "key": "8341",
    "ITA": "MESOLA-MONTICELLI",
    "state_code": "FE",
    "cab": "672709",
    "cap": "44026"
  },
  {
    "key": "8342",
    "ITA": "MESORACA-FILIPPA",
    "state_code": "KR",
    "cab": "426304",
    "cap": "88838"
  },
  {
    "key": "8343",
    "ITA": "MESORACA",
    "state_code": "KR",
    "cab": "426304",
    "cap": "88838"
  },
  {
    "key": "8344",
    "ITA": "MESSINA-MASSA SANTA LUCIA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98157"
  },
  {
    "key": "8345",
    "ITA": "MESSINA-MILI MARINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98131"
  },
  {
    "key": "8346",
    "ITA": "MESSINA-MILI MARINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98132"
  },
  {
    "key": "8347",
    "ITA": "MESSINA-MILI SAN MARCO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98132"
  },
  {
    "key": "8348",
    "ITA": "MESSINA-MILI SUPERIORE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98133"
  },
  {
    "key": "8349",
    "ITA": "MESSINA-MOLINO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98143"
  },
  {
    "key": "8350",
    "ITA": "MESSINA-PACE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98167"
  },
  {
    "key": "8351",
    "ITA": "MESSINA-PACE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98168"
  },
  {
    "key": "8352",
    "ITA": "MESSINA-PEZZOLO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98138"
  },
  {
    "key": "8353",
    "ITA": "MESSINA-RODIA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98161"
  },
  {
    "key": "8354",
    "ITA": "MESSINA-SALICE CALABRO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98154"
  },
  {
    "key": "8355",
    "ITA": "MESSINA-SAN FILIPPO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98144"
  },
  {
    "key": "8356",
    "ITA": "MESSINA-SAN FILIPPO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98145"
  },
  {
    "key": "8357",
    "ITA": "MESSINA-SAN FILIPPO INFERIORE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98142"
  },
  {
    "key": "8358",
    "ITA": "MESSINA-SAN MICHELE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98152"
  },
  {
    "key": "8359",
    "ITA": "MESSINA-SAN SABA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98162"
  },
  {
    "key": "8360",
    "ITA": "MESSINA-SANTA MARGHERITA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98135"
  },
  {
    "key": "8361",
    "ITA": "MESSINA-SANT'AGATA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98166"
  },
  {
    "key": "8362",
    "ITA": "MESSINA-SANTO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98146"
  },
  {
    "key": "8363",
    "ITA": "MESSINA-SANTO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98148"
  },
  {
    "key": "8364",
    "ITA": "MESSINA-SANTO STEFANO DI BRIGA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98137"
  },
  {
    "key": "8365",
    "ITA": "MESSINA-SANTO STEFANO MEDIO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98135"
  },
  {
    "key": "8366",
    "ITA": "MESSINA-SCALA RITIRO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98152"
  },
  {
    "key": "8367",
    "ITA": "MESSINA-SPARTA'",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98163"
  },
  {
    "key": "8368",
    "ITA": "MESSINA-TORRE FARO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98164"
  },
  {
    "key": "8369",
    "ITA": "MESSINA-TREMESTIERI",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98128"
  },
  {
    "key": "8370",
    "ITA": "MESSINA-VILLAGGIO ALDISIO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98147"
  },
  {
    "key": "8371",
    "ITA": "MESSINA-ZAFFERIA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98127"
  },
  {
    "key": "8372",
    "ITA": "MESSINA-CASTANEA DELLE FURIE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98155"
  },
  {
    "key": "8373",
    "ITA": "MESSINA-CATARATTI",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98149"
  },
  {
    "key": "8374",
    "ITA": "MESSINA-CONTEMPLAZIONE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98168"
  },
  {
    "key": "8375",
    "ITA": "MESSINA-CONTESSE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98125"
  },
  {
    "key": "8376",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98121"
  },
  {
    "key": "8377",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98122"
  },
  {
    "key": "8378",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98123"
  },
  {
    "key": "8379",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98124"
  },
  {
    "key": "8380",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98125"
  },
  {
    "key": "8381",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98126"
  },
  {
    "key": "8382",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98127"
  },
  {
    "key": "8383",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98128"
  },
  {
    "key": "8384",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98129"
  },
  {
    "key": "8385",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98131"
  },
  {
    "key": "8386",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98132"
  },
  {
    "key": "8387",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98133"
  },
  {
    "key": "8388",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98134"
  },
  {
    "key": "8389",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98135"
  },
  {
    "key": "8390",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98136"
  },
  {
    "key": "8391",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98137"
  },
  {
    "key": "8392",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98138"
  },
  {
    "key": "8393",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98139"
  },
  {
    "key": "8394",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98141"
  },
  {
    "key": "8395",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98142"
  },
  {
    "key": "8396",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98143"
  },
  {
    "key": "8397",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98144"
  },
  {
    "key": "8398",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98145"
  },
  {
    "key": "8399",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98146"
  },
  {
    "key": "8400",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98147"
  },
  {
    "key": "8401",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98148"
  },
  {
    "key": "8402",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98149"
  },
  {
    "key": "8403",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98151"
  },
  {
    "key": "8404",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98152"
  },
  {
    "key": "8405",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98153"
  },
  {
    "key": "8406",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98154"
  },
  {
    "key": "8407",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98155"
  },
  {
    "key": "8408",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98156"
  },
  {
    "key": "8409",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98157"
  },
  {
    "key": "8410",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98158"
  },
  {
    "key": "8411",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98161"
  },
  {
    "key": "8412",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98162"
  },
  {
    "key": "8413",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98163"
  },
  {
    "key": "8414",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98164"
  },
  {
    "key": "8415",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98165"
  },
  {
    "key": "8416",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98166"
  },
  {
    "key": "8417",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98167"
  },
  {
    "key": "8418",
    "ITA": "MESSINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98168"
  },
  {
    "key": "8419",
    "ITA": "MESSINA-ALTOLIA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98143"
  },
  {
    "key": "8420",
    "ITA": "MESSINA-BORDONARO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98145"
  },
  {
    "key": "8421",
    "ITA": "MESSINA-BRIGA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98139"
  },
  {
    "key": "8422",
    "ITA": "MESSINA-BRIGA MARINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98139"
  },
  {
    "key": "8423",
    "ITA": "MESSINA-CAMARO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98149"
  },
  {
    "key": "8424",
    "ITA": "MESSINA-CAMARO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98151"
  },
  {
    "key": "8425",
    "ITA": "MESSINA-CAMARO INFERIORE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98149"
  },
  {
    "key": "8426",
    "ITA": "MESSINA-CUMIA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98146"
  },
  {
    "key": "8427",
    "ITA": "MESSINA-GALATI MARINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98134"
  },
  {
    "key": "8428",
    "ITA": "MESSINA-GANZIRRI",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98165"
  },
  {
    "key": "8429",
    "ITA": "MESSINA-GESSO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98153"
  },
  {
    "key": "8430",
    "ITA": "MESSINA-GIAMPILIERI",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98142"
  },
  {
    "key": "8431",
    "ITA": "MESSINA-GIAMPILIERI MARINA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98141"
  },
  {
    "key": "8432",
    "ITA": "MESSINA-LARDERIA",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98129"
  },
  {
    "key": "8433",
    "ITA": "MESSINA-LIDO DI MORTELLE",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98164"
  },
  {
    "key": "8434",
    "ITA": "MESSINA-MASSA SAN GIORGIO",
    "state_code": "ME",
    "cab": "165001",
    "cap": "98156"
  },
  {
    "key": "8435",
    "ITA": "MESTRINO-ARLESEGA",
    "state_code": "PD",
    "cab": "626507",
    "cap": "35035"
  },
  {
    "key": "8436",
    "ITA": "MESTRINO",
    "state_code": "PD",
    "cab": "626507",
    "cap": "35035"
  },
  {
    "key": "8437",
    "ITA": "META",
    "state_code": "NA",
    "cab": "399907",
    "cap": "80062"
  },
  {
    "key": "8438",
    "ITA": "MEUGLIANO",
    "state_code": "TO",
    "cab": "900878",
    "cap": "10080"
  },
  {
    "key": "8439",
    "ITA": "MEZZAGO",
    "state_code": "MB",
    "cab": "893107",
    "cap": "20050"
  },
  {
    "key": "8440",
    "ITA": "MEZZANA",
    "state_code": "TN",
    "cab": "350108",
    "cap": "38020"
  },
  {
    "key": "8441",
    "ITA": "MEZZANA BIGLI",
    "state_code": "PV",
    "cab": "560201",
    "cap": "27030"
  },
  {
    "key": "8442",
    "ITA": "MEZZANA BIGLI-BALOSSA BIGLI",
    "state_code": "PV",
    "cab": "560201",
    "cap": "27039"
  },
  {
    "key": "8443",
    "ITA": "MEZZANA MORTIGLIENGO",
    "state_code": "BI",
    "cab": "916007",
    "cap": "13831"
  },
  {
    "key": "8444",
    "ITA": "MEZZANA RABATTONE",
    "state_code": "PV",
    "cab": "930586",
    "cap": "27030"
  },
  {
    "key": "8445",
    "ITA": "MEZZANE DI SOTTO",
    "state_code": "VR",
    "cab": "323006",
    "cap": "37030"
  },
  {
    "key": "8446",
    "ITA": "MEZZANEGO-PASSO DEL BOCCO",
    "state_code": "GE",
    "cab": "320309",
    "cap": "16046"
  },
  {
    "key": "8447",
    "ITA": "MEZZANEGO-BORGONOVO",
    "state_code": "GE",
    "cab": "320309",
    "cap": "16046"
  },
  {
    "key": "8448",
    "ITA": "MEZZANEGO",
    "state_code": "GE",
    "cab": "320309",
    "cap": "16046"
  },
  {
    "key": "8449",
    "ITA": "MEZZANI-MEZZANO SUPERIORE",
    "state_code": "PR",
    "cab": "658005",
    "cap": "43055"
  },
  {
    "key": "8450",
    "ITA": "MEZZANI-MEZZANO RONDANI",
    "state_code": "PR",
    "cab": "658005",
    "cap": "43055"
  },
  {
    "key": "8451",
    "ITA": "MEZZANI-MEZZANO INFERIORE",
    "state_code": "PR",
    "cab": "658005",
    "cap": "43055"
  },
  {
    "key": "8452",
    "ITA": "MEZZANI",
    "state_code": "PR",
    "cab": "658005",
    "cap": "43055"
  },
  {
    "key": "8453",
    "ITA": "MEZZANI-CASALE",
    "state_code": "PR",
    "cab": "658005",
    "cap": "43055"
  },
  {
    "key": "8454",
    "ITA": "MEZZANINO-BUSCA",
    "state_code": "PV",
    "cab": "930594",
    "cap": "27040"
  },
  {
    "key": "8455",
    "ITA": "MEZZANINO-TORNELLO",
    "state_code": "PV",
    "cab": "930594",
    "cap": "27040"
  },
  {
    "key": "8456",
    "ITA": "MEZZANINO",
    "state_code": "PV",
    "cab": "930594",
    "cap": "27040"
  },
  {
    "key": "8457",
    "ITA": "MEZZANO",
    "state_code": "TN",
    "cab": "350207",
    "cap": "38050"
  },
  {
    "key": "8458",
    "ITA": "MEZZEGRA",
    "state_code": "CO",
    "cab": "925800",
    "cap": "22010"
  },
  {
    "key": "8459",
    "ITA": "MEZZEGRA-AZZANO",
    "state_code": "CO",
    "cab": "925800",
    "cap": "22010"
  },
  {
    "key": "8460",
    "ITA": "MEZZENILE",
    "state_code": "TO",
    "cab": "285189",
    "cap": "10070"
  },
  {
    "key": "8461",
    "ITA": "MEZZOCORONA",
    "state_code": "TN",
    "cab": "350306",
    "cap": "38016"
  },
  {
    "key": "8462",
    "ITA": "MEZZOJUSO",
    "state_code": "PA",
    "cab": "434308",
    "cap": "90030"
  },
  {
    "key": "8463",
    "ITA": "MEZZOLDO",
    "state_code": "BG",
    "cab": "928283",
    "cap": "24010"
  },
  {
    "key": "8464",
    "ITA": "MEZZOLOMBARDO",
    "state_code": "TN",
    "cab": "350405",
    "cap": "38017"
  },
  {
    "key": "8465",
    "ITA": "MEZZOMERICO",
    "state_code": "NO",
    "cab": "917450",
    "cap": "28040"
  },
  {
    "key": "8466",
    "ITA": "MIAGLIANO",
    "state_code": "BI",
    "cab": "916015",
    "cap": "13816"
  },
  {
    "key": "8467",
    "ITA": "MIANE-PREMAOR",
    "state_code": "TV",
    "cab": "617902",
    "cap": "31050"
  },
  {
    "key": "8468",
    "ITA": "MIANE-COMBAI",
    "state_code": "TV",
    "cab": "617902",
    "cap": "31050"
  },
  {
    "key": "8469",
    "ITA": "MIANE",
    "state_code": "TV",
    "cab": "617902",
    "cap": "31050"
  },
  {
    "key": "8470",
    "ITA": "MIASINO",
    "state_code": "NO",
    "cab": "917468",
    "cap": "28010"
  },
  {
    "key": "8471",
    "ITA": "MIAZZINA",
    "state_code": "VB",
    "cab": "917476",
    "cap": "28817"
  },
  {
    "key": "8472",
    "ITA": "MICIGLIANO",
    "state_code": "RI",
    "cab": "947242",
    "cap": "2010"
  },
  {
    "key": "8473",
    "ITA": "MIGGIANO",
    "state_code": "LE",
    "cab": "797704",
    "cap": "73035"
  },
  {
    "key": "8474",
    "ITA": "MIGLIANICO",
    "state_code": "CH",
    "cab": "777607",
    "cap": "66010"
  },
  {
    "key": "8475",
    "ITA": "MIGLIARINO",
    "state_code": "FE",
    "cab": "672808",
    "cap": "44027"
  },
  {
    "key": "8476",
    "ITA": "MIGLIARO",
    "state_code": "FE",
    "cab": "673905",
    "cap": "44020"
  },
  {
    "key": "8477",
    "ITA": "MIGLIERINA",
    "state_code": "CZ",
    "cab": "913152",
    "cap": "88040"
  },
  {
    "key": "8478",
    "ITA": "MIGLIONICO",
    "state_code": "MT",
    "cab": "803502",
    "cap": "75010"
  },
  {
    "key": "8479",
    "ITA": "MIGNANEGO",
    "state_code": "GE",
    "cab": "320408",
    "cap": "16018"
  },
  {
    "key": "8480",
    "ITA": "MIGNANEGO-GIOVI",
    "state_code": "GE",
    "cab": "320408",
    "cap": "16018"
  },
  {
    "key": "8481",
    "ITA": "MIGNANO MONTE LUNGO",
    "state_code": "CE",
    "cab": "749101",
    "cap": "81049"
  },
  {
    "key": "8482",
    "ITA": "MIGNANO MONTE LUNGO-CASPOLI",
    "state_code": "CE",
    "cab": "749101",
    "cap": "81049"
  },
  {
    "key": "8483",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20121"
  },
  {
    "key": "8484",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20122"
  },
  {
    "key": "8485",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20123"
  },
  {
    "key": "8486",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20124"
  },
  {
    "key": "8487",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20125"
  },
  {
    "key": "8488",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20126"
  },
  {
    "key": "8489",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20127"
  },
  {
    "key": "8490",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20128"
  },
  {
    "key": "8491",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20129"
  },
  {
    "key": "8492",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20131"
  },
  {
    "key": "8493",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20132"
  },
  {
    "key": "8494",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20152"
  },
  {
    "key": "8495",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20153"
  },
  {
    "key": "8496",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20154"
  },
  {
    "key": "8497",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20155"
  },
  {
    "key": "8498",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20156"
  },
  {
    "key": "8499",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20157"
  },
  {
    "key": "8500",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20158"
  },
  {
    "key": "8501",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20159"
  },
  {
    "key": "8502",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20161"
  },
  {
    "key": "8503",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20162"
  },
  {
    "key": "8504",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20133"
  },
  {
    "key": "8505",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20134"
  },
  {
    "key": "8506",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20135"
  },
  {
    "key": "8507",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20136"
  },
  {
    "key": "8508",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20137"
  },
  {
    "key": "8509",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20138"
  },
  {
    "key": "8510",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20139"
  },
  {
    "key": "8511",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20141"
  },
  {
    "key": "8512",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20142"
  },
  {
    "key": "8513",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20143"
  },
  {
    "key": "8514",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20144"
  },
  {
    "key": "8515",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20145"
  },
  {
    "key": "8516",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20146"
  },
  {
    "key": "8517",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20147"
  },
  {
    "key": "8518",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20148"
  },
  {
    "key": "8519",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20149"
  },
  {
    "key": "8520",
    "ITA": "MILANO",
    "state_code": "MI",
    "cab": "16006",
    "cap": "20151"
  },
  {
    "key": "8521",
    "ITA": "MILAZZO-SANTA MARINA DI MILAZZO",
    "state_code": "ME",
    "cab": "822908",
    "cap": "98057"
  },
  {
    "key": "8522",
    "ITA": "MILAZZO",
    "state_code": "ME",
    "cab": "822908",
    "cap": "98057"
  },
  {
    "key": "8523",
    "ITA": "MILAZZO-SAN PIETRO DI MILAZZO",
    "state_code": "ME",
    "cab": "822908",
    "cap": "98057"
  },
  {
    "key": "8524",
    "ITA": "MILENA",
    "state_code": "CL",
    "cab": "833608",
    "cap": "93010"
  },
  {
    "key": "8525",
    "ITA": "MILETO-COMPARNI",
    "state_code": "VV",
    "cab": "426403",
    "cap": "89852"
  },
  {
    "key": "8526",
    "ITA": "MILETO-PARAVATI",
    "state_code": "VV",
    "cab": "426403",
    "cap": "89852"
  },
  {
    "key": "8527",
    "ITA": "MILETO",
    "state_code": "VV",
    "cab": "426403",
    "cap": "89852"
  },
  {
    "key": "8528",
    "ITA": "MILETO-SAN GIOVANNI",
    "state_code": "VV",
    "cab": "426403",
    "cap": "89852"
  },
  {
    "key": "8529",
    "ITA": "MILIS",
    "state_code": "OR",
    "cab": "878603",
    "cap": "9070"
  },
  {
    "key": "8530",
    "ITA": "MILITELLO IN VAL DI CATANIA",
    "state_code": "CT",
    "cab": "840306",
    "cap": "95043"
  },
  {
    "key": "8531",
    "ITA": "MILITELLO ROSMARINO",
    "state_code": "ME",
    "cab": "890301",
    "cap": "98070"
  },
  {
    "key": "8532",
    "ITA": "MILLESIMO",
    "state_code": "SV",
    "cab": "494401",
    "cap": "17017"
  },
  {
    "key": "8533",
    "ITA": "MILO-FORNAZZO",
    "state_code": "CT",
    "cab": "840405",
    "cap": "95010"
  },
  {
    "key": "8534",
    "ITA": "MILO",
    "state_code": "CT",
    "cab": "840405",
    "cap": "95010"
  },
  {
    "key": "8535",
    "ITA": "MILZANO",
    "state_code": "BS",
    "cab": "449702",
    "cap": "25020"
  },
  {
    "key": "8536",
    "ITA": "MINEO-BORGO LUPO",
    "state_code": "CT",
    "cab": "840504",
    "cap": "95044"
  },
  {
    "key": "8537",
    "ITA": "MINEO",
    "state_code": "CT",
    "cab": "840504",
    "cap": "95044"
  },
  {
    "key": "8538",
    "ITA": "MINERBE",
    "state_code": "VR",
    "cab": "595603",
    "cap": "37046"
  },
  {
    "key": "8539",
    "ITA": "MINERBIO",
    "state_code": "BO",
    "cab": "369108",
    "cap": "40061"
  },
  {
    "key": "8540",
    "ITA": "MINERBIO-CA' DE' FABBRI",
    "state_code": "BO",
    "cab": "369108",
    "cap": "40061"
  },
  {
    "key": "8541",
    "ITA": "MINERVINO DI LECCE-SPECCHIA GALLONE",
    "state_code": "LE",
    "cab": "797803",
    "cap": "73027"
  },
  {
    "key": "8542",
    "ITA": "MINERVINO DI LECCE-COCUMOLA",
    "state_code": "LE",
    "cab": "797803",
    "cap": "73027"
  },
  {
    "key": "8543",
    "ITA": "MINERVINO DI LECCE",
    "state_code": "LE",
    "cab": "797803",
    "cap": "73027"
  },
  {
    "key": "8544",
    "ITA": "MINERVINO MURGE",
    "state_code": "BT",
    "cab": "415307",
    "cap": "70055"
  },
  {
    "key": "8545",
    "ITA": "MINORI",
    "state_code": "SA",
    "cab": "762500",
    "cap": "84010"
  },
  {
    "key": "8546",
    "ITA": "MINTURNO-MARINA DI MINTURNO",
    "state_code": "LT",
    "cab": "740308",
    "cap": "4028"
  },
  {
    "key": "8547",
    "ITA": "MINTURNO-SANTA MARIA INFANTE",
    "state_code": "LT",
    "cab": "740308",
    "cap": "4026"
  },
  {
    "key": "8548",
    "ITA": "MINTURNO-TREMENSUOLI",
    "state_code": "LT",
    "cab": "740308",
    "cap": "4026"
  },
  {
    "key": "8549",
    "ITA": "MINTURNO",
    "state_code": "LT",
    "cab": "740308",
    "cap": "4026"
  },
  {
    "key": "8550",
    "ITA": "MINTURNO-SCAURI",
    "state_code": "LT",
    "cab": "740308",
    "cap": "4028"
  },
  {
    "key": "8551",
    "ITA": "MINTURNO-TUFO DI MINTURNO",
    "state_code": "LT",
    "cab": "740308",
    "cap": "4026"
  },
  {
    "key": "8552",
    "ITA": "MINUCCIANO-CASONE CARPINELLI",
    "state_code": "LU",
    "cab": "701805",
    "cap": "55034"
  },
  {
    "key": "8553",
    "ITA": "MINUCCIANO-GORFIGLIANO",
    "state_code": "LU",
    "cab": "701805",
    "cap": "55034"
  },
  {
    "key": "8554",
    "ITA": "MINUCCIANO",
    "state_code": "LU",
    "cab": "701805",
    "cap": "55034"
  },
  {
    "key": "8555",
    "ITA": "MINUCCIANO-PIEVE SAN LORENZO",
    "state_code": "LU",
    "cab": "701805",
    "cap": "55034"
  },
  {
    "key": "8556",
    "ITA": "MINUCCIANO-GRAMOLAZZO",
    "state_code": "LU",
    "cab": "701805",
    "cap": "55034"
  },
  {
    "key": "8557",
    "ITA": "MIOGLIA",
    "state_code": "SV",
    "cab": "923177",
    "cap": "17040"
  },
  {
    "key": "8558",
    "ITA": "MIRA-ORIAGO",
    "state_code": "VE",
    "cab": "361808",
    "cap": "30034"
  },
  {
    "key": "8559",
    "ITA": "MIRA-MIRA TAGLIO",
    "state_code": "VE",
    "cab": "361808",
    "cap": "30034"
  },
  {
    "key": "8560",
    "ITA": "MIRA-MIRA PORTE",
    "state_code": "VE",
    "cab": "361808",
    "cap": "30034"
  },
  {
    "key": "8561",
    "ITA": "MIRA-GAMBARARE",
    "state_code": "VE",
    "cab": "361808",
    "cap": "30034"
  },
  {
    "key": "8562",
    "ITA": "MIRA-MARANO",
    "state_code": "VE",
    "cab": "361808",
    "cap": "30034"
  },
  {
    "key": "8563",
    "ITA": "MIRA-BORBIAGO",
    "state_code": "VE",
    "cab": "361808",
    "cap": "30034"
  },
  {
    "key": "8564",
    "ITA": "MIRA",
    "state_code": "VE",
    "cab": "361808",
    "cap": "30034"
  },
  {
    "key": "8565",
    "ITA": "MIRABELLA ECLANO-CALORE",
    "state_code": "AV",
    "cab": "757708",
    "cap": "83036"
  },
  {
    "key": "8566",
    "ITA": "MIRABELLA ECLANO-PASSO DI MIRABELLA",
    "state_code": "AV",
    "cab": "757708",
    "cap": "83036"
  },
  {
    "key": "8567",
    "ITA": "MIRABELLA ECLANO-PIANOPANTANO",
    "state_code": "AV",
    "cab": "757708",
    "cap": "83036"
  },
  {
    "key": "8568",
    "ITA": "MIRABELLA ECLANO",
    "state_code": "AV",
    "cab": "757708",
    "cap": "83036"
  },
  {
    "key": "8569",
    "ITA": "MIRABELLA IMBACCARI",
    "state_code": "CT",
    "cab": "840603",
    "cap": "95040"
  },
  {
    "key": "8570",
    "ITA": "MIRABELLO",
    "state_code": "FE",
    "cab": "672907",
    "cap": "44043"
  },
  {
    "key": "8571",
    "ITA": "MIRABELLO MONFERRATO",
    "state_code": "AL",
    "cab": "483602",
    "cap": "15040"
  },
  {
    "key": "8572",
    "ITA": "MIRABELLO SANNITICO",
    "state_code": "CB",
    "cab": "721209",
    "cap": "86010"
  },
  {
    "key": "8573",
    "ITA": "MIRADOLO TERME",
    "state_code": "PV",
    "cab": "560300",
    "cap": "27010"
  },
  {
    "key": "8574",
    "ITA": "MIRADOLO TERME-CAMPORINALDO",
    "state_code": "PV",
    "cab": "560300",
    "cap": "27010"
  },
  {
    "key": "8575",
    "ITA": "MIRANDA",
    "state_code": "IS",
    "cab": "186411",
    "cap": "86080"
  },
  {
    "key": "8576",
    "ITA": "MIRANDOLA-TRAMUSCHIO",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8577",
    "ITA": "MIRANDOLA-SAN MARTINO SPINO",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8578",
    "ITA": "MIRANDOLA-SAN MARTINO CARANO",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8579",
    "ITA": "MIRANDOLA-SAN GIACOMO RONCOLE",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8580",
    "ITA": "MIRANDOLA-QUARANTOLI",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8581",
    "ITA": "MIRANDOLA-PONTE SAN PELLEGRINO",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8582",
    "ITA": "MIRANDOLA-MORTIZZUOLO",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8583",
    "ITA": "MIRANDOLA-GAVELLO",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8584",
    "ITA": "MIRANDOLA",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8585",
    "ITA": "MIRANDOLA-CIVIDALE",
    "state_code": "MO",
    "cab": "668509",
    "cap": "41037"
  },
  {
    "key": "8586",
    "ITA": "MIRANO",
    "state_code": "VE",
    "cab": "361907",
    "cap": "30035"
  },
  {
    "key": "8587",
    "ITA": "MIRANO-BALLO'",
    "state_code": "VE",
    "cab": "361907",
    "cap": "30035"
  },
  {
    "key": "8588",
    "ITA": "MIRANO-SCALTENIGO",
    "state_code": "VE",
    "cab": "361907",
    "cap": "30035"
  },
  {
    "key": "8589",
    "ITA": "MIRANO-VETREGO",
    "state_code": "VE",
    "cab": "361907",
    "cap": "30035"
  },
  {
    "key": "8590",
    "ITA": "MIRANO-ZIANIGO",
    "state_code": "VE",
    "cab": "361907",
    "cap": "30035"
  },
  {
    "key": "8591",
    "ITA": "MIRTO",
    "state_code": "ME",
    "cab": "961458",
    "cap": "98070"
  },
  {
    "key": "8592",
    "ITA": "MISANO ADRIATICO-SCACCIANO",
    "state_code": "RN",
    "cab": "678607",
    "cap": "47843"
  },
  {
    "key": "8593",
    "ITA": "MISANO ADRIATICO-SANTA MONICA",
    "state_code": "RN",
    "cab": "678607",
    "cap": "47843"
  },
  {
    "key": "8594",
    "ITA": "MISANO ADRIATICO-MISANO MONTE",
    "state_code": "RN",
    "cab": "678607",
    "cap": "47843"
  },
  {
    "key": "8595",
    "ITA": "MISANO ADRIATICO-CELLA",
    "state_code": "RN",
    "cab": "678607",
    "cap": "47843"
  },
  {
    "key": "8596",
    "ITA": "MISANO ADRIATICO-BELVEDERE",
    "state_code": "RN",
    "cab": "678607",
    "cap": "47843"
  },
  {
    "key": "8597",
    "ITA": "MISANO ADRIATICO",
    "state_code": "RN",
    "cab": "678607",
    "cap": "47843"
  },
  {
    "key": "8598",
    "ITA": "MISANO ADRIATICO-VILLAGGIO ARGENTINA",
    "state_code": "RN",
    "cab": "678607",
    "cap": "47843"
  },
  {
    "key": "8599",
    "ITA": "MISANO DI GERA D'ADDA",
    "state_code": "BG",
    "cab": "532101",
    "cap": "24040"
  },
  {
    "key": "8600",
    "ITA": "MISILMERI",
    "state_code": "PA",
    "cab": "434407",
    "cap": "90036"
  },
  {
    "key": "8601",
    "ITA": "MISILMERI-PORTELLA DI MARE",
    "state_code": "PA",
    "cab": "434407",
    "cap": "90036"
  },
  {
    "key": "8602",
    "ITA": "MISINTO",
    "state_code": "MB",
    "cab": "341404",
    "cap": "20020"
  },
  {
    "key": "8603",
    "ITA": "MISINTO-CASCINA NUOVA",
    "state_code": "MB",
    "cab": "341404",
    "cap": "20020"
  },
  {
    "key": "8604",
    "ITA": "MISSAGLIA-MARESSO",
    "state_code": "LC",
    "cab": "515502",
    "cap": "23873"
  },
  {
    "key": "8605",
    "ITA": "MISSAGLIA",
    "state_code": "LC",
    "cab": "515502",
    "cap": "23873"
  },
  {
    "key": "8606",
    "ITA": "MISSANELLO",
    "state_code": "PZ",
    "cab": "280016",
    "cap": "85010"
  },
  {
    "key": "8607",
    "ITA": "MISTERBIANCO",
    "state_code": "CT",
    "cab": "840702",
    "cap": "95045"
  },
  {
    "key": "8608",
    "ITA": "MISTERBIANCO-SAN NULLO",
    "state_code": "CT",
    "cab": "840702",
    "cap": "95045"
  },
  {
    "key": "8609",
    "ITA": "MISTRETTA",
    "state_code": "ME",
    "cab": "823005",
    "cap": "98073"
  },
  {
    "key": "8610",
    "ITA": "MOASCA",
    "state_code": "AT",
    "cab": "920256",
    "cap": "14050"
  },
  {
    "key": "8611",
    "ITA": "MOCONESI-GATTORNA",
    "state_code": "GE",
    "cab": "320507",
    "cap": "16047"
  },
  {
    "key": "8612",
    "ITA": "MOCONESI-FERRADA",
    "state_code": "GE",
    "cab": "320507",
    "cap": "16047"
  },
  {
    "key": "8613",
    "ITA": "MOCONESI",
    "state_code": "GE",
    "cab": "320507",
    "cap": "16047"
  },
  {
    "key": "8614",
    "ITA": "MODENA-SALICETO SAN GIULIANO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8615",
    "ITA": "MODENA-SALICETO PANARO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8616",
    "ITA": "MODENA-SAN DAMASO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41126"
  },
  {
    "key": "8617",
    "ITA": "MODENA-SAN DONNINO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8618",
    "ITA": "MODENA-VACIGLIO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8619",
    "ITA": "MODENA-VILLANOVA",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8620",
    "ITA": "MODENA-SAN DAMASO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8621",
    "ITA": "MODENA",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8622",
    "ITA": "MODENA-ALBARETO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8623",
    "ITA": "MODENA-BAGGIOVARA",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8624",
    "ITA": "MODENA-CHIESA NUOVA DI MARZAGLIA",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8625",
    "ITA": "MODENA-COGNENTO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8626",
    "ITA": "MODENA-FRETO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8627",
    "ITA": "MODENA-GANACETO",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8628",
    "ITA": "MODENA-LESIGNANA",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8629",
    "ITA": "MODENA-MARZAGLIA",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8630",
    "ITA": "MODENA-PORTILE",
    "state_code": "MO",
    "cab": "129007",
    "cap": "41100"
  },
  {
    "key": "8631",
    "ITA": "MODICA-ROCCIOLA SORDA",
    "state_code": "RG",
    "cab": "844803",
    "cap": "97015"
  },
  {
    "key": "8632",
    "ITA": "MODICA-MODICA ALTA",
    "state_code": "RG",
    "cab": "844803",
    "cap": "97015"
  },
  {
    "key": "8633",
    "ITA": "MODICA-FRIGINTINI",
    "state_code": "RG",
    "cab": "844803",
    "cap": "97015"
  },
  {
    "key": "8634",
    "ITA": "MODICA-CAVA D'ISPICA",
    "state_code": "RG",
    "cab": "844803",
    "cap": "97015"
  },
  {
    "key": "8635",
    "ITA": "MODICA",
    "state_code": "RG",
    "cab": "844803",
    "cap": "97015"
  },
  {
    "key": "8636",
    "ITA": "MODICA-MARINA DI MODICA",
    "state_code": "RG",
    "cab": "844803",
    "cap": "97010"
  },
  {
    "key": "8637",
    "ITA": "MODIGLIANA",
    "state_code": "FC",
    "cab": "678706",
    "cap": "47015"
  },
  {
    "key": "8638",
    "ITA": "MODIGLIANA-SANTA REPARATA",
    "state_code": "FC",
    "cab": "678706",
    "cap": "47015"
  },
  {
    "key": "8639",
    "ITA": "MODOLO",
    "state_code": "OR",
    "cab": "965764",
    "cap": "8019"
  },
  {
    "key": "8640",
    "ITA": "MODUGNO",
    "state_code": "BA",
    "cab": "415406",
    "cap": "70026"
  },
  {
    "key": "8641",
    "ITA": "MOENA-FORNO",
    "state_code": "TN",
    "cab": "350504",
    "cap": "38035"
  },
  {
    "key": "8642",
    "ITA": "MOENA",
    "state_code": "TN",
    "cab": "350504",
    "cap": "38035"
  },
  {
    "key": "8643",
    "ITA": "MOGGIO",
    "state_code": "LC",
    "cab": "820308",
    "cap": "23817"
  },
  {
    "key": "8644",
    "ITA": "MOGGIO UDINESE",
    "state_code": "UD",
    "cab": "639708",
    "cap": "33015"
  },
  {
    "key": "8645",
    "ITA": "MOGGIO UDINESE-MOGGIO DI SOPRA",
    "state_code": "UD",
    "cab": "639708",
    "cap": "33015"
  },
  {
    "key": "8646",
    "ITA": "MOGGIO UDINESE-MOGGIO DI SOTTO",
    "state_code": "UD",
    "cab": "639708",
    "cap": "33015"
  },
  {
    "key": "8647",
    "ITA": "MOGLIA",
    "state_code": "MN",
    "cab": "577304",
    "cap": "46024"
  },
  {
    "key": "8648",
    "ITA": "MOGLIA-BONDANELLO",
    "state_code": "MN",
    "cab": "577304",
    "cap": "46024"
  },
  {
    "key": "8649",
    "ITA": "MOGLIANO",
    "state_code": "MC",
    "cab": "689604",
    "cap": "62010"
  },
  {
    "key": "8650",
    "ITA": "MOGLIANO VENETO-ZERMAN",
    "state_code": "TV",
    "cab": "618009",
    "cap": "31021"
  },
  {
    "key": "8651",
    "ITA": "MOGLIANO VENETO-MAROCCO",
    "state_code": "TV",
    "cab": "618009",
    "cap": "31021"
  },
  {
    "key": "8652",
    "ITA": "MOGLIANO VENETO-BONISIOLO",
    "state_code": "TV",
    "cab": "618009",
    "cap": "31021"
  },
  {
    "key": "8653",
    "ITA": "MOGLIANO VENETO",
    "state_code": "TV",
    "cab": "618009",
    "cap": "31021"
  },
  {
    "key": "8654",
    "ITA": "MOGLIANO VENETO-CAMPOCROCE",
    "state_code": "TV",
    "cab": "618009",
    "cap": "31021"
  },
  {
    "key": "8655",
    "ITA": "MOGORELLA",
    "state_code": "OR",
    "cab": "966820",
    "cap": "9080"
  },
  {
    "key": "8656",
    "ITA": "MOGORO",
    "state_code": "OR",
    "cab": "856005",
    "cap": "9095"
  },
  {
    "key": "8657",
    "ITA": "MOIANO-LUZZANO",
    "state_code": "BN",
    "cab": "755801",
    "cap": "82010"
  },
  {
    "key": "8658",
    "ITA": "MOIANO",
    "state_code": "BN",
    "cab": "755801",
    "cap": "82010"
  },
  {
    "key": "8659",
    "ITA": "MOIMACCO",
    "state_code": "UD",
    "cab": "644302",
    "cap": "33040"
  },
  {
    "key": "8660",
    "ITA": "MOIO ALCANTARA",
    "state_code": "ME",
    "cab": "889907",
    "cap": "98030"
  },
  {
    "key": "8661",
    "ITA": "MOIO DE' CALVI",
    "state_code": "BG",
    "cab": "928291",
    "cap": "24010"
  },
  {
    "key": "8662",
    "ITA": "MOIO DELLA CIVITELLA-PELLARE",
    "state_code": "SA",
    "cab": "766501",
    "cap": "84060"
  },
  {
    "key": "8663",
    "ITA": "MOIO DELLA CIVITELLA",
    "state_code": "SA",
    "cab": "766501",
    "cap": "84060"
  },
  {
    "key": "8664",
    "ITA": "MOIOLA",
    "state_code": "CN",
    "cab": "918870",
    "cap": "12010"
  },
  {
    "key": "8665",
    "ITA": "MOLA DI BARI",
    "state_code": "BA",
    "cab": "415505",
    "cap": "70042"
  },
  {
    "key": "8666",
    "ITA": "MOLA DI BARI-COZZE",
    "state_code": "BA",
    "cab": "415505",
    "cap": "70042"
  },
  {
    "key": "8667",
    "ITA": "MOLA DI BARI-SAN MADERNO",
    "state_code": "BA",
    "cab": "415505",
    "cap": "70042"
  },
  {
    "key": "8668",
    "ITA": "MOLARE",
    "state_code": "AL",
    "cab": "483701",
    "cap": "15074"
  },
  {
    "key": "8669",
    "ITA": "MOLAZZANA",
    "state_code": "LU",
    "cab": "179325",
    "cap": "55020"
  },
  {
    "key": "8670",
    "ITA": "MOLAZZANA-SASSI",
    "state_code": "LU",
    "cab": "179325",
    "cap": "55020"
  },
  {
    "key": "8671",
    "ITA": "MOLFETTA",
    "state_code": "BA",
    "cab": "415604",
    "cap": "70056"
  },
  {
    "key": "8672",
    "ITA": "MOLINA ATERNO",
    "state_code": "AQ",
    "cab": "909747",
    "cap": "67020"
  },
  {
    "key": "8673",
    "ITA": "MOLINA DI LEDRO-BIACESA DI LEDRO",
    "state_code": "TN",
    "cab": "350603",
    "cap": "38060"
  },
  {
    "key": "8674",
    "ITA": "MOLINA DI LEDRO",
    "state_code": "TN",
    "cab": "350603",
    "cap": "38060"
  },
  {
    "key": "8675",
    "ITA": "MOLINARA",
    "state_code": "BN",
    "cab": "885509",
    "cap": "82020"
  },
  {
    "key": "8676",
    "ITA": "MOLINELLA-SELVA",
    "state_code": "BO",
    "cab": "369207",
    "cap": "40062"
  },
  {
    "key": "8677",
    "ITA": "MOLINELLA-SAN PIETRO CAPOFIUME",
    "state_code": "BO",
    "cab": "369207",
    "cap": "40062"
  },
  {
    "key": "8678",
    "ITA": "MOLINELLA-SAN MARTINO IN ARGINE",
    "state_code": "BO",
    "cab": "369207",
    "cap": "40062"
  },
  {
    "key": "8679",
    "ITA": "MOLINELLA-MARMORTA",
    "state_code": "BO",
    "cab": "369207",
    "cap": "40062"
  },
  {
    "key": "8680",
    "ITA": "MOLINELLA",
    "state_code": "BO",
    "cab": "369207",
    "cap": "40062"
  },
  {
    "key": "8681",
    "ITA": "MOLINI DI TRIORA",
    "state_code": "IM",
    "cab": "922476",
    "cap": "18010"
  },
  {
    "key": "8682",
    "ITA": "MOLINI DI TRIORA-AGAGGIO INFERIORE",
    "state_code": "IM",
    "cab": "922476",
    "cap": "18010"
  },
  {
    "key": "8683",
    "ITA": "MOLINO DEI TORTI",
    "state_code": "AL",
    "cab": "488007",
    "cap": "15050"
  },
  {
    "key": "8684",
    "ITA": "MOLISE",
    "state_code": "CB",
    "cab": "910687",
    "cap": "86020"
  },
  {
    "key": "8685",
    "ITA": "MOLITERNO",
    "state_code": "PZ",
    "cab": "421107",
    "cap": "85047"
  },
  {
    "key": "8686",
    "ITA": "MOLLIA",
    "state_code": "VC",
    "cab": "916023",
    "cap": "13020"
  },
  {
    "key": "8687",
    "ITA": "MOLOCHIO",
    "state_code": "RC",
    "cab": "814509",
    "cap": "89010"
  },
  {
    "key": "8688",
    "ITA": "MOLTENO",
    "state_code": "LC",
    "cab": "515601",
    "cap": "23847"
  },
  {
    "key": "8689",
    "ITA": "MOLTENO-LUZZANA",
    "state_code": "LC",
    "cab": "515601",
    "cap": "23847"
  },
  {
    "key": "8690",
    "ITA": "MOLTRASIO",
    "state_code": "CO",
    "cab": "519405",
    "cap": "22010"
  },
  {
    "key": "8691",
    "ITA": "MOLVENA",
    "state_code": "VI",
    "cab": "883702",
    "cap": "36060"
  },
  {
    "key": "8692",
    "ITA": "MOLVENA-VILLA DI MOLVENA",
    "state_code": "VI",
    "cab": "883702",
    "cap": "36060"
  },
  {
    "key": "8693",
    "ITA": "MOLVENO",
    "state_code": "TN",
    "cab": "350702",
    "cap": "38018"
  },
  {
    "key": "8694",
    "ITA": "MOMBALDONE",
    "state_code": "AT",
    "cab": "920264",
    "cap": "14050"
  },
  {
    "key": "8695",
    "ITA": "MOMBARCARO-BRAGIOLI",
    "state_code": "CN",
    "cab": "918888",
    "cap": "12070"
  },
  {
    "key": "8696",
    "ITA": "MOMBARCARO",
    "state_code": "CN",
    "cab": "918888",
    "cap": "12070"
  },
  {
    "key": "8697",
    "ITA": "MOMBAROCCIO",
    "state_code": "PU",
    "cab": "683706",
    "cap": "61024"
  },
  {
    "key": "8698",
    "ITA": "MOMBARUZZO-BAZZANA",
    "state_code": "AT",
    "cab": "474809",
    "cap": "14046"
  },
  {
    "key": "8699",
    "ITA": "MOMBARUZZO",
    "state_code": "AT",
    "cab": "474809",
    "cap": "14046"
  },
  {
    "key": "8700",
    "ITA": "MOMBASIGLIO",
    "state_code": "CN",
    "cab": "566000",
    "cap": "12070"
  },
  {
    "key": "8701",
    "ITA": "MOMBELLO DI TORINO",
    "state_code": "TO",
    "cab": "283846",
    "cap": "10020"
  },
  {
    "key": "8702",
    "ITA": "MOMBELLO MONFERRATO",
    "state_code": "AL",
    "cab": "483800",
    "cap": "15020"
  },
  {
    "key": "8703",
    "ITA": "MOMBELLO MONFERRATO-POZZENGO",
    "state_code": "AL",
    "cab": "483800",
    "cap": "15020"
  },
  {
    "key": "8704",
    "ITA": "MOMBELLO MONFERRATO-CASALINO DI MOMBELLO",
    "state_code": "AL",
    "cab": "483800",
    "cap": "15020"
  },
  {
    "key": "8705",
    "ITA": "MOMBERCELLI",
    "state_code": "AT",
    "cab": "474908",
    "cap": "14047"
  },
  {
    "key": "8706",
    "ITA": "MOMO",
    "state_code": "NO",
    "cab": "455204",
    "cap": "28015"
  },
  {
    "key": "8707",
    "ITA": "MOMPANTERO",
    "state_code": "TO",
    "cab": "900902",
    "cap": "10059"
  },
  {
    "key": "8708",
    "ITA": "MOMPANTERO-SAN GIUSEPPE",
    "state_code": "TO",
    "cab": "900902",
    "cap": "10059"
  },
  {
    "key": "8709",
    "ITA": "MOMPEO",
    "state_code": "RI",
    "cab": "947259",
    "cap": "2040"
  },
  {
    "key": "8710",
    "ITA": "MOMPERONE",
    "state_code": "AL",
    "cab": "921304",
    "cap": "15050"
  },
  {
    "key": "8711",
    "ITA": "MONACILIONI",
    "state_code": "CB",
    "cab": "910695",
    "cap": "86040"
  },
  {
    "key": "8712",
    "ITA": "MONALE",
    "state_code": "AT",
    "cab": "475004",
    "cap": "14013"
  },
  {
    "key": "8713",
    "ITA": "MONASTERACE-MONASTERACE MARINA",
    "state_code": "RC",
    "cab": "814608",
    "cap": "89040"
  },
  {
    "key": "8714",
    "ITA": "MONASTERACE",
    "state_code": "RC",
    "cab": "814608",
    "cap": "89040"
  },
  {
    "key": "8715",
    "ITA": "MONASTERO BORMIDA",
    "state_code": "AT",
    "cab": "475103",
    "cap": "14058"
  },
  {
    "key": "8716",
    "ITA": "MONASTERO DI LANZO",
    "state_code": "TO",
    "cab": "285122",
    "cap": "10070"
  },
  {
    "key": "8717",
    "ITA": "MONASTERO DI VASCO",
    "state_code": "CN",
    "cab": "458703",
    "cap": "12080"
  },
  {
    "key": "8718",
    "ITA": "MONASTEROLO CASOTTO",
    "state_code": "CN",
    "cab": "918912",
    "cap": "12080"
  },
  {
    "key": "8719",
    "ITA": "MONASTEROLO DEL CASTELLO-MONASTEROLO",
    "state_code": "BG",
    "cab": "928309",
    "cap": "24060"
  },
  {
    "key": "8720",
    "ITA": "MONASTEROLO DEL CASTELLO",
    "state_code": "BG",
    "cab": "928309",
    "cap": "24060"
  },
  {
    "key": "8721",
    "ITA": "MONASTEROLO DI SAVIGLIANO",
    "state_code": "CN",
    "cab": "464701",
    "cap": "12030"
  },
  {
    "key": "8722",
    "ITA": "MONASTIER DI TREVISO",
    "state_code": "TV",
    "cab": "618108",
    "cap": "31050"
  },
  {
    "key": "8723",
    "ITA": "MONASTIR",
    "state_code": "CA",
    "cab": "860205",
    "cap": "9023"
  },
  {
    "key": "8724",
    "ITA": "MONCALIERI-REVIGLIASCO",
    "state_code": "TO",
    "cab": "200006",
    "cap": "10024"
  },
  {
    "key": "8725",
    "ITA": "MONCALIERI",
    "state_code": "TO",
    "cab": "200006",
    "cap": "10024"
  },
  {
    "key": "8726",
    "ITA": "MONCALIERI-BARAUDA",
    "state_code": "TO",
    "cab": "200006",
    "cap": "10024"
  },
  {
    "key": "8727",
    "ITA": "MONCALIERI-BORGO SAN PIETRO DI MONCALIERI",
    "state_code": "TO",
    "cab": "200006",
    "cap": "10024"
  },
  {
    "key": "8728",
    "ITA": "MONCALIERI-COLLE DELLA MADDALENA",
    "state_code": "TO",
    "cab": "200006",
    "cap": "10024"
  },
  {
    "key": "8729",
    "ITA": "MONCALIERI-TAGLIAFERRO",
    "state_code": "TO",
    "cab": "200006",
    "cap": "10024"
  },
  {
    "key": "8730",
    "ITA": "MONCALIERI-TESTONA",
    "state_code": "TO",
    "cab": "200006",
    "cap": "10024"
  },
  {
    "key": "8731",
    "ITA": "MONCALVO",
    "state_code": "AT",
    "cab": "475202",
    "cap": "14036"
  },
  {
    "key": "8732",
    "ITA": "MONCENISIO",
    "state_code": "TO",
    "cab": "900928",
    "cap": "10050"
  },
  {
    "key": "8733",
    "ITA": "MONCESTINO",
    "state_code": "AL",
    "cab": "921312",
    "cap": "15020"
  },
  {
    "key": "8734",
    "ITA": "MONCHIERO",
    "state_code": "CN",
    "cab": "429704",
    "cap": "12060"
  },
  {
    "key": "8735",
    "ITA": "MONCHIO DELLE CORTI-RIANA",
    "state_code": "PR",
    "cab": "658104",
    "cap": "43010"
  },
  {
    "key": "8736",
    "ITA": "MONCHIO DELLE CORTI-RIGOSO",
    "state_code": "PR",
    "cab": "658104",
    "cap": "43010"
  },
  {
    "key": "8737",
    "ITA": "MONCHIO DELLE CORTI-VALDITACCA",
    "state_code": "PR",
    "cab": "658104",
    "cap": "43010"
  },
  {
    "key": "8738",
    "ITA": "MONCHIO DELLE CORTI",
    "state_code": "PR",
    "cab": "658104",
    "cap": "43010"
  },
  {
    "key": "8739",
    "ITA": "MONCLASSICO",
    "state_code": "TN",
    "cab": "471607",
    "cap": "38020"
  },
  {
    "key": "8740",
    "ITA": "MONCRIVELLO",
    "state_code": "VC",
    "cab": "916031",
    "cap": "13040"
  },
  {
    "key": "8741",
    "ITA": "MONCUCCO TORINESE",
    "state_code": "AT",
    "cab": "920272",
    "cap": "14024"
  },
  {
    "key": "8742",
    "ITA": "MONDAINO",
    "state_code": "RN",
    "cab": "678805",
    "cap": "47836"
  },
  {
    "key": "8743",
    "ITA": "MONDAVIO",
    "state_code": "PU",
    "cab": "683805",
    "cap": "61040"
  },
  {
    "key": "8744",
    "ITA": "MONDAVIO-SAN FILIPPO SUL CESANO",
    "state_code": "PU",
    "cab": "683805",
    "cap": "61040"
  },
  {
    "key": "8745",
    "ITA": "MONDAVIO-SAN MICHELE AL FIUME",
    "state_code": "PU",
    "cab": "683805",
    "cap": "61040"
  },
  {
    "key": "8746",
    "ITA": "MONDAVIO-SANT'ANDREA DI SUASA",
    "state_code": "PU",
    "cab": "683805",
    "cap": "61040"
  },
  {
    "key": "8747",
    "ITA": "MONDOLFO-CENTOCROCI",
    "state_code": "PU",
    "cab": "683904",
    "cap": "61037"
  },
  {
    "key": "8748",
    "ITA": "MONDOLFO-MAROTTA",
    "state_code": "PU",
    "cab": "683904",
    "cap": "61037"
  },
  {
    "key": "8749",
    "ITA": "MONDOLFO",
    "state_code": "PU",
    "cab": "683904",
    "cap": "61037"
  },
  {
    "key": "8750",
    "ITA": "MONDOVI'-RIFREDDO MONDOVI'",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8751",
    "ITA": "MONDOVI'",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8752",
    "ITA": "MONDOVI'-PASCOMONTI",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8753",
    "ITA": "MONDOVI'-POGLIOLA",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8754",
    "ITA": "MONDOVI'-BREOLUNGI",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8755",
    "ITA": "MONDOVI'-GRATTERIA",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8756",
    "ITA": "MONDOVI'-PIAZZA",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8757",
    "ITA": "MONDOVI'-SANT'ANNA AVAGNINA",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8758",
    "ITA": "MONDOVI'-BREO",
    "state_code": "CN",
    "cab": "464800",
    "cap": "12084"
  },
  {
    "key": "8759",
    "ITA": "MONDRAGONE",
    "state_code": "CE",
    "cab": "749200",
    "cap": "81034"
  },
  {
    "key": "8760",
    "ITA": "MONEGLIA",
    "state_code": "GE",
    "cab": "320606",
    "cap": "16030"
  },
  {
    "key": "8761",
    "ITA": "MONESIGLIO",
    "state_code": "CN",
    "cab": "464909",
    "cap": "12077"
  },
  {
    "key": "8762",
    "ITA": "MONFALCONE-MARINA JULIA",
    "state_code": "GO",
    "cab": "646109",
    "cap": "34074"
  },
  {
    "key": "8763",
    "ITA": "MONFALCONE",
    "state_code": "GO",
    "cab": "646109",
    "cap": "34074"
  },
  {
    "key": "8764",
    "ITA": "MONFORTE D'ALBA",
    "state_code": "CN",
    "cab": "465005",
    "cap": "12065"
  },
  {
    "key": "8765",
    "ITA": "MONFORTE D'ALBA-PERNO",
    "state_code": "CN",
    "cab": "465005",
    "cap": "12065"
  },
  {
    "key": "8766",
    "ITA": "MONFORTE SAN GIORGIO-MONFORTE MARINA",
    "state_code": "ME",
    "cab": "823104",
    "cap": "98041"
  },
  {
    "key": "8767",
    "ITA": "MONFORTE SAN GIORGIO-PELLEGRINO",
    "state_code": "ME",
    "cab": "823104",
    "cap": "98041"
  },
  {
    "key": "8768",
    "ITA": "MONFORTE SAN GIORGIO",
    "state_code": "ME",
    "cab": "823104",
    "cap": "98041"
  },
  {
    "key": "8769",
    "ITA": "MONFUMO",
    "state_code": "TV",
    "cab": "705806",
    "cap": "31010"
  },
  {
    "key": "8770",
    "ITA": "MONGARDINO",
    "state_code": "AT",
    "cab": "475301",
    "cap": "14040"
  },
  {
    "key": "8771",
    "ITA": "MONGHIDORO",
    "state_code": "BO",
    "cab": "369306",
    "cap": "40063"
  },
  {
    "key": "8772",
    "ITA": "MONGIANA",
    "state_code": "VV",
    "cab": "913160",
    "cap": "89823"
  },
  {
    "key": "8773",
    "ITA": "MONGIARDINO LIGURE",
    "state_code": "AL",
    "cab": "921320",
    "cap": "15060"
  },
  {
    "key": "8774",
    "ITA": "MONGIUFFI MELIA",
    "state_code": "ME",
    "cab": "961474",
    "cap": "98030"
  },
  {
    "key": "8775",
    "ITA": "MONGIUFFI MELIA-MELIA",
    "state_code": "ME",
    "cab": "961474",
    "cap": "98030"
  },
  {
    "key": "8776",
    "ITA": "MONGIUFFI MELIA-MONGIUFFI",
    "state_code": "ME",
    "cab": "961474",
    "cap": "98030"
  },
  {
    "key": "8777",
    "ITA": "MONGRANDO",
    "state_code": "BI",
    "cab": "445700",
    "cap": "13888"
  },
  {
    "key": "8778",
    "ITA": "MONGRANDO-CERESANE",
    "state_code": "BI",
    "cab": "445700",
    "cap": "13888"
  },
  {
    "key": "8779",
    "ITA": "MONGRANDO-CURANUOVA",
    "state_code": "BI",
    "cab": "445700",
    "cap": "13888"
  },
  {
    "key": "8780",
    "ITA": "MONGRASSANO",
    "state_code": "CS",
    "cab": "811307",
    "cap": "87040"
  },
  {
    "key": "8781",
    "ITA": "MONGRASSANO-STAZIONE DI MONGRASSANO",
    "state_code": "CS",
    "cab": "811307",
    "cap": "87040"
  },
  {
    "key": "8782",
    "ITA": "MONGUELFO-TESIDO",
    "state_code": "BZ",
    "cab": "586008",
    "cap": "39035"
  },
  {
    "key": "8783",
    "ITA": "MONGUELFO",
    "state_code": "BZ",
    "cab": "586008",
    "cap": "39035"
  },
  {
    "key": "8784",
    "ITA": "MONGUZZO",
    "state_code": "CO",
    "cab": "183251",
    "cap": "22040"
  },
  {
    "key": "8785",
    "ITA": "MONGUZZO-NOBILE",
    "state_code": "CO",
    "cab": "183251",
    "cap": "22040"
  },
  {
    "key": "8786",
    "ITA": "MONIGA DEL GARDA",
    "state_code": "BS",
    "cab": "547703",
    "cap": "25080"
  },
  {
    "key": "8787",
    "ITA": "MONLEALE",
    "state_code": "AL",
    "cab": "483909",
    "cap": "15059"
  },
  {
    "key": "8788",
    "ITA": "MONNO",
    "state_code": "BS",
    "cab": "929489",
    "cap": "25040"
  },
  {
    "key": "8789",
    "ITA": "MONOPOLI-IMPALATA",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8790",
    "ITA": "MONOPOLI-LAMALUNGA",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8791",
    "ITA": "MONOPOLI-SANTA LUCIA",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8792",
    "ITA": "MONOPOLI-SANT'ANTONIO D'ASCULA",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8793",
    "ITA": "MONOPOLI",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8794",
    "ITA": "MONOPOLI-ANTONELLI",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8795",
    "ITA": "MONOPOLI-COZZANA",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8796",
    "ITA": "MONOPOLI-GORGOFREDDO",
    "state_code": "BA",
    "cab": "415703",
    "cap": "70043"
  },
  {
    "key": "8797",
    "ITA": "MONREALE-GRISI'",
    "state_code": "PA",
    "cab": "434506",
    "cap": "90040"
  },
  {
    "key": "8798",
    "ITA": "MONREALE-VILLA CIAMBRA",
    "state_code": "PA",
    "cab": "434506",
    "cap": "90046"
  },
  {
    "key": "8799",
    "ITA": "MONREALE-SAN MARTINO DELLE SCALE",
    "state_code": "PA",
    "cab": "434506",
    "cap": "90046"
  },
  {
    "key": "8800",
    "ITA": "MONREALE",
    "state_code": "PA",
    "cab": "434506",
    "cap": "90046"
  },
  {
    "key": "8801",
    "ITA": "MONREALE-MALPASSO",
    "state_code": "PA",
    "cab": "434506",
    "cap": "90046"
  },
  {
    "key": "8802",
    "ITA": "MONREALE-PIOPPO",
    "state_code": "PA",
    "cab": "434506",
    "cap": "90046"
  },
  {
    "key": "8803",
    "ITA": "MONREALE-POGGIO SAN FRANCESCO",
    "state_code": "PA",
    "cab": "434506",
    "cap": "90046"
  },
  {
    "key": "8804",
    "ITA": "MONRUPINO",
    "state_code": "TS",
    "cab": "364703",
    "cap": "34016"
  },
  {
    "key": "8805",
    "ITA": "MONSAMPIETRO MORICO-SANT'ELPIDIO MORICO",
    "state_code": "FM",
    "cab": "942532",
    "cap": "63029"
  },
  {
    "key": "8806",
    "ITA": "MONSAMPIETRO MORICO",
    "state_code": "FM",
    "cab": "942532",
    "cap": "63029"
  },
  {
    "key": "8807",
    "ITA": "MONSAMPOLO DEL TRONTO-STELLA DI MONSAMPOLO",
    "state_code": "AP",
    "cab": "694901",
    "cap": "63030"
  },
  {
    "key": "8808",
    "ITA": "MONSAMPOLO DEL TRONTO",
    "state_code": "AP",
    "cab": "694901",
    "cap": "63030"
  },
  {
    "key": "8809",
    "ITA": "MONSANO",
    "state_code": "AN",
    "cab": "374009",
    "cap": "60030"
  },
  {
    "key": "8810",
    "ITA": "MONSELICE-COSTA CALCINARA",
    "state_code": "PD",
    "cab": "626606",
    "cap": "35043"
  },
  {
    "key": "8811",
    "ITA": "MONSELICE",
    "state_code": "PD",
    "cab": "626606",
    "cap": "35043"
  },
  {
    "key": "8812",
    "ITA": "MONSELICE-SAN COSMA",
    "state_code": "PD",
    "cab": "626606",
    "cap": "35043"
  },
  {
    "key": "8813",
    "ITA": "MONSERRATO",
    "state_code": "CA",
    "cab": "488205",
    "cap": "9042"
  },
  {
    "key": "8814",
    "ITA": "MONSUMMANO TERME-UGGIA",
    "state_code": "PT",
    "cab": "704403",
    "cap": "51015"
  },
  {
    "key": "8815",
    "ITA": "MONSUMMANO TERME",
    "state_code": "PT",
    "cab": "704403",
    "cap": "51015"
  },
  {
    "key": "8816",
    "ITA": "MONSUMMANO TERME-GROTTA GIUSTI",
    "state_code": "PT",
    "cab": "704403",
    "cap": "51015"
  },
  {
    "key": "8817",
    "ITA": "MONSUMMANO TERME-CINTOLESE",
    "state_code": "PT",
    "cab": "704403",
    "cap": "51015"
  },
  {
    "key": "8818",
    "ITA": "MONSUMMANO TERME-POZZARELLO",
    "state_code": "PT",
    "cab": "704403",
    "cap": "51015"
  },
  {
    "key": "8819",
    "ITA": "MONSUMMANO TERME-PAZZERA",
    "state_code": "PT",
    "cab": "704403",
    "cap": "51015"
  },
  {
    "key": "8820",
    "ITA": "MONSUMMANO TERME-MONTEVETTOLINI",
    "state_code": "PT",
    "cab": "704403",
    "cap": "51015"
  },
  {
    "key": "8821",
    "ITA": "MONTA'-SAN ROCCO",
    "state_code": "CN",
    "cab": "465104",
    "cap": "12046"
  },
  {
    "key": "8822",
    "ITA": "MONTA'",
    "state_code": "CN",
    "cab": "465104",
    "cap": "12046"
  },
  {
    "key": "8823",
    "ITA": "MONTABONE",
    "state_code": "AT",
    "cab": "287334",
    "cap": "14040"
  },
  {
    "key": "8824",
    "ITA": "MONTACUTO",
    "state_code": "AL",
    "cab": "921338",
    "cap": "15050"
  },
  {
    "key": "8825",
    "ITA": "MONTAFIA",
    "state_code": "AT",
    "cab": "475400",
    "cap": "14014"
  },
  {
    "key": "8826",
    "ITA": "MONTAGANO",
    "state_code": "CB",
    "cab": "910703",
    "cap": "86023"
  },
  {
    "key": "8827",
    "ITA": "MONTAGNA",
    "state_code": "BZ",
    "cab": "592204",
    "cap": "39040"
  },
  {
    "key": "8828",
    "ITA": "MONTAGNA IN VALTELLINA",
    "state_code": "SO",
    "cab": "522201",
    "cap": "23020"
  },
  {
    "key": "8829",
    "ITA": "MONTAGNANA",
    "state_code": "PD",
    "cab": "626705",
    "cap": "35044"
  },
  {
    "key": "8830",
    "ITA": "MONTAGNAREALE",
    "state_code": "ME",
    "cab": "827501",
    "cap": "98060"
  },
  {
    "key": "8831",
    "ITA": "MONTAGNAREALE-SAN LORENZITTO",
    "state_code": "ME",
    "cab": "827501",
    "cap": "98060"
  },
  {
    "key": "8832",
    "ITA": "MONTAGNE",
    "state_code": "TN",
    "cab": "350801",
    "cap": "38070"
  },
  {
    "key": "8833",
    "ITA": "MONTAGUTO",
    "state_code": "AV",
    "cab": "951251",
    "cap": "83030"
  },
  {
    "key": "8834",
    "ITA": "MONTAIONE",
    "state_code": "FI",
    "cab": "379503",
    "cap": "50050"
  },
  {
    "key": "8835",
    "ITA": "MONTALBANO ELICONA-SANTA BARBARA",
    "state_code": "ME",
    "cab": "823203",
    "cap": "98065"
  },
  {
    "key": "8836",
    "ITA": "MONTALBANO ELICONA-BRAIDI",
    "state_code": "ME",
    "cab": "823203",
    "cap": "98065"
  },
  {
    "key": "8837",
    "ITA": "MONTALBANO ELICONA",
    "state_code": "ME",
    "cab": "823203",
    "cap": "98065"
  },
  {
    "key": "8838",
    "ITA": "MONTALBANO JONICO",
    "state_code": "MT",
    "cab": "803601",
    "cap": "75023"
  },
  {
    "key": "8839",
    "ITA": "MONTALCINO-MONTE AMIATA",
    "state_code": "SI",
    "cab": "255000",
    "cap": "53024"
  },
  {
    "key": "8840",
    "ITA": "MONTALCINO-SANT'ANGELO IN COLLE",
    "state_code": "SI",
    "cab": "255000",
    "cap": "53024"
  },
  {
    "key": "8841",
    "ITA": "MONTALCINO",
    "state_code": "SI",
    "cab": "255000",
    "cap": "53024"
  },
  {
    "key": "8842",
    "ITA": "MONTALCINO-TORRENIERI",
    "state_code": "SI",
    "cab": "255000",
    "cap": "53024"
  },
  {
    "key": "8843",
    "ITA": "MONTALCINO-CASTELNUOVO DELL'ABATE",
    "state_code": "SI",
    "cab": "255000",
    "cap": "53024"
  },
  {
    "key": "8844",
    "ITA": "MONTALDEO",
    "state_code": "AL",
    "cab": "921346",
    "cap": "15060"
  },
  {
    "key": "8845",
    "ITA": "MONTALDO BORMIDA",
    "state_code": "AL",
    "cab": "921353",
    "cap": "15010"
  },
  {
    "key": "8846",
    "ITA": "MONTALDO DI MONDOVI'",
    "state_code": "CN",
    "cab": "918938",
    "cap": "12080"
  },
  {
    "key": "8847",
    "ITA": "MONTALDO ROERO",
    "state_code": "CN",
    "cab": "918946",
    "cap": "12040"
  },
  {
    "key": "8848",
    "ITA": "MONTALDO SCARAMPI",
    "state_code": "AT",
    "cab": "920298",
    "cap": "14048"
  },
  {
    "key": "8849",
    "ITA": "MONTALDO TORINESE",
    "state_code": "TO",
    "cab": "283879",
    "cap": "10020"
  },
  {
    "key": "8850",
    "ITA": "MONTALE-TOBBIANA",
    "state_code": "PT",
    "cab": "704502",
    "cap": "51037"
  },
  {
    "key": "8851",
    "ITA": "MONTALE-STAZIONE DI MONTALE",
    "state_code": "PT",
    "cab": "704502",
    "cap": "51037"
  },
  {
    "key": "8852",
    "ITA": "MONTALE",
    "state_code": "PT",
    "cab": "704502",
    "cap": "51037"
  },
  {
    "key": "8853",
    "ITA": "MONTALE-FOGNANO",
    "state_code": "PT",
    "cab": "704502",
    "cap": "51037"
  },
  {
    "key": "8854",
    "ITA": "MONTALENGHE",
    "state_code": "TO",
    "cab": "283903",
    "cap": "10090"
  },
  {
    "key": "8855",
    "ITA": "MONTALLEGRO",
    "state_code": "AG",
    "cab": "830000",
    "cap": "92010"
  },
  {
    "key": "8856",
    "ITA": "MONTALTO DELLE MARCHE",
    "state_code": "AP",
    "cab": "695007",
    "cap": "63034"
  },
  {
    "key": "8857",
    "ITA": "MONTALTO DELLE MARCHE-PATRIGNONE",
    "state_code": "AP",
    "cab": "695007",
    "cap": "63034"
  },
  {
    "key": "8858",
    "ITA": "MONTALTO DELLE MARCHE-PORCHIA",
    "state_code": "AP",
    "cab": "695007",
    "cap": "63034"
  },
  {
    "key": "8859",
    "ITA": "MONTALTO DI CASTRO-PESCIA ROMANA",
    "state_code": "VT",
    "cab": "731505",
    "cap": "1014"
  },
  {
    "key": "8860",
    "ITA": "MONTALTO DI CASTRO",
    "state_code": "VT",
    "cab": "731505",
    "cap": "1014"
  },
  {
    "key": "8861",
    "ITA": "MONTALTO DORA",
    "state_code": "TO",
    "cab": "306308",
    "cap": "10016"
  },
  {
    "key": "8862",
    "ITA": "MONTALTO LIGURE",
    "state_code": "IM",
    "cab": "922484",
    "cap": "18010"
  },
  {
    "key": "8863",
    "ITA": "MONTALTO PAVESE",
    "state_code": "PV",
    "cab": "930602",
    "cap": "27040"
  },
  {
    "key": "8864",
    "ITA": "MONTALTO UFFUGO-TAVERNA DI MONTALTO UFFUGO",
    "state_code": "CS",
    "cab": "808204",
    "cap": "87040"
  },
  {
    "key": "8865",
    "ITA": "MONTALTO UFFUGO-PARANTORO",
    "state_code": "CS",
    "cab": "808204",
    "cap": "87046"
  },
  {
    "key": "8866",
    "ITA": "MONTALTO UFFUGO-CALDOPIANO",
    "state_code": "CS",
    "cab": "808204",
    "cap": "87046"
  },
  {
    "key": "8867",
    "ITA": "MONTALTO UFFUGO",
    "state_code": "CS",
    "cab": "808204",
    "cap": "87046"
  },
  {
    "key": "8868",
    "ITA": "MONTALTO UFFUGO-VACCARIZZO",
    "state_code": "CS",
    "cab": "808204",
    "cap": "87046"
  },
  {
    "key": "8869",
    "ITA": "MONTALTO UFFUGO-MONTALTO UFFUGO SCALO",
    "state_code": "CS",
    "cab": "808204",
    "cap": "87040"
  },
  {
    "key": "8870",
    "ITA": "MONTANARO",
    "state_code": "TO",
    "cab": "306407",
    "cap": "10017"
  },
  {
    "key": "8871",
    "ITA": "MONTANASO LOMBARDO",
    "state_code": "LO",
    "cab": "671107",
    "cap": "26836"
  },
  {
    "key": "8872",
    "ITA": "MONTANERA",
    "state_code": "CN",
    "cab": "495903",
    "cap": "12040"
  },
  {
    "key": "8873",
    "ITA": "MONTANO ANTILIA-MASSICELLE",
    "state_code": "SA",
    "cab": "488304",
    "cap": "84060"
  },
  {
    "key": "8874",
    "ITA": "MONTANO ANTILIA-ABATEMARCO",
    "state_code": "SA",
    "cab": "488304",
    "cap": "84060"
  },
  {
    "key": "8875",
    "ITA": "MONTANO ANTILIA",
    "state_code": "SA",
    "cab": "488304",
    "cap": "84060"
  },
  {
    "key": "8876",
    "ITA": "MONTANO LUCINO",
    "state_code": "CO",
    "cab": "515700",
    "cap": "22070"
  },
  {
    "key": "8877",
    "ITA": "MONTANO LUCINO-LUCINO",
    "state_code": "CO",
    "cab": "515700",
    "cap": "22070"
  },
  {
    "key": "8878",
    "ITA": "MONTANO LUCINO-MONTANO",
    "state_code": "CO",
    "cab": "515700",
    "cap": "22070"
  },
  {
    "key": "8879",
    "ITA": "MONTAPPONE",
    "state_code": "FM",
    "cab": "695106",
    "cap": "63020"
  },
  {
    "key": "8880",
    "ITA": "MONTAQUILA",
    "state_code": "IS",
    "cab": "781500",
    "cap": "86070"
  },
  {
    "key": "8881",
    "ITA": "MONTAQUILA-ROCCARAVINDOLA",
    "state_code": "IS",
    "cab": "781500",
    "cap": "86070"
  },
  {
    "key": "8882",
    "ITA": "MONTAQUILA-ROCCARAVINDOLA STAZIONE",
    "state_code": "IS",
    "cab": "781500",
    "cap": "86070"
  },
  {
    "key": "8883",
    "ITA": "MONTAQUILA-TAVERNA RAVINDOLA",
    "state_code": "IS",
    "cab": "781500",
    "cap": "86070"
  },
  {
    "key": "8884",
    "ITA": "MONTASOLA",
    "state_code": "RI",
    "cab": "947267",
    "cap": "2040"
  },
  {
    "key": "8885",
    "ITA": "MONTAURO-MONTAURO STAZIONE",
    "state_code": "CZ",
    "cab": "913178",
    "cap": "88060"
  },
  {
    "key": "8886",
    "ITA": "MONTAURO",
    "state_code": "CZ",
    "cab": "913178",
    "cap": "88060"
  },
  {
    "key": "8887",
    "ITA": "MONTAZZOLI",
    "state_code": "CH",
    "cab": "954776",
    "cap": "66030"
  },
  {
    "key": "8888",
    "ITA": "MONTE ARGENTARIO-PORTO SANTO STEFANO",
    "state_code": "GR",
    "cab": "723007",
    "cap": "58019"
  },
  {
    "key": "8889",
    "ITA": "MONTE ARGENTARIO",
    "state_code": "GR",
    "cab": "723007",
    "cap": "58019"
  },
  {
    "key": "8890",
    "ITA": "MONTE ARGENTARIO-PORTO ERCOLE",
    "state_code": "GR",
    "cab": "723007",
    "cap": "58018"
  },
  {
    "key": "8891",
    "ITA": "MONTE CASTELLO DI VIBIO",
    "state_code": "PG",
    "cab": "385302",
    "cap": "6057"
  },
  {
    "key": "8892",
    "ITA": "MONTE CAVALLO",
    "state_code": "MC",
    "cab": "941971",
    "cap": "62036"
  },
  {
    "key": "8893",
    "ITA": "MONTE CERIGNONE-VALLE DI TEVA",
    "state_code": "PU",
    "cab": "941484",
    "cap": "61010"
  },
  {
    "key": "8894",
    "ITA": "MONTE CERIGNONE",
    "state_code": "PU",
    "cab": "941484",
    "cap": "61010"
  },
  {
    "key": "8895",
    "ITA": "MONTE COLOMBO-CROCE",
    "state_code": "RN",
    "cab": "678904",
    "cap": "47854"
  },
  {
    "key": "8896",
    "ITA": "MONTE COLOMBO",
    "state_code": "RN",
    "cab": "678904",
    "cap": "47854"
  },
  {
    "key": "8897",
    "ITA": "MONTE COLOMBO-TAVERNA DI MONTE COLOMBO",
    "state_code": "RN",
    "cab": "678904",
    "cap": "47854"
  },
  {
    "key": "8898",
    "ITA": "MONTE COLOMBO-SAN SAVINO",
    "state_code": "RN",
    "cab": "678904",
    "cap": "47854"
  },
  {
    "key": "8899",
    "ITA": "MONTE COMPATRI",
    "state_code": "RM",
    "cab": "392001",
    "cap": "77"
  },
  {
    "key": "8900",
    "ITA": "MONTE CREMASCO",
    "state_code": "CR",
    "cab": "569103",
    "cap": "26010"
  },
  {
    "key": "8901",
    "ITA": "MONTE DI MALO",
    "state_code": "VI",
    "cab": "656009",
    "cap": "36030"
  },
  {
    "key": "8902",
    "ITA": "MONTE DI MALO-PRIABONA",
    "state_code": "VI",
    "cab": "656009",
    "cap": "36030"
  },
  {
    "key": "8903",
    "ITA": "MONTE DI PROCIDA",
    "state_code": "NA",
    "cab": "400002",
    "cap": "80070"
  },
  {
    "key": "8904",
    "ITA": "MONTE GIBERTO",
    "state_code": "FM",
    "cab": "198572",
    "cap": "63020"
  },
  {
    "key": "8905",
    "ITA": "MONTE GRIMANO TERME",
    "state_code": "PU",
    "cab": "684209",
    "cap": "61010"
  },
  {
    "key": "8906",
    "ITA": "MONTE ISOLA",
    "state_code": "BS",
    "cab": "555409",
    "cap": "25050"
  },
  {
    "key": "8907",
    "ITA": "MONTE ISOLA-PESCHIERA MARAGLIO",
    "state_code": "BS",
    "cab": "555409",
    "cap": "25050"
  },
  {
    "key": "8908",
    "ITA": "MONTE ISOLA-SIVIANO",
    "state_code": "BS",
    "cab": "555409",
    "cap": "25050"
  },
  {
    "key": "8909",
    "ITA": "MONTE MARENZO",
    "state_code": "LC",
    "cab": "734103",
    "cap": "23804"
  },
  {
    "key": "8910",
    "ITA": "MONTE PORZIO",
    "state_code": "PU",
    "cab": "684506",
    "cap": "61040"
  },
  {
    "key": "8911",
    "ITA": "MONTE PORZIO-CASTELVECCHIO",
    "state_code": "PU",
    "cab": "684506",
    "cap": "61040"
  },
  {
    "key": "8912",
    "ITA": "MONTE PORZIO CATONE",
    "state_code": "RM",
    "cab": "392308",
    "cap": "40"
  },
  {
    "key": "8913",
    "ITA": "MONTE PORZIO CATONE-ARMETTA",
    "state_code": "RM",
    "cab": "392308",
    "cap": "40"
  },
  {
    "key": "8914",
    "ITA": "MONTE RINALDO",
    "state_code": "FM",
    "cab": "942607",
    "cap": "63020"
  },
  {
    "key": "8915",
    "ITA": "MONTE ROBERTO",
    "state_code": "AN",
    "cab": "374405",
    "cap": "60030"
  },
  {
    "key": "8916",
    "ITA": "MONTE ROBERTO-PIANELLO VALLESINA",
    "state_code": "AN",
    "cab": "374405",
    "cap": "60030"
  },
  {
    "key": "8917",
    "ITA": "MONTE ROMANO",
    "state_code": "VT",
    "cab": "731703",
    "cap": "1010"
  },
  {
    "key": "8918",
    "ITA": "MONTE SAN BIAGIO",
    "state_code": "LT",
    "cab": "740407",
    "cap": "4020"
  },
  {
    "key": "8919",
    "ITA": "MONTE SAN GIACOMO",
    "state_code": "SA",
    "cab": "952697",
    "cap": "84030"
  },
  {
    "key": "8920",
    "ITA": "MONTE SAN GIOVANNI CAMPANO-ANITRELLA",
    "state_code": "FR",
    "cab": "744805",
    "cap": "3025"
  },
  {
    "key": "8921",
    "ITA": "MONTE SAN GIOVANNI CAMPANO-COLLI",
    "state_code": "FR",
    "cab": "744805",
    "cap": "3025"
  },
  {
    "key": "8922",
    "ITA": "MONTE SAN GIOVANNI CAMPANO",
    "state_code": "FR",
    "cab": "744805",
    "cap": "3025"
  },
  {
    "key": "8923",
    "ITA": "MONTE SAN GIOVANNI CAMPANO-PORRINO",
    "state_code": "FR",
    "cab": "744805",
    "cap": "3025"
  },
  {
    "key": "8924",
    "ITA": "MONTE SAN GIOVANNI CAMPANO-CHIAIAMARI",
    "state_code": "FR",
    "cab": "744805",
    "cap": "3025"
  },
  {
    "key": "8925",
    "ITA": "MONTE SAN GIOVANNI IN SABINA",
    "state_code": "RI",
    "cab": "947291",
    "cap": "2040"
  },
  {
    "key": "8926",
    "ITA": "MONTE SAN GIUSTO-VILLA SAN FILIPPO",
    "state_code": "MC",
    "cab": "690107",
    "cap": "62015"
  },
  {
    "key": "8927",
    "ITA": "MONTE SAN GIUSTO",
    "state_code": "MC",
    "cab": "690107",
    "cap": "62015"
  },
  {
    "key": "8928",
    "ITA": "MONTE SAN MARTINO",
    "state_code": "MC",
    "cab": "690206",
    "cap": "62020"
  },
  {
    "key": "8929",
    "ITA": "MONTE SAN PIETRANGELI",
    "state_code": "FM",
    "cab": "695908",
    "cap": "63815"
  },
  {
    "key": "8930",
    "ITA": "MONTE SAN PIETRO-SAN MARTINO",
    "state_code": "BO",
    "cab": "369504",
    "cap": "40050"
  },
  {
    "key": "8931",
    "ITA": "MONTE SAN PIETRO-MONTE SAN GIOVANNI",
    "state_code": "BO",
    "cab": "369504",
    "cap": "40050"
  },
  {
    "key": "8932",
    "ITA": "MONTE SAN PIETRO-GAVIGNANO",
    "state_code": "BO",
    "cab": "369504",
    "cap": "40050"
  },
  {
    "key": "8933",
    "ITA": "MONTE SAN PIETRO-CALDERINO",
    "state_code": "BO",
    "cab": "369504",
    "cap": "40050"
  },
  {
    "key": "8934",
    "ITA": "MONTE SAN PIETRO",
    "state_code": "BO",
    "cab": "369504",
    "cap": "40050"
  },
  {
    "key": "8935",
    "ITA": "MONTE SAN SAVINO-MONTAGNANO",
    "state_code": "AR",
    "cab": "715300",
    "cap": "52048"
  },
  {
    "key": "8936",
    "ITA": "MONTE SAN SAVINO-ALBERORO",
    "state_code": "AR",
    "cab": "715300",
    "cap": "52048"
  },
  {
    "key": "8937",
    "ITA": "MONTE SAN SAVINO",
    "state_code": "AR",
    "cab": "715300",
    "cap": "52048"
  },
  {
    "key": "8938",
    "ITA": "MONTE SAN SAVINO-PALAZZUOLO",
    "state_code": "AR",
    "cab": "715300",
    "cap": "52048"
  },
  {
    "key": "8939",
    "ITA": "MONTE SAN VITO-BORGHETTO",
    "state_code": "AN",
    "cab": "374504",
    "cap": "60037"
  },
  {
    "key": "8940",
    "ITA": "MONTE SAN VITO-LE COZZE",
    "state_code": "AN",
    "cab": "374504",
    "cap": "60037"
  },
  {
    "key": "8941",
    "ITA": "MONTE SAN VITO",
    "state_code": "AN",
    "cab": "374504",
    "cap": "60037"
  },
  {
    "key": "8942",
    "ITA": "MONTE SANT'ANGELO",
    "state_code": "FG",
    "cab": "784900",
    "cap": "71037"
  },
  {
    "key": "8943",
    "ITA": "MONTE SANT'ANGELO-MACCHIA",
    "state_code": "FG",
    "cab": "784900",
    "cap": "71030"
  },
  {
    "key": "8944",
    "ITA": "MONTE SANTA MARIA TIBERINA-LIPPIANO",
    "state_code": "PG",
    "cab": "907600",
    "cap": "6010"
  },
  {
    "key": "8945",
    "ITA": "MONTE SANTA MARIA TIBERINA",
    "state_code": "PG",
    "cab": "907600",
    "cap": "6010"
  },
  {
    "key": "8946",
    "ITA": "MONTE URANO",
    "state_code": "FM",
    "cab": "696005",
    "cap": "63015"
  },
  {
    "key": "8947",
    "ITA": "MONTE VIDON COMBATTE",
    "state_code": "FM",
    "cab": "198754",
    "cap": "63027"
  },
  {
    "key": "8948",
    "ITA": "MONTE VIDON CORRADO",
    "state_code": "FM",
    "cab": "198663",
    "cap": "63020"
  },
  {
    "key": "8949",
    "ITA": "MONTEBELLO DELLA BATTAGLIA",
    "state_code": "PV",
    "cab": "560409",
    "cap": "27054"
  },
  {
    "key": "8950",
    "ITA": "MONTEBELLO DI BERTONA",
    "state_code": "PE",
    "cab": "954073",
    "cap": "65010"
  },
  {
    "key": "8951",
    "ITA": "MONTEBELLO IONICO-MASELLA",
    "state_code": "RC",
    "cab": "814707",
    "cap": "89064"
  },
  {
    "key": "8952",
    "ITA": "MONTEBELLO IONICO-FOSSATO IONICO",
    "state_code": "RC",
    "cab": "814707",
    "cap": "89060"
  },
  {
    "key": "8953",
    "ITA": "MONTEBELLO IONICO",
    "state_code": "RC",
    "cab": "814707",
    "cap": "89064"
  },
  {
    "key": "8954",
    "ITA": "MONTEBELLO IONICO-SALINE IONICHE",
    "state_code": "RC",
    "cab": "814707",
    "cap": "89060"
  },
  {
    "key": "8955",
    "ITA": "MONTEBELLO SUL SANGRO",
    "state_code": "CH",
    "cab": "954545",
    "cap": "66040"
  },
  {
    "key": "8956",
    "ITA": "MONTEBELLO SUL SANGRO-BUONANOTTE",
    "state_code": "CH",
    "cab": "954545",
    "cap": "66040"
  },
  {
    "key": "8957",
    "ITA": "MONTEBELLO VICENTINO",
    "state_code": "VI",
    "cab": "605204",
    "cap": "36054"
  },
  {
    "key": "8958",
    "ITA": "MONTEBELLUNA-BIADENE",
    "state_code": "TV",
    "cab": "618207",
    "cap": "31044"
  },
  {
    "key": "8959",
    "ITA": "MONTEBELLUNA",
    "state_code": "TV",
    "cab": "618207",
    "cap": "31044"
  },
  {
    "key": "8960",
    "ITA": "MONTEBRUNO",
    "state_code": "GE",
    "cab": "676908",
    "cap": "16025"
  },
  {
    "key": "8961",
    "ITA": "MONTEBUONO",
    "state_code": "RI",
    "cab": "736801",
    "cap": "2040"
  },
  {
    "key": "8962",
    "ITA": "MONTEBUONO-FIANELLO",
    "state_code": "RI",
    "cab": "736801",
    "cap": "2040"
  },
  {
    "key": "8963",
    "ITA": "MONTECALVO IN FOGLIA-CA' GALLO",
    "state_code": "PU",
    "cab": "687103",
    "cap": "61020"
  },
  {
    "key": "8964",
    "ITA": "MONTECALVO IN FOGLIA",
    "state_code": "PU",
    "cab": "687103",
    "cap": "61020"
  },
  {
    "key": "8965",
    "ITA": "MONTECALVO IN FOGLIA-BORGO MASSANO",
    "state_code": "PU",
    "cab": "687103",
    "cap": "61020"
  },
  {
    "key": "8966",
    "ITA": "MONTECALVO IRPINO",
    "state_code": "AV",
    "cab": "758805",
    "cap": "83037"
  },
  {
    "key": "8967",
    "ITA": "MONTECALVO VERSIGGIA",
    "state_code": "PV",
    "cab": "930610",
    "cap": "27047"
  },
  {
    "key": "8968",
    "ITA": "MONTECARLO",
    "state_code": "LU",
    "cab": "701904",
    "cap": "55015"
  },
  {
    "key": "8969",
    "ITA": "MONTECARLO-SAN SALVATORE",
    "state_code": "LU",
    "cab": "701904",
    "cap": "55015"
  },
  {
    "key": "8970",
    "ITA": "MONTECARLO-TURCHETTO",
    "state_code": "LU",
    "cab": "701904",
    "cap": "55015"
  },
  {
    "key": "8971",
    "ITA": "MONTECAROTTO",
    "state_code": "AN",
    "cab": "374108",
    "cap": "60036"
  },
  {
    "key": "8972",
    "ITA": "MONTECASSIANO-SANT'EGIDIO",
    "state_code": "MC",
    "cab": "689703",
    "cap": "62010"
  },
  {
    "key": "8973",
    "ITA": "MONTECASSIANO-SAMBUCHETO",
    "state_code": "MC",
    "cab": "689703",
    "cap": "62010"
  },
  {
    "key": "8974",
    "ITA": "MONTECASSIANO",
    "state_code": "MC",
    "cab": "689703",
    "cap": "62010"
  },
  {
    "key": "8975",
    "ITA": "MONTECASTELLO",
    "state_code": "AL",
    "cab": "921361",
    "cap": "15040"
  },
  {
    "key": "8976",
    "ITA": "MONTECASTRILLI",
    "state_code": "TR",
    "cab": "726604",
    "cap": "5026"
  },
  {
    "key": "8977",
    "ITA": "MONTECASTRILLI-CASTEL DELL'AQUILA",
    "state_code": "TR",
    "cab": "726604",
    "cap": "5026"
  },
  {
    "key": "8978",
    "ITA": "MONTECASTRILLI-CASTELTODINO",
    "state_code": "TR",
    "cab": "726604",
    "cap": "5026"
  },
  {
    "key": "8979",
    "ITA": "MONTECASTRILLI-COLLESECCO",
    "state_code": "TR",
    "cab": "726604",
    "cap": "5026"
  },
  {
    "key": "8980",
    "ITA": "MONTECASTRILLI-FARNETTA",
    "state_code": "TR",
    "cab": "726604",
    "cap": "5026"
  },
  {
    "key": "8981",
    "ITA": "MONTECASTRILLI-QUADRELLI",
    "state_code": "TR",
    "cab": "726604",
    "cap": "5026"
  },
  {
    "key": "8982",
    "ITA": "MONTECATINI TERME-MONTECATINI ALTO",
    "state_code": "PT",
    "cab": "704601",
    "cap": "51016"
  },
  {
    "key": "8983",
    "ITA": "MONTECATINI TERME",
    "state_code": "PT",
    "cab": "704601",
    "cap": "51016"
  },
  {
    "key": "8984",
    "ITA": "MONTECATINI TERME-NIEVOLE",
    "state_code": "PT",
    "cab": "704601",
    "cap": "51016"
  },
  {
    "key": "8985",
    "ITA": "MONTECATINI VAL DI CECINA",
    "state_code": "PI",
    "cab": "710509",
    "cap": "56040"
  },
  {
    "key": "8986",
    "ITA": "MONTECATINI VAL DI CECINA-CASINO DI TERRA",
    "state_code": "PI",
    "cab": "710509",
    "cap": "56040"
  },
  {
    "key": "8987",
    "ITA": "MONTECATINI VAL DI CECINA-CASTELLO DI QUERCETO",
    "state_code": "PI",
    "cab": "710509",
    "cap": "56040"
  },
  {
    "key": "8988",
    "ITA": "MONTECATINI VAL DI CECINA-PONTEGINORI",
    "state_code": "PI",
    "cab": "710509",
    "cap": "56040"
  },
  {
    "key": "8989",
    "ITA": "MONTECATINI VAL DI CECINA-SASSA",
    "state_code": "PI",
    "cab": "710509",
    "cap": "56040"
  },
  {
    "key": "8990",
    "ITA": "MONTECCHIA DI CROSARA",
    "state_code": "VR",
    "cab": "595702",
    "cap": "37030"
  },
  {
    "key": "8991",
    "ITA": "MONTECCHIO",
    "state_code": "TR",
    "cab": "726703",
    "cap": "5020"
  },
  {
    "key": "8992",
    "ITA": "MONTECCHIO-TENAGLIE",
    "state_code": "TR",
    "cab": "726703",
    "cap": "5020"
  },
  {
    "key": "8993",
    "ITA": "MONTECCHIO-MELEZZOLE",
    "state_code": "TR",
    "cab": "726703",
    "cap": "5020"
  },
  {
    "key": "8994",
    "ITA": "MONTECCHIO EMILIA",
    "state_code": "RE",
    "cab": "663996",
    "cap": "42027"
  },
  {
    "key": "8995",
    "ITA": "MONTECCHIO EMILIA",
    "state_code": "RE",
    "cab": "663906",
    "cap": "42027"
  },
  {
    "key": "8996",
    "ITA": "MONTECCHIO MAGGIORE",
    "state_code": "VI",
    "cab": "605303",
    "cap": "36075"
  },
  {
    "key": "8997",
    "ITA": "MONTECCHIO MAGGIORE-ALTE CECCATO",
    "state_code": "VI",
    "cab": "605303",
    "cap": "36075"
  },
  {
    "key": "8998",
    "ITA": "MONTECCHIO MAGGIORE-SANT'URBANO",
    "state_code": "VI",
    "cab": "605303",
    "cap": "36075"
  },
  {
    "key": "8999",
    "ITA": "MONTECCHIO PRECALCINO-PREARA",
    "state_code": "VI",
    "cab": "605402",
    "cap": "36030"
  },
  {
    "key": "9000",
    "ITA": "MONTECCHIO PRECALCINO",
    "state_code": "VI",
    "cab": "605402",
    "cap": "36030"
  },
  {
    "key": "9001",
    "ITA": "MONTECCHIO PRECALCINO-LEVA'",
    "state_code": "VI",
    "cab": "605402",
    "cap": "36030"
  },
  {
    "key": "9002",
    "ITA": "MONTECHIARO D'ACQUI-MONTECHIARO DENICE",
    "state_code": "AL",
    "cab": "674705",
    "cap": "15010"
  },
  {
    "key": "9003",
    "ITA": "MONTECHIARO D'ACQUI",
    "state_code": "AL",
    "cab": "674705",
    "cap": "15010"
  },
  {
    "key": "9004",
    "ITA": "MONTECHIARO D'ASTI",
    "state_code": "AT",
    "cab": "475509",
    "cap": "14025"
  },
  {
    "key": "9005",
    "ITA": "MONTECHIARUGOLO-MONTICELLI TERME",
    "state_code": "PR",
    "cab": "658203",
    "cap": "43022"
  },
  {
    "key": "9006",
    "ITA": "MONTECHIARUGOLO-BASILICANOVA",
    "state_code": "PR",
    "cab": "658203",
    "cap": "43030"
  },
  {
    "key": "9007",
    "ITA": "MONTECHIARUGOLO-BASILICAGOIANO",
    "state_code": "PR",
    "cab": "658203",
    "cap": "43022"
  },
  {
    "key": "9008",
    "ITA": "MONTECHIARUGOLO",
    "state_code": "PR",
    "cab": "658203",
    "cap": "43022"
  },
  {
    "key": "9009",
    "ITA": "MONTECICCARDO",
    "state_code": "PU",
    "cab": "278366",
    "cap": "61024"
  },
  {
    "key": "9010",
    "ITA": "MONTECILFONE",
    "state_code": "CB",
    "cab": "910711",
    "cap": "86032"
  },
  {
    "key": "9011",
    "ITA": "MONTECOMPATRI-MOLARA",
    "state_code": "RM",
    "cab": "392001",
    "cap": "40"
  },
  {
    "key": "9012",
    "ITA": "MONTECOMPATRI-LAGHETTO DI MONTECOMPATRI",
    "state_code": "RM",
    "cab": "392001",
    "cap": "40"
  },
  {
    "key": "9013",
    "ITA": "MONTECOPIOLO-VILLAGRANDE",
    "state_code": "PU",
    "cab": "684001",
    "cap": "61014"
  },
  {
    "key": "9014",
    "ITA": "MONTECOPIOLO",
    "state_code": "PU",
    "cab": "684001",
    "cap": "61014"
  },
  {
    "key": "9015",
    "ITA": "MONTECOPIOLO-MADONNA DI PUGLIANO",
    "state_code": "PU",
    "cab": "684001",
    "cap": "61014"
  },
  {
    "key": "9016",
    "ITA": "MONTECORICE",
    "state_code": "SA",
    "cab": "894006",
    "cap": "84060"
  },
  {
    "key": "9017",
    "ITA": "MONTECORICE-FORNELLI",
    "state_code": "SA",
    "cab": "894006",
    "cap": "84060"
  },
  {
    "key": "9018",
    "ITA": "MONTECORICE-ORTODONICO",
    "state_code": "SA",
    "cab": "894006",
    "cap": "84060"
  },
  {
    "key": "9019",
    "ITA": "MONTECORICE-AGNONE CILENTO",
    "state_code": "SA",
    "cab": "894006",
    "cap": "84060"
  },
  {
    "key": "9020",
    "ITA": "MONTECORICE-CASE DEL CONTE",
    "state_code": "SA",
    "cab": "894006",
    "cap": "84040"
  },
  {
    "key": "9021",
    "ITA": "MONTECORVINO PUGLIANO-SANTA TECLA",
    "state_code": "SA",
    "cab": "765800",
    "cap": "84090"
  },
  {
    "key": "9022",
    "ITA": "MONTECORVINO PUGLIANO-CASTELPAGANO",
    "state_code": "SA",
    "cab": "765800",
    "cap": "84090"
  },
  {
    "key": "9023",
    "ITA": "MONTECORVINO PUGLIANO-PUGLIANO",
    "state_code": "SA",
    "cab": "765800",
    "cap": "84090"
  },
  {
    "key": "9024",
    "ITA": "MONTECORVINO PUGLIANO",
    "state_code": "SA",
    "cab": "765800",
    "cap": "84090"
  },
  {
    "key": "9025",
    "ITA": "MONTECORVINO ROVELLA-LENZI",
    "state_code": "SA",
    "cab": "762609",
    "cap": "84096"
  },
  {
    "key": "9026",
    "ITA": "MONTECORVINO ROVELLA-MACCHIA",
    "state_code": "SA",
    "cab": "762609",
    "cap": "84096"
  },
  {
    "key": "9027",
    "ITA": "MONTECORVINO ROVELLA-GAURO",
    "state_code": "SA",
    "cab": "762609",
    "cap": "84096"
  },
  {
    "key": "9028",
    "ITA": "MONTECORVINO ROVELLA-SAN MARTINO MONTECORVINO ROVELLA",
    "state_code": "SA",
    "cab": "762609",
    "cap": "84096"
  },
  {
    "key": "9029",
    "ITA": "MONTECORVINO ROVELLA",
    "state_code": "SA",
    "cab": "762609",
    "cap": "84096"
  },
  {
    "key": "9030",
    "ITA": "MONTECOSARO",
    "state_code": "MC",
    "cab": "689802",
    "cap": "62010"
  },
  {
    "key": "9031",
    "ITA": "MONTECOSARO-MONTECOSARO STAZIONE",
    "state_code": "MC",
    "cab": "689802",
    "cap": "62010"
  },
  {
    "key": "9032",
    "ITA": "MONTECOSARO-BORGO STAZIONE",
    "state_code": "MC",
    "cab": "689802",
    "cap": "62010"
  },
  {
    "key": "9033",
    "ITA": "MONTECRESTESE-PONTETTO",
    "state_code": "VB",
    "cab": "917484",
    "cap": "28864"
  },
  {
    "key": "9034",
    "ITA": "MONTECRESTESE",
    "state_code": "VB",
    "cab": "917484",
    "cap": "28864"
  },
  {
    "key": "9035",
    "ITA": "MONTECRESTESE-ROLDO",
    "state_code": "VB",
    "cab": "917484",
    "cap": "28864"
  },
  {
    "key": "9036",
    "ITA": "MONTECRETO-ACQUARIA",
    "state_code": "MO",
    "cab": "668608",
    "cap": "41025"
  },
  {
    "key": "9037",
    "ITA": "MONTECRETO",
    "state_code": "MO",
    "cab": "668608",
    "cap": "41025"
  },
  {
    "key": "9038",
    "ITA": "MONTEDINOVE",
    "state_code": "AP",
    "cab": "942540",
    "cap": "63034"
  },
  {
    "key": "9039",
    "ITA": "MONTEDORO",
    "state_code": "CL",
    "cab": "833707",
    "cap": "93010"
  },
  {
    "key": "9040",
    "ITA": "MONTEFALCIONE",
    "state_code": "AV",
    "cab": "759605",
    "cap": "83030"
  },
  {
    "key": "9041",
    "ITA": "MONTEFALCO-MADONNA DELLA STELLA",
    "state_code": "PG",
    "cab": "385401",
    "cap": "6036"
  },
  {
    "key": "9042",
    "ITA": "MONTEFALCO-SAN MARCO DI MONTEFALCO",
    "state_code": "PG",
    "cab": "385401",
    "cap": "6036"
  },
  {
    "key": "9043",
    "ITA": "MONTEFALCO",
    "state_code": "PG",
    "cab": "385401",
    "cap": "6036"
  },
  {
    "key": "9044",
    "ITA": "MONTEFALCONE APPENNINO",
    "state_code": "FM",
    "cab": "198549",
    "cap": "63028"
  },
  {
    "key": "9045",
    "ITA": "MONTEFALCONE DI VAL FORTORE",
    "state_code": "BN",
    "cab": "950386",
    "cap": "82025"
  },
  {
    "key": "9046",
    "ITA": "MONTEFALCONE NEL SANNIO",
    "state_code": "CB",
    "cab": "411702",
    "cap": "86033"
  },
  {
    "key": "9047",
    "ITA": "MONTEFANO",
    "state_code": "MC",
    "cab": "689901",
    "cap": "62010"
  },
  {
    "key": "9048",
    "ITA": "MONTEFELCINO-MONTEGUIDUCCIO",
    "state_code": "PU",
    "cab": "684100",
    "cap": "61030"
  },
  {
    "key": "9049",
    "ITA": "MONTEFELCINO",
    "state_code": "PU",
    "cab": "684100",
    "cap": "61030"
  },
  {
    "key": "9050",
    "ITA": "MONTEFERRANTE",
    "state_code": "CH",
    "cab": "954784",
    "cap": "66040"
  },
  {
    "key": "9051",
    "ITA": "MONTEFIASCONE-LE MOSSE",
    "state_code": "VT",
    "cab": "731604",
    "cap": "1027"
  },
  {
    "key": "9052",
    "ITA": "MONTEFIASCONE",
    "state_code": "VT",
    "cab": "731604",
    "cap": "1027"
  },
  {
    "key": "9053",
    "ITA": "MONTEFIASCONE-ZEPPONAMI",
    "state_code": "VT",
    "cab": "731604",
    "cap": "1027"
  },
  {
    "key": "9054",
    "ITA": "MONTEFINO-VILLA BOZZA",
    "state_code": "TE",
    "cab": "885103",
    "cap": "64030"
  },
  {
    "key": "9055",
    "ITA": "MONTEFINO",
    "state_code": "TE",
    "cab": "885103",
    "cap": "64030"
  },
  {
    "key": "9056",
    "ITA": "MONTEFIORE CONCA-SERBADONE",
    "state_code": "RN",
    "cab": "679001",
    "cap": "47834"
  },
  {
    "key": "9057",
    "ITA": "MONTEFIORE CONCA",
    "state_code": "RN",
    "cab": "679001",
    "cap": "47834"
  },
  {
    "key": "9058",
    "ITA": "MONTEFIORE DELL'ASO",
    "state_code": "AP",
    "cab": "695205",
    "cap": "63010"
  },
  {
    "key": "9059",
    "ITA": "MONTEFIORINO-GUSCIOLA",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9060",
    "ITA": "MONTEFIORINO-LAGO",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9061",
    "ITA": "MONTEFIORINO",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9062",
    "ITA": "MONTEFIORINO-RUBBIANO",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9063",
    "ITA": "MONTEFIORINO-VITRIOLA",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9064",
    "ITA": "MONTEFIORINO-FARNETA",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9065",
    "ITA": "MONTEFIORINO-CASOLA",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9066",
    "ITA": "MONTEFIORINO-MACOGNANO",
    "state_code": "MO",
    "cab": "668707",
    "cap": "41045"
  },
  {
    "key": "9067",
    "ITA": "MONTEFLAVIO",
    "state_code": "RM",
    "cab": "908236",
    "cap": "10"
  },
  {
    "key": "9068",
    "ITA": "MONTEFORTE CILENTO",
    "state_code": "SA",
    "cab": "952689",
    "cap": "84060"
  },
  {
    "key": "9069",
    "ITA": "MONTEFORTE D'ALPONE-BROGNOLIGO",
    "state_code": "VR",
    "cab": "595801",
    "cap": "37032"
  },
  {
    "key": "9070",
    "ITA": "MONTEFORTE D'ALPONE-COSTALUNGA",
    "state_code": "VR",
    "cab": "595801",
    "cap": "37032"
  },
  {
    "key": "9071",
    "ITA": "MONTEFORTE D'ALPONE",
    "state_code": "VR",
    "cab": "595801",
    "cap": "37032"
  },
  {
    "key": "9072",
    "ITA": "MONTEFORTE IRPINO-MOLINELLE",
    "state_code": "AV",
    "cab": "895706",
    "cap": "83024"
  },
  {
    "key": "9073",
    "ITA": "MONTEFORTE IRPINO",
    "state_code": "AV",
    "cab": "895706",
    "cap": "83024"
  },
  {
    "key": "9074",
    "ITA": "MONTEFORTINO",
    "state_code": "FM",
    "cab": "695304",
    "cap": "63044"
  },
  {
    "key": "9075",
    "ITA": "MONTEFORTINO-SANTA LUCIA IN CONSILVANO",
    "state_code": "FM",
    "cab": "695304",
    "cap": "63044"
  },
  {
    "key": "9076",
    "ITA": "MONTEFRANCO",
    "state_code": "TR",
    "cab": "726802",
    "cap": "5030"
  },
  {
    "key": "9077",
    "ITA": "MONTEFRANCO-FONTECHIARUCCIA",
    "state_code": "TR",
    "cab": "726802",
    "cap": "5030"
  },
  {
    "key": "9078",
    "ITA": "MONTEFREDANE",
    "state_code": "AV",
    "cab": "951293",
    "cap": "83030"
  },
  {
    "key": "9079",
    "ITA": "MONTEFREDANE-ARCELLA",
    "state_code": "AV",
    "cab": "951293",
    "cap": "83030"
  },
  {
    "key": "9080",
    "ITA": "MONTEFUSCO",
    "state_code": "AV",
    "cab": "951301",
    "cap": "83030"
  },
  {
    "key": "9081",
    "ITA": "MONTEFUSCO-SERRA",
    "state_code": "AV",
    "cab": "951301",
    "cap": "83030"
  },
  {
    "key": "9082",
    "ITA": "MONTEGABBIONE",
    "state_code": "TR",
    "cab": "726901",
    "cap": "5010"
  },
  {
    "key": "9083",
    "ITA": "MONTEGALDA",
    "state_code": "VI",
    "cab": "605501",
    "cap": "36047"
  },
  {
    "key": "9084",
    "ITA": "MONTEGALDELLA",
    "state_code": "VI",
    "cab": "933960",
    "cap": "36047"
  },
  {
    "key": "9085",
    "ITA": "MONTEGALLO-BISIGNANO",
    "state_code": "AP",
    "cab": "697706",
    "cap": "63040"
  },
  {
    "key": "9086",
    "ITA": "MONTEGALLO",
    "state_code": "AP",
    "cab": "697706",
    "cap": "63040"
  },
  {
    "key": "9087",
    "ITA": "MONTEGIARDINO DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47898"
  },
  {
    "key": "9088",
    "ITA": "MONTEGIOCO",
    "state_code": "AL",
    "cab": "921387",
    "cap": "15050"
  },
  {
    "key": "9089",
    "ITA": "MONTEGIORDANO",
    "state_code": "CS",
    "cab": "959056",
    "cap": "87070"
  },
  {
    "key": "9090",
    "ITA": "MONTEGIORDANO-MONTEGIORDANO MARINA",
    "state_code": "CS",
    "cab": "959056",
    "cap": "87070"
  },
  {
    "key": "9091",
    "ITA": "MONTEGIORGIO",
    "state_code": "FM",
    "cab": "695403",
    "cap": "63025"
  },
  {
    "key": "9092",
    "ITA": "MONTEGIORGIO-PIANE DI MONTEGIORGIO",
    "state_code": "FM",
    "cab": "695403",
    "cap": "63025"
  },
  {
    "key": "9093",
    "ITA": "MONTEGIORGIO-ALTETA",
    "state_code": "FM",
    "cab": "695403",
    "cap": "63025"
  },
  {
    "key": "9094",
    "ITA": "MONTEGRANARO",
    "state_code": "FM",
    "cab": "695502",
    "cap": "63014"
  },
  {
    "key": "9095",
    "ITA": "MONTEGRIDOLFO",
    "state_code": "RN",
    "cab": "941013",
    "cap": "47837"
  },
  {
    "key": "9096",
    "ITA": "MONTEGRIMANO-MONTELICCIANO",
    "state_code": "PU",
    "cab": "684209",
    "cap": "61010"
  },
  {
    "key": "9097",
    "ITA": "MONTEGRIMANO-SAVIGNANO MONTETASSI",
    "state_code": "PU",
    "cab": "684209",
    "cap": "61010"
  },
  {
    "key": "9098",
    "ITA": "MONTEGRINO VALTRAVAGLIA",
    "state_code": "VA",
    "cab": "924647",
    "cap": "21010"
  },
  {
    "key": "9099",
    "ITA": "MONTEGROSSO D'ASTI-MONTEGROSSO D'ASTI STAZIONE",
    "state_code": "AT",
    "cab": "475608",
    "cap": "14048"
  },
  {
    "key": "9100",
    "ITA": "MONTEGROSSO D'ASTI",
    "state_code": "AT",
    "cab": "475608",
    "cap": "14048"
  },
  {
    "key": "9101",
    "ITA": "MONTEGROSSO PIAN LATTE",
    "state_code": "IM",
    "cab": "922492",
    "cap": "18025"
  },
  {
    "key": "9102",
    "ITA": "MONTEGROTTO TERME",
    "state_code": "PD",
    "cab": "626804",
    "cap": "35036"
  },
  {
    "key": "9103",
    "ITA": "MONTEIASI",
    "state_code": "TA",
    "cab": "789602",
    "cap": "74020"
  },
  {
    "key": "9104",
    "ITA": "MONTELABBATE",
    "state_code": "PU",
    "cab": "684308",
    "cap": "61025"
  },
  {
    "key": "9105",
    "ITA": "MONTELABBATE-OSTERIA NUOVA",
    "state_code": "PU",
    "cab": "684308",
    "cap": "61025"
  },
  {
    "key": "9106",
    "ITA": "MONTELANICO",
    "state_code": "RM",
    "cab": "392100",
    "cap": "30"
  },
  {
    "key": "9107",
    "ITA": "MONTELAPIANO",
    "state_code": "CH",
    "cab": "954792",
    "cap": "66040"
  },
  {
    "key": "9108",
    "ITA": "MONTELEONE D'ORVIETO",
    "state_code": "TR",
    "cab": "727008",
    "cap": "5017"
  },
  {
    "key": "9109",
    "ITA": "MONTELEONE D'ORVIETO-SANTA MARIA",
    "state_code": "TR",
    "cab": "727008",
    "cap": "5017"
  },
  {
    "key": "9110",
    "ITA": "MONTELEONE DI FERMO",
    "state_code": "FM",
    "cab": "198606",
    "cap": "63029"
  },
  {
    "key": "9111",
    "ITA": "MONTELEONE DI PUGLIA",
    "state_code": "FG",
    "cab": "784801",
    "cap": "71020"
  },
  {
    "key": "9112",
    "ITA": "MONTELEONE DI SPOLETO",
    "state_code": "PG",
    "cab": "385500",
    "cap": "6045"
  },
  {
    "key": "9113",
    "ITA": "MONTELEONE ROCCA DORIA",
    "state_code": "SS",
    "cab": "874404",
    "cap": "7010"
  },
  {
    "key": "9114",
    "ITA": "MONTELEONE SABINO",
    "state_code": "RI",
    "cab": "947275",
    "cap": "2033"
  },
  {
    "key": "9115",
    "ITA": "MONTELEONE SABINO-GINESTRA SABINA",
    "state_code": "RI",
    "cab": "947275",
    "cap": "2033"
  },
  {
    "key": "9116",
    "ITA": "MONTELEPRE",
    "state_code": "PA",
    "cab": "434605",
    "cap": "90040"
  },
  {
    "key": "9117",
    "ITA": "MONTELEPRE-CORTIGLIA",
    "state_code": "PA",
    "cab": "434605",
    "cap": "90040"
  },
  {
    "key": "9118",
    "ITA": "MONTELIBRETTI",
    "state_code": "RM",
    "cab": "392209",
    "cap": "10"
  },
  {
    "key": "9119",
    "ITA": "MONTELIBRETTI-BORGO SANTA MARIA",
    "state_code": "RM",
    "cab": "392209",
    "cap": "10"
  },
  {
    "key": "9120",
    "ITA": "MONTELLA-SORBO DI MONTELLA",
    "state_code": "AV",
    "cab": "757807",
    "cap": "83048"
  },
  {
    "key": "9121",
    "ITA": "MONTELLA",
    "state_code": "AV",
    "cab": "757807",
    "cap": "83048"
  },
  {
    "key": "9122",
    "ITA": "MONTELLA-FONTANA DI MONTELLA",
    "state_code": "AV",
    "cab": "757807",
    "cap": "83048"
  },
  {
    "key": "9123",
    "ITA": "MONTELLO",
    "state_code": "BG",
    "cab": "539502",
    "cap": "24060"
  },
  {
    "key": "9124",
    "ITA": "MONTELONGO",
    "state_code": "CB",
    "cab": "266593",
    "cap": "86040"
  },
  {
    "key": "9125",
    "ITA": "MONTELPARO",
    "state_code": "FM",
    "cab": "198630",
    "cap": "63020"
  },
  {
    "key": "9126",
    "ITA": "MONTELUPO ALBESE",
    "state_code": "CN",
    "cab": "918961",
    "cap": "12050"
  },
  {
    "key": "9127",
    "ITA": "MONTELUPO FIORENTINO-FIBBIANA",
    "state_code": "FI",
    "cab": "379602",
    "cap": "50056"
  },
  {
    "key": "9128",
    "ITA": "MONTELUPO FIORENTINO-AMBROGIANA",
    "state_code": "FI",
    "cab": "379602",
    "cap": "50056"
  },
  {
    "key": "9129",
    "ITA": "MONTELUPO FIORENTINO",
    "state_code": "FI",
    "cab": "379602",
    "cap": "50056"
  },
  {
    "key": "9130",
    "ITA": "MONTELUPO FIORENTINO-SAMMINIATELLO",
    "state_code": "FI",
    "cab": "379602",
    "cap": "50056"
  },
  {
    "key": "9131",
    "ITA": "MONTELUPO FIORENTINO-SAMMONTANA",
    "state_code": "FI",
    "cab": "379602",
    "cap": "50056"
  },
  {
    "key": "9132",
    "ITA": "MONTELUPONE",
    "state_code": "MC",
    "cab": "690008",
    "cap": "62010"
  },
  {
    "key": "9133",
    "ITA": "MONTEMAGGIORE AL METAURO-VILLANOVA",
    "state_code": "PU",
    "cab": "684407",
    "cap": "61030"
  },
  {
    "key": "9134",
    "ITA": "MONTEMAGGIORE AL METAURO",
    "state_code": "PU",
    "cab": "684407",
    "cap": "61030"
  },
  {
    "key": "9135",
    "ITA": "MONTEMAGGIORE BELSITO",
    "state_code": "PA",
    "cab": "434704",
    "cap": "90020"
  },
  {
    "key": "9136",
    "ITA": "MONTEMAGNO",
    "state_code": "AT",
    "cab": "475707",
    "cap": "14030"
  },
  {
    "key": "9137",
    "ITA": "MONTEMALE DI CUNEO",
    "state_code": "CN",
    "cab": "918979",
    "cap": "12025"
  },
  {
    "key": "9138",
    "ITA": "MONTEMARANO",
    "state_code": "AV",
    "cab": "728105",
    "cap": "83040"
  },
  {
    "key": "9139",
    "ITA": "MONTEMARCIANO",
    "state_code": "AN",
    "cab": "374207",
    "cap": "60018"
  },
  {
    "key": "9140",
    "ITA": "MONTEMARCIANO-LUNGOMARE",
    "state_code": "AN",
    "cab": "374207",
    "cap": "60018"
  },
  {
    "key": "9141",
    "ITA": "MONTEMARCIANO-MARINA DI MONTEMARCIANO",
    "state_code": "AN",
    "cab": "374207",
    "cap": "60018"
  },
  {
    "key": "9142",
    "ITA": "MONTEMARZINO",
    "state_code": "AL",
    "cab": "921395",
    "cap": "15050"
  },
  {
    "key": "9143",
    "ITA": "MONTEMESOLA",
    "state_code": "TA",
    "cab": "789701",
    "cap": "74020"
  },
  {
    "key": "9144",
    "ITA": "MONTEMEZZO",
    "state_code": "CO",
    "cab": "925842",
    "cap": "22010"
  },
  {
    "key": "9145",
    "ITA": "MONTEMIGNAIO",
    "state_code": "AR",
    "cab": "715102",
    "cap": "52010"
  },
  {
    "key": "9146",
    "ITA": "MONTEMILETTO",
    "state_code": "AV",
    "cab": "759308",
    "cap": "83038"
  },
  {
    "key": "9147",
    "ITA": "MONTEMILETTO-MONTAPERTO",
    "state_code": "AV",
    "cab": "759308",
    "cap": "83038"
  },
  {
    "key": "9148",
    "ITA": "MONTEMILONE",
    "state_code": "PZ",
    "cab": "421206",
    "cap": "85020"
  },
  {
    "key": "9149",
    "ITA": "MONTEMITRO",
    "state_code": "CB",
    "cab": "910745",
    "cap": "86030"
  },
  {
    "key": "9150",
    "ITA": "MONTEMONACO",
    "state_code": "AP",
    "cab": "695601",
    "cap": "63048"
  },
  {
    "key": "9151",
    "ITA": "MONTEMURLO-OSTE",
    "state_code": "PO",
    "cab": "379701",
    "cap": "59013"
  },
  {
    "key": "9152",
    "ITA": "MONTEMURLO-FORNACELLE",
    "state_code": "PO",
    "cab": "379701",
    "cap": "59013"
  },
  {
    "key": "9153",
    "ITA": "MONTEMURLO",
    "state_code": "PO",
    "cab": "379701",
    "cap": "59013"
  },
  {
    "key": "9154",
    "ITA": "MONTEMURRO",
    "state_code": "PZ",
    "cab": "912022",
    "cap": "85053"
  },
  {
    "key": "9155",
    "ITA": "MONTENARS",
    "state_code": "UD",
    "cab": "937029",
    "cap": "33010"
  },
  {
    "key": "9156",
    "ITA": "MONTENERO DI BISACCIA",
    "state_code": "CB",
    "cab": "410605",
    "cap": "86036"
  },
  {
    "key": "9157",
    "ITA": "MONTENERO SABINO",
    "state_code": "RI",
    "cab": "947283",
    "cap": "2040"
  },
  {
    "key": "9158",
    "ITA": "MONTENERO VAL COCCHIARA",
    "state_code": "IS",
    "cab": "955716",
    "cap": "86080"
  },
  {
    "key": "9159",
    "ITA": "MONTENERODOMO",
    "state_code": "CH",
    "cab": "954800",
    "cap": "66010"
  },
  {
    "key": "9160",
    "ITA": "MONTEODORISIO",
    "state_code": "CH",
    "cab": "491704",
    "cap": "66050"
  },
  {
    "key": "9161",
    "ITA": "MONTEPAONE",
    "state_code": "CZ",
    "cab": "429308",
    "cap": "88060"
  },
  {
    "key": "9162",
    "ITA": "MONTEPAONE-MONTEPAONE LIDO",
    "state_code": "CZ",
    "cab": "429308",
    "cap": "88060"
  },
  {
    "key": "9163",
    "ITA": "MONTEPARANO",
    "state_code": "TA",
    "cab": "508002",
    "cap": "74020"
  },
  {
    "key": "9164",
    "ITA": "MONTEPRANDONE",
    "state_code": "AP",
    "cab": "695700",
    "cap": "63033"
  },
  {
    "key": "9165",
    "ITA": "MONTEPRANDONE-CENTOBUCHI",
    "state_code": "AP",
    "cab": "695700",
    "cap": "63033"
  },
  {
    "key": "9166",
    "ITA": "MONTEPULCIANO-ACQUAVIVA",
    "state_code": "SI",
    "cab": "256008",
    "cap": "53045"
  },
  {
    "key": "9167",
    "ITA": "MONTEPULCIANO-ABBADIA",
    "state_code": "SI",
    "cab": "256008",
    "cap": "53045"
  },
  {
    "key": "9168",
    "ITA": "MONTEPULCIANO",
    "state_code": "SI",
    "cab": "256008",
    "cap": "53045"
  },
  {
    "key": "9169",
    "ITA": "MONTEPULCIANO-MONTEPULCIANO STAZIONE",
    "state_code": "SI",
    "cab": "256008",
    "cap": "53045"
  },
  {
    "key": "9170",
    "ITA": "MONTEPULCIANO-GRACCIANO",
    "state_code": "SI",
    "cab": "256008",
    "cap": "53045"
  },
  {
    "key": "9171",
    "ITA": "MONTEPULCIANO-VALIANO",
    "state_code": "SI",
    "cab": "256008",
    "cap": "53045"
  },
  {
    "key": "9172",
    "ITA": "MONTEPULCIANO-SANT'ALBINO",
    "state_code": "SI",
    "cab": "256008",
    "cap": "53045"
  },
  {
    "key": "9173",
    "ITA": "MONTERADO-PONTE RIO",
    "state_code": "AN",
    "cab": "374306",
    "cap": "60010"
  },
  {
    "key": "9174",
    "ITA": "MONTERADO",
    "state_code": "AN",
    "cab": "374306",
    "cap": "60010"
  },
  {
    "key": "9175",
    "ITA": "MONTERCHI",
    "state_code": "AR",
    "cab": "715201",
    "cap": "52035"
  },
  {
    "key": "9176",
    "ITA": "MONTERCHI-LE VILLE",
    "state_code": "AR",
    "cab": "715201",
    "cap": "52035"
  },
  {
    "key": "9177",
    "ITA": "MONTEREALE",
    "state_code": "AQ",
    "cab": "406207",
    "cap": "67015"
  },
  {
    "key": "9178",
    "ITA": "MONTEREALE-VILLE DI FANO",
    "state_code": "AQ",
    "cab": "406207",
    "cap": "67015"
  },
  {
    "key": "9179",
    "ITA": "MONTEREALE-SAN GIOVANNI PAGANICA",
    "state_code": "AQ",
    "cab": "406207",
    "cap": "67015"
  },
  {
    "key": "9180",
    "ITA": "MONTEREALE-ARINGO",
    "state_code": "AQ",
    "cab": "406207",
    "cap": "67015"
  },
  {
    "key": "9181",
    "ITA": "MONTEREALE-CESAPROBA",
    "state_code": "AQ",
    "cab": "406207",
    "cap": "67015"
  },
  {
    "key": "9182",
    "ITA": "MONTEREALE-MARANA",
    "state_code": "AQ",
    "cab": "406207",
    "cap": "67015"
  },
  {
    "key": "9183",
    "ITA": "MONTEREALE VALCELLINA",
    "state_code": "PN",
    "cab": "649103",
    "cap": "33086"
  },
  {
    "key": "9184",
    "ITA": "MONTEREALE VALCELLINA-MALNISIO",
    "state_code": "PN",
    "cab": "649103",
    "cap": "33086"
  },
  {
    "key": "9185",
    "ITA": "MONTEREALE VALCELLINA-SAN LEONARDO",
    "state_code": "PN",
    "cab": "649103",
    "cap": "33086"
  },
  {
    "key": "9186",
    "ITA": "MONTERENZIO",
    "state_code": "BO",
    "cab": "369405",
    "cap": "40050"
  },
  {
    "key": "9187",
    "ITA": "MONTERENZIO-BISANO",
    "state_code": "BO",
    "cab": "369405",
    "cap": "40050"
  },
  {
    "key": "9188",
    "ITA": "MONTERENZIO-PIZZANO",
    "state_code": "BO",
    "cab": "369405",
    "cap": "40050"
  },
  {
    "key": "9189",
    "ITA": "MONTERENZIO-RIGNANO BOLOGNESE",
    "state_code": "BO",
    "cab": "369405",
    "cap": "40050"
  },
  {
    "key": "9190",
    "ITA": "MONTERENZIO-SAN BENEDETTO DEL QUERCETO",
    "state_code": "BO",
    "cab": "369405",
    "cap": "40050"
  },
  {
    "key": "9191",
    "ITA": "MONTERENZIO-SAVAZZA",
    "state_code": "BO",
    "cab": "369405",
    "cap": "40050"
  },
  {
    "key": "9192",
    "ITA": "MONTERIGGIONI-CASTELLINA SCALO",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9193",
    "ITA": "MONTERIGGIONI-BELVERDE",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9194",
    "ITA": "MONTERIGGIONI-BADESSE",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9195",
    "ITA": "MONTERIGGIONI",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9196",
    "ITA": "MONTERIGGIONI-STROVE",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9197",
    "ITA": "MONTERIGGIONI-TOGNAZZA",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9198",
    "ITA": "MONTERIGGIONI-SAN MARTINO",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9199",
    "ITA": "MONTERIGGIONI-UOPINI",
    "state_code": "SI",
    "cab": "718809",
    "cap": "53035"
  },
  {
    "key": "9200",
    "ITA": "MONTERODUNI-SANT'EUSANIO",
    "state_code": "IS",
    "cab": "781609",
    "cap": "86075"
  },
  {
    "key": "9201",
    "ITA": "MONTERODUNI",
    "state_code": "IS",
    "cab": "781609",
    "cap": "86075"
  },
  {
    "key": "9202",
    "ITA": "MONTERONI D'ARBIA",
    "state_code": "SI",
    "cab": "718908",
    "cap": "53014"
  },
  {
    "key": "9203",
    "ITA": "MONTERONI D'ARBIA-PONTE A TRESSA",
    "state_code": "SI",
    "cab": "718908",
    "cap": "53014"
  },
  {
    "key": "9204",
    "ITA": "MONTERONI D'ARBIA-LUCIGNANO D'ARBIA",
    "state_code": "SI",
    "cab": "718908",
    "cap": "53014"
  },
  {
    "key": "9205",
    "ITA": "MONTERONI D'ARBIA-VILLE DI CORSANO",
    "state_code": "SI",
    "cab": "718908",
    "cap": "53014"
  },
  {
    "key": "9206",
    "ITA": "MONTERONI D'ARBIA-PONTE D'ARBIA",
    "state_code": "SI",
    "cab": "718908",
    "cap": "53014"
  },
  {
    "key": "9207",
    "ITA": "MONTERONI DI LECCE",
    "state_code": "LE",
    "cab": "797902",
    "cap": "73047"
  },
  {
    "key": "9208",
    "ITA": "MONTEROSI",
    "state_code": "VT",
    "cab": "731802",
    "cap": "1030"
  },
  {
    "key": "9209",
    "ITA": "MONTEROSSO AL MARE",
    "state_code": "SP",
    "cab": "497909",
    "cap": "19016"
  },
  {
    "key": "9210",
    "ITA": "MONTEROSSO ALMO",
    "state_code": "RG",
    "cab": "844902",
    "cap": "97010"
  },
  {
    "key": "9211",
    "ITA": "MONTEROSSO CALABRO",
    "state_code": "VV",
    "cab": "418103",
    "cap": "89819"
  },
  {
    "key": "9212",
    "ITA": "MONTEROSSO GRANA-VILLA SAN PIETRO",
    "state_code": "CN",
    "cab": "793208",
    "cap": "12020"
  },
  {
    "key": "9213",
    "ITA": "MONTEROSSO GRANA",
    "state_code": "CN",
    "cab": "793208",
    "cap": "12020"
  },
  {
    "key": "9214",
    "ITA": "MONTEROTONDO",
    "state_code": "RM",
    "cab": "392407",
    "cap": "15"
  },
  {
    "key": "9215",
    "ITA": "MONTEROTONDO-MONTEROTONDO STAZIONE",
    "state_code": "RM",
    "cab": "392407",
    "cap": "15"
  },
  {
    "key": "9216",
    "ITA": "MONTEROTONDO MARITTIMO-FRASSINE",
    "state_code": "GR",
    "cab": "724104",
    "cap": "58025"
  },
  {
    "key": "9217",
    "ITA": "MONTEROTONDO MARITTIMO",
    "state_code": "GR",
    "cab": "724104",
    "cap": "58025"
  },
  {
    "key": "9218",
    "ITA": "MONTEROTONDO MARITTIMO-LAGO BORACIFERO",
    "state_code": "GR",
    "cab": "724104",
    "cap": "58025"
  },
  {
    "key": "9219",
    "ITA": "MONTERUBBIANO",
    "state_code": "FM",
    "cab": "695809",
    "cap": "63026"
  },
  {
    "key": "9220",
    "ITA": "MONTERUBBIANO-RUBBIANELLO",
    "state_code": "FM",
    "cab": "695809",
    "cap": "63026"
  },
  {
    "key": "9221",
    "ITA": "MONTESANO SALENTINO",
    "state_code": "LE",
    "cab": "775304",
    "cap": "73030"
  },
  {
    "key": "9222",
    "ITA": "MONTESANO SULLA MARCELLANA-PRATO COMUNE",
    "state_code": "SA",
    "cab": "765909",
    "cap": "84030"
  },
  {
    "key": "9223",
    "ITA": "MONTESANO SULLA MARCELLANA-MONTESANO SCALO",
    "state_code": "SA",
    "cab": "765909",
    "cap": "84030"
  },
  {
    "key": "9224",
    "ITA": "MONTESANO SULLA MARCELLANA-ARENABIANCA",
    "state_code": "SA",
    "cab": "765909",
    "cap": "84030"
  },
  {
    "key": "9225",
    "ITA": "MONTESANO SULLA MARCELLANA",
    "state_code": "SA",
    "cab": "765909",
    "cap": "84033"
  },
  {
    "key": "9226",
    "ITA": "MONTESANO SULLA MARCELLANA-TARDIANO",
    "state_code": "SA",
    "cab": "765909",
    "cap": "84030"
  },
  {
    "key": "9227",
    "ITA": "MONTESARCHIO",
    "state_code": "BN",
    "cab": "753707",
    "cap": "82016"
  },
  {
    "key": "9228",
    "ITA": "MONTESARCHIO-CIRIGNANO",
    "state_code": "BN",
    "cab": "753707",
    "cap": "82016"
  },
  {
    "key": "9229",
    "ITA": "MONTESARCHIO-VARONI",
    "state_code": "BN",
    "cab": "753707",
    "cap": "82016"
  },
  {
    "key": "9230",
    "ITA": "MONTESCAGLIOSO",
    "state_code": "MT",
    "cab": "803700",
    "cap": "75024"
  },
  {
    "key": "9231",
    "ITA": "MONTESCANO",
    "state_code": "PV",
    "cab": "930628",
    "cap": "27040"
  },
  {
    "key": "9232",
    "ITA": "MONTESCHENO",
    "state_code": "VB",
    "cab": "917492",
    "cap": "28843"
  },
  {
    "key": "9233",
    "ITA": "MONTESCUDAIO",
    "state_code": "PI",
    "cab": "710608",
    "cap": "56040"
  },
  {
    "key": "9234",
    "ITA": "MONTESCUDO",
    "state_code": "RN",
    "cab": "679100",
    "cap": "47854"
  },
  {
    "key": "9235",
    "ITA": "MONTESCUDO-SANTA MARIA DEL PIANO",
    "state_code": "RN",
    "cab": "679100",
    "cap": "47854"
  },
  {
    "key": "9236",
    "ITA": "MONTESCUDO-TRARIVI",
    "state_code": "RN",
    "cab": "679100",
    "cap": "47854"
  },
  {
    "key": "9237",
    "ITA": "MONTESE-IOLA",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9238",
    "ITA": "MONTESE-CASTELLUCCIO",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9239",
    "ITA": "MONTESE-BERTOCCHI",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9240",
    "ITA": "MONTESE",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9241",
    "ITA": "MONTESE-SEMELANO",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41059"
  },
  {
    "key": "9242",
    "ITA": "MONTESE-SAN MARTINO",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9243",
    "ITA": "MONTESE-SAN GIACOMO MAGGIORE",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9244",
    "ITA": "MONTESE-SALTO",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9245",
    "ITA": "MONTESE-MONTESPECCHIO",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9246",
    "ITA": "MONTESE-MASERNO",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41055"
  },
  {
    "key": "9247",
    "ITA": "MONTESE-MONTALTO",
    "state_code": "MO",
    "cab": "668806",
    "cap": "41059"
  },
  {
    "key": "9248",
    "ITA": "MONTESEGALE",
    "state_code": "PV",
    "cab": "930636",
    "cap": "27052"
  },
  {
    "key": "9249",
    "ITA": "MONTESILVANO-MONTESILVANO MARINA",
    "state_code": "PE",
    "cab": "773408",
    "cap": "65015"
  },
  {
    "key": "9250",
    "ITA": "MONTESILVANO-VILLA CARMINE",
    "state_code": "PE",
    "cab": "773408",
    "cap": "65015"
  },
  {
    "key": "9251",
    "ITA": "MONTESILVANO",
    "state_code": "PE",
    "cab": "773408",
    "cap": "65015"
  },
  {
    "key": "9252",
    "ITA": "MONTESILVANO-MONTESILVANO COLLE",
    "state_code": "PE",
    "cab": "773408",
    "cap": "65015"
  },
  {
    "key": "9253",
    "ITA": "MONTESPERTOLI",
    "state_code": "FI",
    "cab": "379800",
    "cap": "50025"
  },
  {
    "key": "9254",
    "ITA": "MONTESPERTOLI-BACCAIANO",
    "state_code": "FI",
    "cab": "379800",
    "cap": "50025"
  },
  {
    "key": "9255",
    "ITA": "MONTESPERTOLI-LUCARDO",
    "state_code": "FI",
    "cab": "379800",
    "cap": "50025"
  },
  {
    "key": "9256",
    "ITA": "MONTESPERTOLI-MARTIGNANA",
    "state_code": "FI",
    "cab": "379800",
    "cap": "50025"
  },
  {
    "key": "9257",
    "ITA": "MONTESPERTOLI-MONTAGNANA VAL DI PESA",
    "state_code": "FI",
    "cab": "379800",
    "cap": "50025"
  },
  {
    "key": "9258",
    "ITA": "MONTESPERTOLI-SAN QUIRICO IN COLLINA",
    "state_code": "FI",
    "cab": "379800",
    "cap": "50025"
  },
  {
    "key": "9259",
    "ITA": "MONTEU DA PO",
    "state_code": "TO",
    "cab": "283937",
    "cap": "10020"
  },
  {
    "key": "9260",
    "ITA": "MONTEU ROERO",
    "state_code": "CN",
    "cab": "918995",
    "cap": "12040"
  },
  {
    "key": "9261",
    "ITA": "MONTEVAGO",
    "state_code": "AG",
    "cab": "830109",
    "cap": "92010"
  },
  {
    "key": "9262",
    "ITA": "MONTEVARCHI-MONCIONI",
    "state_code": "AR",
    "cab": "715409",
    "cap": "52025"
  },
  {
    "key": "9263",
    "ITA": "MONTEVARCHI-LEVANE",
    "state_code": "AR",
    "cab": "715409",
    "cap": "52025"
  },
  {
    "key": "9264",
    "ITA": "MONTEVARCHI-MERCATALE VALDARNO",
    "state_code": "AR",
    "cab": "715409",
    "cap": "52020"
  },
  {
    "key": "9265",
    "ITA": "MONTEVARCHI",
    "state_code": "AR",
    "cab": "715409",
    "cap": "52025"
  },
  {
    "key": "9266",
    "ITA": "MONTEVECCHIA",
    "state_code": "LC",
    "cab": "499202",
    "cap": "23874"
  },
  {
    "key": "9267",
    "ITA": "MONTEVECCHIA-QUATTRO STRADE",
    "state_code": "LC",
    "cab": "499202",
    "cap": "23874"
  },
  {
    "key": "9268",
    "ITA": "MONTEVEGLIO",
    "state_code": "BO",
    "cab": "369603",
    "cap": "40050"
  },
  {
    "key": "9269",
    "ITA": "MONTEVERDE",
    "state_code": "AV",
    "cab": "758508",
    "cap": "83049"
  },
  {
    "key": "9270",
    "ITA": "MONTEVERDI MARITTIMO",
    "state_code": "PI",
    "cab": "710707",
    "cap": "56040"
  },
  {
    "key": "9271",
    "ITA": "MONTEVERDI MARITTIMO-CANNETO",
    "state_code": "PI",
    "cab": "710707",
    "cap": "56040"
  },
  {
    "key": "9272",
    "ITA": "MONTEVIALE",
    "state_code": "VI",
    "cab": "635805",
    "cap": "36050"
  },
  {
    "key": "9273",
    "ITA": "MONTEZEMOLO",
    "state_code": "CN",
    "cab": "919001",
    "cap": "12070"
  },
  {
    "key": "9274",
    "ITA": "MONTI-SU CANALE",
    "state_code": "OT",
    "cab": "874503",
    "cap": "7020"
  },
  {
    "key": "9275",
    "ITA": "MONTI",
    "state_code": "OT",
    "cab": "874503",
    "cap": "7020"
  },
  {
    "key": "9276",
    "ITA": "MONTIANO",
    "state_code": "FC",
    "cab": "679209",
    "cap": "47020"
  },
  {
    "key": "9277",
    "ITA": "MONTICELLI BRUSATI-PARMEZZANA CALZANA",
    "state_code": "BS",
    "cab": "554907",
    "cap": "25040"
  },
  {
    "key": "9278",
    "ITA": "MONTICELLI BRUSATI",
    "state_code": "BS",
    "cab": "554907",
    "cap": "25040"
  },
  {
    "key": "9279",
    "ITA": "MONTICELLI D'ONGINA",
    "state_code": "PC",
    "cab": "653600",
    "cap": "29010"
  },
  {
    "key": "9280",
    "ITA": "MONTICELLI D'ONGINA-FOGAROLE",
    "state_code": "PC",
    "cab": "653600",
    "cap": "29010"
  },
  {
    "key": "9281",
    "ITA": "MONTICELLI D'ONGINA-OLZA",
    "state_code": "PC",
    "cab": "653600",
    "cap": "29010"
  },
  {
    "key": "9282",
    "ITA": "MONTICELLI D'ONGINA-SAN NAZZARO D'ONGINA",
    "state_code": "PC",
    "cab": "653600",
    "cap": "29010"
  },
  {
    "key": "9283",
    "ITA": "MONTICELLI PAVESE",
    "state_code": "PV",
    "cab": "560508",
    "cap": "27010"
  },
  {
    "key": "9284",
    "ITA": "MONTICELLO BRIANZA",
    "state_code": "LC",
    "cab": "518704",
    "cap": "23876"
  },
  {
    "key": "9285",
    "ITA": "MONTICELLO CONTE OTTO-CAVAZZALE",
    "state_code": "VI",
    "cab": "605600",
    "cap": "36010"
  },
  {
    "key": "9286",
    "ITA": "MONTICELLO CONTE OTTO-VIGARDOLO",
    "state_code": "VI",
    "cab": "605600",
    "cap": "36010"
  },
  {
    "key": "9287",
    "ITA": "MONTICELLO CONTE OTTO",
    "state_code": "VI",
    "cab": "605600",
    "cap": "36010"
  },
  {
    "key": "9288",
    "ITA": "MONTICELLO D'ALBA-BORGO",
    "state_code": "CN",
    "cab": "465203",
    "cap": "12066"
  },
  {
    "key": "9289",
    "ITA": "MONTICELLO D'ALBA",
    "state_code": "CN",
    "cab": "465203",
    "cap": "12066"
  },
  {
    "key": "9290",
    "ITA": "MONTICHIARI",
    "state_code": "BS",
    "cab": "547802",
    "cap": "25018"
  },
  {
    "key": "9291",
    "ITA": "MONTICHIARI-NOVAGLI",
    "state_code": "BS",
    "cab": "547802",
    "cap": "25018"
  },
  {
    "key": "9292",
    "ITA": "MONTICHIARI-SANT'ANTONIO",
    "state_code": "BS",
    "cab": "547802",
    "cap": "25018"
  },
  {
    "key": "9293",
    "ITA": "MONTICHIARI-VIGHIZZOLO",
    "state_code": "BS",
    "cab": "547802",
    "cap": "25018"
  },
  {
    "key": "9294",
    "ITA": "MONTICIANO-SCALVAIA",
    "state_code": "SI",
    "cab": "719005",
    "cap": "53015"
  },
  {
    "key": "9295",
    "ITA": "MONTICIANO-TOCCHI",
    "state_code": "SI",
    "cab": "719005",
    "cap": "53015"
  },
  {
    "key": "9296",
    "ITA": "MONTICIANO-SAN LORENZO A MERSE",
    "state_code": "SI",
    "cab": "719005",
    "cap": "53015"
  },
  {
    "key": "9297",
    "ITA": "MONTICIANO",
    "state_code": "SI",
    "cab": "719005",
    "cap": "53015"
  },
  {
    "key": "9298",
    "ITA": "MONTICIANO-IESA",
    "state_code": "SI",
    "cab": "719005",
    "cap": "53015"
  },
  {
    "key": "9299",
    "ITA": "MONTIERI",
    "state_code": "GR",
    "cab": "723106",
    "cap": "58026"
  },
  {
    "key": "9300",
    "ITA": "MONTIERI-BOCCHEGGIANO",
    "state_code": "GR",
    "cab": "723106",
    "cap": "58026"
  },
  {
    "key": "9301",
    "ITA": "MONTIERI-GERFALCO",
    "state_code": "GR",
    "cab": "723106",
    "cap": "58026"
  },
  {
    "key": "9302",
    "ITA": "MONTIERI-TRAVALE",
    "state_code": "GR",
    "cab": "723106",
    "cap": "58026"
  },
  {
    "key": "9303",
    "ITA": "MONTIGLIO MONFERRATO",
    "state_code": "AT",
    "cab": "475806",
    "cap": "14026"
  },
  {
    "key": "9304",
    "ITA": "MONTIGLIO MONFERRATO-COLCAVAGNO",
    "state_code": "AT",
    "cab": "475806",
    "cap": "14026"
  },
  {
    "key": "9305",
    "ITA": "MONTIGLIO MONFERRATO-MONTIGLIO",
    "state_code": "AT",
    "cab": "475806",
    "cap": "14026"
  },
  {
    "key": "9306",
    "ITA": "MONTIGLIO MONFERRATO-SCANDELUZZA",
    "state_code": "AT",
    "cab": "475806",
    "cap": "14020"
  },
  {
    "key": "9307",
    "ITA": "MONTIGNOSO-PRATO CAPANNE",
    "state_code": "MS",
    "cab": "699405",
    "cap": "54038"
  },
  {
    "key": "9308",
    "ITA": "MONTIGNOSO-SAN VITO",
    "state_code": "MS",
    "cab": "699405",
    "cap": "54038"
  },
  {
    "key": "9309",
    "ITA": "MONTIGNOSO-CINQUALE",
    "state_code": "MS",
    "cab": "699405",
    "cap": "54038"
  },
  {
    "key": "9310",
    "ITA": "MONTIGNOSO-CERRETO",
    "state_code": "MS",
    "cab": "699405",
    "cap": "54038"
  },
  {
    "key": "9311",
    "ITA": "MONTIGNOSO",
    "state_code": "MS",
    "cab": "699405",
    "cap": "54038"
  },
  {
    "key": "9312",
    "ITA": "MONTIRONE",
    "state_code": "BS",
    "cab": "547901",
    "cap": "25010"
  },
  {
    "key": "9313",
    "ITA": "MONTJOVET",
    "state_code": "AO",
    "cab": "902494",
    "cap": "11020"
  },
  {
    "key": "9314",
    "ITA": "MONTODINE",
    "state_code": "CR",
    "cab": "569202",
    "cap": "26010"
  },
  {
    "key": "9315",
    "ITA": "MONTOGGIO-TREFONTANE",
    "state_code": "GE",
    "cab": "320705",
    "cap": "16026"
  },
  {
    "key": "9316",
    "ITA": "MONTOGGIO",
    "state_code": "GE",
    "cab": "320705",
    "cap": "16026"
  },
  {
    "key": "9317",
    "ITA": "MONTONE",
    "state_code": "PG",
    "cab": "385609",
    "cap": "6014"
  },
  {
    "key": "9318",
    "ITA": "MONTOPOLI DI SABINA-BOCCHIGNANO",
    "state_code": "RI",
    "cab": "736900",
    "cap": "2034"
  },
  {
    "key": "9319",
    "ITA": "MONTOPOLI DI SABINA",
    "state_code": "RI",
    "cab": "736900",
    "cap": "2034"
  },
  {
    "key": "9320",
    "ITA": "MONTOPOLI IN VAL D'ARNO",
    "state_code": "PI",
    "cab": "710806",
    "cap": "56020"
  },
  {
    "key": "9321",
    "ITA": "MONTOPOLI IN VAL D'ARNO-CAPANNE",
    "state_code": "PI",
    "cab": "710806",
    "cap": "56020"
  },
  {
    "key": "9322",
    "ITA": "MONTOPOLI IN VAL D'ARNO-CASTEL DEL BOSCO",
    "state_code": "PI",
    "cab": "710806",
    "cap": "56020"
  },
  {
    "key": "9323",
    "ITA": "MONTOPOLI IN VAL D'ARNO-MARTI",
    "state_code": "PI",
    "cab": "710806",
    "cap": "56020"
  },
  {
    "key": "9324",
    "ITA": "MONTOPOLI IN VAL D'ARNO-MONTOPOLI",
    "state_code": "PI",
    "cab": "710806",
    "cap": "56020"
  },
  {
    "key": "9325",
    "ITA": "MONTOPOLI IN VAL D'ARNO-SAN ROMANO",
    "state_code": "PI",
    "cab": "710806",
    "cap": "56020"
  },
  {
    "key": "9326",
    "ITA": "MONTORFANO",
    "state_code": "CO",
    "cab": "565507",
    "cap": "22030"
  },
  {
    "key": "9327",
    "ITA": "MONTORIO AL VOMANO-LEOGNANO",
    "state_code": "TE",
    "cab": "769307",
    "cap": "64046"
  },
  {
    "key": "9328",
    "ITA": "MONTORIO AL VOMANO-CUSCIANO",
    "state_code": "TE",
    "cab": "769307",
    "cap": "64046"
  },
  {
    "key": "9329",
    "ITA": "MONTORIO AL VOMANO",
    "state_code": "TE",
    "cab": "769307",
    "cap": "64046"
  },
  {
    "key": "9330",
    "ITA": "MONTORIO AL VOMANO-COLLEVECCHIO DI MONTORIO",
    "state_code": "TE",
    "cab": "769307",
    "cap": "64046"
  },
  {
    "key": "9331",
    "ITA": "MONTORIO NEI FRENTANI",
    "state_code": "CB",
    "cab": "910752",
    "cap": "86040"
  },
  {
    "key": "9332",
    "ITA": "MONTORIO ROMANO",
    "state_code": "RM",
    "cab": "395806",
    "cap": "10"
  },
  {
    "key": "9333",
    "ITA": "MONTORO INFERIORE",
    "state_code": "AV",
    "cab": "758904",
    "cap": "83025"
  },
  {
    "key": "9334",
    "ITA": "MONTORO INFERIORE-BORGO",
    "state_code": "AV",
    "cab": "758904",
    "cap": "83025"
  },
  {
    "key": "9335",
    "ITA": "MONTORO INFERIORE-FIGLIOLI",
    "state_code": "AV",
    "cab": "758904",
    "cap": "83025"
  },
  {
    "key": "9336",
    "ITA": "MONTORO INFERIORE-MISCIANO",
    "state_code": "AV",
    "cab": "758904",
    "cap": "83025"
  },
  {
    "key": "9337",
    "ITA": "MONTORO INFERIORE-PIANO",
    "state_code": "AV",
    "cab": "758904",
    "cap": "83025"
  },
  {
    "key": "9338",
    "ITA": "MONTORO INFERIORE-PIAZZA DI PANDOLA",
    "state_code": "AV",
    "cab": "758904",
    "cap": "83025"
  },
  {
    "key": "9339",
    "ITA": "MONTORO INFERIORE-PRETURO",
    "state_code": "AV",
    "cab": "758904",
    "cap": "83025"
  },
  {
    "key": "9340",
    "ITA": "MONTORO SUPERIORE",
    "state_code": "AV",
    "cab": "892406",
    "cap": "83026"
  },
  {
    "key": "9341",
    "ITA": "MONTORO SUPERIORE-ATERRANA",
    "state_code": "AV",
    "cab": "892406",
    "cap": "83026"
  },
  {
    "key": "9342",
    "ITA": "MONTORO SUPERIORE-BANZANO",
    "state_code": "AV",
    "cab": "892406",
    "cap": "83026"
  },
  {
    "key": "9343",
    "ITA": "MONTORO SUPERIORE-CALIANO",
    "state_code": "AV",
    "cab": "892406",
    "cap": "83026"
  },
  {
    "key": "9344",
    "ITA": "MONTORO SUPERIORE-SAN PIETRO",
    "state_code": "AV",
    "cab": "892406",
    "cap": "83026"
  },
  {
    "key": "9345",
    "ITA": "MONTORO SUPERIORE-TORCHIATI",
    "state_code": "AV",
    "cab": "892406",
    "cap": "83026"
  },
  {
    "key": "9346",
    "ITA": "MONTORSO VICENTINO",
    "state_code": "VI",
    "cab": "605709",
    "cap": "36050"
  },
  {
    "key": "9347",
    "ITA": "MONTOTTONE",
    "state_code": "FM",
    "cab": "696104",
    "cap": "63020"
  },
  {
    "key": "9348",
    "ITA": "MONTRESTA-SANTA MARIA",
    "state_code": "OR",
    "cab": "867408",
    "cap": "8010"
  },
  {
    "key": "9349",
    "ITA": "MONTRESTA",
    "state_code": "OR",
    "cab": "867408",
    "cap": "8010"
  },
  {
    "key": "9350",
    "ITA": "MONTU' BECCARIA",
    "state_code": "PV",
    "cab": "560607",
    "cap": "27040"
  },
  {
    "key": "9351",
    "ITA": "MONVALLE",
    "state_code": "VA",
    "cab": "817205",
    "cap": "21020"
  },
  {
    "key": "9352",
    "ITA": "MONZA",
    "state_code": "MB",
    "cab": "204008",
    "cap": "20052"
  },
  {
    "key": "9353",
    "ITA": "MONZA-SAN FRUTTUOSO",
    "state_code": "MB",
    "cab": "204008",
    "cap": "20052"
  },
  {
    "key": "9354",
    "ITA": "MONZA",
    "state_code": "MB",
    "cab": "204008",
    "cap": "20900"
  },
  {
    "key": "9355",
    "ITA": "MONZAMBANO",
    "state_code": "MN",
    "cab": "577403",
    "cap": "46040"
  },
  {
    "key": "9356",
    "ITA": "MONZUNO-VADO",
    "state_code": "BO",
    "cab": "369702",
    "cap": "40036"
  },
  {
    "key": "9357",
    "ITA": "MONZUNO-RIOVEGGIO",
    "state_code": "BO",
    "cab": "369702",
    "cap": "40036"
  },
  {
    "key": "9358",
    "ITA": "MONZUNO",
    "state_code": "BO",
    "cab": "369702",
    "cap": "40036"
  },
  {
    "key": "9359",
    "ITA": "MORANO CALABRO",
    "state_code": "CS",
    "cab": "888404",
    "cap": "87016"
  },
  {
    "key": "9360",
    "ITA": "MORANO SUL PO",
    "state_code": "AL",
    "cab": "484006",
    "cap": "15025"
  },
  {
    "key": "9361",
    "ITA": "MORANSENGO",
    "state_code": "AT",
    "cab": "920306",
    "cap": "14023"
  },
  {
    "key": "9362",
    "ITA": "MORARO",
    "state_code": "GO",
    "cab": "937730",
    "cap": "34070"
  },
  {
    "key": "9363",
    "ITA": "MORAZZONE",
    "state_code": "VA",
    "cab": "504407",
    "cap": "21040"
  },
  {
    "key": "9364",
    "ITA": "MORBEGNO-CAMPOVICO",
    "state_code": "SO",
    "cab": "522300",
    "cap": "23017"
  },
  {
    "key": "9365",
    "ITA": "MORBEGNO",
    "state_code": "SO",
    "cab": "522300",
    "cap": "23017"
  },
  {
    "key": "9366",
    "ITA": "MORBELLO",
    "state_code": "AL",
    "cab": "921403",
    "cap": "15010"
  },
  {
    "key": "9367",
    "ITA": "MORCIANO DI LEUCA-BARBARANO DEL CAPO",
    "state_code": "LE",
    "cab": "798009",
    "cap": "73040"
  },
  {
    "key": "9368",
    "ITA": "MORCIANO DI LEUCA",
    "state_code": "LE",
    "cab": "798009",
    "cap": "73040"
  },
  {
    "key": "9369",
    "ITA": "MORCIANO DI ROMAGNA",
    "state_code": "RN",
    "cab": "679308",
    "cap": "47833"
  },
  {
    "key": "9370",
    "ITA": "MORCONE-CUFFIANO",
    "state_code": "BN",
    "cab": "753806",
    "cap": "82026"
  },
  {
    "key": "9371",
    "ITA": "MORCONE",
    "state_code": "BN",
    "cab": "753806",
    "cap": "82026"
  },
  {
    "key": "9372",
    "ITA": "MORDANO",
    "state_code": "BO",
    "cab": "369801",
    "cap": "40027"
  },
  {
    "key": "9373",
    "ITA": "MORDANO-BUBANO",
    "state_code": "BO",
    "cab": "369801",
    "cap": "40027"
  },
  {
    "key": "9374",
    "ITA": "MORENGO",
    "state_code": "BG",
    "cab": "896506",
    "cap": "24050"
  },
  {
    "key": "9375",
    "ITA": "MORES",
    "state_code": "SS",
    "cab": "874602",
    "cap": "7013"
  },
  {
    "key": "9376",
    "ITA": "MORESCO",
    "state_code": "FM",
    "cab": "198788",
    "cap": "63026"
  },
  {
    "key": "9377",
    "ITA": "MORETTA",
    "state_code": "CN",
    "cab": "465302",
    "cap": "12033"
  },
  {
    "key": "9378",
    "ITA": "MORFASSO",
    "state_code": "PC",
    "cab": "653709",
    "cap": "29020"
  },
  {
    "key": "9379",
    "ITA": "MORGANO-BADOERE",
    "state_code": "TV",
    "cab": "618306",
    "cap": "31050"
  },
  {
    "key": "9380",
    "ITA": "MORGANO",
    "state_code": "TV",
    "cab": "618306",
    "cap": "31050"
  },
  {
    "key": "9381",
    "ITA": "MORGEX",
    "state_code": "AO",
    "cab": "316208",
    "cap": "11017"
  },
  {
    "key": "9382",
    "ITA": "MORGONGIORI",
    "state_code": "OR",
    "cab": "878702",
    "cap": "9090"
  },
  {
    "key": "9383",
    "ITA": "MORI",
    "state_code": "TN",
    "cab": "350900",
    "cap": "38065"
  },
  {
    "key": "9384",
    "ITA": "MORI-PANNONE",
    "state_code": "TN",
    "cab": "350900",
    "cap": "38065"
  },
  {
    "key": "9385",
    "ITA": "MORI-MANZANO",
    "state_code": "TN",
    "cab": "350900",
    "cap": "38065"
  },
  {
    "key": "9386",
    "ITA": "MORI-SANO",
    "state_code": "TN",
    "cab": "350900",
    "cap": "38065"
  },
  {
    "key": "9387",
    "ITA": "MORI-VALLE SAN FELICE",
    "state_code": "TN",
    "cab": "350900",
    "cap": "38065"
  },
  {
    "key": "9388",
    "ITA": "MORI-BESAGNO",
    "state_code": "TN",
    "cab": "350900",
    "cap": "38065"
  },
  {
    "key": "9389",
    "ITA": "MORIAGO DELLA BATTAGLIA-MOSNIGO",
    "state_code": "TV",
    "cab": "883009",
    "cap": "31010"
  },
  {
    "key": "9390",
    "ITA": "MORIAGO DELLA BATTAGLIA",
    "state_code": "TV",
    "cab": "883009",
    "cap": "31010"
  },
  {
    "key": "9391",
    "ITA": "MORICONE",
    "state_code": "RM",
    "cab": "392506",
    "cap": "10"
  },
  {
    "key": "9392",
    "ITA": "MORIGERATI",
    "state_code": "SA",
    "cab": "952713",
    "cap": "84030"
  },
  {
    "key": "9393",
    "ITA": "MORIGERATI-SICILI'",
    "state_code": "SA",
    "cab": "952713",
    "cap": "84030"
  },
  {
    "key": "9394",
    "ITA": "MORIMONDO",
    "state_code": "MI",
    "cab": "904003",
    "cap": "20081"
  },
  {
    "key": "9395",
    "ITA": "MORINO-GRANCIA",
    "state_code": "AQ",
    "cab": "909754",
    "cap": "67050"
  },
  {
    "key": "9396",
    "ITA": "MORINO",
    "state_code": "AQ",
    "cab": "909754",
    "cap": "67050"
  },
  {
    "key": "9397",
    "ITA": "MORINO-RENDINARA",
    "state_code": "AQ",
    "cab": "909754",
    "cap": "67050"
  },
  {
    "key": "9398",
    "ITA": "MORIONDO TORINESE",
    "state_code": "TO",
    "cab": "283960",
    "cap": "10020"
  },
  {
    "key": "9399",
    "ITA": "MORLUPO",
    "state_code": "RM",
    "cab": "392605",
    "cap": "67"
  },
  {
    "key": "9400",
    "ITA": "MORMANNO",
    "state_code": "CS",
    "cab": "808303",
    "cap": "87026"
  },
  {
    "key": "9401",
    "ITA": "MORNAGO-CRUGNOLA",
    "state_code": "VA",
    "cab": "504506",
    "cap": "21020"
  },
  {
    "key": "9402",
    "ITA": "MORNAGO",
    "state_code": "VA",
    "cab": "504506",
    "cap": "21020"
  },
  {
    "key": "9403",
    "ITA": "MORNAGO-MONTONATE",
    "state_code": "VA",
    "cab": "504506",
    "cap": "21020"
  },
  {
    "key": "9404",
    "ITA": "MORNESE",
    "state_code": "AL",
    "cab": "790808",
    "cap": "15075"
  },
  {
    "key": "9405",
    "ITA": "MORNICO AL SERIO",
    "state_code": "BG",
    "cab": "532200",
    "cap": "24050"
  },
  {
    "key": "9406",
    "ITA": "MORNICO LOSANA-MORNICO",
    "state_code": "PV",
    "cab": "277194",
    "cap": "27040"
  },
  {
    "key": "9407",
    "ITA": "MORNICO LOSANA",
    "state_code": "PV",
    "cab": "277194",
    "cap": "27040"
  },
  {
    "key": "9408",
    "ITA": "MOROLO-CERQUOTTI MADONNA DEL PIANO",
    "state_code": "FR",
    "cab": "744904",
    "cap": "3017"
  },
  {
    "key": "9409",
    "ITA": "MOROLO",
    "state_code": "FR",
    "cab": "744904",
    "cap": "3017"
  },
  {
    "key": "9410",
    "ITA": "MOROZZO-TRUCCHI",
    "state_code": "CN",
    "cab": "465401",
    "cap": "12040"
  },
  {
    "key": "9411",
    "ITA": "MOROZZO",
    "state_code": "CN",
    "cab": "465401",
    "cap": "12040"
  },
  {
    "key": "9412",
    "ITA": "MORRA DE SANCTIS",
    "state_code": "AV",
    "cab": "951368",
    "cap": "83040"
  },
  {
    "key": "9413",
    "ITA": "MORRO D'ALBA",
    "state_code": "AN",
    "cab": "374603",
    "cap": "60030"
  },
  {
    "key": "9414",
    "ITA": "MORRO D'ORO",
    "state_code": "TE",
    "cab": "769406",
    "cap": "64020"
  },
  {
    "key": "9415",
    "ITA": "MORRO D'ORO-PAGLIARE",
    "state_code": "TE",
    "cab": "769406",
    "cap": "64020"
  },
  {
    "key": "9416",
    "ITA": "MORRO REATINO",
    "state_code": "RI",
    "cab": "947309",
    "cap": "2010"
  },
  {
    "key": "9417",
    "ITA": "MORRONE DEL SANNIO",
    "state_code": "CB",
    "cab": "910760",
    "cap": "86040"
  },
  {
    "key": "9418",
    "ITA": "MORROVALLE-STAZIONE MORROVALLE",
    "state_code": "MC",
    "cab": "690305",
    "cap": "62010"
  },
  {
    "key": "9419",
    "ITA": "MORROVALLE-PINTURA",
    "state_code": "MC",
    "cab": "690305",
    "cap": "62010"
  },
  {
    "key": "9420",
    "ITA": "MORROVALLE",
    "state_code": "MC",
    "cab": "690305",
    "cap": "62010"
  },
  {
    "key": "9421",
    "ITA": "MORROVALLE-TRODICA",
    "state_code": "MC",
    "cab": "690305",
    "cap": "62010"
  },
  {
    "key": "9422",
    "ITA": "MORSANO AL TAGLIAMENTO-MUSSONS",
    "state_code": "PN",
    "cab": "649202",
    "cap": "33075"
  },
  {
    "key": "9423",
    "ITA": "MORSANO AL TAGLIAMENTO",
    "state_code": "PN",
    "cab": "649202",
    "cap": "33075"
  },
  {
    "key": "9424",
    "ITA": "MORSASCO",
    "state_code": "AL",
    "cab": "921429",
    "cap": "15010"
  },
  {
    "key": "9425",
    "ITA": "MORTARA",
    "state_code": "PV",
    "cab": "560706",
    "cap": "27036"
  },
  {
    "key": "9426",
    "ITA": "MORTEGLIANO",
    "state_code": "UD",
    "cab": "639807",
    "cap": "33050"
  },
  {
    "key": "9427",
    "ITA": "MORTEGLIANO-CHIASELLIS",
    "state_code": "UD",
    "cab": "639807",
    "cap": "33050"
  },
  {
    "key": "9428",
    "ITA": "MORTEGLIANO-LAVARIANO",
    "state_code": "UD",
    "cab": "639807",
    "cap": "33050"
  },
  {
    "key": "9429",
    "ITA": "MORTERONE",
    "state_code": "LC",
    "cab": "925875",
    "cap": "23811"
  },
  {
    "key": "9430",
    "ITA": "MORUZZO",
    "state_code": "UD",
    "cab": "644401",
    "cap": "33030"
  },
  {
    "key": "9431",
    "ITA": "MORUZZO-BRAZZACCO",
    "state_code": "UD",
    "cab": "644401",
    "cap": "33030"
  },
  {
    "key": "9432",
    "ITA": "MORUZZO-SANTA MARGHERITA",
    "state_code": "UD",
    "cab": "644401",
    "cap": "33030"
  },
  {
    "key": "9433",
    "ITA": "MOSCAZZANO",
    "state_code": "CR",
    "cab": "564807",
    "cap": "26010"
  },
  {
    "key": "9434",
    "ITA": "MOSCHIANO",
    "state_code": "AV",
    "cab": "951376",
    "cap": "83020"
  },
  {
    "key": "9435",
    "ITA": "MOSCIANO SANT'ANGELO-NOTARESCO STAZIONE",
    "state_code": "TE",
    "cab": "769505",
    "cap": "64023"
  },
  {
    "key": "9436",
    "ITA": "MOSCIANO SANT'ANGELO",
    "state_code": "TE",
    "cab": "769505",
    "cap": "64023"
  },
  {
    "key": "9437",
    "ITA": "MOSCIANO SANT'ANGELO-MONTONE",
    "state_code": "TE",
    "cab": "769505",
    "cap": "64023"
  },
  {
    "key": "9438",
    "ITA": "MOSCUFO",
    "state_code": "PE",
    "cab": "773507",
    "cap": "65010"
  },
  {
    "key": "9439",
    "ITA": "MOSO IN PASSIRIA",
    "state_code": "BZ",
    "cab": "586107",
    "cap": "39013"
  },
  {
    "key": "9440",
    "ITA": "MOSO IN PASSIRIA-PLAN IN PASSIRIA",
    "state_code": "BZ",
    "cab": "586107",
    "cap": "39013"
  },
  {
    "key": "9441",
    "ITA": "MOSSA",
    "state_code": "GO",
    "cab": "937748",
    "cap": "34070"
  },
  {
    "key": "9442",
    "ITA": "MOSSANO",
    "state_code": "VI",
    "cab": "285965",
    "cap": "36024"
  },
  {
    "key": "9443",
    "ITA": "MOSSO-PISTOLESA",
    "state_code": "BI",
    "cab": "820100",
    "cap": "13822"
  },
  {
    "key": "9444",
    "ITA": "MOSSO",
    "state_code": "BI",
    "cab": "820100",
    "cap": "13822"
  },
  {
    "key": "9445",
    "ITA": "MOTTA BALUFFI",
    "state_code": "CR",
    "cab": "569301",
    "cap": "26045"
  },
  {
    "key": "9446",
    "ITA": "MOTTA CAMASTRA",
    "state_code": "ME",
    "cab": "961490",
    "cap": "98030"
  },
  {
    "key": "9447",
    "ITA": "MOTTA D'AFFERMO-TORREMUZZA",
    "state_code": "ME",
    "cab": "961508",
    "cap": "98070"
  },
  {
    "key": "9448",
    "ITA": "MOTTA D'AFFERMO",
    "state_code": "ME",
    "cab": "961508",
    "cap": "98070"
  },
  {
    "key": "9449",
    "ITA": "MOTTA DE' CONTI",
    "state_code": "VC",
    "cab": "445908",
    "cap": "13010"
  },
  {
    "key": "9450",
    "ITA": "MOTTA DI LIVENZA",
    "state_code": "TV",
    "cab": "618405",
    "cap": "31045"
  },
  {
    "key": "9451",
    "ITA": "MOTTA MONTECORVINO",
    "state_code": "FG",
    "cab": "499608",
    "cap": "71030"
  },
  {
    "key": "9452",
    "ITA": "MOTTA SAN GIOVANNI",
    "state_code": "RC",
    "cab": "888909",
    "cap": "89065"
  },
  {
    "key": "9453",
    "ITA": "MOTTA SAN GIOVANNI-LAZZARO",
    "state_code": "RC",
    "cab": "888909",
    "cap": "89062"
  },
  {
    "key": "9454",
    "ITA": "MOTTA SANT'ANASTASIA",
    "state_code": "CT",
    "cab": "840801",
    "cap": "95040"
  },
  {
    "key": "9455",
    "ITA": "MOTTA SANTA LUCIA",
    "state_code": "CZ",
    "cab": "913202",
    "cap": "88040"
  },
  {
    "key": "9456",
    "ITA": "MOTTA VISCONTI",
    "state_code": "MI",
    "cab": "334201",
    "cap": "20086"
  },
  {
    "key": "9457",
    "ITA": "MOTTAFOLLONE",
    "state_code": "CS",
    "cab": "959072",
    "cap": "87010"
  },
  {
    "key": "9458",
    "ITA": "MOTTALCIATA",
    "state_code": "BI",
    "cab": "446005",
    "cap": "13874"
  },
  {
    "key": "9459",
    "ITA": "MOTTEGGIANA-VILLA SAVIOLA",
    "state_code": "MN",
    "cab": "577502",
    "cap": "46020"
  },
  {
    "key": "9460",
    "ITA": "MOTTEGGIANA",
    "state_code": "MN",
    "cab": "577502",
    "cap": "46020"
  },
  {
    "key": "9461",
    "ITA": "MOTTOLA",
    "state_code": "TA",
    "cab": "789800",
    "cap": "74017"
  },
  {
    "key": "9462",
    "ITA": "MOTTOLA-SAN BASILIO MOTTOLA",
    "state_code": "TA",
    "cab": "789800",
    "cap": "74017"
  },
  {
    "key": "9463",
    "ITA": "MOZZAGROGNA",
    "state_code": "CH",
    "cab": "954826",
    "cap": "66030"
  },
  {
    "key": "9464",
    "ITA": "MOZZAGROGNA-VILLA ROMAGNOLI",
    "state_code": "CH",
    "cab": "954826",
    "cap": "66030"
  },
  {
    "key": "9465",
    "ITA": "MOZZANICA",
    "state_code": "BG",
    "cab": "532309",
    "cap": "24050"
  },
  {
    "key": "9466",
    "ITA": "MOZZATE",
    "state_code": "CO",
    "cab": "515809",
    "cap": "22076"
  },
  {
    "key": "9467",
    "ITA": "MOZZECANE",
    "state_code": "VR",
    "cab": "595900",
    "cap": "37060"
  },
  {
    "key": "9468",
    "ITA": "MOZZO",
    "state_code": "BG",
    "cab": "532408",
    "cap": "24030"
  },
  {
    "key": "9469",
    "ITA": "MOZZO-CROCETTE",
    "state_code": "BG",
    "cab": "532408",
    "cap": "24030"
  },
  {
    "key": "9470",
    "ITA": "MUCCIA",
    "state_code": "MC",
    "cab": "690404",
    "cap": "62034"
  },
  {
    "key": "9471",
    "ITA": "MUGGIA-SAN ROCCO",
    "state_code": "TS",
    "cab": "364802",
    "cap": "34015"
  },
  {
    "key": "9472",
    "ITA": "MUGGIA-STRAMARE",
    "state_code": "TS",
    "cab": "364802",
    "cap": "34015"
  },
  {
    "key": "9473",
    "ITA": "MUGGIA-AQUILINIA",
    "state_code": "TS",
    "cab": "364802",
    "cap": "34015"
  },
  {
    "key": "9474",
    "ITA": "MUGGIA",
    "state_code": "TS",
    "cab": "364802",
    "cap": "34015"
  },
  {
    "key": "9475",
    "ITA": "MUGGIO",
    "state_code": "MB",
    "cab": "334300",
    "cap": "20053"
  },
  {
    "key": "9476",
    "ITA": "MUGGIO'-TACCONA",
    "state_code": "MB",
    "cab": "334300",
    "cap": "20053"
  },
  {
    "key": "9477",
    "ITA": "MUGNANO DEL CARDINALE",
    "state_code": "AV",
    "cab": "757906",
    "cap": "83027"
  },
  {
    "key": "9478",
    "ITA": "MUGNANO DI NAPOLI",
    "state_code": "NA",
    "cab": "400101",
    "cap": "80018"
  },
  {
    "key": "9479",
    "ITA": "MULAZZANO",
    "state_code": "LO",
    "cab": "334409",
    "cap": "26837"
  },
  {
    "key": "9480",
    "ITA": "MULAZZANO-CASSINO D'ALBERI",
    "state_code": "LO",
    "cab": "334409",
    "cap": "26837"
  },
  {
    "key": "9481",
    "ITA": "MULAZZANO-QUARTIANO",
    "state_code": "LO",
    "cab": "334409",
    "cap": "26837"
  },
  {
    "key": "9482",
    "ITA": "MULAZZO",
    "state_code": "MS",
    "cab": "699504",
    "cap": "54026"
  },
  {
    "key": "9483",
    "ITA": "MULAZZO-ARPIOLA",
    "state_code": "MS",
    "cab": "699504",
    "cap": "54026"
  },
  {
    "key": "9484",
    "ITA": "MULAZZO-GROPPOLI",
    "state_code": "MS",
    "cab": "699504",
    "cap": "54026"
  },
  {
    "key": "9485",
    "ITA": "MULAZZO-MONTEREGGIO",
    "state_code": "MS",
    "cab": "699504",
    "cap": "54026"
  },
  {
    "key": "9486",
    "ITA": "MURA",
    "state_code": "BS",
    "cab": "929513",
    "cap": "25070"
  },
  {
    "key": "9487",
    "ITA": "MURAVERA",
    "state_code": "CA",
    "cab": "439208",
    "cap": "9043"
  },
  {
    "key": "9488",
    "ITA": "MURAZZANO",
    "state_code": "CN",
    "cab": "465500",
    "cap": "12060"
  },
  {
    "key": "9489",
    "ITA": "MURELLO",
    "state_code": "CN",
    "cab": "479105",
    "cap": "12030"
  },
  {
    "key": "9490",
    "ITA": "MURIALDO-PIANO",
    "state_code": "SV",
    "cab": "923185",
    "cap": "17013"
  },
  {
    "key": "9491",
    "ITA": "MURIALDO-VALLE",
    "state_code": "SV",
    "cab": "923185",
    "cap": "17013"
  },
  {
    "key": "9492",
    "ITA": "MURIALDO",
    "state_code": "SV",
    "cab": "923185",
    "cap": "17013"
  },
  {
    "key": "9493",
    "ITA": "MURISENGO",
    "state_code": "AL",
    "cab": "484105",
    "cap": "15020"
  },
  {
    "key": "9494",
    "ITA": "MURLO",
    "state_code": "SI",
    "cab": "719104",
    "cap": "53016"
  },
  {
    "key": "9495",
    "ITA": "MURLO-CASCIANO",
    "state_code": "SI",
    "cab": "719104",
    "cap": "53016"
  },
  {
    "key": "9496",
    "ITA": "MURLO-VESCOVADO",
    "state_code": "SI",
    "cab": "719104",
    "cap": "53016"
  },
  {
    "key": "9497",
    "ITA": "MURO LECCESE",
    "state_code": "LE",
    "cab": "798108",
    "cap": "73036"
  },
  {
    "key": "9498",
    "ITA": "MURO LUCANO-CAPO DI GIANO",
    "state_code": "PZ",
    "cab": "421305",
    "cap": "85054"
  },
  {
    "key": "9499",
    "ITA": "MURO LUCANO",
    "state_code": "PZ",
    "cab": "421305",
    "cap": "85054"
  },
  {
    "key": "9500",
    "ITA": "MUROS",
    "state_code": "SS",
    "cab": "964742",
    "cap": "7030"
  },
  {
    "key": "9501",
    "ITA": "MUSCOLINE",
    "state_code": "BS",
    "cab": "771303",
    "cap": "25080"
  },
  {
    "key": "9502",
    "ITA": "MUSCOLINE-CHIESA",
    "state_code": "BS",
    "cab": "771303",
    "cap": "25080"
  },
  {
    "key": "9503",
    "ITA": "MUSEI",
    "state_code": "SU",
    "cab": "860304",
    "cap": "9010"
  },
  {
    "key": "9504",
    "ITA": "MUSILE DI PIAVE",
    "state_code": "VE",
    "cab": "362004",
    "cap": "30024"
  },
  {
    "key": "9505",
    "ITA": "MUSILE DI PIAVE-CROCE",
    "state_code": "VE",
    "cab": "362004",
    "cap": "30024"
  },
  {
    "key": "9506",
    "ITA": "MUSSO",
    "state_code": "CO",
    "cab": "635300",
    "cap": "22010"
  },
  {
    "key": "9507",
    "ITA": "MUSSOLENTE",
    "state_code": "VI",
    "cab": "605808",
    "cap": "36065"
  },
  {
    "key": "9508",
    "ITA": "MUSSOLENTE-CASONI",
    "state_code": "VI",
    "cab": "605808",
    "cap": "36065"
  },
  {
    "key": "9509",
    "ITA": "MUSSOMELI",
    "state_code": "CL",
    "cab": "833806",
    "cap": "93014"
  },
  {
    "key": "9510",
    "ITA": "MUSSOMELI-POLIZZELLO",
    "state_code": "CL",
    "cab": "833806",
    "cap": "93014"
  },
  {
    "key": "9511",
    "ITA": "MUZZANA DEL TURGNANO",
    "state_code": "UD",
    "cab": "639906",
    "cap": "33055"
  },
  {
    "key": "9512",
    "ITA": "MUZZANO",
    "state_code": "BI",
    "cab": "916049",
    "cap": "13895"
  },
  {
    "key": "9513",
    "ITA": "NAGO TORBOLE",
    "state_code": "TN",
    "cab": "351007",
    "cap": "38069"
  },
  {
    "key": "9514",
    "ITA": "NAGO TORBOLE-NAGO",
    "state_code": "TN",
    "cab": "351007",
    "cap": "38069"
  },
  {
    "key": "9515",
    "ITA": "NAGO TORBOLE-TORBOLE",
    "state_code": "TN",
    "cab": "351007",
    "cap": "38069"
  },
  {
    "key": "9516",
    "ITA": "NALLES",
    "state_code": "BZ",
    "cab": "586206",
    "cap": "39010"
  },
  {
    "key": "9517",
    "ITA": "NANNO",
    "state_code": "TN",
    "cab": "351106",
    "cap": "38010"
  },
  {
    "key": "9518",
    "ITA": "NANTO-PONTE DI NANTO",
    "state_code": "VI",
    "cab": "605907",
    "cap": "36024"
  },
  {
    "key": "9519",
    "ITA": "NANTO",
    "state_code": "VI",
    "cab": "605907",
    "cap": "36024"
  },
  {
    "key": "9520",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80121"
  },
  {
    "key": "9521",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80122"
  },
  {
    "key": "9522",
    "ITA": "NAPOLI-SOCCAVO",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80126"
  },
  {
    "key": "9523",
    "ITA": "NAPOLI-SECONDIGLIANO",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80144"
  },
  {
    "key": "9524",
    "ITA": "NAPOLI-SCAMPIA",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80145"
  },
  {
    "key": "9525",
    "ITA": "NAPOLI-PISCINOLA",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80145"
  },
  {
    "key": "9526",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80123"
  },
  {
    "key": "9527",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80124"
  },
  {
    "key": "9528",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80125"
  },
  {
    "key": "9529",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80126"
  },
  {
    "key": "9530",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80127"
  },
  {
    "key": "9531",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80128"
  },
  {
    "key": "9532",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80129"
  },
  {
    "key": "9533",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80131"
  },
  {
    "key": "9534",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80132"
  },
  {
    "key": "9535",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80133"
  },
  {
    "key": "9536",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80134"
  },
  {
    "key": "9537",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80135"
  },
  {
    "key": "9538",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80136"
  },
  {
    "key": "9539",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80137"
  },
  {
    "key": "9540",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80138"
  },
  {
    "key": "9541",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80139"
  },
  {
    "key": "9542",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80141"
  },
  {
    "key": "9543",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80142"
  },
  {
    "key": "9544",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80143"
  },
  {
    "key": "9545",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80144"
  },
  {
    "key": "9546",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80145"
  },
  {
    "key": "9547",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80146"
  },
  {
    "key": "9548",
    "ITA": "NAPOLI",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80147"
  },
  {
    "key": "9549",
    "ITA": "NAPOLI-ARENELLA",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80131"
  },
  {
    "key": "9550",
    "ITA": "NAPOLI-BARRA",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80147"
  },
  {
    "key": "9551",
    "ITA": "NAPOLI-CHIAIA",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80122"
  },
  {
    "key": "9552",
    "ITA": "NAPOLI-MIANO",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80145"
  },
  {
    "key": "9553",
    "ITA": "NAPOLI-PIANURA",
    "state_code": "NA",
    "cab": "34009",
    "cap": "80126"
  },
  {
    "key": "9554",
    "ITA": "NARBOLIA",
    "state_code": "OR",
    "cab": "878801",
    "cap": "9070"
  },
  {
    "key": "9555",
    "ITA": "NARCAO",
    "state_code": "SU",
    "cab": "860403",
    "cap": "9010"
  },
  {
    "key": "9556",
    "ITA": "NARCAO-RIOMURTAS",
    "state_code": "SU",
    "cab": "860403",
    "cap": "9010"
  },
  {
    "key": "9557",
    "ITA": "NARCAO-ROSAS",
    "state_code": "SU",
    "cab": "860403",
    "cap": "9010"
  },
  {
    "key": "9558",
    "ITA": "NARCAO-TERRASEO",
    "state_code": "SU",
    "cab": "860403",
    "cap": "9010"
  },
  {
    "key": "9559",
    "ITA": "NARDO'",
    "state_code": "LE",
    "cab": "798207",
    "cap": "73048"
  },
  {
    "key": "9560",
    "ITA": "NARDO'-SANTA CATERINA",
    "state_code": "LE",
    "cab": "798207",
    "cap": "73050"
  },
  {
    "key": "9561",
    "ITA": "NARDO'-SANTA CHIARA",
    "state_code": "LE",
    "cab": "798207",
    "cap": "73050"
  },
  {
    "key": "9562",
    "ITA": "NARDO'-SANTA MARIA AL BAGNO",
    "state_code": "LE",
    "cab": "798207",
    "cap": "73050"
  },
  {
    "key": "9563",
    "ITA": "NARDO'-VILLAGGIO BONCORE",
    "state_code": "LE",
    "cab": "798207",
    "cap": "73050"
  },
  {
    "key": "9564",
    "ITA": "NARDODIPACE",
    "state_code": "VV",
    "cab": "913210",
    "cap": "89824"
  },
  {
    "key": "9565",
    "ITA": "NARNI",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9566",
    "ITA": "NARNI-VIGNE",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9567",
    "ITA": "NARNI-CAPITONE",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9568",
    "ITA": "NARNI-GUALDO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9569",
    "ITA": "NARNI-ITIELI",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9570",
    "ITA": "NARNI-MONTORO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9571",
    "ITA": "NARNI-NARNI SCALO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9572",
    "ITA": "NARNI-NERA MONTORO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9573",
    "ITA": "NARNI-PONTE SAN LORENZO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9574",
    "ITA": "NARNI-SAN LIBERATO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9575",
    "ITA": "NARNI-SAN VITO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9576",
    "ITA": "NARNI-SANT'URBANO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9577",
    "ITA": "NARNI-SCHIFANOIA",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9578",
    "ITA": "NARNI-STIFONE",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9579",
    "ITA": "NARNI-TAIZZANO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9580",
    "ITA": "NARNI-TESTACCIO",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9581",
    "ITA": "NARNI-BORGARIA",
    "state_code": "TR",
    "cab": "727107",
    "cap": "5035"
  },
  {
    "key": "9582",
    "ITA": "NARO",
    "state_code": "AG",
    "cab": "830208",
    "cap": "92028"
  },
  {
    "key": "9583",
    "ITA": "NARZOLE",
    "state_code": "CN",
    "cab": "465609",
    "cap": "12068"
  },
  {
    "key": "9584",
    "ITA": "NASINO",
    "state_code": "SV",
    "cab": "923193",
    "cap": "17030"
  },
  {
    "key": "9585",
    "ITA": "NASO",
    "state_code": "ME",
    "cab": "823302",
    "cap": "98074"
  },
  {
    "key": "9586",
    "ITA": "NASO-MALO'",
    "state_code": "ME",
    "cab": "823302",
    "cap": "98074"
  },
  {
    "key": "9587",
    "ITA": "NASO-CRESTA",
    "state_code": "ME",
    "cab": "823302",
    "cap": "98074"
  },
  {
    "key": "9588",
    "ITA": "NATURNO-STAVA",
    "state_code": "BZ",
    "cab": "586305",
    "cap": "39025"
  },
  {
    "key": "9589",
    "ITA": "NATURNO",
    "state_code": "BZ",
    "cab": "586305",
    "cap": "39025"
  },
  {
    "key": "9590",
    "ITA": "NAVE",
    "state_code": "BS",
    "cab": "548008",
    "cap": "25075"
  },
  {
    "key": "9591",
    "ITA": "NAVE SAN ROCCO",
    "state_code": "TN",
    "cab": "351205",
    "cap": "38010"
  },
  {
    "key": "9592",
    "ITA": "NAVELLI-CIVITARETENGA",
    "state_code": "AQ",
    "cab": "909762",
    "cap": "67020"
  },
  {
    "key": "9593",
    "ITA": "NAVELLI",
    "state_code": "AQ",
    "cab": "909762",
    "cap": "67020"
  },
  {
    "key": "9594",
    "ITA": "NAZ SCIAVES-RASA",
    "state_code": "BZ",
    "cab": "586404",
    "cap": "39040"
  },
  {
    "key": "9595",
    "ITA": "NAZ SCIAVES-NAZ",
    "state_code": "BZ",
    "cab": "586404",
    "cap": "39040"
  },
  {
    "key": "9596",
    "ITA": "NAZ SCIAVES",
    "state_code": "BZ",
    "cab": "586404",
    "cap": "39040"
  },
  {
    "key": "9597",
    "ITA": "NAZ SCIAVES-SCIAVES",
    "state_code": "BZ",
    "cab": "586404",
    "cap": "39040"
  },
  {
    "key": "9598",
    "ITA": "NAZZANO",
    "state_code": "RM",
    "cab": "392704",
    "cap": "60"
  },
  {
    "key": "9599",
    "ITA": "NE",
    "state_code": "GE",
    "cab": "320804",
    "cap": "16040"
  },
  {
    "key": "9600",
    "ITA": "NE-CONSCENTI",
    "state_code": "GE",
    "cab": "320804",
    "cap": "16040"
  },
  {
    "key": "9601",
    "ITA": "NE-PIANDIFIENO",
    "state_code": "GE",
    "cab": "320804",
    "cap": "16040"
  },
  {
    "key": "9602",
    "ITA": "NE-REPPIA",
    "state_code": "GE",
    "cab": "320804",
    "cap": "16040"
  },
  {
    "key": "9603",
    "ITA": "NE-STATALE",
    "state_code": "GE",
    "cab": "320804",
    "cap": "16040"
  },
  {
    "key": "9604",
    "ITA": "NEBBIUNO",
    "state_code": "NO",
    "cab": "455303",
    "cap": "28010"
  },
  {
    "key": "9605",
    "ITA": "NEGRAR",
    "state_code": "VR",
    "cab": "596007",
    "cap": "37024"
  },
  {
    "key": "9606",
    "ITA": "NEGRAR-ARBIZZANO",
    "state_code": "VR",
    "cab": "596007",
    "cap": "37024"
  },
  {
    "key": "9607",
    "ITA": "NEGRAR-FANE",
    "state_code": "VR",
    "cab": "596007",
    "cap": "37020"
  },
  {
    "key": "9608",
    "ITA": "NEGRAR-PRUN",
    "state_code": "VR",
    "cab": "596007",
    "cap": "37020"
  },
  {
    "key": "9609",
    "ITA": "NEGRAR-SANTA MARIA DI NEGRAR",
    "state_code": "VR",
    "cab": "596007",
    "cap": "37024"
  },
  {
    "key": "9610",
    "ITA": "NEIRONE",
    "state_code": "GE",
    "cab": "903237",
    "cap": "16040"
  },
  {
    "key": "9611",
    "ITA": "NEIRONE-OGNIO",
    "state_code": "GE",
    "cab": "903237",
    "cap": "16040"
  },
  {
    "key": "9612",
    "ITA": "NEIRONE-ROCCATAGLIATA",
    "state_code": "GE",
    "cab": "903237",
    "cap": "16040"
  },
  {
    "key": "9613",
    "ITA": "NEIVE",
    "state_code": "CN",
    "cab": "465708",
    "cap": "12052"
  },
  {
    "key": "9614",
    "ITA": "NEIVE-BORGONOVO",
    "state_code": "CN",
    "cab": "465708",
    "cap": "12052"
  },
  {
    "key": "9615",
    "ITA": "NEMBRO-GAVARNO RINNOVATA",
    "state_code": "BG",
    "cab": "532507",
    "cap": "24027"
  },
  {
    "key": "9616",
    "ITA": "NEMBRO",
    "state_code": "BG",
    "cab": "532507",
    "cap": "24027"
  },
  {
    "key": "9617",
    "ITA": "NEMI",
    "state_code": "RM",
    "cab": "392803",
    "cap": "40"
  },
  {
    "key": "9618",
    "ITA": "NEMOLI",
    "state_code": "PZ",
    "cab": "912030",
    "cap": "85040"
  },
  {
    "key": "9619",
    "ITA": "NEONELI",
    "state_code": "OR",
    "cab": "878900",
    "cap": "9080"
  },
  {
    "key": "9620",
    "ITA": "NEPI",
    "state_code": "VT",
    "cab": "731901",
    "cap": "1036"
  },
  {
    "key": "9621",
    "ITA": "NERETO",
    "state_code": "TE",
    "cab": "769604",
    "cap": "64015"
  },
  {
    "key": "9622",
    "ITA": "NEROLA",
    "state_code": "RM",
    "cab": "392902",
    "cap": "17"
  },
  {
    "key": "9623",
    "ITA": "NEROLA-ACQUAVIVA",
    "state_code": "RM",
    "cab": "392902",
    "cap": "17"
  },
  {
    "key": "9624",
    "ITA": "NERVESA DELLA BATTAGLIA-BAVARIA",
    "state_code": "TV",
    "cab": "618504",
    "cap": "31040"
  },
  {
    "key": "9625",
    "ITA": "NERVESA DELLA BATTAGLIA",
    "state_code": "TV",
    "cab": "618504",
    "cap": "31040"
  },
  {
    "key": "9626",
    "ITA": "NERVIANO",
    "state_code": "MI",
    "cab": "334508",
    "cap": "20014"
  },
  {
    "key": "9627",
    "ITA": "NERVIANO-SANT'ILARIO MILANESE",
    "state_code": "MI",
    "cab": "334508",
    "cap": "20014"
  },
  {
    "key": "9628",
    "ITA": "NESPOLO",
    "state_code": "RI",
    "cab": "947317",
    "cap": "2020"
  },
  {
    "key": "9629",
    "ITA": "NESSO",
    "state_code": "CO",
    "cab": "519801",
    "cap": "22020"
  },
  {
    "key": "9630",
    "ITA": "NETRO",
    "state_code": "BI",
    "cab": "291476",
    "cap": "13896"
  },
  {
    "key": "9631",
    "ITA": "NETTUNO",
    "state_code": "RM",
    "cab": "393009",
    "cap": "48"
  },
  {
    "key": "9632",
    "ITA": "NEVIANO",
    "state_code": "LE",
    "cab": "798306",
    "cap": "73040"
  },
  {
    "key": "9633",
    "ITA": "NEVIANO DEGLI ARDUINI",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43024"
  },
  {
    "key": "9634",
    "ITA": "NEVIANO DEGLI ARDUINI-BAZZANO PARMENSE",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43020"
  },
  {
    "key": "9635",
    "ITA": "NEVIANO DEGLI ARDUINI-LUPAZZANO",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43024"
  },
  {
    "key": "9636",
    "ITA": "NEVIANO DEGLI ARDUINI-MEDIANO",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43020"
  },
  {
    "key": "9637",
    "ITA": "NEVIANO DEGLI ARDUINI-VEZZANO",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43020"
  },
  {
    "key": "9638",
    "ITA": "NEVIANO DEGLI ARDUINI-PROVAZZANO",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43029"
  },
  {
    "key": "9639",
    "ITA": "NEVIANO DEGLI ARDUINI-SASSO DI NEVIANO",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43020"
  },
  {
    "key": "9640",
    "ITA": "NEVIANO DEGLI ARDUINI-SCURANO",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43020"
  },
  {
    "key": "9641",
    "ITA": "NEVIANO DEGLI ARDUINI-MOZZANO",
    "state_code": "PR",
    "cab": "658302",
    "cap": "43024"
  },
  {
    "key": "9642",
    "ITA": "NEVIGLIE",
    "state_code": "CN",
    "cab": "779900",
    "cap": "12050"
  },
  {
    "key": "9643",
    "ITA": "NIARDO",
    "state_code": "BS",
    "cab": "478305",
    "cap": "25050"
  },
  {
    "key": "9644",
    "ITA": "NIARDO-CRIST",
    "state_code": "BS",
    "cab": "478305",
    "cap": "25050"
  },
  {
    "key": "9645",
    "ITA": "NIBBIANO",
    "state_code": "PC",
    "cab": "653808",
    "cap": "29010"
  },
  {
    "key": "9646",
    "ITA": "NIBBIANO-TREVOZZO",
    "state_code": "PC",
    "cab": "653808",
    "cap": "29010"
  },
  {
    "key": "9647",
    "ITA": "NIBBIANO-TASSARA",
    "state_code": "PC",
    "cab": "653808",
    "cap": "29010"
  },
  {
    "key": "9648",
    "ITA": "NIBBIANO-STRA'",
    "state_code": "PC",
    "cab": "653808",
    "cap": "29010"
  },
  {
    "key": "9649",
    "ITA": "NIBBIOLA",
    "state_code": "NO",
    "cab": "917500",
    "cap": "28070"
  },
  {
    "key": "9650",
    "ITA": "NIBIONNO",
    "state_code": "LC",
    "cab": "515908",
    "cap": "23895"
  },
  {
    "key": "9651",
    "ITA": "NIBIONNO-TABIAGO",
    "state_code": "LC",
    "cab": "515908",
    "cap": "23895"
  },
  {
    "key": "9652",
    "ITA": "NIBIONNO-CIBRONE",
    "state_code": "LC",
    "cab": "515908",
    "cap": "23895"
  },
  {
    "key": "9653",
    "ITA": "NICHELINO",
    "state_code": "TO",
    "cab": "306506",
    "cap": "10042"
  },
  {
    "key": "9654",
    "ITA": "NICHELINO-STUPINIGI",
    "state_code": "TO",
    "cab": "306506",
    "cap": "10042"
  },
  {
    "key": "9655",
    "ITA": "NICOLOSI",
    "state_code": "CT",
    "cab": "840900",
    "cap": "95030"
  },
  {
    "key": "9656",
    "ITA": "NICORVO",
    "state_code": "PV",
    "cab": "930651",
    "cap": "27020"
  },
  {
    "key": "9657",
    "ITA": "NICOSIA-VILLADORO",
    "state_code": "EN",
    "cab": "836700",
    "cap": "94010"
  },
  {
    "key": "9658",
    "ITA": "NICOSIA",
    "state_code": "EN",
    "cab": "836700",
    "cap": "94014"
  },
  {
    "key": "9659",
    "ITA": "NICOSIA-SAN GIACOMO",
    "state_code": "EN",
    "cab": "836700",
    "cap": "94014"
  },
  {
    "key": "9660",
    "ITA": "NICOTERA-PREITONI",
    "state_code": "VV",
    "cab": "426502",
    "cap": "89844"
  },
  {
    "key": "9661",
    "ITA": "NICOTERA-COMERCONI",
    "state_code": "VV",
    "cab": "426502",
    "cap": "89844"
  },
  {
    "key": "9662",
    "ITA": "NICOTERA-MARINA DI NICOTERA",
    "state_code": "VV",
    "cab": "426502",
    "cap": "89844"
  },
  {
    "key": "9663",
    "ITA": "NICOTERA",
    "state_code": "VV",
    "cab": "426502",
    "cap": "89844"
  },
  {
    "key": "9664",
    "ITA": "NICOTERA-BADIA",
    "state_code": "VV",
    "cab": "426502",
    "cap": "89844"
  },
  {
    "key": "9665",
    "ITA": "NIELLA BELBO",
    "state_code": "CN",
    "cab": "323709",
    "cap": "12050"
  },
  {
    "key": "9666",
    "ITA": "NIELLA TANARO",
    "state_code": "CN",
    "cab": "470500",
    "cap": "12060"
  },
  {
    "key": "9667",
    "ITA": "NIMIS",
    "state_code": "UD",
    "cab": "640003",
    "cap": "33045"
  },
  {
    "key": "9668",
    "ITA": "NISCEMI",
    "state_code": "CL",
    "cab": "833905",
    "cap": "93015"
  },
  {
    "key": "9669",
    "ITA": "NISSORIA",
    "state_code": "EN",
    "cab": "836809",
    "cap": "94010"
  },
  {
    "key": "9670",
    "ITA": "NIZZA DI SICILIA",
    "state_code": "ME",
    "cab": "823401",
    "cap": "98026"
  },
  {
    "key": "9671",
    "ITA": "NIZZA MONFERRATO",
    "state_code": "AT",
    "cab": "475905",
    "cap": "14049"
  },
  {
    "key": "9672",
    "ITA": "NOALE",
    "state_code": "VE",
    "cab": "362103",
    "cap": "30033"
  },
  {
    "key": "9673",
    "ITA": "NOALE-CAPPELLETTA",
    "state_code": "VE",
    "cab": "362103",
    "cap": "30033"
  },
  {
    "key": "9674",
    "ITA": "NOALE-MONIEGO",
    "state_code": "VE",
    "cab": "362103",
    "cap": "30033"
  },
  {
    "key": "9675",
    "ITA": "NOASCA",
    "state_code": "TO",
    "cab": "283994",
    "cap": "10080"
  },
  {
    "key": "9676",
    "ITA": "NOCARA",
    "state_code": "CS",
    "cab": "959080",
    "cap": "87070"
  },
  {
    "key": "9677",
    "ITA": "NOCCIANO",
    "state_code": "PE",
    "cab": "388405",
    "cap": "65010"
  },
  {
    "key": "9678",
    "ITA": "NOCERA INFERIORE",
    "state_code": "SA",
    "cab": "762708",
    "cap": "84014"
  },
  {
    "key": "9679",
    "ITA": "NOCERA SUPERIORE",
    "state_code": "SA",
    "cab": "762807",
    "cap": "84015"
  },
  {
    "key": "9680",
    "ITA": "NOCERA SUPERIORE-MATERDOMINI DI NOCERA",
    "state_code": "SA",
    "cab": "762807",
    "cap": "84015"
  },
  {
    "key": "9681",
    "ITA": "NOCERA TERINESE-MARINA DI NOCERA TERINESE",
    "state_code": "CZ",
    "cab": "426601",
    "cap": "88040"
  },
  {
    "key": "9682",
    "ITA": "NOCERA TERINESE",
    "state_code": "CZ",
    "cab": "426601",
    "cap": "88047"
  },
  {
    "key": "9683",
    "ITA": "NOCERA UMBRA-MOLINACCIO",
    "state_code": "PG",
    "cab": "385708",
    "cap": "6025"
  },
  {
    "key": "9684",
    "ITA": "NOCERA UMBRA-GAIFANA",
    "state_code": "PG",
    "cab": "385708",
    "cap": "6025"
  },
  {
    "key": "9685",
    "ITA": "NOCERA UMBRA",
    "state_code": "PG",
    "cab": "385708",
    "cap": "6025"
  },
  {
    "key": "9686",
    "ITA": "NOCETO",
    "state_code": "PR",
    "cab": "658401",
    "cap": "43015"
  },
  {
    "key": "9687",
    "ITA": "NOCETO-CELLA DI COSTAMEZZANA",
    "state_code": "PR",
    "cab": "658401",
    "cap": "43015"
  },
  {
    "key": "9688",
    "ITA": "NOCETO-COSTAMEZZANA",
    "state_code": "PR",
    "cab": "658401",
    "cap": "43015"
  },
  {
    "key": "9689",
    "ITA": "NOCETO-PONTE TARO",
    "state_code": "PR",
    "cab": "658401",
    "cap": "43010"
  },
  {
    "key": "9690",
    "ITA": "NOCI-LAMADACQUA",
    "state_code": "BA",
    "cab": "415802",
    "cap": "70015"
  },
  {
    "key": "9691",
    "ITA": "NOCI",
    "state_code": "BA",
    "cab": "415802",
    "cap": "70015"
  },
  {
    "key": "9692",
    "ITA": "NOCIGLIA",
    "state_code": "LE",
    "cab": "798405",
    "cap": "73020"
  },
  {
    "key": "9693",
    "ITA": "NOEPOLI",
    "state_code": "PZ",
    "cab": "886903",
    "cap": "85035"
  },
  {
    "key": "9694",
    "ITA": "NOGARA",
    "state_code": "VR",
    "cab": "596106",
    "cap": "37054"
  },
  {
    "key": "9695",
    "ITA": "NOGAREDO",
    "state_code": "TN",
    "cab": "351304",
    "cap": "38060"
  },
  {
    "key": "9696",
    "ITA": "NOGAROLE ROCCA",
    "state_code": "VR",
    "cab": "596205",
    "cap": "37060"
  },
  {
    "key": "9697",
    "ITA": "NOGAROLE ROCCA-BAGNOLO",
    "state_code": "VR",
    "cab": "596205",
    "cap": "37060"
  },
  {
    "key": "9698",
    "ITA": "NOGAROLE ROCCA-PRADELLE",
    "state_code": "VR",
    "cab": "596205",
    "cap": "37060"
  },
  {
    "key": "9699",
    "ITA": "NOGAROLE VICENTINO",
    "state_code": "VI",
    "cab": "702902",
    "cap": "36070"
  },
  {
    "key": "9700",
    "ITA": "NOICATTARO-PARCHITELLO",
    "state_code": "BA",
    "cab": "415901",
    "cap": "70016"
  },
  {
    "key": "9701",
    "ITA": "NOICATTARO",
    "state_code": "BA",
    "cab": "415901",
    "cap": "70016"
  },
  {
    "key": "9702",
    "ITA": "NOICATTARO-PARCO SCIZZO",
    "state_code": "BA",
    "cab": "415901",
    "cap": "70016"
  },
  {
    "key": "9703",
    "ITA": "NOLA-POLLASTRI",
    "state_code": "NA",
    "cab": "400200",
    "cap": "80035"
  },
  {
    "key": "9704",
    "ITA": "NOLA-PIAZZOLLA",
    "state_code": "NA",
    "cab": "400200",
    "cap": "80035"
  },
  {
    "key": "9705",
    "ITA": "NOLA-CINQUEVIE",
    "state_code": "NA",
    "cab": "400200",
    "cap": "80035"
  },
  {
    "key": "9706",
    "ITA": "NOLA",
    "state_code": "NA",
    "cab": "400200",
    "cap": "80035"
  },
  {
    "key": "9707",
    "ITA": "NOLA-POLVICA",
    "state_code": "NA",
    "cab": "400200",
    "cap": "80035"
  },
  {
    "key": "9708",
    "ITA": "NOLE",
    "state_code": "TO",
    "cab": "306605",
    "cap": "10076"
  },
  {
    "key": "9709",
    "ITA": "NOLE-SAN GIOVANNI",
    "state_code": "TO",
    "cab": "306605",
    "cap": "10076"
  },
  {
    "key": "9710",
    "ITA": "NOLI",
    "state_code": "SV",
    "cab": "494500",
    "cap": "17026"
  },
  {
    "key": "9711",
    "ITA": "NOMAGLIO",
    "state_code": "TO",
    "cab": "284869",
    "cap": "10010"
  },
  {
    "key": "9712",
    "ITA": "NOMI",
    "state_code": "TN",
    "cab": "351403",
    "cap": "38060"
  },
  {
    "key": "9713",
    "ITA": "NONANTOLA",
    "state_code": "MO",
    "cab": "668905",
    "cap": "41015"
  },
  {
    "key": "9714",
    "ITA": "NONANTOLA-BAGAZZANO",
    "state_code": "MO",
    "cab": "668905",
    "cap": "41015"
  },
  {
    "key": "9715",
    "ITA": "NONANTOLA-CAMPAZZO",
    "state_code": "MO",
    "cab": "668905",
    "cap": "41015"
  },
  {
    "key": "9716",
    "ITA": "NONANTOLA-LA GRANDE",
    "state_code": "MO",
    "cab": "668905",
    "cap": "41015"
  },
  {
    "key": "9717",
    "ITA": "NONANTOLA-REDU'",
    "state_code": "MO",
    "cab": "668905",
    "cap": "41015"
  },
  {
    "key": "9718",
    "ITA": "NONANTOLA-VIA LARGA",
    "state_code": "MO",
    "cab": "668905",
    "cap": "41015"
  },
  {
    "key": "9719",
    "ITA": "NONE",
    "state_code": "TO",
    "cab": "306704",
    "cap": "10060"
  },
  {
    "key": "9720",
    "ITA": "NONIO",
    "state_code": "VB",
    "cab": "917518",
    "cap": "28891"
  },
  {
    "key": "9721",
    "ITA": "NORAGUGUME",
    "state_code": "NU",
    "cab": "965780",
    "cap": "8010"
  },
  {
    "key": "9722",
    "ITA": "NORBELLO-DOMUSNOVAS CANALES",
    "state_code": "OR",
    "cab": "966861",
    "cap": "9070"
  },
  {
    "key": "9723",
    "ITA": "NORBELLO",
    "state_code": "OR",
    "cab": "966861",
    "cap": "9070"
  },
  {
    "key": "9724",
    "ITA": "NORCIA-CASTELLUCCIO",
    "state_code": "PG",
    "cab": "385807",
    "cap": "6046"
  },
  {
    "key": "9725",
    "ITA": "NORCIA-SAN PELLEGRINO DI NORCIA",
    "state_code": "PG",
    "cab": "385807",
    "cap": "6046"
  },
  {
    "key": "9726",
    "ITA": "NORCIA-SAVELLI DI NORCIA",
    "state_code": "PG",
    "cab": "385807",
    "cap": "6046"
  },
  {
    "key": "9727",
    "ITA": "NORCIA-ANCARANO",
    "state_code": "PG",
    "cab": "385807",
    "cap": "6046"
  },
  {
    "key": "9728",
    "ITA": "NORCIA-SERRAVALLE",
    "state_code": "PG",
    "cab": "385807",
    "cap": "6046"
  },
  {
    "key": "9729",
    "ITA": "NORCIA",
    "state_code": "PG",
    "cab": "385807",
    "cap": "6046"
  },
  {
    "key": "9730",
    "ITA": "NORCIA-AGRIANO",
    "state_code": "PG",
    "cab": "385807",
    "cap": "6046"
  },
  {
    "key": "9731",
    "ITA": "NORMA",
    "state_code": "LT",
    "cab": "740506",
    "cap": "4010"
  },
  {
    "key": "9732",
    "ITA": "NOSATE",
    "state_code": "MI",
    "cab": "904011",
    "cap": "20020"
  },
  {
    "key": "9733",
    "ITA": "NOTARESCO-GUARDIA VOMANO",
    "state_code": "TE",
    "cab": "769703",
    "cap": "64024"
  },
  {
    "key": "9734",
    "ITA": "NOTARESCO-PIANURA VOMANO",
    "state_code": "TE",
    "cab": "769703",
    "cap": "64024"
  },
  {
    "key": "9735",
    "ITA": "NOTARESCO",
    "state_code": "TE",
    "cab": "769703",
    "cap": "64024"
  },
  {
    "key": "9736",
    "ITA": "NOTO-SAN PAOLO",
    "state_code": "SR",
    "cab": "847400",
    "cap": "96017"
  },
  {
    "key": "9737",
    "ITA": "NOTO-CASTELLUCCIO",
    "state_code": "SR",
    "cab": "847400",
    "cap": "96017"
  },
  {
    "key": "9738",
    "ITA": "NOTO",
    "state_code": "SR",
    "cab": "847400",
    "cap": "96017"
  },
  {
    "key": "9739",
    "ITA": "NOTO-SAN CORRADO DI FUORI",
    "state_code": "SR",
    "cab": "847400",
    "cap": "96017"
  },
  {
    "key": "9740",
    "ITA": "NOTO-TESTA DELL'ACQUA",
    "state_code": "SR",
    "cab": "847400",
    "cap": "96017"
  },
  {
    "key": "9741",
    "ITA": "NOTO-SANTA LUCIA",
    "state_code": "SR",
    "cab": "847400",
    "cap": "96017"
  },
  {
    "key": "9742",
    "ITA": "NOTO-RIGOLIZIA",
    "state_code": "SR",
    "cab": "847400",
    "cap": "96017"
  },
  {
    "key": "9743",
    "ITA": "NOVA LEVANTE",
    "state_code": "BZ",
    "cab": "586503",
    "cap": "39056"
  },
  {
    "key": "9744",
    "ITA": "NOVA LEVANTE-CAREZZA",
    "state_code": "BZ",
    "cab": "586503",
    "cap": "39056"
  },
  {
    "key": "9745",
    "ITA": "NOVA MILANESE",
    "state_code": "MB",
    "cab": "334607",
    "cap": "20054"
  },
  {
    "key": "9746",
    "ITA": "NOVA PONENTE",
    "state_code": "BZ",
    "cab": "586602",
    "cap": "39050"
  },
  {
    "key": "9747",
    "ITA": "NOVA PONENTE-MONTE SAN PIETRO",
    "state_code": "BZ",
    "cab": "586602",
    "cap": "39050"
  },
  {
    "key": "9748",
    "ITA": "NOVA PONENTE-PONTE NOVA",
    "state_code": "BZ",
    "cab": "586602",
    "cap": "39050"
  },
  {
    "key": "9749",
    "ITA": "NOVA PONENTE-SAN NICOLO' D'EGA",
    "state_code": "BZ",
    "cab": "586602",
    "cap": "39050"
  },
  {
    "key": "9750",
    "ITA": "NOVA SIRI-NOVA SIRI SCALO",
    "state_code": "MT",
    "cab": "803809",
    "cap": "75020"
  },
  {
    "key": "9751",
    "ITA": "NOVA SIRI",
    "state_code": "MT",
    "cab": "803809",
    "cap": "75020"
  },
  {
    "key": "9752",
    "ITA": "NOVAFELTRIA",
    "state_code": "PU",
    "cab": "684605",
    "cap": "61015"
  },
  {
    "key": "9753",
    "ITA": "NOVAFELTRIA-SECCHIANO MARECCHIA",
    "state_code": "PU",
    "cab": "684605",
    "cap": "61015"
  },
  {
    "key": "9754",
    "ITA": "NOVAFELTRIA-PONTE MOLINO BAFFONI",
    "state_code": "PU",
    "cab": "684605",
    "cap": "61015"
  },
  {
    "key": "9755",
    "ITA": "NOVAFELTRIA-PERTICARA",
    "state_code": "PU",
    "cab": "684605",
    "cap": "61015"
  },
  {
    "key": "9756",
    "ITA": "NOVALEDO",
    "state_code": "TN",
    "cab": "351502",
    "cap": "38050"
  },
  {
    "key": "9757",
    "ITA": "NOVALESA",
    "state_code": "TO",
    "cab": "190124",
    "cap": "10050"
  },
  {
    "key": "9758",
    "ITA": "NOVARA-BICOCCA DI NOVARA",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9759",
    "ITA": "NOVARA-CASALGIATE",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9760",
    "ITA": "NOVARA-VIGNALE",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9761",
    "ITA": "NOVARA-OLENGO",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9762",
    "ITA": "NOVARA-PERNATE",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9763",
    "ITA": "NOVARA-SANT'AGABIO",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9764",
    "ITA": "NOVARA-TORRION QUARTARA",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9765",
    "ITA": "NOVARA-VEVERI",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9766",
    "ITA": "NOVARA-AGOGNATE",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9767",
    "ITA": "NOVARA",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9768",
    "ITA": "NOVARA-LUMELLOGNO",
    "state_code": "NO",
    "cab": "101006",
    "cap": "28100"
  },
  {
    "key": "9769",
    "ITA": "NOVARA DI SICILIA-SAN BASILIO DI NOVARA DI SICILIA",
    "state_code": "ME",
    "cab": "823500",
    "cap": "98058"
  },
  {
    "key": "9770",
    "ITA": "NOVARA DI SICILIA",
    "state_code": "ME",
    "cab": "823500",
    "cap": "98058"
  },
  {
    "key": "9771",
    "ITA": "NOVATE MEZZOLA-CAMPO",
    "state_code": "SO",
    "cab": "522409",
    "cap": "23025"
  },
  {
    "key": "9772",
    "ITA": "NOVATE MEZZOLA",
    "state_code": "SO",
    "cab": "522409",
    "cap": "23025"
  },
  {
    "key": "9773",
    "ITA": "NOVATE MILANESE",
    "state_code": "MI",
    "cab": "334706",
    "cap": "20026"
  },
  {
    "key": "9774",
    "ITA": "NOVE",
    "state_code": "VI",
    "cab": "606004",
    "cap": "36055"
  },
  {
    "key": "9775",
    "ITA": "NOVEDRATE",
    "state_code": "CO",
    "cab": "516005",
    "cap": "22060"
  },
  {
    "key": "9776",
    "ITA": "NOVELLARA",
    "state_code": "RE",
    "cab": "664003",
    "cap": "42017"
  },
  {
    "key": "9777",
    "ITA": "NOVELLARA-SAN BERNARDINO",
    "state_code": "RE",
    "cab": "664003",
    "cap": "42017"
  },
  {
    "key": "9778",
    "ITA": "NOVELLARA-VEZZOLA",
    "state_code": "RE",
    "cab": "664003",
    "cap": "42017"
  },
  {
    "key": "9779",
    "ITA": "NOVELLARA-SANTA MARIA",
    "state_code": "RE",
    "cab": "664003",
    "cap": "42017"
  },
  {
    "key": "9780",
    "ITA": "NOVELLARA-SAN GIOVANNI",
    "state_code": "RE",
    "cab": "664003",
    "cap": "42017"
  },
  {
    "key": "9781",
    "ITA": "NOVELLO",
    "state_code": "CN",
    "cab": "665802",
    "cap": "12060"
  },
  {
    "key": "9782",
    "ITA": "NOVENTA DI PIAVE",
    "state_code": "VE",
    "cab": "362202",
    "cap": "30020"
  },
  {
    "key": "9783",
    "ITA": "NOVENTA PADOVANA-OLTRE BRENTA",
    "state_code": "PD",
    "cab": "626903",
    "cap": "35027"
  },
  {
    "key": "9784",
    "ITA": "NOVENTA PADOVANA",
    "state_code": "PD",
    "cab": "626903",
    "cap": "35027"
  },
  {
    "key": "9785",
    "ITA": "NOVENTA VICENTINA",
    "state_code": "VI",
    "cab": "606103",
    "cap": "36025"
  },
  {
    "key": "9786",
    "ITA": "NOVI DI MODENA",
    "state_code": "MO",
    "cab": "669002",
    "cap": "41016"
  },
  {
    "key": "9787",
    "ITA": "NOVI DI MODENA-ROVERETO SULLA SECCHIA",
    "state_code": "MO",
    "cab": "669002",
    "cap": "41016"
  },
  {
    "key": "9788",
    "ITA": "NOVI DI MODENA-SANT'ANTONIO IN MERCADELLO",
    "state_code": "MO",
    "cab": "669002",
    "cap": "41016"
  },
  {
    "key": "9789",
    "ITA": "NOVI LIGURE-MERELLA",
    "state_code": "AL",
    "cab": "484204",
    "cap": "15067"
  },
  {
    "key": "9790",
    "ITA": "NOVI LIGURE",
    "state_code": "AL",
    "cab": "484204",
    "cap": "15067"
  },
  {
    "key": "9791",
    "ITA": "NOVI VELIA",
    "state_code": "SA",
    "cab": "742205",
    "cap": "84060"
  },
  {
    "key": "9792",
    "ITA": "NOVIGLIO-SANTA CORINNA",
    "state_code": "MI",
    "cab": "189340",
    "cap": "20082"
  },
  {
    "key": "9793",
    "ITA": "NOVIGLIO",
    "state_code": "MI",
    "cab": "189340",
    "cap": "20082"
  },
  {
    "key": "9794",
    "ITA": "NOVOLI",
    "state_code": "LE",
    "cab": "798504",
    "cap": "73051"
  },
  {
    "key": "9795",
    "ITA": "NOVOLI-VILLA CONVENTO",
    "state_code": "LE",
    "cab": "798504",
    "cap": "73051"
  },
  {
    "key": "9796",
    "ITA": "NUCETTO",
    "state_code": "CN",
    "cab": "919068",
    "cap": "12070"
  },
  {
    "key": "9797",
    "ITA": "NUGHEDU SAN NICOLO'",
    "state_code": "SS",
    "cab": "874701",
    "cap": "7010"
  },
  {
    "key": "9798",
    "ITA": "NUGHEDU SANTA VITTORIA",
    "state_code": "OR",
    "cab": "966879",
    "cap": "9080"
  },
  {
    "key": "9799",
    "ITA": "NULE",
    "state_code": "SS",
    "cab": "874800",
    "cap": "7010"
  },
  {
    "key": "9800",
    "ITA": "NULVI",
    "state_code": "SS",
    "cab": "849703",
    "cap": "7032"
  },
  {
    "key": "9801",
    "ITA": "NUMANA-NUMANA LIDO",
    "state_code": "AN",
    "cab": "374702",
    "cap": "60026"
  },
  {
    "key": "9802",
    "ITA": "NUMANA-MARCELLI",
    "state_code": "AN",
    "cab": "374702",
    "cap": "60026"
  },
  {
    "key": "9803",
    "ITA": "NUMANA",
    "state_code": "AN",
    "cab": "374702",
    "cap": "60026"
  },
  {
    "key": "9804",
    "ITA": "NUORO",
    "state_code": "NU",
    "cab": "173005",
    "cap": "8100"
  },
  {
    "key": "9805",
    "ITA": "NUORO-LOLLOVE",
    "state_code": "NU",
    "cab": "173005",
    "cap": "8100"
  },
  {
    "key": "9806",
    "ITA": "NUORO-MANASUDDAS",
    "state_code": "NU",
    "cab": "173005",
    "cap": "8100"
  },
  {
    "key": "9807",
    "ITA": "NUORO-MONTE ORTOBENE",
    "state_code": "NU",
    "cab": "173005",
    "cap": "8100"
  },
  {
    "key": "9808",
    "ITA": "NURACHI",
    "state_code": "OR",
    "cab": "879007",
    "cap": "9070"
  },
  {
    "key": "9809",
    "ITA": "NURAGUS",
    "state_code": "CA",
    "cab": "867507",
    "cap": "8030"
  },
  {
    "key": "9810",
    "ITA": "NURAGUS-LIXIUS",
    "state_code": "CA",
    "cab": "867507",
    "cap": "8030"
  },
  {
    "key": "9811",
    "ITA": "NURALLAO",
    "state_code": "CA",
    "cab": "867606",
    "cap": "8030"
  },
  {
    "key": "9812",
    "ITA": "NURAMINIS",
    "state_code": "CA",
    "cab": "860502",
    "cap": "9024"
  },
  {
    "key": "9813",
    "ITA": "NURAMINIS-VILLAGRECA",
    "state_code": "CA",
    "cab": "860502",
    "cap": "9024"
  },
  {
    "key": "9814",
    "ITA": "NURECI",
    "state_code": "OR",
    "cab": "879106",
    "cap": "9080"
  },
  {
    "key": "9815",
    "ITA": "NURRI",
    "state_code": "CA",
    "cab": "867705",
    "cap": "8035"
  },
  {
    "key": "9816",
    "ITA": "NUS",
    "state_code": "AO",
    "cab": "316307",
    "cap": "11020"
  },
  {
    "key": "9817",
    "ITA": "NUS-SAINT BARTHELEMY",
    "state_code": "AO",
    "cab": "316307",
    "cap": "11020"
  },
  {
    "key": "9818",
    "ITA": "NUS-BLAVY",
    "state_code": "AO",
    "cab": "316307",
    "cap": "11020"
  },
  {
    "key": "9819",
    "ITA": "NUSCO-PONTEROMITO",
    "state_code": "AV",
    "cab": "760108",
    "cap": "83051"
  },
  {
    "key": "9820",
    "ITA": "NUSCO",
    "state_code": "AV",
    "cab": "760108",
    "cap": "83051"
  },
  {
    "key": "9821",
    "ITA": "NUVOLENTO",
    "state_code": "BS",
    "cab": "548107",
    "cap": "25080"
  },
  {
    "key": "9822",
    "ITA": "NUVOLERA",
    "state_code": "BS",
    "cab": "548206",
    "cap": "25080"
  },
  {
    "key": "9823",
    "ITA": "NUXIS",
    "state_code": "SU",
    "cab": "860601",
    "cap": "9010"
  },
  {
    "key": "9824",
    "ITA": "OCCHIEPPO INFERIORE",
    "state_code": "BI",
    "cab": "446104",
    "cap": "13897"
  },
  {
    "key": "9825",
    "ITA": "OCCHIEPPO SUPERIORE",
    "state_code": "BI",
    "cab": "318006",
    "cap": "13898"
  },
  {
    "key": "9826",
    "ITA": "OCCHIOBELLO-SANTA MARIA MADDALENA",
    "state_code": "RO",
    "cab": "633800",
    "cap": "45030"
  },
  {
    "key": "9827",
    "ITA": "OCCHIOBELLO",
    "state_code": "RO",
    "cab": "633800",
    "cap": "45030"
  },
  {
    "key": "9828",
    "ITA": "OCCIMIANO",
    "state_code": "AL",
    "cab": "484303",
    "cap": "15040"
  },
  {
    "key": "9829",
    "ITA": "OCRE",
    "state_code": "AQ",
    "cab": "909770",
    "cap": "67040"
  },
  {
    "key": "9830",
    "ITA": "OCRE-SAN MARTINO D'OCRE",
    "state_code": "AQ",
    "cab": "909770",
    "cap": "67040"
  },
  {
    "key": "9831",
    "ITA": "ODALENGO GRANDE",
    "state_code": "AL",
    "cab": "921437",
    "cap": "15020"
  },
  {
    "key": "9832",
    "ITA": "ODALENGO PICCOLO",
    "state_code": "AL",
    "cab": "921445",
    "cap": "15020"
  },
  {
    "key": "9833",
    "ITA": "ODERZO",
    "state_code": "TV",
    "cab": "618603",
    "cap": "31046"
  },
  {
    "key": "9834",
    "ITA": "ODERZO-FAE'",
    "state_code": "TV",
    "cab": "618603",
    "cap": "31046"
  },
  {
    "key": "9835",
    "ITA": "ODERZO-PIAVON",
    "state_code": "TV",
    "cab": "618603",
    "cap": "31046"
  },
  {
    "key": "9836",
    "ITA": "ODERZO-RUSTIGNE'",
    "state_code": "TV",
    "cab": "618603",
    "cap": "31046"
  },
  {
    "key": "9837",
    "ITA": "ODOLO",
    "state_code": "BS",
    "cab": "548305",
    "cap": "25076"
  },
  {
    "key": "9838",
    "ITA": "OFENA",
    "state_code": "AQ",
    "cab": "406306",
    "cap": "67025"
  },
  {
    "key": "9839",
    "ITA": "OFFAGNA",
    "state_code": "AN",
    "cab": "374801",
    "cap": "60020"
  },
  {
    "key": "9840",
    "ITA": "OFFANENGO",
    "state_code": "CR",
    "cab": "569400",
    "cap": "26010"
  },
  {
    "key": "9841",
    "ITA": "OFFIDA",
    "state_code": "AP",
    "cab": "696203",
    "cap": "63035"
  },
  {
    "key": "9842",
    "ITA": "OFFLAGA",
    "state_code": "BS",
    "cab": "548404",
    "cap": "25020"
  },
  {
    "key": "9843",
    "ITA": "OFFLAGA-CIGNANO",
    "state_code": "BS",
    "cab": "548404",
    "cap": "25020"
  },
  {
    "key": "9844",
    "ITA": "OFFLAGA-FAVERZANO",
    "state_code": "BS",
    "cab": "548404",
    "cap": "25020"
  },
  {
    "key": "9845",
    "ITA": "OGGEBBIO",
    "state_code": "VB",
    "cab": "917526",
    "cap": "28824"
  },
  {
    "key": "9846",
    "ITA": "OGGEBBIO-GONTE",
    "state_code": "VB",
    "cab": "917526",
    "cap": "28824"
  },
  {
    "key": "9847",
    "ITA": "OGGIONA CON SANTO STEFANO-SANTO STEFANO",
    "state_code": "VA",
    "cab": "504605",
    "cap": "21040"
  },
  {
    "key": "9848",
    "ITA": "OGGIONA CON SANTO STEFANO-OGGIONA",
    "state_code": "VA",
    "cab": "504605",
    "cap": "21040"
  },
  {
    "key": "9849",
    "ITA": "OGGIONA CON SANTO STEFANO",
    "state_code": "VA",
    "cab": "504605",
    "cap": "21040"
  },
  {
    "key": "9850",
    "ITA": "OGGIONO",
    "state_code": "LC",
    "cab": "516104",
    "cap": "23848"
  },
  {
    "key": "9851",
    "ITA": "OGLIANICO",
    "state_code": "TO",
    "cab": "284026",
    "cap": "10080"
  },
  {
    "key": "9852",
    "ITA": "OGLIASTRO CILENTO-FINOCCHITO",
    "state_code": "SA",
    "cab": "409300",
    "cap": "84061"
  },
  {
    "key": "9853",
    "ITA": "OGLIASTRO CILENTO-EREDITA",
    "state_code": "SA",
    "cab": "409300",
    "cap": "84061"
  },
  {
    "key": "9854",
    "ITA": "OGLIASTRO CILENTO",
    "state_code": "SA",
    "cab": "409300",
    "cap": "84061"
  },
  {
    "key": "9855",
    "ITA": "OLBIA",
    "state_code": "OT",
    "cab": "849802",
    "cap": "7026"
  },
  {
    "key": "9856",
    "ITA": "OLBIA-BERCHIDDEDDU",
    "state_code": "OT",
    "cab": "849802",
    "cap": "7026"
  },
  {
    "key": "9857",
    "ITA": "OLBIA-PORTO ROTONDO",
    "state_code": "OT",
    "cab": "849802",
    "cap": "7026"
  },
  {
    "key": "9858",
    "ITA": "OLBIA-SAN PANTALEO",
    "state_code": "OT",
    "cab": "849802",
    "cap": "7026"
  },
  {
    "key": "9859",
    "ITA": "OLCENENGO",
    "state_code": "VC",
    "cab": "446203",
    "cap": "13047"
  },
  {
    "key": "9860",
    "ITA": "OLDENICO",
    "state_code": "VC",
    "cab": "916072",
    "cap": "13030"
  },
  {
    "key": "9861",
    "ITA": "OLEGGIO",
    "state_code": "NO",
    "cab": "455402",
    "cap": "28047"
  },
  {
    "key": "9862",
    "ITA": "OLEGGIO-FORNACI",
    "state_code": "NO",
    "cab": "455402",
    "cap": "28047"
  },
  {
    "key": "9863",
    "ITA": "OLEGGIO CASTELLO",
    "state_code": "NO",
    "cab": "458000",
    "cap": "28040"
  },
  {
    "key": "9864",
    "ITA": "OLEVANO DI LOMELLINA",
    "state_code": "PV",
    "cab": "930669",
    "cap": "27020"
  },
  {
    "key": "9865",
    "ITA": "OLEVANO ROMANO",
    "state_code": "RM",
    "cab": "393108",
    "cap": "35"
  },
  {
    "key": "9866",
    "ITA": "OLEVANO SUL TUSCIANO-MONTICELLI",
    "state_code": "SA",
    "cab": "762906",
    "cap": "84062"
  },
  {
    "key": "9867",
    "ITA": "OLEVANO SUL TUSCIANO-ARIANO",
    "state_code": "SA",
    "cab": "762906",
    "cap": "84062"
  },
  {
    "key": "9868",
    "ITA": "OLEVANO SUL TUSCIANO",
    "state_code": "SA",
    "cab": "762906",
    "cap": "84062"
  },
  {
    "key": "9869",
    "ITA": "OLEVANO SUL TUSCIANO-SALITTO",
    "state_code": "SA",
    "cab": "762906",
    "cap": "84062"
  },
  {
    "key": "9870",
    "ITA": "OLEVANO SUL TUSCIANO-VALLE",
    "state_code": "SA",
    "cab": "762906",
    "cap": "84062"
  },
  {
    "key": "9871",
    "ITA": "OLGIATE COMASCO",
    "state_code": "CO",
    "cab": "516203",
    "cap": "22077"
  },
  {
    "key": "9872",
    "ITA": "OLGIATE MOLGORA-MONTICELLO",
    "state_code": "LC",
    "cab": "516302",
    "cap": "23887"
  },
  {
    "key": "9873",
    "ITA": "OLGIATE MOLGORA-CANOVA",
    "state_code": "LC",
    "cab": "516302",
    "cap": "23887"
  },
  {
    "key": "9874",
    "ITA": "OLGIATE MOLGORA",
    "state_code": "LC",
    "cab": "516302",
    "cap": "23887"
  },
  {
    "key": "9875",
    "ITA": "OLGIATE OLONA",
    "state_code": "VA",
    "cab": "504704",
    "cap": "21057"
  },
  {
    "key": "9876",
    "ITA": "OLGIATE OLONA-CASCINA BUON GESU'",
    "state_code": "VA",
    "cab": "504704",
    "cap": "21057"
  },
  {
    "key": "9877",
    "ITA": "OLGINATE",
    "state_code": "LC",
    "cab": "516401",
    "cap": "23854"
  },
  {
    "key": "9878",
    "ITA": "OLIENA-SU COLOGONE",
    "state_code": "NU",
    "cab": "867804",
    "cap": "8025"
  },
  {
    "key": "9879",
    "ITA": "OLIENA",
    "state_code": "NU",
    "cab": "867804",
    "cap": "8025"
  },
  {
    "key": "9880",
    "ITA": "OLIVA GESSI",
    "state_code": "PV",
    "cab": "930677",
    "cap": "27050"
  },
  {
    "key": "9881",
    "ITA": "OLIVADI",
    "state_code": "CZ",
    "cab": "913228",
    "cap": "88067"
  },
  {
    "key": "9882",
    "ITA": "OLIVERI",
    "state_code": "ME",
    "cab": "823609",
    "cap": "98060"
  },
  {
    "key": "9883",
    "ITA": "OLIVETO CITRA",
    "state_code": "SA",
    "cab": "767004",
    "cap": "84020"
  },
  {
    "key": "9884",
    "ITA": "OLIVETO LARIO-LIMONTA",
    "state_code": "LC",
    "cab": "925909",
    "cap": "23865"
  },
  {
    "key": "9885",
    "ITA": "OLIVETO LARIO",
    "state_code": "LC",
    "cab": "925909",
    "cap": "23865"
  },
  {
    "key": "9886",
    "ITA": "OLIVETO LARIO-ONNO",
    "state_code": "LC",
    "cab": "925909",
    "cap": "23865"
  },
  {
    "key": "9887",
    "ITA": "OLIVETO LUCANO",
    "state_code": "MT",
    "cab": "958165",
    "cap": "75010"
  },
  {
    "key": "9888",
    "ITA": "OLIVETTA SAN MICHELE",
    "state_code": "IM",
    "cab": "922500",
    "cap": "18030"
  },
  {
    "key": "9889",
    "ITA": "OLIVOLA",
    "state_code": "AL",
    "cab": "921452",
    "cap": "15030"
  },
  {
    "key": "9890",
    "ITA": "OLLASTRA",
    "state_code": "OR",
    "cab": "879205",
    "cap": "9088"
  },
  {
    "key": "9891",
    "ITA": "OLLOLAI",
    "state_code": "NU",
    "cab": "867903",
    "cap": "8020"
  },
  {
    "key": "9892",
    "ITA": "OLLOMONT",
    "state_code": "AO",
    "cab": "902502",
    "cap": "11010"
  },
  {
    "key": "9893",
    "ITA": "OLMEDO",
    "state_code": "SS",
    "cab": "874909",
    "cap": "7040"
  },
  {
    "key": "9894",
    "ITA": "OLMENETA",
    "state_code": "CR",
    "cab": "323501",
    "cap": "26010"
  },
  {
    "key": "9895",
    "ITA": "OLMO AL BREMBO",
    "state_code": "BG",
    "cab": "532606",
    "cap": "24010"
  },
  {
    "key": "9896",
    "ITA": "OLMO GENTILE",
    "state_code": "AT",
    "cab": "920314",
    "cap": "14050"
  },
  {
    "key": "9897",
    "ITA": "OLTRE IL COLLE",
    "state_code": "BG",
    "cab": "532705",
    "cap": "24013"
  },
  {
    "key": "9898",
    "ITA": "OLTRESSENDA ALTA",
    "state_code": "BG",
    "cab": "928341",
    "cap": "24020"
  },
  {
    "key": "9899",
    "ITA": "OLTRONA DI SAN MAMETTE",
    "state_code": "CO",
    "cab": "496000",
    "cap": "22070"
  },
  {
    "key": "9900",
    "ITA": "OLZAI",
    "state_code": "NU",
    "cab": "868000",
    "cap": "8020"
  },
  {
    "key": "9901",
    "ITA": "OME",
    "state_code": "BS",
    "cab": "555003",
    "cap": "25050"
  },
  {
    "key": "9902",
    "ITA": "OMEGNA",
    "state_code": "VB",
    "cab": "455501",
    "cap": "28887"
  },
  {
    "key": "9903",
    "ITA": "OMEGNA-AGRANO",
    "state_code": "VB",
    "cab": "455501",
    "cap": "28887"
  },
  {
    "key": "9904",
    "ITA": "OMEGNA-BAGNELLA",
    "state_code": "VB",
    "cab": "455501",
    "cap": "28887"
  },
  {
    "key": "9905",
    "ITA": "OMEGNA-CIREGGIO",
    "state_code": "VB",
    "cab": "455501",
    "cap": "28887"
  },
  {
    "key": "9906",
    "ITA": "OMEGNA-CRUSINALLO",
    "state_code": "VB",
    "cab": "455501",
    "cap": "28887"
  },
  {
    "key": "9907",
    "ITA": "OMIGNANO",
    "state_code": "SA",
    "cab": "766709",
    "cap": "84060"
  },
  {
    "key": "9908",
    "ITA": "OMIGNANO-OMIGNANO SCALO",
    "state_code": "SA",
    "cab": "766709",
    "cap": "84060"
  },
  {
    "key": "9909",
    "ITA": "ONANI",
    "state_code": "NU",
    "cab": "868109",
    "cap": "8020"
  },
  {
    "key": "9910",
    "ITA": "ONANI-MAMONE",
    "state_code": "NU",
    "cab": "868109",
    "cap": "8020"
  },
  {
    "key": "9911",
    "ITA": "ONANO",
    "state_code": "VT",
    "cab": "732008",
    "cap": "1010"
  },
  {
    "key": "9912",
    "ITA": "ONCINO",
    "state_code": "CN",
    "cab": "919076",
    "cap": "12030"
  },
  {
    "key": "9913",
    "ITA": "ONETA",
    "state_code": "BG",
    "cab": "812404",
    "cap": "24020"
  },
  {
    "key": "9914",
    "ITA": "ONIFAI",
    "state_code": "NU",
    "cab": "965863",
    "cap": "8020"
  },
  {
    "key": "9915",
    "ITA": "ONIFERI",
    "state_code": "NU",
    "cab": "868208",
    "cap": "8020"
  },
  {
    "key": "9916",
    "ITA": "ONO SAN PIETRO",
    "state_code": "BS",
    "cab": "929554",
    "cap": "25040"
  },
  {
    "key": "9917",
    "ITA": "ONORE",
    "state_code": "BG",
    "cab": "928366",
    "cap": "24020"
  },
  {
    "key": "9918",
    "ITA": "ONZO",
    "state_code": "SV",
    "cab": "923201",
    "cap": "17037"
  },
  {
    "key": "9919",
    "ITA": "OPERA",
    "state_code": "MI",
    "cab": "334805",
    "cap": "20090"
  },
  {
    "key": "9920",
    "ITA": "OPERA-NOVERASCO",
    "state_code": "MI",
    "cab": "334805",
    "cap": "20090"
  },
  {
    "key": "9921",
    "ITA": "OPERA-SPORTING MIRASOLE",
    "state_code": "MI",
    "cab": "334805",
    "cap": "20090"
  },
  {
    "key": "9922",
    "ITA": "OPI",
    "state_code": "AQ",
    "cab": "408500",
    "cap": "67030"
  },
  {
    "key": "9923",
    "ITA": "OPPEANO",
    "state_code": "VR",
    "cab": "596304",
    "cap": "37050"
  },
  {
    "key": "9924",
    "ITA": "OPPEANO-VALLESE",
    "state_code": "VR",
    "cab": "596304",
    "cap": "37050"
  },
  {
    "key": "9925",
    "ITA": "OPPEANO-CA' DEGLI OPPI",
    "state_code": "VR",
    "cab": "596304",
    "cap": "37050"
  },
  {
    "key": "9926",
    "ITA": "OPPIDO LUCANO",
    "state_code": "PZ",
    "cab": "421404",
    "cap": "85015"
  },
  {
    "key": "9927",
    "ITA": "OPPIDO MAMERTINA",
    "state_code": "RC",
    "cab": "814806",
    "cap": "89014"
  },
  {
    "key": "9928",
    "ITA": "OPPIDO MAMERTINA-CASTELLACE",
    "state_code": "RC",
    "cab": "814806",
    "cap": "89014"
  },
  {
    "key": "9929",
    "ITA": "OPPIDO MAMERTINA-ZURGONADIO",
    "state_code": "RC",
    "cab": "814806",
    "cap": "89014"
  },
  {
    "key": "9930",
    "ITA": "OPPIDO MAMERTINA-PIMINORO",
    "state_code": "RC",
    "cab": "814806",
    "cap": "89014"
  },
  {
    "key": "9931",
    "ITA": "OPPIDO MAMERTINA-TRESILICO",
    "state_code": "RC",
    "cab": "814806",
    "cap": "89014"
  },
  {
    "key": "9932",
    "ITA": "OPPIDO MAMERTINA-MESSIGNADI",
    "state_code": "RC",
    "cab": "814806",
    "cap": "89014"
  },
  {
    "key": "9933",
    "ITA": "ORA",
    "state_code": "BZ",
    "cab": "586701",
    "cap": "39040"
  },
  {
    "key": "9934",
    "ITA": "ORANI",
    "state_code": "NU",
    "cab": "868307",
    "cap": "8026"
  },
  {
    "key": "9935",
    "ITA": "ORATINO",
    "state_code": "CB",
    "cab": "910778",
    "cap": "86010"
  },
  {
    "key": "9936",
    "ITA": "ORBASSANO",
    "state_code": "TO",
    "cab": "306803",
    "cap": "10043"
  },
  {
    "key": "9937",
    "ITA": "ORBETELLO-FONTEBLANDA",
    "state_code": "GR",
    "cab": "723205",
    "cap": "58010"
  },
  {
    "key": "9938",
    "ITA": "ORBETELLO-ALBINIA",
    "state_code": "GR",
    "cab": "723205",
    "cap": "58010"
  },
  {
    "key": "9939",
    "ITA": "ORBETELLO-TALAMONE",
    "state_code": "GR",
    "cab": "723205",
    "cap": "58010"
  },
  {
    "key": "9940",
    "ITA": "ORBETELLO",
    "state_code": "GR",
    "cab": "723205",
    "cap": "58015"
  },
  {
    "key": "9941",
    "ITA": "ORBETELLO-SANTA LIBERATA",
    "state_code": "GR",
    "cab": "723205",
    "cap": "58010"
  },
  {
    "key": "9942",
    "ITA": "ORBETELLO-POLVEROSA",
    "state_code": "GR",
    "cab": "723205",
    "cap": "58010"
  },
  {
    "key": "9943",
    "ITA": "ORBETELLO-ORBETELLO SCALO",
    "state_code": "GR",
    "cab": "723205",
    "cap": "58015"
  },
  {
    "key": "9944",
    "ITA": "ORCIANO DI PESARO",
    "state_code": "PU",
    "cab": "684704",
    "cap": "61038"
  },
  {
    "key": "9945",
    "ITA": "ORCIANO PISANO",
    "state_code": "PI",
    "cab": "524504",
    "cap": "56040"
  },
  {
    "key": "9946",
    "ITA": "ORCO FEGLINO-FEGLINO",
    "state_code": "SV",
    "cab": "923219",
    "cap": "17024"
  },
  {
    "key": "9947",
    "ITA": "ORCO FEGLINO",
    "state_code": "SV",
    "cab": "923219",
    "cap": "17024"
  },
  {
    "key": "9948",
    "ITA": "ORDONA",
    "state_code": "FG",
    "cab": "886705",
    "cap": "71040"
  },
  {
    "key": "9949",
    "ITA": "ORERO",
    "state_code": "GE",
    "cab": "903245",
    "cap": "16040"
  },
  {
    "key": "9950",
    "ITA": "ORERO-PIAN DEI RATTI",
    "state_code": "GE",
    "cab": "903245",
    "cap": "16040"
  },
  {
    "key": "9951",
    "ITA": "ORERO-ISOLONA",
    "state_code": "GE",
    "cab": "903245",
    "cap": "16040"
  },
  {
    "key": "9952",
    "ITA": "ORGIANO",
    "state_code": "VI",
    "cab": "606202",
    "cap": "36040"
  },
  {
    "key": "9953",
    "ITA": "ORGOSOLO",
    "state_code": "NU",
    "cab": "853507",
    "cap": "8027"
  },
  {
    "key": "9954",
    "ITA": "ORIA-SAN COSIMO",
    "state_code": "BR",
    "cab": "792200",
    "cap": "72024"
  },
  {
    "key": "9955",
    "ITA": "ORIA",
    "state_code": "BR",
    "cab": "792200",
    "cap": "72024"
  },
  {
    "key": "9956",
    "ITA": "ORICOLA",
    "state_code": "AQ",
    "cab": "909796",
    "cap": "67063"
  },
  {
    "key": "9957",
    "ITA": "ORICOLA-CIVITA",
    "state_code": "AQ",
    "cab": "909796",
    "cap": "67063"
  },
  {
    "key": "9958",
    "ITA": "ORIGGIO",
    "state_code": "VA",
    "cab": "504803",
    "cap": "21040"
  },
  {
    "key": "9959",
    "ITA": "ORINO",
    "state_code": "VA",
    "cab": "924662",
    "cap": "21030"
  },
  {
    "key": "9960",
    "ITA": "ORIO AL SERIO",
    "state_code": "BG",
    "cab": "532804",
    "cap": "24050"
  },
  {
    "key": "9961",
    "ITA": "ORIO CANAVESE",
    "state_code": "TO",
    "cab": "284059",
    "cap": "10010"
  },
  {
    "key": "9962",
    "ITA": "ORIO LITTA",
    "state_code": "LO",
    "cab": "334904",
    "cap": "26863"
  },
  {
    "key": "9963",
    "ITA": "ORIOLO",
    "state_code": "CS",
    "cab": "808402",
    "cap": "87073"
  },
  {
    "key": "9964",
    "ITA": "ORIOLO ROMANO",
    "state_code": "VT",
    "cab": "732107",
    "cap": "1010"
  },
  {
    "key": "9965",
    "ITA": "ORISTANO",
    "state_code": "OR",
    "cab": "174003",
    "cap": "9170"
  },
  {
    "key": "9966",
    "ITA": "ORISTANO-DONIGALA FENUGHEDU",
    "state_code": "OR",
    "cab": "174003",
    "cap": "9170"
  },
  {
    "key": "9967",
    "ITA": "ORISTANO-MASSAMA",
    "state_code": "OR",
    "cab": "174003",
    "cap": "9170"
  },
  {
    "key": "9968",
    "ITA": "ORISTANO-NURAXINIEDDU",
    "state_code": "OR",
    "cab": "174003",
    "cap": "9170"
  },
  {
    "key": "9969",
    "ITA": "ORISTANO-SILI'",
    "state_code": "OR",
    "cab": "174003",
    "cap": "9170"
  },
  {
    "key": "9970",
    "ITA": "ORISTANO-TORRE GRANDE",
    "state_code": "OR",
    "cab": "174003",
    "cap": "9170"
  },
  {
    "key": "9971",
    "ITA": "ORMEA",
    "state_code": "CN",
    "cab": "465807",
    "cap": "12078"
  },
  {
    "key": "9972",
    "ITA": "ORMEA-PONTE DI NAVA",
    "state_code": "CN",
    "cab": "465807",
    "cap": "12078"
  },
  {
    "key": "9973",
    "ITA": "ORMELLE",
    "state_code": "TV",
    "cab": "618702",
    "cap": "31024"
  },
  {
    "key": "9974",
    "ITA": "ORMELLE-RONCADELLE",
    "state_code": "TV",
    "cab": "618702",
    "cap": "31024"
  },
  {
    "key": "9975",
    "ITA": "ORNAGO",
    "state_code": "MB",
    "cab": "894303",
    "cap": "20060"
  },
  {
    "key": "9976",
    "ITA": "ORNAVASSO",
    "state_code": "VB",
    "cab": "455600",
    "cap": "28877"
  },
  {
    "key": "9977",
    "ITA": "ORNICA",
    "state_code": "BG",
    "cab": "928374",
    "cap": "24010"
  },
  {
    "key": "9978",
    "ITA": "OROSEI-SOS ALINOS",
    "state_code": "NU",
    "cab": "854000",
    "cap": "8028"
  },
  {
    "key": "9979",
    "ITA": "OROSEI-SAS LINNAS SICCAS",
    "state_code": "NU",
    "cab": "854000",
    "cap": "8028"
  },
  {
    "key": "9980",
    "ITA": "OROSEI-CALA LIBEROTTO",
    "state_code": "NU",
    "cab": "854000",
    "cap": "8028"
  },
  {
    "key": "9981",
    "ITA": "OROSEI",
    "state_code": "NU",
    "cab": "854000",
    "cap": "8028"
  },
  {
    "key": "9982",
    "ITA": "OROSEI-CALA GINEPRO",
    "state_code": "NU",
    "cab": "854000",
    "cap": "8028"
  },
  {
    "key": "9983",
    "ITA": "OROTELLI",
    "state_code": "NU",
    "cab": "868406",
    "cap": "8020"
  },
  {
    "key": "9984",
    "ITA": "ORRIA",
    "state_code": "SA",
    "cab": "952762",
    "cap": "84060"
  },
  {
    "key": "9985",
    "ITA": "ORRIA-PIANO VETRALE",
    "state_code": "SA",
    "cab": "952762",
    "cap": "84060"
  },
  {
    "key": "9986",
    "ITA": "ORROLI",
    "state_code": "CA",
    "cab": "868505",
    "cap": "8030"
  },
  {
    "key": "9987",
    "ITA": "ORSAGO",
    "state_code": "TV",
    "cab": "618801",
    "cap": "31010"
  },
  {
    "key": "9988",
    "ITA": "ORSARA BORMIDA",
    "state_code": "AL",
    "cab": "921460",
    "cap": "15010"
  },
  {
    "key": "9989",
    "ITA": "ORSARA DI PUGLIA",
    "state_code": "FG",
    "cab": "785006",
    "cap": "71027"
  },
  {
    "key": "9990",
    "ITA": "ORSENIGO",
    "state_code": "CO",
    "cab": "382101",
    "cap": "22030"
  },
  {
    "key": "9991",
    "ITA": "ORSOGNA",
    "state_code": "CH",
    "cab": "777706",
    "cap": "66036"
  },
  {
    "key": "9992",
    "ITA": "ORSOMARSO",
    "state_code": "CS",
    "cab": "959098",
    "cap": "87020"
  },
  {
    "key": "9993",
    "ITA": "ORTA DI ATELLA",
    "state_code": "CE",
    "cab": "749309",
    "cap": "81030"
  },
  {
    "key": "9994",
    "ITA": "ORTA NOVA",
    "state_code": "FG",
    "cab": "785105",
    "cap": "71045"
  },
  {
    "key": "9995",
    "ITA": "ORTA SAN GIULIO",
    "state_code": "NO",
    "cab": "455709",
    "cap": "28016"
  },
  {
    "key": "9996",
    "ITA": "ORTA SAN GIULIO-ISOLA SAN GIULIO",
    "state_code": "NO",
    "cab": "455709",
    "cap": "28016"
  },
  {
    "key": "9997",
    "ITA": "ORTA SAN GIULIO-ORTA NOVARESE",
    "state_code": "NO",
    "cab": "455709",
    "cap": "28016"
  },
  {
    "key": "9998",
    "ITA": "ORTACESUS",
    "state_code": "CA",
    "cab": "860700",
    "cap": "9040"
  },
  {
    "key": "9999",
    "ITA": "ORTE",
    "state_code": "VT",
    "cab": "732206",
    "cap": "1028"
  },
  {
    "key": "10000",
    "ITA": "ORTE-ORTE SCALO",
    "state_code": "VT",
    "cab": "732206",
    "cap": "1028"
  },
  {
    "key": "10001",
    "ITA": "ORTELLE-VIGNACASTRISI",
    "state_code": "LE",
    "cab": "957688",
    "cap": "73030"
  },
  {
    "key": "10002",
    "ITA": "ORTELLE",
    "state_code": "LE",
    "cab": "957688",
    "cap": "73030"
  },
  {
    "key": "10003",
    "ITA": "ORTEZZANO",
    "state_code": "FM",
    "cab": "696302",
    "cap": "63020"
  },
  {
    "key": "10004",
    "ITA": "ORTIGNANO RAGGIOLO-ORTIGNANO",
    "state_code": "AR",
    "cab": "945105",
    "cap": "52010"
  },
  {
    "key": "10005",
    "ITA": "ORTIGNANO RAGGIOLO-RAGGIOLO",
    "state_code": "AR",
    "cab": "945105",
    "cap": "52010"
  },
  {
    "key": "10006",
    "ITA": "ORTIGNANO RAGGIOLO",
    "state_code": "AR",
    "cab": "945105",
    "cap": "52010"
  },
  {
    "key": "10007",
    "ITA": "ORTISEI",
    "state_code": "BZ",
    "cab": "586800",
    "cap": "39046"
  },
  {
    "key": "10008",
    "ITA": "ORTONA-VILLA GRANDE",
    "state_code": "CH",
    "cab": "777805",
    "cap": "66026"
  },
  {
    "key": "10009",
    "ITA": "ORTONA-VILLA SAN LEONARDO",
    "state_code": "CH",
    "cab": "777805",
    "cap": "66026"
  },
  {
    "key": "10010",
    "ITA": "ORTONA-VILLA SAN NICOLA",
    "state_code": "CH",
    "cab": "777805",
    "cap": "66026"
  },
  {
    "key": "10011",
    "ITA": "ORTONA",
    "state_code": "CH",
    "cab": "777805",
    "cap": "66026"
  },
  {
    "key": "10012",
    "ITA": "ORTONA-ORTONA FORO",
    "state_code": "CH",
    "cab": "777805",
    "cap": "66026"
  },
  {
    "key": "10013",
    "ITA": "ORTONA-ORTONA PORTO",
    "state_code": "CH",
    "cab": "777805",
    "cap": "66026"
  },
  {
    "key": "10014",
    "ITA": "ORTONA-VILLA CALDARI",
    "state_code": "CH",
    "cab": "777805",
    "cap": "66026"
  },
  {
    "key": "10015",
    "ITA": "ORTONA DEI MARSI",
    "state_code": "AQ",
    "cab": "909804",
    "cap": "67050"
  },
  {
    "key": "10016",
    "ITA": "ORTONA DEI MARSI-CARRITO",
    "state_code": "AQ",
    "cab": "909804",
    "cap": "67050"
  },
  {
    "key": "10017",
    "ITA": "ORTONOVO",
    "state_code": "SP",
    "cab": "498006",
    "cap": "19034"
  },
  {
    "key": "10018",
    "ITA": "ORTONOVO-CASANO",
    "state_code": "SP",
    "cab": "498006",
    "cap": "19034"
  },
  {
    "key": "10019",
    "ITA": "ORTONOVO-DOGANA",
    "state_code": "SP",
    "cab": "498006",
    "cap": "19034"
  },
  {
    "key": "10020",
    "ITA": "ORTONOVO-SERRAVALLE",
    "state_code": "SP",
    "cab": "498006",
    "cap": "19034"
  },
  {
    "key": "10021",
    "ITA": "ORTONOVO-LUNI MARE",
    "state_code": "SP",
    "cab": "498006",
    "cap": "19034"
  },
  {
    "key": "10022",
    "ITA": "ORTONOVO-NICOLA",
    "state_code": "SP",
    "cab": "498006",
    "cap": "19034"
  },
  {
    "key": "10023",
    "ITA": "ORTONOVO-ISOLA DI ORTONOVO",
    "state_code": "SP",
    "cab": "498006",
    "cap": "19034"
  },
  {
    "key": "10024",
    "ITA": "ORTOVERO",
    "state_code": "SV",
    "cab": "494609",
    "cap": "17037"
  },
  {
    "key": "10025",
    "ITA": "ORTOVERO-POGLI",
    "state_code": "SV",
    "cab": "494609",
    "cap": "17037"
  },
  {
    "key": "10026",
    "ITA": "ORTUCCHIO",
    "state_code": "AQ",
    "cab": "406405",
    "cap": "67050"
  },
  {
    "key": "10027",
    "ITA": "ORTUERI",
    "state_code": "NU",
    "cab": "868604",
    "cap": "8036"
  },
  {
    "key": "10028",
    "ITA": "ORUNE-SU PRADU",
    "state_code": "NU",
    "cab": "868703",
    "cap": "8020"
  },
  {
    "key": "10029",
    "ITA": "ORUNE-SANT'EFISIO",
    "state_code": "NU",
    "cab": "868703",
    "cap": "8020"
  },
  {
    "key": "10030",
    "ITA": "ORUNE",
    "state_code": "NU",
    "cab": "868703",
    "cap": "8020"
  },
  {
    "key": "10031",
    "ITA": "ORVIETO-MORRANO VECCHIO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10032",
    "ITA": "ORVIETO-ORVIETO SCALO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10033",
    "ITA": "ORVIETO-PRODO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10034",
    "ITA": "ORVIETO-SFERRACAVALLO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10035",
    "ITA": "ORVIETO-SUGANO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10036",
    "ITA": "ORVIETO-TITIGNANO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10037",
    "ITA": "ORVIETO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10038",
    "ITA": "ORVIETO-CANALE NUOVO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10039",
    "ITA": "ORVIETO-CANALE VECCHIO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10040",
    "ITA": "ORVIETO-CICONIA",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10041",
    "ITA": "ORVIETO-CORBARA",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10042",
    "ITA": "ORVIETO-GABELLETTA",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10043",
    "ITA": "ORVIETO-MORRANO NUOVO",
    "state_code": "TR",
    "cab": "257006",
    "cap": "5018"
  },
  {
    "key": "10044",
    "ITA": "ORVINIO",
    "state_code": "RI",
    "cab": "737007",
    "cap": "2035"
  },
  {
    "key": "10045",
    "ITA": "ORZINUOVI",
    "state_code": "BS",
    "cab": "548503",
    "cap": "25034"
  },
  {
    "key": "10046",
    "ITA": "ORZINUOVI-CONIOLO",
    "state_code": "BS",
    "cab": "548503",
    "cap": "25034"
  },
  {
    "key": "10047",
    "ITA": "ORZIVECCHI",
    "state_code": "BS",
    "cab": "548602",
    "cap": "25030"
  },
  {
    "key": "10048",
    "ITA": "OSASCO",
    "state_code": "TO",
    "cab": "189944",
    "cap": "10060"
  },
  {
    "key": "10049",
    "ITA": "OSASIO",
    "state_code": "TO",
    "cab": "712307",
    "cap": "10040"
  },
  {
    "key": "10050",
    "ITA": "OSCHIRI",
    "state_code": "OT",
    "cab": "875005",
    "cap": "7027"
  },
  {
    "key": "10051",
    "ITA": "OSIDDA",
    "state_code": "NU",
    "cab": "868802",
    "cap": "8020"
  },
  {
    "key": "10052",
    "ITA": "OSIGLIA",
    "state_code": "SV",
    "cab": "923227",
    "cap": "17010"
  },
  {
    "key": "10053",
    "ITA": "OSILO-SANTA VITTORIA",
    "state_code": "SS",
    "cab": "849901",
    "cap": "7033"
  },
  {
    "key": "10054",
    "ITA": "OSILO",
    "state_code": "SS",
    "cab": "849901",
    "cap": "7033"
  },
  {
    "key": "10055",
    "ITA": "OSIMO-CASENUOVE",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10056",
    "ITA": "OSIMO-CAMPOCAVALLO",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10057",
    "ITA": "OSIMO",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10058",
    "ITA": "OSIMO-OSIMO STAZIONE",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10059",
    "ITA": "OSIMO-PADIGLIONE",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10060",
    "ITA": "OSIMO-SAN BIAGIO",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10061",
    "ITA": "OSIMO-SAN SABINO",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10062",
    "ITA": "OSIMO-PASSATEMPO",
    "state_code": "AN",
    "cab": "374900",
    "cap": "60027"
  },
  {
    "key": "10063",
    "ITA": "OSINI",
    "state_code": "OG",
    "cab": "868901",
    "cap": "8040"
  },
  {
    "key": "10064",
    "ITA": "OSIO SOPRA",
    "state_code": "BG",
    "cab": "538504",
    "cap": "24040"
  },
  {
    "key": "10065",
    "ITA": "OSIO SOTTO",
    "state_code": "BG",
    "cab": "532903",
    "cap": "24046"
  },
  {
    "key": "10066",
    "ITA": "OSMATE",
    "state_code": "VA",
    "cab": "924670",
    "cap": "21018"
  },
  {
    "key": "10067",
    "ITA": "OSNAGO",
    "state_code": "LC",
    "cab": "516500",
    "cap": "23875"
  },
  {
    "key": "10068",
    "ITA": "OSOPPO",
    "state_code": "UD",
    "cab": "640102",
    "cap": "33010"
  },
  {
    "key": "10069",
    "ITA": "OSPEDALETTI",
    "state_code": "IM",
    "cab": "490201",
    "cap": "18014"
  },
  {
    "key": "10070",
    "ITA": "OSPEDALETTO",
    "state_code": "TN",
    "cab": "351601",
    "cap": "38050"
  },
  {
    "key": "10071",
    "ITA": "OSPEDALETTO D'ALPINOLO",
    "state_code": "AV",
    "cab": "951392",
    "cap": "83014"
  },
  {
    "key": "10072",
    "ITA": "OSPEDALETTO EUGANEO",
    "state_code": "PD",
    "cab": "627000",
    "cap": "35045"
  },
  {
    "key": "10073",
    "ITA": "OSPEDALETTO LODIGIANO",
    "state_code": "LO",
    "cab": "335000",
    "cap": "26864"
  },
  {
    "key": "10074",
    "ITA": "OSPITALE DI CADORE-TERMINE DI CADORE",
    "state_code": "BL",
    "cab": "934695",
    "cap": "32010"
  },
  {
    "key": "10075",
    "ITA": "OSPITALE DI CADORE",
    "state_code": "BL",
    "cab": "934695",
    "cap": "32010"
  },
  {
    "key": "10076",
    "ITA": "OSPITALETTO",
    "state_code": "BS",
    "cab": "548701",
    "cap": "25035"
  },
  {
    "key": "10077",
    "ITA": "OSSAGO LODIGIANO",
    "state_code": "LO",
    "cab": "706002",
    "cap": "26816"
  },
  {
    "key": "10078",
    "ITA": "OSSANA-CUSIANO",
    "state_code": "TN",
    "cab": "351700",
    "cap": "38026"
  },
  {
    "key": "10079",
    "ITA": "OSSANA-FUCINE",
    "state_code": "TN",
    "cab": "351700",
    "cap": "38026"
  },
  {
    "key": "10080",
    "ITA": "OSSANA",
    "state_code": "TN",
    "cab": "351700",
    "cap": "38026"
  },
  {
    "key": "10081",
    "ITA": "OSSI",
    "state_code": "SS",
    "cab": "875104",
    "cap": "7045"
  },
  {
    "key": "10082",
    "ITA": "OSSIMO-OSSIMO INFERIORE",
    "state_code": "BS",
    "cab": "555102",
    "cap": "25050"
  },
  {
    "key": "10083",
    "ITA": "OSSIMO-OSSIMO SUPERIORE",
    "state_code": "BS",
    "cab": "555102",
    "cap": "25050"
  },
  {
    "key": "10084",
    "ITA": "OSSIMO",
    "state_code": "BS",
    "cab": "555102",
    "cap": "25050"
  },
  {
    "key": "10085",
    "ITA": "OSSONA",
    "state_code": "MI",
    "cab": "335109",
    "cap": "20010"
  },
  {
    "key": "10086",
    "ITA": "OSSUCCIO",
    "state_code": "CO",
    "cab": "925933",
    "cap": "22010"
  },
  {
    "key": "10087",
    "ITA": "OSTANA",
    "state_code": "CN",
    "cab": "919084",
    "cap": "12030"
  },
  {
    "key": "10088",
    "ITA": "OSTELLATO",
    "state_code": "FE",
    "cab": "673004",
    "cap": "44020"
  },
  {
    "key": "10089",
    "ITA": "OSTELLATO-DOGATO",
    "state_code": "FE",
    "cab": "673004",
    "cap": "44020"
  },
  {
    "key": "10090",
    "ITA": "OSTELLATO-SAN GIOVANNI",
    "state_code": "FE",
    "cab": "673004",
    "cap": "44020"
  },
  {
    "key": "10091",
    "ITA": "OSTELLATO-ROVERETO FERRARESE",
    "state_code": "FE",
    "cab": "673004",
    "cap": "44020"
  },
  {
    "key": "10092",
    "ITA": "OSTELLATO-MEDELANA",
    "state_code": "FE",
    "cab": "673004",
    "cap": "44020"
  },
  {
    "key": "10093",
    "ITA": "OSTIANO",
    "state_code": "CR",
    "cab": "569509",
    "cap": "26032"
  },
  {
    "key": "10094",
    "ITA": "OSTIGLIA-CORREGGIOLI",
    "state_code": "MN",
    "cab": "577601",
    "cap": "46035"
  },
  {
    "key": "10095",
    "ITA": "OSTIGLIA",
    "state_code": "MN",
    "cab": "577601",
    "cap": "46035"
  },
  {
    "key": "10096",
    "ITA": "OSTRA-VACCARILE",
    "state_code": "AN",
    "cab": "375006",
    "cap": "60010"
  },
  {
    "key": "10097",
    "ITA": "OSTRA-PIANELLO",
    "state_code": "AN",
    "cab": "375006",
    "cap": "60010"
  },
  {
    "key": "10098",
    "ITA": "OSTRA-CASINE",
    "state_code": "AN",
    "cab": "375006",
    "cap": "60010"
  },
  {
    "key": "10099",
    "ITA": "OSTRA",
    "state_code": "AN",
    "cab": "375006",
    "cap": "60010"
  },
  {
    "key": "10100",
    "ITA": "OSTRA VETERE",
    "state_code": "AN",
    "cab": "375105",
    "cap": "60010"
  },
  {
    "key": "10101",
    "ITA": "OSTUNI",
    "state_code": "BR",
    "cab": "792309",
    "cap": "72017"
  },
  {
    "key": "10102",
    "ITA": "OTRANTO",
    "state_code": "LE",
    "cab": "798603",
    "cap": "73028"
  },
  {
    "key": "10103",
    "ITA": "OTRICOLI-POGGIO DI OTRICOLI",
    "state_code": "TR",
    "cab": "727206",
    "cap": "5030"
  },
  {
    "key": "10104",
    "ITA": "OTRICOLI",
    "state_code": "TR",
    "cab": "727206",
    "cap": "5030"
  },
  {
    "key": "10105",
    "ITA": "OTTANA",
    "state_code": "NU",
    "cab": "853606",
    "cap": "8020"
  },
  {
    "key": "10106",
    "ITA": "OTTATI",
    "state_code": "SA",
    "cab": "952770",
    "cap": "84020"
  },
  {
    "key": "10107",
    "ITA": "OTTAVIANO-SAN GENNARELLO",
    "state_code": "NA",
    "cab": "400309",
    "cap": "80044"
  },
  {
    "key": "10108",
    "ITA": "OTTAVIANO",
    "state_code": "NA",
    "cab": "400309",
    "cap": "80044"
  },
  {
    "key": "10109",
    "ITA": "OTTIGLIO",
    "state_code": "AL",
    "cab": "484402",
    "cap": "15038"
  },
  {
    "key": "10110",
    "ITA": "OTTOBIANO",
    "state_code": "PV",
    "cab": "560805",
    "cap": "27030"
  },
  {
    "key": "10111",
    "ITA": "OTTONE-OREZZOLI",
    "state_code": "PC",
    "cab": "653907",
    "cap": "29026"
  },
  {
    "key": "10112",
    "ITA": "OTTONE",
    "state_code": "PC",
    "cab": "653907",
    "cap": "29026"
  },
  {
    "key": "10113",
    "ITA": "OULX",
    "state_code": "TO",
    "cab": "306902",
    "cap": "10056"
  },
  {
    "key": "10114",
    "ITA": "OULX-BEAULARD",
    "state_code": "TO",
    "cab": "306902",
    "cap": "10056"
  },
  {
    "key": "10115",
    "ITA": "OVADA",
    "state_code": "AL",
    "cab": "484501",
    "cap": "15076"
  },
  {
    "key": "10116",
    "ITA": "OVADA-GNOCCHETTO",
    "state_code": "AL",
    "cab": "484501",
    "cap": "15076"
  },
  {
    "key": "10117",
    "ITA": "OVARO",
    "state_code": "UD",
    "cab": "640201",
    "cap": "33025"
  },
  {
    "key": "10118",
    "ITA": "OVIGLIO",
    "state_code": "AL",
    "cab": "484600",
    "cap": "15026"
  },
  {
    "key": "10119",
    "ITA": "OVINDOLI",
    "state_code": "AQ",
    "cab": "406504",
    "cap": "67046"
  },
  {
    "key": "10120",
    "ITA": "OVINDOLI-SAN POTITO",
    "state_code": "AQ",
    "cab": "406504",
    "cap": "67046"
  },
  {
    "key": "10121",
    "ITA": "OVINDOLI-SANTO IONA",
    "state_code": "AQ",
    "cab": "406504",
    "cap": "67046"
  },
  {
    "key": "10122",
    "ITA": "OVODDA",
    "state_code": "NU",
    "cab": "869008",
    "cap": "8020"
  },
  {
    "key": "10123",
    "ITA": "OYACE",
    "state_code": "AO",
    "cab": "902510",
    "cap": "11010"
  },
  {
    "key": "10124",
    "ITA": "OZEGNA",
    "state_code": "TO",
    "cab": "671206",
    "cap": "10080"
  },
  {
    "key": "10125",
    "ITA": "OZIERI-SAN NICOLA",
    "state_code": "SS",
    "cab": "850008",
    "cap": "7014"
  },
  {
    "key": "10126",
    "ITA": "OZIERI-FRAIGAS",
    "state_code": "SS",
    "cab": "850008",
    "cap": "7014"
  },
  {
    "key": "10127",
    "ITA": "OZIERI-CHILIVANI",
    "state_code": "SS",
    "cab": "850008",
    "cap": "7014"
  },
  {
    "key": "10128",
    "ITA": "OZIERI-VIGNE",
    "state_code": "SS",
    "cab": "850008",
    "cap": "7014"
  },
  {
    "key": "10129",
    "ITA": "OZIERI",
    "state_code": "SS",
    "cab": "850008",
    "cap": "7014"
  },
  {
    "key": "10130",
    "ITA": "OZZANO DELL'EMILIA-MERCATALE",
    "state_code": "BO",
    "cab": "369900",
    "cap": "40064"
  },
  {
    "key": "10131",
    "ITA": "OZZANO DELL'EMILIA",
    "state_code": "BO",
    "cab": "369900",
    "cap": "40064"
  },
  {
    "key": "10132",
    "ITA": "OZZANO MONFERRATO",
    "state_code": "AL",
    "cab": "484709",
    "cap": "15039"
  },
  {
    "key": "10133",
    "ITA": "OZZERO",
    "state_code": "MI",
    "cab": "472001",
    "cap": "20080"
  },
  {
    "key": "10134",
    "ITA": "PABILLONIS",
    "state_code": "SU",
    "cab": "860809",
    "cap": "9030"
  },
  {
    "key": "10135",
    "ITA": "PACE DEL MELA",
    "state_code": "ME",
    "cab": "823708",
    "cap": "98042"
  },
  {
    "key": "10136",
    "ITA": "PACE DEL MELA-GIAMMORO",
    "state_code": "ME",
    "cab": "823708",
    "cap": "98042"
  },
  {
    "key": "10137",
    "ITA": "PACECO-DATTILO",
    "state_code": "TP",
    "cab": "818906",
    "cap": "91027"
  },
  {
    "key": "10138",
    "ITA": "PACECO-NUBIA",
    "state_code": "TP",
    "cab": "818906",
    "cap": "91027"
  },
  {
    "key": "10139",
    "ITA": "PACECO",
    "state_code": "TP",
    "cab": "818906",
    "cap": "91027"
  },
  {
    "key": "10140",
    "ITA": "PACENTRO",
    "state_code": "AQ",
    "cab": "406603",
    "cap": "67030"
  },
  {
    "key": "10141",
    "ITA": "PACHINO",
    "state_code": "SR",
    "cab": "847509",
    "cap": "96018"
  },
  {
    "key": "10142",
    "ITA": "PACHINO-MARZAMEMI",
    "state_code": "SR",
    "cab": "847509",
    "cap": "96018"
  },
  {
    "key": "10143",
    "ITA": "PACIANO",
    "state_code": "PG",
    "cab": "907618",
    "cap": "6060"
  },
  {
    "key": "10144",
    "ITA": "PADENGHE SUL GARDA",
    "state_code": "BS",
    "cab": "548800",
    "cap": "25080"
  },
  {
    "key": "10145",
    "ITA": "PADERGNONE",
    "state_code": "TN",
    "cab": "359000",
    "cap": "38070"
  },
  {
    "key": "10146",
    "ITA": "PADERNA",
    "state_code": "AL",
    "cab": "921478",
    "cap": "15050"
  },
  {
    "key": "10147",
    "ITA": "PADERNO D'ADDA",
    "state_code": "LC",
    "cab": "516609",
    "cap": "23877"
  },
  {
    "key": "10148",
    "ITA": "PADERNO DEL GRAPPA",
    "state_code": "TV",
    "cab": "618900",
    "cap": "31017"
  },
  {
    "key": "10149",
    "ITA": "PADERNO DUGNANO-PALAZZOLO MILANESE",
    "state_code": "MI",
    "cab": "335208",
    "cap": "20030"
  },
  {
    "key": "10150",
    "ITA": "PADERNO DUGNANO",
    "state_code": "MI",
    "cab": "335208",
    "cap": "20037"
  },
  {
    "key": "10151",
    "ITA": "PADERNO FRANCIACORTA",
    "state_code": "BS",
    "cab": "548909",
    "cap": "25050"
  },
  {
    "key": "10152",
    "ITA": "PADERNO PONCHIELLI",
    "state_code": "CR",
    "cab": "569608",
    "cap": "26024"
  },
  {
    "key": "10153",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35125"
  },
  {
    "key": "10154",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35142"
  },
  {
    "key": "10155",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35141"
  },
  {
    "key": "10156",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35139"
  },
  {
    "key": "10157",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35138"
  },
  {
    "key": "10158",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35137"
  },
  {
    "key": "10159",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35136"
  },
  {
    "key": "10160",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35135"
  },
  {
    "key": "10161",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35134"
  },
  {
    "key": "10162",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35133"
  },
  {
    "key": "10163",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35132"
  },
  {
    "key": "10164",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35121"
  },
  {
    "key": "10165",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35122"
  },
  {
    "key": "10166",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35123"
  },
  {
    "key": "10167",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35124"
  },
  {
    "key": "10168",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35143"
  },
  {
    "key": "10169",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35126"
  },
  {
    "key": "10170",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35127"
  },
  {
    "key": "10171",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35128"
  },
  {
    "key": "10172",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35129"
  },
  {
    "key": "10173",
    "ITA": "PADOVA",
    "state_code": "PD",
    "cab": "121004",
    "cap": "35131"
  },
  {
    "key": "10174",
    "ITA": "PADRIA",
    "state_code": "SS",
    "cab": "875203",
    "cap": "7015"
  },
  {
    "key": "10175",
    "ITA": "PADRU",
    "state_code": "OT",
    "cab": "721100",
    "cap": "7020"
  },
  {
    "key": "10176",
    "ITA": "PADULA-PADULA SCALO",
    "state_code": "SA",
    "cab": "763003",
    "cap": "84034"
  },
  {
    "key": "10177",
    "ITA": "PADULA",
    "state_code": "SA",
    "cab": "763003",
    "cap": "84034"
  },
  {
    "key": "10178",
    "ITA": "PADULI",
    "state_code": "BN",
    "cab": "753905",
    "cap": "82020"
  },
  {
    "key": "10179",
    "ITA": "PAESANA",
    "state_code": "CN",
    "cab": "465906",
    "cap": "12034"
  },
  {
    "key": "10180",
    "ITA": "PAESE-PORCELLENGO",
    "state_code": "TV",
    "cab": "619007",
    "cap": "31038"
  },
  {
    "key": "10181",
    "ITA": "PAESE-PADERNELLO",
    "state_code": "TV",
    "cab": "619007",
    "cap": "31038"
  },
  {
    "key": "10182",
    "ITA": "PAESE-CASTAGNOLE",
    "state_code": "TV",
    "cab": "619007",
    "cap": "31038"
  },
  {
    "key": "10183",
    "ITA": "PAESE",
    "state_code": "TV",
    "cab": "619007",
    "cap": "31038"
  },
  {
    "key": "10184",
    "ITA": "PAESE-POSTIOMA",
    "state_code": "TV",
    "cab": "619007",
    "cap": "31038"
  },
  {
    "key": "10185",
    "ITA": "PAGANI",
    "state_code": "SA",
    "cab": "763102",
    "cap": "84016"
  },
  {
    "key": "10186",
    "ITA": "PAGANICO SABINO",
    "state_code": "RI",
    "cab": "947325",
    "cap": "2020"
  },
  {
    "key": "10187",
    "ITA": "PAGAZZANO",
    "state_code": "BG",
    "cab": "676502",
    "cap": "24040"
  },
  {
    "key": "10188",
    "ITA": "PAGLIARA",
    "state_code": "ME",
    "cab": "961516",
    "cap": "98020"
  },
  {
    "key": "10189",
    "ITA": "PAGLIARA-ROCCHENERE",
    "state_code": "ME",
    "cab": "961516",
    "cap": "98020"
  },
  {
    "key": "10190",
    "ITA": "PAGLIARA-LOCADI",
    "state_code": "ME",
    "cab": "961516",
    "cap": "98020"
  },
  {
    "key": "10191",
    "ITA": "PAGLIETA-SANT'EGIDIO",
    "state_code": "CH",
    "cab": "777904",
    "cap": "66020"
  },
  {
    "key": "10192",
    "ITA": "PAGLIETA",
    "state_code": "CH",
    "cab": "777904",
    "cap": "66020"
  },
  {
    "key": "10193",
    "ITA": "PAGNACCO",
    "state_code": "UD",
    "cab": "640300",
    "cap": "33010"
  },
  {
    "key": "10194",
    "ITA": "PAGNO",
    "state_code": "CN",
    "cab": "479006",
    "cap": "12030"
  },
  {
    "key": "10195",
    "ITA": "PAGNONA",
    "state_code": "LC",
    "cab": "925941",
    "cap": "23833"
  },
  {
    "key": "10196",
    "ITA": "PAGO DEL VALLO DI LAURO",
    "state_code": "AV",
    "cab": "951400",
    "cap": "83020"
  },
  {
    "key": "10197",
    "ITA": "PAGO VEIANO",
    "state_code": "BN",
    "cab": "755702",
    "cap": "82020"
  },
  {
    "key": "10198",
    "ITA": "PAISCO LOVENO",
    "state_code": "BS",
    "cab": "929570",
    "cap": "25050"
  },
  {
    "key": "10199",
    "ITA": "PAITONE",
    "state_code": "BS",
    "cab": "488403",
    "cap": "25080"
  },
  {
    "key": "10200",
    "ITA": "PALADINA",
    "state_code": "BG",
    "cab": "533000",
    "cap": "24030"
  },
  {
    "key": "10201",
    "ITA": "PALAGANO-COSTRIGNANO",
    "state_code": "MO",
    "cab": "669101",
    "cap": "41046"
  },
  {
    "key": "10202",
    "ITA": "PALAGANO-BOCCASSUOLO",
    "state_code": "MO",
    "cab": "669101",
    "cap": "41046"
  },
  {
    "key": "10203",
    "ITA": "PALAGANO",
    "state_code": "MO",
    "cab": "669101",
    "cap": "41046"
  },
  {
    "key": "10204",
    "ITA": "PALAGANO-SUSANO",
    "state_code": "MO",
    "cab": "669101",
    "cap": "41046"
  },
  {
    "key": "10205",
    "ITA": "PALAGANO-SAVONIERO",
    "state_code": "MO",
    "cab": "669101",
    "cap": "41046"
  },
  {
    "key": "10206",
    "ITA": "PALAGANO-MONCHIO",
    "state_code": "MO",
    "cab": "669101",
    "cap": "41046"
  },
  {
    "key": "10207",
    "ITA": "PALAGIANELLO",
    "state_code": "TA",
    "cab": "789909",
    "cap": "74018"
  },
  {
    "key": "10208",
    "ITA": "PALAGIANO",
    "state_code": "TA",
    "cab": "790006",
    "cap": "74019"
  },
  {
    "key": "10209",
    "ITA": "PALAGIANO-CONCA D'ORO",
    "state_code": "TA",
    "cab": "790006",
    "cap": "74019"
  },
  {
    "key": "10210",
    "ITA": "PALAGONIA",
    "state_code": "CT",
    "cab": "841007",
    "cap": "95046"
  },
  {
    "key": "10211",
    "ITA": "PALAIA-MONTEFOSCOLI",
    "state_code": "PI",
    "cab": "710905",
    "cap": "56036"
  },
  {
    "key": "10212",
    "ITA": "PALAIA",
    "state_code": "PI",
    "cab": "710905",
    "cap": "56036"
  },
  {
    "key": "10213",
    "ITA": "PALAIA-ALICA",
    "state_code": "PI",
    "cab": "710905",
    "cap": "56036"
  },
  {
    "key": "10214",
    "ITA": "PALAIA-FORCOLI",
    "state_code": "PI",
    "cab": "710905",
    "cap": "56036"
  },
  {
    "key": "10215",
    "ITA": "PALAIA-VILLA SALETTA",
    "state_code": "PI",
    "cab": "710905",
    "cap": "56036"
  },
  {
    "key": "10216",
    "ITA": "PALAIA-PARTINO",
    "state_code": "PI",
    "cab": "710905",
    "cap": "56036"
  },
  {
    "key": "10217",
    "ITA": "PALANZANO-RANZANO",
    "state_code": "PR",
    "cab": "658500",
    "cap": "43025"
  },
  {
    "key": "10218",
    "ITA": "PALANZANO-ISOLA",
    "state_code": "PR",
    "cab": "658500",
    "cap": "43025"
  },
  {
    "key": "10219",
    "ITA": "PALANZANO",
    "state_code": "PR",
    "cab": "658500",
    "cap": "43025"
  },
  {
    "key": "10220",
    "ITA": "PALANZANO-RUZZANO",
    "state_code": "PR",
    "cab": "658500",
    "cap": "43025"
  },
  {
    "key": "10221",
    "ITA": "PALANZANO-SELVANIZZA",
    "state_code": "PR",
    "cab": "658500",
    "cap": "43025"
  },
  {
    "key": "10222",
    "ITA": "PALANZANO-VAIRO SUPERIORE",
    "state_code": "PR",
    "cab": "658500",
    "cap": "43025"
  },
  {
    "key": "10223",
    "ITA": "PALATA",
    "state_code": "CB",
    "cab": "410704",
    "cap": "86037"
  },
  {
    "key": "10224",
    "ITA": "PALAU",
    "state_code": "OT",
    "cab": "851303",
    "cap": "7020"
  },
  {
    "key": "10225",
    "ITA": "PALAZZAGO-GROMLONGO",
    "state_code": "BG",
    "cab": "893602",
    "cap": "24030"
  },
  {
    "key": "10226",
    "ITA": "PALAZZAGO",
    "state_code": "BG",
    "cab": "893602",
    "cap": "24030"
  },
  {
    "key": "10227",
    "ITA": "PALAZZO ADRIANO",
    "state_code": "PA",
    "cab": "434803",
    "cap": "90030"
  },
  {
    "key": "10228",
    "ITA": "PALAZZO CANAVESE",
    "state_code": "TO",
    "cab": "284083",
    "cap": "10010"
  },
  {
    "key": "10229",
    "ITA": "PALAZZO PIGNANO-SCANNABUE",
    "state_code": "CR",
    "cab": "569707",
    "cap": "26020"
  },
  {
    "key": "10230",
    "ITA": "PALAZZO PIGNANO",
    "state_code": "CR",
    "cab": "569707",
    "cap": "26020"
  },
  {
    "key": "10231",
    "ITA": "PALAZZO PIGNANO-CASCINE GANDINI",
    "state_code": "CR",
    "cab": "569707",
    "cap": "26020"
  },
  {
    "key": "10232",
    "ITA": "PALAZZO SAN GERVASIO",
    "state_code": "PZ",
    "cab": "421503",
    "cap": "85026"
  },
  {
    "key": "10233",
    "ITA": "PALAZZOLO ACREIDE",
    "state_code": "SR",
    "cab": "847608",
    "cap": "96010"
  },
  {
    "key": "10234",
    "ITA": "PALAZZOLO DELLO STELLA",
    "state_code": "UD",
    "cab": "640409",
    "cap": "33056"
  },
  {
    "key": "10235",
    "ITA": "PALAZZOLO SULL'OGLIO",
    "state_code": "BS",
    "cab": "549006",
    "cap": "25036"
  },
  {
    "key": "10236",
    "ITA": "PALAZZOLO SULL'OGLIO-SAN PANCRAZIO",
    "state_code": "BS",
    "cab": "549006",
    "cap": "25036"
  },
  {
    "key": "10237",
    "ITA": "PALAZZOLO VERCELLESE",
    "state_code": "VC",
    "cab": "446302",
    "cap": "13040"
  },
  {
    "key": "10238",
    "ITA": "PALAZZUOLO SUL SENIO-MISILEO",
    "state_code": "FI",
    "cab": "379909",
    "cap": "50035"
  },
  {
    "key": "10239",
    "ITA": "PALAZZUOLO SUL SENIO",
    "state_code": "FI",
    "cab": "379909",
    "cap": "50035"
  },
  {
    "key": "10240",
    "ITA": "PALENA",
    "state_code": "CH",
    "cab": "778001",
    "cap": "66017"
  },
  {
    "key": "10241",
    "ITA": "PALERMITI",
    "state_code": "CZ",
    "cab": "268151",
    "cap": "88050"
  },
  {
    "key": "10242",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90121"
  },
  {
    "key": "10243",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90122"
  },
  {
    "key": "10244",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90123"
  },
  {
    "key": "10245",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90124"
  },
  {
    "key": "10246",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90125"
  },
  {
    "key": "10247",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90126"
  },
  {
    "key": "10248",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90127"
  },
  {
    "key": "10249",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90128"
  },
  {
    "key": "10250",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90129"
  },
  {
    "key": "10251",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90131"
  },
  {
    "key": "10252",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90133"
  },
  {
    "key": "10253",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90134"
  },
  {
    "key": "10254",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90135"
  },
  {
    "key": "10255",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90136"
  },
  {
    "key": "10256",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90137"
  },
  {
    "key": "10257",
    "ITA": "PALERMO-TOMMASO NATALE SFERRACAVALLO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90147"
  },
  {
    "key": "10258",
    "ITA": "PALERMO-TRIBUNALI CASTELLAMMARE",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90133"
  },
  {
    "key": "10259",
    "ITA": "PALERMO-ZISA",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90134"
  },
  {
    "key": "10260",
    "ITA": "PALERMO-ZISA",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90135"
  },
  {
    "key": "10261",
    "ITA": "PALERMO-ZISA",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90138"
  },
  {
    "key": "10262",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90138"
  },
  {
    "key": "10263",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90139"
  },
  {
    "key": "10264",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90141"
  },
  {
    "key": "10265",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90142"
  },
  {
    "key": "10266",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90143"
  },
  {
    "key": "10267",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90144"
  },
  {
    "key": "10268",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90145"
  },
  {
    "key": "10269",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90146"
  },
  {
    "key": "10270",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90147"
  },
  {
    "key": "10271",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90148"
  },
  {
    "key": "10272",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90149"
  },
  {
    "key": "10273",
    "ITA": "PALERMO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90151"
  },
  {
    "key": "10274",
    "ITA": "PALERMO-ARENELLA VERGINE MARIA",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90142"
  },
  {
    "key": "10275",
    "ITA": "PALERMO-BRANCACCIO CIACULLI",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90124"
  },
  {
    "key": "10276",
    "ITA": "PALERMO-CRUILLAS",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90146"
  },
  {
    "key": "10277",
    "ITA": "PALERMO-IN VIA ALOI",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90125"
  },
  {
    "key": "10278",
    "ITA": "PALERMO-IN VIA BRANCACCIO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90124"
  },
  {
    "key": "10279",
    "ITA": "PALERMO-IN VIA CHIAVELLI",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90124"
  },
  {
    "key": "10280",
    "ITA": "PALERMO-IN VIA GIOIAMIA",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90134"
  },
  {
    "key": "10281",
    "ITA": "PALERMO-IN VIA SAN NICOLA",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90146"
  },
  {
    "key": "10282",
    "ITA": "PALERMO-IN VIA SANTA MARIA DI GESU'",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90124"
  },
  {
    "key": "10283",
    "ITA": "PALERMO-MEZZOMONREALE BOCCADIFALCO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90126"
  },
  {
    "key": "10284",
    "ITA": "PALERMO-MONTEPELLEGRINO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90142"
  },
  {
    "key": "10285",
    "ITA": "PALERMO-PALLAVICINO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90146"
  },
  {
    "key": "10286",
    "ITA": "PALERMO-PARTANNA MONDELLO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90151"
  },
  {
    "key": "10287",
    "ITA": "PALERMO-RESUTTANA SAN LORENZO",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90146"
  },
  {
    "key": "10288",
    "ITA": "PALERMO-SETTECANNOLI",
    "state_code": "PA",
    "cab": "46003",
    "cap": "90123"
  },
  {
    "key": "10289",
    "ITA": "PALESTRINA-CARCHITTI",
    "state_code": "RM",
    "cab": "393207",
    "cap": "36"
  },
  {
    "key": "10290",
    "ITA": "PALESTRINA",
    "state_code": "RM",
    "cab": "393207",
    "cap": "36"
  },
  {
    "key": "10291",
    "ITA": "PALESTRO",
    "state_code": "PV",
    "cab": "560904",
    "cap": "27030"
  },
  {
    "key": "10292",
    "ITA": "PALIANO",
    "state_code": "FR",
    "cab": "745000",
    "cap": "3018"
  },
  {
    "key": "10293",
    "ITA": "PALIZZI-PALIZZI MARINA",
    "state_code": "RC",
    "cab": "960237",
    "cap": "89038"
  },
  {
    "key": "10294",
    "ITA": "PALIZZI",
    "state_code": "RC",
    "cab": "960237",
    "cap": "89038"
  },
  {
    "key": "10295",
    "ITA": "PALIZZI-PIETRAPENNATA",
    "state_code": "RC",
    "cab": "960237",
    "cap": "89038"
  },
  {
    "key": "10296",
    "ITA": "PALLAGORIO",
    "state_code": "KR",
    "cab": "887505",
    "cap": "88818"
  },
  {
    "key": "10297",
    "ITA": "PALLANZENO",
    "state_code": "VB",
    "cab": "917542",
    "cap": "28884"
  },
  {
    "key": "10298",
    "ITA": "PALLARE",
    "state_code": "SV",
    "cab": "923235",
    "cap": "17043"
  },
  {
    "key": "10299",
    "ITA": "PALMA CAMPANIA-VICO",
    "state_code": "NA",
    "cab": "400408",
    "cap": "80036"
  },
  {
    "key": "10300",
    "ITA": "PALMA CAMPANIA-CASTELLO",
    "state_code": "NA",
    "cab": "400408",
    "cap": "80036"
  },
  {
    "key": "10301",
    "ITA": "PALMA CAMPANIA",
    "state_code": "NA",
    "cab": "400408",
    "cap": "80036"
  },
  {
    "key": "10302",
    "ITA": "PALMA DI MONTECHIARO",
    "state_code": "AG",
    "cab": "830307",
    "cap": "92020"
  },
  {
    "key": "10303",
    "ITA": "PALMANOVA-IALMICCO",
    "state_code": "UD",
    "cab": "640508",
    "cap": "33057"
  },
  {
    "key": "10304",
    "ITA": "PALMANOVA",
    "state_code": "UD",
    "cab": "640508",
    "cap": "33057"
  },
  {
    "key": "10305",
    "ITA": "PALMANOVA-SOTTOSELVA",
    "state_code": "UD",
    "cab": "640508",
    "cap": "33057"
  },
  {
    "key": "10306",
    "ITA": "PALMARIGGI",
    "state_code": "LE",
    "cab": "957696",
    "cap": "73020"
  },
  {
    "key": "10307",
    "ITA": "PALMAS ARBOREA",
    "state_code": "OR",
    "cab": "879304",
    "cap": "9090"
  },
  {
    "key": "10308",
    "ITA": "PALMAS ARBOREA-TIRIA",
    "state_code": "OR",
    "cab": "879304",
    "cap": "9090"
  },
  {
    "key": "10309",
    "ITA": "PALMI-TAUREANA",
    "state_code": "RC",
    "cab": "814905",
    "cap": "89015"
  },
  {
    "key": "10310",
    "ITA": "PALMI",
    "state_code": "RC",
    "cab": "814905",
    "cap": "89015"
  },
  {
    "key": "10311",
    "ITA": "PALMI-TRODIO",
    "state_code": "RC",
    "cab": "814905",
    "cap": "89015"
  },
  {
    "key": "10312",
    "ITA": "PALMIANO",
    "state_code": "AP",
    "cab": "942649",
    "cap": "63049"
  },
  {
    "key": "10313",
    "ITA": "PALMOLI",
    "state_code": "CH",
    "cab": "779306",
    "cap": "66050"
  },
  {
    "key": "10314",
    "ITA": "PALO DEL COLLE",
    "state_code": "BA",
    "cab": "416008",
    "cap": "70027"
  },
  {
    "key": "10315",
    "ITA": "PALOMBARA SABINA-CRETONE",
    "state_code": "RM",
    "cab": "393306",
    "cap": "18"
  },
  {
    "key": "10316",
    "ITA": "PALOMBARA SABINA",
    "state_code": "RM",
    "cab": "393306",
    "cap": "18"
  },
  {
    "key": "10317",
    "ITA": "PALOMBARO",
    "state_code": "CH",
    "cab": "954842",
    "cap": "66010"
  },
  {
    "key": "10318",
    "ITA": "PALOMONTE",
    "state_code": "SA",
    "cab": "671602",
    "cap": "84020"
  },
  {
    "key": "10319",
    "ITA": "PALOMONTE-BIVIO PALOMONTE",
    "state_code": "SA",
    "cab": "671602",
    "cap": "84020"
  },
  {
    "key": "10320",
    "ITA": "PALOMONTE-PERRAZZE",
    "state_code": "SA",
    "cab": "671602",
    "cap": "84020"
  },
  {
    "key": "10321",
    "ITA": "PALOSCO",
    "state_code": "BG",
    "cab": "533109",
    "cap": "24050"
  },
  {
    "key": "10322",
    "ITA": "PALU'",
    "state_code": "VR",
    "cab": "717108",
    "cap": "37050"
  },
  {
    "key": "10323",
    "ITA": "PALU' DEL FERSINA",
    "state_code": "TN",
    "cab": "904987",
    "cap": "38050"
  },
  {
    "key": "10324",
    "ITA": "PALUDI",
    "state_code": "CS",
    "cab": "959106",
    "cap": "87060"
  },
  {
    "key": "10325",
    "ITA": "PALUZZA-CLEULIS",
    "state_code": "UD",
    "cab": "640607",
    "cap": "33026"
  },
  {
    "key": "10326",
    "ITA": "PALUZZA",
    "state_code": "UD",
    "cab": "640607",
    "cap": "33026"
  },
  {
    "key": "10327",
    "ITA": "PALUZZA-TIMAU",
    "state_code": "UD",
    "cab": "640607",
    "cap": "33026"
  },
  {
    "key": "10328",
    "ITA": "PAMPARATO-VALCASOTTO",
    "state_code": "CN",
    "cab": "466003",
    "cap": "12087"
  },
  {
    "key": "10329",
    "ITA": "PAMPARATO-SERRA",
    "state_code": "CN",
    "cab": "466003",
    "cap": "12087"
  },
  {
    "key": "10330",
    "ITA": "PAMPARATO",
    "state_code": "CN",
    "cab": "466003",
    "cap": "12087"
  },
  {
    "key": "10331",
    "ITA": "PANCALIERI",
    "state_code": "TO",
    "cab": "307009",
    "cap": "10060"
  },
  {
    "key": "10332",
    "ITA": "PANCARANA",
    "state_code": "PV",
    "cab": "930685",
    "cap": "27050"
  },
  {
    "key": "10333",
    "ITA": "PANCHIA'",
    "state_code": "TN",
    "cab": "351809",
    "cap": "38030"
  },
  {
    "key": "10334",
    "ITA": "PANDINO-NOSADELLO",
    "state_code": "CR",
    "cab": "569806",
    "cap": "26025"
  },
  {
    "key": "10335",
    "ITA": "PANDINO",
    "state_code": "CR",
    "cab": "569806",
    "cap": "26025"
  },
  {
    "key": "10336",
    "ITA": "PANETTIERI",
    "state_code": "CS",
    "cab": "959114",
    "cap": "87050"
  },
  {
    "key": "10337",
    "ITA": "PANICALE-TAVERNELLE",
    "state_code": "PG",
    "cab": "385906",
    "cap": "6068"
  },
  {
    "key": "10338",
    "ITA": "PANICALE",
    "state_code": "PG",
    "cab": "385906",
    "cap": "6064"
  },
  {
    "key": "10339",
    "ITA": "PANNARANO",
    "state_code": "BN",
    "cab": "754002",
    "cap": "82017"
  },
  {
    "key": "10340",
    "ITA": "PANNI",
    "state_code": "FG",
    "cab": "956391",
    "cap": "71020"
  },
  {
    "key": "10341",
    "ITA": "PANTELLERIA-SCAURI",
    "state_code": "TP",
    "cab": "819003",
    "cap": "91017"
  },
  {
    "key": "10342",
    "ITA": "PANTELLERIA-PANTELLERIA AEROPORTO",
    "state_code": "TP",
    "cab": "819003",
    "cap": "91017"
  },
  {
    "key": "10343",
    "ITA": "PANTELLERIA",
    "state_code": "TP",
    "cab": "819003",
    "cap": "91017"
  },
  {
    "key": "10344",
    "ITA": "PANTELLERIA-KAMMA",
    "state_code": "TP",
    "cab": "819003",
    "cap": "91017"
  },
  {
    "key": "10345",
    "ITA": "PANTIGLIATE",
    "state_code": "MI",
    "cab": "335307",
    "cap": "20090"
  },
  {
    "key": "10346",
    "ITA": "PAOLA-FOSSE",
    "state_code": "CS",
    "cab": "808501",
    "cap": "87027"
  },
  {
    "key": "10347",
    "ITA": "PAOLA-SANTUARIO SAN FRANCESCO",
    "state_code": "CS",
    "cab": "808501",
    "cap": "87027"
  },
  {
    "key": "10348",
    "ITA": "PAOLA-SAN MICELI",
    "state_code": "CS",
    "cab": "808501",
    "cap": "87027"
  },
  {
    "key": "10349",
    "ITA": "PAOLA-PAOLA MARINA",
    "state_code": "CS",
    "cab": "808501",
    "cap": "87027"
  },
  {
    "key": "10350",
    "ITA": "PAOLA",
    "state_code": "CS",
    "cab": "808501",
    "cap": "87027"
  },
  {
    "key": "10351",
    "ITA": "PAOLISI",
    "state_code": "BN",
    "cab": "950402",
    "cap": "82011"
  },
  {
    "key": "10352",
    "ITA": "PAPASIDERO",
    "state_code": "CS",
    "cab": "959122",
    "cap": "87020"
  },
  {
    "key": "10353",
    "ITA": "PAPOZZE-BRAGLIA",
    "state_code": "RO",
    "cab": "633909",
    "cap": "45010"
  },
  {
    "key": "10354",
    "ITA": "PAPOZZE",
    "state_code": "RO",
    "cab": "633909",
    "cap": "45010"
  },
  {
    "key": "10355",
    "ITA": "PARABIAGO-VILLASTANZA",
    "state_code": "MI",
    "cab": "335406",
    "cap": "20015"
  },
  {
    "key": "10356",
    "ITA": "PARABIAGO",
    "state_code": "MI",
    "cab": "335406",
    "cap": "20015"
  },
  {
    "key": "10357",
    "ITA": "PARABITA",
    "state_code": "LE",
    "cab": "798702",
    "cap": "73052"
  },
  {
    "key": "10358",
    "ITA": "PARATICO",
    "state_code": "BS",
    "cab": "549105",
    "cap": "25030"
  },
  {
    "key": "10359",
    "ITA": "PARCINES-RABLA'",
    "state_code": "BZ",
    "cab": "586909",
    "cap": "39020"
  },
  {
    "key": "10360",
    "ITA": "PARCINES-TEL",
    "state_code": "BZ",
    "cab": "586909",
    "cap": "39020"
  },
  {
    "key": "10361",
    "ITA": "PARCINES",
    "state_code": "BZ",
    "cab": "586909",
    "cap": "39020"
  },
  {
    "key": "10362",
    "ITA": "PARE'",
    "state_code": "CO",
    "cab": "516708",
    "cap": "22020"
  },
  {
    "key": "10363",
    "ITA": "PARELLA",
    "state_code": "TO",
    "cab": "284117",
    "cap": "10010"
  },
  {
    "key": "10364",
    "ITA": "PARENTI",
    "state_code": "CS",
    "cab": "788109",
    "cap": "87040"
  },
  {
    "key": "10365",
    "ITA": "PARETE",
    "state_code": "CE",
    "cab": "752105",
    "cap": "81030"
  },
  {
    "key": "10366",
    "ITA": "PARETO",
    "state_code": "AL",
    "cab": "921486",
    "cap": "15010"
  },
  {
    "key": "10367",
    "ITA": "PARGHELIA-FITILI",
    "state_code": "VV",
    "cab": "913251",
    "cap": "89861"
  },
  {
    "key": "10368",
    "ITA": "PARGHELIA",
    "state_code": "VV",
    "cab": "913251",
    "cap": "89861"
  },
  {
    "key": "10369",
    "ITA": "PARLASCO",
    "state_code": "LC",
    "cab": "925958",
    "cap": "23837"
  },
  {
    "key": "10370",
    "ITA": "PARMA-FONTANA",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10371",
    "ITA": "PARMA-FONTANINI",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10372",
    "ITA": "PARMA-FRAORE",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10373",
    "ITA": "PARMA-GAIONE",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10374",
    "ITA": "PARMA-MARANO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10375",
    "ITA": "PARMA-MOLETOLO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10376",
    "ITA": "PARMA-PANOCCHIA",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10377",
    "ITA": "PARMA-PARADIGNA",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10378",
    "ITA": "PARMA-PORPORANO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10379",
    "ITA": "PARMA-SAN LAZZARO PARMENSE",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10380",
    "ITA": "PARMA-SAN LEONARDO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10381",
    "ITA": "PARMA-SAN PANCRAZIO PARMENSE",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10382",
    "ITA": "PARMA-SAN PROSPERO PARMENSE",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10383",
    "ITA": "PARMA-VICOFERTILE",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10384",
    "ITA": "PARMA-VICOFERTILE",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43126"
  },
  {
    "key": "10385",
    "ITA": "PARMA-VICOMERO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10386",
    "ITA": "PARMA-VIGATTO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10387",
    "ITA": "PARMA-CORTILE SAN MARTINO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10388",
    "ITA": "PARMA-CORCAGNANO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10389",
    "ITA": "PARMA-CARIGNANO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10390",
    "ITA": "PARMA-BOTTEGHINO",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10391",
    "ITA": "PARMA-BAGANZOLA",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10392",
    "ITA": "PARMA",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10393",
    "ITA": "PARMA-ALBERI",
    "state_code": "PR",
    "cab": "127001",
    "cap": "43100"
  },
  {
    "key": "10394",
    "ITA": "PARMA",
    "state_code": "PR",
    "cab": "..",
    "cap": "43122"
  },
  {
    "key": "10395",
    "ITA": "PARODI LIGURE",
    "state_code": "AL",
    "cab": "921494",
    "cap": "15060"
  },
  {
    "key": "10396",
    "ITA": "PAROLDO",
    "state_code": "CN",
    "cab": "919100",
    "cap": "12070"
  },
  {
    "key": "10397",
    "ITA": "PAROLISE",
    "state_code": "AV",
    "cab": "951418",
    "cap": "83050"
  },
  {
    "key": "10398",
    "ITA": "PARONA",
    "state_code": "PV",
    "cab": "561001",
    "cap": "27020"
  },
  {
    "key": "10399",
    "ITA": "PARRANO",
    "state_code": "TR",
    "cab": "721308",
    "cap": "5010"
  },
  {
    "key": "10400",
    "ITA": "PARRE",
    "state_code": "BG",
    "cab": "450700",
    "cap": "24020"
  },
  {
    "key": "10401",
    "ITA": "PARRE-PONTE SELVA",
    "state_code": "BG",
    "cab": "450700",
    "cap": "24020"
  },
  {
    "key": "10402",
    "ITA": "PARRE-PARRE PONTE SELVA",
    "state_code": "BG",
    "cab": "450700",
    "cap": "24020"
  },
  {
    "key": "10403",
    "ITA": "PARTANNA",
    "state_code": "TP",
    "cab": "819102",
    "cap": "91028"
  },
  {
    "key": "10404",
    "ITA": "PARTINICO",
    "state_code": "PA",
    "cab": "434902",
    "cap": "90047"
  },
  {
    "key": "10405",
    "ITA": "PARUZZARO",
    "state_code": "NO",
    "cab": "780502",
    "cap": "28040"
  },
  {
    "key": "10406",
    "ITA": "PARZANICA",
    "state_code": "BG",
    "cab": "928424",
    "cap": "24060"
  },
  {
    "key": "10407",
    "ITA": "PASIAN DI PRATO-COLLOREDO DI PRATO",
    "state_code": "UD",
    "cab": "640706",
    "cap": "33037"
  },
  {
    "key": "10408",
    "ITA": "PASIAN DI PRATO-PASSONS",
    "state_code": "UD",
    "cab": "640706",
    "cap": "33037"
  },
  {
    "key": "10409",
    "ITA": "PASIAN DI PRATO",
    "state_code": "UD",
    "cab": "640706",
    "cap": "33037"
  },
  {
    "key": "10410",
    "ITA": "PASIANO DI PORDENONE",
    "state_code": "PN",
    "cab": "649301",
    "cap": "33087"
  },
  {
    "key": "10411",
    "ITA": "PASIANO DI PORDENONE-CECCHINI",
    "state_code": "PN",
    "cab": "649301",
    "cap": "33087"
  },
  {
    "key": "10412",
    "ITA": "PASIANO DI PORDENONE-PASIANO",
    "state_code": "PN",
    "cab": "649301",
    "cap": "33087"
  },
  {
    "key": "10413",
    "ITA": "PASIANO DI PORDENONE-POZZO",
    "state_code": "PN",
    "cab": "649301",
    "cap": "33087"
  },
  {
    "key": "10414",
    "ITA": "PASIANO DI PORDENONE-RIVAROTTA",
    "state_code": "PN",
    "cab": "649301",
    "cap": "33087"
  },
  {
    "key": "10415",
    "ITA": "PASIANO DI PORDENONE-VISINALE",
    "state_code": "PN",
    "cab": "649301",
    "cap": "33087"
  },
  {
    "key": "10416",
    "ITA": "PASPARDO",
    "state_code": "BS",
    "cab": "929596",
    "cap": "25050"
  },
  {
    "key": "10417",
    "ITA": "PASSERANO MARMORITO-MARMORITO",
    "state_code": "AT",
    "cab": "920322",
    "cap": "14020"
  },
  {
    "key": "10418",
    "ITA": "PASSERANO MARMORITO-SCHIERANO",
    "state_code": "AT",
    "cab": "920322",
    "cap": "14020"
  },
  {
    "key": "10419",
    "ITA": "PASSERANO MARMORITO-PASSERANO",
    "state_code": "AT",
    "cab": "920322",
    "cap": "14020"
  },
  {
    "key": "10420",
    "ITA": "PASSERANO MARMORITO",
    "state_code": "AT",
    "cab": "920322",
    "cap": "14020"
  },
  {
    "key": "10421",
    "ITA": "PASSIGNANO SUL TRASIMENO",
    "state_code": "PG",
    "cab": "386003",
    "cap": "6065"
  },
  {
    "key": "10422",
    "ITA": "PASSIGNANO SUL TRASIMENO-CASTEL RIGONE",
    "state_code": "PG",
    "cab": "386003",
    "cap": "6065"
  },
  {
    "key": "10423",
    "ITA": "PASSIRANO-MONTEROTONDO",
    "state_code": "BS",
    "cab": "549204",
    "cap": "25050"
  },
  {
    "key": "10424",
    "ITA": "PASSIRANO-CAMIGNONE",
    "state_code": "BS",
    "cab": "549204",
    "cap": "25040"
  },
  {
    "key": "10425",
    "ITA": "PASSIRANO",
    "state_code": "BS",
    "cab": "549204",
    "cap": "25050"
  },
  {
    "key": "10426",
    "ITA": "PASTENA",
    "state_code": "FR",
    "cab": "898809",
    "cap": "3020"
  },
  {
    "key": "10427",
    "ITA": "PASTORANO",
    "state_code": "CE",
    "cab": "188508",
    "cap": "81050"
  },
  {
    "key": "10428",
    "ITA": "PASTORANO-PANTULIANO",
    "state_code": "CE",
    "cab": "188508",
    "cap": "81050"
  },
  {
    "key": "10429",
    "ITA": "PASTRENGO-PIOVEZZANO",
    "state_code": "VR",
    "cab": "596403",
    "cap": "37010"
  },
  {
    "key": "10430",
    "ITA": "PASTRENGO",
    "state_code": "VR",
    "cab": "596403",
    "cap": "37010"
  },
  {
    "key": "10431",
    "ITA": "PASTURANA",
    "state_code": "AL",
    "cab": "921502",
    "cap": "15060"
  },
  {
    "key": "10432",
    "ITA": "PASTURO",
    "state_code": "LC",
    "cab": "516807",
    "cap": "23818"
  },
  {
    "key": "10433",
    "ITA": "PATERNO",
    "state_code": "PZ",
    "cab": "424309",
    "cap": "85050"
  },
  {
    "key": "10434",
    "ITA": "PATERNO CALABRO",
    "state_code": "CS",
    "cab": "888503",
    "cap": "87040"
  },
  {
    "key": "10435",
    "ITA": "PATERNO CALABRO-CASAL DI BASSO",
    "state_code": "CS",
    "cab": "888503",
    "cap": "87040"
  },
  {
    "key": "10436",
    "ITA": "PATERNO'-SFERRO",
    "state_code": "CT",
    "cab": "841106",
    "cap": "95047"
  },
  {
    "key": "10437",
    "ITA": "PATERNO'",
    "state_code": "CT",
    "cab": "841106",
    "cap": "95047"
  },
  {
    "key": "10438",
    "ITA": "PATERNOPOLI",
    "state_code": "AV",
    "cab": "760009",
    "cap": "83052"
  },
  {
    "key": "10439",
    "ITA": "PATRICA-QUATTRO STRADE",
    "state_code": "FR",
    "cab": "387803",
    "cap": "3010"
  },
  {
    "key": "10440",
    "ITA": "PATRICA",
    "state_code": "FR",
    "cab": "387803",
    "cap": "3010"
  },
  {
    "key": "10441",
    "ITA": "PATTADA",
    "state_code": "SS",
    "cab": "850107",
    "cap": "7016"
  },
  {
    "key": "10442",
    "ITA": "PATTI-TINDARI",
    "state_code": "ME",
    "cab": "823807",
    "cap": "98066"
  },
  {
    "key": "10443",
    "ITA": "PATTI-SCALA",
    "state_code": "ME",
    "cab": "823807",
    "cap": "98066"
  },
  {
    "key": "10444",
    "ITA": "PATTI-SAN COSIMO",
    "state_code": "ME",
    "cab": "823807",
    "cap": "98066"
  },
  {
    "key": "10445",
    "ITA": "PATTI-MONGIOVE",
    "state_code": "ME",
    "cab": "823807",
    "cap": "98066"
  },
  {
    "key": "10446",
    "ITA": "PATTI-MARINA DI PATTI",
    "state_code": "ME",
    "cab": "823807",
    "cap": "98066"
  },
  {
    "key": "10447",
    "ITA": "PATTI-CASE NUOVE RUSSO",
    "state_code": "ME",
    "cab": "823807",
    "cap": "98066"
  },
  {
    "key": "10448",
    "ITA": "PATTI",
    "state_code": "ME",
    "cab": "823807",
    "cap": "98066"
  },
  {
    "key": "10449",
    "ITA": "PATU'",
    "state_code": "LE",
    "cab": "798801",
    "cap": "73053"
  },
  {
    "key": "10450",
    "ITA": "PAU",
    "state_code": "OR",
    "cab": "879403",
    "cap": "9090"
  },
  {
    "key": "10451",
    "ITA": "PAULARO-DIERICO",
    "state_code": "UD",
    "cab": "640805",
    "cap": "33027"
  },
  {
    "key": "10452",
    "ITA": "PAULARO-SALINO",
    "state_code": "UD",
    "cab": "640805",
    "cap": "33027"
  },
  {
    "key": "10453",
    "ITA": "PAULARO",
    "state_code": "UD",
    "cab": "640805",
    "cap": "33027"
  },
  {
    "key": "10454",
    "ITA": "PAULI ARBAREI",
    "state_code": "SU",
    "cab": "914846",
    "cap": "9020"
  },
  {
    "key": "10455",
    "ITA": "PAULILATINO",
    "state_code": "OR",
    "cab": "879502",
    "cap": "9070"
  },
  {
    "key": "10456",
    "ITA": "PAULLO",
    "state_code": "MI",
    "cab": "335505",
    "cap": "20067"
  },
  {
    "key": "10457",
    "ITA": "PAUPISI",
    "state_code": "BN",
    "cab": "950410",
    "cap": "82030"
  },
  {
    "key": "10458",
    "ITA": "PAVAROLO",
    "state_code": "TO",
    "cab": "901074",
    "cap": "10020"
  },
  {
    "key": "10459",
    "ITA": "PAVIA-CA' DELLA TERRA",
    "state_code": "PV",
    "cab": "113001",
    "cap": "27100"
  },
  {
    "key": "10460",
    "ITA": "PAVIA",
    "state_code": "PV",
    "cab": "113001",
    "cap": "27100"
  },
  {
    "key": "10461",
    "ITA": "PAVIA-FOSSARMATO",
    "state_code": "PV",
    "cab": "113001",
    "cap": "27100"
  },
  {
    "key": "10462",
    "ITA": "PAVIA-MIRABELLO DI PAVIA",
    "state_code": "PV",
    "cab": "113001",
    "cap": "27100"
  },
  {
    "key": "10463",
    "ITA": "PAVIA DI UDINE-RISANO",
    "state_code": "UD",
    "cab": "640904",
    "cap": "33050"
  },
  {
    "key": "10464",
    "ITA": "PAVIA DI UDINE-PERCOTO",
    "state_code": "UD",
    "cab": "640904",
    "cap": "33050"
  },
  {
    "key": "10465",
    "ITA": "PAVIA DI UDINE-LUMIGNACCO",
    "state_code": "UD",
    "cab": "640904",
    "cap": "33050"
  },
  {
    "key": "10466",
    "ITA": "PAVIA DI UDINE-LAUZACCO",
    "state_code": "UD",
    "cab": "640904",
    "cap": "33050"
  },
  {
    "key": "10467",
    "ITA": "PAVIA DI UDINE",
    "state_code": "UD",
    "cab": "640904",
    "cap": "33050"
  },
  {
    "key": "10468",
    "ITA": "PAVONE CANAVESE",
    "state_code": "TO",
    "cab": "307108",
    "cap": "10018"
  },
  {
    "key": "10469",
    "ITA": "PAVONE DEL MELLA",
    "state_code": "BS",
    "cab": "549303",
    "cap": "25020"
  },
  {
    "key": "10470",
    "ITA": "PAVULLO NEL FRIGNANO-MICENO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10471",
    "ITA": "PAVULLO NEL FRIGNANO-MONTEBONELLO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10472",
    "ITA": "PAVULLO NEL FRIGNANO-MONTECUCCOLO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10473",
    "ITA": "PAVULLO NEL FRIGNANO-MONTORSO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10474",
    "ITA": "PAVULLO NEL FRIGNANO-MONZONE",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10475",
    "ITA": "PAVULLO NEL FRIGNANO-NIVIANO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10476",
    "ITA": "PAVULLO NEL FRIGNANO-OLINA",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10477",
    "ITA": "PAVULLO NEL FRIGNANO-RENNO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10478",
    "ITA": "PAVULLO NEL FRIGNANO-SANT'ANTONIO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10479",
    "ITA": "PAVULLO NEL FRIGNANO-SASSO GUIDANO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10480",
    "ITA": "PAVULLO NEL FRIGNANO-VERICA",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10481",
    "ITA": "PAVULLO NEL FRIGNANO-COSCOGNO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10482",
    "ITA": "PAVULLO NEL FRIGNANO-CROCETTE",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10483",
    "ITA": "PAVULLO NEL FRIGNANO-FRASSINETI",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10484",
    "ITA": "PAVULLO NEL FRIGNANO-IDDIANO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10485",
    "ITA": "PAVULLO NEL FRIGNANO-GAIATO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10486",
    "ITA": "PAVULLO NEL FRIGNANO-CASTAGNETO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10487",
    "ITA": "PAVULLO NEL FRIGNANO-CAMATTA",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10488",
    "ITA": "PAVULLO NEL FRIGNANO-GAIANELLO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10489",
    "ITA": "PAVULLO NEL FRIGNANO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10490",
    "ITA": "PAVULLO NEL FRIGNANO-BENEDELLO",
    "state_code": "MO",
    "cab": "669200",
    "cap": "41026"
  },
  {
    "key": "10491",
    "ITA": "PAZZANO",
    "state_code": "RC",
    "cab": "960245",
    "cap": "89040"
  },
  {
    "key": "10492",
    "ITA": "PECCIOLI-LEGOLI",
    "state_code": "PI",
    "cab": "711002",
    "cap": "56037"
  },
  {
    "key": "10493",
    "ITA": "PECCIOLI-GHIZZANO",
    "state_code": "PI",
    "cab": "711002",
    "cap": "56037"
  },
  {
    "key": "10494",
    "ITA": "PECCIOLI-FABBRICA",
    "state_code": "PI",
    "cab": "711002",
    "cap": "56037"
  },
  {
    "key": "10495",
    "ITA": "PECCIOLI",
    "state_code": "PI",
    "cab": "711002",
    "cap": "56037"
  },
  {
    "key": "10496",
    "ITA": "PECCO",
    "state_code": "TO",
    "cab": "284141",
    "cap": "10080"
  },
  {
    "key": "10497",
    "ITA": "PECETTO DI VALENZA",
    "state_code": "AL",
    "cab": "921510",
    "cap": "15040"
  },
  {
    "key": "10498",
    "ITA": "PECETTO TORINESE-SAN PIETRO",
    "state_code": "TO",
    "cab": "307207",
    "cap": "10020"
  },
  {
    "key": "10499",
    "ITA": "PECETTO TORINESE",
    "state_code": "TO",
    "cab": "307207",
    "cap": "10020"
  },
  {
    "key": "10500",
    "ITA": "PECORARA",
    "state_code": "PC",
    "cab": "938662",
    "cap": "29010"
  },
  {
    "key": "10501",
    "ITA": "PEDACE-PERITO",
    "state_code": "CS",
    "cab": "959155",
    "cap": "87050"
  },
  {
    "key": "10502",
    "ITA": "PEDACE",
    "state_code": "CS",
    "cab": "959155",
    "cap": "87050"
  },
  {
    "key": "10503",
    "ITA": "PEDARA",
    "state_code": "CT",
    "cab": "841205",
    "cap": "95030"
  },
  {
    "key": "10504",
    "ITA": "PEDASO",
    "state_code": "FM",
    "cab": "696401",
    "cap": "63016"
  },
  {
    "key": "10505",
    "ITA": "PEDAVENA-TRAVAGOLA",
    "state_code": "BL",
    "cab": "612200",
    "cap": "32034"
  },
  {
    "key": "10506",
    "ITA": "PEDAVENA-NORCEN",
    "state_code": "BL",
    "cab": "612200",
    "cap": "32034"
  },
  {
    "key": "10507",
    "ITA": "PEDAVENA-FACEN",
    "state_code": "BL",
    "cab": "612200",
    "cap": "32034"
  },
  {
    "key": "10508",
    "ITA": "PEDAVENA",
    "state_code": "BL",
    "cab": "612200",
    "cap": "32034"
  },
  {
    "key": "10509",
    "ITA": "PEDEMONTE",
    "state_code": "VI",
    "cab": "606301",
    "cap": "36040"
  },
  {
    "key": "10510",
    "ITA": "PEDEMONTE-CASOTTO",
    "state_code": "VI",
    "cab": "606301",
    "cap": "36040"
  },
  {
    "key": "10511",
    "ITA": "PEDEROBBA-COVOLO",
    "state_code": "TV",
    "cab": "619106",
    "cap": "31040"
  },
  {
    "key": "10512",
    "ITA": "PEDEROBBA-ONIGO",
    "state_code": "TV",
    "cab": "619106",
    "cap": "31040"
  },
  {
    "key": "10513",
    "ITA": "PEDEROBBA",
    "state_code": "TV",
    "cab": "619106",
    "cap": "31040"
  },
  {
    "key": "10514",
    "ITA": "PEDESINA",
    "state_code": "SO",
    "cab": "927178",
    "cap": "23010"
  },
  {
    "key": "10515",
    "ITA": "PEDIVIGLIANO",
    "state_code": "CS",
    "cab": "959163",
    "cap": "87050"
  },
  {
    "key": "10516",
    "ITA": "PEDIVIGLIANO-BORBORUSO",
    "state_code": "CS",
    "cab": "959163",
    "cap": "87050"
  },
  {
    "key": "10517",
    "ITA": "PEDRENGO",
    "state_code": "BG",
    "cab": "539403",
    "cap": "24066"
  },
  {
    "key": "10518",
    "ITA": "PEGLIO",
    "state_code": "CO",
    "cab": "925966",
    "cap": "22010"
  },
  {
    "key": "10519",
    "ITA": "PEGLIO",
    "state_code": "PU",
    "cab": "832204",
    "cap": "61049"
  },
  {
    "key": "10520",
    "ITA": "PEGOGNAGA-POLESINE",
    "state_code": "MN",
    "cab": "577700",
    "cap": "46020"
  },
  {
    "key": "10521",
    "ITA": "PEGOGNAGA",
    "state_code": "MN",
    "cab": "577700",
    "cap": "46020"
  },
  {
    "key": "10522",
    "ITA": "PEIA",
    "state_code": "BG",
    "cab": "533208",
    "cap": "24020"
  },
  {
    "key": "10523",
    "ITA": "PEIO-COGOLO",
    "state_code": "TN",
    "cab": "352005",
    "cap": "38024"
  },
  {
    "key": "10524",
    "ITA": "PEIO-CELLEDIZZO",
    "state_code": "TN",
    "cab": "352005",
    "cap": "38024"
  },
  {
    "key": "10525",
    "ITA": "PEIO",
    "state_code": "TN",
    "cab": "352005",
    "cap": "38024"
  },
  {
    "key": "10526",
    "ITA": "PELAGO-DIACCETO",
    "state_code": "FI",
    "cab": "380006",
    "cap": "50060"
  },
  {
    "key": "10527",
    "ITA": "PELAGO-CONSUMA",
    "state_code": "FI",
    "cab": "380006",
    "cap": "50060"
  },
  {
    "key": "10528",
    "ITA": "PELAGO-BORSELLI",
    "state_code": "FI",
    "cab": "380006",
    "cap": "50060"
  },
  {
    "key": "10529",
    "ITA": "PELAGO",
    "state_code": "FI",
    "cab": "380006",
    "cap": "50060"
  },
  {
    "key": "10530",
    "ITA": "PELLA-ALZO",
    "state_code": "NO",
    "cab": "455808",
    "cap": "28010"
  },
  {
    "key": "10531",
    "ITA": "PELLA",
    "state_code": "NO",
    "cab": "455808",
    "cap": "28010"
  },
  {
    "key": "10532",
    "ITA": "PELLEGRINO PARMENSE-MARIANO",
    "state_code": "PR",
    "cab": "658609",
    "cap": "43047"
  },
  {
    "key": "10533",
    "ITA": "PELLEGRINO PARMENSE-IGGIO",
    "state_code": "PR",
    "cab": "658609",
    "cap": "43047"
  },
  {
    "key": "10534",
    "ITA": "PELLEGRINO PARMENSE-GROTTA",
    "state_code": "PR",
    "cab": "658609",
    "cap": "43047"
  },
  {
    "key": "10535",
    "ITA": "PELLEGRINO PARMENSE",
    "state_code": "PR",
    "cab": "658609",
    "cap": "43047"
  },
  {
    "key": "10536",
    "ITA": "PELLEZZANO-COPERCHIA",
    "state_code": "SA",
    "cab": "896407",
    "cap": "84080"
  },
  {
    "key": "10537",
    "ITA": "PELLEZZANO-COLOGNA",
    "state_code": "SA",
    "cab": "896407",
    "cap": "84080"
  },
  {
    "key": "10538",
    "ITA": "PELLEZZANO-CAPRIGLIA",
    "state_code": "SA",
    "cab": "896407",
    "cap": "84080"
  },
  {
    "key": "10539",
    "ITA": "PELLEZZANO-CAPEZZANO INFERIORE",
    "state_code": "SA",
    "cab": "896407",
    "cap": "84080"
  },
  {
    "key": "10540",
    "ITA": "PELLEZZANO",
    "state_code": "SA",
    "cab": "896407",
    "cap": "84080"
  },
  {
    "key": "10541",
    "ITA": "PELLEZZANO-CAPEZZANO SUPERIORE",
    "state_code": "SA",
    "cab": "896407",
    "cap": "84080"
  },
  {
    "key": "10542",
    "ITA": "PELLIO INTELVI",
    "state_code": "CO",
    "cab": "925974",
    "cap": "22020"
  },
  {
    "key": "10543",
    "ITA": "PELLIO INTELVI-PELLIO",
    "state_code": "CO",
    "cab": "925974",
    "cap": "22020"
  },
  {
    "key": "10544",
    "ITA": "PELLIZZANO",
    "state_code": "TN",
    "cab": "352104",
    "cap": "38020"
  },
  {
    "key": "10545",
    "ITA": "PELUGO",
    "state_code": "TN",
    "cab": "904995",
    "cap": "38079"
  },
  {
    "key": "10546",
    "ITA": "PENANGO",
    "state_code": "AT",
    "cab": "920330",
    "cap": "14030"
  },
  {
    "key": "10547",
    "ITA": "PENNA IN TEVERINA",
    "state_code": "TR",
    "cab": "727305",
    "cap": "5028"
  },
  {
    "key": "10548",
    "ITA": "PENNA SAN GIOVANNI",
    "state_code": "MC",
    "cab": "690503",
    "cap": "62020"
  },
  {
    "key": "10549",
    "ITA": "PENNA SANT'ANDREA-VAL VOMANO",
    "state_code": "TE",
    "cab": "769802",
    "cap": "64039"
  },
  {
    "key": "10550",
    "ITA": "PENNA SANT'ANDREA",
    "state_code": "TE",
    "cab": "769802",
    "cap": "64039"
  },
  {
    "key": "10551",
    "ITA": "PENNABILLI",
    "state_code": "RN",
    "cab": "684803",
    "cap": "47864"
  },
  {
    "key": "10552",
    "ITA": "PENNABILLI-SOANNE",
    "state_code": "RN",
    "cab": "684803",
    "cap": "47864"
  },
  {
    "key": "10553",
    "ITA": "PENNABILLI-PONTE MESSA",
    "state_code": "RN",
    "cab": "684803",
    "cap": "47864"
  },
  {
    "key": "10554",
    "ITA": "PENNABILLI-MOLINO DI BASCIO",
    "state_code": "RN",
    "cab": "684803",
    "cap": "47864"
  },
  {
    "key": "10555",
    "ITA": "PENNABILLI-MACIANO",
    "state_code": "RN",
    "cab": "684803",
    "cap": "47864"
  },
  {
    "key": "10556",
    "ITA": "PENNADOMO",
    "state_code": "CH",
    "cab": "954859",
    "cap": "66040"
  },
  {
    "key": "10557",
    "ITA": "PENNAPIEDIMONTE",
    "state_code": "CH",
    "cab": "954867",
    "cap": "66010"
  },
  {
    "key": "10558",
    "ITA": "PENNE-ROCCAFINADAMO",
    "state_code": "PE",
    "cab": "773606",
    "cap": "65017"
  },
  {
    "key": "10559",
    "ITA": "PENNE",
    "state_code": "PE",
    "cab": "773606",
    "cap": "65017"
  },
  {
    "key": "10560",
    "ITA": "PENTONE",
    "state_code": "CZ",
    "cab": "913269",
    "cap": "88050"
  },
  {
    "key": "10561",
    "ITA": "PENTONE-SCOPPOLISE",
    "state_code": "CZ",
    "cab": "913269",
    "cap": "88050"
  },
  {
    "key": "10562",
    "ITA": "PERANO",
    "state_code": "CH",
    "cab": "954875",
    "cap": "66040"
  },
  {
    "key": "10563",
    "ITA": "PERAROLO DI CADORE",
    "state_code": "BL",
    "cab": "934703",
    "cap": "32010"
  },
  {
    "key": "10564",
    "ITA": "PERCA",
    "state_code": "BZ",
    "cab": "591503",
    "cap": "39030"
  },
  {
    "key": "10565",
    "ITA": "PERCILE",
    "state_code": "RM",
    "cab": "908251",
    "cap": "20"
  },
  {
    "key": "10566",
    "ITA": "PERDASDEFOGU",
    "state_code": "OG",
    "cab": "869107",
    "cap": "8046"
  },
  {
    "key": "10567",
    "ITA": "PERDAXIUS",
    "state_code": "SU",
    "cab": "860908",
    "cap": "9010"
  },
  {
    "key": "10568",
    "ITA": "PERDIFUMO-VATOLLA",
    "state_code": "SA",
    "cab": "952804",
    "cap": "84060"
  },
  {
    "key": "10569",
    "ITA": "PERDIFUMO-MERCATO CILENTO",
    "state_code": "SA",
    "cab": "952804",
    "cap": "84060"
  },
  {
    "key": "10570",
    "ITA": "PERDIFUMO",
    "state_code": "SA",
    "cab": "952804",
    "cap": "84060"
  },
  {
    "key": "10571",
    "ITA": "PEREGO",
    "state_code": "LC",
    "cab": "925982",
    "cap": "23888"
  },
  {
    "key": "10572",
    "ITA": "PERETO",
    "state_code": "AQ",
    "cab": "909812",
    "cap": "67064"
  },
  {
    "key": "10573",
    "ITA": "PERFUGAS",
    "state_code": "SS",
    "cab": "875302",
    "cap": "7034"
  },
  {
    "key": "10574",
    "ITA": "PERGINE VALDARNO-PIEVE A PRESCIANO",
    "state_code": "AR",
    "cab": "715508",
    "cap": "52020"
  },
  {
    "key": "10575",
    "ITA": "PERGINE VALDARNO",
    "state_code": "AR",
    "cab": "715508",
    "cap": "52020"
  },
  {
    "key": "10576",
    "ITA": "PERGINE VALDARNO-MONTALTO",
    "state_code": "AR",
    "cab": "715508",
    "cap": "52020"
  },
  {
    "key": "10577",
    "ITA": "PERGINE VALDARNO-CASALONE",
    "state_code": "AR",
    "cab": "715508",
    "cap": "52020"
  },
  {
    "key": "10578",
    "ITA": "PERGINE VALDARNO-CAVI",
    "state_code": "AR",
    "cab": "715508",
    "cap": "52020"
  },
  {
    "key": "10579",
    "ITA": "PERGINE VALDARNO-LATERINA STAZIONE",
    "state_code": "AR",
    "cab": "715508",
    "cap": "52020"
  },
  {
    "key": "10580",
    "ITA": "PERGINE VALSUGANA-RONCOGNO",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10581",
    "ITA": "PERGINE VALSUGANA-MADRANO",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10582",
    "ITA": "PERGINE VALSUGANA-ISCHIA TRENTINA",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10583",
    "ITA": "PERGINE VALSUGANA-COSTASAVINA",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10584",
    "ITA": "PERGINE VALSUGANA-CANZOLINO",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10585",
    "ITA": "PERGINE VALSUGANA-CANEZZA",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10586",
    "ITA": "PERGINE VALSUGANA-CANALE",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10587",
    "ITA": "PERGINE VALSUGANA",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10588",
    "ITA": "PERGINE VALSUGANA-SAN CRISTOFORO",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10589",
    "ITA": "PERGINE VALSUGANA-VIGALZANO",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10590",
    "ITA": "PERGINE VALSUGANA-VIARAGO",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10591",
    "ITA": "PERGINE VALSUGANA-SUSA'",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10592",
    "ITA": "PERGINE VALSUGANA-SERSO",
    "state_code": "TN",
    "cab": "352203",
    "cap": "38057"
  },
  {
    "key": "10593",
    "ITA": "PERGOLA",
    "state_code": "PU",
    "cab": "684902",
    "cap": "61045"
  },
  {
    "key": "10594",
    "ITA": "PERGOLA-BELLISIO SOLFARE",
    "state_code": "PU",
    "cab": "684902",
    "cap": "61045"
  },
  {
    "key": "10595",
    "ITA": "PERGOLA-MONTEROLO",
    "state_code": "PU",
    "cab": "684902",
    "cap": "61045"
  },
  {
    "key": "10596",
    "ITA": "PERINALDO",
    "state_code": "IM",
    "cab": "922518",
    "cap": "18032"
  },
  {
    "key": "10597",
    "ITA": "PERITO",
    "state_code": "SA",
    "cab": "952812",
    "cap": "84060"
  },
  {
    "key": "10598",
    "ITA": "PERITO-OSTIGLIANO",
    "state_code": "SA",
    "cab": "952812",
    "cap": "84060"
  },
  {
    "key": "10599",
    "ITA": "PERLEDO",
    "state_code": "LC",
    "cab": "925990",
    "cap": "23828"
  },
  {
    "key": "10600",
    "ITA": "PERLETTO",
    "state_code": "CN",
    "cab": "919118",
    "cap": "12070"
  },
  {
    "key": "10601",
    "ITA": "PERLO",
    "state_code": "CN",
    "cab": "919126",
    "cap": "12070"
  },
  {
    "key": "10602",
    "ITA": "PERLOZ",
    "state_code": "AO",
    "cab": "902528",
    "cap": "11020"
  },
  {
    "key": "10603",
    "ITA": "PERNUMIA",
    "state_code": "PD",
    "cab": "630509",
    "cap": "35020"
  },
  {
    "key": "10604",
    "ITA": "PERO",
    "state_code": "MI",
    "cab": "335604",
    "cap": "20016"
  },
  {
    "key": "10605",
    "ITA": "PERO-CERCHIATE",
    "state_code": "MI",
    "cab": "335604",
    "cap": "20016"
  },
  {
    "key": "10606",
    "ITA": "PEROSA ARGENTINA",
    "state_code": "TO",
    "cab": "307306",
    "cap": "10063"
  },
  {
    "key": "10607",
    "ITA": "PEROSA CANAVESE",
    "state_code": "TO",
    "cab": "284174",
    "cap": "10010"
  },
  {
    "key": "10608",
    "ITA": "PERRERO-RICLARETTO",
    "state_code": "TO",
    "cab": "430108",
    "cap": "10060"
  },
  {
    "key": "10609",
    "ITA": "PERRERO",
    "state_code": "TO",
    "cab": "430108",
    "cap": "10060"
  },
  {
    "key": "10610",
    "ITA": "PERSICO DOSIMO",
    "state_code": "CR",
    "cab": "569905",
    "cap": "26043"
  },
  {
    "key": "10611",
    "ITA": "PERSICO DOSIMO-DOSIMO",
    "state_code": "CR",
    "cab": "569905",
    "cap": "26043"
  },
  {
    "key": "10612",
    "ITA": "PERSICO DOSIMO-PERSICHELLO",
    "state_code": "CR",
    "cab": "569905",
    "cap": "26043"
  },
  {
    "key": "10613",
    "ITA": "PERTENGO",
    "state_code": "VC",
    "cab": "916080",
    "cap": "13030"
  },
  {
    "key": "10614",
    "ITA": "PERTICA ALTA",
    "state_code": "BS",
    "cab": "929604",
    "cap": "25070"
  },
  {
    "key": "10615",
    "ITA": "PERTICA ALTA-LIVEMMO",
    "state_code": "BS",
    "cab": "929604",
    "cap": "25070"
  },
  {
    "key": "10616",
    "ITA": "PERTICA BASSA",
    "state_code": "BS",
    "cab": "929612",
    "cap": "25078"
  },
  {
    "key": "10617",
    "ITA": "PERTICA BASSA-FORNO D'ONO",
    "state_code": "BS",
    "cab": "929612",
    "cap": "25078"
  },
  {
    "key": "10618",
    "ITA": "PERTOSA",
    "state_code": "SA",
    "cab": "952820",
    "cap": "84030"
  },
  {
    "key": "10619",
    "ITA": "PERTUSIO",
    "state_code": "TO",
    "cab": "284802",
    "cap": "10080"
  },
  {
    "key": "10620",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6127"
  },
  {
    "key": "10621",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6128"
  },
  {
    "key": "10622",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6129"
  },
  {
    "key": "10623",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6131"
  },
  {
    "key": "10624",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6132"
  },
  {
    "key": "10625",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6133"
  },
  {
    "key": "10626",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6134"
  },
  {
    "key": "10627",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6135"
  },
  {
    "key": "10628",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6126"
  },
  {
    "key": "10629",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6125"
  },
  {
    "key": "10630",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6124"
  },
  {
    "key": "10631",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6123"
  },
  {
    "key": "10632",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6121"
  },
  {
    "key": "10633",
    "ITA": "PERUGIA",
    "state_code": "PG",
    "cab": "30007",
    "cap": "6122"
  },
  {
    "key": "10634",
    "ITA": "PESARO-FIORENZUOLA DI FOCARA",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10635",
    "ITA": "PESARO-GINESTRETO",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10636",
    "ITA": "PESARO-NOVILARA",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10637",
    "ITA": "PESARO-PONTE DEL COLOMBARONE",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10638",
    "ITA": "PESARO-POZZO ALTO",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10639",
    "ITA": "PESARO-SANTA VENERANDA",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10640",
    "ITA": "PESARO-VILLA CECCOLINI",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10641",
    "ITA": "PESARO-VILLA FASTIGGI",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10642",
    "ITA": "PESARO-CATTABRIGHE",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10643",
    "ITA": "PESARO-CASE BRUCIATE",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10644",
    "ITA": "PESARO-CANDELARA",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10645",
    "ITA": "PESARO",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10646",
    "ITA": "PESARO-BORGO SANTA MARIA",
    "state_code": "PU",
    "cab": "133009",
    "cap": "61100"
  },
  {
    "key": "10647",
    "ITA": "PESCAGLIA-PIEGAIO BASSO",
    "state_code": "LU",
    "cab": "702001",
    "cap": "55064"
  },
  {
    "key": "10648",
    "ITA": "PESCAGLIA-VILLA A ROGGIO",
    "state_code": "LU",
    "cab": "702001",
    "cap": "55064"
  },
  {
    "key": "10649",
    "ITA": "PESCAGLIA-SAN ROCCO IN TURRITE",
    "state_code": "LU",
    "cab": "702001",
    "cap": "55064"
  },
  {
    "key": "10650",
    "ITA": "PESCAGLIA",
    "state_code": "LU",
    "cab": "702001",
    "cap": "55064"
  },
  {
    "key": "10651",
    "ITA": "PESCAGLIA-LOPPEGLIA",
    "state_code": "LU",
    "cab": "702001",
    "cap": "55060"
  },
  {
    "key": "10652",
    "ITA": "PESCAGLIA-PASCOSO",
    "state_code": "LU",
    "cab": "702001",
    "cap": "55064"
  },
  {
    "key": "10653",
    "ITA": "PESCAGLIA-SAN MARTINO IN FREDDANA",
    "state_code": "LU",
    "cab": "702001",
    "cap": "55060"
  },
  {
    "key": "10654",
    "ITA": "PESCANTINA",
    "state_code": "VR",
    "cab": "596502",
    "cap": "37026"
  },
  {
    "key": "10655",
    "ITA": "PESCANTINA-SETTIMO",
    "state_code": "VR",
    "cab": "596502",
    "cap": "37026"
  },
  {
    "key": "10656",
    "ITA": "PESCANTINA-OSPEDALETTO",
    "state_code": "VR",
    "cab": "596502",
    "cap": "37026"
  },
  {
    "key": "10657",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65124"
  },
  {
    "key": "10658",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65125"
  },
  {
    "key": "10659",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65126"
  },
  {
    "key": "10660",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65127"
  },
  {
    "key": "10661",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65128"
  },
  {
    "key": "10662",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65129"
  },
  {
    "key": "10663",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65123"
  },
  {
    "key": "10664",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65122"
  },
  {
    "key": "10665",
    "ITA": "PESCARA",
    "state_code": "PE",
    "cab": "154005",
    "cap": "65121"
  },
  {
    "key": "10666",
    "ITA": "PESCAROLO ED UNITI",
    "state_code": "CR",
    "cab": "570002",
    "cap": "26033"
  },
  {
    "key": "10667",
    "ITA": "PESCAROLO ED UNITI-PESCAROLO",
    "state_code": "CR",
    "cab": "570002",
    "cap": "26033"
  },
  {
    "key": "10668",
    "ITA": "PESCAROLO ED UNITI-PIEVE TERZAGNI",
    "state_code": "CR",
    "cab": "570002",
    "cap": "26033"
  },
  {
    "key": "10669",
    "ITA": "PESCASSEROLI",
    "state_code": "AQ",
    "cab": "406702",
    "cap": "67032"
  },
  {
    "key": "10670",
    "ITA": "PESCATE",
    "state_code": "LC",
    "cab": "655902",
    "cap": "23855"
  },
  {
    "key": "10671",
    "ITA": "PESCHE",
    "state_code": "IS",
    "cab": "955732",
    "cap": "86090"
  },
  {
    "key": "10672",
    "ITA": "PESCHICI",
    "state_code": "FG",
    "cab": "785204",
    "cap": "71010"
  },
  {
    "key": "10673",
    "ITA": "PESCHIERA BORROMEO-BELLARIA",
    "state_code": "MI",
    "cab": "335703",
    "cap": "20068"
  },
  {
    "key": "10674",
    "ITA": "PESCHIERA BORROMEO-ZELOFOROMAGNO",
    "state_code": "MI",
    "cab": "335703",
    "cap": "20068"
  },
  {
    "key": "10675",
    "ITA": "PESCHIERA BORROMEO-SAN BOVIO",
    "state_code": "MI",
    "cab": "335703",
    "cap": "20068"
  },
  {
    "key": "10676",
    "ITA": "PESCHIERA BORROMEO-MEZZATE",
    "state_code": "MI",
    "cab": "335703",
    "cap": "20068"
  },
  {
    "key": "10677",
    "ITA": "PESCHIERA BORROMEO-LINATE",
    "state_code": "MI",
    "cab": "335703",
    "cap": "20068"
  },
  {
    "key": "10678",
    "ITA": "PESCHIERA BORROMEO-BETTOLA",
    "state_code": "MI",
    "cab": "335703",
    "cap": "20068"
  },
  {
    "key": "10679",
    "ITA": "PESCHIERA BORROMEO",
    "state_code": "MI",
    "cab": "335703",
    "cap": "20068"
  },
  {
    "key": "10680",
    "ITA": "PESCHIERA DEL GARDA",
    "state_code": "VR",
    "cab": "596601",
    "cap": "37019"
  },
  {
    "key": "10681",
    "ITA": "PESCHIERA DEL GARDA-SAN BENEDETTO DI LUGANA",
    "state_code": "VR",
    "cab": "596601",
    "cap": "37019"
  },
  {
    "key": "10682",
    "ITA": "PESCIA-PIETRABUONA",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51017"
  },
  {
    "key": "10683",
    "ITA": "PESCIA",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51017"
  },
  {
    "key": "10684",
    "ITA": "PESCIA-CASTELLARE DI PESCIA",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51012"
  },
  {
    "key": "10685",
    "ITA": "PESCIA-CASTELVECCHIO",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51017"
  },
  {
    "key": "10686",
    "ITA": "PESCIA-COLLODI",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51012"
  },
  {
    "key": "10687",
    "ITA": "PESCIA-VELLANO",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51017"
  },
  {
    "key": "10688",
    "ITA": "PESCIA-PONTE ALL'ABATE",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51012"
  },
  {
    "key": "10689",
    "ITA": "PESCIA-PONTITO",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51017"
  },
  {
    "key": "10690",
    "ITA": "PESCIA-VENERI",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51012"
  },
  {
    "key": "10691",
    "ITA": "PESCIA-SAN QUIRICO",
    "state_code": "PT",
    "cab": "249003",
    "cap": "51017"
  },
  {
    "key": "10692",
    "ITA": "PESCINA",
    "state_code": "AQ",
    "cab": "406801",
    "cap": "67057"
  },
  {
    "key": "10693",
    "ITA": "PESCINA-VENERE",
    "state_code": "AQ",
    "cab": "406801",
    "cap": "67057"
  },
  {
    "key": "10694",
    "ITA": "PESCO SANNITA",
    "state_code": "BN",
    "cab": "781906",
    "cap": "82020"
  },
  {
    "key": "10695",
    "ITA": "PESCOCOSTANZO",
    "state_code": "AQ",
    "cab": "406900",
    "cap": "67033"
  },
  {
    "key": "10696",
    "ITA": "PESCOLANCIANO",
    "state_code": "IS",
    "cab": "955740",
    "cap": "86097"
  },
  {
    "key": "10697",
    "ITA": "PESCOPAGANO",
    "state_code": "PZ",
    "cab": "421602",
    "cap": "85020"
  },
  {
    "key": "10698",
    "ITA": "PESCOPENNATARO",
    "state_code": "IS",
    "cab": "955757",
    "cap": "86080"
  },
  {
    "key": "10699",
    "ITA": "PESCOROCCHIANO-PACE",
    "state_code": "RI",
    "cab": "737106",
    "cap": "2024"
  },
  {
    "key": "10700",
    "ITA": "PESCOROCCHIANO-LEOFRENI",
    "state_code": "RI",
    "cab": "737106",
    "cap": "2024"
  },
  {
    "key": "10701",
    "ITA": "PESCOROCCHIANO-CAMPOLANO",
    "state_code": "RI",
    "cab": "737106",
    "cap": "2020"
  },
  {
    "key": "10702",
    "ITA": "PESCOROCCHIANO-SANT'ELPIDIO",
    "state_code": "RI",
    "cab": "737106",
    "cap": "2024"
  },
  {
    "key": "10703",
    "ITA": "PESCOROCCHIANO",
    "state_code": "RI",
    "cab": "737106",
    "cap": "2024"
  },
  {
    "key": "10704",
    "ITA": "PESCOSANSONESCO-PESCONUOVO",
    "state_code": "PE",
    "cab": "954099",
    "cap": "65020"
  },
  {
    "key": "10705",
    "ITA": "PESCOSANSONESCO",
    "state_code": "PE",
    "cab": "954099",
    "cap": "65020"
  },
  {
    "key": "10706",
    "ITA": "PESCOSOLIDO",
    "state_code": "FR",
    "cab": "948422",
    "cap": "3030"
  },
  {
    "key": "10707",
    "ITA": "PESSANO CON BORNAGO-BORNAGO",
    "state_code": "MI",
    "cab": "341503",
    "cap": "20060"
  },
  {
    "key": "10708",
    "ITA": "PESSANO CON BORNAGO",
    "state_code": "MI",
    "cab": "341503",
    "cap": "20060"
  },
  {
    "key": "10709",
    "ITA": "PESSINA CREMONESE",
    "state_code": "CR",
    "cab": "270827",
    "cap": "26030"
  },
  {
    "key": "10710",
    "ITA": "PESSINETTO",
    "state_code": "TO",
    "cab": "666008",
    "cap": "10070"
  },
  {
    "key": "10711",
    "ITA": "PETACCIATO",
    "state_code": "CB",
    "cab": "674606",
    "cap": "86038"
  },
  {
    "key": "10712",
    "ITA": "PETACCIATO-COLLECALCIONI",
    "state_code": "CB",
    "cab": "674606",
    "cap": "86038"
  },
  {
    "key": "10713",
    "ITA": "PETILIA POLICASTRO-CAMELLINO",
    "state_code": "KR",
    "cab": "426700",
    "cap": "88837"
  },
  {
    "key": "10714",
    "ITA": "PETILIA POLICASTRO-PAGLIARELLE",
    "state_code": "KR",
    "cab": "426700",
    "cap": "88837"
  },
  {
    "key": "10715",
    "ITA": "PETILIA POLICASTRO",
    "state_code": "KR",
    "cab": "426700",
    "cap": "88837"
  },
  {
    "key": "10716",
    "ITA": "PETILIA POLICASTRO-FORESTA",
    "state_code": "KR",
    "cab": "426700",
    "cap": "88837"
  },
  {
    "key": "10717",
    "ITA": "PETINA",
    "state_code": "SA",
    "cab": "952838",
    "cap": "84020"
  },
  {
    "key": "10718",
    "ITA": "PETRALIA SOPRANA-RAFFO",
    "state_code": "PA",
    "cab": "435008",
    "cap": "90026"
  },
  {
    "key": "10719",
    "ITA": "PETRALIA SOPRANA-PIANELLO",
    "state_code": "PA",
    "cab": "435008",
    "cap": "90026"
  },
  {
    "key": "10720",
    "ITA": "PETRALIA SOPRANA",
    "state_code": "PA",
    "cab": "435008",
    "cap": "90026"
  },
  {
    "key": "10721",
    "ITA": "PETRALIA SOPRANA-FASANO'",
    "state_code": "PA",
    "cab": "435008",
    "cap": "90026"
  },
  {
    "key": "10722",
    "ITA": "PETRALIA SOTTANA",
    "state_code": "PA",
    "cab": "435107",
    "cap": "90027"
  },
  {
    "key": "10723",
    "ITA": "PETRELLA SALTO-FIUMATA",
    "state_code": "RI",
    "cab": "737205",
    "cap": "2025"
  },
  {
    "key": "10724",
    "ITA": "PETRELLA SALTO-CASTELMARERI",
    "state_code": "RI",
    "cab": "737205",
    "cap": "2025"
  },
  {
    "key": "10725",
    "ITA": "PETRELLA SALTO-CAPRADOSSO",
    "state_code": "RI",
    "cab": "737205",
    "cap": "2025"
  },
  {
    "key": "10726",
    "ITA": "PETRELLA SALTO",
    "state_code": "RI",
    "cab": "737205",
    "cap": "2025"
  },
  {
    "key": "10727",
    "ITA": "PETRELLA SALTO-BORGO SAN PIETRO",
    "state_code": "RI",
    "cab": "737205",
    "cap": "2025"
  },
  {
    "key": "10728",
    "ITA": "PETRELLA TIFERNINA",
    "state_code": "CB",
    "cab": "687400",
    "cap": "86024"
  },
  {
    "key": "10729",
    "ITA": "PETRIANO-GALLO",
    "state_code": "PU",
    "cab": "685008",
    "cap": "61020"
  },
  {
    "key": "10730",
    "ITA": "PETRIANO",
    "state_code": "PU",
    "cab": "685008",
    "cap": "61020"
  },
  {
    "key": "10731",
    "ITA": "PETRIOLO",
    "state_code": "MC",
    "cab": "690602",
    "cap": "62014"
  },
  {
    "key": "10732",
    "ITA": "PETRITOLI-MOREGNANO",
    "state_code": "FM",
    "cab": "696500",
    "cap": "63027"
  },
  {
    "key": "10733",
    "ITA": "PETRITOLI",
    "state_code": "FM",
    "cab": "696500",
    "cap": "63027"
  },
  {
    "key": "10734",
    "ITA": "PETRIZZI",
    "state_code": "CZ",
    "cab": "913277",
    "cap": "88060"
  },
  {
    "key": "10735",
    "ITA": "PETRONA'",
    "state_code": "CZ",
    "cab": "887604",
    "cap": "88050"
  },
  {
    "key": "10736",
    "ITA": "PETROSINO",
    "state_code": "TP",
    "cab": "817700",
    "cap": "91020"
  },
  {
    "key": "10737",
    "ITA": "PETRURO IRPINO",
    "state_code": "AV",
    "cab": "951434",
    "cap": "83010"
  },
  {
    "key": "10738",
    "ITA": "PETTENASCO",
    "state_code": "NO",
    "cab": "458109",
    "cap": "28028"
  },
  {
    "key": "10739",
    "ITA": "PETTENASCO-PRATOLUNGO",
    "state_code": "NO",
    "cab": "458109",
    "cap": "28028"
  },
  {
    "key": "10740",
    "ITA": "PETTINENGO-VAGLIO",
    "state_code": "BI",
    "cab": "446401",
    "cap": "13843"
  },
  {
    "key": "10741",
    "ITA": "PETTINENGO",
    "state_code": "BI",
    "cab": "446401",
    "cap": "13843"
  },
  {
    "key": "10742",
    "ITA": "PETTINEO",
    "state_code": "ME",
    "cab": "826909",
    "cap": "98070"
  },
  {
    "key": "10743",
    "ITA": "PETTORANELLO DEL MOLISE",
    "state_code": "IS",
    "cab": "955765",
    "cap": "86090"
  },
  {
    "key": "10744",
    "ITA": "PETTORANO SUL GIZIO",
    "state_code": "AQ",
    "cab": "909820",
    "cap": "67034"
  },
  {
    "key": "10745",
    "ITA": "PETTORAZZA GRIMANI",
    "state_code": "RO",
    "cab": "634006",
    "cap": "45010"
  },
  {
    "key": "10746",
    "ITA": "PEVERAGNO-SAN LORENZO PEVERAGNO",
    "state_code": "CN",
    "cab": "466102",
    "cap": "12016"
  },
  {
    "key": "10747",
    "ITA": "PEVERAGNO",
    "state_code": "CN",
    "cab": "466102",
    "cap": "12016"
  },
  {
    "key": "10748",
    "ITA": "PEVERAGNO-SANTA MARGHERITA",
    "state_code": "CN",
    "cab": "466102",
    "cap": "12016"
  },
  {
    "key": "10749",
    "ITA": "PEZZANA",
    "state_code": "VC",
    "cab": "446500",
    "cap": "13010"
  },
  {
    "key": "10750",
    "ITA": "PEZZAZE-LAVONE",
    "state_code": "BS",
    "cab": "555805",
    "cap": "25060"
  },
  {
    "key": "10751",
    "ITA": "PEZZAZE-STRAVIGNINO",
    "state_code": "BS",
    "cab": "555805",
    "cap": "25060"
  },
  {
    "key": "10752",
    "ITA": "PEZZAZE",
    "state_code": "BS",
    "cab": "555805",
    "cap": "25060"
  },
  {
    "key": "10753",
    "ITA": "PEZZOLO VALLE UZZONE",
    "state_code": "CN",
    "cab": "919134",
    "cap": "12070"
  },
  {
    "key": "10754",
    "ITA": "PIACENZA-BORGO TREBBIA",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10755",
    "ITA": "PIACENZA-GERBIDO",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10756",
    "ITA": "PIACENZA-MORTIZZA",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10757",
    "ITA": "PIACENZA",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10758",
    "ITA": "PIACENZA-BAIA DEL RE",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10759",
    "ITA": "PIACENZA-VERZA",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10760",
    "ITA": "PIACENZA-SANT'ANTONIO",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10761",
    "ITA": "PIACENZA-SAN LAZZARO ALBERONI",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10762",
    "ITA": "PIACENZA-SAN BONICO",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10763",
    "ITA": "PIACENZA-RONCAGLIA",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10764",
    "ITA": "PIACENZA-RAFFAELLINA",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10765",
    "ITA": "PIACENZA-PITTOLO",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10766",
    "ITA": "PIACENZA-MUCINASSO",
    "state_code": "PC",
    "cab": "126003",
    "cap": "29100"
  },
  {
    "key": "10767",
    "ITA": "PIACENZA D'ADIGE-VALLI MOCENIGHE",
    "state_code": "PD",
    "cab": "627109",
    "cap": "35040"
  },
  {
    "key": "10768",
    "ITA": "PIACENZA D'ADIGE",
    "state_code": "PD",
    "cab": "627109",
    "cap": "35040"
  },
  {
    "key": "10769",
    "ITA": "PIADENA",
    "state_code": "CR",
    "cab": "570101",
    "cap": "26034"
  },
  {
    "key": "10770",
    "ITA": "PIAGGE",
    "state_code": "PU",
    "cab": "685107",
    "cap": "61030"
  },
  {
    "key": "10771",
    "ITA": "PIAGGINE",
    "state_code": "SA",
    "cab": "364000",
    "cap": "84065"
  },
  {
    "key": "10772",
    "ITA": "PIAN CAMUNO",
    "state_code": "BS",
    "cab": "549501",
    "cap": "25050"
  },
  {
    "key": "10773",
    "ITA": "PIAN DI SCO-FAELLA",
    "state_code": "AR",
    "cab": "715607",
    "cap": "52026"
  },
  {
    "key": "10774",
    "ITA": "PIAN DI SCO-VAGGIO",
    "state_code": "AR",
    "cab": "715607",
    "cap": "52026"
  },
  {
    "key": "10775",
    "ITA": "PIAN DI SCO",
    "state_code": "AR",
    "cab": "715607",
    "cap": "52026"
  },
  {
    "key": "10776",
    "ITA": "PIANA CRIXIA",
    "state_code": "SV",
    "cab": "923243",
    "cap": "17058"
  },
  {
    "key": "10777",
    "ITA": "PIANA DEGLI ALBANESI",
    "state_code": "PA",
    "cab": "435206",
    "cap": "90037"
  },
  {
    "key": "10778",
    "ITA": "PIANA DI MONTE VERNA",
    "state_code": "CE",
    "cab": "404202",
    "cap": "81013"
  },
  {
    "key": "10779",
    "ITA": "PIANA DI MONTE VERNA-VILLA SANTA CROCE",
    "state_code": "CE",
    "cab": "404202",
    "cap": "81013"
  },
  {
    "key": "10780",
    "ITA": "PIANCASTAGNAIO-SARAGIOLO",
    "state_code": "SI",
    "cab": "719203",
    "cap": "53025"
  },
  {
    "key": "10781",
    "ITA": "PIANCASTAGNAIO",
    "state_code": "SI",
    "cab": "719203",
    "cap": "53025"
  },
  {
    "key": "10782",
    "ITA": "PIANCOGNO",
    "state_code": "BS",
    "cab": "554709",
    "cap": "25052"
  },
  {
    "key": "10783",
    "ITA": "PIANCOGNO-ANNUNCIATA",
    "state_code": "BS",
    "cab": "554709",
    "cap": "25052"
  },
  {
    "key": "10784",
    "ITA": "PIANCOGNO-COGNO",
    "state_code": "BS",
    "cab": "554709",
    "cap": "25052"
  },
  {
    "key": "10785",
    "ITA": "PIANCOGNO-PIAN DI BORNO",
    "state_code": "BS",
    "cab": "554709",
    "cap": "25052"
  },
  {
    "key": "10786",
    "ITA": "PIANDIMELETO-MONASTERO",
    "state_code": "PU",
    "cab": "685206",
    "cap": "61026"
  },
  {
    "key": "10787",
    "ITA": "PIANDIMELETO-SAN SISTO",
    "state_code": "PU",
    "cab": "685206",
    "cap": "61026"
  },
  {
    "key": "10788",
    "ITA": "PIANDIMELETO",
    "state_code": "PU",
    "cab": "685206",
    "cap": "61026"
  },
  {
    "key": "10789",
    "ITA": "PIANE CRATI",
    "state_code": "CS",
    "cab": "959171",
    "cap": "87050"
  },
  {
    "key": "10790",
    "ITA": "PIANELLA",
    "state_code": "PE",
    "cab": "773705",
    "cap": "65019"
  },
  {
    "key": "10791",
    "ITA": "PIANELLA-CASTELLANA",
    "state_code": "PE",
    "cab": "773705",
    "cap": "65019"
  },
  {
    "key": "10792",
    "ITA": "PIANELLA-CERRATINA",
    "state_code": "PE",
    "cab": "773705",
    "cap": "65019"
  },
  {
    "key": "10793",
    "ITA": "PIANELLO DEL LARIO",
    "state_code": "CO",
    "cab": "926014",
    "cap": "22010"
  },
  {
    "key": "10794",
    "ITA": "PIANELLO DEL LARIO-CALOZZO",
    "state_code": "CO",
    "cab": "926014",
    "cap": "22010"
  },
  {
    "key": "10795",
    "ITA": "PIANELLO VAL TIDONE",
    "state_code": "PC",
    "cab": "654004",
    "cap": "29010"
  },
  {
    "key": "10796",
    "ITA": "PIANENGO",
    "state_code": "CR",
    "cab": "570200",
    "cap": "26010"
  },
  {
    "key": "10797",
    "ITA": "PIANEZZA",
    "state_code": "TO",
    "cab": "307405",
    "cap": "10044"
  },
  {
    "key": "10798",
    "ITA": "PIANEZZE",
    "state_code": "VI",
    "cab": "479204",
    "cap": "36060"
  },
  {
    "key": "10799",
    "ITA": "PIANFEI",
    "state_code": "CN",
    "cab": "466201",
    "cap": "12080"
  },
  {
    "key": "10800",
    "ITA": "PIANICO",
    "state_code": "BG",
    "cab": "894709",
    "cap": "24060"
  },
  {
    "key": "10801",
    "ITA": "PIANIGA-CAZZAGO",
    "state_code": "VE",
    "cab": "362301",
    "cap": "30030"
  },
  {
    "key": "10802",
    "ITA": "PIANIGA",
    "state_code": "VE",
    "cab": "362301",
    "cap": "30030"
  },
  {
    "key": "10803",
    "ITA": "PIANO DI SORRENTO",
    "state_code": "NA",
    "cab": "400507",
    "cap": "80063"
  },
  {
    "key": "10804",
    "ITA": "PIANOPOLI",
    "state_code": "CZ",
    "cab": "426809",
    "cap": "88040"
  },
  {
    "key": "10805",
    "ITA": "PIANORO-PIANORO VECCHIO",
    "state_code": "BO",
    "cab": "370007",
    "cap": "40060"
  },
  {
    "key": "10806",
    "ITA": "PIANORO-LIVERGNANO",
    "state_code": "BO",
    "cab": "370007",
    "cap": "40060"
  },
  {
    "key": "10807",
    "ITA": "PIANORO-RASTIGNANO",
    "state_code": "BO",
    "cab": "370007",
    "cap": "40067"
  },
  {
    "key": "10808",
    "ITA": "PIANORO",
    "state_code": "BO",
    "cab": "370007",
    "cap": "40065"
  },
  {
    "key": "10809",
    "ITA": "PIANSANO",
    "state_code": "VT",
    "cab": "732305",
    "cap": "1010"
  },
  {
    "key": "10810",
    "ITA": "PIANTEDO",
    "state_code": "SO",
    "cab": "700401",
    "cap": "23010"
  },
  {
    "key": "10811",
    "ITA": "PIARIO",
    "state_code": "BG",
    "cab": "928457",
    "cap": "24020"
  },
  {
    "key": "10812",
    "ITA": "PIASCO",
    "state_code": "CN",
    "cab": "466300",
    "cap": "12026"
  },
  {
    "key": "10813",
    "ITA": "PIATEDA",
    "state_code": "SO",
    "cab": "835603",
    "cap": "23020"
  },
  {
    "key": "10814",
    "ITA": "PIATEDA-BOFFETTO",
    "state_code": "SO",
    "cab": "835603",
    "cap": "23020"
  },
  {
    "key": "10815",
    "ITA": "PIATTO",
    "state_code": "BI",
    "cab": "916098",
    "cap": "13844"
  },
  {
    "key": "10816",
    "ITA": "PIAZZA AL SERCHIO-SAN MICHELE",
    "state_code": "LU",
    "cab": "702100",
    "cap": "55035"
  },
  {
    "key": "10817",
    "ITA": "PIAZZA AL SERCHIO",
    "state_code": "LU",
    "cab": "702100",
    "cap": "55035"
  },
  {
    "key": "10818",
    "ITA": "PIAZZA AL SERCHIO-SANT'ANASTASIO",
    "state_code": "LU",
    "cab": "702100",
    "cap": "55035"
  },
  {
    "key": "10819",
    "ITA": "PIAZZA ARMENIA",
    "state_code": "EN",
    "cab": "836900",
    "cap": "94015"
  },
  {
    "key": "10820",
    "ITA": "PIAZZA ARMERINA-GROTTACALDA",
    "state_code": "EN",
    "cab": "836908",
    "cap": "94015"
  },
  {
    "key": "10821",
    "ITA": "PIAZZA ARMERINA",
    "state_code": "EN",
    "cab": "836908",
    "cap": "94015"
  },
  {
    "key": "10822",
    "ITA": "PIAZZA BREMBANA",
    "state_code": "BG",
    "cab": "533307",
    "cap": "24014"
  },
  {
    "key": "10823",
    "ITA": "PIAZZATORRE",
    "state_code": "BG",
    "cab": "533406",
    "cap": "24010"
  },
  {
    "key": "10824",
    "ITA": "PIAZZOLA SUL BRENTA-VACCARINO",
    "state_code": "PD",
    "cab": "627208",
    "cap": "35016"
  },
  {
    "key": "10825",
    "ITA": "PIAZZOLA SUL BRENTA",
    "state_code": "PD",
    "cab": "627208",
    "cap": "35016"
  },
  {
    "key": "10826",
    "ITA": "PIAZZOLA SUL BRENTA-PRESINA",
    "state_code": "PD",
    "cab": "627208",
    "cap": "35016"
  },
  {
    "key": "10827",
    "ITA": "PIAZZOLA SUL BRENTA-TREMIGNON",
    "state_code": "PD",
    "cab": "627208",
    "cap": "35016"
  },
  {
    "key": "10828",
    "ITA": "PIAZZOLO",
    "state_code": "BG",
    "cab": "928465",
    "cap": "24010"
  },
  {
    "key": "10829",
    "ITA": "PICCIANO-PICCIANELLO",
    "state_code": "PE",
    "cab": "773804",
    "cap": "65010"
  },
  {
    "key": "10830",
    "ITA": "PICCIANO",
    "state_code": "PE",
    "cab": "773804",
    "cap": "65010"
  },
  {
    "key": "10831",
    "ITA": "PICERNO",
    "state_code": "PZ",
    "cab": "421701",
    "cap": "85055"
  },
  {
    "key": "10832",
    "ITA": "PICINISCO",
    "state_code": "FR",
    "cab": "288142",
    "cap": "3040"
  },
  {
    "key": "10833",
    "ITA": "PICO",
    "state_code": "FR",
    "cab": "746503",
    "cap": "3020"
  },
  {
    "key": "10834",
    "ITA": "PIEA",
    "state_code": "AT",
    "cab": "920348",
    "cap": "14020"
  },
  {
    "key": "10835",
    "ITA": "PIEDICAVALLO",
    "state_code": "BI",
    "cab": "916106",
    "cap": "13812"
  },
  {
    "key": "10836",
    "ITA": "PIEDICAVALLO-MONTESINARO",
    "state_code": "BI",
    "cab": "916106",
    "cap": "13812"
  },
  {
    "key": "10837",
    "ITA": "PIEDIMONTE ETNEO-PRESA",
    "state_code": "CT",
    "cab": "841304",
    "cap": "95017"
  },
  {
    "key": "10838",
    "ITA": "PIEDIMONTE ETNEO",
    "state_code": "CT",
    "cab": "841304",
    "cap": "95017"
  },
  {
    "key": "10839",
    "ITA": "PIEDIMONTE ETNEO-VENA",
    "state_code": "CT",
    "cab": "841304",
    "cap": "95017"
  },
  {
    "key": "10840",
    "ITA": "PIEDIMONTE MATESE-SEPICCIANO",
    "state_code": "CE",
    "cab": "749408",
    "cap": "81016"
  },
  {
    "key": "10841",
    "ITA": "PIEDIMONTE MATESE-PIEDIMONTE D'ALIFE",
    "state_code": "CE",
    "cab": "749408",
    "cap": "81016"
  },
  {
    "key": "10842",
    "ITA": "PIEDIMONTE MATESE",
    "state_code": "CE",
    "cab": "749408",
    "cap": "81016"
  },
  {
    "key": "10843",
    "ITA": "PIEDIMONTE SAN GERMANO-PIEDIMONTE SAN GERMANO ALTA",
    "state_code": "FR",
    "cab": "745109",
    "cap": "3030"
  },
  {
    "key": "10844",
    "ITA": "PIEDIMONTE SAN GERMANO",
    "state_code": "FR",
    "cab": "745109",
    "cap": "3030"
  },
  {
    "key": "10845",
    "ITA": "PIEDIMULERA",
    "state_code": "VB",
    "cab": "455907",
    "cap": "28885"
  },
  {
    "key": "10846",
    "ITA": "PIEGARO-PIETRAFITTA",
    "state_code": "PG",
    "cab": "386102",
    "cap": "6066"
  },
  {
    "key": "10847",
    "ITA": "PIEGARO-CASTIGLION FOSCO",
    "state_code": "PG",
    "cab": "386102",
    "cap": "6066"
  },
  {
    "key": "10848",
    "ITA": "PIEGARO",
    "state_code": "PG",
    "cab": "386102",
    "cap": "6066"
  },
  {
    "key": "10849",
    "ITA": "PIENZA-MONTICCHIELLO",
    "state_code": "SI",
    "cab": "719302",
    "cap": "53026"
  },
  {
    "key": "10850",
    "ITA": "PIENZA",
    "state_code": "SI",
    "cab": "719302",
    "cap": "53026"
  },
  {
    "key": "10851",
    "ITA": "PIERANICA",
    "state_code": "CR",
    "cab": "573105",
    "cap": "26017"
  },
  {
    "key": "10852",
    "ITA": "PIETRA DE' GIORGI",
    "state_code": "PV",
    "cab": "930693",
    "cap": "27040"
  },
  {
    "key": "10853",
    "ITA": "PIETRA LIGURE",
    "state_code": "SV",
    "cab": "494708",
    "cap": "17027"
  },
  {
    "key": "10854",
    "ITA": "PIETRA MARAZZI-PAVONE D'ALESSANDRIA",
    "state_code": "AL",
    "cab": "921528",
    "cap": "15040"
  },
  {
    "key": "10855",
    "ITA": "PIETRA MARAZZI",
    "state_code": "AL",
    "cab": "921528",
    "cap": "15040"
  },
  {
    "key": "10856",
    "ITA": "PIETRABBONDANTE",
    "state_code": "IS",
    "cab": "955773",
    "cap": "86085"
  },
  {
    "key": "10857",
    "ITA": "PIETRABRUNA-BOSCOMARE",
    "state_code": "IM",
    "cab": "922526",
    "cap": "18010"
  },
  {
    "key": "10858",
    "ITA": "PIETRABRUNA",
    "state_code": "IM",
    "cab": "922526",
    "cap": "18010"
  },
  {
    "key": "10859",
    "ITA": "PIETRACAMELA",
    "state_code": "TE",
    "cab": "769901",
    "cap": "64047"
  },
  {
    "key": "10860",
    "ITA": "PIETRACAMELA-INTERMESOLI",
    "state_code": "TE",
    "cab": "769901",
    "cap": "64047"
  },
  {
    "key": "10861",
    "ITA": "PIETRACATELLA",
    "state_code": "CB",
    "cab": "885202",
    "cap": "86040"
  },
  {
    "key": "10862",
    "ITA": "PIETRACUPA",
    "state_code": "CB",
    "cab": "910810",
    "cap": "86020"
  },
  {
    "key": "10863",
    "ITA": "PIETRADEFUSI",
    "state_code": "AV",
    "cab": "951442",
    "cap": "83030"
  },
  {
    "key": "10864",
    "ITA": "PIETRADEFUSI-SANT'ELENA IRPINA",
    "state_code": "AV",
    "cab": "951442",
    "cap": "83030"
  },
  {
    "key": "10865",
    "ITA": "PIETRADEFUSI-SANT'ANGELO A CANCELLI",
    "state_code": "AV",
    "cab": "951442",
    "cap": "83030"
  },
  {
    "key": "10866",
    "ITA": "PIETRADEFUSI-DENTECANE",
    "state_code": "AV",
    "cab": "951442",
    "cap": "83030"
  },
  {
    "key": "10867",
    "ITA": "PIETRAFERRAZZANA",
    "state_code": "CH",
    "cab": "955138",
    "cap": "66040"
  },
  {
    "key": "10868",
    "ITA": "PIETRAFITTA",
    "state_code": "CS",
    "cab": "808600",
    "cap": "87050"
  },
  {
    "key": "10869",
    "ITA": "PIETRAGALLA-SAN NICOLA",
    "state_code": "PZ",
    "cab": "421800",
    "cap": "85020"
  },
  {
    "key": "10870",
    "ITA": "PIETRAGALLA-SAN GIORGIO",
    "state_code": "PZ",
    "cab": "421800",
    "cap": "85020"
  },
  {
    "key": "10871",
    "ITA": "PIETRAGALLA",
    "state_code": "PZ",
    "cab": "421800",
    "cap": "85016"
  },
  {
    "key": "10872",
    "ITA": "PIETRALUNGA",
    "state_code": "PG",
    "cab": "386201",
    "cap": "6026"
  },
  {
    "key": "10873",
    "ITA": "PIETRAMELARA",
    "state_code": "CE",
    "cab": "749507",
    "cap": "81051"
  },
  {
    "key": "10874",
    "ITA": "PIETRAMONTECORVINO",
    "state_code": "FG",
    "cab": "785303",
    "cap": "71038"
  },
  {
    "key": "10875",
    "ITA": "PIETRANICO",
    "state_code": "PE",
    "cab": "954107",
    "cap": "65020"
  },
  {
    "key": "10876",
    "ITA": "PIETRAPAOLA",
    "state_code": "CS",
    "cab": "959189",
    "cap": "87060"
  },
  {
    "key": "10877",
    "ITA": "PIETRAPAOLA-VECCHIARELLO",
    "state_code": "CS",
    "cab": "959189",
    "cap": "87060"
  },
  {
    "key": "10878",
    "ITA": "PIETRAPAOLA-CAMIGLIANO",
    "state_code": "CS",
    "cab": "959189",
    "cap": "87060"
  },
  {
    "key": "10879",
    "ITA": "PIETRAPAOLA-PIETRAPAOLA STAZIONE",
    "state_code": "CS",
    "cab": "959189",
    "cap": "87060"
  },
  {
    "key": "10880",
    "ITA": "PIETRAPERTOSA",
    "state_code": "PZ",
    "cab": "491803",
    "cap": "85010"
  },
  {
    "key": "10881",
    "ITA": "PIETRAPERZIA",
    "state_code": "EN",
    "cab": "837005",
    "cap": "94016"
  },
  {
    "key": "10882",
    "ITA": "PIETRAPORZIO",
    "state_code": "CN",
    "cab": "919142",
    "cap": "12010"
  },
  {
    "key": "10883",
    "ITA": "PIETRAROJA",
    "state_code": "BN",
    "cab": "950436",
    "cap": "82030"
  },
  {
    "key": "10884",
    "ITA": "PIETRARUBBIA",
    "state_code": "PU",
    "cab": "941518",
    "cap": "61023"
  },
  {
    "key": "10885",
    "ITA": "PIETRASANTA",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10886",
    "ITA": "PIETRASANTA-VALDICASTELLO CARDUCCI",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10887",
    "ITA": "PIETRASANTA-VALLECCHIA",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10888",
    "ITA": "PIETRASANTA-CAPEZZANO",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10889",
    "ITA": "PIETRASANTA-CAPRIGLIA",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10890",
    "ITA": "PIETRASANTA-CROCIALE",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10891",
    "ITA": "PIETRASANTA-FIUMETTO",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10892",
    "ITA": "PIETRASANTA-FOCETTE",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10893",
    "ITA": "PIETRASANTA-MARINA DI PIETRASANTA",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10894",
    "ITA": "PIETRASANTA-STRETTOIA",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10895",
    "ITA": "PIETRASANTA-TONFANO",
    "state_code": "LU",
    "cab": "702209",
    "cap": "55045"
  },
  {
    "key": "10896",
    "ITA": "PIETRASTORNINA",
    "state_code": "AV",
    "cab": "951459",
    "cap": "83015"
  },
  {
    "key": "10897",
    "ITA": "PIETRASTORNINA-CIARDELLI INFERIORE",
    "state_code": "AV",
    "cab": "951459",
    "cap": "83015"
  },
  {
    "key": "10898",
    "ITA": "PIETRAVAIRANO-SAN FELICE",
    "state_code": "CE",
    "cab": "749606",
    "cap": "81040"
  },
  {
    "key": "10899",
    "ITA": "PIETRAVAIRANO-BORGO SANT'ANTONIO ABATE",
    "state_code": "CE",
    "cab": "749606",
    "cap": "81040"
  },
  {
    "key": "10900",
    "ITA": "PIETRAVAIRANO",
    "state_code": "CE",
    "cab": "749606",
    "cap": "81040"
  },
  {
    "key": "10901",
    "ITA": "PIETRELCINA",
    "state_code": "BN",
    "cab": "755900",
    "cap": "82020"
  },
  {
    "key": "10902",
    "ITA": "PIEVE A NIEVOLE",
    "state_code": "PT",
    "cab": "704700",
    "cap": "51018"
  },
  {
    "key": "10903",
    "ITA": "PIEVE ALBIGNOLA",
    "state_code": "PV",
    "cab": "930701",
    "cap": "27030"
  },
  {
    "key": "10904",
    "ITA": "PIEVE D'ALPAGO-TIGNES",
    "state_code": "BL",
    "cab": "882506",
    "cap": "32010"
  },
  {
    "key": "10905",
    "ITA": "PIEVE D'ALPAGO-GARNA",
    "state_code": "BL",
    "cab": "882506",
    "cap": "32010"
  },
  {
    "key": "10906",
    "ITA": "PIEVE D'ALPAGO",
    "state_code": "BL",
    "cab": "882506",
    "cap": "32010"
  },
  {
    "key": "10907",
    "ITA": "PIEVE D'OLMI",
    "state_code": "CR",
    "cab": "570309",
    "cap": "26040"
  },
  {
    "key": "10908",
    "ITA": "PIEVE DEL CAIRO",
    "state_code": "PV",
    "cab": "561100",
    "cap": "27037"
  },
  {
    "key": "10909",
    "ITA": "PIEVE DI BONO",
    "state_code": "TN",
    "cab": "352302",
    "cap": "38085"
  },
  {
    "key": "10910",
    "ITA": "PIEVE DI BONO-CRETO",
    "state_code": "TN",
    "cab": "352302",
    "cap": "38085"
  },
  {
    "key": "10911",
    "ITA": "PIEVE DI CADORE-SOTTOCASTELLO",
    "state_code": "BL",
    "cab": "612309",
    "cap": "32044"
  },
  {
    "key": "10912",
    "ITA": "PIEVE DI CADORE-TAI DI CADORE",
    "state_code": "BL",
    "cab": "612309",
    "cap": "32044"
  },
  {
    "key": "10913",
    "ITA": "PIEVE DI CADORE-POZZALE",
    "state_code": "BL",
    "cab": "612309",
    "cap": "32044"
  },
  {
    "key": "10914",
    "ITA": "PIEVE DI CADORE",
    "state_code": "BL",
    "cab": "612309",
    "cap": "32044"
  },
  {
    "key": "10915",
    "ITA": "PIEVE DI CENTO",
    "state_code": "BO",
    "cab": "370106",
    "cap": "40066"
  },
  {
    "key": "10916",
    "ITA": "PIEVE DI CORIANO",
    "state_code": "MN",
    "cab": "450601",
    "cap": "46020"
  },
  {
    "key": "10917",
    "ITA": "PIEVE DI LEDRO-MEZZOLAGO",
    "state_code": "TN",
    "cab": "352401",
    "cap": "38060"
  },
  {
    "key": "10918",
    "ITA": "PIEVE DI LEDRO",
    "state_code": "TN",
    "cab": "352401",
    "cap": "38060"
  },
  {
    "key": "10919",
    "ITA": "PIEVE DI SOLIGO-SOLIGHETTO",
    "state_code": "TV",
    "cab": "619205",
    "cap": "31053"
  },
  {
    "key": "10920",
    "ITA": "PIEVE DI SOLIGO",
    "state_code": "TV",
    "cab": "619205",
    "cap": "31053"
  },
  {
    "key": "10921",
    "ITA": "PIEVE DI SOLIGO-BARBISANO",
    "state_code": "TV",
    "cab": "619205",
    "cap": "31053"
  },
  {
    "key": "10922",
    "ITA": "PIEVE DI TECO",
    "state_code": "IM",
    "cab": "490300",
    "cap": "18026"
  },
  {
    "key": "10923",
    "ITA": "PIEVE DI TECO-CALDERARA",
    "state_code": "IM",
    "cab": "490300",
    "cap": "18026"
  },
  {
    "key": "10924",
    "ITA": "PIEVE EMANUELE-FIZZONASCO",
    "state_code": "MI",
    "cab": "335802",
    "cap": "20090"
  },
  {
    "key": "10925",
    "ITA": "PIEVE EMANUELE",
    "state_code": "MI",
    "cab": "335802",
    "cap": "20090"
  },
  {
    "key": "10926",
    "ITA": "PIEVE FISSIRAGA",
    "state_code": "LO",
    "cab": "802108",
    "cap": "26854"
  },
  {
    "key": "10927",
    "ITA": "PIEVE FOSCIANA",
    "state_code": "LU",
    "cab": "702704",
    "cap": "55036"
  },
  {
    "key": "10928",
    "ITA": "PIEVE LIGURE",
    "state_code": "GE",
    "cab": "320903",
    "cap": "16030"
  },
  {
    "key": "10929",
    "ITA": "PIEVE PORTO MORONE",
    "state_code": "PV",
    "cab": "561209",
    "cap": "27017"
  },
  {
    "key": "10930",
    "ITA": "PIEVE SAN GIACOMO",
    "state_code": "CR",
    "cab": "570408",
    "cap": "26035"
  },
  {
    "key": "10931",
    "ITA": "PIEVE SANTO STEFANO",
    "state_code": "AR",
    "cab": "715706",
    "cap": "52036"
  },
  {
    "key": "10932",
    "ITA": "PIEVE SANTO STEFANO-MADONNUCCIA",
    "state_code": "AR",
    "cab": "715706",
    "cap": "52036"
  },
  {
    "key": "10933",
    "ITA": "PIEVE TESINO",
    "state_code": "TN",
    "cab": "352500",
    "cap": "38050"
  },
  {
    "key": "10934",
    "ITA": "PIEVE TORINA-APPENNINO",
    "state_code": "MC",
    "cab": "690800",
    "cap": "62036"
  },
  {
    "key": "10935",
    "ITA": "PIEVE TORINA",
    "state_code": "MC",
    "cab": "690800",
    "cap": "62036"
  },
  {
    "key": "10936",
    "ITA": "PIEVE TORINA-PIE' CASAVECCHIA",
    "state_code": "MC",
    "cab": "690800",
    "cap": "62036"
  },
  {
    "key": "10937",
    "ITA": "PIEVE VERGONTE",
    "state_code": "VB",
    "cab": "456004",
    "cap": "28886"
  },
  {
    "key": "10938",
    "ITA": "PIEVEBOVIGLIANA",
    "state_code": "MC",
    "cab": "690701",
    "cap": "62035"
  },
  {
    "key": "10939",
    "ITA": "PIEVEPELAGO-ROCCAPELAGO",
    "state_code": "MO",
    "cab": "669309",
    "cap": "41027"
  },
  {
    "key": "10940",
    "ITA": "PIEVEPELAGO-SANT'ANDREA PELAGO",
    "state_code": "MO",
    "cab": "669309",
    "cap": "41027"
  },
  {
    "key": "10941",
    "ITA": "PIEVEPELAGO-SANT'ANNA PELAGO",
    "state_code": "MO",
    "cab": "669309",
    "cap": "41027"
  },
  {
    "key": "10942",
    "ITA": "PIEVEPELAGO-TAGLIOLE",
    "state_code": "MO",
    "cab": "669309",
    "cap": "41027"
  },
  {
    "key": "10943",
    "ITA": "PIEVEPELAGO",
    "state_code": "MO",
    "cab": "669309",
    "cap": "41027"
  },
  {
    "key": "10944",
    "ITA": "PIGLIO",
    "state_code": "FR",
    "cab": "745208",
    "cap": "3010"
  },
  {
    "key": "10945",
    "ITA": "PIGNA",
    "state_code": "IM",
    "cab": "708602",
    "cap": "18037"
  },
  {
    "key": "10946",
    "ITA": "PIGNATARO INTERAMNA",
    "state_code": "FR",
    "cab": "896803",
    "cap": "3040"
  },
  {
    "key": "10947",
    "ITA": "PIGNATARO MAGGIORE",
    "state_code": "CE",
    "cab": "749705",
    "cap": "81052"
  },
  {
    "key": "10948",
    "ITA": "PIGNOLA-RIFREDDO",
    "state_code": "PZ",
    "cab": "423400",
    "cap": "85010"
  },
  {
    "key": "10949",
    "ITA": "PIGNOLA-MADONNA DEL PANTANO",
    "state_code": "PZ",
    "cab": "423400",
    "cap": "85010"
  },
  {
    "key": "10950",
    "ITA": "PIGNOLA",
    "state_code": "PZ",
    "cab": "423400",
    "cap": "85010"
  },
  {
    "key": "10951",
    "ITA": "PIGNONE",
    "state_code": "SP",
    "cab": "923755",
    "cap": "19020"
  },
  {
    "key": "10952",
    "ITA": "PIGRA",
    "state_code": "CO",
    "cab": "926022",
    "cap": "22020"
  },
  {
    "key": "10953",
    "ITA": "PILA",
    "state_code": "VC",
    "cab": "916114",
    "cap": "13020"
  },
  {
    "key": "10954",
    "ITA": "PIMENTEL",
    "state_code": "CA",
    "cab": "861005",
    "cap": "9020"
  },
  {
    "key": "10955",
    "ITA": "PIMONTE-TRALIA",
    "state_code": "NA",
    "cab": "404004",
    "cap": "80050"
  },
  {
    "key": "10956",
    "ITA": "PIMONTE-PIAZZA",
    "state_code": "NA",
    "cab": "404004",
    "cap": "80050"
  },
  {
    "key": "10957",
    "ITA": "PIMONTE-FRANCHE",
    "state_code": "NA",
    "cab": "404004",
    "cap": "80050"
  },
  {
    "key": "10958",
    "ITA": "PIMONTE",
    "state_code": "NA",
    "cab": "404004",
    "cap": "80050"
  },
  {
    "key": "10959",
    "ITA": "PINAROLO PO",
    "state_code": "PV",
    "cab": "561308",
    "cap": "27040"
  },
  {
    "key": "10960",
    "ITA": "PINASCA",
    "state_code": "TO",
    "cab": "314807",
    "cap": "10060"
  },
  {
    "key": "10961",
    "ITA": "PINASCA-CASTELNUOVO",
    "state_code": "TO",
    "cab": "314807",
    "cap": "10060"
  },
  {
    "key": "10962",
    "ITA": "PINASCA-COMBALERE",
    "state_code": "TO",
    "cab": "314807",
    "cap": "10060"
  },
  {
    "key": "10963",
    "ITA": "PINASCA-DUBBIONE",
    "state_code": "TO",
    "cab": "314807",
    "cap": "10060"
  },
  {
    "key": "10964",
    "ITA": "PINCARA",
    "state_code": "RO",
    "cab": "634105",
    "cap": "45020"
  },
  {
    "key": "10965",
    "ITA": "PINEROLO-BAUDENASCA",
    "state_code": "TO",
    "cab": "307504",
    "cap": "10064"
  },
  {
    "key": "10966",
    "ITA": "PINEROLO-RIVA DI PINEROLO",
    "state_code": "TO",
    "cab": "307504",
    "cap": "10064"
  },
  {
    "key": "10967",
    "ITA": "PINEROLO-ABBADIA ALPINA",
    "state_code": "TO",
    "cab": "307504",
    "cap": "10064"
  },
  {
    "key": "10968",
    "ITA": "PINEROLO",
    "state_code": "TO",
    "cab": "307504",
    "cap": "10064"
  },
  {
    "key": "10969",
    "ITA": "PINETO-MUTIGNANO",
    "state_code": "TE",
    "cab": "770008",
    "cap": "64025"
  },
  {
    "key": "10970",
    "ITA": "PINETO-BORGO SANTA MARIA IMMACOLATA",
    "state_code": "TE",
    "cab": "770008",
    "cap": "64025"
  },
  {
    "key": "10971",
    "ITA": "PINETO",
    "state_code": "TE",
    "cab": "770008",
    "cap": "64025"
  },
  {
    "key": "10972",
    "ITA": "PINETO-SCERNE",
    "state_code": "TE",
    "cab": "770008",
    "cap": "64025"
  },
  {
    "key": "10973",
    "ITA": "PINO D'ASTI",
    "state_code": "AT",
    "cab": "920355",
    "cap": "14020"
  },
  {
    "key": "10974",
    "ITA": "PINO SULLA SPONDA DEL LAGO MAGGIORE",
    "state_code": "VA",
    "cab": "181487",
    "cap": "21010"
  },
  {
    "key": "10975",
    "ITA": "PINO TORINESE",
    "state_code": "TO",
    "cab": "307603",
    "cap": "10025"
  },
  {
    "key": "10976",
    "ITA": "PINZANO AL TAGLIAMENTO",
    "state_code": "PN",
    "cab": "793901",
    "cap": "33094"
  },
  {
    "key": "10977",
    "ITA": "PINZANO AL TAGLIAMENTO-VALERIANO",
    "state_code": "PN",
    "cab": "793901",
    "cap": "33094"
  },
  {
    "key": "10978",
    "ITA": "PINZOLO",
    "state_code": "TN",
    "cab": "352609",
    "cap": "38086"
  },
  {
    "key": "10979",
    "ITA": "PINZOLO-SANT'ANTONIO DI MAVIGNOLA",
    "state_code": "TN",
    "cab": "352609",
    "cap": "38086"
  },
  {
    "key": "10980",
    "ITA": "PINZOLO-MADONNA DI CAMPIGLIO",
    "state_code": "TN",
    "cab": "352609",
    "cap": "38086"
  },
  {
    "key": "10981",
    "ITA": "PIOBBICO",
    "state_code": "PU",
    "cab": "685305",
    "cap": "61046"
  },
  {
    "key": "10982",
    "ITA": "PIOBESI D'ALBA",
    "state_code": "CN",
    "cab": "458604",
    "cap": "12040"
  },
  {
    "key": "10983",
    "ITA": "PIOBESI TORINESE",
    "state_code": "TO",
    "cab": "307702",
    "cap": "10040"
  },
  {
    "key": "10984",
    "ITA": "PIODE",
    "state_code": "VC",
    "cab": "916122",
    "cap": "13020"
  },
  {
    "key": "10985",
    "ITA": "PIOLTELLO-LIMITO",
    "state_code": "MI",
    "cab": "335901",
    "cap": "20096"
  },
  {
    "key": "10986",
    "ITA": "PIOLTELLO",
    "state_code": "MI",
    "cab": "335901",
    "cap": "20096"
  },
  {
    "key": "10987",
    "ITA": "PIOLTELLO-SEGGIANO",
    "state_code": "MI",
    "cab": "335901",
    "cap": "20096"
  },
  {
    "key": "10988",
    "ITA": "PIOMBINO-RIOTORTO",
    "state_code": "LI",
    "cab": "707208",
    "cap": "57025"
  },
  {
    "key": "10989",
    "ITA": "PIOMBINO-PORTOVECCHIO",
    "state_code": "LI",
    "cab": "707208",
    "cap": "57025"
  },
  {
    "key": "10990",
    "ITA": "PIOMBINO-POPULONIA",
    "state_code": "LI",
    "cab": "707208",
    "cap": "57025"
  },
  {
    "key": "10991",
    "ITA": "PIOMBINO-COTONE",
    "state_code": "LI",
    "cab": "707208",
    "cap": "57025"
  },
  {
    "key": "10992",
    "ITA": "PIOMBINO-COLMATA",
    "state_code": "LI",
    "cab": "707208",
    "cap": "57025"
  },
  {
    "key": "10993",
    "ITA": "PIOMBINO",
    "state_code": "LI",
    "cab": "707208",
    "cap": "57025"
  },
  {
    "key": "10994",
    "ITA": "PIOMBINO-TORRE MOZZA",
    "state_code": "LI",
    "cab": "707208",
    "cap": "57025"
  },
  {
    "key": "10995",
    "ITA": "PIOMBINO DESE-TORRESELLE",
    "state_code": "PD",
    "cab": "627307",
    "cap": "35017"
  },
  {
    "key": "10996",
    "ITA": "PIOMBINO DESE-RONCHI",
    "state_code": "PD",
    "cab": "627307",
    "cap": "35017"
  },
  {
    "key": "10997",
    "ITA": "PIOMBINO DESE-LEVADA",
    "state_code": "PD",
    "cab": "627307",
    "cap": "35017"
  },
  {
    "key": "10998",
    "ITA": "PIOMBINO DESE",
    "state_code": "PD",
    "cab": "627307",
    "cap": "35017"
  },
  {
    "key": "10999",
    "ITA": "PIORACO-SEPPIO",
    "state_code": "MC",
    "cab": "690909",
    "cap": "62025"
  },
  {
    "key": "11000",
    "ITA": "PIORACO",
    "state_code": "MC",
    "cab": "690909",
    "cap": "62025"
  },
  {
    "key": "11001",
    "ITA": "PIOSSASCO-GAROLA",
    "state_code": "TO",
    "cab": "307801",
    "cap": "10045"
  },
  {
    "key": "11002",
    "ITA": "PIOSSASCO",
    "state_code": "TO",
    "cab": "307801",
    "cap": "10045"
  },
  {
    "key": "11003",
    "ITA": "PIOVA' MASSAIA",
    "state_code": "AT",
    "cab": "476002",
    "cap": "14026"
  },
  {
    "key": "11004",
    "ITA": "PIOVE DI SACCO",
    "state_code": "PD",
    "cab": "627406",
    "cap": "35028"
  },
  {
    "key": "11005",
    "ITA": "PIOVE DI SACCO-ARZERELLO",
    "state_code": "PD",
    "cab": "627406",
    "cap": "35028"
  },
  {
    "key": "11006",
    "ITA": "PIOVE DI SACCO-CORTE",
    "state_code": "PD",
    "cab": "627406",
    "cap": "35028"
  },
  {
    "key": "11007",
    "ITA": "PIOVE DI SACCO-PIOVEGA",
    "state_code": "PD",
    "cab": "627406",
    "cap": "35028"
  },
  {
    "key": "11008",
    "ITA": "PIOVENE ROCCHETTE",
    "state_code": "VI",
    "cab": "606400",
    "cap": "36013"
  },
  {
    "key": "11009",
    "ITA": "PIOVERA",
    "state_code": "AL",
    "cab": "921536",
    "cap": "15040"
  },
  {
    "key": "11010",
    "ITA": "PIOZZANO",
    "state_code": "PC",
    "cab": "938670",
    "cap": "29010"
  },
  {
    "key": "11011",
    "ITA": "PIOZZO",
    "state_code": "CN",
    "cab": "466409",
    "cap": "12060"
  },
  {
    "key": "11012",
    "ITA": "PIRAINO-GLIACA",
    "state_code": "ME",
    "cab": "823906",
    "cap": "98060"
  },
  {
    "key": "11013",
    "ITA": "PIRAINO-FIUMARA DI PIRAINO",
    "state_code": "ME",
    "cab": "823906",
    "cap": "98060"
  },
  {
    "key": "11014",
    "ITA": "PIRAINO",
    "state_code": "ME",
    "cab": "823906",
    "cap": "98060"
  },
  {
    "key": "11015",
    "ITA": "PIRAINO-SALINA'",
    "state_code": "ME",
    "cab": "823906",
    "cap": "98060"
  },
  {
    "key": "11016",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56128"
  },
  {
    "key": "11017",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56126"
  },
  {
    "key": "11018",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56127"
  },
  {
    "key": "11019",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56125"
  },
  {
    "key": "11020",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56124"
  },
  {
    "key": "11021",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56123"
  },
  {
    "key": "11022",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56122"
  },
  {
    "key": "11023",
    "ITA": "PISA",
    "state_code": "PI",
    "cab": "140004",
    "cap": "56121"
  },
  {
    "key": "11024",
    "ITA": "PISANO",
    "state_code": "NO",
    "cab": "917575",
    "cap": "28010"
  },
  {
    "key": "11025",
    "ITA": "PISCINA",
    "state_code": "TO",
    "cab": "307900",
    "cap": "10060"
  },
  {
    "key": "11026",
    "ITA": "PISCINAS",
    "state_code": "SU",
    "cab": "915314",
    "cap": "9010"
  },
  {
    "key": "11027",
    "ITA": "PISCIOTTA-RODIO",
    "state_code": "SA",
    "cab": "952853",
    "cap": "84066"
  },
  {
    "key": "11028",
    "ITA": "PISCIOTTA-MARINA DI PISCIOTTA",
    "state_code": "SA",
    "cab": "952853",
    "cap": "84066"
  },
  {
    "key": "11029",
    "ITA": "PISCIOTTA",
    "state_code": "SA",
    "cab": "952853",
    "cap": "84066"
  },
  {
    "key": "11030",
    "ITA": "PISCIOTTA-CAPRIOLI",
    "state_code": "SA",
    "cab": "952853",
    "cap": "84066"
  },
  {
    "key": "11031",
    "ITA": "PISOGNE-TOLINE",
    "state_code": "BS",
    "cab": "549600",
    "cap": "25055"
  },
  {
    "key": "11032",
    "ITA": "PISOGNE-GRATACASOLO",
    "state_code": "BS",
    "cab": "549600",
    "cap": "25055"
  },
  {
    "key": "11033",
    "ITA": "PISOGNE",
    "state_code": "BS",
    "cab": "549600",
    "cap": "25055"
  },
  {
    "key": "11034",
    "ITA": "PISONIANO",
    "state_code": "RM",
    "cab": "908269",
    "cap": "20"
  },
  {
    "key": "11035",
    "ITA": "PISTICCI",
    "state_code": "MT",
    "cab": "803908",
    "cap": "75015"
  },
  {
    "key": "11036",
    "ITA": "PISTICCI-PISTICCI SCALO",
    "state_code": "MT",
    "cab": "803908",
    "cap": "75010"
  },
  {
    "key": "11037",
    "ITA": "PISTICCI-MARCONIA",
    "state_code": "MT",
    "cab": "803908",
    "cap": "75020"
  },
  {
    "key": "11038",
    "ITA": "PISTOIA-PIAZZA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11039",
    "ITA": "PISTOIA-PITECCIO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11040",
    "ITA": "PISTOIA-PONTELUNGO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11041",
    "ITA": "PISTOIA-PONZANO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11042",
    "ITA": "PISTOIA-PRACCHIA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11043",
    "ITA": "PISTOIA-SAMMOMME'",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11044",
    "ITA": "PISTOIA-SAN FELICE",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11045",
    "ITA": "PISTOIA-SATURNANA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11046",
    "ITA": "PISTOIA-VALDIBRANA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11047",
    "ITA": "PISTOIA-VILLA DI BAGGIO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11048",
    "ITA": "PISTOIA-PIASTRE",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11049",
    "ITA": "PISTOIA-ORSIGNA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11050",
    "ITA": "PISTOIA-MASIANO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11051",
    "ITA": "PISTOIA-LE GRAZIE",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11052",
    "ITA": "PISTOIA-CORBEZZI",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11053",
    "ITA": "PISTOIA-CIREGLIO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11054",
    "ITA": "PISTOIA-CHIAZZANO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11055",
    "ITA": "PISTOIA-CAPOSTRADA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11056",
    "ITA": "PISTOIA-CANDEGLIA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11057",
    "ITA": "PISTOIA-BOTTEGONE",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11058",
    "ITA": "PISTOIA",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11059",
    "ITA": "PISTOIA-SANTOMATO",
    "state_code": "PT",
    "cab": "138008",
    "cap": "51100"
  },
  {
    "key": "11060",
    "ITA": "PITEGLIO-PRATACCIO",
    "state_code": "PT",
    "cab": "704809",
    "cap": "51020"
  },
  {
    "key": "11061",
    "ITA": "PITEGLIO-POPIGLIO",
    "state_code": "PT",
    "cab": "704809",
    "cap": "51020"
  },
  {
    "key": "11062",
    "ITA": "PITEGLIO-LA LIMA",
    "state_code": "PT",
    "cab": "704809",
    "cap": "51020"
  },
  {
    "key": "11063",
    "ITA": "PITEGLIO-CRESPOLE",
    "state_code": "PT",
    "cab": "704809",
    "cap": "51020"
  },
  {
    "key": "11064",
    "ITA": "PITEGLIO-PRUNETTA",
    "state_code": "PT",
    "cab": "704809",
    "cap": "51020"
  },
  {
    "key": "11065",
    "ITA": "PITEGLIO",
    "state_code": "PT",
    "cab": "704809",
    "cap": "51020"
  },
  {
    "key": "11066",
    "ITA": "PITEGLIO-CALAMECCA",
    "state_code": "PT",
    "cab": "704809",
    "cap": "51020"
  },
  {
    "key": "11067",
    "ITA": "PITIGLIANO-IL CASONE",
    "state_code": "GR",
    "cab": "723304",
    "cap": "58017"
  },
  {
    "key": "11068",
    "ITA": "PITIGLIANO",
    "state_code": "GR",
    "cab": "723304",
    "cap": "58017"
  },
  {
    "key": "11069",
    "ITA": "PIUBEGA",
    "state_code": "MN",
    "cab": "577809",
    "cap": "46040"
  },
  {
    "key": "11070",
    "ITA": "PIUBEGA-SAN FERMO",
    "state_code": "MN",
    "cab": "577809",
    "cap": "46040"
  },
  {
    "key": "11071",
    "ITA": "PIURO-PROSTO",
    "state_code": "SO",
    "cab": "927202",
    "cap": "23020"
  },
  {
    "key": "11072",
    "ITA": "PIURO-BORGONUOVO DI PIURO",
    "state_code": "SO",
    "cab": "927202",
    "cap": "23020"
  },
  {
    "key": "11073",
    "ITA": "PIURO",
    "state_code": "SO",
    "cab": "927202",
    "cap": "23020"
  },
  {
    "key": "11074",
    "ITA": "PIURO-SANTA CROCE",
    "state_code": "SO",
    "cab": "927202",
    "cap": "23020"
  },
  {
    "key": "11075",
    "ITA": "PIVERONE",
    "state_code": "TO",
    "cab": "661207",
    "cap": "10010"
  },
  {
    "key": "11076",
    "ITA": "PIZZALE",
    "state_code": "PV",
    "cab": "930719",
    "cap": "27050"
  },
  {
    "key": "11077",
    "ITA": "PIZZIGHETTONE-ROGGIONE",
    "state_code": "CR",
    "cab": "570507",
    "cap": "26026"
  },
  {
    "key": "11078",
    "ITA": "PIZZIGHETTONE-REGONA",
    "state_code": "CR",
    "cab": "570507",
    "cap": "26026"
  },
  {
    "key": "11079",
    "ITA": "PIZZIGHETTONE",
    "state_code": "CR",
    "cab": "570507",
    "cap": "26026"
  },
  {
    "key": "11080",
    "ITA": "PIZZO",
    "state_code": "VV",
    "cab": "426908",
    "cap": "89812"
  },
  {
    "key": "11081",
    "ITA": "PIZZO-PIZZO MARINA",
    "state_code": "VV",
    "cab": "426908",
    "cap": "89812"
  },
  {
    "key": "11082",
    "ITA": "PIZZOFERRATO",
    "state_code": "CH",
    "cab": "954883",
    "cap": "66040"
  },
  {
    "key": "11083",
    "ITA": "PIZZOLI-MARRUCI",
    "state_code": "AQ",
    "cab": "407007",
    "cap": "67017"
  },
  {
    "key": "11084",
    "ITA": "PIZZOLI",
    "state_code": "AQ",
    "cab": "407007",
    "cap": "67017"
  },
  {
    "key": "11085",
    "ITA": "PIZZOLI-SAN LORENZO DI PIZZOLI",
    "state_code": "AQ",
    "cab": "407007",
    "cap": "67017"
  },
  {
    "key": "11086",
    "ITA": "PIZZONE",
    "state_code": "IS",
    "cab": "955781",
    "cap": "86071"
  },
  {
    "key": "11087",
    "ITA": "PIZZONI",
    "state_code": "VV",
    "cab": "913293",
    "cap": "89834"
  },
  {
    "key": "11088",
    "ITA": "PLACANICA",
    "state_code": "RC",
    "cab": "960252",
    "cap": "89040"
  },
  {
    "key": "11089",
    "ITA": "PLATACI",
    "state_code": "CS",
    "cab": "959197",
    "cap": "87070"
  },
  {
    "key": "11090",
    "ITA": "PLATANIA",
    "state_code": "CZ",
    "cab": "418202",
    "cap": "88040"
  },
  {
    "key": "11091",
    "ITA": "PLATI'",
    "state_code": "RC",
    "cab": "960260",
    "cap": "89039"
  },
  {
    "key": "11092",
    "ITA": "PLATI'-CIRELLA",
    "state_code": "RC",
    "cab": "960260",
    "cap": "89040"
  },
  {
    "key": "11093",
    "ITA": "PLAUS",
    "state_code": "BZ",
    "cab": "524603",
    "cap": "39025"
  },
  {
    "key": "11094",
    "ITA": "PLESIO",
    "state_code": "CO",
    "cab": "926030",
    "cap": "22010"
  },
  {
    "key": "11095",
    "ITA": "PLOAGHE",
    "state_code": "SS",
    "cab": "850206",
    "cap": "7017"
  },
  {
    "key": "11096",
    "ITA": "PLODIO",
    "state_code": "SV",
    "cab": "923250",
    "cap": "17043"
  },
  {
    "key": "11097",
    "ITA": "PLODIO-PIANI",
    "state_code": "SV",
    "cab": "923250",
    "cap": "17043"
  },
  {
    "key": "11098",
    "ITA": "POCAPAGLIA",
    "state_code": "CN",
    "cab": "470401",
    "cap": "12060"
  },
  {
    "key": "11099",
    "ITA": "POCAPAGLIA-MACELLAI",
    "state_code": "CN",
    "cab": "470401",
    "cap": "12060"
  },
  {
    "key": "11100",
    "ITA": "POCENIA",
    "state_code": "UD",
    "cab": "644500",
    "cap": "33050"
  },
  {
    "key": "11101",
    "ITA": "POCENIA-TORSA",
    "state_code": "UD",
    "cab": "644500",
    "cap": "33050"
  },
  {
    "key": "11102",
    "ITA": "PODENZANA-MONTEDIVALLI",
    "state_code": "MS",
    "cab": "179440",
    "cap": "54010"
  },
  {
    "key": "11103",
    "ITA": "PODENZANA",
    "state_code": "MS",
    "cab": "179440",
    "cap": "54010"
  },
  {
    "key": "11104",
    "ITA": "PODENZANO-SAN POLO",
    "state_code": "PC",
    "cab": "654103",
    "cap": "29027"
  },
  {
    "key": "11105",
    "ITA": "PODENZANO",
    "state_code": "PC",
    "cab": "654103",
    "cap": "29027"
  },
  {
    "key": "11106",
    "ITA": "POFI",
    "state_code": "FR",
    "cab": "745307",
    "cap": "3026"
  },
  {
    "key": "11107",
    "ITA": "POGGIARDO",
    "state_code": "LE",
    "cab": "798900",
    "cap": "73037"
  },
  {
    "key": "11108",
    "ITA": "POGGIARDO-VASTE",
    "state_code": "LE",
    "cab": "798900",
    "cap": "73037"
  },
  {
    "key": "11109",
    "ITA": "POGGIBONSI-BELLAVISTA",
    "state_code": "SI",
    "cab": "719401",
    "cap": "53036"
  },
  {
    "key": "11110",
    "ITA": "POGGIBONSI-STAGGIA",
    "state_code": "SI",
    "cab": "719401",
    "cap": "53036"
  },
  {
    "key": "11111",
    "ITA": "POGGIBONSI",
    "state_code": "SI",
    "cab": "719401",
    "cap": "53036"
  },
  {
    "key": "11112",
    "ITA": "POGGIO A CAIANO-POGGETTO",
    "state_code": "PO",
    "cab": "381806",
    "cap": "59016"
  },
  {
    "key": "11113",
    "ITA": "POGGIO A CAIANO",
    "state_code": "PO",
    "cab": "381806",
    "cap": "59016"
  },
  {
    "key": "11114",
    "ITA": "POGGIO BERNI",
    "state_code": "RN",
    "cab": "681205",
    "cap": "47824"
  },
  {
    "key": "11115",
    "ITA": "POGGIO BERNI-TREBBIO",
    "state_code": "RN",
    "cab": "681205",
    "cap": "47824"
  },
  {
    "key": "11116",
    "ITA": "POGGIO BERNI-SANTO MARINO",
    "state_code": "RN",
    "cab": "681205",
    "cap": "47824"
  },
  {
    "key": "11117",
    "ITA": "POGGIO BUSTONE",
    "state_code": "RI",
    "cab": "388009",
    "cap": "2018"
  },
  {
    "key": "11118",
    "ITA": "POGGIO CATINO",
    "state_code": "RI",
    "cab": "947341",
    "cap": "2040"
  },
  {
    "key": "11119",
    "ITA": "POGGIO IMPERIALE",
    "state_code": "FG",
    "cab": "785402",
    "cap": "71010"
  },
  {
    "key": "11120",
    "ITA": "POGGIO MIRTETO-POGGIO MIRTETO SCALO",
    "state_code": "RI",
    "cab": "737304",
    "cap": "2047"
  },
  {
    "key": "11121",
    "ITA": "POGGIO MIRTETO",
    "state_code": "RI",
    "cab": "737304",
    "cap": "2047"
  },
  {
    "key": "11122",
    "ITA": "POGGIO MIRTETO-CASTEL SAN PIETRO",
    "state_code": "RI",
    "cab": "737304",
    "cap": "2047"
  },
  {
    "key": "11123",
    "ITA": "POGGIO MOIANO-OSTERIA NUOVA",
    "state_code": "RI",
    "cab": "737403",
    "cap": "2037"
  },
  {
    "key": "11124",
    "ITA": "POGGIO MOIANO-FIACCHINI",
    "state_code": "RI",
    "cab": "737403",
    "cap": "2037"
  },
  {
    "key": "11125",
    "ITA": "POGGIO MOIANO",
    "state_code": "RI",
    "cab": "737403",
    "cap": "2037"
  },
  {
    "key": "11126",
    "ITA": "POGGIO MOIANO-CERDOMARE",
    "state_code": "RI",
    "cab": "737403",
    "cap": "2037"
  },
  {
    "key": "11127",
    "ITA": "POGGIO NATIVO",
    "state_code": "RI",
    "cab": "388108",
    "cap": "2030"
  },
  {
    "key": "11128",
    "ITA": "POGGIO NATIVO-MONTE SANTA MARIA",
    "state_code": "RI",
    "cab": "388108",
    "cap": "2030"
  },
  {
    "key": "11129",
    "ITA": "POGGIO PICENZE",
    "state_code": "AQ",
    "cab": "909838",
    "cap": "67026"
  },
  {
    "key": "11130",
    "ITA": "POGGIO RENATICO",
    "state_code": "FE",
    "cab": "673103",
    "cap": "44028"
  },
  {
    "key": "11131",
    "ITA": "POGGIO RENATICO-GALLO",
    "state_code": "FE",
    "cab": "673103",
    "cap": "44028"
  },
  {
    "key": "11132",
    "ITA": "POGGIO RENATICO-CORONELLA",
    "state_code": "FE",
    "cab": "673103",
    "cap": "44028"
  },
  {
    "key": "11133",
    "ITA": "POGGIO RENATICO-CHIESA NUOVA",
    "state_code": "FE",
    "cab": "673103",
    "cap": "44028"
  },
  {
    "key": "11134",
    "ITA": "POGGIO RUSCO",
    "state_code": "MN",
    "cab": "577908",
    "cap": "46025"
  },
  {
    "key": "11135",
    "ITA": "POGGIO SAN LORENZO",
    "state_code": "RI",
    "cab": "947366",
    "cap": "2030"
  },
  {
    "key": "11136",
    "ITA": "POGGIO SAN LORENZO-VILLETTA SANT'ANTONIO",
    "state_code": "RI",
    "cab": "947366",
    "cap": "2030"
  },
  {
    "key": "11137",
    "ITA": "POGGIO SAN MARCELLO",
    "state_code": "AN",
    "cab": "375204",
    "cap": "60030"
  },
  {
    "key": "11138",
    "ITA": "POGGIO SAN VICINO",
    "state_code": "MC",
    "cab": "941989",
    "cap": "62021"
  },
  {
    "key": "11139",
    "ITA": "POGGIO SANNITA",
    "state_code": "IS",
    "cab": "955799",
    "cap": "86086"
  },
  {
    "key": "11140",
    "ITA": "POGGIODOMO",
    "state_code": "PG",
    "cab": "907626",
    "cap": "6040"
  },
  {
    "key": "11141",
    "ITA": "POGGIOFIORITO",
    "state_code": "CH",
    "cab": "811604",
    "cap": "66030"
  },
  {
    "key": "11142",
    "ITA": "POGGIOMARINO-FLOCCO",
    "state_code": "NA",
    "cab": "400606",
    "cap": "80040"
  },
  {
    "key": "11143",
    "ITA": "POGGIOMARINO",
    "state_code": "NA",
    "cab": "400606",
    "cap": "80040"
  },
  {
    "key": "11144",
    "ITA": "POGGIOREALE",
    "state_code": "TP",
    "cab": "819201",
    "cap": "91020"
  },
  {
    "key": "11145",
    "ITA": "POGGIORSINI",
    "state_code": "BA",
    "cab": "416107",
    "cap": "70020"
  },
  {
    "key": "11146",
    "ITA": "POGGIRIDENTI",
    "state_code": "SO",
    "cab": "927210",
    "cap": "23020"
  },
  {
    "key": "11147",
    "ITA": "POGLIANO MILANESE",
    "state_code": "MI",
    "cab": "336008",
    "cap": "20010"
  },
  {
    "key": "11148",
    "ITA": "POGNANA LARIO",
    "state_code": "CO",
    "cab": "926048",
    "cap": "22020"
  },
  {
    "key": "11149",
    "ITA": "POGNANO",
    "state_code": "BG",
    "cab": "802405",
    "cap": "24040"
  },
  {
    "key": "11150",
    "ITA": "POGNO",
    "state_code": "NO",
    "cab": "489302",
    "cap": "28076"
  },
  {
    "key": "11151",
    "ITA": "POIANA MAGGIORE",
    "state_code": "VI",
    "cab": "606509",
    "cap": "36026"
  },
  {
    "key": "11152",
    "ITA": "POIANA MAGGIORE-CAGNANO",
    "state_code": "VI",
    "cab": "606509",
    "cap": "36026"
  },
  {
    "key": "11153",
    "ITA": "POIRINO-MAROCCHI",
    "state_code": "TO",
    "cab": "308007",
    "cap": "10046"
  },
  {
    "key": "11154",
    "ITA": "POIRINO-FAVARI",
    "state_code": "TO",
    "cab": "308007",
    "cap": "10046"
  },
  {
    "key": "11155",
    "ITA": "POIRINO-AVATANEI",
    "state_code": "TO",
    "cab": "308007",
    "cap": "10046"
  },
  {
    "key": "11156",
    "ITA": "POIRINO",
    "state_code": "TO",
    "cab": "308007",
    "cap": "10046"
  },
  {
    "key": "11157",
    "ITA": "POLAVENO",
    "state_code": "BS",
    "cab": "371807",
    "cap": "25060"
  },
  {
    "key": "11158",
    "ITA": "POLAVENO-GOMBIO",
    "state_code": "BS",
    "cab": "371807",
    "cap": "25060"
  },
  {
    "key": "11159",
    "ITA": "POLCENIGO",
    "state_code": "PN",
    "cab": "649400",
    "cap": "33070"
  },
  {
    "key": "11160",
    "ITA": "POLCENIGO-SAN GIOVANNI DI POLCENIGO",
    "state_code": "PN",
    "cab": "649400",
    "cap": "33070"
  },
  {
    "key": "11161",
    "ITA": "POLESELLA",
    "state_code": "RO",
    "cab": "634204",
    "cap": "45038"
  },
  {
    "key": "11162",
    "ITA": "POLESELLA-RACCANO",
    "state_code": "RO",
    "cab": "634204",
    "cap": "45038"
  },
  {
    "key": "11163",
    "ITA": "POLESINE PARMENSE",
    "state_code": "PR",
    "cab": "658708",
    "cap": "43010"
  },
  {
    "key": "11164",
    "ITA": "POLI",
    "state_code": "RM",
    "cab": "395608",
    "cap": "10"
  },
  {
    "key": "11165",
    "ITA": "POLIA-TRECROCI",
    "state_code": "VV",
    "cab": "913319",
    "cap": "89813"
  },
  {
    "key": "11166",
    "ITA": "POLIA-MENNITI",
    "state_code": "VV",
    "cab": "913319",
    "cap": "89813"
  },
  {
    "key": "11167",
    "ITA": "POLIA",
    "state_code": "VV",
    "cab": "913319",
    "cap": "89813"
  },
  {
    "key": "11168",
    "ITA": "POLICORO",
    "state_code": "MT",
    "cab": "804005",
    "cap": "75025"
  },
  {
    "key": "11169",
    "ITA": "POLIGNANO A MARE",
    "state_code": "BA",
    "cab": "416206",
    "cap": "70044"
  },
  {
    "key": "11170",
    "ITA": "POLINAGO-PONTE GOMBOLA",
    "state_code": "MO",
    "cab": "669408",
    "cap": "41040"
  },
  {
    "key": "11171",
    "ITA": "POLINAGO-CASSANO",
    "state_code": "MO",
    "cab": "669408",
    "cap": "41040"
  },
  {
    "key": "11172",
    "ITA": "POLINAGO",
    "state_code": "MO",
    "cab": "669408",
    "cap": "41040"
  },
  {
    "key": "11173",
    "ITA": "POLINAGO-SAN MARTINO VALLATA",
    "state_code": "MO",
    "cab": "669408",
    "cap": "41040"
  },
  {
    "key": "11174",
    "ITA": "POLINO",
    "state_code": "TR",
    "cab": "946327",
    "cap": "5030"
  },
  {
    "key": "11175",
    "ITA": "POLISTENA",
    "state_code": "RC",
    "cab": "815001",
    "cap": "89024"
  },
  {
    "key": "11176",
    "ITA": "POLIZZI GENEROSA",
    "state_code": "PA",
    "cab": "435305",
    "cap": "90028"
  },
  {
    "key": "11177",
    "ITA": "POLLA",
    "state_code": "SA",
    "cab": "763201",
    "cap": "84035"
  },
  {
    "key": "11178",
    "ITA": "POLLEIN",
    "state_code": "AO",
    "cab": "316406",
    "cap": "11020"
  },
  {
    "key": "11179",
    "ITA": "POLLENA TROCCHIA",
    "state_code": "NA",
    "cab": "403600",
    "cap": "80040"
  },
  {
    "key": "11180",
    "ITA": "POLLENA TROCCHIA-MUSCI",
    "state_code": "NA",
    "cab": "403600",
    "cap": "80040"
  },
  {
    "key": "11181",
    "ITA": "POLLENZA",
    "state_code": "MC",
    "cab": "691006",
    "cap": "62010"
  },
  {
    "key": "11182",
    "ITA": "POLLENZA-CASETTE VERDINI",
    "state_code": "MC",
    "cab": "691006",
    "cap": "62010"
  },
  {
    "key": "11183",
    "ITA": "POLLICA-PIOPPI",
    "state_code": "SA",
    "cab": "763300",
    "cap": "84068"
  },
  {
    "key": "11184",
    "ITA": "POLLICA-GALDO",
    "state_code": "SA",
    "cab": "763300",
    "cap": "84068"
  },
  {
    "key": "11185",
    "ITA": "POLLICA-CELSO",
    "state_code": "SA",
    "cab": "763300",
    "cap": "84068"
  },
  {
    "key": "11186",
    "ITA": "POLLICA-CANNICCHIO",
    "state_code": "SA",
    "cab": "763300",
    "cap": "84068"
  },
  {
    "key": "11187",
    "ITA": "POLLICA-ACCIAROLI",
    "state_code": "SA",
    "cab": "763300",
    "cap": "84068"
  },
  {
    "key": "11188",
    "ITA": "POLLICA",
    "state_code": "SA",
    "cab": "763300",
    "cap": "84068"
  },
  {
    "key": "11189",
    "ITA": "POLLINA",
    "state_code": "PA",
    "cab": "435404",
    "cap": "90010"
  },
  {
    "key": "11190",
    "ITA": "POLLINA-FINALE",
    "state_code": "PA",
    "cab": "435404",
    "cap": "90010"
  },
  {
    "key": "11191",
    "ITA": "POLLONE",
    "state_code": "BI",
    "cab": "446609",
    "cap": "13814"
  },
  {
    "key": "11192",
    "ITA": "POLLUTRI",
    "state_code": "CH",
    "cab": "779405",
    "cap": "66020"
  },
  {
    "key": "11193",
    "ITA": "POLONGHERA",
    "state_code": "CN",
    "cab": "466508",
    "cap": "12030"
  },
  {
    "key": "11194",
    "ITA": "POLPENAZZE DEL GARDA",
    "state_code": "BS",
    "cab": "703306",
    "cap": "25080"
  },
  {
    "key": "11195",
    "ITA": "POLVERARA",
    "state_code": "PD",
    "cab": "775205",
    "cap": "35020"
  },
  {
    "key": "11196",
    "ITA": "POLVERIGI",
    "state_code": "AN",
    "cab": "375303",
    "cap": "60020"
  },
  {
    "key": "11197",
    "ITA": "POMARANCE",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56045"
  },
  {
    "key": "11198",
    "ITA": "POMARANCE-LARDERELLO",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56044"
  },
  {
    "key": "11199",
    "ITA": "POMARANCE-LIBBIANO",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56045"
  },
  {
    "key": "11200",
    "ITA": "POMARANCE-SERRAZZANO",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56044"
  },
  {
    "key": "11201",
    "ITA": "POMARANCE-LUSTIGNANO",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56044"
  },
  {
    "key": "11202",
    "ITA": "POMARANCE-MONTEGEMOLI",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56045"
  },
  {
    "key": "11203",
    "ITA": "POMARANCE-MONTECERBOLI",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56044"
  },
  {
    "key": "11204",
    "ITA": "POMARANCE-MICCIANO",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56045"
  },
  {
    "key": "11205",
    "ITA": "POMARANCE-SAN DALMAZIO",
    "state_code": "PI",
    "cab": "711101",
    "cap": "56045"
  },
  {
    "key": "11206",
    "ITA": "POMARETTO",
    "state_code": "TO",
    "cab": "901157",
    "cap": "10063"
  },
  {
    "key": "11207",
    "ITA": "POMARICO",
    "state_code": "MT",
    "cab": "804104",
    "cap": "75016"
  },
  {
    "key": "11208",
    "ITA": "POMARO MONFERRATO",
    "state_code": "AL",
    "cab": "921544",
    "cap": "15040"
  },
  {
    "key": "11209",
    "ITA": "POMAROLO",
    "state_code": "TN",
    "cab": "359109",
    "cap": "38060"
  },
  {
    "key": "11210",
    "ITA": "POMBIA",
    "state_code": "NO",
    "cab": "727800",
    "cap": "28050"
  },
  {
    "key": "11211",
    "ITA": "POMEZIA-TORVAIANICA",
    "state_code": "RM",
    "cab": "220004",
    "cap": "40"
  },
  {
    "key": "11212",
    "ITA": "POMEZIA",
    "state_code": "RM",
    "cab": "220004",
    "cap": "40"
  },
  {
    "key": "11213",
    "ITA": "POMEZIA-SANTA PALOMBA",
    "state_code": "RM",
    "cab": "220004",
    "cap": "40"
  },
  {
    "key": "11214",
    "ITA": "POMEZIA-PRATICA DI MARE",
    "state_code": "RM",
    "cab": "220004",
    "cap": "40"
  },
  {
    "key": "11215",
    "ITA": "POMIGLIANO D'ARCO",
    "state_code": "NA",
    "cab": "400705",
    "cap": "80038"
  },
  {
    "key": "11216",
    "ITA": "POMPEI-MARICONDA",
    "state_code": "NA",
    "cab": "400804",
    "cap": "80045"
  },
  {
    "key": "11217",
    "ITA": "POMPEI-POMPEI SCAVI",
    "state_code": "NA",
    "cab": "400804",
    "cap": "80045"
  },
  {
    "key": "11218",
    "ITA": "POMPEI-MESSIGNO",
    "state_code": "NA",
    "cab": "400804",
    "cap": "80045"
  },
  {
    "key": "11219",
    "ITA": "POMPEI",
    "state_code": "NA",
    "cab": "400804",
    "cap": "80045"
  },
  {
    "key": "11220",
    "ITA": "POMPEIANA",
    "state_code": "IM",
    "cab": "922542",
    "cap": "18015"
  },
  {
    "key": "11221",
    "ITA": "POMPIANO",
    "state_code": "BS",
    "cab": "549709",
    "cap": "25030"
  },
  {
    "key": "11222",
    "ITA": "POMPONESCO",
    "state_code": "MN",
    "cab": "578005",
    "cap": "46030"
  },
  {
    "key": "11223",
    "ITA": "POMPU",
    "state_code": "OR",
    "cab": "966945",
    "cap": "9093"
  },
  {
    "key": "11224",
    "ITA": "PONCARALE",
    "state_code": "BS",
    "cab": "549808",
    "cap": "25020"
  },
  {
    "key": "11225",
    "ITA": "PONDERANO",
    "state_code": "BI",
    "cab": "449603",
    "cap": "13875"
  },
  {
    "key": "11226",
    "ITA": "PONNA",
    "state_code": "CO",
    "cab": "926055",
    "cap": "22020"
  },
  {
    "key": "11227",
    "ITA": "PONSACCO-GIARDINO",
    "state_code": "PI",
    "cab": "711200",
    "cap": "56038"
  },
  {
    "key": "11228",
    "ITA": "PONSACCO",
    "state_code": "PI",
    "cab": "711200",
    "cap": "56038"
  },
  {
    "key": "11229",
    "ITA": "PONSO-BRESEGA",
    "state_code": "PD",
    "cab": "627505",
    "cap": "35040"
  },
  {
    "key": "11230",
    "ITA": "PONSO",
    "state_code": "PD",
    "cab": "627505",
    "cap": "35040"
  },
  {
    "key": "11231",
    "ITA": "PONT CANAVESE",
    "state_code": "TO",
    "cab": "308106",
    "cap": "10085"
  },
  {
    "key": "11232",
    "ITA": "PONT SAINT MARTIN",
    "state_code": "AO",
    "cab": "316505",
    "cap": "11026"
  },
  {
    "key": "11233",
    "ITA": "PONTASSIEVE-SANTA BRIGIDA",
    "state_code": "FI",
    "cab": "380105",
    "cap": "50065"
  },
  {
    "key": "11234",
    "ITA": "PONTASSIEVE-MONTEBONELLO",
    "state_code": "FI",
    "cab": "380105",
    "cap": "50065"
  },
  {
    "key": "11235",
    "ITA": "PONTASSIEVE-MOLINO DEL PIANO",
    "state_code": "FI",
    "cab": "380105",
    "cap": "50065"
  },
  {
    "key": "11236",
    "ITA": "PONTASSIEVE",
    "state_code": "FI",
    "cab": "380105",
    "cap": "50065"
  },
  {
    "key": "11237",
    "ITA": "PONTASSIEVE-SIECI",
    "state_code": "FI",
    "cab": "380105",
    "cap": "50065"
  },
  {
    "key": "11238",
    "ITA": "PONTBOSET",
    "state_code": "AO",
    "cab": "902536",
    "cap": "11020"
  },
  {
    "key": "11239",
    "ITA": "PONTE",
    "state_code": "BN",
    "cab": "754101",
    "cap": "82030"
  },
  {
    "key": "11240",
    "ITA": "PONTE BUGGIANESE",
    "state_code": "PT",
    "cab": "704908",
    "cap": "51019"
  },
  {
    "key": "11241",
    "ITA": "PONTE BUGGIANESE-ANCHIONE",
    "state_code": "PT",
    "cab": "704908",
    "cap": "51019"
  },
  {
    "key": "11242",
    "ITA": "PONTE DELL'OLIO-BIANA",
    "state_code": "PC",
    "cab": "654202",
    "cap": "29028"
  },
  {
    "key": "11243",
    "ITA": "PONTE DELL'OLIO-TORRANO",
    "state_code": "PC",
    "cab": "654202",
    "cap": "29028"
  },
  {
    "key": "11244",
    "ITA": "PONTE DELL'OLIO",
    "state_code": "PC",
    "cab": "654202",
    "cap": "29028"
  },
  {
    "key": "11245",
    "ITA": "PONTE DI LEGNO",
    "state_code": "BS",
    "cab": "549907",
    "cap": "25056"
  },
  {
    "key": "11246",
    "ITA": "PONTE DI PIAVE-LEVADA",
    "state_code": "TV",
    "cab": "619304",
    "cap": "31047"
  },
  {
    "key": "11247",
    "ITA": "PONTE DI PIAVE-NEGRISIA",
    "state_code": "TV",
    "cab": "619304",
    "cap": "31047"
  },
  {
    "key": "11248",
    "ITA": "PONTE DI PIAVE",
    "state_code": "TV",
    "cab": "619304",
    "cap": "31047"
  },
  {
    "key": "11249",
    "ITA": "PONTE GARDENA",
    "state_code": "BZ",
    "cab": "587006",
    "cap": "39040"
  },
  {
    "key": "11250",
    "ITA": "PONTE IN VALTELLINA",
    "state_code": "SO",
    "cab": "522508",
    "cap": "23026"
  },
  {
    "key": "11251",
    "ITA": "PONTE IN VALTELLINA-ARIGNA",
    "state_code": "SO",
    "cab": "522508",
    "cap": "23026"
  },
  {
    "key": "11252",
    "ITA": "PONTE LAMBRO",
    "state_code": "CO",
    "cab": "516906",
    "cap": "22037"
  },
  {
    "key": "11253",
    "ITA": "PONTE NELLE ALPI-SOCCHER",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11254",
    "ITA": "PONTE NELLE ALPI-CASAN",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11255",
    "ITA": "PONTE NELLE ALPI-CADOLA",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11256",
    "ITA": "PONTE NELLE ALPI",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11257",
    "ITA": "PONTE NELLE ALPI-POLPET",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11258",
    "ITA": "PONTE NELLE ALPI-COL DI CUGNAN",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11259",
    "ITA": "PONTE NELLE ALPI-LA SECCA",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11260",
    "ITA": "PONTE NELLE ALPI-PAIANE",
    "state_code": "BL",
    "cab": "612408",
    "cap": "32014"
  },
  {
    "key": "11261",
    "ITA": "PONTE NIZZA",
    "state_code": "PV",
    "cab": "930727",
    "cap": "27050"
  },
  {
    "key": "11262",
    "ITA": "PONTE NOSSA",
    "state_code": "BG",
    "cab": "533505",
    "cap": "24028"
  },
  {
    "key": "11263",
    "ITA": "PONTE SAN NICOLO'",
    "state_code": "PD",
    "cab": "627703",
    "cap": "35020"
  },
  {
    "key": "11264",
    "ITA": "PONTE SAN NICOLO'-RONCAGLIA",
    "state_code": "PD",
    "cab": "627703",
    "cap": "35020"
  },
  {
    "key": "11265",
    "ITA": "PONTE SAN PIETRO",
    "state_code": "BG",
    "cab": "533703",
    "cap": "24036"
  },
  {
    "key": "11266",
    "ITA": "PONTEBBA",
    "state_code": "UD",
    "cab": "641001",
    "cap": "33016"
  },
  {
    "key": "11267",
    "ITA": "PONTECAGNANO FAIANO-PONTECAGNANO",
    "state_code": "SA",
    "cab": "763409",
    "cap": "84098"
  },
  {
    "key": "11268",
    "ITA": "PONTECAGNANO FAIANO-SANT'ANTONIO",
    "state_code": "SA",
    "cab": "763409",
    "cap": "84098"
  },
  {
    "key": "11269",
    "ITA": "PONTECAGNANO FAIANO-MAGAZZENO",
    "state_code": "SA",
    "cab": "763409",
    "cap": "84098"
  },
  {
    "key": "11270",
    "ITA": "PONTECAGNANO FAIANO-FAIANO",
    "state_code": "SA",
    "cab": "763409",
    "cap": "84098"
  },
  {
    "key": "11271",
    "ITA": "PONTECAGNANO FAIANO-CORVINIA",
    "state_code": "SA",
    "cab": "763409",
    "cap": "84098"
  },
  {
    "key": "11272",
    "ITA": "PONTECAGNANO FAIANO",
    "state_code": "SA",
    "cab": "763409",
    "cap": "84098"
  },
  {
    "key": "11273",
    "ITA": "PONTECCHIO POLESINE",
    "state_code": "RO",
    "cab": "282053",
    "cap": "45030"
  },
  {
    "key": "11274",
    "ITA": "PONTECHIANALE",
    "state_code": "CN",
    "cab": "919175",
    "cap": "12020"
  },
  {
    "key": "11275",
    "ITA": "PONTECORVO",
    "state_code": "FR",
    "cab": "745406",
    "cap": "3037"
  },
  {
    "key": "11276",
    "ITA": "PONTECORVO-SANT'OLIVA",
    "state_code": "FR",
    "cab": "745406",
    "cap": "3037"
  },
  {
    "key": "11277",
    "ITA": "PONTECORVO-PASTINE DI PONTECORVO",
    "state_code": "FR",
    "cab": "745406",
    "cap": "3037"
  },
  {
    "key": "11278",
    "ITA": "PONTECURONE",
    "state_code": "AL",
    "cab": "484808",
    "cap": "15055"
  },
  {
    "key": "11279",
    "ITA": "PONTEDASSIO",
    "state_code": "IM",
    "cab": "490409",
    "cap": "18027"
  },
  {
    "key": "11280",
    "ITA": "PONTEDERA-MONTECASTELLO",
    "state_code": "PI",
    "cab": "711309",
    "cap": "56025"
  },
  {
    "key": "11281",
    "ITA": "PONTEDERA-LA ROTTA",
    "state_code": "PI",
    "cab": "711309",
    "cap": "56025"
  },
  {
    "key": "11282",
    "ITA": "PONTEDERA-LA BORRA",
    "state_code": "PI",
    "cab": "711309",
    "cap": "56025"
  },
  {
    "key": "11283",
    "ITA": "PONTEDERA-IL ROMITO",
    "state_code": "PI",
    "cab": "711309",
    "cap": "56025"
  },
  {
    "key": "11284",
    "ITA": "PONTEDERA",
    "state_code": "PI",
    "cab": "711309",
    "cap": "56025"
  },
  {
    "key": "11285",
    "ITA": "PONTEDERA-SANTA LUCIA",
    "state_code": "PI",
    "cab": "711309",
    "cap": "56025"
  },
  {
    "key": "11286",
    "ITA": "PONTEDERA-TREGGIAIA",
    "state_code": "PI",
    "cab": "711309",
    "cap": "56025"
  },
  {
    "key": "11287",
    "ITA": "PONTELANDOLFO",
    "state_code": "BN",
    "cab": "754200",
    "cap": "82027"
  },
  {
    "key": "11288",
    "ITA": "PONTELANDOLFO-GIALLONARDO",
    "state_code": "BN",
    "cab": "754200",
    "cap": "82027"
  },
  {
    "key": "11289",
    "ITA": "PONTELATONE-TREGLIA",
    "state_code": "CE",
    "cab": "949479",
    "cap": "81040"
  },
  {
    "key": "11290",
    "ITA": "PONTELATONE",
    "state_code": "CE",
    "cab": "949479",
    "cap": "81040"
  },
  {
    "key": "11291",
    "ITA": "PONTELONGO",
    "state_code": "PD",
    "cab": "627604",
    "cap": "35029"
  },
  {
    "key": "11292",
    "ITA": "PONTENURE-VALCONASSO",
    "state_code": "PC",
    "cab": "654301",
    "cap": "29010"
  },
  {
    "key": "11293",
    "ITA": "PONTENURE",
    "state_code": "PC",
    "cab": "654301",
    "cap": "29010"
  },
  {
    "key": "11294",
    "ITA": "PONTERANICA",
    "state_code": "BG",
    "cab": "533604",
    "cap": "24010"
  },
  {
    "key": "11295",
    "ITA": "PONTESTURA",
    "state_code": "AL",
    "cab": "484907",
    "cap": "15027"
  },
  {
    "key": "11296",
    "ITA": "PONTEVICO",
    "state_code": "BS",
    "cab": "550004",
    "cap": "25026"
  },
  {
    "key": "11297",
    "ITA": "PONTEY",
    "state_code": "AO",
    "cab": "902544",
    "cap": "11024"
  },
  {
    "key": "11298",
    "ITA": "PONTEY-LASSOLAZ",
    "state_code": "AO",
    "cab": "902544",
    "cap": "11024"
  },
  {
    "key": "11299",
    "ITA": "PONTI",
    "state_code": "AL",
    "cab": "921551",
    "cap": "15010"
  },
  {
    "key": "11300",
    "ITA": "PONTI SUL MINCIO",
    "state_code": "MN",
    "cab": "578104",
    "cap": "46040"
  },
  {
    "key": "11301",
    "ITA": "PONTIDA",
    "state_code": "BG",
    "cab": "892505",
    "cap": "24030"
  },
  {
    "key": "11302",
    "ITA": "PONTINIA",
    "state_code": "LT",
    "cab": "740605",
    "cap": "4014"
  },
  {
    "key": "11303",
    "ITA": "PONTINIA-BORGO PASUBIO",
    "state_code": "LT",
    "cab": "740605",
    "cap": "4014"
  },
  {
    "key": "11304",
    "ITA": "PONTINVREA-GIOVO",
    "state_code": "SV",
    "cab": "923268",
    "cap": "17042"
  },
  {
    "key": "11305",
    "ITA": "PONTINVREA",
    "state_code": "SV",
    "cab": "923268",
    "cap": "17042"
  },
  {
    "key": "11306",
    "ITA": "PONTIROLO NUOVO",
    "state_code": "BG",
    "cab": "533802",
    "cap": "24040"
  },
  {
    "key": "11307",
    "ITA": "PONTOGLIO",
    "state_code": "BS",
    "cab": "550103",
    "cap": "25037"
  },
  {
    "key": "11308",
    "ITA": "PONTREMOLI-VIGNOLA",
    "state_code": "MS",
    "cab": "699603",
    "cap": "54027"
  },
  {
    "key": "11309",
    "ITA": "PONTREMOLI-GUINADI",
    "state_code": "MS",
    "cab": "699603",
    "cap": "54027"
  },
  {
    "key": "11310",
    "ITA": "PONTREMOLI-MOLINELLO",
    "state_code": "MS",
    "cab": "699603",
    "cap": "54027"
  },
  {
    "key": "11311",
    "ITA": "PONTREMOLI-TRAVERDE",
    "state_code": "MS",
    "cab": "699603",
    "cap": "54027"
  },
  {
    "key": "11312",
    "ITA": "PONTREMOLI-CERVARA",
    "state_code": "MS",
    "cab": "699603",
    "cap": "54027"
  },
  {
    "key": "11313",
    "ITA": "PONTREMOLI",
    "state_code": "MS",
    "cab": "699603",
    "cap": "54027"
  },
  {
    "key": "11314",
    "ITA": "PONTREMOLI-GRONDOLA",
    "state_code": "MS",
    "cab": "699603",
    "cap": "54027"
  },
  {
    "key": "11315",
    "ITA": "PONZA",
    "state_code": "LT",
    "cab": "740704",
    "cap": "4027"
  },
  {
    "key": "11316",
    "ITA": "PONZA-LE FORNA",
    "state_code": "LT",
    "cab": "740704",
    "cap": "4020"
  },
  {
    "key": "11317",
    "ITA": "PONZANO DI FERMO",
    "state_code": "FM",
    "cab": "820407",
    "cap": "63020"
  },
  {
    "key": "11318",
    "ITA": "PONZANO DI FERMO-TORCHIARO",
    "state_code": "FM",
    "cab": "820407",
    "cap": "63020"
  },
  {
    "key": "11319",
    "ITA": "PONZANO DI FERMO-CAPPARUCCIA",
    "state_code": "FM",
    "cab": "820407",
    "cap": "63020"
  },
  {
    "key": "11320",
    "ITA": "PONZANO MONFERRATO",
    "state_code": "AL",
    "cab": "921569",
    "cap": "15020"
  },
  {
    "key": "11321",
    "ITA": "PONZANO ROMANO",
    "state_code": "RM",
    "cab": "393405",
    "cap": "60"
  },
  {
    "key": "11322",
    "ITA": "PONZANO VENETO",
    "state_code": "TV",
    "cab": "619403",
    "cap": "31050"
  },
  {
    "key": "11323",
    "ITA": "PONZONE",
    "state_code": "AL",
    "cab": "485003",
    "cap": "15010"
  },
  {
    "key": "11324",
    "ITA": "POPOLI",
    "state_code": "PE",
    "cab": "773903",
    "cap": "65026"
  },
  {
    "key": "11325",
    "ITA": "POPPI",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52014"
  },
  {
    "key": "11326",
    "ITA": "POPPI-PONTE A POPPI",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52014"
  },
  {
    "key": "11327",
    "ITA": "POPPI-MOGGIONA",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52010"
  },
  {
    "key": "11328",
    "ITA": "POPPI-CAMALDOLI",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52010"
  },
  {
    "key": "11329",
    "ITA": "POPPI-BADIA PRATAGLIA",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52010"
  },
  {
    "key": "11330",
    "ITA": "POPPI-QUOTA",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52014"
  },
  {
    "key": "11331",
    "ITA": "POPPI-PORRENA",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52014"
  },
  {
    "key": "11332",
    "ITA": "POPPI-AVENA",
    "state_code": "AR",
    "cab": "715805",
    "cap": "52014"
  },
  {
    "key": "11333",
    "ITA": "PORANO",
    "state_code": "TR",
    "cab": "884403",
    "cap": "5010"
  },
  {
    "key": "11334",
    "ITA": "PORCARI",
    "state_code": "LU",
    "cab": "702308",
    "cap": "55016"
  },
  {
    "key": "11335",
    "ITA": "PORCIA-PALSE",
    "state_code": "PN",
    "cab": "649509",
    "cap": "33080"
  },
  {
    "key": "11336",
    "ITA": "PORCIA-RORAIPICCOLO",
    "state_code": "PN",
    "cab": "649509",
    "cap": "33080"
  },
  {
    "key": "11337",
    "ITA": "PORCIA",
    "state_code": "PN",
    "cab": "649509",
    "cap": "33080"
  },
  {
    "key": "11338",
    "ITA": "PORDENONE-LA COMINA",
    "state_code": "PN",
    "cab": "125005",
    "cap": "33170"
  },
  {
    "key": "11339",
    "ITA": "PORDENONE-VALLENONCELLO",
    "state_code": "PN",
    "cab": "125005",
    "cap": "33170"
  },
  {
    "key": "11340",
    "ITA": "PORDENONE",
    "state_code": "PN",
    "cab": "125005",
    "cap": "33170"
  },
  {
    "key": "11341",
    "ITA": "PORDENONE-BORGO MEDUNA",
    "state_code": "PN",
    "cab": "125005",
    "cap": "33170"
  },
  {
    "key": "11342",
    "ITA": "PORLEZZA-CIMA",
    "state_code": "CO",
    "cab": "517003",
    "cap": "22018"
  },
  {
    "key": "11343",
    "ITA": "PORLEZZA",
    "state_code": "CO",
    "cab": "517003",
    "cap": "22018"
  },
  {
    "key": "11344",
    "ITA": "PORNASSIO-NAVA",
    "state_code": "IM",
    "cab": "922559",
    "cap": "18024"
  },
  {
    "key": "11345",
    "ITA": "PORNASSIO",
    "state_code": "IM",
    "cab": "922559",
    "cap": "18024"
  },
  {
    "key": "11346",
    "ITA": "PORPETTO",
    "state_code": "UD",
    "cab": "645101",
    "cap": "33050"
  },
  {
    "key": "11347",
    "ITA": "PORPETTO-CASTELLO",
    "state_code": "UD",
    "cab": "645101",
    "cap": "33050"
  },
  {
    "key": "11348",
    "ITA": "PORRETTA TERME",
    "state_code": "BO",
    "cab": "370205",
    "cap": "40046"
  },
  {
    "key": "11349",
    "ITA": "PORRETTA TERME-CASTELLUCCIO",
    "state_code": "BO",
    "cab": "370205",
    "cap": "40046"
  },
  {
    "key": "11350",
    "ITA": "PORRETTA TERME-CAPUGNANO",
    "state_code": "BO",
    "cab": "370205",
    "cap": "40046"
  },
  {
    "key": "11351",
    "ITA": "PORTACOMARO-MIGLIANDOLO",
    "state_code": "AT",
    "cab": "476101",
    "cap": "14037"
  },
  {
    "key": "11352",
    "ITA": "PORTACOMARO",
    "state_code": "AT",
    "cab": "476101",
    "cap": "14037"
  },
  {
    "key": "11353",
    "ITA": "PORTALBERA",
    "state_code": "PV",
    "cab": "812602",
    "cap": "27040"
  },
  {
    "key": "11354",
    "ITA": "PORTE",
    "state_code": "TO",
    "cab": "190181",
    "cap": "10060"
  },
  {
    "key": "11355",
    "ITA": "PORTICI",
    "state_code": "NA",
    "cab": "400903",
    "cap": "80055"
  },
  {
    "key": "11356",
    "ITA": "PORTICI-BELLAVISTA",
    "state_code": "NA",
    "cab": "400903",
    "cap": "80055"
  },
  {
    "key": "11357",
    "ITA": "PORTICO DI CASERTA",
    "state_code": "CE",
    "cab": "898403",
    "cap": "81050"
  },
  {
    "key": "11358",
    "ITA": "PORTICO DI CASERTA-MUSICILE",
    "state_code": "CE",
    "cab": "898403",
    "cap": "81050"
  },
  {
    "key": "11359",
    "ITA": "PORTICO E SAN BENEDETTO-BOCCONI",
    "state_code": "FC",
    "cab": "679407",
    "cap": "47010"
  },
  {
    "key": "11360",
    "ITA": "PORTICO E SAN BENEDETTO",
    "state_code": "FC",
    "cab": "679407",
    "cap": "47010"
  },
  {
    "key": "11361",
    "ITA": "PORTICO E SAN BENEDETTO-PORTICO DI ROMAGNA",
    "state_code": "FC",
    "cab": "679407",
    "cap": "47010"
  },
  {
    "key": "11362",
    "ITA": "PORTICO E SAN BENEDETTO-SAN BENEDETTO IN ALPE",
    "state_code": "FC",
    "cab": "679407",
    "cap": "47010"
  },
  {
    "key": "11363",
    "ITA": "PORTIGLIOLA",
    "state_code": "RC",
    "cab": "192807",
    "cap": "89040"
  },
  {
    "key": "11364",
    "ITA": "PORTO AZZURRO",
    "state_code": "LI",
    "cab": "707307",
    "cap": "57036"
  },
  {
    "key": "11365",
    "ITA": "PORTO CERESIO",
    "state_code": "VA",
    "cab": "504902",
    "cap": "21050"
  },
  {
    "key": "11366",
    "ITA": "PORTO CESAREO",
    "state_code": "LE",
    "cab": "801803",
    "cap": "73010"
  },
  {
    "key": "11367",
    "ITA": "PORTO CESAREO-TORRE LAPILLO",
    "state_code": "LE",
    "cab": "801803",
    "cap": "73010"
  },
  {
    "key": "11368",
    "ITA": "PORTO EMPEDOCLE",
    "state_code": "AG",
    "cab": "830406",
    "cap": "92014"
  },
  {
    "key": "11369",
    "ITA": "PORTO MANTOVANO-SOAVE",
    "state_code": "MN",
    "cab": "578203",
    "cap": "46047"
  },
  {
    "key": "11370",
    "ITA": "PORTO MANTOVANO",
    "state_code": "MN",
    "cab": "578203",
    "cap": "46047"
  },
  {
    "key": "11371",
    "ITA": "PORTO MANTOVANO-SANT'ANTONIO",
    "state_code": "MN",
    "cab": "578203",
    "cap": "46047"
  },
  {
    "key": "11372",
    "ITA": "PORTO RECANATI",
    "state_code": "MC",
    "cab": "691105",
    "cap": "62017"
  },
  {
    "key": "11373",
    "ITA": "PORTO SAN GIORGIO",
    "state_code": "FM",
    "cab": "696609",
    "cap": "63017"
  },
  {
    "key": "11374",
    "ITA": "PORTO SANT'ELPIDIO",
    "state_code": "FM",
    "cab": "696708",
    "cap": "63018"
  },
  {
    "key": "11375",
    "ITA": "PORTO TOLLE-GNOCCA",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11376",
    "ITA": "PORTO TOLLE-IVICA",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11377",
    "ITA": "PORTO TOLLE-SCARDOVARI",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11378",
    "ITA": "PORTO TOLLE-TOLLE",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11379",
    "ITA": "PORTO TOLLE-DONZELLA",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11380",
    "ITA": "PORTO TOLLE-CA' ZULIANI",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11381",
    "ITA": "PORTO TOLLE-CA' TIEPOLO",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11382",
    "ITA": "PORTO TOLLE-CA' DOLFIN",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11383",
    "ITA": "PORTO TOLLE-BONELLI",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11384",
    "ITA": "PORTO TOLLE-BOCCASETTE",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11385",
    "ITA": "PORTO TOLLE",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11386",
    "ITA": "PORTO TOLLE-CA' VENIER",
    "state_code": "RO",
    "cab": "634303",
    "cap": "45018"
  },
  {
    "key": "11387",
    "ITA": "PORTO TORRES-ASINARA CALA D'OLIVA",
    "state_code": "SS",
    "cab": "850305",
    "cap": "7046"
  },
  {
    "key": "11388",
    "ITA": "PORTO TORRES-CALA REALE",
    "state_code": "SS",
    "cab": "850305",
    "cap": "7046"
  },
  {
    "key": "11389",
    "ITA": "PORTO TORRES",
    "state_code": "SS",
    "cab": "850305",
    "cap": "7046"
  },
  {
    "key": "11390",
    "ITA": "PORTO VALTRAVAGLIA-LIGURNO",
    "state_code": "VA",
    "cab": "505008",
    "cap": "21010"
  },
  {
    "key": "11391",
    "ITA": "PORTO VALTRAVAGLIA-MUCENO",
    "state_code": "VA",
    "cab": "505008",
    "cap": "21010"
  },
  {
    "key": "11392",
    "ITA": "PORTO VALTRAVAGLIA",
    "state_code": "VA",
    "cab": "505008",
    "cap": "21010"
  },
  {
    "key": "11393",
    "ITA": "PORTO VALTRAVAGLIA-DOMO",
    "state_code": "VA",
    "cab": "505008",
    "cap": "21010"
  },
  {
    "key": "11394",
    "ITA": "PORTO VIRO-CONTARINA",
    "state_code": "RO",
    "cab": "687301",
    "cap": "45014"
  },
  {
    "key": "11395",
    "ITA": "PORTO VIRO-DONADA",
    "state_code": "RO",
    "cab": "687301",
    "cap": "45014"
  },
  {
    "key": "11396",
    "ITA": "PORTO VIRO-VILLAREGIA",
    "state_code": "RO",
    "cab": "687301",
    "cap": "45014"
  },
  {
    "key": "11397",
    "ITA": "PORTO VIRO",
    "state_code": "RO",
    "cab": "687301",
    "cap": "45014"
  },
  {
    "key": "11398",
    "ITA": "PORTOBUFFOLE'",
    "state_code": "TV",
    "cab": "524306",
    "cap": "31040"
  },
  {
    "key": "11399",
    "ITA": "PORTOCANNONE",
    "state_code": "CB",
    "cab": "450502",
    "cap": "86045"
  },
  {
    "key": "11400",
    "ITA": "PORTOFERRAIO-SAN GIOVANNI",
    "state_code": "LI",
    "cab": "707406",
    "cap": "57037"
  },
  {
    "key": "11401",
    "ITA": "PORTOFERRAIO-MAGAZZINI",
    "state_code": "LI",
    "cab": "707406",
    "cap": "57037"
  },
  {
    "key": "11402",
    "ITA": "PORTOFERRAIO-CARPANI",
    "state_code": "LI",
    "cab": "707406",
    "cap": "57037"
  },
  {
    "key": "11403",
    "ITA": "PORTOFERRAIO",
    "state_code": "LI",
    "cab": "707406",
    "cap": "57037"
  },
  {
    "key": "11404",
    "ITA": "PORTOFINO",
    "state_code": "GE",
    "cab": "321000",
    "cap": "16034"
  },
  {
    "key": "11405",
    "ITA": "PORTOGRUARO-LUGUGNANA",
    "state_code": "VE",
    "cab": "362400",
    "cap": "30020"
  },
  {
    "key": "11406",
    "ITA": "PORTOGRUARO",
    "state_code": "VE",
    "cab": "362400",
    "cap": "30026"
  },
  {
    "key": "11407",
    "ITA": "PORTOGRUARO-PRADIPOZZO",
    "state_code": "VE",
    "cab": "362400",
    "cap": "30026"
  },
  {
    "key": "11408",
    "ITA": "PORTOGRUARO-SUMMAGA",
    "state_code": "VE",
    "cab": "362400",
    "cap": "30026"
  },
  {
    "key": "11409",
    "ITA": "PORTOMAGGIORE-RIPAPERSICO",
    "state_code": "FE",
    "cab": "673202",
    "cap": "44015"
  },
  {
    "key": "11410",
    "ITA": "PORTOMAGGIORE-PORTOVERRARA",
    "state_code": "FE",
    "cab": "673202",
    "cap": "44015"
  },
  {
    "key": "11411",
    "ITA": "PORTOMAGGIORE-MAIERO",
    "state_code": "FE",
    "cab": "673202",
    "cap": "44015"
  },
  {
    "key": "11412",
    "ITA": "PORTOMAGGIORE-GAMBULAGA",
    "state_code": "FE",
    "cab": "673202",
    "cap": "44015"
  },
  {
    "key": "11413",
    "ITA": "PORTOMAGGIORE-RUNCO",
    "state_code": "FE",
    "cab": "673202",
    "cap": "44015"
  },
  {
    "key": "11414",
    "ITA": "PORTOMAGGIORE",
    "state_code": "FE",
    "cab": "673202",
    "cap": "44015"
  },
  {
    "key": "11415",
    "ITA": "PORTOPALO DI CAPO PASSERO",
    "state_code": "SR",
    "cab": "848002",
    "cap": "96010"
  },
  {
    "key": "11416",
    "ITA": "PORTOSCUSO-PARINGIANU",
    "state_code": "SU",
    "cab": "439307",
    "cap": "9010"
  },
  {
    "key": "11417",
    "ITA": "PORTOSCUSO",
    "state_code": "SU",
    "cab": "439307",
    "cap": "9010"
  },
  {
    "key": "11418",
    "ITA": "PORTOVENERE-LE GRAZIE",
    "state_code": "SP",
    "cab": "498105",
    "cap": "19025"
  },
  {
    "key": "11419",
    "ITA": "PORTOVENERE-ISOLA PALMARIA",
    "state_code": "SP",
    "cab": "498105",
    "cap": "19025"
  },
  {
    "key": "11420",
    "ITA": "PORTOVENERE",
    "state_code": "SP",
    "cab": "498105",
    "cap": "19025"
  },
  {
    "key": "11421",
    "ITA": "PORTOVENERE-FEZZANO",
    "state_code": "SP",
    "cab": "498105",
    "cap": "19025"
  },
  {
    "key": "11422",
    "ITA": "PORTULA",
    "state_code": "BI",
    "cab": "916155",
    "cap": "13833"
  },
  {
    "key": "11423",
    "ITA": "POSADA",
    "state_code": "NU",
    "cab": "869206",
    "cap": "8020"
  },
  {
    "key": "11424",
    "ITA": "POSADA-MONTE LONGU",
    "state_code": "NU",
    "cab": "869206",
    "cap": "8020"
  },
  {
    "key": "11425",
    "ITA": "POSADA-SA PALA RUIA",
    "state_code": "NU",
    "cab": "869206",
    "cap": "8020"
  },
  {
    "key": "11426",
    "ITA": "POSADA-SAN GIOVANNI",
    "state_code": "NU",
    "cab": "869206",
    "cap": "8020"
  },
  {
    "key": "11427",
    "ITA": "POSADA-SAS MURTAS",
    "state_code": "NU",
    "cab": "869206",
    "cap": "8020"
  },
  {
    "key": "11428",
    "ITA": "POSINA",
    "state_code": "VI",
    "cab": "708107",
    "cap": "36010"
  },
  {
    "key": "11429",
    "ITA": "POSITANO-MONTEPERTUSO",
    "state_code": "SA",
    "cab": "763508",
    "cap": "84017"
  },
  {
    "key": "11430",
    "ITA": "POSITANO",
    "state_code": "SA",
    "cab": "763508",
    "cap": "84017"
  },
  {
    "key": "11431",
    "ITA": "POSSAGNO",
    "state_code": "TV",
    "cab": "619502",
    "cap": "31054"
  },
  {
    "key": "11432",
    "ITA": "POSTA-SIGILLO DI POSTA",
    "state_code": "RI",
    "cab": "737502",
    "cap": "2019"
  },
  {
    "key": "11433",
    "ITA": "POSTA-PICCIAME",
    "state_code": "RI",
    "cab": "737502",
    "cap": "2019"
  },
  {
    "key": "11434",
    "ITA": "POSTA-FAVISCHIO",
    "state_code": "RI",
    "cab": "737502",
    "cap": "2019"
  },
  {
    "key": "11435",
    "ITA": "POSTA",
    "state_code": "RI",
    "cab": "737502",
    "cap": "2019"
  },
  {
    "key": "11436",
    "ITA": "POSTA FIBRENO",
    "state_code": "FR",
    "cab": "948463",
    "cap": "3030"
  },
  {
    "key": "11437",
    "ITA": "POSTAL",
    "state_code": "BZ",
    "cab": "587105",
    "cap": "39014"
  },
  {
    "key": "11438",
    "ITA": "POSTALESIO",
    "state_code": "SO",
    "cab": "927228",
    "cap": "23010"
  },
  {
    "key": "11439",
    "ITA": "POSTIGLIONE",
    "state_code": "SA",
    "cab": "886309",
    "cap": "84026"
  },
  {
    "key": "11440",
    "ITA": "POSTUA",
    "state_code": "VC",
    "cab": "446708",
    "cap": "13010"
  },
  {
    "key": "11441",
    "ITA": "POTENZA",
    "state_code": "PZ",
    "cab": "42002",
    "cap": "85100"
  },
  {
    "key": "11442",
    "ITA": "POTENZA-MONTOCCHIO",
    "state_code": "PZ",
    "cab": "42002",
    "cap": "85100"
  },
  {
    "key": "11443",
    "ITA": "POTENZA-GIULIANO",
    "state_code": "PZ",
    "cab": "42002",
    "cap": "85100"
  },
  {
    "key": "11444",
    "ITA": "POTENZA-AVIGLIANO SCALO",
    "state_code": "PZ",
    "cab": "42002",
    "cap": "85020"
  },
  {
    "key": "11445",
    "ITA": "POTENZA PICENA",
    "state_code": "MC",
    "cab": "691204",
    "cap": "62018"
  },
  {
    "key": "11446",
    "ITA": "POTENZA PICENA-PORTO POTENZA PICENA",
    "state_code": "MC",
    "cab": "691204",
    "cap": "62018"
  },
  {
    "key": "11447",
    "ITA": "POVE DEL GRAPPA",
    "state_code": "VI",
    "cab": "889600",
    "cap": "36020"
  },
  {
    "key": "11448",
    "ITA": "POVEGLIANO-SANTANDRA'",
    "state_code": "TV",
    "cab": "382200",
    "cap": "31050"
  },
  {
    "key": "11449",
    "ITA": "POVEGLIANO",
    "state_code": "TV",
    "cab": "382200",
    "cap": "31050"
  },
  {
    "key": "11450",
    "ITA": "POVEGLIANO-CAMALO'",
    "state_code": "TV",
    "cab": "382200",
    "cap": "31050"
  },
  {
    "key": "11451",
    "ITA": "POVEGLIANO VERONESE",
    "state_code": "VR",
    "cab": "596700",
    "cap": "37064"
  },
  {
    "key": "11452",
    "ITA": "POVIGLIO",
    "state_code": "RE",
    "cab": "664102",
    "cap": "42028"
  },
  {
    "key": "11453",
    "ITA": "POVOLETTO-SAVORGNANO DEL TORRE",
    "state_code": "UD",
    "cab": "641100",
    "cap": "33040"
  },
  {
    "key": "11454",
    "ITA": "POVOLETTO",
    "state_code": "UD",
    "cab": "641100",
    "cap": "33040"
  },
  {
    "key": "11455",
    "ITA": "POVOLETTO-PRIMULACCO",
    "state_code": "UD",
    "cab": "641100",
    "cap": "33040"
  },
  {
    "key": "11456",
    "ITA": "POVOLETTO-MAGREDIS",
    "state_code": "UD",
    "cab": "641100",
    "cap": "33040"
  },
  {
    "key": "11457",
    "ITA": "POVOLETTO-GRIONS",
    "state_code": "UD",
    "cab": "641100",
    "cap": "33040"
  },
  {
    "key": "11458",
    "ITA": "POVOLETTO-RAVOSA",
    "state_code": "UD",
    "cab": "641100",
    "cap": "33040"
  },
  {
    "key": "11459",
    "ITA": "POZZA DI FASSA-PERA",
    "state_code": "TN",
    "cab": "352708",
    "cap": "38036"
  },
  {
    "key": "11460",
    "ITA": "POZZA DI FASSA",
    "state_code": "TN",
    "cab": "352708",
    "cap": "38036"
  },
  {
    "key": "11461",
    "ITA": "POZZAGLIA SABINA",
    "state_code": "RI",
    "cab": "947374",
    "cap": "2030"
  },
  {
    "key": "11462",
    "ITA": "POZZAGLIO ED UNITI",
    "state_code": "CR",
    "cab": "739003",
    "cap": "26010"
  },
  {
    "key": "11463",
    "ITA": "POZZAGLIO ED UNITI-CASALSIGONE",
    "state_code": "CR",
    "cab": "739003",
    "cap": "26010"
  },
  {
    "key": "11464",
    "ITA": "POZZAGLIO ED UNITI-POZZAGLIO",
    "state_code": "CR",
    "cab": "739003",
    "cap": "26010"
  },
  {
    "key": "11465",
    "ITA": "POZZALLO",
    "state_code": "RG",
    "cab": "845008",
    "cap": "97016"
  },
  {
    "key": "11466",
    "ITA": "POZZILLI-SANTA MARIA OLIVETO",
    "state_code": "IS",
    "cab": "781708",
    "cap": "86077"
  },
  {
    "key": "11467",
    "ITA": "POZZILLI",
    "state_code": "IS",
    "cab": "781708",
    "cap": "86077"
  },
  {
    "key": "11468",
    "ITA": "POZZO D'ADDA-BETTOLA DI POZZO D'ADDA",
    "state_code": "MI",
    "cab": "449801",
    "cap": "20060"
  },
  {
    "key": "11469",
    "ITA": "POZZO D'ADDA",
    "state_code": "MI",
    "cab": "449801",
    "cap": "20060"
  },
  {
    "key": "11470",
    "ITA": "POZZOL GROPPO",
    "state_code": "AL",
    "cab": "921577",
    "cap": "15050"
  },
  {
    "key": "11471",
    "ITA": "POZZOLENGO",
    "state_code": "BS",
    "cab": "550202",
    "cap": "25010"
  },
  {
    "key": "11472",
    "ITA": "POZZOLEONE-FRIOLA",
    "state_code": "VI",
    "cab": "606608",
    "cap": "36050"
  },
  {
    "key": "11473",
    "ITA": "POZZOLEONE",
    "state_code": "VI",
    "cab": "606608",
    "cap": "36050"
  },
  {
    "key": "11474",
    "ITA": "POZZOLO FORMIGARO",
    "state_code": "AL",
    "cab": "485102",
    "cap": "15068"
  },
  {
    "key": "11475",
    "ITA": "POZZOMAGGIORE",
    "state_code": "SS",
    "cab": "850404",
    "cap": "7018"
  },
  {
    "key": "11476",
    "ITA": "POZZONOVO",
    "state_code": "PD",
    "cab": "889006",
    "cap": "35020"
  },
  {
    "key": "11477",
    "ITA": "POZZUOLI-MONTERUSCIELLO",
    "state_code": "NA",
    "cab": "401000",
    "cap": "80078"
  },
  {
    "key": "11478",
    "ITA": "POZZUOLI-LIDO DI LICOLA",
    "state_code": "NA",
    "cab": "401000",
    "cap": "80078"
  },
  {
    "key": "11479",
    "ITA": "POZZUOLI-LAGO AVERNO",
    "state_code": "NA",
    "cab": "401000",
    "cap": "80078"
  },
  {
    "key": "11480",
    "ITA": "POZZUOLI-CAPPUCCINI",
    "state_code": "NA",
    "cab": "401000",
    "cap": "80078"
  },
  {
    "key": "11481",
    "ITA": "POZZUOLI-ARCO FELICE",
    "state_code": "NA",
    "cab": "401000",
    "cap": "80078"
  },
  {
    "key": "11482",
    "ITA": "POZZUOLI",
    "state_code": "NA",
    "cab": "401000",
    "cap": "80078"
  },
  {
    "key": "11483",
    "ITA": "POZZUOLI-LUCRINO",
    "state_code": "NA",
    "cab": "401000",
    "cap": "80078"
  },
  {
    "key": "11484",
    "ITA": "POZZUOLO DEL FRIULI-ZUGLIANO",
    "state_code": "UD",
    "cab": "641209",
    "cap": "33050"
  },
  {
    "key": "11485",
    "ITA": "POZZUOLO DEL FRIULI-TERENZANO",
    "state_code": "UD",
    "cab": "641209",
    "cap": "33050"
  },
  {
    "key": "11486",
    "ITA": "POZZUOLO DEL FRIULI-SAMMARDENCHIA",
    "state_code": "UD",
    "cab": "641209",
    "cap": "33050"
  },
  {
    "key": "11487",
    "ITA": "POZZUOLO DEL FRIULI-CARPENETO",
    "state_code": "UD",
    "cab": "641209",
    "cap": "33050"
  },
  {
    "key": "11488",
    "ITA": "POZZUOLO DEL FRIULI",
    "state_code": "UD",
    "cab": "641209",
    "cap": "33050"
  },
  {
    "key": "11489",
    "ITA": "POZZUOLO DEL FRIULI-CARGNACCO",
    "state_code": "UD",
    "cab": "641209",
    "cap": "33050"
  },
  {
    "key": "11490",
    "ITA": "POZZUOLO MARTESANA",
    "state_code": "MI",
    "cab": "336107",
    "cap": "20060"
  },
  {
    "key": "11491",
    "ITA": "POZZUOLO MARTESANA-TRECELLA",
    "state_code": "MI",
    "cab": "336107",
    "cap": "20060"
  },
  {
    "key": "11492",
    "ITA": "PRADALUNGA-CORNALE",
    "state_code": "BG",
    "cab": "533901",
    "cap": "24020"
  },
  {
    "key": "11493",
    "ITA": "PRADALUNGA",
    "state_code": "BG",
    "cab": "533901",
    "cap": "24020"
  },
  {
    "key": "11494",
    "ITA": "PRADAMANO",
    "state_code": "UD",
    "cab": "644609",
    "cap": "33040"
  },
  {
    "key": "11495",
    "ITA": "PRADLEVES",
    "state_code": "CN",
    "cab": "466607",
    "cap": "12027"
  },
  {
    "key": "11496",
    "ITA": "PRAGELATO",
    "state_code": "TO",
    "cab": "308205",
    "cap": "10060"
  },
  {
    "key": "11497",
    "ITA": "PRAIA A MARE",
    "state_code": "CS",
    "cab": "808709",
    "cap": "87028"
  },
  {
    "key": "11498",
    "ITA": "PRAIANO",
    "state_code": "SA",
    "cab": "763607",
    "cap": "84010"
  },
  {
    "key": "11499",
    "ITA": "PRALBOINO",
    "state_code": "BS",
    "cab": "550301",
    "cap": "25020"
  },
  {
    "key": "11500",
    "ITA": "PRALI",
    "state_code": "TO",
    "cab": "190272",
    "cap": "10060"
  },
  {
    "key": "11501",
    "ITA": "PRALORMO",
    "state_code": "TO",
    "cab": "665703",
    "cap": "10040"
  },
  {
    "key": "11502",
    "ITA": "PRALUNGO",
    "state_code": "BI",
    "cab": "446807",
    "cap": "13899"
  },
  {
    "key": "11503",
    "ITA": "PRAMAGGIORE",
    "state_code": "VE",
    "cab": "362509",
    "cap": "30020"
  },
  {
    "key": "11504",
    "ITA": "PRAMAGGIORE-BELFIORE",
    "state_code": "VE",
    "cab": "362509",
    "cap": "30020"
  },
  {
    "key": "11505",
    "ITA": "PRAMAGGIORE-PRAMAGGIORE BLESSAGLIA",
    "state_code": "VE",
    "cab": "362509",
    "cap": "30020"
  },
  {
    "key": "11506",
    "ITA": "PRAMOLLO",
    "state_code": "TO",
    "cab": "901199",
    "cap": "10065"
  },
  {
    "key": "11507",
    "ITA": "PRAROLO",
    "state_code": "VC",
    "cab": "916163",
    "cap": "13012"
  },
  {
    "key": "11508",
    "ITA": "PRAROSTINO",
    "state_code": "TO",
    "cab": "901207",
    "cap": "10060"
  },
  {
    "key": "11509",
    "ITA": "PRASCO",
    "state_code": "AL",
    "cab": "921585",
    "cap": "15010"
  },
  {
    "key": "11510",
    "ITA": "PRASCORSANO",
    "state_code": "TO",
    "cab": "284208",
    "cap": "10080"
  },
  {
    "key": "11511",
    "ITA": "PRASO",
    "state_code": "TN",
    "cab": "905018",
    "cap": "38080"
  },
  {
    "key": "11512",
    "ITA": "PRATA CAMPORTACCIO-SAN CASSIANO",
    "state_code": "SO",
    "cab": "523605",
    "cap": "23020"
  },
  {
    "key": "11513",
    "ITA": "PRATA CAMPORTACCIO",
    "state_code": "SO",
    "cab": "523605",
    "cap": "23020"
  },
  {
    "key": "11514",
    "ITA": "PRATA D'ANSIDONIA-TUSSIO",
    "state_code": "AQ",
    "cab": "909846",
    "cap": "67020"
  },
  {
    "key": "11515",
    "ITA": "PRATA D'ANSIDONIA",
    "state_code": "AQ",
    "cab": "909846",
    "cap": "67020"
  },
  {
    "key": "11516",
    "ITA": "PRATA DI PORDENONE",
    "state_code": "PN",
    "cab": "649608",
    "cap": "33080"
  },
  {
    "key": "11517",
    "ITA": "PRATA DI PORDENONE-GHIRANO",
    "state_code": "PN",
    "cab": "649608",
    "cap": "33080"
  },
  {
    "key": "11518",
    "ITA": "PRATA DI PORDENONE-PUIA",
    "state_code": "PN",
    "cab": "649608",
    "cap": "33080"
  },
  {
    "key": "11519",
    "ITA": "PRATA DI PORDENONE-VILLANOVA",
    "state_code": "PN",
    "cab": "649608",
    "cap": "33080"
  },
  {
    "key": "11520",
    "ITA": "PRATA DI PRINCIPATO ULTRA",
    "state_code": "AV",
    "cab": "396507",
    "cap": "83030"
  },
  {
    "key": "11521",
    "ITA": "PRATA SANNITA",
    "state_code": "CE",
    "cab": "496406",
    "cap": "81010"
  },
  {
    "key": "11522",
    "ITA": "PRATELLA",
    "state_code": "CE",
    "cab": "949503",
    "cap": "81010"
  },
  {
    "key": "11523",
    "ITA": "PRATIGLIONE",
    "state_code": "TO",
    "cab": "284232",
    "cap": "10080"
  },
  {
    "key": "11524",
    "ITA": "PRATO-VIACCIA",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11525",
    "ITA": "PRATO-TAVOLA",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11526",
    "ITA": "PRATO-SANTA MARIA A COLONICA",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11527",
    "ITA": "PRATO-SAN GIORGIO A COLONICA",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11528",
    "ITA": "PRATO-PAPERINO",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11529",
    "ITA": "PRATO-NARNALI",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11530",
    "ITA": "PRATO-MEZZANA",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11531",
    "ITA": "PRATO-MALISETI",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11532",
    "ITA": "PRATO-LA QUERCE",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11533",
    "ITA": "PRATO-IOLO",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11534",
    "ITA": "PRATO-GALCIANA",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11535",
    "ITA": "PRATO-FIGLINE DI PRATO",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11536",
    "ITA": "PRATO-COIANO",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11537",
    "ITA": "PRATO-CAFAGGIO",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11538",
    "ITA": "PRATO",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11539",
    "ITA": "PRATO-SANTA LUCIA",
    "state_code": "PO",
    "cab": "215004",
    "cap": "59100"
  },
  {
    "key": "11540",
    "ITA": "PRATO ALLO STELVIO-MONTECHIARO",
    "state_code": "BZ",
    "cab": "587204",
    "cap": "39026"
  },
  {
    "key": "11541",
    "ITA": "PRATO ALLO STELVIO",
    "state_code": "BZ",
    "cab": "587204",
    "cap": "39026"
  },
  {
    "key": "11542",
    "ITA": "PRATO CARNICO",
    "state_code": "UD",
    "cab": "641308",
    "cap": "33020"
  },
  {
    "key": "11543",
    "ITA": "PRATO CARNICO-PIERIA",
    "state_code": "UD",
    "cab": "641308",
    "cap": "33020"
  },
  {
    "key": "11544",
    "ITA": "PRATO CARNICO-PESARIIS",
    "state_code": "UD",
    "cab": "641308",
    "cap": "33020"
  },
  {
    "key": "11545",
    "ITA": "PRATO SESIA",
    "state_code": "NO",
    "cab": "458208",
    "cap": "28077"
  },
  {
    "key": "11546",
    "ITA": "PRATOLA PELIGNA",
    "state_code": "AQ",
    "cab": "407106",
    "cap": "67035"
  },
  {
    "key": "11547",
    "ITA": "PRATOLA PELIGNA-BAGNATURO",
    "state_code": "AQ",
    "cab": "407106",
    "cap": "67035"
  },
  {
    "key": "11548",
    "ITA": "PRATOLA SERRA-SAN MICHELE DI PRATOLA",
    "state_code": "AV",
    "cab": "759407",
    "cap": "83039"
  },
  {
    "key": "11549",
    "ITA": "PRATOLA SERRA",
    "state_code": "AV",
    "cab": "759407",
    "cap": "83039"
  },
  {
    "key": "11550",
    "ITA": "PRATOLA SERRA-SERRA",
    "state_code": "AV",
    "cab": "759407",
    "cap": "83039"
  },
  {
    "key": "11551",
    "ITA": "PRATOVECCHIO",
    "state_code": "AR",
    "cab": "715904",
    "cap": "52015"
  },
  {
    "key": "11552",
    "ITA": "PRAVISDOMINI-BARCO",
    "state_code": "PN",
    "cab": "649707",
    "cap": "33076"
  },
  {
    "key": "11553",
    "ITA": "PRAVISDOMINI",
    "state_code": "PN",
    "cab": "649707",
    "cap": "33076"
  },
  {
    "key": "11554",
    "ITA": "PRAY-FLECCHIA",
    "state_code": "BI",
    "cab": "446906",
    "cap": "13867"
  },
  {
    "key": "11555",
    "ITA": "PRAY",
    "state_code": "BI",
    "cab": "446906",
    "cap": "13867"
  },
  {
    "key": "11556",
    "ITA": "PRAY-PIANCERI ALTO",
    "state_code": "BI",
    "cab": "446906",
    "cap": "13867"
  },
  {
    "key": "11557",
    "ITA": "PRAZZO",
    "state_code": "CN",
    "cab": "466706",
    "cap": "12028"
  },
  {
    "key": "11558",
    "ITA": "PRAZZO-SAN MICHELE PRAZZO",
    "state_code": "CN",
    "cab": "466706",
    "cap": "12028"
  },
  {
    "key": "11559",
    "ITA": "PRE' SAINT DIDIER",
    "state_code": "AO",
    "cab": "316604",
    "cap": "11010"
  },
  {
    "key": "11560",
    "ITA": "PRE' SAINT DIDIER-VERRAND",
    "state_code": "AO",
    "cab": "316604",
    "cap": "11010"
  },
  {
    "key": "11561",
    "ITA": "PRECENICCO",
    "state_code": "UD",
    "cab": "894501",
    "cap": "33050"
  },
  {
    "key": "11562",
    "ITA": "PRECI-ROCCANOLFI",
    "state_code": "PG",
    "cab": "386300",
    "cap": "6047"
  },
  {
    "key": "11563",
    "ITA": "PRECI-PIEDIVALLE",
    "state_code": "PG",
    "cab": "386300",
    "cap": "6047"
  },
  {
    "key": "11564",
    "ITA": "PRECI-CASALI BELFORTE",
    "state_code": "PG",
    "cab": "386300",
    "cap": "6047"
  },
  {
    "key": "11565",
    "ITA": "PRECI-ABETO",
    "state_code": "PG",
    "cab": "386300",
    "cap": "6047"
  },
  {
    "key": "11566",
    "ITA": "PRECI",
    "state_code": "PG",
    "cab": "386300",
    "cap": "6047"
  },
  {
    "key": "11567",
    "ITA": "PRECI-TODIANO",
    "state_code": "PG",
    "cab": "386300",
    "cap": "6047"
  },
  {
    "key": "11568",
    "ITA": "PREDAIA",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11569",
    "ITA": "PREDAIA-COREDO",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11570",
    "ITA": "PREDAIA-DERMULO",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11571",
    "ITA": "PREDAIA-MOLLARO",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11572",
    "ITA": "PREDAIA-SAN ROMEDIO",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11573",
    "ITA": "PREDAIA-SEGNO",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11574",
    "ITA": "PREDAIA-SMARANO",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11575",
    "ITA": "PREDAIA-TAIO",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11576",
    "ITA": "PREDAIA-TAVON",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11577",
    "ITA": "PREDAIA-TRES",
    "state_code": "TN",
    "cab": "55145",
    "cap": "38012"
  },
  {
    "key": "11578",
    "ITA": "PREDAPPIO-TONTOLA",
    "state_code": "FC",
    "cab": "679506",
    "cap": "47016"
  },
  {
    "key": "11579",
    "ITA": "PREDAPPIO-PREDAPPIO ALTA",
    "state_code": "FC",
    "cab": "679506",
    "cap": "47016"
  },
  {
    "key": "11580",
    "ITA": "PREDAPPIO",
    "state_code": "FC",
    "cab": "679506",
    "cap": "47016"
  },
  {
    "key": "11581",
    "ITA": "PREDAPPIO-ROCCA DELLE CAMINATE",
    "state_code": "FC",
    "cab": "679506",
    "cap": "47016"
  },
  {
    "key": "11582",
    "ITA": "PREDAPPIO-FIUMANA",
    "state_code": "FC",
    "cab": "679506",
    "cap": "47016"
  },
  {
    "key": "11583",
    "ITA": "PREDAZZO",
    "state_code": "TN",
    "cab": "352807",
    "cap": "38037"
  },
  {
    "key": "11584",
    "ITA": "PREDAZZO-BELLAMONTE",
    "state_code": "TN",
    "cab": "352807",
    "cap": "38037"
  },
  {
    "key": "11585",
    "ITA": "PREDAZZO-PANEVEGGIO",
    "state_code": "TN",
    "cab": "352807",
    "cap": "38037"
  },
  {
    "key": "11586",
    "ITA": "PREDAZZO-PASSO ROLLE",
    "state_code": "TN",
    "cab": "352807",
    "cap": "38037"
  },
  {
    "key": "11587",
    "ITA": "PREDOI",
    "state_code": "BZ",
    "cab": "591909",
    "cap": "39030"
  },
  {
    "key": "11588",
    "ITA": "PREDORE",
    "state_code": "BG",
    "cab": "565200",
    "cap": "24060"
  },
  {
    "key": "11589",
    "ITA": "PREDOSA-CASTELFERRO",
    "state_code": "AL",
    "cab": "485201",
    "cap": "15077"
  },
  {
    "key": "11590",
    "ITA": "PREDOSA",
    "state_code": "AL",
    "cab": "485201",
    "cap": "15077"
  },
  {
    "key": "11591",
    "ITA": "PREGANZIOL-SAN TROVASO",
    "state_code": "TV",
    "cab": "619601",
    "cap": "31022"
  },
  {
    "key": "11592",
    "ITA": "PREGANZIOL-SAMBUGHE",
    "state_code": "TV",
    "cab": "619601",
    "cap": "31022"
  },
  {
    "key": "11593",
    "ITA": "PREGANZIOL",
    "state_code": "TV",
    "cab": "619601",
    "cap": "31022"
  },
  {
    "key": "11594",
    "ITA": "PREGANZIOL-BORGO VERDE",
    "state_code": "TV",
    "cab": "619601",
    "cap": "31022"
  },
  {
    "key": "11595",
    "ITA": "PREGANZIOL-FRESCADA",
    "state_code": "TV",
    "cab": "619601",
    "cap": "31022"
  },
  {
    "key": "11596",
    "ITA": "PREGNANA MILANESE",
    "state_code": "MI",
    "cab": "336206",
    "cap": "20010"
  },
  {
    "key": "11597",
    "ITA": "PRELA'",
    "state_code": "IM",
    "cab": "922567",
    "cap": "18020"
  },
  {
    "key": "11598",
    "ITA": "PREMANA",
    "state_code": "LC",
    "cab": "517102",
    "cap": "23834"
  },
  {
    "key": "11599",
    "ITA": "PREMARIACCO",
    "state_code": "UD",
    "cab": "641407",
    "cap": "33040"
  },
  {
    "key": "11600",
    "ITA": "PREMARIACCO-IPPLIS",
    "state_code": "UD",
    "cab": "641407",
    "cap": "33040"
  },
  {
    "key": "11601",
    "ITA": "PREMARIACCO-ORSARIA",
    "state_code": "UD",
    "cab": "641407",
    "cap": "33040"
  },
  {
    "key": "11602",
    "ITA": "PREMENO",
    "state_code": "VB",
    "cab": "456103",
    "cap": "28818"
  },
  {
    "key": "11603",
    "ITA": "PREMIA",
    "state_code": "VB",
    "cab": "917617",
    "cap": "28866"
  },
  {
    "key": "11604",
    "ITA": "PREMIA-SAN ROCCO",
    "state_code": "VB",
    "cab": "917617",
    "cap": "28866"
  },
  {
    "key": "11605",
    "ITA": "PREMILCUORE",
    "state_code": "FC",
    "cab": "679605",
    "cap": "47010"
  },
  {
    "key": "11606",
    "ITA": "PREMOLO",
    "state_code": "BG",
    "cab": "780106",
    "cap": "24020"
  },
  {
    "key": "11607",
    "ITA": "PREMOSELLO CHIOVENDA",
    "state_code": "VB",
    "cab": "458505",
    "cap": "28803"
  },
  {
    "key": "11608",
    "ITA": "PREMOSELLO CHIOVENDA-CUZZAGO",
    "state_code": "VB",
    "cab": "458505",
    "cap": "28803"
  },
  {
    "key": "11609",
    "ITA": "PREONE",
    "state_code": "UD",
    "cab": "937086",
    "cap": "33020"
  },
  {
    "key": "11610",
    "ITA": "PREORE",
    "state_code": "TN",
    "cab": "905026",
    "cap": "38070"
  },
  {
    "key": "11611",
    "ITA": "PREPOTTO-CASTELMONTE",
    "state_code": "UD",
    "cab": "655803",
    "cap": "33040"
  },
  {
    "key": "11612",
    "ITA": "PREPOTTO-PODRESCA",
    "state_code": "UD",
    "cab": "655803",
    "cap": "33040"
  },
  {
    "key": "11613",
    "ITA": "PREPOTTO",
    "state_code": "UD",
    "cab": "655803",
    "cap": "33040"
  },
  {
    "key": "11614",
    "ITA": "PRESEGLIE",
    "state_code": "BS",
    "cab": "550400",
    "cap": "25070"
  },
  {
    "key": "11615",
    "ITA": "PRESEGLIE-GAZZANE",
    "state_code": "BS",
    "cab": "550400",
    "cap": "25070"
  },
  {
    "key": "11616",
    "ITA": "PRESEGLIE-SOTTOCASTELLO",
    "state_code": "BS",
    "cab": "550400",
    "cap": "25070"
  },
  {
    "key": "11617",
    "ITA": "PRESENZANO",
    "state_code": "CE",
    "cab": "949511",
    "cap": "81050"
  },
  {
    "key": "11618",
    "ITA": "PRESENZANO-SAN FELICE",
    "state_code": "CE",
    "cab": "949511",
    "cap": "81050"
  },
  {
    "key": "11619",
    "ITA": "PRESEZZO",
    "state_code": "BG",
    "cab": "539205",
    "cap": "24030"
  },
  {
    "key": "11620",
    "ITA": "PRESICCE",
    "state_code": "LE",
    "cab": "799007",
    "cap": "73054"
  },
  {
    "key": "11621",
    "ITA": "PRESSANA",
    "state_code": "VR",
    "cab": "596809",
    "cap": "37040"
  },
  {
    "key": "11622",
    "ITA": "PRESSANA-CASELLE",
    "state_code": "VR",
    "cab": "596809",
    "cap": "37040"
  },
  {
    "key": "11623",
    "ITA": "PRESTINE",
    "state_code": "BS",
    "cab": "929653",
    "cap": "25040"
  },
  {
    "key": "11624",
    "ITA": "PRETORO",
    "state_code": "CH",
    "cab": "954917",
    "cap": "66010"
  },
  {
    "key": "11625",
    "ITA": "PREVALLE",
    "state_code": "BS",
    "cab": "550509",
    "cap": "25080"
  },
  {
    "key": "11626",
    "ITA": "PREZZA",
    "state_code": "AQ",
    "cab": "909853",
    "cap": "67030"
  },
  {
    "key": "11627",
    "ITA": "PREZZA-CAMPO DI FANO",
    "state_code": "AQ",
    "cab": "909853",
    "cap": "67030"
  },
  {
    "key": "11628",
    "ITA": "PREZZO",
    "state_code": "TN",
    "cab": "905034",
    "cap": "38085"
  },
  {
    "key": "11629",
    "ITA": "PRIERO",
    "state_code": "CN",
    "cab": "919183",
    "cap": "12070"
  },
  {
    "key": "11630",
    "ITA": "PRIGNANO CILENTO",
    "state_code": "SA",
    "cab": "952879",
    "cap": "84060"
  },
  {
    "key": "11631",
    "ITA": "PRIGNANO SULLA SECCHIA-MORANO",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11632",
    "ITA": "PRIGNANO SULLA SECCHIA-PIGNETO",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11633",
    "ITA": "PRIGNANO SULLA SECCHIA-SALTINO",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11634",
    "ITA": "PRIGNANO SULLA SECCHIA-MONTEBARANZONE",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11635",
    "ITA": "PRIGNANO SULLA SECCHIA-MONCERRATO",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11636",
    "ITA": "PRIGNANO SULLA SECCHIA-SASSO MORELLO",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11637",
    "ITA": "PRIGNANO SULLA SECCHIA",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11638",
    "ITA": "PRIGNANO SULLA SECCHIA-CASTELVECCHIO",
    "state_code": "MO",
    "cab": "669507",
    "cap": "41048"
  },
  {
    "key": "11639",
    "ITA": "PRIMALUNA",
    "state_code": "LC",
    "cab": "520007",
    "cap": "23819"
  },
  {
    "key": "11640",
    "ITA": "PRIMALUNA-CORTABBIO",
    "state_code": "LC",
    "cab": "520007",
    "cap": "23819"
  },
  {
    "key": "11641",
    "ITA": "PRIOCCA",
    "state_code": "CN",
    "cab": "466805",
    "cap": "12040"
  },
  {
    "key": "11642",
    "ITA": "PRIOLA",
    "state_code": "CN",
    "cab": "919191",
    "cap": "12070"
  },
  {
    "key": "11643",
    "ITA": "PRIOLO GARGALLO",
    "state_code": "SR",
    "cab": "846105",
    "cap": "96010"
  },
  {
    "key": "11644",
    "ITA": "PRIOLO GARGALLO-SAN FOCA'",
    "state_code": "SR",
    "cab": "846105",
    "cap": "96010"
  },
  {
    "key": "11645",
    "ITA": "PRIVERNO-FOSSANOVA",
    "state_code": "LT",
    "cab": "740803",
    "cap": "4015"
  },
  {
    "key": "11646",
    "ITA": "PRIVERNO",
    "state_code": "LT",
    "cab": "740803",
    "cap": "4015"
  },
  {
    "key": "11647",
    "ITA": "PRIZZI",
    "state_code": "PA",
    "cab": "435503",
    "cap": "90038"
  },
  {
    "key": "11648",
    "ITA": "PRIZZI-FILAGA",
    "state_code": "PA",
    "cab": "435503",
    "cap": "90038"
  },
  {
    "key": "11649",
    "ITA": "PROCENO",
    "state_code": "VT",
    "cab": "946756",
    "cap": "1020"
  },
  {
    "key": "11650",
    "ITA": "PROCIDA",
    "state_code": "NA",
    "cab": "401109",
    "cap": "80079"
  },
  {
    "key": "11651",
    "ITA": "PROPATA",
    "state_code": "GE",
    "cab": "903252",
    "cap": "16027"
  },
  {
    "key": "11652",
    "ITA": "PROSERPIO",
    "state_code": "CO",
    "cab": "182279",
    "cap": "22030"
  },
  {
    "key": "11653",
    "ITA": "PROSSEDI",
    "state_code": "LT",
    "cab": "740902",
    "cap": "4010"
  },
  {
    "key": "11654",
    "ITA": "PROSSEDI-PISTERZO",
    "state_code": "LT",
    "cab": "740902",
    "cap": "4010"
  },
  {
    "key": "11655",
    "ITA": "PROVAGLIO D'ISEO-ZURANE",
    "state_code": "BS",
    "cab": "550608",
    "cap": "25050"
  },
  {
    "key": "11656",
    "ITA": "PROVAGLIO D'ISEO",
    "state_code": "BS",
    "cab": "550608",
    "cap": "25050"
  },
  {
    "key": "11657",
    "ITA": "PROVAGLIO D'ISEO-FONTANE",
    "state_code": "BS",
    "cab": "550608",
    "cap": "25050"
  },
  {
    "key": "11658",
    "ITA": "PROVAGLIO D'ISEO-GRESINE",
    "state_code": "BS",
    "cab": "550608",
    "cap": "25050"
  },
  {
    "key": "11659",
    "ITA": "PROVAGLIO D'ISEO-PROVEZZE",
    "state_code": "BS",
    "cab": "550608",
    "cap": "25050"
  },
  {
    "key": "11660",
    "ITA": "PROVAGLIO VAL SABBIA",
    "state_code": "BS",
    "cab": "929661",
    "cap": "25070"
  },
  {
    "key": "11661",
    "ITA": "PROVES",
    "state_code": "BZ",
    "cab": "591701",
    "cap": "39040"
  },
  {
    "key": "11662",
    "ITA": "PROVVIDENTI",
    "state_code": "CB",
    "cab": "910836",
    "cap": "86040"
  },
  {
    "key": "11663",
    "ITA": "PRUNETTO",
    "state_code": "CN",
    "cab": "919209",
    "cap": "12077"
  },
  {
    "key": "11664",
    "ITA": "PUEGNAGO SUL GARDA-RAFFA",
    "state_code": "BS",
    "cab": "555904",
    "cap": "25080"
  },
  {
    "key": "11665",
    "ITA": "PUEGNAGO SUL GARDA",
    "state_code": "BS",
    "cab": "555904",
    "cap": "25080"
  },
  {
    "key": "11666",
    "ITA": "PUEGNAGO SUL GARDA-CASTELLO",
    "state_code": "BS",
    "cab": "555904",
    "cap": "25080"
  },
  {
    "key": "11667",
    "ITA": "PUGLIANELLO",
    "state_code": "BN",
    "cab": "950451",
    "cap": "82030"
  },
  {
    "key": "11668",
    "ITA": "PULA-FORTE VILLAGE",
    "state_code": "CA",
    "cab": "439406",
    "cap": "9010"
  },
  {
    "key": "11669",
    "ITA": "PULA",
    "state_code": "CA",
    "cab": "439406",
    "cap": "9010"
  },
  {
    "key": "11670",
    "ITA": "PULFERO",
    "state_code": "UD",
    "cab": "724302",
    "cap": "33046"
  },
  {
    "key": "11671",
    "ITA": "PULSANO",
    "state_code": "TA",
    "cab": "790105",
    "cap": "74026"
  },
  {
    "key": "11672",
    "ITA": "PULSANO-BOSCO CAGGIONE",
    "state_code": "TA",
    "cab": "790105",
    "cap": "74026"
  },
  {
    "key": "11673",
    "ITA": "PULSANO-LIDO SILVANA",
    "state_code": "TA",
    "cab": "790105",
    "cap": "74026"
  },
  {
    "key": "11674",
    "ITA": "PULSANO-MONTI D'ARENA",
    "state_code": "TA",
    "cab": "790105",
    "cap": "74026"
  },
  {
    "key": "11675",
    "ITA": "PUMENENGO",
    "state_code": "BG",
    "cab": "534008",
    "cap": "24050"
  },
  {
    "key": "11676",
    "ITA": "PUOS D'ALPAGO",
    "state_code": "BL",
    "cab": "612507",
    "cap": "32015"
  },
  {
    "key": "11677",
    "ITA": "PUOS D'ALPAGO-CORNEI",
    "state_code": "BL",
    "cab": "612507",
    "cap": "32015"
  },
  {
    "key": "11678",
    "ITA": "PUSIANO",
    "state_code": "CO",
    "cab": "832402",
    "cap": "22030"
  },
  {
    "key": "11679",
    "ITA": "PUTIFIGARI",
    "state_code": "SS",
    "cab": "875401",
    "cap": "7040"
  },
  {
    "key": "11680",
    "ITA": "PUTIGNANO-SAN PIETRO PITURNO",
    "state_code": "BA",
    "cab": "416305",
    "cap": "70017"
  },
  {
    "key": "11681",
    "ITA": "PUTIGNANO-SAN MICHELE IN MONTE LAURETO",
    "state_code": "BA",
    "cab": "416305",
    "cap": "70017"
  },
  {
    "key": "11682",
    "ITA": "PUTIGNANO",
    "state_code": "BA",
    "cab": "416305",
    "cap": "70017"
  },
  {
    "key": "11683",
    "ITA": "QUADRELLE",
    "state_code": "AV",
    "cab": "951483",
    "cap": "83020"
  },
  {
    "key": "11684",
    "ITA": "QUADRI",
    "state_code": "CH",
    "cab": "778100",
    "cap": "66040"
  },
  {
    "key": "11685",
    "ITA": "QUAGLIUZZO",
    "state_code": "TO",
    "cab": "284265",
    "cap": "10010"
  },
  {
    "key": "11686",
    "ITA": "QUALIANO",
    "state_code": "NA",
    "cab": "401208",
    "cap": "80019"
  },
  {
    "key": "11687",
    "ITA": "QUARANTI",
    "state_code": "AT",
    "cab": "920363",
    "cap": "14040"
  },
  {
    "key": "11688",
    "ITA": "QUAREGNA",
    "state_code": "BI",
    "cab": "495804",
    "cap": "13854"
  },
  {
    "key": "11689",
    "ITA": "QUARGNENTO",
    "state_code": "AL",
    "cab": "485300",
    "cap": "15044"
  },
  {
    "key": "11690",
    "ITA": "QUARNA SOPRA",
    "state_code": "VB",
    "cab": "917633",
    "cap": "28898"
  },
  {
    "key": "11691",
    "ITA": "QUARNA SOTTO",
    "state_code": "VB",
    "cab": "917641",
    "cap": "28896"
  },
  {
    "key": "11692",
    "ITA": "QUARONA",
    "state_code": "VC",
    "cab": "447003",
    "cap": "13017"
  },
  {
    "key": "11693",
    "ITA": "QUARONA-DOCCIO",
    "state_code": "VC",
    "cab": "447003",
    "cap": "13017"
  },
  {
    "key": "11694",
    "ITA": "QUARRATA",
    "state_code": "PT",
    "cab": "705004",
    "cap": "51039"
  },
  {
    "key": "11695",
    "ITA": "QUARRATA-CATENA",
    "state_code": "PT",
    "cab": "705004",
    "cap": "51039"
  },
  {
    "key": "11696",
    "ITA": "QUARRATA-FERRUCCIA",
    "state_code": "PT",
    "cab": "705004",
    "cap": "51030"
  },
  {
    "key": "11697",
    "ITA": "QUARRATA-TIZZANA",
    "state_code": "PT",
    "cab": "705004",
    "cap": "51039"
  },
  {
    "key": "11698",
    "ITA": "QUARRATA-OLMI",
    "state_code": "PT",
    "cab": "705004",
    "cap": "51039"
  },
  {
    "key": "11699",
    "ITA": "QUARRATA-SANTONUOVO",
    "state_code": "PT",
    "cab": "705004",
    "cap": "51039"
  },
  {
    "key": "11700",
    "ITA": "QUARRATA-MONTEMAGNO",
    "state_code": "PT",
    "cab": "705004",
    "cap": "51039"
  },
  {
    "key": "11701",
    "ITA": "QUART",
    "state_code": "AO",
    "cab": "292987",
    "cap": "11020"
  },
  {
    "key": "11702",
    "ITA": "QUART-VILLEFRANCHE",
    "state_code": "AO",
    "cab": "292987",
    "cap": "11020"
  },
  {
    "key": "11703",
    "ITA": "QUART-VILLE SUR NUS",
    "state_code": "AO",
    "cab": "292987",
    "cap": "11020"
  },
  {
    "key": "11704",
    "ITA": "QUART-VILLAIR",
    "state_code": "AO",
    "cab": "292987",
    "cap": "11020"
  },
  {
    "key": "11705",
    "ITA": "QUARTO",
    "state_code": "NA",
    "cab": "401307",
    "cap": "80010"
  },
  {
    "key": "11706",
    "ITA": "QUARTO D'ALTINO",
    "state_code": "VE",
    "cab": "362608",
    "cap": "30020"
  },
  {
    "key": "11707",
    "ITA": "QUARTO D'ALTINO-PORTEGRANDI",
    "state_code": "VE",
    "cab": "362608",
    "cap": "30020"
  },
  {
    "key": "11708",
    "ITA": "QUARTU SANT'ELENA",
    "state_code": "CA",
    "cab": "439505",
    "cap": "9045"
  },
  {
    "key": "11709",
    "ITA": "QUARTU SANT'ELENA-FLUMINI DI QUARTU SANT'ELENA",
    "state_code": "CA",
    "cab": "439505",
    "cap": "9045"
  },
  {
    "key": "11710",
    "ITA": "QUARTUCCIU",
    "state_code": "CA",
    "cab": "441204",
    "cap": "9044"
  },
  {
    "key": "11711",
    "ITA": "QUASSOLO",
    "state_code": "TO",
    "cab": "285270",
    "cap": "10010"
  },
  {
    "key": "11712",
    "ITA": "QUATTORDIO-PIEPASSO",
    "state_code": "AL",
    "cab": "485409",
    "cap": "15028"
  },
  {
    "key": "11713",
    "ITA": "QUATTORDIO",
    "state_code": "AL",
    "cab": "485409",
    "cap": "15028"
  },
  {
    "key": "11714",
    "ITA": "QUATTRO CASTELLA",
    "state_code": "RE",
    "cab": "664201",
    "cap": "42020"
  },
  {
    "key": "11715",
    "ITA": "QUATTRO CASTELLA-RONCOLO",
    "state_code": "RE",
    "cab": "664201",
    "cap": "42020"
  },
  {
    "key": "11716",
    "ITA": "QUATTRO CASTELLA-PUIANELLO",
    "state_code": "RE",
    "cab": "664201",
    "cap": "42020"
  },
  {
    "key": "11717",
    "ITA": "QUATTRO CASTELLA-MONTECAVOLO",
    "state_code": "RE",
    "cab": "664201",
    "cap": "42020"
  },
  {
    "key": "11718",
    "ITA": "QUERO",
    "state_code": "BL",
    "cab": "612606",
    "cap": "32030"
  },
  {
    "key": "11719",
    "ITA": "QUILIANO",
    "state_code": "SV",
    "cab": "494807",
    "cap": "17047"
  },
  {
    "key": "11720",
    "ITA": "QUILIANO-VALLEGGIA",
    "state_code": "SV",
    "cab": "494807",
    "cap": "17047"
  },
  {
    "key": "11721",
    "ITA": "QUINCINETTO",
    "state_code": "TO",
    "cab": "312702",
    "cap": "10010"
  },
  {
    "key": "11722",
    "ITA": "QUINDICI",
    "state_code": "AV",
    "cab": "951491",
    "cap": "83020"
  },
  {
    "key": "11723",
    "ITA": "QUINGENTOLE",
    "state_code": "MN",
    "cab": "578302",
    "cap": "46020"
  },
  {
    "key": "11724",
    "ITA": "QUINTANO",
    "state_code": "CR",
    "cab": "931816",
    "cap": "26017"
  },
  {
    "key": "11725",
    "ITA": "QUINTO DI TREVISO-SANTA CRISTINA",
    "state_code": "TV",
    "cab": "619700",
    "cap": "31055"
  },
  {
    "key": "11726",
    "ITA": "QUINTO DI TREVISO",
    "state_code": "TV",
    "cab": "619700",
    "cap": "31055"
  },
  {
    "key": "11727",
    "ITA": "QUINTO VERCELLESE",
    "state_code": "VC",
    "cab": "916189",
    "cap": "13030"
  },
  {
    "key": "11728",
    "ITA": "QUINTO VICENTINO",
    "state_code": "VI",
    "cab": "608703",
    "cap": "36050"
  },
  {
    "key": "11729",
    "ITA": "QUINTO VICENTINO-VILLAGGIO MONTEGRAPPA",
    "state_code": "VI",
    "cab": "608703",
    "cap": "36050"
  },
  {
    "key": "11730",
    "ITA": "QUINTO VICENTINO-LANZE'",
    "state_code": "VI",
    "cab": "608703",
    "cap": "36050"
  },
  {
    "key": "11731",
    "ITA": "QUINZANO D'OGLIO",
    "state_code": "BS",
    "cab": "550707",
    "cap": "25027"
  },
  {
    "key": "11732",
    "ITA": "QUISTELLO-NUVOLATO",
    "state_code": "MN",
    "cab": "578401",
    "cap": "46026"
  },
  {
    "key": "11733",
    "ITA": "QUISTELLO",
    "state_code": "MN",
    "cab": "578401",
    "cap": "46026"
  },
  {
    "key": "11734",
    "ITA": "QUITTENGO-BALMA",
    "state_code": "BI",
    "cab": "916197",
    "cap": "13812"
  },
  {
    "key": "11735",
    "ITA": "QUITTENGO",
    "state_code": "BI",
    "cab": "916197",
    "cap": "13812"
  },
  {
    "key": "11736",
    "ITA": "RABBI",
    "state_code": "TN",
    "cab": "352906",
    "cap": "38020"
  },
  {
    "key": "11737",
    "ITA": "RABBI-PRACORNO",
    "state_code": "TN",
    "cab": "352906",
    "cap": "38020"
  },
  {
    "key": "11738",
    "ITA": "RACALE",
    "state_code": "LE",
    "cab": "799106",
    "cap": "73055"
  },
  {
    "key": "11739",
    "ITA": "RACALMUTO",
    "state_code": "AG",
    "cab": "830505",
    "cap": "92020"
  },
  {
    "key": "11740",
    "ITA": "RACCONIGI",
    "state_code": "CN",
    "cab": "466904",
    "cap": "12035"
  },
  {
    "key": "11741",
    "ITA": "RACCUJA",
    "state_code": "ME",
    "cab": "824003",
    "cap": "98067"
  },
  {
    "key": "11742",
    "ITA": "RACCUJA-ZAPPA",
    "state_code": "ME",
    "cab": "824003",
    "cap": "98067"
  },
  {
    "key": "11743",
    "ITA": "RACCUJA-FONDACHELLO DI RACCUJA",
    "state_code": "ME",
    "cab": "824003",
    "cap": "98067"
  },
  {
    "key": "11744",
    "ITA": "RACINES-VALGIOVO",
    "state_code": "BZ",
    "cab": "591602",
    "cap": "39040"
  },
  {
    "key": "11745",
    "ITA": "RACINES",
    "state_code": "BZ",
    "cab": "591602",
    "cap": "39040"
  },
  {
    "key": "11746",
    "ITA": "RACINES-CASATEIA",
    "state_code": "BZ",
    "cab": "591602",
    "cap": "39040"
  },
  {
    "key": "11747",
    "ITA": "RACINES-MARETA",
    "state_code": "BZ",
    "cab": "591602",
    "cap": "39040"
  },
  {
    "key": "11748",
    "ITA": "RACINES-RIDANNA",
    "state_code": "BZ",
    "cab": "591602",
    "cap": "39040"
  },
  {
    "key": "11749",
    "ITA": "RACINES-STANGHE",
    "state_code": "BZ",
    "cab": "591602",
    "cap": "39040"
  },
  {
    "key": "11750",
    "ITA": "RADDA IN CHIANTI",
    "state_code": "SI",
    "cab": "719500",
    "cap": "53017"
  },
  {
    "key": "11751",
    "ITA": "RADDA IN CHIANTI-LUCARELLI",
    "state_code": "SI",
    "cab": "719500",
    "cap": "53017"
  },
  {
    "key": "11752",
    "ITA": "RADDUSA",
    "state_code": "CT",
    "cab": "841403",
    "cap": "95040"
  },
  {
    "key": "11753",
    "ITA": "RADICOFANI",
    "state_code": "SI",
    "cab": "719609",
    "cap": "53040"
  },
  {
    "key": "11754",
    "ITA": "RADICOFANI-CONTIGNANO",
    "state_code": "SI",
    "cab": "719609",
    "cap": "53040"
  },
  {
    "key": "11755",
    "ITA": "RADICONDOLI",
    "state_code": "SI",
    "cab": "719708",
    "cap": "53030"
  },
  {
    "key": "11756",
    "ITA": "RADICONDOLI-BELFORTE",
    "state_code": "SI",
    "cab": "719708",
    "cap": "53030"
  },
  {
    "key": "11757",
    "ITA": "RADICONDOLI-ANQUA",
    "state_code": "SI",
    "cab": "719708",
    "cap": "53030"
  },
  {
    "key": "11758",
    "ITA": "RAFFADALI",
    "state_code": "AG",
    "cab": "830604",
    "cap": "92015"
  },
  {
    "key": "11759",
    "ITA": "RAGALNA",
    "state_code": "CT",
    "cab": "889709",
    "cap": "95030"
  },
  {
    "key": "11760",
    "ITA": "RAGOGNA",
    "state_code": "UD",
    "cab": "644708",
    "cap": "33030"
  },
  {
    "key": "11761",
    "ITA": "RAGOGNA-SAN PIETRO",
    "state_code": "UD",
    "cab": "644708",
    "cap": "33030"
  },
  {
    "key": "11762",
    "ITA": "RAGOGNA-SAN GIACOMO",
    "state_code": "UD",
    "cab": "644708",
    "cap": "33030"
  },
  {
    "key": "11763",
    "ITA": "RAGOGNA-MURIS",
    "state_code": "UD",
    "cab": "644708",
    "cap": "33030"
  },
  {
    "key": "11764",
    "ITA": "RAGOLI",
    "state_code": "TN",
    "cab": "353003",
    "cap": "38070"
  },
  {
    "key": "11765",
    "ITA": "RAGUSA",
    "state_code": "RG",
    "cab": "170001",
    "cap": "97100"
  },
  {
    "key": "11766",
    "ITA": "RAGUSA-DONNAFUGATA",
    "state_code": "RG",
    "cab": "170001",
    "cap": "97017"
  },
  {
    "key": "11767",
    "ITA": "RAGUSA-BELLOCOZZO",
    "state_code": "RG",
    "cab": "170001",
    "cap": "97010"
  },
  {
    "key": "11768",
    "ITA": "RAGUSA-RAGUSA IBLA",
    "state_code": "RG",
    "cab": "170001",
    "cap": "97100"
  },
  {
    "key": "11769",
    "ITA": "RAGUSA-MARINA DI RAGUSA",
    "state_code": "RG",
    "cab": "170001",
    "cap": "97010"
  },
  {
    "key": "11770",
    "ITA": "RAIANO",
    "state_code": "AQ",
    "cab": "407205",
    "cap": "67027"
  },
  {
    "key": "11771",
    "ITA": "RAMACCA-LIBERTINIA",
    "state_code": "CT",
    "cab": "841502",
    "cap": "95040"
  },
  {
    "key": "11772",
    "ITA": "RAMACCA",
    "state_code": "CT",
    "cab": "841502",
    "cap": "95040"
  },
  {
    "key": "11773",
    "ITA": "RAMISETO",
    "state_code": "RE",
    "cab": "664300",
    "cap": "42030"
  },
  {
    "key": "11774",
    "ITA": "RAMISETO-SUCCISO NUOVO",
    "state_code": "RE",
    "cab": "664300",
    "cap": "42030"
  },
  {
    "key": "11775",
    "ITA": "RAMISETO-PIEVE SAN VINCENZO",
    "state_code": "RE",
    "cab": "664300",
    "cap": "42030"
  },
  {
    "key": "11776",
    "ITA": "RAMISETO-CASTAGNETO",
    "state_code": "RE",
    "cab": "664300",
    "cap": "42030"
  },
  {
    "key": "11777",
    "ITA": "RAMPONIO VERNA",
    "state_code": "CO",
    "cab": "926097",
    "cap": "22020"
  },
  {
    "key": "11778",
    "ITA": "RANCIO VALCUVIA",
    "state_code": "VA",
    "cab": "924696",
    "cap": "21030"
  },
  {
    "key": "11779",
    "ITA": "RANCO",
    "state_code": "VA",
    "cab": "924704",
    "cap": "21020"
  },
  {
    "key": "11780",
    "ITA": "RANDAZZO",
    "state_code": "CT",
    "cab": "841601",
    "cap": "95036"
  },
  {
    "key": "11781",
    "ITA": "RANDAZZO-CALDERARA",
    "state_code": "CT",
    "cab": "841601",
    "cap": "95036"
  },
  {
    "key": "11782",
    "ITA": "RANICA",
    "state_code": "BG",
    "cab": "534107",
    "cap": "24020"
  },
  {
    "key": "11783",
    "ITA": "RANZANICO",
    "state_code": "BG",
    "cab": "928523",
    "cap": "24060"
  },
  {
    "key": "11784",
    "ITA": "RANZO",
    "state_code": "IM",
    "cab": "922575",
    "cap": "18020"
  },
  {
    "key": "11785",
    "ITA": "RAPAGNANO-CONTRADA TENNA",
    "state_code": "FM",
    "cab": "696807",
    "cap": "63025"
  },
  {
    "key": "11786",
    "ITA": "RAPAGNANO",
    "state_code": "FM",
    "cab": "696807",
    "cap": "63025"
  },
  {
    "key": "11787",
    "ITA": "RAPALLO-SAN MICHELE DI PAGANA",
    "state_code": "GE",
    "cab": "321109",
    "cap": "16035"
  },
  {
    "key": "11788",
    "ITA": "RAPALLO-SAN MASSIMO",
    "state_code": "GE",
    "cab": "321109",
    "cap": "16035"
  },
  {
    "key": "11789",
    "ITA": "RAPALLO",
    "state_code": "GE",
    "cab": "321109",
    "cap": "16035"
  },
  {
    "key": "11790",
    "ITA": "RAPINO",
    "state_code": "CH",
    "cab": "778209",
    "cap": "66010"
  },
  {
    "key": "11791",
    "ITA": "RAPOLANO TERME",
    "state_code": "SI",
    "cab": "719807",
    "cap": "53040"
  },
  {
    "key": "11792",
    "ITA": "RAPOLANO TERME-SERRE DI RAPOLANO",
    "state_code": "SI",
    "cab": "719807",
    "cap": "53040"
  },
  {
    "key": "11793",
    "ITA": "RAPOLLA",
    "state_code": "PZ",
    "cab": "421909",
    "cap": "85027"
  },
  {
    "key": "11794",
    "ITA": "RAPONE",
    "state_code": "PZ",
    "cab": "912071",
    "cap": "85020"
  },
  {
    "key": "11795",
    "ITA": "RASSA",
    "state_code": "VC",
    "cab": "916205",
    "cap": "13020"
  },
  {
    "key": "11796",
    "ITA": "RASUN ANTERSELVA-RASUN DI SOPRA",
    "state_code": "BZ",
    "cab": "587303",
    "cap": "39030"
  },
  {
    "key": "11797",
    "ITA": "RASUN ANTERSELVA-ANTERSELVA DI MEZZO",
    "state_code": "BZ",
    "cab": "587303",
    "cap": "39030"
  },
  {
    "key": "11798",
    "ITA": "RASUN ANTERSELVA",
    "state_code": "BZ",
    "cab": "587303",
    "cap": "39030"
  },
  {
    "key": "11799",
    "ITA": "RASUN ANTERSELVA-RASUN DI SOTTO",
    "state_code": "BZ",
    "cab": "587303",
    "cap": "39030"
  },
  {
    "key": "11800",
    "ITA": "RASURA",
    "state_code": "SO",
    "cab": "927244",
    "cap": "23010"
  },
  {
    "key": "11801",
    "ITA": "RAVANUSA",
    "state_code": "AG",
    "cab": "830703",
    "cap": "92029"
  },
  {
    "key": "11802",
    "ITA": "RAVANUSA-CAMPOBELLO RAVANUSA STAZIONE",
    "state_code": "AG",
    "cab": "830703",
    "cap": "92029"
  },
  {
    "key": "11803",
    "ITA": "RAVARINO-STUFFIONE",
    "state_code": "MO",
    "cab": "669606",
    "cap": "41017"
  },
  {
    "key": "11804",
    "ITA": "RAVARINO",
    "state_code": "MO",
    "cab": "669606",
    "cap": "41017"
  },
  {
    "key": "11805",
    "ITA": "RAVASCLETTO",
    "state_code": "UD",
    "cab": "681403",
    "cap": "33020"
  },
  {
    "key": "11806",
    "ITA": "RAVELLO",
    "state_code": "SA",
    "cab": "763706",
    "cap": "84010"
  },
  {
    "key": "11807",
    "ITA": "RAVENNA-LIDO DI CLASSE",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11808",
    "ITA": "RAVENNA-LIDO DI SAVIO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11809",
    "ITA": "RAVENNA-MADONNA DELL'ALBERO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11810",
    "ITA": "RAVENNA-MARINA DI RAVENNA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11811",
    "ITA": "RAVENNA-MARINA ROMEA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11812",
    "ITA": "RAVENNA-MEZZANO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11813",
    "ITA": "RAVENNA-PIANGIPANE",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11814",
    "ITA": "RAVENNA-PORTO CORSINI",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11815",
    "ITA": "RAVENNA-PORTO FUORI",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11816",
    "ITA": "RAVENNA-PUNTA MARINA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11817",
    "ITA": "RAVENNA-ROMEA VECCHIA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11818",
    "ITA": "RAVENNA-RONCALCECI",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11819",
    "ITA": "RAVENNA-SAN BARTOLO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11820",
    "ITA": "RAVENNA-SAN PIETRO IN CAMPIANO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11821",
    "ITA": "RAVENNA-SAN PIETRO IN TRENTO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11822",
    "ITA": "RAVENNA-SAN PIETRO IN VINCOLI",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11823",
    "ITA": "RAVENNA-SAN ROMUALDO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11824",
    "ITA": "RAVENNA-SAN ZACCARIA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11825",
    "ITA": "RAVENNA-SANT'ALBERTO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48020"
  },
  {
    "key": "11826",
    "ITA": "RAVENNA-SANTERNO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11827",
    "ITA": "RAVENNA-SANTO STEFANO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11828",
    "ITA": "RAVENNA-SAVARNA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48020"
  },
  {
    "key": "11829",
    "ITA": "RAVENNA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11830",
    "ITA": "RAVENNA-AMMONITE",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11831",
    "ITA": "RAVENNA-CAMERLONA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11832",
    "ITA": "RAVENNA-CAMPIANO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11833",
    "ITA": "RAVENNA-CARRAIE",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11834",
    "ITA": "RAVENNA-CASAL BORSETTI",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48010"
  },
  {
    "key": "11835",
    "ITA": "RAVENNA-CASTIGLIONE DI RAVENNA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11836",
    "ITA": "RAVENNA-CLASSE",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11837",
    "ITA": "RAVENNA-COCCOLIA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11838",
    "ITA": "RAVENNA-DUCENTA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11839",
    "ITA": "RAVENNA-DURAZZANO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11840",
    "ITA": "RAVENNA-FILETTO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11841",
    "ITA": "RAVENNA-FORNACE ZARATTINI",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11842",
    "ITA": "RAVENNA-FOSSO GHIAIA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11843",
    "ITA": "RAVENNA-GAMBELLARA",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11844",
    "ITA": "RAVENNA-GHIBULLO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11845",
    "ITA": "RAVENNA-LIDO ADRIANO",
    "state_code": "RA",
    "cab": "131003",
    "cap": "48100"
  },
  {
    "key": "11846",
    "ITA": "RAVEO",
    "state_code": "UD",
    "cab": "937136",
    "cap": "33029"
  },
  {
    "key": "11847",
    "ITA": "RAVISCANINA",
    "state_code": "CE",
    "cab": "949529",
    "cap": "81017"
  },
  {
    "key": "11848",
    "ITA": "RAVISCANINA-QUATTROVENTI",
    "state_code": "CE",
    "cab": "949529",
    "cap": "81017"
  },
  {
    "key": "11849",
    "ITA": "RE",
    "state_code": "VB",
    "cab": "917658",
    "cap": "28856"
  },
  {
    "key": "11850",
    "ITA": "REA",
    "state_code": "PV",
    "cab": "930743",
    "cap": "27040"
  },
  {
    "key": "11851",
    "ITA": "REALMONTE",
    "state_code": "AG",
    "cab": "830802",
    "cap": "92010"
  },
  {
    "key": "11852",
    "ITA": "REANA DEL ROIALE",
    "state_code": "UD",
    "cab": "641506",
    "cap": "33010"
  },
  {
    "key": "11853",
    "ITA": "REANA DEL ROIALE-QUALSO",
    "state_code": "UD",
    "cab": "641506",
    "cap": "33010"
  },
  {
    "key": "11854",
    "ITA": "REANA DEL ROIALE-VERGNACCO",
    "state_code": "UD",
    "cab": "641506",
    "cap": "33010"
  },
  {
    "key": "11855",
    "ITA": "REANO",
    "state_code": "TO",
    "cab": "805606",
    "cap": "10090"
  },
  {
    "key": "11856",
    "ITA": "RECALE",
    "state_code": "CE",
    "cab": "752204",
    "cap": "81020"
  },
  {
    "key": "11857",
    "ITA": "RECANATI",
    "state_code": "MC",
    "cab": "691303",
    "cap": "62019"
  },
  {
    "key": "11858",
    "ITA": "RECANATI-MUSONE",
    "state_code": "MC",
    "cab": "691303",
    "cap": "62019"
  },
  {
    "key": "11859",
    "ITA": "RECCO",
    "state_code": "GE",
    "cab": "321208",
    "cap": "16036"
  },
  {
    "key": "11860",
    "ITA": "RECETTO",
    "state_code": "NO",
    "cab": "917666",
    "cap": "28060"
  },
  {
    "key": "11861",
    "ITA": "RECOARO TERME",
    "state_code": "VI",
    "cab": "606707",
    "cap": "36076"
  },
  {
    "key": "11862",
    "ITA": "RECOARO TERME-ROVEGLIANA",
    "state_code": "VI",
    "cab": "606707",
    "cap": "36076"
  },
  {
    "key": "11863",
    "ITA": "REDAVALLE",
    "state_code": "PV",
    "cab": "930750",
    "cap": "27050"
  },
  {
    "key": "11864",
    "ITA": "REDONDESCO",
    "state_code": "MN",
    "cab": "578500",
    "cap": "46010"
  },
  {
    "key": "11865",
    "ITA": "REFRANCORE",
    "state_code": "AT",
    "cab": "476200",
    "cap": "14030"
  },
  {
    "key": "11866",
    "ITA": "REFRONTOLO",
    "state_code": "TV",
    "cab": "622605",
    "cap": "31020"
  },
  {
    "key": "11867",
    "ITA": "REGALBUTO",
    "state_code": "EN",
    "cab": "837104",
    "cap": "94017"
  },
  {
    "key": "11868",
    "ITA": "REGGELLO",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11869",
    "ITA": "REGGELLO-BORGO A CASCIA",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11870",
    "ITA": "REGGELLO-CANCELLI",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11871",
    "ITA": "REGGELLO-DONNINI",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11872",
    "ITA": "REGGELLO-LECCIO",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11873",
    "ITA": "REGGELLO-MATASSINO",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11874",
    "ITA": "REGGELLO-PIETRAPIANA",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11875",
    "ITA": "REGGELLO-SALTINO",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11876",
    "ITA": "REGGELLO-SAN DONATO FRONZANO",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11877",
    "ITA": "REGGELLO-SANT'ELLERO",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50060"
  },
  {
    "key": "11878",
    "ITA": "REGGELLO-TOSI",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11879",
    "ITA": "REGGELLO-VAGGIO",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11880",
    "ITA": "REGGELLO-VALLOMBROSA",
    "state_code": "FI",
    "cab": "380204",
    "cap": "50066"
  },
  {
    "key": "11881",
    "ITA": "REGGIO DI CALABRIA-TRIZZINO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11882",
    "ITA": "REGGIO DI CALABRIA-TRABOCCHETTO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11883",
    "ITA": "REGGIO DI CALABRIA-VINCO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11884",
    "ITA": "REGGIO DI CALABRIA-VITO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89122"
  },
  {
    "key": "11885",
    "ITA": "REGGIO DI CALABRIA-VILLA SAN GIUSEPPE",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11886",
    "ITA": "REGGIO DI CALABRIA-TERRETI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11887",
    "ITA": "REGGIO DI CALABRIA-SCHINDILIFA'",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11888",
    "ITA": "REGGIO DI CALABRIA-SAN SPERATO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11889",
    "ITA": "REGGIO DI CALABRIA-SAN SALVATORE",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11890",
    "ITA": "REGGIO DI CALABRIA-SAN GREGORIO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89134"
  },
  {
    "key": "11891",
    "ITA": "REGGIO DI CALABRIA-SAMBATELLO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11892",
    "ITA": "REGGIO DI CALABRIA-SALICE CALABRO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11893",
    "ITA": "REGGIO DI CALABRIA-ROSARIO VALANIDI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89134"
  },
  {
    "key": "11894",
    "ITA": "REGGIO DI CALABRIA-ROSALI'",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11895",
    "ITA": "REGGIO DI CALABRIA-RAVAGNESE",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89131"
  },
  {
    "key": "11896",
    "ITA": "REGGIO DI CALABRIA-PUZZI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89131"
  },
  {
    "key": "11897",
    "ITA": "REGGIO DI CALABRIA-PODARGONI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11898",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89121"
  },
  {
    "key": "11899",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89122"
  },
  {
    "key": "11900",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89123"
  },
  {
    "key": "11901",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89124"
  },
  {
    "key": "11902",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89125"
  },
  {
    "key": "11903",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11904",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89127"
  },
  {
    "key": "11905",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89128"
  },
  {
    "key": "11906",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89129"
  },
  {
    "key": "11907",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89131"
  },
  {
    "key": "11908",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89132"
  },
  {
    "key": "11909",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11910",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89134"
  },
  {
    "key": "11911",
    "ITA": "REGGIO DI CALABRIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11912",
    "ITA": "REGGIO DI CALABRIA-ARASI'",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11913",
    "ITA": "REGGIO DI CALABRIA-ARCHI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89121"
  },
  {
    "key": "11914",
    "ITA": "REGGIO DI CALABRIA-ARMO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89131"
  },
  {
    "key": "11915",
    "ITA": "REGGIO DI CALABRIA-BOCALE",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89134"
  },
  {
    "key": "11916",
    "ITA": "REGGIO DI CALABRIA-CANNAVO'",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11917",
    "ITA": "REGGIO DI CALABRIA-CATAFORIO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11918",
    "ITA": "REGGIO DI CALABRIA-CATONA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11919",
    "ITA": "REGGIO DI CALABRIA-CERASI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11920",
    "ITA": "REGGIO DI CALABRIA-CROCE VALANIDI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89134"
  },
  {
    "key": "11921",
    "ITA": "REGGIO DI CALABRIA-DIMINNITI",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11922",
    "ITA": "REGGIO DI CALABRIA-GALLICO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89135"
  },
  {
    "key": "11923",
    "ITA": "REGGIO DI CALABRIA-GALLINA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89131"
  },
  {
    "key": "11924",
    "ITA": "REGGIO DI CALABRIA-IN VIA DALMAZIA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11925",
    "ITA": "REGGIO DI CALABRIA-MOSORROFA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11926",
    "ITA": "REGGIO DI CALABRIA-ORTI'",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11927",
    "ITA": "REGGIO DI CALABRIA-ORTI' INFERIORE",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11928",
    "ITA": "REGGIO DI CALABRIA-ORTI' SUPERIORE",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89126"
  },
  {
    "key": "11929",
    "ITA": "REGGIO DI CALABRIA-PAVIGLIANA",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89133"
  },
  {
    "key": "11930",
    "ITA": "REGGIO DI CALABRIA-PELLARO",
    "state_code": "RC",
    "cab": "163006",
    "cap": "89134"
  },
  {
    "key": "11931",
    "ITA": "REGGIO EMILIA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42121"
  },
  {
    "key": "11932",
    "ITA": "REGGIO EMILIA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42122"
  },
  {
    "key": "11933",
    "ITA": "REGGIO EMILIA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42123"
  },
  {
    "key": "11934",
    "ITA": "REGGIO EMILIA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42124"
  },
  {
    "key": "11935",
    "ITA": "REGGIO EMILIA-BAGNO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11936",
    "ITA": "REGGIO EMILIA-CADE'",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11937",
    "ITA": "REGGIO EMILIA-CANALI",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11938",
    "ITA": "REGGIO EMILIA-CASTELLAZZO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11939",
    "ITA": "REGGIO EMILIA-CAVAZZOLI",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11940",
    "ITA": "REGGIO EMILIA-MASONE",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11941",
    "ITA": "REGGIO EMILIA-MASSENZATICO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11942",
    "ITA": "REGGIO EMILIA-OSPIZIO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11943",
    "ITA": "REGGIO EMILIA-PIEVE MODOLENA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11944",
    "ITA": "REGGIO EMILIA-QUARESIMO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11945",
    "ITA": "REGGIO EMILIA-RIVALTA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11946",
    "ITA": "REGGIO EMILIA-RONCOCESI",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11947",
    "ITA": "REGGIO EMILIA-SAN MAURIZIO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11948",
    "ITA": "REGGIO EMILIA-SAN PELLEGRINO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11949",
    "ITA": "REGGIO EMILIA-SESSO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11950",
    "ITA": "REGGIO EMILIA-CELLA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11951",
    "ITA": "REGGIO EMILIA-CODEMONDO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11952",
    "ITA": "REGGIO EMILIA-CORTICELLA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11953",
    "ITA": "REGGIO EMILIA-COVIOLO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11954",
    "ITA": "REGGIO EMILIA-FOGLIANO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11955",
    "ITA": "REGGIO EMILIA-GAIDA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11956",
    "ITA": "REGGIO EMILIA-GAVASSA",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11957",
    "ITA": "REGGIO EMILIA-GAVASSETO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11958",
    "ITA": "REGGIO EMILIA-MANCASALE",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11959",
    "ITA": "REGGIO EMILIA-MARMIROLO",
    "state_code": "RE",
    "cab": "128009",
    "cap": "42100"
  },
  {
    "key": "11960",
    "ITA": "REGGIOLO",
    "state_code": "RE",
    "cab": "664409",
    "cap": "42046"
  },
  {
    "key": "11961",
    "ITA": "REGGIOLO-BRUGNETO",
    "state_code": "RE",
    "cab": "664409",
    "cap": "42046"
  },
  {
    "key": "11962",
    "ITA": "REINO",
    "state_code": "BN",
    "cab": "950469",
    "cap": "82020"
  },
  {
    "key": "11963",
    "ITA": "REITANO",
    "state_code": "ME",
    "cab": "827204",
    "cap": "98070"
  },
  {
    "key": "11964",
    "ITA": "REMANZACCO-ORZANO",
    "state_code": "UD",
    "cab": "641605",
    "cap": "33047"
  },
  {
    "key": "11965",
    "ITA": "REMANZACCO-ZIRACCO",
    "state_code": "UD",
    "cab": "641605",
    "cap": "33047"
  },
  {
    "key": "11966",
    "ITA": "REMANZACCO-CERNEGLONS",
    "state_code": "UD",
    "cab": "641605",
    "cap": "33047"
  },
  {
    "key": "11967",
    "ITA": "REMANZACCO",
    "state_code": "UD",
    "cab": "641605",
    "cap": "33047"
  },
  {
    "key": "11968",
    "ITA": "REMEDELLO",
    "state_code": "BS",
    "cab": "550806",
    "cap": "25010"
  },
  {
    "key": "11969",
    "ITA": "REMEDELLO-REMEDELLO DI SOTTO",
    "state_code": "BS",
    "cab": "550806",
    "cap": "25010"
  },
  {
    "key": "11970",
    "ITA": "REMEDELLO-REMEDELLO DI SOPRA",
    "state_code": "BS",
    "cab": "550806",
    "cap": "25010"
  },
  {
    "key": "11971",
    "ITA": "RENATE",
    "state_code": "MB",
    "cab": "336305",
    "cap": "20055"
  },
  {
    "key": "11972",
    "ITA": "RENDE",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11973",
    "ITA": "RENDE-CASTIGLIONE COSENTINO STAZIONE",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11974",
    "ITA": "RENDE-COMMENDA",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11975",
    "ITA": "RENDE-QUATTROMIGLIA",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11976",
    "ITA": "RENDE-ROGES",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11977",
    "ITA": "RENDE-ARCAVACATA",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11978",
    "ITA": "RENDE-SURDO",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11979",
    "ITA": "RENDE-SANTO STEFANO",
    "state_code": "CS",
    "cab": "808808",
    "cap": "87036"
  },
  {
    "key": "11980",
    "ITA": "RENON-AUNA INFERIORE",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39054"
  },
  {
    "key": "11981",
    "ITA": "RENON-AUNA DI SOPRA",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39054"
  },
  {
    "key": "11982",
    "ITA": "RENON",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39054"
  },
  {
    "key": "11983",
    "ITA": "RENON-CAMPODAZZO",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39040"
  },
  {
    "key": "11984",
    "ITA": "RENON-VANGA",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39054"
  },
  {
    "key": "11985",
    "ITA": "RENON-SOPRABOLZANO",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39054"
  },
  {
    "key": "11986",
    "ITA": "RENON-COLLALBO",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39054"
  },
  {
    "key": "11987",
    "ITA": "RENON-SANT'OTTILIA IN RENON",
    "state_code": "BZ",
    "cab": "587402",
    "cap": "39054"
  },
  {
    "key": "11988",
    "ITA": "RESANA-SAN MARCO",
    "state_code": "TV",
    "cab": "619809",
    "cap": "31023"
  },
  {
    "key": "11989",
    "ITA": "RESANA",
    "state_code": "TV",
    "cab": "619809",
    "cap": "31023"
  },
  {
    "key": "11990",
    "ITA": "RESANA-CASTELMINIO",
    "state_code": "TV",
    "cab": "619809",
    "cap": "31023"
  },
  {
    "key": "11991",
    "ITA": "RESCALDINA-RESCALDA",
    "state_code": "MI",
    "cab": "336404",
    "cap": "20027"
  },
  {
    "key": "11992",
    "ITA": "RESCALDINA",
    "state_code": "MI",
    "cab": "336404",
    "cap": "20027"
  },
  {
    "key": "11993",
    "ITA": "RESIA-STOLVIZZA",
    "state_code": "UD",
    "cab": "774604",
    "cap": "33010"
  },
  {
    "key": "11994",
    "ITA": "RESIA",
    "state_code": "UD",
    "cab": "774604",
    "cap": "33010"
  },
  {
    "key": "11995",
    "ITA": "RESIUTTA",
    "state_code": "UD",
    "cab": "937151",
    "cap": "33010"
  },
  {
    "key": "11996",
    "ITA": "RESUTTANO",
    "state_code": "CL",
    "cab": "834002",
    "cap": "93010"
  },
  {
    "key": "11997",
    "ITA": "RETORBIDO",
    "state_code": "PV",
    "cab": "496109",
    "cap": "27050"
  },
  {
    "key": "11998",
    "ITA": "REVELLO",
    "state_code": "CN",
    "cab": "467001",
    "cap": "12036"
  },
  {
    "key": "11999",
    "ITA": "REVELLO-STAFFARDA",
    "state_code": "CN",
    "cab": "467001",
    "cap": "12036"
  },
  {
    "key": "12000",
    "ITA": "REVERE",
    "state_code": "MN",
    "cab": "578609",
    "cap": "46036"
  },
  {
    "key": "12001",
    "ITA": "REVIGLIASCO D'ASTI",
    "state_code": "AT",
    "cab": "476309",
    "cap": "14010"
  },
  {
    "key": "12002",
    "ITA": "REVINE LAGO-REVINE",
    "state_code": "TV",
    "cab": "619908",
    "cap": "31020"
  },
  {
    "key": "12003",
    "ITA": "REVINE LAGO-LAGO",
    "state_code": "TV",
    "cab": "619908",
    "cap": "31020"
  },
  {
    "key": "12004",
    "ITA": "REVINE LAGO",
    "state_code": "TV",
    "cab": "619908",
    "cap": "31020"
  },
  {
    "key": "12005",
    "ITA": "REVO'-TREGIOVO",
    "state_code": "TN",
    "cab": "353102",
    "cap": "38028"
  },
  {
    "key": "12006",
    "ITA": "REVO'",
    "state_code": "TN",
    "cab": "353102",
    "cap": "38028"
  },
  {
    "key": "12007",
    "ITA": "REZZAGO",
    "state_code": "CO",
    "cab": "926105",
    "cap": "22030"
  },
  {
    "key": "12008",
    "ITA": "REZZATO-VIRLE TREPONTI",
    "state_code": "BS",
    "cab": "550905",
    "cap": "25086"
  },
  {
    "key": "12009",
    "ITA": "REZZATO",
    "state_code": "BS",
    "cab": "550905",
    "cap": "25086"
  },
  {
    "key": "12010",
    "ITA": "REZZO",
    "state_code": "IM",
    "cab": "922583",
    "cap": "18026"
  },
  {
    "key": "12011",
    "ITA": "REZZO-CENOVA",
    "state_code": "IM",
    "cab": "922583",
    "cap": "18026"
  },
  {
    "key": "12012",
    "ITA": "REZZOAGLIO-PARAZZUOLO",
    "state_code": "GE",
    "cab": "321307",
    "cap": "16048"
  },
  {
    "key": "12013",
    "ITA": "REZZOAGLIO-PRIOSA",
    "state_code": "GE",
    "cab": "321307",
    "cap": "16048"
  },
  {
    "key": "12014",
    "ITA": "REZZOAGLIO-MAGNASCO",
    "state_code": "GE",
    "cab": "321307",
    "cap": "16048"
  },
  {
    "key": "12015",
    "ITA": "REZZOAGLIO-CABANNE",
    "state_code": "GE",
    "cab": "321307",
    "cap": "16048"
  },
  {
    "key": "12016",
    "ITA": "REZZOAGLIO-ALPEPIANA",
    "state_code": "GE",
    "cab": "321307",
    "cap": "16048"
  },
  {
    "key": "12017",
    "ITA": "REZZOAGLIO",
    "state_code": "GE",
    "cab": "321307",
    "cap": "16048"
  },
  {
    "key": "12018",
    "ITA": "RHEMES NOTRE DAME",
    "state_code": "AO",
    "cab": "902569",
    "cap": "11010"
  },
  {
    "key": "12019",
    "ITA": "RHEMES SAINT GEORGES",
    "state_code": "AO",
    "cab": "902577",
    "cap": "11010"
  },
  {
    "key": "12020",
    "ITA": "RHO",
    "state_code": "MI",
    "cab": "205005",
    "cap": "20017"
  },
  {
    "key": "12021",
    "ITA": "RHO-TERRAZZANO",
    "state_code": "MI",
    "cab": "205005",
    "cap": "20017"
  },
  {
    "key": "12022",
    "ITA": "RHO-PASSIRANA",
    "state_code": "MI",
    "cab": "205005",
    "cap": "20017"
  },
  {
    "key": "12023",
    "ITA": "RHO-MAZZO MILANESE",
    "state_code": "MI",
    "cab": "205005",
    "cap": "20017"
  },
  {
    "key": "12024",
    "ITA": "RHO-LUCERNATE",
    "state_code": "MI",
    "cab": "205005",
    "cap": "20017"
  },
  {
    "key": "12025",
    "ITA": "RIACE",
    "state_code": "RC",
    "cab": "960286",
    "cap": "89040"
  },
  {
    "key": "12026",
    "ITA": "RIACE-RIACE MARINA",
    "state_code": "RC",
    "cab": "960286",
    "cap": "89040"
  },
  {
    "key": "12027",
    "ITA": "RIALTO",
    "state_code": "SV",
    "cab": "923276",
    "cap": "17020"
  },
  {
    "key": "12028",
    "ITA": "RIANO",
    "state_code": "RM",
    "cab": "393504",
    "cap": "60"
  },
  {
    "key": "12029",
    "ITA": "RIANO-BELVEDERE",
    "state_code": "RM",
    "cab": "393504",
    "cap": "60"
  },
  {
    "key": "12030",
    "ITA": "RIARDO",
    "state_code": "CE",
    "cab": "267492",
    "cap": "81053"
  },
  {
    "key": "12031",
    "ITA": "RIBERA",
    "state_code": "AG",
    "cab": "830901",
    "cap": "92016"
  },
  {
    "key": "12032",
    "ITA": "RIBERA-BORGO BONSIGNORE",
    "state_code": "AG",
    "cab": "830901",
    "cap": "92016"
  },
  {
    "key": "12033",
    "ITA": "RIBORDONE",
    "state_code": "TO",
    "cab": "284299",
    "cap": "10080"
  },
  {
    "key": "12034",
    "ITA": "RICADI",
    "state_code": "VV",
    "cab": "409607",
    "cap": "89866"
  },
  {
    "key": "12035",
    "ITA": "RICADI-SAN NICOLO'",
    "state_code": "VV",
    "cab": "409607",
    "cap": "89866"
  },
  {
    "key": "12036",
    "ITA": "RICADI-LAMPAZZONE",
    "state_code": "VV",
    "cab": "409607",
    "cap": "89866"
  },
  {
    "key": "12037",
    "ITA": "RICADI-BARBALACONI",
    "state_code": "VV",
    "cab": "409607",
    "cap": "89866"
  },
  {
    "key": "12038",
    "ITA": "RICADI-SANTA DOMENICA",
    "state_code": "VV",
    "cab": "409607",
    "cap": "89866"
  },
  {
    "key": "12039",
    "ITA": "RICALDONE",
    "state_code": "AL",
    "cab": "921593",
    "cap": "15010"
  },
  {
    "key": "12040",
    "ITA": "RICCIA",
    "state_code": "CB",
    "cab": "410803",
    "cap": "86016"
  },
  {
    "key": "12041",
    "ITA": "RICCIONE",
    "state_code": "RN",
    "cab": "241000",
    "cap": "47838"
  },
  {
    "key": "12042",
    "ITA": "RICCO' DEL GOLFO DI SPEZIA",
    "state_code": "SP",
    "cab": "371906",
    "cap": "19020"
  },
  {
    "key": "12043",
    "ITA": "RICCO' DEL GOLFO DI SPEZIA-SAN BENEDETTO",
    "state_code": "SP",
    "cab": "371906",
    "cap": "19020"
  },
  {
    "key": "12044",
    "ITA": "RICCO' DEL GOLFO DI SPEZIA-PONZO'",
    "state_code": "SP",
    "cab": "371906",
    "cap": "19020"
  },
  {
    "key": "12045",
    "ITA": "RICENGO",
    "state_code": "CR",
    "cab": "931824",
    "cap": "26010"
  },
  {
    "key": "12046",
    "ITA": "RICIGLIANO",
    "state_code": "SA",
    "cab": "952887",
    "cap": "84020"
  },
  {
    "key": "12047",
    "ITA": "RIESE PIO X-SPINEDA",
    "state_code": "TV",
    "cab": "620005",
    "cap": "31039"
  },
  {
    "key": "12048",
    "ITA": "RIESE PIO X-VALLA'",
    "state_code": "TV",
    "cab": "620005",
    "cap": "31030"
  },
  {
    "key": "12049",
    "ITA": "RIESE PIO X-POGGIANA",
    "state_code": "TV",
    "cab": "620005",
    "cap": "31039"
  },
  {
    "key": "12050",
    "ITA": "RIESE PIO X",
    "state_code": "TV",
    "cab": "620005",
    "cap": "31039"
  },
  {
    "key": "12051",
    "ITA": "RIESI",
    "state_code": "CL",
    "cab": "834101",
    "cap": "93016"
  },
  {
    "key": "12052",
    "ITA": "RIETI-POGGIO PERUGINO",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2100"
  },
  {
    "key": "12053",
    "ITA": "RIETI-POGGIO FIDONI",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2100"
  },
  {
    "key": "12054",
    "ITA": "RIETI-PIE' DI MOGGIO",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2010"
  },
  {
    "key": "12055",
    "ITA": "RIETI-MONTE TERMINILLO",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2100"
  },
  {
    "key": "12056",
    "ITA": "RIETI-CASETTE",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2100"
  },
  {
    "key": "12057",
    "ITA": "RIETI",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2100"
  },
  {
    "key": "12058",
    "ITA": "RIETI-SAN GIOVANNI REATINO",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2100"
  },
  {
    "key": "12059",
    "ITA": "RIETI-VAZIA",
    "state_code": "RI",
    "cab": "146001",
    "cap": "2100"
  },
  {
    "key": "12060",
    "ITA": "RIFIANO",
    "state_code": "BZ",
    "cab": "587501",
    "cap": "39010"
  },
  {
    "key": "12061",
    "ITA": "RIFREDDO-RIFREDDO DI SALUZZO",
    "state_code": "CN",
    "cab": "429803",
    "cap": "12030"
  },
  {
    "key": "12062",
    "ITA": "RIFREDDO",
    "state_code": "CN",
    "cab": "429803",
    "cap": "12030"
  },
  {
    "key": "12063",
    "ITA": "RIGNANO FLAMINIO",
    "state_code": "RM",
    "cab": "393603",
    "cap": "68"
  },
  {
    "key": "12064",
    "ITA": "RIGNANO GARGANICO",
    "state_code": "FG",
    "cab": "708305",
    "cap": "71010"
  },
  {
    "key": "12065",
    "ITA": "RIGNANO SULL'ARNO",
    "state_code": "FI",
    "cab": "380303",
    "cap": "50067"
  },
  {
    "key": "12066",
    "ITA": "RIGNANO SULL'ARNO-ROSANO",
    "state_code": "FI",
    "cab": "380303",
    "cap": "50067"
  },
  {
    "key": "12067",
    "ITA": "RIGNANO SULL'ARNO-SAN DONATO IN COLLINA",
    "state_code": "FI",
    "cab": "380303",
    "cap": "50067"
  },
  {
    "key": "12068",
    "ITA": "RIGNANO SULL'ARNO-TROGHI",
    "state_code": "FI",
    "cab": "380303",
    "cap": "50067"
  },
  {
    "key": "12069",
    "ITA": "RIGOLATO",
    "state_code": "UD",
    "cab": "793505",
    "cap": "33020"
  },
  {
    "key": "12070",
    "ITA": "RIMA SAN GIUSEPPE",
    "state_code": "VC",
    "cab": "916213",
    "cap": "13026"
  },
  {
    "key": "12071",
    "ITA": "RIMASCO",
    "state_code": "VC",
    "cab": "916221",
    "cap": "13026"
  },
  {
    "key": "12072",
    "ITA": "RIMELLA",
    "state_code": "VC",
    "cab": "916239",
    "cap": "13020"
  },
  {
    "key": "12073",
    "ITA": "RIMINI",
    "state_code": "RN",
    "cab": "242008",
    "cap": "47921"
  },
  {
    "key": "12074",
    "ITA": "RIMINI",
    "state_code": "RN",
    "cab": "242008",
    "cap": "47922"
  },
  {
    "key": "12075",
    "ITA": "RIMINI",
    "state_code": "RN",
    "cab": "242008",
    "cap": "47923"
  },
  {
    "key": "12076",
    "ITA": "RIMINI",
    "state_code": "RN",
    "cab": "242008",
    "cap": "47924"
  },
  {
    "key": "12077",
    "ITA": "RIO DI PUSTERIA",
    "state_code": "BZ",
    "cab": "587600",
    "cap": "39037"
  },
  {
    "key": "12078",
    "ITA": "RIO MARINA-CAVO",
    "state_code": "LI",
    "cab": "707505",
    "cap": "57038"
  },
  {
    "key": "12079",
    "ITA": "RIO MARINA",
    "state_code": "LI",
    "cab": "707505",
    "cap": "57038"
  },
  {
    "key": "12080",
    "ITA": "RIO NELL'ELBA",
    "state_code": "LI",
    "cab": "707604",
    "cap": "57039"
  },
  {
    "key": "12081",
    "ITA": "RIO SALICETO",
    "state_code": "RE",
    "cab": "664508",
    "cap": "42010"
  },
  {
    "key": "12082",
    "ITA": "RIOFREDDO",
    "state_code": "RM",
    "cab": "908285",
    "cap": "20"
  },
  {
    "key": "12083",
    "ITA": "RIOLA SARDO",
    "state_code": "OR",
    "cab": "879601",
    "cap": "9070"
  },
  {
    "key": "12084",
    "ITA": "RIOLO TERME-BORGO RIVOLA",
    "state_code": "RA",
    "cab": "675801",
    "cap": "48025"
  },
  {
    "key": "12085",
    "ITA": "RIOLO TERME",
    "state_code": "RA",
    "cab": "675801",
    "cap": "48025"
  },
  {
    "key": "12086",
    "ITA": "RIOLUNATO-CASTELLO",
    "state_code": "MO",
    "cab": "670901",
    "cap": "41020"
  },
  {
    "key": "12087",
    "ITA": "RIOLUNATO-GROPPO",
    "state_code": "MO",
    "cab": "670901",
    "cap": "41020"
  },
  {
    "key": "12088",
    "ITA": "RIOLUNATO-SERPIANO",
    "state_code": "MO",
    "cab": "670901",
    "cap": "41020"
  },
  {
    "key": "12089",
    "ITA": "RIOLUNATO",
    "state_code": "MO",
    "cab": "670901",
    "cap": "41020"
  },
  {
    "key": "12090",
    "ITA": "RIOLUNATO-CASTELLINO",
    "state_code": "MO",
    "cab": "670901",
    "cap": "41020"
  },
  {
    "key": "12091",
    "ITA": "RIOMAGGIORE-MANAROLA",
    "state_code": "SP",
    "cab": "498204",
    "cap": "19017"
  },
  {
    "key": "12092",
    "ITA": "RIOMAGGIORE",
    "state_code": "SP",
    "cab": "498204",
    "cap": "19017"
  },
  {
    "key": "12093",
    "ITA": "RIONERO IN VULTURE",
    "state_code": "PZ",
    "cab": "422006",
    "cap": "85028"
  },
  {
    "key": "12094",
    "ITA": "RIONERO IN VULTURE-MONTICCHIO BAGNI",
    "state_code": "PZ",
    "cab": "422006",
    "cap": "85028"
  },
  {
    "key": "12095",
    "ITA": "RIONERO IN VULTURE-MONTICCHIO",
    "state_code": "PZ",
    "cab": "422006",
    "cap": "85028"
  },
  {
    "key": "12096",
    "ITA": "RIONERO SANNITICO",
    "state_code": "IS",
    "cab": "955815",
    "cap": "86087"
  },
  {
    "key": "12097",
    "ITA": "RIPA TEATINA",
    "state_code": "CH",
    "cab": "779504",
    "cap": "66010"
  },
  {
    "key": "12098",
    "ITA": "RIPABOTTONI",
    "state_code": "CB",
    "cab": "910844",
    "cap": "86040"
  },
  {
    "key": "12099",
    "ITA": "RIPABOTTONI-RIPABOTTONI STAZIONE",
    "state_code": "CB",
    "cab": "910844",
    "cap": "86040"
  },
  {
    "key": "12100",
    "ITA": "RIPACANDIDA",
    "state_code": "PZ",
    "cab": "422105",
    "cap": "85020"
  },
  {
    "key": "12101",
    "ITA": "RIPALIMOSANI",
    "state_code": "CB",
    "cab": "396200",
    "cap": "86025"
  },
  {
    "key": "12102",
    "ITA": "RIPALTA ARPINA",
    "state_code": "CR",
    "cab": "573709",
    "cap": "26010"
  },
  {
    "key": "12103",
    "ITA": "RIPALTA CREMASCA-ZAPPELLO",
    "state_code": "CR",
    "cab": "570606",
    "cap": "26010"
  },
  {
    "key": "12104",
    "ITA": "RIPALTA CREMASCA-RIPALTA NUOVA",
    "state_code": "CR",
    "cab": "570606",
    "cap": "26010"
  },
  {
    "key": "12105",
    "ITA": "RIPALTA CREMASCA-BOLZONE",
    "state_code": "CR",
    "cab": "570606",
    "cap": "26010"
  },
  {
    "key": "12106",
    "ITA": "RIPALTA CREMASCA",
    "state_code": "CR",
    "cab": "570606",
    "cap": "26010"
  },
  {
    "key": "12107",
    "ITA": "RIPALTA GUERINA",
    "state_code": "CR",
    "cab": "931840",
    "cap": "26010"
  },
  {
    "key": "12108",
    "ITA": "RIPARBELLA",
    "state_code": "PI",
    "cab": "711408",
    "cap": "56046"
  },
  {
    "key": "12109",
    "ITA": "RIPATRANSONE-SAN SAVINO",
    "state_code": "AP",
    "cab": "696906",
    "cap": "63038"
  },
  {
    "key": "12110",
    "ITA": "RIPATRANSONE",
    "state_code": "AP",
    "cab": "696906",
    "cap": "63038"
  },
  {
    "key": "12111",
    "ITA": "RIPE",
    "state_code": "AN",
    "cab": "375402",
    "cap": "60010"
  },
  {
    "key": "12112",
    "ITA": "RIPE-BRUGNETTO",
    "state_code": "AN",
    "cab": "375402",
    "cap": "60010"
  },
  {
    "key": "12113",
    "ITA": "RIPE-PASSO DI RIPE",
    "state_code": "AN",
    "cab": "375402",
    "cap": "60010"
  },
  {
    "key": "12114",
    "ITA": "RIPE SAN GINESIO",
    "state_code": "MC",
    "cab": "681809",
    "cap": "62020"
  },
  {
    "key": "12115",
    "ITA": "RIPI",
    "state_code": "FR",
    "cab": "745505",
    "cap": "3027"
  },
  {
    "key": "12116",
    "ITA": "RIPOSTO-TORRE ARCHIRAFI",
    "state_code": "CT",
    "cab": "841700",
    "cap": "95018"
  },
  {
    "key": "12117",
    "ITA": "RIPOSTO",
    "state_code": "CT",
    "cab": "841700",
    "cap": "95018"
  },
  {
    "key": "12118",
    "ITA": "RITTANA",
    "state_code": "CN",
    "cab": "919225",
    "cap": "12010"
  },
  {
    "key": "12119",
    "ITA": "RIVA DEL GARDA-PREGASINA",
    "state_code": "TN",
    "cab": "353201",
    "cap": "38060"
  },
  {
    "key": "12120",
    "ITA": "RIVA DEL GARDA-VARONE",
    "state_code": "TN",
    "cab": "353201",
    "cap": "38066"
  },
  {
    "key": "12121",
    "ITA": "RIVA DEL GARDA",
    "state_code": "TN",
    "cab": "353201",
    "cap": "38066"
  },
  {
    "key": "12122",
    "ITA": "RIVA DI SOLTO",
    "state_code": "BG",
    "cab": "264960",
    "cap": "24060"
  },
  {
    "key": "12123",
    "ITA": "RIVA LIGURE",
    "state_code": "IM",
    "cab": "490508",
    "cap": "18015"
  },
  {
    "key": "12124",
    "ITA": "RIVA PRESSO CHIERI",
    "state_code": "TO",
    "cab": "308403",
    "cap": "10020"
  },
  {
    "key": "12125",
    "ITA": "RIVA VALDOBBIA-CA' DI IANZO",
    "state_code": "VC",
    "cab": "916247",
    "cap": "13020"
  },
  {
    "key": "12126",
    "ITA": "RIVA VALDOBBIA",
    "state_code": "VC",
    "cab": "916247",
    "cap": "13020"
  },
  {
    "key": "12127",
    "ITA": "RIVALBA",
    "state_code": "TO",
    "cab": "284323",
    "cap": "10090"
  },
  {
    "key": "12128",
    "ITA": "RIVALTA BORMIDA",
    "state_code": "AL",
    "cab": "485508",
    "cap": "15010"
  },
  {
    "key": "12129",
    "ITA": "RIVALTA DI TORINO",
    "state_code": "TO",
    "cab": "308304",
    "cap": "10040"
  },
  {
    "key": "12130",
    "ITA": "RIVALTA DI TORINO-GERBOLE",
    "state_code": "TO",
    "cab": "308304",
    "cap": "10040"
  },
  {
    "key": "12131",
    "ITA": "RIVAMONTE AGORDINO",
    "state_code": "BL",
    "cab": "742502",
    "cap": "32020"
  },
  {
    "key": "12132",
    "ITA": "RIVANAZZANO",
    "state_code": "PV",
    "cab": "561407",
    "cap": "27055"
  },
  {
    "key": "12133",
    "ITA": "RIVARA",
    "state_code": "TO",
    "cab": "308502",
    "cap": "10080"
  },
  {
    "key": "12134",
    "ITA": "RIVAROLO CANAVESE",
    "state_code": "TO",
    "cab": "308601",
    "cap": "10086"
  },
  {
    "key": "12135",
    "ITA": "RIVAROLO CANAVESE-ARGENTERA",
    "state_code": "TO",
    "cab": "308601",
    "cap": "10086"
  },
  {
    "key": "12136",
    "ITA": "RIVAROLO DEL RE ED UNITI",
    "state_code": "CR",
    "cab": "570705",
    "cap": "26036"
  },
  {
    "key": "12137",
    "ITA": "RIVAROLO DEL RE ED UNITI-RIVAROLO DEL RE",
    "state_code": "CR",
    "cab": "570705",
    "cap": "26036"
  },
  {
    "key": "12138",
    "ITA": "RIVAROLO MANTOVANO-CIVIDALE MANTOVANO",
    "state_code": "MN",
    "cab": "578708",
    "cap": "46017"
  },
  {
    "key": "12139",
    "ITA": "RIVAROLO MANTOVANO",
    "state_code": "MN",
    "cab": "578708",
    "cap": "46017"
  },
  {
    "key": "12140",
    "ITA": "RIVARONE",
    "state_code": "AL",
    "cab": "921601",
    "cap": "15040"
  },
  {
    "key": "12141",
    "ITA": "RIVAROSSA",
    "state_code": "TO",
    "cab": "499004",
    "cap": "10040"
  },
  {
    "key": "12142",
    "ITA": "RIVE",
    "state_code": "VC",
    "cab": "916254",
    "cap": "13030"
  },
  {
    "key": "12143",
    "ITA": "RIVE D'ARCANO-RODEANO",
    "state_code": "UD",
    "cab": "884106",
    "cap": "33030"
  },
  {
    "key": "12144",
    "ITA": "RIVE D'ARCANO",
    "state_code": "UD",
    "cab": "884106",
    "cap": "33030"
  },
  {
    "key": "12145",
    "ITA": "RIVELLO-SAN COSTANTINO",
    "state_code": "PZ",
    "cab": "424200",
    "cap": "85040"
  },
  {
    "key": "12146",
    "ITA": "RIVELLO",
    "state_code": "PZ",
    "cab": "424200",
    "cap": "85040"
  },
  {
    "key": "12147",
    "ITA": "RIVERGARO-ROVELETO LANDI",
    "state_code": "PC",
    "cab": "654400",
    "cap": "29029"
  },
  {
    "key": "12148",
    "ITA": "RIVERGARO",
    "state_code": "PC",
    "cab": "654400",
    "cap": "29029"
  },
  {
    "key": "12149",
    "ITA": "RIVERGARO-ANCARANO DI SOPRA",
    "state_code": "PC",
    "cab": "654400",
    "cap": "29029"
  },
  {
    "key": "12150",
    "ITA": "RIVERGARO-NIVIANO",
    "state_code": "PC",
    "cab": "654400",
    "cap": "29029"
  },
  {
    "key": "12151",
    "ITA": "RIVIGNANO",
    "state_code": "UD",
    "cab": "641704",
    "cap": "33061"
  },
  {
    "key": "12152",
    "ITA": "RIVIGNANO E TEOR",
    "state_code": "UD",
    "cab": "54338",
    "cap": "33058"
  },
  {
    "key": "12153",
    "ITA": "RIVISONDOLI",
    "state_code": "AQ",
    "cab": "407304",
    "cap": "67036"
  },
  {
    "key": "12154",
    "ITA": "RIVODUTRI-PIEDICOLLE",
    "state_code": "RI",
    "cab": "737601",
    "cap": "2010"
  },
  {
    "key": "12155",
    "ITA": "RIVODUTRI",
    "state_code": "RI",
    "cab": "737601",
    "cap": "2010"
  },
  {
    "key": "12156",
    "ITA": "RIVOLI-TETTI NEIROTTI",
    "state_code": "TO",
    "cab": "308700",
    "cap": "10098"
  },
  {
    "key": "12157",
    "ITA": "RIVOLI-CASCINE VICA",
    "state_code": "TO",
    "cab": "308700",
    "cap": "10098"
  },
  {
    "key": "12158",
    "ITA": "RIVOLI-BRUERE",
    "state_code": "TO",
    "cab": "308700",
    "cap": "10098"
  },
  {
    "key": "12159",
    "ITA": "RIVOLI",
    "state_code": "TO",
    "cab": "308700",
    "cap": "10098"
  },
  {
    "key": "12160",
    "ITA": "RIVOLI VERONESE",
    "state_code": "VR",
    "cab": "600403",
    "cap": "37010"
  },
  {
    "key": "12161",
    "ITA": "RIVOLTA D'ADDA",
    "state_code": "CR",
    "cab": "570804",
    "cap": "26027"
  },
  {
    "key": "12162",
    "ITA": "RIZZICONI",
    "state_code": "RC",
    "cab": "815100",
    "cap": "89016"
  },
  {
    "key": "12163",
    "ITA": "RIZZICONI-CIRELLO",
    "state_code": "RC",
    "cab": "815100",
    "cap": "89016"
  },
  {
    "key": "12164",
    "ITA": "RIZZICONI-DROSI",
    "state_code": "RC",
    "cab": "815100",
    "cap": "89016"
  },
  {
    "key": "12165",
    "ITA": "RIZZICONI-SPINA",
    "state_code": "RC",
    "cab": "815100",
    "cap": "89016"
  },
  {
    "key": "12166",
    "ITA": "RO",
    "state_code": "FE",
    "cab": "673301",
    "cap": "44030"
  },
  {
    "key": "12167",
    "ITA": "RO-RUINA",
    "state_code": "FE",
    "cab": "673301",
    "cap": "44030"
  },
  {
    "key": "12168",
    "ITA": "RO-GUARDA",
    "state_code": "FE",
    "cab": "673301",
    "cap": "44030"
  },
  {
    "key": "12169",
    "ITA": "RO-ALBERONE DI RO",
    "state_code": "FE",
    "cab": "673301",
    "cap": "44030"
  },
  {
    "key": "12170",
    "ITA": "ROANA-MEZZASELVA DI ROANA",
    "state_code": "VI",
    "cab": "606806",
    "cap": "36010"
  },
  {
    "key": "12171",
    "ITA": "ROANA-CESUNA",
    "state_code": "VI",
    "cab": "606806",
    "cap": "36010"
  },
  {
    "key": "12172",
    "ITA": "ROANA-CANOVE DI ROANA",
    "state_code": "VI",
    "cab": "606806",
    "cap": "36010"
  },
  {
    "key": "12173",
    "ITA": "ROANA-CAMPOROVERE",
    "state_code": "VI",
    "cab": "606806",
    "cap": "36010"
  },
  {
    "key": "12174",
    "ITA": "ROANA",
    "state_code": "VI",
    "cab": "606806",
    "cap": "36010"
  },
  {
    "key": "12175",
    "ITA": "ROANA-TRESCHE' CONCA",
    "state_code": "VI",
    "cab": "606806",
    "cap": "36010"
  },
  {
    "key": "12176",
    "ITA": "ROASCHIA",
    "state_code": "CN",
    "cab": "919233",
    "cap": "12010"
  },
  {
    "key": "12177",
    "ITA": "ROASCIO",
    "state_code": "CN",
    "cab": "919241",
    "cap": "12073"
  },
  {
    "key": "12178",
    "ITA": "ROASIO-SANT'EUSEBIO",
    "state_code": "VC",
    "cab": "447102",
    "cap": "13060"
  },
  {
    "key": "12179",
    "ITA": "ROASIO-SAN MAURIZIO",
    "state_code": "VC",
    "cab": "447102",
    "cap": "13060"
  },
  {
    "key": "12180",
    "ITA": "ROASIO",
    "state_code": "VC",
    "cab": "447102",
    "cap": "13060"
  },
  {
    "key": "12181",
    "ITA": "ROATTO",
    "state_code": "AT",
    "cab": "920371",
    "cap": "14018"
  },
  {
    "key": "12182",
    "ITA": "ROBASSOMERO",
    "state_code": "TO",
    "cab": "314203",
    "cap": "10070"
  },
  {
    "key": "12183",
    "ITA": "ROBBIATE",
    "state_code": "LC",
    "cab": "519900",
    "cap": "23899"
  },
  {
    "key": "12184",
    "ITA": "ROBBIO",
    "state_code": "PV",
    "cab": "561506",
    "cap": "27038"
  },
  {
    "key": "12185",
    "ITA": "ROBECCHETTO CON INDUNO",
    "state_code": "MI",
    "cab": "336503",
    "cap": "20020"
  },
  {
    "key": "12186",
    "ITA": "ROBECCO D'OGLIO",
    "state_code": "CR",
    "cab": "570903",
    "cap": "26010"
  },
  {
    "key": "12187",
    "ITA": "ROBECCO PAVESE",
    "state_code": "PV",
    "cab": "930776",
    "cap": "27042"
  },
  {
    "key": "12188",
    "ITA": "ROBECCO SUL NAVIGLIO-CASTERNO",
    "state_code": "MI",
    "cab": "336602",
    "cap": "20087"
  },
  {
    "key": "12189",
    "ITA": "ROBECCO SUL NAVIGLIO-CASTELLAZZO DE' BARZI",
    "state_code": "MI",
    "cab": "336602",
    "cap": "20087"
  },
  {
    "key": "12190",
    "ITA": "ROBECCO SUL NAVIGLIO",
    "state_code": "MI",
    "cab": "336602",
    "cap": "20087"
  },
  {
    "key": "12191",
    "ITA": "ROBELLA",
    "state_code": "AT",
    "cab": "476408",
    "cap": "14020"
  },
  {
    "key": "12192",
    "ITA": "ROBILANTE",
    "state_code": "CN",
    "cab": "467100",
    "cap": "12017"
  },
  {
    "key": "12193",
    "ITA": "ROBURENT",
    "state_code": "CN",
    "cab": "467209",
    "cap": "12080"
  },
  {
    "key": "12194",
    "ITA": "ROBURENT-PRA'",
    "state_code": "CN",
    "cab": "467209",
    "cap": "12080"
  },
  {
    "key": "12195",
    "ITA": "ROCCA CANAVESE",
    "state_code": "TO",
    "cab": "430207",
    "cap": "10070"
  },
  {
    "key": "12196",
    "ITA": "ROCCA CANTERANO",
    "state_code": "RM",
    "cab": "908293",
    "cap": "20"
  },
  {
    "key": "12197",
    "ITA": "ROCCA CIGLIE'",
    "state_code": "CN",
    "cab": "919266",
    "cap": "12060"
  },
  {
    "key": "12198",
    "ITA": "ROCCA D'ARAZZO",
    "state_code": "AT",
    "cab": "476507",
    "cap": "14030"
  },
  {
    "key": "12199",
    "ITA": "ROCCA D'ARCE",
    "state_code": "FR",
    "cab": "948471",
    "cap": "3030"
  },
  {
    "key": "12200",
    "ITA": "ROCCA D'EVANDRO-COCURUZZO",
    "state_code": "CE",
    "cab": "749804",
    "cap": "81040"
  },
  {
    "key": "12201",
    "ITA": "ROCCA D'EVANDRO-CAMINO",
    "state_code": "CE",
    "cab": "749804",
    "cap": "81040"
  },
  {
    "key": "12202",
    "ITA": "ROCCA D'EVANDRO-BIVIO MORTOLA",
    "state_code": "CE",
    "cab": "749804",
    "cap": "81040"
  },
  {
    "key": "12203",
    "ITA": "ROCCA D'EVANDRO",
    "state_code": "CE",
    "cab": "749804",
    "cap": "81040"
  },
  {
    "key": "12204",
    "ITA": "ROCCA DE' BALDI-CRAVA",
    "state_code": "CN",
    "cab": "467308",
    "cap": "12047"
  },
  {
    "key": "12205",
    "ITA": "ROCCA DE' BALDI",
    "state_code": "CN",
    "cab": "467308",
    "cap": "12047"
  },
  {
    "key": "12206",
    "ITA": "ROCCA DE' GIORGI",
    "state_code": "PV",
    "cab": "930784",
    "cap": "27040"
  },
  {
    "key": "12207",
    "ITA": "ROCCA DI BOTTE",
    "state_code": "AQ",
    "cab": "294074",
    "cap": "67066"
  },
  {
    "key": "12208",
    "ITA": "ROCCA DI CAMBIO",
    "state_code": "AQ",
    "cab": "909887",
    "cap": "67047"
  },
  {
    "key": "12209",
    "ITA": "ROCCA DI CAVE",
    "state_code": "RM",
    "cab": "908301",
    "cap": "30"
  },
  {
    "key": "12210",
    "ITA": "ROCCA DI MEZZO",
    "state_code": "AQ",
    "cab": "407403",
    "cap": "67048"
  },
  {
    "key": "12211",
    "ITA": "ROCCA DI MEZZO-ROVERE",
    "state_code": "AQ",
    "cab": "407403",
    "cap": "67048"
  },
  {
    "key": "12212",
    "ITA": "ROCCA DI NETO-CORAZZO",
    "state_code": "KR",
    "cab": "427005",
    "cap": "88821"
  },
  {
    "key": "12213",
    "ITA": "ROCCA DI NETO",
    "state_code": "KR",
    "cab": "427005",
    "cap": "88821"
  },
  {
    "key": "12214",
    "ITA": "ROCCA DI PAPA",
    "state_code": "RM",
    "cab": "393702",
    "cap": "40"
  },
  {
    "key": "12215",
    "ITA": "ROCCA GRIMALDA",
    "state_code": "AL",
    "cab": "921627",
    "cap": "15078"
  },
  {
    "key": "12216",
    "ITA": "ROCCA IMPERIALE-ROCCA IMPERIALE MARINA",
    "state_code": "CS",
    "cab": "808907",
    "cap": "87074"
  },
  {
    "key": "12217",
    "ITA": "ROCCA IMPERIALE",
    "state_code": "CS",
    "cab": "808907",
    "cap": "87074"
  },
  {
    "key": "12218",
    "ITA": "ROCCA MASSIMA",
    "state_code": "LT",
    "cab": "947812",
    "cap": "4010"
  },
  {
    "key": "12219",
    "ITA": "ROCCA PIA",
    "state_code": "AQ",
    "cab": "909895",
    "cap": "67030"
  },
  {
    "key": "12220",
    "ITA": "ROCCA PIETORE-LASTE DI ROCCA PIETORE",
    "state_code": "BL",
    "cab": "613604",
    "cap": "32020"
  },
  {
    "key": "12221",
    "ITA": "ROCCA PIETORE-SANTA MARIA DELLE GRAZIE",
    "state_code": "BL",
    "cab": "613604",
    "cap": "32020"
  },
  {
    "key": "12222",
    "ITA": "ROCCA PIETORE",
    "state_code": "BL",
    "cab": "613604",
    "cap": "32020"
  },
  {
    "key": "12223",
    "ITA": "ROCCA PRIORA",
    "state_code": "RM",
    "cab": "393801",
    "cap": "40"
  },
  {
    "key": "12224",
    "ITA": "ROCCA PRIORA-COLLE DI FUORI",
    "state_code": "RM",
    "cab": "393801",
    "cap": "40"
  },
  {
    "key": "12225",
    "ITA": "ROCCA SAN CASCIANO-CALBOLA",
    "state_code": "FC",
    "cab": "679704",
    "cap": "47017"
  },
  {
    "key": "12226",
    "ITA": "ROCCA SAN CASCIANO",
    "state_code": "FC",
    "cab": "679704",
    "cap": "47017"
  },
  {
    "key": "12227",
    "ITA": "ROCCA SAN FELICE",
    "state_code": "AV",
    "cab": "951517",
    "cap": "83050"
  },
  {
    "key": "12228",
    "ITA": "ROCCA SAN GIOVANNI",
    "state_code": "CH",
    "cab": "388207",
    "cap": "66020"
  },
  {
    "key": "12229",
    "ITA": "ROCCA SANTA MARIA",
    "state_code": "TE",
    "cab": "770107",
    "cap": "64010"
  },
  {
    "key": "12230",
    "ITA": "ROCCA SANTO STEFANO",
    "state_code": "RM",
    "cab": "908327",
    "cap": "30"
  },
  {
    "key": "12231",
    "ITA": "ROCCA SINIBALDA",
    "state_code": "RI",
    "cab": "737700",
    "cap": "2026"
  },
  {
    "key": "12232",
    "ITA": "ROCCA SINIBALDA-POSTICCIOLA",
    "state_code": "RI",
    "cab": "737700",
    "cap": "2026"
  },
  {
    "key": "12233",
    "ITA": "ROCCA SUSELLA",
    "state_code": "PV",
    "cab": "930792",
    "cap": "27052"
  },
  {
    "key": "12234",
    "ITA": "ROCCABASCERANA-CASSANO CAUDINO",
    "state_code": "AV",
    "cab": "951509",
    "cap": "83016"
  },
  {
    "key": "12235",
    "ITA": "ROCCABASCERANA-SQUILLANI",
    "state_code": "AV",
    "cab": "951509",
    "cap": "83016"
  },
  {
    "key": "12236",
    "ITA": "ROCCABASCERANA",
    "state_code": "AV",
    "cab": "951509",
    "cap": "83016"
  },
  {
    "key": "12237",
    "ITA": "ROCCABASCERANA-TUFARA VALLE",
    "state_code": "AV",
    "cab": "951509",
    "cap": "83016"
  },
  {
    "key": "12238",
    "ITA": "ROCCABERNARDA",
    "state_code": "KR",
    "cab": "712901",
    "cap": "88835"
  },
  {
    "key": "12239",
    "ITA": "ROCCABIANCA",
    "state_code": "PR",
    "cab": "658807",
    "cap": "43010"
  },
  {
    "key": "12240",
    "ITA": "ROCCABIANCA-RAGAZZOLA",
    "state_code": "PR",
    "cab": "658807",
    "cap": "43010"
  },
  {
    "key": "12241",
    "ITA": "ROCCABIANCA-FONTANELLE",
    "state_code": "PR",
    "cab": "658807",
    "cap": "43010"
  },
  {
    "key": "12242",
    "ITA": "ROCCABRUNA",
    "state_code": "CN",
    "cab": "919258",
    "cap": "12020"
  },
  {
    "key": "12243",
    "ITA": "ROCCACASALE",
    "state_code": "AQ",
    "cab": "909861",
    "cap": "67030"
  },
  {
    "key": "12244",
    "ITA": "ROCCADASPIDE",
    "state_code": "SA",
    "cab": "763805",
    "cap": "84069"
  },
  {
    "key": "12245",
    "ITA": "ROCCADASPIDE-ACQUAVIVA",
    "state_code": "SA",
    "cab": "763805",
    "cap": "84069"
  },
  {
    "key": "12246",
    "ITA": "ROCCADASPIDE-CARRATIELLO",
    "state_code": "SA",
    "cab": "763805",
    "cap": "84069"
  },
  {
    "key": "12247",
    "ITA": "ROCCADASPIDE-FONTE",
    "state_code": "SA",
    "cab": "763805",
    "cap": "84069"
  },
  {
    "key": "12248",
    "ITA": "ROCCADASPIDE-SERRA",
    "state_code": "SA",
    "cab": "763805",
    "cap": "84069"
  },
  {
    "key": "12249",
    "ITA": "ROCCAFIORITA",
    "state_code": "ME",
    "cab": "961540",
    "cap": "98030"
  },
  {
    "key": "12250",
    "ITA": "ROCCAFLUVIONE",
    "state_code": "AP",
    "cab": "697003",
    "cap": "63049"
  },
  {
    "key": "12251",
    "ITA": "ROCCAFLUVIONE-AGELLI",
    "state_code": "AP",
    "cab": "697003",
    "cap": "63049"
  },
  {
    "key": "12252",
    "ITA": "ROCCAFLUVIONE-MARSIA",
    "state_code": "AP",
    "cab": "697003",
    "cap": "63049"
  },
  {
    "key": "12253",
    "ITA": "ROCCAFORTE DEL GRECO-GHORIO",
    "state_code": "RC",
    "cab": "960294",
    "cap": "89060"
  },
  {
    "key": "12254",
    "ITA": "ROCCAFORTE DEL GRECO",
    "state_code": "RC",
    "cab": "960294",
    "cap": "89060"
  },
  {
    "key": "12255",
    "ITA": "ROCCAFORTE LIGURE",
    "state_code": "AL",
    "cab": "921619",
    "cap": "15060"
  },
  {
    "key": "12256",
    "ITA": "ROCCAFORTE MONDOVI'-LURISIA",
    "state_code": "CN",
    "cab": "467407",
    "cap": "12088"
  },
  {
    "key": "12257",
    "ITA": "ROCCAFORTE MONDOVI'-PREA",
    "state_code": "CN",
    "cab": "467407",
    "cap": "12088"
  },
  {
    "key": "12258",
    "ITA": "ROCCAFORTE MONDOVI'",
    "state_code": "CN",
    "cab": "467407",
    "cap": "12088"
  },
  {
    "key": "12259",
    "ITA": "ROCCAFORZATA",
    "state_code": "TA",
    "cab": "956821",
    "cap": "74020"
  },
  {
    "key": "12260",
    "ITA": "ROCCAFRANCA",
    "state_code": "BS",
    "cab": "551002",
    "cap": "25030"
  },
  {
    "key": "12261",
    "ITA": "ROCCAFRANCA-LUDRIANO",
    "state_code": "BS",
    "cab": "551002",
    "cap": "25030"
  },
  {
    "key": "12262",
    "ITA": "ROCCAGIOVINE",
    "state_code": "RM",
    "cab": "908319",
    "cap": "20"
  },
  {
    "key": "12263",
    "ITA": "ROCCAGLORIOSA",
    "state_code": "SA",
    "cab": "952895",
    "cap": "84060"
  },
  {
    "key": "12264",
    "ITA": "ROCCAGLORIOSA-ACQUAVENA",
    "state_code": "SA",
    "cab": "952895",
    "cap": "84040"
  },
  {
    "key": "12265",
    "ITA": "ROCCAGORGA",
    "state_code": "LT",
    "cab": "741009",
    "cap": "4010"
  },
  {
    "key": "12266",
    "ITA": "ROCCALBEGNA",
    "state_code": "GR",
    "cab": "723403",
    "cap": "58053"
  },
  {
    "key": "12267",
    "ITA": "ROCCALBEGNA-CANA",
    "state_code": "GR",
    "cab": "723403",
    "cap": "58053"
  },
  {
    "key": "12268",
    "ITA": "ROCCALBEGNA-VALLERONA",
    "state_code": "GR",
    "cab": "723403",
    "cap": "58053"
  },
  {
    "key": "12269",
    "ITA": "ROCCALBEGNA-TRIANA",
    "state_code": "GR",
    "cab": "723403",
    "cap": "58053"
  },
  {
    "key": "12270",
    "ITA": "ROCCALBEGNA-SANTA CATERINA",
    "state_code": "GR",
    "cab": "723403",
    "cap": "58053"
  },
  {
    "key": "12271",
    "ITA": "ROCCALUMERA",
    "state_code": "ME",
    "cab": "824102",
    "cap": "98027"
  },
  {
    "key": "12272",
    "ITA": "ROCCALUMERA-ALLUME",
    "state_code": "ME",
    "cab": "824102",
    "cap": "98027"
  },
  {
    "key": "12273",
    "ITA": "ROCCALUMERA-SCIGLIO",
    "state_code": "ME",
    "cab": "824102",
    "cap": "98027"
  },
  {
    "key": "12274",
    "ITA": "ROCCAMANDOLFI",
    "state_code": "IS",
    "cab": "955823",
    "cap": "86092"
  },
  {
    "key": "12275",
    "ITA": "ROCCAMENA",
    "state_code": "PA",
    "cab": "435602",
    "cap": "90040"
  },
  {
    "key": "12276",
    "ITA": "ROCCAMONFINA-GAROFALI",
    "state_code": "CE",
    "cab": "749903",
    "cap": "81035"
  },
  {
    "key": "12277",
    "ITA": "ROCCAMONFINA-GALLO",
    "state_code": "CE",
    "cab": "749903",
    "cap": "81035"
  },
  {
    "key": "12278",
    "ITA": "ROCCAMONFINA-FONTANAFREDDA",
    "state_code": "CE",
    "cab": "749903",
    "cap": "81035"
  },
  {
    "key": "12279",
    "ITA": "ROCCAMONFINA",
    "state_code": "CE",
    "cab": "749903",
    "cap": "81035"
  },
  {
    "key": "12280",
    "ITA": "ROCCAMONFINA-FILORSI",
    "state_code": "CE",
    "cab": "749903",
    "cap": "81035"
  },
  {
    "key": "12281",
    "ITA": "ROCCAMONTEPIANO",
    "state_code": "CH",
    "cab": "954933",
    "cap": "66010"
  },
  {
    "key": "12282",
    "ITA": "ROCCAMONTEPIANO-TERRANOVA",
    "state_code": "CH",
    "cab": "954933",
    "cap": "66010"
  },
  {
    "key": "12283",
    "ITA": "ROCCAMONTEPIANO-SAN ROCCO",
    "state_code": "CH",
    "cab": "954933",
    "cap": "66010"
  },
  {
    "key": "12284",
    "ITA": "ROCCAMORICE",
    "state_code": "PE",
    "cab": "954115",
    "cap": "65020"
  },
  {
    "key": "12285",
    "ITA": "ROCCANOVA",
    "state_code": "PZ",
    "cab": "423806",
    "cap": "85036"
  },
  {
    "key": "12286",
    "ITA": "ROCCANTICA",
    "state_code": "RI",
    "cab": "947382",
    "cap": "2040"
  },
  {
    "key": "12287",
    "ITA": "ROCCAPALUMBA-REGALGIOFFOLI",
    "state_code": "PA",
    "cab": "435701",
    "cap": "90020"
  },
  {
    "key": "12288",
    "ITA": "ROCCAPALUMBA",
    "state_code": "PA",
    "cab": "435701",
    "cap": "90020"
  },
  {
    "key": "12289",
    "ITA": "ROCCAPIEMONTE",
    "state_code": "SA",
    "cab": "763904",
    "cap": "84086"
  },
  {
    "key": "12290",
    "ITA": "ROCCAPIEMONTE-CASALI SAN POTITO",
    "state_code": "SA",
    "cab": "763904",
    "cap": "84086"
  },
  {
    "key": "12291",
    "ITA": "ROCCARAINOLA-GARGANI",
    "state_code": "NA",
    "cab": "885905",
    "cap": "80030"
  },
  {
    "key": "12292",
    "ITA": "ROCCARAINOLA",
    "state_code": "NA",
    "cab": "885905",
    "cap": "80030"
  },
  {
    "key": "12293",
    "ITA": "ROCCARASO-PIETRANSIERI",
    "state_code": "AQ",
    "cab": "407502",
    "cap": "67037"
  },
  {
    "key": "12294",
    "ITA": "ROCCARASO",
    "state_code": "AQ",
    "cab": "407502",
    "cap": "67037"
  },
  {
    "key": "12295",
    "ITA": "ROCCARASO-AREMOGNA",
    "state_code": "AQ",
    "cab": "407502",
    "cap": "67037"
  },
  {
    "key": "12296",
    "ITA": "ROCCAROMANA-STATIGLIANO",
    "state_code": "CE",
    "cab": "949552",
    "cap": "81051"
  },
  {
    "key": "12297",
    "ITA": "ROCCAROMANA",
    "state_code": "CE",
    "cab": "949552",
    "cap": "81051"
  },
  {
    "key": "12298",
    "ITA": "ROCCASCALEGNA",
    "state_code": "CH",
    "cab": "954958",
    "cap": "66040"
  },
  {
    "key": "12299",
    "ITA": "ROCCASECCA-CAPRILE",
    "state_code": "FR",
    "cab": "745604",
    "cap": "3038"
  },
  {
    "key": "12300",
    "ITA": "ROCCASECCA",
    "state_code": "FR",
    "cab": "745604",
    "cap": "3038"
  },
  {
    "key": "12301",
    "ITA": "ROCCASECCA-ROCCASECCA STAZIONE",
    "state_code": "FR",
    "cab": "745604",
    "cap": "3038"
  },
  {
    "key": "12302",
    "ITA": "ROCCASECCA DEI VOLSCI",
    "state_code": "LT",
    "cab": "947820",
    "cap": "4010"
  },
  {
    "key": "12303",
    "ITA": "ROCCASICURA",
    "state_code": "IS",
    "cab": "955831",
    "cap": "86080"
  },
  {
    "key": "12304",
    "ITA": "ROCCASPARVERA",
    "state_code": "CN",
    "cab": "919274",
    "cap": "12010"
  },
  {
    "key": "12305",
    "ITA": "ROCCASPINALVETI-OLMI",
    "state_code": "CH",
    "cab": "778308",
    "cap": "66050"
  },
  {
    "key": "12306",
    "ITA": "ROCCASPINALVETI",
    "state_code": "CH",
    "cab": "778308",
    "cap": "66050"
  },
  {
    "key": "12307",
    "ITA": "ROCCASTRADA-STICCIANO SCALO",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58036"
  },
  {
    "key": "12308",
    "ITA": "ROCCASTRADA-TORNIELLA",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58036"
  },
  {
    "key": "12309",
    "ITA": "ROCCASTRADA-STICCIANO",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58036"
  },
  {
    "key": "12310",
    "ITA": "ROCCASTRADA-SASSOFORTINO",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58036"
  },
  {
    "key": "12311",
    "ITA": "ROCCASTRADA-RIBOLLA",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58027"
  },
  {
    "key": "12312",
    "ITA": "ROCCASTRADA-MONTEMASSI",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58027"
  },
  {
    "key": "12313",
    "ITA": "ROCCASTRADA",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58036"
  },
  {
    "key": "12314",
    "ITA": "ROCCASTRADA-ROCCATEDERIGHI",
    "state_code": "GR",
    "cab": "723502",
    "cap": "58036"
  },
  {
    "key": "12315",
    "ITA": "ROCCAVALDINA",
    "state_code": "ME",
    "cab": "961557",
    "cap": "98040"
  },
  {
    "key": "12316",
    "ITA": "ROCCAVERANO",
    "state_code": "AT",
    "cab": "671008",
    "cap": "14050"
  },
  {
    "key": "12317",
    "ITA": "ROCCAVIGNALE",
    "state_code": "SV",
    "cab": "923284",
    "cap": "17017"
  },
  {
    "key": "12318",
    "ITA": "ROCCAVIONE-BRIGNOLA",
    "state_code": "CN",
    "cab": "467506",
    "cap": "12018"
  },
  {
    "key": "12319",
    "ITA": "ROCCAVIONE",
    "state_code": "CN",
    "cab": "467506",
    "cap": "12018"
  },
  {
    "key": "12320",
    "ITA": "ROCCAVIVARA",
    "state_code": "CB",
    "cab": "910869",
    "cap": "86020"
  },
  {
    "key": "12321",
    "ITA": "ROCCELLA IONICA",
    "state_code": "RC",
    "cab": "815209",
    "cap": "89047"
  },
  {
    "key": "12322",
    "ITA": "ROCCELLA VALDEMONE",
    "state_code": "ME",
    "cab": "961565",
    "cap": "98030"
  },
  {
    "key": "12323",
    "ITA": "ROCCHETTA A VOLTURNO-CASTELNUOVO AL VOLTURNO",
    "state_code": "IS",
    "cab": "955849",
    "cap": "86070"
  },
  {
    "key": "12324",
    "ITA": "ROCCHETTA A VOLTURNO",
    "state_code": "IS",
    "cab": "955849",
    "cap": "86070"
  },
  {
    "key": "12325",
    "ITA": "ROCCHETTA A VOLTURNO-ROCCHETTA NUOVA",
    "state_code": "IS",
    "cab": "955849",
    "cap": "86070"
  },
  {
    "key": "12326",
    "ITA": "ROCCHETTA BELBO",
    "state_code": "CN",
    "cab": "919282",
    "cap": "12050"
  },
  {
    "key": "12327",
    "ITA": "ROCCHETTA DI VARA-VEPPO",
    "state_code": "SP",
    "cab": "923771",
    "cap": "19020"
  },
  {
    "key": "12328",
    "ITA": "ROCCHETTA DI VARA-SUVERO",
    "state_code": "SP",
    "cab": "923771",
    "cap": "19020"
  },
  {
    "key": "12329",
    "ITA": "ROCCHETTA DI VARA",
    "state_code": "SP",
    "cab": "923771",
    "cap": "19020"
  },
  {
    "key": "12330",
    "ITA": "ROCCHETTA E CROCE-VAL D'ASSANO",
    "state_code": "CE",
    "cab": "949560",
    "cap": "81042"
  },
  {
    "key": "12331",
    "ITA": "ROCCHETTA E CROCE-PETRULO",
    "state_code": "CE",
    "cab": "949560",
    "cap": "81042"
  },
  {
    "key": "12332",
    "ITA": "ROCCHETTA E CROCE",
    "state_code": "CE",
    "cab": "949560",
    "cap": "81042"
  },
  {
    "key": "12333",
    "ITA": "ROCCHETTA LIGURE",
    "state_code": "AL",
    "cab": "485607",
    "cap": "15060"
  },
  {
    "key": "12334",
    "ITA": "ROCCHETTA NERVINA",
    "state_code": "IM",
    "cab": "922591",
    "cap": "18030"
  },
  {
    "key": "12335",
    "ITA": "ROCCHETTA PALAFEA",
    "state_code": "AT",
    "cab": "920397",
    "cap": "14042"
  },
  {
    "key": "12336",
    "ITA": "ROCCHETTA SANT'ANTONIO",
    "state_code": "FG",
    "cab": "785501",
    "cap": "71020"
  },
  {
    "key": "12337",
    "ITA": "ROCCHETTA SANT'ANTONIO-ROCCHETTA SANT'ANTONIO STAZIONE",
    "state_code": "FG",
    "cab": "785501",
    "cap": "71020"
  },
  {
    "key": "12338",
    "ITA": "ROCCHETTA TANARO",
    "state_code": "AT",
    "cab": "476606",
    "cap": "14030"
  },
  {
    "key": "12339",
    "ITA": "RODANO-LUCINO",
    "state_code": "MI",
    "cab": "341602",
    "cap": "20090"
  },
  {
    "key": "12340",
    "ITA": "RODANO-MILLEPINI",
    "state_code": "MI",
    "cab": "341602",
    "cap": "20090"
  },
  {
    "key": "12341",
    "ITA": "RODANO",
    "state_code": "MI",
    "cab": "341602",
    "cap": "20090"
  },
  {
    "key": "12342",
    "ITA": "RODDI",
    "state_code": "CN",
    "cab": "919290",
    "cap": "12060"
  },
  {
    "key": "12343",
    "ITA": "RODDINO",
    "state_code": "CN",
    "cab": "919308",
    "cap": "12050"
  },
  {
    "key": "12344",
    "ITA": "RODELLO",
    "state_code": "CN",
    "cab": "787903",
    "cap": "12050"
  },
  {
    "key": "12345",
    "ITA": "RODENGO",
    "state_code": "BZ",
    "cab": "587709",
    "cap": "39037"
  },
  {
    "key": "12346",
    "ITA": "RODENGO SAIANO-PONTE CINGOLI",
    "state_code": "BS",
    "cab": "551101",
    "cap": "25050"
  },
  {
    "key": "12347",
    "ITA": "RODENGO SAIANO",
    "state_code": "BS",
    "cab": "551101",
    "cap": "25050"
  },
  {
    "key": "12348",
    "ITA": "RODERO",
    "state_code": "CO",
    "cab": "926121",
    "cap": "22070"
  },
  {
    "key": "12349",
    "ITA": "RODI GARGANICO",
    "state_code": "FG",
    "cab": "785600",
    "cap": "71012"
  },
  {
    "key": "12350",
    "ITA": "RODI' MILICI-MILICI",
    "state_code": "ME",
    "cab": "824201",
    "cap": "98059"
  },
  {
    "key": "12351",
    "ITA": "RODI' MILICI",
    "state_code": "ME",
    "cab": "824201",
    "cap": "98059"
  },
  {
    "key": "12352",
    "ITA": "RODIGO",
    "state_code": "MN",
    "cab": "578807",
    "cap": "46040"
  },
  {
    "key": "12353",
    "ITA": "RODIGO-RIVALTA",
    "state_code": "MN",
    "cab": "578807",
    "cap": "46040"
  },
  {
    "key": "12354",
    "ITA": "ROE' VOLCIANO",
    "state_code": "BS",
    "cab": "551200",
    "cap": "25077"
  },
  {
    "key": "12355",
    "ITA": "ROE' VOLCIANO-ROE'",
    "state_code": "BS",
    "cab": "551200",
    "cap": "25077"
  },
  {
    "key": "12356",
    "ITA": "ROFRANO",
    "state_code": "SA",
    "cab": "693002",
    "cap": "84070"
  },
  {
    "key": "12357",
    "ITA": "ROGENO",
    "state_code": "LC",
    "cab": "450809",
    "cap": "23849"
  },
  {
    "key": "12358",
    "ITA": "ROGENO-CASLETTO",
    "state_code": "LC",
    "cab": "450809",
    "cap": "23849"
  },
  {
    "key": "12359",
    "ITA": "ROGGIANO GRAVINA",
    "state_code": "CS",
    "cab": "809004",
    "cap": "87017"
  },
  {
    "key": "12360",
    "ITA": "ROGHUDI",
    "state_code": "RC",
    "cab": "960302",
    "cap": "89060"
  },
  {
    "key": "12361",
    "ITA": "ROGHUDI-ROGHUDI NUOVO",
    "state_code": "RC",
    "cab": "960302",
    "cap": "89060"
  },
  {
    "key": "12362",
    "ITA": "ROGLIANO",
    "state_code": "CS",
    "cab": "809103",
    "cap": "87054"
  },
  {
    "key": "12363",
    "ITA": "ROGLIANO-SALIANO",
    "state_code": "CS",
    "cab": "809103",
    "cap": "87054"
  },
  {
    "key": "12364",
    "ITA": "ROGNANO",
    "state_code": "PV",
    "cab": "930800",
    "cap": "27010"
  },
  {
    "key": "12365",
    "ITA": "ROGNO",
    "state_code": "BG",
    "cab": "318204",
    "cap": "24060"
  },
  {
    "key": "12366",
    "ITA": "ROGOLO",
    "state_code": "SO",
    "cab": "927251",
    "cap": "23010"
  },
  {
    "key": "12367",
    "ITA": "ROIATE",
    "state_code": "RM",
    "cab": "908335",
    "cap": "30"
  },
  {
    "key": "12368",
    "ITA": "ROIO DEL SANGRO",
    "state_code": "CH",
    "cab": "954966",
    "cap": "66040"
  },
  {
    "key": "12369",
    "ITA": "ROISAN",
    "state_code": "AO",
    "cab": "902585",
    "cap": "11010"
  },
  {
    "key": "12370",
    "ITA": "ROISAN-LA CRETAZ",
    "state_code": "AO",
    "cab": "902585",
    "cap": "11010"
  },
  {
    "key": "12371",
    "ITA": "ROLETTO",
    "state_code": "TO",
    "cab": "189886",
    "cap": "10060"
  },
  {
    "key": "12372",
    "ITA": "ROLO",
    "state_code": "RE",
    "cab": "664607",
    "cap": "42047"
  },
  {
    "key": "12373",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "168"
  },
  {
    "key": "12374",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "169"
  },
  {
    "key": "12375",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "171"
  },
  {
    "key": "12376",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "172"
  },
  {
    "key": "12377",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "173"
  },
  {
    "key": "12378",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "174"
  },
  {
    "key": "12379",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "175"
  },
  {
    "key": "12380",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "176"
  },
  {
    "key": "12381",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "177"
  },
  {
    "key": "12382",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "178"
  },
  {
    "key": "12383",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "179"
  },
  {
    "key": "12384",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "199"
  },
  {
    "key": "12385",
    "ITA": "ROMA-COLLE DELLA VALENTINA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "132"
  },
  {
    "key": "12386",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "181"
  },
  {
    "key": "12387",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "182"
  },
  {
    "key": "12388",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "183"
  },
  {
    "key": "12389",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "184"
  },
  {
    "key": "12390",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "185"
  },
  {
    "key": "12391",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "186"
  },
  {
    "key": "12392",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "187"
  },
  {
    "key": "12393",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "188"
  },
  {
    "key": "12394",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "189"
  },
  {
    "key": "12395",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "191"
  },
  {
    "key": "12396",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "192"
  },
  {
    "key": "12397",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "193"
  },
  {
    "key": "12398",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "194"
  },
  {
    "key": "12399",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "195"
  },
  {
    "key": "12400",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "196"
  },
  {
    "key": "12401",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "197"
  },
  {
    "key": "12402",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "198"
  },
  {
    "key": "12403",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "118"
  },
  {
    "key": "12404",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "119"
  },
  {
    "key": "12405",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "121"
  },
  {
    "key": "12406",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "122"
  },
  {
    "key": "12407",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "123"
  },
  {
    "key": "12408",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "124"
  },
  {
    "key": "12409",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "125"
  },
  {
    "key": "12410",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "126"
  },
  {
    "key": "12411",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "127"
  },
  {
    "key": "12412",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "128"
  },
  {
    "key": "12413",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "131"
  },
  {
    "key": "12414",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "132"
  },
  {
    "key": "12415",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "133"
  },
  {
    "key": "12416",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "134"
  },
  {
    "key": "12417",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "135"
  },
  {
    "key": "12418",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "136"
  },
  {
    "key": "12419",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "137"
  },
  {
    "key": "12420",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "138"
  },
  {
    "key": "12421",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "139"
  },
  {
    "key": "12422",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "141"
  },
  {
    "key": "12423",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "142"
  },
  {
    "key": "12424",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "143"
  },
  {
    "key": "12425",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "144"
  },
  {
    "key": "12426",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "145"
  },
  {
    "key": "12427",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "146"
  },
  {
    "key": "12428",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "147"
  },
  {
    "key": "12429",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "148"
  },
  {
    "key": "12430",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "149"
  },
  {
    "key": "12431",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "151"
  },
  {
    "key": "12432",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "152"
  },
  {
    "key": "12433",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "153"
  },
  {
    "key": "12434",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "154"
  },
  {
    "key": "12435",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "155"
  },
  {
    "key": "12436",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "156"
  },
  {
    "key": "12437",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "157"
  },
  {
    "key": "12438",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "158"
  },
  {
    "key": "12439",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "159"
  },
  {
    "key": "12440",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "161"
  },
  {
    "key": "12441",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "162"
  },
  {
    "key": "12442",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "163"
  },
  {
    "key": "12443",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "164"
  },
  {
    "key": "12444",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "165"
  },
  {
    "key": "12445",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "166"
  },
  {
    "key": "12446",
    "ITA": "ROMA",
    "state_code": "RM",
    "cab": "32003",
    "cap": "167"
  },
  {
    "key": "12447",
    "ITA": "ROMAGNANO AL MONTE",
    "state_code": "SA",
    "cab": "952911",
    "cap": "84020"
  },
  {
    "key": "12448",
    "ITA": "ROMAGNANO SESIA",
    "state_code": "NO",
    "cab": "456202",
    "cap": "28078"
  },
  {
    "key": "12449",
    "ITA": "ROMAGNESE",
    "state_code": "PV",
    "cab": "561605",
    "cap": "27050"
  },
  {
    "key": "12450",
    "ITA": "ROMALLO",
    "state_code": "TN",
    "cab": "353300",
    "cap": "38028"
  },
  {
    "key": "12451",
    "ITA": "ROMANA",
    "state_code": "SS",
    "cab": "875500",
    "cap": "7010"
  },
  {
    "key": "12452",
    "ITA": "ROMANENGO",
    "state_code": "CR",
    "cab": "571000",
    "cap": "26014"
  },
  {
    "key": "12453",
    "ITA": "ROMANO CANAVESE",
    "state_code": "TO",
    "cab": "308809",
    "cap": "10090"
  },
  {
    "key": "12454",
    "ITA": "ROMANO D'EZZELINO",
    "state_code": "VI",
    "cab": "609008",
    "cap": "36060"
  },
  {
    "key": "12455",
    "ITA": "ROMANO D'EZZELINO-FELLETTE",
    "state_code": "VI",
    "cab": "609008",
    "cap": "36060"
  },
  {
    "key": "12456",
    "ITA": "ROMANO D'EZZELINO-SPIN",
    "state_code": "VI",
    "cab": "609008",
    "cap": "36060"
  },
  {
    "key": "12457",
    "ITA": "ROMANO DI LOMBARDIA",
    "state_code": "BG",
    "cab": "534206",
    "cap": "24058"
  },
  {
    "key": "12458",
    "ITA": "ROMANS D'ISONZO-VERSA",
    "state_code": "GO",
    "cab": "646208",
    "cap": "34076"
  },
  {
    "key": "12459",
    "ITA": "ROMANS D'ISONZO-FRATTA",
    "state_code": "GO",
    "cab": "646208",
    "cap": "34076"
  },
  {
    "key": "12460",
    "ITA": "ROMANS D'ISONZO",
    "state_code": "GO",
    "cab": "646208",
    "cap": "34076"
  },
  {
    "key": "12461",
    "ITA": "ROMBIOLO-PRESINACI",
    "state_code": "VV",
    "cab": "581306",
    "cap": "89841"
  },
  {
    "key": "12462",
    "ITA": "ROMBIOLO-PERNOCARI",
    "state_code": "VV",
    "cab": "581306",
    "cap": "89841"
  },
  {
    "key": "12463",
    "ITA": "ROMBIOLO-MOLADI",
    "state_code": "VV",
    "cab": "581306",
    "cap": "89841"
  },
  {
    "key": "12464",
    "ITA": "ROMBIOLO",
    "state_code": "VV",
    "cab": "581306",
    "cap": "89841"
  },
  {
    "key": "12465",
    "ITA": "ROMENO",
    "state_code": "TN",
    "cab": "353409",
    "cap": "38010"
  },
  {
    "key": "12466",
    "ITA": "ROMENO-MALGOLO",
    "state_code": "TN",
    "cab": "353409",
    "cap": "38010"
  },
  {
    "key": "12467",
    "ITA": "ROMENTINO",
    "state_code": "NO",
    "cab": "456301",
    "cap": "28068"
  },
  {
    "key": "12468",
    "ITA": "ROMETTA",
    "state_code": "ME",
    "cab": "824300",
    "cap": "98043"
  },
  {
    "key": "12469",
    "ITA": "ROMETTA-GIMELLO",
    "state_code": "ME",
    "cab": "824300",
    "cap": "98043"
  },
  {
    "key": "12470",
    "ITA": "ROMETTA-ROMETTA MAREA",
    "state_code": "ME",
    "cab": "824300",
    "cap": "98043"
  },
  {
    "key": "12471",
    "ITA": "ROMETTA-SANT'ANDREA",
    "state_code": "ME",
    "cab": "824300",
    "cap": "98043"
  },
  {
    "key": "12472",
    "ITA": "RONAGO",
    "state_code": "CO",
    "cab": "926147",
    "cap": "22027"
  },
  {
    "key": "12473",
    "ITA": "RONCA'",
    "state_code": "VR",
    "cab": "596908",
    "cap": "37030"
  },
  {
    "key": "12474",
    "ITA": "RONCA'-TERROSSA",
    "state_code": "VR",
    "cab": "596908",
    "cap": "37030"
  },
  {
    "key": "12475",
    "ITA": "RONCADE",
    "state_code": "TV",
    "cab": "620104",
    "cap": "31056"
  },
  {
    "key": "12476",
    "ITA": "RONCADE-BIANCADE",
    "state_code": "TV",
    "cab": "620104",
    "cap": "31056"
  },
  {
    "key": "12477",
    "ITA": "RONCADE-MUSESTRE",
    "state_code": "TV",
    "cab": "620104",
    "cap": "31056"
  },
  {
    "key": "12478",
    "ITA": "RONCADE-SAN CIPRIANO",
    "state_code": "TV",
    "cab": "620104",
    "cap": "31056"
  },
  {
    "key": "12479",
    "ITA": "RONCADELLE",
    "state_code": "BS",
    "cab": "551309",
    "cap": "25030"
  },
  {
    "key": "12480",
    "ITA": "RONCARO",
    "state_code": "PV",
    "cab": "930818",
    "cap": "27010"
  },
  {
    "key": "12481",
    "ITA": "RONCEGNO-MONTE DI MEZZO",
    "state_code": "TN",
    "cab": "353508",
    "cap": "38050"
  },
  {
    "key": "12482",
    "ITA": "RONCEGNO-SANTA BRIGIDA",
    "state_code": "TN",
    "cab": "353508",
    "cap": "38050"
  },
  {
    "key": "12483",
    "ITA": "RONCEGNO-MARTER",
    "state_code": "TN",
    "cab": "353508",
    "cap": "38050"
  },
  {
    "key": "12484",
    "ITA": "RONCEGNO",
    "state_code": "TN",
    "cab": "353508",
    "cap": "38050"
  },
  {
    "key": "12485",
    "ITA": "RONCELLO",
    "state_code": "MB",
    "cab": "894402",
    "cap": "20040"
  },
  {
    "key": "12486",
    "ITA": "RONCHI DEI LEGIONARI",
    "state_code": "GO",
    "cab": "646307",
    "cap": "34077"
  },
  {
    "key": "12487",
    "ITA": "RONCHI DEI LEGIONARI-VERMEGLIANO",
    "state_code": "GO",
    "cab": "646307",
    "cap": "34077"
  },
  {
    "key": "12488",
    "ITA": "RONCHI VALSUGANA",
    "state_code": "TN",
    "cab": "905042",
    "cap": "38050"
  },
  {
    "key": "12489",
    "ITA": "RONCHIS",
    "state_code": "UD",
    "cab": "641803",
    "cap": "33050"
  },
  {
    "key": "12490",
    "ITA": "RONCIGLIONE-LAGO DI VICO",
    "state_code": "VT",
    "cab": "732404",
    "cap": "1037"
  },
  {
    "key": "12491",
    "ITA": "RONCIGLIONE",
    "state_code": "VT",
    "cab": "732404",
    "cap": "1037"
  },
  {
    "key": "12492",
    "ITA": "RONCO ALL'ADIGE",
    "state_code": "VR",
    "cab": "597005",
    "cap": "37055"
  },
  {
    "key": "12493",
    "ITA": "RONCO ALL'ADIGE-ALBARO",
    "state_code": "VR",
    "cab": "597005",
    "cap": "37055"
  },
  {
    "key": "12494",
    "ITA": "RONCO ALL'ADIGE-TOMBAZOSANA",
    "state_code": "VR",
    "cab": "597005",
    "cap": "37055"
  },
  {
    "key": "12495",
    "ITA": "RONCO BIELLESE",
    "state_code": "BI",
    "cab": "916262",
    "cap": "13845"
  },
  {
    "key": "12496",
    "ITA": "RONCO BRIANTINO",
    "state_code": "MB",
    "cab": "893206",
    "cap": "20050"
  },
  {
    "key": "12497",
    "ITA": "RONCO CANAVESE",
    "state_code": "TO",
    "cab": "308908",
    "cap": "10080"
  },
  {
    "key": "12498",
    "ITA": "RONCO SCRIVIA",
    "state_code": "GE",
    "cab": "321406",
    "cap": "16019"
  },
  {
    "key": "12499",
    "ITA": "RONCO SCRIVIA-BORGO FORNARI",
    "state_code": "GE",
    "cab": "321406",
    "cap": "16019"
  },
  {
    "key": "12500",
    "ITA": "RONCO SCRIVIA-PIEVE",
    "state_code": "GE",
    "cab": "321406",
    "cap": "16019"
  },
  {
    "key": "12501",
    "ITA": "RONCOBELLO",
    "state_code": "BG",
    "cab": "812206",
    "cap": "24010"
  },
  {
    "key": "12502",
    "ITA": "RONCOBELLO-BORDOGNA",
    "state_code": "BG",
    "cab": "812206",
    "cap": "24010"
  },
  {
    "key": "12503",
    "ITA": "RONCOFERRARO",
    "state_code": "MN",
    "cab": "578906",
    "cap": "46037"
  },
  {
    "key": "12504",
    "ITA": "RONCOFERRARO-BARBASSO",
    "state_code": "MN",
    "cab": "578906",
    "cap": "46037"
  },
  {
    "key": "12505",
    "ITA": "RONCOFERRARO-BORGO CASTELLETTO",
    "state_code": "MN",
    "cab": "578906",
    "cap": "46037"
  },
  {
    "key": "12506",
    "ITA": "RONCOFERRARO-GOVERNOLO",
    "state_code": "MN",
    "cab": "578906",
    "cap": "46037"
  },
  {
    "key": "12507",
    "ITA": "RONCOFERRARO-PONTEMERLANO",
    "state_code": "MN",
    "cab": "578906",
    "cap": "46037"
  },
  {
    "key": "12508",
    "ITA": "RONCOFERRARO-CASALE",
    "state_code": "MN",
    "cab": "578906",
    "cap": "46037"
  },
  {
    "key": "12509",
    "ITA": "RONCOFERRARO-VILLA GARIBALDI",
    "state_code": "MN",
    "cab": "578906",
    "cap": "46037"
  },
  {
    "key": "12510",
    "ITA": "RONCOFREDDO",
    "state_code": "FC",
    "cab": "679803",
    "cap": "47020"
  },
  {
    "key": "12511",
    "ITA": "RONCOFREDDO-ORIOLA",
    "state_code": "FC",
    "cab": "679803",
    "cap": "47020"
  },
  {
    "key": "12512",
    "ITA": "RONCOLA",
    "state_code": "BG",
    "cab": "928564",
    "cap": "24030"
  },
  {
    "key": "12513",
    "ITA": "RONCONE",
    "state_code": "TN",
    "cab": "353607",
    "cap": "38087"
  },
  {
    "key": "12514",
    "ITA": "RONDANINA",
    "state_code": "GE",
    "cab": "903260",
    "cap": "16025"
  },
  {
    "key": "12515",
    "ITA": "RONDANINA-PONTE TREBBIA",
    "state_code": "GE",
    "cab": "903260",
    "cap": "16025"
  },
  {
    "key": "12516",
    "ITA": "RONDISSONE",
    "state_code": "TO",
    "cab": "317909",
    "cap": "10030"
  },
  {
    "key": "12517",
    "ITA": "RONSECCO",
    "state_code": "VC",
    "cab": "447201",
    "cap": "13036"
  },
  {
    "key": "12518",
    "ITA": "RONZO CHIENIS",
    "state_code": "TN",
    "cab": "351908",
    "cap": "38060"
  },
  {
    "key": "12519",
    "ITA": "RONZO CHIENIS-RONZO",
    "state_code": "TN",
    "cab": "351908",
    "cap": "38060"
  },
  {
    "key": "12520",
    "ITA": "RONZO CHIENIS-CHIENIS",
    "state_code": "TN",
    "cab": "351908",
    "cap": "38060"
  },
  {
    "key": "12521",
    "ITA": "RONZONE",
    "state_code": "TN",
    "cab": "905059",
    "cap": "38010"
  },
  {
    "key": "12522",
    "ITA": "ROPPOLO",
    "state_code": "BI",
    "cab": "916270",
    "cap": "13883"
  },
  {
    "key": "12523",
    "ITA": "RORA'",
    "state_code": "TO",
    "cab": "901348",
    "cap": "10060"
  },
  {
    "key": "12524",
    "ITA": "ROSA'",
    "state_code": "VI",
    "cab": "606905",
    "cap": "36027"
  },
  {
    "key": "12525",
    "ITA": "ROSARNO",
    "state_code": "RC",
    "cab": "815308",
    "cap": "89025"
  },
  {
    "key": "12526",
    "ITA": "ROSARNO-BOSCO",
    "state_code": "RC",
    "cab": "815308",
    "cap": "89025"
  },
  {
    "key": "12527",
    "ITA": "ROSASCO",
    "state_code": "PV",
    "cab": "561704",
    "cap": "27030"
  },
  {
    "key": "12528",
    "ITA": "ROSATE",
    "state_code": "MI",
    "cab": "336701",
    "cap": "20088"
  },
  {
    "key": "12529",
    "ITA": "ROSAZZA",
    "state_code": "BI",
    "cab": "916288",
    "cap": "13815"
  },
  {
    "key": "12530",
    "ITA": "ROSCIANO-VILLA SAN GIOVANNI",
    "state_code": "PE",
    "cab": "774000",
    "cap": "65020"
  },
  {
    "key": "12531",
    "ITA": "ROSCIANO-VILLA OLIVETI",
    "state_code": "PE",
    "cab": "774000",
    "cap": "65020"
  },
  {
    "key": "12532",
    "ITA": "ROSCIANO",
    "state_code": "PE",
    "cab": "774000",
    "cap": "65020"
  },
  {
    "key": "12533",
    "ITA": "ROSCIANO-VILLA BADESSA",
    "state_code": "PE",
    "cab": "774000",
    "cap": "65020"
  },
  {
    "key": "12534",
    "ITA": "ROSCIGNO",
    "state_code": "SA",
    "cab": "764001",
    "cap": "84020"
  },
  {
    "key": "12535",
    "ITA": "ROSE",
    "state_code": "CS",
    "cab": "811505",
    "cap": "87040"
  },
  {
    "key": "12536",
    "ITA": "ROSELLO-GIULIOPOLI",
    "state_code": "CH",
    "cab": "954974",
    "cap": "66040"
  },
  {
    "key": "12537",
    "ITA": "ROSELLO",
    "state_code": "CH",
    "cab": "954974",
    "cap": "66040"
  },
  {
    "key": "12538",
    "ITA": "ROSETO CAPO SPULICO-ROSETO CAPO SPULICO STAZIONE",
    "state_code": "CS",
    "cab": "959213",
    "cap": "87070"
  },
  {
    "key": "12539",
    "ITA": "ROSETO CAPO SPULICO-BORGATA MARINA",
    "state_code": "CS",
    "cab": "959213",
    "cap": "87070"
  },
  {
    "key": "12540",
    "ITA": "ROSETO CAPO SPULICO",
    "state_code": "CS",
    "cab": "959213",
    "cap": "87070"
  },
  {
    "key": "12541",
    "ITA": "ROSETO DEGLI ABRUZZI-COLOGNA PAESE",
    "state_code": "TE",
    "cab": "770206",
    "cap": "64026"
  },
  {
    "key": "12542",
    "ITA": "ROSETO DEGLI ABRUZZI-SANTA LUCIA",
    "state_code": "TE",
    "cab": "770206",
    "cap": "64026"
  },
  {
    "key": "12543",
    "ITA": "ROSETO DEGLI ABRUZZI-MONTEPAGANO",
    "state_code": "TE",
    "cab": "770206",
    "cap": "64026"
  },
  {
    "key": "12544",
    "ITA": "ROSETO DEGLI ABRUZZI-SAN GIOVANNI",
    "state_code": "TE",
    "cab": "770206",
    "cap": "64026"
  },
  {
    "key": "12545",
    "ITA": "ROSETO DEGLI ABRUZZI",
    "state_code": "TE",
    "cab": "770206",
    "cap": "64026"
  },
  {
    "key": "12546",
    "ITA": "ROSETO DEGLI ABRUZZI-COLOGNA SPIAGGIA",
    "state_code": "TE",
    "cab": "770206",
    "cap": "64026"
  },
  {
    "key": "12547",
    "ITA": "ROSETO VALFORTORE",
    "state_code": "FG",
    "cab": "785709",
    "cap": "71039"
  },
  {
    "key": "12548",
    "ITA": "ROSIGNANO MARITTIMO-VADA",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12549",
    "ITA": "ROSIGNANO MARITTIMO-CALETTA DI CASTIGLIONCELLO",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12550",
    "ITA": "ROSIGNANO MARITTIMO-NIBBIAIA",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12551",
    "ITA": "ROSIGNANO MARITTIMO-ROSIGNANO SOLVAY",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12552",
    "ITA": "ROSIGNANO MARITTIMO-CASTELNUOVO MISERICORDIA",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12553",
    "ITA": "ROSIGNANO MARITTIMO-CASTIGLIONCELLO",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12554",
    "ITA": "ROSIGNANO MARITTIMO-GABBRO",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12555",
    "ITA": "ROSIGNANO MARITTIMO",
    "state_code": "LI",
    "cab": "251009",
    "cap": "57016"
  },
  {
    "key": "12556",
    "ITA": "ROSIGNANO MONFERRATO-SAN MARTINO",
    "state_code": "AL",
    "cab": "376806",
    "cap": "15030"
  },
  {
    "key": "12557",
    "ITA": "ROSIGNANO MONFERRATO",
    "state_code": "AL",
    "cab": "376806",
    "cap": "15030"
  },
  {
    "key": "12558",
    "ITA": "ROSIGNANO MONFERRATO-STEVANI",
    "state_code": "AL",
    "cab": "376806",
    "cap": "15030"
  },
  {
    "key": "12559",
    "ITA": "ROSOLINA-ROSOLINA MARE",
    "state_code": "RO",
    "cab": "232009",
    "cap": "45010"
  },
  {
    "key": "12560",
    "ITA": "ROSOLINA",
    "state_code": "RO",
    "cab": "232009",
    "cap": "45010"
  },
  {
    "key": "12561",
    "ITA": "ROSOLINI",
    "state_code": "SR",
    "cab": "847707",
    "cap": "96019"
  },
  {
    "key": "12562",
    "ITA": "ROSORA",
    "state_code": "AN",
    "cab": "375501",
    "cap": "60030"
  },
  {
    "key": "12563",
    "ITA": "ROSORA-ANGELI",
    "state_code": "AN",
    "cab": "375501",
    "cap": "60030"
  },
  {
    "key": "12564",
    "ITA": "ROSSA",
    "state_code": "VC",
    "cab": "916296",
    "cap": "13020"
  },
  {
    "key": "12565",
    "ITA": "ROSSANA-LEMMA",
    "state_code": "CN",
    "cab": "524405",
    "cap": "12020"
  },
  {
    "key": "12566",
    "ITA": "ROSSANA",
    "state_code": "CN",
    "cab": "524405",
    "cap": "12020"
  },
  {
    "key": "12567",
    "ITA": "ROSSANO-ROSSANO STAZIONE",
    "state_code": "CS",
    "cab": "809202",
    "cap": "87067"
  },
  {
    "key": "12568",
    "ITA": "ROSSANO-PIRAGINETI",
    "state_code": "CS",
    "cab": "809202",
    "cap": "87067"
  },
  {
    "key": "12569",
    "ITA": "ROSSANO",
    "state_code": "CS",
    "cab": "809202",
    "cap": "87067"
  },
  {
    "key": "12570",
    "ITA": "ROSSANO-AMICA",
    "state_code": "CS",
    "cab": "809202",
    "cap": "87067"
  },
  {
    "key": "12571",
    "ITA": "ROSSANO VENETO",
    "state_code": "VI",
    "cab": "607002",
    "cap": "36028"
  },
  {
    "key": "12572",
    "ITA": "ROSSIGLIONE",
    "state_code": "GE",
    "cab": "321505",
    "cap": "16010"
  },
  {
    "key": "12573",
    "ITA": "ROSTA",
    "state_code": "TO",
    "cab": "312603",
    "cap": "10090"
  },
  {
    "key": "12574",
    "ITA": "ROTA D'IMAGNA-FRONTALE",
    "state_code": "BG",
    "cab": "928572",
    "cap": "24037"
  },
  {
    "key": "12575",
    "ITA": "ROTA D'IMAGNA",
    "state_code": "BG",
    "cab": "928572",
    "cap": "24037"
  },
  {
    "key": "12576",
    "ITA": "ROTA D'IMAGNA-CALCHERA",
    "state_code": "BG",
    "cab": "928572",
    "cap": "24037"
  },
  {
    "key": "12577",
    "ITA": "ROTA GRECA",
    "state_code": "CS",
    "cab": "809301",
    "cap": "87010"
  },
  {
    "key": "12578",
    "ITA": "ROTELLA-CASTEL DI CROCE",
    "state_code": "AP",
    "cab": "835405",
    "cap": "63030"
  },
  {
    "key": "12579",
    "ITA": "ROTELLA",
    "state_code": "AP",
    "cab": "835405",
    "cap": "63030"
  },
  {
    "key": "12580",
    "ITA": "ROTELLO",
    "state_code": "CB",
    "cab": "609701",
    "cap": "86040"
  },
  {
    "key": "12581",
    "ITA": "ROTONDA",
    "state_code": "PZ",
    "cab": "422204",
    "cap": "85048"
  },
  {
    "key": "12582",
    "ITA": "ROTONDELLA",
    "state_code": "MT",
    "cab": "804203",
    "cap": "75026"
  },
  {
    "key": "12583",
    "ITA": "ROTONDI-FERRARI DI CERVINARA",
    "state_code": "AV",
    "cab": "758003",
    "cap": "83017"
  },
  {
    "key": "12584",
    "ITA": "ROTONDI",
    "state_code": "AV",
    "cab": "758003",
    "cap": "83017"
  },
  {
    "key": "12585",
    "ITA": "ROTTOFRENO",
    "state_code": "PC",
    "cab": "654509",
    "cap": "29010"
  },
  {
    "key": "12586",
    "ITA": "ROTTOFRENO-SANT'IMENTO",
    "state_code": "PC",
    "cab": "654509",
    "cap": "29010"
  },
  {
    "key": "12587",
    "ITA": "ROTTOFRENO-SAN NICOLO'",
    "state_code": "PC",
    "cab": "654509",
    "cap": "29010"
  },
  {
    "key": "12588",
    "ITA": "ROTZO",
    "state_code": "VI",
    "cab": "934059",
    "cap": "36010"
  },
  {
    "key": "12589",
    "ITA": "ROURE",
    "state_code": "TO",
    "cab": "189738",
    "cap": "10060"
  },
  {
    "key": "12590",
    "ITA": "ROURE-VILLARETTO",
    "state_code": "TO",
    "cab": "189738",
    "cap": "10060"
  },
  {
    "key": "12591",
    "ITA": "ROURE-CASTEL DEL BOSCO",
    "state_code": "TO",
    "cab": "189738",
    "cap": "10060"
  },
  {
    "key": "12592",
    "ITA": "ROURE-RORETO",
    "state_code": "TO",
    "cab": "189738",
    "cap": "10060"
  },
  {
    "key": "12593",
    "ITA": "ROVAGNATE",
    "state_code": "LC",
    "cab": "517201",
    "cap": "23888"
  },
  {
    "key": "12594",
    "ITA": "ROVASENDA",
    "state_code": "VC",
    "cab": "488700",
    "cap": "13040"
  },
  {
    "key": "12595",
    "ITA": "ROVATO-SANT'ANDREA",
    "state_code": "BS",
    "cab": "551408",
    "cap": "25038"
  },
  {
    "key": "12596",
    "ITA": "ROVATO-LODETTO",
    "state_code": "BS",
    "cab": "551408",
    "cap": "25038"
  },
  {
    "key": "12597",
    "ITA": "ROVATO-DUOMO",
    "state_code": "BS",
    "cab": "551408",
    "cap": "25038"
  },
  {
    "key": "12598",
    "ITA": "ROVATO",
    "state_code": "BS",
    "cab": "551408",
    "cap": "25038"
  },
  {
    "key": "12599",
    "ITA": "ROVATO-SANT'ANNA",
    "state_code": "BS",
    "cab": "551408",
    "cap": "25038"
  },
  {
    "key": "12600",
    "ITA": "ROVEGNO-CASANOVA",
    "state_code": "GE",
    "cab": "321604",
    "cap": "16028"
  },
  {
    "key": "12601",
    "ITA": "ROVEGNO",
    "state_code": "GE",
    "cab": "321604",
    "cap": "16028"
  },
  {
    "key": "12602",
    "ITA": "ROVELLASCA",
    "state_code": "CO",
    "cab": "517300",
    "cap": "22069"
  },
  {
    "key": "12603",
    "ITA": "ROVELLO PORRO",
    "state_code": "CO",
    "cab": "517409",
    "cap": "22070"
  },
  {
    "key": "12604",
    "ITA": "ROVERBELLA-PELLALOCO",
    "state_code": "MN",
    "cab": "579003",
    "cap": "46048"
  },
  {
    "key": "12605",
    "ITA": "ROVERBELLA-MALAVICINA",
    "state_code": "MN",
    "cab": "579003",
    "cap": "46048"
  },
  {
    "key": "12606",
    "ITA": "ROVERBELLA-CANEDOLE",
    "state_code": "MN",
    "cab": "579003",
    "cap": "46048"
  },
  {
    "key": "12607",
    "ITA": "ROVERBELLA",
    "state_code": "MN",
    "cab": "579003",
    "cap": "46048"
  },
  {
    "key": "12608",
    "ITA": "ROVERBELLA-CASTIGLIONE MANTOVANO",
    "state_code": "MN",
    "cab": "579003",
    "cap": "46048"
  },
  {
    "key": "12609",
    "ITA": "ROVERCHIARA-ROVERCHIARETTA",
    "state_code": "VR",
    "cab": "597104",
    "cap": "37050"
  },
  {
    "key": "12610",
    "ITA": "ROVERCHIARA",
    "state_code": "VR",
    "cab": "597104",
    "cap": "37050"
  },
  {
    "key": "12611",
    "ITA": "ROVERE' DELLA LUNA",
    "state_code": "TN",
    "cab": "353706",
    "cap": "38030"
  },
  {
    "key": "12612",
    "ITA": "ROVERE' VERONESE",
    "state_code": "VR",
    "cab": "597302",
    "cap": "37028"
  },
  {
    "key": "12613",
    "ITA": "ROVEREDO DI GUA'",
    "state_code": "VR",
    "cab": "597203",
    "cap": "37040"
  },
  {
    "key": "12614",
    "ITA": "ROVEREDO DI GUA'-SABBION",
    "state_code": "VR",
    "cab": "597203",
    "cap": "37040"
  },
  {
    "key": "12615",
    "ITA": "ROVEREDO IN PIANO",
    "state_code": "PN",
    "cab": "649806",
    "cap": "33080"
  },
  {
    "key": "12616",
    "ITA": "ROVERETO-MORI FERROVIA",
    "state_code": "TN",
    "cab": "208009",
    "cap": "38068"
  },
  {
    "key": "12617",
    "ITA": "ROVERETO-BORGO SACCO",
    "state_code": "TN",
    "cab": "208009",
    "cap": "38068"
  },
  {
    "key": "12618",
    "ITA": "ROVERETO-LIZZANELLA",
    "state_code": "TN",
    "cab": "208009",
    "cap": "38068"
  },
  {
    "key": "12619",
    "ITA": "ROVERETO-MARCO",
    "state_code": "TN",
    "cab": "208009",
    "cap": "38068"
  },
  {
    "key": "12620",
    "ITA": "ROVERETO",
    "state_code": "TN",
    "cab": "208009",
    "cap": "38068"
  },
  {
    "key": "12621",
    "ITA": "ROVESCALA",
    "state_code": "PV",
    "cab": "561803",
    "cap": "27040"
  },
  {
    "key": "12622",
    "ITA": "ROVETTA",
    "state_code": "BG",
    "cab": "534305",
    "cap": "24020"
  },
  {
    "key": "12623",
    "ITA": "ROVETTA-SAN LORENZO DI ROVETTA",
    "state_code": "BG",
    "cab": "534305",
    "cap": "24020"
  },
  {
    "key": "12624",
    "ITA": "ROVIANO",
    "state_code": "RM",
    "cab": "908343",
    "cap": "27"
  },
  {
    "key": "12625",
    "ITA": "ROVIGO-BORSEA",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12626",
    "ITA": "ROVIGO-BUSO SARZANO",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12627",
    "ITA": "ROVIGO-SANT'APOLLINARE",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12628",
    "ITA": "ROVIGO-CONCADIRAME",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12629",
    "ITA": "ROVIGO-GRANZETTE",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12630",
    "ITA": "ROVIGO-GRIGNANO POLESINE",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12631",
    "ITA": "ROVIGO-MARDIMAGO",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12632",
    "ITA": "ROVIGO-ROVERDICRE'",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12633",
    "ITA": "ROVIGO-BOARA POLESINE",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12634",
    "ITA": "ROVIGO",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12635",
    "ITA": "ROVIGO-CANTONAZZO",
    "state_code": "RO",
    "cab": "122002",
    "cap": "45100"
  },
  {
    "key": "12636",
    "ITA": "ROVITO",
    "state_code": "CS",
    "cab": "959221",
    "cap": "87050"
  },
  {
    "key": "12637",
    "ITA": "ROVOLON",
    "state_code": "PD",
    "cab": "627802",
    "cap": "35030"
  },
  {
    "key": "12638",
    "ITA": "ROVOLON-BASTIA",
    "state_code": "PD",
    "cab": "627802",
    "cap": "35030"
  },
  {
    "key": "12639",
    "ITA": "ROZZANO",
    "state_code": "MI",
    "cab": "336800",
    "cap": "20089"
  },
  {
    "key": "12640",
    "ITA": "ROZZANO-QUINTO DE STAMPI",
    "state_code": "MI",
    "cab": "336800",
    "cap": "20089"
  },
  {
    "key": "12641",
    "ITA": "RUBANO-SARMEOLA",
    "state_code": "PD",
    "cab": "627901",
    "cap": "35030"
  },
  {
    "key": "12642",
    "ITA": "RUBANO-BOSCO",
    "state_code": "PD",
    "cab": "627901",
    "cap": "35030"
  },
  {
    "key": "12643",
    "ITA": "RUBANO-VILLAGUATTERA",
    "state_code": "PD",
    "cab": "627901",
    "cap": "35030"
  },
  {
    "key": "12644",
    "ITA": "RUBANO",
    "state_code": "PD",
    "cab": "627901",
    "cap": "35030"
  },
  {
    "key": "12645",
    "ITA": "RUBIANA",
    "state_code": "TO",
    "cab": "312801",
    "cap": "10040"
  },
  {
    "key": "12646",
    "ITA": "RUBIERA",
    "state_code": "RE",
    "cab": "664706",
    "cap": "42048"
  },
  {
    "key": "12647",
    "ITA": "RUDA",
    "state_code": "UD",
    "cab": "644807",
    "cap": "33050"
  },
  {
    "key": "12648",
    "ITA": "RUDA-PERTEOLE",
    "state_code": "UD",
    "cab": "644807",
    "cap": "33050"
  },
  {
    "key": "12649",
    "ITA": "RUDIANO",
    "state_code": "BS",
    "cab": "551507",
    "cap": "25030"
  },
  {
    "key": "12650",
    "ITA": "RUEGLIO",
    "state_code": "TO",
    "cab": "284356",
    "cap": "10010"
  },
  {
    "key": "12651",
    "ITA": "RUFFANO",
    "state_code": "LE",
    "cab": "799205",
    "cap": "73049"
  },
  {
    "key": "12652",
    "ITA": "RUFFANO-TORREPADULI",
    "state_code": "LE",
    "cab": "799205",
    "cap": "73040"
  },
  {
    "key": "12653",
    "ITA": "RUFFIA",
    "state_code": "CN",
    "cab": "919332",
    "cap": "12030"
  },
  {
    "key": "12654",
    "ITA": "RUFFRE'",
    "state_code": "TN",
    "cab": "359307",
    "cap": "38010"
  },
  {
    "key": "12655",
    "ITA": "RUFINA",
    "state_code": "FI",
    "cab": "380402",
    "cap": "50068"
  },
  {
    "key": "12656",
    "ITA": "RUFINA-CONTEA",
    "state_code": "FI",
    "cab": "380402",
    "cap": "50060"
  },
  {
    "key": "12657",
    "ITA": "RUFINA-POMINO",
    "state_code": "FI",
    "cab": "380402",
    "cap": "50068"
  },
  {
    "key": "12658",
    "ITA": "RUINAS",
    "state_code": "OR",
    "cab": "879700",
    "cap": "9085"
  },
  {
    "key": "12659",
    "ITA": "RUINO-POMETO",
    "state_code": "PV",
    "cab": "930826",
    "cap": "27040"
  },
  {
    "key": "12660",
    "ITA": "RUINO",
    "state_code": "PV",
    "cab": "930826",
    "cap": "27040"
  },
  {
    "key": "12661",
    "ITA": "RUINO-TORRE DEGLI ALBERI",
    "state_code": "PV",
    "cab": "930826",
    "cap": "27040"
  },
  {
    "key": "12662",
    "ITA": "RUMO",
    "state_code": "TN",
    "cab": "353805",
    "cap": "38020"
  },
  {
    "key": "12663",
    "ITA": "RUMO-MARCENA",
    "state_code": "TN",
    "cab": "353805",
    "cap": "38020"
  },
  {
    "key": "12664",
    "ITA": "RUMO-MOCENIGO",
    "state_code": "TN",
    "cab": "353805",
    "cap": "38020"
  },
  {
    "key": "12665",
    "ITA": "RUOTI",
    "state_code": "PZ",
    "cab": "422303",
    "cap": "85056"
  },
  {
    "key": "12666",
    "ITA": "RUSSI-GODO",
    "state_code": "RA",
    "cab": "675900",
    "cap": "48026"
  },
  {
    "key": "12667",
    "ITA": "RUSSI-SAN PANCRAZIO",
    "state_code": "RA",
    "cab": "675900",
    "cap": "48026"
  },
  {
    "key": "12668",
    "ITA": "RUSSI",
    "state_code": "RA",
    "cab": "675900",
    "cap": "48026"
  },
  {
    "key": "12669",
    "ITA": "RUTIGLIANO",
    "state_code": "BA",
    "cab": "416404",
    "cap": "70018"
  },
  {
    "key": "12670",
    "ITA": "RUTINO",
    "state_code": "SA",
    "cab": "952929",
    "cap": "84070"
  },
  {
    "key": "12671",
    "ITA": "RUVIANO",
    "state_code": "CE",
    "cab": "949578",
    "cap": "81010"
  },
  {
    "key": "12672",
    "ITA": "RUVIANO-ALVIGNANELLO",
    "state_code": "CE",
    "cab": "949578",
    "cap": "81010"
  },
  {
    "key": "12673",
    "ITA": "RUVO DEL MONTE",
    "state_code": "PZ",
    "cab": "422402",
    "cap": "85020"
  },
  {
    "key": "12674",
    "ITA": "RUVO DI PUGLIA",
    "state_code": "BA",
    "cab": "416503",
    "cap": "70037"
  },
  {
    "key": "12675",
    "ITA": "SABAUDIA",
    "state_code": "LT",
    "cab": "741108",
    "cap": "4016"
  },
  {
    "key": "12676",
    "ITA": "SABAUDIA-BORGO SAN DONATO",
    "state_code": "LT",
    "cab": "741108",
    "cap": "4010"
  },
  {
    "key": "12677",
    "ITA": "SABAUDIA-BORGO VODICE",
    "state_code": "LT",
    "cab": "741108",
    "cap": "4010"
  },
  {
    "key": "12678",
    "ITA": "SABAUDIA-BAIA D'ARGENTO",
    "state_code": "LT",
    "cab": "741108",
    "cap": "4016"
  },
  {
    "key": "12679",
    "ITA": "SABBIA",
    "state_code": "VC",
    "cab": "916312",
    "cap": "13020"
  },
  {
    "key": "12680",
    "ITA": "SABBIO CHIESE",
    "state_code": "BS",
    "cab": "551606",
    "cap": "25070"
  },
  {
    "key": "12681",
    "ITA": "SABBIONETA-VILLA PASQUALI",
    "state_code": "MN",
    "cab": "579102",
    "cap": "46018"
  },
  {
    "key": "12682",
    "ITA": "SABBIONETA-PONTETERRA",
    "state_code": "MN",
    "cab": "579102",
    "cap": "46018"
  },
  {
    "key": "12683",
    "ITA": "SABBIONETA-BREDA CISONI",
    "state_code": "MN",
    "cab": "579102",
    "cap": "46018"
  },
  {
    "key": "12684",
    "ITA": "SABBIONETA",
    "state_code": "MN",
    "cab": "579102",
    "cap": "46018"
  },
  {
    "key": "12685",
    "ITA": "SACCO",
    "state_code": "SA",
    "cab": "952937",
    "cap": "84070"
  },
  {
    "key": "12686",
    "ITA": "SACCOLONGO",
    "state_code": "PD",
    "cab": "858209",
    "cap": "35030"
  },
  {
    "key": "12687",
    "ITA": "SACILE-SCHIAVOI",
    "state_code": "PN",
    "cab": "649905",
    "cap": "33077"
  },
  {
    "key": "12688",
    "ITA": "SACILE-CAVOLANO",
    "state_code": "PN",
    "cab": "649905",
    "cap": "33077"
  },
  {
    "key": "12689",
    "ITA": "SACILE-SAN GIOVANNI DI LIVENZA",
    "state_code": "PN",
    "cab": "649905",
    "cap": "33077"
  },
  {
    "key": "12690",
    "ITA": "SACILE",
    "state_code": "PN",
    "cab": "649905",
    "cap": "33077"
  },
  {
    "key": "12691",
    "ITA": "SACROFANO",
    "state_code": "RM",
    "cab": "393900",
    "cap": "60"
  },
  {
    "key": "12692",
    "ITA": "SACROFANO-MONTE CAMINETTO",
    "state_code": "RM",
    "cab": "393900",
    "cap": "60"
  },
  {
    "key": "12693",
    "ITA": "SADALI",
    "state_code": "CA",
    "cab": "869305",
    "cap": "8030"
  },
  {
    "key": "12694",
    "ITA": "SAGAMA",
    "state_code": "OR",
    "cab": "869404",
    "cap": "8010"
  },
  {
    "key": "12695",
    "ITA": "SAGLIANO MICCA",
    "state_code": "BI",
    "cab": "447300",
    "cap": "13816"
  },
  {
    "key": "12696",
    "ITA": "SAGRADO-SAN MARTINO DEL CARSO",
    "state_code": "GO",
    "cab": "858407",
    "cap": "34078"
  },
  {
    "key": "12697",
    "ITA": "SAGRADO-POGGIO TERZARMATA",
    "state_code": "GO",
    "cab": "858407",
    "cap": "34078"
  },
  {
    "key": "12698",
    "ITA": "SAGRADO",
    "state_code": "GO",
    "cab": "858407",
    "cap": "34078"
  },
  {
    "key": "12699",
    "ITA": "SAGRON MIS",
    "state_code": "TN",
    "cab": "905075",
    "cap": "38050"
  },
  {
    "key": "12700",
    "ITA": "SAINT CHRISTOPHE",
    "state_code": "AO",
    "cab": "317305",
    "cap": "11020"
  },
  {
    "key": "12701",
    "ITA": "SAINT CHRISTOPHE-CHEF LIEU",
    "state_code": "AO",
    "cab": "317305",
    "cap": "11020"
  },
  {
    "key": "12702",
    "ITA": "SAINT DENIS",
    "state_code": "AO",
    "cab": "902601",
    "cap": "11023"
  },
  {
    "key": "12703",
    "ITA": "SAINT MARCEL",
    "state_code": "AO",
    "cab": "843904",
    "cap": "11020"
  },
  {
    "key": "12704",
    "ITA": "SAINT NICOLAS",
    "state_code": "AO",
    "cab": "902627",
    "cap": "11010"
  },
  {
    "key": "12705",
    "ITA": "SAINT OYEN",
    "state_code": "AO",
    "cab": "902635",
    "cap": "11014"
  },
  {
    "key": "12706",
    "ITA": "SAINT PIERRE",
    "state_code": "AO",
    "cab": "317503",
    "cap": "11010"
  },
  {
    "key": "12707",
    "ITA": "SAINT RHEMY EN BOSSES-BOSSES",
    "state_code": "AO",
    "cab": "902650",
    "cap": "11010"
  },
  {
    "key": "12708",
    "ITA": "SAINT RHEMY EN BOSSES",
    "state_code": "AO",
    "cab": "902650",
    "cap": "11010"
  },
  {
    "key": "12709",
    "ITA": "SAINT VINCENT",
    "state_code": "AO",
    "cab": "316703",
    "cap": "11027"
  },
  {
    "key": "12710",
    "ITA": "SAINT VINCENT-MORON",
    "state_code": "AO",
    "cab": "316703",
    "cap": "11027"
  },
  {
    "key": "12711",
    "ITA": "SALA BAGANZA-SAN VITALE",
    "state_code": "PR",
    "cab": "658906",
    "cap": "43038"
  },
  {
    "key": "12712",
    "ITA": "SALA BAGANZA",
    "state_code": "PR",
    "cab": "658906",
    "cap": "43038"
  },
  {
    "key": "12713",
    "ITA": "SALA BAGANZA-TALIGNANO",
    "state_code": "PR",
    "cab": "658906",
    "cap": "43030"
  },
  {
    "key": "12714",
    "ITA": "SALA BIELLESE",
    "state_code": "BI",
    "cab": "817304",
    "cap": "13884"
  },
  {
    "key": "12715",
    "ITA": "SALA BOLOGNESE-OSTERIA NUOVA",
    "state_code": "BO",
    "cab": "370304",
    "cap": "40010"
  },
  {
    "key": "12716",
    "ITA": "SALA BOLOGNESE",
    "state_code": "BO",
    "cab": "370304",
    "cap": "40010"
  },
  {
    "key": "12717",
    "ITA": "SALA BOLOGNESE-PADULLE",
    "state_code": "BO",
    "cab": "370304",
    "cap": "40010"
  },
  {
    "key": "12718",
    "ITA": "SALA COMACINA",
    "state_code": "CO",
    "cab": "738906",
    "cap": "22010"
  },
  {
    "key": "12719",
    "ITA": "SALA CONSILINA",
    "state_code": "SA",
    "cab": "764100",
    "cap": "84036"
  },
  {
    "key": "12720",
    "ITA": "SALA CONSILINA-TRINITA'",
    "state_code": "SA",
    "cab": "764100",
    "cap": "84036"
  },
  {
    "key": "12721",
    "ITA": "SALA MONFERRATO",
    "state_code": "AL",
    "cab": "921643",
    "cap": "15030"
  },
  {
    "key": "12722",
    "ITA": "SALANDRA",
    "state_code": "MT",
    "cab": "804302",
    "cap": "75017"
  },
  {
    "key": "12723",
    "ITA": "SALAPARUTA",
    "state_code": "TP",
    "cab": "819300",
    "cap": "91020"
  },
  {
    "key": "12724",
    "ITA": "SALARA",
    "state_code": "RO",
    "cab": "458802",
    "cap": "45030"
  },
  {
    "key": "12725",
    "ITA": "SALASCO",
    "state_code": "VC",
    "cab": "916338",
    "cap": "13040"
  },
  {
    "key": "12726",
    "ITA": "SALASSA",
    "state_code": "TO",
    "cab": "450205",
    "cap": "10080"
  },
  {
    "key": "12727",
    "ITA": "SALBERTRAND",
    "state_code": "TO",
    "cab": "190249",
    "cap": "10050"
  },
  {
    "key": "12728",
    "ITA": "SALCEDO",
    "state_code": "VI",
    "cab": "471300",
    "cap": "36040"
  },
  {
    "key": "12729",
    "ITA": "SALCEDO-LAVERDA",
    "state_code": "VI",
    "cab": "471300",
    "cap": "36040"
  },
  {
    "key": "12730",
    "ITA": "SALCITO",
    "state_code": "CB",
    "cab": "910885",
    "cap": "86026"
  },
  {
    "key": "12731",
    "ITA": "SALE",
    "state_code": "AL",
    "cab": "485706",
    "cap": "15045"
  },
  {
    "key": "12732",
    "ITA": "SALE DELLE LANGHE",
    "state_code": "CN",
    "cab": "919340",
    "cap": "12070"
  },
  {
    "key": "12733",
    "ITA": "SALE MARASINO",
    "state_code": "BS",
    "cab": "551705",
    "cap": "25057"
  },
  {
    "key": "12734",
    "ITA": "SALE SAN GIOVANNI",
    "state_code": "CN",
    "cab": "919357",
    "cap": "12070"
  },
  {
    "key": "12735",
    "ITA": "SALEMI-GORGAZZO",
    "state_code": "TP",
    "cab": "819409",
    "cap": "91018"
  },
  {
    "key": "12736",
    "ITA": "SALEMI-SAN CIRO",
    "state_code": "TP",
    "cab": "819409",
    "cap": "91018"
  },
  {
    "key": "12737",
    "ITA": "SALEMI-ULMI",
    "state_code": "TP",
    "cab": "819409",
    "cap": "91018"
  },
  {
    "key": "12738",
    "ITA": "SALEMI",
    "state_code": "TP",
    "cab": "819409",
    "cap": "91018"
  },
  {
    "key": "12739",
    "ITA": "SALEMI-FILCI",
    "state_code": "TP",
    "cab": "819409",
    "cap": "91018"
  },
  {
    "key": "12740",
    "ITA": "SALENTO",
    "state_code": "SA",
    "cab": "952945",
    "cap": "84070"
  },
  {
    "key": "12741",
    "ITA": "SALERANO CANAVESE",
    "state_code": "TO",
    "cab": "901413",
    "cap": "10010"
  },
  {
    "key": "12742",
    "ITA": "SALERANO SUL LAMBRO",
    "state_code": "LO",
    "cab": "336909",
    "cap": "26857"
  },
  {
    "key": "12743",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84127"
  },
  {
    "key": "12744",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84135"
  },
  {
    "key": "12745",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84134"
  },
  {
    "key": "12746",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84133"
  },
  {
    "key": "12747",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84132"
  },
  {
    "key": "12748",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84131"
  },
  {
    "key": "12749",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84121"
  },
  {
    "key": "12750",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84122"
  },
  {
    "key": "12751",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84123"
  },
  {
    "key": "12752",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84124"
  },
  {
    "key": "12753",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84125"
  },
  {
    "key": "12754",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84126"
  },
  {
    "key": "12755",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84128"
  },
  {
    "key": "12756",
    "ITA": "SALERNO",
    "state_code": "SA",
    "cab": "152009",
    "cap": "84129"
  },
  {
    "key": "12757",
    "ITA": "SALETTO",
    "state_code": "PD",
    "cab": "628008",
    "cap": "35046"
  },
  {
    "key": "12758",
    "ITA": "SALGAREDA-CAMPO DI PIETRA",
    "state_code": "TV",
    "cab": "622803",
    "cap": "31040"
  },
  {
    "key": "12759",
    "ITA": "SALGAREDA",
    "state_code": "TV",
    "cab": "622803",
    "cap": "31040"
  },
  {
    "key": "12760",
    "ITA": "SALI VERCELLESE",
    "state_code": "VC",
    "cab": "292466",
    "cap": "13040"
  },
  {
    "key": "12761",
    "ITA": "SALICE SALENTINO",
    "state_code": "LE",
    "cab": "799304",
    "cap": "73015"
  },
  {
    "key": "12762",
    "ITA": "SALICETO",
    "state_code": "CN",
    "cab": "467605",
    "cap": "12079"
  },
  {
    "key": "12763",
    "ITA": "SALISANO",
    "state_code": "RI",
    "cab": "947390",
    "cap": "2040"
  },
  {
    "key": "12764",
    "ITA": "SALIZZOLE",
    "state_code": "VR",
    "cab": "597401",
    "cap": "37056"
  },
  {
    "key": "12765",
    "ITA": "SALIZZOLE-BIONDE",
    "state_code": "VR",
    "cab": "597401",
    "cap": "37056"
  },
  {
    "key": "12766",
    "ITA": "SALIZZOLE-CROSAROL",
    "state_code": "VR",
    "cab": "597401",
    "cap": "37056"
  },
  {
    "key": "12767",
    "ITA": "SALIZZOLE-ENGAZZA'",
    "state_code": "VR",
    "cab": "597401",
    "cap": "37056"
  },
  {
    "key": "12768",
    "ITA": "SALIZZOLE-VALMORSEL",
    "state_code": "VR",
    "cab": "597401",
    "cap": "37056"
  },
  {
    "key": "12769",
    "ITA": "SALLE",
    "state_code": "PE",
    "cab": "954123",
    "cap": "65020"
  },
  {
    "key": "12770",
    "ITA": "SALMOUR",
    "state_code": "CN",
    "cab": "470807",
    "cap": "12040"
  },
  {
    "key": "12771",
    "ITA": "SALO'-BARBARANO",
    "state_code": "BS",
    "cab": "551804",
    "cap": "25087"
  },
  {
    "key": "12772",
    "ITA": "SALO'-CAMPOVERDE",
    "state_code": "BS",
    "cab": "551804",
    "cap": "25087"
  },
  {
    "key": "12773",
    "ITA": "SALO'",
    "state_code": "BS",
    "cab": "551804",
    "cap": "25087"
  },
  {
    "key": "12774",
    "ITA": "SALORNO",
    "state_code": "BZ",
    "cab": "587808",
    "cap": "39040"
  },
  {
    "key": "12775",
    "ITA": "SALORNO-CAURIA",
    "state_code": "BZ",
    "cab": "587808",
    "cap": "39040"
  },
  {
    "key": "12776",
    "ITA": "SALSOMAGGIORE TERME-SCIPIONE",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12777",
    "ITA": "SALSOMAGGIORE TERME-BAGNI DI TABIANO",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12778",
    "ITA": "SALSOMAGGIORE TERME-CANGELASIO",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12779",
    "ITA": "SALSOMAGGIORE TERME-TABIANO",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12780",
    "ITA": "SALSOMAGGIORE TERME-BARGONE",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12781",
    "ITA": "SALSOMAGGIORE TERME-CAMPORE",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12782",
    "ITA": "SALSOMAGGIORE TERME-PIE' DI VIA",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12783",
    "ITA": "SALSOMAGGIORE TERME",
    "state_code": "PR",
    "cab": "659003",
    "cap": "43039"
  },
  {
    "key": "12784",
    "ITA": "SALTARA",
    "state_code": "PU",
    "cab": "685404",
    "cap": "61030"
  },
  {
    "key": "12785",
    "ITA": "SALTARA-CALCINELLI",
    "state_code": "PU",
    "cab": "685404",
    "cap": "61030"
  },
  {
    "key": "12786",
    "ITA": "SALTRIO",
    "state_code": "VA",
    "cab": "507509",
    "cap": "21050"
  },
  {
    "key": "12787",
    "ITA": "SALUDECIO-SANTA MARIA DEL MONTE",
    "state_code": "RN",
    "cab": "679902",
    "cap": "47835"
  },
  {
    "key": "12788",
    "ITA": "SALUDECIO",
    "state_code": "RN",
    "cab": "679902",
    "cap": "47835"
  },
  {
    "key": "12789",
    "ITA": "SALUGGIA",
    "state_code": "VC",
    "cab": "447409",
    "cap": "13040"
  },
  {
    "key": "12790",
    "ITA": "SALUGGIA-SANT'ANTONINO",
    "state_code": "VC",
    "cab": "447409",
    "cap": "13040"
  },
  {
    "key": "12791",
    "ITA": "SALUSSOLA",
    "state_code": "BI",
    "cab": "708404",
    "cap": "13885"
  },
  {
    "key": "12792",
    "ITA": "SALUSSOLA-VIGELLIO",
    "state_code": "BI",
    "cab": "708404",
    "cap": "13885"
  },
  {
    "key": "12793",
    "ITA": "SALUSSOLA-BRIANCO",
    "state_code": "BI",
    "cab": "708404",
    "cap": "13885"
  },
  {
    "key": "12794",
    "ITA": "SALUZZO-CERVIGNASCO",
    "state_code": "CN",
    "cab": "467704",
    "cap": "12037"
  },
  {
    "key": "12795",
    "ITA": "SALUZZO-SAN LAZZARO",
    "state_code": "CN",
    "cab": "467704",
    "cap": "12037"
  },
  {
    "key": "12796",
    "ITA": "SALUZZO",
    "state_code": "CN",
    "cab": "467704",
    "cap": "12037"
  },
  {
    "key": "12797",
    "ITA": "SALUZZO-VIA DEI ROMANI",
    "state_code": "CN",
    "cab": "467704",
    "cap": "12037"
  },
  {
    "key": "12798",
    "ITA": "SALVE",
    "state_code": "LE",
    "cab": "799403",
    "cap": "73050"
  },
  {
    "key": "12799",
    "ITA": "SALVE-RUGGIANO",
    "state_code": "LE",
    "cab": "799403",
    "cap": "73050"
  },
  {
    "key": "12800",
    "ITA": "SALVIROLA",
    "state_code": "CR",
    "cab": "564708",
    "cap": "26010"
  },
  {
    "key": "12801",
    "ITA": "SALVITELLE",
    "state_code": "SA",
    "cab": "952952",
    "cap": "84020"
  },
  {
    "key": "12802",
    "ITA": "SALZA DI PINEROLO",
    "state_code": "TO",
    "cab": "901421",
    "cap": "10060"
  },
  {
    "key": "12803",
    "ITA": "SALZA IRPINA",
    "state_code": "AV",
    "cab": "951525",
    "cap": "83050"
  },
  {
    "key": "12804",
    "ITA": "SALZANO-ROBEGANO",
    "state_code": "VE",
    "cab": "362707",
    "cap": "30030"
  },
  {
    "key": "12805",
    "ITA": "SALZANO",
    "state_code": "VE",
    "cab": "362707",
    "cap": "30030"
  },
  {
    "key": "12806",
    "ITA": "SAMARATE-VERGHERA",
    "state_code": "VA",
    "cab": "505107",
    "cap": "21017"
  },
  {
    "key": "12807",
    "ITA": "SAMARATE-CASCINA COSTA",
    "state_code": "VA",
    "cab": "505107",
    "cap": "21017"
  },
  {
    "key": "12808",
    "ITA": "SAMARATE-CASCINA ELISA",
    "state_code": "VA",
    "cab": "505107",
    "cap": "21017"
  },
  {
    "key": "12809",
    "ITA": "SAMARATE",
    "state_code": "VA",
    "cab": "505107",
    "cap": "21017"
  },
  {
    "key": "12810",
    "ITA": "SAMASSI",
    "state_code": "SU",
    "cab": "861104",
    "cap": "9030"
  },
  {
    "key": "12811",
    "ITA": "SAMATZAI",
    "state_code": "CA",
    "cab": "861203",
    "cap": "9020"
  },
  {
    "key": "12812",
    "ITA": "SAMBUCA DI SICILIA",
    "state_code": "AG",
    "cab": "831008",
    "cap": "92017"
  },
  {
    "key": "12813",
    "ITA": "SAMBUCA PISTOIESE-TORRI",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12814",
    "ITA": "SAMBUCA PISTOIESE-TREPPIO",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12815",
    "ITA": "SAMBUCA PISTOIESE",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12816",
    "ITA": "SAMBUCA PISTOIESE-CASTELLO",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12817",
    "ITA": "SAMBUCA PISTOIESE-COLLINA",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12818",
    "ITA": "SAMBUCA PISTOIESE-FRASSIGNONI",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12819",
    "ITA": "SAMBUCA PISTOIESE-PAVANA",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12820",
    "ITA": "SAMBUCA PISTOIESE-SAN PELLEGRINO",
    "state_code": "PT",
    "cab": "705103",
    "cap": "51020"
  },
  {
    "key": "12821",
    "ITA": "SAMBUCI",
    "state_code": "RM",
    "cab": "908350",
    "cap": "20"
  },
  {
    "key": "12822",
    "ITA": "SAMBUCO",
    "state_code": "CN",
    "cab": "919373",
    "cap": "12010"
  },
  {
    "key": "12823",
    "ITA": "SAMMICHELE DI BARI",
    "state_code": "BA",
    "cab": "416602",
    "cap": "70010"
  },
  {
    "key": "12824",
    "ITA": "SAMO",
    "state_code": "RC",
    "cab": "960310",
    "cap": "89030"
  },
  {
    "key": "12825",
    "ITA": "SAMOLACO",
    "state_code": "SO",
    "cab": "820209",
    "cap": "23027"
  },
  {
    "key": "12826",
    "ITA": "SAMOLACO-ERA",
    "state_code": "SO",
    "cab": "820209",
    "cap": "23027"
  },
  {
    "key": "12827",
    "ITA": "SAMOLACO-SOMAGGIA",
    "state_code": "SO",
    "cab": "820209",
    "cap": "23027"
  },
  {
    "key": "12828",
    "ITA": "SAMOLACO-SAN PIETRO",
    "state_code": "SO",
    "cab": "820209",
    "cap": "23027"
  },
  {
    "key": "12829",
    "ITA": "SAMONE",
    "state_code": "TN",
    "cab": "353904",
    "cap": "38059"
  },
  {
    "key": "12830",
    "ITA": "SAMONE",
    "state_code": "TO",
    "cab": "181115",
    "cap": "10010"
  },
  {
    "key": "12831",
    "ITA": "SAMPEYRE",
    "state_code": "CN",
    "cab": "467803",
    "cap": "12020"
  },
  {
    "key": "12832",
    "ITA": "SAMPEYRE-VILLAR",
    "state_code": "CN",
    "cab": "467803",
    "cap": "12020"
  },
  {
    "key": "12833",
    "ITA": "SAMPEYRE-RORE",
    "state_code": "CN",
    "cab": "467803",
    "cap": "12020"
  },
  {
    "key": "12834",
    "ITA": "SAMUGHEO",
    "state_code": "OR",
    "cab": "879809",
    "cap": "9086"
  },
  {
    "key": "12835",
    "ITA": "SAN BARTOLOMEO AL MARE",
    "state_code": "IM",
    "cab": "490607",
    "cap": "18016"
  },
  {
    "key": "12836",
    "ITA": "SAN BARTOLOMEO IN GALDO",
    "state_code": "BN",
    "cab": "754309",
    "cap": "82028"
  },
  {
    "key": "12837",
    "ITA": "SAN BARTOLOMEO VAL CAVARGNA",
    "state_code": "CO",
    "cab": "519207",
    "cap": "22010"
  },
  {
    "key": "12838",
    "ITA": "SAN BASILE",
    "state_code": "CS",
    "cab": "959239",
    "cap": "87010"
  },
  {
    "key": "12839",
    "ITA": "SAN BASILIO",
    "state_code": "CA",
    "cab": "861302",
    "cap": "9040"
  },
  {
    "key": "12840",
    "ITA": "SAN BASSANO",
    "state_code": "CR",
    "cab": "571109",
    "cap": "26020"
  },
  {
    "key": "12841",
    "ITA": "SAN BELLINO",
    "state_code": "RO",
    "cab": "634402",
    "cap": "45020"
  },
  {
    "key": "12842",
    "ITA": "SAN BENEDETTO BELBO",
    "state_code": "CN",
    "cab": "919381",
    "cap": "12050"
  },
  {
    "key": "12843",
    "ITA": "SAN BENEDETTO DEI MARSI",
    "state_code": "AQ",
    "cab": "407601",
    "cap": "67058"
  },
  {
    "key": "12844",
    "ITA": "SAN BENEDETTO DEL TRONTO",
    "state_code": "AP",
    "cab": "244004",
    "cap": "63039"
  },
  {
    "key": "12845",
    "ITA": "SAN BENEDETTO DEL TRONTO-PORTO D'ASCOLI",
    "state_code": "AP",
    "cab": "244004",
    "cap": "63039"
  },
  {
    "key": "12846",
    "ITA": "SAN BENEDETTO IN PERILLIS",
    "state_code": "AQ",
    "cab": "909903",
    "cap": "67020"
  },
  {
    "key": "12847",
    "ITA": "SAN BENEDETTO PO-SAN SIRO",
    "state_code": "MN",
    "cab": "579201",
    "cap": "46027"
  },
  {
    "key": "12848",
    "ITA": "SAN BENEDETTO PO-PORTIOLO",
    "state_code": "MN",
    "cab": "579201",
    "cap": "46027"
  },
  {
    "key": "12849",
    "ITA": "SAN BENEDETTO PO-MIRASOLE",
    "state_code": "MN",
    "cab": "579201",
    "cap": "46027"
  },
  {
    "key": "12850",
    "ITA": "SAN BENEDETTO PO",
    "state_code": "MN",
    "cab": "579201",
    "cap": "46027"
  },
  {
    "key": "12851",
    "ITA": "SAN BENEDETTO ULLANO",
    "state_code": "CS",
    "cab": "959247",
    "cap": "87040"
  },
  {
    "key": "12852",
    "ITA": "SAN BENEDETTO VAL DI SAMBRO-CASTEL DELL'ALPI",
    "state_code": "BO",
    "cab": "370403",
    "cap": "40048"
  },
  {
    "key": "12853",
    "ITA": "SAN BENEDETTO VAL DI SAMBRO-PIANO DEL VOGLIO",
    "state_code": "BO",
    "cab": "370403",
    "cap": "40048"
  },
  {
    "key": "12854",
    "ITA": "SAN BENEDETTO VAL DI SAMBRO-MADONNA DEI FORNELLI",
    "state_code": "BO",
    "cab": "370403",
    "cap": "40048"
  },
  {
    "key": "12855",
    "ITA": "SAN BENEDETTO VAL DI SAMBRO",
    "state_code": "BO",
    "cab": "370403",
    "cap": "40048"
  },
  {
    "key": "12856",
    "ITA": "SAN BENIGNO CANAVESE",
    "state_code": "TO",
    "cab": "309005",
    "cap": "10080"
  },
  {
    "key": "12857",
    "ITA": "SAN BERNARDINO VERBANO-BIENO",
    "state_code": "VB",
    "cab": "917674",
    "cap": "28804"
  },
  {
    "key": "12858",
    "ITA": "SAN BERNARDINO VERBANO",
    "state_code": "VB",
    "cab": "917674",
    "cap": "28804"
  },
  {
    "key": "12859",
    "ITA": "SAN BIAGIO DELLA CIMA",
    "state_code": "IM",
    "cab": "922609",
    "cap": "18036"
  },
  {
    "key": "12860",
    "ITA": "SAN BIAGIO DI CALLALTA-SPERCENIGO",
    "state_code": "TV",
    "cab": "620203",
    "cap": "31048"
  },
  {
    "key": "12861",
    "ITA": "SAN BIAGIO DI CALLALTA-OLMI",
    "state_code": "TV",
    "cab": "620203",
    "cap": "31048"
  },
  {
    "key": "12862",
    "ITA": "SAN BIAGIO DI CALLALTA-FAGARE'",
    "state_code": "TV",
    "cab": "620203",
    "cap": "31042"
  },
  {
    "key": "12863",
    "ITA": "SAN BIAGIO DI CALLALTA-CAVRIE",
    "state_code": "TV",
    "cab": "620203",
    "cap": "31048"
  },
  {
    "key": "12864",
    "ITA": "SAN BIAGIO DI CALLALTA",
    "state_code": "TV",
    "cab": "620203",
    "cap": "31048"
  },
  {
    "key": "12865",
    "ITA": "SAN BIAGIO PLATANI",
    "state_code": "AG",
    "cab": "831107",
    "cap": "92020"
  },
  {
    "key": "12866",
    "ITA": "SAN BIAGIO SARACINISCO",
    "state_code": "FR",
    "cab": "948489",
    "cap": "3040"
  },
  {
    "key": "12867",
    "ITA": "SAN BIASE",
    "state_code": "CB",
    "cab": "910893",
    "cap": "86020"
  },
  {
    "key": "12868",
    "ITA": "SAN BONIFACIO",
    "state_code": "VR",
    "cab": "597500",
    "cap": "37047"
  },
  {
    "key": "12869",
    "ITA": "SAN BONIFACIO-LOCARA",
    "state_code": "VR",
    "cab": "597500",
    "cap": "37047"
  },
  {
    "key": "12870",
    "ITA": "SAN BONIFACIO-VILLABELLA",
    "state_code": "VR",
    "cab": "597500",
    "cap": "37047"
  },
  {
    "key": "12871",
    "ITA": "SAN BONIFACIO-PROVA",
    "state_code": "VR",
    "cab": "597500",
    "cap": "37047"
  },
  {
    "key": "12872",
    "ITA": "SAN BUONO",
    "state_code": "CH",
    "cab": "954982",
    "cap": "66050"
  },
  {
    "key": "12873",
    "ITA": "SAN CALOGERO-CALIMERA CALABRA",
    "state_code": "VV",
    "cab": "427104",
    "cap": "89842"
  },
  {
    "key": "12874",
    "ITA": "SAN CALOGERO",
    "state_code": "VV",
    "cab": "427104",
    "cap": "89842"
  },
  {
    "key": "12875",
    "ITA": "SAN CANDIDO",
    "state_code": "BZ",
    "cab": "587907",
    "cap": "39038"
  },
  {
    "key": "12876",
    "ITA": "SAN CANZIAN D'ISONZO-PIERIS",
    "state_code": "GO",
    "cab": "646406",
    "cap": "34075"
  },
  {
    "key": "12877",
    "ITA": "SAN CANZIAN D'ISONZO-ISOLA MOROSINI",
    "state_code": "GO",
    "cab": "646406",
    "cap": "34075"
  },
  {
    "key": "12878",
    "ITA": "SAN CANZIAN D'ISONZO-BEGLIANO",
    "state_code": "GO",
    "cab": "646406",
    "cap": "34075"
  },
  {
    "key": "12879",
    "ITA": "SAN CANZIAN D'ISONZO",
    "state_code": "GO",
    "cab": "646406",
    "cap": "34075"
  },
  {
    "key": "12880",
    "ITA": "SAN CARLO CANAVESE",
    "state_code": "TO",
    "cab": "313304",
    "cap": "10070"
  },
  {
    "key": "12881",
    "ITA": "SAN CASCIANO DEI BAGNI",
    "state_code": "SI",
    "cab": "719906",
    "cap": "53040"
  },
  {
    "key": "12882",
    "ITA": "SAN CASCIANO DEI BAGNI-PALAZZONE",
    "state_code": "SI",
    "cab": "719906",
    "cap": "53040"
  },
  {
    "key": "12883",
    "ITA": "SAN CASCIANO DEI BAGNI-CELLE SUL RIGO",
    "state_code": "SI",
    "cab": "719906",
    "cap": "53040"
  },
  {
    "key": "12884",
    "ITA": "SAN CASCIANO IN VAL DI PESA-SPEDALETTO",
    "state_code": "FI",
    "cab": "380501",
    "cap": "50026"
  },
  {
    "key": "12885",
    "ITA": "SAN CASCIANO IN VAL DI PESA-SAN PANCRAZIO",
    "state_code": "FI",
    "cab": "380501",
    "cap": "50020"
  },
  {
    "key": "12886",
    "ITA": "SAN CASCIANO IN VAL DI PESA-ROMOLA",
    "state_code": "FI",
    "cab": "380501",
    "cap": "50020"
  },
  {
    "key": "12887",
    "ITA": "SAN CASCIANO IN VAL DI PESA-MONTEFIRIDOLFI",
    "state_code": "FI",
    "cab": "380501",
    "cap": "50020"
  },
  {
    "key": "12888",
    "ITA": "SAN CASCIANO IN VAL DI PESA-MERCATALE",
    "state_code": "FI",
    "cab": "380501",
    "cap": "50020"
  },
  {
    "key": "12889",
    "ITA": "SAN CASCIANO IN VAL DI PESA-CERBAIA",
    "state_code": "FI",
    "cab": "380501",
    "cap": "50020"
  },
  {
    "key": "12890",
    "ITA": "SAN CASCIANO IN VAL DI PESA",
    "state_code": "FI",
    "cab": "380501",
    "cap": "50026"
  },
  {
    "key": "12891",
    "ITA": "SAN CASSIANO",
    "state_code": "LE",
    "cab": "957761",
    "cap": "73020"
  },
  {
    "key": "12892",
    "ITA": "SAN CATALDO",
    "state_code": "CL",
    "cab": "834200",
    "cap": "93017"
  },
  {
    "key": "12893",
    "ITA": "SAN CESAREO",
    "state_code": "RM",
    "cab": "412205",
    "cap": "30"
  },
  {
    "key": "12894",
    "ITA": "SAN CESARIO DI LECCE",
    "state_code": "LE",
    "cab": "799502",
    "cap": "73016"
  },
  {
    "key": "12895",
    "ITA": "SAN CESARIO SUL PANARO",
    "state_code": "MO",
    "cab": "669705",
    "cap": "41018"
  },
  {
    "key": "12896",
    "ITA": "SAN CHIRICO NUOVO",
    "state_code": "PZ",
    "cab": "912105",
    "cap": "85010"
  },
  {
    "key": "12897",
    "ITA": "SAN CHIRICO RAPARO",
    "state_code": "PZ",
    "cab": "912113",
    "cap": "85030"
  },
  {
    "key": "12898",
    "ITA": "SAN CIPIRELLO",
    "state_code": "PA",
    "cab": "435800",
    "cap": "90040"
  },
  {
    "key": "12899",
    "ITA": "SAN CIPRIANO D'AVERSA",
    "state_code": "CE",
    "cab": "752303",
    "cap": "81036"
  },
  {
    "key": "12900",
    "ITA": "SAN CIPRIANO PICENTINO-PEZZANO",
    "state_code": "SA",
    "cab": "764209",
    "cap": "84099"
  },
  {
    "key": "12901",
    "ITA": "SAN CIPRIANO PICENTINO-FILETTA",
    "state_code": "SA",
    "cab": "764209",
    "cap": "84099"
  },
  {
    "key": "12902",
    "ITA": "SAN CIPRIANO PICENTINO-CAMPIGLIANO",
    "state_code": "SA",
    "cab": "764209",
    "cap": "84099"
  },
  {
    "key": "12903",
    "ITA": "SAN CIPRIANO PICENTINO-VIGNALE",
    "state_code": "SA",
    "cab": "764209",
    "cap": "84099"
  },
  {
    "key": "12904",
    "ITA": "SAN CIPRIANO PICENTINO",
    "state_code": "SA",
    "cab": "764209",
    "cap": "84099"
  },
  {
    "key": "12905",
    "ITA": "SAN CIPRIANO PO",
    "state_code": "PV",
    "cab": "930834",
    "cap": "27043"
  },
  {
    "key": "12906",
    "ITA": "SAN CLEMENTE-SANT'ANDREA IN CASALE",
    "state_code": "RN",
    "cab": "681106",
    "cap": "47832"
  },
  {
    "key": "12907",
    "ITA": "SAN CLEMENTE",
    "state_code": "RN",
    "cab": "681106",
    "cap": "47832"
  },
  {
    "key": "12908",
    "ITA": "SAN COLOMBANO AL LAMBRO",
    "state_code": "MI",
    "cab": "337006",
    "cap": "20078"
  },
  {
    "key": "12909",
    "ITA": "SAN COLOMBANO BELMONTE",
    "state_code": "TO",
    "cab": "901454",
    "cap": "10080"
  },
  {
    "key": "12910",
    "ITA": "SAN COLOMBANO CERTENOLI",
    "state_code": "GE",
    "cab": "321703",
    "cap": "16040"
  },
  {
    "key": "12911",
    "ITA": "SAN COLOMBANO CERTENOLI-CALVARI",
    "state_code": "GE",
    "cab": "321703",
    "cap": "16040"
  },
  {
    "key": "12912",
    "ITA": "SAN COLOMBANO CERTENOLI-CELESIA",
    "state_code": "GE",
    "cab": "321703",
    "cap": "16040"
  },
  {
    "key": "12913",
    "ITA": "SAN CONO",
    "state_code": "CT",
    "cab": "841809",
    "cap": "95040"
  },
  {
    "key": "12914",
    "ITA": "SAN COSMO ALBANESE",
    "state_code": "CS",
    "cab": "959254",
    "cap": "87060"
  },
  {
    "key": "12915",
    "ITA": "SAN COSTANTINO ALBANESE",
    "state_code": "PZ",
    "cab": "912121",
    "cap": "85030"
  },
  {
    "key": "12916",
    "ITA": "SAN COSTANTINO CALABRO",
    "state_code": "VV",
    "cab": "191148",
    "cap": "89851"
  },
  {
    "key": "12917",
    "ITA": "SAN COSTANZO-CERASA",
    "state_code": "PU",
    "cab": "685503",
    "cap": "61039"
  },
  {
    "key": "12918",
    "ITA": "SAN COSTANZO",
    "state_code": "PU",
    "cab": "685503",
    "cap": "61039"
  },
  {
    "key": "12919",
    "ITA": "SAN CRISTOFORO",
    "state_code": "AL",
    "cab": "921650",
    "cap": "15060"
  },
  {
    "key": "12920",
    "ITA": "SAN DAMIANO AL COLLE",
    "state_code": "PV",
    "cab": "930842",
    "cap": "27040"
  },
  {
    "key": "12921",
    "ITA": "SAN DAMIANO D'ASTI-SAN PIETRO",
    "state_code": "AT",
    "cab": "476705",
    "cap": "14015"
  },
  {
    "key": "12922",
    "ITA": "SAN DAMIANO D'ASTI",
    "state_code": "AT",
    "cab": "476705",
    "cap": "14015"
  },
  {
    "key": "12923",
    "ITA": "SAN DAMIANO MACRA-LOTTULO",
    "state_code": "CN",
    "cab": "459305",
    "cap": "12029"
  },
  {
    "key": "12924",
    "ITA": "SAN DAMIANO MACRA",
    "state_code": "CN",
    "cab": "459305",
    "cap": "12029"
  },
  {
    "key": "12925",
    "ITA": "SAN DANIELE DEL FRIULI",
    "state_code": "UD",
    "cab": "641902",
    "cap": "33038"
  },
  {
    "key": "12926",
    "ITA": "SAN DANIELE DEL FRIULI-VILLANOVA",
    "state_code": "UD",
    "cab": "641902",
    "cap": "33038"
  },
  {
    "key": "12927",
    "ITA": "SAN DANIELE PO",
    "state_code": "CR",
    "cab": "571208",
    "cap": "26046"
  },
  {
    "key": "12928",
    "ITA": "SAN DEMETRIO CORONE-MACCHIA ALBANESE",
    "state_code": "CS",
    "cab": "809400",
    "cap": "87069"
  },
  {
    "key": "12929",
    "ITA": "SAN DEMETRIO CORONE",
    "state_code": "CS",
    "cab": "809400",
    "cap": "87069"
  },
  {
    "key": "12930",
    "ITA": "SAN DEMETRIO NE' VESTINI",
    "state_code": "AQ",
    "cab": "407700",
    "cap": "67028"
  },
  {
    "key": "12931",
    "ITA": "SAN DIDERO",
    "state_code": "TO",
    "cab": "901462",
    "cap": "10050"
  },
  {
    "key": "12932",
    "ITA": "SAN DONA' DI PIAVE-PASSARELLA",
    "state_code": "VE",
    "cab": "362806",
    "cap": "30027"
  },
  {
    "key": "12933",
    "ITA": "SAN DONA' DI PIAVE-CALVECCHIA",
    "state_code": "VE",
    "cab": "362806",
    "cap": "30027"
  },
  {
    "key": "12934",
    "ITA": "SAN DONA' DI PIAVE",
    "state_code": "VE",
    "cab": "362806",
    "cap": "30027"
  },
  {
    "key": "12935",
    "ITA": "SAN DONACI",
    "state_code": "BR",
    "cab": "792408",
    "cap": "72025"
  },
  {
    "key": "12936",
    "ITA": "SAN DONATO DI LECCE-GALUGNANO",
    "state_code": "LE",
    "cab": "799601",
    "cap": "73010"
  },
  {
    "key": "12937",
    "ITA": "SAN DONATO DI LECCE",
    "state_code": "LE",
    "cab": "799601",
    "cap": "73010"
  },
  {
    "key": "12938",
    "ITA": "SAN DONATO DI NINEA-POLICASTRELLO",
    "state_code": "CS",
    "cab": "959262",
    "cap": "87010"
  },
  {
    "key": "12939",
    "ITA": "SAN DONATO DI NINEA",
    "state_code": "CS",
    "cab": "959262",
    "cap": "87010"
  },
  {
    "key": "12940",
    "ITA": "SAN DONATO MILANESE-SORIGHERIO",
    "state_code": "MI",
    "cab": "337105",
    "cap": "20097"
  },
  {
    "key": "12941",
    "ITA": "SAN DONATO MILANESE",
    "state_code": "MI",
    "cab": "337105",
    "cap": "20097"
  },
  {
    "key": "12942",
    "ITA": "SAN DONATO MILANESE-POASCO",
    "state_code": "MI",
    "cab": "337105",
    "cap": "20097"
  },
  {
    "key": "12943",
    "ITA": "SAN DONATO MILANESE-METANOPOLI",
    "state_code": "MI",
    "cab": "337105",
    "cap": "20097"
  },
  {
    "key": "12944",
    "ITA": "SAN DONATO VAL DI COMINO",
    "state_code": "FR",
    "cab": "746701",
    "cap": "3046"
  },
  {
    "key": "12945",
    "ITA": "SAN DORLIGO DELLA VALLE-BAGNOLI DELLA ROSANDRA",
    "state_code": "TS",
    "cab": "364901",
    "cap": "34018"
  },
  {
    "key": "12946",
    "ITA": "SAN DORLIGO DELLA VALLE",
    "state_code": "TS",
    "cab": "364901",
    "cap": "34018"
  },
  {
    "key": "12947",
    "ITA": "SAN DORLIGO DELLA VALLE-SANT'ANTONIO IN BOSCO",
    "state_code": "TS",
    "cab": "364901",
    "cap": "34018"
  },
  {
    "key": "12948",
    "ITA": "SAN DORLIGO DELLA VALLE-SAN GIUSEPPE DELLA CHIUSA",
    "state_code": "TS",
    "cab": "364901",
    "cap": "34018"
  },
  {
    "key": "12949",
    "ITA": "SAN DORLIGO DELLA VALLE-DOMIO",
    "state_code": "TS",
    "cab": "364901",
    "cap": "34018"
  },
  {
    "key": "12950",
    "ITA": "SAN FEDELE INTELVI",
    "state_code": "CO",
    "cab": "517508",
    "cap": "22028"
  },
  {
    "key": "12951",
    "ITA": "SAN FELE",
    "state_code": "PZ",
    "cab": "422501",
    "cap": "85020"
  },
  {
    "key": "12952",
    "ITA": "SAN FELICE A CANCELLO-CAVE DI SAN FELICE",
    "state_code": "CE",
    "cab": "750000",
    "cap": "81027"
  },
  {
    "key": "12953",
    "ITA": "SAN FELICE A CANCELLO",
    "state_code": "CE",
    "cab": "750000",
    "cap": "81027"
  },
  {
    "key": "12954",
    "ITA": "SAN FELICE A CANCELLO-CANCELLO DI FERROVIA",
    "state_code": "CE",
    "cab": "750000",
    "cap": "81027"
  },
  {
    "key": "12955",
    "ITA": "SAN FELICE A CANCELLO-POLVICA",
    "state_code": "CE",
    "cab": "750000",
    "cap": "81027"
  },
  {
    "key": "12956",
    "ITA": "SAN FELICE A CANCELLO-SAN MARCO TROTTI",
    "state_code": "CE",
    "cab": "750000",
    "cap": "81027"
  },
  {
    "key": "12957",
    "ITA": "SAN FELICE CIRCEO-BORGO MONTENERO",
    "state_code": "LT",
    "cab": "741207",
    "cap": "4017"
  },
  {
    "key": "12958",
    "ITA": "SAN FELICE CIRCEO",
    "state_code": "LT",
    "cab": "741207",
    "cap": "4017"
  },
  {
    "key": "12959",
    "ITA": "SAN FELICE DEL BENACO",
    "state_code": "BS",
    "cab": "551903",
    "cap": "25010"
  },
  {
    "key": "12960",
    "ITA": "SAN FELICE DEL MOLISE",
    "state_code": "CB",
    "cab": "910901",
    "cap": "86030"
  },
  {
    "key": "12961",
    "ITA": "SAN FELICE SUL PANARO-RIVARA",
    "state_code": "MO",
    "cab": "669804",
    "cap": "41038"
  },
  {
    "key": "12962",
    "ITA": "SAN FELICE SUL PANARO",
    "state_code": "MO",
    "cab": "669804",
    "cap": "41038"
  },
  {
    "key": "12963",
    "ITA": "SAN FELICE SUL PANARO-DOGARO",
    "state_code": "MO",
    "cab": "669804",
    "cap": "41038"
  },
  {
    "key": "12964",
    "ITA": "SAN FELICE SUL PANARO-CONFINE",
    "state_code": "MO",
    "cab": "669804",
    "cap": "41038"
  },
  {
    "key": "12965",
    "ITA": "SAN FELICE SUL PANARO-SAN BIAGIO",
    "state_code": "MO",
    "cab": "669804",
    "cap": "41038"
  },
  {
    "key": "12966",
    "ITA": "SAN FELICE SUL PANARO-PAVIGNANE",
    "state_code": "MO",
    "cab": "669804",
    "cap": "41038"
  },
  {
    "key": "12967",
    "ITA": "SAN FERDINANDO",
    "state_code": "RC",
    "cab": "816306",
    "cap": "89026"
  },
  {
    "key": "12968",
    "ITA": "SAN FERDINANDO DI PUGLIA",
    "state_code": "BT",
    "cab": "785808",
    "cap": "71046"
  },
  {
    "key": "12969",
    "ITA": "SAN FERMO DELLA BATTAGLIA",
    "state_code": "CO",
    "cab": "517607",
    "cap": "22020"
  },
  {
    "key": "12970",
    "ITA": "SAN FILI",
    "state_code": "CS",
    "cab": "809509",
    "cap": "87037"
  },
  {
    "key": "12971",
    "ITA": "SAN FILI-BUCITA",
    "state_code": "CS",
    "cab": "809509",
    "cap": "87037"
  },
  {
    "key": "12972",
    "ITA": "SAN FILIPPO DEL MELA-CATTAFI",
    "state_code": "ME",
    "cab": "824409",
    "cap": "98044"
  },
  {
    "key": "12973",
    "ITA": "SAN FILIPPO DEL MELA-OLIVARELLA",
    "state_code": "ME",
    "cab": "824409",
    "cap": "98044"
  },
  {
    "key": "12974",
    "ITA": "SAN FILIPPO DEL MELA-CORRIOLO",
    "state_code": "ME",
    "cab": "824409",
    "cap": "98044"
  },
  {
    "key": "12975",
    "ITA": "SAN FILIPPO DEL MELA",
    "state_code": "ME",
    "cab": "824409",
    "cap": "98044"
  },
  {
    "key": "12976",
    "ITA": "SAN FILIPPO DEL MELA-ARCHI",
    "state_code": "ME",
    "cab": "824409",
    "cap": "98044"
  },
  {
    "key": "12977",
    "ITA": "SAN FIOR-SAN FIOR DI SOPRA",
    "state_code": "TV",
    "cab": "620302",
    "cap": "31020"
  },
  {
    "key": "12978",
    "ITA": "SAN FIOR-SAN FIOR DI SOTTO",
    "state_code": "TV",
    "cab": "620302",
    "cap": "31020"
  },
  {
    "key": "12979",
    "ITA": "SAN FIOR-CASTELLO ROGANZUOLO",
    "state_code": "TV",
    "cab": "620302",
    "cap": "31020"
  },
  {
    "key": "12980",
    "ITA": "SAN FIOR",
    "state_code": "TV",
    "cab": "620302",
    "cap": "31020"
  },
  {
    "key": "12981",
    "ITA": "SAN FIORANO",
    "state_code": "LO",
    "cab": "904128",
    "cap": "26848"
  },
  {
    "key": "12982",
    "ITA": "SAN FLORIANO DEL COLLIO-GIASBANA",
    "state_code": "GO",
    "cab": "937763",
    "cap": "34070"
  },
  {
    "key": "12983",
    "ITA": "SAN FLORIANO DEL COLLIO",
    "state_code": "GO",
    "cab": "937763",
    "cap": "34070"
  },
  {
    "key": "12984",
    "ITA": "SAN FLORO",
    "state_code": "CZ",
    "cab": "913368",
    "cap": "88021"
  },
  {
    "key": "12985",
    "ITA": "SAN FRANCESCO AL CAMPO",
    "state_code": "TO",
    "cab": "313106",
    "cap": "10070"
  },
  {
    "key": "12986",
    "ITA": "SAN FRATELLO",
    "state_code": "ME",
    "cab": "824508",
    "cap": "98075"
  },
  {
    "key": "12987",
    "ITA": "SAN GAVINO MONREALE",
    "state_code": "SU",
    "cab": "439604",
    "cap": "9037"
  },
  {
    "key": "12988",
    "ITA": "SAN GEMINI",
    "state_code": "TR",
    "cab": "727404",
    "cap": "5029"
  },
  {
    "key": "12989",
    "ITA": "SAN GENESIO ATESINO-COLOGNA DI SOTTO",
    "state_code": "BZ",
    "cab": "588004",
    "cap": "39050"
  },
  {
    "key": "12990",
    "ITA": "SAN GENESIO ATESINO-AVIGNA",
    "state_code": "BZ",
    "cab": "588004",
    "cap": "39050"
  },
  {
    "key": "12991",
    "ITA": "SAN GENESIO ATESINO-VALAS",
    "state_code": "BZ",
    "cab": "588004",
    "cap": "39050"
  },
  {
    "key": "12992",
    "ITA": "SAN GENESIO ATESINO",
    "state_code": "BZ",
    "cab": "588004",
    "cap": "39050"
  },
  {
    "key": "12993",
    "ITA": "SAN GENESIO ED UNITI",
    "state_code": "PV",
    "cab": "561902",
    "cap": "27010"
  },
  {
    "key": "12994",
    "ITA": "SAN GENNARO VESUVIANO",
    "state_code": "NA",
    "cab": "401505",
    "cap": "80040"
  },
  {
    "key": "12995",
    "ITA": "SAN GERMANO CHISONE",
    "state_code": "TO",
    "cab": "524207",
    "cap": "10065"
  },
  {
    "key": "12996",
    "ITA": "SAN GERMANO DEI BERICI",
    "state_code": "VI",
    "cab": "934075",
    "cap": "36040"
  },
  {
    "key": "12997",
    "ITA": "SAN GERMANO VERCELLESE",
    "state_code": "VC",
    "cab": "447607",
    "cap": "13047"
  },
  {
    "key": "12998",
    "ITA": "SAN GERVASIO BRESCIANO",
    "state_code": "BS",
    "cab": "552000",
    "cap": "25020"
  },
  {
    "key": "12999",
    "ITA": "SAN GIACOMO DEGLI SCHIAVONI",
    "state_code": "CB",
    "cab": "910919",
    "cap": "86030"
  },
  {
    "key": "13000",
    "ITA": "SAN GIACOMO DELLE SEGNATE",
    "state_code": "MN",
    "cab": "579300",
    "cap": "46020"
  },
  {
    "key": "13001",
    "ITA": "SAN GIACOMO FILIPPO-LIRONE",
    "state_code": "SO",
    "cab": "927277",
    "cap": "23020"
  },
  {
    "key": "13002",
    "ITA": "SAN GIACOMO FILIPPO",
    "state_code": "SO",
    "cab": "927277",
    "cap": "23020"
  },
  {
    "key": "13003",
    "ITA": "SAN GIACOMO VERCELLESE",
    "state_code": "VC",
    "cab": "915777",
    "cap": "13030"
  },
  {
    "key": "13004",
    "ITA": "SAN GILLIO",
    "state_code": "TO",
    "cab": "314302",
    "cap": "10040"
  },
  {
    "key": "13005",
    "ITA": "SAN GIMIGNANO-BADIA A ELMI",
    "state_code": "SI",
    "cab": "720003",
    "cap": "53037"
  },
  {
    "key": "13006",
    "ITA": "SAN GIMIGNANO-CASTEL SAN GIMIGNANO",
    "state_code": "SI",
    "cab": "720003",
    "cap": "53030"
  },
  {
    "key": "13007",
    "ITA": "SAN GIMIGNANO-ULIGNANO",
    "state_code": "SI",
    "cab": "720003",
    "cap": "53037"
  },
  {
    "key": "13008",
    "ITA": "SAN GIMIGNANO",
    "state_code": "SI",
    "cab": "720003",
    "cap": "53037"
  },
  {
    "key": "13009",
    "ITA": "SAN GINESIO-PASSO SAN GINESIO",
    "state_code": "MC",
    "cab": "691402",
    "cap": "62026"
  },
  {
    "key": "13010",
    "ITA": "SAN GINESIO",
    "state_code": "MC",
    "cab": "691402",
    "cap": "62026"
  },
  {
    "key": "13011",
    "ITA": "SAN GINESIO-PIAN DI PIECA",
    "state_code": "MC",
    "cab": "691402",
    "cap": "62026"
  },
  {
    "key": "13012",
    "ITA": "SAN GIORGIO A CREMANO",
    "state_code": "NA",
    "cab": "401604",
    "cap": "80046"
  },
  {
    "key": "13013",
    "ITA": "SAN GIORGIO A LIRI",
    "state_code": "FR",
    "cab": "745703",
    "cap": "3047"
  },
  {
    "key": "13014",
    "ITA": "SAN GIORGIO ALBANESE",
    "state_code": "CS",
    "cab": "959288",
    "cap": "87060"
  },
  {
    "key": "13015",
    "ITA": "SAN GIORGIO BIGARELLO",
    "state_code": "MN",
    "cab": "580605",
    "cap": "46051"
  },
  {
    "key": "13016",
    "ITA": "SAN GIORGIO CANAVESE",
    "state_code": "TO",
    "cab": "309104",
    "cap": "10090"
  },
  {
    "key": "13017",
    "ITA": "SAN GIORGIO DEL SANNIO-SAN GIOVANNI DI SAN GIORGIO DEL SANNIO",
    "state_code": "BN",
    "cab": "754408",
    "cap": "82018"
  },
  {
    "key": "13018",
    "ITA": "SAN GIORGIO DEL SANNIO",
    "state_code": "BN",
    "cab": "754408",
    "cap": "82018"
  },
  {
    "key": "13019",
    "ITA": "SAN GIORGIO DELLA RICHINVELDA-DOMANINS",
    "state_code": "PN",
    "cab": "650002",
    "cap": "33095"
  },
  {
    "key": "13020",
    "ITA": "SAN GIORGIO DELLA RICHINVELDA",
    "state_code": "PN",
    "cab": "650002",
    "cap": "33095"
  },
  {
    "key": "13021",
    "ITA": "SAN GIORGIO DELLA RICHINVELDA-PROVESANO",
    "state_code": "PN",
    "cab": "650002",
    "cap": "33095"
  },
  {
    "key": "13022",
    "ITA": "SAN GIORGIO DELLA RICHINVELDA-RAUSCEDO",
    "state_code": "PN",
    "cab": "650002",
    "cap": "33095"
  },
  {
    "key": "13023",
    "ITA": "SAN GIORGIO DELLE PERTICHE-CAVINO",
    "state_code": "PD",
    "cab": "628107",
    "cap": "35010"
  },
  {
    "key": "13024",
    "ITA": "SAN GIORGIO DELLE PERTICHE-ARSEGO",
    "state_code": "PD",
    "cab": "628107",
    "cap": "35010"
  },
  {
    "key": "13025",
    "ITA": "SAN GIORGIO DELLE PERTICHE",
    "state_code": "PD",
    "cab": "628107",
    "cap": "35010"
  },
  {
    "key": "13026",
    "ITA": "SAN GIORGIO DI LOMELLINA",
    "state_code": "PV",
    "cab": "562009",
    "cap": "27020"
  },
  {
    "key": "13027",
    "ITA": "SAN GIORGIO DI MANTOVA-VILLANOVA DE BELLIS",
    "state_code": "MN",
    "cab": "580605",
    "cap": "46030"
  },
  {
    "key": "13028",
    "ITA": "SAN GIORGIO DI MANTOVA-TRIPOLI",
    "state_code": "MN",
    "cab": "580605",
    "cap": "46030"
  },
  {
    "key": "13029",
    "ITA": "SAN GIORGIO DI MANTOVA",
    "state_code": "MN",
    "cab": "580605",
    "cap": "46030"
  },
  {
    "key": "13030",
    "ITA": "SAN GIORGIO DI MANTOVA-MOTTELLA",
    "state_code": "MN",
    "cab": "580605",
    "cap": "46030"
  },
  {
    "key": "13031",
    "ITA": "SAN GIORGIO DI NOGARO",
    "state_code": "UD",
    "cab": "642009",
    "cap": "33058"
  },
  {
    "key": "13032",
    "ITA": "SAN GIORGIO DI PESARO",
    "state_code": "PU",
    "cab": "685602",
    "cap": "61030"
  },
  {
    "key": "13033",
    "ITA": "SAN GIORGIO DI PIANO",
    "state_code": "BO",
    "cab": "370502",
    "cap": "40016"
  },
  {
    "key": "13034",
    "ITA": "SAN GIORGIO IN BOSCO",
    "state_code": "PD",
    "cab": "630608",
    "cap": "35010"
  },
  {
    "key": "13035",
    "ITA": "SAN GIORGIO IONICO",
    "state_code": "TA",
    "cab": "790204",
    "cap": "74027"
  },
  {
    "key": "13036",
    "ITA": "SAN GIORGIO LA MOLARA",
    "state_code": "BN",
    "cab": "754507",
    "cap": "82020"
  },
  {
    "key": "13037",
    "ITA": "SAN GIORGIO LUCANO",
    "state_code": "MT",
    "cab": "804401",
    "cap": "75027"
  },
  {
    "key": "13038",
    "ITA": "SAN GIORGIO MONFERRATO",
    "state_code": "AL",
    "cab": "676304",
    "cap": "15020"
  },
  {
    "key": "13039",
    "ITA": "SAN GIORGIO MONFERRATO-SAN GIORGIO",
    "state_code": "AL",
    "cab": "676304",
    "cap": "15020"
  },
  {
    "key": "13040",
    "ITA": "SAN GIORGIO MORGETO",
    "state_code": "RC",
    "cab": "815407",
    "cap": "89017"
  },
  {
    "key": "13041",
    "ITA": "SAN GIORGIO PIACENTINO",
    "state_code": "PC",
    "cab": "654608",
    "cap": "29019"
  },
  {
    "key": "13042",
    "ITA": "SAN GIORGIO PIACENTINO-GODI",
    "state_code": "PC",
    "cab": "654608",
    "cap": "29019"
  },
  {
    "key": "13043",
    "ITA": "SAN GIORGIO PIACENTINO-SAN DAMIANO",
    "state_code": "PC",
    "cab": "654608",
    "cap": "29019"
  },
  {
    "key": "13044",
    "ITA": "SAN GIORGIO SCARAMPI",
    "state_code": "AT",
    "cab": "920405",
    "cap": "14059"
  },
  {
    "key": "13045",
    "ITA": "SAN GIORGIO SU LEGNANO",
    "state_code": "MI",
    "cab": "337204",
    "cap": "20010"
  },
  {
    "key": "13046",
    "ITA": "SAN GIORIO DI SUSA",
    "state_code": "TO",
    "cab": "901512",
    "cap": "10050"
  },
  {
    "key": "13047",
    "ITA": "SAN GIORIO DI SUSA-SAN GIORIO",
    "state_code": "TO",
    "cab": "901512",
    "cap": "10050"
  },
  {
    "key": "13048",
    "ITA": "SAN GIOVANNI A PIRO-SCARIO",
    "state_code": "SA",
    "cab": "409409",
    "cap": "84070"
  },
  {
    "key": "13049",
    "ITA": "SAN GIOVANNI A PIRO",
    "state_code": "SA",
    "cab": "409409",
    "cap": "84070"
  },
  {
    "key": "13050",
    "ITA": "SAN GIOVANNI A PIRO-BOSCO",
    "state_code": "SA",
    "cab": "409409",
    "cap": "84040"
  },
  {
    "key": "13051",
    "ITA": "SAN GIOVANNI AL NATISONE-VILLANOVA DELLO IUDRIO",
    "state_code": "UD",
    "cab": "642108",
    "cap": "33048"
  },
  {
    "key": "13052",
    "ITA": "SAN GIOVANNI AL NATISONE-MEDEUZZA",
    "state_code": "UD",
    "cab": "642108",
    "cap": "33048"
  },
  {
    "key": "13053",
    "ITA": "SAN GIOVANNI AL NATISONE-DOLEGNANO",
    "state_code": "UD",
    "cab": "642108",
    "cap": "33048"
  },
  {
    "key": "13054",
    "ITA": "SAN GIOVANNI AL NATISONE",
    "state_code": "UD",
    "cab": "642108",
    "cap": "33048"
  },
  {
    "key": "13055",
    "ITA": "SAN GIOVANNI BIANCO",
    "state_code": "BG",
    "cab": "534404",
    "cap": "24015"
  },
  {
    "key": "13056",
    "ITA": "SAN GIOVANNI D'ASSO",
    "state_code": "SI",
    "cab": "720102",
    "cap": "53020"
  },
  {
    "key": "13057",
    "ITA": "SAN GIOVANNI D'ASSO-MONTISI",
    "state_code": "SI",
    "cab": "720102",
    "cap": "53020"
  },
  {
    "key": "13058",
    "ITA": "SAN GIOVANNI DEL DOSSO",
    "state_code": "MN",
    "cab": "579409",
    "cap": "46020"
  },
  {
    "key": "13059",
    "ITA": "SAN GIOVANNI DI GERACE",
    "state_code": "RC",
    "cab": "960328",
    "cap": "89040"
  },
  {
    "key": "13060",
    "ITA": "SAN GIOVANNI GEMINI",
    "state_code": "AG",
    "cab": "831206",
    "cap": "92020"
  },
  {
    "key": "13061",
    "ITA": "SAN GIOVANNI ILARIONE",
    "state_code": "VR",
    "cab": "597609",
    "cap": "37035"
  },
  {
    "key": "13062",
    "ITA": "SAN GIOVANNI IN CROCE",
    "state_code": "CR",
    "cab": "571307",
    "cap": "26037"
  },
  {
    "key": "13063",
    "ITA": "SAN GIOVANNI IN FIORE",
    "state_code": "CS",
    "cab": "809608",
    "cap": "87055"
  },
  {
    "key": "13064",
    "ITA": "SAN GIOVANNI IN FIORE-LORICA",
    "state_code": "CS",
    "cab": "809608",
    "cap": "87055"
  },
  {
    "key": "13065",
    "ITA": "SAN GIOVANNI IN FIORE-MONTE OLIVETO",
    "state_code": "CS",
    "cab": "809608",
    "cap": "87055"
  },
  {
    "key": "13066",
    "ITA": "SAN GIOVANNI IN GALDO",
    "state_code": "CB",
    "cab": "910927",
    "cap": "86010"
  },
  {
    "key": "13067",
    "ITA": "SAN GIOVANNI IN MARIGNANO-PIANVENTENA",
    "state_code": "RN",
    "cab": "680009",
    "cap": "47842"
  },
  {
    "key": "13068",
    "ITA": "SAN GIOVANNI IN MARIGNANO-MONTALBANO",
    "state_code": "RN",
    "cab": "680009",
    "cap": "47842"
  },
  {
    "key": "13069",
    "ITA": "SAN GIOVANNI IN MARIGNANO",
    "state_code": "RN",
    "cab": "680009",
    "cap": "47842"
  },
  {
    "key": "13070",
    "ITA": "SAN GIOVANNI IN PERSICETO",
    "state_code": "BO",
    "cab": "370601",
    "cap": "40017"
  },
  {
    "key": "13071",
    "ITA": "SAN GIOVANNI IN PERSICETO-AMOLA DI PIANO",
    "state_code": "BO",
    "cab": "370601",
    "cap": "40017"
  },
  {
    "key": "13072",
    "ITA": "SAN GIOVANNI IN PERSICETO-DECIMA",
    "state_code": "BO",
    "cab": "370601",
    "cap": "40017"
  },
  {
    "key": "13073",
    "ITA": "SAN GIOVANNI IN PERSICETO-BUDRIE",
    "state_code": "BO",
    "cab": "370601",
    "cap": "40017"
  },
  {
    "key": "13074",
    "ITA": "SAN GIOVANNI IN PERSICETO-SAN MATTEO DELLA DECIMA",
    "state_code": "BO",
    "cab": "370601",
    "cap": "40017"
  },
  {
    "key": "13075",
    "ITA": "SAN GIOVANNI INCARICO",
    "state_code": "FR",
    "cab": "747303",
    "cap": "3028"
  },
  {
    "key": "13076",
    "ITA": "SAN GIOVANNI LA PUNTA",
    "state_code": "CT",
    "cab": "841908",
    "cap": "95037"
  },
  {
    "key": "13077",
    "ITA": "SAN GIOVANNI LA PUNTA-TRAPPETO",
    "state_code": "CT",
    "cab": "841908",
    "cap": "95037"
  },
  {
    "key": "13078",
    "ITA": "SAN GIOVANNI LIPIONI",
    "state_code": "CH",
    "cab": "954990",
    "cap": "66050"
  },
  {
    "key": "13079",
    "ITA": "SAN GIOVANNI LUPATOTO-RALDON",
    "state_code": "VR",
    "cab": "597708",
    "cap": "37057"
  },
  {
    "key": "13080",
    "ITA": "SAN GIOVANNI LUPATOTO-POZZO CAMACICI",
    "state_code": "VR",
    "cab": "597708",
    "cap": "37057"
  },
  {
    "key": "13081",
    "ITA": "SAN GIOVANNI LUPATOTO",
    "state_code": "VR",
    "cab": "597708",
    "cap": "37057"
  },
  {
    "key": "13082",
    "ITA": "SAN GIOVANNI ROTONDO",
    "state_code": "FG",
    "cab": "785907",
    "cap": "71013"
  },
  {
    "key": "13083",
    "ITA": "SAN GIOVANNI ROTONDO-MATINE",
    "state_code": "FG",
    "cab": "785907",
    "cap": "71013"
  },
  {
    "key": "13084",
    "ITA": "SAN GIOVANNI SUERGIU-MATZACCARA",
    "state_code": "SU",
    "cab": "861401",
    "cap": "9010"
  },
  {
    "key": "13085",
    "ITA": "SAN GIOVANNI SUERGIU-PALMAS",
    "state_code": "SU",
    "cab": "861401",
    "cap": "9010"
  },
  {
    "key": "13086",
    "ITA": "SAN GIOVANNI SUERGIU",
    "state_code": "SU",
    "cab": "861401",
    "cap": "9010"
  },
  {
    "key": "13087",
    "ITA": "SAN GIOVANNI SUERGIU-IS URIGUS",
    "state_code": "SU",
    "cab": "861401",
    "cap": "9010"
  },
  {
    "key": "13088",
    "ITA": "SAN GIOVANNI TEATINO-SAMBUCETO",
    "state_code": "CH",
    "cab": "778407",
    "cap": "66020"
  },
  {
    "key": "13089",
    "ITA": "SAN GIOVANNI TEATINO",
    "state_code": "CH",
    "cab": "778407",
    "cap": "66020"
  },
  {
    "key": "13090",
    "ITA": "SAN GIOVANNI VALDARNO",
    "state_code": "AR",
    "cab": "716001",
    "cap": "52027"
  },
  {
    "key": "13091",
    "ITA": "SAN GIULIANO DEL SANNIO",
    "state_code": "CB",
    "cab": "910935",
    "cap": "86010"
  },
  {
    "key": "13092",
    "ITA": "SAN GIULIANO DI PUGLIA",
    "state_code": "CB",
    "cab": "811703",
    "cap": "86040"
  },
  {
    "key": "13093",
    "ITA": "SAN GIULIANO MILANESE",
    "state_code": "MI",
    "cab": "337303",
    "cap": "20098"
  },
  {
    "key": "13094",
    "ITA": "SAN GIULIANO MILANESE-SESTO ULTERIANO",
    "state_code": "MI",
    "cab": "337303",
    "cap": "20098"
  },
  {
    "key": "13095",
    "ITA": "SAN GIULIANO MILANESE-BORGO LOMBARDO",
    "state_code": "MI",
    "cab": "337303",
    "cap": "20098"
  },
  {
    "key": "13096",
    "ITA": "SAN GIULIANO TERME-ARENA",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56010"
  },
  {
    "key": "13097",
    "ITA": "SAN GIULIANO TERME-AGNANO",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13098",
    "ITA": "SAN GIULIANO TERME",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13099",
    "ITA": "SAN GIULIANO TERME-GELLO",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13100",
    "ITA": "SAN GIULIANO TERME-GHEZZANO",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56010"
  },
  {
    "key": "13101",
    "ITA": "SAN GIULIANO TERME-RIPAFRATTA",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13102",
    "ITA": "SAN GIULIANO TERME-MOLINA DI QUOSA",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13103",
    "ITA": "SAN GIULIANO TERME-CAMPO",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56010"
  },
  {
    "key": "13104",
    "ITA": "SAN GIULIANO TERME-ASCIANO",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13105",
    "ITA": "SAN GIULIANO TERME-RIGOLI",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13106",
    "ITA": "SAN GIULIANO TERME-PONTASSERCHIO",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13107",
    "ITA": "SAN GIULIANO TERME-PAPPIANA",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56017"
  },
  {
    "key": "13108",
    "ITA": "SAN GIULIANO TERME-MEZZANA",
    "state_code": "PI",
    "cab": "253005",
    "cap": "56010"
  },
  {
    "key": "13109",
    "ITA": "SAN GIUSEPPE JATO",
    "state_code": "PA",
    "cab": "435909",
    "cap": "90048"
  },
  {
    "key": "13110",
    "ITA": "SAN GIUSEPPE VESUVIANO",
    "state_code": "NA",
    "cab": "401703",
    "cap": "80047"
  },
  {
    "key": "13111",
    "ITA": "SAN GIUSEPPE VESUVIANO-SANTA MARIA LA SCALA",
    "state_code": "NA",
    "cab": "401703",
    "cap": "80047"
  },
  {
    "key": "13112",
    "ITA": "SAN GIUSEPPE VESUVIANO-CASILLI",
    "state_code": "NA",
    "cab": "401703",
    "cap": "80047"
  },
  {
    "key": "13113",
    "ITA": "SAN GIUSTINO",
    "state_code": "PG",
    "cab": "386409",
    "cap": "6016"
  },
  {
    "key": "13114",
    "ITA": "SAN GIUSTINO-LAMA",
    "state_code": "PG",
    "cab": "386409",
    "cap": "6016"
  },
  {
    "key": "13115",
    "ITA": "SAN GIUSTINO-SELCI",
    "state_code": "PG",
    "cab": "386409",
    "cap": "6016"
  },
  {
    "key": "13116",
    "ITA": "SAN GIUSTO CANAVESE",
    "state_code": "TO",
    "cab": "309203",
    "cap": "10090"
  },
  {
    "key": "13117",
    "ITA": "SAN GODENZO",
    "state_code": "FI",
    "cab": "380600",
    "cap": "50060"
  },
  {
    "key": "13118",
    "ITA": "SAN GREGORIO D'IPPONA",
    "state_code": "VV",
    "cab": "913376",
    "cap": "89853"
  },
  {
    "key": "13119",
    "ITA": "SAN GREGORIO DA SASSOLA",
    "state_code": "RM",
    "cab": "724401",
    "cap": "10"
  },
  {
    "key": "13120",
    "ITA": "SAN GREGORIO DI CATANIA-CERZA",
    "state_code": "CT",
    "cab": "842005",
    "cap": "95027"
  },
  {
    "key": "13121",
    "ITA": "SAN GREGORIO DI CATANIA",
    "state_code": "CT",
    "cab": "842005",
    "cap": "95027"
  },
  {
    "key": "13122",
    "ITA": "SAN GREGORIO MAGNO",
    "state_code": "SA",
    "cab": "886408",
    "cap": "84020"
  },
  {
    "key": "13123",
    "ITA": "SAN GREGORIO MATESE",
    "state_code": "CE",
    "cab": "949594",
    "cap": "81010"
  },
  {
    "key": "13124",
    "ITA": "SAN GREGORIO NELLE ALPI",
    "state_code": "BL",
    "cab": "712505",
    "cap": "32030"
  },
  {
    "key": "13125",
    "ITA": "SAN GREGORIO NELLE ALPI-PADERNO",
    "state_code": "BL",
    "cab": "712505",
    "cap": "32030"
  },
  {
    "key": "13126",
    "ITA": "SAN LAZZARO DI SAVENA-PONTICELLA",
    "state_code": "BO",
    "cab": "370700",
    "cap": "40068"
  },
  {
    "key": "13127",
    "ITA": "SAN LAZZARO DI SAVENA-MARTIRI DI PIZZOCALVO",
    "state_code": "BO",
    "cab": "370700",
    "cap": "40068"
  },
  {
    "key": "13128",
    "ITA": "SAN LAZZARO DI SAVENA-PULCE",
    "state_code": "BO",
    "cab": "370700",
    "cap": "40068"
  },
  {
    "key": "13129",
    "ITA": "SAN LAZZARO DI SAVENA-SAN LAZZARO",
    "state_code": "BO",
    "cab": "370700",
    "cap": "40068"
  },
  {
    "key": "13130",
    "ITA": "SAN LAZZARO DI SAVENA-FARNETO",
    "state_code": "BO",
    "cab": "370700",
    "cap": "40068"
  },
  {
    "key": "13131",
    "ITA": "SAN LAZZARO DI SAVENA-CASTEL DEI BRITTI",
    "state_code": "BO",
    "cab": "370700",
    "cap": "40068"
  },
  {
    "key": "13132",
    "ITA": "SAN LAZZARO DI SAVENA",
    "state_code": "BO",
    "cab": "370700",
    "cap": "40068"
  },
  {
    "key": "13133",
    "ITA": "SAN LEO-PIETRACUTA",
    "state_code": "PU",
    "cab": "685701",
    "cap": "61018"
  },
  {
    "key": "13134",
    "ITA": "SAN LEO-MONTEMAGGIO",
    "state_code": "PU",
    "cab": "685701",
    "cap": "61018"
  },
  {
    "key": "13135",
    "ITA": "SAN LEO",
    "state_code": "RN",
    "cab": "685701",
    "cap": "61018"
  },
  {
    "key": "13136",
    "ITA": "SAN LEONARDO",
    "state_code": "UD",
    "cab": "642207",
    "cap": "33040"
  },
  {
    "key": "13137",
    "ITA": "SAN LEONARDO IN PASSIRIA-VALTINA",
    "state_code": "BZ",
    "cab": "588103",
    "cap": "39015"
  },
  {
    "key": "13138",
    "ITA": "SAN LEONARDO IN PASSIRIA",
    "state_code": "BZ",
    "cab": "588103",
    "cap": "39015"
  },
  {
    "key": "13139",
    "ITA": "SAN LEONARDO IN PASSIRIA-SANT'ORSOLA",
    "state_code": "BZ",
    "cab": "588103",
    "cap": "39010"
  },
  {
    "key": "13140",
    "ITA": "SAN LEUCIO DEL SANNIO",
    "state_code": "BN",
    "cab": "756304",
    "cap": "82010"
  },
  {
    "key": "13141",
    "ITA": "SAN LEUCIO DEL SANNIO-CAVUOTI",
    "state_code": "BN",
    "cab": "756304",
    "cap": "82010"
  },
  {
    "key": "13142",
    "ITA": "SAN LORENZELLO",
    "state_code": "BN",
    "cab": "950485",
    "cap": "82030"
  },
  {
    "key": "13143",
    "ITA": "SAN LORENZO-CHORIO",
    "state_code": "RC",
    "cab": "960336",
    "cap": "89060"
  },
  {
    "key": "13144",
    "ITA": "SAN LORENZO-MARINA DI SAN LORENZO",
    "state_code": "RC",
    "cab": "960336",
    "cap": "89060"
  },
  {
    "key": "13145",
    "ITA": "SAN LORENZO-SAN FANTINO",
    "state_code": "RC",
    "cab": "960336",
    "cap": "89060"
  },
  {
    "key": "13146",
    "ITA": "SAN LORENZO-SAN PANTALEONE",
    "state_code": "RC",
    "cab": "960336",
    "cap": "89060"
  },
  {
    "key": "13147",
    "ITA": "SAN LORENZO",
    "state_code": "RC",
    "cab": "960336",
    "cap": "89069"
  },
  {
    "key": "13148",
    "ITA": "SAN LORENZO AL MARE",
    "state_code": "IM",
    "cab": "490706",
    "cap": "18017"
  },
  {
    "key": "13149",
    "ITA": "SAN LORENZO BELLIZZI",
    "state_code": "CS",
    "cab": "959296",
    "cap": "87070"
  },
  {
    "key": "13150",
    "ITA": "SAN LORENZO DEL VALLO",
    "state_code": "CS",
    "cab": "959304",
    "cap": "87040"
  },
  {
    "key": "13151",
    "ITA": "SAN LORENZO DI SEBATO",
    "state_code": "BZ",
    "cab": "588202",
    "cap": "39030"
  },
  {
    "key": "13152",
    "ITA": "SAN LORENZO IN BANALE",
    "state_code": "TN",
    "cab": "354001",
    "cap": "38078"
  },
  {
    "key": "13153",
    "ITA": "SAN LORENZO IN BANALE-MOLINE",
    "state_code": "TN",
    "cab": "354001",
    "cap": "38078"
  },
  {
    "key": "13154",
    "ITA": "SAN LORENZO IN CAMPO",
    "state_code": "PU",
    "cab": "685800",
    "cap": "61047"
  },
  {
    "key": "13155",
    "ITA": "SAN LORENZO IN CAMPO-SAN VITO SUL CESANO",
    "state_code": "PU",
    "cab": "685800",
    "cap": "61047"
  },
  {
    "key": "13156",
    "ITA": "SAN LORENZO ISONTINO",
    "state_code": "GO",
    "cab": "471805",
    "cap": "34070"
  },
  {
    "key": "13157",
    "ITA": "SAN LORENZO MAGGIORE",
    "state_code": "BN",
    "cab": "950493",
    "cap": "82034"
  },
  {
    "key": "13158",
    "ITA": "SAN LORENZO NUOVO",
    "state_code": "VT",
    "cab": "732602",
    "cap": "1020"
  },
  {
    "key": "13159",
    "ITA": "SAN LUCA-SANTUARIO DI POLSI",
    "state_code": "RC",
    "cab": "192989",
    "cap": "89030"
  },
  {
    "key": "13160",
    "ITA": "SAN LUCA",
    "state_code": "RC",
    "cab": "192989",
    "cap": "89030"
  },
  {
    "key": "13161",
    "ITA": "SAN LUCIDO-POLLELLA",
    "state_code": "CS",
    "cab": "809707",
    "cap": "87038"
  },
  {
    "key": "13162",
    "ITA": "SAN LUCIDO",
    "state_code": "CS",
    "cab": "809707",
    "cap": "87038"
  },
  {
    "key": "13163",
    "ITA": "SAN LUPO",
    "state_code": "BN",
    "cab": "950501",
    "cap": "82034"
  },
  {
    "key": "13164",
    "ITA": "SAN MANGO D'AQUINO",
    "state_code": "CZ",
    "cab": "913384",
    "cap": "88040"
  },
  {
    "key": "13165",
    "ITA": "SAN MANGO PIEMONTE",
    "state_code": "SA",
    "cab": "952986",
    "cap": "84090"
  },
  {
    "key": "13166",
    "ITA": "SAN MANGO SUL CALORE",
    "state_code": "AV",
    "cab": "951533",
    "cap": "83050"
  },
  {
    "key": "13167",
    "ITA": "SAN MARCELLINO",
    "state_code": "CE",
    "cab": "751503",
    "cap": "81030"
  },
  {
    "key": "13168",
    "ITA": "SAN MARCELLO",
    "state_code": "AN",
    "cab": "375600",
    "cap": "60030"
  },
  {
    "key": "13169",
    "ITA": "SAN MARCELLO PISTOIESE",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13170",
    "ITA": "SAN MARCELLO PISTOIESE-BARDALONE",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13171",
    "ITA": "SAN MARCELLO PISTOIESE-PONTEPETRI",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13172",
    "ITA": "SAN MARCELLO PISTOIESE-MARESCA",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13173",
    "ITA": "SAN MARCELLO PISTOIESE-MAMMIANO",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13174",
    "ITA": "SAN MARCELLO PISTOIESE-LIZZANO PISTOIESE",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13175",
    "ITA": "SAN MARCELLO PISTOIESE-LIMESTRE",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13176",
    "ITA": "SAN MARCELLO PISTOIESE-GAVINANA",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13177",
    "ITA": "SAN MARCELLO PISTOIESE-CAMPO TIZZORO",
    "state_code": "PT",
    "cab": "705202",
    "cap": "51028"
  },
  {
    "key": "13178",
    "ITA": "SAN MARCO ARGENTANO",
    "state_code": "CS",
    "cab": "809806",
    "cap": "87018"
  },
  {
    "key": "13179",
    "ITA": "SAN MARCO ARGENTANO-SAN MARCO ARGENTANO STAZIONE",
    "state_code": "CS",
    "cab": "809806",
    "cap": "87018"
  },
  {
    "key": "13180",
    "ITA": "SAN MARCO D'ALUNZIO",
    "state_code": "ME",
    "cab": "824607",
    "cap": "98070"
  },
  {
    "key": "13181",
    "ITA": "SAN MARCO DEI CAVOTI",
    "state_code": "BN",
    "cab": "754606",
    "cap": "82029"
  },
  {
    "key": "13182",
    "ITA": "SAN MARCO EVANGELISTA",
    "state_code": "CE",
    "cab": "751008",
    "cap": "81020"
  },
  {
    "key": "13183",
    "ITA": "SAN MARCO IN LAMIS",
    "state_code": "FG",
    "cab": "786004",
    "cap": "71014"
  },
  {
    "key": "13184",
    "ITA": "SAN MARCO IN LAMIS-BORGO CELANO",
    "state_code": "FG",
    "cab": "786004",
    "cap": "71014"
  },
  {
    "key": "13185",
    "ITA": "SAN MARCO LA CATOLA",
    "state_code": "FG",
    "cab": "956417",
    "cap": "71030"
  },
  {
    "key": "13186",
    "ITA": "SAN MARINO DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47890"
  },
  {
    "key": "13187",
    "ITA": "SAN MARTINO AL TAGLIAMENTO",
    "state_code": "PN",
    "cab": "938225",
    "cap": "33098"
  },
  {
    "key": "13188",
    "ITA": "SAN MARTINO ALFIERI",
    "state_code": "AT",
    "cab": "476804",
    "cap": "14010"
  },
  {
    "key": "13189",
    "ITA": "SAN MARTINO BUON ALBERGO-MARCELLISE",
    "state_code": "VR",
    "cab": "597906",
    "cap": "37036"
  },
  {
    "key": "13190",
    "ITA": "SAN MARTINO BUON ALBERGO-MAMBROTTA",
    "state_code": "VR",
    "cab": "597906",
    "cap": "37036"
  },
  {
    "key": "13191",
    "ITA": "SAN MARTINO BUON ALBERGO-FERRAZZE",
    "state_code": "VR",
    "cab": "597906",
    "cap": "37036"
  },
  {
    "key": "13192",
    "ITA": "SAN MARTINO BUON ALBERGO",
    "state_code": "VR",
    "cab": "597906",
    "cap": "37036"
  },
  {
    "key": "13193",
    "ITA": "SAN MARTINO CANAVESE",
    "state_code": "TO",
    "cab": "284380",
    "cap": "10010"
  },
  {
    "key": "13194",
    "ITA": "SAN MARTINO D'AGRI",
    "state_code": "PZ",
    "cab": "912139",
    "cap": "85030"
  },
  {
    "key": "13195",
    "ITA": "SAN MARTINO DALL'ARGINE",
    "state_code": "MN",
    "cab": "579508",
    "cap": "46010"
  },
  {
    "key": "13196",
    "ITA": "SAN MARTINO DEL LAGO",
    "state_code": "CR",
    "cab": "931865",
    "cap": "26040"
  },
  {
    "key": "13197",
    "ITA": "SAN MARTINO DI FINITA",
    "state_code": "CS",
    "cab": "959312",
    "cap": "87010"
  },
  {
    "key": "13198",
    "ITA": "SAN MARTINO DI FINITA-SANTA MARIA LE GROTTE",
    "state_code": "CS",
    "cab": "959312",
    "cap": "87010"
  },
  {
    "key": "13199",
    "ITA": "SAN MARTINO DI LUPARI",
    "state_code": "PD",
    "cab": "628206",
    "cap": "35018"
  },
  {
    "key": "13200",
    "ITA": "SAN MARTINO DI VENEZZE-TRONA DI SOPRA",
    "state_code": "RO",
    "cab": "634501",
    "cap": "45030"
  },
  {
    "key": "13201",
    "ITA": "SAN MARTINO DI VENEZZE",
    "state_code": "RO",
    "cab": "634501",
    "cap": "45030"
  },
  {
    "key": "13202",
    "ITA": "SAN MARTINO DI VENEZZE-BEVERARE",
    "state_code": "RO",
    "cab": "634501",
    "cap": "45030"
  },
  {
    "key": "13203",
    "ITA": "SAN MARTINO IN BADIA-PICCOLINO",
    "state_code": "BZ",
    "cab": "588301",
    "cap": "39030"
  },
  {
    "key": "13204",
    "ITA": "SAN MARTINO IN BADIA",
    "state_code": "BZ",
    "cab": "588301",
    "cap": "39030"
  },
  {
    "key": "13205",
    "ITA": "SAN MARTINO IN BADIA-LONGIARU'",
    "state_code": "BZ",
    "cab": "588301",
    "cap": "39030"
  },
  {
    "key": "13206",
    "ITA": "SAN MARTINO IN PASSIRIA-SALTUSIO",
    "state_code": "BZ",
    "cab": "588400",
    "cap": "39010"
  },
  {
    "key": "13207",
    "ITA": "SAN MARTINO IN PASSIRIA",
    "state_code": "BZ",
    "cab": "588400",
    "cap": "39010"
  },
  {
    "key": "13208",
    "ITA": "SAN MARTINO IN PENSILIS",
    "state_code": "CB",
    "cab": "410902",
    "cap": "86046"
  },
  {
    "key": "13209",
    "ITA": "SAN MARTINO IN RIO",
    "state_code": "RE",
    "cab": "664805",
    "cap": "42018"
  },
  {
    "key": "13210",
    "ITA": "SAN MARTINO IN STRADA",
    "state_code": "LO",
    "cab": "337402",
    "cap": "26817"
  },
  {
    "key": "13211",
    "ITA": "SAN MARTINO SANNITA",
    "state_code": "BN",
    "cab": "754705",
    "cap": "82010"
  },
  {
    "key": "13212",
    "ITA": "SAN MARTINO SICCOMARIO-BIVIO CAVA MANARA",
    "state_code": "PV",
    "cab": "562108",
    "cap": "27028"
  },
  {
    "key": "13213",
    "ITA": "SAN MARTINO SICCOMARIO",
    "state_code": "PV",
    "cab": "562108",
    "cap": "27028"
  },
  {
    "key": "13214",
    "ITA": "SAN MARTINO SULLA MARRUCINA",
    "state_code": "CH",
    "cab": "269597",
    "cap": "66010"
  },
  {
    "key": "13215",
    "ITA": "SAN MARTINO VALLE CAUDINA",
    "state_code": "AV",
    "cab": "897504",
    "cap": "83018"
  },
  {
    "key": "13216",
    "ITA": "SAN MARZANO DI SAN GIUSEPPE",
    "state_code": "TA",
    "cab": "790303",
    "cap": "74020"
  },
  {
    "key": "13217",
    "ITA": "SAN MARZANO OLIVETO",
    "state_code": "AT",
    "cab": "476903",
    "cap": "14050"
  },
  {
    "key": "13218",
    "ITA": "SAN MARZANO SUL SARNO",
    "state_code": "SA",
    "cab": "764308",
    "cap": "84010"
  },
  {
    "key": "13219",
    "ITA": "SAN MASSIMO",
    "state_code": "CB",
    "cab": "910950",
    "cap": "86027"
  },
  {
    "key": "13220",
    "ITA": "SAN MAURIZIO CANAVESE-MALANGHERO",
    "state_code": "TO",
    "cab": "309302",
    "cap": "10077"
  },
  {
    "key": "13221",
    "ITA": "SAN MAURIZIO CANAVESE-CERETTA DI SAN MAURIZIO CANAVESE",
    "state_code": "TO",
    "cab": "309302",
    "cap": "10077"
  },
  {
    "key": "13222",
    "ITA": "SAN MAURIZIO CANAVESE",
    "state_code": "TO",
    "cab": "309302",
    "cap": "10077"
  },
  {
    "key": "13223",
    "ITA": "SAN MAURIZIO D'OPAGLIO",
    "state_code": "NO",
    "cab": "456400",
    "cap": "28017"
  },
  {
    "key": "13224",
    "ITA": "SAN MAURO CASTELVERDE-BORRELLO DI SAN MAURO CASTELVERDE",
    "state_code": "PA",
    "cab": "436006",
    "cap": "90010"
  },
  {
    "key": "13225",
    "ITA": "SAN MAURO CASTELVERDE",
    "state_code": "PA",
    "cab": "436006",
    "cap": "90010"
  },
  {
    "key": "13226",
    "ITA": "SAN MAURO CILENTO",
    "state_code": "SA",
    "cab": "952994",
    "cap": "84070"
  },
  {
    "key": "13227",
    "ITA": "SAN MAURO CILENTO-CASALSOTTANO",
    "state_code": "SA",
    "cab": "952994",
    "cap": "84070"
  },
  {
    "key": "13228",
    "ITA": "SAN MAURO DI SALINE",
    "state_code": "VR",
    "cab": "933366",
    "cap": "37030"
  },
  {
    "key": "13229",
    "ITA": "SAN MAURO FORTE",
    "state_code": "MT",
    "cab": "804500",
    "cap": "75010"
  },
  {
    "key": "13230",
    "ITA": "SAN MAURO LA BRUCA",
    "state_code": "SA",
    "cab": "953000",
    "cap": "84070"
  },
  {
    "key": "13231",
    "ITA": "SAN MAURO MARCHESATO",
    "state_code": "KR",
    "cab": "913392",
    "cap": "88831"
  },
  {
    "key": "13232",
    "ITA": "SAN MAURO PASCOLI",
    "state_code": "FC",
    "cab": "680108",
    "cap": "47030"
  },
  {
    "key": "13233",
    "ITA": "SAN MAURO PASCOLI-SAN MAURO A MARE",
    "state_code": "FC",
    "cab": "680108",
    "cap": "47030"
  },
  {
    "key": "13234",
    "ITA": "SAN MAURO TORINESE",
    "state_code": "TO",
    "cab": "309401",
    "cap": "10099"
  },
  {
    "key": "13235",
    "ITA": "SAN MICHELE AL TAGLIAMENTO-CESAROLO",
    "state_code": "VE",
    "cab": "362905",
    "cap": "30028"
  },
  {
    "key": "13236",
    "ITA": "SAN MICHELE AL TAGLIAMENTO-POZZI",
    "state_code": "VE",
    "cab": "362905",
    "cap": "30028"
  },
  {
    "key": "13237",
    "ITA": "SAN MICHELE AL TAGLIAMENTO-BIBIONE PINEDA",
    "state_code": "VE",
    "cab": "362905",
    "cap": "30020"
  },
  {
    "key": "13238",
    "ITA": "SAN MICHELE AL TAGLIAMENTO-BIBIONE",
    "state_code": "VE",
    "cab": "362905",
    "cap": "30020"
  },
  {
    "key": "13239",
    "ITA": "SAN MICHELE AL TAGLIAMENTO",
    "state_code": "VE",
    "cab": "362905",
    "cap": "30028"
  },
  {
    "key": "13240",
    "ITA": "SAN MICHELE AL TAGLIAMENTO-SAN GIORGIO AL TAGLIAMENTO",
    "state_code": "VE",
    "cab": "362905",
    "cap": "30028"
  },
  {
    "key": "13241",
    "ITA": "SAN MICHELE ALL'ADIGE",
    "state_code": "TN",
    "cab": "354100",
    "cap": "38010"
  },
  {
    "key": "13242",
    "ITA": "SAN MICHELE DI GANZARIA",
    "state_code": "CT",
    "cab": "842104",
    "cap": "95040"
  },
  {
    "key": "13243",
    "ITA": "SAN MICHELE DI SERINO",
    "state_code": "AV",
    "cab": "951558",
    "cap": "83020"
  },
  {
    "key": "13244",
    "ITA": "SAN MICHELE MONDOVI'",
    "state_code": "CN",
    "cab": "468108",
    "cap": "12080"
  },
  {
    "key": "13245",
    "ITA": "SAN MICHELE SALENTINO",
    "state_code": "BR",
    "cab": "792507",
    "cap": "72018"
  },
  {
    "key": "13246",
    "ITA": "SAN MINIATO-SAN MINIATO BASSO",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56028"
  },
  {
    "key": "13247",
    "ITA": "SAN MINIATO-PONTE A ELSA",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56028"
  },
  {
    "key": "13248",
    "ITA": "SAN MINIATO-LA SERRA",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56024"
  },
  {
    "key": "13249",
    "ITA": "SAN MINIATO-LA SCALA",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56028"
  },
  {
    "key": "13250",
    "ITA": "SAN MINIATO-ISOLA",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56028"
  },
  {
    "key": "13251",
    "ITA": "SAN MINIATO-CORAZZANO",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56024"
  },
  {
    "key": "13252",
    "ITA": "SAN MINIATO",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56028"
  },
  {
    "key": "13253",
    "ITA": "SAN MINIATO-PONTE A EGOLA",
    "state_code": "PI",
    "cab": "711507",
    "cap": "56024"
  },
  {
    "key": "13254",
    "ITA": "SAN NAZARIO",
    "state_code": "VI",
    "cab": "738302",
    "cap": "36020"
  },
  {
    "key": "13255",
    "ITA": "SAN NAZARIO-SAN MARINO",
    "state_code": "VI",
    "cab": "738302",
    "cap": "36020"
  },
  {
    "key": "13256",
    "ITA": "SAN NAZARIO-CARPANE'",
    "state_code": "VI",
    "cab": "738302",
    "cap": "36020"
  },
  {
    "key": "13257",
    "ITA": "SAN NAZZARO",
    "state_code": "BN",
    "cab": "950519",
    "cap": "82018"
  },
  {
    "key": "13258",
    "ITA": "SAN NAZZARO SESIA",
    "state_code": "NO",
    "cab": "917682",
    "cap": "28060"
  },
  {
    "key": "13259",
    "ITA": "SAN NAZZARO VAL CAVARGNA",
    "state_code": "CO",
    "cab": "926170",
    "cap": "22010"
  },
  {
    "key": "13260",
    "ITA": "SAN NICANDRO GARGANICO",
    "state_code": "FG",
    "cab": "786103",
    "cap": "71015"
  },
  {
    "key": "13261",
    "ITA": "SAN NICOLA ARCELLA",
    "state_code": "CS",
    "cab": "959320",
    "cap": "87020"
  },
  {
    "key": "13262",
    "ITA": "SAN NICOLA BARONIA",
    "state_code": "AV",
    "cab": "951566",
    "cap": "83050"
  },
  {
    "key": "13263",
    "ITA": "SAN NICOLA DA CRISSA",
    "state_code": "VV",
    "cab": "191171",
    "cap": "89821"
  },
  {
    "key": "13264",
    "ITA": "SAN NICOLA DELL'ALTO",
    "state_code": "KR",
    "cab": "913418",
    "cap": "88817"
  },
  {
    "key": "13265",
    "ITA": "SAN NICOLA LA STRADA",
    "state_code": "CE",
    "cab": "750109",
    "cap": "81020"
  },
  {
    "key": "13266",
    "ITA": "SAN NICOLA MANFREDI-PAGLIARA",
    "state_code": "BN",
    "cab": "755405",
    "cap": "82010"
  },
  {
    "key": "13267",
    "ITA": "SAN NICOLA MANFREDI",
    "state_code": "BN",
    "cab": "755405",
    "cap": "82010"
  },
  {
    "key": "13268",
    "ITA": "SAN NICOLA MANFREDI-BOSCO PERROTTA",
    "state_code": "BN",
    "cab": "755405",
    "cap": "82010"
  },
  {
    "key": "13269",
    "ITA": "SAN NICOLA MANFREDI-IANNASSI",
    "state_code": "BN",
    "cab": "755405",
    "cap": "82010"
  },
  {
    "key": "13270",
    "ITA": "SAN NICOLA MANFREDI-MONTEROCCHETTA",
    "state_code": "BN",
    "cab": "755405",
    "cap": "82010"
  },
  {
    "key": "13271",
    "ITA": "SAN NICOLO' D'ARCIDANO",
    "state_code": "OR",
    "cab": "879908",
    "cap": "9097"
  },
  {
    "key": "13272",
    "ITA": "SAN NICOLO' DI COMELICO",
    "state_code": "BL",
    "cab": "934752",
    "cap": "32040"
  },
  {
    "key": "13273",
    "ITA": "SAN NICOLO' GERREI",
    "state_code": "CA",
    "cab": "861500",
    "cap": "9040"
  },
  {
    "key": "13274",
    "ITA": "SAN PANCRAZIO",
    "state_code": "BZ",
    "cab": "588509",
    "cap": "39010"
  },
  {
    "key": "13275",
    "ITA": "SAN PANCRAZIO SALENTINO",
    "state_code": "BR",
    "cab": "792606",
    "cap": "72026"
  },
  {
    "key": "13276",
    "ITA": "SAN PAOLO",
    "state_code": "BS",
    "cab": "549402",
    "cap": "25020"
  },
  {
    "key": "13277",
    "ITA": "SAN PAOLO-SCARPIZZOLO",
    "state_code": "BS",
    "cab": "549402",
    "cap": "25020"
  },
  {
    "key": "13278",
    "ITA": "SAN PAOLO ALBANESE",
    "state_code": "PZ",
    "cab": "911909",
    "cap": "85030"
  },
  {
    "key": "13279",
    "ITA": "SAN PAOLO BEL SITO",
    "state_code": "NA",
    "cab": "565606",
    "cap": "80030"
  },
  {
    "key": "13280",
    "ITA": "SAN PAOLO CERVO",
    "state_code": "BI",
    "cab": "916361",
    "cap": "13812"
  },
  {
    "key": "13281",
    "ITA": "SAN PAOLO D'ARGON",
    "state_code": "BG",
    "cab": "538108",
    "cap": "24060"
  },
  {
    "key": "13282",
    "ITA": "SAN PAOLO DI CIVITATE",
    "state_code": "FG",
    "cab": "786202",
    "cap": "71010"
  },
  {
    "key": "13283",
    "ITA": "SAN PAOLO DI CIVITATE-DIFENSOLA",
    "state_code": "FG",
    "cab": "786202",
    "cap": "71010"
  },
  {
    "key": "13284",
    "ITA": "SAN PAOLO DI JESI",
    "state_code": "AN",
    "cab": "375709",
    "cap": "60038"
  },
  {
    "key": "13285",
    "ITA": "SAN PAOLO SOLBRITO",
    "state_code": "AT",
    "cab": "920413",
    "cap": "14010"
  },
  {
    "key": "13286",
    "ITA": "SAN PELLEGRINO TERME-SANTA CROCE",
    "state_code": "BG",
    "cab": "534503",
    "cap": "24016"
  },
  {
    "key": "13287",
    "ITA": "SAN PELLEGRINO TERME",
    "state_code": "BG",
    "cab": "534503",
    "cap": "24016"
  },
  {
    "key": "13288",
    "ITA": "SAN PIER D'ISONZO-CASSEGLIANO",
    "state_code": "GO",
    "cab": "564500",
    "cap": "34070"
  },
  {
    "key": "13289",
    "ITA": "SAN PIER D'ISONZO",
    "state_code": "GO",
    "cab": "564500",
    "cap": "34070"
  },
  {
    "key": "13290",
    "ITA": "SAN PIER NICETO",
    "state_code": "ME",
    "cab": "824706",
    "cap": "98045"
  },
  {
    "key": "13291",
    "ITA": "SAN PIER NICETO-SAN PIER MARINA",
    "state_code": "ME",
    "cab": "824706",
    "cap": "98045"
  },
  {
    "key": "13292",
    "ITA": "SAN PIERO A SIEVE",
    "state_code": "FI",
    "cab": "380709",
    "cap": "50037"
  },
  {
    "key": "13293",
    "ITA": "SAN PIERO PATTI-TESORIERO",
    "state_code": "ME",
    "cab": "824805",
    "cap": "98068"
  },
  {
    "key": "13294",
    "ITA": "SAN PIERO PATTI",
    "state_code": "ME",
    "cab": "824805",
    "cap": "98068"
  },
  {
    "key": "13295",
    "ITA": "SAN PIERO PATTI-FIUMARA",
    "state_code": "ME",
    "cab": "824805",
    "cap": "98068"
  },
  {
    "key": "13296",
    "ITA": "SAN PIETRO A MAIDA",
    "state_code": "CZ",
    "cab": "427203",
    "cap": "88025"
  },
  {
    "key": "13297",
    "ITA": "SAN PIETRO AL NATISONE",
    "state_code": "UD",
    "cab": "642306",
    "cap": "33049"
  },
  {
    "key": "13298",
    "ITA": "SAN PIETRO AL TANAGRO",
    "state_code": "SA",
    "cab": "953018",
    "cap": "84030"
  },
  {
    "key": "13299",
    "ITA": "SAN PIETRO APOSTOLO",
    "state_code": "CZ",
    "cab": "913426",
    "cap": "88040"
  },
  {
    "key": "13300",
    "ITA": "SAN PIETRO AVELLANA",
    "state_code": "IS",
    "cab": "955856",
    "cap": "86088"
  },
  {
    "key": "13301",
    "ITA": "SAN PIETRO CLARENZA",
    "state_code": "CT",
    "cab": "842203",
    "cap": "95030"
  },
  {
    "key": "13302",
    "ITA": "SAN PIETRO DI CADORE-PRESENAIO",
    "state_code": "BL",
    "cab": "708008",
    "cap": "32040"
  },
  {
    "key": "13303",
    "ITA": "SAN PIETRO DI CADORE-COSTALTA",
    "state_code": "BL",
    "cab": "708008",
    "cap": "32040"
  },
  {
    "key": "13304",
    "ITA": "SAN PIETRO DI CADORE",
    "state_code": "BL",
    "cab": "708008",
    "cap": "32040"
  },
  {
    "key": "13305",
    "ITA": "SAN PIETRO DI CARIDA'-SAN PIER FEDELE",
    "state_code": "RC",
    "cab": "960351",
    "cap": "89020"
  },
  {
    "key": "13306",
    "ITA": "SAN PIETRO DI CARIDA'",
    "state_code": "RC",
    "cab": "960351",
    "cap": "89020"
  },
  {
    "key": "13307",
    "ITA": "SAN PIETRO DI FELETTO-RUA",
    "state_code": "TV",
    "cab": "883108",
    "cap": "31020"
  },
  {
    "key": "13308",
    "ITA": "SAN PIETRO DI FELETTO",
    "state_code": "TV",
    "cab": "883108",
    "cap": "31020"
  },
  {
    "key": "13309",
    "ITA": "SAN PIETRO DI FELETTO-BAGNOLO",
    "state_code": "TV",
    "cab": "883108",
    "cap": "31020"
  },
  {
    "key": "13310",
    "ITA": "SAN PIETRO DI MORUBIO",
    "state_code": "VR",
    "cab": "598003",
    "cap": "37050"
  },
  {
    "key": "13311",
    "ITA": "SAN PIETRO DI MORUBIO-BONAVICINA",
    "state_code": "VR",
    "cab": "598003",
    "cap": "37050"
  },
  {
    "key": "13312",
    "ITA": "SAN PIETRO IN AMANTEA",
    "state_code": "CS",
    "cab": "270611",
    "cap": "87030"
  },
  {
    "key": "13313",
    "ITA": "SAN PIETRO IN CARIANO",
    "state_code": "VR",
    "cab": "598102",
    "cap": "37029"
  },
  {
    "key": "13314",
    "ITA": "SAN PIETRO IN CARIANO-BURE",
    "state_code": "VR",
    "cab": "598102",
    "cap": "37029"
  },
  {
    "key": "13315",
    "ITA": "SAN PIETRO IN CARIANO-CORRUBBIO",
    "state_code": "VR",
    "cab": "598102",
    "cap": "37029"
  },
  {
    "key": "13316",
    "ITA": "SAN PIETRO IN CARIANO-NEGARINE",
    "state_code": "VR",
    "cab": "598102",
    "cap": "37029"
  },
  {
    "key": "13317",
    "ITA": "SAN PIETRO IN CARIANO-PEDEMONTE",
    "state_code": "VR",
    "cab": "598102",
    "cap": "37029"
  },
  {
    "key": "13318",
    "ITA": "SAN PIETRO IN CARIANO-SAN FLORIANO",
    "state_code": "VR",
    "cab": "598102",
    "cap": "37029"
  },
  {
    "key": "13319",
    "ITA": "SAN PIETRO IN CASALE-MACCARETOLO",
    "state_code": "BO",
    "cab": "370809",
    "cap": "40018"
  },
  {
    "key": "13320",
    "ITA": "SAN PIETRO IN CASALE",
    "state_code": "BO",
    "cab": "370809",
    "cap": "40018"
  },
  {
    "key": "13321",
    "ITA": "SAN PIETRO IN CERRO",
    "state_code": "PC",
    "cab": "938688",
    "cap": "29010"
  },
  {
    "key": "13322",
    "ITA": "SAN PIETRO IN GU",
    "state_code": "PD",
    "cab": "628305",
    "cap": "35010"
  },
  {
    "key": "13323",
    "ITA": "SAN PIETRO IN GUARANO",
    "state_code": "CS",
    "cab": "959346",
    "cap": "87047"
  },
  {
    "key": "13324",
    "ITA": "SAN PIETRO IN GUARANO-REDIPIANO",
    "state_code": "CS",
    "cab": "959346",
    "cap": "87047"
  },
  {
    "key": "13325",
    "ITA": "SAN PIETRO IN GUARANO-SAN BENEDETTO IN GUARANO",
    "state_code": "CS",
    "cab": "959346",
    "cap": "87047"
  },
  {
    "key": "13326",
    "ITA": "SAN PIETRO IN LAMA",
    "state_code": "LE",
    "cab": "799809",
    "cap": "73010"
  },
  {
    "key": "13327",
    "ITA": "SAN PIETRO INFINE",
    "state_code": "CE",
    "cab": "949610",
    "cap": "81049"
  },
  {
    "key": "13328",
    "ITA": "SAN PIETRO MOSEZZO-NIBBIA",
    "state_code": "NO",
    "cab": "457804",
    "cap": "28060"
  },
  {
    "key": "13329",
    "ITA": "SAN PIETRO MOSEZZO",
    "state_code": "NO",
    "cab": "457804",
    "cap": "28060"
  },
  {
    "key": "13330",
    "ITA": "SAN PIETRO MUSSOLINO-SAN PIETRO VECCHIO",
    "state_code": "VI",
    "cab": "609107",
    "cap": "36070"
  },
  {
    "key": "13331",
    "ITA": "SAN PIETRO MUSSOLINO",
    "state_code": "VI",
    "cab": "609107",
    "cap": "36070"
  },
  {
    "key": "13332",
    "ITA": "SAN PIETRO VAL LEMINA",
    "state_code": "TO",
    "cab": "791103",
    "cap": "10060"
  },
  {
    "key": "13333",
    "ITA": "SAN PIETRO VERNOTICO",
    "state_code": "BR",
    "cab": "792705",
    "cap": "72027"
  },
  {
    "key": "13334",
    "ITA": "SAN PIETRO VIMINARIO",
    "state_code": "PD",
    "cab": "720904",
    "cap": "35020"
  },
  {
    "key": "13335",
    "ITA": "SAN PIO DELLE CAMERE-CASTELNUOVO",
    "state_code": "AQ",
    "cab": "909911",
    "cap": "67020"
  },
  {
    "key": "13336",
    "ITA": "SAN PIO DELLE CAMERE",
    "state_code": "AQ",
    "cab": "909911",
    "cap": "67020"
  },
  {
    "key": "13337",
    "ITA": "SAN POLO D'ENZA",
    "state_code": "RE",
    "cab": "664904",
    "cap": "42020"
  },
  {
    "key": "13338",
    "ITA": "SAN POLO DEI CAVALIERI",
    "state_code": "RM",
    "cab": "394007",
    "cap": "10"
  },
  {
    "key": "13339",
    "ITA": "SAN POLO DI PIAVE",
    "state_code": "TV",
    "cab": "620401",
    "cap": "31020"
  },
  {
    "key": "13340",
    "ITA": "SAN POLO MATESE",
    "state_code": "CB",
    "cab": "910968",
    "cap": "86020"
  },
  {
    "key": "13341",
    "ITA": "SAN PONSO",
    "state_code": "TO",
    "cab": "284745",
    "cap": "10080"
  },
  {
    "key": "13342",
    "ITA": "SAN POSSIDONIO",
    "state_code": "MO",
    "cab": "669903",
    "cap": "41039"
  },
  {
    "key": "13343",
    "ITA": "SAN POTITO SANNITICO",
    "state_code": "CE",
    "cab": "949628",
    "cap": "81016"
  },
  {
    "key": "13344",
    "ITA": "SAN POTITO ULTRA",
    "state_code": "AV",
    "cab": "951574",
    "cap": "83050"
  },
  {
    "key": "13345",
    "ITA": "SAN PRISCO",
    "state_code": "CE",
    "cab": "750208",
    "cap": "81054"
  },
  {
    "key": "13346",
    "ITA": "SAN PROCOPIO",
    "state_code": "RC",
    "cab": "960369",
    "cap": "89020"
  },
  {
    "key": "13347",
    "ITA": "SAN PROSPERO-SAN PIETRO",
    "state_code": "MO",
    "cab": "670000",
    "cap": "41030"
  },
  {
    "key": "13348",
    "ITA": "SAN PROSPERO-SAN MARTINO DI SAN PROSPERO",
    "state_code": "MO",
    "cab": "670000",
    "cap": "41030"
  },
  {
    "key": "13349",
    "ITA": "SAN PROSPERO-SAN LORENZO DELLA PIOPPA",
    "state_code": "MO",
    "cab": "670000",
    "cap": "41030"
  },
  {
    "key": "13350",
    "ITA": "SAN PROSPERO-STAGGIA",
    "state_code": "MO",
    "cab": "670000",
    "cap": "41030"
  },
  {
    "key": "13351",
    "ITA": "SAN PROSPERO",
    "state_code": "MO",
    "cab": "670000",
    "cap": "41030"
  },
  {
    "key": "13352",
    "ITA": "SAN QUIRICO D'ORCIA-BAGNO VIGNONI",
    "state_code": "SI",
    "cab": "720201",
    "cap": "53027"
  },
  {
    "key": "13353",
    "ITA": "SAN QUIRICO D'ORCIA",
    "state_code": "SI",
    "cab": "720201",
    "cap": "53027"
  },
  {
    "key": "13354",
    "ITA": "SAN QUIRINO-SAN FOCA",
    "state_code": "PN",
    "cab": "650804",
    "cap": "33080"
  },
  {
    "key": "13355",
    "ITA": "SAN QUIRINO-SEDRANO",
    "state_code": "PN",
    "cab": "650804",
    "cap": "33080"
  },
  {
    "key": "13356",
    "ITA": "SAN QUIRINO",
    "state_code": "PN",
    "cab": "650804",
    "cap": "33080"
  },
  {
    "key": "13357",
    "ITA": "SAN RAFFAELE CIMENA",
    "state_code": "TO",
    "cab": "412809",
    "cap": "10090"
  },
  {
    "key": "13358",
    "ITA": "SAN RAFFAELE CIMENA-PIANA SAN RAFFAELE",
    "state_code": "TO",
    "cab": "412809",
    "cap": "10090"
  },
  {
    "key": "13359",
    "ITA": "SAN REMO-BUSSANA",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13360",
    "ITA": "SAN REMO-COLDIRODI",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13361",
    "ITA": "SAN REMO-POGGIO",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13362",
    "ITA": "SAN REMO-SAN BARTOLOMEO",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13363",
    "ITA": "SAN REMO-SAN GIACOMO",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13364",
    "ITA": "SAN REMO-SAN ROMOLO",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13365",
    "ITA": "SAN REMO",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13366",
    "ITA": "SAN REMO-VEREZZO",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13367",
    "ITA": "SAN REMO-BORELLO",
    "state_code": "IM",
    "cab": "227009",
    "cap": "18038"
  },
  {
    "key": "13368",
    "ITA": "SAN ROBERTO",
    "state_code": "RC",
    "cab": "816603",
    "cap": "89050"
  },
  {
    "key": "13369",
    "ITA": "SAN ROBERTO-COLELLI",
    "state_code": "RC",
    "cab": "816603",
    "cap": "89050"
  },
  {
    "key": "13370",
    "ITA": "SAN ROBERTO-ACQUACALDA",
    "state_code": "RC",
    "cab": "816603",
    "cap": "89050"
  },
  {
    "key": "13371",
    "ITA": "SAN ROCCO AL PORTO",
    "state_code": "LO",
    "cab": "337501",
    "cap": "26865"
  },
  {
    "key": "13372",
    "ITA": "SAN ROMANO IN GARFAGNANA",
    "state_code": "LU",
    "cab": "708701",
    "cap": "55038"
  },
  {
    "key": "13373",
    "ITA": "SAN RUFO",
    "state_code": "SA",
    "cab": "565705",
    "cap": "84030"
  },
  {
    "key": "13374",
    "ITA": "SAN SALVATORE DI FITALIA",
    "state_code": "ME",
    "cab": "961573",
    "cap": "98070"
  },
  {
    "key": "13375",
    "ITA": "SAN SALVATORE MONFERRATO",
    "state_code": "AL",
    "cab": "485805",
    "cap": "15046"
  },
  {
    "key": "13376",
    "ITA": "SAN SALVATORE TELESINO",
    "state_code": "BN",
    "cab": "754804",
    "cap": "82030"
  },
  {
    "key": "13377",
    "ITA": "SAN SALVO-SAN SALVO MARINA",
    "state_code": "CH",
    "cab": "778506",
    "cap": "66050"
  },
  {
    "key": "13378",
    "ITA": "SAN SALVO",
    "state_code": "CH",
    "cab": "778506",
    "cap": "66050"
  },
  {
    "key": "13379",
    "ITA": "SAN SEBASTIANO AL VESUVIO",
    "state_code": "NA",
    "cab": "403303",
    "cap": "80040"
  },
  {
    "key": "13380",
    "ITA": "SAN SEBASTIANO CURONE",
    "state_code": "AL",
    "cab": "485904",
    "cap": "15056"
  },
  {
    "key": "13381",
    "ITA": "SAN SEBASTIANO DA PO",
    "state_code": "TO",
    "cab": "574202",
    "cap": "10020"
  },
  {
    "key": "13382",
    "ITA": "SAN SEBASTIANO DA PO-COLOMBARO",
    "state_code": "TO",
    "cab": "574202",
    "cap": "10020"
  },
  {
    "key": "13383",
    "ITA": "SAN SECONDO DI PINEROLO-MIRADOLO",
    "state_code": "TO",
    "cab": "309500",
    "cap": "10060"
  },
  {
    "key": "13384",
    "ITA": "SAN SECONDO DI PINEROLO",
    "state_code": "TO",
    "cab": "309500",
    "cap": "10060"
  },
  {
    "key": "13385",
    "ITA": "SAN SECONDO PARMENSE",
    "state_code": "PR",
    "cab": "659102",
    "cap": "43017"
  },
  {
    "key": "13386",
    "ITA": "SAN SEVERINO LUCANO-VILLANETO",
    "state_code": "PZ",
    "cab": "716902",
    "cap": "85030"
  },
  {
    "key": "13387",
    "ITA": "SAN SEVERINO LUCANO",
    "state_code": "PZ",
    "cab": "716902",
    "cap": "85030"
  },
  {
    "key": "13388",
    "ITA": "SAN SEVERINO LUCANO-MEZZANA SALICE",
    "state_code": "PZ",
    "cab": "716902",
    "cap": "85030"
  },
  {
    "key": "13389",
    "ITA": "SAN SEVERINO MARCHE-CASTEL SAN PIETRO",
    "state_code": "MC",
    "cab": "691501",
    "cap": "62027"
  },
  {
    "key": "13390",
    "ITA": "SAN SEVERINO MARCHE-CESOLO",
    "state_code": "MC",
    "cab": "691501",
    "cap": "62027"
  },
  {
    "key": "13391",
    "ITA": "SAN SEVERINO MARCHE",
    "state_code": "MC",
    "cab": "691501",
    "cap": "62027"
  },
  {
    "key": "13392",
    "ITA": "SAN SEVERO",
    "state_code": "FG",
    "cab": "786301",
    "cap": "71016"
  },
  {
    "key": "13393",
    "ITA": "SAN SIRO-REZZONICO",
    "state_code": "CO",
    "cab": "851600",
    "cap": "22010"
  },
  {
    "key": "13394",
    "ITA": "SAN SIRO-SANT'ABBONDIO",
    "state_code": "CO",
    "cab": "851600",
    "cap": "22010"
  },
  {
    "key": "13395",
    "ITA": "SAN SIRO-SANTA MARIA",
    "state_code": "CO",
    "cab": "851600",
    "cap": "22010"
  },
  {
    "key": "13396",
    "ITA": "SAN SIRO-ACQUASERIA",
    "state_code": "CO",
    "cab": "851600",
    "cap": "22010"
  },
  {
    "key": "13397",
    "ITA": "SAN SIRO",
    "state_code": "CO",
    "cab": "851600",
    "cap": "22010"
  },
  {
    "key": "13398",
    "ITA": "SAN SOSSIO BARONIA",
    "state_code": "AV",
    "cab": "951582",
    "cap": "83050"
  },
  {
    "key": "13399",
    "ITA": "SAN SOSTENE-SAN SOSTENE MARINA",
    "state_code": "CZ",
    "cab": "913434",
    "cap": "88060"
  },
  {
    "key": "13400",
    "ITA": "SAN SOSTENE",
    "state_code": "CZ",
    "cab": "913434",
    "cap": "88060"
  },
  {
    "key": "13401",
    "ITA": "SAN SOSTI",
    "state_code": "CS",
    "cab": "809905",
    "cap": "87010"
  },
  {
    "key": "13402",
    "ITA": "SAN SPERATE",
    "state_code": "CA",
    "cab": "440909",
    "cap": "9026"
  },
  {
    "key": "13403",
    "ITA": "SAN TAMMARO",
    "state_code": "CE",
    "cab": "495606",
    "cap": "81050"
  },
  {
    "key": "13404",
    "ITA": "SAN TEODORO",
    "state_code": "ME",
    "cab": "899203",
    "cap": "98030"
  },
  {
    "key": "13405",
    "ITA": "SAN TEODORO",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13406",
    "ITA": "SAN TEODORO-BADUALGA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13407",
    "ITA": "SAN TEODORO-TRAVERSA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13408",
    "ITA": "SAN TEODORO-TIRIDDULI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13409",
    "ITA": "SAN TEODORO-BUDDITOGLIU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13410",
    "ITA": "SAN TEODORO-CAPO CODA CAVALLO",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13411",
    "ITA": "SAN TEODORO-FRANCULACCIU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13412",
    "ITA": "SAN TEODORO-LA RUNCINA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13413",
    "ITA": "SAN TEODORO-L'ALZONI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13414",
    "ITA": "SAN TEODORO-LI MORI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13415",
    "ITA": "SAN TEODORO-LI TEGGI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13416",
    "ITA": "SAN TEODORO-LU CUPONEDDI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13417",
    "ITA": "SAN TEODORO-LU FRAILI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13418",
    "ITA": "SAN TEODORO-LU IMPOSTU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13419",
    "ITA": "SAN TEODORO-LU LIONI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13420",
    "ITA": "SAN TEODORO-LU MUVRUNEDDU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13421",
    "ITA": "SAN TEODORO-LU RICCIU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13422",
    "ITA": "SAN TEODORO-LU TITIMBARU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13423",
    "ITA": "SAN TEODORO-LUTTURAI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13424",
    "ITA": "SAN TEODORO-MONTE PETROSU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13425",
    "ITA": "SAN TEODORO-NURAGHEDDU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13426",
    "ITA": "SAN TEODORO-PATTIMEDDA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13427",
    "ITA": "SAN TEODORO-PIRA MASEDA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13428",
    "ITA": "SAN TEODORO-PUNTALDIA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13429",
    "ITA": "SAN TEODORO-RINAGGIU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13430",
    "ITA": "SAN TEODORO-SCHIFONI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13431",
    "ITA": "SAN TEODORO-SILIMINI",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13432",
    "ITA": "SAN TEODORO-SITAGLIACCIU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13433",
    "ITA": "SAN TEODORO-STAZZU BRUCIATU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13434",
    "ITA": "SAN TEODORO-STAZZU MESU",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13435",
    "ITA": "SAN TEODORO-STRAULAS",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13436",
    "ITA": "SAN TEODORO-SUAREDDA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13437",
    "ITA": "SAN TEODORO-TERRAPADEDDA",
    "state_code": "OT",
    "cab": "854208",
    "cap": "8020"
  },
  {
    "key": "13438",
    "ITA": "SAN TOMASO AGORDINO",
    "state_code": "BL",
    "cab": "934778",
    "cap": "32020"
  },
  {
    "key": "13439",
    "ITA": "SAN TOMASO AGORDINO-AVOSCAN",
    "state_code": "BL",
    "cab": "934778",
    "cap": "32020"
  },
  {
    "key": "13440",
    "ITA": "SAN VALENTINO IN ABRUZZO CITERIORE",
    "state_code": "PE",
    "cab": "774109",
    "cap": "65020"
  },
  {
    "key": "13441",
    "ITA": "SAN VALENTINO TORIO",
    "state_code": "SA",
    "cab": "764605",
    "cap": "84010"
  },
  {
    "key": "13442",
    "ITA": "SAN VENANZO",
    "state_code": "TR",
    "cab": "727503",
    "cap": "5010"
  },
  {
    "key": "13443",
    "ITA": "SAN VENANZO-COLLELUNGO",
    "state_code": "TR",
    "cab": "727503",
    "cap": "5010"
  },
  {
    "key": "13444",
    "ITA": "SAN VENANZO-OSPEDALETTO",
    "state_code": "TR",
    "cab": "727503",
    "cap": "5010"
  },
  {
    "key": "13445",
    "ITA": "SAN VENANZO-PORNELLO",
    "state_code": "TR",
    "cab": "727503",
    "cap": "5010"
  },
  {
    "key": "13446",
    "ITA": "SAN VENANZO-RIPALVELLA",
    "state_code": "TR",
    "cab": "727503",
    "cap": "5010"
  },
  {
    "key": "13447",
    "ITA": "SAN VENANZO-SAN VITO IN MONTE",
    "state_code": "TR",
    "cab": "727503",
    "cap": "5010"
  },
  {
    "key": "13448",
    "ITA": "SAN VENANZO-POGGIO AQUILONE",
    "state_code": "TR",
    "cab": "727503",
    "cap": "5010"
  },
  {
    "key": "13449",
    "ITA": "SAN VENDEMIANO-ZOPPE'",
    "state_code": "TV",
    "cab": "620609",
    "cap": "31020"
  },
  {
    "key": "13450",
    "ITA": "SAN VENDEMIANO-FOSSAMERLO",
    "state_code": "TV",
    "cab": "620609",
    "cap": "31020"
  },
  {
    "key": "13451",
    "ITA": "SAN VENDEMIANO-COSNIGA",
    "state_code": "TV",
    "cab": "620609",
    "cap": "31020"
  },
  {
    "key": "13452",
    "ITA": "SAN VENDEMIANO",
    "state_code": "TV",
    "cab": "620609",
    "cap": "31020"
  },
  {
    "key": "13453",
    "ITA": "SAN VERO MILIS-MANDRIOLA",
    "state_code": "OR",
    "cab": "880104",
    "cap": "9070"
  },
  {
    "key": "13454",
    "ITA": "SAN VERO MILIS-SA ROCCA TUNDA",
    "state_code": "OR",
    "cab": "880104",
    "cap": "9070"
  },
  {
    "key": "13455",
    "ITA": "SAN VERO MILIS-PUTZU IDU",
    "state_code": "OR",
    "cab": "880104",
    "cap": "9070"
  },
  {
    "key": "13456",
    "ITA": "SAN VERO MILIS",
    "state_code": "OR",
    "cab": "880104",
    "cap": "9070"
  },
  {
    "key": "13457",
    "ITA": "SAN VINCENZO-SAN CARLO",
    "state_code": "LI",
    "cab": "707703",
    "cap": "57027"
  },
  {
    "key": "13458",
    "ITA": "SAN VINCENZO",
    "state_code": "LI",
    "cab": "707703",
    "cap": "57027"
  },
  {
    "key": "13459",
    "ITA": "SAN VINCENZO LA COSTA",
    "state_code": "CS",
    "cab": "810002",
    "cap": "87030"
  },
  {
    "key": "13460",
    "ITA": "SAN VINCENZO LA COSTA-GESUITI",
    "state_code": "CS",
    "cab": "810002",
    "cap": "87030"
  },
  {
    "key": "13461",
    "ITA": "SAN VINCENZO VALLE ROVETO",
    "state_code": "AQ",
    "cab": "885004",
    "cap": "67050"
  },
  {
    "key": "13462",
    "ITA": "SAN VINCENZO VALLE ROVETO-SAN VINCENZO VECCHIO",
    "state_code": "AQ",
    "cab": "885004",
    "cap": "67050"
  },
  {
    "key": "13463",
    "ITA": "SAN VINCENZO VALLE ROVETO-SAN VINCENZO VALLE ROVETO SUPERIORE",
    "state_code": "AQ",
    "cab": "885004",
    "cap": "67050"
  },
  {
    "key": "13464",
    "ITA": "SAN VINCENZO VALLE ROVETO-ROCCAVIVI",
    "state_code": "AQ",
    "cab": "885004",
    "cap": "67050"
  },
  {
    "key": "13465",
    "ITA": "SAN VINCENZO VALLE ROVETO-CASTRONOVO",
    "state_code": "AQ",
    "cab": "885004",
    "cap": "67050"
  },
  {
    "key": "13466",
    "ITA": "SAN VITALIANO",
    "state_code": "NA",
    "cab": "403808",
    "cap": "80030"
  },
  {
    "key": "13467",
    "ITA": "SAN VITO",
    "state_code": "CA",
    "cab": "861609",
    "cap": "9040"
  },
  {
    "key": "13468",
    "ITA": "SAN VITO AL TAGLIAMENTO-SAVORGNANO",
    "state_code": "PN",
    "cab": "650101",
    "cap": "33078"
  },
  {
    "key": "13469",
    "ITA": "SAN VITO AL TAGLIAMENTO-GLERIS",
    "state_code": "PN",
    "cab": "650101",
    "cap": "33078"
  },
  {
    "key": "13470",
    "ITA": "SAN VITO AL TAGLIAMENTO",
    "state_code": "PN",
    "cab": "650101",
    "cap": "33078"
  },
  {
    "key": "13471",
    "ITA": "SAN VITO AL TORRE",
    "state_code": "UD",
    "cab": "642405",
    "cap": "33050"
  },
  {
    "key": "13472",
    "ITA": "SAN VITO CHIETINO-SANT'APOLLINARE CHIETINO",
    "state_code": "CH",
    "cab": "778605",
    "cap": "66038"
  },
  {
    "key": "13473",
    "ITA": "SAN VITO CHIETINO",
    "state_code": "CH",
    "cab": "778605",
    "cap": "66038"
  },
  {
    "key": "13474",
    "ITA": "SAN VITO CHIETINO-MARINA DI SAN VITO",
    "state_code": "CH",
    "cab": "778605",
    "cap": "66038"
  },
  {
    "key": "13475",
    "ITA": "SAN VITO DEI NORMANNI",
    "state_code": "BR",
    "cab": "792804",
    "cap": "72019"
  },
  {
    "key": "13476",
    "ITA": "SAN VITO DI CADORE-CHIAPUZZA",
    "state_code": "BL",
    "cab": "612903",
    "cap": "32046"
  },
  {
    "key": "13477",
    "ITA": "SAN VITO DI CADORE",
    "state_code": "BL",
    "cab": "612903",
    "cap": "32046"
  },
  {
    "key": "13478",
    "ITA": "SAN VITO DI FAGAGNA-SILVELLA",
    "state_code": "UD",
    "cab": "459008",
    "cap": "33030"
  },
  {
    "key": "13479",
    "ITA": "SAN VITO DI FAGAGNA",
    "state_code": "UD",
    "cab": "459008",
    "cap": "33030"
  },
  {
    "key": "13480",
    "ITA": "SAN VITO DI LEGUZZANO",
    "state_code": "VI",
    "cab": "607200",
    "cap": "36030"
  },
  {
    "key": "13481",
    "ITA": "SAN VITO LO CAPO",
    "state_code": "TP",
    "cab": "819607",
    "cap": "91010"
  },
  {
    "key": "13482",
    "ITA": "SAN VITO LO CAPO-CASTELLUZZO",
    "state_code": "TP",
    "cab": "819607",
    "cap": "91010"
  },
  {
    "key": "13483",
    "ITA": "SAN VITO LO CAPO-MACARI",
    "state_code": "TP",
    "cab": "819607",
    "cap": "91010"
  },
  {
    "key": "13484",
    "ITA": "SAN VITO ROMANO",
    "state_code": "RM",
    "cab": "394205",
    "cap": "30"
  },
  {
    "key": "13485",
    "ITA": "SAN VITO SULLO IONIO",
    "state_code": "CZ",
    "cab": "429407",
    "cap": "88067"
  },
  {
    "key": "13486",
    "ITA": "SAN VITTORE DEL LAZIO",
    "state_code": "FR",
    "cab": "747105",
    "cap": "3040"
  },
  {
    "key": "13487",
    "ITA": "SAN VITTORE OLONA",
    "state_code": "MI",
    "cab": "337907",
    "cap": "20028"
  },
  {
    "key": "13488",
    "ITA": "SAN ZENO DI MONTAGNA",
    "state_code": "VR",
    "cab": "598409",
    "cap": "37010"
  },
  {
    "key": "13489",
    "ITA": "SAN ZENO DI MONTAGNA-SAN ZENO",
    "state_code": "VR",
    "cab": "598409",
    "cap": "37010"
  },
  {
    "key": "13490",
    "ITA": "SAN ZENO NAVIGLIO",
    "state_code": "BS",
    "cab": "552109",
    "cap": "25010"
  },
  {
    "key": "13491",
    "ITA": "SAN ZENONE AL LAMBRO-VILLA BISSONE",
    "state_code": "MI",
    "cab": "342501",
    "cap": "20070"
  },
  {
    "key": "13492",
    "ITA": "SAN ZENONE AL LAMBRO",
    "state_code": "MI",
    "cab": "342501",
    "cap": "20070"
  },
  {
    "key": "13493",
    "ITA": "SAN ZENONE AL PO",
    "state_code": "PV",
    "cab": "562603",
    "cap": "27010"
  },
  {
    "key": "13494",
    "ITA": "SAN ZENONE DEGLI EZZELINI-LIEDOLO",
    "state_code": "TV",
    "cab": "620708",
    "cap": "31020"
  },
  {
    "key": "13495",
    "ITA": "SAN ZENONE DEGLI EZZELINI-CA' RAINATI",
    "state_code": "TV",
    "cab": "620708",
    "cap": "31020"
  },
  {
    "key": "13496",
    "ITA": "SAN ZENONE DEGLI EZZELINI",
    "state_code": "TV",
    "cab": "620708",
    "cap": "31020"
  },
  {
    "key": "13497",
    "ITA": "SANARICA",
    "state_code": "LE",
    "cab": "957704",
    "cap": "73030"
  },
  {
    "key": "13498",
    "ITA": "SANDIGLIANO",
    "state_code": "BI",
    "cab": "447508",
    "cap": "13876"
  },
  {
    "key": "13499",
    "ITA": "SANDRIGO",
    "state_code": "VI",
    "cab": "607101",
    "cap": "36066"
  },
  {
    "key": "13500",
    "ITA": "SANFRE'",
    "state_code": "CN",
    "cab": "467902",
    "cap": "12040"
  },
  {
    "key": "13501",
    "ITA": "SANFRONT",
    "state_code": "CN",
    "cab": "468009",
    "cap": "12030"
  },
  {
    "key": "13502",
    "ITA": "SANGANO",
    "state_code": "TO",
    "cab": "313205",
    "cap": "10090"
  },
  {
    "key": "13503",
    "ITA": "SANGIANO",
    "state_code": "VA",
    "cab": "924787",
    "cap": "21038"
  },
  {
    "key": "13504",
    "ITA": "SANGINETO",
    "state_code": "CS",
    "cab": "959270",
    "cap": "87020"
  },
  {
    "key": "13505",
    "ITA": "SANGINETO-SANGINETO LIDO",
    "state_code": "CS",
    "cab": "959270",
    "cap": "87020"
  },
  {
    "key": "13506",
    "ITA": "SANGINETO-LE CRETE",
    "state_code": "CS",
    "cab": "959270",
    "cap": "87020"
  },
  {
    "key": "13507",
    "ITA": "SANGUINETTO",
    "state_code": "VR",
    "cab": "597807",
    "cap": "37058"
  },
  {
    "key": "13508",
    "ITA": "SANLURI-SANLURI STATO",
    "state_code": "SU",
    "cab": "439703",
    "cap": "9025"
  },
  {
    "key": "13509",
    "ITA": "SANLURI",
    "state_code": "SU",
    "cab": "439703",
    "cap": "9025"
  },
  {
    "key": "13510",
    "ITA": "SANNAZZARO DE' BURGONDI",
    "state_code": "PV",
    "cab": "562207",
    "cap": "27039"
  },
  {
    "key": "13511",
    "ITA": "SANNICANDRO DI BARI",
    "state_code": "BA",
    "cab": "416701",
    "cap": "70028"
  },
  {
    "key": "13512",
    "ITA": "SANNICOLA-SAN SIMONE",
    "state_code": "LE",
    "cab": "799700",
    "cap": "73017"
  },
  {
    "key": "13513",
    "ITA": "SANNICOLA",
    "state_code": "LE",
    "cab": "799700",
    "cap": "73017"
  },
  {
    "key": "13514",
    "ITA": "SANNICOLA-CHIESANUOVA",
    "state_code": "LE",
    "cab": "799700",
    "cap": "73017"
  },
  {
    "key": "13515",
    "ITA": "SANNICOLA-LIDO CONCHIGLIE",
    "state_code": "LE",
    "cab": "799700",
    "cap": "73017"
  },
  {
    "key": "13516",
    "ITA": "SANSEPOLCRO-GRICIGNANO",
    "state_code": "AR",
    "cab": "716100",
    "cap": "52037"
  },
  {
    "key": "13517",
    "ITA": "SANSEPOLCRO",
    "state_code": "AR",
    "cab": "716100",
    "cap": "52037"
  },
  {
    "key": "13518",
    "ITA": "SANSEPOLCRO-SANTA FIORA",
    "state_code": "AR",
    "cab": "716100",
    "cap": "52037"
  },
  {
    "key": "13519",
    "ITA": "SANT'AGAPITO-SANT'AGAPITO SCALO",
    "state_code": "IS",
    "cab": "955864",
    "cap": "86070"
  },
  {
    "key": "13520",
    "ITA": "SANT'AGAPITO",
    "state_code": "IS",
    "cab": "955864",
    "cap": "86070"
  },
  {
    "key": "13521",
    "ITA": "SANT'AGATA BOLOGNESE",
    "state_code": "BO",
    "cab": "370908",
    "cap": "40019"
  },
  {
    "key": "13522",
    "ITA": "SANT'AGATA DE' GOTI-LAIANO",
    "state_code": "BN",
    "cab": "754903",
    "cap": "82019"
  },
  {
    "key": "13523",
    "ITA": "SANT'AGATA DE' GOTI",
    "state_code": "BN",
    "cab": "754903",
    "cap": "82019"
  },
  {
    "key": "13524",
    "ITA": "SANT'AGATA DE' GOTI-BAGNOLI",
    "state_code": "BN",
    "cab": "754903",
    "cap": "82019"
  },
  {
    "key": "13525",
    "ITA": "SANT'AGATA DE' GOTI-FAGGIANO",
    "state_code": "BN",
    "cab": "754903",
    "cap": "82019"
  },
  {
    "key": "13526",
    "ITA": "SANT'AGATA DEL BIANCO",
    "state_code": "RC",
    "cab": "960393",
    "cap": "89030"
  },
  {
    "key": "13527",
    "ITA": "SANT'AGATA DI ESARO",
    "state_code": "CS",
    "cab": "959379",
    "cap": "87010"
  },
  {
    "key": "13528",
    "ITA": "SANT'AGATA DI MILITELLO-VALLEBRUCA",
    "state_code": "ME",
    "cab": "824904",
    "cap": "98076"
  },
  {
    "key": "13529",
    "ITA": "SANT'AGATA DI MILITELLO",
    "state_code": "ME",
    "cab": "824904",
    "cap": "98076"
  },
  {
    "key": "13530",
    "ITA": "SANT'AGATA DI MILITELLO-TORRECANDELE",
    "state_code": "ME",
    "cab": "824904",
    "cap": "98076"
  },
  {
    "key": "13531",
    "ITA": "SANT'AGATA DI PUGLIA",
    "state_code": "FG",
    "cab": "786400",
    "cap": "71028"
  },
  {
    "key": "13532",
    "ITA": "SANT'AGATA FELTRIA",
    "state_code": "RN",
    "cab": "685909",
    "cap": "61019"
  },
  {
    "key": "13533",
    "ITA": "SANT'AGATA FELTRIA-SAN DONATO",
    "state_code": "RN",
    "cab": "685909",
    "cap": "61019"
  },
  {
    "key": "13534",
    "ITA": "SANT'AGATA FOSSILI",
    "state_code": "AL",
    "cab": "921676",
    "cap": "15050"
  },
  {
    "key": "13535",
    "ITA": "SANT'AGATA LI BATTIATI",
    "state_code": "CT",
    "cab": "842302",
    "cap": "95030"
  },
  {
    "key": "13536",
    "ITA": "SANT'AGATA SUL SANTERNO",
    "state_code": "RA",
    "cab": "676007",
    "cap": "48020"
  },
  {
    "key": "13537",
    "ITA": "SANT'AGNELLO-COLLI DI FONTANELLE",
    "state_code": "NA",
    "cab": "401802",
    "cap": "80065"
  },
  {
    "key": "13538",
    "ITA": "SANT'AGNELLO",
    "state_code": "NA",
    "cab": "401802",
    "cap": "80065"
  },
  {
    "key": "13539",
    "ITA": "SANT'AGOSTINO",
    "state_code": "FE",
    "cab": "673400",
    "cap": "44047"
  },
  {
    "key": "13540",
    "ITA": "SANT'AGOSTINO-SAN CARLO",
    "state_code": "FE",
    "cab": "673400",
    "cap": "44047"
  },
  {
    "key": "13541",
    "ITA": "SANT'AGOSTINO-DOSSO",
    "state_code": "FE",
    "cab": "673400",
    "cap": "44047"
  },
  {
    "key": "13542",
    "ITA": "SANT'ALBANO STURA",
    "state_code": "CN",
    "cab": "468207",
    "cap": "12040"
  },
  {
    "key": "13543",
    "ITA": "SANT'ALESSIO CON VIALONE",
    "state_code": "PV",
    "cab": "930859",
    "cap": "27016"
  },
  {
    "key": "13544",
    "ITA": "SANT'ALESSIO IN ASPROMONTE",
    "state_code": "RC",
    "cab": "960401",
    "cap": "89050"
  },
  {
    "key": "13545",
    "ITA": "SANT'ALESSIO SICULO",
    "state_code": "ME",
    "cab": "827006",
    "cap": "98030"
  },
  {
    "key": "13546",
    "ITA": "SANT'ALFIO",
    "state_code": "CT",
    "cab": "842401",
    "cap": "95010"
  },
  {
    "key": "13547",
    "ITA": "SANT'AMBROGIO DI TORINO",
    "state_code": "TO",
    "cab": "309609",
    "cap": "10057"
  },
  {
    "key": "13548",
    "ITA": "SANT'AMBROGIO DI VALPOLICELLA",
    "state_code": "VR",
    "cab": "598201",
    "cap": "37015"
  },
  {
    "key": "13549",
    "ITA": "SANT'AMBROGIO DI VALPOLICELLA-GARGAGNAGO",
    "state_code": "VR",
    "cab": "598201",
    "cap": "37015"
  },
  {
    "key": "13550",
    "ITA": "SANT'AMBROGIO DI VALPOLICELLA-DOMEGLIARA",
    "state_code": "VR",
    "cab": "598201",
    "cap": "37015"
  },
  {
    "key": "13551",
    "ITA": "SANT'AMBROGIO DI VALPOLICELLA-MONTE",
    "state_code": "VR",
    "cab": "598201",
    "cap": "37015"
  },
  {
    "key": "13552",
    "ITA": "SANT'AMBROGIO SUL GARIGLIANO",
    "state_code": "FR",
    "cab": "948513",
    "cap": "3040"
  },
  {
    "key": "13553",
    "ITA": "SANT'ANASTASIA-PONTE DI FERRO",
    "state_code": "NA",
    "cab": "401901",
    "cap": "80048"
  },
  {
    "key": "13554",
    "ITA": "SANT'ANASTASIA-STARZA VECCHIA",
    "state_code": "NA",
    "cab": "401901",
    "cap": "80048"
  },
  {
    "key": "13555",
    "ITA": "SANT'ANASTASIA-MADONNA DELL'ARCO",
    "state_code": "NA",
    "cab": "401901",
    "cap": "80048"
  },
  {
    "key": "13556",
    "ITA": "SANT'ANASTASIA",
    "state_code": "NA",
    "cab": "401901",
    "cap": "80048"
  },
  {
    "key": "13557",
    "ITA": "SANT'ANATOLIA DI NARCO",
    "state_code": "PG",
    "cab": "907634",
    "cap": "6040"
  },
  {
    "key": "13558",
    "ITA": "SANT'ANDREA APOSTOLO DELLO IONIO",
    "state_code": "CZ",
    "cab": "913459",
    "cap": "88060"
  },
  {
    "key": "13559",
    "ITA": "SANT'ANDREA APOSTOLO DELLO IONIO-SANT'ANDREA IONIO MARINA",
    "state_code": "CZ",
    "cab": "913459",
    "cap": "88060"
  },
  {
    "key": "13560",
    "ITA": "SANT'ANDREA DEL GARIGLIANO",
    "state_code": "FR",
    "cab": "948521",
    "cap": "3040"
  },
  {
    "key": "13561",
    "ITA": "SANT'ANDREA DI CONZA",
    "state_code": "AV",
    "cab": "951608",
    "cap": "83053"
  },
  {
    "key": "13562",
    "ITA": "SANT'ANDREA FRIUS",
    "state_code": "CA",
    "cab": "861708",
    "cap": "9040"
  },
  {
    "key": "13563",
    "ITA": "SANT'ANGELO A CUPOLO-PERRILLO",
    "state_code": "BN",
    "cab": "396705",
    "cap": "82010"
  },
  {
    "key": "13564",
    "ITA": "SANT'ANGELO A CUPOLO-PASTENE",
    "state_code": "BN",
    "cab": "396705",
    "cap": "82010"
  },
  {
    "key": "13565",
    "ITA": "SANT'ANGELO A CUPOLO-MONTORSI",
    "state_code": "BN",
    "cab": "396705",
    "cap": "82010"
  },
  {
    "key": "13566",
    "ITA": "SANT'ANGELO A CUPOLO-MACCOLI",
    "state_code": "BN",
    "cab": "396705",
    "cap": "82010"
  },
  {
    "key": "13567",
    "ITA": "SANT'ANGELO A CUPOLO-BAGNARA",
    "state_code": "BN",
    "cab": "396705",
    "cap": "82010"
  },
  {
    "key": "13568",
    "ITA": "SANT'ANGELO A CUPOLO",
    "state_code": "BN",
    "cab": "396705",
    "cap": "82010"
  },
  {
    "key": "13569",
    "ITA": "SANT'ANGELO A CUPOLO-MOTTA",
    "state_code": "BN",
    "cab": "396705",
    "cap": "82010"
  },
  {
    "key": "13570",
    "ITA": "SANT'ANGELO A FASANELLA",
    "state_code": "SA",
    "cab": "953042",
    "cap": "84027"
  },
  {
    "key": "13571",
    "ITA": "SANT'ANGELO A SCALA",
    "state_code": "AV",
    "cab": "951624",
    "cap": "83010"
  },
  {
    "key": "13572",
    "ITA": "SANT'ANGELO ALL'ESCA",
    "state_code": "AV",
    "cab": "951616",
    "cap": "83050"
  },
  {
    "key": "13573",
    "ITA": "SANT'ANGELO D'ALIFE",
    "state_code": "CE",
    "cab": "949651",
    "cap": "81017"
  },
  {
    "key": "13574",
    "ITA": "SANT'ANGELO DEI LOMBARDI",
    "state_code": "AV",
    "cab": "758102",
    "cap": "83054"
  },
  {
    "key": "13575",
    "ITA": "SANT'ANGELO DEI LOMBARDI-SAN VITO",
    "state_code": "AV",
    "cab": "758102",
    "cap": "83054"
  },
  {
    "key": "13576",
    "ITA": "SANT'ANGELO DEL PESCO",
    "state_code": "IS",
    "cab": "955880",
    "cap": "86080"
  },
  {
    "key": "13577",
    "ITA": "SANT'ANGELO DI BROLO-SANTA MARIA LO PIANO",
    "state_code": "ME",
    "cab": "825208",
    "cap": "98060"
  },
  {
    "key": "13578",
    "ITA": "SANT'ANGELO DI BROLO",
    "state_code": "ME",
    "cab": "825208",
    "cap": "98060"
  },
  {
    "key": "13579",
    "ITA": "SANT'ANGELO DI BROLO-CAVALLO PASTORIO",
    "state_code": "ME",
    "cab": "825208",
    "cap": "98060"
  },
  {
    "key": "13580",
    "ITA": "SANT'ANGELO DI BROLO-SAN SILVESTRO",
    "state_code": "ME",
    "cab": "825208",
    "cap": "98060"
  },
  {
    "key": "13581",
    "ITA": "SANT'ANGELO DI PIOVE DI SACCO",
    "state_code": "PD",
    "cab": "628503",
    "cap": "35020"
  },
  {
    "key": "13582",
    "ITA": "SANT'ANGELO DI PIOVE DI SACCO-VIGOROVEA",
    "state_code": "PD",
    "cab": "628503",
    "cap": "35020"
  },
  {
    "key": "13583",
    "ITA": "SANT'ANGELO IN LIZZOLA-MONTECCHIO",
    "state_code": "PU",
    "cab": "686006",
    "cap": "61020"
  },
  {
    "key": "13584",
    "ITA": "SANT'ANGELO IN LIZZOLA",
    "state_code": "PU",
    "cab": "686006",
    "cap": "61020"
  },
  {
    "key": "13585",
    "ITA": "SANT'ANGELO IN PONTANO",
    "state_code": "MC",
    "cab": "691600",
    "cap": "62020"
  },
  {
    "key": "13586",
    "ITA": "SANT'ANGELO IN VADO",
    "state_code": "PU",
    "cab": "686105",
    "cap": "61048"
  },
  {
    "key": "13587",
    "ITA": "SANT'ANGELO LE FRATTE",
    "state_code": "PZ",
    "cab": "365601",
    "cap": "85050"
  },
  {
    "key": "13588",
    "ITA": "SANT'ANGELO LIMOSANO",
    "state_code": "CB",
    "cab": "910976",
    "cap": "86020"
  },
  {
    "key": "13589",
    "ITA": "SANT'ANGELO LODIGIANO",
    "state_code": "LO",
    "cab": "337600",
    "cap": "26866"
  },
  {
    "key": "13590",
    "ITA": "SANT'ANGELO LOMELLINA",
    "state_code": "PV",
    "cab": "930875",
    "cap": "27030"
  },
  {
    "key": "13591",
    "ITA": "SANT'ANGELO MUXARO",
    "state_code": "AG",
    "cab": "831503",
    "cap": "92020"
  },
  {
    "key": "13592",
    "ITA": "SANT'ANGELO ROMANO",
    "state_code": "RM",
    "cab": "365007",
    "cap": "10"
  },
  {
    "key": "13593",
    "ITA": "SANT'ANNA ARRESI",
    "state_code": "SU",
    "cab": "861807",
    "cap": "9010"
  },
  {
    "key": "13594",
    "ITA": "SANT'ANNA D'ALFAEDO-FOSSE",
    "state_code": "VR",
    "cab": "598300",
    "cap": "37020"
  },
  {
    "key": "13595",
    "ITA": "SANT'ANNA D'ALFAEDO",
    "state_code": "VR",
    "cab": "598300",
    "cap": "37020"
  },
  {
    "key": "13596",
    "ITA": "SANT'ANNA D'ALFAEDO-CERNA",
    "state_code": "VR",
    "cab": "598300",
    "cap": "37020"
  },
  {
    "key": "13597",
    "ITA": "SANT'ANTIMO",
    "state_code": "NA",
    "cab": "402008",
    "cap": "80029"
  },
  {
    "key": "13598",
    "ITA": "SANT'ANTIOCO",
    "state_code": "SU",
    "cab": "439802",
    "cap": "9017"
  },
  {
    "key": "13599",
    "ITA": "SANT'ANTONINO DI SUSA",
    "state_code": "TO",
    "cab": "309708",
    "cap": "10050"
  },
  {
    "key": "13600",
    "ITA": "SANT'ANTONIO ABATE",
    "state_code": "NA",
    "cab": "402107",
    "cap": "80057"
  },
  {
    "key": "13601",
    "ITA": "SANT'ANTONIO DI GALLURA",
    "state_code": "OT",
    "cab": "877001",
    "cap": "7030"
  },
  {
    "key": "13602",
    "ITA": "SANT'APOLLINARE",
    "state_code": "FR",
    "cab": "471904",
    "cap": "3048"
  },
  {
    "key": "13603",
    "ITA": "SANT'ARCANGELO",
    "state_code": "PZ",
    "cab": "422600",
    "cap": "85037"
  },
  {
    "key": "13604",
    "ITA": "SANT'ARCANGELO-SAN BRANCATO",
    "state_code": "PZ",
    "cab": "422600",
    "cap": "85037"
  },
  {
    "key": "13605",
    "ITA": "SANT'ARCANGELO TRIMONTE",
    "state_code": "BN",
    "cab": "950592",
    "cap": "82021"
  },
  {
    "key": "13606",
    "ITA": "SANT'ARPINO",
    "state_code": "CE",
    "cab": "893503",
    "cap": "81030"
  },
  {
    "key": "13607",
    "ITA": "SANT'ARSENIO",
    "state_code": "SA",
    "cab": "764407",
    "cap": "84037"
  },
  {
    "key": "13608",
    "ITA": "SANT'EGIDIO ALLA VIBRATA-FARAONE NUOVO",
    "state_code": "TE",
    "cab": "770305",
    "cap": "64016"
  },
  {
    "key": "13609",
    "ITA": "SANT'EGIDIO ALLA VIBRATA-VILLA MATTONI",
    "state_code": "TE",
    "cab": "770305",
    "cap": "64016"
  },
  {
    "key": "13610",
    "ITA": "SANT'EGIDIO ALLA VIBRATA-PAOLANTONIO",
    "state_code": "TE",
    "cab": "770305",
    "cap": "64016"
  },
  {
    "key": "13611",
    "ITA": "SANT'EGIDIO ALLA VIBRATA",
    "state_code": "TE",
    "cab": "770305",
    "cap": "64016"
  },
  {
    "key": "13612",
    "ITA": "SANT'EGIDIO DEL MONTE ALBINO",
    "state_code": "SA",
    "cab": "764506",
    "cap": "84010"
  },
  {
    "key": "13613",
    "ITA": "SANT'EGIDIO DEL MONTE ALBINO-SAN LORENZO DI SANT'EGIDIO",
    "state_code": "SA",
    "cab": "764506",
    "cap": "84010"
  },
  {
    "key": "13614",
    "ITA": "SANT'ELENA",
    "state_code": "PD",
    "cab": "628602",
    "cap": "35040"
  },
  {
    "key": "13615",
    "ITA": "SANT'ELENA SANNITA",
    "state_code": "IS",
    "cab": "955898",
    "cap": "86095"
  },
  {
    "key": "13616",
    "ITA": "SANT'ELIA A PIANISI",
    "state_code": "CB",
    "cab": "411108",
    "cap": "86048"
  },
  {
    "key": "13617",
    "ITA": "SANT'ELIA FIUMERAPIDO",
    "state_code": "FR",
    "cab": "745802",
    "cap": "3049"
  },
  {
    "key": "13618",
    "ITA": "SANT'ELIA FIUMERAPIDO-VALLELUCE",
    "state_code": "FR",
    "cab": "745802",
    "cap": "3049"
  },
  {
    "key": "13619",
    "ITA": "SANT'ELIA FIUMERAPIDO-PORTELLA",
    "state_code": "FR",
    "cab": "745802",
    "cap": "3049"
  },
  {
    "key": "13620",
    "ITA": "SANT'ELIA FIUMERAPIDO-OLIVELLA",
    "state_code": "FR",
    "cab": "745802",
    "cap": "3049"
  },
  {
    "key": "13621",
    "ITA": "SANT'ELPIDIO A MARE",
    "state_code": "FM",
    "cab": "697201",
    "cap": "63019"
  },
  {
    "key": "13622",
    "ITA": "SANT'ELPIDIO A MARE-BIVIO CASCINARE",
    "state_code": "FM",
    "cab": "697201",
    "cap": "63019"
  },
  {
    "key": "13623",
    "ITA": "SANT'ELPIDIO A MARE-CASCINARE",
    "state_code": "FM",
    "cab": "697201",
    "cap": "63019"
  },
  {
    "key": "13624",
    "ITA": "SANT'ELPIDIO A MARE-LA LUCE",
    "state_code": "FM",
    "cab": "697201",
    "cap": "63019"
  },
  {
    "key": "13625",
    "ITA": "SANT'ELPIDIO A MARE-CASETTE D'ETE",
    "state_code": "FM",
    "cab": "697201",
    "cap": "63019"
  },
  {
    "key": "13626",
    "ITA": "SANT'ELPIDIO A MARE-CASTELLANO",
    "state_code": "FM",
    "cab": "697201",
    "cap": "63019"
  },
  {
    "key": "13627",
    "ITA": "SANT'ELPIDIO A MARE-CRETAROLA",
    "state_code": "FM",
    "cab": "697201",
    "cap": "63019"
  },
  {
    "key": "13628",
    "ITA": "SANT'EUFEMIA A MAIELLA",
    "state_code": "PE",
    "cab": "954131",
    "cap": "65020"
  },
  {
    "key": "13629",
    "ITA": "SANT'EUFEMIA D'ASPROMONTE",
    "state_code": "RC",
    "cab": "815506",
    "cap": "89027"
  },
  {
    "key": "13630",
    "ITA": "SANT'EUSANIO DEL SANGRO",
    "state_code": "CH",
    "cab": "779801",
    "cap": "66037"
  },
  {
    "key": "13631",
    "ITA": "SANT'EUSANIO FORCONESE",
    "state_code": "AQ",
    "cab": "909937",
    "cap": "67020"
  },
  {
    "key": "13632",
    "ITA": "SANT'ILARIO D'ENZA",
    "state_code": "RE",
    "cab": "665000",
    "cap": "42049"
  },
  {
    "key": "13633",
    "ITA": "SANT'ILARIO D'ENZA-CALERNO",
    "state_code": "RE",
    "cab": "665000",
    "cap": "42049"
  },
  {
    "key": "13634",
    "ITA": "SANT'ILARIO DELLO IONIO",
    "state_code": "RC",
    "cab": "960419",
    "cap": "89040"
  },
  {
    "key": "13635",
    "ITA": "SANT'ILARIO DELLO IONIO-CONDOJANNI",
    "state_code": "RC",
    "cab": "960419",
    "cap": "89040"
  },
  {
    "key": "13636",
    "ITA": "SANT'ILARIO DELLO IONIO-MARINA DI SANT'ILARIO DELLO IONIO",
    "state_code": "RC",
    "cab": "960419",
    "cap": "89040"
  },
  {
    "key": "13637",
    "ITA": "SANT'IPPOLITO-SORBOLONGO",
    "state_code": "PU",
    "cab": "686204",
    "cap": "61040"
  },
  {
    "key": "13638",
    "ITA": "SANT'IPPOLITO",
    "state_code": "PU",
    "cab": "686204",
    "cap": "61040"
  },
  {
    "key": "13639",
    "ITA": "SANT'OLCESE-MANESSENO",
    "state_code": "GE",
    "cab": "321901",
    "cap": "16010"
  },
  {
    "key": "13640",
    "ITA": "SANT'OLCESE",
    "state_code": "GE",
    "cab": "321901",
    "cap": "16010"
  },
  {
    "key": "13641",
    "ITA": "SANT'OLCESE-PICCARELLO",
    "state_code": "GE",
    "cab": "321901",
    "cap": "16010"
  },
  {
    "key": "13642",
    "ITA": "SANT'OMERO-GARRUFO",
    "state_code": "TE",
    "cab": "770404",
    "cap": "64027"
  },
  {
    "key": "13643",
    "ITA": "SANT'OMERO-POGGIO MORELLO",
    "state_code": "TE",
    "cab": "770404",
    "cap": "64027"
  },
  {
    "key": "13644",
    "ITA": "SANT'OMERO",
    "state_code": "TE",
    "cab": "770404",
    "cap": "64027"
  },
  {
    "key": "13645",
    "ITA": "SANT'OMOBONO TERME",
    "state_code": "BG",
    "cab": "534602",
    "cap": "24038"
  },
  {
    "key": "13646",
    "ITA": "SANT'ONOFRIO",
    "state_code": "VV",
    "cab": "913475",
    "cap": "89843"
  },
  {
    "key": "13647",
    "ITA": "SANT'ORESTE",
    "state_code": "RM",
    "cab": "395905",
    "cap": "60"
  },
  {
    "key": "13648",
    "ITA": "SANT'ORSOLA TERME",
    "state_code": "TN",
    "cab": "354209",
    "cap": "38050"
  },
  {
    "key": "13649",
    "ITA": "SANT'URBANO",
    "state_code": "PD",
    "cab": "628701",
    "cap": "35040"
  },
  {
    "key": "13650",
    "ITA": "SANT'URBANO-CARMIGNANO",
    "state_code": "PD",
    "cab": "628701",
    "cap": "35040"
  },
  {
    "key": "13651",
    "ITA": "SANT'URBANO-CA' MOROSINI",
    "state_code": "PD",
    "cab": "628701",
    "cap": "35040"
  },
  {
    "key": "13652",
    "ITA": "SANTA BRIGIDA-MUGGIASCA",
    "state_code": "BG",
    "cab": "928598",
    "cap": "24010"
  },
  {
    "key": "13653",
    "ITA": "SANTA BRIGIDA-COLLA",
    "state_code": "BG",
    "cab": "928598",
    "cap": "24010"
  },
  {
    "key": "13654",
    "ITA": "SANTA BRIGIDA",
    "state_code": "BG",
    "cab": "928598",
    "cap": "24010"
  },
  {
    "key": "13655",
    "ITA": "SANTA CATERINA ALBANESE-IOGGI",
    "state_code": "CS",
    "cab": "959353",
    "cap": "87010"
  },
  {
    "key": "13656",
    "ITA": "SANTA CATERINA ALBANESE",
    "state_code": "CS",
    "cab": "959353",
    "cap": "87010"
  },
  {
    "key": "13657",
    "ITA": "SANTA CATERINA DELLO IONIO",
    "state_code": "CZ",
    "cab": "365700",
    "cap": "88060"
  },
  {
    "key": "13658",
    "ITA": "SANTA CATERINA DELLO IONIO-SANTA CATERINA DELLO IONIO MARINA",
    "state_code": "CZ",
    "cab": "365700",
    "cap": "88060"
  },
  {
    "key": "13659",
    "ITA": "SANTA CATERINA VILLARMOSA",
    "state_code": "CL",
    "cab": "834309",
    "cap": "93018"
  },
  {
    "key": "13660",
    "ITA": "SANTA CESAREA TERME",
    "state_code": "LE",
    "cab": "799908",
    "cap": "73020"
  },
  {
    "key": "13661",
    "ITA": "SANTA CESAREA TERME-VITIGLIANO",
    "state_code": "LE",
    "cab": "799908",
    "cap": "73020"
  },
  {
    "key": "13662",
    "ITA": "SANTA CESAREA TERME-CERFIGNANO",
    "state_code": "LE",
    "cab": "799908",
    "cap": "73020"
  },
  {
    "key": "13663",
    "ITA": "SANTA CRISTINA D'ASPROMONTE",
    "state_code": "RC",
    "cab": "960385",
    "cap": "89056"
  },
  {
    "key": "13664",
    "ITA": "SANTA CRISTINA D'ASPROMONTE-LUBRICHI",
    "state_code": "RC",
    "cab": "960385",
    "cap": "89056"
  },
  {
    "key": "13665",
    "ITA": "SANTA CRISTINA E BISSONE",
    "state_code": "PV",
    "cab": "562306",
    "cap": "27010"
  },
  {
    "key": "13666",
    "ITA": "SANTA CRISTINA GELA",
    "state_code": "PA",
    "cab": "436105",
    "cap": "90030"
  },
  {
    "key": "13667",
    "ITA": "SANTA CRISTINA VAL GARDENA",
    "state_code": "BZ",
    "cab": "588608",
    "cap": "39047"
  },
  {
    "key": "13668",
    "ITA": "SANTA CROCE CAMERINA",
    "state_code": "RG",
    "cab": "845107",
    "cap": "97017"
  },
  {
    "key": "13669",
    "ITA": "SANTA CROCE DEL SANNIO",
    "state_code": "BN",
    "cab": "950535",
    "cap": "82020"
  },
  {
    "key": "13670",
    "ITA": "SANTA CROCE DI MAGLIANO",
    "state_code": "CB",
    "cab": "411009",
    "cap": "86047"
  },
  {
    "key": "13671",
    "ITA": "SANTA CROCE SULL'ARNO-STAFFOLI",
    "state_code": "PI",
    "cab": "711606",
    "cap": "56029"
  },
  {
    "key": "13672",
    "ITA": "SANTA CROCE SULL'ARNO",
    "state_code": "PI",
    "cab": "711606",
    "cap": "56029"
  },
  {
    "key": "13673",
    "ITA": "SANTA DOMENICA TALAO",
    "state_code": "CS",
    "cab": "959361",
    "cap": "87020"
  },
  {
    "key": "13674",
    "ITA": "SANTA DOMENICA VITTORIA",
    "state_code": "ME",
    "cab": "961581",
    "cap": "98030"
  },
  {
    "key": "13675",
    "ITA": "SANTA ELISABETTA",
    "state_code": "AG",
    "cab": "831305",
    "cap": "92020"
  },
  {
    "key": "13676",
    "ITA": "SANTA FIORA-SELVA",
    "state_code": "GR",
    "cab": "723601",
    "cap": "58037"
  },
  {
    "key": "13677",
    "ITA": "SANTA FIORA-BAGNORE",
    "state_code": "GR",
    "cab": "723601",
    "cap": "58037"
  },
  {
    "key": "13678",
    "ITA": "SANTA FIORA-MARRONETO",
    "state_code": "GR",
    "cab": "723601",
    "cap": "58037"
  },
  {
    "key": "13679",
    "ITA": "SANTA FIORA",
    "state_code": "GR",
    "cab": "723601",
    "cap": "58037"
  },
  {
    "key": "13680",
    "ITA": "SANTA FIORA-BAGNOLO",
    "state_code": "GR",
    "cab": "723601",
    "cap": "58037"
  },
  {
    "key": "13681",
    "ITA": "SANTA FLAVIA-SANT'ELIA",
    "state_code": "PA",
    "cab": "436204",
    "cap": "90017"
  },
  {
    "key": "13682",
    "ITA": "SANTA FLAVIA",
    "state_code": "PA",
    "cab": "436204",
    "cap": "90017"
  },
  {
    "key": "13683",
    "ITA": "SANTA FLAVIA-PORTICELLO",
    "state_code": "PA",
    "cab": "436204",
    "cap": "90017"
  },
  {
    "key": "13684",
    "ITA": "SANTA GIULETTA",
    "state_code": "PV",
    "cab": "562405",
    "cap": "27046"
  },
  {
    "key": "13685",
    "ITA": "SANTA GIUSTA",
    "state_code": "OR",
    "cab": "880005",
    "cap": "9096"
  },
  {
    "key": "13686",
    "ITA": "SANTA GIUSTINA-FORMEGAN",
    "state_code": "BL",
    "cab": "612705",
    "cap": "32035"
  },
  {
    "key": "13687",
    "ITA": "SANTA GIUSTINA-MEANO",
    "state_code": "BL",
    "cab": "612705",
    "cap": "32035"
  },
  {
    "key": "13688",
    "ITA": "SANTA GIUSTINA",
    "state_code": "BL",
    "cab": "612705",
    "cap": "32035"
  },
  {
    "key": "13689",
    "ITA": "SANTA GIUSTINA IN COLLE-FRATTE",
    "state_code": "PD",
    "cab": "630707",
    "cap": "35010"
  },
  {
    "key": "13690",
    "ITA": "SANTA GIUSTINA IN COLLE",
    "state_code": "PD",
    "cab": "630707",
    "cap": "35010"
  },
  {
    "key": "13691",
    "ITA": "SANTA LUCE-PIEVE DI SANTA LUCE",
    "state_code": "PI",
    "cab": "711705",
    "cap": "56040"
  },
  {
    "key": "13692",
    "ITA": "SANTA LUCE-POMAIA",
    "state_code": "PI",
    "cab": "711705",
    "cap": "56040"
  },
  {
    "key": "13693",
    "ITA": "SANTA LUCE-PASTINA",
    "state_code": "PI",
    "cab": "711705",
    "cap": "56040"
  },
  {
    "key": "13694",
    "ITA": "SANTA LUCE",
    "state_code": "PI",
    "cab": "711705",
    "cap": "56040"
  },
  {
    "key": "13695",
    "ITA": "SANTA LUCIA DEL MELA",
    "state_code": "ME",
    "cab": "825000",
    "cap": "98046"
  },
  {
    "key": "13696",
    "ITA": "SANTA LUCIA DI PIAVE",
    "state_code": "TV",
    "cab": "620500",
    "cap": "31025"
  },
  {
    "key": "13697",
    "ITA": "SANTA LUCIA DI SERINO",
    "state_code": "AV",
    "cab": "951590",
    "cap": "83020"
  },
  {
    "key": "13698",
    "ITA": "SANTA MARGHERITA D'ADIGE",
    "state_code": "PD",
    "cab": "628404",
    "cap": "35040"
  },
  {
    "key": "13699",
    "ITA": "SANTA MARGHERITA DI BELICE",
    "state_code": "AG",
    "cab": "831404",
    "cap": "92018"
  },
  {
    "key": "13700",
    "ITA": "SANTA MARGHERITA DI STAFFORA",
    "state_code": "PV",
    "cab": "930867",
    "cap": "27050"
  },
  {
    "key": "13701",
    "ITA": "SANTA MARGHERITA DI STAFFORA-CASANOVA DI DESTRA",
    "state_code": "PV",
    "cab": "930867",
    "cap": "27050"
  },
  {
    "key": "13702",
    "ITA": "SANTA MARGHERITA LIGURE",
    "state_code": "GE",
    "cab": "321802",
    "cap": "16038"
  },
  {
    "key": "13703",
    "ITA": "SANTA MARGHERITA LIGURE-PARAGGI",
    "state_code": "GE",
    "cab": "321802",
    "cap": "16038"
  },
  {
    "key": "13704",
    "ITA": "SANTA MARGHERITA LIGURE-SAN LORENZO DELLA COSTA",
    "state_code": "GE",
    "cab": "321802",
    "cap": "16038"
  },
  {
    "key": "13705",
    "ITA": "SANTA MARIA A MONTE",
    "state_code": "PI",
    "cab": "711804",
    "cap": "56020"
  },
  {
    "key": "13706",
    "ITA": "SANTA MARIA A MONTE-CERRETTI",
    "state_code": "PI",
    "cab": "711804",
    "cap": "56020"
  },
  {
    "key": "13707",
    "ITA": "SANTA MARIA A MONTE-MONTECALVOLI",
    "state_code": "PI",
    "cab": "711804",
    "cap": "56020"
  },
  {
    "key": "13708",
    "ITA": "SANTA MARIA A VICO",
    "state_code": "CE",
    "cab": "750307",
    "cap": "81028"
  },
  {
    "key": "13709",
    "ITA": "SANTA MARIA CAPUA VETERE",
    "state_code": "CE",
    "cab": "750406",
    "cap": "81055"
  },
  {
    "key": "13710",
    "ITA": "SANTA MARIA COGHINAS",
    "state_code": "SS",
    "cab": "871301",
    "cap": "7030"
  },
  {
    "key": "13711",
    "ITA": "SANTA MARIA DEL CEDRO-MARCELLINA",
    "state_code": "CS",
    "cab": "811000",
    "cap": "87020"
  },
  {
    "key": "13712",
    "ITA": "SANTA MARIA DEL CEDRO-GRANATA",
    "state_code": "CS",
    "cab": "811000",
    "cap": "87020"
  },
  {
    "key": "13713",
    "ITA": "SANTA MARIA DEL CEDRO",
    "state_code": "CS",
    "cab": "811000",
    "cap": "87020"
  },
  {
    "key": "13714",
    "ITA": "SANTA MARIA DEL MOLISE-SANT'ANGELO IN GROTTE",
    "state_code": "IS",
    "cab": "955872",
    "cap": "86096"
  },
  {
    "key": "13715",
    "ITA": "SANTA MARIA DEL MOLISE",
    "state_code": "IS",
    "cab": "955872",
    "cap": "86096"
  },
  {
    "key": "13716",
    "ITA": "SANTA MARIA DELLA VERSA",
    "state_code": "PV",
    "cab": "562504",
    "cap": "27047"
  },
  {
    "key": "13717",
    "ITA": "SANTA MARIA DI LICODIA",
    "state_code": "CT",
    "cab": "842500",
    "cap": "95038"
  },
  {
    "key": "13718",
    "ITA": "SANTA MARIA DI SALA-VETERNIGO",
    "state_code": "VE",
    "cab": "363002",
    "cap": "30036"
  },
  {
    "key": "13719",
    "ITA": "SANTA MARIA DI SALA",
    "state_code": "VE",
    "cab": "363002",
    "cap": "30036"
  },
  {
    "key": "13720",
    "ITA": "SANTA MARIA DI SALA-CALTANA",
    "state_code": "VE",
    "cab": "363002",
    "cap": "30030"
  },
  {
    "key": "13721",
    "ITA": "SANTA MARIA DI SALA-SANT'ANGELO",
    "state_code": "VE",
    "cab": "363002",
    "cap": "30036"
  },
  {
    "key": "13722",
    "ITA": "SANTA MARIA DI SALA-STIGLIANO",
    "state_code": "VE",
    "cab": "363002",
    "cap": "30036"
  },
  {
    "key": "13723",
    "ITA": "SANTA MARIA HOE'",
    "state_code": "LC",
    "cab": "926196",
    "cap": "23889"
  },
  {
    "key": "13724",
    "ITA": "SANTA MARIA IMBARO",
    "state_code": "CH",
    "cab": "724708",
    "cap": "66030"
  },
  {
    "key": "13725",
    "ITA": "SANTA MARIA LA CARITA'",
    "state_code": "NA",
    "cab": "403402",
    "cap": "80050"
  },
  {
    "key": "13726",
    "ITA": "SANTA MARIA LA FOSSA",
    "state_code": "CE",
    "cab": "409003",
    "cap": "81050"
  },
  {
    "key": "13727",
    "ITA": "SANTA MARIA LA LONGA-MERETO DI CAPITOLO",
    "state_code": "UD",
    "cab": "899005",
    "cap": "33050"
  },
  {
    "key": "13728",
    "ITA": "SANTA MARIA LA LONGA",
    "state_code": "UD",
    "cab": "899005",
    "cap": "33050"
  },
  {
    "key": "13729",
    "ITA": "SANTA MARIA LA LONGA-TISSANO",
    "state_code": "UD",
    "cab": "899005",
    "cap": "33050"
  },
  {
    "key": "13730",
    "ITA": "SANTA MARIA MAGGIORE",
    "state_code": "VB",
    "cab": "456509",
    "cap": "28857"
  },
  {
    "key": "13731",
    "ITA": "SANTA MARIA NUOVA",
    "state_code": "AN",
    "cab": "375808",
    "cap": "60030"
  },
  {
    "key": "13732",
    "ITA": "SANTA MARIA NUOVA-COLLINA SANTA MARIA NUOVA",
    "state_code": "AN",
    "cab": "375808",
    "cap": "60030"
  },
  {
    "key": "13733",
    "ITA": "SANTA MARINA",
    "state_code": "SA",
    "cab": "409508",
    "cap": "84070"
  },
  {
    "key": "13734",
    "ITA": "SANTA MARINA-POLICASTRO BUSSENTINO",
    "state_code": "SA",
    "cab": "409508",
    "cap": "84067"
  },
  {
    "key": "13735",
    "ITA": "SANTA MARINA SALINA",
    "state_code": "ME",
    "cab": "825109",
    "cap": "98050"
  },
  {
    "key": "13736",
    "ITA": "SANTA MARINA SALINA-LINGUA",
    "state_code": "ME",
    "cab": "825109",
    "cap": "98050"
  },
  {
    "key": "13737",
    "ITA": "SANTA MARINELLA",
    "state_code": "RM",
    "cab": "394106",
    "cap": "58"
  },
  {
    "key": "13738",
    "ITA": "SANTA MARINELLA-SANTA SEVERA",
    "state_code": "RM",
    "cab": "394106",
    "cap": "50"
  },
  {
    "key": "13739",
    "ITA": "SANTA NINFA",
    "state_code": "TP",
    "cab": "819508",
    "cap": "91029"
  },
  {
    "key": "13740",
    "ITA": "SANTA PAOLINA",
    "state_code": "AV",
    "cab": "951632",
    "cap": "83030"
  },
  {
    "key": "13741",
    "ITA": "SANTA SEVERINA",
    "state_code": "KR",
    "cab": "887703",
    "cap": "88832"
  },
  {
    "key": "13742",
    "ITA": "SANTA SEVERINA-ALTILIA",
    "state_code": "KR",
    "cab": "887703",
    "cap": "88832"
  },
  {
    "key": "13743",
    "ITA": "SANTA SOFIA",
    "state_code": "FC",
    "cab": "680306",
    "cap": "47018"
  },
  {
    "key": "13744",
    "ITA": "SANTA SOFIA-CORNIOLO",
    "state_code": "FC",
    "cab": "680306",
    "cap": "47018"
  },
  {
    "key": "13745",
    "ITA": "SANTA SOFIA-BISERNO",
    "state_code": "FC",
    "cab": "680306",
    "cap": "47018"
  },
  {
    "key": "13746",
    "ITA": "SANTA SOFIA D'EPIRO",
    "state_code": "CS",
    "cab": "888602",
    "cap": "87048"
  },
  {
    "key": "13747",
    "ITA": "SANTA TERESA DI RIVA-BARRACCA",
    "state_code": "ME",
    "cab": "825307",
    "cap": "98028"
  },
  {
    "key": "13748",
    "ITA": "SANTA TERESA DI RIVA-MISSERIO",
    "state_code": "ME",
    "cab": "825307",
    "cap": "98028"
  },
  {
    "key": "13749",
    "ITA": "SANTA TERESA DI RIVA",
    "state_code": "ME",
    "cab": "825307",
    "cap": "98028"
  },
  {
    "key": "13750",
    "ITA": "SANTA TERESA GALLURA",
    "state_code": "OT",
    "cab": "850503",
    "cap": "7028"
  },
  {
    "key": "13751",
    "ITA": "SANTA TERESA GALLURA-SAN PASQUALE",
    "state_code": "OT",
    "cab": "850503",
    "cap": "7028"
  },
  {
    "key": "13752",
    "ITA": "SANTA VENERINA-LINERA",
    "state_code": "CT",
    "cab": "842609",
    "cap": "95010"
  },
  {
    "key": "13753",
    "ITA": "SANTA VENERINA-DAGALA DEL RE",
    "state_code": "CT",
    "cab": "842609",
    "cap": "95010"
  },
  {
    "key": "13754",
    "ITA": "SANTA VENERINA",
    "state_code": "CT",
    "cab": "842609",
    "cap": "95010"
  },
  {
    "key": "13755",
    "ITA": "SANTA VITTORIA D'ALBA-VILLA",
    "state_code": "CN",
    "cab": "468306",
    "cap": "12069"
  },
  {
    "key": "13756",
    "ITA": "SANTA VITTORIA D'ALBA",
    "state_code": "CN",
    "cab": "468306",
    "cap": "12069"
  },
  {
    "key": "13757",
    "ITA": "SANTA VITTORIA D'ALBA-CINZANO",
    "state_code": "CN",
    "cab": "468306",
    "cap": "12069"
  },
  {
    "key": "13758",
    "ITA": "SANTA VITTORIA IN MATENANO",
    "state_code": "FM",
    "cab": "697102",
    "cap": "63028"
  },
  {
    "key": "13759",
    "ITA": "SANTADI-TERRESOLI",
    "state_code": "SU",
    "cab": "861906",
    "cap": "9010"
  },
  {
    "key": "13760",
    "ITA": "SANTADI",
    "state_code": "SU",
    "cab": "861906",
    "cap": "9010"
  },
  {
    "key": "13761",
    "ITA": "SANTARCANGELO DI ROMAGNA",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13762",
    "ITA": "SANTARCANGELO DI ROMAGNA-CIOLA CORNIALE",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13763",
    "ITA": "SANTARCANGELO DI ROMAGNA-MONTALBANO DI SANTARCANGELO DI ROMAGNA",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13764",
    "ITA": "SANTARCANGELO DI ROMAGNA-SANT'ERMETE",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13765",
    "ITA": "SANTARCANGELO DI ROMAGNA-SANTA GIUSTINA DI SANTARCANGELO",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13766",
    "ITA": "SANTARCANGELO DI ROMAGNA-SAN MARTINO DEI MULINI",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13767",
    "ITA": "SANTARCANGELO DI ROMAGNA-SAN MICHELE",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13768",
    "ITA": "SANTARCANGELO DI ROMAGNA-SAN VITO",
    "state_code": "RN",
    "cab": "680207",
    "cap": "47822"
  },
  {
    "key": "13769",
    "ITA": "SANTE MARIE-SCANZANO",
    "state_code": "AQ",
    "cab": "909929",
    "cap": "67067"
  },
  {
    "key": "13770",
    "ITA": "SANTE MARIE-SANTO STEFANO",
    "state_code": "AQ",
    "cab": "909929",
    "cap": "67067"
  },
  {
    "key": "13771",
    "ITA": "SANTE MARIE",
    "state_code": "AQ",
    "cab": "909929",
    "cap": "67067"
  },
  {
    "key": "13772",
    "ITA": "SANTENA",
    "state_code": "TO",
    "cab": "309807",
    "cap": "10026"
  },
  {
    "key": "13773",
    "ITA": "SANTERAMO IN COLLE",
    "state_code": "BA",
    "cab": "416800",
    "cap": "70029"
  },
  {
    "key": "13774",
    "ITA": "SANTHIA'",
    "state_code": "VC",
    "cab": "447706",
    "cap": "13048"
  },
  {
    "key": "13775",
    "ITA": "SANTI COSMA E DAMIANO-SAN LORENZO DI SANTI COSMA E DAMIANO",
    "state_code": "LT",
    "cab": "741900",
    "cap": "4020"
  },
  {
    "key": "13776",
    "ITA": "SANTI COSMA E DAMIANO",
    "state_code": "LT",
    "cab": "741900",
    "cap": "4020"
  },
  {
    "key": "13777",
    "ITA": "SANTI COSMA E DAMIANO-CAMPOMAGGIORE SAN LUCA",
    "state_code": "LT",
    "cab": "741900",
    "cap": "4020"
  },
  {
    "key": "13778",
    "ITA": "SANTI COSMA E DAMIANO-GRUNUOVO",
    "state_code": "LT",
    "cab": "741900",
    "cap": "4020"
  },
  {
    "key": "13779",
    "ITA": "SANTO STEFANO AL MARE",
    "state_code": "IM",
    "cab": "490805",
    "cap": "18010"
  },
  {
    "key": "13780",
    "ITA": "SANTO STEFANO BELBO-VALDIVILLA",
    "state_code": "CN",
    "cab": "468405",
    "cap": "12058"
  },
  {
    "key": "13781",
    "ITA": "SANTO STEFANO BELBO",
    "state_code": "CN",
    "cab": "468405",
    "cap": "12058"
  },
  {
    "key": "13782",
    "ITA": "SANTO STEFANO D'AVETO-ALLEGREZZE",
    "state_code": "GE",
    "cab": "322008",
    "cap": "16049"
  },
  {
    "key": "13783",
    "ITA": "SANTO STEFANO D'AVETO-AMBORZASCO",
    "state_code": "GE",
    "cab": "322008",
    "cap": "16049"
  },
  {
    "key": "13784",
    "ITA": "SANTO STEFANO D'AVETO",
    "state_code": "GE",
    "cab": "322008",
    "cap": "16049"
  },
  {
    "key": "13785",
    "ITA": "SANTO STEFANO DEL SOLE",
    "state_code": "AV",
    "cab": "951640",
    "cap": "83050"
  },
  {
    "key": "13786",
    "ITA": "SANTO STEFANO DI CADORE-CAMPOLONGO DI CADORE",
    "state_code": "BL",
    "cab": "612804",
    "cap": "32045"
  },
  {
    "key": "13787",
    "ITA": "SANTO STEFANO DI CADORE-COSTALISSOIO",
    "state_code": "BL",
    "cab": "612804",
    "cap": "32045"
  },
  {
    "key": "13788",
    "ITA": "SANTO STEFANO DI CADORE",
    "state_code": "BL",
    "cab": "612804",
    "cap": "32045"
  },
  {
    "key": "13789",
    "ITA": "SANTO STEFANO DI CAMASTRA",
    "state_code": "ME",
    "cab": "825406",
    "cap": "98077"
  },
  {
    "key": "13790",
    "ITA": "SANTO STEFANO DI MAGRA-PONZANO SUPERIORE",
    "state_code": "SP",
    "cab": "498303",
    "cap": "19037"
  },
  {
    "key": "13791",
    "ITA": "SANTO STEFANO DI MAGRA",
    "state_code": "SP",
    "cab": "498303",
    "cap": "19037"
  },
  {
    "key": "13792",
    "ITA": "SANTO STEFANO DI ROGLIANO",
    "state_code": "CS",
    "cab": "412106",
    "cap": "87056"
  },
  {
    "key": "13793",
    "ITA": "SANTO STEFANO DI SESSANIO",
    "state_code": "AQ",
    "cab": "909945",
    "cap": "67020"
  },
  {
    "key": "13794",
    "ITA": "SANTO STEFANO IN ASPROMONTE-GAMBARIE",
    "state_code": "RC",
    "cab": "815605",
    "cap": "89057"
  },
  {
    "key": "13795",
    "ITA": "SANTO STEFANO IN ASPROMONTE",
    "state_code": "RC",
    "cab": "815605",
    "cap": "89057"
  },
  {
    "key": "13796",
    "ITA": "SANTO STEFANO LODIGIANO",
    "state_code": "LO",
    "cab": "337709",
    "cap": "26849"
  },
  {
    "key": "13797",
    "ITA": "SANTO STEFANO QUISQUINA",
    "state_code": "AG",
    "cab": "831602",
    "cap": "92020"
  },
  {
    "key": "13798",
    "ITA": "SANTO STEFANO ROERO-SAN LORENZO ROERO",
    "state_code": "CN",
    "cab": "919407",
    "cap": "12040"
  },
  {
    "key": "13799",
    "ITA": "SANTO STEFANO ROERO",
    "state_code": "CN",
    "cab": "919407",
    "cap": "12040"
  },
  {
    "key": "13800",
    "ITA": "SANTO STEFANO TICINO",
    "state_code": "MI",
    "cab": "337808",
    "cap": "20010"
  },
  {
    "key": "13801",
    "ITA": "SANTO STINO DI LIVENZA-LA SALUTE DI LIVENZA",
    "state_code": "VE",
    "cab": "363101",
    "cap": "30020"
  },
  {
    "key": "13802",
    "ITA": "SANTO STINO DI LIVENZA-CORBOLONE",
    "state_code": "VE",
    "cab": "363101",
    "cap": "30029"
  },
  {
    "key": "13803",
    "ITA": "SANTO STINO DI LIVENZA",
    "state_code": "VE",
    "cab": "363101",
    "cap": "30029"
  },
  {
    "key": "13804",
    "ITA": "SANTOMENNA",
    "state_code": "SA",
    "cab": "953059",
    "cap": "84020"
  },
  {
    "key": "13805",
    "ITA": "SANTOPADRE",
    "state_code": "FR",
    "cab": "896902",
    "cap": "3030"
  },
  {
    "key": "13806",
    "ITA": "SANTORSO",
    "state_code": "VI",
    "cab": "883801",
    "cap": "36014"
  },
  {
    "key": "13807",
    "ITA": "SANTU LUSSURGIU-SAN LEONARDO",
    "state_code": "OR",
    "cab": "856104",
    "cap": "9075"
  },
  {
    "key": "13808",
    "ITA": "SANTU LUSSURGIU",
    "state_code": "OR",
    "cab": "856104",
    "cap": "9075"
  },
  {
    "key": "13809",
    "ITA": "SANZA",
    "state_code": "SA",
    "cab": "886507",
    "cap": "84030"
  },
  {
    "key": "13810",
    "ITA": "SANZENO-CASEZ",
    "state_code": "TN",
    "cab": "354308",
    "cap": "38010"
  },
  {
    "key": "13811",
    "ITA": "SANZENO",
    "state_code": "TN",
    "cab": "354308",
    "cap": "38010"
  },
  {
    "key": "13812",
    "ITA": "SANZENO-BANCO",
    "state_code": "TN",
    "cab": "354308",
    "cap": "38010"
  },
  {
    "key": "13813",
    "ITA": "SAONARA-VILLATORA",
    "state_code": "PD",
    "cab": "628800",
    "cap": "35020"
  },
  {
    "key": "13814",
    "ITA": "SAONARA",
    "state_code": "PD",
    "cab": "628800",
    "cap": "35020"
  },
  {
    "key": "13815",
    "ITA": "SAPONARA",
    "state_code": "ME",
    "cab": "825505",
    "cap": "98047"
  },
  {
    "key": "13816",
    "ITA": "SAPONARA-SCARCELLI",
    "state_code": "ME",
    "cab": "825505",
    "cap": "98047"
  },
  {
    "key": "13817",
    "ITA": "SAPONARA-SAPONARA MARITTIMA",
    "state_code": "ME",
    "cab": "825505",
    "cap": "98047"
  },
  {
    "key": "13818",
    "ITA": "SAPONARA-CAVALIERE",
    "state_code": "ME",
    "cab": "825505",
    "cap": "98047"
  },
  {
    "key": "13819",
    "ITA": "SAPPADA",
    "state_code": "BL",
    "cab": "613000",
    "cap": "32047"
  },
  {
    "key": "13820",
    "ITA": "SAPPADA-GRANVILLA",
    "state_code": "BL",
    "cab": "613000",
    "cap": "32047"
  },
  {
    "key": "13821",
    "ITA": "SAPRI",
    "state_code": "SA",
    "cab": "764704",
    "cap": "84073"
  },
  {
    "key": "13822",
    "ITA": "SARACENA",
    "state_code": "CS",
    "cab": "810101",
    "cap": "87010"
  },
  {
    "key": "13823",
    "ITA": "SARACINESCO",
    "state_code": "RM",
    "cab": "908392",
    "cap": "20"
  },
  {
    "key": "13824",
    "ITA": "SARCEDO",
    "state_code": "VI",
    "cab": "883900",
    "cap": "36030"
  },
  {
    "key": "13825",
    "ITA": "SARCONI",
    "state_code": "PZ",
    "cab": "912162",
    "cap": "85050"
  },
  {
    "key": "13826",
    "ITA": "SARDARA",
    "state_code": "SU",
    "cab": "862003",
    "cap": "9030"
  },
  {
    "key": "13827",
    "ITA": "SARDIGLIANO-CUQUELLO",
    "state_code": "AL",
    "cab": "921684",
    "cap": "15060"
  },
  {
    "key": "13828",
    "ITA": "SARDIGLIANO",
    "state_code": "AL",
    "cab": "921684",
    "cap": "15060"
  },
  {
    "key": "13829",
    "ITA": "SAREGO-MONTICELLO DI FARA",
    "state_code": "VI",
    "cab": "607309",
    "cap": "36040"
  },
  {
    "key": "13830",
    "ITA": "SAREGO",
    "state_code": "VI",
    "cab": "607309",
    "cap": "36040"
  },
  {
    "key": "13831",
    "ITA": "SAREGO-MELEDO",
    "state_code": "VI",
    "cab": "607309",
    "cap": "36040"
  },
  {
    "key": "13832",
    "ITA": "SARENTINO-VILLA",
    "state_code": "BZ",
    "cab": "588707",
    "cap": "39058"
  },
  {
    "key": "13833",
    "ITA": "SARENTINO",
    "state_code": "BZ",
    "cab": "588707",
    "cap": "39058"
  },
  {
    "key": "13834",
    "ITA": "SARENTINO-CAMPOLASTA",
    "state_code": "BZ",
    "cab": "588707",
    "cap": "39058"
  },
  {
    "key": "13835",
    "ITA": "SAREZZANO",
    "state_code": "AL",
    "cab": "486001",
    "cap": "15050"
  },
  {
    "key": "13836",
    "ITA": "SAREZZO-PONTE ZANANO",
    "state_code": "BS",
    "cab": "552208",
    "cap": "25068"
  },
  {
    "key": "13837",
    "ITA": "SAREZZO",
    "state_code": "BS",
    "cab": "552208",
    "cap": "25068"
  },
  {
    "key": "13838",
    "ITA": "SAREZZO-ZANANO",
    "state_code": "BS",
    "cab": "552208",
    "cap": "25068"
  },
  {
    "key": "13839",
    "ITA": "SARMATO",
    "state_code": "PC",
    "cab": "654707",
    "cap": "29010"
  },
  {
    "key": "13840",
    "ITA": "SARMEDE-MONTANER",
    "state_code": "TV",
    "cab": "620807",
    "cap": "31010"
  },
  {
    "key": "13841",
    "ITA": "SARMEDE",
    "state_code": "TV",
    "cab": "620807",
    "cap": "31026"
  },
  {
    "key": "13842",
    "ITA": "SARNANO",
    "state_code": "MC",
    "cab": "691709",
    "cap": "62028"
  },
  {
    "key": "13843",
    "ITA": "SARNICO",
    "state_code": "BG",
    "cab": "534701",
    "cap": "24067"
  },
  {
    "key": "13844",
    "ITA": "SARNO-EPISCOPIO",
    "state_code": "SA",
    "cab": "764803",
    "cap": "84087"
  },
  {
    "key": "13845",
    "ITA": "SARNO",
    "state_code": "SA",
    "cab": "764803",
    "cap": "84087"
  },
  {
    "key": "13846",
    "ITA": "SARNO-LAVORATE",
    "state_code": "SA",
    "cab": "764803",
    "cap": "84087"
  },
  {
    "key": "13847",
    "ITA": "SARNONICO-SEIO",
    "state_code": "TN",
    "cab": "671305",
    "cap": "38011"
  },
  {
    "key": "13848",
    "ITA": "SARNONICO",
    "state_code": "TN",
    "cab": "671305",
    "cap": "38011"
  },
  {
    "key": "13849",
    "ITA": "SARONNO",
    "state_code": "VA",
    "cab": "505206",
    "cap": "21047"
  },
  {
    "key": "13850",
    "ITA": "SARRE-ANGELIN",
    "state_code": "AO",
    "cab": "317107",
    "cap": "11010"
  },
  {
    "key": "13851",
    "ITA": "SARRE-ARENSOD",
    "state_code": "AO",
    "cab": "317107",
    "cap": "11010"
  },
  {
    "key": "13852",
    "ITA": "SARRE-CHESALLET",
    "state_code": "AO",
    "cab": "317107",
    "cap": "11010"
  },
  {
    "key": "13853",
    "ITA": "SARRE",
    "state_code": "AO",
    "cab": "317107",
    "cap": "11010"
  },
  {
    "key": "13854",
    "ITA": "SARRE-MONTAN",
    "state_code": "AO",
    "cab": "317107",
    "cap": "11010"
  },
  {
    "key": "13855",
    "ITA": "SARRE-SAINT MAURICE",
    "state_code": "AO",
    "cab": "317107",
    "cap": "11010"
  },
  {
    "key": "13856",
    "ITA": "SARROCH-VILLA D'ORRI",
    "state_code": "CA",
    "cab": "439901",
    "cap": "9018"
  },
  {
    "key": "13857",
    "ITA": "SARROCH",
    "state_code": "CA",
    "cab": "439901",
    "cap": "9018"
  },
  {
    "key": "13858",
    "ITA": "SARSINA-SORBANO",
    "state_code": "FC",
    "cab": "680405",
    "cap": "47027"
  },
  {
    "key": "13859",
    "ITA": "SARSINA-QUARTO",
    "state_code": "FC",
    "cab": "680405",
    "cap": "47027"
  },
  {
    "key": "13860",
    "ITA": "SARSINA-PIEVE DI RIVOSCHIO",
    "state_code": "FC",
    "cab": "680405",
    "cap": "47027"
  },
  {
    "key": "13861",
    "ITA": "SARSINA",
    "state_code": "FC",
    "cab": "680405",
    "cap": "47027"
  },
  {
    "key": "13862",
    "ITA": "SARSINA-RANCHIO",
    "state_code": "FC",
    "cab": "680405",
    "cap": "47027"
  },
  {
    "key": "13863",
    "ITA": "SARTEANO",
    "state_code": "SI",
    "cab": "720300",
    "cap": "53047"
  },
  {
    "key": "13864",
    "ITA": "SARTIRANA LOMELLINA",
    "state_code": "PV",
    "cab": "562702",
    "cap": "27020"
  },
  {
    "key": "13865",
    "ITA": "SARULE",
    "state_code": "NU",
    "cab": "869503",
    "cap": "8020"
  },
  {
    "key": "13866",
    "ITA": "SARZANA-SAN LAZZARO",
    "state_code": "SP",
    "cab": "498402",
    "cap": "19038"
  },
  {
    "key": "13867",
    "ITA": "SARZANA-MARINELLA DI SARZANA",
    "state_code": "SP",
    "cab": "498402",
    "cap": "19038"
  },
  {
    "key": "13868",
    "ITA": "SARZANA-FALCINELLO",
    "state_code": "SP",
    "cab": "498402",
    "cap": "19038"
  },
  {
    "key": "13869",
    "ITA": "SARZANA",
    "state_code": "SP",
    "cab": "498402",
    "cap": "19038"
  },
  {
    "key": "13870",
    "ITA": "SARZANA-SANTA CATERINA",
    "state_code": "SP",
    "cab": "498402",
    "cap": "19038"
  },
  {
    "key": "13871",
    "ITA": "SARZANA-SARZANELLO",
    "state_code": "SP",
    "cab": "498402",
    "cap": "19038"
  },
  {
    "key": "13872",
    "ITA": "SASSANO-CAIAZZANO",
    "state_code": "SA",
    "cab": "766907",
    "cap": "84038"
  },
  {
    "key": "13873",
    "ITA": "SASSANO-SILLA",
    "state_code": "SA",
    "cab": "766907",
    "cap": "84030"
  },
  {
    "key": "13874",
    "ITA": "SASSANO",
    "state_code": "SA",
    "cab": "766907",
    "cap": "84038"
  },
  {
    "key": "13875",
    "ITA": "SASSARI-MACCIADOSA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7100"
  },
  {
    "key": "13876",
    "ITA": "SASSARI-RUMANEDDA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13877",
    "ITA": "SASSARI-SAN GIOVANNI",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7100"
  },
  {
    "key": "13878",
    "ITA": "SASSARI-TOTTUBELLA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13879",
    "ITA": "SASSARI",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7100"
  },
  {
    "key": "13880",
    "ITA": "SASSARI-ARGENTIERA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13881",
    "ITA": "SASSARI-BANCALI",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7100"
  },
  {
    "key": "13882",
    "ITA": "SASSARI-BIANCAREDDU",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13883",
    "ITA": "SASSARI-CAMPANEDDA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13884",
    "ITA": "SASSARI-CANAGLIA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13885",
    "ITA": "SASSARI-LA CORTE",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13886",
    "ITA": "SASSARI-LA LANDRIGGA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7100"
  },
  {
    "key": "13887",
    "ITA": "SASSARI-LI PUNTI",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7100"
  },
  {
    "key": "13888",
    "ITA": "SASSARI-OTTAVA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7100"
  },
  {
    "key": "13889",
    "ITA": "SASSARI-PALMADULA",
    "state_code": "SS",
    "cab": "172007",
    "cap": "7040"
  },
  {
    "key": "13890",
    "ITA": "SASSELLO-PALO",
    "state_code": "SV",
    "cab": "494906",
    "cap": "17046"
  },
  {
    "key": "13891",
    "ITA": "SASSELLO",
    "state_code": "SV",
    "cab": "494906",
    "cap": "17046"
  },
  {
    "key": "13892",
    "ITA": "SASSELLO-PIAMPALUDO",
    "state_code": "SV",
    "cab": "494906",
    "cap": "17046"
  },
  {
    "key": "13893",
    "ITA": "SASSETTA",
    "state_code": "LI",
    "cab": "707802",
    "cap": "57020"
  },
  {
    "key": "13894",
    "ITA": "SASSINORO",
    "state_code": "BN",
    "cab": "950550",
    "cap": "82026"
  },
  {
    "key": "13895",
    "ITA": "SASSO DI CASTALDA",
    "state_code": "PZ",
    "cab": "912170",
    "cap": "85050"
  },
  {
    "key": "13896",
    "ITA": "SASSO MARCONI-FONTANA",
    "state_code": "BO",
    "cab": "371005",
    "cap": "40037"
  },
  {
    "key": "13897",
    "ITA": "SASSO MARCONI-BORGONUOVO",
    "state_code": "BO",
    "cab": "371005",
    "cap": "40037"
  },
  {
    "key": "13898",
    "ITA": "SASSO MARCONI",
    "state_code": "BO",
    "cab": "371005",
    "cap": "40037"
  },
  {
    "key": "13899",
    "ITA": "SASSO MARCONI-PONTECCHIO MARCONI",
    "state_code": "BO",
    "cab": "371005",
    "cap": "40037"
  },
  {
    "key": "13900",
    "ITA": "SASSOCORVARO-MERCATALE",
    "state_code": "PU",
    "cab": "686303",
    "cap": "61028"
  },
  {
    "key": "13901",
    "ITA": "SASSOCORVARO-CAPRAZZINO",
    "state_code": "PU",
    "cab": "686303",
    "cap": "61028"
  },
  {
    "key": "13902",
    "ITA": "SASSOCORVARO",
    "state_code": "PU",
    "cab": "686303",
    "cap": "61028"
  },
  {
    "key": "13903",
    "ITA": "SASSOFELTRIO-VALLE SANT'ANASTASIO",
    "state_code": "PU",
    "cab": "686402",
    "cap": "61013"
  },
  {
    "key": "13904",
    "ITA": "SASSOFELTRIO",
    "state_code": "PU",
    "cab": "686402",
    "cap": "61013"
  },
  {
    "key": "13905",
    "ITA": "SASSOFELTRIO-FRATTE DI SASSOFELTRIO",
    "state_code": "PU",
    "cab": "686402",
    "cap": "61013"
  },
  {
    "key": "13906",
    "ITA": "SASSOFERRATO-MONTEROSSO STAZIONE",
    "state_code": "AN",
    "cab": "375907",
    "cap": "60041"
  },
  {
    "key": "13907",
    "ITA": "SASSOFERRATO-MONTEROSSO",
    "state_code": "AN",
    "cab": "375907",
    "cap": "60041"
  },
  {
    "key": "13908",
    "ITA": "SASSOFERRATO-CABERNARDI",
    "state_code": "AN",
    "cab": "375907",
    "cap": "60041"
  },
  {
    "key": "13909",
    "ITA": "SASSOFERRATO-BORGO SASSOFERRATO",
    "state_code": "AN",
    "cab": "375907",
    "cap": "60041"
  },
  {
    "key": "13910",
    "ITA": "SASSOFERRATO",
    "state_code": "AN",
    "cab": "375907",
    "cap": "60041"
  },
  {
    "key": "13911",
    "ITA": "SASSOFERRATO-PERTICANO",
    "state_code": "AN",
    "cab": "375907",
    "cap": "60041"
  },
  {
    "key": "13912",
    "ITA": "SASSOFERRATO-PIANO DI FRASSINETA",
    "state_code": "AN",
    "cab": "375907",
    "cap": "60041"
  },
  {
    "key": "13913",
    "ITA": "SASSUOLO",
    "state_code": "MO",
    "cab": "670109",
    "cap": "41049"
  },
  {
    "key": "13914",
    "ITA": "SASSUOLO-MONTEGIBBIO",
    "state_code": "MO",
    "cab": "670109",
    "cap": "41049"
  },
  {
    "key": "13915",
    "ITA": "SASSUOLO-SAN MICHELE DEI MUCCHIETTI",
    "state_code": "MO",
    "cab": "670109",
    "cap": "41049"
  },
  {
    "key": "13916",
    "ITA": "SATRIANO",
    "state_code": "CZ",
    "cab": "913491",
    "cap": "88060"
  },
  {
    "key": "13917",
    "ITA": "SATRIANO DI LUCANIA",
    "state_code": "PZ",
    "cab": "423301",
    "cap": "85050"
  },
  {
    "key": "13918",
    "ITA": "SAURIS",
    "state_code": "UD",
    "cab": "937219",
    "cap": "33020"
  },
  {
    "key": "13919",
    "ITA": "SAUZE D'OULX-SALICE D'ULZIO",
    "state_code": "TO",
    "cab": "309906",
    "cap": "10050"
  },
  {
    "key": "13920",
    "ITA": "SAUZE D'OULX",
    "state_code": "TO",
    "cab": "309906",
    "cap": "10050"
  },
  {
    "key": "13921",
    "ITA": "SAUZE DI CESANA",
    "state_code": "TO",
    "cab": "901579",
    "cap": "10054"
  },
  {
    "key": "13922",
    "ITA": "SAVA",
    "state_code": "TA",
    "cab": "790402",
    "cap": "74028"
  },
  {
    "key": "13923",
    "ITA": "SAVELLI",
    "state_code": "KR",
    "cab": "913509",
    "cap": "88825"
  },
  {
    "key": "13924",
    "ITA": "SAVIANO",
    "state_code": "NA",
    "cab": "402206",
    "cap": "80039"
  },
  {
    "key": "13925",
    "ITA": "SAVIANO-PIAZZOLLA DI SAVIANO",
    "state_code": "NA",
    "cab": "402206",
    "cap": "80039"
  },
  {
    "key": "13926",
    "ITA": "SAVIGLIANO-LEVALDIGI",
    "state_code": "CN",
    "cab": "468504",
    "cap": "12038"
  },
  {
    "key": "13927",
    "ITA": "SAVIGLIANO",
    "state_code": "CN",
    "cab": "468504",
    "cap": "12038"
  },
  {
    "key": "13928",
    "ITA": "SAVIGNANO IRPINO",
    "state_code": "AV",
    "cab": "759803",
    "cap": "83030"
  },
  {
    "key": "13929",
    "ITA": "SAVIGNANO IRPINO-SAVIGNANO STAZIONE",
    "state_code": "AV",
    "cab": "759803",
    "cap": "83030"
  },
  {
    "key": "13930",
    "ITA": "SAVIGNANO SUL PANARO-MULINO",
    "state_code": "MO",
    "cab": "670208",
    "cap": "41056"
  },
  {
    "key": "13931",
    "ITA": "SAVIGNANO SUL PANARO-MAGAZZINO",
    "state_code": "MO",
    "cab": "670208",
    "cap": "41056"
  },
  {
    "key": "13932",
    "ITA": "SAVIGNANO SUL PANARO-GAROFANO",
    "state_code": "MO",
    "cab": "670208",
    "cap": "41056"
  },
  {
    "key": "13933",
    "ITA": "SAVIGNANO SUL PANARO-FORMICA",
    "state_code": "MO",
    "cab": "670208",
    "cap": "41056"
  },
  {
    "key": "13934",
    "ITA": "SAVIGNANO SUL PANARO",
    "state_code": "MO",
    "cab": "670208",
    "cap": "41056"
  },
  {
    "key": "13935",
    "ITA": "SAVIGNANO SUL RUBICONE-FIUMICINO DI SAVIGNANO",
    "state_code": "FC",
    "cab": "680504",
    "cap": "47039"
  },
  {
    "key": "13936",
    "ITA": "SAVIGNANO SUL RUBICONE",
    "state_code": "FC",
    "cab": "680504",
    "cap": "47039"
  },
  {
    "key": "13937",
    "ITA": "SAVIGNO",
    "state_code": "BO",
    "cab": "371104",
    "cap": "40060"
  },
  {
    "key": "13938",
    "ITA": "SAVIGNO-VEDEGHETO",
    "state_code": "BO",
    "cab": "371104",
    "cap": "40060"
  },
  {
    "key": "13939",
    "ITA": "SAVIGNONE-ISORELLE",
    "state_code": "GE",
    "cab": "322107",
    "cap": "16010"
  },
  {
    "key": "13940",
    "ITA": "SAVIGNONE-PONTE DI SAVIGNONE",
    "state_code": "GE",
    "cab": "322107",
    "cap": "16010"
  },
  {
    "key": "13941",
    "ITA": "SAVIGNONE",
    "state_code": "GE",
    "cab": "322107",
    "cap": "16010"
  },
  {
    "key": "13942",
    "ITA": "SAVIORE DELL'ADAMELLO",
    "state_code": "BS",
    "cab": "929687",
    "cap": "25040"
  },
  {
    "key": "13943",
    "ITA": "SAVIORE DELL'ADAMELLO-VALLE DI SAVIORE",
    "state_code": "BS",
    "cab": "929687",
    "cap": "25050"
  },
  {
    "key": "13944",
    "ITA": "SAVOCA-RINA",
    "state_code": "ME",
    "cab": "961615",
    "cap": "98038"
  },
  {
    "key": "13945",
    "ITA": "SAVOCA",
    "state_code": "ME",
    "cab": "961615",
    "cap": "98038"
  },
  {
    "key": "13946",
    "ITA": "SAVOGNA",
    "state_code": "UD",
    "cab": "937227",
    "cap": "33040"
  },
  {
    "key": "13947",
    "ITA": "SAVOGNA D'ISONZO-RUPA",
    "state_code": "GO",
    "cab": "646505",
    "cap": "34070"
  },
  {
    "key": "13948",
    "ITA": "SAVOGNA D'ISONZO-GABRIA",
    "state_code": "GO",
    "cab": "646505",
    "cap": "34070"
  },
  {
    "key": "13949",
    "ITA": "SAVOGNA D'ISONZO",
    "state_code": "GO",
    "cab": "646505",
    "cap": "34070"
  },
  {
    "key": "13950",
    "ITA": "SAVOGNA D'ISONZO-SAN MICHELE DEL CARSO",
    "state_code": "GO",
    "cab": "646505",
    "cap": "34078"
  },
  {
    "key": "13951",
    "ITA": "SAVOIA DI LUCANIA",
    "state_code": "PZ",
    "cab": "912196",
    "cap": "85050"
  },
  {
    "key": "13952",
    "ITA": "SAVONA-SANTUARIO",
    "state_code": "SV",
    "cab": "106005",
    "cap": "17100"
  },
  {
    "key": "13953",
    "ITA": "SAVONA-ZINOLA",
    "state_code": "SV",
    "cab": "106005",
    "cap": "17100"
  },
  {
    "key": "13954",
    "ITA": "SAVONA-LEGINO",
    "state_code": "SV",
    "cab": "106005",
    "cap": "17100"
  },
  {
    "key": "13955",
    "ITA": "SAVONA-LAVAGNOLA",
    "state_code": "SV",
    "cab": "106005",
    "cap": "17100"
  },
  {
    "key": "13956",
    "ITA": "SAVONA-FORNACI",
    "state_code": "SV",
    "cab": "106005",
    "cap": "17100"
  },
  {
    "key": "13957",
    "ITA": "SAVONA",
    "state_code": "SV",
    "cab": "106005",
    "cap": "17100"
  },
  {
    "key": "13958",
    "ITA": "SCAFA",
    "state_code": "PE",
    "cab": "774208",
    "cap": "65027"
  },
  {
    "key": "13959",
    "ITA": "SCAFA-DECONTRA",
    "state_code": "PE",
    "cab": "774208",
    "cap": "65027"
  },
  {
    "key": "13960",
    "ITA": "SCAFATI",
    "state_code": "SA",
    "cab": "764902",
    "cap": "84018"
  },
  {
    "key": "13961",
    "ITA": "SCAFATI-SAN PIETRO DI SCAFATI",
    "state_code": "SA",
    "cab": "764902",
    "cap": "84018"
  },
  {
    "key": "13962",
    "ITA": "SCAGNELLO",
    "state_code": "CN",
    "cab": "919415",
    "cap": "12070"
  },
  {
    "key": "13963",
    "ITA": "SCALA-PONTONE",
    "state_code": "SA",
    "cab": "953083",
    "cap": "84010"
  },
  {
    "key": "13964",
    "ITA": "SCALA",
    "state_code": "SA",
    "cab": "953083",
    "cap": "84010"
  },
  {
    "key": "13965",
    "ITA": "SCALA COELI",
    "state_code": "CS",
    "cab": "959411",
    "cap": "87060"
  },
  {
    "key": "13966",
    "ITA": "SCALA COELI-SAN MORELLO",
    "state_code": "CS",
    "cab": "959411",
    "cap": "87060"
  },
  {
    "key": "13967",
    "ITA": "SCALDASOLE",
    "state_code": "PV",
    "cab": "562801",
    "cap": "27020"
  },
  {
    "key": "13968",
    "ITA": "SCALEA",
    "state_code": "CS",
    "cab": "810200",
    "cap": "87029"
  },
  {
    "key": "13969",
    "ITA": "SCALENGHE-VIOTTO",
    "state_code": "TO",
    "cab": "310003",
    "cap": "10060"
  },
  {
    "key": "13970",
    "ITA": "SCALENGHE",
    "state_code": "TO",
    "cab": "310003",
    "cap": "10060"
  },
  {
    "key": "13971",
    "ITA": "SCALETTA ZANCLEA",
    "state_code": "ME",
    "cab": "825604",
    "cap": "98029"
  },
  {
    "key": "13972",
    "ITA": "SCALETTA ZANCLEA-GUIDOMANDRI MARINA",
    "state_code": "ME",
    "cab": "825604",
    "cap": "98029"
  },
  {
    "key": "13973",
    "ITA": "SCALETTA ZANCLEA-GUIDOMANDRI SUPERIORE",
    "state_code": "ME",
    "cab": "825604",
    "cap": "98029"
  },
  {
    "key": "13974",
    "ITA": "SCAMPITELLA",
    "state_code": "AV",
    "cab": "692608",
    "cap": "83050"
  },
  {
    "key": "13975",
    "ITA": "SCANDALE",
    "state_code": "KR",
    "cab": "427302",
    "cap": "88831"
  },
  {
    "key": "13976",
    "ITA": "SCANDIANO",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13977",
    "ITA": "SCANDIANO-IANO",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13978",
    "ITA": "SCANDIANO-PRATISSOLO",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13979",
    "ITA": "SCANDIANO-RONDINARA",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13980",
    "ITA": "SCANDIANO-VENTOSO",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13981",
    "ITA": "SCANDIANO-BOSCO",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13982",
    "ITA": "SCANDIANO-CA' DE CAROLI",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13983",
    "ITA": "SCANDIANO-ARCETO",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13984",
    "ITA": "SCANDIANO-FELLEGARA",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13985",
    "ITA": "SCANDIANO-CHIOZZA",
    "state_code": "RE",
    "cab": "665109",
    "cap": "42019"
  },
  {
    "key": "13986",
    "ITA": "SCANDICCI-CASELLINA",
    "state_code": "FI",
    "cab": "380808",
    "cap": "50018"
  },
  {
    "key": "13987",
    "ITA": "SCANDICCI-SAN VINCENZO A TORRI",
    "state_code": "FI",
    "cab": "380808",
    "cap": "50018"
  },
  {
    "key": "13988",
    "ITA": "SCANDICCI",
    "state_code": "FI",
    "cab": "380808",
    "cap": "50018"
  },
  {
    "key": "13989",
    "ITA": "SCANDICCI-LE BAGNESE SAN GIUSTO",
    "state_code": "FI",
    "cab": "380808",
    "cap": "50018"
  },
  {
    "key": "13990",
    "ITA": "SCANDICCI-SAN MARTINO ALLA PALMA",
    "state_code": "FI",
    "cab": "380808",
    "cap": "50018"
  },
  {
    "key": "13991",
    "ITA": "SCANDICCI-BADIA A SETTIMO",
    "state_code": "FI",
    "cab": "380808",
    "cap": "50018"
  },
  {
    "key": "13992",
    "ITA": "SCANDOLARA RAVARA",
    "state_code": "CR",
    "cab": "571406",
    "cap": "26040"
  },
  {
    "key": "13993",
    "ITA": "SCANDOLARA RAVARA-CASTELPONZONE",
    "state_code": "CR",
    "cab": "571406",
    "cap": "26040"
  },
  {
    "key": "13994",
    "ITA": "SCANDOLARA RIPA D'OGLIO",
    "state_code": "CR",
    "cab": "931873",
    "cap": "26047"
  },
  {
    "key": "13995",
    "ITA": "SCANDRIGLIA",
    "state_code": "RI",
    "cab": "737809",
    "cap": "2038"
  },
  {
    "key": "13996",
    "ITA": "SCANDRIGLIA-PONTICELLI",
    "state_code": "RI",
    "cab": "737809",
    "cap": "2038"
  },
  {
    "key": "13997",
    "ITA": "SCANNO",
    "state_code": "AQ",
    "cab": "407809",
    "cap": "67038"
  },
  {
    "key": "13998",
    "ITA": "SCANNO-FRATTURA",
    "state_code": "AQ",
    "cab": "407809",
    "cap": "67038"
  },
  {
    "key": "13999",
    "ITA": "SCANO DI MONTIFERRO",
    "state_code": "OR",
    "cab": "880203",
    "cap": "9078"
  },
  {
    "key": "14000",
    "ITA": "SCANSANO-PRESELLE",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14001",
    "ITA": "SCANSANO-POMONTE",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14002",
    "ITA": "SCANSANO-POGGIOFERRO",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14003",
    "ITA": "SCANSANO-POLVERAIA",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14004",
    "ITA": "SCANSANO-PANCOLE",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14005",
    "ITA": "SCANSANO-MURCI",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14006",
    "ITA": "SCANSANO-MONTORGIALI",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14007",
    "ITA": "SCANSANO-BACCINELLO",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14008",
    "ITA": "SCANSANO",
    "state_code": "GR",
    "cab": "723700",
    "cap": "58054"
  },
  {
    "key": "14009",
    "ITA": "SCANZANO JONICO-RECOLETA",
    "state_code": "MT",
    "cab": "804906",
    "cap": "75020"
  },
  {
    "key": "14010",
    "ITA": "SCANZANO JONICO",
    "state_code": "MT",
    "cab": "804906",
    "cap": "75020"
  },
  {
    "key": "14011",
    "ITA": "SCANZOROSCIATE-GAVARNO",
    "state_code": "BG",
    "cab": "534800",
    "cap": "24020"
  },
  {
    "key": "14012",
    "ITA": "SCANZOROSCIATE",
    "state_code": "BG",
    "cab": "534800",
    "cap": "24020"
  },
  {
    "key": "14013",
    "ITA": "SCANZOROSCIATE-ROSCIATE",
    "state_code": "BG",
    "cab": "534800",
    "cap": "24020"
  },
  {
    "key": "14014",
    "ITA": "SCANZOROSCIATE-SCANZO",
    "state_code": "BG",
    "cab": "534800",
    "cap": "24020"
  },
  {
    "key": "14015",
    "ITA": "SCANZOROSCIATE-TRIBULINA",
    "state_code": "BG",
    "cab": "534800",
    "cap": "24020"
  },
  {
    "key": "14016",
    "ITA": "SCANZOROSCIATE-NEGRONE",
    "state_code": "BG",
    "cab": "534800",
    "cap": "24020"
  },
  {
    "key": "14017",
    "ITA": "SCAPOLI",
    "state_code": "IS",
    "cab": "955906",
    "cap": "86070"
  },
  {
    "key": "14018",
    "ITA": "SCARLINO",
    "state_code": "GR",
    "cab": "723809",
    "cap": "58020"
  },
  {
    "key": "14019",
    "ITA": "SCARLINO-PUNTONE",
    "state_code": "GR",
    "cab": "723809",
    "cap": "58020"
  },
  {
    "key": "14020",
    "ITA": "SCARLINO-SCARLINO SCALO",
    "state_code": "GR",
    "cab": "723809",
    "cap": "58020"
  },
  {
    "key": "14021",
    "ITA": "SCARMAGNO",
    "state_code": "TO",
    "cab": "310102",
    "cap": "10010"
  },
  {
    "key": "14022",
    "ITA": "SCARNAFIGI",
    "state_code": "CN",
    "cab": "468603",
    "cap": "12030"
  },
  {
    "key": "14023",
    "ITA": "SCARPERIA",
    "state_code": "FI",
    "cab": "380907",
    "cap": "50038"
  },
  {
    "key": "14024",
    "ITA": "SCARPERIA-SANT'AGATA",
    "state_code": "FI",
    "cab": "380907",
    "cap": "50038"
  },
  {
    "key": "14025",
    "ITA": "SCENA",
    "state_code": "BZ",
    "cab": "588806",
    "cap": "39017"
  },
  {
    "key": "14026",
    "ITA": "SCERNI",
    "state_code": "CH",
    "cab": "778704",
    "cap": "66020"
  },
  {
    "key": "14027",
    "ITA": "SCERNI-SAN GIACOMO DI SCERNI",
    "state_code": "CH",
    "cab": "778704",
    "cap": "66020"
  },
  {
    "key": "14028",
    "ITA": "SCHEGGIA E PASCELUPO-SCHEGGIA",
    "state_code": "PG",
    "cab": "386508",
    "cap": "6027"
  },
  {
    "key": "14029",
    "ITA": "SCHEGGIA E PASCELUPO-PASCELUPO",
    "state_code": "PG",
    "cab": "386508",
    "cap": "6027"
  },
  {
    "key": "14030",
    "ITA": "SCHEGGIA E PASCELUPO-ISOLA FOSSARA",
    "state_code": "PG",
    "cab": "386508",
    "cap": "6027"
  },
  {
    "key": "14031",
    "ITA": "SCHEGGIA E PASCELUPO",
    "state_code": "PG",
    "cab": "386508",
    "cap": "6027"
  },
  {
    "key": "14032",
    "ITA": "SCHEGGINO-CESELLI",
    "state_code": "PG",
    "cab": "386607",
    "cap": "6040"
  },
  {
    "key": "14033",
    "ITA": "SCHEGGINO",
    "state_code": "PG",
    "cab": "386607",
    "cap": "6040"
  },
  {
    "key": "14034",
    "ITA": "SCHIAVI DI ABRUZZO-TAVERNA DI SCHIAVI DI ABRUZZO",
    "state_code": "CH",
    "cab": "778803",
    "cap": "66045"
  },
  {
    "key": "14035",
    "ITA": "SCHIAVI DI ABRUZZO",
    "state_code": "CH",
    "cab": "778803",
    "cap": "66045"
  },
  {
    "key": "14036",
    "ITA": "SCHIAVON-LONGA",
    "state_code": "VI",
    "cab": "607408",
    "cap": "36060"
  },
  {
    "key": "14037",
    "ITA": "SCHIAVON",
    "state_code": "VI",
    "cab": "607408",
    "cap": "36060"
  },
  {
    "key": "14038",
    "ITA": "SCHIGNANO",
    "state_code": "CO",
    "cab": "926212",
    "cap": "22020"
  },
  {
    "key": "14039",
    "ITA": "SCHIGNANO-OCCAGNO",
    "state_code": "CO",
    "cab": "926212",
    "cap": "22020"
  },
  {
    "key": "14040",
    "ITA": "SCHILPARIO",
    "state_code": "BG",
    "cab": "534909",
    "cap": "24020"
  },
  {
    "key": "14041",
    "ITA": "SCHIO-MONTE MAGRE'",
    "state_code": "VI",
    "cab": "607507",
    "cap": "36015"
  },
  {
    "key": "14042",
    "ITA": "SCHIO-SANT'ULDERICO",
    "state_code": "VI",
    "cab": "607507",
    "cap": "36015"
  },
  {
    "key": "14043",
    "ITA": "SCHIO-TRETTO",
    "state_code": "VI",
    "cab": "607507",
    "cap": "36015"
  },
  {
    "key": "14044",
    "ITA": "SCHIO-GIAVENALE",
    "state_code": "VI",
    "cab": "607507",
    "cap": "36015"
  },
  {
    "key": "14045",
    "ITA": "SCHIO-MAGRE' DI SCHIO",
    "state_code": "VI",
    "cab": "607507",
    "cap": "36015"
  },
  {
    "key": "14046",
    "ITA": "SCHIO",
    "state_code": "VI",
    "cab": "607507",
    "cap": "36015"
  },
  {
    "key": "14047",
    "ITA": "SCHIVENOGLIA",
    "state_code": "MN",
    "cab": "579607",
    "cap": "46020"
  },
  {
    "key": "14048",
    "ITA": "SCIACCA",
    "state_code": "AG",
    "cab": "831701",
    "cap": "92019"
  },
  {
    "key": "14049",
    "ITA": "SCIARA",
    "state_code": "PA",
    "cab": "436303",
    "cap": "90020"
  },
  {
    "key": "14050",
    "ITA": "SCICLI-SAMPIERI",
    "state_code": "RG",
    "cab": "845206",
    "cap": "97018"
  },
  {
    "key": "14051",
    "ITA": "SCICLI-CAVA D'ALIGA",
    "state_code": "RG",
    "cab": "845206",
    "cap": "97018"
  },
  {
    "key": "14052",
    "ITA": "SCICLI-DONNALUCATA",
    "state_code": "RG",
    "cab": "845206",
    "cap": "97018"
  },
  {
    "key": "14053",
    "ITA": "SCICLI-JUNGI",
    "state_code": "RG",
    "cab": "845206",
    "cap": "97018"
  },
  {
    "key": "14054",
    "ITA": "SCICLI",
    "state_code": "RG",
    "cab": "845206",
    "cap": "97018"
  },
  {
    "key": "14055",
    "ITA": "SCIDO",
    "state_code": "RC",
    "cab": "960427",
    "cap": "89010"
  },
  {
    "key": "14056",
    "ITA": "SCIGLIANO-DIANO",
    "state_code": "CS",
    "cab": "811406",
    "cap": "87057"
  },
  {
    "key": "14057",
    "ITA": "SCIGLIANO-CALVISI",
    "state_code": "CS",
    "cab": "811406",
    "cap": "87057"
  },
  {
    "key": "14058",
    "ITA": "SCIGLIANO",
    "state_code": "CS",
    "cab": "811406",
    "cap": "87057"
  },
  {
    "key": "14059",
    "ITA": "SCILLA",
    "state_code": "RC",
    "cab": "815704",
    "cap": "89058"
  },
  {
    "key": "14060",
    "ITA": "SCILLA-MELIA",
    "state_code": "RC",
    "cab": "815704",
    "cap": "89058"
  },
  {
    "key": "14061",
    "ITA": "SCILLA-FAVAZZINA",
    "state_code": "RC",
    "cab": "815704",
    "cap": "89058"
  },
  {
    "key": "14062",
    "ITA": "SCILLATO",
    "state_code": "PA",
    "cab": "889105",
    "cap": "90020"
  },
  {
    "key": "14063",
    "ITA": "SCIOLZE",
    "state_code": "TO",
    "cab": "901587",
    "cap": "10090"
  },
  {
    "key": "14064",
    "ITA": "SCISCIANO-SPARTIMENTO",
    "state_code": "NA",
    "cab": "402305",
    "cap": "80030"
  },
  {
    "key": "14065",
    "ITA": "SCISCIANO",
    "state_code": "NA",
    "cab": "402305",
    "cap": "80030"
  },
  {
    "key": "14066",
    "ITA": "SCLAFANI BAGNI",
    "state_code": "PA",
    "cab": "438002",
    "cap": "90020"
  },
  {
    "key": "14067",
    "ITA": "SCONTRONE",
    "state_code": "AQ",
    "cab": "909960",
    "cap": "67030"
  },
  {
    "key": "14068",
    "ITA": "SCONTRONE-VILLA SCONTRONE",
    "state_code": "AQ",
    "cab": "909960",
    "cap": "67030"
  },
  {
    "key": "14069",
    "ITA": "SCOPA",
    "state_code": "VC",
    "cab": "447805",
    "cap": "13027"
  },
  {
    "key": "14070",
    "ITA": "SCOPELLO",
    "state_code": "VC",
    "cab": "447904",
    "cap": "13028"
  },
  {
    "key": "14071",
    "ITA": "SCOPPITO-VIGLIANO",
    "state_code": "AQ",
    "cab": "408401",
    "cap": "67019"
  },
  {
    "key": "14072",
    "ITA": "SCOPPITO",
    "state_code": "AQ",
    "cab": "408401",
    "cap": "67019"
  },
  {
    "key": "14073",
    "ITA": "SCOPPITO-SELLA DI CORNO",
    "state_code": "AQ",
    "cab": "408401",
    "cap": "67019"
  },
  {
    "key": "14074",
    "ITA": "SCORDIA",
    "state_code": "CT",
    "cab": "842708",
    "cap": "95048"
  },
  {
    "key": "14075",
    "ITA": "SCORRANO",
    "state_code": "LE",
    "cab": "800003",
    "cap": "73020"
  },
  {
    "key": "14076",
    "ITA": "SCORZE'",
    "state_code": "VE",
    "cab": "363200",
    "cap": "30037"
  },
  {
    "key": "14077",
    "ITA": "SCORZE'-PESEGGIA",
    "state_code": "VE",
    "cab": "363200",
    "cap": "30030"
  },
  {
    "key": "14078",
    "ITA": "SCORZE'-RIO SAN MARTINO",
    "state_code": "VE",
    "cab": "363200",
    "cap": "30037"
  },
  {
    "key": "14079",
    "ITA": "SCURCOLA MARSICANA-CAPPELLE",
    "state_code": "AQ",
    "cab": "407908",
    "cap": "67068"
  },
  {
    "key": "14080",
    "ITA": "SCURCOLA MARSICANA",
    "state_code": "AQ",
    "cab": "407908",
    "cap": "67068"
  },
  {
    "key": "14081",
    "ITA": "SCURELLE",
    "state_code": "TN",
    "cab": "354407",
    "cap": "38050"
  },
  {
    "key": "14082",
    "ITA": "SCURZOLENGO",
    "state_code": "AT",
    "cab": "477000",
    "cap": "14030"
  },
  {
    "key": "14083",
    "ITA": "SEBORGA",
    "state_code": "IM",
    "cab": "922617",
    "cap": "18012"
  },
  {
    "key": "14084",
    "ITA": "SECINARO",
    "state_code": "AQ",
    "cab": "909986",
    "cap": "67029"
  },
  {
    "key": "14085",
    "ITA": "SECLI'",
    "state_code": "LE",
    "cab": "957712",
    "cap": "73050"
  },
  {
    "key": "14086",
    "ITA": "SECUGNAGO",
    "state_code": "LO",
    "cab": "338004",
    "cap": "26826"
  },
  {
    "key": "14087",
    "ITA": "SEDEGLIANO",
    "state_code": "UD",
    "cab": "642504",
    "cap": "33039"
  },
  {
    "key": "14088",
    "ITA": "SEDEGLIANO-TURRIDA",
    "state_code": "UD",
    "cab": "642504",
    "cap": "33039"
  },
  {
    "key": "14089",
    "ITA": "SEDEGLIANO-SAN LORENZO",
    "state_code": "UD",
    "cab": "642504",
    "cap": "33039"
  },
  {
    "key": "14090",
    "ITA": "SEDEGLIANO-GRADISCA DI SEDEGLIANO",
    "state_code": "UD",
    "cab": "642504",
    "cap": "33039"
  },
  {
    "key": "14091",
    "ITA": "SEDEGLIANO-CODERNO",
    "state_code": "UD",
    "cab": "642504",
    "cap": "33039"
  },
  {
    "key": "14092",
    "ITA": "SEDICO-BRIBANO",
    "state_code": "BL",
    "cab": "613109",
    "cap": "32036"
  },
  {
    "key": "14093",
    "ITA": "SEDICO-ROE ALTE",
    "state_code": "BL",
    "cab": "613109",
    "cap": "32036"
  },
  {
    "key": "14094",
    "ITA": "SEDICO-MAS",
    "state_code": "BL",
    "cab": "613109",
    "cap": "32036"
  },
  {
    "key": "14095",
    "ITA": "SEDICO",
    "state_code": "BL",
    "cab": "613109",
    "cap": "32036"
  },
  {
    "key": "14096",
    "ITA": "SEDILO",
    "state_code": "OR",
    "cab": "880302",
    "cap": "9076"
  },
  {
    "key": "14097",
    "ITA": "SEDINI",
    "state_code": "SS",
    "cab": "875708",
    "cap": "7035"
  },
  {
    "key": "14098",
    "ITA": "SEDRIANO",
    "state_code": "MI",
    "cab": "338103",
    "cap": "20018"
  },
  {
    "key": "14099",
    "ITA": "SEDRINA-BOTTA",
    "state_code": "BG",
    "cab": "893701",
    "cap": "24010"
  },
  {
    "key": "14100",
    "ITA": "SEDRINA",
    "state_code": "BG",
    "cab": "893701",
    "cap": "24010"
  },
  {
    "key": "14101",
    "ITA": "SEFRO",
    "state_code": "MC",
    "cab": "942003",
    "cap": "62025"
  },
  {
    "key": "14102",
    "ITA": "SEGARIU",
    "state_code": "SU",
    "cab": "862102",
    "cap": "9040"
  },
  {
    "key": "14103",
    "ITA": "SEGGIANO",
    "state_code": "GR",
    "cab": "723908",
    "cap": "58038"
  },
  {
    "key": "14104",
    "ITA": "SEGNI",
    "state_code": "RM",
    "cab": "394304",
    "cap": "37"
  },
  {
    "key": "14105",
    "ITA": "SEGONZANO-VALCAVA",
    "state_code": "TN",
    "cab": "354506",
    "cap": "38047"
  },
  {
    "key": "14106",
    "ITA": "SEGONZANO",
    "state_code": "TN",
    "cab": "354506",
    "cap": "38047"
  },
  {
    "key": "14107",
    "ITA": "SEGONZANO-QUARAS",
    "state_code": "TN",
    "cab": "354506",
    "cap": "38047"
  },
  {
    "key": "14108",
    "ITA": "SEGONZANO-SEVIGNANO",
    "state_code": "TN",
    "cab": "354506",
    "cap": "38047"
  },
  {
    "key": "14109",
    "ITA": "SEGRATE-TREGAREZZO",
    "state_code": "MI",
    "cab": "206003",
    "cap": "20090"
  },
  {
    "key": "14110",
    "ITA": "SEGRATE-SAN FELICE",
    "state_code": "MI",
    "cab": "206003",
    "cap": "20090"
  },
  {
    "key": "14111",
    "ITA": "SEGRATE-NOVEGRO",
    "state_code": "MI",
    "cab": "206003",
    "cap": "20090"
  },
  {
    "key": "14112",
    "ITA": "SEGRATE-LINATE AEROPORTO",
    "state_code": "MI",
    "cab": "206003",
    "cap": "20090"
  },
  {
    "key": "14113",
    "ITA": "SEGRATE",
    "state_code": "MI",
    "cab": "206003",
    "cap": "20090"
  },
  {
    "key": "14114",
    "ITA": "SEGUSINO",
    "state_code": "TV",
    "cab": "883207",
    "cap": "31040"
  },
  {
    "key": "14115",
    "ITA": "SELARGIUS-SU PLANU",
    "state_code": "CA",
    "cab": "441006",
    "cap": "9047"
  },
  {
    "key": "14116",
    "ITA": "SELARGIUS",
    "state_code": "CA",
    "cab": "441006",
    "cap": "9047"
  },
  {
    "key": "14117",
    "ITA": "SELCI",
    "state_code": "RI",
    "cab": "737908",
    "cap": "2040"
  },
  {
    "key": "14118",
    "ITA": "SELEGAS",
    "state_code": "CA",
    "cab": "862201",
    "cap": "9040"
  },
  {
    "key": "14119",
    "ITA": "SELLANO-CAMMORO",
    "state_code": "PG",
    "cab": "386706",
    "cap": "6030"
  },
  {
    "key": "14120",
    "ITA": "SELLANO",
    "state_code": "PG",
    "cab": "386706",
    "cap": "6030"
  },
  {
    "key": "14121",
    "ITA": "SELLERO",
    "state_code": "BS",
    "cab": "929695",
    "cap": "25050"
  },
  {
    "key": "14122",
    "ITA": "SELLERO-NOVELLE",
    "state_code": "BS",
    "cab": "929695",
    "cap": "25050"
  },
  {
    "key": "14123",
    "ITA": "SELLIA",
    "state_code": "CZ",
    "cab": "913517",
    "cap": "88050"
  },
  {
    "key": "14124",
    "ITA": "SELLIA MARINA-URIA",
    "state_code": "CZ",
    "cab": "428607",
    "cap": "88050"
  },
  {
    "key": "14125",
    "ITA": "SELLIA MARINA",
    "state_code": "CZ",
    "cab": "428607",
    "cap": "88050"
  },
  {
    "key": "14126",
    "ITA": "SELLIA MARINA-CALABRICATA",
    "state_code": "CZ",
    "cab": "428607",
    "cap": "88050"
  },
  {
    "key": "14127",
    "ITA": "SELLIA MARINA-LA PETRIZIA",
    "state_code": "CZ",
    "cab": "428607",
    "cap": "88050"
  },
  {
    "key": "14128",
    "ITA": "SELVA DEI MOLINI",
    "state_code": "BZ",
    "cab": "592006",
    "cap": "39030"
  },
  {
    "key": "14129",
    "ITA": "SELVA DI CADORE",
    "state_code": "BL",
    "cab": "613901",
    "cap": "32020"
  },
  {
    "key": "14130",
    "ITA": "SELVA DI PROGNO-SAN BORTOLO",
    "state_code": "VR",
    "cab": "706200",
    "cap": "37030"
  },
  {
    "key": "14131",
    "ITA": "SELVA DI PROGNO",
    "state_code": "VR",
    "cab": "706200",
    "cap": "37030"
  },
  {
    "key": "14132",
    "ITA": "SELVA DI VAL GARDENA",
    "state_code": "BZ",
    "cab": "588905",
    "cap": "39048"
  },
  {
    "key": "14133",
    "ITA": "SELVA DI VAL GARDENA-SELVA",
    "state_code": "BZ",
    "cab": "588905",
    "cap": "39048"
  },
  {
    "key": "14134",
    "ITA": "SELVAZZANO DENTRO-TENCAROLA",
    "state_code": "PD",
    "cab": "628909",
    "cap": "35030"
  },
  {
    "key": "14135",
    "ITA": "SELVAZZANO DENTRO",
    "state_code": "PD",
    "cab": "628909",
    "cap": "35030"
  },
  {
    "key": "14136",
    "ITA": "SELVAZZANO DENTRO-CASELLE",
    "state_code": "PD",
    "cab": "628909",
    "cap": "35030"
  },
  {
    "key": "14137",
    "ITA": "SELVAZZANO DENTRO-FERIOLE",
    "state_code": "PD",
    "cab": "628909",
    "cap": "35030"
  },
  {
    "key": "14138",
    "ITA": "SELVE MARCONE",
    "state_code": "BI",
    "cab": "916379",
    "cap": "13841"
  },
  {
    "key": "14139",
    "ITA": "SELVINO",
    "state_code": "BG",
    "cab": "535005",
    "cap": "24020"
  },
  {
    "key": "14140",
    "ITA": "SEMESTENE",
    "state_code": "SS",
    "cab": "875807",
    "cap": "7010"
  },
  {
    "key": "14141",
    "ITA": "SEMIANA",
    "state_code": "PV",
    "cab": "930883",
    "cap": "27020"
  },
  {
    "key": "14142",
    "ITA": "SEMINARA",
    "state_code": "RC",
    "cab": "815803",
    "cap": "89028"
  },
  {
    "key": "14143",
    "ITA": "SEMINARA-BARRITTERI",
    "state_code": "RC",
    "cab": "815803",
    "cap": "89028"
  },
  {
    "key": "14144",
    "ITA": "SEMINARA-SANT'ANNA",
    "state_code": "RC",
    "cab": "815803",
    "cap": "89028"
  },
  {
    "key": "14145",
    "ITA": "SEMPRONIANO-PETRICCI",
    "state_code": "GR",
    "cab": "724203",
    "cap": "58055"
  },
  {
    "key": "14146",
    "ITA": "SEMPRONIANO",
    "state_code": "GR",
    "cab": "724203",
    "cap": "58055"
  },
  {
    "key": "14147",
    "ITA": "SEMPRONIANO-CATABBIO",
    "state_code": "GR",
    "cab": "724203",
    "cap": "58055"
  },
  {
    "key": "14148",
    "ITA": "SEMPRONIANO-CELLENA",
    "state_code": "GR",
    "cab": "724203",
    "cap": "58055"
  },
  {
    "key": "14149",
    "ITA": "SENAGO",
    "state_code": "MI",
    "cab": "338202",
    "cap": "20030"
  },
  {
    "key": "14150",
    "ITA": "SENALE SAN FELICE-SENALE",
    "state_code": "BZ",
    "cab": "891309",
    "cap": "39010"
  },
  {
    "key": "14151",
    "ITA": "SENALE SAN FELICE",
    "state_code": "BZ",
    "cab": "891309",
    "cap": "39010"
  },
  {
    "key": "14152",
    "ITA": "SENALE SAN FELICE-SAN FELICE",
    "state_code": "BZ",
    "cab": "891309",
    "cap": "39010"
  },
  {
    "key": "14153",
    "ITA": "SENALES",
    "state_code": "BZ",
    "cab": "589002",
    "cap": "39020"
  },
  {
    "key": "14154",
    "ITA": "SENALES-MADONNA",
    "state_code": "BZ",
    "cab": "589002",
    "cap": "39020"
  },
  {
    "key": "14155",
    "ITA": "SENALES-SANTA CATERINA",
    "state_code": "BZ",
    "cab": "589002",
    "cap": "39020"
  },
  {
    "key": "14156",
    "ITA": "SENEGHE",
    "state_code": "OR",
    "cab": "880401",
    "cap": "9070"
  },
  {
    "key": "14157",
    "ITA": "SENERCHIA",
    "state_code": "AV",
    "cab": "951673",
    "cap": "83050"
  },
  {
    "key": "14158",
    "ITA": "SENIGA",
    "state_code": "BS",
    "cab": "552307",
    "cap": "25020"
  },
  {
    "key": "14159",
    "ITA": "SENIGALLIA-MARZOCCA",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14160",
    "ITA": "SENIGALLIA-SCAPEZZANO",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14161",
    "ITA": "SENIGALLIA-VALLONE",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14162",
    "ITA": "SENIGALLIA-MONTIGNANO",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14163",
    "ITA": "SENIGALLIA-RONCITELLI",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14164",
    "ITA": "SENIGALLIA-SANT'ANGELO",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14165",
    "ITA": "SENIGALLIA",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14166",
    "ITA": "SENIGALLIA-CESANO DI SENIGALLIA",
    "state_code": "AN",
    "cab": "213009",
    "cap": "60019"
  },
  {
    "key": "14167",
    "ITA": "SENIS",
    "state_code": "OR",
    "cab": "880500",
    "cap": "9080"
  },
  {
    "key": "14168",
    "ITA": "SENISE",
    "state_code": "PZ",
    "cab": "422709",
    "cap": "85038"
  },
  {
    "key": "14169",
    "ITA": "SENNA COMASCO",
    "state_code": "CO",
    "cab": "458901",
    "cap": "22070"
  },
  {
    "key": "14170",
    "ITA": "SENNA LODIGIANA",
    "state_code": "LO",
    "cab": "338301",
    "cap": "26856"
  },
  {
    "key": "14171",
    "ITA": "SENNA LODIGIANA-MIRABELLO",
    "state_code": "LO",
    "cab": "338301",
    "cap": "26856"
  },
  {
    "key": "14172",
    "ITA": "SENNARIOLO",
    "state_code": "OR",
    "cab": "880609",
    "cap": "9078"
  },
  {
    "key": "14173",
    "ITA": "SENNORI",
    "state_code": "SS",
    "cab": "850602",
    "cap": "7036"
  },
  {
    "key": "14174",
    "ITA": "SENORBI'",
    "state_code": "CA",
    "cab": "440008",
    "cap": "9040"
  },
  {
    "key": "14175",
    "ITA": "SENORBI'-ARIXI",
    "state_code": "CA",
    "cab": "440008",
    "cap": "9040"
  },
  {
    "key": "14176",
    "ITA": "SENORBI'-SISINI",
    "state_code": "CA",
    "cab": "440008",
    "cap": "9040"
  },
  {
    "key": "14177",
    "ITA": "SEPINO",
    "state_code": "CB",
    "cab": "411207",
    "cap": "86017"
  },
  {
    "key": "14178",
    "ITA": "SEPPIANA",
    "state_code": "VB",
    "cab": "917708",
    "cap": "28843"
  },
  {
    "key": "14179",
    "ITA": "SEQUALS-SOLIMBERGO",
    "state_code": "PN",
    "cab": "681304",
    "cap": "33090"
  },
  {
    "key": "14180",
    "ITA": "SEQUALS-LESTANS",
    "state_code": "PN",
    "cab": "681304",
    "cap": "33090"
  },
  {
    "key": "14181",
    "ITA": "SEQUALS",
    "state_code": "PN",
    "cab": "681304",
    "cap": "33090"
  },
  {
    "key": "14182",
    "ITA": "SERAVEZZA-RIOMAGNO",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55047"
  },
  {
    "key": "14183",
    "ITA": "SERAVEZZA-QUERCETA",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55047"
  },
  {
    "key": "14184",
    "ITA": "SERAVEZZA-PONTEROSSO",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55047"
  },
  {
    "key": "14185",
    "ITA": "SERAVEZZA",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55047"
  },
  {
    "key": "14186",
    "ITA": "SERAVEZZA-AZZANO",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55047"
  },
  {
    "key": "14187",
    "ITA": "SERAVEZZA-BASATI",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55040"
  },
  {
    "key": "14188",
    "ITA": "SERAVEZZA-RIPA",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55047"
  },
  {
    "key": "14189",
    "ITA": "SERAVEZZA-POZZI",
    "state_code": "LU",
    "cab": "702407",
    "cap": "55047"
  },
  {
    "key": "14190",
    "ITA": "SERDIANA",
    "state_code": "CA",
    "cab": "862300",
    "cap": "9040"
  },
  {
    "key": "14191",
    "ITA": "SEREGNO",
    "state_code": "MB",
    "cab": "338400",
    "cap": "20038"
  },
  {
    "key": "14192",
    "ITA": "SEREN DEL GRAPPA",
    "state_code": "BL",
    "cab": "692905",
    "cap": "32030"
  },
  {
    "key": "14193",
    "ITA": "SERGNANO",
    "state_code": "CR",
    "cab": "571505",
    "cap": "26010"
  },
  {
    "key": "14194",
    "ITA": "SERIATE-CASSINONE",
    "state_code": "BG",
    "cab": "535104",
    "cap": "24068"
  },
  {
    "key": "14195",
    "ITA": "SERIATE",
    "state_code": "BG",
    "cab": "535104",
    "cap": "24068"
  },
  {
    "key": "14196",
    "ITA": "SERINA",
    "state_code": "BG",
    "cab": "535203",
    "cap": "24017"
  },
  {
    "key": "14197",
    "ITA": "SERINO",
    "state_code": "AV",
    "cab": "759100",
    "cap": "83028"
  },
  {
    "key": "14198",
    "ITA": "SERINO-CANALE",
    "state_code": "AV",
    "cab": "759100",
    "cap": "83028"
  },
  {
    "key": "14199",
    "ITA": "SERINO-SALA",
    "state_code": "AV",
    "cab": "759100",
    "cap": "83028"
  },
  {
    "key": "14200",
    "ITA": "SERINO-SAN BIAGIO",
    "state_code": "AV",
    "cab": "759100",
    "cap": "83028"
  },
  {
    "key": "14201",
    "ITA": "SERINO-SAN SOSSIO DI SERINO",
    "state_code": "AV",
    "cab": "759100",
    "cap": "83028"
  },
  {
    "key": "14202",
    "ITA": "SERINO-FERRARI STAZIONE SERINO",
    "state_code": "AV",
    "cab": "759100",
    "cap": "83028"
  },
  {
    "key": "14203",
    "ITA": "SERLE",
    "state_code": "BS",
    "cab": "552406",
    "cap": "25080"
  },
  {
    "key": "14204",
    "ITA": "SERMIDE-SANTA CROCE",
    "state_code": "MN",
    "cab": "579706",
    "cap": "46028"
  },
  {
    "key": "14205",
    "ITA": "SERMIDE",
    "state_code": "MN",
    "cab": "579706",
    "cap": "46028"
  },
  {
    "key": "14206",
    "ITA": "SERMIDE-CAPOSOTTO",
    "state_code": "MN",
    "cab": "579706",
    "cap": "46028"
  },
  {
    "key": "14207",
    "ITA": "SERMIDE-MALCANTONE",
    "state_code": "MN",
    "cab": "579706",
    "cap": "46028"
  },
  {
    "key": "14208",
    "ITA": "SERMIDE-MOGLIA DI SERMIDE",
    "state_code": "MN",
    "cab": "579706",
    "cap": "46028"
  },
  {
    "key": "14209",
    "ITA": "SERMONETA-CARRARA",
    "state_code": "LT",
    "cab": "741801",
    "cap": "4013"
  },
  {
    "key": "14210",
    "ITA": "SERMONETA-MONTICCHIO",
    "state_code": "LT",
    "cab": "741801",
    "cap": "4013"
  },
  {
    "key": "14211",
    "ITA": "SERMONETA-LATINA AEROPORTO",
    "state_code": "LT",
    "cab": "741801",
    "cap": "4013"
  },
  {
    "key": "14212",
    "ITA": "SERMONETA-SERMONETA SCALO",
    "state_code": "LT",
    "cab": "741801",
    "cap": "4013"
  },
  {
    "key": "14213",
    "ITA": "SERMONETA-DOGANELLA",
    "state_code": "LT",
    "cab": "741801",
    "cap": "4013"
  },
  {
    "key": "14214",
    "ITA": "SERMONETA",
    "state_code": "LT",
    "cab": "741801",
    "cap": "4013"
  },
  {
    "key": "14215",
    "ITA": "SERNAGLIA DELLA BATTAGLIA",
    "state_code": "TV",
    "cab": "620906",
    "cap": "31020"
  },
  {
    "key": "14216",
    "ITA": "SERNAGLIA DELLA BATTAGLIA-FALZE' DI PIAVE",
    "state_code": "TV",
    "cab": "620906",
    "cap": "31020"
  },
  {
    "key": "14217",
    "ITA": "SERNIO",
    "state_code": "SO",
    "cab": "927285",
    "cap": "23030"
  },
  {
    "key": "14218",
    "ITA": "SEROLE",
    "state_code": "AT",
    "cab": "920439",
    "cap": "14050"
  },
  {
    "key": "14219",
    "ITA": "SERRA D'AIELLO",
    "state_code": "CS",
    "cab": "959437",
    "cap": "87030"
  },
  {
    "key": "14220",
    "ITA": "SERRA DE' CONTI",
    "state_code": "AN",
    "cab": "376004",
    "cap": "60030"
  },
  {
    "key": "14221",
    "ITA": "SERRA DE' CONTI-OSTERIA",
    "state_code": "AN",
    "cab": "376004",
    "cap": "60030"
  },
  {
    "key": "14222",
    "ITA": "SERRA PEDACE",
    "state_code": "CS",
    "cab": "959445",
    "cap": "87050"
  },
  {
    "key": "14223",
    "ITA": "SERRA PEDACE-SILVANA MANSIO",
    "state_code": "CS",
    "cab": "959445",
    "cap": "87050"
  },
  {
    "key": "14224",
    "ITA": "SERRA RICCO'-MAINETTO",
    "state_code": "GE",
    "cab": "322206",
    "cap": "16010"
  },
  {
    "key": "14225",
    "ITA": "SERRA RICCO'-CASTAGNA",
    "state_code": "GE",
    "cab": "322206",
    "cap": "16010"
  },
  {
    "key": "14226",
    "ITA": "SERRA RICCO'",
    "state_code": "GE",
    "cab": "322206",
    "cap": "16010"
  },
  {
    "key": "14227",
    "ITA": "SERRA RICCO'-ORERO DI SERRA RICCO'",
    "state_code": "GE",
    "cab": "322206",
    "cap": "16015"
  },
  {
    "key": "14228",
    "ITA": "SERRA RICCO'-PRELO",
    "state_code": "GE",
    "cab": "322206",
    "cap": "16010"
  },
  {
    "key": "14229",
    "ITA": "SERRA RICCO'-PEDEMONTE",
    "state_code": "GE",
    "cab": "322206",
    "cap": "16010"
  },
  {
    "key": "14230",
    "ITA": "SERRA SAN BRUNO",
    "state_code": "VV",
    "cab": "427401",
    "cap": "89822"
  },
  {
    "key": "14231",
    "ITA": "SERRA SAN QUIRICO",
    "state_code": "AN",
    "cab": "376103",
    "cap": "60048"
  },
  {
    "key": "14232",
    "ITA": "SERRA SAN QUIRICO-CASTELLARO",
    "state_code": "AN",
    "cab": "376103",
    "cap": "60048"
  },
  {
    "key": "14233",
    "ITA": "SERRA SAN QUIRICO-DOMO",
    "state_code": "AN",
    "cab": "376103",
    "cap": "60048"
  },
  {
    "key": "14234",
    "ITA": "SERRA SAN QUIRICO-SASSO",
    "state_code": "AN",
    "cab": "376103",
    "cap": "60048"
  },
  {
    "key": "14235",
    "ITA": "SERRA SAN QUIRICO-SERRA SAN QUIRICO STAZIONE",
    "state_code": "AN",
    "cab": "376103",
    "cap": "60048"
  },
  {
    "key": "14236",
    "ITA": "SERRA SANT'ABBONDIO",
    "state_code": "PU",
    "cab": "686501",
    "cap": "61040"
  },
  {
    "key": "14237",
    "ITA": "SERRACAPRIOLA",
    "state_code": "FG",
    "cab": "786509",
    "cap": "71010"
  },
  {
    "key": "14238",
    "ITA": "SERRADIFALCO",
    "state_code": "CL",
    "cab": "834408",
    "cap": "93010"
  },
  {
    "key": "14239",
    "ITA": "SERRALUNGA D'ALBA",
    "state_code": "CN",
    "cab": "919423",
    "cap": "12050"
  },
  {
    "key": "14240",
    "ITA": "SERRALUNGA DI CREA",
    "state_code": "AL",
    "cab": "486100",
    "cap": "15020"
  },
  {
    "key": "14241",
    "ITA": "SERRAMANNA",
    "state_code": "SU",
    "cab": "440107",
    "cap": "9038"
  },
  {
    "key": "14242",
    "ITA": "SERRAMAZZONI-VARANA",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14243",
    "ITA": "SERRAMAZZONI-FAETO",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14244",
    "ITA": "SERRAMAZZONI-LIGORZANO",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14245",
    "ITA": "SERRAMAZZONI-MONFESTINO",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14246",
    "ITA": "SERRAMAZZONI-MONTAGNANA",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14247",
    "ITA": "SERRAMAZZONI-PAZZANO",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14248",
    "ITA": "SERRAMAZZONI-VALLE",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14249",
    "ITA": "SERRAMAZZONI",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14250",
    "ITA": "SERRAMAZZONI-SELVA",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14251",
    "ITA": "SERRAMAZZONI-SAN DALMAZIO",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14252",
    "ITA": "SERRAMAZZONI-ROCCA SANTA MARIA",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14253",
    "ITA": "SERRAMAZZONI-RICCO'",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14254",
    "ITA": "SERRAMAZZONI-POMPEANO",
    "state_code": "MO",
    "cab": "670307",
    "cap": "41028"
  },
  {
    "key": "14255",
    "ITA": "SERRAMEZZANA",
    "state_code": "SA",
    "cab": "953091",
    "cap": "84070"
  },
  {
    "key": "14256",
    "ITA": "SERRAMONACESCA",
    "state_code": "PE",
    "cab": "954149",
    "cap": "65025"
  },
  {
    "key": "14257",
    "ITA": "SERRAPETRONA",
    "state_code": "MC",
    "cab": "691808",
    "cap": "62020"
  },
  {
    "key": "14258",
    "ITA": "SERRARA FONTANA-SANT'ANGELO",
    "state_code": "NA",
    "cab": "402404",
    "cap": "80070"
  },
  {
    "key": "14259",
    "ITA": "SERRARA FONTANA-FONTANA",
    "state_code": "NA",
    "cab": "402404",
    "cap": "80070"
  },
  {
    "key": "14260",
    "ITA": "SERRARA FONTANA-SERRARA",
    "state_code": "NA",
    "cab": "402404",
    "cap": "80070"
  },
  {
    "key": "14261",
    "ITA": "SERRARA FONTANA-SUCCHIVO",
    "state_code": "NA",
    "cab": "402404",
    "cap": "80070"
  },
  {
    "key": "14262",
    "ITA": "SERRARA FONTANA",
    "state_code": "NA",
    "cab": "402404",
    "cap": "80070"
  },
  {
    "key": "14263",
    "ITA": "SERRASTRETTA-ANGOLI",
    "state_code": "CZ",
    "cab": "427500",
    "cap": "88040"
  },
  {
    "key": "14264",
    "ITA": "SERRASTRETTA-ACCARIA ROSARIA",
    "state_code": "CZ",
    "cab": "427500",
    "cap": "88040"
  },
  {
    "key": "14265",
    "ITA": "SERRASTRETTA",
    "state_code": "CZ",
    "cab": "427500",
    "cap": "88040"
  },
  {
    "key": "14266",
    "ITA": "SERRASTRETTA-CANCELLO",
    "state_code": "CZ",
    "cab": "427500",
    "cap": "88040"
  },
  {
    "key": "14267",
    "ITA": "SERRASTRETTA-SAN MICHELE",
    "state_code": "CZ",
    "cab": "427500",
    "cap": "88040"
  },
  {
    "key": "14268",
    "ITA": "SERRASTRETTA-MIGLIUSO",
    "state_code": "CZ",
    "cab": "427500",
    "cap": "88040"
  },
  {
    "key": "14269",
    "ITA": "SERRATA",
    "state_code": "RC",
    "cab": "960435",
    "cap": "89020"
  },
  {
    "key": "14270",
    "ITA": "SERRAVALLE A PO-LIBIOLA",
    "state_code": "MN",
    "cab": "579805",
    "cap": "46030"
  },
  {
    "key": "14271",
    "ITA": "SERRAVALLE A PO",
    "state_code": "MN",
    "cab": "579805",
    "cap": "46030"
  },
  {
    "key": "14272",
    "ITA": "SERRAVALLE DI CHIENTI",
    "state_code": "MC",
    "cab": "691907",
    "cap": "62038"
  },
  {
    "key": "14273",
    "ITA": "SERRAVALLE DI CHIENTI-CESI",
    "state_code": "MC",
    "cab": "691907",
    "cap": "62038"
  },
  {
    "key": "14274",
    "ITA": "SERRAVALLE DI REP. DI SAN MARINO",
    "state_code": "EE",
    "cab": "98004",
    "cap": "47899"
  },
  {
    "key": "14275",
    "ITA": "SERRAVALLE LANGHE",
    "state_code": "CN",
    "cab": "919431",
    "cap": "12050"
  },
  {
    "key": "14276",
    "ITA": "SERRAVALLE PISTOIESE-CASALGUIDI",
    "state_code": "PT",
    "cab": "705301",
    "cap": "51034"
  },
  {
    "key": "14277",
    "ITA": "SERRAVALLE PISTOIESE",
    "state_code": "PT",
    "cab": "705301",
    "cap": "51030"
  },
  {
    "key": "14278",
    "ITA": "SERRAVALLE PISTOIESE-PONTE DI SERRAVALLE",
    "state_code": "PT",
    "cab": "705301",
    "cap": "51030"
  },
  {
    "key": "14279",
    "ITA": "SERRAVALLE PISTOIESE-STAZIONE MASOTTI",
    "state_code": "PT",
    "cab": "705301",
    "cap": "51030"
  },
  {
    "key": "14280",
    "ITA": "SERRAVALLE SCRIVIA",
    "state_code": "AL",
    "cab": "486209",
    "cap": "15069"
  },
  {
    "key": "14281",
    "ITA": "SERRAVALLE SESIA",
    "state_code": "VC",
    "cab": "448001",
    "cap": "13037"
  },
  {
    "key": "14282",
    "ITA": "SERRAVALLE SESIA-BORNATE SESIA",
    "state_code": "VC",
    "cab": "448001",
    "cap": "13037"
  },
  {
    "key": "14283",
    "ITA": "SERRAVALLE SESIA-VINTEBBIO",
    "state_code": "VC",
    "cab": "448001",
    "cap": "13037"
  },
  {
    "key": "14284",
    "ITA": "SERRE-PERSANO",
    "state_code": "SA",
    "cab": "765602",
    "cap": "84023"
  },
  {
    "key": "14285",
    "ITA": "SERRE",
    "state_code": "SA",
    "cab": "765602",
    "cap": "84028"
  },
  {
    "key": "14286",
    "ITA": "SERRE-BORGO SAN LAZZARO",
    "state_code": "SA",
    "cab": "765602",
    "cap": "84028"
  },
  {
    "key": "14287",
    "ITA": "SERRENTI",
    "state_code": "SU",
    "cab": "441105",
    "cap": "9027"
  },
  {
    "key": "14288",
    "ITA": "SERRI",
    "state_code": "CA",
    "cab": "869602",
    "cap": "8030"
  },
  {
    "key": "14289",
    "ITA": "SERRONE-LA FORMA",
    "state_code": "FR",
    "cab": "884601",
    "cap": "3010"
  },
  {
    "key": "14290",
    "ITA": "SERRONE",
    "state_code": "FR",
    "cab": "884601",
    "cap": "3010"
  },
  {
    "key": "14291",
    "ITA": "SERRUNGARINA",
    "state_code": "PU",
    "cab": "686600",
    "cap": "61030"
  },
  {
    "key": "14292",
    "ITA": "SERRUNGARINA-TAVERNELLE",
    "state_code": "PU",
    "cab": "686600",
    "cap": "61030"
  },
  {
    "key": "14293",
    "ITA": "SERSALE",
    "state_code": "CZ",
    "cab": "428706",
    "cap": "88054"
  },
  {
    "key": "14294",
    "ITA": "SERVIGLIANO-CURETTA",
    "state_code": "FM",
    "cab": "697300",
    "cap": "63029"
  },
  {
    "key": "14295",
    "ITA": "SERVIGLIANO",
    "state_code": "FM",
    "cab": "697300",
    "cap": "63029"
  },
  {
    "key": "14296",
    "ITA": "SESSA AURUNCA-FONTANARADINA",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14297",
    "ITA": "SESSA AURUNCA-FASANI",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14298",
    "ITA": "SESSA AURUNCA-LAURO DI SESSA AURUNCA",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14299",
    "ITA": "SESSA AURUNCA-PIEDIMONTE",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14300",
    "ITA": "SESSA AURUNCA-SAN CARLO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14301",
    "ITA": "SESSA AURUNCA-VALOGNO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14302",
    "ITA": "SESSA AURUNCA-SANT'AGATA",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14303",
    "ITA": "SESSA AURUNCA-SAN CASTRESE",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14304",
    "ITA": "SESSA AURUNCA-SAN MARTINO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14305",
    "ITA": "SESSA AURUNCA-SANTA MARIA VALONGO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14306",
    "ITA": "SESSA AURUNCA",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14307",
    "ITA": "SESSA AURUNCA-CUPA",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14308",
    "ITA": "SESSA AURUNCA-CORIGLIANO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14309",
    "ITA": "SESSA AURUNCA-CASCANO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14310",
    "ITA": "SESSA AURUNCA-CARANO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14311",
    "ITA": "SESSA AURUNCA-AVEZZANO SORBELLO",
    "state_code": "CE",
    "cab": "750505",
    "cap": "81037"
  },
  {
    "key": "14312",
    "ITA": "SESSA CILENTO",
    "state_code": "SA",
    "cab": "376707",
    "cap": "84074"
  },
  {
    "key": "14313",
    "ITA": "SESSA CILENTO-SANTA LUCIA CILENTO",
    "state_code": "SA",
    "cab": "376707",
    "cap": "84074"
  },
  {
    "key": "14314",
    "ITA": "SESSA CILENTO-SAN MANGO",
    "state_code": "SA",
    "cab": "376707",
    "cap": "84074"
  },
  {
    "key": "14315",
    "ITA": "SESSAME",
    "state_code": "AT",
    "cab": "920447",
    "cap": "14058"
  },
  {
    "key": "14316",
    "ITA": "SESSANO DEL MOLISE",
    "state_code": "IS",
    "cab": "955914",
    "cap": "86097"
  },
  {
    "key": "14317",
    "ITA": "SESTA GODANO",
    "state_code": "SP",
    "cab": "498501",
    "cap": "19020"
  },
  {
    "key": "14318",
    "ITA": "SESTINO-MONTERONE",
    "state_code": "AR",
    "cab": "716209",
    "cap": "52038"
  },
  {
    "key": "14319",
    "ITA": "SESTINO-COLCELLALTO",
    "state_code": "AR",
    "cab": "716209",
    "cap": "52038"
  },
  {
    "key": "14320",
    "ITA": "SESTINO",
    "state_code": "AR",
    "cab": "716209",
    "cap": "52038"
  },
  {
    "key": "14321",
    "ITA": "SESTO-SAN GIUSEPPE",
    "state_code": "BZ",
    "cab": "589101",
    "cap": "39030"
  },
  {
    "key": "14322",
    "ITA": "SESTO",
    "state_code": "BZ",
    "cab": "589101",
    "cap": "39030"
  },
  {
    "key": "14323",
    "ITA": "SESTO AL REGHENA-CASETTE",
    "state_code": "PN",
    "cab": "650200",
    "cap": "33079"
  },
  {
    "key": "14324",
    "ITA": "SESTO AL REGHENA-RAMUSCELLO",
    "state_code": "PN",
    "cab": "650200",
    "cap": "33079"
  },
  {
    "key": "14325",
    "ITA": "SESTO AL REGHENA-BAGNAROLA",
    "state_code": "PN",
    "cab": "650200",
    "cap": "33079"
  },
  {
    "key": "14326",
    "ITA": "SESTO AL REGHENA",
    "state_code": "PN",
    "cab": "650200",
    "cap": "33079"
  },
  {
    "key": "14327",
    "ITA": "SESTO CALENDE-LISANZA",
    "state_code": "VA",
    "cab": "505305",
    "cap": "21018"
  },
  {
    "key": "14328",
    "ITA": "SESTO CALENDE",
    "state_code": "VA",
    "cab": "505305",
    "cap": "21018"
  },
  {
    "key": "14329",
    "ITA": "SESTO CAMPANO",
    "state_code": "IS",
    "cab": "781807",
    "cap": "86078"
  },
  {
    "key": "14330",
    "ITA": "SESTO CAMPANO-ROCCAPIPIROZZI",
    "state_code": "IS",
    "cab": "781807",
    "cap": "86078"
  },
  {
    "key": "14331",
    "ITA": "SESTO CAMPANO-CAMPOPINO",
    "state_code": "IS",
    "cab": "781807",
    "cap": "86078"
  },
  {
    "key": "14332",
    "ITA": "SESTO CAMPANO-SELVOTTA",
    "state_code": "IS",
    "cab": "781807",
    "cap": "86078"
  },
  {
    "key": "14333",
    "ITA": "SESTO CAMPANO-PIANURA",
    "state_code": "IS",
    "cab": "781807",
    "cap": "86078"
  },
  {
    "key": "14334",
    "ITA": "SESTO ED UNITI-SESTO CREMONESE",
    "state_code": "CR",
    "cab": "571604",
    "cap": "26028"
  },
  {
    "key": "14335",
    "ITA": "SESTO ED UNITI",
    "state_code": "CR",
    "cab": "571604",
    "cap": "26028"
  },
  {
    "key": "14336",
    "ITA": "SESTO ED UNITI-CASANOVA DEL MORBASCO",
    "state_code": "CR",
    "cab": "571604",
    "cap": "26028"
  },
  {
    "key": "14337",
    "ITA": "SESTO FIORENTINO-QUERCETO",
    "state_code": "FI",
    "cab": "381004",
    "cap": "50019"
  },
  {
    "key": "14338",
    "ITA": "SESTO FIORENTINO-QUINTO",
    "state_code": "FI",
    "cab": "381004",
    "cap": "50019"
  },
  {
    "key": "14339",
    "ITA": "SESTO FIORENTINO-COLONNATA",
    "state_code": "FI",
    "cab": "381004",
    "cap": "50019"
  },
  {
    "key": "14340",
    "ITA": "SESTO FIORENTINO",
    "state_code": "FI",
    "cab": "381004",
    "cap": "50019"
  },
  {
    "key": "14341",
    "ITA": "SESTO FIORENTINO-PONTE A GIOGOLI",
    "state_code": "FI",
    "cab": "381004",
    "cap": "50019"
  },
  {
    "key": "14342",
    "ITA": "SESTO FIORENTINO-OSMANNORO",
    "state_code": "FI",
    "cab": "381004",
    "cap": "50019"
  },
  {
    "key": "14343",
    "ITA": "SESTO SAN GIOVANNI",
    "state_code": "MI",
    "cab": "207001",
    "cap": "20099"
  },
  {
    "key": "14344",
    "ITA": "SESTOLA-CASTELLARO",
    "state_code": "MO",
    "cab": "670406",
    "cap": "41029"
  },
  {
    "key": "14345",
    "ITA": "SESTOLA-ROCCHETTA SANDRI",
    "state_code": "MO",
    "cab": "670406",
    "cap": "41029"
  },
  {
    "key": "14346",
    "ITA": "SESTOLA-RONCOSCAGLIA",
    "state_code": "MO",
    "cab": "670406",
    "cap": "41029"
  },
  {
    "key": "14347",
    "ITA": "SESTOLA-VESALE",
    "state_code": "MO",
    "cab": "670406",
    "cap": "41029"
  },
  {
    "key": "14348",
    "ITA": "SESTOLA-CASINE",
    "state_code": "MO",
    "cab": "670406",
    "cap": "41029"
  },
  {
    "key": "14349",
    "ITA": "SESTOLA",
    "state_code": "MO",
    "cab": "670406",
    "cap": "41029"
  },
  {
    "key": "14350",
    "ITA": "SESTRI LEVANTE",
    "state_code": "GE",
    "cab": "322305",
    "cap": "16039"
  },
  {
    "key": "14351",
    "ITA": "SESTRI LEVANTE-PILA SUL GROMOLO",
    "state_code": "GE",
    "cab": "322305",
    "cap": "16039"
  },
  {
    "key": "14352",
    "ITA": "SESTRI LEVANTE-RIVA TRIGOSO",
    "state_code": "GE",
    "cab": "322305",
    "cap": "16039"
  },
  {
    "key": "14353",
    "ITA": "SESTRI LEVANTE-SANTA VITTORIA DI LIBIOLA",
    "state_code": "GE",
    "cab": "322305",
    "cap": "16039"
  },
  {
    "key": "14354",
    "ITA": "SESTRIERE",
    "state_code": "TO",
    "cab": "310201",
    "cap": "10058"
  },
  {
    "key": "14355",
    "ITA": "SESTRIERE-COLLE SESTRIERE",
    "state_code": "TO",
    "cab": "310201",
    "cap": "10058"
  },
  {
    "key": "14356",
    "ITA": "SESTU",
    "state_code": "CA",
    "cab": "440206",
    "cap": "9028"
  },
  {
    "key": "14357",
    "ITA": "SETTALA-CALEPPIO",
    "state_code": "MI",
    "cab": "338509",
    "cap": "20090"
  },
  {
    "key": "14358",
    "ITA": "SETTALA",
    "state_code": "MI",
    "cab": "338509",
    "cap": "20090"
  },
  {
    "key": "14359",
    "ITA": "SETTALA-PREMENUGO",
    "state_code": "MI",
    "cab": "338509",
    "cap": "20090"
  },
  {
    "key": "14360",
    "ITA": "SETTEFRATI",
    "state_code": "FR",
    "cab": "948570",
    "cap": "3040"
  },
  {
    "key": "14361",
    "ITA": "SETTEFRATI-PIETRAFITTA",
    "state_code": "FR",
    "cab": "948570",
    "cap": "3040"
  },
  {
    "key": "14362",
    "ITA": "SETTIME",
    "state_code": "AT",
    "cab": "477109",
    "cap": "14020"
  },
  {
    "key": "14363",
    "ITA": "SETTIMO MILANESE-VIGHIGNOLO",
    "state_code": "MI",
    "cab": "338608",
    "cap": "20019"
  },
  {
    "key": "14364",
    "ITA": "SETTIMO MILANESE",
    "state_code": "MI",
    "cab": "338608",
    "cap": "20019"
  },
  {
    "key": "14365",
    "ITA": "SETTIMO ROTTARO",
    "state_code": "TO",
    "cab": "901595",
    "cap": "10010"
  },
  {
    "key": "14366",
    "ITA": "SETTIMO SAN PIETRO",
    "state_code": "CA",
    "cab": "862409",
    "cap": "9040"
  },
  {
    "key": "14367",
    "ITA": "SETTIMO TORINESE-OLIMPIA",
    "state_code": "TO",
    "cab": "310300",
    "cap": "10036"
  },
  {
    "key": "14368",
    "ITA": "SETTIMO TORINESE",
    "state_code": "TO",
    "cab": "310300",
    "cap": "10036"
  },
  {
    "key": "14369",
    "ITA": "SETTIMO VITTONE",
    "state_code": "TO",
    "cab": "310409",
    "cap": "10010"
  },
  {
    "key": "14370",
    "ITA": "SETTINGIANO",
    "state_code": "CZ",
    "cab": "700104",
    "cap": "88040"
  },
  {
    "key": "14371",
    "ITA": "SETTINGIANO-MARTELLETTO",
    "state_code": "CZ",
    "cab": "700104",
    "cap": "88040"
  },
  {
    "key": "14372",
    "ITA": "SETZU",
    "state_code": "SU",
    "cab": "915041",
    "cap": "9029"
  },
  {
    "key": "14373",
    "ITA": "SEUI",
    "state_code": "OG",
    "cab": "853705",
    "cap": "8037"
  },
  {
    "key": "14374",
    "ITA": "SEULO",
    "state_code": "CA",
    "cab": "869701",
    "cap": "8030"
  },
  {
    "key": "14375",
    "ITA": "SEVESO",
    "state_code": "MB",
    "cab": "338707",
    "cap": "20030"
  },
  {
    "key": "14376",
    "ITA": "SEVESO-BARUCCANA",
    "state_code": "MB",
    "cab": "338707",
    "cap": "20030"
  },
  {
    "key": "14377",
    "ITA": "SEZZADIO",
    "state_code": "AL",
    "cab": "486308",
    "cap": "15079"
  },
  {
    "key": "14378",
    "ITA": "SEZZE-COLLI DI SUSO",
    "state_code": "LT",
    "cab": "741306",
    "cap": "4018"
  },
  {
    "key": "14379",
    "ITA": "SEZZE",
    "state_code": "LT",
    "cab": "741306",
    "cap": "4018"
  },
  {
    "key": "14380",
    "ITA": "SEZZE-SEZZE SCALO",
    "state_code": "LT",
    "cab": "741306",
    "cap": "4010"
  },
  {
    "key": "14381",
    "ITA": "SFRUZ",
    "state_code": "TN",
    "cab": "354605",
    "cap": "38010"
  },
  {
    "key": "14382",
    "ITA": "SGONICO",
    "state_code": "TS",
    "cab": "286641",
    "cap": "34010"
  },
  {
    "key": "14383",
    "ITA": "SGURGOLA",
    "state_code": "FR",
    "cab": "745901",
    "cap": "3010"
  },
  {
    "key": "14384",
    "ITA": "SIAMAGGIORE",
    "state_code": "OR",
    "cab": "967075",
    "cap": "9070"
  },
  {
    "key": "14385",
    "ITA": "SIAMAGGIORE-PARDU NOU",
    "state_code": "OR",
    "cab": "967075",
    "cap": "9070"
  },
  {
    "key": "14386",
    "ITA": "SIAMANNA",
    "state_code": "OR",
    "cab": "880708",
    "cap": "9080"
  },
  {
    "key": "14387",
    "ITA": "SIANO",
    "state_code": "SA",
    "cab": "766204",
    "cap": "84088"
  },
  {
    "key": "14388",
    "ITA": "SIAPICCIA",
    "state_code": "OR",
    "cab": "967265",
    "cap": "9080"
  },
  {
    "key": "14389",
    "ITA": "SICIGNANO DEGLI ALBURNI",
    "state_code": "SA",
    "cab": "765008",
    "cap": "84029"
  },
  {
    "key": "14390",
    "ITA": "SICIGNANO DEGLI ALBURNI-SICIGNANO DEGLI ALBURNI STAZIONE",
    "state_code": "SA",
    "cab": "765008",
    "cap": "84029"
  },
  {
    "key": "14391",
    "ITA": "SICIGNANO DEGLI ALBURNI-GALDO DEGLI ALBURNI",
    "state_code": "SA",
    "cab": "765008",
    "cap": "84029"
  },
  {
    "key": "14392",
    "ITA": "SICIGNANO DEGLI ALBURNI-CASTELLUCCIO COSENTINO",
    "state_code": "SA",
    "cab": "765008",
    "cap": "84029"
  },
  {
    "key": "14393",
    "ITA": "SICIGNANO DEGLI ALBURNI-ZUPPINO",
    "state_code": "SA",
    "cab": "765008",
    "cap": "84029"
  },
  {
    "key": "14394",
    "ITA": "SICIGNANO DEGLI ALBURNI-SCORZO",
    "state_code": "SA",
    "cab": "765008",
    "cap": "84029"
  },
  {
    "key": "14395",
    "ITA": "SICULIANA",
    "state_code": "AG",
    "cab": "831800",
    "cap": "92010"
  },
  {
    "key": "14396",
    "ITA": "SIDDI",
    "state_code": "SU",
    "cab": "862508",
    "cap": "9020"
  },
  {
    "key": "14397",
    "ITA": "SIDERNO",
    "state_code": "RC",
    "cab": "815902",
    "cap": "89048"
  },
  {
    "key": "14398",
    "ITA": "SIDERNO-SIDERNO MARINA",
    "state_code": "RC",
    "cab": "815902",
    "cap": "89048"
  },
  {
    "key": "14399",
    "ITA": "SIDERNO-SIDERNO SUPERIORE",
    "state_code": "RC",
    "cab": "815902",
    "cap": "89040"
  },
  {
    "key": "14400",
    "ITA": "SIDERNO-DONISI",
    "state_code": "RC",
    "cab": "815902",
    "cap": "89048"
  },
  {
    "key": "14401",
    "ITA": "SIENA-COSTALPINO",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14402",
    "ITA": "SIENA-TAVERNE D'ARBIA",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14403",
    "ITA": "SIENA-SANT'ANDREA A MONTECCHIO",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14404",
    "ITA": "SIENA-PONTE AL BOZZONE",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14405",
    "ITA": "SIENA-MALAFRASCA",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14406",
    "ITA": "SIENA-ISOLA D'ARBIA",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14407",
    "ITA": "SIENA-CORONCINA",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14408",
    "ITA": "SIENA",
    "state_code": "SI",
    "cab": "142000",
    "cap": "53100"
  },
  {
    "key": "14409",
    "ITA": "SIGILLO",
    "state_code": "PG",
    "cab": "386805",
    "cap": "6028"
  },
  {
    "key": "14410",
    "ITA": "SIGNA-SAN MAURO",
    "state_code": "FI",
    "cab": "381103",
    "cap": "50058"
  },
  {
    "key": "14411",
    "ITA": "SIGNA",
    "state_code": "FI",
    "cab": "381103",
    "cap": "50058"
  },
  {
    "key": "14412",
    "ITA": "SILANDRO",
    "state_code": "BZ",
    "cab": "589200",
    "cap": "39028"
  },
  {
    "key": "14413",
    "ITA": "SILANDRO-VEZZANO",
    "state_code": "BZ",
    "cab": "589200",
    "cap": "39028"
  },
  {
    "key": "14414",
    "ITA": "SILANDRO-COVELANO",
    "state_code": "BZ",
    "cab": "589200",
    "cap": "39028"
  },
  {
    "key": "14415",
    "ITA": "SILANUS",
    "state_code": "NU",
    "cab": "869800",
    "cap": "8017"
  },
  {
    "key": "14416",
    "ITA": "SILEA",
    "state_code": "TV",
    "cab": "621003",
    "cap": "31057"
  },
  {
    "key": "14417",
    "ITA": "SILEA-SANT'ELENA",
    "state_code": "TV",
    "cab": "621003",
    "cap": "31057"
  },
  {
    "key": "14418",
    "ITA": "SILEA-CENDON",
    "state_code": "TV",
    "cab": "621003",
    "cap": "31057"
  },
  {
    "key": "14419",
    "ITA": "SILIGO",
    "state_code": "SS",
    "cab": "875906",
    "cap": "7040"
  },
  {
    "key": "14420",
    "ITA": "SILIQUA",
    "state_code": "CA",
    "cab": "862607",
    "cap": "9010"
  },
  {
    "key": "14421",
    "ITA": "SILIUS",
    "state_code": "CA",
    "cab": "862706",
    "cap": "9040"
  },
  {
    "key": "14422",
    "ITA": "SILLANO",
    "state_code": "LU",
    "cab": "692707",
    "cap": "55030"
  },
  {
    "key": "14423",
    "ITA": "SILLAVENGO",
    "state_code": "NO",
    "cab": "917716",
    "cap": "28064"
  },
  {
    "key": "14424",
    "ITA": "SILVANO D'ORBA",
    "state_code": "AL",
    "cab": "488601",
    "cap": "15060"
  },
  {
    "key": "14425",
    "ITA": "SILVANO PIETRA",
    "state_code": "PV",
    "cab": "930891",
    "cap": "27050"
  },
  {
    "key": "14426",
    "ITA": "SILVI-SILVI MARINA",
    "state_code": "TE",
    "cab": "770503",
    "cap": "64028"
  },
  {
    "key": "14427",
    "ITA": "SILVI-SAN SILVESTRO",
    "state_code": "TE",
    "cab": "770503",
    "cap": "64028"
  },
  {
    "key": "14428",
    "ITA": "SILVI-PIANACCE",
    "state_code": "TE",
    "cab": "770503",
    "cap": "64028"
  },
  {
    "key": "14429",
    "ITA": "SILVI",
    "state_code": "TE",
    "cab": "770503",
    "cap": "64028"
  },
  {
    "key": "14430",
    "ITA": "SIMALA",
    "state_code": "OR",
    "cab": "880807",
    "cap": "9090"
  },
  {
    "key": "14431",
    "ITA": "SIMAXIS",
    "state_code": "OR",
    "cab": "880906",
    "cap": "9088"
  },
  {
    "key": "14432",
    "ITA": "SIMBARIO",
    "state_code": "VV",
    "cab": "913558",
    "cap": "89822"
  },
  {
    "key": "14433",
    "ITA": "SIMERI CRICHI",
    "state_code": "CZ",
    "cab": "913566",
    "cap": "88050"
  },
  {
    "key": "14434",
    "ITA": "SIMERI CRICHI-CRICHI",
    "state_code": "CZ",
    "cab": "913566",
    "cap": "88050"
  },
  {
    "key": "14435",
    "ITA": "SIMERI CRICHI-SIMERI",
    "state_code": "CZ",
    "cab": "913566",
    "cap": "88050"
  },
  {
    "key": "14436",
    "ITA": "SINAGRA",
    "state_code": "ME",
    "cab": "825703",
    "cap": "98069"
  },
  {
    "key": "14437",
    "ITA": "SINALUNGA-SCROFIANO",
    "state_code": "SI",
    "cab": "720409",
    "cap": "53048"
  },
  {
    "key": "14438",
    "ITA": "SINALUNGA-RIGOMAGNO",
    "state_code": "SI",
    "cab": "720409",
    "cap": "53048"
  },
  {
    "key": "14439",
    "ITA": "SINALUNGA",
    "state_code": "SI",
    "cab": "720409",
    "cap": "53048"
  },
  {
    "key": "14440",
    "ITA": "SINALUNGA-GUAZZINO",
    "state_code": "SI",
    "cab": "720409",
    "cap": "53048"
  },
  {
    "key": "14441",
    "ITA": "SINALUNGA-RIGAIOLO",
    "state_code": "SI",
    "cab": "720409",
    "cap": "53048"
  },
  {
    "key": "14442",
    "ITA": "SINALUNGA-PIEVE DI SINALUNGA",
    "state_code": "SI",
    "cab": "720409",
    "cap": "53048"
  },
  {
    "key": "14443",
    "ITA": "SINALUNGA-BETTOLLE",
    "state_code": "SI",
    "cab": "720409",
    "cap": "53048"
  },
  {
    "key": "14444",
    "ITA": "SINDIA",
    "state_code": "NU",
    "cab": "869909",
    "cap": "8018"
  },
  {
    "key": "14445",
    "ITA": "SINI",
    "state_code": "OR",
    "cab": "881003",
    "cap": "9090"
  },
  {
    "key": "14446",
    "ITA": "SINIO",
    "state_code": "CN",
    "cab": "919449",
    "cap": "12050"
  },
  {
    "key": "14447",
    "ITA": "SINISCOLA-ENA SA CHITTA",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14448",
    "ITA": "SINISCOLA-LA CALETTA",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14449",
    "ITA": "SINISCOLA-MANDRAS",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14450",
    "ITA": "SINISCOLA-OVEDI'",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14451",
    "ITA": "SINISCOLA-SA PERTA RUIA",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14452",
    "ITA": "SINISCOLA-SA PISCHERA",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14453",
    "ITA": "SINISCOLA-SANTA LUCIA",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14454",
    "ITA": "SINISCOLA-SARENARGIU",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14455",
    "ITA": "SINISCOLA-SU TILIO'",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14456",
    "ITA": "SINISCOLA",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8029"
  },
  {
    "key": "14457",
    "ITA": "SINISCOLA-BERCHIDDA",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14458",
    "ITA": "SINISCOLA-CAPO COMINO",
    "state_code": "NU",
    "cab": "853804",
    "cap": "8020"
  },
  {
    "key": "14459",
    "ITA": "SINNAI",
    "state_code": "CA",
    "cab": "440305",
    "cap": "9048"
  },
  {
    "key": "14460",
    "ITA": "SINOPOLI",
    "state_code": "RC",
    "cab": "816009",
    "cap": "89020"
  },
  {
    "key": "14461",
    "ITA": "SINOPOLI-SINOPOLI INFERIORE",
    "state_code": "RC",
    "cab": "816009",
    "cap": "89020"
  },
  {
    "key": "14462",
    "ITA": "SIRACUSA-CASSIBILE",
    "state_code": "SR",
    "cab": "171009",
    "cap": "96100"
  },
  {
    "key": "14463",
    "ITA": "SIRACUSA-CARROZZIERE",
    "state_code": "SR",
    "cab": "171009",
    "cap": "96100"
  },
  {
    "key": "14464",
    "ITA": "SIRACUSA-BELVEDERE",
    "state_code": "SR",
    "cab": "171009",
    "cap": "96100"
  },
  {
    "key": "14465",
    "ITA": "SIRACUSA",
    "state_code": "SR",
    "cab": "171009",
    "cap": "96100"
  },
  {
    "key": "14466",
    "ITA": "SIRIGNANO",
    "state_code": "AV",
    "cab": "951699",
    "cap": "83020"
  },
  {
    "key": "14467",
    "ITA": "SIRIS",
    "state_code": "OR",
    "cab": "967125",
    "cap": "9090"
  },
  {
    "key": "14468",
    "ITA": "SIRMIONE",
    "state_code": "BS",
    "cab": "552505",
    "cap": "25019"
  },
  {
    "key": "14469",
    "ITA": "SIRMIONE-COLOMBARE DI SIRMIONE",
    "state_code": "BS",
    "cab": "552505",
    "cap": "25019"
  },
  {
    "key": "14470",
    "ITA": "SIROLO",
    "state_code": "AN",
    "cab": "376202",
    "cap": "60020"
  },
  {
    "key": "14471",
    "ITA": "SIRONE",
    "state_code": "LC",
    "cab": "520502",
    "cap": "23844"
  },
  {
    "key": "14472",
    "ITA": "SIROR",
    "state_code": "TN",
    "cab": "354704",
    "cap": "38054"
  },
  {
    "key": "14473",
    "ITA": "SIROR-SAN MARTINO DI CASTROZZA",
    "state_code": "TN",
    "cab": "354704",
    "cap": "38054"
  },
  {
    "key": "14474",
    "ITA": "SIRTORI",
    "state_code": "LC",
    "cab": "674002",
    "cap": "23896"
  },
  {
    "key": "14475",
    "ITA": "SIRTORI-BEVERA DI SIRTORI",
    "state_code": "LC",
    "cab": "674002",
    "cap": "23896"
  },
  {
    "key": "14476",
    "ITA": "SISSA-GRAMIGNAZZO",
    "state_code": "PR",
    "cab": "659201",
    "cap": "43018"
  },
  {
    "key": "14477",
    "ITA": "SISSA",
    "state_code": "PR",
    "cab": "659201",
    "cap": "43018"
  },
  {
    "key": "14478",
    "ITA": "SISSA-COLTARO",
    "state_code": "PR",
    "cab": "659201",
    "cap": "43018"
  },
  {
    "key": "14479",
    "ITA": "SIURGUS DONIGALA",
    "state_code": "CA",
    "cab": "862805",
    "cap": "9040"
  },
  {
    "key": "14480",
    "ITA": "SIZIANO",
    "state_code": "PV",
    "cab": "562900",
    "cap": "27010"
  },
  {
    "key": "14481",
    "ITA": "SIZZANO",
    "state_code": "NO",
    "cab": "456608",
    "cap": "28070"
  },
  {
    "key": "14482",
    "ITA": "SLUDERNO",
    "state_code": "BZ",
    "cab": "589309",
    "cap": "39020"
  },
  {
    "key": "14483",
    "ITA": "SMARANO",
    "state_code": "TN",
    "cab": "895904",
    "cap": "38010"
  },
  {
    "key": "14484",
    "ITA": "SMERILLO",
    "state_code": "FM",
    "cab": "942672",
    "cap": "63020"
  },
  {
    "key": "14485",
    "ITA": "SMERILLO-SAN MARTINO AL FAGGIO",
    "state_code": "FM",
    "cab": "942672",
    "cap": "63020"
  },
  {
    "key": "14486",
    "ITA": "SOAVE",
    "state_code": "VR",
    "cab": "598508",
    "cap": "37038"
  },
  {
    "key": "14487",
    "ITA": "SOAVE-CASTELLETTO",
    "state_code": "VR",
    "cab": "598508",
    "cap": "37038"
  },
  {
    "key": "14488",
    "ITA": "SOCCHIEVE",
    "state_code": "UD",
    "cab": "937235",
    "cap": "33020"
  },
  {
    "key": "14489",
    "ITA": "SOCCHIEVE-MEDIIS",
    "state_code": "UD",
    "cab": "937235",
    "cap": "33020"
  },
  {
    "key": "14490",
    "ITA": "SODDI'",
    "state_code": "OR",
    "cab": "967281",
    "cap": "9080"
  },
  {
    "key": "14491",
    "ITA": "SOGLIANO AL RUBICONE-MONTEPETRA",
    "state_code": "FC",
    "cab": "680603",
    "cap": "47030"
  },
  {
    "key": "14492",
    "ITA": "SOGLIANO AL RUBICONE-RONTAGNANO",
    "state_code": "FC",
    "cab": "680603",
    "cap": "47030"
  },
  {
    "key": "14493",
    "ITA": "SOGLIANO AL RUBICONE-SAVIGNANO DI RIGO",
    "state_code": "FC",
    "cab": "680603",
    "cap": "47030"
  },
  {
    "key": "14494",
    "ITA": "SOGLIANO AL RUBICONE-MONTEGELLI",
    "state_code": "FC",
    "cab": "680603",
    "cap": "47030"
  },
  {
    "key": "14495",
    "ITA": "SOGLIANO AL RUBICONE",
    "state_code": "FC",
    "cab": "680603",
    "cap": "47030"
  },
  {
    "key": "14496",
    "ITA": "SOGLIANO CAVOUR",
    "state_code": "LE",
    "cab": "800102",
    "cap": "73010"
  },
  {
    "key": "14497",
    "ITA": "SOGLIO",
    "state_code": "AT",
    "cab": "920454",
    "cap": "14020"
  },
  {
    "key": "14498",
    "ITA": "SOIANO DEL LAGO",
    "state_code": "BS",
    "cab": "647206",
    "cap": "25080"
  },
  {
    "key": "14499",
    "ITA": "SOIANO DEL LAGO-SOIANO",
    "state_code": "BS",
    "cab": "647206",
    "cap": "25080"
  },
  {
    "key": "14500",
    "ITA": "SOLAGNA",
    "state_code": "VI",
    "cab": "671404",
    "cap": "36020"
  },
  {
    "key": "14501",
    "ITA": "SOLARINO",
    "state_code": "SR",
    "cab": "847806",
    "cap": "96010"
  },
  {
    "key": "14502",
    "ITA": "SOLARO",
    "state_code": "MI",
    "cab": "338806",
    "cap": "20020"
  },
  {
    "key": "14503",
    "ITA": "SOLARO-VILLAGGIO BROLLO",
    "state_code": "MI",
    "cab": "338806",
    "cap": "20020"
  },
  {
    "key": "14504",
    "ITA": "SOLAROLO",
    "state_code": "RA",
    "cab": "676106",
    "cap": "48027"
  },
  {
    "key": "14505",
    "ITA": "SOLAROLO-GAIANO",
    "state_code": "RA",
    "cab": "676106",
    "cap": "48027"
  },
  {
    "key": "14506",
    "ITA": "SOLAROLO RAINERIO",
    "state_code": "CR",
    "cab": "931881",
    "cap": "26030"
  },
  {
    "key": "14507",
    "ITA": "SOLARUSSA",
    "state_code": "OR",
    "cab": "881102",
    "cap": "9077"
  },
  {
    "key": "14508",
    "ITA": "SOLBIATE",
    "state_code": "CO",
    "cab": "926253",
    "cap": "22070"
  },
  {
    "key": "14509",
    "ITA": "SOLBIATE-CONCAGNO",
    "state_code": "CO",
    "cab": "926253",
    "cap": "22070"
  },
  {
    "key": "14510",
    "ITA": "SOLBIATE ARNO",
    "state_code": "VA",
    "cab": "505404",
    "cap": "21048"
  },
  {
    "key": "14511",
    "ITA": "SOLBIATE ARNO-MONTE",
    "state_code": "VA",
    "cab": "505404",
    "cap": "21048"
  },
  {
    "key": "14512",
    "ITA": "SOLBIATE OLONA",
    "state_code": "VA",
    "cab": "505503",
    "cap": "21058"
  },
  {
    "key": "14513",
    "ITA": "SOLDANO",
    "state_code": "IM",
    "cab": "922625",
    "cap": "18036"
  },
  {
    "key": "14514",
    "ITA": "SOLEMINIS",
    "state_code": "CA",
    "cab": "862904",
    "cap": "9040"
  },
  {
    "key": "14515",
    "ITA": "SOLERO",
    "state_code": "AL",
    "cab": "486407",
    "cap": "15029"
  },
  {
    "key": "14516",
    "ITA": "SOLESINO",
    "state_code": "PD",
    "cab": "629006",
    "cap": "35047"
  },
  {
    "key": "14517",
    "ITA": "SOLETO",
    "state_code": "LE",
    "cab": "800201",
    "cap": "73010"
  },
  {
    "key": "14518",
    "ITA": "SOLFERINO",
    "state_code": "MN",
    "cab": "579904",
    "cap": "46040"
  },
  {
    "key": "14519",
    "ITA": "SOLIERA-SOZZIGALLI",
    "state_code": "MO",
    "cab": "670505",
    "cap": "41019"
  },
  {
    "key": "14520",
    "ITA": "SOLIERA-LIMIDI",
    "state_code": "MO",
    "cab": "670505",
    "cap": "41010"
  },
  {
    "key": "14521",
    "ITA": "SOLIERA",
    "state_code": "MO",
    "cab": "670505",
    "cap": "41019"
  },
  {
    "key": "14522",
    "ITA": "SOLIERA-APPALTO",
    "state_code": "MO",
    "cab": "670505",
    "cap": "41019"
  },
  {
    "key": "14523",
    "ITA": "SOLIGNANO-PRELERNA",
    "state_code": "PR",
    "cab": "659300",
    "cap": "43040"
  },
  {
    "key": "14524",
    "ITA": "SOLIGNANO-CARPADASCO",
    "state_code": "PR",
    "cab": "659300",
    "cap": "43049"
  },
  {
    "key": "14525",
    "ITA": "SOLIGNANO",
    "state_code": "PR",
    "cab": "659300",
    "cap": "43040"
  },
  {
    "key": "14526",
    "ITA": "SOLIGNANO-SPECCHIO",
    "state_code": "PR",
    "cab": "659300",
    "cap": "43040"
  },
  {
    "key": "14527",
    "ITA": "SOLOFRA-SANT'ANDREA APOSTOLO",
    "state_code": "AV",
    "cab": "758201",
    "cap": "83029"
  },
  {
    "key": "14528",
    "ITA": "SOLOFRA",
    "state_code": "AV",
    "cab": "758201",
    "cap": "83029"
  },
  {
    "key": "14529",
    "ITA": "SOLOFRA-SANT'AGATA IRPINA",
    "state_code": "AV",
    "cab": "758201",
    "cap": "83029"
  },
  {
    "key": "14530",
    "ITA": "SOLONGHELLO",
    "state_code": "AL",
    "cab": "921700",
    "cap": "15020"
  },
  {
    "key": "14531",
    "ITA": "SOLOPACA",
    "state_code": "BN",
    "cab": "755009",
    "cap": "82036"
  },
  {
    "key": "14532",
    "ITA": "SOLTO COLLINA",
    "state_code": "BG",
    "cab": "535302",
    "cap": "24060"
  },
  {
    "key": "14533",
    "ITA": "SOLZA",
    "state_code": "BG",
    "cab": "817502",
    "cap": "24030"
  },
  {
    "key": "14534",
    "ITA": "SOMAGLIA",
    "state_code": "LO",
    "cab": "338905",
    "cap": "26867"
  },
  {
    "key": "14535",
    "ITA": "SOMAGLIA-SAN MARTINO PIZZOLANO",
    "state_code": "LO",
    "cab": "338905",
    "cap": "26867"
  },
  {
    "key": "14536",
    "ITA": "SOMANO",
    "state_code": "CN",
    "cab": "919456",
    "cap": "12060"
  },
  {
    "key": "14537",
    "ITA": "SOMMA LOMBARDO-COAREZZA",
    "state_code": "VA",
    "cab": "505602",
    "cap": "21019"
  },
  {
    "key": "14538",
    "ITA": "SOMMA LOMBARDO-MALPENSA AEROPORTO",
    "state_code": "VA",
    "cab": "505602",
    "cap": "21019"
  },
  {
    "key": "14539",
    "ITA": "SOMMA LOMBARDO-MADDALENA",
    "state_code": "VA",
    "cab": "505602",
    "cap": "21019"
  },
  {
    "key": "14540",
    "ITA": "SOMMA LOMBARDO-CASE NUOVE",
    "state_code": "VA",
    "cab": "505602",
    "cap": "21019"
  },
  {
    "key": "14541",
    "ITA": "SOMMA LOMBARDO",
    "state_code": "VA",
    "cab": "505602",
    "cap": "21019"
  },
  {
    "key": "14542",
    "ITA": "SOMMA VESUVIANA",
    "state_code": "NA",
    "cab": "402503",
    "cap": "80049"
  },
  {
    "key": "14543",
    "ITA": "SOMMACAMPAGNA-CUSTOZA",
    "state_code": "VR",
    "cab": "598607",
    "cap": "37066"
  },
  {
    "key": "14544",
    "ITA": "SOMMACAMPAGNA-CASELLE DI SOMMACAMPAGNA",
    "state_code": "VR",
    "cab": "598607",
    "cap": "37066"
  },
  {
    "key": "14545",
    "ITA": "SOMMACAMPAGNA",
    "state_code": "VR",
    "cab": "598607",
    "cap": "37066"
  },
  {
    "key": "14546",
    "ITA": "SOMMARIVA DEL BOSCO",
    "state_code": "CN",
    "cab": "468702",
    "cap": "12048"
  },
  {
    "key": "14547",
    "ITA": "SOMMARIVA PERNO",
    "state_code": "CN",
    "cab": "468801",
    "cap": "12040"
  },
  {
    "key": "14548",
    "ITA": "SOMMARIVA PERNO-SAN GIUSEPPE",
    "state_code": "CN",
    "cab": "468801",
    "cap": "12040"
  },
  {
    "key": "14549",
    "ITA": "SOMMATINO-TRABIA MINIERE",
    "state_code": "CL",
    "cab": "834507",
    "cap": "93019"
  },
  {
    "key": "14550",
    "ITA": "SOMMATINO",
    "state_code": "CL",
    "cab": "834507",
    "cap": "93019"
  },
  {
    "key": "14551",
    "ITA": "SOMMO",
    "state_code": "PV",
    "cab": "277400",
    "cap": "27048"
  },
  {
    "key": "14552",
    "ITA": "SONA-SAN GIORGIO IN SALICI",
    "state_code": "VR",
    "cab": "598706",
    "cap": "37060"
  },
  {
    "key": "14553",
    "ITA": "SONA-PALAZZOLO",
    "state_code": "VR",
    "cab": "598706",
    "cap": "37060"
  },
  {
    "key": "14554",
    "ITA": "SONA",
    "state_code": "VR",
    "cab": "598706",
    "cap": "37060"
  },
  {
    "key": "14555",
    "ITA": "SONA-LUGAGNANO",
    "state_code": "VR",
    "cab": "598706",
    "cap": "37060"
  },
  {
    "key": "14556",
    "ITA": "SONCINO",
    "state_code": "CR",
    "cab": "571703",
    "cap": "26029"
  },
  {
    "key": "14557",
    "ITA": "SONCINO-GALLIGNANO",
    "state_code": "CR",
    "cab": "571703",
    "cap": "26029"
  },
  {
    "key": "14558",
    "ITA": "SONDALO-VILLAGGIO SONDALO",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14559",
    "ITA": "SONDALO-PINETA DI SORTENNA",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14560",
    "ITA": "SONDALO-MONDADIZZA",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14561",
    "ITA": "SONDALO-LE PRESE",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14562",
    "ITA": "SONDALO-FRONTALE",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14563",
    "ITA": "SONDALO-ABETINA VALLESANA",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14564",
    "ITA": "SONDALO",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14565",
    "ITA": "SONDALO-PENDOSSO",
    "state_code": "SO",
    "cab": "522607",
    "cap": "23035"
  },
  {
    "key": "14566",
    "ITA": "SONDRIO",
    "state_code": "SO",
    "cab": "110007",
    "cap": "23100"
  },
  {
    "key": "14567",
    "ITA": "SONDRIO-TRIANGIA",
    "state_code": "SO",
    "cab": "110007",
    "cap": "23100"
  },
  {
    "key": "14568",
    "ITA": "SONDRIO-SANT'ANNA",
    "state_code": "SO",
    "cab": "110007",
    "cap": "23100"
  },
  {
    "key": "14569",
    "ITA": "SONDRIO-PONCHIERA",
    "state_code": "SO",
    "cab": "110007",
    "cap": "23100"
  },
  {
    "key": "14570",
    "ITA": "SONDRIO-MOSSINI",
    "state_code": "SO",
    "cab": "110007",
    "cap": "23100"
  },
  {
    "key": "14571",
    "ITA": "SONGAVAZZO",
    "state_code": "BG",
    "cab": "788000",
    "cap": "24020"
  },
  {
    "key": "14572",
    "ITA": "SONICO",
    "state_code": "BS",
    "cab": "478206",
    "cap": "25048"
  },
  {
    "key": "14573",
    "ITA": "SONNINO",
    "state_code": "LT",
    "cab": "741405",
    "cap": "4010"
  },
  {
    "key": "14574",
    "ITA": "SONNINO-SONNINO SCALO",
    "state_code": "LT",
    "cab": "741405",
    "cap": "4010"
  },
  {
    "key": "14575",
    "ITA": "SOPRANA",
    "state_code": "BI",
    "cab": "916387",
    "cap": "13834"
  },
  {
    "key": "14576",
    "ITA": "SORA-SELVA DI SORA",
    "state_code": "FR",
    "cab": "746008",
    "cap": "3039"
  },
  {
    "key": "14577",
    "ITA": "SORA-CARNELLO",
    "state_code": "FR",
    "cab": "746008",
    "cap": "3039"
  },
  {
    "key": "14578",
    "ITA": "SORA",
    "state_code": "FR",
    "cab": "746008",
    "cap": "3039"
  },
  {
    "key": "14579",
    "ITA": "SORAGA",
    "state_code": "TN",
    "cab": "354803",
    "cap": "38030"
  },
  {
    "key": "14580",
    "ITA": "SORAGNA",
    "state_code": "PR",
    "cab": "659409",
    "cap": "43019"
  },
  {
    "key": "14581",
    "ITA": "SORANO-SAN QUIRICO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14582",
    "ITA": "SORANO-SAN VALENTINO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14583",
    "ITA": "SORANO-MONTEVITOZZO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14584",
    "ITA": "SORANO-MONTEBUONO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14585",
    "ITA": "SORANO-ELMO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14586",
    "ITA": "SORANO-SOVANA",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14587",
    "ITA": "SORANO-SAN GIOVANNI DELLE CONTEE",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14588",
    "ITA": "SORANO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14589",
    "ITA": "SORANO-PRATOLUNGO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14590",
    "ITA": "SORANO-MONTORIO",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14591",
    "ITA": "SORANO-CASTELL'OTTIERI",
    "state_code": "GR",
    "cab": "724005",
    "cap": "58010"
  },
  {
    "key": "14592",
    "ITA": "SORBO SAN BASILE",
    "state_code": "CZ",
    "cab": "913574",
    "cap": "88050"
  },
  {
    "key": "14593",
    "ITA": "SORBO SERPICO",
    "state_code": "AV",
    "cab": "951707",
    "cap": "83050"
  },
  {
    "key": "14594",
    "ITA": "SORBOLO",
    "state_code": "PR",
    "cab": "659508",
    "cap": "43058"
  },
  {
    "key": "14595",
    "ITA": "SORBOLO-CHIOZZOLA",
    "state_code": "PR",
    "cab": "659508",
    "cap": "43058"
  },
  {
    "key": "14596",
    "ITA": "SORDEVOLO",
    "state_code": "BI",
    "cab": "495705",
    "cap": "13817"
  },
  {
    "key": "14597",
    "ITA": "SORDIO",
    "state_code": "LO",
    "cab": "703504",
    "cap": "26858"
  },
  {
    "key": "14598",
    "ITA": "SORESINA",
    "state_code": "CR",
    "cab": "571802",
    "cap": "26015"
  },
  {
    "key": "14599",
    "ITA": "SORGA'-BONFERRARO",
    "state_code": "VR",
    "cab": "598805",
    "cap": "37060"
  },
  {
    "key": "14600",
    "ITA": "SORGA'",
    "state_code": "VR",
    "cab": "598805",
    "cap": "37060"
  },
  {
    "key": "14601",
    "ITA": "SORGA'-PONTEPOSSERO",
    "state_code": "VR",
    "cab": "598805",
    "cap": "37060"
  },
  {
    "key": "14602",
    "ITA": "SORGONO",
    "state_code": "NU",
    "cab": "854109",
    "cap": "8038"
  },
  {
    "key": "14603",
    "ITA": "SORI-CAPRENO",
    "state_code": "GE",
    "cab": "322404",
    "cap": "16030"
  },
  {
    "key": "14604",
    "ITA": "SORI-CANEPA",
    "state_code": "GE",
    "cab": "322404",
    "cap": "16030"
  },
  {
    "key": "14605",
    "ITA": "SORI",
    "state_code": "GE",
    "cab": "322404",
    "cap": "16030"
  },
  {
    "key": "14606",
    "ITA": "SORIANELLO",
    "state_code": "VV",
    "cab": "913582",
    "cap": "89831"
  },
  {
    "key": "14607",
    "ITA": "SORIANO CALABRO",
    "state_code": "VV",
    "cab": "427609",
    "cap": "89831"
  },
  {
    "key": "14608",
    "ITA": "SORIANO NEL CIMINO-CHIA",
    "state_code": "VT",
    "cab": "732701",
    "cap": "1038"
  },
  {
    "key": "14609",
    "ITA": "SORIANO NEL CIMINO",
    "state_code": "VT",
    "cab": "732701",
    "cap": "1038"
  },
  {
    "key": "14610",
    "ITA": "SORICO",
    "state_code": "CO",
    "cab": "926261",
    "cap": "22010"
  },
  {
    "key": "14611",
    "ITA": "SORISO",
    "state_code": "NO",
    "cab": "456707",
    "cap": "28010"
  },
  {
    "key": "14612",
    "ITA": "SORISOLE-PETOSINO",
    "state_code": "BG",
    "cab": "535401",
    "cap": "24010"
  },
  {
    "key": "14613",
    "ITA": "SORISOLE",
    "state_code": "BG",
    "cab": "535401",
    "cap": "24010"
  },
  {
    "key": "14614",
    "ITA": "SORMANO",
    "state_code": "CO",
    "cab": "926279",
    "cap": "22030"
  },
  {
    "key": "14615",
    "ITA": "SORRADILE",
    "state_code": "OR",
    "cab": "881201",
    "cap": "9080"
  },
  {
    "key": "14616",
    "ITA": "SORRENTO-PRIORA",
    "state_code": "NA",
    "cab": "402602",
    "cap": "80067"
  },
  {
    "key": "14617",
    "ITA": "SORRENTO-CAPO DI SORRENTO",
    "state_code": "NA",
    "cab": "402602",
    "cap": "80067"
  },
  {
    "key": "14618",
    "ITA": "SORRENTO",
    "state_code": "NA",
    "cab": "402602",
    "cap": "80067"
  },
  {
    "key": "14619",
    "ITA": "SORSO-PLATAMONA",
    "state_code": "SS",
    "cab": "850701",
    "cap": "7037"
  },
  {
    "key": "14620",
    "ITA": "SORSO",
    "state_code": "SS",
    "cab": "850701",
    "cap": "7037"
  },
  {
    "key": "14621",
    "ITA": "SORTINO",
    "state_code": "SR",
    "cab": "847905",
    "cap": "96010"
  },
  {
    "key": "14622",
    "ITA": "SOSPIRO",
    "state_code": "CR",
    "cab": "571901",
    "cap": "26048"
  },
  {
    "key": "14623",
    "ITA": "SOSPIRO-SAN SALVATORE",
    "state_code": "CR",
    "cab": "571901",
    "cap": "26048"
  },
  {
    "key": "14624",
    "ITA": "SOSPIROLO",
    "state_code": "BL",
    "cab": "613802",
    "cap": "32037"
  },
  {
    "key": "14625",
    "ITA": "SOSPIROLO-MIS",
    "state_code": "BL",
    "cab": "613802",
    "cap": "32037"
  },
  {
    "key": "14626",
    "ITA": "SOSSANO",
    "state_code": "VI",
    "cab": "607606",
    "cap": "36040"
  },
  {
    "key": "14627",
    "ITA": "SOSTEGNO",
    "state_code": "BI",
    "cab": "448100",
    "cap": "13868"
  },
  {
    "key": "14628",
    "ITA": "SOTTO IL MONTE GIOVANNI XXIII-PIAZZA CADUTI",
    "state_code": "BG",
    "cab": "614404",
    "cap": "24039"
  },
  {
    "key": "14629",
    "ITA": "SOTTO IL MONTE GIOVANNI XXIII",
    "state_code": "BG",
    "cab": "614404",
    "cap": "24039"
  },
  {
    "key": "14630",
    "ITA": "SOVER",
    "state_code": "TN",
    "cab": "354902",
    "cap": "38048"
  },
  {
    "key": "14631",
    "ITA": "SOVERATO",
    "state_code": "CZ",
    "cab": "427708",
    "cap": "88068"
  },
  {
    "key": "14632",
    "ITA": "SOVERATO-SOVERATO MARINA",
    "state_code": "CZ",
    "cab": "427708",
    "cap": "88068"
  },
  {
    "key": "14633",
    "ITA": "SOVERATO-SOVERATO SUPERIORE",
    "state_code": "CZ",
    "cab": "427708",
    "cap": "88068"
  },
  {
    "key": "14634",
    "ITA": "SOVERE",
    "state_code": "BG",
    "cab": "535500",
    "cap": "24060"
  },
  {
    "key": "14635",
    "ITA": "SOVERIA MANNELLI-SAN TOMMASO",
    "state_code": "CZ",
    "cab": "427807",
    "cap": "88049"
  },
  {
    "key": "14636",
    "ITA": "SOVERIA MANNELLI-COLLA",
    "state_code": "CZ",
    "cab": "427807",
    "cap": "88049"
  },
  {
    "key": "14637",
    "ITA": "SOVERIA MANNELLI",
    "state_code": "CZ",
    "cab": "427807",
    "cap": "88049"
  },
  {
    "key": "14638",
    "ITA": "SOVERIA SIMERI",
    "state_code": "CZ",
    "cab": "913590",
    "cap": "88050"
  },
  {
    "key": "14639",
    "ITA": "SOVERZENE",
    "state_code": "BL",
    "cab": "934810",
    "cap": "32010"
  },
  {
    "key": "14640",
    "ITA": "SOVICILLE",
    "state_code": "SI",
    "cab": "720508",
    "cap": "53018"
  },
  {
    "key": "14641",
    "ITA": "SOVICILLE-ROSIA",
    "state_code": "SI",
    "cab": "720508",
    "cap": "53018"
  },
  {
    "key": "14642",
    "ITA": "SOVICILLE-SAN ROCCO A PILLI",
    "state_code": "SI",
    "cab": "720508",
    "cap": "53018"
  },
  {
    "key": "14643",
    "ITA": "SOVICO",
    "state_code": "MB",
    "cab": "339002",
    "cap": "20050"
  },
  {
    "key": "14644",
    "ITA": "SOVIZZO",
    "state_code": "VI",
    "cab": "607705",
    "cap": "36050"
  },
  {
    "key": "14645",
    "ITA": "SOVRAMONTE",
    "state_code": "BL",
    "cab": "613208",
    "cap": "32030"
  },
  {
    "key": "14646",
    "ITA": "SOVRAMONTE-SORRIVA",
    "state_code": "BL",
    "cab": "613208",
    "cap": "32030"
  },
  {
    "key": "14647",
    "ITA": "SOZZAGO",
    "state_code": "NO",
    "cab": "917724",
    "cap": "28060"
  },
  {
    "key": "14648",
    "ITA": "SPADAFORA-SAN MARTINO",
    "state_code": "ME",
    "cab": "825802",
    "cap": "98048"
  },
  {
    "key": "14649",
    "ITA": "SPADAFORA",
    "state_code": "ME",
    "cab": "825802",
    "cap": "98048"
  },
  {
    "key": "14650",
    "ITA": "SPADOLA",
    "state_code": "VV",
    "cab": "191080",
    "cap": "89822"
  },
  {
    "key": "14651",
    "ITA": "SPARANISE",
    "state_code": "CE",
    "cab": "750604",
    "cap": "81056"
  },
  {
    "key": "14652",
    "ITA": "SPARONE",
    "state_code": "TO",
    "cab": "314708",
    "cap": "10080"
  },
  {
    "key": "14653",
    "ITA": "SPECCHIA",
    "state_code": "LE",
    "cab": "800300",
    "cap": "73040"
  },
  {
    "key": "14654",
    "ITA": "SPELLO",
    "state_code": "PG",
    "cab": "386904",
    "cap": "6038"
  },
  {
    "key": "14655",
    "ITA": "SPELLO-CAPITAN LORETO",
    "state_code": "PG",
    "cab": "386904",
    "cap": "6038"
  },
  {
    "key": "14656",
    "ITA": "SPERA",
    "state_code": "TN",
    "cab": "355008",
    "cap": "38059"
  },
  {
    "key": "14657",
    "ITA": "SPERLINGA",
    "state_code": "EN",
    "cab": "499509",
    "cap": "94010"
  },
  {
    "key": "14658",
    "ITA": "SPERLONGA",
    "state_code": "LT",
    "cab": "741504",
    "cap": "4029"
  },
  {
    "key": "14659",
    "ITA": "SPERONE",
    "state_code": "AV",
    "cab": "396606",
    "cap": "83020"
  },
  {
    "key": "14660",
    "ITA": "SPESSA",
    "state_code": "PV",
    "cab": "930917",
    "cap": "27010"
  },
  {
    "key": "14661",
    "ITA": "SPEZZANO ALBANESE-SPEZZANO ALBANESE TERME",
    "state_code": "CS",
    "cab": "810309",
    "cap": "87019"
  },
  {
    "key": "14662",
    "ITA": "SPEZZANO ALBANESE-SPEZZANO ALBANESE STAZIONE",
    "state_code": "CS",
    "cab": "810309",
    "cap": "87019"
  },
  {
    "key": "14663",
    "ITA": "SPEZZANO ALBANESE",
    "state_code": "CS",
    "cab": "810309",
    "cap": "87019"
  },
  {
    "key": "14664",
    "ITA": "SPEZZANO DELLA SILA-CROCE DI MAGARA",
    "state_code": "CS",
    "cab": "810408",
    "cap": "87052"
  },
  {
    "key": "14665",
    "ITA": "SPEZZANO DELLA SILA-CAMIGLIATELLO SILANO",
    "state_code": "CS",
    "cab": "810408",
    "cap": "87052"
  },
  {
    "key": "14666",
    "ITA": "SPEZZANO DELLA SILA",
    "state_code": "CS",
    "cab": "810408",
    "cap": "87058"
  },
  {
    "key": "14667",
    "ITA": "SPEZZANO DELLA SILA-MOCCONE",
    "state_code": "CS",
    "cab": "810408",
    "cap": "87052"
  },
  {
    "key": "14668",
    "ITA": "SPEZZANO PICCOLO",
    "state_code": "CS",
    "cab": "959452",
    "cap": "87050"
  },
  {
    "key": "14669",
    "ITA": "SPIAZZO",
    "state_code": "TN",
    "cab": "355107",
    "cap": "38088"
  },
  {
    "key": "14670",
    "ITA": "SPIAZZO-SPIAZZO RENDENA",
    "state_code": "TN",
    "cab": "355107",
    "cap": "38088"
  },
  {
    "key": "14671",
    "ITA": "SPIGNO MONFERRATO",
    "state_code": "AL",
    "cab": "486506",
    "cap": "15018"
  },
  {
    "key": "14672",
    "ITA": "SPIGNO SATURNIA",
    "state_code": "LT",
    "cab": "742007",
    "cap": "4020"
  },
  {
    "key": "14673",
    "ITA": "SPIGNO SATURNIA-SPIGNO SATURNIA INFERIORE",
    "state_code": "LT",
    "cab": "742007",
    "cap": "4020"
  },
  {
    "key": "14674",
    "ITA": "SPILAMBERTO",
    "state_code": "MO",
    "cab": "670604",
    "cap": "41057"
  },
  {
    "key": "14675",
    "ITA": "SPILAMBERTO-SAN VITO",
    "state_code": "MO",
    "cab": "670604",
    "cap": "41057"
  },
  {
    "key": "14676",
    "ITA": "SPILIMBERGO-ISTRAGO",
    "state_code": "PN",
    "cab": "650309",
    "cap": "33097"
  },
  {
    "key": "14677",
    "ITA": "SPILIMBERGO-BARBEANO",
    "state_code": "PN",
    "cab": "650309",
    "cap": "33097"
  },
  {
    "key": "14678",
    "ITA": "SPILIMBERGO",
    "state_code": "PN",
    "cab": "650309",
    "cap": "33097"
  },
  {
    "key": "14679",
    "ITA": "SPILIMBERGO-VACILE",
    "state_code": "PN",
    "cab": "650309",
    "cap": "33097"
  },
  {
    "key": "14680",
    "ITA": "SPILIMBERGO-TAURIANO",
    "state_code": "PN",
    "cab": "650309",
    "cap": "33097"
  },
  {
    "key": "14681",
    "ITA": "SPILINGA-PANAIA",
    "state_code": "VV",
    "cab": "364109",
    "cap": "89864"
  },
  {
    "key": "14682",
    "ITA": "SPILINGA",
    "state_code": "VV",
    "cab": "364109",
    "cap": "89864"
  },
  {
    "key": "14683",
    "ITA": "SPINADESCO",
    "state_code": "CR",
    "cab": "572008",
    "cap": "26020"
  },
  {
    "key": "14684",
    "ITA": "SPINAZZOLA",
    "state_code": "BT",
    "cab": "416909",
    "cap": "70058"
  },
  {
    "key": "14685",
    "ITA": "SPINEA-ORGNANO",
    "state_code": "VE",
    "cab": "363309",
    "cap": "30038"
  },
  {
    "key": "14686",
    "ITA": "SPINEA-FORNASE",
    "state_code": "VE",
    "cab": "363309",
    "cap": "30038"
  },
  {
    "key": "14687",
    "ITA": "SPINEA",
    "state_code": "VE",
    "cab": "363309",
    "cap": "30038"
  },
  {
    "key": "14688",
    "ITA": "SPINEDA",
    "state_code": "CR",
    "cab": "572107",
    "cap": "26030"
  },
  {
    "key": "14689",
    "ITA": "SPINETE",
    "state_code": "CB",
    "cab": "910984",
    "cap": "86020"
  },
  {
    "key": "14690",
    "ITA": "SPINETO SCRIVIA",
    "state_code": "AL",
    "cab": "921718",
    "cap": "15050"
  },
  {
    "key": "14691",
    "ITA": "SPINETOLI",
    "state_code": "AP",
    "cab": "697409",
    "cap": "63036"
  },
  {
    "key": "14692",
    "ITA": "SPINETOLI-PAGLIARE",
    "state_code": "AP",
    "cab": "697409",
    "cap": "63036"
  },
  {
    "key": "14693",
    "ITA": "SPINO D'ADDA",
    "state_code": "CR",
    "cab": "572206",
    "cap": "26016"
  },
  {
    "key": "14694",
    "ITA": "SPINONE AL LAGO",
    "state_code": "BG",
    "cab": "928630",
    "cap": "24060"
  },
  {
    "key": "14695",
    "ITA": "SPINOSO",
    "state_code": "PZ",
    "cab": "912204",
    "cap": "85039"
  },
  {
    "key": "14696",
    "ITA": "SPIRANO",
    "state_code": "BG",
    "cab": "535609",
    "cap": "24050"
  },
  {
    "key": "14697",
    "ITA": "SPOLETO-STRETTURA",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14698",
    "ITA": "SPOLETO-SANT'ANGELO IN MERCOLE",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14699",
    "ITA": "SPOLETO-SAN MARTINO IN TRIGNANO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14700",
    "ITA": "SPOLETO-SAN GIOVANNI DI BAIANO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14701",
    "ITA": "SPOLETO-SAN GIACOMO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14702",
    "ITA": "SPOLETO-SAN BRIZIO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14703",
    "ITA": "SPOLETO-MORGNANO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14704",
    "ITA": "SPOLETO-MONTEMARTANO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14705",
    "ITA": "SPOLETO-MAIANO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14706",
    "ITA": "SPOLETO-MADONNA DI BAIANO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14707",
    "ITA": "SPOLETO-EGGI",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14708",
    "ITA": "SPOLETO-CORTACCIONE",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14709",
    "ITA": "SPOLETO-BEROIDE",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14710",
    "ITA": "SPOLETO-BAZZANO INFERIORE",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14711",
    "ITA": "SPOLETO-BAIANO DI SPOLETO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14712",
    "ITA": "SPOLETO-TERZO LA PIEVE",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14713",
    "ITA": "SPOLETO",
    "state_code": "PG",
    "cab": "218008",
    "cap": "6049"
  },
  {
    "key": "14714",
    "ITA": "SPOLTORE",
    "state_code": "PE",
    "cab": "774307",
    "cap": "65010"
  },
  {
    "key": "14715",
    "ITA": "SPOLTORE-VILLA RASPA",
    "state_code": "PE",
    "cab": "774307",
    "cap": "65010"
  },
  {
    "key": "14716",
    "ITA": "SPOLTORE-SANTA TERESA",
    "state_code": "PE",
    "cab": "774307",
    "cap": "65010"
  },
  {
    "key": "14717",
    "ITA": "SPOLTORE-CAPRARA D'ABRUZZO",
    "state_code": "PE",
    "cab": "774307",
    "cap": "65010"
  },
  {
    "key": "14718",
    "ITA": "SPONGANO",
    "state_code": "LE",
    "cab": "800409",
    "cap": "73038"
  },
  {
    "key": "14719",
    "ITA": "SPORMAGGIORE",
    "state_code": "TN",
    "cab": "355206",
    "cap": "38010"
  },
  {
    "key": "14720",
    "ITA": "SPORMINORE",
    "state_code": "TN",
    "cab": "355305",
    "cap": "38010"
  },
  {
    "key": "14721",
    "ITA": "SPOTORNO",
    "state_code": "SV",
    "cab": "495002",
    "cap": "17028"
  },
  {
    "key": "14722",
    "ITA": "SPRESIANO",
    "state_code": "TV",
    "cab": "621102",
    "cap": "31027"
  },
  {
    "key": "14723",
    "ITA": "SPRESIANO-LOVADINA",
    "state_code": "TV",
    "cab": "621102",
    "cap": "31027"
  },
  {
    "key": "14724",
    "ITA": "SPRESIANO-VISNADELLO",
    "state_code": "TV",
    "cab": "621102",
    "cap": "31027"
  },
  {
    "key": "14725",
    "ITA": "SPRIANA",
    "state_code": "SO",
    "cab": "927293",
    "cap": "23020"
  },
  {
    "key": "14726",
    "ITA": "SQUILLACE",
    "state_code": "CZ",
    "cab": "887802",
    "cap": "88069"
  },
  {
    "key": "14727",
    "ITA": "SQUILLACE-SQUILLACE LIDO",
    "state_code": "CZ",
    "cab": "887802",
    "cap": "88069"
  },
  {
    "key": "14728",
    "ITA": "SQUINZANO",
    "state_code": "LE",
    "cab": "800508",
    "cap": "73018"
  },
  {
    "key": "14729",
    "ITA": "STAFFOLO",
    "state_code": "AN",
    "cab": "376301",
    "cap": "60039"
  },
  {
    "key": "14730",
    "ITA": "STAGNO LOMBARDO",
    "state_code": "CR",
    "cab": "572305",
    "cap": "26049"
  },
  {
    "key": "14731",
    "ITA": "STAITI",
    "state_code": "RC",
    "cab": "960443",
    "cap": "89030"
  },
  {
    "key": "14732",
    "ITA": "STALETTI",
    "state_code": "CZ",
    "cab": "913632",
    "cap": "88069"
  },
  {
    "key": "14733",
    "ITA": "STALETTI-COPANELLO",
    "state_code": "CZ",
    "cab": "913632",
    "cap": "88069"
  },
  {
    "key": "14734",
    "ITA": "STANGHELLA",
    "state_code": "PD",
    "cab": "629105",
    "cap": "35048"
  },
  {
    "key": "14735",
    "ITA": "STANGHELLA-PONTE GORZONE",
    "state_code": "PD",
    "cab": "629105",
    "cap": "35048"
  },
  {
    "key": "14736",
    "ITA": "STANGHELLA-PISANA",
    "state_code": "PD",
    "cab": "629105",
    "cap": "35048"
  },
  {
    "key": "14737",
    "ITA": "STARANZANO",
    "state_code": "GO",
    "cab": "646604",
    "cap": "34079"
  },
  {
    "key": "14738",
    "ITA": "STARANZANO-BISTRIGNA",
    "state_code": "GO",
    "cab": "646604",
    "cap": "34079"
  },
  {
    "key": "14739",
    "ITA": "STATTE",
    "state_code": "TA",
    "cab": "651307",
    "cap": "74010"
  },
  {
    "key": "14740",
    "ITA": "STAZZANO",
    "state_code": "AL",
    "cab": "487900",
    "cap": "15060"
  },
  {
    "key": "14741",
    "ITA": "STAZZEMA-RUOSINA",
    "state_code": "LU",
    "cab": "702506",
    "cap": "55040"
  },
  {
    "key": "14742",
    "ITA": "STAZZEMA-PONTESTAZZEMESE",
    "state_code": "LU",
    "cab": "702506",
    "cap": "55040"
  },
  {
    "key": "14743",
    "ITA": "STAZZEMA-PALAGNANA",
    "state_code": "LU",
    "cab": "702506",
    "cap": "55060"
  },
  {
    "key": "14744",
    "ITA": "STAZZEMA",
    "state_code": "LU",
    "cab": "702506",
    "cap": "55040"
  },
  {
    "key": "14745",
    "ITA": "STAZZEMA-TERRINCA",
    "state_code": "LU",
    "cab": "702506",
    "cap": "55040"
  },
  {
    "key": "14746",
    "ITA": "STAZZONA",
    "state_code": "CO",
    "cab": "182337",
    "cap": "22010"
  },
  {
    "key": "14747",
    "ITA": "STEFANACONI",
    "state_code": "VV",
    "cab": "191650",
    "cap": "89843"
  },
  {
    "key": "14748",
    "ITA": "STELLA-SAN MARTINO",
    "state_code": "SV",
    "cab": "923292",
    "cap": "17044"
  },
  {
    "key": "14749",
    "ITA": "STELLA-SANTA GIUSTINA",
    "state_code": "SV",
    "cab": "923292",
    "cap": "17044"
  },
  {
    "key": "14750",
    "ITA": "STELLA-SAN BERNARDO",
    "state_code": "SV",
    "cab": "923292",
    "cap": "17044"
  },
  {
    "key": "14751",
    "ITA": "STELLA",
    "state_code": "SV",
    "cab": "923292",
    "cap": "17044"
  },
  {
    "key": "14752",
    "ITA": "STELLA CILENTO",
    "state_code": "SA",
    "cab": "953133",
    "cap": "84070"
  },
  {
    "key": "14753",
    "ITA": "STELLANELLO",
    "state_code": "SV",
    "cab": "923300",
    "cap": "17020"
  },
  {
    "key": "14754",
    "ITA": "STELVIO-GOMAGOI",
    "state_code": "BZ",
    "cab": "589408",
    "cap": "39029"
  },
  {
    "key": "14755",
    "ITA": "STELVIO-SOLDA",
    "state_code": "BZ",
    "cab": "589408",
    "cap": "39029"
  },
  {
    "key": "14756",
    "ITA": "STELVIO",
    "state_code": "BZ",
    "cab": "589408",
    "cap": "39029"
  },
  {
    "key": "14757",
    "ITA": "STELVIO-TRAFOI",
    "state_code": "BZ",
    "cab": "589408",
    "cap": "39029"
  },
  {
    "key": "14758",
    "ITA": "STENICO-VILLA BANALE",
    "state_code": "TN",
    "cab": "358903",
    "cap": "38070"
  },
  {
    "key": "14759",
    "ITA": "STENICO",
    "state_code": "TN",
    "cab": "358903",
    "cap": "38070"
  },
  {
    "key": "14760",
    "ITA": "STERNATIA",
    "state_code": "LE",
    "cab": "802009",
    "cap": "73010"
  },
  {
    "key": "14761",
    "ITA": "STEZZANO",
    "state_code": "BG",
    "cab": "535708",
    "cap": "24040"
  },
  {
    "key": "14762",
    "ITA": "STIA",
    "state_code": "AR",
    "cab": "716308",
    "cap": "52017"
  },
  {
    "key": "14763",
    "ITA": "STIA-PAPIANO",
    "state_code": "AR",
    "cab": "716308",
    "cap": "52017"
  },
  {
    "key": "14764",
    "ITA": "STIENTA-SABBIONI",
    "state_code": "RO",
    "cab": "634600",
    "cap": "45039"
  },
  {
    "key": "14765",
    "ITA": "STIENTA-ZAMPINE",
    "state_code": "RO",
    "cab": "634600",
    "cap": "45039"
  },
  {
    "key": "14766",
    "ITA": "STIENTA",
    "state_code": "RO",
    "cab": "634600",
    "cap": "45039"
  },
  {
    "key": "14767",
    "ITA": "STIGLIANO",
    "state_code": "MT",
    "cab": "804609",
    "cap": "75018"
  },
  {
    "key": "14768",
    "ITA": "STIGNANO",
    "state_code": "RC",
    "cab": "960450",
    "cap": "89040"
  },
  {
    "key": "14769",
    "ITA": "STILO",
    "state_code": "RC",
    "cab": "816801",
    "cap": "89049"
  },
  {
    "key": "14770",
    "ITA": "STIMIGLIANO",
    "state_code": "RI",
    "cab": "884700",
    "cap": "2048"
  },
  {
    "key": "14771",
    "ITA": "STIMIGLIANO-STIMIGLIANO SCALO",
    "state_code": "RI",
    "cab": "884700",
    "cap": "2048"
  },
  {
    "key": "14772",
    "ITA": "STINTINO",
    "state_code": "SS",
    "cab": "655506",
    "cap": "7040"
  },
  {
    "key": "14773",
    "ITA": "STIO",
    "state_code": "SA",
    "cab": "953141",
    "cap": "84075"
  },
  {
    "key": "14774",
    "ITA": "STIO-GORGA",
    "state_code": "SA",
    "cab": "953141",
    "cap": "84075"
  },
  {
    "key": "14775",
    "ITA": "STORNARA",
    "state_code": "FG",
    "cab": "786608",
    "cap": "71047"
  },
  {
    "key": "14776",
    "ITA": "STORNARELLA",
    "state_code": "FG",
    "cab": "787606",
    "cap": "71048"
  },
  {
    "key": "14777",
    "ITA": "STORO",
    "state_code": "TN",
    "cab": "355404",
    "cap": "38089"
  },
  {
    "key": "14778",
    "ITA": "STORO-DARZO",
    "state_code": "TN",
    "cab": "355404",
    "cap": "38089"
  },
  {
    "key": "14779",
    "ITA": "STORO-LODRONE",
    "state_code": "TN",
    "cab": "355404",
    "cap": "38089"
  },
  {
    "key": "14780",
    "ITA": "STRA",
    "state_code": "VE",
    "cab": "363408",
    "cap": "30039"
  },
  {
    "key": "14781",
    "ITA": "STRA-PALUELLO",
    "state_code": "VE",
    "cab": "363408",
    "cap": "30039"
  },
  {
    "key": "14782",
    "ITA": "STRA-SAN PIETRO DI STRA'",
    "state_code": "VE",
    "cab": "363408",
    "cap": "30039"
  },
  {
    "key": "14783",
    "ITA": "STRADELLA",
    "state_code": "PV",
    "cab": "563007",
    "cap": "27049"
  },
  {
    "key": "14784",
    "ITA": "STRAMBINELLO",
    "state_code": "TO",
    "cab": "901611",
    "cap": "10010"
  },
  {
    "key": "14785",
    "ITA": "STRAMBINO-CERONE",
    "state_code": "TO",
    "cab": "310508",
    "cap": "10019"
  },
  {
    "key": "14786",
    "ITA": "STRAMBINO-CARRONE",
    "state_code": "TO",
    "cab": "310508",
    "cap": "10019"
  },
  {
    "key": "14787",
    "ITA": "STRAMBINO",
    "state_code": "TO",
    "cab": "310508",
    "cap": "10019"
  },
  {
    "key": "14788",
    "ITA": "STRANGOLAGALLI",
    "state_code": "FR",
    "cab": "898908",
    "cap": "3020"
  },
  {
    "key": "14789",
    "ITA": "STREGNA",
    "state_code": "UD",
    "cab": "937243",
    "cap": "33040"
  },
  {
    "key": "14790",
    "ITA": "STREMBO",
    "state_code": "TN",
    "cab": "355503",
    "cap": "38080"
  },
  {
    "key": "14791",
    "ITA": "STRESA-MAGOGNINO",
    "state_code": "VB",
    "cab": "456806",
    "cap": "28838"
  },
  {
    "key": "14792",
    "ITA": "STRESA-ISOLA BELLA",
    "state_code": "VB",
    "cab": "456806",
    "cap": "28838"
  },
  {
    "key": "14793",
    "ITA": "STRESA-CARCIANO",
    "state_code": "VB",
    "cab": "456806",
    "cap": "28838"
  },
  {
    "key": "14794",
    "ITA": "STRESA-BINDA",
    "state_code": "VB",
    "cab": "456806",
    "cap": "28838"
  },
  {
    "key": "14795",
    "ITA": "STRESA",
    "state_code": "VB",
    "cab": "456806",
    "cap": "28838"
  },
  {
    "key": "14796",
    "ITA": "STRESA-LEVO",
    "state_code": "VB",
    "cab": "456806",
    "cap": "28838"
  },
  {
    "key": "14797",
    "ITA": "STREVI",
    "state_code": "AL",
    "cab": "412403",
    "cap": "15019"
  },
  {
    "key": "14798",
    "ITA": "STRIANO",
    "state_code": "NA",
    "cab": "402701",
    "cap": "80040"
  },
  {
    "key": "14799",
    "ITA": "STRIGNO",
    "state_code": "TN",
    "cab": "355602",
    "cap": "38059"
  },
  {
    "key": "14800",
    "ITA": "STRONA-FONTANELLA OZINO",
    "state_code": "BI",
    "cab": "681502",
    "cap": "13823"
  },
  {
    "key": "14801",
    "ITA": "STRONA",
    "state_code": "BI",
    "cab": "681502",
    "cap": "13823"
  },
  {
    "key": "14802",
    "ITA": "STRONCONE",
    "state_code": "TR",
    "cab": "727602",
    "cap": "5039"
  },
  {
    "key": "14803",
    "ITA": "STRONGOLI-MARINA DI STRONGOLI",
    "state_code": "KR",
    "cab": "427906",
    "cap": "88815"
  },
  {
    "key": "14804",
    "ITA": "STRONGOLI",
    "state_code": "KR",
    "cab": "427906",
    "cap": "88816"
  },
  {
    "key": "14805",
    "ITA": "STROPPIANA",
    "state_code": "VC",
    "cab": "448209",
    "cap": "13010"
  },
  {
    "key": "14806",
    "ITA": "STROPPO",
    "state_code": "CN",
    "cab": "919464",
    "cap": "12020"
  },
  {
    "key": "14807",
    "ITA": "STROZZA",
    "state_code": "BG",
    "cab": "928648",
    "cap": "24030"
  },
  {
    "key": "14808",
    "ITA": "STURNO",
    "state_code": "AV",
    "cab": "897603",
    "cap": "83055"
  },
  {
    "key": "14809",
    "ITA": "SUARDI",
    "state_code": "PV",
    "cab": "930925",
    "cap": "27030"
  },
  {
    "key": "14810",
    "ITA": "SUBBIANO-SANTA MAMA",
    "state_code": "AR",
    "cab": "716407",
    "cap": "52010"
  },
  {
    "key": "14811",
    "ITA": "SUBBIANO",
    "state_code": "AR",
    "cab": "716407",
    "cap": "52010"
  },
  {
    "key": "14812",
    "ITA": "SUBIACO",
    "state_code": "RM",
    "cab": "394403",
    "cap": "28"
  },
  {
    "key": "14813",
    "ITA": "SUCCIVO",
    "state_code": "CE",
    "cab": "365403",
    "cap": "81030"
  },
  {
    "key": "14814",
    "ITA": "SUEGLIO",
    "state_code": "LC",
    "cab": "926295",
    "cap": "23835"
  },
  {
    "key": "14815",
    "ITA": "SUELLI",
    "state_code": "CA",
    "cab": "863001",
    "cap": "9040"
  },
  {
    "key": "14816",
    "ITA": "SUELLO",
    "state_code": "LC",
    "cab": "926303",
    "cap": "23867"
  },
  {
    "key": "14817",
    "ITA": "SUISIO",
    "state_code": "BG",
    "cab": "539304",
    "cap": "24040"
  },
  {
    "key": "14818",
    "ITA": "SULBIATE",
    "state_code": "MB",
    "cab": "891903",
    "cap": "20050"
  },
  {
    "key": "14819",
    "ITA": "SULMONA-MARANE",
    "state_code": "AQ",
    "cab": "408005",
    "cap": "67039"
  },
  {
    "key": "14820",
    "ITA": "SULMONA-CAVATE",
    "state_code": "AQ",
    "cab": "408005",
    "cap": "67039"
  },
  {
    "key": "14821",
    "ITA": "SULMONA-ARABONA",
    "state_code": "AQ",
    "cab": "408005",
    "cap": "67039"
  },
  {
    "key": "14822",
    "ITA": "SULMONA-ALBANESE",
    "state_code": "AQ",
    "cab": "408005",
    "cap": "67039"
  },
  {
    "key": "14823",
    "ITA": "SULMONA-ABAZIA DI SULMONA",
    "state_code": "AQ",
    "cab": "408005",
    "cap": "67039"
  },
  {
    "key": "14824",
    "ITA": "SULMONA-TORRONE",
    "state_code": "AQ",
    "cab": "408005",
    "cap": "67039"
  },
  {
    "key": "14825",
    "ITA": "SULMONA",
    "state_code": "AQ",
    "cab": "408005",
    "cap": "67039"
  },
  {
    "key": "14826",
    "ITA": "SULZANO",
    "state_code": "BS",
    "cab": "552604",
    "cap": "25058"
  },
  {
    "key": "14827",
    "ITA": "SUMIRAGO",
    "state_code": "VA",
    "cab": "506907",
    "cap": "21040"
  },
  {
    "key": "14828",
    "ITA": "SUMMONTE-STARZE",
    "state_code": "AV",
    "cab": "951731",
    "cap": "83010"
  },
  {
    "key": "14829",
    "ITA": "SUMMONTE",
    "state_code": "AV",
    "cab": "951731",
    "cap": "83010"
  },
  {
    "key": "14830",
    "ITA": "SUNI",
    "state_code": "OR",
    "cab": "870006",
    "cap": "8010"
  },
  {
    "key": "14831",
    "ITA": "SUNO",
    "state_code": "NO",
    "cab": "456905",
    "cap": "28019"
  },
  {
    "key": "14832",
    "ITA": "SUNO-BARAGGIA",
    "state_code": "NO",
    "cab": "456905",
    "cap": "28019"
  },
  {
    "key": "14833",
    "ITA": "SUPERSANO",
    "state_code": "LE",
    "cab": "800607",
    "cap": "73040"
  },
  {
    "key": "14834",
    "ITA": "SUPINO",
    "state_code": "FR",
    "cab": "746107",
    "cap": "3019"
  },
  {
    "key": "14835",
    "ITA": "SURANO",
    "state_code": "LE",
    "cab": "957738",
    "cap": "73030"
  },
  {
    "key": "14836",
    "ITA": "SURBO",
    "state_code": "LE",
    "cab": "800706",
    "cap": "73010"
  },
  {
    "key": "14837",
    "ITA": "SUSA",
    "state_code": "TO",
    "cab": "310607",
    "cap": "10059"
  },
  {
    "key": "14838",
    "ITA": "SUSEGANA-PRIULA",
    "state_code": "TV",
    "cab": "621201",
    "cap": "31010"
  },
  {
    "key": "14839",
    "ITA": "SUSEGANA",
    "state_code": "TV",
    "cab": "621201",
    "cap": "31058"
  },
  {
    "key": "14840",
    "ITA": "SUSEGANA-COLFOSCO",
    "state_code": "TV",
    "cab": "621201",
    "cap": "31030"
  },
  {
    "key": "14841",
    "ITA": "SUSTINENTE",
    "state_code": "MN",
    "cab": "580001",
    "cap": "46030"
  },
  {
    "key": "14842",
    "ITA": "SUSTINENTE-CA' VECCHIA",
    "state_code": "MN",
    "cab": "580001",
    "cap": "46030"
  },
  {
    "key": "14843",
    "ITA": "SUSTINENTE-SACCHETTA",
    "state_code": "MN",
    "cab": "580001",
    "cap": "46030"
  },
  {
    "key": "14844",
    "ITA": "SUTERA",
    "state_code": "CL",
    "cab": "834606",
    "cap": "93010"
  },
  {
    "key": "14845",
    "ITA": "SUTRI",
    "state_code": "VT",
    "cab": "732800",
    "cap": "1015"
  },
  {
    "key": "14846",
    "ITA": "SUTRIO",
    "state_code": "UD",
    "cab": "642603",
    "cap": "33020"
  },
  {
    "key": "14847",
    "ITA": "SUVERETO-MONTIONI",
    "state_code": "LI",
    "cab": "707901",
    "cap": "57028"
  },
  {
    "key": "14848",
    "ITA": "SUVERETO",
    "state_code": "LI",
    "cab": "707901",
    "cap": "57028"
  },
  {
    "key": "14849",
    "ITA": "SUZZARA-RIVA",
    "state_code": "MN",
    "cab": "580100",
    "cap": "46029"
  },
  {
    "key": "14850",
    "ITA": "SUZZARA",
    "state_code": "MN",
    "cab": "580100",
    "cap": "46029"
  },
  {
    "key": "14851",
    "ITA": "SUZZARA-BRUSATASSO",
    "state_code": "MN",
    "cab": "580100",
    "cap": "46029"
  },
  {
    "key": "14852",
    "ITA": "SUZZARA-SAILETTO",
    "state_code": "MN",
    "cab": "580100",
    "cap": "46029"
  },
  {
    "key": "14853",
    "ITA": "SUZZARA-SAN PROSPERO",
    "state_code": "MN",
    "cab": "580100",
    "cap": "46029"
  },
  {
    "key": "14854",
    "ITA": "SUZZARA-TABELLANO",
    "state_code": "MN",
    "cab": "580100",
    "cap": "46029"
  },
  {
    "key": "14855",
    "ITA": "TACENO",
    "state_code": "LC",
    "cab": "926311",
    "cap": "23837"
  },
  {
    "key": "14856",
    "ITA": "TADASUNI",
    "state_code": "OR",
    "cab": "967158",
    "cap": "9080"
  },
  {
    "key": "14857",
    "ITA": "TAGGIA",
    "state_code": "IM",
    "cab": "490904",
    "cap": "18018"
  },
  {
    "key": "14858",
    "ITA": "TAGGIA-ARMA DI TAGGIA",
    "state_code": "IM",
    "cab": "490904",
    "cap": "18018"
  },
  {
    "key": "14859",
    "ITA": "TAGLIACOZZO-POGGETELLO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14860",
    "ITA": "TAGLIACOZZO-SORBO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14861",
    "ITA": "TAGLIACOZZO-VILLA SAN SEBASTIANO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14862",
    "ITA": "TAGLIACOZZO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14863",
    "ITA": "TAGLIACOZZO-GALLO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14864",
    "ITA": "TAGLIACOZZO-TREMONTI",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14865",
    "ITA": "TAGLIACOZZO-POGGIO FILIPPO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14866",
    "ITA": "TAGLIACOZZO-ROCCACERRO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14867",
    "ITA": "TAGLIACOZZO-SAN DONATO",
    "state_code": "AQ",
    "cab": "408104",
    "cap": "67069"
  },
  {
    "key": "14868",
    "ITA": "TAGLIO DI PO",
    "state_code": "RO",
    "cab": "634709",
    "cap": "45019"
  },
  {
    "key": "14869",
    "ITA": "TAGLIO DI PO-MAZZORNO",
    "state_code": "RO",
    "cab": "634709",
    "cap": "45019"
  },
  {
    "key": "14870",
    "ITA": "TAGLIO DI PO-CA' VENDRAMIN",
    "state_code": "RO",
    "cab": "634709",
    "cap": "45019"
  },
  {
    "key": "14871",
    "ITA": "TAGLIO DI PO-POLESINELLO",
    "state_code": "RO",
    "cab": "634709",
    "cap": "45019"
  },
  {
    "key": "14872",
    "ITA": "TAGLIOLO MONFERRATO-MONGIARDINO",
    "state_code": "AL",
    "cab": "921742",
    "cap": "15070"
  },
  {
    "key": "14873",
    "ITA": "TAGLIOLO MONFERRATO",
    "state_code": "AL",
    "cab": "921742",
    "cap": "15070"
  },
  {
    "key": "14874",
    "ITA": "TAIBON AGORDINO",
    "state_code": "BL",
    "cab": "882605",
    "cap": "32027"
  },
  {
    "key": "14875",
    "ITA": "TAINO",
    "state_code": "VA",
    "cab": "505701",
    "cap": "21020"
  },
  {
    "key": "14876",
    "ITA": "TAIO",
    "state_code": "TN",
    "cab": "355701",
    "cap": "38012"
  },
  {
    "key": "14877",
    "ITA": "TAIPANA",
    "state_code": "UD",
    "cab": "937250",
    "cap": "33040"
  },
  {
    "key": "14878",
    "ITA": "TALAMELLO",
    "state_code": "RN",
    "cab": "941526",
    "cap": "61015"
  },
  {
    "key": "14879",
    "ITA": "TALAMONA",
    "state_code": "SO",
    "cab": "522706",
    "cap": "23018"
  },
  {
    "key": "14880",
    "ITA": "TALANA",
    "state_code": "OG",
    "cab": "870105",
    "cap": "8040"
  },
  {
    "key": "14881",
    "ITA": "TALEGGIO-OLDA",
    "state_code": "BG",
    "cab": "535807",
    "cap": "24010"
  },
  {
    "key": "14882",
    "ITA": "TALEGGIO-PEGHERA",
    "state_code": "BG",
    "cab": "535807",
    "cap": "24010"
  },
  {
    "key": "14883",
    "ITA": "TALEGGIO",
    "state_code": "BG",
    "cab": "535807",
    "cap": "24010"
  },
  {
    "key": "14884",
    "ITA": "TALLA",
    "state_code": "AR",
    "cab": "716506",
    "cap": "52010"
  },
  {
    "key": "14885",
    "ITA": "TALLA-FALTONA",
    "state_code": "AR",
    "cab": "716506",
    "cap": "52010"
  },
  {
    "key": "14886",
    "ITA": "TALMASSONS",
    "state_code": "UD",
    "cab": "642702",
    "cap": "33030"
  },
  {
    "key": "14887",
    "ITA": "TALMASSONS-FLAMBRO",
    "state_code": "UD",
    "cab": "642702",
    "cap": "33030"
  },
  {
    "key": "14888",
    "ITA": "TALMASSONS-FLUMIGNANO",
    "state_code": "UD",
    "cab": "642702",
    "cap": "33030"
  },
  {
    "key": "14889",
    "ITA": "TAMBRE",
    "state_code": "BL",
    "cab": "613307",
    "cap": "32010"
  },
  {
    "key": "14890",
    "ITA": "TAORMINA-CHIANCHITTA",
    "state_code": "ME",
    "cab": "825901",
    "cap": "98039"
  },
  {
    "key": "14891",
    "ITA": "TAORMINA-TRAPPITELLO",
    "state_code": "ME",
    "cab": "825901",
    "cap": "98039"
  },
  {
    "key": "14892",
    "ITA": "TAORMINA-MAZZEO",
    "state_code": "ME",
    "cab": "825901",
    "cap": "98039"
  },
  {
    "key": "14893",
    "ITA": "TAORMINA-MAZZARO'",
    "state_code": "ME",
    "cab": "825901",
    "cap": "98039"
  },
  {
    "key": "14894",
    "ITA": "TAORMINA",
    "state_code": "ME",
    "cab": "825901",
    "cap": "98039"
  },
  {
    "key": "14895",
    "ITA": "TAPOGLIANO",
    "state_code": "UD",
    "cab": "937268",
    "cap": "33040"
  },
  {
    "key": "14896",
    "ITA": "TARANO",
    "state_code": "RI",
    "cab": "947416",
    "cap": "2040"
  },
  {
    "key": "14897",
    "ITA": "TARANO-SAN POLO",
    "state_code": "RI",
    "cab": "947416",
    "cap": "2040"
  },
  {
    "key": "14898",
    "ITA": "TARANTA PELIGNA",
    "state_code": "CH",
    "cab": "955039",
    "cap": "66018"
  },
  {
    "key": "14899",
    "ITA": "TARANTASCA",
    "state_code": "CN",
    "cab": "468900",
    "cap": "12020"
  },
  {
    "key": "14900",
    "ITA": "TARANTO-LAMA",
    "state_code": "TA",
    "cab": "158006",
    "cap": "74100"
  },
  {
    "key": "14901",
    "ITA": "TARANTO-PAOLO VI",
    "state_code": "TA",
    "cab": "158006",
    "cap": "74100"
  },
  {
    "key": "14902",
    "ITA": "TARANTO-SAN VITO TARANTO",
    "state_code": "TA",
    "cab": "158006",
    "cap": "74100"
  },
  {
    "key": "14903",
    "ITA": "TARANTO-TALSANO",
    "state_code": "TA",
    "cab": "158006",
    "cap": "74100"
  },
  {
    "key": "14904",
    "ITA": "TARANTO",
    "state_code": "TA",
    "cab": "158006",
    "cap": "74100"
  },
  {
    "key": "14905",
    "ITA": "TARCENTO-COLLALTO",
    "state_code": "UD",
    "cab": "642801",
    "cap": "33017"
  },
  {
    "key": "14906",
    "ITA": "TARCENTO",
    "state_code": "UD",
    "cab": "642801",
    "cap": "33017"
  },
  {
    "key": "14907",
    "ITA": "TARCENTO-BULFONS",
    "state_code": "UD",
    "cab": "642801",
    "cap": "33017"
  },
  {
    "key": "14908",
    "ITA": "TARQUINIA",
    "state_code": "VT",
    "cab": "732909",
    "cap": "1016"
  },
  {
    "key": "14909",
    "ITA": "TARQUINIA-LIDO DI TARQUINIA",
    "state_code": "VT",
    "cab": "732909",
    "cap": "1016"
  },
  {
    "key": "14910",
    "ITA": "TARQUINIA-MARINA VELCA",
    "state_code": "VT",
    "cab": "732909",
    "cap": "1016"
  },
  {
    "key": "14911",
    "ITA": "TARSIA",
    "state_code": "CS",
    "cab": "810507",
    "cap": "87040"
  },
  {
    "key": "14912",
    "ITA": "TARTANO",
    "state_code": "SO",
    "cab": "927301",
    "cap": "23010"
  },
  {
    "key": "14913",
    "ITA": "TARTANO-SANT'ANTONIO MORIGNONE",
    "state_code": "SO",
    "cab": "927301",
    "cap": "23010"
  },
  {
    "key": "14914",
    "ITA": "TARTANO-CAMPO TARTANO",
    "state_code": "SO",
    "cab": "927301",
    "cap": "23010"
  },
  {
    "key": "14915",
    "ITA": "TARVISIO",
    "state_code": "UD",
    "cab": "642900",
    "cap": "33018"
  },
  {
    "key": "14916",
    "ITA": "TARVISIO-FUSINE IN VALROMANA",
    "state_code": "UD",
    "cab": "642900",
    "cap": "33018"
  },
  {
    "key": "14917",
    "ITA": "TARVISIO-CAVE DEL PREDIL",
    "state_code": "UD",
    "cab": "642900",
    "cap": "33018"
  },
  {
    "key": "14918",
    "ITA": "TARVISIO-CAMPOROSSO IN VALCANALE",
    "state_code": "UD",
    "cab": "642900",
    "cap": "33018"
  },
  {
    "key": "14919",
    "ITA": "TARZO",
    "state_code": "TV",
    "cab": "621300",
    "cap": "31020"
  },
  {
    "key": "14920",
    "ITA": "TARZO-CORBANESE",
    "state_code": "TV",
    "cab": "621300",
    "cap": "31020"
  },
  {
    "key": "14921",
    "ITA": "TASSAROLO",
    "state_code": "AL",
    "cab": "921759",
    "cap": "15060"
  },
  {
    "key": "14922",
    "ITA": "TASSULLO",
    "state_code": "TN",
    "cab": "355800",
    "cap": "38010"
  },
  {
    "key": "14923",
    "ITA": "TAURANO",
    "state_code": "AV",
    "cab": "951749",
    "cap": "83020"
  },
  {
    "key": "14924",
    "ITA": "TAURASI",
    "state_code": "AV",
    "cab": "897702",
    "cap": "83030"
  },
  {
    "key": "14925",
    "ITA": "TAURIANOVA",
    "state_code": "RC",
    "cab": "816108",
    "cap": "89029"
  },
  {
    "key": "14926",
    "ITA": "TAURIANOVA-AMATO DI TAURIANOVA",
    "state_code": "RC",
    "cab": "816108",
    "cap": "89029"
  },
  {
    "key": "14927",
    "ITA": "TAURIANOVA-SAN MARTINO",
    "state_code": "RC",
    "cab": "816108",
    "cap": "89029"
  },
  {
    "key": "14928",
    "ITA": "TAURISANO",
    "state_code": "LE",
    "cab": "800805",
    "cap": "73056"
  },
  {
    "key": "14929",
    "ITA": "TAVAGNACCO-FELETTO UMBERTO",
    "state_code": "UD",
    "cab": "643007",
    "cap": "33010"
  },
  {
    "key": "14930",
    "ITA": "TAVAGNACCO",
    "state_code": "UD",
    "cab": "643007",
    "cap": "33010"
  },
  {
    "key": "14931",
    "ITA": "TAVAGNACCO-ADEGLIACCO",
    "state_code": "UD",
    "cab": "643007",
    "cap": "33010"
  },
  {
    "key": "14932",
    "ITA": "TAVAGNACCO-COLUGNA",
    "state_code": "UD",
    "cab": "643007",
    "cap": "33010"
  },
  {
    "key": "14933",
    "ITA": "TAVAGNACCO-CAVALICCO",
    "state_code": "UD",
    "cab": "643007",
    "cap": "33010"
  },
  {
    "key": "14934",
    "ITA": "TAVAGNASCO",
    "state_code": "TO",
    "cab": "901629",
    "cap": "10010"
  },
  {
    "key": "14935",
    "ITA": "TAVARNELLE VAL DI PESA",
    "state_code": "FI",
    "cab": "381202",
    "cap": "50028"
  },
  {
    "key": "14936",
    "ITA": "TAVARNELLE VAL DI PESA-SAN DONATO IN POGGIO",
    "state_code": "FI",
    "cab": "381202",
    "cap": "50028"
  },
  {
    "key": "14937",
    "ITA": "TAVARNELLE VAL DI PESA-SAMBUCA",
    "state_code": "FI",
    "cab": "381202",
    "cap": "50028"
  },
  {
    "key": "14938",
    "ITA": "TAVAZZANO CON VILLAVESCO-TAVAZZANO",
    "state_code": "LO",
    "cab": "340604",
    "cap": "26838"
  },
  {
    "key": "14939",
    "ITA": "TAVAZZANO CON VILLAVESCO-VILLAVESCO",
    "state_code": "LO",
    "cab": "340604",
    "cap": "26838"
  },
  {
    "key": "14940",
    "ITA": "TAVAZZANO CON VILLAVESCO",
    "state_code": "LO",
    "cab": "340604",
    "cap": "26838"
  },
  {
    "key": "14941",
    "ITA": "TAVENNA",
    "state_code": "CB",
    "cab": "910992",
    "cap": "86030"
  },
  {
    "key": "14942",
    "ITA": "TAVERNA-VILLAGGIO MANCUSO",
    "state_code": "CZ",
    "cab": "428003",
    "cap": "88055"
  },
  {
    "key": "14943",
    "ITA": "TAVERNA-VILLAGGIO RACISE",
    "state_code": "CZ",
    "cab": "428003",
    "cap": "88055"
  },
  {
    "key": "14944",
    "ITA": "TAVERNA",
    "state_code": "CZ",
    "cab": "428003",
    "cap": "88055"
  },
  {
    "key": "14945",
    "ITA": "TAVERNERIO",
    "state_code": "CO",
    "cab": "517706",
    "cap": "22038"
  },
  {
    "key": "14946",
    "ITA": "TAVERNERIO-SOLZAGO",
    "state_code": "CO",
    "cab": "517706",
    "cap": "22038"
  },
  {
    "key": "14947",
    "ITA": "TAVERNOLA BERGAMASCA",
    "state_code": "BG",
    "cab": "535906",
    "cap": "24060"
  },
  {
    "key": "14948",
    "ITA": "TAVERNOLE SUL MELLA",
    "state_code": "BS",
    "cab": "552703",
    "cap": "25060"
  },
  {
    "key": "14949",
    "ITA": "TAVIANO",
    "state_code": "LE",
    "cab": "800904",
    "cap": "73057"
  },
  {
    "key": "14950",
    "ITA": "TAVIGLIANO",
    "state_code": "BI",
    "cab": "916411",
    "cap": "13811"
  },
  {
    "key": "14951",
    "ITA": "TAVOLETO",
    "state_code": "PU",
    "cab": "686709",
    "cap": "61020"
  },
  {
    "key": "14952",
    "ITA": "TAVULLIA-RIO SALSO",
    "state_code": "PU",
    "cab": "686808",
    "cap": "61010"
  },
  {
    "key": "14953",
    "ITA": "TAVULLIA-CASE BERNARDI",
    "state_code": "PU",
    "cab": "686808",
    "cap": "61010"
  },
  {
    "key": "14954",
    "ITA": "TAVULLIA-PADIGLIONE",
    "state_code": "PU",
    "cab": "686808",
    "cap": "61010"
  },
  {
    "key": "14955",
    "ITA": "TAVULLIA",
    "state_code": "PU",
    "cab": "686808",
    "cap": "61010"
  },
  {
    "key": "14956",
    "ITA": "TAVULLIA-BELVEDERE FOGLIENSE",
    "state_code": "PU",
    "cab": "686808",
    "cap": "61010"
  },
  {
    "key": "14957",
    "ITA": "TEANA",
    "state_code": "PZ",
    "cab": "912212",
    "cap": "85032"
  },
  {
    "key": "14958",
    "ITA": "TEANO-PUGLIANO",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14959",
    "ITA": "TEANO-CASAFREDDA",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14960",
    "ITA": "TEANO",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14961",
    "ITA": "TEANO-VERSANO",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14962",
    "ITA": "TEANO-SAN MARCO",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14963",
    "ITA": "TEANO-CASAMOSTRA",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14964",
    "ITA": "TEANO-CASI",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14965",
    "ITA": "TEANO-FONTANELLE",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14966",
    "ITA": "TEANO-FURNOLO",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14967",
    "ITA": "TEANO-CASALE DI TEANO",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14968",
    "ITA": "TEANO-SAN GIULIANO",
    "state_code": "CE",
    "cab": "750703",
    "cap": "81057"
  },
  {
    "key": "14969",
    "ITA": "TEGGIANO-SAN MARCO DI TEGGIANO",
    "state_code": "SA",
    "cab": "765107",
    "cap": "84039"
  },
  {
    "key": "14970",
    "ITA": "TEGGIANO-PRATO PERILLO",
    "state_code": "SA",
    "cab": "765107",
    "cap": "84039"
  },
  {
    "key": "14971",
    "ITA": "TEGGIANO-PANTANO DI TEGGIANO",
    "state_code": "SA",
    "cab": "765107",
    "cap": "84039"
  },
  {
    "key": "14972",
    "ITA": "TEGGIANO",
    "state_code": "SA",
    "cab": "765107",
    "cap": "84039"
  },
  {
    "key": "14973",
    "ITA": "TEGLIO",
    "state_code": "SO",
    "cab": "522805",
    "cap": "23036"
  },
  {
    "key": "14974",
    "ITA": "TEGLIO-SAN GIACOMO",
    "state_code": "SO",
    "cab": "522805",
    "cap": "23036"
  },
  {
    "key": "14975",
    "ITA": "TEGLIO-TRESENDA",
    "state_code": "SO",
    "cab": "522805",
    "cap": "23036"
  },
  {
    "key": "14976",
    "ITA": "TEGLIO VENETO",
    "state_code": "VE",
    "cab": "363507",
    "cap": "30025"
  },
  {
    "key": "14977",
    "ITA": "TEGLIO VENETO-CINTELLO",
    "state_code": "VE",
    "cab": "363507",
    "cap": "30025"
  },
  {
    "key": "14978",
    "ITA": "TELESE TERME",
    "state_code": "BN",
    "cab": "755108",
    "cap": "82037"
  },
  {
    "key": "14979",
    "ITA": "TELGATE",
    "state_code": "BG",
    "cab": "536003",
    "cap": "24060"
  },
  {
    "key": "14980",
    "ITA": "TELTI",
    "state_code": "OT",
    "cab": "876706",
    "cap": "7020"
  },
  {
    "key": "14981",
    "ITA": "TELVE",
    "state_code": "TN",
    "cab": "355909",
    "cap": "38050"
  },
  {
    "key": "14982",
    "ITA": "TELVE-TELVE DI VALSUGANA",
    "state_code": "TN",
    "cab": "355909",
    "cap": "38050"
  },
  {
    "key": "14983",
    "ITA": "TELVE DI SOPRA",
    "state_code": "TN",
    "cab": "905117",
    "cap": "38050"
  },
  {
    "key": "14984",
    "ITA": "TEMPIO PAUSANIA-BASSACUTENA",
    "state_code": "OT",
    "cab": "850800",
    "cap": "7020"
  },
  {
    "key": "14985",
    "ITA": "TEMPIO PAUSANIA-NUCHIS",
    "state_code": "OT",
    "cab": "850800",
    "cap": "7029"
  },
  {
    "key": "14986",
    "ITA": "TEMPIO PAUSANIA",
    "state_code": "OT",
    "cab": "850800",
    "cap": "7029"
  },
  {
    "key": "14987",
    "ITA": "TEMU'",
    "state_code": "BS",
    "cab": "552802",
    "cap": "25050"
  },
  {
    "key": "14988",
    "ITA": "TEMU'-PONTAGNA",
    "state_code": "BS",
    "cab": "552802",
    "cap": "25050"
  },
  {
    "key": "14989",
    "ITA": "TENNA",
    "state_code": "TN",
    "cab": "356006",
    "cap": "38050"
  },
  {
    "key": "14990",
    "ITA": "TENNO",
    "state_code": "TN",
    "cab": "356105",
    "cap": "38060"
  },
  {
    "key": "14991",
    "ITA": "TENNO-GAVAZZO NUOVA",
    "state_code": "TN",
    "cab": "356105",
    "cap": "38060"
  },
  {
    "key": "14992",
    "ITA": "TENNO-COLOGNA",
    "state_code": "TN",
    "cab": "356105",
    "cap": "38060"
  },
  {
    "key": "14993",
    "ITA": "TEOLO-VILLA",
    "state_code": "PD",
    "cab": "629204",
    "cap": "35037"
  },
  {
    "key": "14994",
    "ITA": "TEOLO-TREPONTI",
    "state_code": "PD",
    "cab": "629204",
    "cap": "35037"
  },
  {
    "key": "14995",
    "ITA": "TEOLO-SAN BIAGIO",
    "state_code": "PD",
    "cab": "629204",
    "cap": "35037"
  },
  {
    "key": "14996",
    "ITA": "TEOLO-BRESSEO",
    "state_code": "PD",
    "cab": "629204",
    "cap": "35037"
  },
  {
    "key": "14997",
    "ITA": "TEOLO",
    "state_code": "PD",
    "cab": "629204",
    "cap": "35037"
  },
  {
    "key": "14998",
    "ITA": "TEOR",
    "state_code": "UD",
    "cab": "894907",
    "cap": "33050"
  },
  {
    "key": "14999",
    "ITA": "TEOR-RIVAROTTA",
    "state_code": "UD",
    "cab": "894907",
    "cap": "33050"
  },
  {
    "key": "15000",
    "ITA": "TEORA",
    "state_code": "AV",
    "cab": "891101",
    "cap": "83056"
  },
  {
    "key": "15001",
    "ITA": "TERAMO-COLLEATTERRATO ALTO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15002",
    "ITA": "TERAMO-VILLA RIPA",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15003",
    "ITA": "TERAMO-VARANO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15004",
    "ITA": "TERAMO-VALLE SAN GIOVANNI",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15005",
    "ITA": "TERAMO-TORDINIA",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15006",
    "ITA": "TERAMO-SPIANO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15007",
    "ITA": "TERAMO-SANT'ATTO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15008",
    "ITA": "TERAMO-SAN NICOLO' A TORDINO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15009",
    "ITA": "TERAMO-POGGIO SAN VITTORINO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15010",
    "ITA": "TERAMO-PIANO D'ACCIO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15011",
    "ITA": "TERAMO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15012",
    "ITA": "TERAMO-CARTECCHIO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15013",
    "ITA": "TERAMO-CASTAGNETO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15014",
    "ITA": "TERAMO-COLLE SANTA MARIA",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15015",
    "ITA": "TERAMO-VILLA VOMANO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15016",
    "ITA": "TERAMO-COLLEMINUCCIO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15017",
    "ITA": "TERAMO-FORCELLA",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15018",
    "ITA": "TERAMO-FRONDAROLA",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15019",
    "ITA": "TERAMO-MIANO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15020",
    "ITA": "TERAMO-NEPEZZANO",
    "state_code": "TE",
    "cab": "153007",
    "cap": "64100"
  },
  {
    "key": "15021",
    "ITA": "TERDOBBIATE",
    "state_code": "NO",
    "cab": "917732",
    "cap": "28070"
  },
  {
    "key": "15022",
    "ITA": "TERELLE",
    "state_code": "FR",
    "cab": "288340",
    "cap": "3040"
  },
  {
    "key": "15023",
    "ITA": "TERENTO",
    "state_code": "BZ",
    "cab": "589507",
    "cap": "39030"
  },
  {
    "key": "15024",
    "ITA": "TERENZO-BOSCHI DI BARDONE",
    "state_code": "PR",
    "cab": "939017",
    "cap": "43040"
  },
  {
    "key": "15025",
    "ITA": "TERENZO",
    "state_code": "PR",
    "cab": "939017",
    "cap": "43040"
  },
  {
    "key": "15026",
    "ITA": "TERENZO-CASOLA",
    "state_code": "PR",
    "cab": "939017",
    "cap": "43040"
  },
  {
    "key": "15027",
    "ITA": "TERENZO-SELVA DEL BOCCHETTO",
    "state_code": "PR",
    "cab": "939017",
    "cap": "43040"
  },
  {
    "key": "15028",
    "ITA": "TERENZO-LESIGNANO PALMIA",
    "state_code": "PR",
    "cab": "939017",
    "cap": "43040"
  },
  {
    "key": "15029",
    "ITA": "TERENZO-CORNIANA",
    "state_code": "PR",
    "cab": "939017",
    "cap": "43040"
  },
  {
    "key": "15030",
    "ITA": "TERENZO-CASSIO",
    "state_code": "PR",
    "cab": "939017",
    "cap": "43040"
  },
  {
    "key": "15031",
    "ITA": "TERGU",
    "state_code": "SS",
    "cab": "965012",
    "cap": "7030"
  },
  {
    "key": "15032",
    "ITA": "TERLAGO",
    "state_code": "TN",
    "cab": "356204",
    "cap": "38070"
  },
  {
    "key": "15033",
    "ITA": "TERLANO-SETTEQUERCE",
    "state_code": "BZ",
    "cab": "589606",
    "cap": "39018"
  },
  {
    "key": "15034",
    "ITA": "TERLANO-VILPIANO",
    "state_code": "BZ",
    "cab": "589606",
    "cap": "39018"
  },
  {
    "key": "15035",
    "ITA": "TERLANO",
    "state_code": "BZ",
    "cab": "589606",
    "cap": "39018"
  },
  {
    "key": "15036",
    "ITA": "TERLIZZI",
    "state_code": "BA",
    "cab": "417006",
    "cap": "70038"
  },
  {
    "key": "15037",
    "ITA": "TERME VIGLIATORE-CASTROREALE TERME",
    "state_code": "ME",
    "cab": "826503",
    "cap": "98050"
  },
  {
    "key": "15038",
    "ITA": "TERME VIGLIATORE",
    "state_code": "ME",
    "cab": "826503",
    "cap": "98050"
  },
  {
    "key": "15039",
    "ITA": "TERME VIGLIATORE-VIGLIATORE",
    "state_code": "ME",
    "cab": "826503",
    "cap": "98050"
  },
  {
    "key": "15040",
    "ITA": "TERMENO SULLA STRADA DEL VINO",
    "state_code": "BZ",
    "cab": "589705",
    "cap": "39040"
  },
  {
    "key": "15041",
    "ITA": "TERMINI IMERESE-DANIGARCI",
    "state_code": "PA",
    "cab": "436402",
    "cap": "90018"
  },
  {
    "key": "15042",
    "ITA": "TERMINI IMERESE",
    "state_code": "PA",
    "cab": "436402",
    "cap": "90018"
  },
  {
    "key": "15043",
    "ITA": "TERMOLI",
    "state_code": "CB",
    "cab": "411306",
    "cap": "86039"
  },
  {
    "key": "15044",
    "ITA": "TERNATE",
    "state_code": "VA",
    "cab": "507905",
    "cap": "21020"
  },
  {
    "key": "15045",
    "ITA": "TERNENGO",
    "state_code": "BI",
    "cab": "916429",
    "cap": "13844"
  },
  {
    "key": "15046",
    "ITA": "TERNI-ROCCA SAN ZENONE",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15047",
    "ITA": "TERNI-PIEDILUCO",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15048",
    "ITA": "TERNI-VALENZA",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15049",
    "ITA": "TERNI-PAPIGNO",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15050",
    "ITA": "TERNI-MIRANDA",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15051",
    "ITA": "TERNI-MARMORE",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15052",
    "ITA": "TERNI-GIUNCANO SCALO",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15053",
    "ITA": "TERNI-GABELLETTA DI CESI",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15054",
    "ITA": "TERNI-COLLESTATTE PIANO",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15055",
    "ITA": "TERNI-COLLESTATTE",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15056",
    "ITA": "TERNI-COLLESCIPOLI",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15057",
    "ITA": "TERNI-CESI",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15058",
    "ITA": "TERNI-CECALOCCO",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15059",
    "ITA": "TERNI-BATTIFERRO",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15060",
    "ITA": "TERNI",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15061",
    "ITA": "TERNI-TORREORSINA",
    "state_code": "TR",
    "cab": "144006",
    "cap": "5100"
  },
  {
    "key": "15062",
    "ITA": "TERNO D'ISOLA",
    "state_code": "BG",
    "cab": "536102",
    "cap": "24030"
  },
  {
    "key": "15063",
    "ITA": "TERRACINA-LA FIORA",
    "state_code": "LT",
    "cab": "741603",
    "cap": "4019"
  },
  {
    "key": "15064",
    "ITA": "TERRACINA-BADINO",
    "state_code": "LT",
    "cab": "741603",
    "cap": "4019"
  },
  {
    "key": "15065",
    "ITA": "TERRACINA",
    "state_code": "LT",
    "cab": "741603",
    "cap": "4019"
  },
  {
    "key": "15066",
    "ITA": "TERRACINA-BORGO HERMADA",
    "state_code": "LT",
    "cab": "741603",
    "cap": "4019"
  },
  {
    "key": "15067",
    "ITA": "TERRAGNOLO",
    "state_code": "TN",
    "cab": "356303",
    "cap": "38060"
  },
  {
    "key": "15068",
    "ITA": "TERRAGNOLO-VALDUGA",
    "state_code": "TN",
    "cab": "356303",
    "cap": "38060"
  },
  {
    "key": "15069",
    "ITA": "TERRALBA-TANCA MARCHESE",
    "state_code": "OR",
    "cab": "856203",
    "cap": "9098"
  },
  {
    "key": "15070",
    "ITA": "TERRALBA",
    "state_code": "OR",
    "cab": "856203",
    "cap": "9098"
  },
  {
    "key": "15071",
    "ITA": "TERRANOVA DA SIBARI",
    "state_code": "CS",
    "cab": "810606",
    "cap": "87010"
  },
  {
    "key": "15072",
    "ITA": "TERRANOVA DEI PASSERINI",
    "state_code": "LO",
    "cab": "904169",
    "cap": "26827"
  },
  {
    "key": "15073",
    "ITA": "TERRANOVA DI POLLINO-CASA DEL CONTE",
    "state_code": "PZ",
    "cab": "424002",
    "cap": "85030"
  },
  {
    "key": "15074",
    "ITA": "TERRANOVA DI POLLINO",
    "state_code": "PZ",
    "cab": "424002",
    "cap": "85030"
  },
  {
    "key": "15075",
    "ITA": "TERRANOVA SAPPO MINULIO",
    "state_code": "RC",
    "cab": "960476",
    "cap": "89010"
  },
  {
    "key": "15076",
    "ITA": "TERRANUOVA BRACCIOLINI-MADRIGALE",
    "state_code": "AR",
    "cab": "716605",
    "cap": "52028"
  },
  {
    "key": "15077",
    "ITA": "TERRANUOVA BRACCIOLINI-MALVA",
    "state_code": "AR",
    "cab": "716605",
    "cap": "52028"
  },
  {
    "key": "15078",
    "ITA": "TERRANUOVA BRACCIOLINI-PENNA",
    "state_code": "AR",
    "cab": "716605",
    "cap": "52028"
  },
  {
    "key": "15079",
    "ITA": "TERRANUOVA BRACCIOLINI-VILLE",
    "state_code": "AR",
    "cab": "716605",
    "cap": "52028"
  },
  {
    "key": "15080",
    "ITA": "TERRANUOVA BRACCIOLINI-CAMPOGIALLI",
    "state_code": "AR",
    "cab": "716605",
    "cap": "52028"
  },
  {
    "key": "15081",
    "ITA": "TERRANUOVA BRACCIOLINI",
    "state_code": "AR",
    "cab": "716605",
    "cap": "52028"
  },
  {
    "key": "15082",
    "ITA": "TERRASINI-CITTA' DEL MARE",
    "state_code": "PA",
    "cab": "436501",
    "cap": "90049"
  },
  {
    "key": "15083",
    "ITA": "TERRASINI",
    "state_code": "PA",
    "cab": "436501",
    "cap": "90049"
  },
  {
    "key": "15084",
    "ITA": "TERRASSA PADOVANA",
    "state_code": "PD",
    "cab": "721001",
    "cap": "35020"
  },
  {
    "key": "15085",
    "ITA": "TERRASSA PADOVANA-ARZERCAVALLI",
    "state_code": "PD",
    "cab": "721001",
    "cap": "35020"
  },
  {
    "key": "15086",
    "ITA": "TERRAVECCHIA",
    "state_code": "CS",
    "cab": "959460",
    "cap": "87060"
  },
  {
    "key": "15087",
    "ITA": "TERRAZZO-BEGOSSO",
    "state_code": "VR",
    "cab": "598904",
    "cap": "37040"
  },
  {
    "key": "15088",
    "ITA": "TERRAZZO",
    "state_code": "VR",
    "cab": "598904",
    "cap": "37040"
  },
  {
    "key": "15089",
    "ITA": "TERRES",
    "state_code": "TN",
    "cab": "905125",
    "cap": "38010"
  },
  {
    "key": "15090",
    "ITA": "TERRICCIOLA",
    "state_code": "PI",
    "cab": "711903",
    "cap": "56030"
  },
  {
    "key": "15091",
    "ITA": "TERRICCIOLA-SOIANA",
    "state_code": "PI",
    "cab": "711903",
    "cap": "56030"
  },
  {
    "key": "15092",
    "ITA": "TERRICCIOLA-SELVATELLE",
    "state_code": "PI",
    "cab": "711903",
    "cap": "56030"
  },
  {
    "key": "15093",
    "ITA": "TERRICCIOLA-MORRONA",
    "state_code": "PI",
    "cab": "711903",
    "cap": "56030"
  },
  {
    "key": "15094",
    "ITA": "TERRUGGIA",
    "state_code": "AL",
    "cab": "921767",
    "cap": "15030"
  },
  {
    "key": "15095",
    "ITA": "TERTENIA",
    "state_code": "OG",
    "cab": "870204",
    "cap": "8047"
  },
  {
    "key": "15096",
    "ITA": "TERTENIA-MIGHELI",
    "state_code": "OG",
    "cab": "870204",
    "cap": "8047"
  },
  {
    "key": "15097",
    "ITA": "TERZIGNO",
    "state_code": "NA",
    "cab": "402800",
    "cap": "80040"
  },
  {
    "key": "15098",
    "ITA": "TERZIGNO-BOCCIA AL MAURO",
    "state_code": "NA",
    "cab": "402800",
    "cap": "80040"
  },
  {
    "key": "15099",
    "ITA": "TERZO",
    "state_code": "AL",
    "cab": "921775",
    "cap": "15010"
  },
  {
    "key": "15100",
    "ITA": "TERZO D'AQUILEIA",
    "state_code": "UD",
    "cab": "643106",
    "cap": "33050"
  },
  {
    "key": "15101",
    "ITA": "TERZOLAS",
    "state_code": "TN",
    "cab": "417808",
    "cap": "38027"
  },
  {
    "key": "15102",
    "ITA": "TERZORIO",
    "state_code": "IM",
    "cab": "922633",
    "cap": "18010"
  },
  {
    "key": "15103",
    "ITA": "TESERO",
    "state_code": "TN",
    "cab": "356402",
    "cap": "38038"
  },
  {
    "key": "15104",
    "ITA": "TESIMO-PRISSIANO",
    "state_code": "BZ",
    "cab": "589804",
    "cap": "39010"
  },
  {
    "key": "15105",
    "ITA": "TESIMO",
    "state_code": "BZ",
    "cab": "589804",
    "cap": "39010"
  },
  {
    "key": "15106",
    "ITA": "TESSENNANO",
    "state_code": "VT",
    "cab": "946764",
    "cap": "1010"
  },
  {
    "key": "15107",
    "ITA": "TESTICO",
    "state_code": "SV",
    "cab": "923318",
    "cap": "17020"
  },
  {
    "key": "15108",
    "ITA": "TETI",
    "state_code": "NU",
    "cab": "870303",
    "cap": "8030"
  },
  {
    "key": "15109",
    "ITA": "TEULADA",
    "state_code": "CA",
    "cab": "863100",
    "cap": "9019"
  },
  {
    "key": "15110",
    "ITA": "TEVEROLA",
    "state_code": "CE",
    "cab": "750802",
    "cap": "81030"
  },
  {
    "key": "15111",
    "ITA": "TEZZE SUL BRENTA",
    "state_code": "VI",
    "cab": "607804",
    "cap": "36056"
  },
  {
    "key": "15112",
    "ITA": "TEZZE SUL BRENTA-BELVEDERE",
    "state_code": "VI",
    "cab": "607804",
    "cap": "36056"
  },
  {
    "key": "15113",
    "ITA": "THIENE",
    "state_code": "VI",
    "cab": "607903",
    "cap": "36016"
  },
  {
    "key": "15114",
    "ITA": "THIENE-ROZZAMPIA",
    "state_code": "VI",
    "cab": "607903",
    "cap": "36016"
  },
  {
    "key": "15115",
    "ITA": "THIESI",
    "state_code": "SS",
    "cab": "850909",
    "cap": "7047"
  },
  {
    "key": "15116",
    "ITA": "TIANA",
    "state_code": "NU",
    "cab": "870402",
    "cap": "8020"
  },
  {
    "key": "15117",
    "ITA": "TIARNO DI SOPRA",
    "state_code": "TN",
    "cab": "356501",
    "cap": "38060"
  },
  {
    "key": "15118",
    "ITA": "TIARNO DI SOTTO",
    "state_code": "TN",
    "cab": "359703",
    "cap": "38060"
  },
  {
    "key": "15119",
    "ITA": "TICENGO",
    "state_code": "CR",
    "cab": "931899",
    "cap": "26020"
  },
  {
    "key": "15120",
    "ITA": "TICINETO",
    "state_code": "AL",
    "cab": "486605",
    "cap": "15040"
  },
  {
    "key": "15121",
    "ITA": "TIGGIANO",
    "state_code": "LE",
    "cab": "957746",
    "cap": "73030"
  },
  {
    "key": "15122",
    "ITA": "TIGLIETO",
    "state_code": "GE",
    "cab": "322503",
    "cap": "16010"
  },
  {
    "key": "15123",
    "ITA": "TIGLIOLE-PRATOMORONE",
    "state_code": "AT",
    "cab": "477208",
    "cap": "14016"
  },
  {
    "key": "15124",
    "ITA": "TIGLIOLE",
    "state_code": "AT",
    "cab": "477208",
    "cap": "14016"
  },
  {
    "key": "15125",
    "ITA": "TIGNALE",
    "state_code": "BS",
    "cab": "552901",
    "cap": "25080"
  },
  {
    "key": "15126",
    "ITA": "TIGNALE-GARDOLA",
    "state_code": "BS",
    "cab": "552901",
    "cap": "25080"
  },
  {
    "key": "15127",
    "ITA": "TINNURA",
    "state_code": "OR",
    "cab": "966135",
    "cap": "8010"
  },
  {
    "key": "15128",
    "ITA": "TIONE DEGLI ABRUZZI",
    "state_code": "AQ",
    "cab": "909994",
    "cap": "67020"
  },
  {
    "key": "15129",
    "ITA": "TIONE DEGLI ABRUZZI-GORIANO VALLI",
    "state_code": "AQ",
    "cab": "909994",
    "cap": "67020"
  },
  {
    "key": "15130",
    "ITA": "TIONE DI TRENTO-SAONE",
    "state_code": "TN",
    "cab": "356600",
    "cap": "38079"
  },
  {
    "key": "15131",
    "ITA": "TIONE DI TRENTO",
    "state_code": "TN",
    "cab": "356600",
    "cap": "38079"
  },
  {
    "key": "15132",
    "ITA": "TIRANO-MADONNA DI TIRANO",
    "state_code": "SO",
    "cab": "522904",
    "cap": "23037"
  },
  {
    "key": "15133",
    "ITA": "TIRANO",
    "state_code": "SO",
    "cab": "522904",
    "cap": "23037"
  },
  {
    "key": "15134",
    "ITA": "TIRES",
    "state_code": "BZ",
    "cab": "589903",
    "cap": "39050"
  },
  {
    "key": "15135",
    "ITA": "TIRIOLO-PRATORA",
    "state_code": "CZ",
    "cab": "428102",
    "cap": "88040"
  },
  {
    "key": "15136",
    "ITA": "TIRIOLO",
    "state_code": "CZ",
    "cab": "428102",
    "cap": "88056"
  },
  {
    "key": "15137",
    "ITA": "TIROLO",
    "state_code": "BZ",
    "cab": "590000",
    "cap": "39019"
  },
  {
    "key": "15138",
    "ITA": "TISSI",
    "state_code": "SS",
    "cab": "876003",
    "cap": "7040"
  },
  {
    "key": "15139",
    "ITA": "TITO-TITO SCALO",
    "state_code": "PZ",
    "cab": "422808",
    "cap": "85050"
  },
  {
    "key": "15140",
    "ITA": "TITO",
    "state_code": "PZ",
    "cab": "422808",
    "cap": "85050"
  },
  {
    "key": "15141",
    "ITA": "TIVOLI",
    "state_code": "RM",
    "cab": "394502",
    "cap": "19"
  },
  {
    "key": "15142",
    "ITA": "TIVOLI-TIVOLI TERME",
    "state_code": "RM",
    "cab": "394502",
    "cap": "11"
  },
  {
    "key": "15143",
    "ITA": "TIVOLI-PONTELUCANO",
    "state_code": "RM",
    "cab": "394502",
    "cap": "10"
  },
  {
    "key": "15144",
    "ITA": "TIVOLI-EMPOLITANA",
    "state_code": "RM",
    "cab": "394502",
    "cap": "19"
  },
  {
    "key": "15145",
    "ITA": "TIVOLI-BIVIO SAN POLO",
    "state_code": "RM",
    "cab": "394502",
    "cap": "19"
  },
  {
    "key": "15146",
    "ITA": "TIVOLI-VILLA ADRIANA",
    "state_code": "RM",
    "cab": "394502",
    "cap": "10"
  },
  {
    "key": "15147",
    "ITA": "TIVOLI-ARCI",
    "state_code": "RM",
    "cab": "394502",
    "cap": "19"
  },
  {
    "key": "15148",
    "ITA": "TIZZANO VAL PARMA-CARPANETO",
    "state_code": "PR",
    "cab": "659607",
    "cap": "43028"
  },
  {
    "key": "15149",
    "ITA": "TIZZANO VAL PARMA-RENO",
    "state_code": "PR",
    "cab": "659607",
    "cap": "43028"
  },
  {
    "key": "15150",
    "ITA": "TIZZANO VAL PARMA-MUSIARA INFERIORE",
    "state_code": "PR",
    "cab": "659607",
    "cap": "43028"
  },
  {
    "key": "15151",
    "ITA": "TIZZANO VAL PARMA-LAGRIMONE",
    "state_code": "PR",
    "cab": "659607",
    "cap": "43028"
  },
  {
    "key": "15152",
    "ITA": "TIZZANO VAL PARMA-CAPRIGLIO",
    "state_code": "PR",
    "cab": "659607",
    "cap": "43028"
  },
  {
    "key": "15153",
    "ITA": "TIZZANO VAL PARMA-CAPOPONTE",
    "state_code": "PR",
    "cab": "659607",
    "cap": "43028"
  },
  {
    "key": "15154",
    "ITA": "TIZZANO VAL PARMA",
    "state_code": "PR",
    "cab": "659607",
    "cap": "43028"
  },
  {
    "key": "15155",
    "ITA": "TOANO-CERREDOLO",
    "state_code": "RE",
    "cab": "665208",
    "cap": "42010"
  },
  {
    "key": "15156",
    "ITA": "TOANO-CAVOLA",
    "state_code": "RE",
    "cab": "665208",
    "cap": "42010"
  },
  {
    "key": "15157",
    "ITA": "TOANO",
    "state_code": "RE",
    "cab": "665208",
    "cap": "42010"
  },
  {
    "key": "15158",
    "ITA": "TOANO-SAN CASSIANO",
    "state_code": "RE",
    "cab": "665208",
    "cap": "42010"
  },
  {
    "key": "15159",
    "ITA": "TOANO-QUARA",
    "state_code": "RE",
    "cab": "665208",
    "cap": "42010"
  },
  {
    "key": "15160",
    "ITA": "TOCCO CAUDIO",
    "state_code": "BN",
    "cab": "950568",
    "cap": "82030"
  },
  {
    "key": "15161",
    "ITA": "TOCCO DA CASAURIA",
    "state_code": "PE",
    "cab": "774406",
    "cap": "65028"
  },
  {
    "key": "15162",
    "ITA": "TOCENO",
    "state_code": "VB",
    "cab": "917740",
    "cap": "28858"
  },
  {
    "key": "15163",
    "ITA": "TODI",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15164",
    "ITA": "TODI-PONTERIO",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15165",
    "ITA": "TODI-PONTECUTI",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15166",
    "ITA": "TODI-PIAN DI SAN MARTINO",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15167",
    "ITA": "TODI-PANTALLA",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15168",
    "ITA": "TODI-MONTICELLO",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15169",
    "ITA": "TODI-MONTENERO",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15170",
    "ITA": "TODI-IZZALINI",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15171",
    "ITA": "TODI-ILCI",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15172",
    "ITA": "TODI-COLLEVALENZA",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15173",
    "ITA": "TODI-CANONICA",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15174",
    "ITA": "TODI-CAMERATA",
    "state_code": "PG",
    "cab": "387001",
    "cap": "6059"
  },
  {
    "key": "15175",
    "ITA": "TOFFIA",
    "state_code": "RI",
    "cab": "947424",
    "cap": "2039"
  },
  {
    "key": "15176",
    "ITA": "TOIRANO",
    "state_code": "SV",
    "cab": "495101",
    "cap": "17055"
  },
  {
    "key": "15177",
    "ITA": "TOLENTINO",
    "state_code": "MC",
    "cab": "692004",
    "cap": "62029"
  },
  {
    "key": "15178",
    "ITA": "TOLFA-SANTA SEVERA NORD",
    "state_code": "RM",
    "cab": "394601",
    "cap": "59"
  },
  {
    "key": "15179",
    "ITA": "TOLFA",
    "state_code": "RM",
    "cab": "394601",
    "cap": "59"
  },
  {
    "key": "15180",
    "ITA": "TOLLEGNO",
    "state_code": "BI",
    "cab": "448308",
    "cap": "13818"
  },
  {
    "key": "15181",
    "ITA": "TOLLO",
    "state_code": "CH",
    "cab": "778902",
    "cap": "66010"
  },
  {
    "key": "15182",
    "ITA": "TOLMEZZO",
    "state_code": "UD",
    "cab": "643205",
    "cap": "33028"
  },
  {
    "key": "15183",
    "ITA": "TOLMEZZO-ILLEGIO",
    "state_code": "UD",
    "cab": "643205",
    "cap": "33028"
  },
  {
    "key": "15184",
    "ITA": "TOLMEZZO-IMPONZO",
    "state_code": "UD",
    "cab": "643205",
    "cap": "33028"
  },
  {
    "key": "15185",
    "ITA": "TOLMEZZO-CADUNEA",
    "state_code": "UD",
    "cab": "643205",
    "cap": "33028"
  },
  {
    "key": "15186",
    "ITA": "TOLMEZZO-CANEVA DI TOLMEZZO",
    "state_code": "UD",
    "cab": "643205",
    "cap": "33028"
  },
  {
    "key": "15187",
    "ITA": "TOLMEZZO-TERZO DI TOLMEZZO",
    "state_code": "UD",
    "cab": "643205",
    "cap": "33028"
  },
  {
    "key": "15188",
    "ITA": "TOLVE",
    "state_code": "PZ",
    "cab": "422907",
    "cap": "85017"
  },
  {
    "key": "15189",
    "ITA": "TOMBOLO",
    "state_code": "PD",
    "cab": "629303",
    "cap": "35019"
  },
  {
    "key": "15190",
    "ITA": "TOMBOLO-ONARA",
    "state_code": "PD",
    "cab": "629303",
    "cap": "35019"
  },
  {
    "key": "15191",
    "ITA": "TON-VIGO DI TON",
    "state_code": "TN",
    "cab": "356709",
    "cap": "38010"
  },
  {
    "key": "15192",
    "ITA": "TON-VIGO ANAUNIA",
    "state_code": "TN",
    "cab": "356709",
    "cap": "38010"
  },
  {
    "key": "15193",
    "ITA": "TON",
    "state_code": "TN",
    "cab": "356709",
    "cap": "38010"
  },
  {
    "key": "15194",
    "ITA": "TONADICO",
    "state_code": "TN",
    "cab": "358507",
    "cap": "38054"
  },
  {
    "key": "15195",
    "ITA": "TONARA",
    "state_code": "NU",
    "cab": "870501",
    "cap": "8039"
  },
  {
    "key": "15196",
    "ITA": "TONCO",
    "state_code": "AT",
    "cab": "477307",
    "cap": "14039"
  },
  {
    "key": "15197",
    "ITA": "TONENGO",
    "state_code": "AT",
    "cab": "920462",
    "cap": "14023"
  },
  {
    "key": "15198",
    "ITA": "TONEZZA DEL CIMONE",
    "state_code": "VI",
    "cab": "666404",
    "cap": "36040"
  },
  {
    "key": "15199",
    "ITA": "TORA E PICCILLI-TORA",
    "state_code": "CE",
    "cab": "949685",
    "cap": "81044"
  },
  {
    "key": "15200",
    "ITA": "TORA E PICCILLI-PICCILLI",
    "state_code": "CE",
    "cab": "949685",
    "cap": "81044"
  },
  {
    "key": "15201",
    "ITA": "TORA E PICCILLI",
    "state_code": "CE",
    "cab": "949685",
    "cap": "81044"
  },
  {
    "key": "15202",
    "ITA": "TORANO CASTELLO",
    "state_code": "CS",
    "cab": "810705",
    "cap": "87010"
  },
  {
    "key": "15203",
    "ITA": "TORANO CASTELLO-TORANO CASTELLO SCALO",
    "state_code": "CS",
    "cab": "810705",
    "cap": "87010"
  },
  {
    "key": "15204",
    "ITA": "TORANO CASTELLO-SARTANO",
    "state_code": "CS",
    "cab": "810705",
    "cap": "87010"
  },
  {
    "key": "15205",
    "ITA": "TORANO NUOVO",
    "state_code": "TE",
    "cab": "479303",
    "cap": "64010"
  },
  {
    "key": "15206",
    "ITA": "TORBOLE CASAGLIA",
    "state_code": "BS",
    "cab": "553008",
    "cap": "25030"
  },
  {
    "key": "15207",
    "ITA": "TORCEGNO",
    "state_code": "TN",
    "cab": "889501",
    "cap": "38050"
  },
  {
    "key": "15208",
    "ITA": "TORCHIARA",
    "state_code": "SA",
    "cab": "765206",
    "cap": "84076"
  },
  {
    "key": "15209",
    "ITA": "TORCHIARA-COPERSITO",
    "state_code": "SA",
    "cab": "765206",
    "cap": "84076"
  },
  {
    "key": "15210",
    "ITA": "TORCHIAROLO",
    "state_code": "BR",
    "cab": "792903",
    "cap": "72020"
  },
  {
    "key": "15211",
    "ITA": "TORELLA DEI LOMBARDI",
    "state_code": "AV",
    "cab": "897801",
    "cap": "83057"
  },
  {
    "key": "15212",
    "ITA": "TORELLA DEL SANNIO",
    "state_code": "CB",
    "cab": "911008",
    "cap": "86028"
  },
  {
    "key": "15213",
    "ITA": "TORGIANO-FORNACI",
    "state_code": "PG",
    "cab": "387100",
    "cap": "6089"
  },
  {
    "key": "15214",
    "ITA": "TORGIANO-PONTE NUOVO",
    "state_code": "PG",
    "cab": "387100",
    "cap": "6089"
  },
  {
    "key": "15215",
    "ITA": "TORGIANO-BRUFA",
    "state_code": "PG",
    "cab": "387100",
    "cap": "6089"
  },
  {
    "key": "15216",
    "ITA": "TORGIANO",
    "state_code": "PG",
    "cab": "387100",
    "cap": "6089"
  },
  {
    "key": "15217",
    "ITA": "TORGNON-MONGNOD",
    "state_code": "AO",
    "cab": "902676",
    "cap": "11020"
  },
  {
    "key": "15218",
    "ITA": "TORGNON",
    "state_code": "AO",
    "cab": "902676",
    "cap": "11020"
  },
  {
    "key": "15219",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10136"
  },
  {
    "key": "15220",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10155"
  },
  {
    "key": "15221",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10154"
  },
  {
    "key": "15222",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10121"
  },
  {
    "key": "15223",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10122"
  },
  {
    "key": "15224",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10123"
  },
  {
    "key": "15225",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10124"
  },
  {
    "key": "15226",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10125"
  },
  {
    "key": "15227",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10126"
  },
  {
    "key": "15228",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10127"
  },
  {
    "key": "15229",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10128"
  },
  {
    "key": "15230",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10129"
  },
  {
    "key": "15231",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10131"
  },
  {
    "key": "15232",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10132"
  },
  {
    "key": "15233",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10133"
  },
  {
    "key": "15234",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10134"
  },
  {
    "key": "15235",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10135"
  },
  {
    "key": "15236",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10156"
  },
  {
    "key": "15237",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10137"
  },
  {
    "key": "15238",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10138"
  },
  {
    "key": "15239",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10139"
  },
  {
    "key": "15240",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10141"
  },
  {
    "key": "15241",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10142"
  },
  {
    "key": "15242",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10143"
  },
  {
    "key": "15243",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10144"
  },
  {
    "key": "15244",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10145"
  },
  {
    "key": "15245",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10146"
  },
  {
    "key": "15246",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10147"
  },
  {
    "key": "15247",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10148"
  },
  {
    "key": "15248",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10149"
  },
  {
    "key": "15249",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10151"
  },
  {
    "key": "15250",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10152"
  },
  {
    "key": "15251",
    "ITA": "TORINO",
    "state_code": "TO",
    "cab": "10009",
    "cap": "10153"
  },
  {
    "key": "15252",
    "ITA": "TORINO DI SANGRO-TORINO DI SANGRO STAZIONE",
    "state_code": "CH",
    "cab": "857904",
    "cap": "66020"
  },
  {
    "key": "15253",
    "ITA": "TORINO DI SANGRO",
    "state_code": "CH",
    "cab": "857904",
    "cap": "66020"
  },
  {
    "key": "15254",
    "ITA": "TORITTO",
    "state_code": "BA",
    "cab": "417105",
    "cap": "70020"
  },
  {
    "key": "15255",
    "ITA": "TORLINO VIMERCATI",
    "state_code": "CR",
    "cab": "572404",
    "cap": "26017"
  },
  {
    "key": "15256",
    "ITA": "TORNACO",
    "state_code": "NO",
    "cab": "457002",
    "cap": "28070"
  },
  {
    "key": "15257",
    "ITA": "TORNARECCIO-SAN GIOVANNI",
    "state_code": "CH",
    "cab": "955054",
    "cap": "66046"
  },
  {
    "key": "15258",
    "ITA": "TORNARECCIO",
    "state_code": "CH",
    "cab": "955054",
    "cap": "66046"
  },
  {
    "key": "15259",
    "ITA": "TORNATA",
    "state_code": "CR",
    "cab": "931907",
    "cap": "26030"
  },
  {
    "key": "15260",
    "ITA": "TORNIMPARTE-VILLAGRANDE",
    "state_code": "AQ",
    "cab": "408906",
    "cap": "67049"
  },
  {
    "key": "15261",
    "ITA": "TORNIMPARTE",
    "state_code": "AQ",
    "cab": "408906",
    "cap": "67049"
  },
  {
    "key": "15262",
    "ITA": "TORNIMPARTE-ROCCA SANTO STEFANO",
    "state_code": "AQ",
    "cab": "408906",
    "cap": "67049"
  },
  {
    "key": "15263",
    "ITA": "TORNIMPARTE-SAN NICOLA",
    "state_code": "AQ",
    "cab": "408906",
    "cap": "67049"
  },
  {
    "key": "15264",
    "ITA": "TORNO",
    "state_code": "CO",
    "cab": "518902",
    "cap": "22020"
  },
  {
    "key": "15265",
    "ITA": "TORNOLO-CASALE DI TORNOLO",
    "state_code": "PR",
    "cab": "659706",
    "cap": "43059"
  },
  {
    "key": "15266",
    "ITA": "TORNOLO",
    "state_code": "PR",
    "cab": "659706",
    "cap": "43059"
  },
  {
    "key": "15267",
    "ITA": "TORNOLO-TARSOGNO",
    "state_code": "PR",
    "cab": "659706",
    "cap": "43059"
  },
  {
    "key": "15268",
    "ITA": "TORNOLO-SANTA MARIA DEL TARO",
    "state_code": "PR",
    "cab": "659706",
    "cap": "43059"
  },
  {
    "key": "15269",
    "ITA": "TORO",
    "state_code": "CB",
    "cab": "911016",
    "cap": "86018"
  },
  {
    "key": "15270",
    "ITA": "TORPE'-TALAVA'",
    "state_code": "NU",
    "cab": "870600",
    "cap": "8020"
  },
  {
    "key": "15271",
    "ITA": "TORPE'-CONCAS",
    "state_code": "NU",
    "cab": "870600",
    "cap": "8020"
  },
  {
    "key": "15272",
    "ITA": "TORPE'-BRUNELLA",
    "state_code": "NU",
    "cab": "870600",
    "cap": "8020"
  },
  {
    "key": "15273",
    "ITA": "TORPE'",
    "state_code": "NU",
    "cab": "870600",
    "cap": "8020"
  },
  {
    "key": "15274",
    "ITA": "TORPE'-SU COSSU",
    "state_code": "NU",
    "cab": "870600",
    "cap": "8020"
  },
  {
    "key": "15275",
    "ITA": "TORRACA",
    "state_code": "SA",
    "cab": "953158",
    "cap": "84030"
  },
  {
    "key": "15276",
    "ITA": "TORRALBA",
    "state_code": "SS",
    "cab": "876102",
    "cap": "7048"
  },
  {
    "key": "15277",
    "ITA": "TORRAZZA COSTE",
    "state_code": "PV",
    "cab": "563106",
    "cap": "27050"
  },
  {
    "key": "15278",
    "ITA": "TORRAZZA PIEMONTE",
    "state_code": "TO",
    "cab": "499301",
    "cap": "10037"
  },
  {
    "key": "15279",
    "ITA": "TORRAZZO",
    "state_code": "BI",
    "cab": "916437",
    "cap": "13884"
  },
  {
    "key": "15280",
    "ITA": "TORRE ANNUNZIATA",
    "state_code": "NA",
    "cab": "402909",
    "cap": "80058"
  },
  {
    "key": "15281",
    "ITA": "TORRE BERETTI E CASTELLARO",
    "state_code": "PV",
    "cab": "930933",
    "cap": "27030"
  },
  {
    "key": "15282",
    "ITA": "TORRE BERETTI E CASTELLARO-CASTELLARO DE' GIORGI",
    "state_code": "PV",
    "cab": "930933",
    "cap": "27030"
  },
  {
    "key": "15283",
    "ITA": "TORRE BOLDONE",
    "state_code": "BG",
    "cab": "536201",
    "cap": "24020"
  },
  {
    "key": "15284",
    "ITA": "TORRE BORMIDA",
    "state_code": "CN",
    "cab": "919472",
    "cap": "12050"
  },
  {
    "key": "15285",
    "ITA": "TORRE CAJETANI",
    "state_code": "FR",
    "cab": "387902",
    "cap": "3010"
  },
  {
    "key": "15286",
    "ITA": "TORRE CANAVESE",
    "state_code": "TO",
    "cab": "901645",
    "cap": "10010"
  },
  {
    "key": "15287",
    "ITA": "TORRE D'ARESE",
    "state_code": "PV",
    "cab": "930941",
    "cap": "27010"
  },
  {
    "key": "15288",
    "ITA": "TORRE D'ISOLA",
    "state_code": "PV",
    "cab": "930966",
    "cap": "27020"
  },
  {
    "key": "15289",
    "ITA": "TORRE D'ISOLA-MASSAUA",
    "state_code": "PV",
    "cab": "930966",
    "cap": "27020"
  },
  {
    "key": "15290",
    "ITA": "TORRE D'ISOLA-CASOTTOLE",
    "state_code": "PV",
    "cab": "930966",
    "cap": "27020"
  },
  {
    "key": "15291",
    "ITA": "TORRE DE' BUSI",
    "state_code": "LC",
    "cab": "928663",
    "cap": "23806"
  },
  {
    "key": "15292",
    "ITA": "TORRE DE' BUSI-FAVIRANO",
    "state_code": "LC",
    "cab": "928663",
    "cap": "23806"
  },
  {
    "key": "15293",
    "ITA": "TORRE DE' BUSI-SAN GOTTARDO",
    "state_code": "LC",
    "cab": "928663",
    "cap": "23806"
  },
  {
    "key": "15294",
    "ITA": "TORRE DE' BUSI-VALCAVA",
    "state_code": "LC",
    "cab": "928663",
    "cap": "23806"
  },
  {
    "key": "15295",
    "ITA": "TORRE DE' NEGRI",
    "state_code": "PV",
    "cab": "930958",
    "cap": "27011"
  },
  {
    "key": "15296",
    "ITA": "TORRE DE' PASSERI",
    "state_code": "PE",
    "cab": "774505",
    "cap": "65029"
  },
  {
    "key": "15297",
    "ITA": "TORRE DE' PICENARDI-SAN LORENZO DE' PICENARDI",
    "state_code": "CR",
    "cab": "572503",
    "cap": "26038"
  },
  {
    "key": "15298",
    "ITA": "TORRE DE' PICENARDI",
    "state_code": "CR",
    "cab": "572503",
    "cap": "26038"
  },
  {
    "key": "15299",
    "ITA": "TORRE DE' ROVERI",
    "state_code": "BG",
    "cab": "832709",
    "cap": "24060"
  },
  {
    "key": "15300",
    "ITA": "TORRE DEL GRECO",
    "state_code": "NA",
    "cab": "403006",
    "cap": "80059"
  },
  {
    "key": "15301",
    "ITA": "TORRE DEL GRECO-SANTA MARIA LA BRUNA",
    "state_code": "NA",
    "cab": "403006",
    "cap": "80059"
  },
  {
    "key": "15302",
    "ITA": "TORRE DEL GRECO-LEOPARDI",
    "state_code": "NA",
    "cab": "403006",
    "cap": "80059"
  },
  {
    "key": "15303",
    "ITA": "TORRE DI MOSTO",
    "state_code": "VE",
    "cab": "363606",
    "cap": "30020"
  },
  {
    "key": "15304",
    "ITA": "TORRE DI RUGGIERO",
    "state_code": "CZ",
    "cab": "913657",
    "cap": "88060"
  },
  {
    "key": "15305",
    "ITA": "TORRE DI SANTA MARIA",
    "state_code": "SO",
    "cab": "927319",
    "cap": "23020"
  },
  {
    "key": "15306",
    "ITA": "TORRE LE NOCELLE",
    "state_code": "AV",
    "cab": "951780",
    "cap": "83030"
  },
  {
    "key": "15307",
    "ITA": "TORRE MONDOVI'",
    "state_code": "CN",
    "cab": "919480",
    "cap": "12080"
  },
  {
    "key": "15308",
    "ITA": "TORRE ORSAIA",
    "state_code": "SA",
    "cab": "565804",
    "cap": "84077"
  },
  {
    "key": "15309",
    "ITA": "TORRE ORSAIA-CASTEL RUGGERO",
    "state_code": "SA",
    "cab": "565804",
    "cap": "84077"
  },
  {
    "key": "15310",
    "ITA": "TORRE PALLAVICINA",
    "state_code": "BG",
    "cab": "687905",
    "cap": "24050"
  },
  {
    "key": "15311",
    "ITA": "TORRE PELLICE",
    "state_code": "TO",
    "cab": "310706",
    "cap": "10066"
  },
  {
    "key": "15312",
    "ITA": "TORRE SAN GIORGIO",
    "state_code": "CN",
    "cab": "451005",
    "cap": "12030"
  },
  {
    "key": "15313",
    "ITA": "TORRE SAN PATRIZIO",
    "state_code": "FM",
    "cab": "697508",
    "cap": "63010"
  },
  {
    "key": "15314",
    "ITA": "TORRE SANTA SUSANNA",
    "state_code": "BR",
    "cab": "793000",
    "cap": "72028"
  },
  {
    "key": "15315",
    "ITA": "TORREANO",
    "state_code": "UD",
    "cab": "894600",
    "cap": "33040"
  },
  {
    "key": "15316",
    "ITA": "TORREBELVICINO",
    "state_code": "VI",
    "cab": "608802",
    "cap": "36036"
  },
  {
    "key": "15317",
    "ITA": "TORREBELVICINO-PIEVEBELVICINO",
    "state_code": "VI",
    "cab": "608802",
    "cap": "36036"
  },
  {
    "key": "15318",
    "ITA": "TORREBRUNA-GUARDIABRUNA",
    "state_code": "CH",
    "cab": "955062",
    "cap": "66050"
  },
  {
    "key": "15319",
    "ITA": "TORREBRUNA",
    "state_code": "CH",
    "cab": "955062",
    "cap": "66050"
  },
  {
    "key": "15320",
    "ITA": "TORRECUSO",
    "state_code": "BN",
    "cab": "897900",
    "cap": "82030"
  },
  {
    "key": "15321",
    "ITA": "TORREGLIA",
    "state_code": "PD",
    "cab": "630806",
    "cap": "35038"
  },
  {
    "key": "15322",
    "ITA": "TORREGROTTA",
    "state_code": "ME",
    "cab": "826008",
    "cap": "98040"
  },
  {
    "key": "15323",
    "ITA": "TORREGROTTA-SCALA DI TORREGROTTA",
    "state_code": "ME",
    "cab": "826008",
    "cap": "98040"
  },
  {
    "key": "15324",
    "ITA": "TORREMAGGIORE-PETRULLI",
    "state_code": "FG",
    "cab": "786707",
    "cap": "71017"
  },
  {
    "key": "15325",
    "ITA": "TORREMAGGIORE",
    "state_code": "FG",
    "cab": "786707",
    "cap": "71017"
  },
  {
    "key": "15326",
    "ITA": "TORRENOVA",
    "state_code": "ME",
    "cab": "826701",
    "cap": "98070"
  },
  {
    "key": "15327",
    "ITA": "TORRESINA",
    "state_code": "CN",
    "cab": "919506",
    "cap": "12070"
  },
  {
    "key": "15328",
    "ITA": "TORRETTA",
    "state_code": "PA",
    "cab": "436600",
    "cap": "90040"
  },
  {
    "key": "15329",
    "ITA": "TORREVECCHIA PIA",
    "state_code": "PV",
    "cab": "563205",
    "cap": "27010"
  },
  {
    "key": "15330",
    "ITA": "TORREVECCHIA PIA-VIGONZONE",
    "state_code": "PV",
    "cab": "563205",
    "cap": "27010"
  },
  {
    "key": "15331",
    "ITA": "TORREVECCHIA TEATINA",
    "state_code": "CH",
    "cab": "365106",
    "cap": "66010"
  },
  {
    "key": "15332",
    "ITA": "TORREVECCHIA TEATINA-CASTELFERRATO",
    "state_code": "CH",
    "cab": "365106",
    "cap": "66010"
  },
  {
    "key": "15333",
    "ITA": "TORRI DEL BENACO",
    "state_code": "VR",
    "cab": "599001",
    "cap": "37010"
  },
  {
    "key": "15334",
    "ITA": "TORRI DI QUARTESOLO-MAROLA",
    "state_code": "VI",
    "cab": "608000",
    "cap": "36040"
  },
  {
    "key": "15335",
    "ITA": "TORRI DI QUARTESOLO",
    "state_code": "VI",
    "cab": "608000",
    "cap": "36040"
  },
  {
    "key": "15336",
    "ITA": "TORRI DI QUARTESOLO-LERINO",
    "state_code": "VI",
    "cab": "608000",
    "cap": "36040"
  },
  {
    "key": "15337",
    "ITA": "TORRI IN SABINA",
    "state_code": "RI",
    "cab": "738005",
    "cap": "2049"
  },
  {
    "key": "15338",
    "ITA": "TORRIANA",
    "state_code": "RN",
    "cab": "680702",
    "cap": "47825"
  },
  {
    "key": "15339",
    "ITA": "TORRICE",
    "state_code": "FR",
    "cab": "897009",
    "cap": "3020"
  },
  {
    "key": "15340",
    "ITA": "TORRICELLA",
    "state_code": "TA",
    "cab": "790501",
    "cap": "74020"
  },
  {
    "key": "15341",
    "ITA": "TORRICELLA DEL PIZZO",
    "state_code": "CR",
    "cab": "931915",
    "cap": "26040"
  },
  {
    "key": "15342",
    "ITA": "TORRICELLA IN SABINA",
    "state_code": "RI",
    "cab": "947432",
    "cap": "2030"
  },
  {
    "key": "15343",
    "ITA": "TORRICELLA PELIGNA",
    "state_code": "CH",
    "cab": "779009",
    "cap": "66019"
  },
  {
    "key": "15344",
    "ITA": "TORRICELLA PELIGNA-FALLASCOSO",
    "state_code": "CH",
    "cab": "779009",
    "cap": "66019"
  },
  {
    "key": "15345",
    "ITA": "TORRICELLA SICURA-IOANELLA",
    "state_code": "TE",
    "cab": "770602",
    "cap": "64010"
  },
  {
    "key": "15346",
    "ITA": "TORRICELLA SICURA-SANTO STEFANO",
    "state_code": "TE",
    "cab": "770602",
    "cap": "64010"
  },
  {
    "key": "15347",
    "ITA": "TORRICELLA SICURA",
    "state_code": "TE",
    "cab": "770602",
    "cap": "64010"
  },
  {
    "key": "15348",
    "ITA": "TORRICELLA VERZATE",
    "state_code": "PV",
    "cab": "930974",
    "cap": "27050"
  },
  {
    "key": "15349",
    "ITA": "TORRIGLIA",
    "state_code": "GE",
    "cab": "322602",
    "cap": "16029"
  },
  {
    "key": "15350",
    "ITA": "TORRIGLIA-FASCIA DI CARLO",
    "state_code": "GE",
    "cab": "322602",
    "cap": "16029"
  },
  {
    "key": "15351",
    "ITA": "TORRIGLIA-LACCIO",
    "state_code": "GE",
    "cab": "322602",
    "cap": "16029"
  },
  {
    "key": "15352",
    "ITA": "TORRILE-SAN POLO",
    "state_code": "PR",
    "cab": "659805",
    "cap": "43056"
  },
  {
    "key": "15353",
    "ITA": "TORRILE",
    "state_code": "PR",
    "cab": "659805",
    "cap": "43056"
  },
  {
    "key": "15354",
    "ITA": "TORRIONI",
    "state_code": "AV",
    "cab": "951798",
    "cap": "83010"
  },
  {
    "key": "15355",
    "ITA": "TORRITA DI SIENA-TORRITA STAZIONE",
    "state_code": "SI",
    "cab": "720607",
    "cap": "53049"
  },
  {
    "key": "15356",
    "ITA": "TORRITA DI SIENA-MONTEFOLLONICO",
    "state_code": "SI",
    "cab": "720607",
    "cap": "53049"
  },
  {
    "key": "15357",
    "ITA": "TORRITA DI SIENA",
    "state_code": "SI",
    "cab": "720607",
    "cap": "53049"
  },
  {
    "key": "15358",
    "ITA": "TORRITA TIBERINA",
    "state_code": "RM",
    "cab": "908400",
    "cap": "60"
  },
  {
    "key": "15359",
    "ITA": "TORTOLI'",
    "state_code": "OG",
    "cab": "853903",
    "cap": "8048"
  },
  {
    "key": "15360",
    "ITA": "TORTOLI'-ARBATAX",
    "state_code": "OG",
    "cab": "853903",
    "cap": "8048"
  },
  {
    "key": "15361",
    "ITA": "TORTOLI'-CALAMORESCA",
    "state_code": "OG",
    "cab": "853903",
    "cap": "8048"
  },
  {
    "key": "15362",
    "ITA": "TORTOLI'-IS MURDEGUS",
    "state_code": "OG",
    "cab": "853903",
    "cap": "8048"
  },
  {
    "key": "15363",
    "ITA": "TORTOLI'-PORTO FRAILIS",
    "state_code": "OG",
    "cab": "853903",
    "cap": "8048"
  },
  {
    "key": "15364",
    "ITA": "TORTOLI'-SU PINU",
    "state_code": "OG",
    "cab": "853903",
    "cap": "8048"
  },
  {
    "key": "15365",
    "ITA": "TORTOLI'-SU TROCCU",
    "state_code": "OG",
    "cab": "853903",
    "cap": "8048"
  },
  {
    "key": "15366",
    "ITA": "TORTONA-TORRE GAROFOLI",
    "state_code": "AL",
    "cab": "486704",
    "cap": "15057"
  },
  {
    "key": "15367",
    "ITA": "TORTONA-PASSALACQUA",
    "state_code": "AL",
    "cab": "486704",
    "cap": "15057"
  },
  {
    "key": "15368",
    "ITA": "TORTONA",
    "state_code": "AL",
    "cab": "486704",
    "cap": "15057"
  },
  {
    "key": "15369",
    "ITA": "TORTONA-CASTELLAR PONZANO",
    "state_code": "AL",
    "cab": "486704",
    "cap": "15057"
  },
  {
    "key": "15370",
    "ITA": "TORTONA-RIVALTA SCRIVIA",
    "state_code": "AL",
    "cab": "486704",
    "cap": "15057"
  },
  {
    "key": "15371",
    "ITA": "TORTORA-TORTORA MARINA",
    "state_code": "CS",
    "cab": "959478",
    "cap": "87020"
  },
  {
    "key": "15372",
    "ITA": "TORTORA",
    "state_code": "CS",
    "cab": "959478",
    "cap": "87020"
  },
  {
    "key": "15373",
    "ITA": "TORTORELLA",
    "state_code": "SA",
    "cab": "953174",
    "cap": "84030"
  },
  {
    "key": "15374",
    "ITA": "TORTORETO-TORTORETO LIDO",
    "state_code": "TE",
    "cab": "770701",
    "cap": "64018"
  },
  {
    "key": "15375",
    "ITA": "TORTORETO",
    "state_code": "TE",
    "cab": "770701",
    "cap": "64018"
  },
  {
    "key": "15376",
    "ITA": "TORTORETO-SALINO",
    "state_code": "TE",
    "cab": "770701",
    "cap": "64018"
  },
  {
    "key": "15377",
    "ITA": "TORTORICI-SANT'ANTONINO SCIORTINO",
    "state_code": "ME",
    "cab": "826107",
    "cap": "98078"
  },
  {
    "key": "15378",
    "ITA": "TORTORICI-SCETI",
    "state_code": "ME",
    "cab": "826107",
    "cap": "98078"
  },
  {
    "key": "15379",
    "ITA": "TORTORICI-MOIRA",
    "state_code": "ME",
    "cab": "826107",
    "cap": "98078"
  },
  {
    "key": "15380",
    "ITA": "TORTORICI-ILOMBATI",
    "state_code": "ME",
    "cab": "826107",
    "cap": "98078"
  },
  {
    "key": "15381",
    "ITA": "TORTORICI",
    "state_code": "ME",
    "cab": "826107",
    "cap": "98078"
  },
  {
    "key": "15382",
    "ITA": "TORTORICI-GRAZIA",
    "state_code": "ME",
    "cab": "826107",
    "cap": "98078"
  },
  {
    "key": "15383",
    "ITA": "TORVISCOSA-MALISANA",
    "state_code": "UD",
    "cab": "643304",
    "cap": "33050"
  },
  {
    "key": "15384",
    "ITA": "TORVISCOSA",
    "state_code": "UD",
    "cab": "643304",
    "cap": "33050"
  },
  {
    "key": "15385",
    "ITA": "TOSCOLANO MADERNO-MADERNO",
    "state_code": "BS",
    "cab": "553107",
    "cap": "25088"
  },
  {
    "key": "15386",
    "ITA": "TOSCOLANO MADERNO",
    "state_code": "BS",
    "cab": "553107",
    "cap": "25088"
  },
  {
    "key": "15387",
    "ITA": "TOSSICIA-CHIARINO",
    "state_code": "TE",
    "cab": "770800",
    "cap": "64049"
  },
  {
    "key": "15388",
    "ITA": "TOSSICIA-AZZINANO",
    "state_code": "TE",
    "cab": "770800",
    "cap": "64049"
  },
  {
    "key": "15389",
    "ITA": "TOSSICIA",
    "state_code": "TE",
    "cab": "770800",
    "cap": "64049"
  },
  {
    "key": "15390",
    "ITA": "TOVO DI SANT'AGATA",
    "state_code": "SO",
    "cab": "927327",
    "cap": "23030"
  },
  {
    "key": "15391",
    "ITA": "TOVO SAN GIACOMO-BARDINO NUOVO",
    "state_code": "SV",
    "cab": "180950",
    "cap": "17020"
  },
  {
    "key": "15392",
    "ITA": "TOVO SAN GIACOMO-BARDINO VECCHIO",
    "state_code": "SV",
    "cab": "180950",
    "cap": "17020"
  },
  {
    "key": "15393",
    "ITA": "TOVO SAN GIACOMO",
    "state_code": "SV",
    "cab": "180950",
    "cap": "17020"
  },
  {
    "key": "15394",
    "ITA": "TRABIA",
    "state_code": "PA",
    "cab": "436709",
    "cap": "90019"
  },
  {
    "key": "15395",
    "ITA": "TRABIA-SAN NICOLO' L'ARENA",
    "state_code": "PA",
    "cab": "436709",
    "cap": "90019"
  },
  {
    "key": "15396",
    "ITA": "TRADATE-ABBIATE GUAZZONE",
    "state_code": "VA",
    "cab": "505800",
    "cap": "21049"
  },
  {
    "key": "15397",
    "ITA": "TRADATE",
    "state_code": "VA",
    "cab": "505800",
    "cap": "21049"
  },
  {
    "key": "15398",
    "ITA": "TRAMATZA",
    "state_code": "OR",
    "cab": "881300",
    "cap": "9070"
  },
  {
    "key": "15399",
    "ITA": "TRAMBILENO",
    "state_code": "TN",
    "cab": "358606",
    "cap": "38068"
  },
  {
    "key": "15400",
    "ITA": "TRAMONTI-CAMPINOLA",
    "state_code": "SA",
    "cab": "703009",
    "cap": "84010"
  },
  {
    "key": "15401",
    "ITA": "TRAMONTI",
    "state_code": "SA",
    "cab": "703009",
    "cap": "84010"
  },
  {
    "key": "15402",
    "ITA": "TRAMONTI DI SOPRA-CHIEVOLIS",
    "state_code": "PN",
    "cab": "938258",
    "cap": "33090"
  },
  {
    "key": "15403",
    "ITA": "TRAMONTI DI SOPRA",
    "state_code": "PN",
    "cab": "938258",
    "cap": "33090"
  },
  {
    "key": "15404",
    "ITA": "TRAMONTI DI SOTTO",
    "state_code": "PN",
    "cab": "938266",
    "cap": "33090"
  },
  {
    "key": "15405",
    "ITA": "TRAMONTI DI SOTTO-CAMPONE",
    "state_code": "PN",
    "cab": "938266",
    "cap": "33090"
  },
  {
    "key": "15406",
    "ITA": "TRAMUTOLA",
    "state_code": "PZ",
    "cab": "423004",
    "cap": "85057"
  },
  {
    "key": "15407",
    "ITA": "TRANA",
    "state_code": "TO",
    "cab": "412908",
    "cap": "10090"
  },
  {
    "key": "15408",
    "ITA": "TRANA-SAN BERNARDINO",
    "state_code": "TO",
    "cab": "412908",
    "cap": "10090"
  },
  {
    "key": "15409",
    "ITA": "TRANI",
    "state_code": "BT",
    "cab": "417204",
    "cap": "70059"
  },
  {
    "key": "15410",
    "ITA": "TRANSACQUA",
    "state_code": "TN",
    "cab": "356808",
    "cap": "38054"
  },
  {
    "key": "15411",
    "ITA": "TRAONA",
    "state_code": "SO",
    "cab": "524009",
    "cap": "23019"
  },
  {
    "key": "15412",
    "ITA": "TRAPANI-FULGATORE",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91010"
  },
  {
    "key": "15413",
    "ITA": "TRAPANI-GUARRATO",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15414",
    "ITA": "TRAPANI-LOCOGRANDE",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15415",
    "ITA": "TRAPANI-MARAUSA",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15416",
    "ITA": "TRAPANI-RILIEVO",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15417",
    "ITA": "TRAPANI-SALINAGRANDE",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15418",
    "ITA": "TRAPANI-UMMARI",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91010"
  },
  {
    "key": "15419",
    "ITA": "TRAPANI-XITTA",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15420",
    "ITA": "TRAPANI-FONTANELLE CASASANTA",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15421",
    "ITA": "TRAPANI-BORGO FAZIO",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91010"
  },
  {
    "key": "15422",
    "ITA": "TRAPANI-BORGO ANNUNZIATA",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15423",
    "ITA": "TRAPANI-BIRGI AEROSTAZIONE",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15424",
    "ITA": "TRAPANI",
    "state_code": "TP",
    "cab": "164004",
    "cap": "91100"
  },
  {
    "key": "15425",
    "ITA": "TRAPPETO",
    "state_code": "PA",
    "cab": "436808",
    "cap": "90040"
  },
  {
    "key": "15426",
    "ITA": "TRAREGO VIGGIONA",
    "state_code": "VB",
    "cab": "917757",
    "cap": "28826"
  },
  {
    "key": "15427",
    "ITA": "TRASACCO",
    "state_code": "AQ",
    "cab": "408203",
    "cap": "67059"
  },
  {
    "key": "15428",
    "ITA": "TRASAGHIS-PEONIS",
    "state_code": "UD",
    "cab": "643403",
    "cap": "33010"
  },
  {
    "key": "15429",
    "ITA": "TRASAGHIS-BRAULINS",
    "state_code": "UD",
    "cab": "643403",
    "cap": "33010"
  },
  {
    "key": "15430",
    "ITA": "TRASAGHIS-AVASINIS",
    "state_code": "UD",
    "cab": "643403",
    "cap": "33010"
  },
  {
    "key": "15431",
    "ITA": "TRASAGHIS-ALESSO",
    "state_code": "UD",
    "cab": "643403",
    "cap": "33010"
  },
  {
    "key": "15432",
    "ITA": "TRASAGHIS",
    "state_code": "UD",
    "cab": "643403",
    "cap": "33010"
  },
  {
    "key": "15433",
    "ITA": "TRASQUERA",
    "state_code": "VB",
    "cab": "917765",
    "cap": "28868"
  },
  {
    "key": "15434",
    "ITA": "TRASQUERA-ISELLE",
    "state_code": "VB",
    "cab": "917765",
    "cap": "28868"
  },
  {
    "key": "15435",
    "ITA": "TRATALIAS",
    "state_code": "SU",
    "cab": "863209",
    "cap": "9010"
  },
  {
    "key": "15436",
    "ITA": "TRAUSELLA",
    "state_code": "TO",
    "cab": "901660",
    "cap": "10080"
  },
  {
    "key": "15437",
    "ITA": "TRAVACO' SICCOMARIO-ROTTA",
    "state_code": "PV",
    "cab": "563304",
    "cap": "27020"
  },
  {
    "key": "15438",
    "ITA": "TRAVACO' SICCOMARIO",
    "state_code": "PV",
    "cab": "563304",
    "cap": "27020"
  },
  {
    "key": "15439",
    "ITA": "TRAVAGLIATO",
    "state_code": "BS",
    "cab": "553206",
    "cap": "25039"
  },
  {
    "key": "15440",
    "ITA": "TRAVEDONA MONATE",
    "state_code": "VA",
    "cab": "505909",
    "cap": "21028"
  },
  {
    "key": "15441",
    "ITA": "TRAVERSELLA",
    "state_code": "TO",
    "cab": "901678",
    "cap": "10080"
  },
  {
    "key": "15442",
    "ITA": "TRAVERSETOLO-MAMIANO",
    "state_code": "PR",
    "cab": "659904",
    "cap": "43029"
  },
  {
    "key": "15443",
    "ITA": "TRAVERSETOLO-CASTIONE DE' BARATTI",
    "state_code": "PR",
    "cab": "659904",
    "cap": "43029"
  },
  {
    "key": "15444",
    "ITA": "TRAVERSETOLO",
    "state_code": "PR",
    "cab": "659904",
    "cap": "43029"
  },
  {
    "key": "15445",
    "ITA": "TRAVERSETOLO-VIGNALE",
    "state_code": "PR",
    "cab": "659904",
    "cap": "43029"
  },
  {
    "key": "15446",
    "ITA": "TRAVES",
    "state_code": "TO",
    "cab": "901686",
    "cap": "10070"
  },
  {
    "key": "15447",
    "ITA": "TRAVES-LA VILLA",
    "state_code": "TO",
    "cab": "901686",
    "cap": "10070"
  },
  {
    "key": "15448",
    "ITA": "TRAVESIO",
    "state_code": "PN",
    "cab": "650408",
    "cap": "33090"
  },
  {
    "key": "15449",
    "ITA": "TRAVESIO-TOPPO",
    "state_code": "PN",
    "cab": "650408",
    "cap": "33090"
  },
  {
    "key": "15450",
    "ITA": "TRAVESIO-USAGO",
    "state_code": "PN",
    "cab": "650408",
    "cap": "33090"
  },
  {
    "key": "15451",
    "ITA": "TRAVO",
    "state_code": "PC",
    "cab": "654806",
    "cap": "29020"
  },
  {
    "key": "15452",
    "ITA": "TRAVO-QUADRELLI",
    "state_code": "PC",
    "cab": "654806",
    "cap": "29020"
  },
  {
    "key": "15453",
    "ITA": "TREBASELEGHE-SILVELLE",
    "state_code": "PD",
    "cab": "629402",
    "cap": "35010"
  },
  {
    "key": "15454",
    "ITA": "TREBASELEGHE-SANT'AMBROGIO",
    "state_code": "PD",
    "cab": "629402",
    "cap": "35010"
  },
  {
    "key": "15455",
    "ITA": "TREBASELEGHE-FOSSALTA",
    "state_code": "PD",
    "cab": "629402",
    "cap": "35010"
  },
  {
    "key": "15456",
    "ITA": "TREBASELEGHE",
    "state_code": "PD",
    "cab": "629402",
    "cap": "35010"
  },
  {
    "key": "15457",
    "ITA": "TREBISACCE",
    "state_code": "CS",
    "cab": "810804",
    "cap": "87075"
  },
  {
    "key": "15458",
    "ITA": "TRECASALI",
    "state_code": "PR",
    "cab": "660001",
    "cap": "43010"
  },
  {
    "key": "15459",
    "ITA": "TRECASALI-SAN QUIRICO TRECASALI",
    "state_code": "PR",
    "cab": "660001",
    "cap": "43010"
  },
  {
    "key": "15460",
    "ITA": "TRECASALI-VIAROLO",
    "state_code": "PR",
    "cab": "660001",
    "cap": "43010"
  },
  {
    "key": "15461",
    "ITA": "TRECASALI-RONCO CAMPO CANNETO",
    "state_code": "PR",
    "cab": "660001",
    "cap": "43010"
  },
  {
    "key": "15462",
    "ITA": "TRECASE",
    "state_code": "NA",
    "cab": "365502",
    "cap": "80040"
  },
  {
    "key": "15463",
    "ITA": "TRECASTAGNI",
    "state_code": "CT",
    "cab": "842807",
    "cap": "95039"
  },
  {
    "key": "15464",
    "ITA": "TRECASTELLI",
    "state_code": "AN",
    "cab": "375402",
    "cap": "60012"
  },
  {
    "key": "15465",
    "ITA": "TRECATE",
    "state_code": "NO",
    "cab": "457101",
    "cap": "28069"
  },
  {
    "key": "15466",
    "ITA": "TRECCHINA-PIANO DEI PERI",
    "state_code": "PZ",
    "cab": "491407",
    "cap": "85049"
  },
  {
    "key": "15467",
    "ITA": "TRECCHINA",
    "state_code": "PZ",
    "cab": "491407",
    "cap": "85049"
  },
  {
    "key": "15468",
    "ITA": "TRECENTA-SARIANO",
    "state_code": "RO",
    "cab": "634808",
    "cap": "45027"
  },
  {
    "key": "15469",
    "ITA": "TRECENTA-PISSATOLA",
    "state_code": "RO",
    "cab": "634808",
    "cap": "45027"
  },
  {
    "key": "15470",
    "ITA": "TRECENTA",
    "state_code": "RO",
    "cab": "634808",
    "cap": "45027"
  },
  {
    "key": "15471",
    "ITA": "TREDOZIO",
    "state_code": "FC",
    "cab": "680801",
    "cap": "47019"
  },
  {
    "key": "15472",
    "ITA": "TREGLIO",
    "state_code": "CH",
    "cab": "955088",
    "cap": "66030"
  },
  {
    "key": "15473",
    "ITA": "TREGNAGO-COGOLLO",
    "state_code": "VR",
    "cab": "599100",
    "cap": "37039"
  },
  {
    "key": "15474",
    "ITA": "TREGNAGO",
    "state_code": "VR",
    "cab": "599100",
    "cap": "37039"
  },
  {
    "key": "15475",
    "ITA": "TREGNAGO-CENTRO",
    "state_code": "VR",
    "cab": "599100",
    "cap": "37039"
  },
  {
    "key": "15476",
    "ITA": "TREIA-CHIESANUOVA",
    "state_code": "MC",
    "cab": "692103",
    "cap": "62010"
  },
  {
    "key": "15477",
    "ITA": "TREIA-SANTA MARIA IN SELVA",
    "state_code": "MC",
    "cab": "692103",
    "cap": "62010"
  },
  {
    "key": "15478",
    "ITA": "TREIA",
    "state_code": "MC",
    "cab": "692103",
    "cap": "62010"
  },
  {
    "key": "15479",
    "ITA": "TREIA-PASSO DI TREIA",
    "state_code": "MC",
    "cab": "692103",
    "cap": "62010"
  },
  {
    "key": "15480",
    "ITA": "TREISO",
    "state_code": "CN",
    "cab": "844001",
    "cap": "12050"
  },
  {
    "key": "15481",
    "ITA": "TREMENICO",
    "state_code": "LC",
    "cab": "926337",
    "cap": "23836"
  },
  {
    "key": "15482",
    "ITA": "TREMESTIERI ETNEO-CANALICCHIO",
    "state_code": "CT",
    "cab": "842906",
    "cap": "95030"
  },
  {
    "key": "15483",
    "ITA": "TREMESTIERI ETNEO",
    "state_code": "CT",
    "cab": "842906",
    "cap": "95030"
  },
  {
    "key": "15484",
    "ITA": "TREMEZZO",
    "state_code": "CO",
    "cab": "518803",
    "cap": "22019"
  },
  {
    "key": "15485",
    "ITA": "TREMOSINE-VESIO",
    "state_code": "BS",
    "cab": "553305",
    "cap": "25010"
  },
  {
    "key": "15486",
    "ITA": "TREMOSINE",
    "state_code": "BS",
    "cab": "553305",
    "cap": "25010"
  },
  {
    "key": "15487",
    "ITA": "TREMOSINE-CAMPIONE",
    "state_code": "BS",
    "cab": "553305",
    "cap": "25010"
  },
  {
    "key": "15488",
    "ITA": "TRENTA-MORELLI",
    "state_code": "CS",
    "cab": "959486",
    "cap": "87050"
  },
  {
    "key": "15489",
    "ITA": "TRENTA",
    "state_code": "CS",
    "cab": "959486",
    "cap": "87050"
  },
  {
    "key": "15490",
    "ITA": "TRENTA-MAGLI",
    "state_code": "CS",
    "cab": "959486",
    "cap": "87050"
  },
  {
    "key": "15491",
    "ITA": "TRENTINARA",
    "state_code": "SA",
    "cab": "953190",
    "cap": "84070"
  },
  {
    "key": "15492",
    "ITA": "TRENTO-VANEZE",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15493",
    "ITA": "TRENTO-VALSORDA",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15494",
    "ITA": "TRENTO-SOPRAMONTE",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15495",
    "ITA": "TRENTO-SARDAGNA",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15496",
    "ITA": "TRENTO-ROMAGNANO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38060"
  },
  {
    "key": "15497",
    "ITA": "TRENTO-RAVINA",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38040"
  },
  {
    "key": "15498",
    "ITA": "TRENTO-POVO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15499",
    "ITA": "TRENTO-MONTEVACCINO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15500",
    "ITA": "TRENTO-MEANO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15501",
    "ITA": "TRENTO-MATTARELLO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15502",
    "ITA": "TRENTO-MARTIGNANO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15503",
    "ITA": "TRENTO-GARDOLO DI MEZZO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15504",
    "ITA": "TRENTO-COGNOLA",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15505",
    "ITA": "TRENTO-CADINE",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15506",
    "ITA": "TRENTO-BASELGA DEL BONDONE",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15507",
    "ITA": "TRENTO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15508",
    "ITA": "TRENTO-VILLAZZANO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15509",
    "ITA": "TRENTO-VILLAMONTAGNA",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15510",
    "ITA": "TRENTO-VIGOLO BASELGA",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15511",
    "ITA": "TRENTO-VELA",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15512",
    "ITA": "TRENTO-VIGO MEANO",
    "state_code": "TN",
    "cab": "18002",
    "cap": "38100"
  },
  {
    "key": "15513",
    "ITA": "TRENTOLA DUCENTA",
    "state_code": "CE",
    "cab": "885608",
    "cap": "81038"
  },
  {
    "key": "15514",
    "ITA": "TRENZANO-COSSIRANO",
    "state_code": "BS",
    "cab": "553404",
    "cap": "25030"
  },
  {
    "key": "15515",
    "ITA": "TRENZANO",
    "state_code": "BS",
    "cab": "553404",
    "cap": "25030"
  },
  {
    "key": "15516",
    "ITA": "TREPPO CARNICO",
    "state_code": "UD",
    "cab": "937292",
    "cap": "33020"
  },
  {
    "key": "15517",
    "ITA": "TREPPO GRANDE-CARVACCO",
    "state_code": "UD",
    "cab": "643502",
    "cap": "33010"
  },
  {
    "key": "15518",
    "ITA": "TREPPO GRANDE",
    "state_code": "UD",
    "cab": "643502",
    "cap": "33010"
  },
  {
    "key": "15519",
    "ITA": "TREPPO GRANDE-VENDOGLIO",
    "state_code": "UD",
    "cab": "643502",
    "cap": "33010"
  },
  {
    "key": "15520",
    "ITA": "TREPUZZI",
    "state_code": "LE",
    "cab": "801001",
    "cap": "73019"
  },
  {
    "key": "15521",
    "ITA": "TREQUANDA",
    "state_code": "SI",
    "cab": "720706",
    "cap": "53020"
  },
  {
    "key": "15522",
    "ITA": "TREQUANDA-CASTELMUZIO",
    "state_code": "SI",
    "cab": "720706",
    "cap": "53020"
  },
  {
    "key": "15523",
    "ITA": "TREQUANDA-PETROIO",
    "state_code": "SI",
    "cab": "720706",
    "cap": "53020"
  },
  {
    "key": "15524",
    "ITA": "TRES",
    "state_code": "TN",
    "cab": "356907",
    "cap": "38010"
  },
  {
    "key": "15525",
    "ITA": "TRESANA-BARBARASCO",
    "state_code": "MS",
    "cab": "699702",
    "cap": "54012"
  },
  {
    "key": "15526",
    "ITA": "TRESANA-VILLA DI TRESANA",
    "state_code": "MS",
    "cab": "699702",
    "cap": "54012"
  },
  {
    "key": "15527",
    "ITA": "TRESANA",
    "state_code": "MS",
    "cab": "699702",
    "cap": "54012"
  },
  {
    "key": "15528",
    "ITA": "TRESCORE BALNEARIO",
    "state_code": "BG",
    "cab": "536300",
    "cap": "24069"
  },
  {
    "key": "15529",
    "ITA": "TRESCORE CREMASCO",
    "state_code": "CR",
    "cab": "572602",
    "cap": "26017"
  },
  {
    "key": "15530",
    "ITA": "TRESIGALLO",
    "state_code": "FE",
    "cab": "673707",
    "cap": "44039"
  },
  {
    "key": "15531",
    "ITA": "TRESIGALLO-RERO",
    "state_code": "FE",
    "cab": "673707",
    "cap": "44039"
  },
  {
    "key": "15532",
    "ITA": "TRESIGALLO-FINAL DI RERO",
    "state_code": "FE",
    "cab": "673707",
    "cap": "44039"
  },
  {
    "key": "15533",
    "ITA": "TRESIVIO-PRASOMASO",
    "state_code": "SO",
    "cab": "523803",
    "cap": "23020"
  },
  {
    "key": "15534",
    "ITA": "TRESIVIO",
    "state_code": "SO",
    "cab": "523803",
    "cap": "23020"
  },
  {
    "key": "15535",
    "ITA": "TRESNURAGHES",
    "state_code": "OR",
    "cab": "881409",
    "cap": "9079"
  },
  {
    "key": "15536",
    "ITA": "TREVENZUOLO",
    "state_code": "VR",
    "cab": "599209",
    "cap": "37060"
  },
  {
    "key": "15537",
    "ITA": "TREVENZUOLO-RONCOLEVA'",
    "state_code": "VR",
    "cab": "599209",
    "cap": "37060"
  },
  {
    "key": "15538",
    "ITA": "TREVI-BORGO DI TREVI",
    "state_code": "PG",
    "cab": "387209",
    "cap": "6039"
  },
  {
    "key": "15539",
    "ITA": "TREVI-CANNAIOLA",
    "state_code": "PG",
    "cab": "387209",
    "cap": "6039"
  },
  {
    "key": "15540",
    "ITA": "TREVI-LI CELLI",
    "state_code": "PG",
    "cab": "387209",
    "cap": "6039"
  },
  {
    "key": "15541",
    "ITA": "TREVI-MATIGGE",
    "state_code": "PG",
    "cab": "387209",
    "cap": "6039"
  },
  {
    "key": "15542",
    "ITA": "TREVI-SANTA MARIA IN VALLE",
    "state_code": "PG",
    "cab": "387209",
    "cap": "6039"
  },
  {
    "key": "15543",
    "ITA": "TREVI",
    "state_code": "PG",
    "cab": "387209",
    "cap": "6039"
  },
  {
    "key": "15544",
    "ITA": "TREVI NEL LAZIO",
    "state_code": "FR",
    "cab": "746800",
    "cap": "3010"
  },
  {
    "key": "15545",
    "ITA": "TREVICO",
    "state_code": "AV",
    "cab": "951806",
    "cap": "83058"
  },
  {
    "key": "15546",
    "ITA": "TREVICO-MOLINI",
    "state_code": "AV",
    "cab": "951806",
    "cap": "83058"
  },
  {
    "key": "15547",
    "ITA": "TREVIGLIO-CASTEL CERRETO",
    "state_code": "BG",
    "cab": "536409",
    "cap": "24047"
  },
  {
    "key": "15548",
    "ITA": "TREVIGLIO",
    "state_code": "BG",
    "cab": "536409",
    "cap": "24047"
  },
  {
    "key": "15549",
    "ITA": "TREVIGLIO-GEROMINA",
    "state_code": "BG",
    "cab": "536409",
    "cap": "24047"
  },
  {
    "key": "15550",
    "ITA": "TREVIGNANO",
    "state_code": "TV",
    "cab": "621409",
    "cap": "31040"
  },
  {
    "key": "15551",
    "ITA": "TREVIGNANO-FALZE'",
    "state_code": "TV",
    "cab": "621409",
    "cap": "31040"
  },
  {
    "key": "15552",
    "ITA": "TREVIGNANO-SIGNORESSA",
    "state_code": "TV",
    "cab": "621409",
    "cap": "31040"
  },
  {
    "key": "15553",
    "ITA": "TREVIGNANO-MUSANO",
    "state_code": "TV",
    "cab": "621409",
    "cap": "31040"
  },
  {
    "key": "15554",
    "ITA": "TREVIGNANO ROMANO-VICARELLO",
    "state_code": "RM",
    "cab": "394700",
    "cap": "69"
  },
  {
    "key": "15555",
    "ITA": "TREVIGNANO ROMANO",
    "state_code": "RM",
    "cab": "394700",
    "cap": "69"
  },
  {
    "key": "15556",
    "ITA": "TREVILLE",
    "state_code": "AL",
    "cab": "921783",
    "cap": "15030"
  },
  {
    "key": "15557",
    "ITA": "TREVIOLO",
    "state_code": "BG",
    "cab": "536508",
    "cap": "24048"
  },
  {
    "key": "15558",
    "ITA": "TREVISO-SANTA MARIA DELLA ROVERE",
    "state_code": "TV",
    "cab": "120006",
    "cap": "31100"
  },
  {
    "key": "15559",
    "ITA": "TREVISO-MONIGO",
    "state_code": "TV",
    "cab": "120006",
    "cap": "31100"
  },
  {
    "key": "15560",
    "ITA": "TREVISO",
    "state_code": "TV",
    "cab": "120006",
    "cap": "31100"
  },
  {
    "key": "15561",
    "ITA": "TREVISO-FIERA",
    "state_code": "TV",
    "cab": "120006",
    "cap": "31100"
  },
  {
    "key": "15562",
    "ITA": "TREVISO-SELVANA BASSA",
    "state_code": "TV",
    "cab": "120006",
    "cap": "31100"
  },
  {
    "key": "15563",
    "ITA": "TREVISO-CANIZZANO",
    "state_code": "TV",
    "cab": "120006",
    "cap": "31100"
  },
  {
    "key": "15564",
    "ITA": "TREVISO BRESCIANO",
    "state_code": "BS",
    "cab": "929729",
    "cap": "25070"
  },
  {
    "key": "15565",
    "ITA": "TREVISO BRESCIANO-TREBBIO",
    "state_code": "BS",
    "cab": "929729",
    "cap": "25070"
  },
  {
    "key": "15566",
    "ITA": "TREZZANO ROSA",
    "state_code": "MI",
    "cab": "342600",
    "cap": "20060"
  },
  {
    "key": "15567",
    "ITA": "TREZZANO SUL NAVIGLIO",
    "state_code": "MI",
    "cab": "339101",
    "cap": "20090"
  },
  {
    "key": "15568",
    "ITA": "TREZZANO SUL NAVIGLIO-ZINGONE",
    "state_code": "MI",
    "cab": "339101",
    "cap": "20090"
  },
  {
    "key": "15569",
    "ITA": "TREZZO SULL'ADDA",
    "state_code": "MI",
    "cab": "339200",
    "cap": "20056"
  },
  {
    "key": "15570",
    "ITA": "TREZZO SULL'ADDA-CONCESA",
    "state_code": "MI",
    "cab": "339200",
    "cap": "20056"
  },
  {
    "key": "15571",
    "ITA": "TREZZO TINELLA",
    "state_code": "CN",
    "cab": "273078",
    "cap": "12050"
  },
  {
    "key": "15572",
    "ITA": "TREZZONE",
    "state_code": "CO",
    "cab": "926352",
    "cap": "22010"
  },
  {
    "key": "15573",
    "ITA": "TRIBANO",
    "state_code": "PD",
    "cab": "629501",
    "cap": "35020"
  },
  {
    "key": "15574",
    "ITA": "TRIBIANO",
    "state_code": "MI",
    "cab": "705608",
    "cap": "20067"
  },
  {
    "key": "15575",
    "ITA": "TRIBOGNA",
    "state_code": "GE",
    "cab": "903278",
    "cap": "16030"
  },
  {
    "key": "15576",
    "ITA": "TRICARICO",
    "state_code": "MT",
    "cab": "804708",
    "cap": "75019"
  },
  {
    "key": "15577",
    "ITA": "TRICARICO-CALLE",
    "state_code": "MT",
    "cab": "804708",
    "cap": "75019"
  },
  {
    "key": "15578",
    "ITA": "TRICASE-TRICASE PORTO",
    "state_code": "LE",
    "cab": "801100",
    "cap": "73039"
  },
  {
    "key": "15579",
    "ITA": "TRICASE-TUTINO",
    "state_code": "LE",
    "cab": "801100",
    "cap": "73039"
  },
  {
    "key": "15580",
    "ITA": "TRICASE-SANT'EUFEMIA",
    "state_code": "LE",
    "cab": "801100",
    "cap": "73039"
  },
  {
    "key": "15581",
    "ITA": "TRICASE-LUCUGNANO",
    "state_code": "LE",
    "cab": "801100",
    "cap": "73030"
  },
  {
    "key": "15582",
    "ITA": "TRICASE",
    "state_code": "LE",
    "cab": "801100",
    "cap": "73039"
  },
  {
    "key": "15583",
    "ITA": "TRICASE-CAPRARICA DEL CAPO",
    "state_code": "LE",
    "cab": "801100",
    "cap": "73039"
  },
  {
    "key": "15584",
    "ITA": "TRICASE-DEPRESSA",
    "state_code": "LE",
    "cab": "801100",
    "cap": "73039"
  },
  {
    "key": "15585",
    "ITA": "TRICERRO",
    "state_code": "VC",
    "cab": "725002",
    "cap": "13038"
  },
  {
    "key": "15586",
    "ITA": "TRICESIMO",
    "state_code": "UD",
    "cab": "643601",
    "cap": "33019"
  },
  {
    "key": "15587",
    "ITA": "TRICESIMO-LEONACCO",
    "state_code": "UD",
    "cab": "643601",
    "cap": "33019"
  },
  {
    "key": "15588",
    "ITA": "TRICHIANA",
    "state_code": "BL",
    "cab": "613406",
    "cap": "32028"
  },
  {
    "key": "15589",
    "ITA": "TRICHIANA-SANT'ANTONIO TORTAL",
    "state_code": "BL",
    "cab": "613406",
    "cap": "32028"
  },
  {
    "key": "15590",
    "ITA": "TRIEI",
    "state_code": "OG",
    "cab": "870709",
    "cap": "8040"
  },
  {
    "key": "15591",
    "ITA": "TRIEI-ARDALI",
    "state_code": "OG",
    "cab": "870709",
    "cap": "8040"
  },
  {
    "key": "15592",
    "ITA": "TRIEI-S'ARRIDELLU",
    "state_code": "OG",
    "cab": "870709",
    "cap": "8040"
  },
  {
    "key": "15593",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34128"
  },
  {
    "key": "15594",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34129"
  },
  {
    "key": "15595",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34131"
  },
  {
    "key": "15596",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34132"
  },
  {
    "key": "15597",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34133"
  },
  {
    "key": "15598",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34134"
  },
  {
    "key": "15599",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34135"
  },
  {
    "key": "15600",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34136"
  },
  {
    "key": "15601",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34137"
  },
  {
    "key": "15602",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34138"
  },
  {
    "key": "15603",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34139"
  },
  {
    "key": "15604",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34141"
  },
  {
    "key": "15605",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34142"
  },
  {
    "key": "15606",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34143"
  },
  {
    "key": "15607",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34144"
  },
  {
    "key": "15608",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34145"
  },
  {
    "key": "15609",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34146"
  },
  {
    "key": "15610",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34147"
  },
  {
    "key": "15611",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34148"
  },
  {
    "key": "15612",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34149"
  },
  {
    "key": "15613",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34151"
  },
  {
    "key": "15614",
    "ITA": "TRIESTE-GRIGNANO",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34014"
  },
  {
    "key": "15615",
    "ITA": "TRIESTE-PROSECCO",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34017"
  },
  {
    "key": "15616",
    "ITA": "TRIESTE-SANTA CROCE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34014"
  },
  {
    "key": "15617",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34121"
  },
  {
    "key": "15618",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34017"
  },
  {
    "key": "15619",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34014"
  },
  {
    "key": "15620",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34127"
  },
  {
    "key": "15621",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34126"
  },
  {
    "key": "15622",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34125"
  },
  {
    "key": "15623",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34124"
  },
  {
    "key": "15624",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34012"
  },
  {
    "key": "15625",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34122"
  },
  {
    "key": "15626",
    "ITA": "TRIESTE",
    "state_code": "TS",
    "cab": "22004",
    "cap": "34123"
  },
  {
    "key": "15627",
    "ITA": "TRIGGIANO",
    "state_code": "BA",
    "cab": "417303",
    "cap": "70019"
  },
  {
    "key": "15628",
    "ITA": "TRIGOLO",
    "state_code": "CR",
    "cab": "572701",
    "cap": "26018"
  },
  {
    "key": "15629",
    "ITA": "TRINITA'",
    "state_code": "CN",
    "cab": "469007",
    "cap": "12049"
  },
  {
    "key": "15630",
    "ITA": "TRINITA' D'AGULTU E VIGNOLA-LU COLBU",
    "state_code": "OT",
    "cab": "876201",
    "cap": "7038"
  },
  {
    "key": "15631",
    "ITA": "TRINITA' D'AGULTU E VIGNOLA",
    "state_code": "OT",
    "cab": "876201",
    "cap": "7038"
  },
  {
    "key": "15632",
    "ITA": "TRINITAPOLI",
    "state_code": "BT",
    "cab": "786806",
    "cap": "71049"
  },
  {
    "key": "15633",
    "ITA": "TRINO",
    "state_code": "VC",
    "cab": "448407",
    "cap": "13039"
  },
  {
    "key": "15634",
    "ITA": "TRIORA",
    "state_code": "IM",
    "cab": "922641",
    "cap": "18010"
  },
  {
    "key": "15635",
    "ITA": "TRIPI",
    "state_code": "ME",
    "cab": "961623",
    "cap": "98060"
  },
  {
    "key": "15636",
    "ITA": "TRIPI-CAMPOGRANDE",
    "state_code": "ME",
    "cab": "961623",
    "cap": "98060"
  },
  {
    "key": "15637",
    "ITA": "TRISOBBIO-VILLA BOTTERI",
    "state_code": "AL",
    "cab": "921791",
    "cap": "15070"
  },
  {
    "key": "15638",
    "ITA": "TRISOBBIO",
    "state_code": "AL",
    "cab": "921791",
    "cap": "15070"
  },
  {
    "key": "15639",
    "ITA": "TRISSINO",
    "state_code": "VI",
    "cab": "608109",
    "cap": "36070"
  },
  {
    "key": "15640",
    "ITA": "TRISSINO-LOVARA",
    "state_code": "VI",
    "cab": "608109",
    "cap": "36070"
  },
  {
    "key": "15641",
    "ITA": "TRIUGGIO",
    "state_code": "MB",
    "cab": "339309",
    "cap": "20050"
  },
  {
    "key": "15642",
    "ITA": "TRIUGGIO-TREGASIO",
    "state_code": "MB",
    "cab": "339309",
    "cap": "20050"
  },
  {
    "key": "15643",
    "ITA": "TRIUGGIO-CANONICA",
    "state_code": "MB",
    "cab": "339309",
    "cap": "20050"
  },
  {
    "key": "15644",
    "ITA": "TRIVENTO",
    "state_code": "CB",
    "cab": "411405",
    "cap": "86029"
  },
  {
    "key": "15645",
    "ITA": "TRIVERO-PRATRIVERO",
    "state_code": "BI",
    "cab": "448506",
    "cap": "13835"
  },
  {
    "key": "15646",
    "ITA": "TRIVERO-VICO",
    "state_code": "BI",
    "cab": "448506",
    "cap": "13835"
  },
  {
    "key": "15647",
    "ITA": "TRIVERO-PONZONE",
    "state_code": "BI",
    "cab": "448506",
    "cap": "13835"
  },
  {
    "key": "15648",
    "ITA": "TRIVERO-BULLIANA",
    "state_code": "BI",
    "cab": "448506",
    "cap": "13835"
  },
  {
    "key": "15649",
    "ITA": "TRIVERO-BOTTO",
    "state_code": "BI",
    "cab": "448506",
    "cap": "13835"
  },
  {
    "key": "15650",
    "ITA": "TRIVERO",
    "state_code": "BI",
    "cab": "448506",
    "cap": "13835"
  },
  {
    "key": "15651",
    "ITA": "TRIVERO-GIARDINO",
    "state_code": "BI",
    "cab": "448506",
    "cap": "13835"
  },
  {
    "key": "15652",
    "ITA": "TRIVIGLIANO-SAN GIOVANNI",
    "state_code": "FR",
    "cab": "746602",
    "cap": "3010"
  },
  {
    "key": "15653",
    "ITA": "TRIVIGLIANO",
    "state_code": "FR",
    "cab": "746602",
    "cap": "3010"
  },
  {
    "key": "15654",
    "ITA": "TRIVIGLIANO-PATONI",
    "state_code": "FR",
    "cab": "746602",
    "cap": "3010"
  },
  {
    "key": "15655",
    "ITA": "TRIVIGNANO UDINESE-CLAUIANO",
    "state_code": "UD",
    "cab": "896209",
    "cap": "33050"
  },
  {
    "key": "15656",
    "ITA": "TRIVIGNANO UDINESE",
    "state_code": "UD",
    "cab": "896209",
    "cap": "33050"
  },
  {
    "key": "15657",
    "ITA": "TRIVIGNO",
    "state_code": "PZ",
    "cab": "912246",
    "cap": "85018"
  },
  {
    "key": "15658",
    "ITA": "TRIVOLZIO",
    "state_code": "PV",
    "cab": "930982",
    "cap": "27020"
  },
  {
    "key": "15659",
    "ITA": "TRODENA-FONTANEFREDDE",
    "state_code": "BZ",
    "cab": "590109",
    "cap": "39040"
  },
  {
    "key": "15660",
    "ITA": "TRODENA-SAN LUGANO",
    "state_code": "BZ",
    "cab": "590109",
    "cap": "39040"
  },
  {
    "key": "15661",
    "ITA": "TRODENA",
    "state_code": "BZ",
    "cab": "590109",
    "cap": "39040"
  },
  {
    "key": "15662",
    "ITA": "TROFARELLO",
    "state_code": "TO",
    "cab": "310805",
    "cap": "10028"
  },
  {
    "key": "15663",
    "ITA": "TROFARELLO-VALLE SAUGLIO",
    "state_code": "TO",
    "cab": "310805",
    "cap": "10028"
  },
  {
    "key": "15664",
    "ITA": "TROIA",
    "state_code": "FG",
    "cab": "786905",
    "cap": "71029"
  },
  {
    "key": "15665",
    "ITA": "TROIA-BORGO GIARDINETTO",
    "state_code": "FG",
    "cab": "786905",
    "cap": "71029"
  },
  {
    "key": "15666",
    "ITA": "TROINA",
    "state_code": "EN",
    "cab": "837203",
    "cap": "94018"
  },
  {
    "key": "15667",
    "ITA": "TROMELLO",
    "state_code": "PV",
    "cab": "563403",
    "cap": "27020"
  },
  {
    "key": "15668",
    "ITA": "TRONTANO",
    "state_code": "VB",
    "cab": "489203",
    "cap": "28859"
  },
  {
    "key": "15669",
    "ITA": "TRONZANO LAGO MAGGIORE",
    "state_code": "VA",
    "cab": "924746",
    "cap": "21010"
  },
  {
    "key": "15670",
    "ITA": "TRONZANO VERCELLESE",
    "state_code": "VC",
    "cab": "448605",
    "cap": "13049"
  },
  {
    "key": "15671",
    "ITA": "TROPEA",
    "state_code": "VV",
    "cab": "428201",
    "cap": "89861"
  },
  {
    "key": "15672",
    "ITA": "TROVO",
    "state_code": "PV",
    "cab": "278036",
    "cap": "27020"
  },
  {
    "key": "15673",
    "ITA": "TRUCCAZZANO",
    "state_code": "MI",
    "cab": "341800",
    "cap": "20060"
  },
  {
    "key": "15674",
    "ITA": "TRUCCAZZANO-ALBIGNANO",
    "state_code": "MI",
    "cab": "341800",
    "cap": "20060"
  },
  {
    "key": "15675",
    "ITA": "TUBRE",
    "state_code": "BZ",
    "cab": "590208",
    "cap": "39020"
  },
  {
    "key": "15676",
    "ITA": "TUENNO",
    "state_code": "TN",
    "cab": "357004",
    "cap": "38019"
  },
  {
    "key": "15677",
    "ITA": "TUFARA",
    "state_code": "CB",
    "cab": "911024",
    "cap": "86010"
  },
  {
    "key": "15678",
    "ITA": "TUFILLO",
    "state_code": "CH",
    "cab": "955096",
    "cap": "66050"
  },
  {
    "key": "15679",
    "ITA": "TUFINO",
    "state_code": "NA",
    "cab": "908996",
    "cap": "80030"
  },
  {
    "key": "15680",
    "ITA": "TUFINO-SCHIAVA",
    "state_code": "NA",
    "cab": "908996",
    "cap": "80030"
  },
  {
    "key": "15681",
    "ITA": "TUFO",
    "state_code": "AV",
    "cab": "951814",
    "cap": "83010"
  },
  {
    "key": "15682",
    "ITA": "TUGLIE",
    "state_code": "LE",
    "cab": "801209",
    "cap": "73058"
  },
  {
    "key": "15683",
    "ITA": "TUILI",
    "state_code": "SU",
    "cab": "863308",
    "cap": "9029"
  },
  {
    "key": "15684",
    "ITA": "TULA",
    "state_code": "SS",
    "cab": "876300",
    "cap": "7010"
  },
  {
    "key": "15685",
    "ITA": "TUORO SUL TRASIMENO",
    "state_code": "PG",
    "cab": "387308",
    "cap": "6069"
  },
  {
    "key": "15686",
    "ITA": "TUORO SUL TRASIMENO-BORGHETTO DI TUORO",
    "state_code": "PG",
    "cab": "387308",
    "cap": "6069"
  },
  {
    "key": "15687",
    "ITA": "TUORO SUL TRASIMENO-ISOLA MAGGIORE",
    "state_code": "PG",
    "cab": "387308",
    "cap": "6069"
  },
  {
    "key": "15688",
    "ITA": "TURANIA",
    "state_code": "RI",
    "cab": "947440",
    "cap": "2020"
  },
  {
    "key": "15689",
    "ITA": "TURANO LODIGIANO",
    "state_code": "LO",
    "cab": "904201",
    "cap": "26828"
  },
  {
    "key": "15690",
    "ITA": "TURANO LODIGIANO-MELEGNANELLO",
    "state_code": "LO",
    "cab": "904201",
    "cap": "26828"
  },
  {
    "key": "15691",
    "ITA": "TURATE",
    "state_code": "CO",
    "cab": "517805",
    "cap": "22078"
  },
  {
    "key": "15692",
    "ITA": "TURBIGO",
    "state_code": "MI",
    "cab": "339408",
    "cap": "20029"
  },
  {
    "key": "15693",
    "ITA": "TURI",
    "state_code": "BA",
    "cab": "417402",
    "cap": "70010"
  },
  {
    "key": "15694",
    "ITA": "TURRI",
    "state_code": "SU",
    "cab": "863407",
    "cap": "9020"
  },
  {
    "key": "15695",
    "ITA": "TURRIACO",
    "state_code": "GO",
    "cab": "646703",
    "cap": "34070"
  },
  {
    "key": "15696",
    "ITA": "TURRIVALIGNANI",
    "state_code": "PE",
    "cab": "954156",
    "cap": "65020"
  },
  {
    "key": "15697",
    "ITA": "TURSI",
    "state_code": "MT",
    "cab": "804807",
    "cap": "75028"
  },
  {
    "key": "15698",
    "ITA": "TURSI-CAPRARICO",
    "state_code": "MT",
    "cab": "804807",
    "cap": "75028"
  },
  {
    "key": "15699",
    "ITA": "TURSI-GANNANO",
    "state_code": "MT",
    "cab": "804807",
    "cap": "75028"
  },
  {
    "key": "15700",
    "ITA": "TUSA",
    "state_code": "ME",
    "cab": "826206",
    "cap": "98079"
  },
  {
    "key": "15701",
    "ITA": "TUSA-CASTEL DI TUSA",
    "state_code": "ME",
    "cab": "826206",
    "cap": "98079"
  },
  {
    "key": "15702",
    "ITA": "TUSCANIA",
    "state_code": "VT",
    "cab": "733006",
    "cap": "1017"
  },
  {
    "key": "15703",
    "ITA": "UBIALE CLANEZZO",
    "state_code": "BG",
    "cab": "265843",
    "cap": "24010"
  },
  {
    "key": "15704",
    "ITA": "UBIALE CLANEZZO-CLANEZZO",
    "state_code": "BG",
    "cab": "265843",
    "cap": "24010"
  },
  {
    "key": "15705",
    "ITA": "UBIALE CLANEZZO-UBIALE",
    "state_code": "BG",
    "cab": "265843",
    "cap": "24010"
  },
  {
    "key": "15706",
    "ITA": "UBOLDO",
    "state_code": "VA",
    "cab": "506006",
    "cap": "21040"
  },
  {
    "key": "15707",
    "ITA": "UCRIA",
    "state_code": "ME",
    "cab": "890202",
    "cap": "98060"
  },
  {
    "key": "15708",
    "ITA": "UDINE",
    "state_code": "UD",
    "cab": "123000",
    "cap": "33100"
  },
  {
    "key": "15709",
    "ITA": "UDINE-BALDASSERIA",
    "state_code": "UD",
    "cab": "123000",
    "cap": "33100"
  },
  {
    "key": "15710",
    "ITA": "UDINE-LAIPACCO",
    "state_code": "UD",
    "cab": "123000",
    "cap": "33100"
  },
  {
    "key": "15711",
    "ITA": "UDINE-GERVASUTTA",
    "state_code": "UD",
    "cab": "123000",
    "cap": "33100"
  },
  {
    "key": "15712",
    "ITA": "UDINE-GODIA",
    "state_code": "UD",
    "cab": "123000",
    "cap": "33100"
  },
  {
    "key": "15713",
    "ITA": "UDINE-CUSSIGNACCO",
    "state_code": "UD",
    "cab": "123000",
    "cap": "33100"
  },
  {
    "key": "15714",
    "ITA": "UGENTO-GEMINI",
    "state_code": "LE",
    "cab": "801308",
    "cap": "73059"
  },
  {
    "key": "15715",
    "ITA": "UGENTO",
    "state_code": "LE",
    "cab": "801308",
    "cap": "73059"
  },
  {
    "key": "15716",
    "ITA": "UGGIANO LA CHIESA-CASAMASSELLA",
    "state_code": "LE",
    "cab": "801407",
    "cap": "73020"
  },
  {
    "key": "15717",
    "ITA": "UGGIANO LA CHIESA",
    "state_code": "LE",
    "cab": "801407",
    "cap": "73020"
  },
  {
    "key": "15718",
    "ITA": "UGGIATE TREVANO",
    "state_code": "CO",
    "cab": "517904",
    "cap": "22029"
  },
  {
    "key": "15719",
    "ITA": "ULA' TIRSO",
    "state_code": "OR",
    "cab": "881508",
    "cap": "9080"
  },
  {
    "key": "15720",
    "ITA": "ULASSAI",
    "state_code": "OG",
    "cab": "870808",
    "cap": "8040"
  },
  {
    "key": "15721",
    "ITA": "ULTIMO",
    "state_code": "BZ",
    "cab": "590307",
    "cap": "39016"
  },
  {
    "key": "15722",
    "ITA": "ULTIMO-SANTA VALBURGA",
    "state_code": "BZ",
    "cab": "590307",
    "cap": "39016"
  },
  {
    "key": "15723",
    "ITA": "ULTIMO-SANTA GERTRUDE",
    "state_code": "BZ",
    "cab": "590307",
    "cap": "39016"
  },
  {
    "key": "15724",
    "ITA": "ULTIMO-SAN NICOLO'",
    "state_code": "BZ",
    "cab": "590307",
    "cap": "39016"
  },
  {
    "key": "15725",
    "ITA": "UMBERTIDE",
    "state_code": "PG",
    "cab": "387407",
    "cap": "6019"
  },
  {
    "key": "15726",
    "ITA": "UMBERTIDE-CALZOLARO",
    "state_code": "PG",
    "cab": "387407",
    "cap": "6018"
  },
  {
    "key": "15727",
    "ITA": "UMBERTIDE-MONTECASTELLI",
    "state_code": "PG",
    "cab": "387407",
    "cap": "6019"
  },
  {
    "key": "15728",
    "ITA": "UMBERTIDE-VERNA",
    "state_code": "PG",
    "cab": "387407",
    "cap": "6019"
  },
  {
    "key": "15729",
    "ITA": "UMBERTIDE-PIERANTONIO",
    "state_code": "PG",
    "cab": "387407",
    "cap": "6019"
  },
  {
    "key": "15730",
    "ITA": "UMBERTIDE-PREGGIO",
    "state_code": "PG",
    "cab": "387407",
    "cap": "6019"
  },
  {
    "key": "15731",
    "ITA": "UMBERTIDE-NICCONE",
    "state_code": "PG",
    "cab": "387407",
    "cap": "6019"
  },
  {
    "key": "15732",
    "ITA": "UMBRIATICO-PERTICARO",
    "state_code": "KR",
    "cab": "913665",
    "cap": "88823"
  },
  {
    "key": "15733",
    "ITA": "UMBRIATICO",
    "state_code": "KR",
    "cab": "913665",
    "cap": "88823"
  },
  {
    "key": "15734",
    "ITA": "URAGO D'OGLIO",
    "state_code": "BS",
    "cab": "553503",
    "cap": "25030"
  },
  {
    "key": "15735",
    "ITA": "URAS",
    "state_code": "OR",
    "cab": "881607",
    "cap": "9099"
  },
  {
    "key": "15736",
    "ITA": "URBANA",
    "state_code": "PD",
    "cab": "882902",
    "cap": "35040"
  },
  {
    "key": "15737",
    "ITA": "URBANIA-MURAGLIONE",
    "state_code": "PU",
    "cab": "686907",
    "cap": "61049"
  },
  {
    "key": "15738",
    "ITA": "URBANIA",
    "state_code": "PU",
    "cab": "686907",
    "cap": "61049"
  },
  {
    "key": "15739",
    "ITA": "URBE-VARA INFERIORE",
    "state_code": "SV",
    "cab": "495200",
    "cap": "17048"
  },
  {
    "key": "15740",
    "ITA": "URBE-SAN PIETRO D'OLBA",
    "state_code": "SV",
    "cab": "495200",
    "cap": "17048"
  },
  {
    "key": "15741",
    "ITA": "URBE-VARA SUPERIORE",
    "state_code": "SV",
    "cab": "495200",
    "cap": "17048"
  },
  {
    "key": "15742",
    "ITA": "URBE",
    "state_code": "SV",
    "cab": "495200",
    "cap": "17048"
  },
  {
    "key": "15743",
    "ITA": "URBINO-CASTELLO DI CAVALLINO",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15744",
    "ITA": "URBINO-VIA PIANA",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15745",
    "ITA": "URBINO-PIEVE DI CAGNA",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15746",
    "ITA": "URBINO-PONTE IN FOGLIA",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15747",
    "ITA": "URBINO-SCHIETI",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15748",
    "ITA": "URBINO-TRASANNI",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15749",
    "ITA": "URBINO-CANAVACCIO",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15750",
    "ITA": "URBINO",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15751",
    "ITA": "URBINO-GADANA",
    "state_code": "PU",
    "cab": "687004",
    "cap": "61029"
  },
  {
    "key": "15752",
    "ITA": "URBISAGLIA",
    "state_code": "MC",
    "cab": "692202",
    "cap": "62010"
  },
  {
    "key": "15753",
    "ITA": "URGNANO",
    "state_code": "BG",
    "cab": "536607",
    "cap": "24059"
  },
  {
    "key": "15754",
    "ITA": "URGNANO-BASELLA",
    "state_code": "BG",
    "cab": "536607",
    "cap": "24059"
  },
  {
    "key": "15755",
    "ITA": "URI",
    "state_code": "SS",
    "cab": "876409",
    "cap": "7040"
  },
  {
    "key": "15756",
    "ITA": "URURI",
    "state_code": "CB",
    "cab": "411504",
    "cap": "86049"
  },
  {
    "key": "15757",
    "ITA": "URZULEI",
    "state_code": "OG",
    "cab": "870907",
    "cap": "8040"
  },
  {
    "key": "15758",
    "ITA": "USCIO",
    "state_code": "GE",
    "cab": "322701",
    "cap": "16030"
  },
  {
    "key": "15759",
    "ITA": "USCIO-COLONIA ARNALDI",
    "state_code": "GE",
    "cab": "322701",
    "cap": "16030"
  },
  {
    "key": "15760",
    "ITA": "USELLUS",
    "state_code": "OR",
    "cab": "881706",
    "cap": "9090"
  },
  {
    "key": "15761",
    "ITA": "USINI",
    "state_code": "SS",
    "cab": "876508",
    "cap": "7049"
  },
  {
    "key": "15762",
    "ITA": "USMATE VELATE",
    "state_code": "MB",
    "cab": "339507",
    "cap": "20865"
  },
  {
    "key": "15763",
    "ITA": "USMATE VELATE-VELATE",
    "state_code": "MB",
    "cab": "339507",
    "cap": "20865"
  },
  {
    "key": "15764",
    "ITA": "USSANA",
    "state_code": "CA",
    "cab": "863506",
    "cap": "9020"
  },
  {
    "key": "15765",
    "ITA": "USSARAMANNA",
    "state_code": "SU",
    "cab": "863605",
    "cap": "9020"
  },
  {
    "key": "15766",
    "ITA": "USSASSAI",
    "state_code": "OG",
    "cab": "871004",
    "cap": "8040"
  },
  {
    "key": "15767",
    "ITA": "USSEAUX",
    "state_code": "TO",
    "cab": "901694",
    "cap": "10060"
  },
  {
    "key": "15768",
    "ITA": "USSEGLIO",
    "state_code": "TO",
    "cab": "901702",
    "cap": "10070"
  },
  {
    "key": "15769",
    "ITA": "USSITA",
    "state_code": "MC",
    "cab": "692301",
    "cap": "62039"
  },
  {
    "key": "15770",
    "ITA": "USTICA",
    "state_code": "PA",
    "cab": "436907",
    "cap": "90010"
  },
  {
    "key": "15771",
    "ITA": "UTA",
    "state_code": "CA",
    "cab": "863704",
    "cap": "9010"
  },
  {
    "key": "15772",
    "ITA": "UZZANO-SANTA LUCIA",
    "state_code": "PT",
    "cab": "705509",
    "cap": "51010"
  },
  {
    "key": "15773",
    "ITA": "UZZANO-FORONE",
    "state_code": "PT",
    "cab": "705509",
    "cap": "51010"
  },
  {
    "key": "15774",
    "ITA": "UZZANO",
    "state_code": "PT",
    "cab": "705509",
    "cap": "51010"
  },
  {
    "key": "15775",
    "ITA": "VACCARIZZO ALBANESE",
    "state_code": "CS",
    "cab": "959494",
    "cap": "87060"
  },
  {
    "key": "15776",
    "ITA": "VACONE",
    "state_code": "RI",
    "cab": "947457",
    "cap": "2040"
  },
  {
    "key": "15777",
    "ITA": "VACRI",
    "state_code": "CH",
    "cab": "388306",
    "cap": "66010"
  },
  {
    "key": "15778",
    "ITA": "VADENA",
    "state_code": "BZ",
    "cab": "891200",
    "cap": "39051"
  },
  {
    "key": "15779",
    "ITA": "VADO LIGURE",
    "state_code": "SV",
    "cab": "495309",
    "cap": "17047"
  },
  {
    "key": "15780",
    "ITA": "VAGLI SOTTO-VAGLI SOPRA",
    "state_code": "LU",
    "cab": "471409",
    "cap": "55030"
  },
  {
    "key": "15781",
    "ITA": "VAGLI SOTTO",
    "state_code": "LU",
    "cab": "471409",
    "cap": "55030"
  },
  {
    "key": "15782",
    "ITA": "VAGLIA",
    "state_code": "FI",
    "cab": "381301",
    "cap": "50036"
  },
  {
    "key": "15783",
    "ITA": "VAGLIA-BIVIGLIANO",
    "state_code": "FI",
    "cab": "381301",
    "cap": "50036"
  },
  {
    "key": "15784",
    "ITA": "VAGLIA-PRATOLINO",
    "state_code": "FI",
    "cab": "381301",
    "cap": "50036"
  },
  {
    "key": "15785",
    "ITA": "VAGLIA-FONTEBUONA",
    "state_code": "FI",
    "cab": "381301",
    "cap": "50036"
  },
  {
    "key": "15786",
    "ITA": "VAGLIO BASILICATA",
    "state_code": "PZ",
    "cab": "491506",
    "cap": "85010"
  },
  {
    "key": "15787",
    "ITA": "VAGLIO SERRA",
    "state_code": "AT",
    "cab": "920470",
    "cap": "14049"
  },
  {
    "key": "15788",
    "ITA": "VAIANO-LA BRIGLIA",
    "state_code": "PO",
    "cab": "381400",
    "cap": "59021"
  },
  {
    "key": "15789",
    "ITA": "VAIANO",
    "state_code": "PO",
    "cab": "381400",
    "cap": "59021"
  },
  {
    "key": "15790",
    "ITA": "VAIANO-SCHIGNANO",
    "state_code": "PO",
    "cab": "381400",
    "cap": "59021"
  },
  {
    "key": "15791",
    "ITA": "VAIANO CREMASCO",
    "state_code": "CR",
    "cab": "572800",
    "cap": "26010"
  },
  {
    "key": "15792",
    "ITA": "VAIE",
    "state_code": "TO",
    "cab": "791202",
    "cap": "10050"
  },
  {
    "key": "15793",
    "ITA": "VAILATE",
    "state_code": "CR",
    "cab": "572909",
    "cap": "26019"
  },
  {
    "key": "15794",
    "ITA": "VAIRANO PATENORA",
    "state_code": "CE",
    "cab": "750901",
    "cap": "81058"
  },
  {
    "key": "15795",
    "ITA": "VAIRANO PATENORA-MARZANELLO",
    "state_code": "CE",
    "cab": "750901",
    "cap": "81058"
  },
  {
    "key": "15796",
    "ITA": "VAIRANO PATENORA-PATENORA",
    "state_code": "CE",
    "cab": "750901",
    "cap": "81058"
  },
  {
    "key": "15797",
    "ITA": "VAIRANO PATENORA-VAIRANO",
    "state_code": "CE",
    "cab": "750901",
    "cap": "81058"
  },
  {
    "key": "15798",
    "ITA": "VAIRANO PATENORA-VAIRANO SCALO",
    "state_code": "CE",
    "cab": "750901",
    "cap": "81059"
  },
  {
    "key": "15799",
    "ITA": "VAJONT",
    "state_code": "PN",
    "cab": "721506",
    "cap": "33080"
  },
  {
    "key": "15800",
    "ITA": "VAL DELLA TORRE-BRIONE",
    "state_code": "TO",
    "cab": "666206",
    "cap": "10040"
  },
  {
    "key": "15801",
    "ITA": "VAL DELLA TORRE-MONTELERA",
    "state_code": "TO",
    "cab": "666206",
    "cap": "10040"
  },
  {
    "key": "15802",
    "ITA": "VAL DELLA TORRE",
    "state_code": "TO",
    "cab": "666206",
    "cap": "10040"
  },
  {
    "key": "15803",
    "ITA": "VAL DI NIZZA",
    "state_code": "PV",
    "cab": "931006",
    "cap": "27050"
  },
  {
    "key": "15804",
    "ITA": "VAL DI VIZZE",
    "state_code": "BZ",
    "cab": "590505",
    "cap": "39049"
  },
  {
    "key": "15805",
    "ITA": "VAL DI VIZZE-PRATI",
    "state_code": "BZ",
    "cab": "590505",
    "cap": "39049"
  },
  {
    "key": "15806",
    "ITA": "VAL DI VIZZE-STAZIONE",
    "state_code": "BZ",
    "cab": "590505",
    "cap": "39049"
  },
  {
    "key": "15807",
    "ITA": "VAL MASINO-CATAEGGIO",
    "state_code": "SO",
    "cab": "927350",
    "cap": "23010"
  },
  {
    "key": "15808",
    "ITA": "VAL MASINO-SAN MARTINO",
    "state_code": "SO",
    "cab": "927350",
    "cap": "23010"
  },
  {
    "key": "15809",
    "ITA": "VAL MASINO",
    "state_code": "SO",
    "cab": "927350",
    "cap": "23010"
  },
  {
    "key": "15810",
    "ITA": "VAL REZZO",
    "state_code": "CO",
    "cab": "926386",
    "cap": "22010"
  },
  {
    "key": "15811",
    "ITA": "VALBONDIONE-BONDIONE",
    "state_code": "BG",
    "cab": "536706",
    "cap": "24020"
  },
  {
    "key": "15812",
    "ITA": "VALBONDIONE",
    "state_code": "BG",
    "cab": "536706",
    "cap": "24020"
  },
  {
    "key": "15813",
    "ITA": "VALBONDIONE-LIZZOLA",
    "state_code": "BG",
    "cab": "536706",
    "cap": "24020"
  },
  {
    "key": "15814",
    "ITA": "VALBONDIONE-FIUMENERO",
    "state_code": "BG",
    "cab": "536706",
    "cap": "24020"
  },
  {
    "key": "15815",
    "ITA": "VALBREMBO",
    "state_code": "BG",
    "cab": "891507",
    "cap": "24030"
  },
  {
    "key": "15816",
    "ITA": "VALBREMBO-SCANO AL BREMBO",
    "state_code": "BG",
    "cab": "891507",
    "cap": "24030"
  },
  {
    "key": "15817",
    "ITA": "VALBREVENNA-CARSI",
    "state_code": "GE",
    "cab": "903286",
    "cap": "16010"
  },
  {
    "key": "15818",
    "ITA": "VALBREVENNA",
    "state_code": "GE",
    "cab": "903286",
    "cap": "16010"
  },
  {
    "key": "15819",
    "ITA": "VALBRONA",
    "state_code": "CO",
    "cab": "518001",
    "cap": "22039"
  },
  {
    "key": "15820",
    "ITA": "VALBRONA-OSIGO",
    "state_code": "CO",
    "cab": "518001",
    "cap": "22039"
  },
  {
    "key": "15821",
    "ITA": "VALDA",
    "state_code": "TN",
    "cab": "905182",
    "cap": "38030"
  },
  {
    "key": "15822",
    "ITA": "VALDAGNO-MAGLIO DI SOPRA",
    "state_code": "VI",
    "cab": "608208",
    "cap": "36078"
  },
  {
    "key": "15823",
    "ITA": "VALDAGNO-NOVALE",
    "state_code": "VI",
    "cab": "608208",
    "cap": "36078"
  },
  {
    "key": "15824",
    "ITA": "VALDAGNO-PIANA",
    "state_code": "VI",
    "cab": "608208",
    "cap": "36078"
  },
  {
    "key": "15825",
    "ITA": "VALDAGNO",
    "state_code": "VI",
    "cab": "608208",
    "cap": "36078"
  },
  {
    "key": "15826",
    "ITA": "VALDAGNO-CASTELVECCHIO",
    "state_code": "VI",
    "cab": "608208",
    "cap": "36078"
  },
  {
    "key": "15827",
    "ITA": "VALDAGNO-SAN QUIRICO",
    "state_code": "VI",
    "cab": "608208",
    "cap": "36078"
  },
  {
    "key": "15828",
    "ITA": "VALDAORA",
    "state_code": "BZ",
    "cab": "590406",
    "cap": "39030"
  },
  {
    "key": "15829",
    "ITA": "VALDAORA-VALDAORA DI MEZZO",
    "state_code": "BZ",
    "cab": "590406",
    "cap": "39030"
  },
  {
    "key": "15830",
    "ITA": "VALDAORA-VALDAORA DI SOTTO",
    "state_code": "BZ",
    "cab": "590406",
    "cap": "39030"
  },
  {
    "key": "15831",
    "ITA": "VALDASTICO-BARCAROLA",
    "state_code": "VI",
    "cab": "609404",
    "cap": "36040"
  },
  {
    "key": "15832",
    "ITA": "VALDASTICO-PEDESCALA",
    "state_code": "VI",
    "cab": "609404",
    "cap": "36040"
  },
  {
    "key": "15833",
    "ITA": "VALDASTICO-SAN PIETRO VALDASTICO",
    "state_code": "VI",
    "cab": "609404",
    "cap": "36040"
  },
  {
    "key": "15834",
    "ITA": "VALDASTICO",
    "state_code": "VI",
    "cab": "609404",
    "cap": "36040"
  },
  {
    "key": "15835",
    "ITA": "VALDENGO",
    "state_code": "BI",
    "cab": "448704",
    "cap": "13855"
  },
  {
    "key": "15836",
    "ITA": "VALDERICE-CHIESANUOVA",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91010"
  },
  {
    "key": "15837",
    "ITA": "VALDERICE-CROCCI",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91019"
  },
  {
    "key": "15838",
    "ITA": "VALDERICE-CROCEVIE",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91019"
  },
  {
    "key": "15839",
    "ITA": "VALDERICE-FICO",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91019"
  },
  {
    "key": "15840",
    "ITA": "VALDERICE-BONAGIA",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91019"
  },
  {
    "key": "15841",
    "ITA": "VALDERICE-TONNARA DI BONAGIA",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91019"
  },
  {
    "key": "15842",
    "ITA": "VALDERICE",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91019"
  },
  {
    "key": "15843",
    "ITA": "VALDERICE-SANT'ANDREA BONAGIA",
    "state_code": "TP",
    "cab": "819706",
    "cap": "91019"
  },
  {
    "key": "15844",
    "ITA": "VALDIDENTRO-BAGNI NUOVI",
    "state_code": "SO",
    "cab": "523001",
    "cap": "23038"
  },
  {
    "key": "15845",
    "ITA": "VALDIDENTRO-SEMOGO",
    "state_code": "SO",
    "cab": "523001",
    "cap": "23038"
  },
  {
    "key": "15846",
    "ITA": "VALDIDENTRO-ISOLACCIA",
    "state_code": "SO",
    "cab": "523001",
    "cap": "23038"
  },
  {
    "key": "15847",
    "ITA": "VALDIDENTRO",
    "state_code": "SO",
    "cab": "523001",
    "cap": "23038"
  },
  {
    "key": "15848",
    "ITA": "VALDIERI",
    "state_code": "CN",
    "cab": "469106",
    "cap": "12010"
  },
  {
    "key": "15849",
    "ITA": "VALDIERI-ANDONNO",
    "state_code": "CN",
    "cab": "469106",
    "cap": "12010"
  },
  {
    "key": "15850",
    "ITA": "VALDIERI-SANT'ANNA",
    "state_code": "CN",
    "cab": "469106",
    "cap": "12010"
  },
  {
    "key": "15851",
    "ITA": "VALDINA",
    "state_code": "ME",
    "cab": "961649",
    "cap": "98040"
  },
  {
    "key": "15852",
    "ITA": "VALDINA-FONDACHELLO",
    "state_code": "ME",
    "cab": "961649",
    "cap": "98040"
  },
  {
    "key": "15853",
    "ITA": "VALDISOTTO-PIATTA",
    "state_code": "SO",
    "cab": "523100",
    "cap": "23030"
  },
  {
    "key": "15854",
    "ITA": "VALDISOTTO-CEPINA",
    "state_code": "SO",
    "cab": "523100",
    "cap": "23030"
  },
  {
    "key": "15855",
    "ITA": "VALDISOTTO",
    "state_code": "SO",
    "cab": "523100",
    "cap": "23030"
  },
  {
    "key": "15856",
    "ITA": "VALDOBBIADENE",
    "state_code": "TV",
    "cab": "621508",
    "cap": "31049"
  },
  {
    "key": "15857",
    "ITA": "VALDOBBIADENE-BIGOLINO",
    "state_code": "TV",
    "cab": "621508",
    "cap": "31030"
  },
  {
    "key": "15858",
    "ITA": "VALDOBBIADENE-PIANEZZE",
    "state_code": "TV",
    "cab": "621508",
    "cap": "31049"
  },
  {
    "key": "15859",
    "ITA": "VALDOBBIADENE-SAN PIETRO DI BARBOZZA",
    "state_code": "TV",
    "cab": "621508",
    "cap": "31049"
  },
  {
    "key": "15860",
    "ITA": "VALDOBBIADENE-SAN VITO DI VALDOBBIADENE",
    "state_code": "TV",
    "cab": "621508",
    "cap": "31049"
  },
  {
    "key": "15861",
    "ITA": "VALDOBBIADENE-SANTO STEFANO",
    "state_code": "TV",
    "cab": "621508",
    "cap": "31049"
  },
  {
    "key": "15862",
    "ITA": "VALDOBBIADENE-GUIA",
    "state_code": "TV",
    "cab": "621508",
    "cap": "31049"
  },
  {
    "key": "15863",
    "ITA": "VALDUGGIA-ZUCCARO",
    "state_code": "VC",
    "cab": "448803",
    "cap": "13018"
  },
  {
    "key": "15864",
    "ITA": "VALDUGGIA",
    "state_code": "VC",
    "cab": "448803",
    "cap": "13018"
  },
  {
    "key": "15865",
    "ITA": "VALEGGIO",
    "state_code": "PV",
    "cab": "931014",
    "cap": "27020"
  },
  {
    "key": "15866",
    "ITA": "VALEGGIO SUL MINCIO",
    "state_code": "VR",
    "cab": "599308",
    "cap": "37067"
  },
  {
    "key": "15867",
    "ITA": "VALEGGIO SUL MINCIO-SALIONZE",
    "state_code": "VR",
    "cab": "599308",
    "cap": "37067"
  },
  {
    "key": "15868",
    "ITA": "VALENTANO",
    "state_code": "VT",
    "cab": "733105",
    "cap": "1018"
  },
  {
    "key": "15869",
    "ITA": "VALENZA-VILLABELLA",
    "state_code": "AL",
    "cab": "486803",
    "cap": "15048"
  },
  {
    "key": "15870",
    "ITA": "VALENZA-MONTE",
    "state_code": "AL",
    "cab": "486803",
    "cap": "15048"
  },
  {
    "key": "15871",
    "ITA": "VALENZA",
    "state_code": "AL",
    "cab": "486803",
    "cap": "15048"
  },
  {
    "key": "15872",
    "ITA": "VALENZANO",
    "state_code": "BA",
    "cab": "417501",
    "cap": "70010"
  },
  {
    "key": "15873",
    "ITA": "VALERA FRATTA",
    "state_code": "LO",
    "cab": "339606",
    "cap": "26859"
  },
  {
    "key": "15874",
    "ITA": "VALFABBRICA",
    "state_code": "PG",
    "cab": "387506",
    "cap": "6029"
  },
  {
    "key": "15875",
    "ITA": "VALFABBRICA-CASA CASTALDA",
    "state_code": "PG",
    "cab": "387506",
    "cap": "6029"
  },
  {
    "key": "15876",
    "ITA": "VALFENERA",
    "state_code": "AT",
    "cab": "477406",
    "cap": "14017"
  },
  {
    "key": "15877",
    "ITA": "VALFLORIANA",
    "state_code": "TN",
    "cab": "357103",
    "cap": "38040"
  },
  {
    "key": "15878",
    "ITA": "VALFURVA-SANT'ANTONIO",
    "state_code": "SO",
    "cab": "523209",
    "cap": "23030"
  },
  {
    "key": "15879",
    "ITA": "VALFURVA-SANTA CATERINA",
    "state_code": "SO",
    "cab": "523209",
    "cap": "23030"
  },
  {
    "key": "15880",
    "ITA": "VALFURVA-SAN NICOLO' DI VALFURVA",
    "state_code": "SO",
    "cab": "523209",
    "cap": "23030"
  },
  {
    "key": "15881",
    "ITA": "VALFURVA",
    "state_code": "SO",
    "cab": "523209",
    "cap": "23030"
  },
  {
    "key": "15882",
    "ITA": "VALGANNA-GANNA",
    "state_code": "VA",
    "cab": "924753",
    "cap": "21039"
  },
  {
    "key": "15883",
    "ITA": "VALGANNA",
    "state_code": "VA",
    "cab": "924753",
    "cap": "21039"
  },
  {
    "key": "15884",
    "ITA": "VALGANNA-GHIRLA",
    "state_code": "VA",
    "cab": "924753",
    "cap": "21030"
  },
  {
    "key": "15885",
    "ITA": "VALGIOIE",
    "state_code": "TO",
    "cab": "901736",
    "cap": "10094"
  },
  {
    "key": "15886",
    "ITA": "VALGOGLIO",
    "state_code": "BG",
    "cab": "703207",
    "cap": "24020"
  },
  {
    "key": "15887",
    "ITA": "VALGRANA",
    "state_code": "CN",
    "cab": "469205",
    "cap": "12020"
  },
  {
    "key": "15888",
    "ITA": "VALGREGHENTINO",
    "state_code": "LC",
    "cab": "817601",
    "cap": "23857"
  },
  {
    "key": "15889",
    "ITA": "VALGRISENCHE",
    "state_code": "AO",
    "cab": "902684",
    "cap": "11010"
  },
  {
    "key": "15890",
    "ITA": "VALGUARNERA CAROPEPE",
    "state_code": "EN",
    "cab": "837302",
    "cap": "94019"
  },
  {
    "key": "15891",
    "ITA": "VALLADA AGORDINA",
    "state_code": "BL",
    "cab": "717009",
    "cap": "32020"
  },
  {
    "key": "15892",
    "ITA": "VALLANZENGO",
    "state_code": "BI",
    "cab": "916452",
    "cap": "13847"
  },
  {
    "key": "15893",
    "ITA": "VALLARSA-VALMORBIA",
    "state_code": "TN",
    "cab": "357202",
    "cap": "38060"
  },
  {
    "key": "15894",
    "ITA": "VALLARSA-ANGHEBENI",
    "state_code": "TN",
    "cab": "357202",
    "cap": "38060"
  },
  {
    "key": "15895",
    "ITA": "VALLARSA",
    "state_code": "TN",
    "cab": "357202",
    "cap": "38060"
  },
  {
    "key": "15896",
    "ITA": "VALLARSA-RIVA DI VALLARSA",
    "state_code": "TN",
    "cab": "357202",
    "cap": "38060"
  },
  {
    "key": "15897",
    "ITA": "VALLARSA-RAOSSI",
    "state_code": "TN",
    "cab": "357202",
    "cap": "38060"
  },
  {
    "key": "15898",
    "ITA": "VALLARSA-CORTE",
    "state_code": "TN",
    "cab": "357202",
    "cap": "38060"
  },
  {
    "key": "15899",
    "ITA": "VALLATA",
    "state_code": "AV",
    "cab": "758300",
    "cap": "83059"
  },
  {
    "key": "15900",
    "ITA": "VALLE AGRICOLA",
    "state_code": "CE",
    "cab": "949701",
    "cap": "81010"
  },
  {
    "key": "15901",
    "ITA": "VALLE AURINA",
    "state_code": "BZ",
    "cab": "590604",
    "cap": "39030"
  },
  {
    "key": "15902",
    "ITA": "VALLE AURINA-CADIPIETRA",
    "state_code": "BZ",
    "cab": "590604",
    "cap": "39030"
  },
  {
    "key": "15903",
    "ITA": "VALLE AURINA-LUTAGO",
    "state_code": "BZ",
    "cab": "590604",
    "cap": "39030"
  },
  {
    "key": "15904",
    "ITA": "VALLE AURINA-SAN GIACOMO IN VALLE AURINA",
    "state_code": "BZ",
    "cab": "590604",
    "cap": "39030"
  },
  {
    "key": "15905",
    "ITA": "VALLE AURINA-SAN GIOVANNI IN VALLE AURINA",
    "state_code": "BZ",
    "cab": "590604",
    "cap": "39030"
  },
  {
    "key": "15906",
    "ITA": "VALLE CASTELLANA",
    "state_code": "TE",
    "cab": "770909",
    "cap": "64010"
  },
  {
    "key": "15907",
    "ITA": "VALLE CASTELLANA-CESANO",
    "state_code": "TE",
    "cab": "770909",
    "cap": "64010"
  },
  {
    "key": "15908",
    "ITA": "VALLE CASTELLANA-SAN VITO",
    "state_code": "TE",
    "cab": "770909",
    "cap": "64010"
  },
  {
    "key": "15909",
    "ITA": "VALLE CASTELLANA-PASCELLATA",
    "state_code": "TE",
    "cab": "770909",
    "cap": "64010"
  },
  {
    "key": "15910",
    "ITA": "VALLE CASTELLANA-PIETRALTA",
    "state_code": "TE",
    "cab": "770909",
    "cap": "64010"
  },
  {
    "key": "15911",
    "ITA": "VALLE CASTELLANA-LEOFARA",
    "state_code": "TE",
    "cab": "770909",
    "cap": "64010"
  },
  {
    "key": "15912",
    "ITA": "VALLE DELL'ANGELO",
    "state_code": "SA",
    "cab": "953208",
    "cap": "84070"
  },
  {
    "key": "15913",
    "ITA": "VALLE DI CADORE",
    "state_code": "BL",
    "cab": "655407",
    "cap": "32040"
  },
  {
    "key": "15914",
    "ITA": "VALLE DI CADORE-VENAS",
    "state_code": "BL",
    "cab": "655407",
    "cap": "32040"
  },
  {
    "key": "15915",
    "ITA": "VALLE DI CASIES",
    "state_code": "BZ",
    "cab": "590703",
    "cap": "39030"
  },
  {
    "key": "15916",
    "ITA": "VALLE DI CASIES-SAN MARTINO IN CASIES",
    "state_code": "BZ",
    "cab": "590703",
    "cap": "39030"
  },
  {
    "key": "15917",
    "ITA": "VALLE DI CASIES-SANTA MADDALENA",
    "state_code": "BZ",
    "cab": "590703",
    "cap": "39030"
  },
  {
    "key": "15918",
    "ITA": "VALLE DI MADDALONI",
    "state_code": "CE",
    "cab": "949719",
    "cap": "81020"
  },
  {
    "key": "15919",
    "ITA": "VALLE LOMELLINA",
    "state_code": "PV",
    "cab": "563502",
    "cap": "27020"
  },
  {
    "key": "15920",
    "ITA": "VALLE MOSSO-CROCEMOSSO",
    "state_code": "BI",
    "cab": "448902",
    "cap": "13825"
  },
  {
    "key": "15921",
    "ITA": "VALLE MOSSO-CAMPORE",
    "state_code": "BI",
    "cab": "448902",
    "cap": "13825"
  },
  {
    "key": "15922",
    "ITA": "VALLE MOSSO",
    "state_code": "BI",
    "cab": "448902",
    "cap": "13825"
  },
  {
    "key": "15923",
    "ITA": "VALLE SALIMBENE-MOTTA SAN DAMIANO",
    "state_code": "PV",
    "cab": "179879",
    "cap": "27010"
  },
  {
    "key": "15924",
    "ITA": "VALLE SALIMBENE",
    "state_code": "PV",
    "cab": "179879",
    "cap": "27010"
  },
  {
    "key": "15925",
    "ITA": "VALLE SAN NICOLAO",
    "state_code": "BI",
    "cab": "916460",
    "cap": "13847"
  },
  {
    "key": "15926",
    "ITA": "VALLEBONA",
    "state_code": "IM",
    "cab": "922658",
    "cap": "18012"
  },
  {
    "key": "15927",
    "ITA": "VALLECORSA",
    "state_code": "FR",
    "cab": "746206",
    "cap": "3020"
  },
  {
    "key": "15928",
    "ITA": "VALLECROSIA-VALLECROSIA ALTA",
    "state_code": "IM",
    "cab": "491001",
    "cap": "18019"
  },
  {
    "key": "15929",
    "ITA": "VALLECROSIA",
    "state_code": "IM",
    "cab": "491001",
    "cap": "18019"
  },
  {
    "key": "15930",
    "ITA": "VALLEDOLMO",
    "state_code": "PA",
    "cab": "437004",
    "cap": "90029"
  },
  {
    "key": "15931",
    "ITA": "VALLEDORIA-LA MUDDIZZA",
    "state_code": "SS",
    "cab": "876607",
    "cap": "7039"
  },
  {
    "key": "15932",
    "ITA": "VALLEDORIA-CODARUINA",
    "state_code": "SS",
    "cab": "876607",
    "cap": "7039"
  },
  {
    "key": "15933",
    "ITA": "VALLEDORIA",
    "state_code": "SS",
    "cab": "876607",
    "cap": "7039"
  },
  {
    "key": "15934",
    "ITA": "VALLEFIORITA",
    "state_code": "CZ",
    "cab": "913673",
    "cap": "88050"
  },
  {
    "key": "15935",
    "ITA": "VALLEFOGLIA",
    "state_code": "PU",
    "cab": "686006",
    "cap": "61022"
  },
  {
    "key": "15936",
    "ITA": "VALLELONGA",
    "state_code": "VV",
    "cab": "913681",
    "cap": "89821"
  },
  {
    "key": "15937",
    "ITA": "VALLELUNGA PRATAMENO",
    "state_code": "CL",
    "cab": "834705",
    "cap": "93010"
  },
  {
    "key": "15938",
    "ITA": "VALLEMAIO",
    "state_code": "FR",
    "cab": "948646",
    "cap": "3040"
  },
  {
    "key": "15939",
    "ITA": "VALLEPIETRA",
    "state_code": "RM",
    "cab": "908418",
    "cap": "20"
  },
  {
    "key": "15940",
    "ITA": "VALLERANO",
    "state_code": "VT",
    "cab": "733204",
    "cap": "1030"
  },
  {
    "key": "15941",
    "ITA": "VALLERMOSA",
    "state_code": "CA",
    "cab": "863803",
    "cap": "9010"
  },
  {
    "key": "15942",
    "ITA": "VALLEROTONDA",
    "state_code": "FR",
    "cab": "897108",
    "cap": "3040"
  },
  {
    "key": "15943",
    "ITA": "VALLEROTONDA-CARDITO DI VALLEROTONDA",
    "state_code": "FR",
    "cab": "897108",
    "cap": "3040"
  },
  {
    "key": "15944",
    "ITA": "VALLEROTONDA-VALVORI",
    "state_code": "FR",
    "cab": "897108",
    "cap": "3040"
  },
  {
    "key": "15945",
    "ITA": "VALLEROTONDA-CERRETO DI VALLEROTONDA",
    "state_code": "FR",
    "cab": "897108",
    "cap": "3040"
  },
  {
    "key": "15946",
    "ITA": "VALLESACCARDA",
    "state_code": "AV",
    "cab": "499400",
    "cap": "83050"
  },
  {
    "key": "15947",
    "ITA": "VALLEVE",
    "state_code": "BG",
    "cab": "812305",
    "cap": "24010"
  },
  {
    "key": "15948",
    "ITA": "VALLI DEL PASUBIO-SANT'ANTONIO",
    "state_code": "VI",
    "cab": "608307",
    "cap": "36030"
  },
  {
    "key": "15949",
    "ITA": "VALLI DEL PASUBIO-STARO",
    "state_code": "VI",
    "cab": "608307",
    "cap": "36030"
  },
  {
    "key": "15950",
    "ITA": "VALLI DEL PASUBIO",
    "state_code": "VI",
    "cab": "608307",
    "cap": "36030"
  },
  {
    "key": "15951",
    "ITA": "VALLINFREDA",
    "state_code": "RM",
    "cab": "908426",
    "cap": "20"
  },
  {
    "key": "15952",
    "ITA": "VALLIO TERME-CASE NUOVE",
    "state_code": "BS",
    "cab": "929737",
    "cap": "25080"
  },
  {
    "key": "15953",
    "ITA": "VALLIO TERME",
    "state_code": "BS",
    "cab": "929737",
    "cap": "25080"
  },
  {
    "key": "15954",
    "ITA": "VALLO DELLA LUCANIA",
    "state_code": "SA",
    "cab": "765305",
    "cap": "84078"
  },
  {
    "key": "15955",
    "ITA": "VALLO DELLA LUCANIA-ANGELLARA",
    "state_code": "SA",
    "cab": "765305",
    "cap": "84078"
  },
  {
    "key": "15956",
    "ITA": "VALLO DELLA LUCANIA-MASSA DELLA LUCANIA",
    "state_code": "SA",
    "cab": "765305",
    "cap": "84078"
  },
  {
    "key": "15957",
    "ITA": "VALLO DELLA LUCANIA-PATTANO",
    "state_code": "SA",
    "cab": "765305",
    "cap": "84078"
  },
  {
    "key": "15958",
    "ITA": "VALLO DI NERA",
    "state_code": "PG",
    "cab": "907642",
    "cap": "6040"
  },
  {
    "key": "15959",
    "ITA": "VALLO DI NERA-PIEDIPATERNO",
    "state_code": "PG",
    "cab": "907642",
    "cap": "6040"
  },
  {
    "key": "15960",
    "ITA": "VALLO TORINESE",
    "state_code": "TO",
    "cab": "901744",
    "cap": "10070"
  },
  {
    "key": "15961",
    "ITA": "VALLORIATE",
    "state_code": "CN",
    "cab": "919530",
    "cap": "12010"
  },
  {
    "key": "15962",
    "ITA": "VALMACCA",
    "state_code": "AL",
    "cab": "486902",
    "cap": "15040"
  },
  {
    "key": "15963",
    "ITA": "VALMADRERA",
    "state_code": "LC",
    "cab": "518100",
    "cap": "23868"
  },
  {
    "key": "15964",
    "ITA": "VALMADRERA-CASERTA",
    "state_code": "LC",
    "cab": "518100",
    "cap": "23868"
  },
  {
    "key": "15965",
    "ITA": "VALMALA",
    "state_code": "CN",
    "cab": "919548",
    "cap": "12020"
  },
  {
    "key": "15966",
    "ITA": "VALMONTONE",
    "state_code": "RM",
    "cab": "394809",
    "cap": "38"
  },
  {
    "key": "15967",
    "ITA": "VALMOREA",
    "state_code": "CO",
    "cab": "323303",
    "cap": "22070"
  },
  {
    "key": "15968",
    "ITA": "VALMOREA-CASANOVA LANZA",
    "state_code": "CO",
    "cab": "323303",
    "cap": "22070"
  },
  {
    "key": "15969",
    "ITA": "VALMOZZOLA",
    "state_code": "PR",
    "cab": "660100",
    "cap": "43050"
  },
  {
    "key": "15970",
    "ITA": "VALMOZZOLA-MORMOROLA",
    "state_code": "PR",
    "cab": "660100",
    "cap": "43050"
  },
  {
    "key": "15971",
    "ITA": "VALMOZZOLA-SAN MARTINO DI VALMOZZOLA",
    "state_code": "PR",
    "cab": "660100",
    "cap": "43050"
  },
  {
    "key": "15972",
    "ITA": "VALMOZZOLA-STAZIONE VALMOZZOLA",
    "state_code": "PR",
    "cab": "660100",
    "cap": "43050"
  },
  {
    "key": "15973",
    "ITA": "VALNEGRA",
    "state_code": "BG",
    "cab": "928739",
    "cap": "24010"
  },
  {
    "key": "15974",
    "ITA": "VALPELLINE",
    "state_code": "AO",
    "cab": "902692",
    "cap": "11010"
  },
  {
    "key": "15975",
    "ITA": "VALPERGA",
    "state_code": "TO",
    "cab": "310904",
    "cap": "10087"
  },
  {
    "key": "15976",
    "ITA": "VALPRATO SOANA",
    "state_code": "TO",
    "cab": "181149",
    "cap": "10080"
  },
  {
    "key": "15977",
    "ITA": "VALSAMOGGIA",
    "state_code": "BO",
    "cab": "367805",
    "cap": "40050"
  },
  {
    "key": "15978",
    "ITA": "VALSAVARENCHE",
    "state_code": "AO",
    "cab": "902700",
    "cap": "11010"
  },
  {
    "key": "15979",
    "ITA": "VALSECCA",
    "state_code": "BG",
    "cab": "928747",
    "cap": "24030"
  },
  {
    "key": "15980",
    "ITA": "VALSINNI",
    "state_code": "MT",
    "cab": "886804",
    "cap": "75029"
  },
  {
    "key": "15981",
    "ITA": "VALSOLDA",
    "state_code": "CO",
    "cab": "518209",
    "cap": "22010"
  },
  {
    "key": "15982",
    "ITA": "VALSOLDA-ALBOGASIO",
    "state_code": "CO",
    "cab": "518209",
    "cap": "22010"
  },
  {
    "key": "15983",
    "ITA": "VALSOLDA-ORIA",
    "state_code": "CO",
    "cab": "518209",
    "cap": "22010"
  },
  {
    "key": "15984",
    "ITA": "VALSTAGNA",
    "state_code": "VI",
    "cab": "608406",
    "cap": "36020"
  },
  {
    "key": "15985",
    "ITA": "VALSTRONA",
    "state_code": "VB",
    "cab": "317800",
    "cap": "28897"
  },
  {
    "key": "15986",
    "ITA": "VALSTRONA-SAMBUGHETTO",
    "state_code": "VB",
    "cab": "317800",
    "cap": "28897"
  },
  {
    "key": "15987",
    "ITA": "VALSTRONA-LUZZOGNO",
    "state_code": "VB",
    "cab": "317800",
    "cap": "28897"
  },
  {
    "key": "15988",
    "ITA": "VALSTRONA-FORNERO",
    "state_code": "VB",
    "cab": "317800",
    "cap": "28897"
  },
  {
    "key": "15989",
    "ITA": "VALTOPINA",
    "state_code": "PG",
    "cab": "387605",
    "cap": "6030"
  },
  {
    "key": "15990",
    "ITA": "VALTORTA",
    "state_code": "BG",
    "cab": "928754",
    "cap": "24010"
  },
  {
    "key": "15991",
    "ITA": "VALTOURNENCHE-BREUIL",
    "state_code": "AO",
    "cab": "316802",
    "cap": "11021"
  },
  {
    "key": "15992",
    "ITA": "VALTOURNENCHE",
    "state_code": "AO",
    "cab": "316802",
    "cap": "11028"
  },
  {
    "key": "15993",
    "ITA": "VALTOURNENCHE-CERVINIA",
    "state_code": "AO",
    "cab": "316802",
    "cap": "11021"
  },
  {
    "key": "15994",
    "ITA": "VALTOURNENCHE-PAQUIER",
    "state_code": "AO",
    "cab": "316802",
    "cap": "11028"
  },
  {
    "key": "15995",
    "ITA": "VALVA",
    "state_code": "SA",
    "cab": "953216",
    "cap": "84020"
  },
  {
    "key": "15996",
    "ITA": "VALVASONE",
    "state_code": "PN",
    "cab": "650507",
    "cap": "33098"
  },
  {
    "key": "15997",
    "ITA": "VALVERDE",
    "state_code": "CT",
    "cab": "843003",
    "cap": "95028"
  },
  {
    "key": "15998",
    "ITA": "VALVERDE",
    "state_code": "PV",
    "cab": "931030",
    "cap": "27050"
  },
  {
    "key": "15999",
    "ITA": "VALVESTINO-MOERNA",
    "state_code": "BS",
    "cab": "553602",
    "cap": "25080"
  },
  {
    "key": "16000",
    "ITA": "VALVESTINO",
    "state_code": "BS",
    "cab": "553602",
    "cap": "25080"
  },
  {
    "key": "16001",
    "ITA": "VANDOIES",
    "state_code": "BZ",
    "cab": "590802",
    "cap": "39030"
  },
  {
    "key": "16002",
    "ITA": "VANDOIES-FUNDRES",
    "state_code": "BZ",
    "cab": "590802",
    "cap": "39030"
  },
  {
    "key": "16003",
    "ITA": "VANDOIES-VALLARGA",
    "state_code": "BZ",
    "cab": "590802",
    "cap": "39030"
  },
  {
    "key": "16004",
    "ITA": "VANDOIES-VANDOIES DI SOPRA",
    "state_code": "BZ",
    "cab": "590802",
    "cap": "39030"
  },
  {
    "key": "16005",
    "ITA": "VANDOIES-VANDOIES DI SOTTO",
    "state_code": "BZ",
    "cab": "590802",
    "cap": "39030"
  },
  {
    "key": "16006",
    "ITA": "VANZAGHELLO",
    "state_code": "MI",
    "cab": "341206",
    "cap": "20020"
  },
  {
    "key": "16007",
    "ITA": "VANZAGO",
    "state_code": "MI",
    "cab": "339705",
    "cap": "20010"
  },
  {
    "key": "16008",
    "ITA": "VANZAGO-MANTEGAZZA",
    "state_code": "MI",
    "cab": "339705",
    "cap": "20010"
  },
  {
    "key": "16009",
    "ITA": "VANZONE CON SAN CARLO-SAN CARLO",
    "state_code": "VB",
    "cab": "917799",
    "cap": "28879"
  },
  {
    "key": "16010",
    "ITA": "VANZONE CON SAN CARLO",
    "state_code": "VB",
    "cab": "917799",
    "cap": "28879"
  },
  {
    "key": "16011",
    "ITA": "VAPRIO D'ADDA",
    "state_code": "MI",
    "cab": "339804",
    "cap": "20069"
  },
  {
    "key": "16012",
    "ITA": "VAPRIO D'AGOGNA",
    "state_code": "NO",
    "cab": "917807",
    "cap": "28010"
  },
  {
    "key": "16013",
    "ITA": "VARALLO-VALMAGGIA",
    "state_code": "VC",
    "cab": "449009",
    "cap": "13019"
  },
  {
    "key": "16014",
    "ITA": "VARALLO",
    "state_code": "VC",
    "cab": "449009",
    "cap": "13019"
  },
  {
    "key": "16015",
    "ITA": "VARALLO-MORCA",
    "state_code": "VC",
    "cab": "449009",
    "cap": "13019"
  },
  {
    "key": "16016",
    "ITA": "VARALLO-ROCCAPIETRA",
    "state_code": "VC",
    "cab": "449009",
    "cap": "13019"
  },
  {
    "key": "16017",
    "ITA": "VARALLO POMBIA",
    "state_code": "NO",
    "cab": "457200",
    "cap": "28040"
  },
  {
    "key": "16018",
    "ITA": "VARANO BORGHI",
    "state_code": "VA",
    "cab": "506105",
    "cap": "21020"
  },
  {
    "key": "16019",
    "ITA": "VARANO DE' MELEGARI",
    "state_code": "PR",
    "cab": "660209",
    "cap": "43040"
  },
  {
    "key": "16020",
    "ITA": "VARANO DE' MELEGARI-VIAZZANO",
    "state_code": "PR",
    "cab": "660209",
    "cap": "43040"
  },
  {
    "key": "16021",
    "ITA": "VARANO DE' MELEGARI-VIANINO",
    "state_code": "PR",
    "cab": "660209",
    "cap": "43040"
  },
  {
    "key": "16022",
    "ITA": "VARAPODIO",
    "state_code": "RC",
    "cab": "816504",
    "cap": "89010"
  },
  {
    "key": "16023",
    "ITA": "VARAZZE",
    "state_code": "SV",
    "cab": "495408",
    "cap": "17019"
  },
  {
    "key": "16024",
    "ITA": "VARAZZE-PERO",
    "state_code": "SV",
    "cab": "495408",
    "cap": "17019"
  },
  {
    "key": "16025",
    "ITA": "VARAZZE-FAIE",
    "state_code": "SV",
    "cab": "495408",
    "cap": "17019"
  },
  {
    "key": "16026",
    "ITA": "VARAZZE-ALPICELLA",
    "state_code": "SV",
    "cab": "495408",
    "cap": "17019"
  },
  {
    "key": "16027",
    "ITA": "VARAZZE-CASANOVA",
    "state_code": "SV",
    "cab": "495408",
    "cap": "17019"
  },
  {
    "key": "16028",
    "ITA": "VARCO SABINO",
    "state_code": "RI",
    "cab": "947465",
    "cap": "2020"
  },
  {
    "key": "16029",
    "ITA": "VAREDO-VALERA",
    "state_code": "MB",
    "cab": "339903",
    "cap": "20039"
  },
  {
    "key": "16030",
    "ITA": "VAREDO",
    "state_code": "MB",
    "cab": "339903",
    "cap": "20039"
  },
  {
    "key": "16031",
    "ITA": "VARENA",
    "state_code": "TN",
    "cab": "357301",
    "cap": "38030"
  },
  {
    "key": "16032",
    "ITA": "VARENNA-FIUMELATTE",
    "state_code": "LC",
    "cab": "518308",
    "cap": "23829"
  },
  {
    "key": "16033",
    "ITA": "VARENNA",
    "state_code": "LC",
    "cab": "518308",
    "cap": "23829"
  },
  {
    "key": "16034",
    "ITA": "VARESE-SANTA MARIA DEL MONTE",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16035",
    "ITA": "VARESE-SANT'AMBROGIO OLONA",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16036",
    "ITA": "VARESE-SAN FERMO",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16037",
    "ITA": "VARESE-RASA DI VELATE",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16038",
    "ITA": "VARESE-MASNAGO",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16039",
    "ITA": "VARESE",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16040",
    "ITA": "VARESE-BIZZOZERO",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16041",
    "ITA": "VARESE-CALCINATE DEL PESCE",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16042",
    "ITA": "VARESE-CAPOLAGO",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16043",
    "ITA": "VARESE-CARTABBIA",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16044",
    "ITA": "VARESE-CASBENO",
    "state_code": "VA",
    "cab": "108001",
    "cap": "21100"
  },
  {
    "key": "16045",
    "ITA": "VARESE LIGURE-SCURTABO'",
    "state_code": "SP",
    "cab": "498600",
    "cap": "19020"
  },
  {
    "key": "16046",
    "ITA": "VARESE LIGURE-SAN PIETRO VARA",
    "state_code": "SP",
    "cab": "498600",
    "cap": "19028"
  },
  {
    "key": "16047",
    "ITA": "VARESE LIGURE-PORCIORASCO",
    "state_code": "SP",
    "cab": "498600",
    "cap": "19028"
  },
  {
    "key": "16048",
    "ITA": "VARESE LIGURE-COMUNEGLIA",
    "state_code": "SP",
    "cab": "498600",
    "cap": "19028"
  },
  {
    "key": "16049",
    "ITA": "VARESE LIGURE",
    "state_code": "SP",
    "cab": "498600",
    "cap": "19028"
  },
  {
    "key": "16050",
    "ITA": "VARISELLA",
    "state_code": "TO",
    "cab": "901769",
    "cap": "10070"
  },
  {
    "key": "16051",
    "ITA": "VARMO",
    "state_code": "UD",
    "cab": "643700",
    "cap": "33030"
  },
  {
    "key": "16052",
    "ITA": "VARMO-CANUSSIO",
    "state_code": "UD",
    "cab": "643700",
    "cap": "33030"
  },
  {
    "key": "16053",
    "ITA": "VARMO-ROMANS",
    "state_code": "UD",
    "cab": "643700",
    "cap": "33030"
  },
  {
    "key": "16054",
    "ITA": "VARMO-ROVEREDO",
    "state_code": "UD",
    "cab": "643700",
    "cap": "33030"
  },
  {
    "key": "16055",
    "ITA": "VARNA-NOVACELLA",
    "state_code": "BZ",
    "cab": "590901",
    "cap": "39040"
  },
  {
    "key": "16056",
    "ITA": "VARNA-LEONE SANTA ELISABETTA",
    "state_code": "BZ",
    "cab": "590901",
    "cap": "39040"
  },
  {
    "key": "16057",
    "ITA": "VARNA",
    "state_code": "BZ",
    "cab": "590901",
    "cap": "39040"
  },
  {
    "key": "16058",
    "ITA": "VARSI",
    "state_code": "PR",
    "cab": "660308",
    "cap": "43049"
  },
  {
    "key": "16059",
    "ITA": "VARSI-PESSOLA",
    "state_code": "PR",
    "cab": "660308",
    "cap": "43049"
  },
  {
    "key": "16060",
    "ITA": "VARZI-PIETRAGAVINA",
    "state_code": "PV",
    "cab": "563601",
    "cap": "27057"
  },
  {
    "key": "16061",
    "ITA": "VARZI",
    "state_code": "PV",
    "cab": "563601",
    "cap": "27057"
  },
  {
    "key": "16062",
    "ITA": "VARZO",
    "state_code": "VB",
    "cab": "457309",
    "cap": "28868"
  },
  {
    "key": "16063",
    "ITA": "VAS",
    "state_code": "BL",
    "cab": "712703",
    "cap": "32030"
  },
  {
    "key": "16064",
    "ITA": "VASANELLO",
    "state_code": "VT",
    "cab": "733303",
    "cap": "1030"
  },
  {
    "key": "16065",
    "ITA": "VASIA",
    "state_code": "IM",
    "cab": "922666",
    "cap": "18020"
  },
  {
    "key": "16066",
    "ITA": "VASTO-SAN LORENZO DI VASTO",
    "state_code": "CH",
    "cab": "779108",
    "cap": "66054"
  },
  {
    "key": "16067",
    "ITA": "VASTO-MARINA DI VASTO",
    "state_code": "CH",
    "cab": "779108",
    "cap": "66054"
  },
  {
    "key": "16068",
    "ITA": "VASTO-INCORONATA DI VASTO",
    "state_code": "CH",
    "cab": "779108",
    "cap": "66054"
  },
  {
    "key": "16069",
    "ITA": "VASTO",
    "state_code": "CH",
    "cab": "779108",
    "cap": "66054"
  },
  {
    "key": "16070",
    "ITA": "VASTOGIRARDI-CERRETO",
    "state_code": "IS",
    "cab": "955930",
    "cap": "86089"
  },
  {
    "key": "16071",
    "ITA": "VASTOGIRARDI",
    "state_code": "IS",
    "cab": "955930",
    "cap": "86089"
  },
  {
    "key": "16072",
    "ITA": "VASTOGIRARDI-VILLA SAN MICHELE",
    "state_code": "IS",
    "cab": "955930",
    "cap": "86089"
  },
  {
    "key": "16073",
    "ITA": "VATTARO",
    "state_code": "TN",
    "cab": "357400",
    "cap": "38049"
  },
  {
    "key": "16074",
    "ITA": "VAUDA CANAVESE",
    "state_code": "TO",
    "cab": "805705",
    "cap": "10070"
  },
  {
    "key": "16075",
    "ITA": "VAUDA CANAVESE-VAUDA CANAVESE SUPERIORE",
    "state_code": "TO",
    "cab": "805705",
    "cap": "10070"
  },
  {
    "key": "16076",
    "ITA": "VAZZANO",
    "state_code": "VV",
    "cab": "913699",
    "cap": "89834"
  },
  {
    "key": "16077",
    "ITA": "VAZZOLA-TEZZE",
    "state_code": "TV",
    "cab": "621607",
    "cap": "31028"
  },
  {
    "key": "16078",
    "ITA": "VAZZOLA-VISNA'",
    "state_code": "TV",
    "cab": "621607",
    "cap": "31028"
  },
  {
    "key": "16079",
    "ITA": "VAZZOLA",
    "state_code": "TV",
    "cab": "621607",
    "cap": "31028"
  },
  {
    "key": "16080",
    "ITA": "VECCHIANO-MIGLIARINO",
    "state_code": "PI",
    "cab": "712000",
    "cap": "56019"
  },
  {
    "key": "16081",
    "ITA": "VECCHIANO-FILETTOLE",
    "state_code": "PI",
    "cab": "712000",
    "cap": "56019"
  },
  {
    "key": "16082",
    "ITA": "VECCHIANO-AVANE",
    "state_code": "PI",
    "cab": "712000",
    "cap": "56019"
  },
  {
    "key": "16083",
    "ITA": "VECCHIANO",
    "state_code": "PI",
    "cab": "712000",
    "cap": "56019"
  },
  {
    "key": "16084",
    "ITA": "VECCHIANO-NODICA",
    "state_code": "PI",
    "cab": "712000",
    "cap": "56019"
  },
  {
    "key": "16085",
    "ITA": "VEDANO AL LAMBRO",
    "state_code": "MB",
    "cab": "340000",
    "cap": "20057"
  },
  {
    "key": "16086",
    "ITA": "VEDANO OLONA",
    "state_code": "VA",
    "cab": "506204",
    "cap": "21040"
  },
  {
    "key": "16087",
    "ITA": "VEDDASCA",
    "state_code": "VA",
    "cab": "924761",
    "cap": "21010"
  },
  {
    "key": "16088",
    "ITA": "VEDELAGO-ALBAREDO",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16089",
    "ITA": "VEDELAGO-BARCON",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16090",
    "ITA": "VEDELAGO",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16091",
    "ITA": "VEDELAGO-FOSSALUNGA",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16092",
    "ITA": "VEDELAGO-FANZOLO",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16093",
    "ITA": "VEDELAGO-CAVASAGRA",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16094",
    "ITA": "VEDELAGO-CASACORBA",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16095",
    "ITA": "VEDELAGO-CARPENEDO",
    "state_code": "TV",
    "cab": "621706",
    "cap": "31050"
  },
  {
    "key": "16096",
    "ITA": "VEDESETA",
    "state_code": "BG",
    "cab": "928762",
    "cap": "24010"
  },
  {
    "key": "16097",
    "ITA": "VEDUGGIO CON COLZANO",
    "state_code": "MB",
    "cab": "340109",
    "cap": "20050"
  },
  {
    "key": "16098",
    "ITA": "VEGGIANO",
    "state_code": "PD",
    "cab": "655704",
    "cap": "35030"
  },
  {
    "key": "16099",
    "ITA": "VEGLIE",
    "state_code": "LE",
    "cab": "801506",
    "cap": "73010"
  },
  {
    "key": "16100",
    "ITA": "VEGLIO",
    "state_code": "BI",
    "cab": "916478",
    "cap": "13824"
  },
  {
    "key": "16101",
    "ITA": "VEJANO",
    "state_code": "VT",
    "cab": "733402",
    "cap": "1010"
  },
  {
    "key": "16102",
    "ITA": "VELESO",
    "state_code": "CO",
    "cab": "926394",
    "cap": "22020"
  },
  {
    "key": "16103",
    "ITA": "VELEZZO LOMELLINA",
    "state_code": "PV",
    "cab": "931048",
    "cap": "27020"
  },
  {
    "key": "16104",
    "ITA": "VELLETRI",
    "state_code": "RM",
    "cab": "394908",
    "cap": "49"
  },
  {
    "key": "16105",
    "ITA": "VELLEZZO BELLINI",
    "state_code": "PV",
    "cab": "472100",
    "cap": "27010"
  },
  {
    "key": "16106",
    "ITA": "VELLEZZO BELLINI-GIOVENZANO",
    "state_code": "PV",
    "cab": "472100",
    "cap": "27010"
  },
  {
    "key": "16107",
    "ITA": "VELO D'ASTICO-VELO",
    "state_code": "VI",
    "cab": "655605",
    "cap": "36010"
  },
  {
    "key": "16108",
    "ITA": "VELO D'ASTICO-SEGHE",
    "state_code": "VI",
    "cab": "655605",
    "cap": "36010"
  },
  {
    "key": "16109",
    "ITA": "VELO D'ASTICO",
    "state_code": "VI",
    "cab": "655605",
    "cap": "36010"
  },
  {
    "key": "16110",
    "ITA": "VELO VERONESE",
    "state_code": "VR",
    "cab": "323105",
    "cap": "37030"
  },
  {
    "key": "16111",
    "ITA": "VELO VERONESE-SAN ROCCO DI PIEGARA",
    "state_code": "VR",
    "cab": "323105",
    "cap": "37030"
  },
  {
    "key": "16112",
    "ITA": "VELTURNO",
    "state_code": "BZ",
    "cab": "591206",
    "cap": "39040"
  },
  {
    "key": "16113",
    "ITA": "VENAFRO",
    "state_code": "IS",
    "cab": "781302",
    "cap": "86079"
  },
  {
    "key": "16114",
    "ITA": "VENAFRO-CEPPAGNA",
    "state_code": "IS",
    "cab": "781302",
    "cap": "86079"
  },
  {
    "key": "16115",
    "ITA": "VENARIA REALE-ALTESSANO",
    "state_code": "TO",
    "cab": "311100",
    "cap": "10078"
  },
  {
    "key": "16116",
    "ITA": "VENARIA REALE",
    "state_code": "TO",
    "cab": "311100",
    "cap": "10078"
  },
  {
    "key": "16117",
    "ITA": "VENAROTTA",
    "state_code": "AP",
    "cab": "382408",
    "cap": "63040"
  },
  {
    "key": "16118",
    "ITA": "VENASCA",
    "state_code": "CN",
    "cab": "469304",
    "cap": "12020"
  },
  {
    "key": "16119",
    "ITA": "VENAUS",
    "state_code": "TO",
    "cab": "311001",
    "cap": "10050"
  },
  {
    "key": "16120",
    "ITA": "VENDONE",
    "state_code": "SV",
    "cab": "923334",
    "cap": "17032"
  },
  {
    "key": "16121",
    "ITA": "VENDROGNO",
    "state_code": "LC",
    "cab": "926402",
    "cap": "23838"
  },
  {
    "key": "16122",
    "ITA": "VENEGONO INFERIORE",
    "state_code": "VA",
    "cab": "506303",
    "cap": "21040"
  },
  {
    "key": "16123",
    "ITA": "VENEGONO SUPERIORE",
    "state_code": "VA",
    "cab": "506402",
    "cap": "21040"
  },
  {
    "key": "16124",
    "ITA": "VENETICO-VENETICO MARINA",
    "state_code": "ME",
    "cab": "826305",
    "cap": "98040"
  },
  {
    "key": "16125",
    "ITA": "VENETICO",
    "state_code": "ME",
    "cab": "826305",
    "cap": "98040"
  },
  {
    "key": "16126",
    "ITA": "VENETICO-VENETICO SUPERIORE",
    "state_code": "ME",
    "cab": "826305",
    "cap": "98040"
  },
  {
    "key": "16127",
    "ITA": "VENEZIA-DORSODURO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30123"
  },
  {
    "key": "16128",
    "ITA": "VENEZIA-CASTELLO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30122"
  },
  {
    "key": "16129",
    "ITA": "VENEZIA-GIUDECCA DI BURANO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16130",
    "ITA": "VENEZIA-LIDO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16131",
    "ITA": "VENEZIA-MALAMOCCO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16132",
    "ITA": "VENEZIA-MARGHERA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30175"
  },
  {
    "key": "16133",
    "ITA": "VENEZIA-MAZZORBO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16134",
    "ITA": "VENEZIA-MESTRE",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30171"
  },
  {
    "key": "16135",
    "ITA": "VENEZIA-MESTRE",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30173"
  },
  {
    "key": "16136",
    "ITA": "VENEZIA-MESTRE",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30174"
  },
  {
    "key": "16137",
    "ITA": "VENEZIA-MESTRE",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30175"
  },
  {
    "key": "16138",
    "ITA": "VENEZIA-MURANO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30141"
  },
  {
    "key": "16139",
    "ITA": "VENEZIA-PORTOSECCO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16140",
    "ITA": "VENEZIA-SACCA FISOLA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30133"
  },
  {
    "key": "16141",
    "ITA": "VENEZIA-SAN MARCO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30124"
  },
  {
    "key": "16142",
    "ITA": "VENEZIA-SAN MARTINO DESTRA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16143",
    "ITA": "VENEZIA-SAN MARTINO SINISTRA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16144",
    "ITA": "VENEZIA-SAN MAURO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16145",
    "ITA": "VENEZIA-SAN PIETRO IN VOLTA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16146",
    "ITA": "VENEZIA-SAN POLO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30125"
  },
  {
    "key": "16147",
    "ITA": "VENEZIA-SANTA CROCE",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30135"
  },
  {
    "key": "16148",
    "ITA": "VENEZIA-SANT'ELENA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30132"
  },
  {
    "key": "16149",
    "ITA": "VENEZIA-SCARPA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16150",
    "ITA": "VENEZIA-TERRANOVA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16151",
    "ITA": "VENEZIA-VIANELLI",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16152",
    "ITA": "VENEZIA-ZENNARI",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16153",
    "ITA": "VENEZIA-GIUDECCA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30133"
  },
  {
    "key": "16154",
    "ITA": "VENEZIA-CAMPALTO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30173"
  },
  {
    "key": "16155",
    "ITA": "VENEZIA-BUSETTI",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16156",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30121"
  },
  {
    "key": "16157",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30122"
  },
  {
    "key": "16158",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30123"
  },
  {
    "key": "16159",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30124"
  },
  {
    "key": "16160",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30125"
  },
  {
    "key": "16161",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16162",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30132"
  },
  {
    "key": "16163",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30133"
  },
  {
    "key": "16164",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30135"
  },
  {
    "key": "16165",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30141"
  },
  {
    "key": "16166",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16167",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30171"
  },
  {
    "key": "16168",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30172"
  },
  {
    "key": "16169",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30173"
  },
  {
    "key": "16170",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30174"
  },
  {
    "key": "16171",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30175"
  },
  {
    "key": "16172",
    "ITA": "VENEZIA",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30176"
  },
  {
    "key": "16173",
    "ITA": "VENEZIA-ALBERONI",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30126"
  },
  {
    "key": "16174",
    "ITA": "VENEZIA-BURANO",
    "state_code": "VE",
    "cab": "20008",
    "cap": "30142"
  },
  {
    "key": "16175",
    "ITA": "VENIANO",
    "state_code": "CO",
    "cab": "323402",
    "cap": "22070"
  },
  {
    "key": "16176",
    "ITA": "VENOSA",
    "state_code": "PZ",
    "cab": "423103",
    "cap": "85029"
  },
  {
    "key": "16177",
    "ITA": "VENTICANO-CASTELLO DEL LAGO",
    "state_code": "AV",
    "cab": "758409",
    "cap": "83030"
  },
  {
    "key": "16178",
    "ITA": "VENTICANO",
    "state_code": "AV",
    "cab": "758409",
    "cap": "83030"
  },
  {
    "key": "16179",
    "ITA": "VENTICANO-CAMPANARELLO",
    "state_code": "AV",
    "cab": "758409",
    "cap": "83030"
  },
  {
    "key": "16180",
    "ITA": "VENTIMIGLIA-BEVERA",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16181",
    "ITA": "VENTIMIGLIA-CALVO",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16182",
    "ITA": "VENTIMIGLIA-GRIMALDI",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16183",
    "ITA": "VENTIMIGLIA-LATTE",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16184",
    "ITA": "VENTIMIGLIA-ROVERINO",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16185",
    "ITA": "VENTIMIGLIA-SANT'ANTONIO",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16186",
    "ITA": "VENTIMIGLIA-SEALZA",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16187",
    "ITA": "VENTIMIGLIA-TORRI",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16188",
    "ITA": "VENTIMIGLIA-TRUCCO",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16189",
    "ITA": "VENTIMIGLIA",
    "state_code": "IM",
    "cab": "491100",
    "cap": "18039"
  },
  {
    "key": "16190",
    "ITA": "VENTIMIGLIA DI SICILIA",
    "state_code": "PA",
    "cab": "437103",
    "cap": "90020"
  },
  {
    "key": "16191",
    "ITA": "VENTOTENE",
    "state_code": "LT",
    "cab": "741702",
    "cap": "4020"
  },
  {
    "key": "16192",
    "ITA": "VENTOTENE-SANTO STEFANO",
    "state_code": "LT",
    "cab": "741702",
    "cap": "4020"
  },
  {
    "key": "16193",
    "ITA": "VENZONE",
    "state_code": "UD",
    "cab": "643809",
    "cap": "33010"
  },
  {
    "key": "16194",
    "ITA": "VENZONE-CARNIA",
    "state_code": "UD",
    "cab": "643809",
    "cap": "33010"
  },
  {
    "key": "16195",
    "ITA": "VERANO",
    "state_code": "BZ",
    "cab": "592105",
    "cap": "39010"
  },
  {
    "key": "16196",
    "ITA": "VERANO BRIANZA",
    "state_code": "MB",
    "cab": "340208",
    "cap": "20050"
  },
  {
    "key": "16197",
    "ITA": "VERBANIA",
    "state_code": "VB",
    "cab": "224006",
    "cap": "28921"
  },
  {
    "key": "16198",
    "ITA": "VERBANIA",
    "state_code": "VB",
    "cab": "224006",
    "cap": "28922"
  },
  {
    "key": "16199",
    "ITA": "VERBANIA",
    "state_code": "VB",
    "cab": "224006",
    "cap": "28925"
  },
  {
    "key": "16200",
    "ITA": "VERBANIA",
    "state_code": "VB",
    "cab": "224006",
    "cap": "28924"
  },
  {
    "key": "16201",
    "ITA": "VERBANIA",
    "state_code": "VB",
    "cab": "224006",
    "cap": "28923"
  },
  {
    "key": "16202",
    "ITA": "VERBICARO",
    "state_code": "CS",
    "cab": "811109",
    "cap": "87020"
  },
  {
    "key": "16203",
    "ITA": "VERCANA",
    "state_code": "CO",
    "cab": "926428",
    "cap": "22013"
  },
  {
    "key": "16204",
    "ITA": "VERCEIA",
    "state_code": "SO",
    "cab": "927368",
    "cap": "23020"
  },
  {
    "key": "16205",
    "ITA": "VERCELLI",
    "state_code": "VC",
    "cab": "100008",
    "cap": "13100"
  },
  {
    "key": "16206",
    "ITA": "VERCELLI-CAPPUCCINI",
    "state_code": "VC",
    "cab": "100008",
    "cap": "13100"
  },
  {
    "key": "16207",
    "ITA": "VERCELLI-BRAROLA",
    "state_code": "VC",
    "cab": "100008",
    "cap": "13100"
  },
  {
    "key": "16208",
    "ITA": "VERCELLI-LARIZZATE",
    "state_code": "VC",
    "cab": "100008",
    "cap": "13100"
  },
  {
    "key": "16209",
    "ITA": "VERCURAGO",
    "state_code": "LC",
    "cab": "536805",
    "cap": "23808"
  },
  {
    "key": "16210",
    "ITA": "VERDELLINO",
    "state_code": "BG",
    "cab": "536904",
    "cap": "24040"
  },
  {
    "key": "16211",
    "ITA": "VERDELLINO-ZINGONIA",
    "state_code": "BG",
    "cab": "536904",
    "cap": "24040"
  },
  {
    "key": "16212",
    "ITA": "VERDELLO",
    "state_code": "BG",
    "cab": "537001",
    "cap": "24049"
  },
  {
    "key": "16213",
    "ITA": "VERDERIO INFERIORE",
    "state_code": "LC",
    "cab": "742106",
    "cap": "23879"
  },
  {
    "key": "16214",
    "ITA": "VERDERIO SUPERIORE",
    "state_code": "LC",
    "cab": "894808",
    "cap": "23878"
  },
  {
    "key": "16215",
    "ITA": "VERDUNO",
    "state_code": "CN",
    "cab": "647602",
    "cap": "12060"
  },
  {
    "key": "16216",
    "ITA": "VERGATO-SUSANO",
    "state_code": "BO",
    "cab": "371203",
    "cap": "40038"
  },
  {
    "key": "16217",
    "ITA": "VERGATO-TOLE'",
    "state_code": "BO",
    "cab": "371203",
    "cap": "40038"
  },
  {
    "key": "16218",
    "ITA": "VERGATO-RIOLA",
    "state_code": "BO",
    "cab": "371203",
    "cap": "40038"
  },
  {
    "key": "16219",
    "ITA": "VERGATO-CEREGLIO",
    "state_code": "BO",
    "cab": "371203",
    "cap": "40038"
  },
  {
    "key": "16220",
    "ITA": "VERGATO",
    "state_code": "BO",
    "cab": "371203",
    "cap": "40038"
  },
  {
    "key": "16221",
    "ITA": "VERGEMOLI-FORNOVOLASCO",
    "state_code": "LU",
    "cab": "274431",
    "cap": "55020"
  },
  {
    "key": "16222",
    "ITA": "VERGEMOLI",
    "state_code": "LU",
    "cab": "274431",
    "cap": "55020"
  },
  {
    "key": "16223",
    "ITA": "VERGEMOLI-SAN PELLEGRINETTO",
    "state_code": "LU",
    "cab": "274431",
    "cap": "55020"
  },
  {
    "key": "16224",
    "ITA": "VERGHERETO",
    "state_code": "FC",
    "cab": "680900",
    "cap": "47028"
  },
  {
    "key": "16225",
    "ITA": "VERGHERETO-ALFERO",
    "state_code": "FC",
    "cab": "680900",
    "cap": "47028"
  },
  {
    "key": "16226",
    "ITA": "VERGHERETO-BALZE",
    "state_code": "FC",
    "cab": "680900",
    "cap": "47028"
  },
  {
    "key": "16227",
    "ITA": "VERGIATE-CUIRONE",
    "state_code": "VA",
    "cab": "506501",
    "cap": "21029"
  },
  {
    "key": "16228",
    "ITA": "VERGIATE-CORGENO",
    "state_code": "VA",
    "cab": "506501",
    "cap": "21029"
  },
  {
    "key": "16229",
    "ITA": "VERGIATE",
    "state_code": "VA",
    "cab": "506501",
    "cap": "21029"
  },
  {
    "key": "16230",
    "ITA": "VERGIATE-CIMBRO",
    "state_code": "VA",
    "cab": "506501",
    "cap": "21029"
  },
  {
    "key": "16231",
    "ITA": "VERMEZZO",
    "state_code": "MI",
    "cab": "882100",
    "cap": "20080"
  },
  {
    "key": "16232",
    "ITA": "VERMIGLIO-PASSO DEL TONALE",
    "state_code": "TN",
    "cab": "357509",
    "cap": "38029"
  },
  {
    "key": "16233",
    "ITA": "VERMIGLIO",
    "state_code": "TN",
    "cab": "357509",
    "cap": "38029"
  },
  {
    "key": "16234",
    "ITA": "VERMIGLIO-FRAVIANO",
    "state_code": "TN",
    "cab": "357509",
    "cap": "38029"
  },
  {
    "key": "16235",
    "ITA": "VERNANTE",
    "state_code": "CN",
    "cab": "469403",
    "cap": "12019"
  },
  {
    "key": "16236",
    "ITA": "VERNASCA-BACEDASCO SOTTO",
    "state_code": "PC",
    "cab": "654905",
    "cap": "29010"
  },
  {
    "key": "16237",
    "ITA": "VERNASCA",
    "state_code": "PC",
    "cab": "654905",
    "cap": "29010"
  },
  {
    "key": "16238",
    "ITA": "VERNASCA-VIGOLENO",
    "state_code": "PC",
    "cab": "654905",
    "cap": "29010"
  },
  {
    "key": "16239",
    "ITA": "VERNATE-MONCUCCO",
    "state_code": "MI",
    "cab": "340307",
    "cap": "20080"
  },
  {
    "key": "16240",
    "ITA": "VERNATE-PASTURAGO",
    "state_code": "MI",
    "cab": "340307",
    "cap": "20080"
  },
  {
    "key": "16241",
    "ITA": "VERNATE",
    "state_code": "MI",
    "cab": "340307",
    "cap": "20080"
  },
  {
    "key": "16242",
    "ITA": "VERNAZZA-CORNIGLIA",
    "state_code": "SP",
    "cab": "498709",
    "cap": "19018"
  },
  {
    "key": "16243",
    "ITA": "VERNAZZA",
    "state_code": "SP",
    "cab": "498709",
    "cap": "19018"
  },
  {
    "key": "16244",
    "ITA": "VERNIO-SAN QUIRICO",
    "state_code": "PO",
    "cab": "381509",
    "cap": "59024"
  },
  {
    "key": "16245",
    "ITA": "VERNIO-MONTEPIANO",
    "state_code": "PO",
    "cab": "381509",
    "cap": "59026"
  },
  {
    "key": "16246",
    "ITA": "VERNIO-MERCATALE",
    "state_code": "PO",
    "cab": "381509",
    "cap": "59024"
  },
  {
    "key": "16247",
    "ITA": "VERNIO-CAVARZANO",
    "state_code": "PO",
    "cab": "381509",
    "cap": "59024"
  },
  {
    "key": "16248",
    "ITA": "VERNIO",
    "state_code": "PO",
    "cab": "381509",
    "cap": "59024"
  },
  {
    "key": "16249",
    "ITA": "VERNOLE-VANZE",
    "state_code": "LE",
    "cab": "801605",
    "cap": "73029"
  },
  {
    "key": "16250",
    "ITA": "VERNOLE-STRUDA'",
    "state_code": "LE",
    "cab": "801605",
    "cap": "73029"
  },
  {
    "key": "16251",
    "ITA": "VERNOLE-PISIGNANO",
    "state_code": "LE",
    "cab": "801605",
    "cap": "73029"
  },
  {
    "key": "16252",
    "ITA": "VERNOLE-ACQUARICA DI LECCE",
    "state_code": "LE",
    "cab": "801605",
    "cap": "73029"
  },
  {
    "key": "16253",
    "ITA": "VERNOLE-ACAIA",
    "state_code": "LE",
    "cab": "801605",
    "cap": "73029"
  },
  {
    "key": "16254",
    "ITA": "VERNOLE",
    "state_code": "LE",
    "cab": "801605",
    "cap": "73029"
  },
  {
    "key": "16255",
    "ITA": "VEROLANUOVA-CADIGNANO",
    "state_code": "BS",
    "cab": "553701",
    "cap": "25028"
  },
  {
    "key": "16256",
    "ITA": "VEROLANUOVA",
    "state_code": "BS",
    "cab": "553701",
    "cap": "25028"
  },
  {
    "key": "16257",
    "ITA": "VEROLAVECCHIA",
    "state_code": "BS",
    "cab": "553800",
    "cap": "25029"
  },
  {
    "key": "16258",
    "ITA": "VEROLENGO-CASABIANCA",
    "state_code": "TO",
    "cab": "311209",
    "cap": "10038"
  },
  {
    "key": "16259",
    "ITA": "VEROLENGO-BORGO REVEL",
    "state_code": "TO",
    "cab": "311209",
    "cap": "10038"
  },
  {
    "key": "16260",
    "ITA": "VEROLENGO",
    "state_code": "TO",
    "cab": "311209",
    "cap": "10038"
  },
  {
    "key": "16261",
    "ITA": "VEROLI-SCIFELLI",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16262",
    "ITA": "VEROLI-SANT'ANGELO IN VILLA",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16263",
    "ITA": "VEROLI-SANTA FRANCESCA",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16264",
    "ITA": "VEROLI-PANETTA",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16265",
    "ITA": "VEROLI-GIGLIO",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16266",
    "ITA": "VEROLI-COTROPAGNO",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16267",
    "ITA": "VEROLI-COLLEBERARDI",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16268",
    "ITA": "VEROLI-CASTELMASSIMO",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16269",
    "ITA": "VEROLI-CASAMARI",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16270",
    "ITA": "VEROLI-CASE CAMPOLI",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16271",
    "ITA": "VEROLI",
    "state_code": "FR",
    "cab": "746305",
    "cap": "3029"
  },
  {
    "key": "16272",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37124"
  },
  {
    "key": "16273",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37125"
  },
  {
    "key": "16274",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37126"
  },
  {
    "key": "16275",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37127"
  },
  {
    "key": "16276",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37128"
  },
  {
    "key": "16277",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37129"
  },
  {
    "key": "16278",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37131"
  },
  {
    "key": "16279",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37132"
  },
  {
    "key": "16280",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37133"
  },
  {
    "key": "16281",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37134"
  },
  {
    "key": "16282",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37135"
  },
  {
    "key": "16283",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37136"
  },
  {
    "key": "16284",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37137"
  },
  {
    "key": "16285",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37139"
  },
  {
    "key": "16286",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37141"
  },
  {
    "key": "16287",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37123"
  },
  {
    "key": "16288",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37122"
  },
  {
    "key": "16289",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37121"
  },
  {
    "key": "16290",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37142"
  },
  {
    "key": "16291",
    "ITA": "VERONA",
    "state_code": "VR",
    "cab": "117002",
    "cap": "37138"
  },
  {
    "key": "16292",
    "ITA": "VERONELLA-SAN GREGORIO",
    "state_code": "VR",
    "cab": "857805",
    "cap": "37040"
  },
  {
    "key": "16293",
    "ITA": "VERONELLA",
    "state_code": "VR",
    "cab": "857805",
    "cap": "37040"
  },
  {
    "key": "16294",
    "ITA": "VERRAYES",
    "state_code": "AO",
    "cab": "902718",
    "cap": "11020"
  },
  {
    "key": "16295",
    "ITA": "VERRAYES-GRAND VILLA",
    "state_code": "AO",
    "cab": "902718",
    "cap": "11020"
  },
  {
    "key": "16296",
    "ITA": "VERRES-GLAIR",
    "state_code": "AO",
    "cab": "316901",
    "cap": "11029"
  },
  {
    "key": "16297",
    "ITA": "VERRES",
    "state_code": "AO",
    "cab": "316901",
    "cap": "11029"
  },
  {
    "key": "16298",
    "ITA": "VERRETTO",
    "state_code": "PV",
    "cab": "931063",
    "cap": "27053"
  },
  {
    "key": "16299",
    "ITA": "VERRONE",
    "state_code": "BI",
    "cab": "449108",
    "cap": "13871"
  },
  {
    "key": "16300",
    "ITA": "VERRUA PO",
    "state_code": "PV",
    "cab": "563700",
    "cap": "27040"
  },
  {
    "key": "16301",
    "ITA": "VERRUA SAVOIA",
    "state_code": "TO",
    "cab": "901785",
    "cap": "10020"
  },
  {
    "key": "16302",
    "ITA": "VERTEMATE CON MINOPRIO",
    "state_code": "CO",
    "cab": "891606",
    "cap": "22070"
  },
  {
    "key": "16303",
    "ITA": "VERTEMATE CON MINOPRIO-MINOPRIO",
    "state_code": "CO",
    "cab": "891606",
    "cap": "22070"
  },
  {
    "key": "16304",
    "ITA": "VERTOVA",
    "state_code": "BG",
    "cab": "537100",
    "cap": "24029"
  },
  {
    "key": "16305",
    "ITA": "VERUCCHIO",
    "state_code": "RN",
    "cab": "681007",
    "cap": "47826"
  },
  {
    "key": "16306",
    "ITA": "VERUCCHIO-VILLA VERUCCHIO",
    "state_code": "RN",
    "cab": "681007",
    "cap": "47826"
  },
  {
    "key": "16307",
    "ITA": "VERUNO",
    "state_code": "NO",
    "cab": "661009",
    "cap": "28010"
  },
  {
    "key": "16308",
    "ITA": "VERUNO-REVISLATE",
    "state_code": "NO",
    "cab": "661009",
    "cap": "28010"
  },
  {
    "key": "16309",
    "ITA": "VERVIO",
    "state_code": "SO",
    "cab": "927376",
    "cap": "23030"
  },
  {
    "key": "16310",
    "ITA": "VERVO'",
    "state_code": "TN",
    "cab": "357608",
    "cap": "38010"
  },
  {
    "key": "16311",
    "ITA": "VERZEGNIS",
    "state_code": "UD",
    "cab": "937318",
    "cap": "33020"
  },
  {
    "key": "16312",
    "ITA": "VERZINO",
    "state_code": "KR",
    "cab": "614107",
    "cap": "88819"
  },
  {
    "key": "16313",
    "ITA": "VERZUOLO",
    "state_code": "CN",
    "cab": "469502",
    "cap": "12039"
  },
  {
    "key": "16314",
    "ITA": "VERZUOLO-VILLANOVETTA",
    "state_code": "CN",
    "cab": "469502",
    "cap": "12039"
  },
  {
    "key": "16315",
    "ITA": "VERZUOLO-FALICETTO",
    "state_code": "CN",
    "cab": "469502",
    "cap": "12039"
  },
  {
    "key": "16316",
    "ITA": "VESCOVANA",
    "state_code": "PD",
    "cab": "890004",
    "cap": "35040"
  },
  {
    "key": "16317",
    "ITA": "VESCOVATO-CA' DE' STEFANI",
    "state_code": "CR",
    "cab": "573006",
    "cap": "26039"
  },
  {
    "key": "16318",
    "ITA": "VESCOVATO",
    "state_code": "CR",
    "cab": "573006",
    "cap": "26039"
  },
  {
    "key": "16319",
    "ITA": "VESIME",
    "state_code": "AT",
    "cab": "477505",
    "cap": "14059"
  },
  {
    "key": "16320",
    "ITA": "VESPOLATE",
    "state_code": "NO",
    "cab": "457408",
    "cap": "28079"
  },
  {
    "key": "16321",
    "ITA": "VESSALICO",
    "state_code": "IM",
    "cab": "922674",
    "cap": "18026"
  },
  {
    "key": "16322",
    "ITA": "VESTENANOVA",
    "state_code": "VR",
    "cab": "600106",
    "cap": "37030"
  },
  {
    "key": "16323",
    "ITA": "VESTIGNE'-TINA",
    "state_code": "TO",
    "cab": "674309",
    "cap": "10030"
  },
  {
    "key": "16324",
    "ITA": "VESTIGNE'",
    "state_code": "TO",
    "cab": "674309",
    "cap": "10030"
  },
  {
    "key": "16325",
    "ITA": "VESTONE",
    "state_code": "BS",
    "cab": "553909",
    "cap": "25078"
  },
  {
    "key": "16326",
    "ITA": "VESTONE-NOZZA",
    "state_code": "BS",
    "cab": "553909",
    "cap": "25078"
  },
  {
    "key": "16327",
    "ITA": "VESTRENO",
    "state_code": "LC",
    "cab": "926469",
    "cap": "23822"
  },
  {
    "key": "16328",
    "ITA": "VETRALLA-CURA",
    "state_code": "VT",
    "cab": "733501",
    "cap": "1019"
  },
  {
    "key": "16329",
    "ITA": "VETRALLA",
    "state_code": "VT",
    "cab": "733501",
    "cap": "1019"
  },
  {
    "key": "16330",
    "ITA": "VETRALLA-LA BOTTE",
    "state_code": "VT",
    "cab": "733501",
    "cap": "1019"
  },
  {
    "key": "16331",
    "ITA": "VETRALLA-PIETRARA",
    "state_code": "VT",
    "cab": "733501",
    "cap": "1019"
  },
  {
    "key": "16332",
    "ITA": "VETRALLA-TRE CROCI",
    "state_code": "VT",
    "cab": "733501",
    "cap": "1019"
  },
  {
    "key": "16333",
    "ITA": "VETRALLA-GIARDINO",
    "state_code": "VT",
    "cab": "733501",
    "cap": "1019"
  },
  {
    "key": "16334",
    "ITA": "VETTO-COLA",
    "state_code": "RE",
    "cab": "665307",
    "cap": "42020"
  },
  {
    "key": "16335",
    "ITA": "VETTO-ROSANO",
    "state_code": "RE",
    "cab": "665307",
    "cap": "42020"
  },
  {
    "key": "16336",
    "ITA": "VETTO",
    "state_code": "RE",
    "cab": "665307",
    "cap": "42020"
  },
  {
    "key": "16337",
    "ITA": "VEZZA D'ALBA",
    "state_code": "CN",
    "cab": "469601",
    "cap": "12040"
  },
  {
    "key": "16338",
    "ITA": "VEZZA D'OGLIO",
    "state_code": "BS",
    "cab": "554006",
    "cap": "25059"
  },
  {
    "key": "16339",
    "ITA": "VEZZANO",
    "state_code": "TN",
    "cab": "357707",
    "cap": "38070"
  },
  {
    "key": "16340",
    "ITA": "VEZZANO-MARGONE",
    "state_code": "TN",
    "cab": "357707",
    "cap": "38070"
  },
  {
    "key": "16341",
    "ITA": "VEZZANO-RANZO",
    "state_code": "TN",
    "cab": "357707",
    "cap": "38070"
  },
  {
    "key": "16342",
    "ITA": "VEZZANO LIGURE-VALERIANO",
    "state_code": "SP",
    "cab": "498808",
    "cap": "19020"
  },
  {
    "key": "16343",
    "ITA": "VEZZANO LIGURE-PRATI",
    "state_code": "SP",
    "cab": "498808",
    "cap": "19020"
  },
  {
    "key": "16344",
    "ITA": "VEZZANO LIGURE",
    "state_code": "SP",
    "cab": "498808",
    "cap": "19020"
  },
  {
    "key": "16345",
    "ITA": "VEZZANO LIGURE-BOTTAGNA",
    "state_code": "SP",
    "cab": "498808",
    "cap": "19020"
  },
  {
    "key": "16346",
    "ITA": "VEZZANO LIGURE-FORNOLA",
    "state_code": "SP",
    "cab": "498808",
    "cap": "19020"
  },
  {
    "key": "16347",
    "ITA": "VEZZANO LIGURE-MADONNA DI BUONVIAGGIO",
    "state_code": "SP",
    "cab": "498808",
    "cap": "19020"
  },
  {
    "key": "16348",
    "ITA": "VEZZANO LIGURE-PIANO DI VALERIANO",
    "state_code": "SP",
    "cab": "498808",
    "cap": "19020"
  },
  {
    "key": "16349",
    "ITA": "VEZZANO SUL CROSTOLO-MONTALTO",
    "state_code": "RE",
    "cab": "665406",
    "cap": "42030"
  },
  {
    "key": "16350",
    "ITA": "VEZZANO SUL CROSTOLO-LA VECCHIA",
    "state_code": "RE",
    "cab": "665406",
    "cap": "42030"
  },
  {
    "key": "16351",
    "ITA": "VEZZANO SUL CROSTOLO",
    "state_code": "RE",
    "cab": "665406",
    "cap": "42030"
  },
  {
    "key": "16352",
    "ITA": "VEZZANO SUL CROSTOLO-PECORILE",
    "state_code": "RE",
    "cab": "665406",
    "cap": "42030"
  },
  {
    "key": "16353",
    "ITA": "VEZZI PORTIO",
    "state_code": "SV",
    "cab": "923342",
    "cap": "17028"
  },
  {
    "key": "16354",
    "ITA": "VIADANA",
    "state_code": "MN",
    "cab": "580209",
    "cap": "46019"
  },
  {
    "key": "16355",
    "ITA": "VIADANA-SAN MATTEO DELLE CHIAVICHE",
    "state_code": "MN",
    "cab": "580209",
    "cap": "46030"
  },
  {
    "key": "16356",
    "ITA": "VIADANA-COGOZZO",
    "state_code": "MN",
    "cab": "580209",
    "cap": "46019"
  },
  {
    "key": "16357",
    "ITA": "VIADANA-CIZZOLO",
    "state_code": "MN",
    "cab": "580209",
    "cap": "46030"
  },
  {
    "key": "16358",
    "ITA": "VIADANA-CICOGNARA",
    "state_code": "MN",
    "cab": "580209",
    "cap": "46019"
  },
  {
    "key": "16359",
    "ITA": "VIADANA-BUZZOLETTO",
    "state_code": "MN",
    "cab": "580209",
    "cap": "46019"
  },
  {
    "key": "16360",
    "ITA": "VIADANA-BELLAGUARDA",
    "state_code": "MN",
    "cab": "580209",
    "cap": "46030"
  },
  {
    "key": "16361",
    "ITA": "VIADANICA",
    "state_code": "BG",
    "cab": "928770",
    "cap": "24060"
  },
  {
    "key": "16362",
    "ITA": "VIAGRANDE",
    "state_code": "CT",
    "cab": "843102",
    "cap": "95029"
  },
  {
    "key": "16363",
    "ITA": "VIALE",
    "state_code": "AT",
    "cab": "920488",
    "cap": "14010"
  },
  {
    "key": "16364",
    "ITA": "VIALFRE'",
    "state_code": "TO",
    "cab": "901801",
    "cap": "10090"
  },
  {
    "key": "16365",
    "ITA": "VIANO-SAN GIOVANNI DI QUERCIOLA",
    "state_code": "RE",
    "cab": "665505",
    "cap": "42030"
  },
  {
    "key": "16366",
    "ITA": "VIANO-REGNANO",
    "state_code": "RE",
    "cab": "665505",
    "cap": "42030"
  },
  {
    "key": "16367",
    "ITA": "VIANO",
    "state_code": "RE",
    "cab": "665505",
    "cap": "42030"
  },
  {
    "key": "16368",
    "ITA": "VIAREGGIO-TORRE DEL LAGO PUCCINI",
    "state_code": "LU",
    "cab": "248005",
    "cap": "55049"
  },
  {
    "key": "16369",
    "ITA": "VIAREGGIO",
    "state_code": "LU",
    "cab": "248005",
    "cap": "55049"
  },
  {
    "key": "16370",
    "ITA": "VIARIGI",
    "state_code": "AT",
    "cab": "477604",
    "cap": "14030"
  },
  {
    "key": "16371",
    "ITA": "VIARIGI-ACCORNERI",
    "state_code": "AT",
    "cab": "477604",
    "cap": "14030"
  },
  {
    "key": "16372",
    "ITA": "VIBO VALENTIA-PISCOPIO",
    "state_code": "VV",
    "cab": "428300",
    "cap": "89900"
  },
  {
    "key": "16373",
    "ITA": "VIBO VALENTIA-PORTO SALVO",
    "state_code": "VV",
    "cab": "428300",
    "cap": "89900"
  },
  {
    "key": "16374",
    "ITA": "VIBO VALENTIA-VENA",
    "state_code": "VV",
    "cab": "428300",
    "cap": "89900"
  },
  {
    "key": "16375",
    "ITA": "VIBO VALENTIA-VIBO MARINA",
    "state_code": "VV",
    "cab": "428300",
    "cap": "89900"
  },
  {
    "key": "16376",
    "ITA": "VIBO VALENTIA-LONGOBARDI",
    "state_code": "VV",
    "cab": "428300",
    "cap": "89900"
  },
  {
    "key": "16377",
    "ITA": "VIBO VALENTIA",
    "state_code": "VV",
    "cab": "428300",
    "cap": "89900"
  },
  {
    "key": "16378",
    "ITA": "VIBO VALENTIA-TRIPARNI",
    "state_code": "VV",
    "cab": "428300",
    "cap": "89900"
  },
  {
    "key": "16379",
    "ITA": "VIBONATI",
    "state_code": "SA",
    "cab": "766303",
    "cap": "84079"
  },
  {
    "key": "16380",
    "ITA": "VIBONATI-VILLAMMARE",
    "state_code": "SA",
    "cab": "766303",
    "cap": "84079"
  },
  {
    "key": "16381",
    "ITA": "VICALVI",
    "state_code": "FR",
    "cab": "948661",
    "cap": "3030"
  },
  {
    "key": "16382",
    "ITA": "VICARI",
    "state_code": "PA",
    "cab": "437202",
    "cap": "90020"
  },
  {
    "key": "16383",
    "ITA": "VICCHIO-RUPECANINA",
    "state_code": "FI",
    "cab": "381608",
    "cap": "50039"
  },
  {
    "key": "16384",
    "ITA": "VICCHIO-VILLORE",
    "state_code": "FI",
    "cab": "381608",
    "cap": "50039"
  },
  {
    "key": "16385",
    "ITA": "VICCHIO-CISTIO",
    "state_code": "FI",
    "cab": "381608",
    "cap": "50039"
  },
  {
    "key": "16386",
    "ITA": "VICCHIO",
    "state_code": "FI",
    "cab": "381608",
    "cap": "50039"
  },
  {
    "key": "16387",
    "ITA": "VICCHIO-GATTAIA",
    "state_code": "FI",
    "cab": "381608",
    "cap": "50039"
  },
  {
    "key": "16388",
    "ITA": "VICENZA",
    "state_code": "VI",
    "cab": "118000",
    "cap": "36100"
  },
  {
    "key": "16389",
    "ITA": "VICENZA-ANCONETTA",
    "state_code": "VI",
    "cab": "118000",
    "cap": "36100"
  },
  {
    "key": "16390",
    "ITA": "VICENZA-CAMPEDELLO",
    "state_code": "VI",
    "cab": "118000",
    "cap": "36100"
  },
  {
    "key": "16391",
    "ITA": "VICENZA-LONGARA",
    "state_code": "VI",
    "cab": "118000",
    "cap": "36100"
  },
  {
    "key": "16392",
    "ITA": "VICENZA-SETTECA'",
    "state_code": "VI",
    "cab": "118000",
    "cap": "36100"
  },
  {
    "key": "16393",
    "ITA": "VICENZA-POLEGGE",
    "state_code": "VI",
    "cab": "118000",
    "cap": "36100"
  },
  {
    "key": "16394",
    "ITA": "VICO CANAVESE-DRUSACCO",
    "state_code": "TO",
    "cab": "311308",
    "cap": "10080"
  },
  {
    "key": "16395",
    "ITA": "VICO CANAVESE",
    "state_code": "TO",
    "cab": "311308",
    "cap": "10080"
  },
  {
    "key": "16396",
    "ITA": "VICO DEL GARGANO-SAN MENAIO",
    "state_code": "FG",
    "cab": "787002",
    "cap": "71018"
  },
  {
    "key": "16397",
    "ITA": "VICO DEL GARGANO",
    "state_code": "FG",
    "cab": "787002",
    "cap": "71018"
  },
  {
    "key": "16398",
    "ITA": "VICO DEL GARGANO-UMBRA",
    "state_code": "FG",
    "cab": "787002",
    "cap": "71018"
  },
  {
    "key": "16399",
    "ITA": "VICO EQUENSE",
    "state_code": "NA",
    "cab": "403105",
    "cap": "80069"
  },
  {
    "key": "16400",
    "ITA": "VICO EQUENSE-SEIANO",
    "state_code": "NA",
    "cab": "403105",
    "cap": "80066"
  },
  {
    "key": "16401",
    "ITA": "VICO EQUENSE-MONTECHIARO",
    "state_code": "NA",
    "cab": "403105",
    "cap": "80066"
  },
  {
    "key": "16402",
    "ITA": "VICO EQUENSE-MOIANO",
    "state_code": "NA",
    "cab": "403105",
    "cap": "80060"
  },
  {
    "key": "16403",
    "ITA": "VICO EQUENSE-MASSAQUANO",
    "state_code": "NA",
    "cab": "403105",
    "cap": "80060"
  },
  {
    "key": "16404",
    "ITA": "VICO EQUENSE-VILLAGGIO MONTE FAITO",
    "state_code": "NA",
    "cab": "403105",
    "cap": "80060"
  },
  {
    "key": "16405",
    "ITA": "VICO EQUENSE-FORNACELLA",
    "state_code": "NA",
    "cab": "403105",
    "cap": "80066"
  },
  {
    "key": "16406",
    "ITA": "VICO NEL LAZIO-PITOCCO",
    "state_code": "FR",
    "cab": "897207",
    "cap": "3010"
  },
  {
    "key": "16407",
    "ITA": "VICO NEL LAZIO",
    "state_code": "FR",
    "cab": "897207",
    "cap": "3010"
  },
  {
    "key": "16408",
    "ITA": "VICOFORTE-MOLINE",
    "state_code": "CN",
    "cab": "469700",
    "cap": "12080"
  },
  {
    "key": "16409",
    "ITA": "VICOFORTE-SANTUARIO DI VICOFORTE",
    "state_code": "CN",
    "cab": "469700",
    "cap": "12080"
  },
  {
    "key": "16410",
    "ITA": "VICOFORTE",
    "state_code": "CN",
    "cab": "469700",
    "cap": "12080"
  },
  {
    "key": "16411",
    "ITA": "VICOLI",
    "state_code": "PE",
    "cab": "954164",
    "cap": "65010"
  },
  {
    "key": "16412",
    "ITA": "VICOLUNGO",
    "state_code": "NO",
    "cab": "917823",
    "cap": "28060"
  },
  {
    "key": "16413",
    "ITA": "VICOPISANO-CAPRONA",
    "state_code": "PI",
    "cab": "712109",
    "cap": "56011"
  },
  {
    "key": "16414",
    "ITA": "VICOPISANO-ULIVETO TERME",
    "state_code": "PI",
    "cab": "712109",
    "cap": "56010"
  },
  {
    "key": "16415",
    "ITA": "VICOPISANO-SAN GIOVANNI ALLA VENA",
    "state_code": "PI",
    "cab": "712109",
    "cap": "56010"
  },
  {
    "key": "16416",
    "ITA": "VICOPISANO-CUCIGLIANA",
    "state_code": "PI",
    "cab": "712109",
    "cap": "56010"
  },
  {
    "key": "16417",
    "ITA": "VICOPISANO",
    "state_code": "PI",
    "cab": "712109",
    "cap": "56010"
  },
  {
    "key": "16418",
    "ITA": "VICOVARO",
    "state_code": "RM",
    "cab": "395004",
    "cap": "29"
  },
  {
    "key": "16419",
    "ITA": "VIDDALBA",
    "state_code": "SS",
    "cab": "876904",
    "cap": "7030"
  },
  {
    "key": "16420",
    "ITA": "VIDIGULFO",
    "state_code": "PV",
    "cab": "563809",
    "cap": "27018"
  },
  {
    "key": "16421",
    "ITA": "VIDOR",
    "state_code": "TV",
    "cab": "622704",
    "cap": "31020"
  },
  {
    "key": "16422",
    "ITA": "VIDRACCO",
    "state_code": "TO",
    "cab": "901819",
    "cap": "10080"
  },
  {
    "key": "16423",
    "ITA": "VIESTE",
    "state_code": "FG",
    "cab": "787101",
    "cap": "71019"
  },
  {
    "key": "16424",
    "ITA": "VIETRI DI POTENZA-MOSILEO",
    "state_code": "PZ",
    "cab": "423509",
    "cap": "85058"
  },
  {
    "key": "16425",
    "ITA": "VIETRI DI POTENZA",
    "state_code": "PZ",
    "cab": "423509",
    "cap": "85058"
  },
  {
    "key": "16426",
    "ITA": "VIETRI SUL MARE",
    "state_code": "SA",
    "cab": "765404",
    "cap": "84019"
  },
  {
    "key": "16427",
    "ITA": "VIETRI SUL MARE-BENINCASA",
    "state_code": "SA",
    "cab": "765404",
    "cap": "84019"
  },
  {
    "key": "16428",
    "ITA": "VIETRI SUL MARE-DRAGONEA",
    "state_code": "SA",
    "cab": "765404",
    "cap": "84019"
  },
  {
    "key": "16429",
    "ITA": "VIETRI SUL MARE-MARINA DI VIETRI",
    "state_code": "SA",
    "cab": "765404",
    "cap": "84019"
  },
  {
    "key": "16430",
    "ITA": "VIETRI SUL MARE-MOLINA",
    "state_code": "SA",
    "cab": "765404",
    "cap": "84019"
  },
  {
    "key": "16431",
    "ITA": "VIETRI SUL MARE-RAITO",
    "state_code": "SA",
    "cab": "765404",
    "cap": "84019"
  },
  {
    "key": "16432",
    "ITA": "VIGANELLA",
    "state_code": "VB",
    "cab": "917831",
    "cap": "28841"
  },
  {
    "key": "16433",
    "ITA": "VIGANO SAN MARTINO",
    "state_code": "BG",
    "cab": "928788",
    "cap": "24060"
  },
  {
    "key": "16434",
    "ITA": "VIGANO'",
    "state_code": "LC",
    "cab": "891705",
    "cap": "23897"
  },
  {
    "key": "16435",
    "ITA": "VIGARANO MAINARDA",
    "state_code": "FE",
    "cab": "673509",
    "cap": "44049"
  },
  {
    "key": "16436",
    "ITA": "VIGARANO MAINARDA-VIGARANO PIEVE",
    "state_code": "FE",
    "cab": "673509",
    "cap": "44049"
  },
  {
    "key": "16437",
    "ITA": "VIGASIO",
    "state_code": "VR",
    "cab": "599407",
    "cap": "37068"
  },
  {
    "key": "16438",
    "ITA": "VIGASIO-FORETTE",
    "state_code": "VR",
    "cab": "599407",
    "cap": "37068"
  },
  {
    "key": "16439",
    "ITA": "VIGASIO-ISOLALTA",
    "state_code": "VR",
    "cab": "599407",
    "cap": "37068"
  },
  {
    "key": "16440",
    "ITA": "VIGEVANO",
    "state_code": "PV",
    "cab": "230003",
    "cap": "27029"
  },
  {
    "key": "16441",
    "ITA": "VIGEVANO-SFORZESCA",
    "state_code": "PV",
    "cab": "230003",
    "cap": "27029"
  },
  {
    "key": "16442",
    "ITA": "VIGGIANELLO",
    "state_code": "PZ",
    "cab": "423707",
    "cap": "85040"
  },
  {
    "key": "16443",
    "ITA": "VIGGIANELLO-PEDALI DI VIGGIANELLO",
    "state_code": "PZ",
    "cab": "423707",
    "cap": "85040"
  },
  {
    "key": "16444",
    "ITA": "VIGGIANO",
    "state_code": "PZ",
    "cab": "423202",
    "cap": "85059"
  },
  {
    "key": "16445",
    "ITA": "VIGGIU'",
    "state_code": "VA",
    "cab": "506600",
    "cap": "21059"
  },
  {
    "key": "16446",
    "ITA": "VIGGIU'-BARAGGIA",
    "state_code": "VA",
    "cab": "506600",
    "cap": "21059"
  },
  {
    "key": "16447",
    "ITA": "VIGHIZZOLO D'ESTE",
    "state_code": "PD",
    "cab": "936021",
    "cap": "35040"
  },
  {
    "key": "16448",
    "ITA": "VIGLIANO BIELLESE-VILLAGGI",
    "state_code": "BI",
    "cab": "449207",
    "cap": "13856"
  },
  {
    "key": "16449",
    "ITA": "VIGLIANO BIELLESE",
    "state_code": "BI",
    "cab": "449207",
    "cap": "13856"
  },
  {
    "key": "16450",
    "ITA": "VIGLIANO D'ASTI",
    "state_code": "AT",
    "cab": "477703",
    "cap": "14040"
  },
  {
    "key": "16451",
    "ITA": "VIGNALE MONFERRATO",
    "state_code": "AL",
    "cab": "487009",
    "cap": "15049"
  },
  {
    "key": "16452",
    "ITA": "VIGNANELLO",
    "state_code": "VT",
    "cab": "733600",
    "cap": "1039"
  },
  {
    "key": "16453",
    "ITA": "VIGNATE",
    "state_code": "MI",
    "cab": "340406",
    "cap": "20060"
  },
  {
    "key": "16454",
    "ITA": "VIGNOLA",
    "state_code": "MO",
    "cab": "670703",
    "cap": "41058"
  },
  {
    "key": "16455",
    "ITA": "VIGNOLA FALESINA",
    "state_code": "TN",
    "cab": "905190",
    "cap": "38057"
  },
  {
    "key": "16456",
    "ITA": "VIGNOLA FALESINA-FALESINA",
    "state_code": "TN",
    "cab": "905190",
    "cap": "38057"
  },
  {
    "key": "16457",
    "ITA": "VIGNOLA FALESINA-VIGNOLA",
    "state_code": "TN",
    "cab": "905190",
    "cap": "38057"
  },
  {
    "key": "16458",
    "ITA": "VIGNOLE BORBERA",
    "state_code": "AL",
    "cab": "487108",
    "cap": "15060"
  },
  {
    "key": "16459",
    "ITA": "VIGNOLO",
    "state_code": "CN",
    "cab": "314906",
    "cap": "12010"
  },
  {
    "key": "16460",
    "ITA": "VIGNONE",
    "state_code": "VB",
    "cab": "917849",
    "cap": "28819"
  },
  {
    "key": "16461",
    "ITA": "VIGO DI CADORE-PELOS DI CADORE",
    "state_code": "BL",
    "cab": "613505",
    "cap": "32040"
  },
  {
    "key": "16462",
    "ITA": "VIGO DI CADORE",
    "state_code": "BL",
    "cab": "613505",
    "cap": "32040"
  },
  {
    "key": "16463",
    "ITA": "VIGO DI CADORE-LAGGIO DI CADORE",
    "state_code": "BL",
    "cab": "613505",
    "cap": "32040"
  },
  {
    "key": "16464",
    "ITA": "VIGO DI FASSA-COSTALUNGA",
    "state_code": "TN",
    "cab": "357806",
    "cap": "38039"
  },
  {
    "key": "16465",
    "ITA": "VIGO DI FASSA",
    "state_code": "TN",
    "cab": "357806",
    "cap": "38039"
  },
  {
    "key": "16466",
    "ITA": "VIGO RENDENA",
    "state_code": "TN",
    "cab": "905208",
    "cap": "38080"
  },
  {
    "key": "16467",
    "ITA": "VIGODARZERE-TERRAGLIONE",
    "state_code": "PD",
    "cab": "630905",
    "cap": "35010"
  },
  {
    "key": "16468",
    "ITA": "VIGODARZERE-TAVO",
    "state_code": "PD",
    "cab": "630905",
    "cap": "35010"
  },
  {
    "key": "16469",
    "ITA": "VIGODARZERE-SALETTO DI VIGODARZERE",
    "state_code": "PD",
    "cab": "630905",
    "cap": "35010"
  },
  {
    "key": "16470",
    "ITA": "VIGODARZERE",
    "state_code": "PD",
    "cab": "630905",
    "cap": "35010"
  },
  {
    "key": "16471",
    "ITA": "VIGOLO",
    "state_code": "BG",
    "cab": "928796",
    "cap": "24060"
  },
  {
    "key": "16472",
    "ITA": "VIGOLO VATTARO",
    "state_code": "TN",
    "cab": "357905",
    "cap": "38049"
  },
  {
    "key": "16473",
    "ITA": "VIGOLZONE-GRAZZANO VISCONTI",
    "state_code": "PC",
    "cab": "655001",
    "cap": "29020"
  },
  {
    "key": "16474",
    "ITA": "VIGOLZONE-VILLO'",
    "state_code": "PC",
    "cab": "655001",
    "cap": "29020"
  },
  {
    "key": "16475",
    "ITA": "VIGOLZONE-CARMIANO",
    "state_code": "PC",
    "cab": "655001",
    "cap": "29020"
  },
  {
    "key": "16476",
    "ITA": "VIGOLZONE",
    "state_code": "PC",
    "cab": "655001",
    "cap": "29020"
  },
  {
    "key": "16477",
    "ITA": "VIGONE",
    "state_code": "TO",
    "cab": "311407",
    "cap": "10067"
  },
  {
    "key": "16478",
    "ITA": "VIGONOVO-TOMBELLE",
    "state_code": "VE",
    "cab": "363705",
    "cap": "30030"
  },
  {
    "key": "16479",
    "ITA": "VIGONOVO-GALTA",
    "state_code": "VE",
    "cab": "363705",
    "cap": "30030"
  },
  {
    "key": "16480",
    "ITA": "VIGONOVO",
    "state_code": "VE",
    "cab": "363705",
    "cap": "30030"
  },
  {
    "key": "16481",
    "ITA": "VIGONZA-PIONCA",
    "state_code": "PD",
    "cab": "629600",
    "cap": "35010"
  },
  {
    "key": "16482",
    "ITA": "VIGONZA-SAN VITO",
    "state_code": "PD",
    "cab": "629600",
    "cap": "35010"
  },
  {
    "key": "16483",
    "ITA": "VIGONZA-PERAROLO",
    "state_code": "PD",
    "cab": "629600",
    "cap": "35010"
  },
  {
    "key": "16484",
    "ITA": "VIGONZA",
    "state_code": "PD",
    "cab": "629600",
    "cap": "35010"
  },
  {
    "key": "16485",
    "ITA": "VIGONZA-CODIVERNO",
    "state_code": "PD",
    "cab": "629600",
    "cap": "35010"
  },
  {
    "key": "16486",
    "ITA": "VIGUZZOLO",
    "state_code": "AL",
    "cab": "487207",
    "cap": "15058"
  },
  {
    "key": "16487",
    "ITA": "VILLA AGNEDO-AGNEDO",
    "state_code": "TN",
    "cab": "890806",
    "cap": "38059"
  },
  {
    "key": "16488",
    "ITA": "VILLA AGNEDO",
    "state_code": "TN",
    "cab": "890806",
    "cap": "38059"
  },
  {
    "key": "16489",
    "ITA": "VILLA BARTOLOMEA",
    "state_code": "VR",
    "cab": "599506",
    "cap": "37049"
  },
  {
    "key": "16490",
    "ITA": "VILLA BARTOLOMEA-CARPI DI VILLA BARTOLOMEA",
    "state_code": "VR",
    "cab": "599506",
    "cap": "37049"
  },
  {
    "key": "16491",
    "ITA": "VILLA BARTOLOMEA-SPINIMBECCO",
    "state_code": "VR",
    "cab": "599506",
    "cap": "37049"
  },
  {
    "key": "16492",
    "ITA": "VILLA BASILICA-BOTTICINO",
    "state_code": "LU",
    "cab": "702605",
    "cap": "55019"
  },
  {
    "key": "16493",
    "ITA": "VILLA BASILICA",
    "state_code": "LU",
    "cab": "702605",
    "cap": "55019"
  },
  {
    "key": "16494",
    "ITA": "VILLA BASILICA-PRACANDO",
    "state_code": "LU",
    "cab": "702605",
    "cap": "55019"
  },
  {
    "key": "16495",
    "ITA": "VILLA BISCOSSI",
    "state_code": "PV",
    "cab": "931071",
    "cap": "27035"
  },
  {
    "key": "16496",
    "ITA": "VILLA CARCINA-COGOZZO",
    "state_code": "BS",
    "cab": "554105",
    "cap": "25069"
  },
  {
    "key": "16497",
    "ITA": "VILLA CARCINA-CARCINA",
    "state_code": "BS",
    "cab": "554105",
    "cap": "25069"
  },
  {
    "key": "16498",
    "ITA": "VILLA CARCINA",
    "state_code": "BS",
    "cab": "554105",
    "cap": "25069"
  },
  {
    "key": "16499",
    "ITA": "VILLA CASTELLI",
    "state_code": "BR",
    "cab": "793109",
    "cap": "72029"
  },
  {
    "key": "16500",
    "ITA": "VILLA CELIERA",
    "state_code": "PE",
    "cab": "954172",
    "cap": "65010"
  },
  {
    "key": "16501",
    "ITA": "VILLA COLLEMANDINA-CORFINO",
    "state_code": "LU",
    "cab": "692806",
    "cap": "55030"
  },
  {
    "key": "16502",
    "ITA": "VILLA COLLEMANDINA",
    "state_code": "LU",
    "cab": "692806",
    "cap": "55030"
  },
  {
    "key": "16503",
    "ITA": "VILLA CORTESE",
    "state_code": "MI",
    "cab": "341107",
    "cap": "20020"
  },
  {
    "key": "16504",
    "ITA": "VILLA D'ADDA",
    "state_code": "BG",
    "cab": "537209",
    "cap": "24030"
  },
  {
    "key": "16505",
    "ITA": "VILLA D'ALME'",
    "state_code": "BG",
    "cab": "537308",
    "cap": "24018"
  },
  {
    "key": "16506",
    "ITA": "VILLA D'OGNA",
    "state_code": "BG",
    "cab": "537506",
    "cap": "24020"
  },
  {
    "key": "16507",
    "ITA": "VILLA DEL BOSCO",
    "state_code": "BI",
    "cab": "916486",
    "cap": "13868"
  },
  {
    "key": "16508",
    "ITA": "VILLA DEL CONTE-ABBAZIA PISANI",
    "state_code": "PD",
    "cab": "631002",
    "cap": "35010"
  },
  {
    "key": "16509",
    "ITA": "VILLA DEL CONTE",
    "state_code": "PD",
    "cab": "631002",
    "cap": "35010"
  },
  {
    "key": "16510",
    "ITA": "VILLA DI BRIANO",
    "state_code": "CE",
    "cab": "409102",
    "cap": "81030"
  },
  {
    "key": "16511",
    "ITA": "VILLA DI CHIAVENNA",
    "state_code": "SO",
    "cab": "523308",
    "cap": "23029"
  },
  {
    "key": "16512",
    "ITA": "VILLA DI CHIAVENNA-DOGANA",
    "state_code": "SO",
    "cab": "523308",
    "cap": "23029"
  },
  {
    "key": "16513",
    "ITA": "VILLA DI SERIO",
    "state_code": "BG",
    "cab": "537407",
    "cap": "24020"
  },
  {
    "key": "16514",
    "ITA": "VILLA DI TIRANO",
    "state_code": "SO",
    "cab": "523407",
    "cap": "23030"
  },
  {
    "key": "16515",
    "ITA": "VILLA DI TIRANO-STAZZONA",
    "state_code": "SO",
    "cab": "523407",
    "cap": "23030"
  },
  {
    "key": "16516",
    "ITA": "VILLA ESTENSE",
    "state_code": "PD",
    "cab": "629709",
    "cap": "35040"
  },
  {
    "key": "16517",
    "ITA": "VILLA FARALDI-RIVA FARALDI",
    "state_code": "IM",
    "cab": "922682",
    "cap": "18010"
  },
  {
    "key": "16518",
    "ITA": "VILLA FARALDI",
    "state_code": "IM",
    "cab": "922682",
    "cap": "18010"
  },
  {
    "key": "16519",
    "ITA": "VILLA GUARDIA",
    "state_code": "CO",
    "cab": "518407",
    "cap": "22079"
  },
  {
    "key": "16520",
    "ITA": "VILLA LAGARINA-CASTELLANO",
    "state_code": "TN",
    "cab": "358002",
    "cap": "38060"
  },
  {
    "key": "16521",
    "ITA": "VILLA LAGARINA-PEDERSANO",
    "state_code": "TN",
    "cab": "358002",
    "cap": "38060"
  },
  {
    "key": "16522",
    "ITA": "VILLA LAGARINA",
    "state_code": "TN",
    "cab": "358002",
    "cap": "38060"
  },
  {
    "key": "16523",
    "ITA": "VILLA LATINA",
    "state_code": "FR",
    "cab": "747402",
    "cap": "3040"
  },
  {
    "key": "16524",
    "ITA": "VILLA LITERNO",
    "state_code": "CE",
    "cab": "752402",
    "cap": "81039"
  },
  {
    "key": "16525",
    "ITA": "VILLA LITERNO-BONIFICA VILLA LITERNO",
    "state_code": "CE",
    "cab": "752402",
    "cap": "81039"
  },
  {
    "key": "16526",
    "ITA": "VILLA MINOZZO-CIVAGO",
    "state_code": "RE",
    "cab": "665604",
    "cap": "42030"
  },
  {
    "key": "16527",
    "ITA": "VILLA MINOZZO-GAZZANO",
    "state_code": "RE",
    "cab": "665604",
    "cap": "42030"
  },
  {
    "key": "16528",
    "ITA": "VILLA MINOZZO-MINOZZO",
    "state_code": "RE",
    "cab": "665604",
    "cap": "42030"
  },
  {
    "key": "16529",
    "ITA": "VILLA MINOZZO-SOLOGNO",
    "state_code": "RE",
    "cab": "665604",
    "cap": "42030"
  },
  {
    "key": "16530",
    "ITA": "VILLA MINOZZO-ASTA NELL'EMILIA",
    "state_code": "RE",
    "cab": "665604",
    "cap": "42030"
  },
  {
    "key": "16531",
    "ITA": "VILLA MINOZZO",
    "state_code": "RE",
    "cab": "665604",
    "cap": "42030"
  },
  {
    "key": "16532",
    "ITA": "VILLA POMA",
    "state_code": "MN",
    "cab": "580704",
    "cap": "46020"
  },
  {
    "key": "16533",
    "ITA": "VILLA RENDENA",
    "state_code": "TN",
    "cab": "358101",
    "cap": "38080"
  },
  {
    "key": "16534",
    "ITA": "VILLA RENDENA-VERDESINA",
    "state_code": "TN",
    "cab": "358101",
    "cap": "38080"
  },
  {
    "key": "16535",
    "ITA": "VILLA RENDENA-IAVRE'",
    "state_code": "TN",
    "cab": "358101",
    "cap": "38080"
  },
  {
    "key": "16536",
    "ITA": "VILLA SAN GIOVANNI-ACCIARELLO",
    "state_code": "RC",
    "cab": "816207",
    "cap": "89018"
  },
  {
    "key": "16537",
    "ITA": "VILLA SAN GIOVANNI-FERRITO",
    "state_code": "RC",
    "cab": "816207",
    "cap": "89018"
  },
  {
    "key": "16538",
    "ITA": "VILLA SAN GIOVANNI-PEZZO DI VILLA SAN GIOVANNI",
    "state_code": "RC",
    "cab": "816207",
    "cap": "89018"
  },
  {
    "key": "16539",
    "ITA": "VILLA SAN GIOVANNI",
    "state_code": "RC",
    "cab": "816207",
    "cap": "89018"
  },
  {
    "key": "16540",
    "ITA": "VILLA SAN GIOVANNI-CANNITELLO",
    "state_code": "RC",
    "cab": "816207",
    "cap": "89018"
  },
  {
    "key": "16541",
    "ITA": "VILLA SAN GIOVANNI IN TUSCIA",
    "state_code": "VT",
    "cab": "732503",
    "cap": "1010"
  },
  {
    "key": "16542",
    "ITA": "VILLA SAN PIETRO",
    "state_code": "CA",
    "cab": "915199",
    "cap": "9010"
  },
  {
    "key": "16543",
    "ITA": "VILLA SAN SECONDO",
    "state_code": "AT",
    "cab": "920496",
    "cap": "14020"
  },
  {
    "key": "16544",
    "ITA": "VILLA SANT'ANGELO",
    "state_code": "AQ",
    "cab": "910034",
    "cap": "67020"
  },
  {
    "key": "16545",
    "ITA": "VILLA SANT'ANTONIO-SANT'ANTONIO RUINAS",
    "state_code": "OR",
    "cab": "179564",
    "cap": "9080"
  },
  {
    "key": "16546",
    "ITA": "VILLA SANT'ANTONIO",
    "state_code": "OR",
    "cab": "179564",
    "cap": "9080"
  },
  {
    "key": "16547",
    "ITA": "VILLA SANTA LUCIA",
    "state_code": "FR",
    "cab": "897306",
    "cap": "3030"
  },
  {
    "key": "16548",
    "ITA": "VILLA SANTA LUCIA-PIUMAROLA",
    "state_code": "FR",
    "cab": "897306",
    "cap": "3030"
  },
  {
    "key": "16549",
    "ITA": "VILLA SANTA LUCIA DEGLI ABRUZZI",
    "state_code": "AQ",
    "cab": "910026",
    "cap": "67020"
  },
  {
    "key": "16550",
    "ITA": "VILLA SANTA LUCIA DEGLI ABRUZZI-CARRUFO",
    "state_code": "AQ",
    "cab": "910026",
    "cap": "67020"
  },
  {
    "key": "16551",
    "ITA": "VILLA SANTA MARIA",
    "state_code": "CH",
    "cab": "779207",
    "cap": "66047"
  },
  {
    "key": "16552",
    "ITA": "VILLA SANTINA",
    "state_code": "UD",
    "cab": "643908",
    "cap": "33029"
  },
  {
    "key": "16553",
    "ITA": "VILLA SANTINA-INVILLINO",
    "state_code": "UD",
    "cab": "643908",
    "cap": "33029"
  },
  {
    "key": "16554",
    "ITA": "VILLA SANTO STEFANO",
    "state_code": "FR",
    "cab": "288407",
    "cap": "3020"
  },
  {
    "key": "16555",
    "ITA": "VILLA VERDE",
    "state_code": "OR",
    "cab": "967232",
    "cap": "9090"
  },
  {
    "key": "16556",
    "ITA": "VILLA VICENTINA",
    "state_code": "UD",
    "cab": "771402",
    "cap": "33059"
  },
  {
    "key": "16557",
    "ITA": "VILLABASSA",
    "state_code": "BZ",
    "cab": "591008",
    "cap": "39039"
  },
  {
    "key": "16558",
    "ITA": "VILLABATE",
    "state_code": "PA",
    "cab": "437301",
    "cap": "90039"
  },
  {
    "key": "16559",
    "ITA": "VILLACHIARA",
    "state_code": "BS",
    "cab": "554204",
    "cap": "25030"
  },
  {
    "key": "16560",
    "ITA": "VILLACIDRO",
    "state_code": "SU",
    "cab": "440404",
    "cap": "9039"
  },
  {
    "key": "16561",
    "ITA": "VILLADEATI",
    "state_code": "AL",
    "cab": "921809",
    "cap": "15020"
  },
  {
    "key": "16562",
    "ITA": "VILLADEATI-ZANCO",
    "state_code": "AL",
    "cab": "921809",
    "cap": "15020"
  },
  {
    "key": "16563",
    "ITA": "VILLADEATI-LUSSELLO",
    "state_code": "AL",
    "cab": "921809",
    "cap": "15020"
  },
  {
    "key": "16564",
    "ITA": "VILLADOSE",
    "state_code": "RO",
    "cab": "634907",
    "cap": "45010"
  },
  {
    "key": "16565",
    "ITA": "VILLADOSSOLA",
    "state_code": "VB",
    "cab": "457507",
    "cap": "28844"
  },
  {
    "key": "16566",
    "ITA": "VILLAFALLETTO",
    "state_code": "CN",
    "cab": "469809",
    "cap": "12020"
  },
  {
    "key": "16567",
    "ITA": "VILLAFALLETTO-MONSOLA",
    "state_code": "CN",
    "cab": "469809",
    "cap": "12020"
  },
  {
    "key": "16568",
    "ITA": "VILLAFRANCA D'ASTI",
    "state_code": "AT",
    "cab": "477802",
    "cap": "14018"
  },
  {
    "key": "16569",
    "ITA": "VILLAFRANCA DI VERONA-PIZZOLETTA",
    "state_code": "VR",
    "cab": "599605",
    "cap": "37069"
  },
  {
    "key": "16570",
    "ITA": "VILLAFRANCA DI VERONA",
    "state_code": "VR",
    "cab": "599605",
    "cap": "37069"
  },
  {
    "key": "16571",
    "ITA": "VILLAFRANCA DI VERONA-ROSEGAFERRO",
    "state_code": "VR",
    "cab": "599605",
    "cap": "37069"
  },
  {
    "key": "16572",
    "ITA": "VILLAFRANCA DI VERONA-DOSSOBUONO",
    "state_code": "VR",
    "cab": "599605",
    "cap": "37062"
  },
  {
    "key": "16573",
    "ITA": "VILLAFRANCA DI VERONA-CALURI",
    "state_code": "VR",
    "cab": "599605",
    "cap": "37069"
  },
  {
    "key": "16574",
    "ITA": "VILLAFRANCA DI VERONA-ALPO",
    "state_code": "VR",
    "cab": "599605",
    "cap": "37062"
  },
  {
    "key": "16575",
    "ITA": "VILLAFRANCA DI VERONA-QUADERNI",
    "state_code": "VR",
    "cab": "599605",
    "cap": "37069"
  },
  {
    "key": "16576",
    "ITA": "VILLAFRANCA IN LUNIGIANA",
    "state_code": "MS",
    "cab": "699801",
    "cap": "54028"
  },
  {
    "key": "16577",
    "ITA": "VILLAFRANCA IN LUNIGIANA-MERIZZO",
    "state_code": "MS",
    "cab": "699801",
    "cap": "54028"
  },
  {
    "key": "16578",
    "ITA": "VILLAFRANCA IN LUNIGIANA-VIRGOLETTA",
    "state_code": "MS",
    "cab": "699801",
    "cap": "54028"
  },
  {
    "key": "16579",
    "ITA": "VILLAFRANCA IN LUNIGIANA-FILETTO",
    "state_code": "MS",
    "cab": "699801",
    "cap": "54028"
  },
  {
    "key": "16580",
    "ITA": "VILLAFRANCA PADOVANA-TAGGI'",
    "state_code": "PD",
    "cab": "629808",
    "cap": "35010"
  },
  {
    "key": "16581",
    "ITA": "VILLAFRANCA PADOVANA-RONCHI DI CAMPANILE",
    "state_code": "PD",
    "cab": "629808",
    "cap": "35010"
  },
  {
    "key": "16582",
    "ITA": "VILLAFRANCA PADOVANA",
    "state_code": "PD",
    "cab": "629808",
    "cap": "35010"
  },
  {
    "key": "16583",
    "ITA": "VILLAFRANCA PIEMONTE",
    "state_code": "TO",
    "cab": "311506",
    "cap": "10068"
  },
  {
    "key": "16584",
    "ITA": "VILLAFRANCA SICULA",
    "state_code": "AG",
    "cab": "831909",
    "cap": "92020"
  },
  {
    "key": "16585",
    "ITA": "VILLAFRANCA TIRRENA-SERRO",
    "state_code": "ME",
    "cab": "826404",
    "cap": "98049"
  },
  {
    "key": "16586",
    "ITA": "VILLAFRANCA TIRRENA-CALVARUSO",
    "state_code": "ME",
    "cab": "826404",
    "cap": "98049"
  },
  {
    "key": "16587",
    "ITA": "VILLAFRANCA TIRRENA",
    "state_code": "ME",
    "cab": "826404",
    "cap": "98049"
  },
  {
    "key": "16588",
    "ITA": "VILLAFRANCA TIRRENA-DIVIETO",
    "state_code": "ME",
    "cab": "826404",
    "cap": "98049"
  },
  {
    "key": "16589",
    "ITA": "VILLAFRATI",
    "state_code": "PA",
    "cab": "437400",
    "cap": "90030"
  },
  {
    "key": "16590",
    "ITA": "VILLAGA",
    "state_code": "VI",
    "cab": "934174",
    "cap": "36021"
  },
  {
    "key": "16591",
    "ITA": "VILLAGRANDE STRISAILI",
    "state_code": "OG",
    "cab": "871103",
    "cap": "8049"
  },
  {
    "key": "16592",
    "ITA": "VILLAGRANDE STRISAILI-VILLANOVA STRISAILI",
    "state_code": "OG",
    "cab": "871103",
    "cap": "8040"
  },
  {
    "key": "16593",
    "ITA": "VILLALAGO",
    "state_code": "AQ",
    "cab": "408609",
    "cap": "67030"
  },
  {
    "key": "16594",
    "ITA": "VILLALBA",
    "state_code": "CL",
    "cab": "834804",
    "cap": "93010"
  },
  {
    "key": "16595",
    "ITA": "VILLALFONSINA",
    "state_code": "CH",
    "cab": "955112",
    "cap": "66020"
  },
  {
    "key": "16596",
    "ITA": "VILLALVERNIA",
    "state_code": "AL",
    "cab": "487306",
    "cap": "15050"
  },
  {
    "key": "16597",
    "ITA": "VILLAMAGNA",
    "state_code": "CH",
    "cab": "779603",
    "cap": "66010"
  },
  {
    "key": "16598",
    "ITA": "VILLAMAINA",
    "state_code": "AV",
    "cab": "951830",
    "cap": "83050"
  },
  {
    "key": "16599",
    "ITA": "VILLAMAR",
    "state_code": "SU",
    "cab": "440503",
    "cap": "9020"
  },
  {
    "key": "16600",
    "ITA": "VILLAMARZANA",
    "state_code": "RO",
    "cab": "734400",
    "cap": "45030"
  },
  {
    "key": "16601",
    "ITA": "VILLAMASSARGIA",
    "state_code": "SU",
    "cab": "863902",
    "cap": "9010"
  },
  {
    "key": "16602",
    "ITA": "VILLAMIROGLIO-VALLEGIOLITI",
    "state_code": "AL",
    "cab": "921817",
    "cap": "15020"
  },
  {
    "key": "16603",
    "ITA": "VILLAMIROGLIO",
    "state_code": "AL",
    "cab": "921817",
    "cap": "15020"
  },
  {
    "key": "16604",
    "ITA": "VILLANDRO",
    "state_code": "BZ",
    "cab": "591404",
    "cap": "39040"
  },
  {
    "key": "16605",
    "ITA": "VILLANOVA BIELLESE",
    "state_code": "BI",
    "cab": "916494",
    "cap": "13877"
  },
  {
    "key": "16606",
    "ITA": "VILLANOVA CANAVESE",
    "state_code": "TO",
    "cab": "901827",
    "cap": "10070"
  },
  {
    "key": "16607",
    "ITA": "VILLANOVA D'ALBENGA",
    "state_code": "SV",
    "cab": "495507",
    "cap": "17038"
  },
  {
    "key": "16608",
    "ITA": "VILLANOVA D'ARDENGHI",
    "state_code": "PV",
    "cab": "931089",
    "cap": "27030"
  },
  {
    "key": "16609",
    "ITA": "VILLANOVA D'ASTI",
    "state_code": "AT",
    "cab": "477901",
    "cap": "14019"
  },
  {
    "key": "16610",
    "ITA": "VILLANOVA D'ASTI-VILLANOVA D'ASTI STAZIONE",
    "state_code": "AT",
    "cab": "477901",
    "cap": "14019"
  },
  {
    "key": "16611",
    "ITA": "VILLANOVA DEL BATTISTA",
    "state_code": "AV",
    "cab": "951848",
    "cap": "83030"
  },
  {
    "key": "16612",
    "ITA": "VILLANOVA DEL GHEBBO",
    "state_code": "RO",
    "cab": "635003",
    "cap": "45020"
  },
  {
    "key": "16613",
    "ITA": "VILLANOVA DEL SILLARO-BARGANO",
    "state_code": "LO",
    "cab": "904227",
    "cap": "26818"
  },
  {
    "key": "16614",
    "ITA": "VILLANOVA DEL SILLARO",
    "state_code": "LO",
    "cab": "904227",
    "cap": "26818"
  },
  {
    "key": "16615",
    "ITA": "VILLANOVA DI CAMPOSAMPIERO",
    "state_code": "PD",
    "cab": "629907",
    "cap": "35010"
  },
  {
    "key": "16616",
    "ITA": "VILLANOVA DI CAMPOSAMPIERO-VILLANOVA",
    "state_code": "PD",
    "cab": "629907",
    "cap": "35010"
  },
  {
    "key": "16617",
    "ITA": "VILLANOVA MARCHESANA",
    "state_code": "RO",
    "cab": "635102",
    "cap": "45030"
  },
  {
    "key": "16618",
    "ITA": "VILLANOVA MONDOVI'",
    "state_code": "CN",
    "cab": "469908",
    "cap": "12089"
  },
  {
    "key": "16619",
    "ITA": "VILLANOVA MONFERRATO",
    "state_code": "AL",
    "cab": "487405",
    "cap": "15030"
  },
  {
    "key": "16620",
    "ITA": "VILLANOVA MONTELEONE",
    "state_code": "SS",
    "cab": "851006",
    "cap": "7019"
  },
  {
    "key": "16621",
    "ITA": "VILLANOVA SOLARO",
    "state_code": "CN",
    "cab": "470005",
    "cap": "12030"
  },
  {
    "key": "16622",
    "ITA": "VILLANOVA SULL'ARDA",
    "state_code": "PC",
    "cab": "655100",
    "cap": "29010"
  },
  {
    "key": "16623",
    "ITA": "VILLANOVA SULL'ARDA-VILLANOVA",
    "state_code": "PC",
    "cab": "655100",
    "cap": "29010"
  },
  {
    "key": "16624",
    "ITA": "VILLANOVA TRUSCHEDU",
    "state_code": "OR",
    "cab": "967216",
    "cap": "9084"
  },
  {
    "key": "16625",
    "ITA": "VILLANOVA TULO",
    "state_code": "CA",
    "cab": "871202",
    "cap": "8030"
  },
  {
    "key": "16626",
    "ITA": "VILLANOVAFORRU",
    "state_code": "SU",
    "cab": "915215",
    "cap": "9020"
  },
  {
    "key": "16627",
    "ITA": "VILLANOVAFRANCA",
    "state_code": "SU",
    "cab": "864009",
    "cap": "9020"
  },
  {
    "key": "16628",
    "ITA": "VILLANTERIO",
    "state_code": "PV",
    "cab": "563908",
    "cap": "27019"
  },
  {
    "key": "16629",
    "ITA": "VILLANUOVA SUL CLISI",
    "state_code": "BS",
    "cab": "554303",
    "cap": "25089"
  },
  {
    "key": "16630",
    "ITA": "VILLANUOVA SUL CLISI-BOSTONE",
    "state_code": "BS",
    "cab": "554303",
    "cap": "25089"
  },
  {
    "key": "16631",
    "ITA": "VILLAPERUCCIO",
    "state_code": "SU",
    "cab": "915272",
    "cap": "9010"
  },
  {
    "key": "16632",
    "ITA": "VILLAPIANA-VILLAPIANA SCALO",
    "state_code": "CS",
    "cab": "810903",
    "cap": "87076"
  },
  {
    "key": "16633",
    "ITA": "VILLAPIANA",
    "state_code": "CS",
    "cab": "810903",
    "cap": "87076"
  },
  {
    "key": "16634",
    "ITA": "VILLAPIANA-VILLAPIANA LIDO",
    "state_code": "CS",
    "cab": "810903",
    "cap": "87076"
  },
  {
    "key": "16635",
    "ITA": "VILLAPUTZU",
    "state_code": "CA",
    "cab": "864108",
    "cap": "9040"
  },
  {
    "key": "16636",
    "ITA": "VILLAPUTZU-SANTA MARIA",
    "state_code": "CA",
    "cab": "864108",
    "cap": "9040"
  },
  {
    "key": "16637",
    "ITA": "VILLAR DORA",
    "state_code": "TO",
    "cab": "417600",
    "cap": "10040"
  },
  {
    "key": "16638",
    "ITA": "VILLAR FOCCHIARDO-BARATTE",
    "state_code": "TO",
    "cab": "706101",
    "cap": "10050"
  },
  {
    "key": "16639",
    "ITA": "VILLAR FOCCHIARDO",
    "state_code": "TO",
    "cab": "706101",
    "cap": "10050"
  },
  {
    "key": "16640",
    "ITA": "VILLAR PELLICE",
    "state_code": "TO",
    "cab": "661108",
    "cap": "10060"
  },
  {
    "key": "16641",
    "ITA": "VILLAR PEROSA",
    "state_code": "TO",
    "cab": "311704",
    "cap": "10069"
  },
  {
    "key": "16642",
    "ITA": "VILLAR SAN COSTANZO",
    "state_code": "CN",
    "cab": "705707",
    "cap": "12020"
  },
  {
    "key": "16643",
    "ITA": "VILLARBASSE",
    "state_code": "TO",
    "cab": "311605",
    "cap": "10090"
  },
  {
    "key": "16644",
    "ITA": "VILLARBASSE-CORBIGLIA",
    "state_code": "TO",
    "cab": "311605",
    "cap": "10090"
  },
  {
    "key": "16645",
    "ITA": "VILLARBOIT",
    "state_code": "VC",
    "cab": "916502",
    "cap": "13030"
  },
  {
    "key": "16646",
    "ITA": "VILLAREGGIA",
    "state_code": "TO",
    "cab": "901843",
    "cap": "10030"
  },
  {
    "key": "16647",
    "ITA": "VILLARICCA-TORRETTA",
    "state_code": "NA",
    "cab": "886002",
    "cap": "80010"
  },
  {
    "key": "16648",
    "ITA": "VILLARICCA-SCALZAPECORA",
    "state_code": "NA",
    "cab": "886002",
    "cap": "80010"
  },
  {
    "key": "16649",
    "ITA": "VILLARICCA",
    "state_code": "NA",
    "cab": "886002",
    "cap": "80010"
  },
  {
    "key": "16650",
    "ITA": "VILLAROMAGNANO",
    "state_code": "AL",
    "cab": "487504",
    "cap": "15050"
  },
  {
    "key": "16651",
    "ITA": "VILLAROSA",
    "state_code": "EN",
    "cab": "837401",
    "cap": "94010"
  },
  {
    "key": "16652",
    "ITA": "VILLAROSA-VILLAPRIOLO",
    "state_code": "EN",
    "cab": "837401",
    "cap": "94010"
  },
  {
    "key": "16653",
    "ITA": "VILLASALTO",
    "state_code": "CA",
    "cab": "864207",
    "cap": "9040"
  },
  {
    "key": "16654",
    "ITA": "VILLASANTA",
    "state_code": "MB",
    "cab": "340505",
    "cap": "20058"
  },
  {
    "key": "16655",
    "ITA": "VILLASIMIUS",
    "state_code": "CA",
    "cab": "440602",
    "cap": "9049"
  },
  {
    "key": "16656",
    "ITA": "VILLASOR",
    "state_code": "CA",
    "cab": "440701",
    "cap": "9034"
  },
  {
    "key": "16657",
    "ITA": "VILLASPECIOSA",
    "state_code": "CA",
    "cab": "864306",
    "cap": "9010"
  },
  {
    "key": "16658",
    "ITA": "VILLASTELLONE",
    "state_code": "TO",
    "cab": "311803",
    "cap": "10029"
  },
  {
    "key": "16659",
    "ITA": "VILLATA",
    "state_code": "VC",
    "cab": "449306",
    "cap": "13010"
  },
  {
    "key": "16660",
    "ITA": "VILLAURBANA",
    "state_code": "OR",
    "cab": "881805",
    "cap": "9080"
  },
  {
    "key": "16661",
    "ITA": "VILLAVALLELONGA",
    "state_code": "AQ",
    "cab": "910042",
    "cap": "67050"
  },
  {
    "key": "16662",
    "ITA": "VILLAVERLA-NOVOLEDO",
    "state_code": "VI",
    "cab": "608505",
    "cap": "36030"
  },
  {
    "key": "16663",
    "ITA": "VILLAVERLA",
    "state_code": "VI",
    "cab": "608505",
    "cap": "36030"
  },
  {
    "key": "16664",
    "ITA": "VILLENEUVE",
    "state_code": "AO",
    "cab": "317008",
    "cap": "11018"
  },
  {
    "key": "16665",
    "ITA": "VILLESSE",
    "state_code": "GO",
    "cab": "646802",
    "cap": "34070"
  },
  {
    "key": "16666",
    "ITA": "VILLETTA BARREA",
    "state_code": "AQ",
    "cab": "910059",
    "cap": "67030"
  },
  {
    "key": "16667",
    "ITA": "VILLETTE",
    "state_code": "VB",
    "cab": "917856",
    "cap": "28856"
  },
  {
    "key": "16668",
    "ITA": "VILLIMPENTA",
    "state_code": "MN",
    "cab": "580308",
    "cap": "46039"
  },
  {
    "key": "16669",
    "ITA": "VILLONGO",
    "state_code": "BG",
    "cab": "537605",
    "cap": "24060"
  },
  {
    "key": "16670",
    "ITA": "VILLORBA-LANCENIGO",
    "state_code": "TV",
    "cab": "621805",
    "cap": "31020"
  },
  {
    "key": "16671",
    "ITA": "VILLORBA",
    "state_code": "TV",
    "cab": "621805",
    "cap": "31020"
  },
  {
    "key": "16672",
    "ITA": "VILMINORE DI SCALVE-VILMINORE",
    "state_code": "BG",
    "cab": "537704",
    "cap": "24020"
  },
  {
    "key": "16673",
    "ITA": "VILMINORE DI SCALVE",
    "state_code": "BG",
    "cab": "537704",
    "cap": "24020"
  },
  {
    "key": "16674",
    "ITA": "VIMERCATE",
    "state_code": "MB",
    "cab": "340703",
    "cap": "20059"
  },
  {
    "key": "16675",
    "ITA": "VIMERCATE-ORENO",
    "state_code": "MB",
    "cab": "340703",
    "cap": "20059"
  },
  {
    "key": "16676",
    "ITA": "VIMERCATE-VELASCA",
    "state_code": "MB",
    "cab": "340703",
    "cap": "20059"
  },
  {
    "key": "16677",
    "ITA": "VIMODRONE",
    "state_code": "MI",
    "cab": "340802",
    "cap": "20090"
  },
  {
    "key": "16678",
    "ITA": "VINADIO-PIANCHE",
    "state_code": "CN",
    "cab": "470104",
    "cap": "12010"
  },
  {
    "key": "16679",
    "ITA": "VINADIO-BAGNI DI VINADIO",
    "state_code": "CN",
    "cab": "470104",
    "cap": "12010"
  },
  {
    "key": "16680",
    "ITA": "VINADIO",
    "state_code": "CN",
    "cab": "470104",
    "cap": "12010"
  },
  {
    "key": "16681",
    "ITA": "VINCHIATURO",
    "state_code": "CB",
    "cab": "411603",
    "cap": "86019"
  },
  {
    "key": "16682",
    "ITA": "VINCHIO",
    "state_code": "AT",
    "cab": "478008",
    "cap": "14040"
  },
  {
    "key": "16683",
    "ITA": "VINCI-VITOLINI",
    "state_code": "FI",
    "cab": "381707",
    "cap": "50059"
  },
  {
    "key": "16684",
    "ITA": "VINCI-SPICCHIO",
    "state_code": "FI",
    "cab": "381707",
    "cap": "50059"
  },
  {
    "key": "16685",
    "ITA": "VINCI-SOVIGLIANA",
    "state_code": "FI",
    "cab": "381707",
    "cap": "50059"
  },
  {
    "key": "16686",
    "ITA": "VINCI-SAN PANTALEO",
    "state_code": "FI",
    "cab": "381707",
    "cap": "50059"
  },
  {
    "key": "16687",
    "ITA": "VINCI-ORBIGNANO",
    "state_code": "FI",
    "cab": "381707",
    "cap": "50059"
  },
  {
    "key": "16688",
    "ITA": "VINCI",
    "state_code": "FI",
    "cab": "381707",
    "cap": "50059"
  },
  {
    "key": "16689",
    "ITA": "VINCI-SANT'AMATO",
    "state_code": "FI",
    "cab": "381707",
    "cap": "50059"
  },
  {
    "key": "16690",
    "ITA": "VINOVO",
    "state_code": "TO",
    "cab": "311902",
    "cap": "10048"
  },
  {
    "key": "16691",
    "ITA": "VINOVO-GARINO",
    "state_code": "TO",
    "cab": "311902",
    "cap": "10048"
  },
  {
    "key": "16692",
    "ITA": "VINZAGLIO",
    "state_code": "NO",
    "cab": "917864",
    "cap": "28060"
  },
  {
    "key": "16693",
    "ITA": "VIOLA",
    "state_code": "CN",
    "cab": "470609",
    "cap": "12070"
  },
  {
    "key": "16694",
    "ITA": "VIONE-STADOLINA",
    "state_code": "BS",
    "cab": "929745",
    "cap": "25050"
  },
  {
    "key": "16695",
    "ITA": "VIONE",
    "state_code": "BS",
    "cab": "929745",
    "cap": "25050"
  },
  {
    "key": "16696",
    "ITA": "VIPITENO",
    "state_code": "BZ",
    "cab": "591107",
    "cap": "39049"
  },
  {
    "key": "16697",
    "ITA": "VIRGILIO-PIETOLE",
    "state_code": "MN",
    "cab": "580407",
    "cap": "46030"
  },
  {
    "key": "16698",
    "ITA": "VIRGILIO-CERESE",
    "state_code": "MN",
    "cab": "580407",
    "cap": "46030"
  },
  {
    "key": "16699",
    "ITA": "VIRGILIO-CAPPELLETTA",
    "state_code": "MN",
    "cab": "580407",
    "cap": "46030"
  },
  {
    "key": "16700",
    "ITA": "VIRGILIO",
    "state_code": "MN",
    "cab": "580407",
    "cap": "46030"
  },
  {
    "key": "16701",
    "ITA": "VIRLE PIEMONTE",
    "state_code": "TO",
    "cab": "499103",
    "cap": "10060"
  },
  {
    "key": "16702",
    "ITA": "VISANO",
    "state_code": "BS",
    "cab": "554402",
    "cap": "25010"
  },
  {
    "key": "16703",
    "ITA": "VISCHE",
    "state_code": "TO",
    "cab": "312009",
    "cap": "10030"
  },
  {
    "key": "16704",
    "ITA": "VISCIANO",
    "state_code": "NA",
    "cab": "404103",
    "cap": "80030"
  },
  {
    "key": "16705",
    "ITA": "VISCO",
    "state_code": "UD",
    "cab": "937334",
    "cap": "33040"
  },
  {
    "key": "16706",
    "ITA": "VISONE",
    "state_code": "AL",
    "cab": "487603",
    "cap": "15010"
  },
  {
    "key": "16707",
    "ITA": "VISSO-FEMATRE",
    "state_code": "MC",
    "cab": "692400",
    "cap": "62039"
  },
  {
    "key": "16708",
    "ITA": "VISSO",
    "state_code": "MC",
    "cab": "692400",
    "cap": "62039"
  },
  {
    "key": "16709",
    "ITA": "VISTARINO",
    "state_code": "PV",
    "cab": "775403",
    "cap": "27010"
  },
  {
    "key": "16710",
    "ITA": "VISTRORIO",
    "state_code": "TO",
    "cab": "312108",
    "cap": "10080"
  },
  {
    "key": "16711",
    "ITA": "VITA",
    "state_code": "TP",
    "cab": "819805",
    "cap": "91010"
  },
  {
    "key": "16712",
    "ITA": "VITERBO-TOBIA",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1100"
  },
  {
    "key": "16713",
    "ITA": "VITERBO-SAN MARTINO AL CIMINO",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1100"
  },
  {
    "key": "16714",
    "ITA": "VITERBO-ROCCALVECCE",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1020"
  },
  {
    "key": "16715",
    "ITA": "VITERBO-MAGUGNANO",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1026"
  },
  {
    "key": "16716",
    "ITA": "VITERBO-LA QUERCIA",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1100"
  },
  {
    "key": "16717",
    "ITA": "VITERBO-GROTTE SANTO STEFANO",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1026"
  },
  {
    "key": "16718",
    "ITA": "VITERBO-FASTELLO",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1020"
  },
  {
    "key": "16719",
    "ITA": "VITERBO",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1100"
  },
  {
    "key": "16720",
    "ITA": "VITERBO-BAGNAIA",
    "state_code": "VT",
    "cab": "145003",
    "cap": "1100"
  },
  {
    "key": "16721",
    "ITA": "VITICUSO",
    "state_code": "FR",
    "cab": "948711",
    "cap": "3040"
  },
  {
    "key": "16722",
    "ITA": "VITO D'ASIO-CASIACCO",
    "state_code": "PN",
    "cab": "175091",
    "cap": "33090"
  },
  {
    "key": "16723",
    "ITA": "VITO D'ASIO-ANDUINS",
    "state_code": "PN",
    "cab": "175091",
    "cap": "33090"
  },
  {
    "key": "16724",
    "ITA": "VITO D'ASIO-PIELUNGO",
    "state_code": "PN",
    "cab": "175091",
    "cap": "33090"
  },
  {
    "key": "16725",
    "ITA": "VITO D'ASIO",
    "state_code": "PN",
    "cab": "175091",
    "cap": "33090"
  },
  {
    "key": "16726",
    "ITA": "VITORCHIANO",
    "state_code": "VT",
    "cab": "733709",
    "cap": "1030"
  },
  {
    "key": "16727",
    "ITA": "VITORCHIANO-VITORCHIANO STAZIONE",
    "state_code": "VT",
    "cab": "733709",
    "cap": "1030"
  },
  {
    "key": "16728",
    "ITA": "VITTORIA",
    "state_code": "RG",
    "cab": "263004",
    "cap": "97019"
  },
  {
    "key": "16729",
    "ITA": "VITTORIA-SCOGLITTI",
    "state_code": "RG",
    "cab": "263004",
    "cap": "97019"
  },
  {
    "key": "16730",
    "ITA": "VITTORIO VENETO-SAN GIACOMO DI VEGLIA",
    "state_code": "TV",
    "cab": "621904",
    "cap": "31029"
  },
  {
    "key": "16731",
    "ITA": "VITTORIO VENETO-SAN FLORIANO",
    "state_code": "TV",
    "cab": "621904",
    "cap": "31029"
  },
  {
    "key": "16732",
    "ITA": "VITTORIO VENETO-NOVE",
    "state_code": "TV",
    "cab": "621904",
    "cap": "31029"
  },
  {
    "key": "16733",
    "ITA": "VITTORIO VENETO-FADALTO",
    "state_code": "TV",
    "cab": "621904",
    "cap": "31029"
  },
  {
    "key": "16734",
    "ITA": "VITTORIO VENETO-COZZUOLO",
    "state_code": "TV",
    "cab": "621904",
    "cap": "31029"
  },
  {
    "key": "16735",
    "ITA": "VITTORIO VENETO-CARPESICA",
    "state_code": "TV",
    "cab": "621904",
    "cap": "31029"
  },
  {
    "key": "16736",
    "ITA": "VITTORIO VENETO",
    "state_code": "TV",
    "cab": "621904",
    "cap": "31029"
  },
  {
    "key": "16737",
    "ITA": "VITTORITO",
    "state_code": "AQ",
    "cab": "491902",
    "cap": "67030"
  },
  {
    "key": "16738",
    "ITA": "VITTUONE",
    "state_code": "MI",
    "cab": "340901",
    "cap": "20010"
  },
  {
    "key": "16739",
    "ITA": "VITULANO",
    "state_code": "BN",
    "cab": "756007",
    "cap": "82038"
  },
  {
    "key": "16740",
    "ITA": "VITULAZIO",
    "state_code": "CE",
    "cab": "885707",
    "cap": "81041"
  },
  {
    "key": "16741",
    "ITA": "VIU'-COL SAN GIOVANNI",
    "state_code": "TO",
    "cab": "312207",
    "cap": "10070"
  },
  {
    "key": "16742",
    "ITA": "VIU'",
    "state_code": "TO",
    "cab": "312207",
    "cap": "10070"
  },
  {
    "key": "16743",
    "ITA": "VIVARO",
    "state_code": "PN",
    "cab": "650705",
    "cap": "33099"
  },
  {
    "key": "16744",
    "ITA": "VIVARO ROMANO",
    "state_code": "RM",
    "cab": "908434",
    "cap": "20"
  },
  {
    "key": "16745",
    "ITA": "VIVERONE",
    "state_code": "BI",
    "cab": "449405",
    "cap": "13886"
  },
  {
    "key": "16746",
    "ITA": "VIZZINI",
    "state_code": "CT",
    "cab": "843201",
    "cap": "95049"
  },
  {
    "key": "16747",
    "ITA": "VIZZOLA TICINO",
    "state_code": "VA",
    "cab": "924779",
    "cap": "21010"
  },
  {
    "key": "16748",
    "ITA": "VIZZOLO PREDABISSI",
    "state_code": "MI",
    "cab": "342006",
    "cap": "20070"
  },
  {
    "key": "16749",
    "ITA": "VIZZOLO PREDABISSI-SARMAZZANO",
    "state_code": "MI",
    "cab": "342006",
    "cap": "20070"
  },
  {
    "key": "16750",
    "ITA": "VO'",
    "state_code": "PD",
    "cab": "630004",
    "cap": "35030"
  },
  {
    "key": "16751",
    "ITA": "VO'-VO' VECCHIO",
    "state_code": "PD",
    "cab": "630004",
    "cap": "35030"
  },
  {
    "key": "16752",
    "ITA": "VOBARNO",
    "state_code": "BS",
    "cab": "554501",
    "cap": "25079"
  },
  {
    "key": "16753",
    "ITA": "VOBARNO-DEGAGNA",
    "state_code": "BS",
    "cab": "554501",
    "cap": "25079"
  },
  {
    "key": "16754",
    "ITA": "VOBARNO-CARPENEDA",
    "state_code": "BS",
    "cab": "554501",
    "cap": "25079"
  },
  {
    "key": "16755",
    "ITA": "VOBARNO-POMPEGNINO",
    "state_code": "BS",
    "cab": "554501",
    "cap": "25079"
  },
  {
    "key": "16756",
    "ITA": "VOBBIA",
    "state_code": "GE",
    "cab": "903294",
    "cap": "16010"
  },
  {
    "key": "16757",
    "ITA": "VOCCA",
    "state_code": "VC",
    "cab": "916510",
    "cap": "13020"
  },
  {
    "key": "16758",
    "ITA": "VODO CADORE",
    "state_code": "BL",
    "cab": "581108",
    "cap": "32040"
  },
  {
    "key": "16759",
    "ITA": "VOGHERA",
    "state_code": "PV",
    "cab": "564005",
    "cap": "27058"
  },
  {
    "key": "16760",
    "ITA": "VOGHERA-ORIOLO",
    "state_code": "PV",
    "cab": "564005",
    "cap": "27058"
  },
  {
    "key": "16761",
    "ITA": "VOGHIERA-MONTESANTO",
    "state_code": "FE",
    "cab": "673608",
    "cap": "44019"
  },
  {
    "key": "16762",
    "ITA": "VOGHIERA",
    "state_code": "FE",
    "cab": "673608",
    "cap": "44019"
  },
  {
    "key": "16763",
    "ITA": "VOGHIERA-VOGHENZA",
    "state_code": "FE",
    "cab": "673608",
    "cap": "44019"
  },
  {
    "key": "16764",
    "ITA": "VOGOGNA",
    "state_code": "VB",
    "cab": "457606",
    "cap": "28805"
  },
  {
    "key": "16765",
    "ITA": "VOLANO",
    "state_code": "TN",
    "cab": "358200",
    "cap": "38060"
  },
  {
    "key": "16766",
    "ITA": "VOLLA",
    "state_code": "NA",
    "cab": "403204",
    "cap": "80040"
  },
  {
    "key": "16767",
    "ITA": "VOLONGO",
    "state_code": "CR",
    "cab": "931923",
    "cap": "26030"
  },
  {
    "key": "16768",
    "ITA": "VOLPAGO DEL MONTELLO-VENEGAZZU'",
    "state_code": "TV",
    "cab": "622001",
    "cap": "31040"
  },
  {
    "key": "16769",
    "ITA": "VOLPAGO DEL MONTELLO",
    "state_code": "TV",
    "cab": "622001",
    "cap": "31040"
  },
  {
    "key": "16770",
    "ITA": "VOLPAGO DEL MONTELLO-SELVA DEL MONTELLO",
    "state_code": "TV",
    "cab": "622001",
    "cap": "31040"
  },
  {
    "key": "16771",
    "ITA": "VOLPARA",
    "state_code": "PV",
    "cab": "931105",
    "cap": "27047"
  },
  {
    "key": "16772",
    "ITA": "VOLPEDO",
    "state_code": "AL",
    "cab": "487702",
    "cap": "15059"
  },
  {
    "key": "16773",
    "ITA": "VOLPEGLINO",
    "state_code": "AL",
    "cab": "921825",
    "cap": "15050"
  },
  {
    "key": "16774",
    "ITA": "VOLPIANO",
    "state_code": "TO",
    "cab": "312306",
    "cap": "10088"
  },
  {
    "key": "16775",
    "ITA": "VOLTA MANTOVANA-CERETA",
    "state_code": "MN",
    "cab": "580506",
    "cap": "46049"
  },
  {
    "key": "16776",
    "ITA": "VOLTA MANTOVANA",
    "state_code": "MN",
    "cab": "580506",
    "cap": "46049"
  },
  {
    "key": "16777",
    "ITA": "VOLTAGGIO",
    "state_code": "AL",
    "cab": "487801",
    "cap": "15060"
  },
  {
    "key": "16778",
    "ITA": "VOLTAGO AGORDINO",
    "state_code": "BL",
    "cab": "835306",
    "cap": "32020"
  },
  {
    "key": "16779",
    "ITA": "VOLTAGO AGORDINO-FRASSENE'",
    "state_code": "BL",
    "cab": "835306",
    "cap": "32020"
  },
  {
    "key": "16780",
    "ITA": "VOLTERRA",
    "state_code": "PI",
    "cab": "712208",
    "cap": "56048"
  },
  {
    "key": "16781",
    "ITA": "VOLTERRA-MAZZOLLA",
    "state_code": "PI",
    "cab": "712208",
    "cap": "56048"
  },
  {
    "key": "16782",
    "ITA": "VOLTERRA-PIGNANO",
    "state_code": "PI",
    "cab": "712208",
    "cap": "56048"
  },
  {
    "key": "16783",
    "ITA": "VOLTERRA-ULIGNANO",
    "state_code": "PI",
    "cab": "712208",
    "cap": "56048"
  },
  {
    "key": "16784",
    "ITA": "VOLTERRA-SALINE",
    "state_code": "PI",
    "cab": "712208",
    "cap": "56048"
  },
  {
    "key": "16785",
    "ITA": "VOLTERRA-VILLAMAGNA",
    "state_code": "PI",
    "cab": "712208",
    "cap": "56048"
  },
  {
    "key": "16786",
    "ITA": "VOLTIDO",
    "state_code": "CR",
    "cab": "931931",
    "cap": "26030"
  },
  {
    "key": "16787",
    "ITA": "VOLTURARA APPULA",
    "state_code": "FG",
    "cab": "956433",
    "cap": "71030"
  },
  {
    "key": "16788",
    "ITA": "VOLTURARA IRPINA",
    "state_code": "AV",
    "cab": "759704",
    "cap": "83050"
  },
  {
    "key": "16789",
    "ITA": "VOLTURINO",
    "state_code": "FG",
    "cab": "787507",
    "cap": "71030"
  },
  {
    "key": "16790",
    "ITA": "VOLVERA",
    "state_code": "TO",
    "cab": "312405",
    "cap": "10040"
  },
  {
    "key": "16791",
    "ITA": "VOLVERA-ZUCCHE",
    "state_code": "TO",
    "cab": "312405",
    "cap": "10040"
  },
  {
    "key": "16792",
    "ITA": "VOTTIGNASCO",
    "state_code": "CN",
    "cab": "429902",
    "cap": "12020"
  },
  {
    "key": "16793",
    "ITA": "ZACCANOPOLI",
    "state_code": "VV",
    "cab": "913715",
    "cap": "89867"
  },
  {
    "key": "16794",
    "ITA": "ZAFFERANA ETNEA",
    "state_code": "CT",
    "cab": "843300",
    "cap": "95019"
  },
  {
    "key": "16795",
    "ITA": "ZAFFERANA ETNEA-SARRO",
    "state_code": "CT",
    "cab": "843300",
    "cap": "95019"
  },
  {
    "key": "16796",
    "ITA": "ZAFFERANA ETNEA-FLERI",
    "state_code": "CT",
    "cab": "843300",
    "cap": "95019"
  },
  {
    "key": "16797",
    "ITA": "ZAFFERANA ETNEA-PISANO ETNEO",
    "state_code": "CT",
    "cab": "843300",
    "cap": "95019"
  },
  {
    "key": "16798",
    "ITA": "ZAGARISE",
    "state_code": "CZ",
    "cab": "717306",
    "cap": "88050"
  },
  {
    "key": "16799",
    "ITA": "ZAGAROLO-VALLE MARTELLA",
    "state_code": "RM",
    "cab": "395103",
    "cap": "39"
  },
  {
    "key": "16800",
    "ITA": "ZAGAROLO",
    "state_code": "RM",
    "cab": "395103",
    "cap": "39"
  },
  {
    "key": "16801",
    "ITA": "ZAMBANA",
    "state_code": "TN",
    "cab": "358309",
    "cap": "38010"
  },
  {
    "key": "16802",
    "ITA": "ZAMBRONE",
    "state_code": "VV",
    "cab": "913731",
    "cap": "89868"
  },
  {
    "key": "16803",
    "ITA": "ZAMBRONE-DAFFINA'",
    "state_code": "VV",
    "cab": "913731",
    "cap": "89868"
  },
  {
    "key": "16804",
    "ITA": "ZAMBRONE-SAN GIOVANNI DI ZAMBRONE",
    "state_code": "VV",
    "cab": "913731",
    "cap": "89868"
  },
  {
    "key": "16805",
    "ITA": "ZANDOBBIO",
    "state_code": "BG",
    "cab": "733907",
    "cap": "24060"
  },
  {
    "key": "16806",
    "ITA": "ZANE'",
    "state_code": "VI",
    "cab": "608901",
    "cap": "36010"
  },
  {
    "key": "16807",
    "ITA": "ZANICA",
    "state_code": "BG",
    "cab": "537803",
    "cap": "24050"
  },
  {
    "key": "16808",
    "ITA": "ZAPPONETA",
    "state_code": "FG",
    "cab": "787200",
    "cap": "71030"
  },
  {
    "key": "16809",
    "ITA": "ZAPPONETA-FONTEROSA",
    "state_code": "FG",
    "cab": "787200",
    "cap": "71030"
  },
  {
    "key": "16810",
    "ITA": "ZAVATTARELLO",
    "state_code": "PV",
    "cab": "564104",
    "cap": "27059"
  },
  {
    "key": "16811",
    "ITA": "ZECCONE",
    "state_code": "PV",
    "cab": "931113",
    "cap": "27010"
  },
  {
    "key": "16812",
    "ITA": "ZEDDIANI",
    "state_code": "OR",
    "cab": "881904",
    "cap": "9070"
  },
  {
    "key": "16813",
    "ITA": "ZELBIO",
    "state_code": "CO",
    "cab": "926485",
    "cap": "22020"
  },
  {
    "key": "16814",
    "ITA": "ZELO BUON PERSICO",
    "state_code": "LO",
    "cab": "341008",
    "cap": "26839"
  },
  {
    "key": "16815",
    "ITA": "ZELO SURRIGONE",
    "state_code": "MI",
    "cab": "904243",
    "cap": "20080"
  },
  {
    "key": "16816",
    "ITA": "ZEME",
    "state_code": "PV",
    "cab": "564203",
    "cap": "27030"
  },
  {
    "key": "16817",
    "ITA": "ZENEVREDO",
    "state_code": "PV",
    "cab": "931121",
    "cap": "27049"
  },
  {
    "key": "16818",
    "ITA": "ZENSON DI PIAVE",
    "state_code": "TV",
    "cab": "622100",
    "cap": "31050"
  },
  {
    "key": "16819",
    "ITA": "ZERBA-PEJ",
    "state_code": "PC",
    "cab": "938696",
    "cap": "29020"
  },
  {
    "key": "16820",
    "ITA": "ZERBA",
    "state_code": "PC",
    "cab": "938696",
    "cap": "29020"
  },
  {
    "key": "16821",
    "ITA": "ZERBO",
    "state_code": "PV",
    "cab": "931139",
    "cap": "27017"
  },
  {
    "key": "16822",
    "ITA": "ZERBOLO'",
    "state_code": "PV",
    "cab": "931147",
    "cap": "27020"
  },
  {
    "key": "16823",
    "ITA": "ZERFALIU",
    "state_code": "OR",
    "cab": "882001",
    "cap": "9070"
  },
  {
    "key": "16824",
    "ITA": "ZERI-CHIESA DI ROSSANO",
    "state_code": "MS",
    "cab": "699900",
    "cap": "54029"
  },
  {
    "key": "16825",
    "ITA": "ZERI",
    "state_code": "MS",
    "cab": "699900",
    "cap": "54029"
  },
  {
    "key": "16826",
    "ITA": "ZERMEGHEDO",
    "state_code": "VI",
    "cab": "609503",
    "cap": "36050"
  },
  {
    "key": "16827",
    "ITA": "ZERO BRANCO-SCANDOLARA",
    "state_code": "TV",
    "cab": "622209",
    "cap": "31059"
  },
  {
    "key": "16828",
    "ITA": "ZERO BRANCO",
    "state_code": "TV",
    "cab": "622209",
    "cap": "31059"
  },
  {
    "key": "16829",
    "ITA": "ZERO BRANCO-SANT'ALBERTO",
    "state_code": "TV",
    "cab": "622209",
    "cap": "31059"
  },
  {
    "key": "16830",
    "ITA": "ZEVIO",
    "state_code": "VR",
    "cab": "599704",
    "cap": "37059"
  },
  {
    "key": "16831",
    "ITA": "ZEVIO-CAMPAGNOLA",
    "state_code": "VR",
    "cab": "599704",
    "cap": "37059"
  },
  {
    "key": "16832",
    "ITA": "ZEVIO-PERZACCO",
    "state_code": "VR",
    "cab": "599704",
    "cap": "37059"
  },
  {
    "key": "16833",
    "ITA": "ZEVIO-SANTA MARIA DI ZEVIO",
    "state_code": "VR",
    "cab": "599704",
    "cap": "37059"
  },
  {
    "key": "16834",
    "ITA": "ZEVIO-VOLON",
    "state_code": "VR",
    "cab": "599704",
    "cap": "37059"
  },
  {
    "key": "16835",
    "ITA": "ZIANO DI FIEMME",
    "state_code": "TN",
    "cab": "358408",
    "cap": "38030"
  },
  {
    "key": "16836",
    "ITA": "ZIANO PIACENTINO-VICOBARONE",
    "state_code": "PC",
    "cab": "655209",
    "cap": "29010"
  },
  {
    "key": "16837",
    "ITA": "ZIANO PIACENTINO",
    "state_code": "PC",
    "cab": "655209",
    "cap": "29010"
  },
  {
    "key": "16838",
    "ITA": "ZIBELLO",
    "state_code": "PR",
    "cab": "660407",
    "cap": "43010"
  },
  {
    "key": "16839",
    "ITA": "ZIBELLO-PIEVEOTTOVILLE",
    "state_code": "PR",
    "cab": "660407",
    "cap": "43010"
  },
  {
    "key": "16840",
    "ITA": "ZIBIDO SAN GIACOMO-SAN PIETRO CUSICO",
    "state_code": "MI",
    "cab": "341701",
    "cap": "20080"
  },
  {
    "key": "16841",
    "ITA": "ZIBIDO SAN GIACOMO-MOIRAGO",
    "state_code": "MI",
    "cab": "341701",
    "cap": "20080"
  },
  {
    "key": "16842",
    "ITA": "ZIBIDO SAN GIACOMO",
    "state_code": "MI",
    "cab": "341701",
    "cap": "20080"
  },
  {
    "key": "16843",
    "ITA": "ZIBIDO SAN GIACOMO-BADILE",
    "state_code": "MI",
    "cab": "341701",
    "cap": "20080"
  },
  {
    "key": "16844",
    "ITA": "ZIGNAGO",
    "state_code": "SP",
    "cab": "923789",
    "cap": "19020"
  },
  {
    "key": "16845",
    "ITA": "ZIGNAGO-PIEVE DI ZIGNAGO",
    "state_code": "SP",
    "cab": "923789",
    "cap": "19020"
  },
  {
    "key": "16846",
    "ITA": "ZIMELLA",
    "state_code": "VR",
    "cab": "599803",
    "cap": "37040"
  },
  {
    "key": "16847",
    "ITA": "ZIMELLA-SANTO STEFANO",
    "state_code": "VR",
    "cab": "599803",
    "cap": "37040"
  },
  {
    "key": "16848",
    "ITA": "ZIMONE",
    "state_code": "BI",
    "cab": "916528",
    "cap": "13887"
  },
  {
    "key": "16849",
    "ITA": "ZINASCO-ZINASCO NUOVO",
    "state_code": "PV",
    "cab": "564302",
    "cap": "27030"
  },
  {
    "key": "16850",
    "ITA": "ZINASCO",
    "state_code": "PV",
    "cab": "564302",
    "cap": "27030"
  },
  {
    "key": "16851",
    "ITA": "ZINASCO-SAIRANO",
    "state_code": "PV",
    "cab": "564302",
    "cap": "27030"
  },
  {
    "key": "16852",
    "ITA": "ZINASCO-ZINASCO VECCHIO",
    "state_code": "PV",
    "cab": "564302",
    "cap": "27030"
  },
  {
    "key": "16853",
    "ITA": "ZOAGLI-SANT'ANNA",
    "state_code": "GE",
    "cab": "322800",
    "cap": "16030"
  },
  {
    "key": "16854",
    "ITA": "ZOAGLI",
    "state_code": "GE",
    "cab": "322800",
    "cap": "16030"
  },
  {
    "key": "16855",
    "ITA": "ZOCCA-MONTECORONE",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16856",
    "ITA": "ZOCCA-MONTEOMBRARO",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16857",
    "ITA": "ZOCCA-MONTETORTORE",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16858",
    "ITA": "ZOCCA-MONTEALBANO",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16859",
    "ITA": "ZOCCA",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16860",
    "ITA": "ZOCCA-CIANO",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16861",
    "ITA": "ZOCCA-MISSANO",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16862",
    "ITA": "ZOCCA-ROSOLA",
    "state_code": "MO",
    "cab": "670802",
    "cap": "41059"
  },
  {
    "key": "16863",
    "ITA": "ZOGNO-SPINO",
    "state_code": "BG",
    "cab": "537902",
    "cap": "24019"
  },
  {
    "key": "16864",
    "ITA": "ZOGNO-AMBRIA",
    "state_code": "BG",
    "cab": "537902",
    "cap": "24019"
  },
  {
    "key": "16865",
    "ITA": "ZOGNO",
    "state_code": "BG",
    "cab": "537902",
    "cap": "24019"
  },
  {
    "key": "16866",
    "ITA": "ZOGNO-POSCANTE",
    "state_code": "BG",
    "cab": "537902",
    "cap": "24019"
  },
  {
    "key": "16867",
    "ITA": "ZOLA PREDOSA",
    "state_code": "BO",
    "cab": "371302",
    "cap": "40069"
  },
  {
    "key": "16868",
    "ITA": "ZOLA PREDOSA-PONTE RONCA",
    "state_code": "BO",
    "cab": "371302",
    "cap": "40069"
  },
  {
    "key": "16869",
    "ITA": "ZOLA PREDOSA-RIALE",
    "state_code": "BO",
    "cab": "371302",
    "cap": "40069"
  },
  {
    "key": "16870",
    "ITA": "ZOLA PREDOSA-ZOLA",
    "state_code": "BO",
    "cab": "371302",
    "cap": "40069"
  },
  {
    "key": "16871",
    "ITA": "ZOLDO ALTO",
    "state_code": "BL",
    "cab": "613703",
    "cap": "32010"
  },
  {
    "key": "16872",
    "ITA": "ZOLDO ALTO-MARESON",
    "state_code": "BL",
    "cab": "613703",
    "cap": "32010"
  },
  {
    "key": "16873",
    "ITA": "ZOLDO ALTO-PECOL",
    "state_code": "BL",
    "cab": "613703",
    "cap": "32010"
  },
  {
    "key": "16874",
    "ITA": "ZOLLINO",
    "state_code": "LE",
    "cab": "478602",
    "cap": "73010"
  },
  {
    "key": "16875",
    "ITA": "ZONE",
    "state_code": "BS",
    "cab": "554600",
    "cap": "25050"
  },
  {
    "key": "16876",
    "ITA": "ZOPPE' DI CADORE",
    "state_code": "BL",
    "cab": "774901",
    "cap": "32010"
  },
  {
    "key": "16877",
    "ITA": "ZOPPOLA",
    "state_code": "PN",
    "cab": "650606",
    "cap": "33080"
  },
  {
    "key": "16878",
    "ITA": "ZOPPOLA-CASTIONS",
    "state_code": "PN",
    "cab": "650606",
    "cap": "33080"
  },
  {
    "key": "16879",
    "ITA": "ZOPPOLA-ORCENICO INFERIORE",
    "state_code": "PN",
    "cab": "650606",
    "cap": "33080"
  },
  {
    "key": "16880",
    "ITA": "ZOVENCEDO",
    "state_code": "VI",
    "cab": "934208",
    "cap": "36020"
  },
  {
    "key": "16881",
    "ITA": "ZUBIENA",
    "state_code": "BI",
    "cab": "916536",
    "cap": "13888"
  },
  {
    "key": "16882",
    "ITA": "ZUCCARELLO",
    "state_code": "SV",
    "cab": "923359",
    "cap": "17039"
  },
  {
    "key": "16883",
    "ITA": "ZUCLO",
    "state_code": "TN",
    "cab": "905224",
    "cap": "38079"
  },
  {
    "key": "16884",
    "ITA": "ZUGLIANO",
    "state_code": "VI",
    "cab": "608604",
    "cap": "36030"
  },
  {
    "key": "16885",
    "ITA": "ZUGLIANO-CENTRALE",
    "state_code": "VI",
    "cab": "608604",
    "cap": "36030"
  },
  {
    "key": "16886",
    "ITA": "ZUGLIANO-GRUMOLO PEDEMONTE",
    "state_code": "VI",
    "cab": "608604",
    "cap": "36030"
  },
  {
    "key": "16887",
    "ITA": "ZUGLIO",
    "state_code": "UD",
    "cab": "937342",
    "cap": "33020"
  },
  {
    "key": "16888",
    "ITA": "ZUMAGLIA",
    "state_code": "BI",
    "cab": "916544",
    "cap": "13848"
  },
  {
    "key": "16889",
    "ITA": "ZUMPANO-MALAVICINA",
    "state_code": "CS",
    "cab": "651505",
    "cap": "87040"
  },
  {
    "key": "16890",
    "ITA": "ZUMPANO",
    "state_code": "CS",
    "cab": "651505",
    "cap": "87040"
  },
  {
    "key": "16891",
    "ITA": "ZUNGOLI",
    "state_code": "AV",
    "cab": "951863",
    "cap": "83030"
  },
  {
    "key": "16892",
    "ITA": "ZUNGRI",
    "state_code": "VV",
    "cab": "913749",
    "cap": "89867"
  }
]