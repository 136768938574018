import dayjs from 'dayjs'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const log = new Logger('Marshal/Subscription')

export const marshalSubscription = (subscription) => {
  const parsedSubscription = {
    ...subscription,
    start_date: dayjs(subscription.start_date),
    end_date: _.isNil(subscription.end_date)
      ? null
      : dayjs(subscription.end_date),
  }
  log.debug('marshal sub', parsedSubscription)
  return parsedSubscription
}

export const initSubscription = {
  fixed_fee: 30,
  rope_bell_fee: 20,
  electrified_bell_fee: 25,
}
