import React, { useContext, useState } from 'react'
import { Logger } from 'aws-amplify'
import { Button, Input, Space, Table } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { addPropertyToColumns } from '../../../../utils'
import { ReferentModal } from './ReferentModal'
import _ from 'lodash'
import {
  ModalDelete,
} from '../../../.././Components'
import DeleteRecord from '../../../.././Components/DeleteRecord'
import { CustomerContext } from '../../contexts'
import {Display, LocaleDisplayArray} from "../../../../Components/Display";

const log = new Logger('ReferentsTable')

const StyledTableFooter = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`

/* Referents Table */
export const ReferentsTable = ({ dataSource, prefix, propKey }) => {
  // inject intl
  const intl = useIntl()

  // handle row delete
  const handleDelete = (key) => {
    log.debug('delete referent with index: ', key)
    const newData = _.filter(referents, (item, index) => index !== key)
    setReferents(newData)
  }

  const { readonly } = useContext(CustomerContext)

  // referents columns
  const columns = (() => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.referents.attributes.full_name',
        }),
        dataIndex: 'full_name',
        render: (text, record) => (
          <Display
            value={`${_.get(record, 'first_name')} ${_.get(
              record,
              'last_name',
              ''
            )}`}
          />
        ),
      },
      {
        title: intl.formatMessage({ id: 'ui.referents.attributes.roles' }),
        dataIndex: 'roles',
        render: (_, record) => (
          <LocaleDisplayArray
            localeAttribute={`ui.referents.enums.roles`}
            array={record.roles}
          />
        ),
      },
      {
        title: intl.formatMessage({ id: 'ui.referents.attributes.email' }),
        dataIndex: 'email',
        render: (_, record) => <Display value={record.email} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.referents.attributes.phone_1' }),
        dataIndex: 'phone_1',
        render: (_, record) => <Display value={record.phone_1} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.referents.attributes.phone_2' }),
        dataIndex: 'phone_2',
        render: (_, record) => <Display value={record.phone_2} />,
      },
      {
        title: intl.formatMessage({ id: 'ui.referents.attributes.notes' }),
        dataIndex: 'notes',
        render: (_, record) => <Display value={record.notes} />,
      },

      ...(readonly
        ? []
        : [
            {
              title: intl.formatMessage({
                id: 'ui.actions.title',
              }),
              key: 'action',
              render: (_, record, index) => (
                <>
                  {/* ACTIONS */}
                  <Space>
                    {/* DELETE */}
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        // open modal
                        setDeleteModalData({
                          open: true,
                          referent: record,
                          index,
                        })
                        // handleDelete(index);
                      }}
                    ></Button>
                    {/* EDIT */}
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setModalData({
                          referent: record,
                          open: true,
                        })
                      }}
                    />
                  </Space>
                </>
              ),
            },
          ]),
    ]

    log.verbose('Adding responsive property to columns ...')
    return addPropertyToColumns(columns, 'responsive', ['md'])
  })()

  // modal state
  const [modalData, setModalData] = useState({
    open: false,
    referent: null,
  })

  // delete modal state
  const [deleteModalData, setDeleteModalData] = useState({
    open: false,
  })

  // referents to delete
  const [deletedReferents, setDeletedReferents] = useState([])

  // referents data source
  const [referents, setReferents] = useState(dataSource ?? [])

  log.debug('Referents data source: ', referents)
  log.debug('referents to delete', deletedReferents)
  // index for table key if id not provided
  const [index, setIndex] = useState(_.get(_.maxBy(referents, 'id'), 'id', 0))

  // marshal referent for dataSource
  const marshalReferent = (referent) => {
    log.debug('Current index', index)
    return {
      ...referent,
      key: (() => {
        setIndex(index + 1)
        return index + 1
      })(),
    }
  }

  const onCreate = (referent) => {
    log.info('Received form values...', referent)

    if (referent.id) {
      log.verbose('Referent already has id', referent.id)
      const referentIndex = _.findIndex(referents, (v) => v.id === referent.id)
      setReferents([
        ...referents.slice(0, referentIndex),
        referent,
        ...referents.slice(referentIndex + 1),
      ])
    } else if (referent.key) {
      log.verbose('Referent already has key', referent.key)
      const referentIndex = _.findIndex(
        referents,
        (v) => v.key === referent.key
      )
      setReferents([
        ...referents.slice(0, referentIndex),
        referent,
        ...referents.slice(referentIndex + 1),
      ])
    } else {
      const marshalledReferent = marshalReferent(referent)

      setReferents([...referents, marshalledReferent])
    }

    setModalData({ ...modalData, open: false })
  }

  return (
    <>
      {/* Referent Table */}
      <Table
        bordered
        footer={() =>
          !readonly && (
            <StyledTableFooter>
              <Button
                type={'primary'}
                onClick={() =>
                  setModalData({
                    referent: null,
                    open: true,
                  })
                }
              >
                <FormattedMessage id={'ui.referents.buttons.add'} />
              </Button>
            </StyledTableFooter>
          )
        }
        columns={columns}
        rowKey={(record) => record.id ?? record.key}
        dataSource={referents}
      />

      {/* Referent Modal */}
      <ReferentModal
        initialValues={modalData}
        onCancel={() => {
          setModalData({
            referent: null,
            open: false,
          })
        }}
        onCreate={onCreate}
      />

      {/* Delete Referent Modal */}
      <ModalDelete
        key={`delete-referent-modal-${propKey}`}
        open={deleteModalData.open}
        onOk={() => {
          const referent_id = _.get(deleteModalData.referent, 'id')

          log.verbose('Deleting referent with id ', referent_id)

          if (referent_id) {
            setDeletedReferents([
              ...deletedReferents,
              {
                index: deleteModalData.index,
                id: referent_id,
                _destroy: true,
              },
            ])
          }

          handleDelete(deleteModalData.index)

          setDeleteModalData({
            open: false,
          })
        }}
        model={'referents'}
        data={deleteModalData.referent}
        keys={['first_name', 'last_name', 'email']}
        onCancel={() => {
          setDeleteModalData({
            open: false,
          })
        }}
        recordType={<FormattedMessage id={'ui.referents.singular'} />}
      />

      {/* Add hidden inputs for rails */}
      {referents.map((r, i) => {
        return _.map(r, (value, key) => {
          if (_.isEqual(key, 'roles')) {
            return _.map(_.split(value, ','), (role, idx) => {
              return (
                <Input
                  name={`${prefix}[${i}][${key}][]`}
                  type={'hidden'}
                  value={role}
                  key={`${key}-${idx}`}
                />
              )
            })
          } else {
            return (
              <Input
                name={`${prefix}[${i}][${key}]`}
                type={'hidden'}
                value={value}
                key={key}
              />
            )
          }
        })
      })}

      {/* Add Hidden Record to delete referents*/}
      {deletedReferents.map((r, key) => (
        <DeleteRecord key={key} record={r} prefix={`${prefix}[${r.index}]`} />
      ))}
    </>
  )
}
