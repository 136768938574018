import React from 'react'
import { Button, Form, Modal } from 'antd'
import { Logger } from 'aws-amplify'
import { FormattedMessage } from 'react-intl'
import { ReferentForm } from './ReferentForm'

const log = new Logger('ReferentModal')

export const ReferentModal = ({ initialValues, onCancel, onCreate }) => {
  const [form] = Form.useForm()

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields()
        onCreate(values)
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  log.debug('Modal data', initialValues)

  return (
    <Modal
      afterClose={() => form.resetFields()}
      destroyOnClose
      open={initialValues.open}
      title={<FormattedMessage id={'ui.referents.modal.title'} />}
      onCancel={onCancel}
      footer={
        <Button type={'primary'} onClick={handleOk}>
          <FormattedMessage id={'ui.referents.modal.ok_text'} />
        </Button>
      }
    >
      <ReferentForm form={form} initialValues={initialValues.referent} />
    </Modal>
  )
}