import React, { useContext, useEffect, useState } from 'react'
import { Card, Col } from 'antd'
import { CenteredSpinner } from '../.././Components'
import { FormattedMessage } from 'react-intl'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import { CallContextProvider } from './contexts'
import { GlobalContext } from '../../contexts'
import { CallForm } from './form'
import { marshalCall } from '../../utils/calls'
import { StyledDivider } from '../../styled'
import FormFields from './form/FormFields'
import { InterventionsTable } from './components'
import { DisplayDetail, DisplayPrice } from '../../Components/Display'
import { GutteredRow } from '../../Components'

const log = new Logger('Calls/Detail')

const Show = (props) => {
  log.verbose('Current props: ', props)

  // Tag select supports only array
  props.call.reported_by ||= []

  const currentCall = _.get(props, 'call', {})
  const [call, setCall] = useState(marshalCall(currentCall))

  const { setMode, loading } = useContext(GlobalContext)

  const { facilities, referents } = props

  log.debug('call', { call })

  useEffect(() => {
    setMode('detail')
  }, [])

  const [modalData, setModalData] = useState({ open: false })

  return (
    <CallContextProvider value={{ call, setCall, modalData, setModalData }}>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <>
          {/* removed until further instructions */}

          {/* Add Close form */}
          {/*<CloseForm*/}
          {/*  id={call.id}*/}
          {/*  modalData={modalData}*/}
          {/*  setModalData={setModalData}*/}
          {/*/>*/}

          <CallForm {...props}>
            <Card>
              <FormFields facilities={facilities} referents={referents} />
            </Card>
          </CallForm>

          {/*  Interventions Table */}
          <Card style={{ marginTop: '20px' }}>
            <StyledDivider orientation={'left'}>
              <FormattedMessage id={'ui.calls.linked_interventions'} />
            </StyledDivider>

            <InterventionsTable dataSource={call.interventions} />

            <GutteredRow>
              {/* Total Amount */}
              <Col>
                <DisplayDetail
                  label={
                    <FormattedMessage id={'ui.calls.total_interventions'} />
                  }
                  value={
                    <DisplayPrice
                      value={_.sumBy(
                        _.get(call, 'interventions', []),
                        'total_intervention'
                      )}
                    />
                  }
                />
              </Col>
            </GutteredRow>
          </Card>
        </>
      )}
    </CallContextProvider>
  )
}

export default Show
