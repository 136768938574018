import React, { useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import { StyledContainer, StyledDivider } from '../../../styled'
import { FormattedMessage } from 'react-intl'
import { MaterialsContextProvider } from '../contexts'
import { MaterialsTable } from './index'
import { marshalMaterialsFromInterventions } from '../../../utils/invoices'
import { CalculationFields } from '../form'

const log = new Logger('Invoices/components/Materials')

const Materials = ({ interventions }) => {
  // states

  const [materials, setMaterials] = useState([])

  // update materials based on interventions
  useEffect(() => {
    setMaterials(marshalMaterialsFromInterventions(interventions))
  }, [interventions])

  return (
    <MaterialsContextProvider
      value={{
        materials,
        setMaterials,
      }}
    >
      <StyledContainer>
        <StyledDivider orientation={'left'}>
          <FormattedMessage id={'ui.materials.list_name'} />
        </StyledDivider>

        <MaterialsTable />

        <CalculationFields />
      </StyledContainer>
    </MaterialsContextProvider>
  )
}

export default Materials
