import { CookiesProvider } from 'react-cookie'
import { Authenticator } from '@aws-amplify/ui-react'
import React from 'react'
import App from './App'
import { flattenMessages } from './utils'
import { IntlProvider } from 'react-intl'
import itIt from 'antd/locale/it_IT'

import { messages } from './dictionaries'
import { ConfigProvider } from 'antd'
import { ReferrerWrapperProvider } from './Components/Providers'

export default (props) => {
  return (
    <CookiesProvider>
      <IntlProvider locale={'it'} messages={flattenMessages(messages['it'])}>
        <Authenticator.Provider>
          <ConfigProvider
            locale={itIt}
            theme={{
              components: {
                DatePicker: {
                  colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
                },
              },
              token: {
                colorTextDisabled: 'black',
                colorPrimary: '#002242',
                fontFamily: 'Roboto, sans-serif',
              },
            }}
          >
            <ReferrerWrapperProvider>
              <App {...props} />
            </ReferrerWrapperProvider>
          </ConfigProvider>
        </Authenticator.Provider>
      </IntlProvider>
    </CookiesProvider>
  )
}
