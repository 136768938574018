import React, { useState } from 'react'
import { CustomForm, ModalDelete } from './index'
import { Button, Input } from 'antd'
import { FormattedMessage } from 'react-intl'

const DeleteForm = ({ action, name, record }) => {
  const onFinish = () => {
    document.getElementById(name).submit()
  }

  const [loading, setLoading] = useState(false)

  const [deleteModalData, setDeleteModalData] = useState({
    open: false,
    record,
  })

  return (
    <CustomForm method={'post'} action={action} name={name} onFinish={onFinish}>
      <Input type={'hidden'} value={'delete'} name={'_method'} />

      <Button
        danger
        type={'primary'}
        onClick={() =>
          setDeleteModalData({
            open: true,
            record,
          })
        }
      >
        <FormattedMessage id={'ui.button.delete'} />
      </Button>

      <ModalDelete
        model={'customers'}
        onOk={onFinish}
        open={deleteModalData.open}
        onCancel={() => setDeleteModalData({ open: false })}
        recordType={<FormattedMessage id={'ui.customers.singular'} />}
        data={deleteModalData.record}
        keys={['business_name']}
      />
    </CustomForm>
  )
}

export default DeleteForm;