import { Form as AntdForm, Input } from 'antd'
import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { StyledDatePicker } from '../../styled'
import { GlobalContext } from '../../contexts'
import dayjs from 'dayjs'

var utc = require('dayjs/plugin/utc')

dayjs.extend(utc)

const log = new Logger('DateTimePickerField')
const DateTimePickerField = ({
  name,
  label,
  onChange = undefined,
  initialValue = null,
  placeholder = null,
  railsName = name,
  required = false,
  disabled = false,
}) => {
  log.verbose('Rendering date picker field ...')
  const intl = useIntl()
  const validateRequired = () => {
    return required
      ? [
          {
            required: true,
          },
        ]
      : ''
  }

  const [selectedDate, setSelectedDate] = useState(initialValue)

  const handleChange = (date, dateString) => {
    log.verbose('Selected date', date)
    log.verbose('Selected date string', dateString)
    const parsedDate = _.invoke(
      _.invoke(date, 'utc'),
      'format',
      'YYYY-MM-DD HH:mm:ss'
    )
    setSelectedDate(parsedDate)

    if (_.isFunction(onChange)) onChange(date)
  }

  const { mode } = useContext(GlobalContext)

  return (
    <>
      <AntdForm.Item
        name={_.toPath(name)}
        label={<FormattedMessage id={label} />}
        required={required}
        rules={validateRequired()}
      >
        <StyledDatePicker
          disabled={disabled || mode === 'detail'}
          onChange={handleChange}
          format={'DD-MM-YYYY HH:mm'}
          showTime={{
            format: 'HH:mm',
          }}
        />
      </AntdForm.Item>
      <Input type={'hidden'} name={railsName} value={selectedDate} />
    </>
  )
}

export default DateTimePickerField
