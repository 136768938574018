import React from 'react'

import { Logger } from 'aws-amplify'

import { SearchBox } from '@components/index'

const log = new Logger('Invoices/components/InterventionSearch')

const InterventionSearch = ({
  placeholder = 'ui.interventions.placeholders.search',
  setQuery,
}) => {
  const onSearch = (query) => setQuery(query)

  return <SearchBox onSearch={onSearch} placeholder={placeholder} />
}

export default InterventionSearch
