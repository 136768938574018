import { FormattedMessage } from 'react-intl'
import { Checkbox, Form, Input } from 'antd'
import React, { useContext, useState } from 'react'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'

const log = new Logger('FieldCheckbox')

const FieldCheckbox = ({
  label,
  name,
  checked,
  onChange = undefined,
  disabled = undefined,
  addRailsField = true,
  railsName = name,
}) => {
  const { mode } = useContext(GlobalContext)

  const [checkedState, setCheckedState] = useState(_.defaultTo(checked, false))

  const handleCheckboxChange = (checkboxValue) => {
    log.debug('checkbox changed', { checkboxValue })

    const checkedValue = _.get(checkboxValue, 'target.checked')
    if (_.isFunction(onChange)) {
      onChange(checkedValue)
    }

    setCheckedState(checkedValue)
  }

  return (
    <>
      <Form.Item
        label={<FormattedMessage id={label} />}
        name={_.toPath(name)}
        // initialValue={checked}
        valuePropName={'checked'}
      >
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={_.defaultTo(disabled, mode === 'detail')}
        />
      </Form.Item>
      {/*  Hidden Field For Rails */}
      {addRailsField && (
        <Input type='hidden' name={railsName} value={checkedState} />
      )}
    </>
  )
}

export default FieldCheckbox
