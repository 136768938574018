import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { message } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

const log = new Logger('Errors')
const Errors = ({ errors }) => {
  const intl = useIntl()

  if (!_.isEmpty(errors)) log.error({ errors })

  useEffect(() => {
    // show errors on top

    if (R.all(R.is(String), errors))
      R.forEach((err) => message.error(err), errors)
    else {
      const errorKeys = R.pipe(
        R.chain(
          R.pipe(
            R.toPairs,
            R.map(([key, values]) =>
              R.map((error) => `ui.${key}.${error}`, values)
            ),
            R.flatten
          )
        ),
        R.values
      )(errors)

      R.map((l) => message.error(intl.formatMessage({ id: l })), errorKeys)
    }
  }, [errors])

  return null
}

export default Errors
