import { Form as AntdForm, Input } from 'antd'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { GlobalContext } from '../../contexts'

const FieldEmail = ({
  name,
  label,
  placeholder = null,
  railsName = null,
  required = false,
}) => {
  const intl = useIntl()

  const { mode } = useContext(GlobalContext)

  return (
    <AntdForm.Item
      name={_.toPath(name)}
      label={label}
      rules={[
        {
          required: required,
        },
        {
          type: 'email',
          message: intl.formatMessage({ id: 'ui.validations.invalid_field' }),
        },
      ]}
    >
      <Input
        type={'email'}
        name={railsName ?? name}
        placeholder={placeholder}
        disabled={mode === 'detail'}
      />
    </AntdForm.Item>
  )
}

export default FieldEmail
