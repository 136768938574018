// Object Select

import React, { useContext, useState } from 'react'
import { Form as AntdForm, Input, Select } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'

const log = new Logger('ObjectSelect')

const ObjectSelect = ({
                        name,
                        options, // object
                        label,
                        railsName = name,
                        onChange = undefined,
                        required = false,
                        defaultValue = null,
                        localAttribute = null,
                      }) => {
  const [value, setValue] = useState(defaultValue);

  // inject intl
  const intl = useIntl();

  const builtOptions = _.map(options, (option, index) => {
    return ({
      key: index,
      label: intl.formatMessage({id: `${localAttribute}.${option[0]}`}),
      value: option[1],
    });
  });

  const onSelect = (selectedValue) => {
    setValue(selectedValue);
    if (_.isFunction(onChange))
      onChange();
  };

  const validateRequired = () => {
    return required
      ? [
        {
          required: true,
        },
      ]
      : '';
  };

  const {mode} = useContext(GlobalContext);

  return (
    <>
      {/* Hidden field for rails*/}
      <Input name={railsName} type={'hidden'} value={value}/>
      <AntdForm.Item
        name={_.toPath(name)}
        label={<FormattedMessage id={label}/>}
        required={required}
        rules={validateRequired()}
      >
        <Select
          showSearch
          options={builtOptions}
          optionFilterProp={'label'}
          onSelect={onSelect}
          disabled={mode === 'detail'}
        />
      </AntdForm.Item>
    </>
  );
};

export default ObjectSelect;
