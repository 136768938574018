import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Card, DatePicker, Space } from 'antd'
import { Auth, Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'

import CenteredSpinner from '@components/CenteredSpinner'

import { INTERVENTION_SEARCH, makeApiRequest } from '../../../../api'
import { RouteContext } from '../../contexts'
import InterventionCard from './InterventionCard'

dayjs.extend(customParseFormat)

const log = new Logger('SidebarInterventionsList')
const { RangePicker } = DatePicker
const InterventionsList = (props) => {
  const {
    interventions,
    setInterventions,
    filters,
    ranges,
    orders,
    setRanges,
    stages,
  } = useContext(RouteContext)
  const intl = useIntl()

  const [loading, setLoading] = useState(false)

  const handleRangeChange = (dates) => {
    if (_.isEmpty(dates)) {
      setRanges([])
      return
    }
    const startDate = dates[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const endDate = dates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')

    log.debug('parsed dates', { startDate, endDate })

    setRanges([
      {
        field: 'expected_date',
        from: startDate,
        to: endDate,
      },
    ])
  }

  useEffect(() => {
    const filterIntervention = async () => {
      setLoading(true)
      const { data } = await makeApiRequest(INTERVENTION_SEARCH, 'post', {
        body: {
          filters,
          ranges,
          orders,
          serializer: 'RouteNewInterventionSerializer',
        },
        queryParams: F.marshalPaginationTo({
          pageSize: 9999,
          current: 1,
        }),
      })

      log.debug('filterIntervention.success', data)
      setInterventions(
        _.unionBy(
          data,
          _.filter(
            interventions,
            (i) => _.get(i, 'status') === 'in_progress' && !_.get(i, ' break')
          ),
          'id'
        )
      )
      setLoading(false)
    }

    filterIntervention()
  }, [filters, ranges])

  return (
    <>
      <Space direction='vertical' size={'small'}>
        {/* Interventions to schedule */}
        <Card>
          <FormattedMessage id={'ui.routes.interventions.to_schedule'} />
        </Card>

        {/* RangePicker for expected date */}
        {/*  range picker for filter*/}
        <RangePicker
          placeholder={[
            intl.formatMessage({ id: 'ui.placeholders.date.from' }),
            intl.formatMessage({ id: 'ui.placeholders.date.to' }),
          ]}
          format={'DD-MM-YYYY'}
          disabled={loading}
          onChange={handleRangeChange}
        />
      </Space>

      {/*  Scrollable Interventions Container */}
      {loading ? (
        <CenteredSpinner />
      ) : (
        <div
          style={{
            height: '80vh',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Space direction={'vertical'} size={'small'}>
            {/* Interventions List */}
            {_.map(interventions, (intervention, idx) => {
              const isStage = _.find(stages, {
                id: intervention.id,
                facility: intervention.facility,
              })

              return _.isEmpty(isStage) ||
                _.get(intervention, 'status') === 'in_progress' ? (
                <InterventionCard key={idx} intervention={intervention} />
              ) : null
            })}
          </Space>
        </div>
      )}
    </>
  )
}

export default InterventionsList
