import _ from 'lodash'
import React from 'react'
import { FacilityForm } from './FacilityForm'
import { Logger } from 'aws-amplify'
import { Collapse, Form } from 'antd'

const { Panel } = Collapse

const log = new Logger('FacilityPanel')
export const FacilityPanel = ({
  initialValues,
  index,
  extra,
  loading,
  ...panelProps
}) => {
  const form = Form.useFormInstance()

  const header = Form.useWatch(
    _.toPath(`facilities.${index}.business_name`),
    form
  )

  return (
    <Panel {...panelProps} header={header} extra={extra}>
      <FacilityForm
        index={index}
        initialValues={_.get(initialValues, `[${index}]`, {})}
        prefix={`facilities_attributes[${index}]`}
        loading={loading}
      />
    </Panel>
  )
}
