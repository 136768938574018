import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Table as AntdTable, Button, Space } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'

import { ModalDelete } from '../../../Components'
import {
  Display,
  DisplayDateTime,
  DisplayPrice,
  LocaleDisplay,
} from '../../../Components/Display'
import { getTotalWithoutMaterials } from '../../../utils'
import { isInvoiced } from '../../../utils/invoices'
import { InterventionsContext } from '../contexts'

const log = new Logger('Invoices/components/InterventionsTable')

/**
 *
 * @return {JSX.Element}
 */
const InterventionsTable = ({ onDelete, invoice }) => {
  const intl = useIntl()

  const { facility, interventions, selectedInterventions } =
    useContext(InterventionsContext)

  // states
  const [deleteModalData, setDeleteModalData] = useState({
    open: false,
  })

  log.debug('Facility Interventions', {
    facility,
    interventions,
    selectedInterventions,
  })

  const columns = [
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.id',
      }),
      dataIndex: 'id',
      render: (_, record) => <Display value={record.id} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.call.id',
      }),
      dataIndex: 'call_id',
      render: (a, record) => <Display value={_.get(record, 'call_id')} />,
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.created_at',
      }),
      dataIndex: 'created_at',
      render: (_, record) => (
        <DisplayDateTime value={record.created_at} format={'DD/MM/YYYY'} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.typology',
      }),
      dataIndex: 'typology',
      render: (_, record) => (
        <LocaleDisplay
          localePath={`ui.interventions.enums.typologies`}
          value={record.typology}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.scheduled_datetime',
      }),
      dataIndex: 'scheduled_datetime',
      render: (_, record) => (
        <DisplayDateTime value={record.scheduled_datetime} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.interventions.attributes.total_intervention',
      }),
      dataIndex: 'total_intervention',
      render: (_, record) => (
        <DisplayPrice value={getTotalWithoutMaterials(facility, record)} />
      ),
    },
    {
      ...(!isInvoiced(invoice)
        ? {
            title: intl.formatMessage({
              id: 'ui.actions.title',
            }),
            key: 'action',
            fixed: 'right',
            render: (a, record) => (
              <>
                <Space>
                  {/* DETAIL */}
                  <Button
                    icon={<EyeOutlined />}
                    href={
                      record.call
                        ? `/calls/${record.call.id}/interventions/${record.id}`
                        : `/interventions/${record.id}`
                    }
                  />

                  {/*  DELETE */}

                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      setDeleteModalData({
                        open: true,
                        record,
                      })
                    }
                  />
                </Space>
              </>
            ),
          }
        : {
            title: intl.formatMessage({
              id: 'ui.actions.title',
            }),
            key: 'action',
            fixed: 'right',
            render: (a, record) => (
              <>
                <Space>
                  {/* DETAIL */}
                  <Button
                    icon={<EyeOutlined />}
                    href={
                      record.call
                        ? `/calls/${record.call.id}/interventions/${record.id}`
                        : `/interventions/${record.id}`
                    }
                  />
                </Space>
              </>
            ),
          }),
    },
  ]

  // handle delete modal confirm
  const handleModalConfirm = () => {
    setDeleteModalData((prevModalData) => {
      onDelete(_.get(deleteModalData, 'record'))
      return { open: false }
    })
  }
  return (
    <>
      <AntdTable
        dataSource={selectedInterventions}
        rowKey={'id'}
        columns={columns}
        scroll={{ x: true }}
        pagination={false}
      />

      {/* Delete Intervention Modal*/}
      <ModalDelete
        model={'intervention'}
        onOk={handleModalConfirm}
        open={deleteModalData.open}
        onCancel={() => setDeleteModalData({ open: false })}
        recordType={<FormattedMessage id={'ui.interventions.singular'} />}
      />
    </>
  )
}

export default InterventionsTable
