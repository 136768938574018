import React, { useContext, useEffect, useState } from 'react'
import { CenteredSpinner } from '../.././Components'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'
import { MaterialContextProvider } from './contexts'
import { Card, Input, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { MaterialFields, MaterialForm } from './form'
import { InputField } from '../../Components/Fields'

const log = new Logger('MaterialEdit')

const Edit = (props) => {
  const [loading, setLoading] = useState(false)

  log.verbose('Current props: ', props)

  // material
  const [material, setMaterial] = useState(_.get(props, 'material', {}))

  const { setMode } = useContext(GlobalContext)

  log.debug('props, material', { props, material })
  useEffect(() => {
    setMode('edit')
  }, [])

  return (
    <MaterialContextProvider
      value={{
        material,
        setMaterial,
      }}
    >
      {loading ? (
        <CenteredSpinner />
      ) : (
        <MaterialForm
          title={
            <Typography.Title level={2}>
              <FormattedMessage id={'ui.materials.actions.edit'} />
            </Typography.Title>
          }
          action={`/materials/${_.get(material, 'id')}`}
          {...props}
        >
          {/* Hidden Route ID */}
          <InputField name={'id'} hidden />
          <Input type={'hidden'} name={'_method'} value={'patch'} />

          {/* Form Fields */}
          <Card>
            <MaterialFields />
          </Card>
        </MaterialForm>
      )}
    </MaterialContextProvider>
  )
}

export default Edit
