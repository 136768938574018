import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Card, Col, Divider, Form, Input, Row } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import styled from 'styled-components'

import vehicles from '../../../../../../../../lib/enums/enum_equipment_vehicles.json'
import technicians from '../../../../../../../../lib/enums/enum_technicians.json'
import { GutteredRow } from '../../../.././Components'
import { DateTimePickerField } from '../../../../Components/Fields'
import MultipleSelect from '../../../../Components/Select/MultipleSelect'
import { ModalRemoveStage, SortableStage } from '../../components'
import { RouteContext } from '../../contexts'

const log = new Logger('RouteDetails')

const StyledBottomDiv = styled.div`
  margin-bottom: 20px;
`

const RouteDetails = (props) => {
  const {
    route,
    setRoute,
    stages,
    setStages,
    totalDistance,
    interventions,
    setInterventions,
    totalTime,
  } = useContext(RouteContext)

  // stage modal data
  const [modalData, setModalData] = useState({
    open: false,
  })

  // D&D Sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const form = Form.useFormInstance()

  // handle stage change
  // handle drag end
  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      log.debug('active, over', { active, over })

      setStages((items) => {
        let newItems = items

        const oldIndex = _.findIndex(items, { id: active.id })
        const newIndex = _.findIndex(items, { id: over.id })

        const el = arrayMove(newItems, oldIndex, newIndex)
        log.debug('handleDragEnd POST', { el })
        return el
      })
    }
  }

  // handle stage click
  const handleDeleteStage = (stage) => {
    log.debug('deleting stage', stage)

    setModalData({
      open: true,
      stage: stage,
    })
  }

  // handle remove stage modal ok
  const handleModalOk = () => {
    const stage = _.get(modalData, 'stage', {})

    setStages((items) => {
      const copyItems = [...items]
      let removedItem = _.remove(copyItems, (item) => item.id === stage.id)

      // set removed stage to schedule again
      _.set(removedItem, '[0].status', 'to_schedule')
      _.set(removedItem, '[0].expected_duration', null)
      log.debug('removed item', removedItem)
      setInterventions(_.unionBy(removedItem, interventions, 'id'))

      log.debug('after remove', copyItems)
      return copyItems
    })

    // update form state
    const stagesValues = form.getFieldValue('interventions')
    _.remove(stagesValues, (item) => item.id === stage.id)
    form.setFieldValue('interventions', stagesValues)

    setModalData({ open: false })
  }

  log.debug('props, stages, total time, total distance', {
    props,
    stages,
    totalTime,
    totalDistance,
  })

  return (
    <Card title={<FormattedMessage id={'ui.routes.form.labels.details'} />}>
      {/* Route Details */}
      <GutteredRow>
        <Col xl={12}>
          <FormattedMessage id={'ui.routes.form.labels.total_distance'} />
        </Col>
        <Col xl={12}>
          <span>
            {(totalDistance / 1000).toFixed(0)}
            km
          </span>
          <Input
            type={'hidden'}
            name={`total_distance`}
            value={totalDistance}
          />
        </Col>
      </GutteredRow>
      <GutteredRow>
        <Col xl={12}>
          <FormattedMessage id={'ui.routes.form.labels.total_time'} />
        </Col>
        <Col xl={12}>
          <span>
            {dayjs.duration(totalTime, 'seconds').format('H[h] m[m]')}
          </span>
          <Input type={'hidden'} name={`total_time`} value={totalTime} />
        </Col>
      </GutteredRow>

      <Divider />

      {/* Scheduled Date */}
      <GutteredRow>
        <Col xl={24}>
          <DateTimePickerField
            name={'scheduled_datetime'}
            required
            label={'ui.routes.form.labels.scheduled_datetime'}
            initialValue={_.get(route, 'scheduled_datetime')}
            onChange={(dateTime) =>
              setRoute({ ...route, scheduled_datetime: dateTime })
            }
          />
        </Col>
      </GutteredRow>

      {/* Stages */}
      <GutteredRow>
        <Col>
          <h3>
            <FormattedMessage id={'ui.routes.form.labels.stages'} />
          </h3>
        </Col>
      </GutteredRow>

      {/*  Stages Card*/}
      <StyledBottomDiv>
        <GutteredRow>
          <Col
            xl={24}
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            {/*<Card>*/}
            <Row justify={'end'}>
              <Col>
                <small>
                  <FormattedMessage
                    id={'ui.routes.form.labels.scheduled_time'}
                  />
                </small>
              </Col>
            </Row>
            {/*  Map stages and replace with D&D */}
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={stages}
                strategy={verticalListSortingStrategy}
              >
                {stages.map((stage, idx) => (
                  <StyledBottomDiv key={`stage_${idx}`}>
                    <SortableStage
                      formPrefix={`interventions.${idx}`}
                      prefix={`interventions_attributes[${idx}]`}
                      id={stage.id}
                      key={stage.id}
                      stage={stage}
                      stages={stages}
                      formInstance={form}
                      totalTimeUpdate={totalTime}
                      setStages={setStages}
                      onDeleteClick={(stage) => handleDeleteStage(stage)}
                    >
                      {_.get(stage, 'facility.business_name', '')}
                    </SortableStage>
                  </StyledBottomDiv>
                ))}
              </SortableContext>
            </DndContext>
            {/*</Card>*/}
          </Col>
        </GutteredRow>
      </StyledBottomDiv>

      {/*  Vehicles */}
      <GutteredRow>
        <Col md={24}>
          <MultipleSelect
            railsName={'vehicles[]'}
            name={'vehicles'}
            label={'ui.routes.form.labels.vehicles'}
            required
            options={vehicles}
            defaultValue={[]}
          />
        </Col>
      </GutteredRow>

      {/* Team */}
      <GutteredRow>
        <Col md={24}>
          <MultipleSelect
            name={'team'}
            railsName={'team[]'}
            label={'ui.routes.form.labels.team'}
            required
            options={technicians}
            defaultValue={[]}
          />
        </Col>
      </GutteredRow>

      {/* Intervention Attributes */}
      {_.map(stages, (stage, idx) => {
        const prefix = `interventions_attributes[${idx}]`
        return (
          <Input
            key={`intervention-id-${idx}`}
            type={'hidden'}
            name={`${prefix}[id]`}
            value={_.get(stage, 'id')}
          />
        )
      })}

      {/*  Modal Remove Stage */}
      <ModalRemoveStage
        handleModalOk={handleModalOk}
        onCancel={() => setModalData({ open: false })}
        modalData={modalData}
      />

      {/*  Hidden Timezone Field */}
      <Input name={'timezone'} type={'hidden'} value={dayjs.tz.guess()} />
    </Card>
  )
}

export default RouteDetails
