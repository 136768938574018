import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Card, Input, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import _ from 'lodash'
import * as R from 'ramda'

import { useReferrer } from '@components/Providers/ReferrerWrapperProvider'

import { CenteredSpinner } from '../.././Components'
import { ReferrerInput } from '../../Components'
import { InputField } from '../../Components/Fields'
import { GlobalContext } from '../../contexts'
import { marshalIntervention } from '../../utils'
import { InterventionContextProvider } from './contexts'
import { InterventionForm } from './form'
import FormFields from './form/FormFields'

const log = new Logger('InterventionEdit')

const Edit = (props) => {
  const [loading, setLoading] = useState(false)

  log.verbose('Current props: ', props)

  const [intervention, setIntervention] = useState(
    marshalIntervention(props.intervention)
  )

  const { setMode } = useContext(GlobalContext)

  const { facilities, call, referents } = props

  log.debug('props, intervention, facilities, call', {
    props,
    intervention,
    facilities,
    call,
    referents,
  })

  useEffect(() => {
    setMode('edit')
  }, [])

  const { referrer, queryParams } = useReferrer()

  const baseAction = call
    ? `/calls/${call.id}/interventions/${intervention.id}`
    : `/interventions/${intervention.id}`

  const ref = R.propOr('', 'ref', queryParams)

  const action = R.includes('routes', ref)
    ? R.concat(baseAction, `?ref=${ref}`)
    : baseAction

  return (
    <InterventionContextProvider
      value={{ intervention, setIntervention, call }}
    >
      {loading ? (
        <CenteredSpinner />
      ) : (
        <InterventionForm
          title={
            _.get(intervention, 'father') ? (
              <Typography.Title level={2}>
                <FormattedMessage id={'ui.interventions.actions.reschedule'} />
              </Typography.Title>
            ) : (
              <Typography.Title level={2}>
                <FormattedMessage id={'ui.interventions.actions.edit'} />
              </Typography.Title>
            )
          }
          action={action}
          {...props}
        >
          <Card>
            {/* Hidden Intervention ID */}
            <InputField name={'id'} hidden />
            <Input type={'hidden'} name={'_method'} value={'patch'} />
            <FormFields
              facilities={facilities}
              call={call}
              referents={referents}
            />

            {_.includes(referrer, 'routes') && (
              <ReferrerInput
                params={{
                  expanded_route: _.get(intervention, 'route.id'),
                }}
              />
            )}
          </Card>
        </InterventionForm>
      )}
    </InterventionContextProvider>
  )
}

export default Edit
