import React from 'react'
import { Col, Modal, Space } from 'antd'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { GutteredRow } from './form-components'
import { StyledDetailLabel } from '../styled'
import { LocaleDisplay } from './Display'
import { Logger } from 'aws-amplify'

const log = new Logger('Components/ModalDelete')

const ModalDelete = ({
  open,
  recordType,
  onOk,
  onCancel,
  model,
  data = {},
  keys = [],
  enumPairs = [],
}) => {
  const enums = _.fromPairs(enumPairs)
  
  return (
    <Modal
      open={open}
      okType={'danger'}
      title={
        <FormattedMessage
          id={'ui.modals.delete.title'}
          values={{ record: recordType }}
        />
      }
      okText={<FormattedMessage id={'ui.modals.delete.ok_text'} />}
      cancelText={<FormattedMessage id={'ui.modals.delete.cancel'} />}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Space
        direction={'vertical'}
        style={{
          display: 'flex',
        }}
      >
        {_.map(keys, (k, i) => (
          <GutteredRow key={`row_${i}`}>
            <Col>
              <StyledDetailLabel>
                <FormattedMessage id={`ui.${model}.attributes.${k}`} />
              </StyledDetailLabel>
            </Col>
            <Col>
              {_.isEmpty(_.get(enums, k)) ? (
                data[k]
              ) : (
                <LocaleDisplay localePath={_.get(enums, k)} value={data[k]} />
              )}
            </Col>
          </GutteredRow>
        ))}

        <FormattedMessage id={'ui.modals.delete.description'} />
      </Space>
    </Modal>
  )
}

export default ModalDelete
