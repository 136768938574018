import React, { useContext, useEffect } from 'react'
import { Table as AntdTable } from 'antd'
import { Logger } from 'aws-amplify'
import { useIntl } from 'react-intl'
import { addPropertyToColumns } from '../../../utils'
import { HiddenInputRenderer } from '../../.././Components'
import { InterventionContext } from '../contexts'
import _ from 'lodash'
import { FieldInputNumber } from '../../../Components/Fields'
import { Display, DisplayPrice } from '../../../Components/Display'

const log = new Logger('Intervention/MaterialsTable')

const MaterialsTable = ({ dataSource, calcFunction, setTotalMaterial }) => {
  // inject Intl instance
  const intl = useIntl()

  const {
    materials,
    selectedMaterials,
    setSelectedMaterials,
    setTriggerCalculations,
  } = useContext(InterventionContext)

  const calcTotalForMaterial = (material, quantity = null) => {
    log.debug({ material, quantity, selectedMaterials })

    const unitPrice = _.get(material, 'unit_price')
    const total = _.multiply(unitPrice, quantity)

    const materialIndex = _.findIndex(
      selectedMaterials,
      (v) => v.id === material.id
    )

    material = {
      ...material,
      quantity,
      total,
    }

    log.debug({ material, total, materialIndex })

    const newMaterials = _.map(selectedMaterials, (m) => {
      if (
        _.get(material, 'material_id') === m.id ||
        _.get(material, 'id') === m.id
      ) {
        return material
      }
      return m
    })
    setSelectedMaterials(newMaterials)
  }

  const calcTotalForMaterials = (materials) => {
    const newMaterials = _.map(materials, (material) => {
      const unitPrice = _.get(material, 'unit_price')
      const quantity = _.get(material, 'quantity')
      const total = _.multiply(unitPrice, quantity)

      material = {
        ...material,
        quantity,
        total,
      }

      return material
    })

    log.debug('calcTotalForMaterials POST', { newMaterials })

    setSelectedMaterials(newMaterials)
  }

  // get measure unit by material Id
  const getMeasureUnit = (materialId) =>
    _.get(_.find(materials, { id: materialId }), 'measure_unit', null)

  const getColumns = () => {
    let columns = [
      {
        title: intl.formatMessage({
          id: 'ui.interventions.close_form.materials_table.attributes.name',
        }),
        dataIndex: 'name',
        render: (_, record) => <Display value={record.name} />,
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.close_form.materials_table.attributes.quantity',
        }),
        dataIndex: 'quantity',
        render: (a, record, idx) => (
          <FieldInputNumber
            name={`materials.${idx}.quantity`}
            railsName={`materials[${idx}][quantity]`}
            required
            disabled={false}
            onChange={(_, value) => {
              calcTotalForMaterial(record, value)
            }}
            initialValue={_.get(record, 'quantity')}
            style={{
              marginBottom: 0,
            }}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.close_form.materials_table.attributes.unit_price',
        }),
        dataIndex: 'unit_price',
        render: (a, record) => (
          <DisplayPrice
            value={record.unit_price}
            suffix={`/${getMeasureUnit(
              _.defaultTo(_.get(record, 'material_id'), _.get(record, 'id'))
            )}`}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'ui.interventions.close_form.materials_table.attributes.total',
        }),
        dataIndex: 'total',
        render: (value, record) => {
          log.debug(record)
          return <DisplayPrice value={record.total} />
        },
      },
    ]

    log.debug('Adding responsive property to columns ...')
    return addPropertyToColumns(columns, 'responsive', ['md'])
  }

  const columns = getColumns()

  log.info('Materials data source :', dataSource)

  // hooks

  // run calc total for every material only once
  useEffect(() => {
    // init total for all materials
    const initTotals = () => {
      calcTotalForMaterials(selectedMaterials)
    }
    initTotals()
    calcFunction()
  }, [])

  return (
    <>
      <AntdTable
        dataSource={dataSource}
        rowKey={'id'}
        columns={columns}
        pagination={false}
        size={'small'}
      />

      {/*  Hidden Input */}
      <HiddenInputRenderer
        keys={['id', 'unit_price', 'material_id']}
        list={selectedMaterials}
        prefix={'materials'}
      />
    </>
  )
}

export default MaterialsTable
