import React from 'react'
import { Row } from 'antd'

const GutteredRow = ({ children, style = undefined, ...otherProps }) => (
  <Row
    gutter={{
      xs: 8,
      sm: 16,
      md: 24,
      lg: 32,
    }}
    style={style}
    {...otherProps}
  >
    {children}
  </Row>
)

export default GutteredRow
