import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as F from '@utils/functions'
import { Button, Col, DatePicker, Row, Typography } from 'antd'
import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'

import { MATERIAL_SEARCH, makeApiRequest } from '@/api'
import { DEFAULT_PAGER } from '@/config'

import { CenteredSpinner, GutteredRow } from '../.././Components'
import { GlobalContext } from '../../contexts'
import { StyledCard } from '../../styled'
import { MaterialSearch, MaterialsTable } from './components'
import { MaterialsContextProvider } from './contexts'

dayjs.extend(customParseFormat)

const { Title } = Typography
const { RangePicker } = DatePicker

const log = new Logger('MaterialsIndex')

const Index = (props) => {
  const intl = useIntl()

  // get materials data
  const [materials, setMaterials] = useState(_.get(props, 'materials', []))

  log.info('Materials data: ', materials)

  // loading state
  const [loading, setLoading] = useState(false)

  // search loading state
  const [searchLoading, setSearchLoading] = useState(false)

  // query
  const [query, setQuery] = useState('')
  // filters
  const [filters, setFilters] = useState([])
  const [pagination, setPagination] = useState(DEFAULT_PAGER)

  const isTableChanged = useRef(true)

  const { setMode } = useContext(GlobalContext)

  useEffect(() => {
    setMode('list')
  }, [])

  const filterMaterials = async () => {
    setSearchLoading(true)
    const { data, headers } = await makeApiRequest(MATERIAL_SEARCH, 'post', {
      body: { query, filters },
      queryParams: F.marshalPaginationTo(pagination),
    })

    const newPagination = {
      ...pagination,
      ...F.marshalPaginationFrom(headers),
    }

    log.debug('filterMaterials.success ', { data, newPagination })

    setPagination(newPagination)
    setMaterials(data)
    setSearchLoading(false)
  }

  return loading ? (
    <CenteredSpinner />
  ) : (
    <MaterialsContextProvider
      value={{
        materials,
        setMaterials,
        filters,
        setFilters,
        query,
        setQuery,
        searchLoading,
        setSearchLoading,
        isTableChanged,
        pagination,
        setPagination,
      }}
    >
      <GutteredRow>
        <Col>
          <Title level={3}>
            <FormattedMessage id={'ui.materials.list_name'} />
          </Title>
        </Col>
      </GutteredRow>
      <Row justify={'space-between'}>
        <Col xl={10}>
          {/* materials search*/}
          <MaterialSearch
            onSearch={() => {
              setPagination({ total: pagination.total, ...DEFAULT_PAGER })
              isTableChanged.current = true
            }}
          />
        </Col>
        <Col
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type='primary'
            href='/materials/new'
            onClick={() => setLoading(!loading)}
          >
            <FormattedMessage id={'ui.materials.buttons.create'} />
          </Button>
        </Col>
      </Row>
      <StyledCard>
        <MaterialsTable
          dataSource={materials}
          searchMaterials={filterMaterials}
        />
      </StyledCard>
    </MaterialsContextProvider>
  )
}

export default Index
