import React from 'react'
import { useIntl } from 'react-intl'
import { Table as AntdTable } from 'antd'
import { Logger } from 'aws-amplify'
import { LocaleDisplay } from '../../.././Components/Display'
import { StatusTag } from '../.././Customers/components'
import { StatusTag as SubscriptionTag } from '../.././Subscriptions/components'
import _ from 'lodash'

const log = new Logger('FacilitiesTable')

const getColumns = (intl) => {
  const columns = [
    {
      title: intl.formatMessage({ id: 'ui.facilities.attributes.id' }),
      dataIndex: 'id',
      fixed: true,
    },
    {
      title: intl.formatMessage({
        id: 'ui.facilities.attributes.business_name',
      }),
      dataIndex: 'business_name',
    },
    {
      title: intl.formatMessage({
        id: 'ui.facilities.attributes.address',
      }),
      dataIndex: 'address',
    },
    {
      title: intl.formatMessage({
        id: 'ui.facilities.attributes.rope_bells_count',
      }),
      dataIndex: 'rope_bells_count',
    },
    {
      title: intl.formatMessage({
        id: 'ui.facilities.attributes.electrified_bells_count',
      }),
      dataIndex: 'electrified_bells_count',
    },
    {
      title: intl.formatMessage({ id: 'ui.facilities.attributes.hq_distance' }),
      dataIndex: 'hq_distance',
    },
    {
      title: intl.formatMessage({ id: 'ui.facilities.attributes.status' }),
      dataIndex: 'status',
      render: (_, record) => (
        <StatusTag status={record.status}>
          <LocaleDisplay
            localePath={'ui.facilities.enums.statuses'}
            value={record.status}
          />
        </StatusTag>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'ui.facilities.attributes.subscription_status',
      }),
      dataIndex: 'subscription_status',
      render: (x, record) => (
        <SubscriptionTag status={_.get(record, 'subscription.status', 'blank')}>
          <LocaleDisplay
            localePath={'ui.subscriptions.enums.statuses'}
            value={_.get(record, 'subscription.status', 'blank')}
          />
        </SubscriptionTag>
      ),
    },
  ]

  log.debug('Adding responsive property to columns ...')
  return columns
}

export const Table = (props) => {
  log.debug('Rendering facilities table ...')
  // inject intl instance
  const intl = useIntl()

  const columns = getColumns(intl)

  const { dataSource } = props

  log.debug('Facilities data', dataSource)
  return (
    <AntdTable
      columns={columns}
      rowKey={'id'}
      dataSource={dataSource}
      pagination={false}
      size={'small'}
      scroll={{ x: true }}
    />
  )
}

export default Table
