import React, { useContext, useEffect, useState } from 'react'
import { Logger } from 'aws-amplify'
import { GlobalContext } from '../../contexts'
import _ from 'lodash'
import { InvoiceContextProvider } from './contexts'
import { Card, Input, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import InvoiceForm from './form/InvoiceForm'
import { InvoiceFields } from './form'
import { InputField } from '../../Components/Fields'
import { marshal } from '../../utils/invoices'

const log = new Logger('Invoices/New')

const New = (props) => {
  const { setMode } = useContext(GlobalContext)

  /* states */
  const [invoice, setInvoice] = useState(marshal(_.get(props, 'invoice')))

  log.debug('invoice', {
    invoice,
  })

  useEffect(() => {
    setMode('edit')
  }, [])

  return (
    <InvoiceContextProvider
      value={{
        invoice,
        setInvoice,
      }}
    >
      <InvoiceForm
        title={
          <Typography.Title level={2}>
            <FormattedMessage id={'ui.invoices.actions.edit'} />
          </Typography.Title>
        }
        action={`/invoices/${invoice.id}`}
        {...props}
      >
        {/* Hidden Call ID */}
        <InputField name={'id'} hidden />
        <Input type={'hidden'} name={'_method'} value={'patch'} />

        {/* Form Fields */}
        <Card>
          <InvoiceFields />
        </Card>
      </InvoiceForm>
    </InvoiceContextProvider>
  )
}

export default New
