import { Modal } from 'antd'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl/lib'
import React from 'react'

const ModalRemoveStage = ({ modalData, handleModalOk, onCancel }) => {
  return (
    <Modal
      open={modalData.open}
      title={<span>{_.get(modalData, 'stage.facility.business_name')}</span>}
      okText={<FormattedMessage id={'ui.modals.delete.ok_text'} />}
      onOk={handleModalOk}
      onCancel={onCancel}
      okType={'danger'}
    >
      <FormattedMessage id={'ui.routes.modal.remove_stage.text'} />
    </Modal>
  )
}

export default ModalRemoveStage
