import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Col, Form, Row, Space, Typography } from 'antd'
import styled from 'styled-components'

import { GlobalContext } from '../../../contexts'
import { DeviceContext } from '../contexts'

const StyledDiv = styled.div`
  margin-bottom: 20px;
`

const FormHeader = ({ title, mode, buttonType = {} }) => {
  const { setLoading: setGlobalLoading } = useContext(GlobalContext)

  return (
    <>
      {title}
      <Row justify={'space-between'}>
        <Col>
          <StyledDiv>
            <Button
              type={'primary'}
              href={'/devices'}
              onClick={() => setGlobalLoading(true)}
            >
              <FormattedMessage id={'ui.devices.actions.back_to_list'} />
            </Button>
          </StyledDiv>
        </Col>
        <Col>
          <Form.Item>
            <Button {...buttonType} htmlType={'submit'}>
              <FormattedMessage id={'ui.button.submit'} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default FormHeader
