import { Logger } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'

import { typologies } from '../../enums/intervention'
import { calculateFee } from '../subscriptions'
import { isMaintenance, toHours } from './marshal'

const log = new Logger('Interventions/Helpers')

export const fetchBreakStage = () => {
  return {
    id: -1, // fake id
    priority: 'low',
    break: true,
    notes: 'Break Stage',
    status: 'to_schedule',
    expected_duration: 1,
    typology: _.sample(typologies),
  }
}

export const rejectBreakStage = (stages) => _.reject(stages, { break: true })
export const isBreak = (stage) => _.get(stage, 'break')

export const getTotalWithoutMaterials = (facility, intervention) => {
  let subscriptionFee = 0

  if (isMaintenance(intervention))
    subscriptionFee = calculateFee(_.get(facility, 'subscription'))
  log.debug('subscription fee...', { subscriptionFee })
  const total = _.chain(intervention)
    .get('intervention_materials', [])
    .reduce((acc, m) => {
      acc -= _.multiply(_.get(m, 'unit_price', 0), _.get(m, 'quantity', 0))
      return acc
    }, _.get(intervention, 'total_intervention', 0))
    .subtract(subscriptionFee)
    .round(2)
    .value()

  return total
}

/**
 *
 * @param {dayjs.Dayjs | null} start
 * @param {dayjs.Dayjs | null} end
 * @returns {number}
 */
const toMinutes = (start, end) => {
  const minutes = dayjs(end).diff(dayjs(start), 'm')
  return _.isNaN(minutes) ? 0 : minutes
}

/**
 *
 * @param {Array.<?dayjs.Dayjs>} morningTimes
 * @param {Array.<?dayjs.Dayjs>} eveningTimes
 * @return {[totalMinutes: number,  hours: number]}
 */
export const getWorkingTimes = (morningTimes, eveningTimes) => {
  log.debug('getWorkingTimes PRE', { morningTimes, eveningTimes })
  const morningMinutes = toMinutes(
    _.get(morningTimes, '[0]'),
    _.get(morningTimes, '[1]')
  )

  const eveningMinutes = toMinutes(
    _.get(eveningTimes, '[0]'),
    _.get(eveningTimes, '[1]')
  )

  const totalMinutes = _.sum([morningMinutes, eveningMinutes])
  const hours = totalMinutes > 0 ? toHours(totalMinutes) : NaN

  log.debug('getWorkingTimes POST', { morningMinutes, eveningMinutes, hours })

  return [totalMinutes, hours]
}

export const resetStageTimes = (stages) => {
  const items = _.cloneDeep(stages)

  _.forEach(items, (stage, idx) => {
    // reset stages props
    _.forEach(['totalTime', 'scheduled_time', 'expected_end_time'], (prop) => {
      _.set(items, `[${idx}].${prop}`, null)
    })
  })

  return items
}
